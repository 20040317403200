import React, { useState} from "react";
import { UrlApi } from "../../services/apirest";
import {
  Button,
  Dialog,
  Box,
  Grid,
  Stack,
  Snackbar,
  TextField,
  Alert,
} from "@mui/material";
import moment from "moment"; // PARA LA FECHA
import { grey } from "@mui/material/colors";
import Title from "../Title";
import { logEventos } from "../../services/services";

function Dialog_ajuste_stock(props) {
  const [datosArt, setDatosArt] = useState(props.datosArt);
  const [abrirDialogAjusteStock, setAbrirDialogAjusteStock] = useState(
    props.abrirDialogAjusteStock
  );

  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);
  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [openSnackEditar, setOpenSnackEditar] = React.useState(false);
  const [disabledGuardarRe, setDisabledGuardarRe] = useState(false);
  const [colorTextField, setcolorTextField] = useState();

  // const guardarAjusteStock = async (e) => {
  //   logEventos("Guardar ajuste de stock", "Stock Sucursales", "Se guardo el ajuste de stock", localStorage.getItem("user"))
  //   e.preventDefault();
  //   await fetch(
  //     UrlApi +
  //       "/stock_inventarios/" +
  //       datosArt.id_almacen +
  //       "/" +
  //       datosArt.id_articulo,
  //     {
  //       method: "PUT",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({
  //         cantidad: datosArt.cantidad,
  //         usuario: localStorage.getItem("user"),
  //       }),
  //     }
  //   )
  //     .then((res) => {
  //       if (!res.err) {
  //         setOpenSnack(true);
  //         // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
  //         setColorMensaje("#D4EFDF");
  //         setMensaje("El Ajuste de Stock se Realizo Exitosamente !");
  //       } else {
  //         setOpenSnack(false);
  //         setColorMensaje("#F1948A");
  //         setMensaje("Error al Realizar Ajuste de Stock");
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });
  //   e.preventDefault();
  //   await fetch(UrlApi + "/stock_transacciones", {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify({
  //       id_accion: 201,
  //       id_articulo: datosArt.id_articulo,
  //       almacen_origen: datosArt.id_almacen,
  //       cantidad_recibida: datosArt.cantidad,
  //       fecha_transaccion: moment(datosArt.fecha_transaccion).format(
  //         "YYYY-MM-DD HH:mm:ss"
  //       ),
  //       usuario: localStorage.getItem("user"),
  //     }),
  //   })
  //     .then((res) => {
  //       if (!res.err) {
  //         setOpenSnack(true);
  //         setColorMensaje("#D4EFDF");
  //         setMensaje("El artículo se guardó exitosamente !");
  //       } else {
  //         setOpenSnack(false);
  //         setColorMensaje("#F1948A");
  //         setMensaje("Error al guardar el artículo");
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });
  //   handleClose();
  // };

  //console.log("DATOS ART", datosArt);

  //Funcion para guardar AL EDITAR
  const guardarAjuste = async (e) => {
    logEventos(
      "Guardar ajuste de stock",
      "Stock Sucursales",
      "Se guardo el ajuste de stock",
      localStorage.getItem("user")
    );
    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(UrlApi + "/stock_transacciones", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_accion: 201,
        id_articulo: datosArt.id_articulo,
        almacen_origen: datosArt.id_almacen,
        cantidad_recibida: datosArt.cantidad,
        fecha_transaccion: moment(datosArt.fecha_transaccion).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        usuario: localStorage.getItem("user"),
        autorizar_modificacion: 1,
        detalle_transaccion: datosArt.detalle_transaccion,
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          //   setUpdate(true);
          // setMensajeConfirma(true)
          setColorMensaje("#D4EFDF");
          setMensaje("El artículo se autorizó!");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al autorizar el artículo");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    // console.log(row.id_articulo);
    handleClose();
  };

  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleClose = () => {
    setAbrirDialogAjusteStock(!abrirDialogAjusteStock);
    if (props.onClose) {
      props.onClose();
    }
  };

  const almacenar = (e) => {
    setDatosArt({
      ...datosArt,
      [e.target.name]: e.target.value,
    });
  };

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };
  
  return (
    <>
      <Dialog
        open={abrirDialogAjusteStock}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <Title
          titulo={`Ajuste de Stock`}
          handleClose={() => {
            handleClose();
          }}
        />
        <form onSubmit={guardarAjuste} autoComplete="off">
          <Box
            border={0.5}
            borderColor={grey[300]}
            backgroundColor="#F4F6F6"
            p={2}
            py={2}
            mt={1}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} py={1} mb={1} mt={1}>
                <TextField
                  style={style}
                  label="Artículo"
                  size="small"
                  name="nombre_articulo"
                  value={datosArt.articulo}
                  inputProps={{
                    readOnly: true,
                    "aria-label": "Without label",
                  }}
                />
              </Grid>
              <Grid item xs={12} py={1} mb={1}>
                <TextField
                  style={style}
                  label="Almacen de Origen"
                  size="small"
                  name="almacen"
                  value={datosArt.almacen}
                  inputProps={{
                    readOnly: true,
                    "aria-label": "Without label",
                  }}
                />
              </Grid>
              <Grid item xs={12} py={1} mb={1}>
                <TextField
                  style={style}
                  label="Cantidad en el Inventario"
                  name="cantidad"
                  size="small"
                  inputProps={{
                    maxLength: 100,
                  }}
                  focused
                  required
                  value={datosArt.cantidad}
                  onChange={almacenar}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                    }
                }}
                />
              </Grid>
              <Grid item xs={12} py={1} mb={1}>
                <TextField
                  style={style}
                  label="Detalle del ajuste"
                  name="detalle_transaccion"
                  size="small"
                  inputProps={{
                    maxLength: 100,
                  }}
                  focused
                  required
                  value={datosArt.detalle_transaccion}
                  onChange={almacenar}
                />
              </Grid>
            </Grid>

            <Stack direction="row">
              <Grid container direction={{ xs: "column", sm: "row" }}>
                <Grid item xs={9}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleClose}
                  >
                    No
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="success"
                    type="submit"
                  >
                    Si
                  </Button>
                </Grid>
              </Grid>
            </Stack>
          </Box>
        </form>
      </Dialog>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}

export default Dialog_ajuste_stock;
