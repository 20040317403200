//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio/Nicolas Pascucci
// Creation Date : 06/03/2024
// Version : 1
// Description : Tabla de cantidades de butano,propano,bso y propelente
//------Changes ----------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useEffect, useState, useMemo } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Button,
} from "@mui/material";
import moment from "moment";
import { UrlApi } from "../../services/apirest";
import { styled } from "@mui/system";
import Navbar from "../Navbar";
//CSV
import { ExportToCsv } from "export-to-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { blueGrey } from "@mui/material/colors";
import { logEventos } from "../../services/services";
import CircularProgress from '@mui/material/CircularProgress';


const HeaderCell = styled(TableCell)({
  marginTop: "2px",
  fontSize: 13,
  color: "white",
  fontWeight: "normal",
  backgroundColor: "#2471A3",
  borderColor: "white",
  padding: "6.3px",
});

const TotalCell = styled(TableCell)({
  padding: "6px",
});

function Proveedores_abastecimiento() {
  const [loading, setLoading] = useState(true);
  const [bocasCarga, setBocasCarga] = useState();
  const [solicitudes, setSolicitudes] = useState();
  const [abastecimiento, setAbastecimiento] = useState();
  const [totalesSem, setTotalesSem] = useState()

  const fechaActual = moment();

  useEffect(() => {
    logEventos("Acceso al menú resumen de petroleras", "Resumen de Petroleras", "Ver resumen de pedidos de NaturalGas y de Hipergas", localStorage.getItem("user"))
}, [])

  // console.log(fechaActual);

  // Calcula la fecha del próximo lunes
  const lunesProximo = fechaActual.clone().day(8); // 8 representa el día lunes de la próxima semana

  // Obtén los nombres y fechas de los días de la semana para la próxima semana
  const diasProximaSemana = [];
  for (let i = 0; i < 7; i++) {
    const fechaDia = lunesProximo.clone().add(i, "days");
    diasProximaSemana.push({
      id: i, // Utilizamos la posición en el bucle como id
      nombre: fechaDia.format("dddd"),
      fecha: fechaDia.format("YYYY-MM-DD"),
    });
  }

  const obtenerBocasCarga = () => {
    fetch(UrlApi + "/bocas_carga")
      .then((data) => data.json())
      .then((data) => setBocasCarga(data));
  };
  useEffect(() => {
    obtenerBocasCarga();
  }, []);



  // ========================================================= TABLA ============================================================ //

  const obtenerDatosPorFechaYSucursal = (
    fecha,
    sucursalId,
    tipoGas,
    abastecimiento,
    empresa
  ) => {
    // Verificar si abastecimiento es un array válido
    if (Array.isArray(abastecimiento)) {
      // Filtrar los datos de abastecimiento por fecha, sucursal y empresa
      const datosFiltrados = abastecimiento.filter((dato) => {
        const fechaDato = moment(dato.fecha).format("YYYY-MM-DD");
        return (
          fechaDato === fecha &&
          dato.proveedor === sucursalId &&
          dato.empresa_destino === empresa
        );
      });

      // Verificar si hay datos filtrados
      if (datosFiltrados.length > 0) {
        const dato = datosFiltrados[0]; // Supongamos que solo hay un dato por celda
        // Devolver el valor correspondiente al tipo de gas si existe
        switch (tipoGas) {
          case "butano":
            return dato && dato.butano ? dato.butano : "-";
          case "propano":
            return dato && dato.propano ? dato.propano : "-";
          case "bso":
            return dato && dato.bso ? dato.bso : "-";
          case "propelente":
            return dato && dato.propelente ? dato.propelente : "-";
          default:
            return "-";
        }
      }
    }
    // Si no se encuentran datos o abastecimiento no es un array válido
    return "-";
  };

  const diasSemanaActual = [];
  // console.log("diasSemanaActual",diasSemanaActual);

  // ========================================================= TOTAL X SEMANA ============================================================ //
  const [fechaInicioSemana, setFechaInicioSemana] = useState(
    fechaActual.clone().day(8)
  );

  const obtenerTotalesPorSemana = (sucursalId, empresaDestino) => {
      if (totalesSem) {
        const totales = {
          propano: 0,
          butano: 0,
          bso: 0,
          propelente: 0,
        };
    
        totalesSem.forEach((solicitud) => {
          if (
            solicitud.proveedor === sucursalId &&
            solicitud.empresa_destino === empresaDestino
          ) {
            totales.propano += solicitud.propano !== null ? solicitud.propano : 0;
            totales.butano += solicitud.butano !== null ? solicitud.butano : 0;
            totales.bso += solicitud.bso !== null ? solicitud.bso : 0;
            totales.propelente += solicitud.propelente !== null ? solicitud.propelente : 0;
          }
        });
    
        return totales;
      }
    };
    

  const [totalesPorSucursal, setTotalesPorSucursal] = useState({});


  const obtenerAbastecimiento = () => {
    fetch(UrlApi + "/proveedores_abastecimiento/acotado/'"+diasSemanaActual[0].fecha +"'/'"+diasSemanaActual[6].fecha+"'")
      .then((data) => data.json())
      .then((data) => setAbastecimiento(data));
  };

  const obtenerAbastecimientoTotales = () => {
    fetch(UrlApi + "/proveedores_abastecimiento/totales/'"+diasSemanaActual[0].fecha +"'/'"+diasSemanaActual[6].fecha+"'")
      .then((data) => data.json())
      .then((data) => setTotalesSem(data));
  };
  // useEffect(() => {
  //   obtenerAbastecimiento();
  // }, [diasSemanaActual]);

//  console.log("//abastecimiento", abastecimiento, diasSemanaActual[0]);
  // ========================================================= PARA BOTONES ANT Y SIG  ================================================ //
  const [cambio, setCambio] = useState(true);
  // console.log(fechaInicioSemana, "lunes proximo");
  const irSemanaAnterior = () => {
    setFechaInicioSemana((prevFecha) => prevFecha.clone().subtract(7, "days"));
    setCambio(true);
    setLoading(true)
  };

  const irSemanaSiguiente = () => {
    setFechaInicioSemana((prevFecha) => prevFecha.clone().add(7, "days"));
    setCambio(true);
    setLoading(true)
  };

  for (let i = 0; i < 7; i++) {
    const fechaDia = fechaInicioSemana.clone().add(i, "days");
    diasSemanaActual.push({
      id: i,
      nombre: fechaDia.format("dddd"),
      fecha: fechaDia.format("YYYY-MM-DD"),
    });
  }

  // ========================================================= TOTAL X DIA ============================================================ //

  const obtenerSumaPorTipoGasYFecha = (tipoGas, fecha, empresaDestino) => {
      
    if (abastecimiento) {
      let suma = 0;
      abastecimiento.forEach((solicitud) => {
          if (
          moment(solicitud.fecha).format("YYYY-MM-DD") === fecha &&
          solicitud.empresa_destino === empresaDestino
        ) {
          suma += solicitud[tipoGas] || 0;
        }
      });
      return suma;
    }
    return 0;
  };
  

  // ========================================================= CSV ================================================================= //
  //CSV
  const [abastecimientoCSV, setAbastecimientoCSV] = useState();
  // console.log("dias semana actual", diasSemanaActual);
  const obtenerAbastecimientoCSV = () => {
    fetch(
      UrlApi +
        "/proveedores_abastecimiento/csv/'" +
        diasSemanaActual[0].fecha +
        "'/'" +
        diasSemanaActual[6].fecha +
        "'"
    )
      .then((data) => data.json())
      .then((data) => setAbastecimientoCSV(data));
  };
  // CSV
  useEffect(() => {
    if (cambio === true) {
      obtenerAbastecimientoCSV();
      obtenerAbastecimiento()
      obtenerAbastecimientoTotales()
      setCambio(false);
    }
  }, [solicitudes, cambio]);
  //console.log("totales por semana", totalesSem);

  const columnsCSV = useMemo(
    () => [
      { header: "Fecha            ", accessorKey: "fecha", size: 150 },
      { header: "Empresa", accessorKey: "nombre_empresa", size: 100 },
      { header: "Proveedor", accessorKey: "nombre_proveedor", size: 100 },
      { header: "Boca", accessorKey: "nombre_boca", size: 100 },
      {
        header: "Cantidad de Butano",
        accessorKey: "butano",
        size: 100,
      },
      {
        header: "Cantidad de Propano",
        accessorKey: "propano",
        size: 100,
      },
      {
        header: "Cantidad Butano Desodorizado",
        accessorKey: "bso",
        size: 100,
      },
      {
        header: "Cantidad de Propelente",
        accessorKey: "propelente",
        size: 100,
      },
      { header: "Usuario", accessorKey: "usuario", size: 100 },
    ],
    []
  );

  //CSV
  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    filename: `Lista de Proveedores de Abastecimiento`,
    headers: columnsCSV.map((c) => c.header),
  };
  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportData = () => {
    csvExporter.generateCsv(abastecimientoCSV);
  };
  // ======================================================== TOTAL FINAL =========================================================== //

  // Función para obtener butano y propano de NaturalGas
  const obtenerTotalNaturalGas = (tipoGas) => {
    if (abastecimiento) {
      let totalNaturalGas = 0;
      diasSemanaActual.forEach((fecha) => {
        totalNaturalGas += obtenerSumaPorTipoGasYFecha(tipoGas, fecha.fecha, 4);
      });
      return totalNaturalGas;
    }
    return 0;
  };

  // // Llamada a la función para obtener la cantidad total de butano de NaturalGas
  // const totalButanoN = obtenerTotalNaturalGas("butano");
  // // Llamada a la función para obtener la cantidad total de propano de NaturalGas
  // const totalPropanoN = obtenerTotalNaturalGas("propano");

  const obtenerTotalHipergas = (tipoGas) => {
    if (abastecimiento) {
      let totalHipergas = 0;
      diasSemanaActual.forEach((fecha) => {
        totalHipergas += obtenerSumaPorTipoGasYFecha(tipoGas, fecha.fecha, 1);
      });
      return totalHipergas;
    }
    return 0;
  };

  useEffect(() => {
    // Simula la carga de datos
    setTimeout(() => {
      setLoading(false); // Indica que la carga ha terminado
    }, 3000); // Simula una carga de 2 segundos
  }, [loading]);
return (
  <>
  <Navbar />
  <div style={{ textAlign: "center", position: "relative", marginTop: 13 }}>
    <Button onClick={irSemanaAnterior} variant="contained" size="small">
      &lt; Semana Anterior
    </Button>
    <Button
      onClick={irSemanaSiguiente}
      variant="contained"
      sx={{ marginLeft: 1 }}
      size="small"
    >
      Semana Siguiente &gt;
    </Button>
    <div
      style={{
        position: "absolute",
        top: 0,
        right: 0,
        marginTop: 3,
        marginRight: 6,
      }}
    >
      <Button
        onClick={handleExportData}
        variant="contained"
        style={{ backgroundColor: blueGrey[400], color: "white" }}
        size="small"
        endIcon={<FileDownloadIcon />}
      >
        Exportar a CSV
      </Button>
    </div>
  </div>
  {loading === true?
  <div style={{
    display: 'flex',
    justifyContent: 'center', // Centra horizontalmente
    alignItems: 'center', // Centra verticalmente
    height: '100vh', // Establece la altura al 100% de la ventana
  }}>
    <CircularProgress  />
  </div> 
  :
  <Paper elevation={3}>
    <Table sx={{ mt: 1 }}>
      <TableHead>
        <TableRow>
          <HeaderCell align="center" sx={{ border: "1px solid white" }}>
            <b>Fecha</b>
          </HeaderCell>
          {diasSemanaActual.map((item, index) => (
            <React.Fragment key={index}>
              <HeaderCell
                align="center"
                colSpan={6}
                sx={{ border: "4px solid white" }}
              >
                <b>{moment(item.fecha).format("DD/MM")}</b>
              </HeaderCell>
            </React.Fragment>
          ))}
          <HeaderCell
            align="center"
            colSpan={6}
            sx={{ border: "4px solid white" }}
          >
            <b>Totales</b>
          </HeaderCell>
        </TableRow>
        <TableRow>
          <HeaderCell
            align="center"
            colSpan={1}
            sx={{ border: "1px solid white" }}
          >
            <b>Empresa</b>
          </HeaderCell>
          {diasSemanaActual.map((item, index) => (
            <React.Fragment key={index}>
              <HeaderCell
                align="center"
                colSpan={2}
                sx={{
                  borderLeft: "4px solid white",
                  borderRight: "3px solid white",
                }}
              >
                <b>NaturalGas</b>
              </HeaderCell>
              <HeaderCell
                align="center"
                colSpan={4}
                sx={{
                  borderLeft: "3px solid white",
                  borderRight: "3px solid white",
                }}
              >
                <b>HiperGas</b>
              </HeaderCell>
            </React.Fragment>
          ))}
          <HeaderCell
            align="center"
            colSpan={2}
            sx={{
              borderLeft: "4px solid white",
              borderRight: "3px solid white",
            }}
          >
            <b>NaturalGas</b>
          </HeaderCell>
          <HeaderCell
            align="center"
            colSpan={6}
            sx={{
              borderLeft: "3px solid white",
              borderRight: "3px solid white",
            }}
          >
            <b>Hipergas</b>
          </HeaderCell>
        </TableRow>
        <TableRow>
          <HeaderCell align="center" sx={{ border: "1px solid white" }}>
            <b>Tipo de Gas</b>
          </HeaderCell>
          {diasSemanaActual.map((item, index) => (
            <React.Fragment key={index}>
              <HeaderCell
                align="center"
                colSpan={1}
                sx={{
                  borderLeft: "4px solid white",
                  borderRight: "1px solid white",
                }}
              >
                <b>B</b>
              </HeaderCell>
              <HeaderCell
                align="center"
                colSpan={1}
                sx={{
                  borderLeft: "1px solid white",
                  borderRight: "3px solid white",
                }}
              >
                <b>P</b>
              </HeaderCell>
              <HeaderCell
                align="center"
                colSpan={1}
                sx={{
                  border: "1px solid white",
                  borderRight: "1px solid white",
                }}
              >
                <b>B</b>
              </HeaderCell>
              <HeaderCell
                align="center"
                colSpan={1}
                sx={{
                  border: "1px solid white",
                  borderRight: "1px solid white",
                }}
              >
                <b>P</b>
              </HeaderCell>
              <HeaderCell
                align="center"
                colSpan={1}
                sx={{
                  borderLeft: "1px solid white",
                  borderRight: "1px solid white",
                }}
              >
                <b>BSO</b>
              </HeaderCell>
              <HeaderCell
                align="center"
                colSpan={1}
                sx={{
                  borderLeft: "1px solid white",
                  borderRight: "3px solid white",
                }}
              >
                <b>PPTE</b>
              </HeaderCell>
            </React.Fragment>
          ))}
          <HeaderCell align="center" sx={{borderLeft: "4px solid white", borderRight: "1px solid white" }}>
            <b>B</b>
          </HeaderCell>
          <HeaderCell
            align="center"
            sx={{
              border: "1px solid white",
              borderRight: "3px solid white",
            }}
          >
            <b>P</b>
          </HeaderCell>
          <HeaderCell align="center" sx={{ border: "1px solid white" }}>
            <b>B</b>
          </HeaderCell>
          <HeaderCell align="center" sx={{ border: "1px solid white" }}>
            <b>P</b>
          </HeaderCell>
          <HeaderCell align="center" sx={{ border: "1px solid white" }}>
            <b>BSO</b>
          </HeaderCell>
          <HeaderCell align="center" sx={{ border: "1px solid white" }}>
            <b>PPTE</b>
          </HeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {bocasCarga
          ? bocasCarga.map((boca) => (
              <React.Fragment key={boca.idbocas_carga}>
                <TableRow>
                  <HeaderCell sx={{ border: "1px solid white" }}>
                    {boca.proveedor} - {boca.nombre_boca}
                  </HeaderCell>
                  {diasSemanaActual.map((dia) => (
                    <React.Fragment key={dia.id}>
                      {/* NUMEROS DE LA TABLA */}
                      <TotalCell
                        align="center"
                        sx={{
                          borderLeft: "4px solid black",
                          borderRight: "1px solid black",
                        }}
                      >
                        {obtenerDatosPorFechaYSucursal(
                          dia.fecha,
                          boca.idbocas_carga,
                          "butano",
                          abastecimiento,
                          4
                        )}
                      </TotalCell>
                      <TotalCell
                        align="center"
                        sx={{
                          borderLeft: "1px solid black",
                          borderRight: "3px solid black",
                        }}
                      >
                        {obtenerDatosPorFechaYSucursal(
                          dia.fecha,
                          boca.idbocas_carga,
                          "propano",
                          abastecimiento,
                          4
                        )}
                      </TotalCell>
                      <TotalCell
                        align="center"
                        sx={{
                          borderLeft: "1px solid black",
                          borderRight: "1px solid black",
                        }}
                      >
                        {obtenerDatosPorFechaYSucursal(
                          dia.fecha,
                          boca.idbocas_carga,
                          "butano",
                          abastecimiento,
                          1
                        )}
                      </TotalCell>
                      <TotalCell
                        align="center"
                        sx={{
                          borderLeft: "1px solid black",
                          borderRight: "1px solid black",
                        }}
                      >
                        {obtenerDatosPorFechaYSucursal(
                          dia.fecha,
                          boca.idbocas_carga,
                          "propano",
                          abastecimiento,
                          1
                        )}
                      </TotalCell>
                      <TotalCell
                        align="center"
                        sx={{
                          borderLeft: "1px solid black",
                          borderRight: "1px solid black",
                        }}
                      >
                        {obtenerDatosPorFechaYSucursal(
                          dia.fecha,
                          boca.idbocas_carga,
                          "bso",
                          abastecimiento,
                          1
                        )}
                      </TotalCell>
                      <TotalCell
                        align="center"
                        sx={{
                          borderLeft: "1px solid black",
                          borderRight: "1px solid black",
                        }}
                      >
                        {obtenerDatosPorFechaYSucursal(
                          dia.fecha,
                          boca.idbocas_carga,
                          "propelente",
                          abastecimiento,
                          1
                        )}
                      </TotalCell>
                    </React.Fragment>
                  ))}
                  {/* NUMEROS TOTAL POR SEMANA*/}
                  <TotalCell
                    align="center"
                    sx={{ borderLeft: "3px solid black" }}
                  >
                    <b>
                      {totalesSem
                        ? obtenerTotalesPorSemana(boca.idbocas_carga, 4)
                            .butano
                        : null}
                    </b>
                  </TotalCell>
                  <TotalCell
                    align="center"
                    sx={{
                      borderLeft: "1px solid black",
                      borderRight: "3px solid black",
                    }}
                  >
                    <b>
                      {totalesSem
                        ? obtenerTotalesPorSemana(boca.idbocas_carga, 4)
                            .propano
                        : null}
                    </b>
                  </TotalCell>
                  <TotalCell align="center">
                    <b>
                      {totalesSem
                        ? obtenerTotalesPorSemana(boca.idbocas_carga, 1)
                            .butano
                        : null}
                    </b>
                  </TotalCell>
                  <TotalCell
                    align="center"
                    sx={{
                      borderLeft: "1px solid black",
                      borderRight: "1px solid black",
                    }}
                  >
                    <b>
                      {totalesSem
                        ? obtenerTotalesPorSemana(boca.idbocas_carga, 1)
                            .propano
                        : null}
                    </b>
                  </TotalCell>
                  <TotalCell align="center">
                    <b>
                      {totalesSem
                        ? obtenerTotalesPorSemana(boca.idbocas_carga, 1).bso
                        : null}
                    </b>
                  </TotalCell>
                  <TotalCell
                    align="center"
                    sx={{
                      borderLeft: "1px solid black",
                      borderRight: "1px solid black",
                    }}
                  >
                    <b>
                      {totalesSem
                        ? obtenerTotalesPorSemana(boca.idbocas_carga, 1)
                            .propelente
                        : null}
                    </b>
                  </TotalCell>
                </TableRow>
              </React.Fragment>
            ))
          : null}
        {totalesPorSucursal && bocasCarga && (
          <TableRow>
            <HeaderCell style={{ fontSize: "1em" }}>
              <b>Total</b>
            </HeaderCell>
            {diasSemanaActual.map((fecha, index) => (
              <React.Fragment key={`${fecha}-${index}`}>
                {/* NUMEROS DE TOTAL POR DIA */}
                <TotalCell
                  align="center"
                  sx={{
                    border: "1px solid black",
                    borderLeft: "4px solid black",
                    fontSize: "1.2em",
                  }}
                >
                  {obtenerSumaPorTipoGasYFecha("butano", fecha.fecha, 4)}
                </TotalCell>
                <TotalCell
                  align="center"
                  sx={{
                    border: "1px solid black",
                    borderRight: "3px solid black",
                    fontSize: "1.2em",
                  }}
                >
                  {obtenerSumaPorTipoGasYFecha("propano", fecha.fecha, 4)}
                </TotalCell>
                <TotalCell
                  align="center"
                  sx={{ border: "1px solid black", fontSize: "1.2em" }}
                >
                  {obtenerSumaPorTipoGasYFecha("butano", fecha.fecha, 1)}
                </TotalCell>
                <TotalCell
                  align="center"
                  sx={{ border: "1px solid black", fontSize: "1.2em" }}
                >
                  {obtenerSumaPorTipoGasYFecha("propano", fecha.fecha, 1)}
                </TotalCell>
                <TotalCell
                  align="center"
                  sx={{ border: "1px solid black", fontSize: "1.2em" }}
                >
                  {obtenerSumaPorTipoGasYFecha("bso", fecha.fecha, 1)}
                </TotalCell>
                <TotalCell
                  align="center"
                  sx={{ border: "1px solid black", fontSize: "1.2em" }}
                >
                  {obtenerSumaPorTipoGasYFecha(
                    "propelente",
                    fecha.fecha,
                    1
                  )}
                </TotalCell>
              </React.Fragment>
            ))}
            {/* NUMEROS DE ABAJO TOTAL FINAL*/}
            <TotalCell
              align="center"
              style={{
                fontSize: "1.2em",
                borderLeft: "3px solid black",
                borderRight: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              <b>{obtenerTotalNaturalGas("butano")}</b>
            </TotalCell>
            <TotalCell
              align="center"
              style={{
                fontSize: "1.2em",
                borderLeft: "1px solid black",
                borderRight: "3px solid black",
                borderBottom: "1px solid black",
              }}
            >
              <b>{obtenerTotalNaturalGas("propano")}</b>
            </TotalCell>
            <TotalCell
              align="center"
              style={{
                fontSize: "1.2em",
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              <b>{obtenerTotalHipergas("butano")}</b>
            </TotalCell>
            <TotalCell
              align="center"
              style={{
                fontSize: "1.2em",
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              <b>{obtenerTotalHipergas("propano")}</b>
            </TotalCell>
            <TotalCell
              align="center"
              style={{
                fontSize: "1.2em",
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              <b>{obtenerTotalHipergas("bso")}</b>
            </TotalCell>
            <TotalCell
              align="center"
              style={{
                fontSize: "1.2em",
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              <b>{obtenerTotalHipergas("propelente")}</b>
            </TotalCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </Paper>
    }
  <br></br>
  <br></br>
</>
  );
}

export default Proveedores_abastecimiento;
