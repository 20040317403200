import React, { useEffect, useState, useMemo } from "react";
import Navbar from "../../../components/Navbar";
import TitleUploaded from "../../../components/TitleUploaded";
import Container from "@mui/material/Container";
import moment from "moment";
import { getData } from "../../../services/library";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { FileDownload } from "@mui/icons-material";
import Alert from "@mui/material/Alert";
import jsPDF from "jspdf";
import "jspdf-autotable"; // Importa el complemento para tablas

function SemanaPedidos() {
  const [data, setData] = useState([{}]);
  const inicio = moment().day(1).format("YYYY-MM-DD");
  const fin = moment().day(5).format("YYYY-MM-DD");

  useEffect(() => {
    getData("/viandas/transparente/" + inicio + "/" + fin, setData);
  }, []);

  const [datosOrganizados, setDatosOrganizados] = useState([{}]);
  const obtenerPedidosPorEmpleado = (pedidos) => {
    const agrupados = {};

    // Agrupar pedidos por empleado
    pedidos.forEach((pedido) => {
      const empleado = pedido.nombre_completo;

      if (!agrupados[empleado]) {
        agrupados[empleado] = {}; // Crear un objeto para el empleado si no existe
      }

      const fecha = pedido.fecha; // Obtener la fecha del pedido
      // Construir la descripción del pedido
      agrupados[empleado][fecha] = `${
        pedido.opcion_descripcion || "Sin pedido"
      }${pedido.guarnicion ? ` (${pedido.guarnicion})` : ""}${
        pedido.observaciones ? ` Observaciones: ${pedido.observaciones}` : ""
      }`;
    });

    // Convertir el objeto a un array de [empleado, pedidosPorFecha] y ordenarlo por empleado
    const empleadosOrdenados = Object.entries(agrupados).sort(
      ([empleadoA], [empleadoB]) => empleadoA.localeCompare(empleadoB) // Ordenar alfabéticamente por nombre de empleado
    );

    // Convertir a un formato que incluya el empleado y sus pedidos por fecha
    return empleadosOrdenados.map(([empleado, pedidosPorFecha]) => ({
      empleado,
      ...pedidosPorFecha, // Expande los pedidos agrupados por fecha
    }));
  };

  useEffect(() => {
    data &&
      data.length > 0 &&
      setDatosOrganizados(obtenerPedidosPorEmpleado(data));
  }, [data]);

  const columnaBase = [{ accessorKey: "empleado", header: "Empleado", size:15 }];

  const [dateColumns, setDateColumns] = useState([]);

  const [dates, setDates] = useState([]);
  useEffect(() => {
    if (data.length > 0) {
      const todasLasFechas = data.map((pedido) => pedido.fecha);
      const fechasUnicas = [...new Set(todasLasFechas)];

      setDates(fechasUnicas.sort((a, b) => new Date(a) - new Date(b)));
    }
  }, [data]);

  useEffect(() => {
    if (dates.length > 0) {
      setDateColumns(
        dates.map((fecha, index) => ({
          id: `fecha-${index}`, // Asegura un `id` único
          accessorKey: fecha,
          header: fecha,
          
        }))
      );
    }
  }, [dates]); // Solo se ejecuta cuando todasLasFechas cambia

  const columns = useMemo(() => {
    return [...columnaBase, ...dateColumns];
  }, [columnaBase, dateColumns]); // Dependencia segura

  const handleExportToPDF = () => {
    // Crea un nuevo documento PDF con tamaño personalizado y sin márgenes adicionales
    const doc = new jsPDF({
      orientation: "l", // Orientación: 'p' para vertical, 'l' para horizontal
      unit: "mm", // Unidad de medida (milímetros)
      format: [210, 297], // Tamaño A4 (210x297 mm)
      compress: true, // Compresión para archivos más pequeños
    });

    const tableData = datosOrganizados.map((row) =>
      columns.map((col) => row[col.accessorKey] || "")
    );

    const headers = columns.map((col) => col.header);

    // Establece la posición inicial para la tabla y define los márgenes
    doc.autoTable({
      head: [headers],
      body: tableData,
      startY: 10, // Posición de inicio en el eje Y
      margin: { top: 10, right: 10, bottom: 10, left: 10 }, // Márgenes personalizados
      styles: { fontSize: 10, cellPadding: 2 }, // Estilos generales
      theme: "grid", // Estilo de tabla
    });

    doc.save("PlanillaSemanal.pdf");
  };

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "primary",
    size: "small",
  };

  const initialState = {
    density: "compact",
    showGlobalFilter: true,
    pagination: {
      pageSize: 15,
      pageIndex: 0,
    },
  };

  const tableHeadCellProps = {
    align: "center",
    sx: {
      fontSize: 14, // Tamaño de fuente reducido
      padding: "8px", // Reducción del padding
      color: "white",
      fontWeight: "bold",
      backgroundColor: "#2471A3",
    },
  };
  const muiTableBodyCellProps = {
    sx: {
      fontSize: 13, // Tamaño de fuente más pequeño para las celdas de contenido
      padding: "6px", // Reducir padding para ahorrar espacio
      whiteSpace: "normal", // Forzar a mantener texto en una sola línea
    },
  };

  return (
    <div>
      <Navbar />
      <Container
        maxWidth="xl"
        sx={{ height: "100%", display: "flex", flexDirection: "column" }}
      >
        <TitleUploaded title={"Semana De Pedidos"} />
        {dates &&
        dateColumns &&
        columnaBase &&
        columns &&
        datosOrganizados.length > 1 ? (
          <MaterialReactTable
            // layoutMode={"grid"}
            muiTableBodyCellProps={muiTableBodyCellProps}
            enableColumnActions={false}
            columns={columns}
            data={datosOrganizados}
            initialState={initialState}
            muiTableHeadCellProps={tableHeadCellProps}
            muiSearchTextFieldProps={muiSearchTextFieldProps}
            renderToolbarInternalActions={({ table }) => (
              <>
                <Tooltip title="Descargar lista">
                  <IconButton color="primary" onClick={handleExportToPDF}>
                    <FileDownload />
                  </IconButton>
                </Tooltip>
              </>
            )}
          />
        ) : (
          <Container maxWidth="xl" disableGutters sx={{ my: 2 }}>
            <Alert severity="info" sx={{ border: 1, borderColor: "#85C1E9" }}>
              No se han cargado pedidos para esta semana aún
            </Alert>
          </Container>
        )}
        <br />
        <br />
        <br />
      </Container>
    </div>
  );
}

export default SemanaPedidos;
