import React, { useState, useEffect, useMemo, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UrlApi } from "../../../services/apirest";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import {
  Button,
  Container,
  Box,
  Grid,
  Typography,
  IconButton,
  ListItemIcon,
  Tooltip,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

// ICONOS
import HistoryIcon from "@mui/icons-material/History";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
//Dialog de almacen
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
// boton por enviar / recibido
import ButtonGroup from "@mui/material/ButtonGroup";
import { lightBlue, blueGrey } from "@mui/material/colors";

//adjuntado
import AttachFileIcon from "@mui/icons-material/AttachFile"; //ADJUNTAR ARCHIVOS
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
//ALERTA
import Badge from "@mui/material/Badge";
// import { cuentamensajes } from "../../services/services";
import { v4 as uuidv4 } from "uuid"; //ADJUNTAR IMAGEN

//CSV
import { ExportToCsv } from "export-to-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import moment from "moment";
import { getData } from "../../../services/library";
import AsignarAVehiculo from "./AsignarAVehiculo";
import BajarStockVehiculo from "./BajarStockVehiculo";
import ArticulosEspecificos from "../seguimiento/ArticulosEspecificos";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Inventario_vehiculo(props) {
  let navigate = useNavigate();
  const location = useLocation();
  const [inventario, setInventario] = useState();
  const [tabla, setTabla] = useState(true);
  const [asignar, setAsignar] = useState(false);
  const [artSuc, setArtSuc] = useState();
  const [bajaStock, setBajaStock] = useState(false);
  const [row, setRow] = useState();
  const [verRegistrado, setVerRegistrado] = useState(false);
  const [posicionesCSV, setPosicionesCSV] = useState();
  // console.log("props", location.state);

  const obtenervehiculos = () => {
    getData(
      "/stock_vehiculos/xvehiculo/" + location.state.idvehiculo,
      setInventario
    );
  };

  const obtenerPosiciones = () => {
    getData(
      "/stock_posiciones/posicionesCSV/" + location.state.idvehiculo,
      setPosicionesCSV
    );
  };

  useEffect(() => {
    obtenervehiculos();
    obtenerPosiciones();
  }, [tabla]);

  const handleClickAsignar = () => {
    setAsignar(true);
  };

  const handleCloseAsignar = () => {
    setAsignar(false);
    obtenervehiculos();
  };

  const handleClickBaja = (row) => {
    setRow(row);
    setBajaStock(true);
  };

  const handleClickRegistrado = (row) => {
    setRow(row);
    setVerRegistrado(true);
  };
  // console.log("RowInventario", row);

  const handleCloseBaja = () => {
    setBajaStock(false);
    obtenervehiculos();
  };
  const handleCloseRegistrados = () => {
    setVerRegistrado(false);
    obtenervehiculos();
  };

  const columns = useMemo(
    () => [
      { header: "Articulo", accessorKey: "nombre", size: 95 },
      { header: "Cantidad", accessorKey: "cantidad", size: 100 },
    ],
    []
  );

  const columnsCSV = useMemo(
    () => [
      { header: "Patente", accessorKey: "patente", size: 130 },
      { header: "Artículo", accessorKey: "nombre_articulo", size: 130 },
      {
        header: "Descripción",
        accessorKey: "descripcion_articulo",
        size: 130,
      },
      {
        header: "Identificador",
        accessorKey: "identificador",
        size: 110,
      },
      {
        header: "Posición",
        accessorKey: "posicion",
        size: 60,
      },
    ],
    []
  );

  // ---------------------------------------------------------------
  //CSV
  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    filename: `Posiciones en vehiculo ${location.state.patente}`,
    headers: columnsCSV.map((c) => `**${c.header}**`),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportData = () => {
    csvExporter.generateCsv(posicionesCSV);
  };
  // --------------------------------------------------------------------------------------------------------------------------------- //

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "primary",
    size: "small",
  };
  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };
  //*************************************************************************************************************************************//
  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem",
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const initialState = {
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
  };
  const volverAtras = () => {
    navigate(-1);
  };

  return (
    <>
      {tabla ? (
        <AppBar
          sx={{ position: "relative" }}
          style={{ backgroundColor: "#0979b0" }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={volverAtras}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Inventario Del Vehiculo {location.state.patente}
            </Typography>
            <Button
              type="primary"
              variant="contained"
              style={{ backgroundColor: blueGrey[400], color: "white" }}
              onClick={handleExportData}
              size="medium"
              endIcon={<FileDownloadIcon />}
            >
              Exportar a CSV
            </Button>
          </Toolbar>
        </AppBar>
      ) : null}
      <Container maxWidth="xl" mt={1}>
        {inventario && tabla ? (
          <Box mt={2} mb={4}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
                enableGrouping // para permitir agrupar x estado x ej
                layoutMode="grid"
                muiTableHeadCellProps={tableHeadCellProps}
                muiTableBodyCellProps={({ row }) => ({
                  align: "left",
                  //   sx: {
                  //     backgroundColor:
                  //       row.getValue("estado_stock") === 0
                  //         ? ""
                  //         : row.getValue("estado_stock") === 1
                  //         ? "rgb(255,105,97)"
                  //         : "rgb(253,253,150)",
                  //     color: "black",
                  //     borderColor: "black",
                  //   },
                })}
                enableColumnFilters={false} //desactiva filtros x  columna
                enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
                enableDensityToggle={false} //
                localization={MRT_Localization_ES} //idioma
                enableColumnDragging={false} //para arrastrar columnas
                initialState={initialState}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                columns={columns}
                data={inventario} //con lo q la completas (esto cambia siempre)
                enableStickyFooter
                enableRowActions
                // enableEditing  //para que se pueda editar desde tabla
                positionActionsColumn="last" // posicion de la columna de acciones al final
                // ACCIONES
                renderToolbarInternalActions={(
                  { table } // boton para nuevo , al lado del buscar
                ) => (
                  <>
                    <MRT_FullScreenToggleButton table={table} />
                  </>
                )}
                renderTopToolbarCustomActions={(row, table) => (
                  <>
                    <Typography variant="poster" fontSize={26}>
                      Inventario
                    </Typography>
                    <Button
                      onClick={() => {
                        handleClickAsignar();
                        // setTabla(false)
                      }}
                      color="primary"
                      //   style={{ backgroundColor: blueGrey[700], color: "white" }}
                      variant="contained"
                      size="small"
                      endIcon={<AddCircleOutlineIcon color="asd" />}
                    >
                      Asignar a Vehiculo
                    </Button>
                    <Button
                      onClick={() => {
                        navigate("/historialvehiculo", {
                          state: location.state,
                        });
                      }}
                      style={{ backgroundColor: blueGrey[700], color: "white" }}
                      variant="contained"
                      size="small"
                      endIcon={<HistoryIcon color="asd" />}
                    >
                      HISTORIAL
                    </Button>
                  </>
                )}
                renderRowActions={({ row, table }) => (
                  <>
                    <div>
                      {/* <Tooltip arrow placement="right" title="Asignar">
                          <IconButton
                        //   onClick={()=>handleClickOpenPdf(row.original)} 
                          color="success"
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </Tooltip> */}
                      <Tooltip arrow placement="left" title="Quitar">
                        <IconButton
                          onClick={() => handleClickBaja(row.original)}
                          color="error"
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        arrow
                        placement="top"
                        title="Artículos registrado"
                      >
                        <IconButton
                          onClick={() => handleClickRegistrado(row.original)}
                          color="primary"
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>
                      {row.original.posicion_vehiculo === 1 ? (
                        <Tooltip
                          arrow
                          placement="right"
                          title="Posiciones En Vehiculo"
                        >
                          <IconButton
                            onClick={() => {
                              navigate("/posiciones_neumaticos", {
                                state: {
                                  rowData: row.original, // Pasa los datos de la fila
                                  locationState: location.state, // Pasa los valores de location.state
                                },
                              });
                            }}
                            color="secondary"
                          >
                            <LocalShippingIcon />
                          </IconButton>
                        </Tooltip>
                      ) : null}
                    </div>
                  </>
                )}
              />
            </ThemeProvider>
          </Box>
        ) : null}
        {asignar && (
          <AsignarAVehiculo
            open={asignar}
            setOpen={setAsignar}
            vehiculo={location.state.idvehiculo}
            patente={location.state.patente}
            artSuc={artSuc}
            fuente={location.state.fuente}
            onClose={handleCloseAsignar}
            // actualizar={obtenerPedidos(245)}
          />
        )}
        {bajaStock && (
          <BajarStockVehiculo
            open={bajaStock}
            setOpen={setBajaStock}
            vehiculo={location.state.idvehiculo}
            patente={location.state.patente}
            row={row}
            // artSuc={artSuc}
            onClose={handleCloseBaja}
            // actualizar={obtenerPedidos(245)}
          />
        )}
        {verRegistrado && (
          <ArticulosEspecificos
            open={verRegistrado}
            setOpen={setVerRegistrado}
            vehiculo={location.state.idvehiculo}
            patente={location.state.patente}
            row={row}
            // artSuc={artSuc}
            onClose={handleCloseRegistrados}
            // actualizar={obtenerPedidos(245)}
          />
        )}
      </Container>
    </>
  );
}

export default Inventario_vehiculo;
