import React, { useEffect, useState, useMemo } from 'react';
import MaterialReactTable from "material-react-table";
import { Grid, Box, MenuItem, ListItemIcon, Divider, Container } from '@mui/material'
import { ThemeProvider, createTheme, useTheme } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CheckIcon from '@mui/icons-material/Check';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import Notify from "../../../Notify";
import VerDatosReq from '../ver_requerimientos/lista_requerimientos/VerDatosReq';
import { getData, putData } from '../../../../services/library';
import moment from 'moment';
import { logEventos } from '../../../../services/services';
import ConfirmDialog from '../../../Dialogs/ConfirmDialog';
import { enviarmails } from '../../../../services/services';
import DialogConTextField from '../../../Dialogs/DialogConTextField';

function AutorizarRequerimientos() {
    const [fecha, setFecha] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [showRequerimientos, setshowRequerimientos] = useState(null);
    const [rechazar, setrechazar] = useState(false);
    const [autorizar, setautorizar] = useState(false);
    const [tabla, settabla] = useState(true);
    const globalTheme = useTheme();
    const [open, setOpen] = useState(false);
    const [observaciones, setObs] = useState("");
    const [datosReq, setDatosreq] = useState({});
    const iconCheck = <CheckIcon sx={{ fontSize: 40 }} />;
    const iconRemove = <RemoveCircleOutlineIcon sx={{ fontSize: 40 }} />;
    const [notificacion, setNotificacion] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
    });

    const obtenerRequerimientos = () => {
        getData("/requerimientos_materiales/pendientes_autorizados/" + localStorage.getItem('user'), setshowRequerimientos);
    };

    useEffect(() => {
        obtenerRequerimientos()
    }, [tabla]);

    const handleRechazar = (rowData) => {
        setDatosreq(rowData)
        setrechazar(true)
    };

    const handleAutorizar = (rowData) => {
        setDatosreq(rowData)
        setautorizar(true)
    };

    async function volverestadoAnterior(e, row) {
        await putData(e,
            "/requerimientos_materiales/" + row.idrequerimiento,
            {
                estado: 49,
                observaciones_baja: null
            },
            setNotificacion
        );
        obtenerRequerimientos();
        logEventos("Cambiar estado de requerimiento", "Requerimientos materiales", "Requerimientos materiales", localStorage.getItem("user"))
    };

    const columns = useMemo(() =>
        [{ header: '#', accessorKey: 'idrequerimiento', size: 90 },
        { header: 'Sucursal', accessorKey: 'nombre_sucursal', size: 120 },
        { header: 'Movil', accessorKey: 'patente', size: 100 },
        { header: 'Fecha Entrega', accessorFn: (rm) => moment(rm.fecha_entrega).format("DD-MM-YYYY"), type: "date", size: 120, },
        // { header: 'Fecha Entrega', accessorKey: 'fecha_entrega', type: "date", size: 120 },
        { header: 'Solicita', accessorKey: 'usuario_registra' },
        { header: 'Estado', accessorKey: 'valor_estado' }
        ], []);

    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: globalTheme.palette.mode,
                    primary: globalTheme.palette.primary, //primary color for the table
                    info: {
                        main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            globalTheme.palette.mode === "light"
                                ? "rgb(234, 239, 241)"
                                : "#000", //pure black table in dark mode for fun
                    },
                },
                typography: {
                    button: {
                        textTransform: "none", //customize typography styles for all buttons in table by default
                        fontSize: "1rem",
                    },
                },
                components: {
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                fontSize: "0.8rem", //larger tooltip font size                                
                            },
                        },
                    },
                },

            }), [globalTheme])

    const muiSearchTextFieldProps = {
        placeholder: "Buscar..",
        sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
        variant: "outlined",
        color: "success",
        size: "small",
    };

    const initialState = {
        density: "compact",
        showGlobalFilter: true,
        pagination: { pageIndex: 0, pageSize: 15 }
    };

    const tableHeadCellProps = {
        align: "center",
        sx: {
            fontSize: 16,
            color: "white",
            fontWeight: "normal",
            backgroundColor: "#2471A3",
            borderColor: "black",
        },
    };

    const rowBg = {
        49: "#ffffff",
        50: "#abebc6",
        58: "#85c1e9",
        68: "#fef5e7",
        87: "#ec7063",
        88: "#3bad97",
        93: "#ec7063",
    };

    const toggleDrawer = (row, newOpen) => () => {
        setOpen(true);
        setDatosreq(row)
    };

    async function autorizarRequerimiento(e) {
        await putData(e,
            '/requerimientos_materiales/' + datosReq.idrequerimiento,
            {
                estado: 50,
                fecha_autorizado: fecha,
                observaciones_baja: null
            },
            setNotificacion
        );
        obtenerRequerimientos()
        datosReq && enviarmails(datosReq.email_registrante, "Requerimiento aprobado", `<p>El requerimiento ${datosReq.idrequerimiento} fue aprobado.</p>`)
        logEventos("Autorizar requerimientos", "Requerimientos materiales", "Requerimientos materiales", localStorage.getItem("user"))
    };

    async function rechazarRequerimiento(e) {
        await putData(e,
            '/requerimientos_materiales/' + datosReq.idrequerimiento,
            {
                estado: 87,
                observaciones_baja: observaciones
            },
            setNotificacion
        );
        enviarmails(datosReq.email_registrante, "Requerimiento rechazado", `<p>El requerimiento ${datosReq.idrequerimiento} fue rechazado. Por favor revise.</p>`)
        logEventos("Rechazar requerimientos", "Requerimientos materiales", "Requerimientos materiales", localStorage.getItem("user"))
    };

    return (
        <>
            <Container maxWidth="xl" mt={1} mb={2}>
                {tabla && showRequerimientos ? (
                    <Box mt={2} boxShadow={3} >
                        <ThemeProvider theme={tableTheme}>
                            <MaterialReactTable
                                columns={columns}
                                data={showRequerimientos}
                                enableBottomToolbar={false}
                                enableColumnResizing
                                enableFacetedValues
                                enableRowVirtualization
                                enableColumnVirtualization
                                enableRowActions
                                initialState={initialState}
                                positionActionsColumn="last"
                                muiTableHeadCellProps={tableHeadCellProps}
                                muiTableBodyCellProps={({ row }) => ({
                                    align: "left",
                                    sx: {
                                        backgroundColor:
                                            rowBg[row.original.estado] ?? "#fff",
                                        color: "black",
                                        borderColor: "black",
                                    },
                                })}
                                enableColumnActions={false}
                                enableDensityToggle={false}
                                muiSearchTextFieldProps={muiSearchTextFieldProps}
                                layoutMode="grid"
                                localization={MRT_Localization_ES}
                                enableColumnDragging={false}
                                renderRowActionMenuItems={({ closeMenu, row }) => [
                                    <MenuItem
                                        key={0}
                                        disabled={row.original.estado === 50 && row.original.estado !== 49}
                                        onClick={() => {
                                            handleAutorizar(row.original)
                                            closeMenu();
                                        }}
                                        sx={{ m: 0 }}>
                                        <ListItemIcon>
                                            <CheckIcon color="success" />
                                        </ListItemIcon>
                                        Autorizar requerimiento
                                    </MenuItem>,
                                    <Divider />,
                                    <MenuItem
                                        key={1}
                                        disabled={row.original.estado !== 50 && row.original.estado !== 49}
                                        onClick={() => {
                                            handleRechazar(row.original)
                                            closeMenu();
                                        }}
                                        sx={{ m: 0 }}>
                                        <ListItemIcon>
                                            <RemoveCircleOutlineIcon color="error" />
                                        </ListItemIcon>
                                        Rechazar requerimiento
                                    </MenuItem>,
                                    <Divider />,
                                    <MenuItem
                                        key={2}
                                        disabled={row.original.estado === 49}
                                        onClick={(e) => {
                                            volverestadoAnterior(e, row.original)
                                            closeMenu();
                                        }}
                                        sx={{ m: 0 }}>
                                        <ListItemIcon>
                                            <AutorenewIcon color="primary" />
                                        </ListItemIcon>
                                        Marcar como pendiente
                                    </MenuItem>
                                ]}
                                enableColumnFilters={false}
                                enableHiding={false}
                                enableExpandAll={false}
                                enableToolbarInternalActions={true}
                                positionExpandColumn='first'
                                enableMultiRowSelection={false}
                                muiToolbarAlertBannerProps={false}
                                muiSelectCheckboxProps={false}
                                muiTableBodyRowProps={({ row }) => ({
                                    onClick: () => {
                                        row.getToggleSelectedHandler()
                                        toggleDrawer(row.original)()
                                    },
                                    sx: {
                                        cursor: 'pointer'
                                    }
                                })}
                            />
                        </ThemeProvider>
                    </Box>
                )
                    : null}
                <Grid key={datosReq.idrequerimiento}>
                    {open ?
                        <VerDatosReq
                            datosreq={datosReq}
                            setDatosreq={setDatosreq}
                            anchor={setOpen}
                            tabla={settabla}
                            actualizar={obtenerRequerimientos}
                        /> : null}
                </Grid>
            </Container>

            {rechazar ?
                <DialogConTextField
                    title={"Desaprobar Requerimiento"}
                    open={rechazar}
                    setOpen={setrechazar}
                    notify={notificacion}
                    setNotify={setNotificacion}
                    btnText={"Desaprobar"}
                    guardar={rechazarRequerimiento}
                    color={"#f53649"}
                    subtitle={`¿Está seguro que desea desaprobar el requerimiento N° ${datosReq.idrequerimiento}`}
                    value={observaciones}
                    setValue={setObs}
                    icon={iconRemove}
                /> : null}


            {autorizar ? (
                <ConfirmDialog
                    title={"Aprobar requerimientos"}
                    subtitle={"¿Desea aprobar el requerimiento?"}
                    open={autorizar}
                    setOpen={setautorizar}
                    notify={notificacion}
                    setNotify={setNotificacion}
                    btnText={"Aprobar"}
                    guardar={autorizarRequerimiento}
                    color={"#2ECC71"}
                    icon={iconCheck}
                />
            ) : null}

            {notificacion && notificacion.open ? (
                <Notify
                    mensaje={notificacion.mensaje}
                    open={notificacion.open}
                    color={notificacion.color}
                    severity={notificacion.severity}
                />
            ) : null}

        </>
    )
}

//========================================================================
/*----- Component --------------------------------------------------------/
// Developer     : Candela Grosso   
// Creation Date : 30/11/2023
// Version       : 1.0  
// Description   :  Con este componente el usuario es capaz de :
    - Visualizar todos los requerimientos cargados con la posibilidad de:
        * autorizar, rechazar y en caso de que lo requiera volver a estado de "pendiente autorización"
        * ver los datos de los requerimientos haciendo click en la fila correspondiente
//------Changes --------------------------------------------------------------------- -------------//
// Change Date   :
// Change by     :
// Change description: 
//========================================================================*/


export default AutorizarRequerimientos