import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Button,
    TextField,
    Typography,
    Box,
    Snackbar,
    Alert,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Grid,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; // Icono para el botón de cerrar
import { UrlApi } from '../../services/apirest';

const FileUploadCumples = ({ open, handleClose }) => {
    const [image, setImage] = useState(null);
    const [imageUrl, setImageUrl] = useState('');
    const [textInputs, setTextInputs] = useState(['', '', '']);
    const [isEditing, setIsEditing] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [fileSelected, setFileSelected] = useState(false); // Estado para controlar la selección de archivos

    useEffect(() => {
        if (open) {
            const fetchExistingData = async () => {
                try {
                    const response = await axios.get(UrlApi + '/uploadcumples/get-existing-data');
                    console.log('Datos recibidos:', response.data); // Log para depuración
                    if (response.data.image) {
                        const blob = b64toBlob(response.data.image, 'image/jpeg');
                        const imageUrlBlob = URL.createObjectURL(blob);
                        console.log('URL de la imagen generada:', imageUrlBlob); // Log de depuración
                        setImageUrl(imageUrlBlob);
                        setIsEditing(true);
                    }
                    if (response.data.texts) {
                        setTextInputs(response.data.texts);
                    }
                } catch (error) {
                    console.error('Error al obtener los datos existentes:', error);
                    setSnackbarMessage('Error al obtener los datos existentes.');
                    setSnackbarSeverity('error');
                    setSnackbarOpen(true);
                }
            };

            fetchExistingData();
        } else {
            setImage(null);
            setImageUrl('');
            setTextInputs(['', '', '']);
            setIsEditing(false);
            setFileSelected(false); // Resetear la selección de archivos al cerrar el modal
        }
    }, [open]);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            console.log('Imagen seleccionada:', file); // Log para depuración
            const fileUrl = URL.createObjectURL(file);
            setImageUrl(fileUrl);
            setImage(file);
            setFileSelected(true); // Indicar que un archivo ha sido seleccionado
        } else {
            setFileSelected(false); // No hay archivo seleccionado
        }
    };

    const handleTextChange = (index, value) => {
        const newTextInputs = [...textInputs];
        newTextInputs[index] = value;
        setTextInputs(newTextInputs);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        if (image) {
            formData.append('image', image);
        }

        textInputs.forEach((text, index) => {
            formData.append(`text${index + 1}`, text);
        });

        try {
            const response = await axios.post(UrlApi + '/uploadcumples/upload', formData);
            console.log('Respuesta al subir:', response.data); // Log para depuración
            setSnackbarMessage('Archivos subidos exitosamente.');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            setIsEditing(true);
            setFileSelected(false); // Resetear la selección de archivos después de subir

            if (image) {
                const fileUrl = URL.createObjectURL(image);
                console.log('URL de la imagen después de subir:', fileUrl); // Log de depuración
                setImageUrl(fileUrl);
                setImage(null);
            }
        } catch (error) {
            console.error('Error al subir:', error);
            setSnackbarMessage('Error al subir los archivos.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handleDeleteImage = async () => {
        try {
            await axios.delete(UrlApi + '/uploadcumples/delete-image');
            setImage(null);
            setImageUrl('');
            setIsEditing(false);
            setSnackbarMessage('Imagen eliminada exitosamente.');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Error al eliminar la imagen:', error);
            setSnackbarMessage('Error al eliminar la imagen.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    // Convertir base64 a Blob
    const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth='sm'>
            <DialogTitle>
                Subir o Editar Imagen y Textos
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ p: 2 }}>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                {imageUrl ? (
                                    <img
                                        src={imageUrl}
                                        alt="Vista previa"
                                        style={{ maxWidth: '200px', maxHeight: '150px' }}
                                    />
                                ) : (
                                    <div style={{
                                        width: '200px',
                                        height: '150px',
                                        backgroundColor: 'green',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                        <Typography variant="body2" style={{ color: 'yellow' }}>no hay imagen</Typography>
                                    </div>
                                )}
                            </Grid>
                            <Grid item>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    {imageUrl && (
                                        <>
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                onClick={() => window.open(imageUrl, '_blank')}
                                                style={{ marginBottom: '8px' }}
                                            >
                                                Ver Imagen
                                            </Button>
                                            <Button variant='contained' color='secondary' onClick={handleDeleteImage}>
                                                Eliminar Imagen
                                            </Button>
                                        </>
                                    )}
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        type='submit'
                                        style={{ marginTop: '8px' }}
                                        disabled={!fileSelected && !imageUrl} // Botón deshabilitado si no hay imagen o archivo seleccionado
                                    >
                                        {isEditing ? 'Modificar' : 'Subir'}
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                        <input
                            type='file'
                            accept='image/jpeg, image/png'
                            onChange={handleImageChange}
                            style={{ display: 'block', margin: '16px 0' }}
                        />
                        <Grid container spacing={1}>
                            {textInputs.map((text, index) => (
                                <Grid item xs={12} key={index}>
                                    <TextField
                                        label={`Texto ${index + 1}`}
                                        value={text}
                                        onChange={e => handleTextChange(index, e.target.value)}
                                        fullWidth
                                        margin='dense'
                                        size="small"
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </form>
                </Box>
            </DialogContent>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Snackbar en la esquina superior derecha
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Dialog>
    );
};

export default FileUploadCumples;
