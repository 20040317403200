// El presente componente guarda la configuración de parámetros para la factibilidad.
// Algunos cambios, como el costo de transporte por kilómetro, requieren aprobación del departamento de compras. De momento, ÚNICAMENTE el costo_transporte_km
// La configuración tiene un estado que indica si hay cambios pendientes de aprobación o si está todo en orden. Cuando se modifique la configuración, y se hayan cambiado valores que necesiten aprobación, la configuración se guardará con estado 221. Si no hay cambios que requieran autorización, se guardará con estado 220.
//El costo_transporte_km no puede volver a modificarse hasta que se haya aprobado.
// En la base de datos, el costo de transporte se guarda en dos columnas: una para el valor actual y otra para el valor propuesto pendiente de aprobación.
// Una vez que el nuevo valor es aprobado, se actualiza en la base de datos y se marca como aprobado.
//Cuando el usuario abre el correo siempre le va a pedir que se loguee en el sistema, ya que el enlace está sin "#".
//Si el enlace está con "#", cuando se ingresa, se le borra la ruta que está a continuación y como consecuencia en usuario no será redirigido hacia "/factibilidades/aprobarconfig", ya que el sistema no recibirá parámetros.
// Preguntar a Edu por qué pasa lo anterior.
import React, { useState, useEffect } from "react";
import { UrlApi } from "../../../services/apirest";
import Title from "../../Title";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Notify from "../../Notify";
import Alert from "@mui/material/Alert";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { enviarmails } from "../../../services/services";
import {UrlApp} from "../../../services/apirest"

function FactConfig(props) {
  const [factConfig_original, setFactConfig_original] = useState(null);
  const [factConfig, setFactConfig] = useState([{}]);
  const [disGuardar, setDisGuardar] = useState(false);
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
  const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif

  const getConfig = () => {
    fetch(UrlApi + "/factconfig")
      .then((data) => data.json())
      .then((data) => {
        setFactConfig(data[0]); // Inicio los datos de factConfig como me los traigo de la bd
        setFactConfig_original(data[0]); //Guardo la configuración original para luego compararla y ver si hay modificaciones.
      });
  };

  useEffect(() => {
    getConfig();
  }, []);

  const handleClose = () => {
    props.setShowConfig(false);
    props.setShowTable(true);
  };

  const almacenar = (e) => {
    setFactConfig({ ...factConfig, [e.target.name]: e.target.value });
  };

  async function guardarConfig(e) {
    const autorizar =
      factConfig_original.costo_transporte_km !==
        parseInt(factConfig.costo_transporte_km) ||
      factConfig_original.id_estado === 220;
    e.preventDefault();
    let reqBody = {
      costo_producto_tn: factConfig.costo_producto_tn,
      precio_venta_x_kg: factConfig.precio_venta_x_kg,
      costo_tk05: factConfig && factConfig.costo_tk05,
      costo_tk1: factConfig && factConfig.costo_tk1,
      costo_tk2: factConfig && factConfig.costo_tk2,
      costo_tk4: factConfig && factConfig.costo_tk4,
      costo_tk7: factConfig && factConfig.costo_tk7,
      mail_administracion: factConfig && factConfig.mail_administracion,
      mail_autorizante: factConfig && factConfig.mail_autorizante,
      mail_compras: factConfig && factConfig.mail_compras,
      actualizo: localStorage.getItem("user"),
      costos_operativos: factConfig && factConfig.costos_operativos,
      kg_revision: factConfig && factConfig.kg_revision,
    };
    if (
      factConfig_original.costo_transporte_km !==
        factConfig.costo_transporte_km &&
      (!factConfig_original.id_estado || factConfig_original.id_estado !== 220)
    ) {
      reqBody = {
        ...reqBody,
        costo_transporte_km_pending: factConfig.costo_transporte_km,
        id_estado: 220,
      };
    }
    setDisGuardar(true);
    e.preventDefault();
    const response = await fetch(UrlApi + "/factconfig", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(reqBody),
    });
    if (response.ok === false) {
      alert("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      try {
        const json = await response.json();
        if (json.message) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje(
            json.message === "OK" ? "Guardado" : "Guardado. Actualizar RTA API"
          );
          autorizar &&
            enviarmails(
              `${factConfig_original.mail_compras}`,
              "Configuración Pendiente de Revisión",
              `<p style="color: black;">El costo de transporte por kilómetro ha sido modificado.</p>
              <p style="color: black;"> Por favor,<a href="${UrlApp}factibilidades/aprobarconfig?ruta=${"/factibilidades/aprobarconfig"}">HAGA CLICK AQUÍ</a> para verificar las modificaciones.
              </p>
              `
            );
        } else if (json.sqlMessage) {
          // json && setError(json);
          alert("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
        } else {
          alert("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje("Guardado.");
          autorizar &&
            enviarmails(
              `${factConfig_original.mail_compras}`,
              "Configuración Pendiente de Revisión",
              `<p style="color: black;">El costo de transporte por kilómetro ha sido modificado.</p>
              <p style="color: black;"> Por favor,<a href="${UrlApp}factibilidades/aprobarconfig?ruta=${"/factibilidades/aprobarconfig"}">HAGA CLICK AQUÍ</a> para verificar las modificaciones.
              </p>
              `
            );
          // enviarmails(
          //   `${factConfig_original.mail_compras}`,
          //   "Configuración Pendiente de Revisión",
          //   `<p style="color: black;">El costo de transporte por kilómetro ha sido modificado: $ ${
          //     factConfig.costo_transporte_km
          //   }. Por favor,</p>
          //   <a href="http://192.168.0.13:3000/#/factibilidades/aprobarconfig">haga click AQUÍ</a> para verificar las modificaciones.`
          // );
        } else {
          alert("ERROR DESCONOCIDO.");
        }
      }
    }
  }

  return (
    <div>
      <Container maxWidth="lg">
        <Title
          titulo={"Configuración de Factibilidad"}
          handleClose={handleClose}
        />
        {notificacion ? (
          <Notify
            mensaje={mensaje}
            open={openSnack}
            color={"#D4EFDF"}
            severity={"success"}
            handleClose={handleClose}
          />
        ) : null}

        {factConfig.id_estado === 220 && (
          <Box mt={1} backgroundColor="#FEF5E7">
            <Alert
              icon={<AccessTimeIcon fontSize="inherit" />}
              variant="outlined"
              severity="warning"
            >
              Sus modificaciones deben ser aprobadas por el departamento de
              compras.
            </Alert>
          </Box>
        )}

        <form onSubmit={guardarConfig}>
          <Box
            my={1}
            backgroundColor="#F4F6F6"
            p={2}
            border={0.5}
            borderColor={"#ABB2B9"}
            borderRadius={4}
          >
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item xs={2.3}>
                <TextField
                  type="number"
                  fullWidth
                  name="costo_producto_tn"
                  label="Costo Producto Por Tonelada $"
                  value={factConfig.costo_producto_tn}
                  onChange={almacenar}
                  style={{ backgroundColor: "#fff" }}
                  focused
                  color="primary"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={2.3}>
                <TextField
                  required
                  type="number"
                  fullWidth
                  name="costo_transporte_km"
                  label="Costo Transporte Por KM $"
                  value={
                    factConfig_original && factConfig_original.id_estado === 220
                      ? factConfig.costo_transporte_km_pending
                      : factConfig.costo_transporte_km
                  }
                  onChange={almacenar}
                  style={{ backgroundColor: "#fff" }}
                  focused
                  color={
                    factConfig_original && factConfig_original.id_estado === 220
                      ? "warning"
                      : "primary"
                  }
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{
                    readOnly:
                      factConfig_original &&
                      factConfig_original.id_estado === 220,
                  }}
                />
              </Grid>
              <Grid item xs={2.3}>
                <TextField
                  type="number"
                  fullWidth
                  name="precio_venta_x_kg"
                  label="Precio Venta Por KG"
                  value={factConfig.precio_venta_x_kg}
                  onChange={almacenar}
                  style={{ backgroundColor: "#fff" }}
                  focused
                  color="primary"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={2.3}>
                <TextField
                  type="number"
                  fullWidth
                  name="costos_operativos"
                  label="Costos Operativos (U$S)"
                  value={factConfig.costos_operativos}
                  onChange={almacenar}
                  style={{ backgroundColor: "#fff" }}
                  focused
                  color="primary"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={2.3}>
                <TextField
                  type="number"
                  fullWidth
                  name="kg_revision"
                  label="KG revisión"
                  value={factConfig.kg_revision}
                  onChange={almacenar}
                  style={{ backgroundColor: "#fff" }}
                  focused
                  color="primary"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={2.3}>
                <TextField
                  type="number"
                  fullWidth
                  name="costo_tk05"
                  label="Costo Tanque 0.5 m3 (U$S)"
                  value={factConfig.costo_tk05}
                  onChange={almacenar}
                  style={{ backgroundColor: "#fff" }}
                  focused
                  color="primary"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Grid>

              <Grid item xs={2.3}>
                <TextField
                  type="number"
                  fullWidth
                  name="costo_tk1"
                  label="Costo Tanque 1 m3 (U$S)"
                  value={factConfig.costo_tk1}
                  onChange={almacenar}
                  style={{ backgroundColor: "#fff" }}
                  focused
                  color="primary"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Grid>

              <Grid item xs={2.3}>
                <TextField
                  type="number"
                  fullWidth
                  name="costo_tk2"
                  label="Costo Tanque 2 m3 (U$S)"
                  value={factConfig.costo_tk2}
                  onChange={almacenar}
                  style={{ backgroundColor: "#fff" }}
                  focused
                  color="primary"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={2.3}>
                <TextField
                  type="number"
                  fullWidth
                  name="costo_tk4"
                  label="Costo Tanque 4 m3 (U$S)"
                  value={factConfig.costo_tk4}
                  onChange={almacenar}
                  style={{ backgroundColor: "#fff" }}
                  focused
                  color="primary"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={2.3}>
                <TextField
                  type="number"
                  fullWidth
                  name="costo_tk7"
                  label="Costo Tanque 7 m3 (U$S)"
                  value={factConfig.costo_tk7}
                  onChange={almacenar}
                  style={{ backgroundColor: "#fff" }}
                  focused
                  color="primary"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  name="mail_administracion"
                  label="Mail Administración"
                  value={factConfig.mail_administracion}
                  onChange={almacenar}
                  style={{ backgroundColor: "#fff" }}
                  focused
                  color="primary"
                  type="mail"
                  helperText="Para añadir más de un correo, sepárelos con ; (punto y coma)"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  name="mail_autorizante"
                  label="Mail Autorizante"
                  value={factConfig.mail_autorizante}
                  onChange={almacenar}
                  style={{ backgroundColor: "#fff" }}
                  focused
                  color="primary"
                  type="mail"
                  helperText="Para añadir más de un correo, sepárelos con ; (punto y coma)"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  name="mail_compras"
                  label="Mail Compras"
                  value={factConfig.mail_compras}
                  onChange={almacenar}
                  style={{ backgroundColor: "#fff" }}
                  focused
                  color="primary"
                  type="mail"
                  helperText="Para añadir más de un correo, sepárelos con ; (punto y coma)"
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="error"
                  disabled={disGuardar}
                  onClick={handleClose}
                >
                  volver
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="success"
                  type="submit"
                  disabled={disGuardar}
                >
                  guardar
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Container>
    </div>
  );
}

export default FactConfig;
