import React, { useState, useEffect, useMemo, useRef } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Grid,
  Stack,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  IconButton,
  Autocomplete,
  ListItemIcon,
  Alert,
} from "@mui/material";
import moment from "moment"; // PARA LA FECHA
import { logEventos } from "../../services/services";

function Dialog_confirmacion_recibo(props) {
  const [abrirDialogTrConf, setAbrirDialogTrConf] = useState(
    props.abrirDialogTrConf
  );
  const [transferRow, setTransferRow] = useState(props.transferRow);
  const [cantidadRec, setCantidadRec] = useState(
    props.transferRow.cantidad_enviada
  );
  const [almacenes, setAlmacenes] = useState(props.almacenes);
  const [almacenSeleccionado, setAlmacenSeleccionado] = useState({});
  //   const [inventarioTrSumaCan, setInventarioTrSumaCan] = useState([]);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [openSnackEditar, setOpenSnackEditar] = React.useState(false);
  const [disabledGuardarPen, setDisabledGuardarPen] = useState(false);
  const [colorTextField, setcolorTextField] = useState();

  const [inventarioTr, setInventarioTr] = useState([]);

  //funcion para obtener sucursales
  const obtenerInventarioTr = () => {
    fetch(
      UrlApi +
        "/stock_inventarios/xalmart/" +
        almacenSeleccionado.id_almacen +
        "/" +
        transferRow.id_articulo
    )
      .then((data) => data.json())
      .then((data) => setInventarioTr(data));
  };

  useEffect(() => {
    obtenerInventarioTr();
  }, [almacenSeleccionado.id_almacen, transferRow.id_articulo]);

  // sumar cantidad en recibido por tranferencia

  const [cantidadInventarioTrSuma, setCantidadInventarioTrSuma] = useState(0);

  const handleCantidadInventarioTrSuma = (e) => {
    setCantidadInventarioTrSuma(e.target.value);
  };
  // CALCULO DEL TOTAL FINAL
  useEffect(() => {
    setCantidadInventarioTrSuma(
      inventarioTr[0]
        ? parseInt(inventarioTr[0].cantidad) + parseInt(cantidadRec)
        : null
    );
  }, [inventarioTr, cantidadRec]);

  const guardarConfirmacionRecibo = async (e) => {
    logEventos(
      "Guardar confirmación de recibo",
      "Stock Sucursales",
      "Se guardo confirmación de recibo",
      localStorage.getItem("user")
    );
    e.preventDefault();
    await fetch(UrlApi + "/stock_transacciones/" + transferRow.id_transaccion, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        almacen_destino: almacenSeleccionado.id_almacen,
        cantidad_recibida: cantidadRec,
        cantidad_pendiente: cantidadPendiente,
        fecha_recibido: moment(transferRow.fecha_recibido).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        en_transito: 0,
        detalle_confirmacion: transferRow.detalle_confirmacion,
        usuario: localStorage.getItem("user"),
        estado_confirmacion: 0,
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("La Confirmación se guardó exitosamente !");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar la confirmación");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });

    // ------ put o post para confirmacion de recibo
    e.preventDefault();
    if (inventarioTr.length === 0) {
      await fetch(UrlApi + "/stock_inventarios", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id_almacen: almacenSeleccionado.id_almacen,
          id_articulo: transferRow.id_articulo,
          cantidad: cantidadRec,
          usuario: localStorage.getItem("user"),
        }),
      })
        .then((res) => {
          if (!res.err) {
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setMensaje("El artículo se guardó exitosamente !");
          } else {
            setOpenSnack(false);
            setColorMensaje("#F1948A");
            setMensaje("Error al guardar el artículo");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
      //   handleClickOpenCrear();
    } else {
      await fetch(
        UrlApi +
          "/stock_inventarios/" +
          almacenSeleccionado.id_almacen +
          "/" +
          transferRow.id_articulo,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            cantidad: cantidadInventarioTrSuma,
            usuario: localStorage.getItem("user"),
          }),
        }
      )
        .then((res) => {
          if (!res.err) {
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setMensaje("El artículo se guardó exitosamente !");
          } else {
            setOpenSnack(false);
            setColorMensaje("#F1948A");
            setMensaje("Error al guardar el artículo");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
    e.preventDefault();
    if (transferRow.id_requerimiento !== null) {
      await fetch(
        UrlApi + "/requerimientos_materiales/" + transferRow.id_requerimiento,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            estado: 88,
          }),
        }
      )
        .then((res) => {
          if (!res.err) {
            // setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setMensaje("La Confirmación se guardó exitosamente !");
          } else {
            // setOpenSnack(false);
            setColorMensaje("#F1948A");
            setMensaje("Error al guardar la confirmación");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
    // PARA LIMPIAR LOS DATOS
    handleClose();
  };

  const handleClose = () => {
    setAbrirDialogTrConf(!abrirDialogTrConf);
    if (props.onClose) {
      props.onClose();
    }
  };

  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const [cantidadPendiente, setCantidadPendiente] = useState();

  const handleCantidadPendiente = (e) => {
    setCantidadPendiente(e.target.value);
  };

  // //CALCULO DEL TOTAL FINAL
  useEffect(() => {
    setCantidadPendiente(transferRow.cantidad_enviada - cantidadRec);
  }, [transferRow.cantidad_enviada, cantidadRec]);

  const almacenarTr = (e) => {
    setTransferRow({
      ...transferRow,
      [e.target.name]: e.target.value,
    });
  };
  const almacenarCantidad = (e) => {
    setCantidadRec(e.target.value);
  };

  useEffect(() => {
    if (cantidadPendiente < 0) {
      setDisabledGuardarPen(true);
      setcolorTextField("error");
    } else {
      setDisabledGuardarPen(false);
      setcolorTextField("primary");
    }
  }, [cantidadPendiente, cantidadRec]);
  //console.log("TRANSFER ROW", transferRow, cantidadRec, cantidadInventarioTrSuma);
  return (
    <>
      <Dialog
        open={abrirDialogTrConf}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" alignSelf="center">
          {"Confirmación de recibo de transferencia"}
        </DialogTitle>
        <DialogContent>
          <Container maxWidth="x1">
            <form onSubmit={guardarConfirmacionRecibo} autoComplete="off">
              <Grid container mt={2} mb={1} spacing={1}>
                <Grid item xs={6} mb={2}>
                  <TextField
                    label="Fecha de Envío"
                    name="fecha_transaccion"
                    fullWidth
                    size="small"
                    readOnly
                    value={transferRow.fecha_transaccion}
                  />
                </Grid>
                <Grid item xs={6} py={1} mb={1}>
                  <TextField
                    label="Fecha de Recepción"
                    name="fecha_recibido"
                    size="small"
                    type="date"
                    focused
                    required
                    fullWidth
                    value={moment(transferRow.fecha_recibido).format(
                      "YYYY-MM-DD"
                    )}
                    onChange={almacenarTr}
                  />
                </Grid>
                <Grid item xs={6} mb={2}>
                  <TextField
                    label="Sucursal de Origen"
                    name="sucursal_origen"
                    fullWidth
                    size="small"
                    readOnly
                    value={transferRow.sucursal_origen}
                  />
                </Grid>
                <Grid item xs={6} py={1} mb={1}>
                  <Autocomplete
                    disabled={!almacenes}
                    size="small"
                    focused
                    required
                    disableClearable
                    id="stock_almacenes"
                    noOptionsText={"Almacén INEXISTENTE"}
                    options={almacenes}
                    autoHighlight
                    getOptionLabel={(almacenes) =>
                      almacenes.id_almacen + "-" + almacenes.nombre
                    }
                    onChange={(event, value) => setAlmacenSeleccionado(value)}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        key={option.id}
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.id_almacen} - {option.nombre}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        focused
                        size="small"
                        required
                        placeholder="Tipee para buscar..."
                        {...params}
                        label="Almacén de Destino"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} mb={2}>
                  <TextField
                    label="Artículo"
                    name="articulo"
                    fullWidth
                    size="small"
                    readOnly
                    value={transferRow.nombre_articulo}
                  />
                </Grid>
                <Grid item xs={12} mb={2}>
                  <TextField
                    label="Detalle de Transferencia"
                    name="detalle_transaccion"
                    fullWidth
                    size="small"
                    readOnly
                    value={transferRow.detalle_transaccion}
                  />
                </Grid>
                <Grid item xs={6} mb={2}>
                  <TextField
                    label="Cantidad Enviada"
                    name="cantidad"
                    fullWidth
                    size="small"
                    readOnly
                    value={transferRow.cantidad_enviada}
                  />
                </Grid>
                <Grid item xs={6} mb={2}>
                  <TextField
                    label="Cantidad Recibida"
                    name="cantidad_recibida"
                    fullWidth
                    focused
                    required
                    size="small"
                    onKeyPress={(event) => {
                      if (!/[0-9.]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    color={colorTextField}
                    value={cantidadRec}
                    onChange={almacenarCantidad}
                  />
                </Grid>
                <Grid item xs={3.5} py={1} mb={1}>
                  <TextField
                    label="Cantidad Pendiente"
                    size="small"
                    name="cantidad_pendiente"
                    value={cantidadPendiente}
                    onChange={handleCantidadPendiente}
                    inputProps={{
                      readOnly: true,
                      "aria-label": "Without label",
                    }}
                  />
                </Grid>
                {cantidadPendiente === 0 ? (
                  <Grid item xs={8.5} mb={2}>
                    <TextField
                      label="observaciones"
                      name="detalle_confirmacion"
                      size="small"
                      placeholder="..."
                      inputProps={{
                        maxLength: 100,
                      }}
                      focused
                      fullWidth
                      value={transferRow.detalle_confirmacion}
                      onChange={almacenarTr}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={8.5} mb={2}>
                    <TextField
                      label="observaciones"
                      name="detalle_confirmacion"
                      size="small"
                      placeholder="..."
                      inputProps={{
                        maxLength: 100,
                      }}
                      focused
                      required
                      fullWidth
                      value={transferRow.detalle_confirmacion}
                      onChange={almacenarTr}
                    />
                  </Grid>
                )}
              </Grid>

              <Stack direction="row">
                <Grid container direction={{ xs: "column", sm: "row" }}>
                  <Grid item xs={7.85} mr={6}>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleClose}
                    >
                      Volver
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      fullWidth
                      disabled={disabledGuardarPen}
                      variant="contained"
                      color="success"
                      type="submit"
                    >
                      Confirmar
                    </Button>
                  </Grid>
                </Grid>
              </Stack>
            </form>
          </Container>
        </DialogContent>
      </Dialog>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}

export default Dialog_confirmacion_recibo;
