//----- Component --------------------------------------------------------/
// Developer     : Guillermo Sedran
// Creation Date : 12/01/2024
// Version       : 1
// Description   : Pagina principal donde se visualizan todos los graficos con sus datos, pudiendo expandirlos para ver cada grafico con mas detalle
//
//------Changes ----------------------------------------------------------------------------------//
// Change Date   :
// Change by     :
// Change description:
//
// Change Date   :
// Change by     :
// Change description:
//
//========================================================================/

import Navbar from "../Navbar";
import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Container, Typography, Dialog, DialogContent } from "@mui/material";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";

// ICONOS
import CircularProgress from "@mui/material/CircularProgress";
import SettingsIcon from "@mui/icons-material/Settings";

// GRAFICAS
import Grafica1 from "./graficas/Area";
import Grafica2 from "./graficas/Area2";
import Grafica3 from "./graficas/PieChart";
import Grafica4 from "./graficas/LineChart";
import Grafica5 from "./graficas/BarChartComparativo";
import Grafica6 from "./graficas/PieChart2";

// GRAFICAS EXPANDIDAS
import Grafica1Expandida from "./graficas/AreaExpandida";
import Grafica2Expandida from "./graficas/Area2Expandida";
import Grafica3Expandida from "./graficas/PieChartExpandida";
import Grafica4Expandida from "./graficas/LineChartExpandida";
// import Grafica5Expandida from "./graficas/BarChartComparativoExpandida";
// import Grafica6Expandida from "./graficas/PieChart2Expandida";

function PanelDeControl() {
  const [datosGrafico2, setDatosGrafico2] = useState([]);
  const [tituloCardSeleccionada, setTituloCardSeleccionada] = useState("");

  // ***************************************************************************

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedComponentIndex, setSelectedComponentIndex] = useState(null);

  const handleExpandirClick = (index) => {
    setOpenDialog(true);
    setSelectedComponentIndex(index);
    setTituloCardSeleccionada(componentsConfig[index].title);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedComponentIndex(null);
  };

  // **********************************************

  const [drawerOpen, setDrawerOpen] = useState(false);

  // Función para abrir el Drawer
  const handleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  console.log("DRAWER OPEN PADRE: ", drawerOpen);

  // **********************************************

  // LOS COMPONENTES QUE VOY A LLAMAR Y SUS PROPS

  // EN PANEL DE CONTROL (MENU PRINCIPAL)
  const componentsConfig = [
    {
      title: "Sucursales",
      component: Grafica1,
      options: {
        // datosGrafico: {datosGrafico1},
        prop1: "Valor para Grafica1",
        prop2: "Otro valor",
      },
    },
    {
      title: "Deudores",
      component: Grafica2,
      options: {
        // PROPS QUE LE ENVIO AL GRAFICO
        datosGrafico: datosGrafico2,
        prop1: "Valor para Grafica2 CON PROPS",
        prop2: "Otro valor pasado con props",
        // PROPIEDAD BOOLEANA QUE CREO PARA INDICAR SI SE EXTENDIO LA INFORMACION DEL GRAFICO O NO
        // isExpanded: isExpanded,
      },
    },
    {
      title: "Vehículos",
      component: Grafica3,
      options: {
        // datosGrafico: {datosGrafico3},
        prop1: "Valor para Grafica3",
        prop2: "Otro valor",
      },
    },
    {
      title: "Título 4",
      component: Grafica4,
      options: {
        // datosGrafico: {datosGrafico4},
        prop1: "Valor para Grafica4",
        prop2: "Otro valor",
      },
    },
    {
      title: "Título 5",
      component: Grafica5,
      options: {
        // datosGrafico: {datosGrafico5},
        prop1: "Valor para Grafica5",
        prop2: "Otro valor",
      },
    },
    {
      title: "Título 6",
      component: Grafica6,
      options: {
        // datosGrafico: {datosGrafico6},
        prop1: "Valor para Grafica6",
        prop2: "Otro valor",
      },
    },
  ];

  const componentsConfig2 = [
    {
      // component: Grafica1,
      component: Grafica1Expandida,
      options: {
        // datosGrafico: {datosGrafico1},
        prop1: "Valor para Grafica1",
        prop2: "Otro valor",
        drawerOpen: drawerOpen, // Pasar el estado drawerOpen al componente hijo
        handleDrawer: handleDrawer, // Pasar la función handleOpenDrawer al componente hijo
      },
    },
    {
      component: Grafica2Expandida,
      options: {
        // PROPS QUE LE ENVIO AL GRAFICO
        // datosGrafico: datosGrafico2,
        prop1: "Valor para Grafica2 CON PROPS",
        prop2: "Otro valor pasado con props",
        // drawerOpen: drawerOpen, // Pasa la función al componente hijo
        // PROPIEDAD BOOLEANA QUE CREO PARA INDICAR SI SE EXTENDIO LA INFORMACION DEL GRAFICO O NO
        drawerOpen: drawerOpen, // Pasar el estado drawerOpen al componente hijo
        handleDrawer: handleDrawer, // Pasar la función handleOpenDrawer al componente hijo
        // handleDrawerClose: handleDrawerClose
        // handleDrawerClose: handleCloseDrawer
      },
    },
    {
      component: Grafica3Expandida,
      options: {
        // datosGrafico: {datosGrafico3},
        prop1: "Valor para Grafica3",
        prop2: "Otro valor",
        // PROPIEDAD BOOLEANA QUE CREO PARA INDICAR SI SE EXTENDIO LA INFORMACION DEL GRAFICO O NO
        drawerOpen: drawerOpen, // Pasar el estado drawerOpen al componente hijo
        handleDrawer: handleDrawer, // Pasar la función handleOpenDrawer al componente hijo
      },
    },
    // {
    //   component: Grafica4,
    //   options: {
    //     // datosGrafico: {datosGrafico4},
    //     prop1: "Valor para Grafica4",
    //     prop2: "Otro valor",
    //   },
    // },
    {
      component: Grafica4Expandida,
      options: {
        // datosGrafico: {datosGrafico4},
        prop1: "Valor para Grafica4",
        prop2: "Otro valor",
      },
    },
    {
      component: Grafica5,
      options: {
        // datosGrafico: {datosGrafico5},
        prop1: "Valor para Grafica5",
        prop2: "Otro valor",
      },
    },
    {
      component: Grafica6,
      options: {
        // datosGrafico: {datosGrafico6},
        prop1: "Valor para Grafica6",
        prop2: "Otro valor",
      },
    },
  ];

  // **********************************

  return (
    <>
      <Navbar />
      <div
        style={{
          backgroundColor: "#F2F2F2",
          paddingTop: "5px",
        }}
      >
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          style={{
            backgroundColor: "#000080",
            color: "#ffffff",
            padding: "15px",
          }}
        >
          <b>PANEL DE CONTROL DE ENVASADO</b>
        </Typography>

        <Grid container mb={4} justifyContent="center" alignItems="center">
          {/* {componentsConfig.map((config, index) => (
            <Grid
              item
              key={index}
              xs={12}
              sm={6}
              md={4}
              sx={{ padding: "8px" }}
            >
              <Card
                variant="outlined"
                style={{ border: "1px solid black" }}
                sx={{ borderRadius: "10px 10px 10px 10px" }}
              >
                <Card>
                  <Typography
                    variant="h6"
                    component="h2"
                    align="center"
                    sx={{
                      bgcolor: "#1976D2",
                      color: "#FFFFFF",
                    }}
                  >
                    {config.title}
                  </Typography>
                </Card>
                <CardContent
                  sx={{ height: "38vh" }}
                  style={{ position: "relative" }}
                >
                  <config.component key={index} {...config.options} />
                </CardContent>
                <CardActions
                  sx={{ justifyContent: "center", bgcolor: "#F7F7F7" }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => handleExpandirClick(index, false)}
                  >
                    Expandir
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))} */}

          {/* {componentsConfig.map((config, index) => (
            <Grid
              item
              key={index}
              xs={12}
              sm={6}
              md={4}
              sx={{ padding: "8px" }}
            >
              <Card
                variant="outlined"
                style={{
                  border: "1px solid black",
                  maxHeight: "300px",
                  overflow: "auto",
                }}
              >
                <CardContent style={{ maxHeight: "250px", overflow: "auto" }}>
                  <Typography
                    variant="h6"
                    component="h2"
                    align="center"
                    sx={{ bgcolor: "#1976D2", color: "#FFFFFF" }}
                  >
                    {config.title}
                  </Typography>
                  <config.component key={index} {...config.options} />
                </CardContent>
                <CardActions
                  sx={{ justifyContent: "center", bgcolor: "#F7F7F7" }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => handleExpandirClick(index, false)}
                  >
                    Expandir
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))} */}

          {componentsConfig.map((config, index) => (
            <Grid
              item
              key={index}
              xs={12}
              sm={6}
              md={4}
              sx={{ padding: "8px" }}
            >
              <Card variant="outlined" style={{ border: "1px solid black" }}>
                <CardContent style={{ height: "250px", maxHeight: "250px", overflow: "hidden" }}>
                  <Typography
                    variant="h6"
                    component="h2"
                    align="center"
                    sx={{ bgcolor: "#1976D2", color: "#FFFFFF" }}
                  >
                    {config.title}
                  </Typography>
                  <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                    <config.component key={index} {...config.options} />
                  </div>
                </CardContent>
                <CardActions
                  sx={{ justifyContent: "center", bgcolor: "#F7F7F7" }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => handleExpandirClick(index, false)}
                  >
                    Expandir
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>

        <React.Fragment>
          <Container>
            <Dialog
              open={openDialog}
              onClose={handleDialogClose}
              fullScreen
              maxWidth="xl"
            >
              <AppBar sx={{ position: "relative" }}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleDialogClose}
                    aria-label="close"
                    sx={{ marginRight: 2 }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography
                    sx={{ ml: 2, flex: 1 }}
                    variant="h6"
                    component="div"
                  >
                    {/* Detalle del Componente */}
                    {tituloCardSeleccionada}
                  </Typography>
                  <Button
                    variant="outlined"
                    color="inherit"
                    onClick={() => handleDrawer(true)}
                    startIcon={<SettingsIcon />}
                  >
                    Opciones
                  </Button>
                </Toolbar>
              </AppBar>
              <DialogContent sx={{ backgroundColor: "#F7F7F7" }}>
                {selectedComponentIndex !== null && (
                  <div>
                    {selectedComponentIndex !== null &&
                      React.createElement(
                        componentsConfig2[selectedComponentIndex].component,
                        {
                          ...componentsConfig2[selectedComponentIndex].options,
                        }
                      )}
                  </div>
                )}
              </DialogContent>
            </Dialog>
          </Container>
        </React.Fragment>
      </div>
    </>
  );
}

export default PanelDeControl;
