import React, { useEffect, useState, useRef } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Button,
  Container,
  DialogContent,
  DialogTitle,
  Box,
  Stack,
  Grid,
  TextField,
  Autocomplete,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// Notification
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Romaneo_M_envases_destruido(props) {
    const [datosRomaneoEnvases, setDatosRomaneoEnvases] = useState(
        props.datosRomaneoEnvases
      );    
      const [mat_fabricante, setMat_fabricante] = useState(props.mat_fabricante);
      const [matriculaSeleccionada, setMatriculaSeleccionada] = useState(
        props.matriculaSeleccionada
      );
    
        // ------------------ PARA EL FOCUS ------------------
    
        const select1Ref = useRef(null); // MOTIVO DE BAJA
        const textfield1Ref = useRef(null); // NUMERO DE ENVASE
        const autocomplete1Ref = useRef(null); // MATRICULA DE FABRICANTE
        const buttonRef = useRef(null); // BOTON DE GUARDAR
    
    
        const [focusedElement, setFocusedElement] = useState(null);
    
        const focusElement = (elementRef) => {
          if (elementRef.current) {
            elementRef.current.focus();
          }
        };
      
        const handleFocusChange = (elementName) => {
          setFocusedElement(elementName);
        };
    
        useEffect(() => {
          const handleKeyDown = (e) => {
            if (e.key === "Enter" && !e.repeat) {
              e.preventDefault(); // Prevent default tab behavior
              switch (focusedElement) {
                case "select1": // Elijo cuando ocurre
                  focusElement(textfield1Ref); // El nuevo elemento al que voy a enfocar
                  setFocusedElement("textfield1"); // el nombre del elemento
                  break;
                  case "textfield1":
                    focusElement(autocomplete1Ref);
                    setFocusedElement("autocomplete1");
                    break;
                case "autocomplete1": // Llamo al elemento que anteriormente era el nuevo focus y repito
                    // Enfocar el botón
                    if (buttonRef.current) {
                      buttonRef.current.focus();
                    }
                    setFocusedElement("buttonRef");
                  break;
                default:
                  break;
              }
            }
          };
      
          window.addEventListener("keydown", handleKeyDown);
      
          return () => {
            window.removeEventListener("keydown", handleKeyDown);
          };
        }, [focusedElement]);
    
      // ------------------------------------------------------
    
      // NOTIFICACION
      const [openSnack, setOpenSnack] = useState(props.openSnack);
      const [colormensaje, setColorMensaje] = useState(props.colormensaje);
      const [mensaje, setMensaje] = useState(props.mensaje);
      //
      const [disBtn, setDisBtn] = useState(props.disBtn);
    
      // ------------------------------------------------------------
      // DIALOG PARA DESTRUIR
      const [openDestruir, setOpenDestruir] = React.useState(false);
    
      const [descripcion_motivo, setDescripcion_motivo] = useState();
    
      const obtenerDescripcionMotivo = () => {
        fetch(UrlApi + "/romaneo_motivos_baja")
          .then((data) => data.json())
          .then((data) => setDescripcion_motivo(data));
      };

      //funcion que espera los cambios de la funcion de obtener a los romaneo leyendas
      useEffect(() => {
        obtenerDescripcionMotivo();
      }, []);
    
      //*************************************************************************************************************************************/
    
      //Snackbar Notificacion
      const handleCloseSnack = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setOpenSnack(false);
        setLimpiarDestruir(false);
        setLimpiarMatricula(false);
        setDisBtn(false);
      };
    
      //*************************************************************************************************************************************/
    
      const style = {
        backgroundColor: "#ffffff",
        width: "100%",
      };
    
      //*************************************************************************************************************************************/
    
      // LIMPIAR
      const [limpiarDestruir, setLimpiarDestruir] = useState(props.limpiarDestruir);
    
      useEffect(() => {
        datosRomaneoEnvases.nro_envase = "";
      }, [limpiarDestruir]);
    
      // MATRICULA
      const [limpiarMatricula, setLimpiarMatricula] = useState(
        props.limpiarMatricula
      );
    
      //*************************************************************************************************************************************/
    
      // USANDO ROW TOMO LOS DATOS DE ESA FILA
      const handleClickOpenDestruir = (row) => {
        setOpenDestruir(true);
        // AL ABRIR EL DIALOG LOS TEXTFIELDS SE VACIAN
        datosRomaneoEnvases.nro_envase = null;
        matriculaSeleccionada.id_mat_fabricante = null;
        datosRomaneoEnvases.id_mot_baja = null;
        datosRomaneoEnvases.id_estado = null;
      };
    
      //Funcion para guardar al crear
      const guardarDestruir = async (e) => {
        console.log("Estoy por guardar");
        setDisBtn(true);
        e.preventDefault();
        await fetch(UrlApi + "/romaneo_envases", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            nro_envase: datosRomaneoEnvases.nro_envase,
            id_mat_fabricante: matriculaSeleccionada.id_fabricante,
            id_mot_baja: datosRomaneoEnvases.id_mot_baja,
            id_estado: 155,
          }),
        })
          .then((res) => {
            if (!res.err) {
              setOpenSnack(true);
              setColorMensaje("#D4EFDF");
              setMensaje("El envase se guardó exitosamente !");
              console.log("Guardado");
            } else {
              setOpenSnack(false);
              setColorMensaje("#F1948A");
              setMensaje("Error al guardar el envase");
              console.log("Error");
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
        // PARA LIMPIAR LOS DATOS
        handleClickOpenDestruir();
        setLimpiarDestruir(true);
        setLimpiarMatricula(true);
      };
      console.log("POST DESTRUIDO", datosRomaneoEnvases);
    
      //*************************************************************************************************************************************/
      
      const almacenar = (e) => {
        setDatosRomaneoEnvases({
          ...datosRomaneoEnvases,
          [e.target.name]: e.target.value,
        });
      };
    
      //*************************************************************************************************************************************/
    
      useEffect(() => {
        focusElement(select1Ref);
      }, []);
      
      useEffect(() => {
        focusElement(select1Ref);
      }, [!limpiarDestruir]);
    
        // AL ESCRIBIR EL NUMERO DE ENVASE, FOCUS A LA MATRICULA
        useEffect(() => {
          if (datosRomaneoEnvases.nro_envase.length === 9) {
            focusElement(autocomplete1Ref);
          }
        }, [datosRomaneoEnvases.nro_envase]);
    
      return (
        <>
          {" "}
          <DialogTitle alignSelf="center">
            <b>{"Agregar Envase (Destruido)"}</b>
          </DialogTitle>
          <DialogContent>
            <Container maxWidth="xl">
              <form onSubmit={guardarDestruir}>
                <Grid container mt={2} mb={1} spacing={1}>
                  <Grid item xs={12} mb={2}>
                    <FormControl style={style} focused>
                      <InputLabel>Motivo de Baja</InputLabel>
                      <Select
                      autoFocus
                            inputRef={select1Ref}
                            onFocus={() => handleFocusChange("select1")}
                        label="Motivo de Baja"
                        required
                        size="small"
                        name="id_mot_baja"
                        value={datosRomaneoEnvases.id_mot_baja}
                        onChange={almacenar}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {descripcion_motivo
                          ? descripcion_motivo.map((elemento) => (
                              <MenuItem
                                key={elemento.id_motivo}
                                value={elemento.id_motivo}
                              >
                                {elemento.descripcion_motivo}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                  </Grid>
    
                  <Grid item xs={12} mb={2}>
                    <TextField
                          inputRef={textfield1Ref}
                          onFocus={() => handleFocusChange("textfield1")}
                      label="Número de Envase"
                      name="nro_envase"
                      size="small"
                      placeholder="123456789"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      inputProps={{
                        maxLength: 9,
                      }}
                      required
                      fullWidth
                      value={datosRomaneoEnvases.nro_envase}
                      onChange={almacenar}
                    />
                  </Grid>
    
                  <Grid item xs={12} mb={2}>
                    {limpiarMatricula ? (
                      <>
                        <TextField
                          value={(datosRomaneoEnvases.id_mat_fabricante = null)}
                          disabled
                          size="small"
                          label="Matrícula Fabricante"
                          fullWidth
                        />
                      </>
                    ) : (
                      <>
                        <Autocomplete
                          disabled={!mat_fabricante}
                          size="small"
                          required
                          id="id_fabricante"
                          noOptionsText={"Matrícula Fabricante INEXISTENTE"}
                          options={mat_fabricante}
                          autoHighlight
                          getOptionLabel={(mat_fabricante) =>
                            mat_fabricante.id_fabricante +
                            "-" +
                            mat_fabricante.descripcion_fabricante
                          }
                          onChange={(event, value) =>
                            setMatriculaSeleccionada(value)
                          }
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              key={option.id}
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              {option.id_fabricante} -{" "}
                              {option.descripcion_fabricante}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                            inputRef={autocomplete1Ref}
                            onFocus={() => handleFocusChange("autocomplete1")}
                              size="small"
                              placeholder="Tipee para buscar..."
                              {...params}
                              label="Matrícula Fabricante"
                              inputProps={{
                                ...params.inputProps,
                              }}
                            />
                          )}
                        />
                      </>
                    )}
                  </Grid>
                </Grid>
    
                <Stack direction="row">
                  <Grid container direction={{ xs: "column", sm: "row" }}>
                    <Grid item xs={9}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={props.handleCloseDestruir}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                         ref={buttonRef}
                         onFocus={() => handleFocusChange("buttonRef")}
                        fullWidth
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={disBtn}
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              </form>
            </Container>
          </DialogContent>
          {/* MENSAJE AL GUARDAR */}
          <Stack spacing={2} sx={{ width: "100%" }}>
            <Snackbar
              open={openSnack}
              autoHideDuration={1500}
              onClose={handleCloseSnack}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert
                onClose={handleCloseSnack}
                severity="success"
                sx={{ width: "100%" }}
                style={{ backgroundColor: colormensaje, color: "black" }}
              >
                {mensaje}
              </Alert>
            </Snackbar>
          </Stack>
        </>
      );
    }
    
    export default Romaneo_M_envases_destruido;
    