//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio
// Creation Date : 07/11/2024
// Version : 1
// Description : pantalla fusionada de grupos e items
//------Changes --------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useEffect, useState, useMemo } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Container,
  Typography,
  Tooltip,
  IconButton,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  DialogTitle,
} from "@mui/material";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import BlockIcon from "@mui/icons-material/Block";
import EditIcon from "@mui/icons-material/Edit";
import Notify from "../Notify";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { logEventos } from "../../services/services";
import EditarItem from "./Editaritem";
import AddItem from "./AddItem";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";

function TablaItemsGrupos(props) {
  const [items, setItems] = useState(); //tabla
  const [datosItems, setDatosItems] = useState({});
  const globalTheme = useTheme();
  const [tablaITEM, setTablaITEM] = useState(true);
  const [altaITEM, setAltaITEM] = useState(false); //Estado q mostrará o no la pantalla de alta
  const [tabla, setTabla] = useState(true);
  const [row, setRow] = useState(props.row);
  const [confirmar, setConfirmar] = useState(false);
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
  const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
  const [obt, setObt] = useState(0);

  const [dialogEditar, setDialogEditar] = useState(false);
  const [update, setUpdate] = useState(false);
  const [dialogAdd, setDialogAdd] = useState(false);

  // console.log("row Viene", row.original);
  const obtenerItems = () => {
    fetch(UrlApi + "/seg_hig_grupos/itemxgrupo/" + row.original.id_grupo)
      .then((data) => data.json())
      .then((data) => setItems(data));
  };

  useEffect(() => {
    obtenerItems();
  }, []);

  // para actualizar
  useEffect(() => {
    if (update) {
      obtenerItems();
      setUpdate(false);
    }
  }, [update]);

  console.log("items", items);

  console.log("ITEMS X GRUPO", items);

  const abrirModalConfirmar = (datosDeLaLinea) => {
    setConfirmar(true);
    setDatosItems(datosDeLaLinea);
  };

  const handleVolver = () => {
    setConfirmar(false);
    setTablaITEM(true);
  };

  // columnas tabla
  const columns = useMemo(
    () => [
      {
        header: "Nombre",
        accessorKey: "nombre_item",
        size: 200,
      },
      {
        header: "Definición",
        accessorKey: "definicion_item",
        size: 200,
      },
      {
        header: "Observación",
        accessorKey: "palabra_obligatorio",
        size: 70,
      },
      {
        header: "Estado Obs",
        accessorKey: "obs_oblig",
        size: 70,
        enableHiding: true,
      },
      {
        header: "Vencimiento",
        accessorKey: "fecha_obligatorio",
        size: 70,
      },
    ],
    []
  );

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)" //background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "success",
    size: "small",
  };
  const initialState = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
    columnVisibility: {
      obs_oblig: false,
    },
  };
  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };

  const marcarInactivo = async (e) => {
    logEventos("Marca inactivo un item", "Configuración de checklist", "Se inactivo el item", localStorage.getItem("user"))
    e.preventDefault();
    const response = await fetch(
      UrlApi + "/seg_hig_items/" + datosItems.id_item,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(
          datosItems.estado_item === 0 ? { estado_item: 1 } : { estado_item: 0 }
        ),
      }
    );
    if (response.ok === false) {
      const error = response && response.json();
      setError(error);
      alert("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      try {
        const json = await response.json();
        if (json.message) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje(
            json.message === "OK" ? "Guardado" : "Guardado. Actualizar RTA Api."
          );
          obtenerItems();
        } else if (json.sqlMessage) {
          json && setError(json);
          alert("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
        } else {
          alert("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje("Guardado");
          obtenerItems();
        } else {
          alert("Error: " + error);
        }
      }
    }
    handleVolver();
    obtenerItems();
  };

  const handleCloseEditar = () => {
    setDialogEditar(false);
    setUpdate(true);
  };

  const handleCloseAdd = () => {
    setDialogAdd(false);
    setUpdate(true);
  };

  const handleClickSetearDatosEditar = (row) => {
    setDialogEditar(true);
    setDatosItems(row);
  };

  const handleClickNuevoItem = () => {
    setAltaITEM(true);
    setDialogAdd(true);
  };

  // console.log("DATOS ITEM", datosItems);
  // console.log("DATOS ITEM ESTADO", datosItems.estado_item);

  return (
    <div>
      <br></br>
      <br></br>
      <Container maxWidth="lg" mt={1} mb={2}>
        {items && tablaITEM ? (
          <Box mt={2} boxShadow={3}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableColumnResizing
                columns={columns}
                data={items}
                initialState={initialState}
                positionActionsColumn="last"
                enableExpandAll={false}
                enableToolbarInternalActions={true}
                muiTableHeadCellProps={tableHeadCellProps}
                muiTableBodyCellProps={({ row }) => ({
                  align: "left",
                  sx: {
                    backgroundColor:
                      row.original.estado_item === 0 ? "#efc3c3" : "#b3e6b3",
                    color: "black",
                    borderColor: "black",
                  },
                })}
                enableColumnActions={false}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                enableDensityToggle={false}
                renderTopToolbarCustomActions={() => (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddBoxTwoToneIcon />}
                      onClick={handleClickNuevoItem}
                    >
                      Agregar Item
                    </Button>
                    <Typography variant="poster" fontSize={20}>
                      Items del grupo
                    </Typography>
                  </>
                )}
                layoutMode="grid"
                localization={MRT_Localization_ES}
                enableColumnDragging={false} //No permite que se arrastren las columnas.
                enableEditing
                // ACCIONES
                renderToolbarInternalActions={(
                  { table } // boton para nuevo , al lado del buscar
                ) => (
                  <>
                    <MRT_FullScreenToggleButton table={table} />
                  </>
                )}
                renderRowActions={(
                  { row } //las acciones del costado editar y ver
                ) => (
                  <div>
                    <Tooltip arrow placement="left" title="Editar item">
                      <IconButton
                        onClick={() =>
                          handleClickSetearDatosEditar(row.original)
                        }
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    {row.original.estado_item === 0 ? (
                      <Tooltip title="Marcar como activo">
                        <IconButton
                          color="success"
                          onClick={() => abrirModalConfirmar(row.original)}
                        >
                          <CheckCircleOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Marcar como inactivo">
                        <IconButton
                          onClick={() => abrirModalConfirmar(row.original)}
                          color="error"
                        >
                          <BlockIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                )}
              />
            </ThemeProvider>
            <br />
            <br />
          </Box>
        ) : null}

        {confirmar ? (
          <div>
            <Dialog open={confirmar} onClose={handleVolver}>
              <form onSubmit={marcarInactivo}>
                <Box backgroundColor={"#1F618D"}>
                  <DialogTitle>
                    {datosItems.estado_item === 0 ? (
                      <Typography variant="h6" align="center" color="#ffffff">
                        Activar {datosItems.nombre_item}
                      </Typography>
                    ) : (
                      <Typography variant="h6" align="center" color="#ffffff">
                        Desactivar {datosItems.nombre_item}
                      </Typography>
                    )}
                  </DialogTitle>
                </Box>
                <DialogContent>
                  <DialogContentText textAlign={"center"}>
                    {datosItems.estado_item === 0 ? (
                      <> ¿Desea activar el item ? </>
                    ) : (
                      <> ¿Desea desactivar el item ? </>
                    )}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItemns="center"
                  >
                    <Button
                      variant="contained"
                      onClick={handleVolver}
                      style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Confirmar
                    </Button>
                  </Grid>
                </DialogActions>
              </form>
            </Dialog>
          </div>
        ) : null}
        {notificacion ? (
          <Notify
            mensaje={mensaje}
            open={openSnack}
            color={"#D4EFDF"}
            severity={"success"}
            handleClose={handleVolver}
          />
        ) : null}
        {dialogEditar ? (
          <EditarItem
            dialogEditar={dialogEditar}
            datos={datosItems}
            actualizar={setObt}
            onClose={() => handleCloseEditar(false)}
          />
        ) : null}
        {dialogAdd ? (
          <AddItem
            dialogAdd={dialogAdd}
            row={row.original}
            datos={datosItems}
            actualizar={setObt}
            onClose={() => handleCloseAdd(false)}
          />
        ) : null}
      </Container>
      {altaITEM ? (
        <AddItem alta={setAltaITEM} tabla={setTabla}></AddItem>
      ) : null}
    </div>
  );
}

export default TablaItemsGrupos;
