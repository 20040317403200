//----- Component --------------------------------------------------------/

// Developer : Nicolas Pascucci

// Creation Date : 18/06/2024

// Version : 1

// Description : paso cero de la evaluacion del comercial donde se ingresan las ventas mensuales BUTANO
//

//------Changes --------------------------------------------------------------------- -------------//

// Change Date :

// Change by :

// Change description:

//

// Change Date :

// Change by :

// Change description:

//

//========================================================================/
import React, { useState, useEffect } from "react";
import Navbar from "../../Navbar";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import AssignmentIcon from "@mui/icons-material/Assignment";
import {
  Button,
  Box,
  Grid,
  Typography,
  TextField,
  Stack,
} from "@mui/material";
import { UrlApi } from "../../../services/apirest";
import moment from "moment";
import { logEventos } from "../../../services/services";

function CeroStep(props) {
    const [fecha, setFecha] = useState(props.fecha)
    const [objetivos, setObjetivos] = useState(null)
    const [datosVendido, setDatosVendido] = useState({
      distribuidoresButano: props.distribuidoresButano,
      comerciosButano: props.comerciosButano,
      usuariosButano: props.usuariosButano
    })
    const [subitemDistribuidores, setSubitemDistribuidores] = useState(null)
    const [subitemUsuarios, setSubitemUsuarios] = useState(null)
    const [subitemComercios, setSubitemComercios] = useState(null)

    const obtenerSubitemsDistribuidores = () => {
        fetch(UrlApi + "/subitems_comerciales/xiditem/"+6)
          .then((data) => data.json())
          .then((data) => setSubitemDistribuidores(data));
    };

    useEffect(() => {
      obtenerSubitemsDistribuidores()
    }, [])

    const obtenerSubitemsUsuarios = () => {
      fetch(UrlApi + "/subitems_comerciales/xiditem/"+7)
        .then((data) => data.json())
        .then((data) => setSubitemUsuarios(data));
    };
    
    useEffect(() => {
      obtenerSubitemsUsuarios()
    }, [])

    const obtenerSubitemsComercios = () => {
      fetch(UrlApi + "/subitems_comerciales/xiditem/"+8)
        .then((data) => data.json())
        .then((data) => setSubitemComercios(data));
    };
    
    useEffect(() => {
      obtenerSubitemsComercios()
    }, [])

    const obtenerObjetivo = () => {
      fetch(UrlApi + "/objetivos_comerciales/mesyuserysuc/"+localStorage.getItem("user")+"/"+fecha+"/"+props.sucursalSeleccionada.id)
        .then((data) => data.json())
        .then((data) => setObjetivos(data));
    };
    
    useEffect(() => {
      obtenerObjetivo()
    }, [])
    // console.log("objetivos",objetivos, subitemDistribuidores,subitemComercios,subitemUsuarios);

    const [subitemResultadoDistribuidores, setSubitemResultadoDistribuidores] = useState(null);
    const handleSubitemResultadoDistribuidor = (resultado) => {
      setSubitemResultadoDistribuidores(resultado);
    };

    const [subitemResultadoComercios, setSubitemResultadoComercios] = useState(null);
    const handleSubitemResultadoComercios = (resultado) => {
      setSubitemResultadoComercios(resultado);
    };

    const [subitemResultadoUsuarios, setSubitemResultadoUsuarios] = useState(null);
    const handleSubitemResultadoUsuarios = (resultado) => {
      setSubitemResultadoUsuarios(resultado);
    };

    const calcularPorcentajes = () =>{
        // calculo distribuidores
        const objetivoDistribuidores = objetivos? objetivos[0].distribuidores_butano:null
        const vendidoDistribuidores = datosVendido.distribuidoresButano
        const porcentaje = ((vendidoDistribuidores / objetivoDistribuidores) * 100).toFixed(2)
        // console.log(vendidoDistribuidores,"/", objetivoDistribuidores, "* 100 =",  porcentaje);
        function determinarSubitem(porcentaje, subitems) {
            for (let i = 0; i < subitems.length; i++) {
              const subitem = subitems[i];
              if (subitem.hasta === null) {
                if (porcentaje >= subitem.desde) {
                  return subitem;
                }
              } else {
                if (porcentaje >= subitem.desde && porcentaje <= subitem.hasta) {
                  return subitem;
                }
              }
            }
            return null; // Si no cae en ningún subitem
          }
          if (subitemDistribuidores && porcentaje) {
            const subitemResultadoDistribuidores = determinarSubitem(parseFloat(porcentaje), subitemDistribuidores);
            handleSubitemResultadoDistribuidor(subitemResultadoDistribuidores)
          }
          
          // calculo comercios
          const objetivoComercios = objetivos? objetivos[0].comercios_butano:null
          const vendidoComercios = datosVendido.comerciosButano
          const porcentajeComercios = ((vendidoComercios / objetivoComercios) * 100).toFixed(2)
          // console.log(vendidoComercios,"/", objetivoComercios, "* 100 =",  porcentajeComercios);
            if (subitemComercios && porcentajeComercios) {
              const subitemResultadoComercios = determinarSubitem(parseFloat(porcentajeComercios), subitemComercios);
              handleSubitemResultadoComercios(subitemResultadoComercios)
            }

          // calculo usuarios
          const objetivoUsuarios = objetivos? objetivos[0].usuarios_butano:null 
          const vendidoUsuarios = datosVendido.usuariosButano
          const porcentajeUsuarios = ((vendidoUsuarios / objetivoUsuarios) * 100).toFixed(2)
          // console.log(vendidoUsuarios,"/", objetivoUsuarios, "* 100 =",  porcentajeUsuarios);
            if (subitemUsuarios && porcentajeUsuarios) {
              const subitemResultadoUsuarios = determinarSubitem(parseFloat(porcentajeUsuarios), subitemUsuarios);
              handleSubitemResultadoUsuarios(subitemResultadoUsuarios)
            }
        }
        // console.log("ES EL SUBITEM de Distribuidores",subitemResultadoDistribuidores);
        // console.log("ES EL SUBITEM de Comercios",subitemResultadoComercios);
        // console.log("ES EL SUBITEM de Usuarios",subitemResultadoUsuarios);

    // const siguiente = () => {
    //     props.handleNext(datosVendido.distribuidoresButano, datosVendido.comerciosButano, datosVendido.usuariosButano);
    // };

    useEffect(() => {
        if (subitemResultadoDistribuidores !== null && subitemResultadoComercios !== null && subitemResultadoUsuarios !== null) {
          props.handleNext(
            datosVendido.distribuidoresButano, 
            datosVendido.comerciosButano,
            datosVendido.usuariosButano,
            subitemResultadoDistribuidores ? subitemResultadoDistribuidores.porcentaje : null,
            subitemResultadoComercios ? subitemResultadoComercios.porcentaje : null,
            subitemResultadoUsuarios ? subitemResultadoUsuarios.porcentaje : null,
          );
        }
      }, [subitemResultadoDistribuidores, subitemResultadoComercios, subitemResultadoUsuarios]);

    const almacenar = (e) => {
      setDatosVendido({
        ...datosVendido,
        [e.target.name]: e.target.value,
      });
    };

    const [deshabilitado, setDeshabilitado] = useState(false)
    useEffect(() => {
      if (datosVendido.distribuidoresButano === null || datosVendido.comerciosButano === null || datosVendido.usuariosButano === null ) {
        setDeshabilitado(true)
      } else{
        setDeshabilitado(false)
      }
    }, [datosVendido])

  return (
    <div> {props.activeStep === 1 && (
        <div style={{ marginTop: 20, alignItems: "center", display: "flex", flexDirection: "column" }}>
          <Grid container spacing={2}>
            {/* {props.configuracionVista && props.configuracionVista[0].objetivo === 1? 
            <Grid item xs={12}>
            </Grid>
              :null} */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Ingrese los kgs. Vendidos de Butano en el Mes de {moment(fecha).format("MMMM")}: 
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h6" gutterBottom>
                Objetivo para Usuarios: {objetivos && objetivos[0]?objetivos[0].usuarios_butano:null}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h6" gutterBottom>
              Objetivo para Comercios: {objetivos && objetivos[0]?objetivos[0].comercios_butano:null}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h6" gutterBottom>
              Objetivo para Distribuidores: {objetivos && objetivos[0]?objetivos[0].distribuidores_butano:null}
              </Typography>
            </Grid>
                <Grid item xs={4} >
                  <TextField
                    label="Usuarios (kg)"
                    name="usuariosButano"
                    size="small"
                    placeholder="..."
                    onKeyPress={(event) => {
                      if (!/[0-9.]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    inputProps={{
                      maxLength: 10,
                    }}
                    focused
                    required
                    fullWidth
                    value={datosVendido.usuariosButano}
                    onChange={almacenar}
                  />
                </Grid>
            <Grid item xs={4}>
              <TextField
                label="Comercios (kg)"
                name="comerciosButano"
                size="small"
                placeholder="..."
                onKeyPress={(event) => {
                  if (!/[0-9.]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                inputProps={{
                  maxLength: 10,
                }}
                focused
                required
                fullWidth
                value={datosVendido.comerciosButano}
                onChange={almacenar}
              />
            </Grid>
            <Grid item xs={4} >
              <TextField
                label="Distribuidores (kg)"
                name="distribuidoresButano"
                size="small"
                placeholder="..."
                onKeyPress={(event) => {
                  if (!/[0-9.]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                inputProps={{
                  maxLength: 10,
                }}
                focused
                required
                fullWidth
                value={datosVendido.distribuidoresButano}
                onChange={almacenar}
              />
            </Grid>
            {/* <Grid item xs={12} justifyContent={"center"} textAlign={"center"}>
              <Typography variant="h5" gutterBottom>
                Total: {vendidoxmes} kgs.
              </Typography>
            </Grid> */}
          <Grid item xs={10}>
            <Button
              variant="contained"
              color="error"
              //  disabled={activeStep === 0}
              onClick={props.handleBack}
              sx={{ mr: 1 }}
            >
              Volver
            </Button>
            </Grid> 
              <Grid item xs={2}>
                <Button onClick={calcularPorcentajes} fullWidth variant="contained" disabled={deshabilitado}>SIGUIENTE</Button>
              </Grid>
          </Grid>
        </div>
      )}
    </div>
  )
}

export default CeroStep