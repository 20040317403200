import { UrlApi } from '../services/apirest'

export const DownloadFiles = (archivo, headers) => {
    fetch(UrlApi + "/uploadfiles/download/" + archivo, {
        method: "GET",
        headers: headers,
        responseType: "blob"
    })
        .then(resp => resp.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            // the filename you want
            a.download = archivo;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            //alert("your file has downloaded!"); // or you know, something with better UX...
        })
        .catch(() => alert("Algo salio mal"));
};
