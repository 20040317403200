import React, { useState, useEffect, useMemo } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Alert,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Snackbar,
  Stack,
  TextField,
  Box,
} from "@mui/material";
import moment from "moment";
//enviar mails
import { enviarmails } from "../../services/services";
import { grey } from "@mui/material/colors";
import Title from "../Title";
import { postData } from "../../services/library";
import { v4 as uuidv4 } from "uuid"; //ADJUNTAR IMAGEN
import { logEventos } from "../../services/services";
import Agregar_tarea from "../tanques_rehab_serv_tarea/Agregar_tarea";

function Dialog_consumo(props) {
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);
  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [openSnackEditar, setOpenSnackEditar] = React.useState(false);
  const [disabledGuardarRe, setDisabledGuardarRe] = useState(false);
  const [colorTextField, setcolorTextField] = useState();
  const [pantallaAgregarTarea, setPantallaAgregarTarea] = useState(false);

  const [datosArt, setDatosArt] = useState(props.datosArt);
  const [dialogConsumo, setDialogConsumo] = useState(props.dialogConsumo);
  const [consumo, setConsumo] = useState({
    fecha_transaccion: date,
    cantidad_enviada: null,
  });
  const [cantidadSalida, setCantidadSalida] = useState(0);

  const [usuarioCompras, setUsuarioCompras] = useState([]);

  const obtenerUsuarioCompras = () => {
    fetch(UrlApi + "/userdata/sector/4/")
      .then((data) => data.json())
      .then((data) => setUsuarioCompras(data));
  };

  useEffect(() => {
    obtenerUsuarioCompras();
  }, []);

  // const [usuarioAdmin, setUsuarioAdmin] = useState([]);

  // const obtenerUsuarioAdmin = () => {
  //   fetch(UrlApi + "/userdata/sector/5/")
  //     .then((data) => data.json())
  //     .then((data) => setUsuarioAdmin(data));
  // };
  // useEffect(() => {
  //   obtenerUsuarioAdmin();
  // }, []);

  //console.log("usuario admin",usuarioAdmin);

  //console.log("USUARIO COMPRAS", usuarioCompras);

  const [reqExiste, setReqExiste] = useState([]);

  const obtenerRequerimientoExistente = () => {
    fetch(
      UrlApi +
        "/requerimientos_materiales/reqexiste/" +
        props.sucursal +
        "/" +
        datosArt.id_articulo
    )
      .then((data) => data.json())
      .then((data) => setReqExiste(data));
  };

  useEffect(() => {
    obtenerRequerimientoExistente();
  }, [datosArt]);

  //console.log("REQUERIMIENTO", reqExiste, reqExiste.length);

  useEffect(() => {
    setCantidadSalida(datosArt.cantidad - consumo.cantidad_enviada);
  }, [datosArt.cantidad, consumo.cantidad_enviada]);
  //console.log("traigo la cantidad de salida: " + cantidadSalida);

  // const envioMail = async (e) => {
  //   const usuariosNotificar = [
  //     ...(usuarioCompras || []),
  //     ...(usuarioAdmin || []),
  //   ]; // Combinar ambos arrays
  //   // console.log("usuarios notificar",usuariosNotificar);

  //   usuariosNotificar &&
  //     usuariosNotificar.length > 0 &&
  //     usuariosNotificar.forEach((usuario) => {
  //       if (usuario.email) {
  //         if (
  //           cantidadSalida < datosArt.alerta_minima &&
  //           cantidadSalida > datosArt.valor_minimo
  //         ) {
  //           // console.log("EMAIL de CONSUMO", usuario.email);
  //           enviarmails(
  //             usuario.email,
  //             "Advertencia de Nivel de Stock ",
  //             `<p>El artículo <b>${datosArt.articulo}</b> del almacén <b>${datosArt.almacen}</b> esta por debajo del punto de pedido.<br/></p>`
  //           );
  //         } else if (cantidadSalida < datosArt.valor_minimo) {
  //           enviarmails(
  //             usuario.email,
  //             "Alerta de Stock por Debajo del Nivel Mínimo",
  //             `<p>El artículo <b>${datosArt.articulo}</b> del almacén <b>${datosArt.almacen}</b> esta por debajo del stock mínimo.<br/></p>`
  //           );
  //         }
  //       }
  //     });
  // };
  // const envioMail = async (e) => {
  //   usuarioCompras &&
  //     usuarioCompras.length > 0 &&
  //     usuarioCompras.forEach((usuario) => {
  //       if (usuario.email) {
  //         if (
  //           cantidadSalida < datosArt.alerta_minima &&
  //           cantidadSalida > datosArt.valor_minimo
  //         ) {
  //           // console.log("EMAIL de CONSUMO", usuario.email);
  //           enviarmails(
  //             usuario.email,
  //             "Advertencia de Nivel de Stock ",
  //             `<p>El artículo <b>${datosArt.articulo}</b> del almacén <b>${datosArt.almacen}</b> esta por debajo del punto de pedido.<br/></p>`
  //           );
  //         } else if (cantidadSalida < datosArt.valor_minimo) {
  //           enviarmails(
  //             usuario.email,
  //             "Alerta de Stock por Debajo del Nivel Mínimo",
  //             `<p>El artículo <b>${datosArt.articulo}</b> del almacén <b>${datosArt.almacen}</b> esta por debajo del stock mínimo.<br/></p>`
  //           );
  //         }
  //       }
  //     });
  // };

  const [notificacion, setNotificacion] = useState({
    open: false,
    mensaje: "",
    severity: "",
    color: "",
  });
  const [uuid, setUuid] = useState(uuidv4());

  const [envioRequerimiento, setEnvioRequerimiento] = useState({
    id_movil: null,
    id_sucursal: props.sucursal,
    fecha_entrega: moment(datosArt.fecha_transaccion).format(
      "YYYY-MM-DD HH:mm:ss"
    ),
    usuario_registra: localStorage.getItem("user"),
    usuario_autoriza: null,
    estado: 50, //cuando se carga el pedido se pone en estado "A autorizar"
    para_comprar: 1,
    observaciones: "",
    uuid_requerimiento: uuid,
    comentarios_internos: "Pedido Automático - Definir Cantidad",
    para_compras: 0,
    para_rrhh: 0,
    autorizado_jefe_compras: 0,
  });

  const [datosLista, setDatosLista] = useState([
    {
      material: null,
      usuario: localStorage.getItem("user"),
      cantidad: 1,
      // id_unidad_medida: datosArt.id_unidad_medida,
      uuid_requerimiento: envioRequerimiento.uuid_requerimiento,
      id_articulo: datosArt.id_articulo,
    },
  ]);

  useEffect(() => {
    setDatosLista([
      {
        material: null,
        usuario: localStorage.getItem("user"),
        cantidad: 1,
        // id_unidad_medida: datosArt.id_unidad_medida,
        uuid_requerimiento: envioRequerimiento.uuid_requerimiento,
        id_articulo: datosArt.id_articulo,
      },
    ]);
    setEnvioRequerimiento({
      id_movil: null,
      id_sucursal: props.sucursal,
      fecha_entrega: moment(datosArt.fecha_transaccion).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      usuario_registra: localStorage.getItem("user"),
      usuario_autoriza: null,
      estado: 50, //cuando se carga el pedido se pone en estado "A autorizar"
      para_comprar: 1,
      observaciones: "",
      uuid_requerimiento: uuid,
      comentarios_internos: "Pedido Automático - Definir Cantidad",
      para_compras: 0,
      para_rrhh: 0,
      autorizado_jefe_compras: 0,
    });
  }, [datosArt]);

  const guardarListaMateriales = (e) => {
    postData(
      e,
      "/lista_materiales",
      {
        datosLista,
      },
      setNotificacion
    );
  };

  const guardarConsumo = async (e) => {
    logEventos(
      "Guardar consumo",
      "Stock Sucursales",
      "Se guardo el consumo",
      localStorage.getItem("user")
    );
    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(UrlApi + "/stock_transacciones", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_accion: 200,
        id_articulo: datosArt.id_articulo,
        almacen_origen: datosArt.id_almacen,
        cantidad_enviada: consumo.cantidad_enviada,
        fecha_transaccion: moment(datosArt.fecha_transaccion).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        usuario: localStorage.getItem("user"),
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El consumo se guardó correctamente!");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el consumo");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    await fetch(UrlApi + "/stock_inventarios/" + datosArt.id_inventario, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        cantidad: cantidadSalida,
        usuario: localStorage.getItem("user"),
      }),
    })
      .then((res) => {
        if (!res.err) {
          console.log("Guardado");
          // console.log("ARANDA NOS VIGILA");
        } else {
          console.log("Error");
          //  console.log("ARANDA TAMBIEN NOS VIGILA");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    // ACA PONER LA FUNCION DE POST
    if (
      cantidadSalida < datosArt.alerta_minima &&
      cantidadSalida >= datosArt.valor_minimo
    ) {
      //  console.log("ADVERTENCIA AMARILLA");
      if (datosArt.pedido_automatico === 1 && reqExiste.length === 0) {
        //    console.log("ES AUTOMATICO AMARILLO");
        postData(
          e,
          "/requerimientos_materiales",
          {
            id_sucursal: envioRequerimiento.id_sucursal,
            id_movil: envioRequerimiento.id_movil,
            estado: 50, //cuando se carga el pedido se pone en estado "Pendiente autorizacion"
            para_comprar: envioRequerimiento.para_comprar,
            para_compras: envioRequerimiento.para_compras,
            para_rrhh: envioRequerimiento.para_rrhh,
            fecha_entrega: envioRequerimiento.fecha_entrega,
            usuario_registra: envioRequerimiento.usuario_registra,
            usuario_autoriza: envioRequerimiento.username,
            observaciones: envioRequerimiento.observaciones,
            uuid_requerimiento: uuid,
            comentarios_internos: envioRequerimiento.comentarios_internos,
            autorizado_jefe_compras: 0,
          },
          setNotificacion,
          guardarListaMateriales(e)
        )
          .then((response) => {
            console.log("Respuesta de postData en guardarCabecera:", response);
          })
          .catch((error) => {
            console.error("Error en postData en guardarCabecera:", error);
          });
      }
    } else if (cantidadSalida < datosArt.valor_minimo) {
      // console.log("ADVERTENCIA ROJA");
      if (datosArt.pedido_automatico === 1 && reqExiste.length === 0) {
        //   console.log("ES AUTOMATICO ROJO");
        postData(
          e,
          "/requerimientos_materiales",
          {
            id_sucursal: envioRequerimiento.id_sucursal,
            id_movil: envioRequerimiento.id_movil,
            estado: 50, //cuando se carga el pedido se pone en estado "Pendiente autorizacion"
            para_comprar: envioRequerimiento.para_comprar,
            para_compras: envioRequerimiento.para_compras,
            para_rrhh: envioRequerimiento.para_rrhh,
            fecha_entrega: envioRequerimiento.fecha_entrega,
            usuario_registra: envioRequerimiento.usuario_registra,
            usuario_autoriza: envioRequerimiento.username,
            observaciones: envioRequerimiento.observaciones,
            uuid_requerimiento: uuid,
            comentarios_internos: envioRequerimiento.comentarios_internos,
            autorizado_jefe_compras: 0,
          },
          setNotificacion,
          guardarListaMateriales(e)
        )
          .then((response) => {
            console.log("Respuesta de postData en guardarCabecera:", response);
          })
          .catch((error) => {
            console.error("Error en postData en guardarCabecera:", error);
          });
      }
    }
    // if (datosArt.enviamail === 1) {
    //   envioMail();
    // }
    handleClose();
  };
  //console.log("cantidad de consumo" ,cantidadSalida, "punto de pedido ",datosArt.alerta_minima, "stock min" ,datosArt.valor_minimo,"almacen",datosArt.almacen);

  //console.log("requerimientos de materiales" , envioRequerimiento.autorizado_jefe_compras);

  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleClose = () => {
    setDialogConsumo(!dialogConsumo);
    if (props.onClose) {
      props.onClose();
    }
  };

  useEffect(() => {
    if (cantidadSalida < 0) {
      setDisabledGuardarRe(true);
      setcolorTextField("error");
    } else {
      setDisabledGuardarRe(false);
      setcolorTextField("primary");
    }
  }, [cantidadSalida, consumo.cantidad_enviada]);
  //console.log("DATOS ART",datosArt);
  const almacenar = (e) => {
    setConsumo({
      ...consumo,
      [e.target.name]: e.target.value,
    });
  };

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };
  return (
    <>
      <Dialog
        open={dialogConsumo}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <Title
          titulo={`Consumo de Artículo`}
          handleClose={() => {
            handleClose();
          }}
        />
        <form onSubmit={guardarConsumo} autoComplete="off">
          <Box
            border={0.5}
            borderColor={grey[300]}
            backgroundColor="#F4F6F6"
            p={2}
            py={2}
            mt={1}
          >
            <Grid mt={2} mb={2}>
              <TextField
                style={style}
                label="Artículo"
                name="articulo"
                size="small"
                inputProps={{
                  readOnly: true,
                  "aria-label": "Without label",
                }}
                focused
                fullWidth
                value={props.datosArt.articulo}
              />
            </Grid>
            <Grid item py={1} mb={1}>
              <TextField
                style={style}
                label="Fecha Transacción"
                name="fecha_transaccion"
                size="small"
                type="date"
                focused
                required
                fullWidth
                value={moment(datosArt.fecha_transaccion).format("YYYY-MM-DD")}
                onChange={almacenar}
              />
            </Grid>
            <Grid item xs={2.9} py={1} mb={1}>
              <TextField
                style={style}
                label="Cantidad"
                name="cantidad_enviada"
                size="small"
                placeholder="..."
                onKeyPress={(event) => {
                  if (!/[0-9.]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                inputProps={{
                  maxLength: 6,
                }}
                focused
                required
                fullWidth
                color={colorTextField}
                value={consumo.cantidad_enviada}
                onChange={almacenar}
              />
            </Grid>
            <Stack direction="row">
              <Grid container direction={{ xs: "column", sm: "row" }}>
                <Grid item xs={9}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleClose}
                  >
                    Volver
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    fullWidth
                    disabled={disabledGuardarRe}
                    variant="contained"
                    color="success"
                    type="submit"
                  >
                    Confirmar
                  </Button>
                </Grid>
              </Grid>
            </Stack>
          </Box>
        </form>
      </Dialog>
      {pantallaAgregarTarea ? <Agregar_tarea datosArt={datosArt} /> : null}

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}

export default Dialog_consumo;
