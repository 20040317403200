import React, { useState, useEffect } from "react";
import Title from "../../Title";
import { getData } from "../../../services/library";
import { Box, Container, TextField, Grid, Divider, Button } from "@mui/material";
import moment from "moment";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FileUploadMultiple from "../../../services/UploadFiles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#92a898",
        color: theme.palette.common.white,
        fontSize: 14,
        padding: 5
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: 5
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.common.white,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default function Fichacomprobante(props) {
    const [cuerpo, setCuerpo] = useState();
    const [pie, setPie] = useState();
    const [color, setColor] = useState("primary");
    const [openclose, setOpenClose] = useState(false);

    useEffect(() => {
        getData('/comprobantes_cuerpo/x_uuid/' + props.datos.uuid_comprobante, setCuerpo)
        getData('/comprobantes_pie/xuuid/' + props.datos.uuid_comprobante, setPie)
    }, [props.datos]);

    const style = {
        backgroundColor: "#ffffff",
        width: "100%"
    };

    return (
        <div>
            <Container maxWidth="xl">
                <Title titulo={"Comprobante Nro°" + props.datos.idcomprobante} handleClose={() => [props.ficha(false), props.tabla(true)]}/>
                <Box
                    backgroundColor="#F4F6F6"
                    mt={1}
                    p={2}
                    border={0.5}
                    borderColor={"#ABB2B9"}
                    borderRadius={4}>
                    <Grid container spacing={1} mb={1}>
                        <Grid item xs={4}>
                            <TextField
                                size="small"
                                label="Proveedor"
                                value={props.datos.razon_social ?? props.datos.nombre_fantasia}
                                focused
                                placeholder="No hay registro"
                                style={style}
                                color={color}
                                InputProps={{
                                    readOnly: true
                                }} />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                size="small"
                                label="Condición"
                                value={props.datos.condicion_pago}
                                focused
                                placeholder="No hay registro"
                                style={style}
                                color={color}
                                InputProps={{
                                    readOnly: true
                                }} />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                size="small"
                                label="Imputación"
                                value={props.datos.descripcion_imputacion ?? "No hay registro"}
                                focused
                                placeholder="No hay registro"
                                style={style}
                                color={color}
                                InputProps={{
                                    readOnly: true
                                }} />
                        </Grid>

                        <Grid item xs={2}>
                            <TextField
                                size="small"
                                label="Tipo comprobante"
                                value={props.datos.tipo_comprobante}
                                focused
                                placeholder="No hay registro"
                                style={style}
                                color={color}
                                InputProps={{
                                    readOnly: true
                                }} />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                size="small"
                                label="Número de comprobante"
                                value={props.datos.nro_comprobante}
                                focused
                                placeholder="No hay registro"
                                style={style}
                                color={color}
                                InputProps={{
                                    readOnly: true
                                }} />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                size="small"
                                label="Fecha comprobante"
                                value={moment(props.datos.fecha_factura).format("DD-MM-YYYY")}
                                focused
                                placeholder="No hay registro"
                                style={style}
                                color={color}
                                InputProps={{
                                    readOnly: true
                                }} />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                size="small"
                                label="Periodo contable"
                                value={props.datos.mes+'-'+props.datos.año}
                                // value={moment(props.datos.id_perdiodocontable).format("MM-YYYY")}
                                focused
                                placeholder="No hay registro"
                                style={style}
                                color={color}
                                InputProps={{
                                    readOnly: true
                                }} />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                size="small"
                                label="Vencimiento"
                                value={props.datos.vto_factura ? moment(props.datos.vto_factura).format("DD-MM-YYYY") : "No hay registro"}
                                focused
                                style={style}
                                color={color}
                                InputProps={{
                                    readOnly: true
                                }} />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                size="small"
                                label="Nro CAE"
                                value={props.datos.nro_cae ?? "No hay registro"}
                                focused
                                style={style}
                                color={color}
                                InputProps={{
                                    readOnly: true
                                }} />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                size="small"
                                label="Vencimiento CAE"
                                value={props.datos.vto_cae !== null ? moment(props.datos.vto_cae).format("DD-MM-YYYY") : "No hay regitro"}
                                focused
                                placeholder="No hay registro"
                                style={style}
                                color={color}
                                InputProps={{
                                    readOnly: true
                                }} />
                        </Grid>

                        <Grid item xs={2}>
                            <TextField
                                size="small"
                                label="Tipo moneda"
                                value={props.datos.tipomoneda ?? "No hay registro"}
                                focused
                                style={style}
                                color={color}
                                InputProps={{
                                    readOnly: true
                                }} />
                        </Grid>

                        <Grid item xs={2}>
                            <TextField
                                size="small"
                                label="Tipo cambio"
                                value={props.datos.tipo_cambio ?? "No hay registro"}
                                focused
                                style={style}
                                color={color}
                                InputProps={{
                                    readOnly: true
                                }} />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                size="small"
                                label="Sucursal"
                                value={props.datos.nombre ?? "No hay registro"}
                                focused
                                style={style}
                                color={color}
                                InputProps={{
                                    readOnly: true
                                }} />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                size="small"
                                label="Facturado desde"
                                value={props.datos.facturado_desde !== null ? moment(props.datos.facturado_desde).format("DD-MM-YYYY") : "No hay registro"}
                                focused
                                style={style}
                                color={color}
                                InputProps={{
                                    readOnly: true
                                }} />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                size="small"
                                label="Facturado hasta"
                                value={props.datos.facturado_hasta ? moment(props.datos.facturado_hasta).format("DD-MM-YYYY") : "No hay registro"}
                                focused
                                style={style}
                                color={color}
                                InputProps={{
                                    readOnly: true
                                }} />
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                size="small"
                                label="Observaciones"
                                value={props.datos.observaciones ?? "No hay registro"}
                                focused
                                style={style}
                                color={color}
                                InputProps={{
                                    readOnly: true
                                }} />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                size="small"
                                label="Total"
                                value={props.datos.total !== null ? "$" + props.datos.total : "No hay registro"}
                                focused
                                style={style}
                                color={color}
                                InputProps={{
                                    readOnly: true
                                }} />
                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                onClick={() =>
                                 setOpenClose(!openclose)} 
                                style={{
                                    borderColor: "#13406e",
                                    backgroundColor: "#ffffff",
                                }}
                                variant="outlined" size="small" startIcon={<AttachFileIcon />} >Comprobante adjunto</Button>

                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid>
                        {cuerpo && (
                            <Grid container spacing={2}>
                                {cuerpo.some(row => row.id_porcentajeiva !== null) && (
                                    <Grid item xs={6} mt={1}  mb={1}>
                                        <TableContainer component={Paper} mt={1}>
                                            <Table sx={{ minWidth: 500 }} aria-label="customized table">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell>Detalle</StyledTableCell>
                                                        <StyledTableCell align="left">Subtotal</StyledTableCell>
                                                        <StyledTableCell align="left">Alícuota IVA</StyledTableCell>
                                                        <StyledTableCell align="left">Total</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {cuerpo
                                                        .filter(row => row.id_porcentajeiva !== null)
                                                        .map(row => (
                                                            <StyledTableRow key={row.id}>
                                                                <StyledTableCell component="th" scope="row">
                                                                    {row.detalle_productos}
                                                                </StyledTableCell>
                                                                <StyledTableCell align="left">${row.subtotal}</StyledTableCell>
                                                                <StyledTableCell align="left">{row.porcentaje_iva} %</StyledTableCell>
                                                                <StyledTableCell align="left">${row.subtotal_iva}</StyledTableCell>
                                                            </StyledTableRow>
                                                        ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                )}
                                {cuerpo.some(row => row.id_impuesto !== null) && (
                                    <Grid item xs={6}>
                                        <TableContainer component={Paper}  mt={1} mb={1}>
                                            <Table sx={{ minWidth: 500 }} aria-label="customized table">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell>Impuesto</StyledTableCell>
                                                        <StyledTableCell align="left">Detalle</StyledTableCell>
                                                        <StyledTableCell align="left">Alícuota IVA</StyledTableCell>
                                                        <StyledTableCell align="left">Importe</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {cuerpo
                                                        .filter(row => row.id_impuesto !== null)
                                                        .map(row => (
                                                            <StyledTableRow key={row.id}>
                                                                <StyledTableCell component="th" scope="row">
                                                                    {row.impuesto}
                                                                </StyledTableCell>
                                                                <StyledTableCell align="left">{row.detalle}</StyledTableCell>
                                                                <StyledTableCell align="left">{row.porcentaje_alicuota} %</StyledTableCell>
                                                                <StyledTableCell align="left">${row.importe}</StyledTableCell>
                                                            </StyledTableRow>
                                                        ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                )}
                            </Grid>
                        )}
                    </Grid>
                    <Divider />
                    <Grid mt={1} mb={1}>
                        {pie && pie.map((row) =>
                            <TableContainer component={Paper} mt={1}>
                                <Table sx={{ minWidth: 500 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>Otros tributos</StyledTableCell>
                                            <StyledTableCell align="left">Importe neto gravado</StyledTableCell>
                                            <StyledTableCell align="left">Porcentaje IVA</StyledTableCell>
                                            <StyledTableCell align="left">Importe</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        <StyledTableRow key={row.idpie_comprobante}>
                                            <StyledTableCell component="th" scope="row">
                                                ${row.otros_tributos}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">${row.importe_neto_gr}</StyledTableCell>
                                            <StyledTableCell align="left">{row.porcentajeiva} %</StyledTableCell>
                                            <StyledTableCell align="left">${row.importe}</StyledTableCell>
                                        </StyledTableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Grid>
                </Box>
            </Container>
            {openclose ?
                <FileUploadMultiple
                    fileId={props.datos.uuid_comprobante}
                    openclose={true} /> : null}
            <br />
            <br />
        </div>
    )
}