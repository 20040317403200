import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { DialogTitle } from "@mui/material";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { UrlApi } from "../../../../services/apirest";
import {
  Grid,
  Select,
  FormControl,
  TextField,
  MenuItem,
  InputLabel,
} from "@mui/material";
import moment from "moment";

function DialogoTransitoSalida(props) {
  const [open, setOpen] = useState(props.open); // Esto abre o no el modal
  const [datosSalida, setDatosSalida] = useState(props.datos);
  const [direcciones, setDirecciones] = useState([{}]);
  const [clientes, setClientes] = useState([{}]);

  console.log(datosSalida);

  const [datosTransito, setDatosTransito] = useState({
    nro_remito: "",
    fecha: datosSalida.s_fecha,
    pesada: datosSalida.s_pesada,
    id_tractor: datosSalida.s_idtractor ? datosSalida.s_idtractor : "",
    id_tanquemovil: datosSalida.s_idtanquemovil
      ? datosSalida.s_idtanquemovil
      : "",
    id_chofer: datosSalida.s_idchofer ? datosSalida.s_idchofer : "",
    tractor: datosSalida.tractor ? datosSalida.tractor : "",
    tanquemovil: datosSalida.tanquemovil ? datosSalida.tanquemovil : "",
    chofer: datosSalida.chofer ? datosSalida.chofer : "",
    id_producto: datosSalida.s_idproducto ? datosSalida.s_idproducto : "",
    km_inicial: "",
    // km_final:"",
    id_cliente: !props.datos.s_idcliente2 ? props.datos.s_idcliente1 : "",
    id_sucursal: "",
    cli_iddireccionentrega: "",
    cot: "",
    recorrido: "",
    p_precinto1: "",
    p_precinto2: "",
    p_precinto3: "",
    p_porccarga: "",
    p_tempproducto: "",
    p_presion: "",
    uuid: datosSalida.uuid,
    baja: 0,
  });

  const obtenerDirecciones = () => {
    fetch(UrlApi + "/direccionesentrega/cli/" + datosTransito.id_cliente)
      .then((data) => data.json())
      .then((data) => setDirecciones(data));
  };

  useEffect(() => {
    obtenerDirecciones();
    obtenerClientes();
  }, []);

  useEffect(() => {
    obtenerDirecciones();
  }, [datosTransito.id_cliente]);

  const setearDatosTransito = (e) => {
    setDatosTransito({ ...datosTransito, [e.target.name]: e.target.value });
  };

  const handleClose = () => {
    setOpen(false);
    props.setShowDialogTransito(false);
  };

  useEffect(() => {
    props.setShowDialogTransito(open);
  }, [open]);

  const obtenerClientes = () => {
    fetch(UrlApi + "/clientes/propelente/activos")
      .then((data) => data.json())
      .then((data) => setClientes(data));
  };

  async function guardarTransito(e) {
    e.preventDefault();
    await fetch(UrlApi + "/remitos_new", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        nro_remito: datosTransito.nro_remito,
        fecha: datosTransito.fecha,
        pesada: datosTransito.pesada,
        id_tractor: datosSalida.s_idtractor ? datosSalida.s_idtractor : "",
        id_tanquemovil: datosSalida.s_idtanquemovil
          ? datosSalida.s_idtanquemovil
          : "",
        id_chofer: datosSalida.s_idchofer ? datosSalida.s_idchofer : "",
        tractor: datosSalida.tractor ? datosSalida.tractor : "",
        tanquemovil: datosSalida.tanquemovil ? datosSalida.tanquemovil : "",
        chofer: datosSalida.chofer ? datosSalida.chofer : "",
        id_producto: datosSalida.s_idproducto ? datosSalida.s_idproducto : "",
        km_inicial: datosTransito.km_inicial,
        // km_final:"",
        id_cliente: datosTransito.id_cliente,
        // id_sucursal: datosTransito,
        cli_iddireccionentrega: datosTransito.cli_iddireccionentrega,
        cot: datosTransito.cot,
        recorrido: datosTransito.recorrido,
        p_precinto1: datosTransito.p_precinto1,
        p_precinto2: datosTransito.p_precinto2,
        p_precinto3: datosTransito.p_precinto3,
        p_porccarga: datosTransito.p_porccarga,
        p_tempproducto: datosTransito.p_tempproducto,
        p_presion: datosTransito.p_presion,
        uuid: datosSalida.uuid,
        baja: 0,
      }),
    }).then(handleClose())
    //response&&console.log(response)
    // if (response.ok === false) {
    //   console.log("ERROR EN API");
    //   const error = response && response.json();
    //   setError(error);
    //   setSeverityAlerta("error")
    //   setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
    // } else {
    //   console.log("Conexión con api OK");
    //   try {
    //     //Parseo respuesta de la api a json()
    //     const json = await response.json();
    //     //si api me devuelve lo q le puse en message
    //     if (json.message) {
    //       //Si ese mensaje es "OK"
    //       if (json.message === "OK") {
    //         console.log("Guardado Satisfactoriamente");
    //         setNotificacion(true);
    //         setOpenSnack(true);
    //         setColorMensaje("#D4EFDF");
    //         setMensaje("Guardado");
    //         setSeveritySnack("success");
    //       } else {
    //   console.log("res en api incorrecto. Revisar que sea 'OK'")
    //         setNotificacion(true);
    //         setOpenSnack(true);
    //         setColorMensaje("#D4EFDF");
    //         setSeveritySnack("error");
    //         setMensaje("Registro Guardado. REVISAR RESPUESTA DE API (E_S)");
    //       }
    //     } else if(json.sqlMessage) {
    //       json && setError(json);
    //       console.log(json);
    //       setMensajeAlerta("SQL erróneo (" + json.code + " Errno: " + json.errno + " )" );
    //     } else {
    //       setMensajeAlerta("ERROR DESCONOCIDO.");
    //     }
    //   } catch (error) {
    //     if (error.toString().includes("is not valid JSON")) {
    //       console.log("Guardado. Advertencia: Actualizar respuesta de api. JSON invalido.");
    //       setNotificacion(true);
    //       setOpenSnack(true);
    //       setColorMensaje("#D4EFDF");
    //       setMensaje("Guardado");
    //       setSeveritySnack("success");
    //     } else {
    //       setMensajeAlerta("ERROR DESCONOCIDO.");
    //     }
    //   }
    // }
  }
























  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <Box backgroundColor="#076d55" color="white">
          <DialogTitle>Añadir Tránsito</DialogTitle>
        </Box>
        <DialogContent>
          <form onSubmit={guardarTransito}>
            <Grid
              container
              direction="row"
              px={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} container justifyContent="center">
                <Typography p={2} variant="h5">
                  Carga De Tránsito
                </Typography>
              </Grid>
            </Grid>
            <Grid spacing={2} container direction="row" px={2} py={1}>
              <Grid item xs={2}>
                <TextField
                  required
                  size="small"
                  onChange={setearDatosTransito}
                  name="nro_remito"
                  value={datosTransito.nro_remito}
                  color="success"
                  label="Nro Remito"
                  variant="outlined"
                  fullWidth
                  focused
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{
                    maxLength: 10,
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  required
                  size="small"
                  onChange={setearDatosTransito}
                  name="km_inicial"
                  value={datosTransito.km_inicial}
                  color="success"
                  label="KM INICIAL"
                  variant="outlined"
                  fullWidth
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{
                    maxLength: 7,
                  }}
                  focused
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  required
                  size="small"
                  onChange={setearDatosTransito}
                  name="fecha"
                  value={moment(datosTransito.fecha).format("YYYY-MM-DD")}
                  color="success"
                  label="Fecha Remito"
                  variant="outlined"
                  fullWidth
                  type="date"
                  focused
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  disabled
                  size="small"
                  value={datosSalida.s_pesada}
                  color="success"
                  label="Pesada Salida (KG)"
                  variant="outlined"
                  fullWidth
                  type="number"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  size="small"
                  onChange={setearDatosTransito}
                  name="recorrido"
                  value={datosTransito.recorrido}
                  color="success"
                  label="Recorrido"
                  variant="outlined"
                  fullWidth
                  focused
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  required
                  size="small"
                  onChange={setearDatosTransito}
                  name="cot"
                  value={datosTransito.cot}
                  color="success"
                  label="COT"
                  variant="outlined"
                  fullWidth
                  focused
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{
                    maxLength: 15,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  required
                  focused
                  fullWidth
                  disabled={!props.datos.s_idcliente2}
                >
                  <InputLabel color="success">Cliente </InputLabel>
                  <Select
                    required
                    size="small"
                    color="success"
                    value={datosTransito.id_cliente}
                    onChange={setearDatosTransito}
                    name="id_cliente"
                    label="Cliente"
                  >
                    {clientes &&
                      clientes.map((cliente) =>
                        cliente.id === props.datos.s_idcliente1 ||
                        cliente.id === props.datos.s_idcliente2 ? (
                          <MenuItem key={cliente.id} value={cliente.id}>
                            {cliente.razon_social}
                          </MenuItem>
                        ) : null
                      )}
                    {/* <MenuItem >
                            Cliente 1 
                          </MenuItem>
                          <MenuItem >
                            Cliente 2 */}
                    {/* </MenuItem> */}
                    {/* {clientes
                      ? clientes.map((elemento) => (
                          <MenuItem key={elemento.id} value={elemento.id}>
                            {elemento.razon_social}
                          </MenuItem>
                        ))
                      : null} */}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl required focused fullWidth>
                  <InputLabel color="success">
                    {" "}
                    Direccion de entrega{" "}
                  </InputLabel>
                  <Select
                    required
                    size="small"
                    color="success"
                    value={datosTransito.c_iddireccionentrega}
                    onChange={setearDatosTransito}
                    name="cli_iddireccionentrega"
                    label="Direccion de entrega"
                  >
                    {direcciones
                      ? direcciones.map((elemento) => (
                          <MenuItem key={elemento.id} value={elemento.id}>
                            {elemento.direccion_entrega}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid item xs={6}>
                <TextField
                  disabled
                  size="small"
                  name="razon_social"
                  value={datosRegistro.s_sucursal}
                  color="success"
                  variant="outlined"
                  label="Sucursal de entrega "
                  fullWidth
                  focused
                  InputLabelProps={{ shrink: true}}                
                  />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  placeholder="No registrada"
                  disabled
                  size="small"
                  name="sucursal_direccion"
                  value={datosRegistro.sucursal_direccion}
                  color="success"
                  variant="outlined"
                  label="Direccion Sucursal Entrega "
                  fullWidth
                  focused
                  InputLabelProps={{ shrink: true}}                
                  />
              </Grid> */}

              <Grid item xs={4}>
                <TextField
                  required
                  onChange={setearDatosTransito}
                  focused
                  size="small"
                  value={datosTransito.precinto1}
                  name="p_precinto1"
                  color="success"
                  label="Precinto 1"
                  variant="outlined"
                  fullWidth
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{
                    maxLength: 10,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  required
                  focused
                  onChange={setearDatosTransito}
                  size="small"
                  value={datosTransito.c_precinto2}
                  color="success"
                  name="p_precinto2"
                  label="Precinto 2"
                  variant="outlined"
                  fullWidth
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{
                    maxLength: 10,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  required
                  focused
                  onChange={setearDatosTransito}
                  size="small"
                  value={datosTransito.c_precinto3}
                  color="success"
                  name="p_precinto3"
                  label="Precinto 3"
                  variant="outlined"
                  fullWidth
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{
                    maxLength: 10,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  onChange={setearDatosTransito}
                  focused
                  name="p_porccarga"
                  size="small"
                  value={datosTransito.p_porccarga}
                  color="success"
                  label="Carga Tanque (%)"
                  variant="outlined"
                  fullWidth
                  error={
                    datosTransito.p_porccarga && datosTransito.p_porccarga > 85
                  }
                  helperText={
                    datosTransito.p_porccarga && datosTransito.p_porccarga > 85
                      ? "El valor no puede ser mayor al 85%"
                      : ""
                  }
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{
                    maxLength: 2,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  onChange={setearDatosTransito}
                  focused
                  name="p_tempproducto"
                  size="small"
                  value={datosTransito.p_tempproducto}
                  color="success"
                  label="Temperatura Producto (°C)"
                  variant="outlined"
                  fullWidth
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{
                    maxLength: 2,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  focused
                  onChange={setearDatosTransito}
                  name="p_presion"
                  size="small"
                  value={datosTransito.p_presion}
                  color="success"
                  label="Presion"
                  variant="outlined"
                  fullWidth
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{
                    maxLength: 2,
                  }}
                />
              </Grid>
              <Grid
                m={2}
                container
                direction="row"
                justifyContent="space-between"
              >
                <Button variant="outlined" color="error" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="outlined"
                  color="success"
                  // disabled={
                  //   (datosTransito.c_porccarga && datosTransito.c_porccarga > 85) ||
                  //   disBtnGuardar
                  // }
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default DialogoTransitoSalida;
