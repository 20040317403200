//----- Component --------------------------------------------------------/

// Developer :  Daiana Curcio / Candela Grosso

// Creation Date :14/12/2023

// Version : 1

// Description : Para asignar los tecnicos (instaladores)

//

//------Changes --------------------------------------------------------------------- -------------//

// Change Date :

// Change by :

// Change description:

//

// Change Date :

// Change by :

// Change description:

//

//========================================================================/
import { Dialog, Box, TextField, Grid, IconButton, Typography, Button, FormControl, InputLabel, Select, MenuItem, DialogContent, Autocomplete, DialogActions } from "@mui/material";
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import React, { useState, useEffect } from "react";
import { grey } from '@mui/material/colors';
import { v4 as uuidv4 } from 'uuid';
import { UrlApi } from "../../../services/apirest";
import Notify from "../../Notify";
import moment from "moment";
import { enviarsms } from "../../../services/services";
import { logEventos } from "../../../services/services";

export default function AsignarTecnico(props) {
    const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
    const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
    const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
    const [error, setError] = useState(null);
    const [instaladores, setInstaladores] = useState(null);
    const [uniqueInstalador,setUniqueInstalador] = useState("");
    const [disbtn, setdisBtn] = useState(false);
   const [datosFormulario, setdatosFormulario] = useState({
        id_proveedor: null,
        estado: 170,
    });

    const[activeTab,setActiveTab]=useState(props.activeTab)
    const [dataReclamo, setDataReclamo] = useState(props.datos);


    const obtenerInstaladores = () => {
        fetch(UrlApi + '/proveedores/instaladores/')
            .then(data => data.json())
            .then(data => setInstaladores(data))
    };

    useEffect(() => {
        obtenerInstaladores()
    }, []);

    const almacenar = (e) => {
        setdatosFormulario({
            ...datosFormulario,
            [e.target.name]: e.target.value ? e.target.value : " "
        });
    };

    const style = {
        backgroundColor: "#ffffff",
        width: "100%"
    };

   // console.log("UNIQUE INSTALADOR",uniqueInstalador.id);
   // console.log("DATOS RECLAMO", dataReclamo);
  //  console.log("PROPS ACTIVE 4", props.activeTab === 4);

    const handleGuardarReclamo = async (e) => {
        logEventos("Guardar técnico asignado", "Reclamos Granel", "Se guardo el técnico asignado", localStorage.getItem("user"))
        e.preventDefault();
        setDeshabilitado(true);
        let response = await fetch(UrlApi + '/reclamos/' + props.datos.idreclamo, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id_proveedor: uniqueInstalador.id,
                estado: 170,
                // nro_reclamo: props.datos.idreclamo,
            })
        })
        if (response.ok === false) {
            const error = response && response.json();
            setError(error);
            alert("Inconvenientes con la API. No se pudo guardar. ");
        } else {
            try {
                const json = await response.json();
                if (json.message) {
                    if (props.activeTab === 4) {
                        uniqueInstalador && enviarsms(uniqueInstalador.celular_user, `Hola ${uniqueInstalador.razon_social.trim()} se te asignó la OT ${dataReclamo.idreclamo} cliente ${dataReclamo.id_cliente}-${dataReclamo.razon_social}.\nInstalación por factibilidad ${dataReclamo.id_fact} .\nVer en la App.`);
                        setNotificacion(true);
                        setOpenSnack(true);
                        setMensaje(json.message === "OK" ? "Guardado" : "Guardado. Actualizar RTA Api.");
                        props.actualizar();
                    } else {
                        uniqueInstalador && enviarsms(uniqueInstalador.celular_user, `Hola ${uniqueInstalador.razon_social.trim()} se te asignó la OT ${dataReclamo.idreclamo}.\n Ver en la app.`);
                        setNotificacion(true);
                        setOpenSnack(true);
                        setMensaje(json.message === "OK" ? "Guardado" : "Guardado. Actualizar RTA Api.");
                        props.actualizar();
                    }
                } else if (json.sqlMessage) {
                    json && setError(json);
                    alert("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
                } else {
                    alert("ERROR DESCONOCIDO.");
                }
            } catch (error) {
                if (error.toString().includes("is not valid JSON")) {
                    setNotificacion(true);
                    setOpenSnack(true);
                    setMensaje("Guardado");
                    props.actualizar()
                } else {
                    alert("Error: " + error);
                }
            }
        }
    };

    const handleClose = () => {
        props.tabla(true)
        props.open(false)
    };

    //console.log("INSTALADOR SELECCIONADO",uniqueInstalador,"telefono instalador",uniqueInstalador.celular_user);

    const [deshabilitado, setDeshabilitado] = useState(false)

    useEffect(() => {
        if (Object.keys(uniqueInstalador).length === 0 ) {
          setDeshabilitado(true)
        } else{
          setDeshabilitado(false)
        }
      }, [uniqueInstalador])

    return (
        <div>
            <Dialog open={props.open} fullWidth>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container backgroundColor="#1F618D" p={1.5}>
                        <Grid item xs={2}>
                            <IconButton onClick={() => props.open(false)}>
                                <ArrowBackIcon sx={{ color: grey[50] }} />
                            </IconButton>
                        </Grid>

                        <Grid
                            item
                            xs={8}
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography variant="h5" color="#ffffff">
                                Asignar Técnico
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <DialogContent>
                    <Box
                        backgroundColor="#F4F6F6"
                        mt={2}
                        mb={2}
                        ml={3}
                        p={3}
                        border={0.5}
                        borderColor={"#ABB2B9"}
                        borderRadius={4}
                    >
                        <Grid container spacing={1} direction={{ xs: 'column', sm: 'row' }}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    focused
                                    style={style}
                                    noOptionsText={"Instalador INEXISTENTE"}
                                    options={instaladores}
                                    autoHighlight
                                    disableClearable
                                    getOptionLabel={(instaladores) => instaladores.razon_social}
                                    onChange={(event, value) => setUniqueInstalador(value)}
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            key={option.razon_social}
                                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                            {...props}
                                        >
                                            {option.razon_social}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            focused
                                            fullWidth={true}
                                            placeholder="Tipee para buscar un instalador.."
                                            {...params}
                                            variant='outlined'
                                            label="Instalador "
                                            inputProps={{
                                                ...params.inputProps,
                                            }
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-end"

                        mt={0}
                    >
                        <Button
                            disabled={deshabilitado}
                            variant="contained"
                            type="submit"
                            color="success"
                            size="small"
                            onClick={handleGuardarReclamo}
                        >
                            Asignar Técnico
                        </Button>
                    </Grid>
                </DialogActions>
                {
                    notificacion ? (
                        <Notify
                            mensaje={mensaje}
                            open={openSnack}
                            color={"#D4EFDF"}
                            severity={"success"}
                            handleClose={handleClose}
                            error={error}
                        />
                    ) : null}
            </Dialog>
        </div>
    )
}