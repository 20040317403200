import React from "react";
import { useEffect, useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";


function ModalRepetido2(props) {
  const [dataCliente, setDataCliente] = useState(props.cliente);
  const [pedidoRepetido, setPedidoRepetido] = useState(props.pedidoRepetido); // Acá se guardan los datos del pedido que está repetido
  //   console.log(pedidoRepetido)

  useEffect(() => {
    setPedidoRepetido(
      props.pedidoRepetido &&
        props.pedidoRepetido[props.pedidoRepetido.length - 1]
    ); //se guarda el del ultimo id , despues ver por que filtramos
  }, [props.pedidoRepetido]);

  const handleClose = () => {
    props.setTanque({
      capacidad: "",
      delivery_stop: "",
      id_direccionentrega: "",
      id_tanque: "",
      idbateria: "",
      nro: "",
      nro_serie: "",
      vto_pruebahidraulica: "",
    });
    props.setRepetido(null);
  };

  return (
    <div>
      <Dialog open={props.pedidoRepetido} onClose={() => handleClose()}>
        {dataCliente ? (
          <DialogTitle textAlign={"center"}>
            {"Ya existe un pedido para " + dataCliente.razon_social}
          </DialogTitle>
        ) : (
          <DialogTitle textAlign={"center"} backgroundColor="#1F618D" color="#ffffff">
            {"Este pedido ya fue registrado y está pendiente.."}
          </DialogTitle>
        )}
        {pedidoRepetido ? (
          <>
            <DialogContent dividers>
              <DialogContentText>
                <InputLabel>
                  {" "}
                  <b>Numero de pedido: </b> {pedidoRepetido.id_pedido}
                </InputLabel>
                <InputLabel>
                  <b>Kg a cargar:</b> {pedidoRepetido.kgacargar}
                </InputLabel>
                <InputLabel>
                  <b>Registrado:</b> {pedidoRepetido.fecha_registro}
                </InputLabel>
                <InputLabel>
                  <b>Telefonista:</b> {pedidoRepetido.usuario}
                </InputLabel>
                <InputLabel>
                  <b>Estado:</b> {pedidoRepetido.estado}
                </InputLabel>
              </DialogContentText>
            </DialogContent>
            {/* <br></br> */}

            {/* <Grid container justifyContent="center">
              <Grid item m={1}>
              <Typography variant="h7" align="center">
                {"¿ Desea modificar el pedido existente?"}
              </Typography>
              </Grid>
            </Grid> */}
            <DialogActions>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                // alignItems="center"
              >
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={() => props.setRepetido(null)}
                    color="primary"
                  >
                    cargar de todos modos
                  </Button>
                </Grid>
                <Grid item >
                  <p style={{fontSize:12}}>Escape para cancelar*</p>
                  {/* <Button
                    variant="contained"
                    onClick={() => handleClose()}
                    color="error"
                  >
                    cancelar
                  </Button> */}
                </Grid>
                {/* <Grid item xs={3}>
                  <Button
                    disabled
                    onClick={(e) => {
                      // handleModificar(e);
                    }}
                    variant="contained"
                    color="success"
                  >
                    Modificar
                  </Button>
                </Grid> */}
              </Grid>
            </DialogActions>
          </>
        ) : (
          "No pudieron cargarse los datos del pedido"
        )}
      </Dialog>
    </div>
  );
}

export default ModalRepetido2;
