import React from "react";
import Navbar from "../../Navbar";
import { Container } from "@mui/material";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Afacturar from "./Afacturar";
import Facturados from "./Facturados";
import DoneIcon from "@mui/icons-material/Done";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

function Facturar() {
  //MANEJA TAB
  const [opcion, setOpcion] = React.useState("a facturar");

  const handleChange = (event, newValue) => {
    setOpcion(newValue);
  };

  //FIN TAB

  return (
    <div>
      <Navbar />
      <Container maxWidth="xl">
        <Box sx={{ width: "100%" }} >
          <Tabs
            value={opcion}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="Pedidos"
            variant="fullWidth"
          >
            <Tab
              icon={<FormatListBulletedIcon />}
              iconPosition="end"
              value="a facturar"
              label="Pedidos a facturar"
            />
            <Tab
              icon={<DoneIcon />}
              iconPosition="end"
              value="facturados"
              label="Pedidos facturados"
            />
          </Tabs>
        </Box>
        {opcion === "a facturar" ? <Afacturar></Afacturar> : null}

        {opcion === "facturados" ? <Facturados></Facturados> : null}
      </Container>
    </div>
  );
}

export default Facturar;
