import React, { useState, useEffect } from "react";
import { UrlApi } from "../../../services/apirest";
import {
  Container,
  Grid,
  Box,
  TextField,
  IconButton,
  Button,
  Typography,
  Autocomplete,
  Stack,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { grey } from "@mui/material/colors";
import { logEventos } from "../../../services/services";
//notificacion
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Navbar from "../../Navbar";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function EditarModelo(props) {
  const [datos, setDatos] = useState({
      descripcion: props.modelo.descripcion,
    });
  const [datosModelo, setDatosModelo] = useState(props.modelo);
  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
   //*********************************** NOTIFICACION **************************************************************************************************/
  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
    handleClose();
  };

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };
  const size = "small";

  const handleClose = () => {
    props.editar(false);
    props.tabla(true);
  };

  const guardarEditar = async (e) => {
    // logEventos(
    //   "Guardar edición del punto de venta",
    //   "puntos de venta",
    //   "Se guardo la edición",
    //   localStorage.getItem("user")
    // );
    // setDisBtn(true);
    e.preventDefault();
    const response = await fetch(
      UrlApi + "/modelos/" + datosModelo.id,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          descripcion: datos.descripcion,
        }),
      }
    )
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          setColorMensaje("#D4EFDF");
          setMensaje("Se guardó exitosamente !");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const almacenar = (e) => {
    setDatos({
      ...datos,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container backgroundColor="#1F618D" p={1.5}>
            <Grid item xs={2}>
              <IconButton onClick={handleClose}>
                <ArrowBackIcon sx={{ color: grey[50] }} />
              </IconButton>
            </Grid>
            <Grid
              item
              xs={8}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h5" color="#ffffff">
               Editar Modelo
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <form onSubmit={guardarEditar} autoComplete="off">
          <Box
            backgroundColor="#F4F6F6"
            mt={2}
            p={3}
            border={0.5}
            borderColor={"#ABB2B9"}
            borderRadius={4}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    size={size}
                    style={style}
                    focused
                    color="primary"
                    required
                    name="descripcion"
                    label="Modelo"
                    variant="outlined"
                    value={datos.descripcion}
                    onChange={almacenar}
                  />
                </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-end"
              mt={2}
            >
              <Button variant="contained" onClick={handleClose} color="error">
                Volver
              </Button>

              <Button
                variant="contained"
                type="submit"
                color="success"
              >
                Guardar
              </Button>
            </Grid>
          </Box>
        </form>
        {/* SNACKBAR QUE SE MOSTRARA AL GUARDAR LOS CAMBIOS */}
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={openSnack}
            autoHideDuration={1500}
            onClose={handleCloseSnack}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseSnack}
              severity="success"
              sx={{ width: "100%" }}
              style={{ backgroundColor: colormensaje, color: "black" }}
            >
              {mensaje}
            </Alert>
          </Snackbar>
        </Stack>
      </Container>
    </div>
  )
}

export default EditarModelo