import React, { useState, useEffect, useMemo, useRef } from "react";
import { UrlApi } from "../../services/apirest";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Grid,
  Stack,
  Snackbar,
  TextField,
  Typography,
  Autocomplete,
  DialogContentText,
  Alert,
  IconButton,
} from "@mui/material";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Dialog_EditarVar from "./Dialog_EditarVar";
import { logEventos } from "../../services/services";

function Dialog_definir(props) {
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [openSnackEditar, setOpenSnackEditar] = React.useState(false);

  const [update, setUpdate] = useState(false);
  const [datosArticulos, setDatosArticulos] = useState(props.datosArticulos);
  const [definir, setDefinir] = useState(props.definir);
  const [ultimaRow, setUltimaRow] = useState(props.ultimaRow);
  const [sucursalSeleccionada, setSucursalSeleccionada] = useState({});
  const [valores, setValores] = useState({
    minimo: null,
    alerta: null,
  });
  const [stocksVariables, setStocksVariables] = useState();
  const [openEditar, setOpenEditar] = useState(false);
  const [rowVariables, setRowVariables] = useState();

  const obtenerStocksVariables = () => {
    fetch(
      UrlApi + "/stock_articulos/stocksvariables/" + datosArticulos.id_articulo
    )
      .then((data) => data.json())
      .then((data) => setStocksVariables(data));
  };

  useEffect(() => {
    obtenerStocksVariables();
  }, []);
  //console.log("//////////////////////////////ROWVARIIABLES", rowVariables);
  const [sucursales, setSucursales] = useState();
  const obtenerSucursales = () => {
    fetch(UrlApi + "/sucursales")
      .then((data) => data.json())
      .then((data) => setSucursales(data));
  };

  useEffect(() => {
    obtenerSucursales();
  }, []);

  // console.log("ULTIMA ROW", ultimaRow);

  const handleClickLimpiar = () => {
    valores.alerta = "";
    valores.minimo = "";
  };

  const handleClickOpenEditar = (row) => {
    setRowVariables(row);
    setOpenEditar(true);
  };
  const handleCloseEditar = () => {
    setOpenEditar(false);
    setUpdate(true);
  };
  // ultimaRow? parseInt(ultimaRow[0].id_articulo) + parseInt(1) : 1
  const guardarVariable = async (e) => {
    logEventos(
      "Guardar artículo variable",
      "Stock Sucursales",
      "Se guardo artículo variable",
      localStorage.getItem("user")
    );
    console.log("Estoy por guardar artículo");
    e.preventDefault();
    await fetch(UrlApi + "/stock_alertas", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_articulo: datosArticulos.id_articulo,
        id_sucursal: sucursalSeleccionada.id,
        alerta: valores.alerta,
        minimo: valores.minimo,
      }),
    })
      .then((res) => {
        if (!res.err) {
          console.log("Guardado");
          setOpenSnack(true);
          setUpdate(true);
          setColorMensaje("#D4EFDF");
          setMensaje("Las variables se guardaron exitosamente !");
        } else {
          console.log("Error");
        }
      })
      .catch((err) => {
        setColorMensaje("#F1948A");
        setMensaje("Error al guardar las variables");
        console.log(err.message);
      });

    handleClickLimpiar();
  };

  const handleClose = () => {
    setDefinir(!definir);
    if (props.onClose) {
      props.onClose();
    }
  };

  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  useEffect(() => {
    if (update) {
      obtenerSucursales();
      obtenerStocksVariables();
      setUpdate(false);
    }
  }, [update]);

  const almacenar = (e) => {
    setValores({
      ...valores,
      [e.target.name]: e.target.value,
    });
  };

  const columns = useMemo(
    () => [
      {
        header: "ID",
        accessorKey: "id_articulo",
        size: 60,
        enableHiding: true,
      },
      {
        header: "id_sucursal",
        accessorKey: "id_sucursal",
        size: 100,
        enableHiding: true,
      },
      { header: "Sucursal", accessorKey: "sucursal", size: 120 },
      {
        header: "Punto de Pedido",
        accessorKey: "alerta",
        size: 120,
      },
      { header: "Valor Mínimo", accessorKey: "minimo", size: 100 },
    ],
    []
  );

  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)" //background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //override to make tooltip font size larger
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const displayColumnDefOptions = {
    "mrt-row-actions": {
      muiTableHeadCellProps: {
        align: "center",
        sx: (theme) => ({
          fontSize: 14,
          fontStyle: "italic",
          background: "#4da8cf",
          borderRight: "1px solid rgba(224,224,224,1)",
          color: theme.palette.text.primary,
        }),
      },
      size: 50,
      muiTableBodyCellProps: { align: "center" },
    },
  };

  const initialState = {
    //para inicializar la tabla, y la cantidad_enviada de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 5 },
    columnVisibility: {
      id_articulo: false,
      id_sucursal: false,
    },
  };
  // console.log("//////STOCKS VARIABLES/////////////", stocksVariables);
  // console.log("---------------DATOS VARIABLES-------------", sucursalSeleccionada, valores.alerta, valores.minimo);
  return (
    <>
      <Dialog
        open={definir}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            color: "white",
            backgroundColor: "#0979B0",
            border: "1px solid white",
            borderRadius: 1,
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              onClick={() => handleClose()}
              style={{ marginRight: "420px" }}
            >
              <ArrowBackIcon sx={{ color: "white" }} />
            </IconButton>
            <Typography variant="h5" align="center" color="#ffffff">
              Definir Variables de {datosArticulos.nombre}
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form onSubmit={guardarVariable} autoComplete="off">
              <Grid container mt={2} mb={1} spacing={1}>
                <Grid item xs={5} ml={2.9}>
                  {openSnack ? (
                    <TextField
                      value={(sucursalSeleccionada.id = null)}
                      disabled
                      focused
                      fullWidth
                      size="small"
                      label="Guardando variable..."
                    />
                  ) : (
                    <Autocomplete
                      disabled={!sucursales}
                      size="small"
                      focused
                      required
                      disableClearable
                      id="sucursales"
                      noOptionsText={"Sucursal INEXISTENTE"}
                      options={
                        sucursales
                          ? sucursales.filter(
                              (sucursal) =>
                                !stocksVariables.some(
                                  (stock) => stock.id_sucursal === sucursal.id
                                )
                            )
                          : "Cargando..."
                      }
                      autoHighlight
                      getOptionLabel={(sucursales) =>
                        sucursales.id + "-" + sucursales.nombre
                      }
                      onChange={(event, value) =>
                        setSucursalSeleccionada(value)
                      }
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          key={option.id}
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option.id} - {option.nombre}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          focused
                          required
                          size="small"
                          placeholder="Tipee para buscar..."
                          {...params}
                          label="Sucursal"
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}
                    />
                  )}
                </Grid>
                <Grid item xs={1.5} py={1} mb={1}>
                  <TextField
                    label="Punto de pedido"
                    name="alerta"
                    size="small"
                    inputProps={{
                      maxLength: 10,
                    }}
                    focused
                    required
                    onKeyPress={(event) => {
                      if (!/[0-9.]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    value={valores.alerta}
                    onChange={almacenar}
                  />
                </Grid>
                <Grid item xs={1.5} py={1} mb={1}>
                  <TextField
                    label="Stock Minimo"
                    name="minimo"
                    size="small"
                    inputProps={{
                      maxLength: 10,
                    }}
                    focused
                    required
                    onKeyPress={(event) => {
                      if (!/[0-9.]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    value={valores.minimo}
                    onChange={almacenar}
                  />
                </Grid>
                <Grid item xs={1} ml={29}>
                  <Button variant="contained" color="success" type="submit">
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </DialogContentText>
          <Container maxWidth="xl">
            {stocksVariables ? (
              <Box>
                <ThemeProvider theme={tableTheme}>
                  <MaterialReactTable
                    enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
                    enableGrouping // para permitir agrupar x estado x ej
                    displayColumnDefOptions={displayColumnDefOptions}
                    layoutMode="grid"
                    muiTableHeadCellProps={{
                      sx: (theme) => ({
                        background: "#4da8cf",
                        borderRight: "1px solid rgba(224,224,224,1)",
                        color: theme.palette.text.primary,
                        align: "left", // los titulos de la tabla
                      }),
                    }}
                    enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
                    enableColumnFilters={false} //desactiva filtros x  columna
                    enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
                    enableDensityToggle={false} //
                    localization={MRT_Localization_ES} //idioma
                    enableColumnDragging={false} //para arrastrar columnas
                    initialState={initialState}
                    // muiSearchTextFieldProps={muiSearchTextFieldProps}
                    columns={columns}
                    data={stocksVariables} //con lo q la completas (esto cambia siempre)
                    enableStickyFooter
                    enableEditing
                    positionActionsColumn="last" // posicion de la columna de acciones al final
                    // ACCIONES
                    renderToolbarInternalActions={(
                      { table } // boton para nuevo , al lado del buscar
                    ) => (
                      <>
                        <MRT_FullScreenToggleButton table={table} />
                      </>
                    )}
                    renderTopToolbarCustomActions={() => (
                      <>
                        <Typography variant="poster" fontSize={26}>
                          Lista por Sucursal
                        </Typography>
                      </>
                    )}
                    renderRowActions={(
                      { row } //las acciones del costado editar y ver
                    ) => (
                      <>
                        <Button
                          size="small"
                          onClick={() => handleClickOpenEditar(row.original)}
                        >
                          <EditIcon />
                        </Button>
                      </>
                    )}
                  />
                </ThemeProvider>
              </Box>
            ) : null}
          </Container>
        </DialogContent>
        {openEditar ? (
          <Dialog_EditarVar
            rowVariables={rowVariables}
            openEditar={openEditar}
            onClose={() => handleCloseEditar(false)}
          />
        ) : null}
      </Dialog>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}

export default Dialog_definir;
