import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import { green, pink } from "@mui/material/colors";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import FolderIcon from "@mui/icons-material/Folder";
import PageviewIcon from "@mui/icons-material/Pageview";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";



function ErrorModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "#fff",
    borderRadius: 10,
    boxShadow: 24,
  };

  return (
    <div>
      {" "}
      <Modal
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Grid
          style={style}
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          p={2}
        >
          <Grid item xs={2} mt={1}>
              <Avatar sx={{ bgcolor: "#fee2e2" }}>
                <ReportProblemIcon color="error" />
              </Avatar>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="h6" color="initial" pt={1}>
                {props.title}
            </Typography>
            <Typography variant="subtitle1" color="grey" pt={1}>
              {" "}
                {props.description}
            </Typography>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
}

export default ErrorModal;
