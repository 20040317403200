import React from "react";
import { UrlApi } from "../../../services/apirest";
import { useEffect, useState } from "react";
import { Container } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import moment from "moment";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import FormMovimientoTanque from "./FormMovimientoTanque";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import InputMask from "react-input-mask";

//imports
import Alerta from "../../Alerta";
import Notify from "../../Notify";
import Title from "../../Title";

function Editartanque(props) {
  const [datosformulario, setDatosformulario] = useState(props.datos);
  const [datosMovimiento, setDatosMovimiento] = useState({}); // aqui se guardan los datos del registro del movimiento del tanque en caso de que se cambie de sucursal
  const [fabricantes, setFabricantes] = useState(null);
  const [sucursales, setSucursales] = useState();
  const [estados, setEstados] = useState();
  const [duenios, setDuenios] = useState();
  const [estadofisico, setEstadofisico] = useState();
  const [disBtn, setDisBtn] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [flagCancel, setFlagCancel] = useState(0);

  // cosas nuevas
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
  const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
  const [mensajeAlerta, setMensajeAlerta] = useState(""); //mensaje que contendrá el componente "alerta"
  const [severityAlerta, setSeverityAlerta] = useState(""); //define severidad de la alerta (success error o warning)
  const [severitySnack, setSeveritySnack] = useState(""); //define severidad del snackbar (notificación)

  const almacenar = (e) => {
    setDatosformulario({ ...datosformulario, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    obtenerFabricantes();
    obtenerSucursales();
    obtenerEstados();
    obtenerDuenios();
    obtenerEstadoFisico();
  }, []);

  const obtenerEstados = () => {
    fetch(UrlApi + "/estados_tanques")
      .then((data) => data.json())
      .then((data) => setEstados(data));
  };

  const obtenerEstadoFisico = () => {
    fetch(UrlApi + "/desplegables/estadoFisicoTq")
      .then((data) => data.json())
      .then((data) => setEstadofisico(data));
  };

  const obtenerDuenios = () => {
    fetch(UrlApi + "/desplegables/dueniotanque")
      .then((data) => data.json())
      .then((data) => setDuenios(data));
  };

  const handleClose = () => {
    props.editar(false);
    props.tabla(true);
  };

  const obtenerFabricantes = () => {
    fetch(UrlApi + "/fabricantes")
      .then((data) => data.json())
      .then((data) => setFabricantes(data));
  };

  const obtenerSucursales = () => {
    fetch(UrlApi + "/sucursales")
      .then((data) => data.json())
      .then((data) => setSucursales(data));
  };

  //********************************************************************************* */

  useEffect(() => {
    props.datos.id_sucursal !== datosformulario.id_sucursal
      ? setShowForm(true)
      : setShowForm(false);
  }, [datosformulario.id_sucursal]);

  async function guardarTanque(e) {
    setDisBtn(true);
    e.preventDefault();
    const response = await fetch(UrlApi + "/tanques/" + datosformulario.id, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        nro_serie: datosformulario.nro_serie,
        id_sisven:
          datosformulario.id_sisven === "" ? null : datosformulario.id_sisven,
        id_fabricante: datosformulario.id_fabricante,
        fecha_fabricacion:
          datosformulario.fecha_fabricacion === ""
            ? null
            : datosformulario.fecha_fabricacion,
        fecha_compra:
          datosformulario.fecha_compra === ""
            ? null
            : datosformulario.fecha_compra,
        estado_fisico: datosformulario.estado_fisico,
        capacidad:
          datosformulario.capacidad === "" ? null : datosformulario.capacidad,
        observaciones: datosformulario.observaciones,
        baja: 0,
        fecha_ubicacion:
          datosformulario.fecha_ubicacion === ""
            ? null
            : datosformulario.fecha_ubicacion,
        id_estado: datosformulario.id_estado,
        fecha_estado:
          datosformulario.fecha_estado === ""
            ? null
            : datosformulario.fecha_estado,
        duenio: datosformulario.duenio,
        delivery_stop: datosformulario.delivery_stop,
        usuario: localStorage.getItem("user"),
        id_sucursal: datosformulario.id_sucursal,
        fecha_pruebahidraulica:
          datosformulario.fecha_pruebahidraulica === ""
            ? null
            : datosformulario.fecha_pruebahidraulica,
        vto_pruebahidraulica:
          datosformulario.vto_pruebahidraulica === ""
            ? null
            : datosformulario.vto_pruebahidraulica,
      }),
    });
    if (response.ok === false) {
      const error = response && response.json();
      setError(error);
      setSeverityAlerta("error");
      setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      console.log("Conexión con api OK");
      try {
        const json = await response.json();
        if (json.message) {
          if (props.datos.id_sucursal !== datosformulario.id_sucursal) {
            guardarMovimiento(e);
          } else {
            setNotificacion(true);
            setOpenSnack(true);
            setMensaje(
              json.message === "OK"
                ? "Modificación de tanque guardada exitosamente."
                : "Modificacion de tanque guardada exitosamente. Actualizar RTA Api."
            );
            props.actualizar();
          }
        } else if (json.sqlMessage) {
          json && setError(json);
          console.log(json);
          setMensajeAlerta(
            "SQL erróneo (" + json.code + " Errno: " + json.errno + " )"
          );
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          if (props.datos.id_sucursal !== datosformulario.id_sucursal) {
            guardarMovimiento(e);
          } else {
            setNotificacion(true);
            setOpenSnack(true);
            setMensaje(
              "Modificacion de tanque guardada exitosamente. Actualizar RTA Api a Json"
            );
            props.actualizar();
          }
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      }
    }
  }

  async function guardarMovimiento(e) {
    setDisBtn(true);
    e.preventDefault();
    const response = await fetch(UrlApi + "/movimientos_tanques", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(datosMovimiento),
    });
    if (response.ok === false) {
      const error = response && response.json();
      setError(error);
      setSeverityAlerta("error");
      setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      console.log("Conexión con api OK");
      try {
        const json = await response.json();
        if (json.message) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje(
            json.message === "OK"
              ? "Edición y movimiento de tanque guardado correctamente"
              : "Edición y movimiento de tanque guardado correctamente. Actualizar RTA API"
          );
        } else if (json.sqlMessage) {
          json && setError(json);
          console.log(json);
          setMensajeAlerta(
            "SQL erróneo (" + json.code + " Errno: " + json.errno + " )"
          );
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje("Guardado. Actualizar RTA Api A Json.");
          setSeveritySnack("success");
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO: " + error);
        }
      }
    }
  }

  //  csas nuevas
  useEffect(() => {
    if (flagCancel === 1) {
      //SI CANCELO CUANDO TENGO EL FORMULARIO PARA RELLENAR LOS DATOS DEL MOVIMIENTO DE STOCK:
      setDatosformulario({
        ...datosformulario,
        ["id_sucursal"]: props.datos.id_sucursal,
      }); //id_sucursal vuelve a la sucursal inicial
    }
    setFlagCancel(0); // flag en 0
  }, [flagCancel]);

  useEffect(() => {
    if (props.datos.id_sucursal !== datosformulario.id_sucursal)
      setDatosformulario({ ...datosformulario, ["fecha_ubicacion"]: "" });
  }, [datosformulario.id_sucursal]);

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };
  const handleFechaPH = (e) => {
    const fecha = moment(e.target.value).format("YYYY-MM-DD");
    const vencimiento = moment(moment(fecha).add(10, "y")._d).format(
      "YYYY-MM-DD"
    );
    setDatosformulario({
      ...datosformulario,
      fecha_pruebahidraulica: e.target.value,
      vto_pruebahidraulica: vencimiento,
    });
  };

  return (
    <div>
      <Container>
        {notificacion ? (
          <Notify
            mensaje={mensaje}
            open={openSnack}
            color={"#D4EFDF"}
            handleClose={handleClose}
            severity={"success"}
          />
        ) : null}
        {error && mensajeAlerta ? (
          <Alerta mensaje={mensajeAlerta} severity={"error"} />
        ) : null}
        <Title
          titulo={"Editar Tanque: " + datosformulario.nro_serie}
          handleClose={handleClose}
        />
        <form onSubmit={guardarTanque}>
          <Box
            mt={1}
            mb={3}
            backgroundColor="#F4F6F6"
            p={3}
            border={0.5}
            borderColor={"#ABB2B9"}
            borderRadius={4}
          >
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <TextField
                  style={style}
                  required
                  focused
                  name="nro_serie"
                  label="Numero de Serie"
                  color="primary"
                  onChange={almacenar}
                  value={datosformulario.nro_serie}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <StickyNote2Icon />
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    maxLength: 15,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  style={style}
                  placeholder="Numero Sisven"
                  focused
                  name="id_sisven"
                  label="Numero Sisven"
                  color="primary"
                  onChange={almacenar}
                  value={datosformulario.id_sisven}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <StickyNote2Icon />
                      </InputAdornment>
                    ),
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{
                    maxLength: 8,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <InputMask
                  mask="9.99"
                  value={datosformulario.capacidad}
                  disabled={false}
                  maskChar="0"
                  onChange={almacenar}
                >
                  {() => (
                    <TextField
                      style={style}
                      fullWidth
                      required
                      type="text"
                      focused
                      name="capacidad"
                      label="Capacidad (m3)"
                    />
                  )}
                </InputMask>
              </Grid>
              <Grid item xs={3}>
                <FormControl style={style} focused required>
                  <InputLabel>Nuevo / Usado</InputLabel>
                  <Select
                    required
                    name="estado_fisico"
                    focused
                    InputLabelProps={{ shrink: true }}
                    value={datosformulario.estado_fisico}
                    label="Nuevo / Usado"
                    onChange={almacenar}
                  >
                    {estadofisico
                      ? estadofisico.map((elemento) => (
                          <MenuItem key={elemento.id} value={elemento.id}>
                            {elemento.valor}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl style={style} focused >
                  <InputLabel>Dueño</InputLabel>
                  <Select
                    name="duenio"
                    focused
                    InputLabelProps={{ shrink: true }}
                    value={datosformulario.duenio}
                    label="Dueño"
                    onChange={almacenar}
                  >
                    {duenios
                      ? duenios.map((elemento) => (
                          <MenuItem key={elemento.id} value={elemento.id}>
                            {elemento.valor}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl style={style} focused required>
                  <InputLabel>Fabricante</InputLabel>
                  <Select
                    required
                    name="id_fabricante"
                    focused
                    InputLabelProps={{ shrink: true }}
                    value={datosformulario.id_fabricante}
                    label="Fabricante"
                    onChange={almacenar}
                  >
                    {fabricantes
                      ? fabricantes.map((elemento) => (
                          <MenuItem key={elemento.id} value={elemento.id}>
                            {elemento.nombre}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  style={style}
                  focused
                  color="primary"
                  label="Fecha de fabricacion"
                  name="fecha_fabricacion"
                  type="date"
                  onChange={almacenar}
                  value={moment(datosformulario.fecha_fabricacion).format(
                    "YYYY-MM-DD"
                  )}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    max: moment(new Date()).format("YYYY-MM-DD"),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  style={style}
                  focused
                  color="primary"
                  id="date"
                  label="Fecha de compra"
                  name="fecha_compra"
                  type="date"
                  onChange={almacenar}
                  value={moment(datosformulario.fecha_compra).format(
                    "YYYY-MM-DD"
                  )}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: datosformulario.fecha_fabricacion,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                {/* Se deshabilita la edicion de la sucursal si el estado del tanque es ASIGNADO, ya que el tanque se encuentra fisicamente en el domicilio de algun cliente.
                Si permito el cambio de sucursal, esto generaria un nuevo registro del movimiento del tanque que no seria REAL. */}
                <FormControl
                  style={style}
                  focused
                  disabled={datosformulario.id_estado === 6}
                >
                  <InputLabel>Sucursal</InputLabel>
                  <Select
                    required
                    name="id_sucursal"
                    focused
                    InputLabelProps={{ shrink: true, required: true }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={datosformulario.id_sucursal}
                    label="Sucursal"
                    onChange={almacenar}
                  >
                    {sucursales
                      ? sucursales.map((elemento) => (
                          <MenuItem key={elemento.id} value={elemento.id}>
                            {elemento.nombre}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled={datosformulario.id_estado === 6}
                  style={style}
                  focused
                  color="primary"
                  id="date"
                  label="Fecha Ingreso Sucursal"
                  name="fecha_ubicacion"
                  type="date"
                  onChange={almacenar}
                  value={moment(datosformulario.fecha_ubicacion).format(
                    "YYYY-MM-DD"
                  )}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: datosformulario.fecha_compra,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <FormControl style={style} focused>
                  <InputLabel disabled>Estado</InputLabel>
                  <Select
                    disabled
                    required
                    name="id_estado"
                    focused
                    InputLabelProps={{ shrink: true }}
                    value={datosformulario.id_estado}
                    label="Estado"
                    onChange={almacenar}
                  >
                    {estados
                      ? estados.map((elemento) => (
                          <MenuItem key={elemento.id} value={elemento.id}>
                            {elemento.estado}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disabled
                  style={style}
                  focused
                  color="primary"
                  id="date"
                  label="Fecha Estado"
                  name="fecha_estado"
                  type="date"
                  onChange={almacenar}
                  value={moment(datosformulario.fecha_estado).format(
                    "YYYY-MM-DD"
                  )}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  style={style}
                  focused
                  color="primary"
                  label="Fecha Prueba Hidráulica"
                  name="fecha_pruebahidraulica"
                  type="date"
                  onChange={(e) => handleFechaPH(e)}
                  value={moment(datosformulario.fecha_pruebahidraulica).format(
                    "YYYY-MM-DD"
                  )}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  style={style}
                  focused
                  color="primary"
                  label="Vencimiento Prueba Hidráulica"
                  name="vto_pruebahidraulica"
                  type="date"
                  onChange={almacenar}
                  value={moment(datosformulario.vto_pruebahidraulica).format(
                    "YYYY-MM-DD"
                  )}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={9}>
                <TextField
                  onChange={almacenar}
                  style={style}
                  focused
                  color="primary"
                  id="date"
                  label="Observaciones"
                  name="observaciones"
                  value={datosformulario.observaciones}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Switch
                      color="error"
                      checked={datosformulario.delivery_stop === 1}
                      onChange={(e) =>
                        setDatosformulario({
                          ...datosformulario,
                          ["delivery_stop"]: e.target.checked ? 1 : 0,
                        })
                      }
                    />
                  }
                  label="Delivery Stop"
                  labelPlacement="start"
                />
              </Grid>
            </Grid>
            {/* <br></br> */}
            <Grid
              mt={1}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button
                variant="contained"
                color="error"
                onClick={handleClose}
                disabled={disBtn}
              >
                Volver
              </Button>
              <Button
                disabled={
                  disBtn ||
                  datosformulario.capacidad === "0.00" ||
                  !datosformulario.capacidad
                }
                variant="contained"
                type="submit"
                color="success"
              >
                Guardar Tanque
              </Button>
            </Grid>
          </Box>
        </form>
        <br></br>
      </Container>
      {showForm ? (
        <>
          <FormMovimientoTanque
            open={true}
            showForm={setShowForm}
            datosTanque={datosformulario}
            datosMovimiento={setDatosMovimiento}
            datosFormulario={setDatosformulario}
            flagCancel={setFlagCancel}
            datos={datosformulario}
          />
        </>
      ) : null}
    </div>
  );
}

export default Editartanque;
