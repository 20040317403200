import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@mui/material";
import Notify from "../../Notify";
import { UrlApi } from "../../../services/apirest";

function DialogBajaCertificadora(props) {
  console.log(props);
  const handleClose = () => {
    props.setOpen(false);
  };

  const [error, setError] = useState(false);
  const [notificacion, setNotificacion] = useState(false);
  const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
  const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
  const [disBtn, setDisBtn] = useState(false);

  console.log("props", props);
  const marcarInactiva = async (e) => {
    setDisBtn(true);
    e.preventDefault();
    const response = await fetch(
      UrlApi + "/certificadoras/" + props.datosCert.id,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          activo: 0,
        }),
      }
    );
    if (response.ok === false) {
      const error = response && response.json();
      setError(error);
      alert("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      try {
        const json = await response.json();
        if (json.message) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje(
            json.message === "OK" ? "Guardado" : "Guardado. Actualizar RTA Api."
          );
          props.actualizar();
        } else if (json.sqlMessage) {
          json && setError(json);
          alert("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
          props.setOpen(false);
        } else {
          alert("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje("Guardado");
          props.actualizar();
        } else {
          alert("Error: " + error);
        }
      }
    }
  };

  return (
    <div>
      <Dialog open={props.open} onClose={() => handleClose}>
        {notificacion ? (
          <Notify
            mensaje={mensaje}
            open={openSnack}
            color={"#D4EFDF"}
            severity={"success"}
            handleClose={handleClose}
          />
        ) : null}
        <Box backgroundColor={"#1F618D"}>
          <DialogTitle>
            <Typography color="#ffffff" fontSize={20}>
              Baja de certificadora: {props.datosCert.nombre}
            </Typography>
          </DialogTitle>
        </Box>
        <DialogContent>
          <Typography variant="body2" color="initial">
            Confirme que desea dar de baja la certificadora elegida.
          </Typography>
        </DialogContent>

        <DialogActions>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Button
                color="error"
                disabled={disBtn}
                variant="contained"
                onClick={() => handleClose()}
              >
                Cancelar
              </Button>
            </Grid>

            <Grid item>
              <Button
                color="success"
                type="submit"
                variant="contained"
                onClick={(e) => marcarInactiva(e)}
                disabled={disBtn}
              >
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DialogBajaCertificadora;
