import React from "react";
import { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Tabs from "@mui/material/Tabs";
import { Menu, MenuItem } from "@mui/material"
import Tab from "@mui/material/Tab";
import { grey } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { getDataIx0, putData } from "../../../../services/library";
import DireccionesEntrega from "./direccionescliente/DireccionesEntrega";
import TablaTanques from "./tanquescliente/TablaTanques";
import ListContratos from "./contratos/ListContratos";
import PedidosCli from "./PedidosCli";
import DatosCli from "./DatosCli";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Title from "../../../Title";
import ReclamosCli from "./reclamos/ReclamosCli";
import ImpuestosCliente from "./impuestos/ImpuestosCliente";
import Comprobantes from "./cuentacorriente/Comprobantes"
import AddPedidos4 from "../pedidos/altapedido/AddPedidos4";
import { responsiveProperty } from "@mui/material/styles/cssUtils";

function FichaCliente(props) {
  const [id_cliente, setid_cliente] = useState(props.datos.id);
  const [pedidos, setPedidos] = useState(false);
  const [datosformulario, setDatosformulario] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  //maneja el menu de añadir pedido
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  /************************************************************************************************/
  //Maneja TAB
  const [opcion, setOpcion] = useState("datos");

  const handleChange = (event, newValue) => {
    setOpcion(newValue);
  };

  //Cierra la ficha del cliente
  const handleClose = () => {
    props.ficha(false);
    props.tabla(true);
    props.setDataCliente({})
    setid_cliente(null);
    
    
  };

  // useEffect(() => {
  //   getDataIx0("/clientes/" + id_cliente, setDatosformulario);
  // }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getDataIx0("/clientes/" + id_cliente, setDatosformulario);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, [id_cliente]);

  const obtenerDataCliente = async () => {
    setDatosformulario(null)
   await  getDataIx0("/clientes/" + id_cliente, setDatosformulario);
  //  console.log("vuelvo a obtener los datos");
  };

  const openPedidos = () => {
    props.setShowTabs(false)
    props.setTitle(false);
    setPedidos(true)
    handleMenuClose()
  };

  // console.log("props en dataCliente", props.datos);
  // console.log("ficha", props.title)
  // console.log("datosformulario", datosformulario)
// 
  // console.log("id_cliente", id_cliente)
  

  return (
    <div>
      <Container maxWidth="xl">
        {props.title &&
          <Box sx={{ flexGrow: 1, backgroundColor: "#1F618D", mt: 2 }}>
            <Grid
              container
              p={1}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={1}>
                <IconButton onClick={handleClose}>
                  <ArrowBackIcon sx={{ color: grey[50] }} />
                </IconButton>
              </Grid>
              <Grid item xs={11}>
                <Typography variant="h5" color="#fff" textAlign="center">
                  {` ${props.datos.razon_social} (${props.datos.sisven_id
                    ? props.datos.sisven_id
                    : "Sisven Id No Registrado"
                    })  `}
                </Typography>
              </Grid>
              {/* <Grid item xs={1}>
                <IconButton onClick={handleMenuOpen}>
                  <MoreVertIcon sx={{ color: grey[50] }} />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={openPedidos}>Añadir pedido</MenuItem>
                </Menu>
              </Grid> */}
            </Grid>
          </Box>}



        {datosformulario ? (
          <>
            {
              props.showTabs && (
                <>
                  <Box sx={{ width: "100%" }}>
                    <Tabs
                      variant="fullWidth"
                      value={opcion}
                      onChange={handleChange}
                      textColor="primary"
                      indicatorColor={"primary"}
                      aria-label="Clientes"
                    >
                      <Tab value="datos" label="Datos" />
                      {(datosformulario.situacion_iva === "1" ||
                        datosformulario.situacion_iva === "3") && (
                          <Tab value="impuestos" label="Impuestos" />
                        )}
                      <Tab value="direcciones" label="Direcciones de entrega" />
                      <Tab value="tanques" label="Tanques" />
                      {/* <Tab value="contratos" label="Contratos" /> */}
                      <Tab value="pedidos" label="Pedidos" />
                      <Tab value="reclamos" label="Reclamos" />
                      <Tab value="cc" label="Cuenta Corriente" />
                    </Tabs>
                    <br />
                  </Box>
                  {opcion === "datos" ? (
                    <DatosCli
                      datos={datosformulario}
                      ficha={props.ficha}
                      tabla={props.tabla}
                      actualizar={props.actualizar}
                      obtenerDatos={obtenerDataCliente}
                    />
                  ) : null}
                  {opcion === "impuestos" ? (
                    <ImpuestosCliente cliente={datosformulario} />
                  ) : null}
                  {opcion === "direcciones" ? (
                    <DireccionesEntrega cliente={datosformulario} />
                  ) : null}
                  {opcion === "tanques" ? (
                    <TablaTanques datos={datosformulario} />
                  ) : null}
                  {opcion === "contratos" ? (
                    <ListContratos datos={datosformulario} />
                  ) : null}
                  {opcion === "pedidos" ? (
                    <PedidosCli datos={datosformulario} opcion={"pedidos"}  />
                  ) : null}
                  {opcion === "reclamos" ? (
                    <ReclamosCli cliente={datosformulario} />
                  ) : null}
                  {opcion === "cc" ? (
                    <Comprobantes cliente={datosformulario} />
                  ) : null}
                </>
              )
            }

          </>
        ) : (
          <>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              p={3}
            >
              <Grid item xs>
                <Typography m={6} fontSize={30} variant="h2" color="initial">
                  Cargando información de cliente...
                </Typography>
              </Grid>
              <Grid item>
                <CircularProgress size="7rem" color="primary" />
              </Grid>
            </Grid>
          </>
        )}
      </Container>
      {pedidos ? (
        <Container maxWidth="lg">
          <Title
            titulo={props.datos.sisven_id ? `Nuevo Pedido: ${props.datos.razon_social} (${props.datos.sisven_id})` : `Nuevo Pedido: ${props.datos.razon_social}`}
            handleClose={() => {
              setPedidos(false); 
              props.ficha(true)  
              obtenerDataCliente()
              props.setShowTabs(true)
              props.setTitle(true)          
              // props.actualizar();
              // props.tabla(true);
              // setid_cliente();
              // props.setDataCliente({});
              // console.log("props.datos", props.datos)
            }}
          />
          <AddPedidos4
            // datosCli={datosformulario}
            ficha={props.ficha}
            datosCli={props.datos}
            addPedido={setPedidos}
            tabla={props.tabla}
            vengo={"PedidosCli"}
            actualizar={props.actualizar}
            setDataCliente={setDatosformulario}
            obtenerDatos={obtenerDataCliente}
            setShowTabs={props.setShowTabs}
            setTitle={props.setTitle}
            setIdCliente ={setid_cliente}
          />
        </Container>
      ) : null}
    </div>
  );
}

export default FichaCliente;
