import React from "react";
import { Grid, TextField } from '@mui/material';
import moment from "moment";

export default function FormPrecios(props) {
    const { style, size, almacenar, datoslista } = props;
    const today = new Date().toISOString().split("T")[0]; // Formato YYYY-MM-DD

    const vigenciaFormatted = moment(datoslista.vigencia).add(1, 'days').format('YYYY-MM-DD');

    return (
        <>
            {datoslista &&
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    mt={1}
                    ml={1}
                    mr={1}
                >
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            size={size}
                            style={style}
                            focused
                            color="primary"
                            required
                            name="precio_litro_sin_iva"
                            label="Precio litro sin IVA"
                            variant="outlined"
                            value={isNaN(datoslista.precio_litro_sin_iva) ? 0 : datoslista.precio_litro_sin_iva}
                            onChange={almacenar}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            size={size}
                            style={style}
                            focused
                            color="primary"
                            required
                            name="precio_litro_con_iva"
                            label="Precio litro con IVA"
                            variant="outlined"
                            value={isNaN(datoslista.precio_litro_con_iva) ? 0 : datoslista.precio_litro_con_iva}
                            onChange={almacenar}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            size={size}
                            style={style}
                            focused
                            color="primary"
                            required
                            name="precio_kilo_sin_iva"
                            label="Precio kilo sin IVA"
                            variant="outlined"
                            value={isNaN(datoslista.precio_kilo_sin_iva) ? 0 : datoslista.precio_kilo_sin_iva}
                            onChange={almacenar}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            size={size}
                            style={style}
                            focused
                            color="primary"
                            required
                            name="precio_kilo_con_iva"
                            label="Precio kilo con IVA"
                            variant="outlined"
                            value={isNaN(datoslista.precio_kilo_con_iva) ? 0 : datoslista.precio_kilo_con_iva}
                            onChange={almacenar}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            size={size}
                            style={style}
                            focused
                            color="primary"
                            required
                            type="date"
                            name="vigencia"
                            label="Vigencia"
                            variant="outlined"
                            value={datoslista.vigencia}
                            onChange={almacenar}
                            // InputProps={{
                            //     inputProps: {
                            //         min: today
                            //     }
                            // }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            size={size}
                            style={style}
                            focused
                            color="primary"
                            // required
                            type="date"
                            name="fin_vigencia"
                            label="Fin Vigencia"
                            variant="outlined"
                            value={datoslista.fin_vigencia}
                            onChange={almacenar}
                            // InputProps={{
                            //     inputProps: {
                            //         min: vigenciaFormatted
                            //     }
                            // }}
                        />
                    </Grid>
                </Grid>
            }
        </>

    )
}

//----- Component --------------------------------------------------------/
// Developer :  Candela Grosso
// Creation Date: 06/09/2024
// Version : 1
// Description : formulario para agregar precios, solo tngo los textfields y eso
//========================================================================/