import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { enviarmails } from '../../../../../services/services';
import { getData, postData } from '../../../../../services/library';
import { IconButton, Grid, Box, Select, FormControl, TextField, MenuItem, InputLabel, Container, Stack, Button, Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import { Delete, AttachFile, AddCircleOutline } from '@mui/icons-material';
import Notify from "../../../../Notify";
import Title from "../../../../Title";
import FileUploadMultiple from '../../../../../services/UploadFiles';
import CircularProgress from '@mui/material/CircularProgress';
import AddArticulosDialog from '../AddArticulosDialog';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { logEventos } from '../../../../../services/services';

const filter = createFilterOptions();

export default function Addrequerimientos(props) {
    const [uuid, setUuid] = useState(uuidv4());
    const [date, setFecha] = useState(moment(new Date()).format("DD-MM-YYYY"));
    const [autorizanteSelected, setautorizanteSelected] = useState();
    const [disBtn, setDisBtn] = useState(false);
    const [openclose, setOpenClose] = useState(false);
    const [movil, setMovil] = useState(null);
    const [sucursales, setSucursales] = useState(null);
    const [usuarios, setUsuarios] = useState([]);
    const [usuariosPiden, setusuariosPiden] = useState();
    const [articulos, setArticulos] = useState([]);
    const [autocompleteOpen, setAutocompleteOpen] = useState(false);
    const [autocompleteLoading, setAutocompleteLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [medidas, setmedidas] = useState(null);

    //datos para la tabla "requerimientos_materiales"
    const [datosFormulario, setDatosFormulario] = useState({
        id_movil: null,
        id_sucursal: localStorage.getItem('sucursal'),
        fecha_entrega: null,
        usuario_registra: localStorage.getItem('user'),
        usuario_autoriza: autorizanteSelected ,
        estado: 49, //cuando se carga el pedido se pone en estado "A autorizar"
        para_comprar: 1,
        observaciones: null,
        uuid_requerimiento: uuid,
        comentarios_internos: null,
        para_compras: null,
        para_rrhh: null
    });

    //datos para la tabla "lista_materiales"
    const [datosLista, setDatosLista] = useState([{
        usuario: localStorage.getItem('user'),
        cantidad: null,
        uuid_requerimiento: datosFormulario.uuid_requerimiento,
        id_articulo: null,
        material: null,
        id_unidad_medida: null
    }]);

    const [isChecked, setChecked] = useState(datosLista && datosLista.map(() => true));

    const [notificacion, setNotificacion] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
    });

    const loadArticles = () => {
        setAutocompleteLoading(true);
        try {
            getData('/stock_articulos/allarticles', setArticulos)
        } catch (error) {
            console.error('Error al cargar artículos:', error);
        } finally {
            setAutocompleteLoading(false)
        }
    };

    useEffect(() => {
        getData("/sucursales", setSucursales);
        getData("/unidad_medida", setmedidas);
    }, []);

    useEffect(() => {
        const userPreselected = usuarios && usuarios.find(usuario => usuario.orden === 1);
        if (userPreselected) {
            setautorizanteSelected(userPreselected)
        }
    }, [usuarios, datosFormulario.id_sucursal]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await loadArticles();
            } catch (error) {
                console.error('Error al cargar artículos:', error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        getData("/userdata/autorizxsucu/" + datosFormulario.id_sucursal, setUsuarios);
        getData('/vehiculos/sucursal/' + datosFormulario.id_sucursal, setMovil);
        getData('/userdata/noautorizantes/' + datosFormulario.id_sucursal, setusuariosPiden);
    }, [datosFormulario.id_sucursal]);

    //FUNCION PARA VOLVER A LA TABLA DE REQUERIMIENTOS
    const handleClose = () => {
        props.alta(false)
        props.tabla(true)
    };

    const handleCheckChange = (index) => {
        // Crear una copia del array de estados
        const newChecked = [...isChecked];
        // Cambiar el valor del estado correspondiente
        newChecked[index] = !newChecked[index];
        // Actualizar el estado
        setChecked(newChecked);
    };

    const almacenarRequerimientos = (e) => {
        setDatosFormulario({
            ...datosFormulario,
            [e.target.name]: e.target.value ? e.target.value : null
        })
    };

    const agregarLinea = () => {
        const nuevaLinea = {
            id_unidad_medida: null,
            material: null,
            cantidad: null,
            id_articulo: null,
            usuario: localStorage.getItem('user'),
            uuid_requerimiento: datosFormulario.uuid_requerimiento
        };
        setDatosLista((prevLineas) => [...prevLineas, nuevaLinea]);
    };

    const handleCantidadChange = (index, cantidad) => {
        setDatosLista((prevLineas) =>
            prevLineas.map((linea, i) =>
                i === index ? { ...linea, cantidad } : linea
            )
        );
    };

    const handleMaterialChange = (index, material) => {
        setDatosLista((prevLineas) =>
            prevLineas.map((linea, i) =>
                i === index ? { ...linea, material } : linea
            )
        );
    };

    const handleMedidaChange = (index, id_unidad_medida) => {
        setDatosLista((prevLineas) =>
            prevLineas.map((linea, i) =>
                i === index ? { ...linea, id_unidad_medida } : linea
            )
        );
    };

    const handleArticuloChange = (index, selectedArticulo) => {
        setDatosLista((prevLineas) =>
            prevLineas.map((linea, i) =>
                i === index ? { ...linea, id_articulo: selectedArticulo?.id_articulo || null } : linea,

            )
        );
    };

    const deshacerAlmacenar = (index) => {
        setDatosLista(prevState => prevState.filter((_, i) => i !== index));
    };

    const handleAgregarArticulo = (nuevoArticulo) => {
        // Manejar la adición de un nuevo artículo desde el diálogo
        setArticulos((prevArticulos) => [...prevArticulos, nuevoArticulo]);
        setDialogOpen(false);
    };

    const handleCerrarModal = () => {
        setDialogOpen(false);
        loadArticles();
    };

    const guardarCabecera = (e) => {
        setDisBtn(true);
        postData(e,
            '/requerimientos_materiales',
            {
                id_sucursal: datosFormulario.id_sucursal,
                id_movil: datosFormulario.id_movil,
                estado: 49, //cuando se carga el pedido se pone en estado "Pendiente autorizacion"
                para_comprar: datosFormulario.para_comprar,
                para_compras: datosFormulario.para_compras,
                para_rrhh: datosFormulario.para_rrhh,
                fecha_entrega: datosFormulario.fecha_entrega,
                usuario_registra: datosFormulario.usuario_registra,
                usuario_autoriza: autorizanteSelected.username,
                observaciones: datosFormulario.observaciones,
                uuid_requerimiento: uuid,
                comentarios_internos: datosFormulario.comentarios_internos,
                autorizado_jefe_compras: 0
            },
            setNotificacion,
            datosLista && (datosLista.id_articulo || datosLista.material) !== null ? guardarListaMateriales(e) : console.log("No se guardo"),
            autorizanteSelected && enviarmails(autorizanteSelected.email, "Solicitud de requerimientos", `<p>${datosFormulario.usuario_registra} ha enviado una solicitud de compra para usted.</p>`),
            logEventos("Carga de requerimientos", "Requerimientos materiales", "Requerimientos materiales", localStorage.getItem("user"))
        );
    };

    const guardarListaMateriales = (e) => {
        postData(e,
            "/lista_materiales",
            {
                datosLista
            },
            setNotificacion,
            logEventos("Carga de lista materiales", "Lista materiales", "Lista materiales", localStorage.getItem("user"))
        );
    };

    const handleseleccionarUsuario = (e) => {
        setautorizanteSelected(e.target.value)
    };

    const style = {
        backgroundColor: "#ffffff",
        width: "100%"
    };

    const handleChange = (e) => {
        setDatosFormulario({
            ...datosFormulario, [e.target.name]: e.target.checked ? 1 : 0
        })
    };

    return (
        <div>
            <Container >
                <Title
                    titulo={`Cargar nuevo requerimiento ` + `${date}`}
                    handleClose={handleClose} />
                <form onSubmit={guardarCabecera} autoComplete="off">
                    <Box
                        backgroundColor="#F4F6F6"
                        mt={2}
                        p={3}
                        mb={5}
                        border={0.5}
                        borderColor={"#ABB2B9"}
                        borderRadius={4}
                    >
                        <Grid container spacing={1} direction={{ xs: 'column', sm: 'row' }}>
                            <Grid item xs={4}>
                                <TextField
                                    name='id_sucursal'
                                    size='small'
                                    select
                                    style={style}
                                    required
                                    focused
                                    color="primary"
                                    placeholder='Elija una sucursal'
                                    value={datosFormulario.id_sucursal || ''}
                                    onChange={almacenarRequerimientos}
                                    label="Sucursal"
                                >
                                    {(sucursales ?? []).map(elemento => (
                                        <MenuItem key={elemento.id} value={elemento.id}>
                                            {elemento.nombre}
                                        </MenuItem>
                                    ))}
                                </TextField>

                            </Grid>

                            <Grid item xs={4}>
                                <FormControl style={style} size='small' focused color="primary">
                                    <InputLabel id="lbl_movil">Movil</InputLabel>
                                    <Select
                                        name='id_movil'
                                        value={datosFormulario.id_movil || ''}
                                        onChange={almacenarRequerimientos}
                                        label="Movil"
                                    >
                                        {(movil ?? []).map(elemento => (
                                            <MenuItem key={elemento.id} value={elemento.id}>
                                                {elemento.modelo} - {elemento.patente}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={4}>
                                <TextField
                                    name="fecha_entrega"
                                    focused
                                    size='small'
                                    required
                                    style={style}
                                    label="Fecha de entrega"
                                    value={moment(datosFormulario.fecha_entrega).format("YYYY-MM-DD")}
                                    onChange={almacenarRequerimientos}
                                    type="date"
                                    inputProps={{
                                        min: moment(new Date()).format("YYYY-MM-DD")
                                    }}
                                />

                            </Grid>

                            <Grid item xs={3}>
                                <TextField
                                    style={style}
                                    size='small'
                                    name='usuario_registra'
                                    required
                                    margin='dense'
                                    focused
                                    color="primary"
                                    placeholder='Elija un usuario'
                                    value={datosFormulario.usuario_registra || ''}
                                    label="Registra"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                >
                                    {(usuariosPiden ?? []).map(elemento => (
                                        <MenuItem key={elemento.username} value={elemento.username}>
                                            {elemento.nombrepila}   {elemento.apellido}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid item xs={3}>
                                <FormControl size='small' style={style} focused color="primary" required margin='dense'>
                                    <InputLabel id="lbl_autorizante">Autoriza</InputLabel>
                                    <Select
                                        required
                                        name='usuario_autoriza'
                                        value={autorizanteSelected || ''}
                                        onChange={handleseleccionarUsuario}
                                        label="Autoriza">
                                        {(usuarios ?? []).map(elemento => (
                                            <MenuItem key={elemento.username} value={elemento}>
                                                {elemento.nombrepila} {elemento.apellido}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={2}>
                                <FormControlLabel
                                    disabled={datosFormulario.para_rrhh === 1 || datosFormulario.para_compras === 1}
                                    labelPlacement="bottom"
                                    label="Para comprar"
                                    required={(datosFormulario.para_compras === 0 || null && datosFormulario.para_rrhh === 0 || null)}
                                    control={
                                        <Checkbox
                                            name='para_comprar'
                                            onChange={handleChange}
                                            defaultChecked
                                        />
                                    }
                                />
                            </Grid>

                            {datosFormulario && datosFormulario.para_comprar !== 1 ?
                                <>
                                    <Grid item xs={2}>
                                        <FormControlLabel
                                            required={(datosFormulario.para_comprar === 0 || null && datosFormulario.para_rrhh === 0 || null)}
                                            disabled={datosFormulario.para_rrhh === 1}
                                            labelPlacement="bottom"
                                            label="Interno Compras"
                                            control={
                                                <Checkbox
                                                    name='para_compras'
                                                    onChange={handleChange}

                                                />
                                            }
                                        />
                                    </Grid>

                                    <Grid item xs={2}>
                                        <FormControlLabel
                                            disabled={datosFormulario.para_compras === 1}
                                            required={(datosFormulario.para_comprar === 0 || null && datosFormulario.para_compras === 0 || null)}
                                            labelPlacement="bottom"
                                            label="Interno RRHH"
                                            control={
                                                <Checkbox
                                                    name='para_rrhh'
                                                    onChange={handleChange}

                                                />
                                            }
                                        />
                                    </Grid>
                                </> : null}
                        </Grid>
                        <Box sx={{ flexGrow: 2 }} alignItems="flex">
                            {datosLista && datosLista.map((linea, index) => (
                                <div key={index} style={{ paddingBottom: '12px', paddingLeft: "15px", paddingTop: "8px" }}>
                                    <Stack spacing={1} direction="row" mt={1}>
                                        <FormControlLabel
                                            size="small"
                                            onChange={() => handleCheckChange(index)}
                                            labelPlacement="start"
                                            label="De Stock"
                                            control={<Checkbox checked={isChecked[index]} />}
                                        />
                                        <TextField
                                            focused color="primary" required size="small"
                                            style={{ width: '20ch', backgroundColor: "#ffffff" }}
                                            type="number"
                                            autoComplete='off'
                                            label="Cantidad"
                                            value={linea.cantidad}
                                            onChange={(e) => handleCantidadChange(index, e.target.value)}
                                        />

                                        {isChecked[index] ? (
                                            <Autocomplete
                                                fullWidth
                                                options={articulos}
                                                autoHighlight
                                                getOptionLabel={(option) => (option ? option.nombre || '' : '')}
                                                value={linea.articulo}
                                                onChange={(event, newValue) => {
                                                    if (newValue && newValue.inputValue) {
                                                        handleAgregarArticulo(newValue.inputValue);
                                                        setDialogOpen(true);
                                                    } else {
                                                        handleArticuloChange(index, newValue);
                                                    }
                                                }}
                                                onOpen={() => {
                                                    if (!autocompleteOpen && articulos.length === 0) {
                                                        loadArticles();
                                                    }
                                                    setAutocompleteOpen(true);
                                                }}
                                                onClose={() => setAutocompleteOpen(false)}
                                                loading={autocompleteLoading}
                                                isOptionEqualToValue={(option, value) => option.nombre === value.nombre}
                                                filterOptions={(options, params) => {
                                                    const filtered = filter(options, params);

                                                    if (params.inputValue !== '') {
                                                        filtered.push({
                                                            inputValue: params.inputValue,
                                                            nombre: `Añadir articulo "${params.inputValue}"`,

                                                        });
                                                    }

                                                    return filtered;
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        focused
                                                        color="primary"
                                                        required
                                                        style={style}
                                                        label="Articulo"
                                                        size="small"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {autocompleteLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                                renderOption={(props, option) => (
                                                    <Box
                                                        component="li"
                                                        key={option.nombre}
                                                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                                        {...props}
                                                    >
                                                        {`${option.nombre}`}
                                                    </Box>
                                                )}
                                            />
                                        ) :
                                            <>
                                                <FormControl focused color="primary" margin='dense' required size='small'>
                                                    <InputLabel id="lbl_autorizante">Unidad de medida</InputLabel>
                                                    <Select
                                                        labelId={`unidadMedida-${index}`}
                                                        id={`unidadMedida-${index}`}
                                                        value={linea.id_unidad_medida}
                                                        onChange={(e) => handleMedidaChange(index, e.target.value)}
                                                        fullWidth
                                                        style={{ width: '20ch', backgroundColor: "#ffffff" }}
                                                        name='id_unidad_medida'
                                                        label="Unidad de medida"
                                                    >
                                                        {(medidas ?? []).map(elemento => (
                                                            <MenuItem key={elemento.id} value={elemento.id}>
                                                                {elemento.descripcion}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                <TextField
                                                    focused color="primary" required size="small"
                                                    style={{ width: '60ch', backgroundColor: "#ffffff" }}
                                                    label="Articulo/ Servicio"
                                                    value={linea.material}
                                                    onChange={(e) => handleMaterialChange(index, e.target.value)}
                                                />
                                            </>
                                        }

                                        <Tooltip title="Eliminar linea">
                                            <IconButton
                                                variant="outlined"
                                                disabled={datosLista && index === datosLista.length - 1}
                                                color='error'
                                                onClick={() => deshacerAlmacenar(index)}
                                            >
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                        {index === datosLista.length - 1 && (
                                            <Tooltip title="Agregar linea">
                                                <IconButton
                                                    variant="outloned"
                                                    color="primary"
                                                    onClick={() => { agregarLinea() }}>
                                                    <AddCircleOutline />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </Stack>
                                </div>
                            ))}
                        </Box>

                        <Grid container spacing={1} mt={0.5} direction={{ xs: 'column', sm: 'row' }}>

                            {/*OBSERVACIONES*/}
                            <Grid item xs={12} mb={2}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    name="observaciones"
                                    style={style}
                                    type="text"
                                    inputProps={{ maxLength: 700 }}
                                    focused
                                    label="Observaciones para la orden de compra"
                                    multiline
                                    value={datosFormulario.observaciones}
                                    onChange={almacenarRequerimientos}
                                    disabled={datosFormulario.para_compras === 1 || datosFormulario.para_rrhh === 1}
                                />
                            </Grid>

                            <Grid item xs={10}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    name="comentarios_internos"
                                    style={style}
                                    type="text"
                                    inputProps={{ maxLength: 700 }}
                                    focused
                                    label="Comentarios internos"
                                    multiline
                                    value={datosFormulario.comentarios_internos}
                                    onChange={almacenarRequerimientos}
                                />
                            </Grid>

                            <Grid item xs={2}>
                                <Button onClick={() => setOpenClose(!openclose)} variant="outlined" style={{
                                    borderColor: "#13406e",
                                    backgroundColor: "#ffffff",
                                }}
                                    startIcon={<AttachFile />} >Adjuntar presupuestos</Button>
                            </Grid>

                            <Grid mt={2}
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItemns="center"
                            >
                                <Button
                                    variant="contained"
                                    onClick={handleClose}
                                    color="error">
                                    Volver
                                </Button>

                                <Button
                                    disabled={disBtn}
                                    variant="contained"
                                    type='submit'
                                    // onClick={guardarCabecera}
                                    color="success">
                                    Guardar requerimiento
                                </Button>

                            </Grid>
                        </Grid>
                    </Box>
                </form>
            </Container>

            <AddArticulosDialog
                open={dialogOpen}
                onClose={() => handleCerrarModal()}
                onAgregarArticulo={handleAgregarArticulo}
                actualizar={loadArticles}
            />
            {openclose ? <FileUploadMultiple fileId={datosFormulario.uuid_requerimiento} openclose={true} /> : null}

            {notificacion && notificacion.open ? (
                <Notify
                    mensaje={notificacion.mensaje}
                    open={notificacion.open}
                    color={notificacion.color}
                    handleClose={handleClose}
                    severity={notificacion.severity}
                />
            ) : null}

        </div>
    );
};

//========================================================================
/*----- Component --------------------------------------------------------/
// Developer     : Candela Grosso   
// Creation Date : 29/11/2023
// Version       : 1.0  
// Description   :  Con este componente el usuario es capaz de :
    - Cargar nuevo requerimiento con : lista de materiales y la posibilidad de cargar uno o mas presupuestos
//------Changes --------------------------------------------------------------------- -------------//
// Change Date   :
// Change by     :
// Change description: 
 ----> datosFormulario.fecha_entrega es una instancia válida de Date antes de intentar formatearla con moment. Si no es una fecha válida, se establece el valor del TextField como una cadena vacía (''). Esto debería ayudar a evitar el mensaje de error "Invalid date"
//========================================================================*/