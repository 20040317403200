import React, { useState, useEffect } from "react";
import { Box, Stack, IconButton, Button, Grid, Typography, Container, DialogTitle, TextField, InputAdornment } from "@mui/material";

import AccountCircle from "@mui/icons-material/AccountCircle";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { grey } from "@mui/material/colors";
import ContactsIcon from "@mui/icons-material/Contacts";
import MapIcon from "@mui/icons-material/Map";
import PushPinIcon from "@mui/icons-material/PushPin";

function Fichadireccion(props) {
  const [datosformulario, setDatosformulario] = useState(props.datos);

  //Colores segun activo o inactivo
  const [color, setColor] = useState("primary");
  const [colorborde, setColorborde] = useState("");

  /************************************************************************************************/
  //Defino los colores de la ficha de los datos de la direccion , segun este activa o inactiva
  const definirColores = () => {
    if (datosformulario.activo === 0) {
      setColor("error");
      setColorborde("error.main");
    } else {
      setColor("primary");
      setColorborde("primary.main");
    }
  };

  useEffect(() => {
    definirColores();
  }, []);
  /************************************************************************************************/
  const handleClose = () => {
    props.ficha(false);
    props.tabla(true);
  };
  const style = {
    backgroundColor: "#ffffff",
    width: "100%"
  };

  return (
    <div>
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container backgroundColor={colorborde} p={1.5}>
            <Grid item xs={2}>
              <IconButton onClick={handleClose}>
                <ArrowBackIcon sx={{ color: grey[50] }} />
              </IconButton>
            </Grid>
            <Grid
              item
              xs={8}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h5" color="#ffffff">
                {" "}
                {datosformulario.direccion_entrega}{" "}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box
          backgroundColor="#F4F6F6"
          mt={2}
          p={3}
          border={0.5}
          borderColor={colorborde}
          borderRadius={4}
        >
          {/* <Box
            mx={1}
            mb={1}
            sx={{
              "& > :not(style)": { m: 1 },
              border: 0.5,
              borderRadius: 2,
              textAlign: "center",
              backgroundColor: "#e0e0e0",
              borderColor: "#90a4ae",
            }}
          >
            <h3>{datosformulario.direccion_entrega} </h3>
          </Box> */}

          <Grid container spacing={2} mb={1}>
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                label="Cliente"
                name="razon_social"
                color={color}
                style={style}
                focused
                id="razon_social"
               // variant="filled"
                value={datosformulario.razon_social}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                label="Direccion de entrega"
                name="direccion_entrega"
                color={color}
                focused
                id="direccion_entrega"
                //variant="filled"
                style={style}
                value={datosformulario.direccion_entrega}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOnIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                label="Lugar de entrega"
                name="nombre_direccion"
                color={color}
                focused
                id="nombre_direccion"
                //variant="filled"
                style={style}
                value={datosformulario.nombre_direccion}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOnIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                label="Contacto"
                name="contacto"
                color={color}
                focused
                id="contacto"
                //variant="filled"
                style={style}
                value={datosformulario.contacto}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <ContactsIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                label="Provincia"
                name="provincia"
                color={color}
                focused
                id="provincia"
                style={style}
                //variant="filled"
                value={datosformulario.provincia}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOnIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                label="Localidad"
                name="localidad"
                color={color}
                focused
                style={style}
                id="localidad"
                //variant="filled"
                value={datosformulario.localidad}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOnIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                label="Sucursal"
                name="sucursal"
                color={color}
                focused
                id="sucursal"
                //variant="filled"
                style={style}
                value={datosformulario.sucursal}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOnIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                label="Ruta"
                name="ruta"
                color={color}
                focused
                id="ruta"
                //variant="filled"
                style={style}
                value={datosformulario.nombre}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <MapIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                label="Mails"
                name="mails"
                color={color}
                focused
                id="mails"
                //variant="filled"
                style={style}
                value={datosformulario.mails}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <ContactMailIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                fullWidth
                label="Telefono 1"
                name="telefono1"
                color={color}
                focused
                id="telefono1"
                //variant="filled"
                style={style}
                value={datosformulario.telefono1}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <ContactPhoneIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                fullWidth
                label="Telefono 2 "
                name="telefono2"
                color={color}
                focused
                id="telefono2"
                //variant="filled"
                style={style}
                value={datosformulario.telefono2}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <ContactPhoneIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                fullWidth
                label="Telefono 3"
                name="telefono3"
                color={color}
                focused
                id="telefono3"
                //variant="filled"
                style={style}
                value={datosformulario.telefono3}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <ContactPhoneIcon />
                    </InputAdornment>
                  ),
                }}
              /></Grid>

            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                label="Latitud"
                name="latitud"
                color={color}
                focused
                id="latitud"
                //variant="filled"
                style={style}
                value={datosformulario.latitud}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <PushPinIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                label="Longitud"
                name="longitud"
                color={color}
                focused
                id="longitud"
                //variant="filled"
                style={style}
                value={datosformulario.longitud}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <PushPinIcon />
                    </InputAdornment>
                  ),
                }}
              /></Grid>
          </Grid>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Button
              disabled
              color={color}
              variant="outlined"
              startIcon={<MapIcon />}
            >
              Ver en mapa
            </Button>
          </Stack>
        </Box>
        <br></br>
        <br />
      </Container>
    </div>
  );
}

export default Fichadireccion;
