//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio
// Creation Date : 05/09/2024
// Version : 1
// Description : Tabla de proximas fechas de revision
//------Changes -------------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React from "react";
import { Box, Grid, TextField } from "@mui/material";
import moment from "moment";

function Prox_fechas_revisiones({ datosTanque }) {
  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };
  const size = "small";

  return (
    <div>
      <br></br>
      <br></br>
      <Box
        my={1}
        backgroundColor="#F4F6F6"
        p={2}
        border={0.5}
        borderColor={"#ABB2B9"}
        borderRadius={4}
        maxWidth={600} // Ajusta el tamaño máximo del Box
        margin="auto" // Centra el Box en su contenedor
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              size={size}
              style={style}
              focused
              color="primary"
              label="Última revisión quinquenal"
              variant="outlined"
              value={moment(datosTanque.fecha_ultima).format("DD-MM-YYYY")}
              InputProps={{
                readOnly: true, // Establece el campo como solo lectura
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              size={size}
              style={style}
              focused
              color="primary"
              label="Próxima revisión quinquenal"
              variant="outlined"
              value={moment(datosTanque.prox_ultima).format("DD-MM-YYYY")}
              InputProps={{
                readOnly: true, // Establece el campo como solo lectura
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              size={size}
              style={style}
              focused
              color="primary"
              label="Última revisión de válvulas de seguridad"
              variant="outlined"
              value={moment(datosTanque.fecha_rev_val_seg).format("DD-MM-YYYY")}
              InputProps={{
                readOnly: true, // Establece el campo como solo lectura
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              size={size}
              style={style}
              focused
              color="primary"
              label="Próxima revisión de válvulas de seguridad"
              variant="outlined"
              value={moment(datosTanque.prox_rev_val_seg).format("DD-MM-YYYY")}
              InputProps={{
                readOnly: true, // Establece el campo como solo lectura
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              size={size}
              style={style}
              focused
              color="primary"
              label="Última revisión de válvulas de exceso"
              variant="outlined"
              value={moment(datosTanque.fecha_rev_val_exc).format("DD-MM-YYYY")}
              InputProps={{
                readOnly: true, // Establece el campo como solo lectura
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              size={size}
              style={style}
              focused
              color="primary"
              label="Próxima revisión de válvulas de exceso"
              variant="outlined"
              value={moment(datosTanque.prox_rev_val_exc).format("DD-MM-YYYY")}
              InputProps={{
                readOnly: true, // Establece el campo como solo lectura
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              size={size}
              style={style}
              focused
              color="primary"
              label="Última revisión de mangueras"
              variant="outlined"
              value={moment(datosTanque.fecha_rev_man).format("DD-MM-YYYY")}
              InputProps={{
                readOnly: true, // Establece el campo como solo lectura
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              size={size}
              style={style}
              focused
              color="primary"
              label="Próxima revisión de mangueras"
              variant="outlined"
              value={moment(datosTanque.prox_rev_man).format("DD-MM-YYYY")}
              InputProps={{
                readOnly: true, // Establece el campo como solo lectura
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} mt={2}>
          <TextField
            fullWidth
            size={size}
            style={style}
            focused
            color="primary"
            label="Datos"
            variant="outlined"
            value={datosTanque.datos}
            InputProps={{
              readOnly: true, // Establece el campo como solo lectura
            }}
          />
        </Grid>
      </Box>
    </div>
  );
}

export default Prox_fechas_revisiones;
