//----- Component --------------------------------------------------------/

// Developer : Nicolas Pascucci

// Creation Date : 21/05/2024

// Version : 1

// Description : Editar subitem
//

//------Changes --------------------------------------------------------------------- -------------//

// Change Date :

// Change by :

// Change description:

//

// Change Date :

// Change by :

// Change description:

//

//========================================================================/
import React, { useState, useEffect } from "react";
import { UrlApi } from "../../../services/apirest";
import {
  Container,
  Grid,
  Box,
  TextField,
  IconButton,
  Button,
  InputAdornment,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { grey } from "@mui/material/colors";
import Notify from "../../Notify";
import Alerta from "../../Alerta";
import WifiCalling3Icon from "@mui/icons-material/WifiCalling3";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { logEventos } from "../../../services/services";
import PercentIcon from '@mui/icons-material/Percent';

function EditarSubitemEvaluacion(props) {
    const [datosSubitem, setDatosSubitem] = useState(props.datos);
    const [disBtn, setDisBtn] = useState(false);
    const [openSnack, setOpenSnack] = React.useState(false);
    const [colormensaje, setColorMensaje] = useState("");
    const [mensaje, setMensaje] = useState("");
    const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
    const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
    const [mensajeAlerta, setMensajeAlerta] = useState(""); //mensaje que contendrá el componente "alerta"
    const [finalizar, setFinalizar] = useState(); //finalizar === 1 lo manda como prop el componente "notify" e indica que la transaccion ha finalizado y debe ejecutarse la funcion para cerrar
    const [severityAlerta, setSeverityAlerta] = useState(""); //define severidad de la alerta (success error o warning)
    const [severitySnack, setSeveritySnack] = useState(""); //define severidad del snackbar (notificación)

    useEffect(() => {
        setDatosSubitem({
          ...props.datos,
        });
      }, [props.datos]);
    
      const handleClose = () => {
        return props.editar(false), props.tabla(true), props.actualizar(1);
      };

      const guardarEditar = async (e) => {
        logEventos("Guardar edición del subitem", "Evaluacion Comerciales", "Se guardo la edición del subitem", localStorage.getItem("user"))
        setDisBtn(true);
        e.preventDefault();
        const response = await fetch(
          UrlApi + "/subitems_comerciales/" + datosSubitem.id_subitem,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                descripcion: datosSubitem.descripcion,
                porcentaje: datosSubitem.porcentaje,
                desde:datosSubitem.desde,
                hasta:datosSubitem.hasta,
                usuario: localStorage.getItem("user"),
            }),
          }
        );
        if (response.ok === false) {
          console.log("ERROR EN API");
          const error = response && response.json();
          setError(error);
          setSeverityAlerta("error");
          setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
        } else {
          console.log("Conexión con api OK");
          try {
            //Parseo respuesta de la api a json()
            const json = await response.json();
            //si api me devuelve lo q le puse en message
            if (json.message) {
              //Si ese mensaje es "OK"
              if (json.message === "OK") {
                console.log("Guardado Satisfactoriamente");
                setNotificacion(true);
                setOpenSnack(true);
                setColorMensaje("#D4EFDF");
                setMensaje("Guardado");
                setSeveritySnack("success");
              } else {
                console.log("res en api incorrecto. Revisar que sea 'OK'");
                setNotificacion(true);
                setOpenSnack(true);
                setColorMensaje("#D4EFDF");
                setSeveritySnack("error");
                setMensaje("Registro Guardado. REVISAR RESPUESTA DE API (E_S)");
              }
            } else if (json.sqlMessage) {
              json && setError(json);
              console.log(json);
              setMensajeAlerta(
                "SQL erróneo (" + json.code + " Errno: " + json.errno + " )"
              );
            } else {
              setMensajeAlerta("ERROR DESCONOCIDO.");
            }
          } catch (error) {
            if (error.toString().includes("is not valid JSON")) {
              console.log(
                "Guardado. Advertencia: Actualizar respuesta de api. JSON invalido."
              );
              setNotificacion(true);
              setOpenSnack(true);
              setColorMensaje("#D4EFDF");
              setMensaje("Guardado");
              setSeveritySnack("success");
            } else {
              setMensajeAlerta("ERROR DESCONOCIDO.");
            }
          }
        }
        handleClose();
      };

          //funcion para almacenar
    const almacenar = (e) => {
        setDatosSubitem({
          ...datosSubitem,
          [e.target.name]: e.target.value === "" ? null : e.target.value,
        });
      };
    
      const style = {
        backgroundColor: "#ffffff",
        width: "100%",
      };
    
      const size = "small";

  return (
    <div>
           <br></br>
        <Container>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container backgroundColor="#1F618D" p={1.5}>
              <Grid item xs={2}>
                <IconButton onClick={handleClose}>
                  <ArrowBackIcon sx={{ color: grey[50] }} />
                </IconButton>
              </Grid>

              <Grid
                item
                xs={8}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="h5" color="#ffffff">
                  Editar Parametro
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <form onSubmit={guardarEditar} autoComplete="off">
            <Box
              backgroundColor="#F4F6F6"
              mt={2}
              p={3}
              border={0.5}
              borderColor={"#ABB2B9"}
              borderRadius={4}
            >
               <Grid container justifyContent="center" alignItems="center">
               <Grid item xs={12} sm={10} md={8} lg={6}>
                <TextField
                  fullWidth
                  size={size}
                  margin="normal" 
                  placeholder="..."
                  style={style}
                  focused
                  color="primary"
                  name="descripcion"
                  label="Descripción"
                  variant="outlined"
                  value={datosSubitem.descripcion}
                  inputProps={{
                    maxLength: 60,
                  }}
                  onChange={almacenar}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AssignmentIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={10} md={8} lg={6}>
                <TextField
                  fullWidth
                  size={size}
                  margin="normal" 
                  placeholder="..."
                  style={style}
                  focused
                  color="primary"
                  name="porcentaje"
                  label="Adicional %"
                  variant="outlined"
                  value={datosSubitem.porcentaje}
                  inputProps={{
                    maxLength: 10,
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={almacenar}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PercentIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={10} md={8} lg={6}>
                <TextField
                  fullWidth
                  size={size}
                  margin="normal" 
                  placeholder="..."
                  style={style}
                  focused
                  color="primary"
                  name="desde"
                  label="Desde %"
                  variant="outlined"
                  value={datosSubitem.desde}
                  inputProps={{
                    maxLength: 10,
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={almacenar}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PercentIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={10} md={8} lg={6}>
                <TextField
                  fullWidth
                  size={size}
                  margin="normal" 
                  placeholder="..."
                  style={style}
                  focused
                  color="primary"
                  name="hasta"
                  label="Hasta %"
                  variant="outlined"
                  value={datosSubitem.hasta}
                  inputProps={{
                    maxLength: 10,
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={almacenar}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PercentIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
              <Grid
                container
                justifyContent="space-between"
                alignItems="flex-end"
                mt={2}
              >
                <Button variant="contained" onClick={handleClose} color="error">
                  Volver
                </Button>

                <Button
                  disabled={disBtn}
                  variant="contained"
                  type="submit"
                  color="success"
                >
                  Guardar Edición
                </Button>
              </Grid>
            </Box>
          </form>
        </Container>
        {notificacion ? (
          <Notify
            mensaje={mensaje}
            open={openSnack}
            color={colormensaje}
            finalizar={setFinalizar}
            severity={severitySnack}
          />
        ) : null}
        {/* ALERT --> SE MUESTRA SI HAY UN ERROR EN LA API (NO SE CONECTA O ESTÁ MAL LA RUTA, ETC.) */}
        {error && mensajeAlerta ? (
          <Alerta mensaje={mensajeAlerta} severity={severityAlerta} />
        ) : null}
      </div>
  )
}

export default EditarSubitemEvaluacion