//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio/Nicolas Pascucci
// Creation Date : 13/03/2024
// Version : 1
// Description : Dialog para la confirmacion de los recibidos p/ num de pto venta y remito
//------Changes ----------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Button,
  Dialog,
  Box,
  Grid,
  Stack,
  Snackbar,
  TextField,
  Alert,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import Title from "../Title";
import { logEventos } from "../../services/services";

function Dialog_recibido(props) {
  // para abrir
  const [abrirDialogRecibido, setAbrirDialogRecibido] = useState(
    props.abrirDialogRecibido
  );

  const [datosViaje, setDatosViaje] = useState(props.row);
  const [update, setUpdate] = useState(false);

  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");

  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleClose = () => {
    setAbrirDialogRecibido(!abrirDialogRecibido);
    if (props.onClose) {
      props.onClose();
    }
  };

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  //Funcion para guardar recibido
  const guardarRecibido = async (e) => {
    logEventos("Guardar remito", "Viajes a Sucursales", "Se guardo remito", localStorage.getItem("user"))
    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(UrlApi + "/viajes_abastecimiento/" + datosViaje.id_viaje, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        nro_remito_puntodeventa: datosViaje.nro_remito_puntodeventa,
        nro_remito_numero: datosViaje.nro_remito_numero,
        estado: 233,
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("La confirmación se guardó exitosamente !");
          console.log("Guardado");
          setUpdate(true);
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar la confirmación de recepción");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    handleClose();
  };

  const almacenar = (e) => {
    setDatosViaje({
      ...datosViaje,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <Dialog
        open={abrirDialogRecibido}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
      >
        <Title
          titulo={`Marcar como Recibido`}
          handleClose={() => {
            handleClose();
          }}
        />
        <form onSubmit={guardarRecibido} autoComplete="off">
          <Box
            border={0.5}
            borderColor={grey[300]}
            backgroundColor="#F4F6F6"
            p={2}
            py={2}
            mt={1}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6} mb={2}>
                <TextField
                  style={style}
                  label="N° Pto.Venta"
                  name="nro_remito_puntodeventa"
                  size="small"
                  placeholder="0000"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{
                    maxLength: 5,
                  }}
                  focused
                  required
                  fullWidth
                  value={datosViaje.nro_remito_puntodeventa}
                  onChange={almacenar}
                />
              </Grid>
              <Grid item xs={6} py={1} mb={1}>
                <TextField
                  style={style}
                  label="N° Remito"
                  name="nro_remito_numero"
                  size="small"
                  placeholder="00000123"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{
                    maxLength: 8,
                  }}
                  focused
                  required
                  fullWidth
                  value={datosViaje.nro_remito_numero}
                  onChange={almacenar}
                />
              </Grid>
            </Grid>

            <Stack direction="row">
              <Grid container direction={{ xs: "column", sm: "row" }}>
                <Grid item xs={9}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleClose}
                  >
                    No
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="success"
                    type="submit"
                  >
                    Si
                  </Button>
                </Grid>
              </Grid>
            </Stack>
          </Box>
        </form>
      </Dialog>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
}

export default Dialog_recibido;
