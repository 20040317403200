//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio/Nicolas Pascucci
// Creation Date : 25/04/2024
// Version : 1
// Description : es el cartel de auditoria al auditar x item
//------Changes --------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Dialog,
  TextField,
  Button,
  Autocomplete,
  Box,
} from "@mui/material";
import Notify from "../../Notify";
import { logEventos } from "../../../services/services";
import { UrlApi } from "../../../services/apirest";
import moment from "moment";

function ModalCheckAud(props) {
  const { observacion, sucursal, resultados } = props;
  const [disabledGuardar, setDisabledGuardar] = useState(false);
  //---------------- NOTIFICACION -----------------//
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
  const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)

  //console.log("OBSERVACION", observacion);
  //console.log("SUCURSAL", sucursal.nombre);
 // console.log("SUCURSAL ID", sucursal.id);
 // console.log("SUCURSAL ID2", sucursal.sucursal_id);
  // console.log("RESULTADOS DESDE PROPS: ", resultados.id_check);

  //--------------------------------------------------------------------//
  const [responsable, setResponsable] = useState();
  const [responsableSeleccionado, setResponsableSeleccionado] = useState("");

  const obtenerResponsable = () => {
    fetch(UrlApi + "/checklist/userXsuc/" + sucursal.sucursal_id)
      .then((data) => data.json())
      .then((data) => setResponsable(data));
  };
  useEffect(() => {
    obtenerResponsable();
  }, [sucursal]);

 // console.log("RESPONSABLE SELECCIONADO", responsableSeleccionado);
 // console.log("RESPONSABLE", responsable);

  //------------- FECHA ACTUAL ---------------------//
  // OBTENER FECHA ACTUAL
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);

  // Obtener la fecha actual y sumarle un día
  const minFecha = moment(date).add(1, "days").format("YYYY-MM-DD");

  //console.log("VENCIMIENTO", minFecha);

  //----------------------- DATOS DE AUDITORIA --------------------------------------//

  const [datosAuditoriaHallazgos, setDatosAuditoriaHallazgos] = useState({
    auditor: localStorage.getItem("user"),
    fecha_registro: date,
    fecha_auditoria: date,
    id_sucursal: sucursal.id,
    problema_sugerencia: "Según control de depósito",
    descripcion_acciones: observacion,
    responsable: responsableSeleccionado,
    vencimiento: minFecha,
    estado: 178,
  });

  // ------------------ESTILO -------------------- //
  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  const size = "small";
  // -------------- GUARDAR ------------------//
  const almacenar = (e) => {
    setDatosAuditoriaHallazgos({
      ...datosAuditoriaHallazgos,
      [e.target.name]: e.target.value,
    });
  };

  const guardarAuditoria = async (e) => {
    logEventos(
      "Guardar pase a auditoría",
      "Resultados de Control",
      "Se paso a auditoria",
      localStorage.getItem("user")
    );
    e.preventDefault();
    const response = await fetch(UrlApi + "/auditorias", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        auditor: localStorage.getItem("user"),
        fecha_registro: datosAuditoriaHallazgos.fecha_registro,
        fecha_auditoria: datosAuditoriaHallazgos.fecha_auditoria,
        id_sucursal: sucursal.sucursal_id,
        responsable: responsableSeleccionado.id,
        problema_sugerencia: datosAuditoriaHallazgos.problema_sugerencia,
        descripcion_acciones: datosAuditoriaHallazgos.descripcion_acciones,
        vencimiento: datosAuditoriaHallazgos.vencimiento,
        estado: datosAuditoriaHallazgos.estado,
      }),
    });
    if (response.ok === false) {
      const error = response && response.json();
      setError(error);
      alert("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      try {
        const json = await response.json();
        if (json.message) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje(
            json.message === "OK" ? "Guardado" : "Guardado. Actualizar RTA Api."
          );
          await fetch(UrlApi + "/checklist/" + resultados.id_check, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              auditado: 1,
            }),
          })
            .then((res) => {
              if (!res.err) {
                setOpenSnack(true);
                setMensaje("La auditoria se guardó exitosamente !");
              } else {
                setOpenSnack(false);
                setMensaje("Error al guardar la auditoria");
              }
            })
            .catch((err) => {
              console.log(err.message);
            });
        } else if (json.sqlMessage) {
          json && setError(json);
          alert("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
        } else {
          alert("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje("Guardado");
        } else {
          alert("Error: " + error);
        }
      }
    }
  };
  //-----------------------------------------//
  const handleClose = () => {
    props.open(false);
    props.obtenerResultadosDespuesAuditar();
  };

  useEffect(() => {
    if (responsableSeleccionado.length === 0) {
      setDisabledGuardar(true);
    } else {
      setDisabledGuardar(false);
    }
  }, [responsableSeleccionado]);

  return (
    <div>
      <Dialog
        open={() => props.open(true)}
        onClose={() => props.open(false)}
        fullWidth
      >
        <Grid
          container
          spacing={1}
          direction="center"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          backgroundColor="#1F618D"
          pb={1}
          mt={1}
        >
          <Grid item xs={10}>
            <div style={{ textAlign: "center" }}>
              <Typography
                variant="h5"
                component="h2"
                style={{ color: "#ffffff" }}
              >
                Pasar a auditoría
              </Typography>
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          direction="center"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          // ml={3}
        >
          <Grid item xs={6} md={5} mt={3}>
            <TextField
              fullWidth
              size={size}
              focused
              color="primary"
              name="id_sucursal"
              label="Sucursal"
              variant="outlined"
              value={sucursal.nombre}
              inputProps={{
                readOnly: true,
              }}
              onChange={almacenar}
            />
          </Grid>
          <Grid item xs={6} md={5} mt={3}>
            <TextField
              fullWidth
              size={size}
              focused
              color="primary"
              name="auditor"
              label="Auditor"
              variant="outlined"
              value={localStorage.getItem("user")}
              inputProps={{
                readOnly: true,
              }}
              onChange={almacenar}
            />
          </Grid>
          <Grid item xs={12} md={10}>
            <TextField
              fullWidth
              size={size}
              focused
              color="primary"
              name="observacion"
              label="Observaciones"
              variant="outlined"
              multiline
              rows={5}
              value={observacion}
              inputProps={{
                readOnly: true,
              }}
              onChange={almacenar}
            />
          </Grid>
          <Grid item xs={6} md={10}>
            <TextField
              label="Problema / Sugerencia"
              fullWidth
              focused
              size={size}
              name="problema_sugerencia"
              value={datosAuditoriaHallazgos.problema_sugerencia}
              inputProps={{
                readOnly: true,
              }}
              onChange={almacenar}
            />
          </Grid>
          <Grid item xs={6} md={10}>
            {responsable && ( // Verifica si responsable tiene valores asignados
              <Autocomplete
                disabled={!responsable}
                disableClearable
                size="small"
                required
                noOptionsText={"Responsable INEXISTENTE"}
                options={responsable}
                autoHighlight
                getOptionLabel={(users) => users.username}
                onChange={(event, value) => setResponsableSeleccionado(value)}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    key={option.id}
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.username}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                    }}
                    focused
                    size="small"
                    placeholder="Tipee para buscar..."
                    label="Responsable"
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={6} md={5}>
            <TextField
              fullWidth
              required
              focused
              size="small"
              type="date"
              placeholder="Fecha de Auditoria"
              name="fecha_auditoria"
              label="Fecha de Auditoria"
              variant="outlined"
              value={moment(datosAuditoriaHallazgos.fecha_auditoria).format(
                "YYYY-MM-DD"
              )}
              onChange={almacenar}
              inputProps={{
                max: date,
              }}
            />
          </Grid>
          <Grid item xs={6} md={5}>
            <TextField
              fullWidth
              required
              focused
              size="small"
              type="date"
              placeholder="Vencimiento"
              name="vencimiento"
              label="Vencimiento"
              variant="outlined"
              value={moment(datosAuditoriaHallazgos.vencimiento).format(
                "YYYY-MM-DD"
              )}
              inputProps={{ min: minFecha }}
              onChange={almacenar}
            />
          </Grid>
        </Grid>

        <Grid container direction={{ xs: "column", sm: "row" }}>
          <Grid item xs={3} mt={3} ml={3} mb={4}>
            <Button
              fullWidth
              variant="contained"
              color="error"
              onClick={() => props.open(false)}
            >
              Volver
            </Button>
          </Grid>
          <Grid item xs={3} mt={3} ml={31} mb={4}>
            <Button
              disabled={disabledGuardar}
              fullWidth
              variant="contained"
              color="primary"
              onClick={guardarAuditoria}
            >
              Enviar
            </Button>
          </Grid>
        </Grid>

        {notificacion ? (
          <Notify
            mensaje={mensaje}
            open={openSnack}
            color={"#D4EFDF"}
            severity={"success"}
            handleClose={handleClose}
          />
        ) : null}
      </Dialog>
    </div>
  );
}

export default ModalCheckAud;
