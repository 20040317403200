import React, { useState, useEffect, useMemo } from "react";
import { Grid, Box, MenuItem, ListItemIcon, Container, List, Typography } from '@mui/material';
import { UrlApi } from '../../../../services/apirest';
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import EventNoteIcon from '@mui/icons-material/EventNote';
import DeleteIcon from '@mui/icons-material/Delete';
import Modaldatospedidos from './Modaldatospedidos'
import Modalquitarpedido from './Modalquitarpedido';
import WhereToVoteTwoToneIcon from "@mui/icons-material/WhereToVoteTwoTone";
import { number } from "prop-types";
import DialogEntregado from "../../../referencias-tablas/pedidos/DialogEntregado";
import { Edit, Preview } from "@mui/icons-material";
import DialogConTextField from "../../../Dialogs/DialogConTextField";
import { putData } from "../../../../services/library";
import Notify from "../../../Notify";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

export default function VerDatosRep(props) {
    const [datos, setDatos] = useState(props.datos);
    const [pedidos, setPedidos] = useState();
    const [openPedidos, setopenPedidos] = useState(false);
    const [eliminarPedido, seteliminarPedidos] = useState(false)
    const [datosPedidos, setDatosPedidos] = useState({});
    const [openObservaciones, setopenObservaciones] = useState(false);
    const [observaciones, setObs] = useState("");
    const iconRemove = <RemoveCircleOutlineIcon sx={{ fontSize: 40 }} />;
    const [notificacion, setNotificacion] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
    });

    const obtenerPedidos = () => {
        fetch(UrlApi + '/pedidos/reparto/' + datos.nro_reparto)
            .then(data => data.json())
            .then(data => setPedidos(data))
    };

    // console.log("pedidos", pedidos);
    

    useEffect(() => {
        obtenerPedidos()
    }, [props.sucursalSeleccionada, props.setsucursalSeleccionada]);

    const openEditar = (datos) => {
        setopenPedidos(true)
        setDatosPedidos(datos)
    };

    const openModalEliminar = (datos) => {
        seteliminarPedidos(true)
        setDatosPedidos(datos)
    };

    const columns = useMemo(() =>
        [{ header: '   #', accessorKey: 'id', size: '50', enableEditing: false, },
        // { header: 'Sisven ID', accessorKey: 'sisven_id',  enableEditing: false, },
        {
            header: 'Razón Social',
            size: '200',
            accessorFn: (c) =>
                `(${c?.sisven_id ?? ""}) ${c.razon_social} (${c.empresa})`, enableEditing: false
        },
        { header: 'CUIT/DNI', accessorKey: 'cuit', enableEditing: false ,size: '110'},
        { header: 'Sitación iva', accessorKey: 'condicion_iva', enableEditing: false },
        { header: 'Dirección de entrega', accessorKey: 'direccion_entrega', enableEditing: false },
        { header: 'Localidad', accessorKey: 'localidad', enableEditing: false ,size: '150'},
        { header: 'Kg a cargar', accessorKey: 'kgacargar', enableEditing: false,size: '110' },
        { header: 'Estado', accessorKey: 'estado', enableEditing: false ,size: '110'},
        { header: 'Orden', accessorKey: 'orden_prioridad', type: "number", size: '50', enableEditing: (row) => row.original.id_estado === 23 },

        ], []);

    const tableHeadCellProps = {
        align: "left",
        sx: {
            fontSize: 14,
            color: "black",
            fontWeight: "normal",
            paddingTop: 1,
            paddingBottom: 1,
            backgroundColor: "#D4E6F1",
            borderColor: "#FDFEFE",
        },
    };

    const rowBg = {
        19: "#FFFFFF",
        20: "#FEF5E7", //revisado -> natural
        21: "#ABEBC6", //aprobado -> verde clarito
        22: "#DC7633", //rechazado -> rojo
        23: "#85C1E9", //asignado -> celestito
        41: "#DC7633", //entregado
        25: "#D7BDE2", //Facturado -> violeta
        32: "#EC7063", //CANCELADO -> BORDÓ
        // 40: "#FFFF80", //pendiente -> amarillo
    };

    const editarOrden = async ({ exitEditingMode, row, values }) => {
        let response = await fetch(UrlApi + '/pedidos/' + row.original.id, {
            method: 'PUT',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                orden_prioridad: values.orden_prioridad
            })
        })
        obtenerPedidos()
        exitEditingMode()
    };

    const saveObs = (e) => {
        putData(e,
            `/pedidos/${datosPedidos.id}`,
            {
                observaciones: observaciones
            },
            setNotificacion,
            setObs("")
        )
    };

    const handleClose = () => {
        setopenObservaciones(false)
        obtenerPedidos()

    };

    const initialState = {
        // density: "compact",
        showGlobalFilter: true,
        pagination: { pageIndex: 0, pageSize: 10 }
    };


    return (
        <div >
            {pedidos &&
                <Box boxShadow={3} sx={{  margin:"auto"}}>
                    <MaterialReactTable
                        editingMode="row"
                        // enableFacetedValues
                        enableColumnResizing
                        columns={columns}
                        initialState={initialState}
                        data={pedidos}
                        layoutMode="semantic"
                        enableEditing={row => row.id_estado !== 23}
                        enableRowActions
                        positionActionsColumn='last'
                        muiTableHeadCellProps={tableHeadCellProps}
                        localization={MRT_Localization_ES}
                        muiTableBodyCellProps={({ row }) => ({
                            align: "left",
                            sx: {
                                backgroundColor:
                                    rowBg[row.original.id_estado] ?? "#fff",
                                color: "black",
                                borderColor: "black",
                            },
                        })}
                        enableColumnActions={false}
                        renderRowActionMenuItems={({ closeMenu, row }) =>
                            [
                                <MenuItem
                                    disabled={row.original.id_estado !== 23}
                                    key={1}
                                    onClick={() => {
                                        openModalEliminar(row.original)
                                        closeMenu();
                                    }}
                                    sx={{ m: 0 }}
                                >
                                    <ListItemIcon>
                                        <DeleteIcon color="error" />
                                    </ListItemIcon>
                                    Quitar pedido del reparto
                                </MenuItem>,
                                <MenuItem
                                    // disabled={row.original.id_estado !== 23}
                                    key={2}
                                    onClick={() => {
                                        setDatosPedidos(row.original)
                                        setopenObservaciones(true);
                                        closeMenu();
                                    }}
                                    sx={{ m: 0 }}
                                >
                                    <ListItemIcon>
                                        <Preview color="secondary" />
                                    </ListItemIcon>
                                    Observaciones
                                </MenuItem>

                            ]


                            // <MenuItem
                            // key={2}
                            // onClick></MenuItem>   
                            // row.original.estado_reparto !== 6 || (row.original.id_estado === 40 || row.original.id_estado === 41 || row.original.id_estado === 23) ? (
                            //     <MenuItem
                            //         key={0}
                            //         onClick={() => {
                            //             openEditar(row.original)
                            //             closeMenu();
                            //         }}
                            //         sx={{ m: 0 }}
                            //     >
                            //         <ListItemIcon>
                            //             <WhereToVoteTwoToneIcon color="success" />
                            //         </ListItemIcon>
                            //          Registrar Visita
                            //     </MenuItem>
                            // ) : null
                            // ,


                        }
                        enableColumnFilters={false}
                        enableColumnDragging={false}
                        enableHiding={false}
                        enableTopToolbar={false}
                        enablePagination={false}
                        enableBottomToolbar={false}
                        onEditingRowSave={editarOrden}
                    />
                </Box>
            }

            {openObservaciones ?
                <DialogConTextField
                    title={`Observaciones`}
                    open={openObservaciones}
                    setOpen={setopenObservaciones}
                    notify={notificacion}
                    setNotify={setNotificacion}
                    btnText={"Guardar"}
                    guardar={saveObs}
                    color={"#e89d25"}
                    subtitle={<Typography>
                        {datosPedidos.observaciones ? (
                            <>
                                <strong>{datosPedidos.observaciones}</strong>
                                <br />
                                En caso de ser necesario puede ingresar nuevas
                            </>
                        ) : (
                            "No hay observaciones. Puede guardar alguna en caso de ser necesario"
                        )}
                    </Typography>}
                    value={observaciones}
                    setValue={setObs}
                    icon={iconRemove}
                /> : null}

            {/* {openPedidos ? (
                <Modaldatospedidos actualizar={obtenerPedidos} datos={datosPedidos} open={setopenPedidos} />
            ) : null} */}

            {
                openPedidos ? (
                    <DialogEntregado
                        pedido={datosPedidos}
                        open={openPedidos}
                        setOpen={setopenPedidos}
                        actualizar={obtenerPedidos}
                    />
                ) : null
            }
            {eliminarPedido ? (
                <Modalquitarpedido actualizar={obtenerPedidos} datos={datosPedidos} open={seteliminarPedidos} />
            ) : null}


            {notificacion && notificacion.open ? (
                <Notify
                    mensaje={notificacion.mensaje}
                    open={notificacion.open}
                    color={notificacion.color}
                    handleClose={handleClose}
                    severity={notificacion.severity}
                />
            ) : null}

        </div>
    )
}

