import Navbar from "../../../Navbar";
import React, { useState, useEffect, useMemo } from "react";
import { UrlApi } from "../../../../services/apirest";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Tooltip,
  TextField,
  Stack,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //idioma
import { createTheme, ThemeProvider, useTheme } from "@mui/material";

// ICONOS
import AddBoxIcon from "@mui/icons-material/AddBox";
import BlockIcon from "@mui/icons-material/Block";
import AssignmentIcon from "@mui/icons-material/Assignment";
import NotesIcon from "@mui/icons-material/Notes";
import EditIcon from "@mui/icons-material/Edit";

//Notification
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ProductosPropelentes() {
  const [productosPropelentes, setProductosPropelentes] = useState();
  const [tabla, setTabla] = useState(true);
  const [update, setUpdate] = useState();
  const [datosFormulario, setDatosFormulario] = useState({});

  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");

  const obtenerProductosPropelentes = () => {
    fetch(UrlApi + "/propelente_productos") //fetch con la urp de api q esta en services/apirest + la ruta
      .then((data) => data.json()) //mando los datos que recibo en json
      .then((data) => setProductosPropelentes(data)); //seteo clientes
  };

  useEffect(() => {
    obtenerProductosPropelentes();
  }, [tabla]);

  // FUNCION QUE SE EJECUTA CADA VEZ QUE HAY UN CAMBIO EN LA BASE DE DATOS
  // SIRVE PARA QUE LA TABLA MOSTRADA SE ACTUALICE EN TIEMPO REAL
  useEffect(() => {
    if (update) obtenerProductosPropelentes();
  }, [update]);

  //**********************************************************************************************/

  // NOTIFICACION
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
    setTabla(true);
    setUpdate(false);
    setOpenDialogOnOff(false);
    setOpenCrear(false);
    setOpenEditar(false);
  };

  //*************************************************************************************************************************************/

  // DIALOG PARA CREAR LOS DATOS
  const [openCrear, setOpenCrear] = React.useState(false);

  const handleClickOpenCrear = () => {
    setOpenCrear(true);
    // AL ABRIR EL DIALOG LOS TEXTFIELDS SE VACIAN
    datosFormulario.nombre = "";
    datosFormulario.descripcion = "";
    datosFormulario.porcentaje_propano = "";
    datosFormulario.porcentaje_butano = "";
    datosFormulario.estado = 1;
  };

  const handleCloseCrear = () => {
    setOpenCrear(false);
  };

  //*********************************************************************************************/

  // DIALOG PARA EDITAR LOS DATOS
  const [openEditar, setOpenEditar] = React.useState(false);

  // USANDO ROW TOMO LOS DATOS DE ESA FILA
  const handleClickOpenEditar = (row) => {
    setOpenEditar(true);
    setDatosFormulario(row);
  };

  const handleCloseEditar = (row) => {
    setOpenEditar(false);
    setDatosFormulario(row);
  };

  //**********************************************************************************************/

  // DIALOG PARA ACTIVAR/DESACTIVAR EL PRODUCTO
  const [openDialogOnOff, setOpenDialogOnOff] = React.useState(false);

  const handleCloseDialogOnOff = () => {
    setOpenDialogOnOff(!openDialogOnOff);
  };

  const dialogActivar = (row) => {
    setDatosFormulario(row);
    setOpenDialogOnOff(true);
    setTabla(true);
  };

  //**********************************************************************************************/ F

  //Funcion para guardar AL CREAR
  const guardarCrear = async (e) => {
    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(UrlApi + "/propelente_productos", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        nombre: datosFormulario.nombre,
        descripcion: datosFormulario.descripcion,
        porcentaje_propano: datosFormulario.porcentaje_propano,
        porcentaje_butano: datosFormulario.porcentaje_butano,
        estado: datosFormulario.estado,
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          setUpdate(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El producto se guardó exitosamente !");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el producto");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  console.log(datosFormulario);

  //**********************************************************************************************/

  //Funcion para guardar AL EDITAR
  const guardarEditar = async (e) => {
    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(
      UrlApi + "/propelente_productos/" + datosFormulario.idproducto,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          nombre: datosFormulario.nombre,
          descripcion: datosFormulario.descripcion,
          porcentaje_propano: datosFormulario.porcentaje_propano,
          porcentaje_butano: datosFormulario.porcentaje_butano,
        }),
      }
    )
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          setUpdate(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El producto se guardó exitosamente!");
          //console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el producto");
          //console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    //console.log(datosFormulario);
  };

  //**********************************************************************************************/
  // FUNCIONES PARA GUARDAR:
  // ACTIVAR
  const guardarActivo = async (e) => {
    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(
      UrlApi + "/propelente_productos/" + datosFormulario.idproducto,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          estado: 1,
        }),
      }
    )
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          setUpdate(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El producto se guardó exitosamente!");
          //console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el producto");
          //console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  // DESACTIVAR
  const guardarInactivo = async (e) => {
    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(
      UrlApi + "/propelente_productos/" + datosFormulario.idproducto,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          estado: 2,
        }),
      }
    )
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          setUpdate(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El producto se guardó exitosamente!");
          //console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el producto");
          //console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const almacenar = (e) => {
    setDatosFormulario({
      ...datosFormulario,
      [e.target.name]: e.target.value,
    });
  };

  //**********************************************************************************************/

  const columns = [
    {
      header: "ID",
      accessorKey: "idproducto",
      minSize: 30,
      size: 50,
      maxSize: 100,
    },
    {
      header: "Nombre",
      accessorKey: "nombre",
      minSize: 50,
      size: 50,
      maxSize: 100,
    },
    {
      header: "Descripcion",
      accessorKey: "descripcion",
      minSize: 50,
      size: 80,
      maxSize: 100,
    },
    // {
    //   header: "% de Propano",
    //   accessorKey: "porcentaje_propano",
    // },
    // {
    //   header: "% de Butano",
    //   accessorKey: "porcentaje_butano",
    // },
    {
      header: "Estado",
      accessorKey: "valor",
    },
  ];

  // ESTILO PARA LA TABLA
  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)" //random light yellow color for the background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //override to make tooltip font size larger
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  return (
    <>
      <Navbar />
      <br />
      <Container maxWidth="xl" style={{ marginBottom: "30px" }}>
        {productosPropelentes && tabla ? (
          <ThemeProvider theme={tableTheme}>
            <MaterialReactTable
              columns={columns}
              data={productosPropelentes}
              initialState={{
                showGlobalFilter: true,
                density: "compact",
              }}
              localization={MRT_Localization_ES} //idioma ES
              // COLORES PARA LAS CELDAS SEGUN ACTIVO/INACTIVO
              muiTableBodyCellProps={({ row }) => ({
                sx: {
                  backgroundColor:
                    row.original.estado === 1 ? "#66FF66" : "#FF5050",
                  color: "black",
                  borderColor: "black",
                  //COLOR AL PASAR EL MOUSE POR ENCIMA
                  "&:hover": {
                    backgroundColor:
                      row.original.estado === 1 ? "#3ad63a" : "#f22929",
                  },
                  "& .MuiTableCell-root:first-child": {
                    border: "none",
                  },
                },
              })}
              // TITULO
              renderTopToolbarCustomActions={({ table }) => (
                <Grid item mt={-2.5}>
                  <h2>PRODUCTOS PARA PROPELENTES</h2>
                </Grid>
              )}
              // PANEL DE DETALLES / FICHA
              renderDetailPanel={({ row }) => (
                <>
                  <Container maxWidth="xl">
                    <br />
                    <DialogTitle
                      style={{
                        color: "white",
                        backgroundColor: "#4abced",
                        height: "30px",
                        marginTop: "-20px",
                      }}
                    >
                      PRODUCTO: {row.original.nombre}
                    </DialogTitle>
                    <br />
                    <Box border={2} px={2} pt={3} borderRadius={4}>
                      <Grid container maxWidth="xl" spacing={2}>
                        <Grid item xs={3} py={1} mb={2}>
                          <TextField
                            fullWidth
                            placeholder="No hay registro"
                            label="ID"
                            name="idproducto"
                            focused
                            variant="filled"
                            value={row.original.idproducto}
                            InputProps={{
                              readOnly: true,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <AssignmentIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} py={1} mb={3}>
                          <TextField
                            fullWidth
                            placeholder="No hay registro"
                            label="DESCRIPCION"
                            name="descripcion"
                            focused
                            variant="filled"
                            value={row.original.descripcion}
                            InputProps={{
                              readOnly: true,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <NotesIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} py={1} mb={3}>
                          <TextField
                            fullWidth
                            placeholder="No hay registro"
                            label="Propano"
                            name="Porcentaje de Propano"
                            focused
                            variant="filled"
                            value={row.original.porcentaje_propano}
                            InputProps={{
                              readOnly: true,
                              startAdornment: (
                                <InputAdornment position="start">
                                  %
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} py={1} mb={3}>
                          <TextField
                            fullWidth
                            placeholder="No hay registro"
                            label="Butano"
                            name="Porcentaje de Butano"
                            focused
                            variant="filled"
                            value={row.original.porcentaje_butano}
                            InputProps={{
                              readOnly: true,
                              startAdornment: (
                                <InputAdornment position="start">
                                  %
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Container>
                </>
              )}
              // ACCIONES
              renderToolbarInternalActions={({ table }) => (
                <>
                  <Tooltip arrow placement="bottom" title="Agregar Producto">
                    <IconButton color="primary">
                      <AddBoxIcon
                        onClick={() => {
                          handleClickOpenCrear();
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <MRT_FullScreenToggleButton table={table} />
                </>
              )}
              enableRowActions
              renderRowActions={({ row }) => (
                <>
                  <Tooltip arrow placement="left" title="Editar Producto">
                    {/* <IconButton onClick={() => editarDatos(row.original)}> */}
                    <IconButton
                      onClick={() => handleClickOpenEditar(row.original)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip
                    arrow
                    placement="left"
                    title={
                      row.original.estado === 1
                        ? "Desactivar Producto"
                        : "Activar Producto"
                    }
                  >
                    <IconButton onClick={() => dialogActivar(row.original)}>
                      <BlockIcon
                        sx={{
                          color:
                            row.original.estado === 1 ? "red" : "lightgreen",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </>
              )}
              positionActionsColumn="last"
              // TRUE
              enableTopToolbar
              enablePinning
              // FALSE
              enableColumnFilters={false} //opcion de filtrado por columnas
              enableDensityToggle={false} //para que no se vea pero esta activo en el estado
              enableHiding={false} //oculta la propiedad show hide columns
            />
          </ThemeProvider>
        ) : null}

        {/* AGREGAR */}
        <Dialog
          open={openCrear}
          onClose={handleCloseCrear}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">
            {"AGREGAR PRODUCTO"}
          </DialogTitle>
          <DialogContent>
            <Container maxWidth="xl">
              <form onSubmit={guardarCrear}>
                <Grid
                  container
                  spacing={1}
                  mt={1}
                  direction={{ xs: "column", sm: "row" }}
                >
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      focused
                      id="nombre"
                      label="Nombre"
                      name="nombre"
                      placeholder="IGP, C4..."
                      value={datosFormulario.nombre}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <TextField
                      fullWidth
                      focused
                      id="descripcion"
                      label="Descripcion"
                      name="descripcion"
                      placeholder="Propano, Butano, Propelente..."
                      onChange={almacenar}
                      value={datosFormulario.descripcion}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={1}
                  mt={1}
                  direction={{ xs: "column", sm: "row" }}
                >
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      focused
                      id="propano"
                      label="% Propano"
                      placeholder="-"
                      name="porcentaje_propano"
                      value={datosFormulario.porcentaje_propano}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      focused
                      id="butano"
                      label="% Butano"
                      placeholder="-"
                      name="porcentaje_butano"
                      value={datosFormulario.porcentaje_butano}
                      onChange={almacenar}
                    />
                  </Grid>
                </Grid>

                <Stack spacing={2} mt={4} direction="row">
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleCloseCrear}
                  >
                    Cancelar
                  </Button>
                  <Button variant="contained" type="submit" color="success">
                    Guardar
                  </Button>
                </Stack>

                <Stack spacing={2} sx={{ width: "100%" }}>
                  <Snackbar
                    open={openSnack}
                    autoHideDuration={1500}
                    onClose={handleCloseSnack}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  >
                    <Alert
                      onClose={handleCloseSnack}
                      severity="success"
                      sx={{ width: "100%" }}
                      style={{ backgroundColor: colormensaje, color: "black" }}
                    >
                      {mensaje}
                    </Alert>
                  </Snackbar>
                </Stack>
              </form>
            </Container>
          </DialogContent>
        </Dialog>

        {/* {editar ? (
          <EditarProductosPropelentes
            datosFormulario={datosFormulario}
            editar={setEditar}
            tabla={setTabla}
          />
        ) : null} */}

        {/* EDITAR METODO */}
        <Dialog
          open={openEditar}
          onClose={handleCloseEditar}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <Box
            style={{
              borderWidth: 2,
              borderStyle: "solid",
              borderColor: "#1976D2",
            }}
          >
            <DialogTitle id="alert-dialog-title">
              {"EDITAR PRODUCTO"}
            </DialogTitle>
            <DialogContent>
              <Container maxWidth="xl">
                <form onSubmit={guardarEditar}>
                  <Grid
                    container
                    spacing={1}
                    mt={1}
                    direction={{ xs: "column", sm: "row" }}
                  >
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        focused
                        name="nombre"
                        label="Nombre"
                        InputLabelProps={{ shrink: true }}
                        value={datosFormulario.nombre}
                        onChange={almacenar}
                      />
                    </Grid>

                    <Grid item xs={12} mt={1}>
                      <TextField
                        fullWidth
                        focused
                        name="descripcion"
                        id=""
                        label="Descripcion"
                        InputLabelProps={{ shrink: true }}
                        value={datosFormulario.descripcion}
                        onChange={almacenar}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={1}
                    mt={1}
                    direction={{ xs: "column", sm: "row" }}
                  >
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        focused
                        name="porcentaje_propano"
                        label="% Propano"
                        InputLabelProps={{ shrink: true }}
                        value={datosFormulario.porcentaje_propano}
                        onChange={almacenar}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        focused
                        name="porcentaje_butano"
                        id=""
                        label="% Butano"
                        InputLabelProps={{ shrink: true }}
                        value={datosFormulario.porcentaje_butano}
                        onChange={almacenar}
                      />
                    </Grid>
                  </Grid>

                  <Stack spacing={2} mt={4} direction="row">
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleCloseEditar}
                    >
                      Cancelar
                    </Button>

                    <Button variant="contained" color="primary" type="submit">
                      Guardar
                    </Button>
                  </Stack>

                  <Stack spacing={2} sx={{ width: "100%" }}>
                    <Snackbar
                      open={openSnack}
                      autoHideDuration={1500}
                      onClose={handleCloseSnack}
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    >
                      <Alert
                        onClose={handleCloseSnack}
                        severity="success"
                        sx={{ width: "100%" }}
                        style={{
                          backgroundColor: colormensaje,
                          color: "black",
                        }}
                      >
                        {mensaje}
                      </Alert>
                    </Snackbar>
                  </Stack>
                </form>
              </Container>
            </DialogContent>
          </Box>
        </Dialog>
      </Container>

      {/* DIALOG PARA BLOQUEAR/DESBLOQUEAR EL PRODUCTO */}
      <Dialog open={openDialogOnOff} onClose={handleCloseDialogOnOff}>
        <Box
          style={{
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: datosFormulario.estado === 1 ? "#e32712" : "#24ff1c",
            color: datosFormulario.estado === 1 ? "red" : "green",
          }}
        >
          <DialogTitle>
            <Typography align="center">
              <b>
                SE {datosFormulario.estado === 1 ? "DESACTIVARÁ" : "ACTIVARÁ"}{" "}
                EL PRODUCTO:
              </b>
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Grid item textAlign={"center"} mb={4}>
                <br />
                <b>{datosFormulario.nombre}</b>
              </Grid>
            </DialogContentText>
            <DialogContentText textAlign={"center"}>
              {"¿Confirma que quiere " +
                (datosFormulario.estado === 1 ? "desactivar" : "activar") +
                " este producto?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Stack spacing={4} direction="row">
              <Grid item mr={15}>
                <Button
                  style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
                  variant="contained"
                  onClick={handleCloseDialogOnOff}
                >
                  Cancelar
                </Button>
              </Grid>
              {datosFormulario.estado === 1 ? (
                <Button
                  style={{ backgroundColor: "#fc7a23", color: "#ffffff" }}
                  onClick={guardarInactivo}
                  variant="contained"
                >
                  Desactivar
                </Button>
              ) : (
                <Button
                  style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                  onClick={guardarActivo}
                  variant="contained"
                >
                  Activar
                </Button>
              )}
            </Stack>
          </DialogActions>
        </Box>
      </Dialog>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1000}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}

export default ProductosPropelentes;