import React, { useState, useEffect, useMemo } from 'react';
import { getData, putData } from '../../../services/library';
import { UrlApi } from '../../../services/apirest';
import {
  Box, Grid, Typography, IconButton, Dialog, DialogContent, DialogActions, Button
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { grey } from '@mui/material/colors';
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import Notify from '../../Notify';
import { logEventos } from '../../../services/services';

export default function ListaMaterialesPanel(props) {
  const [datosReq, setDatosReq] = useState(props.datos);
  const [listaMateriales, setlistaMateriales] = useState([]);
  const globalTheme = useTheme();
  const [notificacion, setNotificacion] = useState({
    open: false,
    mensaje: "",
    severity: "",
    color: "",
  });

  const obtenerListaMaterial = () => {
    getData('/lista_materiales/req/' + datosReq.uuid_requerimiento, setlistaMateriales)
  };

  useEffect(() => {
    obtenerListaMaterial()
  }, []);

  const handleClose = (e) => {
    actualizarEstado(e)
    props.open(false)
  };

  async function actualizarEstado(e) {
    const everyUuid = listaMateriales && listaMateriales.every((material) => material.uuid_ordencompra);
    if (everyUuid) {
      await putData(e,
        '/requerimientos_materiales/' + datosReq.idrequerimiento,
        {
          estado: 58,
          uuid_ordencompra: props.uuid
        },
        setNotificacion
      )
    }
    putData(e,
      '/uploadfiles/' + datosReq.uuid_requerimiento,
      {
        uuid_ordencompra: props.uuid
      },
      setNotificacion
    )
  }

  const guardarTodo = async ({ exitEditingMode, row, values }) => {
    let subtotal;

    if (values.bonificacion_pesos !== null && values.bonificacion_pesos !== undefined && values.bonificacion_pesos !== '') {
      // Calcular el subtotal con bonificación en pesos
      subtotal = (values.cantidad_actualizada * values.precio_unitario) - values.bonificacion_pesos;
      // console.log("subtotal1", subtotal);
    } else if (values.bonificacion_porcentaje !== null && values.bonificacion_porcentaje !== undefined && values.bonificacion_porcentaje !== "") {
      // Calcular el subtotal con bonificación en porcentaje
      subtotal = (values.precio_unitario * values.cantidad_actualizada) * (1 - values.bonificacion_porcentaje / 100);
      // console.log("subtotal2", subtotal);
    } else {
      // Calcular el subtotal sin bonificación
      subtotal = values.precio_unitario * values.cantidad_actualizada;
      // console.log("subtotal3", subtotal);
    }
    // console.log("valies",)
    await fetch(UrlApi + '/lista_materiales/lista/' + row.original.idlista, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        precio_unitario: values.precio_unitario,
        cantidad_actualizada: values.cantidad_actualizada,
        subtotal: subtotal,
        bonificacion_pesos: values.bonificacion_pesos ? values.bonificacion_pesos : null,
        bonificacion_porcentaje: values.bonificacion_porcentaje ? values.bonificacion_porcentaje : null,
        uuid_ordencompra: props.uuid,
        usuario: localStorage.getItem('user')
      })
    });
    obtenerListaMaterial()
    props.actualizar()
    props.obtenerReq()
    exitEditingMode();
    logEventos("Editar lista material", "Agregar a orden de compra ", "Generar ordenes", localStorage.getItem("user"))
  };


  const columns = useMemo(() =>
    [{ header: 'Requerido', accessorKey: 'cantidad', enableEditing: false, size: 50 },
    { header: 'A Comprar', accessorKey: 'cantidad_actualizada', required: false, type: 'numeric', size: 70 },
    { header: 'Articulo', accessorFn: (lm) => lm.nombre ?? lm.material, enableEditing: false, },
    { header: 'Unidad', accessorFn: (lm) => lm.descripcion ?? lm.descripcion_material, enableEditing: false, size: 40 },
    { header: '$ Unitario', accessorKey: 'precio_unitario', type: 'double', size: 40, required: true },
    { header: 'Bonif %', accessorKey: 'bonificacion_porcentaje', type: 'double', required: false, size: 40 },
    { header: 'Bonif $', accessorKey: 'bonificacion_pesos', type: 'double', required: false, size: 40 },
    { header: 'Subtotal', accessorKey: 'subtotal', type: 'double', enableEditing: false, size: 40 },

    ], []);

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //larger tooltip font size                                
              },
            },
          },
        },

      }), [globalTheme]);

  const tableHeadCellProps = {
    align: "center",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      // paddingTop: 1,
      // paddingBottom: 1,
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };

  const initialState = {
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 15 }
  };

  return (
    <Dialog open={props.open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullScreen
    >
      <>
        <DialogContent>
          <Box
            width="100%"
            backgroundColor="#CCD1D1"
            alignItems="center"
            justifyContent="center"
          >

            {listaMateriales &&
              <ThemeProvider theme={tableTheme}>
                <MaterialReactTable
                  enableColumnResizing
                  columns={columns}
                  data={listaMateriales}
                  initialState={initialState}
                  positionActionsColumn="last"
                  enableExpandAll={false}
                  enableToolbarInternalActions={true}
                  muiTableHeadCellProps={tableHeadCellProps}
                  muiTableBodyCellProps={{
                    align: "center",
                    sx: {
                      backgroundColor: "#fff",
                      color: "black",
                      borderColor: "black"
                    }
                  }}
                  enableColumnActions={false}
                  enableDensityToggle={false}
                  renderTopToolbarCustomActions={() => (
                    <>
                      <Grid item xs={2}>
                        <IconButton onClick={handleClose}>
                          <ArrowBackIcon sx={{ color: grey[800] }} />
                        </IconButton>
                      </Grid>

                      <Typography variant="poster" fontSize={20} textAlign={"center"}>
                        LISTADO DE ARTICULOS
                      </Typography>
                      {/* <br />
                      <Typography fontSize={12} textAlign={"center"}>
                        (Los materiales actualizados serán agregados a la orden de compra)
                      </Typography> */}
                    </>
                  )}
                  layoutMode="grid"
                  localization={MRT_Localization_ES}
                  enableColumnDragging={false}
                  enableColumnFilters={false}
                  enableHiding={false}
                  editingMode="row"
                  enableEditing
                  positionExpandColumn='first'
                  enableStickyFooter
                  onEditingRowSave={guardarTodo}
                />
              </ThemeProvider>
              // </Box>
            }
          </Box>
          {notificacion && notificacion.open ? (
            <Notify
              mensaje={notificacion.mensaje}
              open={notificacion.open}
              color={notificacion.color}
              handleClose={handleClose}
              severity={notificacion.severity}
            />
          ) : null}
        </DialogContent>
        {/* <DialogActions>
        <Grid container direction="row" justifyContent="space-between" alignItemns="center">
          <Button variant="contained" style={{ backgroundColor: "#ff3333", color: '#FFF8F8', marginRight: "5%" }} onClick={handleClose}>
            Volver
          </Button>
          <Button variant="contained" disabled={disbtn} style={{ backgroundColor: "#288d1f", color: "#FFF8F8" }} type="submit" onClick={guardarListaMaterial} >
            Guardar
          </Button>
        </Grid>
      </DialogActions> */}
      </>
    </Dialog>
  )

}
