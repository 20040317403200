import React, { useEffect, useState } from 'react'
import { UrlApi } from '../../../services/apirest';
import { MenuItem, IconButton, Button, Grid, InputLabel, Typography, Box, InputAdornment, Snackbar, DialogTitle, TextField, Stack, Select, FormControl, Container } from '@mui/material';
import TimelineIcon from '@mui/icons-material/Timeline';
import { grey } from '@mui/material/colors';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AssignmentIcon from '@mui/icons-material/Assignment';
import moment from 'moment';
import Notify from '../../Notify';
import FileUploadMultiple from '../../../services/UploadFiles';
import AttachFileIcon from '@mui/icons-material/AttachFile';

function Editarcontratos(props) {
    const [datosFormularios, setDatosFormularios] = useState(props.datos);
    const [clientes, setClientes] = useState(null);
    const [factibilidades, setFactibilidades] = useState(null);
    const [desplegablesEstado, setDesplegablesEstado] = useState(null) //ACTIVO INACTIVO TERMINADO
    const [desplegablesModalidadC, setDesplegablesModalidadC] = useState(null) //COMODATO, TANTQUE PROPIEDAD DEL CLIENTE : MODALIDAD
    const [productos, setProductos] = useState(null);
    const [disBtn, setDisBtn] = useState(false);
    const [disClientes, setDisclientes] = useState(true);
    const [notificacion, setNotificacion] = useState(false);
    const [openSnack, setOpenSnack] = React.useState(false);
    const [mensaje, setMensaje] = useState("");
    const [error, setError] = useState("");
    const [openclose, setOpenClose] = useState(false);
    console.log("datosf", datosFormularios.uuid_contrato)
    //CONSULTAS A LA API-----------------------------------------------------

    const obtenerClientes = async () => {
        const data = await fetch(UrlApi + "/clientes")
        const clientesData = await data.json()
        setClientes(clientesData)
    };

    const obtenerFactibilidades = async () => {
        const data = await fetch(UrlApi + "/fact")
        const factData = await data.json()
        setFactibilidades(factData)
        console.log(factData)
    };

    //obtener desplegable: tipo de contrato : MODALIDAD
    const obtenerDesplegablesModalidadC = async () => {
        const data = await fetch(UrlApi + "/desplegables/modalidadcontratos")
        const modalidadContratoData = await data.json()
        setDesplegablesModalidadC(modalidadContratoData)
    };

    //const obtener desplegable: estado contrato
    const obtenerDesplegableEstado = async () => {
        const data = await fetch(UrlApi + "/desplegables/estadocontratos")
        const estadoContratoData = await data.json()
        setDesplegablesEstado(estadoContratoData)
    };

    //const para obtener los productos
    const obtenerProductos = async () => {
        const data = await fetch(UrlApi + "/productos")
        const productosData = await data.json()
        setProductos(productosData)
    };
    //FIN CONSULTAS ---------------------------------------------------------

    useEffect(() => {
        obtenerClientes()
        obtenerFactibilidades()
        obtenerDesplegablesModalidadC()
        obtenerDesplegableEstado()
        obtenerProductos()
    }, []);

    const guardarContratos = async (e) => {
        setDisBtn(true);
        e.preventDefault();
        const response = await fetch(UrlApi + "/contratos/" + datosFormularios.id, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    nro_contrato: datosFormularios.nro_contrato,
                    consumo_kilos: datosFormularios.consumo_kilos,
                    consumo_litros: datosFormularios.consumo_litros,
                    id_factibilidad: datosFormularios.id_factibilidad,
                    id_modalidad: datosFormularios.id_modalidad,
                    id_estado: datosFormularios.id_estado,
                    fecha_inicio: datosFormularios.fecha_inicio,
                    vencimiento_contrato: datosFormularios.vencimiento_contrato,
                    id_cliente: datosFormularios.id_cliente,
                    id_producto: datosFormularios.id_producto,
                    usuario: localStorage.getItem('user')
                })
        })
        if (response.ok === false) {
            const error = response && response.json();
            setError(error);
            alert("Inconvenientes con la API. No se pudo guardar. ");
        } else {
            try {
                const json = await response.json();
                if (json.message) {
                    setNotificacion(true);
                    setOpenSnack(true);
                    setMensaje(
                        json.message === "OK" ? "Guardado" : "Guardado. Actualizar RTA Api."
                    );
                    // obtenerFabricantes()
                } else if (json.sqlMessage) {
                    json && setError(json);
                    alert("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
                } else {
                    alert("ERROR DESCONOCIDO.");
                }
            } catch (error) {
                if (error.toString().includes("is not valid JSON")) {
                    setNotificacion(true);
                    setOpenSnack(true);
                    setMensaje("Guardado");
                    // obtenerFabricantes()
                } else {
                    alert("Error: " + error);
                }
            }
        }
        handleClose()
    }

    const almacenar = (e) => {
        setDatosFormularios({ ...datosFormularios, [e.target.name]: e.target.value })
    };

    const handleClose = () => {
        props.tabla(true)
        props.editar(false)
    };

    const style = {
        backgroundColor: "#ffffff",
        width: "100%"
    };

    return (
        <div>
            <Container>
                <Box sx={{ flexGrow: 1 }} mt={2}>
                    <Grid container backgroundColor="#1F618D" p={1.5}>
                        <Grid item xs={2}>
                            <IconButton onClick={handleClose}>
                                <ArrowBackIcon sx={{ color: grey[50] }} />
                            </IconButton>
                        </Grid>

                        <Grid
                            item
                            xs={8}
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography variant="h5" color="#ffffff">
                                Editar contrato
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <form onSubmit={guardarContratos} autoComplete='off'>
                    <Box
                        backgroundColor="#F4F6F6"
                        mt={2}
                        p={3}
                        border={0.5}
                        borderColor={"#ABB2B9"}
                        borderRadius={4}
                    >
                        <Grid container spacing={1} style={{ padding: 10 }} direction={{ xs: 'column', sm: 'row' }}>
                            <Grid item xs={12}>
                                <FormControl style={style} focused color="primary" required margin="dense" disabled={disClientes}>
                                    <InputLabel id="lbl_clientes">Cliente</InputLabel>
                                    <Select
                                        name='id_cliente'
                                        margin="dense"
                                        value={datosFormularios.id_cliente}
                                        onChange={almacenar}
                                        label="Clientes">
                                        {clientes ? clientes.map(elemento => (
                                            <MenuItem key={elemento.id} value={elemento.id}>
                                                {elemento.razon_social}
                                            </MenuItem>
                                        )) : null}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={4} >
                                <TextField placeholder='Número contrato'
                                    required
                                    focused
                                    margin="dense"
                                    style={style}
                                    name="nro_contrato"
                                    label="Número contrato"
                                    color="primary"
                                    type="number"
                                    value={datosFormularios.nro_contrato}
                                    onChange={almacenar}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AssignmentIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField placeholder='Consumo en kilos'
                                    required
                                    type="number"
                                    focused
                                    margin="dense"
                                    style={style}
                                    name="consumo_kilos"
                                    label="Consumo en kilos"
                                    color="primary"
                                    value={datosFormularios.consumo_kilos}
                                    onChange={almacenar}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <TimelineIcon />
                                            </InputAdornment>
                                        )
                                    }} />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField placeholder='Consumo en litros'
                                    margin="dense"
                                    focused
                                    style={style}
                                    name="consumo_litros"
                                    type="number"
                                    label="Consumo en litros"
                                    color="primary"
                                    value={datosFormularios.consumo_litros}
                                    onChange={almacenar}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <TimelineIcon />
                                            </InputAdornment>
                                        )
                                    }} />
                            </Grid>

                            <Grid item xs={4}>
                                <FormControl style={style} focused color="primary" margin="dense">
                                    <InputLabel id="lbl_fact">Factibilidad</InputLabel>
                                    <Select
                                        name='id_factibilidad'
                                        value={datosFormularios.id_factibilidad}
                                        onChange={almacenar}
                                        label="Factibilidad">
                                        {factibilidades ? factibilidades.map(elemento => (
                                            <MenuItem key={elemento.idfactibilidad} value={elemento.idfactibilidad}>
                                                {elemento.idfactibilidad}
                                            </MenuItem>
                                        )) : null}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl style={style} focused color="primary" required margin="dense">
                                    <InputLabel id="lbl_modalidad">Modalidad</InputLabel>
                                    <Select
                                        margin="dense"
                                        name='id_modalidad'
                                        value={datosFormularios.id_modalidad}
                                        onChange={almacenar}
                                        label="Modalidad">
                                        {desplegablesModalidadC ? desplegablesModalidadC.map(elemento => (
                                            <MenuItem key={elemento.id} value={elemento.id}>
                                                {elemento.valor}
                                            </MenuItem>
                                        )) : null}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl style={style} focused color="primary" margin="dense" required>
                                    <InputLabel id="lbl_estado">Estado</InputLabel>
                                    <Select margin="dense"
                                        required
                                        name='id_estado'
                                        value={datosFormularios.id_estado}
                                        onChange={almacenar}
                                        label="Estado">
                                        {desplegablesEstado ? desplegablesEstado.map(elemento => (
                                            <MenuItem key={elemento.id} value={elemento.id}>
                                                {elemento.valor}
                                            </MenuItem>
                                        )) : null}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl style={style} focused color="primary" required margin="dense">
                                    <InputLabel id="lbl_producto">Producto</InputLabel>
                                    <Select
                                        name='id_producto'
                                        value={datosFormularios.id_producto}
                                        onChange={almacenar}
                                        label="Producto">
                                        {productos ? productos.map(elemento => (
                                            <MenuItem key={elemento.idproducto} value={elemento.idproducto}>
                                                {elemento.nombre}
                                            </MenuItem>
                                        )) : null}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    style={style}
                                    color="primary"
                                    focused
                                    required
                                    label="Fecha inicio"
                                    margin="dense"
                                    name='fecha_inicio'
                                    type="date"
                                    variant="outlined"
                                    rows={2}
                                    value={moment(datosFormularios.fecha_inicio).format("YYYY-MM-DD")}
                                    onChange={almacenar} />

                            </Grid>
                            <Grid item xs={4}>
                                <TextField style={style}
                                    label="Vencimiento contrato"
                                    color="primary"
                                    focused
                                    required
                                    margin="dense"
                                    name='vencimiento_contrato'
                                    type="date"
                                    variant="outlined"
                                    rows={2}
                                    value={moment(datosFormularios.vencimiento_contrato).format("YYYY-MM-DD")}
                                    onChange={almacenar} />

                            </Grid>

                        </Grid>
                        <Grid mt={2} container direction="row" justifyContent="space-between" alignItemns="center">
                            <Button variant="contained" onClick={handleClose} color="error">Volver</Button>
                            <Button onClick={() => setOpenClose(!openclose)} variant="outlined" size="small" startIcon={<AttachFileIcon />} >Adjuntar contrato</Button>
                            <Button disabled={disBtn} variant="contained" type="submit" color="success" >Guardar Contrato</Button>
                        </Grid>
                    </Box>
                </form>
            </Container>
            {openclose ? (
                <FileUploadMultiple
                    openclose={true}
                    fileId={datosFormularios.uuid_contrato}
                />
            ) : null}
            {
                notificacion ? (
                    <Notify
                        mensaje={mensaje}
                        open={openSnack}
                        color={"#D4EFDF"}
                        severity={"success"}
                        handleClose={handleClose}
                    />
                ) : null
            }
        </div>
    )
}

export default Editarcontratos