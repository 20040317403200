//----- Component --------------------------------------------------------/

// Developer : Nicolas Pascucci

// Creation Date : 21/05/2024

// Version : 1

// Description : tabla de items en evaluacion 
//

//------Changes --------------------------------------------------------------------- -------------//

// Change Date :

// Change by :

// Change description:

//

// Change Date :

// Change by :

// Change description:

//

//========================================================================/
import React, { useEffect, useState, useMemo } from "react";
import { UrlApi } from "../../../services/apirest";
import {
  Container,
  Typography,
  Tooltip,
  IconButton,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  DialogTitle,
} from "@mui/material";
import Navbar from "../../Navbar";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import BlockIcon from "@mui/icons-material/Block";
import EditIcon from "@mui/icons-material/Edit";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Notify from "../../Notify";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import ContentPasteOffIcon from "@mui/icons-material/ContentPasteOff";
import { logEventos } from "../../../services/services";
import AddItemEvaluacion from "./AddItemEvaluacion";
import EditarItemEvaluacion from "./EditarItemEvaluacion";
import PostAddIcon from '@mui/icons-material/PostAdd';
import Subitems_comerciales from "./Subitems_comerciales";

function Items_comerciales() {
  const [items, setItems] = useState(); //tabla
  const [datosItems, setDatosItems] = useState({});
  const [alta, setAlta] = useState(false); //Estado q mostrará o no la pantalla de alta
  const [tabla, setTabla] = useState(true);
  const [editar, setEditar] = useState(false); //Estado q mostrará o no el form de edicion de choferes
  const [confirmar, setConfirmar] = useState(false);
  const globalTheme = useTheme();
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
  const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
  const [hiddenmuestrainactivo, setHiddenmuestrainactivo] = useState(false);
  const [hiddenmuestraactivos, setHiddenmuestraactivos] = useState(true);
  const [obt, setObt] = useState(0);
  const [verSubitems, setVerSubitems] = useState(false)

  const obtenerItems = () => {
    setHiddenmuestraactivos(true);
    setHiddenmuestrainactivo(false);
    fetch(UrlApi + "/items_comerciales/activos")
      .then((data) => data.json())
      .then((data) => setItems(data));
  };

  const obtenerinactivos = () => {
    setHiddenmuestrainactivo(true);
    setHiddenmuestraactivos(false);
    fetch(UrlApi + "/items_comerciales/inactivos")
      .then((data) => data.json())
      .then((data) => setItems(data));
  };

    // console.log("items",items);
  //ejecuta la funcion de obtener
  useEffect(() => {
    obtenerItems();
  }, [tabla, obt, confirmar]);

  const abrirModalConfirmar = (datosDeLaLinea) => {
    setConfirmar(true);
    setDatosItems(datosDeLaLinea);
  };
  const handleVolver = () => {
    setConfirmar(false);
    setTabla(true);
  };
  const handleClickNuevo = () => {
    setAlta(true);
    setTabla(false);
  };
  const handleClickEditar = (row) => {
    setDatosItems(row)
    setEditar(true);
    setTabla(false);
  };
  const handleVerSubitems = (row) => {
    setDatosItems(row)
    setVerSubitems(true);
    setTabla(false);
  };

  const marcarInactivo = async (e) => {
    // logEventos("Guardar motivo de emergencia inactivo", "Motivos Reclamos", "Se guardo el motivo de emergencia inactivo", localStorage.getItem("user"))
    e.preventDefault();
    const response = await fetch(
      UrlApi + "/items_comerciales/" + datosItems.id_item,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(
          datosItems.activo === 0
            ? { activo: 1 }
            : { activo: 0 }
        ),
      }
    );
    if (response.ok === false) {
      const error = response && response.json();
      setError(error);
      alert("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      try {
        const json = await response.json();
        if (json.message) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje(
            json.message === "OK" ? "Guardado" : "Guardado. Actualizar RTA Api."
          );
          obtenerItems();
        } else if (json.sqlMessage) {
          json && setError(json);
          alert("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
        } else {
          alert("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje("Guardado");
          obtenerItems();
        } else {
          alert("Error: " + error);
        }
      }
    }
    handleVolver();
    obtenerItems();
  };
// *********************************************************** Tabla ********************************************************************** //
const tableTheme = useMemo(
  () =>
    createTheme({
      palette: {
        mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
        primary: globalTheme.palette.primary, //primary color for the table
        info: {
          main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
        },
        background: {
          default:
            globalTheme.palette.mode === "light"
              ? "rgb(234, 239, 241)" //background in light mode
              : "#000", //pure black table in dark mode for fun
        },
      },
      typography: {
        button: {
          textTransform: "none", //customize typography styles for all buttons in table by default
          fontSize: "1rem",
        },
      },
      components: {
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              fontSize: "0.8rem", //override to make tooltip font size larger
            },
          },
        },
      },
    }),
  [globalTheme]
);

const muiSearchTextFieldProps = {
  placeholder: "Buscar..",
  sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
  variant: "outlined",
  color: "success",
  size: "small",
};
const initialState = {
  //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
  density: "compact",
  showGlobalFilter: true,
  pagination: { pageIndex: 0, pageSize: 10 },
  columnVisibility: {
    activo:false
  }
};
const tableHeadCellProps = {
  align: "left",
  sx: {
    fontSize: 16,
    color: "white",
    fontWeight: "normal",
    backgroundColor: "#2471A3",
    borderColor: "black",
  },
};

  const columns = useMemo(
    // TABLA Objetivos
    () => [
      { header: "id_item", accessorKey: "id_item", size: 70, enableHiding: true },
      {
        header: "Descripcion",
        accessorKey: "descripcion",
        size: 160,
      },
      {
        header: "Peso %",
        accessorKey: "peso",
        size: 100,
      },
      {
        header: "activo",
        accessorKey: "activo",
        size: 70,
        enableHiding: true,
      },
    ],
    []
  );

  useEffect(() => {
    logEventos("Tabla de items de evaluacion", "Evaluacion Comerciales", "Se visualizan los items en evaluacion", localStorage.getItem("user"))
}, [])

  return (
    <div>
    <Navbar />
          <Container maxWidth="xl" mt={1} mb={2}>
            {items && tabla ? (
              <Box mt={2} boxShadow={3}>
                <ThemeProvider theme={tableTheme}>
                  <MaterialReactTable
                    enableColumnResizing
                    columns={columns}
                    data={items}
                    initialState={initialState}
                    positionActionsColumn="last"
                    enableExpandAll={false}
                    enableToolbarInternalActions={true}
                    muiTableHeadCellProps={tableHeadCellProps}
                    muiTableBodyCellProps={({ row }) => ({
                      align: "left",
                      sx: {
                        // backgroundColor:
                        //   row.original.activo === 0 ? "#efc3c3" : "#b3e6b3",
                        color: "black",
                        borderColor: "black",
                      },
                    })}
                    enableColumnActions={false}
                    muiSearchTextFieldProps={muiSearchTextFieldProps}
                    enableDensityToggle={false}
                    renderTopToolbarCustomActions={() => (
                      <>
                        {/* <Button
                          variant="contained"
                          color="primary"
                          startIcon={<AddBoxTwoToneIcon />}
                          onClick={handleClickNuevo}
                        >
                          Nuevo Item
                        </Button> */}
                        <Typography variant="poster" fontSize={26}>
                          <AssignmentIcon sx={{ size: 20 }} />
                          Items de Evaluacion de Comerciales
                        </Typography>
                      </>
                    )}
                    layoutMode="grid"
                    localization={MRT_Localization_ES}
                    enableColumnDragging={false} //No permite que se arrastren las columnas.
                    enableEditing
                    // ACCIONES
                    renderToolbarInternalActions={(
                      { table } // boton para nuevo , al lado del buscar
                    ) => (
                      <>
                        {/* {!hiddenmuestraactivos ? (
                          <Tooltip title="Ver items activos">
                            <IconButton
                              color="success"
                              onClick={() => {
                                obtenerItems();
                              }}
                            >
                              <ContentPasteIcon />
                            </IconButton>
                          </Tooltip>
                        ) : null}
                        {!hiddenmuestrainactivo ? (
                          <Tooltip title="Ver items inactivos">
                            <IconButton
                              color="error"
                              onClick={() => {
                                obtenerinactivos();
                              }}
                            >
                              <ContentPasteOffIcon />
                            </IconButton>
                          </Tooltip>
                        ) : null} */}
                        <MRT_FullScreenToggleButton table={table} />
                      </>
                    )}
                    renderRowActions={(
                      { row } //las acciones del costado editar y ver
                    ) => (
                      <div>
                        <Tooltip arrow placement="left" title="Editar Item">
                          <IconButton
                            onClick={() => handleClickEditar(row.original)}
                            color="primary"
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip> 
                        {/* {row.original.activo === 0 ? (
                          <Tooltip title="Marcar como activo">
                            <IconButton
                              color="success"
                              onClick={() => abrirModalConfirmar(row.original)}
                            >
                              <CheckCircleOutlineIcon />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Marcar como inactivo">
                            <IconButton
                              onClick={() => abrirModalConfirmar(row.original)}
                              color="error"
                            >
                              <BlockIcon />
                            </IconButton>
                          </Tooltip>
                        )} */}
                        <Tooltip arrow placement="left" title="Ver Subitems">
                          <IconButton
                            onClick={() => handleVerSubitems(row.original)}
                            color="primary"
                          >
                            <PostAddIcon />
                          </IconButton>
                        </Tooltip> 
                      </div>
                    )}
                  />
                </ThemeProvider>
                <br />
                <br />
              </Box>
            ) : null}
    
            {confirmar ? (
              <div>
                <Dialog open={confirmar} onClose={handleVolver}>
                  <form onSubmit={marcarInactivo}>
                    <Box backgroundColor={"#1F618D"}>
                      <DialogTitle>
                        {datosItems.activo === 0 ? (
                          <Typography variant="h6" align="center" color="#ffffff">
                            Activar {datosItems.descripcion}
                          </Typography>
                        ) : (
                          <Typography variant="h6" align="center" color="#ffffff">
                            Desactivar {datosItems.descripcion}
                          </Typography>
                        )}
                      </DialogTitle>
                    </Box>
                    <DialogContent>
                      <DialogContentText textAlign={"center"}>
                        {datosItems.activo === 0 ? (
                          <> ¿Desea activar el item ? </>
                        ) : (
                          <> ¿Desea desactivar el item ? </>
                        )}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItemns="center"
                      >
                        <Button
                          variant="contained"
                          onClick={handleVolver}
                          style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
                        >
                          Cancelar
                        </Button>
                        <Button
                          style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Confirmar
                        </Button>
                      </Grid>
                    </DialogActions>
                  </form>
                </Dialog>
              </div>
            ) : null}
            {notificacion ? (
              <Notify
                mensaje={mensaje}
                open={openSnack}
                color={"#D4EFDF"}
                severity={"success"}
                handleClose={handleVolver}
              />
            ) : null}
    
            {alta ? <AddItemEvaluacion alta={setAlta} tabla={setTabla}></AddItemEvaluacion> : null}
            {editar ? <EditarItemEvaluacion editar={setEditar} tabla={setTabla} datos={datosItems} actualizar={setObt}></EditarItemEvaluacion> : null}
            {verSubitems?<Subitems_comerciales verSubitems={setVerSubitems} tabla={setTabla} datos={datosItems} actualizar={setObt}></Subitems_comerciales>: null}
          </Container>   
    </div>
  )
}

export default Items_comerciales