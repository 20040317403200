import { Container, Typography, Snackbar, SnackbarContent, IconButton, Drawer, Divider } from '@mui/material'
import React, { useState, useEffect, useMemo } from 'react'
import { UrlApi } from '../../../services/apirest';
import Navbar from '../../Navbar'
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { PersonAdd } from '@mui/icons-material';
import DatosDelUsuario from './DatosDelUsuario';

function GestionUsuarios() {
  const [users, setUsers] = useState()
  const [userSelected, setUserSelected] = useState({})
  const [nuevo, setNuevo] = useState(false)
  const [tabla, setTabla] = useState(true)
  const [snack, setSnack] = useState(false)
  const [mensaje, setMensaje] = useState("Ups!!")
  const [colormensaje, setColorMensaje] = useState("black")

  ///drawer derecho
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    obtenerUsuarios()
  }, [tabla, snack])

  const obtenerUsuarios = async () => {
    const data = await fetch(UrlApi + "/userdata/")
    const userData = await data.json()
    setUsers(userData)
  }

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnack(false);
  };

  //// Drawer derecho
  const handleDrawerToggle = () => {
    obtenerUsuarios()
    setNuevo(false)
    setDrawerOpen(!drawerOpen);
  };

  const handleClickNuevo = () => {
    setNuevo(true)
    setDrawerOpen(!drawerOpen)
  }

  const columnas = useMemo(
    () => [
      {
        accessorKey: 'username',
        header: 'Usuario',
        size: 0,
      },
      {
        accessorKey: 'nombrepila',
        header: 'Nombre',
        size: 0,
      },
      {
        accessorKey: 'apellido',
        header: 'Apellido',
        size: 0,
      },
      {
        accessorKey: 'email',
        header: 'E-mail',
        size: 0,
      },
      {
        accessorKey: 'sucursal',
        header: 'Sucursal',
      },
    ],
    [],
  );

  return (
    <div>
      <Navbar />
      <Container sx={{ marginTop: '30px' }} >
        {localStorage.getItem("role") === "superusuario" && tabla ?
          <MaterialReactTable
            data={users ?? []} //fallback to array if data is undefined
            state={{ showProgressBars: users ? false : true }} //or showSkeletons
            columns={columnas}
            localization={MRT_Localization_ES}
            enableDensityToggle={false}
            enableColumnFilters={false}
            enableTableFooter={false}
            enableHiding={false}
            enableColumnActions={false}
            enableStickyHeader
            muiTableContainerProps={{ sx: { maxHeight: '500px' } }}
            enablePagination={false}
            enableBottomToolbar={false}

            initialState={{
              density: 'compact',
            }}

            memoMode="cells"

            muiTableBodyCellProps={({ row }) => ({
              sx: { color: row.original.bloqueado === 'NO' ? "black" : "red" },
            })}
            muiTableHeadCellProps={({ }) => ({ sx: { color: 'white', backgroundColor: 'teal' } })}

            muiTableBodyRowProps={({ row }) => ({
              onClick: (event) => {
                setUserSelected(row.original)
                handleDrawerToggle();
              },
              sx: {
                cursor: 'pointer', //you might want to change the cursor too when adding an onClick
              },
            })}


            renderTopToolbarCustomActions={() => (
              <>
                <IconButton
                  variant="outlined"
                  size="small"
                  color="secondary"
                  title="Nuevo"
                  onClick={() => handleClickNuevo()}
                  disableRipple={true}
                >
                  <PersonAdd />
                </IconButton>
                <Typography variant="h5" sx={{ display: 'flex', justifyContent: "end" }}>Usuarios del sistema</Typography>
              </>
            )}

          /> : <></>}
      </Container>


      <Snackbar
        open={snack}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
        message={mensaje}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <SnackbarContent
          style={{ backgroundColor: colormensaje, color: 'white', fontWeight: 'bold', }}
          message={mensaje}
        />
      </Snackbar>

      <Drawer anchor="right"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        PaperProps={{
          style: { width: 450, backgroundColor: 'lightgoldenrodyellow' },
        }}
        transitionDuration={{ enter: 600, exit: 300 }} // Ajusta la duración de la transición
      >
        <Container sx={{ marginTop: '60px' }}>
          <DatosDelUsuario datos={nuevo ? "nuevo" : userSelected} handleDrawerToggle={handleDrawerToggle} />
          <Divider />
        </Container>
      </Drawer>

    </div>
  )
}

export default GestionUsuarios
