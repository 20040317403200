//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio
// Creation Date : 05/08/2024
// Version : 1
// Description : Pantalla principal de estadisticas de desconexion mobile
//------Changes ----------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React from "react";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@mui/material";
import Navbar from "../../../Navbar";
import Tabla_estados_desconexion_mobile from "./Tabla_estados_desconexion_mobile";
import Grafico_estados_desc_mobile from "./Grafico_estados_desc_mobile";
import Tabla_instaladores_desc_mobile from "./Tabla_instaladores_desc_mobile";


function Estadisticas_desconexion_mobile() {
  return (
    <div>
      <Navbar />
      <Box
        bgcolor="#2B76A6"
        color="white"
        marginBottom="1em"
        borderRadius="0.5em"
        marginTop={1}
        width="90%" // Ajusta esto según el ancho deseado
        mx="auto" // Centrar horizontalmente
      >
        <Typography variant="h6" component="div" sx={{ marginLeft: "1em" }}>
          Panel de estadísticas de reclamos con desconexión
        </Typography>
      </Box>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Tabla_estados_desconexion_mobile />
        </Grid>
        <Grid item xs={12}>
          <Grafico_estados_desc_mobile />
        </Grid>
        <Grid item xs={12}>
          <Tabla_instaladores_desc_mobile />
        </Grid>
      </Grid>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
}

export default Estadisticas_desconexion_mobile;
