import React, { useEffect, useState, useMemo } from 'react';
import { Grid, Box, InputLabel, Button, Dialog, DialogContent, DialogActions, DialogContentText } from '@mui/material';
import moment from 'moment' // con esto manejamos las fechas bien
import { grey } from "@mui/material/colors";
import { UrlApi } from '../../../../services/apirest';
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import Notify from '../../../Notify';
import { enviarsms } from '../../../../services/services';
import { logEventos } from '../../../../services/services';
import { getData } from '../../../../services/library';

export default function Modalagregarpedidos(props) {
    const [pedidos, setPedidos] = useState([]);
    const [rowSelection, setRowSelection] = useState({});
    const [totalkilos, setTotalkilo] = useState(0);
    const [datosSeleccionados, setDatosSeleccionados] = useState(null);
    const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
    const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
    const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
    const [error, setError] = useState(null); //guardará el error que lance la api (si existe)  3
    const globalTheme = useTheme();
    const [disbtn, setDisbtn] = useState(false);

    const obtenerPedidos = () => {
        getData('/pedidos/sucursales/' + props.datos.id_sucursal, setPedidos)
    };

    useEffect(() => {
        obtenerPedidos()
    }, [props.datos.id_sucursal]);

    const tableHeadCellProps = {
        align: "center",
        sx: {
            fontSize: 16,
            color: "white",
            fontWeight: "normal",
            backgroundColor: "#2471A3",
            borderColor: "black",
        },
    };

    const columns = useMemo(() =>
        [{ header: 'Pedido', accessorKey: 'idpedido' },
        { header: 'Sisven', accessorKey: 'IDsisven' },
        { header: 'Cliente', accessorKey: 'razon_social' },
        { header: 'Dir.Entrega', accessorKey: 'nombre_direccion' },
        { header: 'Fecha requerida', accessorKey: 'fecha_requerida', type: "date", format: "YYYY-MM-DD" },
        { header: 'A entregar', accessorKey: 'kgacargar' },
        { header: 'Prioridad', accessorKey: 'orden_prioridad' }
        ], []);

    const muiSearchTextFieldProps = {
        placeholder: "Buscar..",
        sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
        variant: "outlined",
        color: "success",
        size: "small",
    };

    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: globalTheme.palette.mode,
                    primary: globalTheme.palette.primary, //primary color for the table
                    info: {
                        main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            globalTheme.palette.mode === "light"
                                ? "rgb(234, 239, 241)"
                                : "#000", //pure black table in dark mode for fun
                    },
                },
                typography: {
                    button: {
                        textTransform: "none", //customize typography styles for all buttons in table by default
                        fontSize: "1.2rem",
                    },
                },
                // components: {
                //     MuiTooltip: {
                //         styleOverrides: {
                //             tooltip: {
                //                 fontSize: "1.1rem", //larger tooltip font size                                
                //             },
                //         },
                //     },
                // },

            }), [globalTheme]);

    const initialState = {
        density: "compact",
        showGlobalFilter: true,
        pagination: { pageIndex: 0, pageSize: 10 }
    };

    useEffect(() => {
        const { datosSeleccionados, totalkilos } = Object.keys(rowSelection).reduce((result, key) => {
            if (rowSelection[key]) {
                result.datosSeleccionados.push({ id: pedidos[key].idpedido, "id_estado": 23, "nro_reparto": props.datos.nro_reparto, razon_social: pedidos[key].razon_social, nombre_direccion: pedidos[key].nombre_direccion, kgacargar: pedidos[key].kgacargar })
                result.totalkilos += pedidos[key].kgacargar;
            }
            return result;
        }, { datosSeleccionados: [], totalkilos: 0 });
        setDatosSeleccionados(datosSeleccionados);
        setTotalkilo(totalkilos);
    }, [rowSelection, totalkilos]);

    const ponernumerodereparto = async (e) => {
        setDisbtn(true);
        e.preventDefault();
        let response = await fetch(UrlApi + '/pedidos/x/delivery1', {
            method: "PUT", headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(datosSeleccionados)
        })
        if (response.ok === false) {
            const error = response && response.json();
            setError(error);
            alert("Inconvenientes con la API. No se pudo guardar. ");
        } else {
            try {
                const json = await response.json();
                if (json.message) {
                    setNotificacion(true);
                    setOpenSnack(true);
                    setMensaje(
                        json.message === "OK" ? "Guardado" : "Guardado. Actualizar RTA Api."
                    );
                    props.actualizar()
                } else if (json.sqlMessage) {
                    json && setError(json);
                    alert("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
                } else {
                    alert("ERROR DESCONOCIDO.");
                }
            } catch (error) {
                if (error.toString().includes("is not valid JSON")) {
                    setNotificacion(true);
                    setOpenSnack(true);
                    setMensaje("Guardado");
                    props.actualizar()
                } else {
                    alert("Error: " + error);
                }
            }
        }
        props.datos.telefono &&
            props.datos.estado === 8
            &&
            datosSeleccionados.razon_social &&
            enviarsms(props.datos.telefono, `Nuevo pedido para: ${datosSeleccionados.razon_social.trim()}. \n Refresque App.`)
            logEventos("Poner nro de reparto", "Pedidos", "Repartos", localStorage.getItem("user"))
    };

    return (
        <div>
            <Dialog open={props.open} onClose={() => { props.open(false) }} maxWidth={false}>
                {pedidos && pedidos.length > 0 ?
                    <DialogContent>
                        <Grid
                            p={2}
                            container
                            backgroundColor={grey[100]}
                            border={1}
                            borderColor={grey[300]}
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Grid container alignItems="center" justifyContent="space-between">
                                <Grid item xs={2}>
                                    <InputLabel focused>Capacidad </InputLabel>
                                    <InputLabel >{props.datos.capacidad}</InputLabel>
                                </Grid>
                                <Grid item xs={2}>
                                    <InputLabel focused>Kg cargados </InputLabel>
                                    <InputLabel >{props.datos.kgxreparto}</InputLabel>
                                </Grid>
                                <Grid item xs={2}>
                                    <InputLabel focused> Disponible </InputLabel>
                                    <InputLabel >{(props.datos.capacidad - props.datos.kgxreparto) - totalkilos}</InputLabel>
                                </Grid>
                                <Grid item xs={2}>
                                    <InputLabel focused> Fecha de salida </InputLabel>

                                    <InputLabel >{moment(props.datos.fecha_salida_reparto).format("DD-MM-YYYY")}</InputLabel>

                                </Grid>
                            </Grid>

                        </Grid>

                        <ThemeProvider theme={tableTheme}>
                            <Box mt={2} mb={3} boxShadow={3}>
                                <MaterialReactTable
                                    enableExpandAll={false}
                                    enableToolbarInternalActions={true}
                                    enableColumnResizing
                                    layoutMode="grid"
                                    muiTableHeadCellProps={tableHeadCellProps}
                                    muiTableBodyCellProps={({ row }) => ({
                                        align: "center",
                                        sx: {
                                            backgroundColor: "#ffffff",
                                            color: "black",
                                            borderColor: "black",
                                        },
                                    })}
                                    enableColumnFilters={false}
                                    enableHiding={false}
                                    enableDensityToggle={false}
                                    localization={MRT_Localization_ES}
                                    enableColumnDragging={false}
                                    initialState={initialState}
                                    muiSearchTextFieldProps={muiSearchTextFieldProps}
                                    positionExpandColumn='first'
                                    columns={columns}
                                    data={pedidos}
                                    positionActionsColumn="last"
                                    enablePagination={false}
                                    enableRowSelection
                                    onRowSelectionChange={setRowSelection}
                                    state={{ rowSelection }}
                                    enableStickyFooter
                                    enableBottomToolbar={false}
                                    enableStickyHeader
                                />
                            </Box>
                        </ThemeProvider>

                        <DialogActions>
                            <Grid item flex={0} container direction="column">
                                <Grid item xs={4}>
                                    <Button onClick={ponernumerodereparto} variant="outlined" color="success" disabled={disbtn}>
                                        Confirmar
                                    </Button>
                                </Grid>
                                {/* <Grid item xs={4}>
                                    <Button onClick={() => { props.open(false) }} variant="outlined" color="error" >
                                        Volver
                                    </Button>
                                </Grid> */}
                            </Grid>
                        </DialogActions>
                    </DialogContent>
                    :
                    <DialogContent>
                        <DialogContentText textAlign={"center"}>
                            No hay pedidos pendientes para asignar a este reparto
                        </DialogContentText>
                        <DialogActions>
                            <Grid item flex={0} container direction="column">
                                <Grid item xs={4}>
                                    <Button onClick={() => props.open(false)} variant="outlined" color="error" >
                                        Volver
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </DialogContent>
                }

                {
                    notificacion ? (
                        <Notify
                            mensaje={mensaje}
                            open={openSnack}
                            color={"#D4EFDF"}
                            severity={"success"}
                            handleClose={() => props.open(false)}
                        />
                    ) : null
                }
            </Dialog>
        </div>
    )

}