import { Grid,Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Title from '../../Title';
import Notify from '../../Notify';

export default function Editarestado(props) {
    const [datos, setdatos] = useState(props.datos);
    const [disbtn, setDisBtn] = useState(false);
    const [notificacion, setNotificacion] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
    });
    
    const handleClose = () =>{
        props.open(false);
        props.actualizar()
    };

    return (
        <div>
            <Container>
                <Dialog open={props.open}>
                    <form>
                        <Box backgroundColor={"#1F618D"}>
                            <DialogTitle>
                                {datos.activo === 0 ?
                                    <Typography variant="h6" align='center' color={"#ffff"}>
                                        Activar cuenta {datos.nro_cuenta}
                                    </Typography>
                                    :
                                    <Typography variant="h6" align='center' color={"#ffff"}>
                                        Dar de baja cuenta {datos.nro_cuenta}
                                    </Typography>}
                            </DialogTitle>
                        </Box>
                        <DialogContent>
                            <DialogContentText textAlign={"center"}>
                                {datos.activo === 0 ?
                                    <>¿Desea activar cuenta nro° {datos.nro_cuenta}</>
                                    :
                                    <>¿Desea dar de baja cuenta nro° {datos.nro_cuenta}</>}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Grid container direction="row" justifyContent="space-between" alignItemns="center">
                                <Button variant="contained" color="error">Volver</Button>
                                <Button variant="contained" color="succes">Confirmar</Button>
                            </Grid>
                        </DialogActions>
                        {notificacion && notificacion.open ? (
                            <Notify
                                mensaje={notificacion.mensaje}
                                open={notificacion.open}
                                color={notificacion.color}
                                handleClose={handleClose}
                                severity={notificacion.severity}
                            />
                        ) : null}
                    </form>
                </Dialog>
            </Container>
        </div>
    )
}

