import { Stack, Skeleton } from "@mui/material";

const TableSkeleton = () => {
  return (
    <>
      <Stack spacing={1} maxHeight maxWidth alignContent={"center"}>
        <Skeleton
          variant="table"
          height={100}
          rows={5}
          columns={4}
          sx={{ border: "1px solid #ccc" }}
        />
        <Skeleton variant="rectangular" animation="wave" />
        <Skeleton variant="rectangular" animation="wave" />
        <Skeleton variant="rectangular" animation="wave" />
        <Skeleton variant="rectangular" animation="wave" />
        <Skeleton variant="rectangular" animation="wave" />
        <Skeleton
          variant="table"
          height={100}
          rows={5}
          columns={4}
          sx={{ border: "1px solid #ccc" }}
        />
      </Stack>{" "}
    </>
  );
};

export default TableSkeleton;
