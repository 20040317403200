//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio
// Creation Date : 08/11/2024
// Version : 1
// Description : pantalla para ver los resultados del checklist
//------Changes --------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect } from "react";
import { UrlApi } from "../../../services/apirest";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Stack,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Divider from "@mui/material/Divider";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
//ADJUNTAR
import AttachFileIcon from "@mui/icons-material/AttachFile"; //ADJUNTAR IMAGEN
import FileUploadHS from "../Checklist/FileUploadHS";
import { v4 as uuidv4 } from "uuid"; //ADJUNTAR IMAGEN
import Modal_audit_check from "./Modal_audit_check";
import moment from "moment";
import Dialog_revision_check from "./Dialog_revision_check";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ResultadoPanel(props) {
  const { datosResultados, mostrarResultadoCheck, tabla } = props;

  const fechaFormat = moment(datosResultados.fecha, "YYYY-MM-DD").format(
    "YYYY-MM"
  );
  const [fechasVencimiento, setFechasVencimiento] = useState({});
  const [adjuntos, setAdjuntos] = useState({});

  const [resultadosCheck, setResultadosCheck] = useState();
  const [selectedDate, setSelectedDate] = useState("");
  const [depositoSeleccionado, setDepositoSeleccionado] = useState("");
  const [uuid, setUuid] = useState(uuidv4()); //ADJUNTAR IMAGEN
  const [openCloseAdj, setOpenCloseAdj] = useState(false); //ADJUNTAR IMAGEN
  const [checkboxStates, setCheckboxStates] = useState({});
  const [observaciones, setObservaciones] = useState({});
  const [fechaResultadoCheck, setFechaResultadoCheck] = useState({});
  const [diasHabiles, setDiasHabiles] = useState({});
  const [esAuditor, setEsAuditor] = useState(localStorage.getItem("auditor"));
  //console.log("ES AUDITOR", esAuditor);
  const [openModal, setOpenModal] = useState(false);
  const [itemId, setItemId] = useState("");
  const [revision, setRevision] = useState(false);

  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");

  // -------- fecha -----------//
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };
  const formattedDate = `${selectedDate}`;
  // ----------------------------- GET ---------------------- //

  const [gruposEItems, setGruposEItems] = useState([]);
  const [terminado, setTerminado] = useState(false);

  const obtenerGruposEItems = () => {
    fetch(UrlApi + "/seg_hig_grupos/activos")
      .then((response) => response.json())
      .then((grupos) => {
        const promises = grupos.map((grupo) => {
          // Retorna una promesa para cada llamada a la API
          return fetch(
            UrlApi + `/seg_hig_grupos/itemxgrupoActivos/${grupo.id_grupo}`
          )
            .then((response) => response.json())
            .then((items) => ({ grupo, items }));
        });
        // Espera a que todas las promesas se resuelvan
        return Promise.all(promises);
      })
      .then((gruposItemsArray) => {
        // Una vez que todas las llamadas a la API se completen con éxito,
        // actualiza el estado con el array completo de grupos e items
        setGruposEItems(gruposItemsArray);
        setTerminado(true);
      })
      .catch((error) =>
        console.error("Error al obtener grupos e items:", error)
      );
  };

  useEffect(() => {
    obtenerGruposEItems();
  }, []);

  // console.log("GRUPOS E ITEMS", gruposEItems);

  // console.log("OBSERVACIONES: ", observaciones);

  const obtenerResultadosCheck = () => {
    fetch(
      UrlApi +
        "/seg_hig_checklist/resultadoCheck/" +
        datosResultados.sucursal +
        "/" +
        datosResultados.comercial +
        "/" +
        fechaFormat
    )
      .then((data) => data.json())
      .then((data) => {
        // --------------------- CHECKBOX------------------------------ //
        // Crear un nuevo objeto para almacenar los estados de los checkboxes actualizados
        const newCheckboxStates = { ...checkboxStates }; // Copiar el estado actual de los checkboxes
        // Iterar sobre los resultados obtenidos
        data.forEach((resultado) => {
          // Comprobar si el grupo ya existe en el estado de los checkboxes
          if (!newCheckboxStates[resultado.id_grupo]) {
            newCheckboxStates[resultado.id_grupo] = {};
          }
          // Actualizar el estado de los checkboxes para este grupo y item
          if (resultado.cumple === 1) {
            newCheckboxStates[resultado.id_grupo][resultado.id_item] = "cumple";
          } else if (resultado.no_cumple === 1) {
            newCheckboxStates[resultado.id_grupo][resultado.id_item] =
              "no_cumple";
          } else {
            newCheckboxStates[resultado.id_grupo][resultado.id_item] = "n_a";
          }
        });
        // Actualizar el estado checkboxStates con los nuevos valores obtenidos
        setCheckboxStates(newCheckboxStates);
        // También puedes hacer otras cosas con los resultados si es necesario
        setResultadosCheck(data);
        // -------------------- OBSERVACIONES ---------------------- //
        // Crear un nuevo objeto para almacenar las observaciones de cada ítem
        const newObservaciones = { ...observaciones };

        // console.log("NEW OBSERVACIONES: ", newObservaciones);
        // Iterar sobre los resultados obtenidos
        data.forEach((resultado) => {
          // Actualizar las observaciones para este ítem
          newObservaciones[resultado.id_item] = resultado.observacion || ""; // Asumiendo que la propiedad en los resultados es 'observacion'
        });
        // Actualizar el estado 'observaciones' con los nuevos valores obtenidos
        setObservaciones(newObservaciones);
        // También puedes hacer otras cosas con los resultados si es necesario
        setResultadosCheck(data);
        // -------------------- FECHA ---------------------- //
        // Extraer la fecha de los resultados si está presente en la respuesta de la API
        const fecha = data.length > 0 ? data[0].fecha : null;
        // Guardar la fecha en el estado
        setFechaResultadoCheck(fecha);
        // -------------------- UUID DE ADJUNTOS ---------------------- //
        const newAdjuntos = {};
        data.forEach((resultado) => {
          // Imprime el resultado para asegurarte de que el uuid_adjunto existe
          console.log("Resultado item:", resultado);

          if (resultado.uuid) {
            newAdjuntos[resultado.id_item] = {
              uuid: resultado.uuid,
              open: false,
            };
          }
        });
        setAdjuntos(newAdjuntos);
        console.log("Adjuntos almacenados:", newAdjuntos);
        // -------------------- FECHAS DE VENCIMIENTO ---------------------- //
        // Crear un nuevo objeto para almacenar fechas de vencimiento actualizadas
        const newFechasVencimiento = {}; // Empezar con un objeto vacío para evitar arrastrar datos anteriores

        data.forEach((resultado) => {
          // Si el item tiene una fecha de vencimiento, la almacenamos
          if (resultado.fecha_vencimiento) {
            newFechasVencimiento[resultado.id_item] =
              resultado.fecha_vencimiento || "";
          }
        });
        // Actualizar el estado con las nuevas fechas de vencimiento
        setFechasVencimiento(newFechasVencimiento);

        // Guardar la fecha general del resultado del checklist si está presente
        const fecha2 = data.length > 0 ? data[0].fecha2 : null;
        setFechaResultadoCheck(fecha2);
      });
  };

  useEffect(() => {
    obtenerResultadosCheck();
  }, [datosResultados]);

  //console.log("OBTENER RESULTADOS CHECK", resultadosCheck);

  const [deposito, setDeposito] = useState();

  const obtenerDepositos = () => {
    fetch(UrlApi + "/sucursales")
      .then((data) => data.json())
      .then((data) => setDeposito(data));
  };
  useEffect(() => {
    obtenerDepositos();
  }, []);

  // console.log("DEPOSITOS", depositoSeleccionado);

  const [comercial, setComercial] = useState();

  const obtenerComercial = () => {
    fetch(UrlApi + "/representantes/activos/")
      .then((data) => data.json())
      .then((data) => setComercial(data));
  };
  useEffect(() => {
    obtenerComercial();
  }, []);
  //console.log("COMERCIAL", comercialSeleccionado);

  const [responsable, setResponsable] = useState();
  const [responsableSeleccionado, setResponsableSeleccionado] = useState("");

  const obtenerResponsable = () => {
    fetch(UrlApi + "/checklist/userXsuc/" + depositoSeleccionado.id)
      .then((data) => data.json())
      .then((data) => setResponsable(data));
  };
  useEffect(() => {
    obtenerResponsable();
  }, [depositoSeleccionado]);

  // console.log("RESPONSABLE PLANTA", responsable);
  //console.log("RESPONSABLE SELECCIONADO", responsableSeleccionado);
  // ------------------ESTILO -------------------- //
  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  const size = "small";
 //-------------------- ADJUNTO -------------------------- //
  // Función para manejar la apertura/cierre del diálogo de adjuntar
  const handleOpenCloseAdj = (itemId, isOpen) => {
    setAdjuntos((prev) => ({
      ...prev,
      [itemId]: {
        ...prev[itemId],
        open: isOpen,
        uuid: prev[itemId]?.uuid, // Usa el UUID existente
      },
    }));
    console.log("UUID", [itemId]?.uuid);
  };

  // Función para obtener el UUID del archivo
  const getUuidForItem = (itemId) => adjuntos[itemId]?.uuid || "";

  // Función para verificar si el diálogo está abierto para un ítem
  const isAdjDialogOpen = (itemId) => adjuntos[itemId]?.open || false;
  //-------- Función para contar la cantidad de checks marcados ------------- //
  const contarChecksMarcados = () => {
    let cantidadCumple = 0;
    let cantidadNoCumple = 0;
    let cantidadNA = 0;

    // Iterar sobre los grupos
    for (const grupoId in checkboxStates) {
      // Iterar sobre los items dentro del grupo
      for (const itemId in checkboxStates[grupoId]) {
        const estado = checkboxStates[grupoId][itemId];
        // Contar los estados de los checkboxes
        if (estado === "cumple") {
          cantidadCumple++;
        } else if (estado === "no_cumple") {
          cantidadNoCumple++;
        } else if (estado === "n_a") {
          cantidadNA++;
        }
      }
    }

    return {
      cumple: cantidadCumple,
      no_cumple: cantidadNoCumple,
      n_a: cantidadNA,
    };
  };
  // Llamada a la función para obtener la cantidad de checks marcados
  const cantidadChecks = contarChecksMarcados(checkboxStates);

  //--------------- modal para auditoria ---------------------//
  const [itemIndex, setItemIndex] = useState();
  const [resultadoEncontrado, setResultadoEncontrado] = useState(null);
  // Al abrir el modal, establece el ítem actual seleccionado
  const handleOpenModalCheck = (itemId, itemIndex, grupoId) => {
    setItemId(itemId);
    setItemIndex(itemIndex);
    // console.log("item ID MODAL: ", itemId);
    setOpenModal(true);

    const resultado = resultadosCheck.find(
      (resultado) =>
        resultado.id_item === itemId && resultado.id_grupo === grupoId
    );
    // Verificar si se encontró un resultado
    if (resultado) {
      //  console.log("Resultado encontrado:", resultado.id_check);
      setResultadoEncontrado(resultado);
    } else {
    }
  };
  // console.log("Index item", itemIndex);

  // console.log("OBSERVACIONES: ", observaciones[itemId]);
  //---------------------------- CHECK DE REVISION ----------------------------//
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = async (event) => {
    const checked = event.target.checked;
    setIsChecked(checked);

    if (checked) {
      setRevision(true); // Abre el diálogo
    }
  };

  const handleVolverMostrar = () => {
    setRevision(false); // Cierra el diálogo
    setIsChecked(false); // Desmarca el checkbox
  };
  const handleVolverGuardar = () => {
    setRevision(false); // Cierra el diálogo
    setIsChecked(true); // Desmarca el checkbox
  };

  const [estado, setEstado] = useState();

  const obtenerEstado = () => {
    fetch(
      UrlApi +
        "/seg_hig_auditoria/mescomercialsuc/" +
        datosResultados.comercial +
        "/" +
        fechaFormat +
        "/" +
        datosResultados.sucursal
    )
      .then((data) => data.json())
      .then((data) => {
        setEstado(data);
        // Actualizar el estado del checkbox basado en la respuesta
        if (data && data.length > 0 && data[0].auditado === 1) {
          setIsChecked(true);
        } else {
          setIsChecked(false);
        }
      });
  };

  useEffect(() => {
    obtenerEstado();
  }, [datosResultados]);

  //console.log("ESTADO", estado);
  //--------------------------- NOTIFICACION----------------------------------------------//
  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  //--------------------------------PARA EL BOTÓN VOLVER---------------------------------//
  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };
  //console.log("datos auditado",datosResultados.auditado);
  //console.log("datos resultado",datosResultados);
  //console.log("estados",estado);

  return (
    <div>
      <Box>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          ml={2}
          marginTop={2}
        >
          <Grid item xs={4} md={4}>
            <TextField
              label="Responsable"
              name="responsable"
              size="small"
              focused
              fullWidth
              value={datosResultados.nombre_apellido}
            />
          </Grid>
          <Grid item xs={4} md={3}>
            <TextField
              label="Sucursal"
              name="deposito"
              size="small"
              focused
              fullWidth
              value={datosResultados.nombre}
            />
          </Grid>
          <Grid item xs={4} md={3}>
            <TextField
              focused
              size="small"
              label="Período"
              value={moment(datosResultados.fecha, "YYYY-MM-DD").format(
                "MM-YYYY"
              )}
              // value={datosResultados.fecha}
            />
          </Grid>
        </Grid>
      </Box>
      <br></br>
      {resultadosCheck && resultadosCheck.length > 0 ? (
        <Paper
          elevation={6}
          style={{ width: "80%", padding: "20px", marginLeft: 70 }}
        >
          <Typography
            variant="poster"
            fontSize={20}
            style={{ textDecoration: "underline" }}
          >
            Control de seguridad e higiene:
          </Typography>
          <br></br>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="poster" fontSize={20}>
                Responsable: {datosResultados.nombre_apellido}
                <br></br>
                Sucursal: {datosResultados.nombre}
              </Typography>
            </Grid>

            {datosResultados.auditado === "Si" ? (
              <Grid item xs={6}>
                <Typography variant="poster" fontSize={20}>
                  {/* Auditor: {estado[0].auditor} */}
                  Auditor: {estado ? estado[0].auditor : null}
                  <br></br>
                  Fecha de auditoría:{" "}
                  {moment(datosResultados.fecha_auditado).format("DD-MM-YYYY")}
                </Typography>
              </Grid>
            ) : null}
          </Grid>
          <Table>
            <br></br>
            <TableBody>
              {/* Mapea sobre gruposEItems para renderizar cada grupo y sus items */}
              {terminado === true &&
                gruposEItems.map((grupoItem, grupoIndex) => (
                  <React.Fragment key={grupoItem.grupo.id_grupo}>
                    <TableRow>
                      <TableCell
                        style={{ fontSize: 17, backgroundColor: "#C7C7C7" }}
                        colSpan={3}
                      >
                        <b>{grupoItem.grupo.nombre_grupo}</b>
                      </TableCell>
                    </TableRow>
                    {grupoItem.items.map((item, itemIndex) => (
                      <TableRow key={item.id_item}>
                        <TableCell style={{ fontSize: 16 }}>
                          <b>{item.nombre_item}</b>
                          <br></br>
                          <br></br>
                          {item.definicion_item}
                        </TableCell>
                        <TableCell>
                          <FormGroup
                            aria-label="position"
                            row
                            // style={{ marginRight: 220 }}
                            style={{ marginRight: 100 }}
                          >
                            <FormControlLabel
                              labelPlacement="end"
                              label="Cumple"
                              control={
                                <Checkbox
                                  name="cumple"
                                  checked={
                                    checkboxStates[grupoItem.grupo.id_grupo]?.[
                                      item.id_item
                                    ] === "cumple" || false
                                  }
                                />
                              }
                            />
                            <FormControlLabel
                              labelPlacement="end"
                              label="No cumple"
                              control={
                                <Checkbox
                                  name="no_cumple"
                                  checked={
                                    checkboxStates[grupoItem.grupo.id_grupo]?.[
                                      item.id_item
                                    ] === "no_cumple" || false
                                  }
                                />
                              }
                            />
                            <FormControlLabel
                              labelPlacement="end"
                              label="N/A"
                              control={
                                <Checkbox
                                  name="n_a"
                                  checked={
                                    checkboxStates[grupoItem.grupo.id_grupo]?.[
                                      item.id_item
                                    ] === "n_a" || false
                                  }
                                />
                              }
                            />
                          </FormGroup>
                        </TableCell>
                        <TableCell>
                        {fechasVencimiento[item.id_item] ? (
                            <TextField
                              fullWidth
                              size={size}
                              color="primary"
                              name="fecha_vencimiento"
                              label="Fecha de Vencimiento"
                              variant="outlined"
                              value={moment(
                                fechasVencimiento[item.id_item]
                              ).format("DD-MM-YYYY")}
                              inputProps={{
                                readOnly: true, // Campo de solo lectura
                              }}
                            />
                          ) : (
                            " * No tiene fecha de vencimiento *"
                          )}
                          <TextField
                            fullWidth
                            size={size}
                            margin="normal"
                            style={{
                              ...style,
                              width: "100%",
                              marginRight: 300,
                            }}
                            focused
                            color="primary"
                            name="observacion"
                            label="Observaciones"
                            variant="outlined"
                            multiline
                            rows={5}
                            value={observaciones[item.id_item]} // Usar la observación correspondiente
                            inputProps={{
                              maxLength: 200,
                              readOnly: true, // Establecer el campo como de solo lecturasa
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Adjuntos" arrow>
                            <IconButton
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                handleOpenCloseAdj(item.id_item, true)
                              }
                            >
                              <AttachFileIcon />
                            </IconButton>
                          </Tooltip>
                          {isAdjDialogOpen(item.id_item) && (
                            <>
                              {console.log(
                                "Mostrando adjunto para item:",
                                item.id_item,
                                "con UUID:",
                                getUuidForItem(item.id_item)
                              )}
                              <FileUploadHS
                                fileId={getUuidForItem(item.id_item)}
                                openclose={true}
                                mostrar={false}
                                onClose={() =>
                                  handleOpenCloseAdj(item.id_item, false)
                                }
                              />
                            </>
                          )}
                        </TableCell>
                        {esAuditor === "1" ? (
                          <TableCell>
                            <Grid
                              container
                              sx={{ justifyContent: "left", mt: 4 }}
                            >
                              <Grid item mr={5} mt={-2}>
                                {resultadosCheck &&
                                resultadosCheck.some(
                                  (resultado) =>
                                    resultado.id_grupo ===
                                      grupoItem.grupo.id_grupo &&
                                    resultado.id_item === item.id_item &&
                                    resultado.auditado === 1
                                ) ? (
                                  <Button
                                    variant="outlined"
                                    aria-readonly
                                    color={"error"}
                                    size="small"
                                  >
                                    Auditado
                                  </Button>
                                ) : (
                                  <Button
                                    variant="outlined"
                                    onClick={() => {
                                      handleOpenModalCheck(
                                        item.id_item,
                                        itemIndex,
                                        grupoItem.grupo.id_grupo
                                      );
                                    }}
                                    size="small"
                                  >
                                    Auditar
                                  </Button>
                                )}
                              </Grid>
                            </Grid>
                          </TableCell>
                        ) : null}
                      </TableRow>
                    ))}
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
          <Box
            sx={{
              textAlign: "center",
              marginTop: 5,
              minHeight: "2vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>
              <Typography
                variant="poster"
                fontSize={19}
                style={{ textDecoration: "underline", marginTop: 20 }}
              >
                Resultados :
              </Typography>
              <br />
              <br />
              <Typography variant="poster" fontSize={19}>
                Cumplidos : {cantidadChecks.cumple} - No cumplidos :{" "}
                {cantidadChecks.no_cumple}
              </Typography>
            
            </div>
          </Box>
          <br></br>
        
          <Box sx={{ flexGrow: 1 }} alignItems="flex">
            {esAuditor === "1" ? (
              <>
                <Divider style={{ width: "101%", marginTop: 20 }} />
                <br></br>
                <Typography
                  variant="poster"
                  fontSize={26}
                  marginLeft={2}
                  style={{ textDecoration: "underline" }}
                >
                  Revisión de auditoría
                </Typography>
                <FormGroup
                  style={{
                    textAlign: "center",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="large"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        name="revision"
                        disabled={isChecked} // Deshabilita el checkbox si está marcado
                      />
                    }
                    label="Revisión realizada"
                  />
                </FormGroup>
              </>
            ) : null}

            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-end"
              mt={2}
            >
              <Button variant="contained" onClick={handleClose} color="error">
                Volver
              </Button>
            </Grid>

            {/* SNACKBAR QUE SE MOSTRARA AL GUARDAR LOS CAMBIOS */}
            <Stack spacing={2} sx={{ width: "100%" }}>
              <Snackbar
                open={openSnack}
                autoHideDuration={1500}
                onClose={handleCloseSnack}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <Alert
                  onClose={handleCloseSnack}
                  severity="success"
                  sx={{ width: "100%" }}
                  style={{ backgroundColor: colormensaje, color: "black" }}
                >
                  {mensaje}
                </Alert>
              </Snackbar>
            </Stack>
          </Box>
          <br></br>
        </Paper>
      ) : (
        <Paper
          elevation={6}
          style={{ width: "80%", padding: "20px", marginLeft: 70 }}
        >
          <Typography variant="body1" color="textSecondary" align="center">
            <strong>
              {" "}
              No hay ningún resultado de control de depósito para la sucursal o
              comercial o fecha seleccionada.
            </strong>
          </Typography>
        </Paper>
      )}

      {openModal ? (
        <Modal_audit_check
          open={setOpenModal}
          onClose={() => handleOpenModalCheck(false)} // Proporciona una función de cierre
          sucursal={datosResultados}
          resultados={resultadoEncontrado || ""}
          observacion={observaciones[itemId] || ""}
          itemId={itemId} // Pasa el itemId como propiedad
          obtenerResultadosDespuesAuditar={obtenerResultadosCheck}
        />
      ) : null}

      {revision ? (
        <Dialog_revision_check
          revision={revision}
          datosResultados={datosResultados}
          onClose={handleVolverGuardar}
          onClick={handleVolverMostrar}
          setIsChecked={setIsChecked} // Pasamos la función para controlar el estado
        />
      ) : null}
      <br></br>
    </div>
  );
}

export default ResultadoPanel;
