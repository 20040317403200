//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio
// Creation Date : 4/10/2024
// Version : 1
// Description : pantalla para autorizaciones de modificaciones
//
//------Changes --------------------------------------------------------------------- -------------//
// Change Date :
// Change by :
// Change description:
//
// Change Date :
// Change by :
// Change description:
//
//========================================================================/
import React, { useState, useEffect, useMemo } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Box,
  DialogContentText,
  Typography,
  DialogActions,
} from "@mui/material";
import moment from "moment"; // PARA LA FECHA
//email
import { logEventos } from "../../services/services";
import { grey } from "@mui/material/colors";
import { enviarmails } from "../../services/services";
import { v4 as uuidv4 } from "uuid"; //ADJUNTAR IMAGEN
import { postData } from "../../services/library";

function Dialog_aut_modif(props) {
  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [openSnackEditar, setOpenSnackEditar] = React.useState(false);
  const [openCrear, setOpenCrear] = React.useState(false);
  const [disabledGuardarRe, setDisabledGuardarRe] = useState(false);
  const [colorTextField, setcolorTextField] = useState();
  //   const [update, setUpdate] = useState(false);

  const [row, setRow] = useState(props.row);
  const [abrirConfirmacion, setAbrirConfirmacion] = useState(
    props.abrirConfirmacion
  );
  //*************************************************************************************************************************************/
  // RECHAZO MODIFICACION - (ABAJO)
  const [dialogRechazarModificacion, setDialogRechazarModificacion] =
    useState(false);
  const handleCloseDialogRechazarModificacion = () => {
    setDialogRechazarModificacion(false);
  };

  const handleClickOpenRechazar = async (row) => {
    setDialogRechazarModificacion(true);
  };

  const handleCloseRechazo = () => {
    setDialogRechazarModificacion(false);
  };

  const handleClose = () => {
    //   setAbrirConfirmacion(false);
    setAbrirConfirmacion(!abrirConfirmacion);
    // setSnack(true)
    if (props.onClose) {
      props.onClose();
    }
  };

  //************ GUARDAR AJUSTE *************************************************************************************************************************/

  const guardarAjusteStock = async (e) => {
    // logEventos(
    //   "Guardar ajuste de stock",
    //   "Stock Sucursales",
    //   "Se guardo el ajuste de stock",
    //   localStorage.getItem("user")
    // );
    e.preventDefault();
    await fetch(
      UrlApi + "/stock_inventarios/" + row.id_almacen_origen + "/" + row.id_articulo,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          cantidad: row.cantidad_recibida,
          usuario: localStorage.getItem("user"),
        }),
      }
    )
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          setColorMensaje("#D4EFDF");
          setMensaje("El Ajuste de Stock se Realizo Exitosamente !");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al Realizar Ajuste de Stock");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    e.preventDefault();
    await fetch(UrlApi + "/stock_transacciones/" + row.id_transaccion, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        autorizar_modificacion: 2,
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El artículo se guardó exitosamente !");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el artículo");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    handleClose();
  };
  const guardarRechazar = async (e) => {
    // logEventos(
    //   "Guardar rechazar artículo",
    //   "Stock Autorizar Articulos",
    //   "Se rechazo el artículo",
    //   localStorage.getItem("user")
    // );
    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(UrlApi + "/stock_transacciones/" + row.id_transaccion, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        autorizar_modificacion: 3,
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          //   setUpdate(true);
          // setMensajeConfirma(true)
          setColorMensaje("#D4EFDF");
          setMensaje("El artículo se rechazó!");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al rechazar el artículo");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    // console.log(row.id_articulo);
    handleClose();
  };
  //*******************************************************************/
// console.log("ROW DIALOG", row);
  // console.log("ID ALMACEN ORIGEN", row.id_almacen_origen);
  // console.log("ID ALMACEN DESTINO", row.id_almacen_destino);
  // console.log("ID ARTICULO", row.id_articulo);
  // console.log("CANTIDAD NUEVA", row.cantidad_recibida);
  //**********************************************************************/
  const [usuarioCompras, setUsuarioCompras] = useState([]);

  const obtenerUsuarioCompras = () => {
    fetch(UrlApi + "/userdata/sector/4/")
      .then((data) => data.json())
      .then((data) => setUsuarioCompras(data));
  };
  useEffect(() => {
    obtenerUsuarioCompras();
  }, []);

  // const [usuarioAdmin, setUsuarioAdmin] = useState([]);

  // const obtenerUsuarioAdmin = () => {
  //   fetch(UrlApi + "/userdata/sector/5/")
  //     .then((data) => data.json())
  //     .then((data) => setUsuarioAdmin(data));
  // };
  // useEffect(() => {
  //   obtenerUsuarioAdmin();
  // }, []);

  //console.log("usuario admin",usuarioAdmin);
  //console.log("usuario compras",usuarioCompras);  
//*******************************************************************/
  const [reqExiste, setReqExiste] = useState([]);

  const obtenerRequerimientoExistente = () => {
    fetch(UrlApi + "/requerimientos_materiales/reqexiste/" + localStorage.getItem("sucursal") + "/" + row.id_articulo)
      .then((data) => data.json())
      .then((data) => setReqExiste(data));
  };
  
  useEffect(() => {
    obtenerRequerimientoExistente();
  }, [row]);
//****************************************************************** */
  const [inventarioTrResta, setInventarioTrResta] = useState([]);
  //funcion para obtener sucursales
  const obtenerInventarioTrResta = () => {
    fetch(
      UrlApi +
        "/stock_inventarios/xalmart/" +
        row.id_almacen_origen +
        "/" +
        row.id_articulo
    )
      .then((data) => data.json())
      .then((data) => setInventarioTrResta(data));
  };

  useEffect(() => {
    obtenerInventarioTrResta();
  }, [row.id_almacen_origen, row.id_articulo]);

  const [inventarioConv, setInventarioConv] = useState([]);
  const obtenerInventarioConv = () => {
    fetch(
      UrlApi +
        "/stock_inventarios/xalmart/" +
        row.id_almacen_destino +
        "/" +
        row.convertible_a
    )
      .then((data) => data.json())
      .then((data) => setInventarioConv(data));
  };

  useEffect(() => {
    obtenerInventarioConv();
  }, [row.id_almacen_destino, row.convertible_a]);

  const [cantidadInventarioConv, setCantidadInventarioConv] = useState(0);

  // CALCULO DEL TOTAL FINAL
  useEffect(() => {
    setCantidadInventarioConv(
      inventarioConv[0]
        ? parseInt(inventarioConv[0].cantidad) +
            parseInt(row.cantidad_enviada)
        : null
    );
  }, [inventarioConv, row.cantidad_enviada,row.id_almacen_destino]);

  const [cantidadInventarioTrResta, setCantidadInventarioTrResta] = useState(0);

  // const handleCantidadInventarioTrResta = (e) => {
  //   setCantidadInventarioTrResta(e.target.value);
  // };

  // CALCULO DEL TOTAL FINAL
 
  useEffect(() => {
    setCantidadInventarioTrResta(
      inventarioTrResta[0] && row.cantidad_enviada
        ? parseInt(inventarioTrResta[0].cantidad) - parseInt(row.cantidad_enviada)
        : 0 // valor por defecto en caso de que inventarioTrResta[0] no esté disponible
    );
  }, [inventarioTrResta, row.cantidad_enviada]);

  // console.log("cantidad QUE VA A TENER EL NUEVO",cantidadInventarioConv);
  // console.log("CANT DE LO QUE QUEDA",cantidadInventarioTrResta);
  // console.log("inventarioConv",inventarioConv);
  // console.log("inventarioTrResta",inventarioTrResta);
  // console.log("cantidad enviada",row.cantidad_enviada);
  
  // const envioMail = async (e) => {
  //   const usuariosNotificar = [...(usuarioCompras || []), ...(usuarioAdmin || [])]; // Combinar ambos arrays
  //  // console.log("usuarios notificar",usuariosNotificar);

  //   usuariosNotificar &&
  //     usuariosNotificar.length > 0 &&
  //     usuariosNotificar.forEach((usuario) => {
  //       if (usuario.email) {
  //         if (
  //           cantidadInventarioTrResta < row.alerta_minima &&
  //           cantidadInventarioTrResta > row.valor_minimo
  //         ) {
  //           enviarmails(
  //             usuario.email,
  //             "Advertencia de Nivel de Stock",
  //             `<p>El artículo <b>${row.nombre_articulo}</b> del almacén <b>${row.nombre_almacen_origen}</b> está por debajo del punto de pedido.<br/></p>`
  //           );
  //         } else if (cantidadInventarioTrResta < row.valor_minimo) {
  //           enviarmails(
  //             usuario.email,
  //             "Alerta de Stock por Debajo del Nivel Mínimo",
  //             `<p>El artículo <b>${row.nombre_articulo}</b> del almacén <b>${row.nombre_almacen_origen}</b> está por debajo del stock mínimo.<br/></p>`
  //           );
  //         }
  //       }
  //     });
  // };

  // const envioMail = async (e) => {
  //   usuarioCompras &&
  //     usuarioCompras.length > 0 &&
  //     usuarioCompras.forEach((usuario) => {
  //       if (usuario.email) {
  //         if (
  //           cantidadInventarioTrResta < row.alerta_minima &&
  //           cantidadInventarioTrResta > row.valor_minimo
  //         ) {
  //           enviarmails(
  //             usuario.email,
  //             "Advertencia de Nivel de Stock",
  //             `<p>El artículo <b>${row.nombre_articulo}</b> del almacén <b>${row.nombre_almacen_origen}</b> está por debajo del punto de pedido.<br/></p>`
  //           );
  //         } else if (cantidadInventarioTrResta < row.valor_minimo) {
  //           enviarmails(
  //             usuario.email,
  //             "Alerta de Stock por Debajo del Nivel Mínimo",
  //             `<p>El artículo <b>${row.nombre_articulo}</b> del almacén <b>${row.nombre_almacen_origen}</b> está por debajo del stock mínimo.<br/></p>`
  //           );
  //         }
  //       }
  //     });
  // };


  // *********************************REQUERIMIENTO***************************************** //
const [notificacion, setNotificacion] = useState({
  open: false,
  mensaje: "",
  severity: "",
  color: "",
});
const [uuid, setUuid] = useState(uuidv4());

const [envioRequerimiento, setEnvioRequerimiento] = useState({
  id_movil: null,
  id_sucursal: localStorage.getItem("sucursal"),
  fecha_entrega: moment(row.fecha_transaccion).format(
    "YYYY-MM-DD HH:mm:ss"
  ),
  usuario_registra: localStorage.getItem('user'),
  usuario_autoriza: null,
  estado: 50, //cuando se carga el pedido se pone en estado "A autorizar"
  para_comprar: 1,
  observaciones: "",
  uuid_requerimiento: uuid,
  comentarios_internos: "Pedido Automático - Definir Cantidad",
  para_compras: 0,
  para_rrhh: 0,
  autorizado_jefe_compras: 0,
})

const [datosLista, setDatosLista] = useState([{
  material: null,
  usuario: localStorage.getItem('user'),
  cantidad: 1,
  // id_unidad_medida: row.id_unidad_medida,
  uuid_requerimiento: envioRequerimiento.uuid_requerimiento,
  id_articulo: row.id_articulo
}]);


useEffect(() => {
setDatosLista([{
  material: null,
  usuario: localStorage.getItem('user'),
  cantidad: 1,
  // id_unidad_medida: row.id_unidad_medida,
  uuid_requerimiento: envioRequerimiento.uuid_requerimiento,
  id_articulo: row.id_articulo
}])
setEnvioRequerimiento({
id_movil: null,
id_sucursal: localStorage.getItem("sucursal"),
fecha_entrega: moment(row.fecha_transaccion).format(
  "YYYY-MM-DD HH:mm:ss"
),
usuario_registra: localStorage.getItem('user'),
usuario_autoriza: null,
estado: 50, //cuando se carga el pedido se pone en estado "A autorizar"
para_comprar: 1,
observaciones: "",
uuid_requerimiento: uuid,
comentarios_internos: "Pedido Automático - Definir Cantidad",
para_compras: 0,
para_rrhh: 0,
autorizado_jefe_compras: 0,
})
}, [row])

const guardarListaMateriales = (e) => {
postData(e,
    "/lista_materiales",
    {
        datosLista
    },
    setNotificacion
);
};
console.log(row);

// ************************************************************************** //
  const guardarConversion = async (e) => {
    logEventos("Guardar conversión", "Stock Sucursales", "Se guardo conversión", localStorage.getItem("user"))
    e.preventDefault();

    // console.log("Valor inicial de inventarioTrResta POST:", inventarioTrResta);

    if (inventarioConv.length === 0) {
      await fetch(UrlApi + "/stock_inventarios", {
        // PUT SI YA EXISTE / POST SI ES NUEVO
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id_articulo: row.convertible_a,
          cantidad: row.cantidad_enviada,
          id_almacen: row.id_almacen_destino,
          usuario: localStorage.getItem("user"),
        }),
      })
        .then((res) => {
          if (!res.err) {
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setMensaje("El artículo se guardó exitosamente !");
          } else {
            setOpenSnack(false);
            setColorMensaje("#F1948A");
            setMensaje("Error al guardar el artículo");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
          // Verificar cantidad después de la primera operación
    // console.log("Cantidad después del PUT CANT INV:", cantidadInventarioTrResta);
      await fetch(
        UrlApi +
          "/stock_inventarios/" +
          row.id_almacen_origen +
          "/" +
          row.id_articulo,
        {
          // PUT SI YA EXISTE / POST SI ES NUEVO
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            cantidad: cantidadInventarioTrResta,
            usuario: localStorage.getItem("user"),
          }),
        }
      )
        .then((res) => {
          if (!res.err) {
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setMensaje("El artículo se guardó exitosamente !");
          } else {
            setOpenSnack(false);
            setColorMensaje("#F1948A");
            setMensaje("Error al guardar el artículo");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else {
      // console.log("Cantidad después del PUT CANT INV CONV:", cantidadInventarioTrResta);

      await fetch(
        UrlApi +
          "/stock_inventarios/" +
          row.id_almacen_destino +
          "/" +
          row.convertible_a,
        {
          // PUT SI YA EXISTE / POST SI ES NUEVO
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            cantidad: cantidadInventarioConv,
            usuario: localStorage.getItem("user"),
          }),
        }
      )
        .then((res) => {
          if (!res.err) {
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setMensaje("El artículo se guardó exitosamente !");
          } else {
            setOpenSnack(false);
            setColorMensaje("#F1948A");
            setMensaje("Error al guardar el artículo");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
        // console.log("Cantidad después del PUT 2 CANT INV:", cantidadInventarioTrResta);

      await fetch(
        UrlApi +
          "/stock_inventarios/" +
          row.id_almacen_origen +
          "/" +
          row.id_articulo,
        {
          // PUT SI YA EXISTE / POST SI ES NUEVO
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            cantidad: cantidadInventarioTrResta,
            usuario: localStorage.getItem("user"),
          }),
        }
      )
        .then((res) => {
          if (!res.err) {
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setMensaje("El artículo se guardó exitosamente !");
          } else {
            setOpenSnack(false);
            setColorMensaje("#F1948A");
            setMensaje("Error al guardar el artículo");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
      }
      await fetch(UrlApi + "/stock_transacciones/" + row.id_transaccion, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          autorizar_modificacion: 2,
        }),
      })
    .then((res) => {
      if (!res.err) {
        setOpenSnack(true);
        // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
        //   setUpdate(true);
        // setMensajeConfirma(true)
        setColorMensaje("#D4EFDF");
        setMensaje("El artículo se autorizó!");
        console.log("Guardado");
      } else {
        setOpenSnack(false);
        setColorMensaje("#F1948A");
        setMensaje("Error al autorizar el artículo");
        console.log("Error");
      }
    })
    .catch((err) => {
      console.log(err.message);
    });
    if (cantidadInventarioTrResta<row.alerta_minima && cantidadInventarioTrResta>=row.valor_minimo ) {
    //  console.log("ADVERTENCIA AMARILLA");
      if (row.pedido_automatico === 1 && reqExiste.length === 0) {
      //  console.log("ES AUTOMATICO AMARILLO");
        postData(e,
          '/requerimientos_materiales',
          {
              id_sucursal: envioRequerimiento.id_sucursal,
              id_movil: envioRequerimiento.id_movil,
              estado: 50, //cuando se carga el pedido se pone en estado "Pendiente autorizacion"
              para_comprar: envioRequerimiento.para_comprar,
              para_compras: envioRequerimiento.para_compras,
              para_rrhh: envioRequerimiento.para_rrhh,
              fecha_entrega: envioRequerimiento.fecha_entrega,
              usuario_registra: envioRequerimiento.usuario_registra,
              usuario_autoriza: envioRequerimiento.username,
              observaciones: envioRequerimiento.observaciones,
              uuid_requerimiento: uuid,
              comentarios_internos: envioRequerimiento.comentarios_internos,
              autorizado_jefe_compras: 0,
          },
          setNotificacion,
          guardarListaMateriales(e),
          )
          .then((response) => {
            console.log("Respuesta de postData en guardarCabecera:", response);
          })
          .catch((error) => {
            console.error("Error en postData en guardarCabecera:", error);
          });
      }
    } else if (cantidadInventarioTrResta<row.valor_minimo) {
     // console.log("ADVERTENCIA ROJA");
      if (row.pedido_automatico === 1 && reqExiste.length === 0) {
     //   console.log("ES AUTOMATICO ROJO");
        postData(e,
          '/requerimientos_materiales',
          {
              id_sucursal: envioRequerimiento.id_sucursal,
              id_movil: envioRequerimiento.id_movil,
              estado: 50, //cuando se carga el pedido se pone en estado "Pendiente autorizacion"
              para_comprar: envioRequerimiento.para_comprar,
              para_compras: envioRequerimiento.para_compras,
              para_rrhh: envioRequerimiento.para_rrhh,
              fecha_entrega: envioRequerimiento.fecha_entrega,
              usuario_registra: envioRequerimiento.usuario_registra,
              usuario_autoriza: envioRequerimiento.username,
              observaciones: envioRequerimiento.observaciones,
              uuid_requerimiento: uuid,
              comentarios_internos: envioRequerimiento.comentarios_internos,
              autorizado_jefe_compras: 0
          },
          setNotificacion,
          guardarListaMateriales(e),
          )
          .then((response) => {
            console.log("Respuesta de postData en guardarCabecera:", response);
          })
          .catch((error) => {
            console.error("Error en postData en guardarCabecera:", error);
          });
      }
    }
    // if (row.enviamail === 1) {
    //   envioMail();
    // }
    handleClose();
  };

  useEffect(() => {
    if (cantidadInventarioTrResta < 0) {
      setDisabledGuardarRe(true);
      setcolorTextField("error");
    } else {
      setDisabledGuardarRe(false);
      setcolorTextField("primary");
    }
  }, [cantidadInventarioTrResta, row.cantidad_enviada]);


  return (
    <div>
      {/*------------ DIALOG APROBAR AJUSTE---------------*/}
      {row.tipo_transaccion === "Ajuste" ? (
        <Dialog
          open={abrirConfirmacion}
          onClose={handleClose}
          maxWidth="xs"
          fullWidth
        >
          <Box backgroundColor={"#1F618D"} mt={1}>
            <DialogTitle>
              <Typography variant="h6" align="center" color="#ffffff">
                Confirmar Ajuste
              </Typography>
            </DialogTitle>
          </Box>
          <form onSubmit={guardarAjusteStock}>
            <Box
              border={0.5}
              borderColor={grey[300]}
              backgroundColor="#F4F6F6"
              p={2}
              py={2}
              mt={1}
            >
              <DialogContent>
                <DialogContentText textAlign={"center"}>
                  ¿Desea Autorizar el Ajuste?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItemns="center"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClose}
                    style={{ backgroundColor: "#1976D2", color: "#ffffff" }}
                  >
                    Volver
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleClickOpenRechazar}
                    style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
                  >
                    Rechazar
                  </Button>
                  <Button
                    style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Autorizar
                  </Button>
                </Grid>
              </DialogActions>
            </Box>
          </form>
        </Dialog>
      ) : null}

      {/* ------ DIALOG RECHAZAR AJUSTE ------ */}
      {row.tipo_transaccion === "Ajuste" ? (
        <Dialog
          open={dialogRechazarModificacion}
          onClose={handleCloseDialogRechazarModificacion}
        >
          <form onSubmit={guardarRechazar}>
            <Box backgroundColor={"#1F618D"} mt={1}>
              <DialogTitle>
                <Typography variant="h6" align="center" color="#ffffff">
                  Rechazar Ajuste
                </Typography>
              </DialogTitle>
            </Box>
            <Box
              border={0.5}
              borderColor={grey[300]}
              backgroundColor="#F4F6F6"
              p={2}
              py={2}
              mt={1}
            >
              <DialogContent>
                <DialogContentText textAlign={"center"}>
                  ¿Desea Rechazar el Ajuste?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItemns="center"
                >
                  <Button
                    variant="contained"
                    onClick={handleCloseRechazo}
                    style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
                  >
                    Volver
                  </Button>
                  <Button
                    style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Confirmar
                  </Button>
                </Grid>
              </DialogActions>
            </Box>
          </form>
        </Dialog>
      ) : null}

      {/*------------ DIALOG APROBAR CONVERSION ---------------*/}
      {row.tipo_transaccion === "Conversion" ? (
        <Dialog
          open={abrirConfirmacion}
          onClose={handleClose}
          maxWidth="xs"
          fullWidth
        >
          <Box backgroundColor={"#1F618D"} mt={1}>
            <DialogTitle>
              <Typography variant="h6" align="center" color="#ffffff">
                Confirmar Conversión
              </Typography>
            </DialogTitle>
          </Box>
          <form
          onSubmit={guardarConversion}
          >
            <Box
              border={0.5}
              borderColor={grey[300]}
              backgroundColor="#F4F6F6"
              p={2}
              py={2}
              mt={1}
            >
              <DialogContent>
                <DialogContentText textAlign={"center"}>
                  ¿Desea Autorizar la Conversión?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItemns="center"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClose}
                    style={{ backgroundColor: "#1976D2", color: "#ffffff" }}
                  >
                    Volver
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleClickOpenRechazar}
                    style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
                  >
                    Rechazar
                  </Button>
                  <Button
                    style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Autorizar
                  </Button>
                </Grid>
              </DialogActions>
            </Box>
          </form>
        </Dialog>
      ) : null}

      {/* ------ DIALOG RECHAZAR CONVERSION ------ */}
      {row.tipo_transaccion === "Conversion" ? (
        <Dialog
          open={dialogRechazarModificacion}
          onClose={handleCloseDialogRechazarModificacion}
        >
          <form 
          onSubmit={guardarRechazar}
          >
            <Box backgroundColor={"#1F618D"} mt={1}>
              <DialogTitle>
                <Typography variant="h6" align="center" color="#ffffff">
                  Rechazar Conversión
                </Typography>
              </DialogTitle>
            </Box>
            <Box
              border={0.5}
              borderColor={grey[300]}
              backgroundColor="#F4F6F6"
              p={2}
              py={2}
              mt={1}
            >
              <DialogContent>
                <DialogContentText textAlign={"center"}>
                  ¿Desea Rechazar la Conversión?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItemns="center"
                >
                  <Button
                    variant="contained"
                    onClick={handleCloseRechazo}
                    style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
                  >
                    Volver
                  </Button>
                  <Button
                    style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Confirmar
                  </Button>
                </Grid>
              </DialogActions>
            </Box>
          </form>
        </Dialog>
      ) : null}
    </div>
  );
}

export default Dialog_aut_modif;
