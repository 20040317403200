import React, { useState, useEffect, useMemo, useRef } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Button,
  Box,
  Grid,
  Stack,
  Snackbar,
  TextField,
  Autocomplete,
  Alert,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment"; // PARA LA FECHA
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { grey } from "@mui/material/colors";
import { size } from "lodash";
import { logEventos } from "../../services/services";

function ConfigDias(props) {
    const [openSnack, setOpenSnack] = React.useState(false);
    const [colormensaje, setColorMensaje] = useState("");
    const [mensaje, setMensaje] = useState("");
    const [configDias, setConfigDias] = useState(props.configDias)
    const [lunes, setLunes] = useState(false)
    const [martes, setMartes] = useState(false)
    const [miercoles, setMiercoles] = useState(false)
    const [jueves, setJueves] = useState(false)
    const [viernes, setViernes] = useState(false)
    let lunesValor= lunes?1:0
    let martesValor= martes?1:0
    let miercolesValor= miercoles?1:0
    let juevesValor= jueves? 1:0
    let viernesValor= viernes?1:0

    const handleChangeLunes = (event) => {
      setLunes(event.target.checked);
    };
    const handleChangeMartes = (event) => {
      setMartes(event.target.checked);
    };
    const handleChangeMiercoles = (event) => {
      setMiercoles(event.target.checked);
    };
    const handleChangeJueves = (event) => {
      setJueves(event.target.checked);
    };
    const handleChangeViernes = (event) => {
      setViernes(event.target.checked);
    };
   // console.log("Lunes", lunes, lunesValor);
   // console.log("Martes", martes, martesValor);
   // console.log("Miercoles", miercoles, miercolesValor);
   // console.log("Jueves", jueves, juevesValor);
  //  console.log("Viernes", viernes, viernesValor);

    const guardarConfiguracion = async (e) => {
      logEventos("Guardar configuración de dias", "Pedidos Semanales", "Se guardo la configuración", localStorage.getItem("user"))
      e.preventDefault();
      await fetch(UrlApi + "/config_diashabilitados", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          lunes: lunesValor,
          martes: martesValor,
          miercoles: miercolesValor,
          jueves: juevesValor,
          viernes: viernesValor,
          usuario: localStorage.getItem("user"),
        }),
      })
        .then((res) => {
          if (!res.err) {
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setMensaje("La configuración se guardó exitosamente !");
          } else {
            setOpenSnack(false);
            setColorMensaje("#F1948A");
            setMensaje("Error al guardar la configuración");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
      //Estados para pantalla
      const style = {
        backgroundColor: "#ffffff",
        width: "100%",
      };
        //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const handleClose = () => {
    setConfigDias(!configDias)
      if (props.onClose) {
          props.onClose();
        }
    };
  return (
    <>
    <>
      <form onSubmit={guardarConfiguracion}>
        <Box
          border={0.5}
          borderColor={grey[300]}
          backgroundColor="#F4F6F6"
          p={2}
          py={2}
          mt={1}
          style={{display: "flex", justifyContent:"center"}}
        >
         <FormControl component="fieldset">
            <FormGroup aria-label="position" row >
              <FormControlLabel
                value="Lunes"
                control={<Checkbox
                  checked={lunes}
                  onChange={handleChangeLunes}  
                />}
                label={<span style={{fontSize:"1.2rem"}}>Lunes</span>}
                labelPlacement="top"
              />
              <FormControlLabel
                value="Martes"
                control={<Checkbox
                  checked={martes} 
                  onChange={handleChangeMartes}
                />}
                label={<span style={{fontSize:"1.2rem"}}>Martes</span>}
                labelPlacement="top"
              />
              <FormControlLabel
                value="Miercoles"
                control={<Checkbox
                  checked={miercoles}
                  onChange={handleChangeMiercoles}  
                />}
                label={<span style={{fontSize:"1.2rem"}}>Miercoles</span>}
                labelPlacement="top"
              />
              <FormControlLabel
                value="Jueves"
                control={<Checkbox 
                  checked={jueves}
                  onChange={handleChangeJueves}
                />}
                label={<span style={{fontSize:"1.2rem"}}>Jueves</span>}
                labelPlacement="top"
              />
              <FormControlLabel
                value="Viernes"
                control={<Checkbox
                  checked={viernes}
                  onChange={handleChangeViernes}  
                />}
                label={<span style={{fontSize:"1.2rem"}}>Viernes</span>}
                labelPlacement="top"
              />
            </FormGroup>
          </FormControl>
        </Box>
        <Box>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mt={2}
          >
            <Button variant="contained" color="error" onClick={handleClose}>
              Volver
            </Button>
            <Button variant="contained" type="submit" color="success">
              Guardar Configuración
            </Button>
          </Grid>
        </Box>
      </form>
    </>
    <>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  </>
  )
}

export default ConfigDias