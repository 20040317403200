import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  Divider,
  Grid,
  Button,
  Box,
  Tooltip,
  Fab,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";
import { useLocation, useNavigate } from "react-router-dom";
import { getData, postData } from "../../../services/library";
import { UrlApi } from "../../../services/apirest";
import SaveIcon from "@mui/icons-material/Save";
import Notify from "../../Notify";
import Image from "../../../img/tractocamion.jpg";
import Tanque from "../../../img/tanque.jpg";
import Jaula from "../../../img/jaulacamion.jpg";
import Rueda from "../../../img/ruedados.png";

function PosicionesNeumaticos() {
  let navigate = useNavigate();
  const location = useLocation();
  const [notificacion, setNotificacion] = useState({
    open: false,
    mensaje: "",
    severity: "",
    color: "",
  });
  const [detalleArticulo, setDetalleArticulo] = useState([]);
  const [posicionesGuardadas, setPosicionesGuardadas] = useState([]);
  const [positions, setPositions] = useState(Array(13).fill(null)); // Posiciones inicialmente vacías
  const [posicionesHistorial, setPosicionesHistorial] = useState([]);

  // console.log("location", location.state);

  // ACTUALIZACION DE FUNCIONES
  const obtenerRuedasDisponibles = () => {
    getData(
      `/stock_seguimiento/detalle/${location.state.rowData.articulo}/${location.state.rowData.vehiculo}`,
      (data) => {
        const ruedasAsignadas = posicionesGuardadas.map(
          (item) => item.identificador
        );
        const ruedasDisponibles = data.filter(
          (item) => !ruedasAsignadas.includes(item.identificador)
        );
        setDetalleArticulo(ruedasDisponibles);
      }
    );
  };

  // Función para obtener las posiciones guardadas y actualizar el estado de posiciones
  const obtenerPosicionesGuardadas = () => {
    getData(
      `/stock_posiciones/posiciones/${location.state.rowData.vehiculo}/${location.state.rowData.articulo}`,
      (data) => {
        setPosicionesGuardadas(data);

        // Actualizar las posiciones en el array
        const updatedPositions = Array(13).fill(null); // Asume 11 posiciones
        data.forEach((item) => {
          if (item.posicion >= 1 && item.posicion <= 13) {
            // Guarda un objeto con el identificador de la rueda y el id de la posición
            updatedPositions[item.posicion - 1] = {
              identificador: item.identificador,
              id: item.id, // Aquí guardamos el ID de la posición
            };
          }
        });
        setPositions(updatedPositions);
      }
    );
  };

  const obtenerPosicionesHistorial = () => {
    getData(
      `/stock_posiciones/posicioneshistorial/${location.state.rowData.vehiculo}/${location.state.rowData.articulo}`,
      setPosicionesHistorial
    );
  };

  // useEffect para obtener las ruedas disponibles
  useEffect(() => {
    obtenerRuedasDisponibles();
  }, [
    location.state.rowData.articulo,
    location.state.rowData.vehiculo,
    posicionesGuardadas,
  ]);

  // useEffect para obtener las posiciones guardadas
  useEffect(() => {
    obtenerPosicionesGuardadas();
  }, [location.state.rowData.vehiculo, location.state.rowData.articulo]);
  // console.log("posiciones", posicionesGuardadas);
  useEffect(() => {
    obtenerPosicionesHistorial();
  }, [location.state.rowData.vehiculo, location.state.rowData.articulo]);
  // console.log("posiciones Historial", posicionesHistorial);

  const rectsCamion = [
    { id: 1, top: 55, left: 226 },
    { id: 2, top: 55, left: 472 },
    { id: 3, top: 205, left: 105 },
    { id: 4, top: 205, left: 226 },
    { id: 5, top: 205, left: 472 },
    { id: 6, top: 205, left: 597 },
    { id: 7, top: 305, left: 105 },
    { id: 8, top: 305, left: 226 },
    { id: 9, top: 305, left: 472 },
    { id: 10, top: 305, left: 597 },
    { id: 11, top: 372, left: 350 },
  ];

  const rectsTanque = [
    { id: 1, top: 40, left: 105 },
    { id: 2, top: 40, left: 230 },
    { id: 3, top: 40, left: 472 },
    { id: 4, top: 40, left: 597 },
    { id: 5, top: 165, left: 105 },
    { id: 6, top: 165, left: 230 },
    { id: 7, top: 165, left: 472 },
    { id: 8, top: 165, left: 597 },
    { id: 9, top: 295, left: 105 },
    { id: 10, top: 295, left: 230 },
    { id: 11, top: 295, left: 472 },
    { id: 12, top: 295, left: 597 },
    { id: 13, top: 370, left: 354 },
  ];

  const rectsJaula = [
    { id: 1, top: 40, left: 105 },
    { id: 2, top: 40, left: 230 },
    { id: 3, top: 40, left: 472 },
    { id: 4, top: 40, left: 597 },
    { id: 5, top: 165, left: 105 },
    { id: 6, top: 165, left: 230 },
    { id: 7, top: 165, left: 472 },
    { id: 8, top: 165, left: 597 },
    { id: 9, top: 295, left: 105 },
    { id: 10, top: 295, left: 230 },
    { id: 11, top: 295, left: 472 },
    { id: 12, top: 295, left: 597 },
    { id: 13, top: 370, left: 354 },
  ];

  const rects =
    location.state.locationState.id_tipo === 10 ||
    location.state.locationState.id_tipo === 269
      ? rectsCamion
      : location.state.locationState.id_tipo === 64
      ? rectsTanque
      : location.state.locationState.id_tipo === 268 ||
        location.state.locationState.id_tipo === 270
      ? rectsJaula
      : null;

  const handleStop = (e, data, identificador) => {
    const rect = e.target.getBoundingClientRect(); // Obtener la posición del elemento soltado
    const container = document
      .getElementById("vehicle-container")
      .getBoundingClientRect(); // Contenedor del vehículo
    const offsetX = rect.left - container.left; // Coordenada relativa al contenedor
    const offsetY = rect.top - container.top; // Coordenada relativa al contenedor

    const index = rects.findIndex(
      (rect) =>
        offsetX > rect.left &&
        offsetX < rect.left + 120 && // Ancho del rectángulo destino
        offsetY > rect.top &&
        offsetY < rect.top + 80 // Alto del rectángulo destino
    );

    if (index !== -1 && !positions[index]) {
      const newPositions = [...positions];
      newPositions[index] = identificador;
      setPositions(newPositions);

      setDetalleArticulo((prevDetalleArticulo) =>
        prevDetalleArticulo.filter(
          (item) => item.identificador !== identificador
        )
      );
    }
  };

  // const handleCancel = (positionIndex) => {
  //   const ruedaId = positions[positionIndex];
  //   if (!ruedaId) return;

  //   const newPositions = [...positions];
  //   newPositions[positionIndex] = null;
  //   setPositions(newPositions);

  //   setDetalleArticulo((prevDetalleArticulo) => [
  //     ...prevDetalleArticulo,
  //     { identificador: ruedaId, id: ruedaId },
  //   ]);
  // };
  // const handleCancel = async (positionIndex) => {
  //   const rueda = positions[positionIndex];
  //   if (!rueda) return; // Si no hay rueda en la posición, salir

  //   // Si la rueda tiene estructura de objeto, realizar DELETE
  //   if (typeof rueda === "object" && rueda.id) {
  //     try {
  //       await fetch(UrlApi + `/stock_posiciones/${rueda.id}`, {
  //         method: "DELETE",
  //         headers: { "Content-Type": "application/json" },
  //       });

  //       setNotificacion({
  //         open: true,
  //         mensaje: "Posición eliminada correctamente",
  //         severity: "success",
  //         color: "#D4EFDF",
  //       });

  //       // Actualizar datos locales después del DELETE
  //       obtenerPosicionesGuardadas();
  //     } catch (error) {
  //       console.error(error);
  //       setNotificacion({
  //         open: true,
  //         mensaje: "Error al eliminar la posición",
  //         severity: "error",
  //         color: "red",
  //       });
  //       return; // Salir si falla el DELETE
  //     }
  //   }

  //   // Actualizar el estado local
  //   const newPositions = [...positions];
  //   newPositions[positionIndex] = null; // Vaciar la posición
  //   setPositions(newPositions);

  //   // Devolver la rueda al listado de disponibles
  //   const identificador =
  //     typeof rueda === "object" ? rueda.identificador : rueda;
  //   setDetalleArticulo((prevDetalleArticulo) => [
  //     ...prevDetalleArticulo,
  //     { identificador },
  //   ]);
  // };
  const handleCancel = async (positionIndex) => {
    const rueda = positions[positionIndex];
    if (!rueda) return; // Si no hay rueda en la posición, salir

    // Si la rueda tiene estructura de objeto, realizar PUT para desactivar
    if (typeof rueda === "object" && rueda.id) {
      try {
        await fetch(UrlApi + `/stock_posiciones/${rueda.id}`, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ activo: 0 }), // Actualizar activo a 0
        });

        setNotificacion({
          open: true,
          mensaje: "Posición desactivada correctamente",
          severity: "success",
          color: "#D4EFDF",
        });

        // Actualizar datos locales después del PUT
        obtenerPosicionesGuardadas();
        obtenerPosicionesHistorial();
      } catch (error) {
        console.error(error);
        setNotificacion({
          open: true,
          mensaje: "Error al desactivar la posición",
          severity: "error",
          color: "red",
        });
        return; // Salir si falla el PUT
      }
    }

    // Actualizar el estado local
    const newPositions = [...positions];
    newPositions[positionIndex] = null; // Vaciar la posición
    setPositions(newPositions);

    // Devolver la rueda al listado de disponibles
    const identificador =
      typeof rueda === "object" ? rueda.identificador : rueda;
    setDetalleArticulo((prevDetalleArticulo) => [
      ...prevDetalleArticulo,
      { identificador },
    ]);
  };

  const volverAtras = () => {
    navigate(-1);
  };

  const guardarPosiciones = (e) => {
    const datosAGuardar = positions
      .map((position, index) => {
        if (!position) return null; // Ignorar posiciones vacías

        // Manejar objetos y valores simples
        const identificador =
          typeof position === "object" ? position.identificador : position;

        // Verificar si ya está guardada
        const yaGuardada = posicionesGuardadas.some(
          (item) =>
            item.posicion === rects[index].id &&
            item.identificador === identificador
        );

        if (yaGuardada) return null;

        // Crear el objeto para guardar
        return {
          vehiculo: location.state.rowData.vehiculo,
          articulo: location.state.rowData.articulo,
          identificador,
          posicion: rects[index].id,
          activo: 1,
          usuario: localStorage.getItem("user"),
        };
      })
      .filter((item) => item !== null); // Filtrar posiciones vacías y las ya guardadas

    if (datosAGuardar.length === 0) {
      console.warn("No hay nuevas posiciones para guardar");
      return;
    }

    // Enviar los datos al backend
    postData(e, "/stock_posiciones", { datosAGuardar }, setNotificacion);

    // Actualizar datos locales
    obtenerRuedasDisponibles();
    obtenerPosicionesGuardadas();
    obtenerPosicionesHistorial();
  };

  useEffect(() => {
    if (notificacion.open) {
      const timer = setTimeout(() => {
        setNotificacion((prev) => ({ ...prev, open: false }));
      }, 3000); // Tiempo en milisegundos para cerrar automáticamente (3s)

      return () => clearTimeout(timer); // Limpia el temporizador si el componente se desmonta
    }
  }, [notificacion.open]);

  return (
    <div
      style={{
        backgroundColor: "#f0f0f0",
        minHeight: "100vh", // Asegura que cubra toda la altura de la ventana
        display: "flex", // Para centrar el contenido si es necesario
        flexDirection: "column",
      }}
    >
      <AppBar
        sx={{ position: "relative" }}
        style={{ backgroundColor: "#0979b0" }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={volverAtras}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Posiciones en Vehículo
          </Typography>
        </Toolbar>
      </AppBar>
      {location.state.locationState.id_tipo === 10 ||
      location.state.locationState.id_tipo === 64 ||
      location.state.locationState.id_tipo === 268 ||
      location.state.locationState.id_tipo === 269 ||
      location.state.locationState.id_tipo === 270 ? (
        <>
          <Grid
            container
            spacing={2}
            style={{ marginTop: 0, backgroundColor: "#f0f0f0" }}
          >
            {/* Títulos */}
            <Grid
              item
              xs={4}
              sx={{
                borderTop: "1px solid #fff",
                borderRight: "2px solid #fff",
                borderBottom: "2px solid #fff",
                backgroundColor: "#2471A3",
              }}
            >
              <Typography
                variant="h5"
                component="div"
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  color: "#fff",
                  marginBottom: "10px",
                }}
              >
                Artículos Disponibles
              </Typography>
            </Grid>
            <Grid
              item
              xs={8}
              sx={{
                borderTop: "1px solid #fff",
                borderBottom: "2px solid #fff",
                backgroundColor: "#2471A3",
              }}
            >
              <Typography
                variant="h5"
                component="div"
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  color: "#fff",
                  marginBottom: "10px",
                }}
              >
                Posiciones del Vehículo
              </Typography>
            </Grid>

            {/* Contenido */}
            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start", // Evitar que los elementos se estiren verticalmente
                padding: 1,
              }}
            >
              <div
                style={{
                  display: "flex",
                  marginLeft: 10,
                  flexWrap: "wrap", // Asegurar que las ruedas se organicen correctamente
                  gap: "10px",
                  justifyContent: "center",
                  zIndex: 10,
                  padding: "10px", // Relleno interno para el contenedor
                  border: "1px solid #ccc", // Borde para destacar el grupo
                  borderRadius: "12px", // Bordes redondeados para el grupo
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.15)", // Sombra grupal
                  backgroundColor: "#fff", // Fondo opcional para el contraste
                  width: "93%",
                }}
              >
                {/* Verificar si hay ruedas disponibles */}
                {detalleArticulo.length === 0 ? (
                  <Typography
                    variant="h6"
                    component="div"
                    style={{
                      fontWeight: "bold",
                      color: "#f44336",
                      textAlign: "center",
                      width: "100%",
                      marginTop: "20px",
                    }}
                  >
                    No hay artículos disponibles
                  </Typography>
                ) : (
                  detalleArticulo.map((item) => (
                    <Draggable
                      key={item.id}
                      onStop={(e, data) =>
                        handleStop(e, data, item.identificador)
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          cursor: "grab",
                          margin: "5px", // Espaciado entre ruedas
                          borderRadius: "8px", // Opcional, bordes redondeados
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "bold",
                            color: "#000",
                            marginBottom: "5px", // Espaciado entre el texto y la rueda
                          }}
                        >
                          {item.identificador}
                        </span>

                        <div
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "50%",
                            position: "relative",
                            overflow: "hidden",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={Rueda}
                            alt={item.identificador}
                            style={{
                              width: "100%",
                              height: "100%", // Asegura que la altura y el ancho sean iguales
                              objectFit: "cover",
                              position: "absolute",
                            }}
                          />
                        </div>
                      </div>
                    </Draggable>
                  ))
                )}
              </div>

              <Box
                sx={{
                  margin: 1,
                  padding: "10px",
                  borderRadius: "12px",
                  backgroundColor: "#fff",
                  border: "1px solid #ccc",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.15)",
                  textAlign: "center",
                  // maxWidth: "800px",
                  width: "93%",
                }}
              >
                {/* Título del historial */}
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "#333",
                    marginBottom: "16px",
                    textAlign: "center",
                    borderBottom: "2px solid #ccc",
                    paddingBottom: "8px",
                  }}
                >
                  Historial de posiciones
                </Typography>

                {/* Contenido de las posiciones */}
                <Grid container spacing={2}>
                  {rects.map((rect, index) => {
                    const historialPosicion = posicionesHistorial
                      .filter(
                        (hist) => hist.posicion === rect.id && hist.activo === 0
                      )
                      .sort(
                        (a, b) =>
                          new Date(b.updated_at).getTime() -
                          new Date(a.updated_at).getTime()
                      )[0]; // Tomar solo la entrada más reciente

                    return (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <Box
                          sx={{
                            padding: "16px",
                            borderRadius: "8px",
                            backgroundColor: "#f9f9f9",
                            border: "1px solid #ddd",
                            boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
                            textAlign: "left",
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              color: "#000",
                              marginBottom: "8px",
                            }}
                          >
                            Posición: {rect.id}
                          </Typography>

                          {historialPosicion ? (
                            <>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "14px",
                                  color: "#555",
                                  marginBottom: "4px",
                                }}
                              >
                                Art. Anterior: {historialPosicion.identificador}
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "12px",
                                  color: "#888",
                                }}
                              >
                                Baja:{" "}
                                {new Date(
                                  historialPosicion.updated_at
                                ).toLocaleDateString()}
                              </Typography>
                            </>
                          ) : (
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "12px",
                                color: "#888",
                              }}
                            >
                              Sin historial reciente.
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Grid>

            <Grid
              item
              xs={8}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              {/* Contenedor principal con el box */}
              <div
                style={{
                  position: "relative", // Asegura que el contenido absoluto esté contenido
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px", // Relleno interno
                  border: "1px solid #ccc", // Borde para el box
                  borderRadius: "12px", // Bordes redondeados
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.15)", // Sombra grupal
                  backgroundColor: "#fff", // Fondo opcional
                  width: "95%",
                  maxWidth: "900px", // Ajusta el tamaño máximo si es necesario
                  minHeight: "180px", // Garantiza un tamaño suficiente para el contenido
                  overflow: "visible", // Permite que el contenido sea visible
                }}
              >
                {/* Contenedor interno del vehículo */}
                <div
                  id="vehicle-container"
                  style={{
                    position: "relative",
                    width: "100%",
                    maxWidth: "800px",
                    height: "430px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 1,
                    transform: "translateY(-30px)", // Reemplazo del margen negativo
                    marginRight: 40,
                  }}
                >
                  <>
                    <img
                      src={
                        location.state.locationState.id_tipo === 10 ||
                        location.state.locationState.id_tipo === 269
                          ? Image
                          : location.state.locationState.id_tipo === 64
                          ? Tanque
                          : location.state.locationState.id_tipo === 268 ||
                            location.state.locationState.id_tipo === 270
                          ? Jaula
                          : null
                      }
                      alt="Camión con posiciones"
                      style={{
                        borderRadius: "15px",
                        position: "absolute",
                        width: "100%",
                        height: "75%",
                        objectFit: "contain",
                        zIndex: 0,
                      }}
                    />
                    {rects.map((rect, index) => (
                      <div
                        key={rect.id}
                        style={{
                          position: "absolute",
                          top: rect.top,
                          left: rect.left,
                          width: "90px",
                          height: "90px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          zIndex: 5,
                          backgroundColor: positions[index]
                            ? "#c2ffab"
                            : "#fff",
                          borderRadius: positions[index] ? "10px" : "5px",
                          border: positions[index] ? "none" : "2px dashed #000",
                          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        {positions[index] ? (
                          <>
                            <div
                              style={{
                                marginBottom: "4px",
                                color: "#000",
                                fontWeight: "bold",
                              }}
                            >
                              {`Pos ${rect.id}`}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "65%",
                                height: "75%",
                                position: "relative",
                                backgroundColor: "#c2ffab",
                              }}
                            >
                              <img
                                src={Rueda}
                                alt="Rueda"
                                style={{
                                  width: "80%",
                                  height: "80%",
                                  objectFit: "contain",
                                  marginTop: "3px",
                                }}
                              />
                              <IconButton
                                size="small"
                                onClick={() => handleCancel(index)}
                                style={{
                                  position: "absolute",
                                  top: "-10px",
                                  right: "-10px",
                                  zIndex: 10,
                                }}
                              >
                                <CancelIcon color="error" />
                              </IconButton>
                              <span
                                style={{
                                  fontWeight: "bold",
                                  color: "#000",
                                  fontSize: "13px",
                                  marginBottom: "2px",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Art.{" "}
                                {positions[index].identificador ||
                                  positions[index]}
                              </span>
                            </div>
                          </>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            <span
                              style={{
                                color: "#000",
                                fontWeight: "bold",
                                fontSize: "14px",
                              }}
                            >
                              {`Pos ${rect.id}`}
                            </span>
                          </div>
                        )}
                      </div>
                    ))}
                  </>
                </div>
              </div>
            </Grid>
          </Grid>

          <Box
            sx={{
              position: "fixed",
              bottom: 16,
              right: 16,
              zIndex: 1000,
              mb: 3,
            }}
          >
            <Tooltip title="Guardar">
              <Fab
                color="success"
                aria-label="add"
                onClick={guardarPosiciones}
                sx={{ mr: 1 }}
              >
                <SaveIcon />
              </Fab>
            </Tooltip>
          </Box>
        </>
      ) : (
        <Typography
          marginTop={20}
          display={"flex"}
          justifyContent={"center"}
          fontSize={25}
        >
          No se puede posicionar artículos en este tipo de vehículo
        </Typography>
      )}

      {notificacion && notificacion.open ? (
        <Notify
          mensaje={notificacion.mensaje}
          open={notificacion.open}
          color={notificacion.color}
          handleClose={() => setNotificacion({ ...notificacion, open: false })}
          severity={notificacion.severity}
        />
      ) : null}
    </div>
  );
}

export default PosicionesNeumaticos;
