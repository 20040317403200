import React, { useEffect, useState } from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { UrlApiEnvasado } from "../../../services/apirest";

// ICONOS
import CircularProgress from "@mui/material/CircularProgress";

export default function StackedAreas(props) {
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState([]);

  // Obtener el año y mes actuales
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  // Inicializar con el año y mes actuales
  const [selectedYear, setSelectedYear] = useState(currentYear.toString());

  // ***************************************************************

  // GETS DE LOS DATOS PARA EL CHART
   const obtenerDatosGrafico = async () => {
    setLoading(true);
    try {

      // Construir la URL base hasta el año seleccionado
      let url = `${UrlApiEnvasado}/envasado/fac_reng/pesototalvendido/anio/${selectedYear}`;

      // Array para almacenar las partes adicionales de la URL
      const partesUrl = [];

      // Agregar parte fija "sucursal/" a la URL
      partesUrl.push(`sucursal`);

      // if (sucursalSeleccionada) partesUrl.push(`${sucursalSeleccionada.id}`);

      // Agregar parte fija "chofer/" a la URL
      partesUrl.push(`chofer`);

      // if (choferSeleccionado) partesUrl.push(`${choferSeleccionado.id}`);

      // Agregar parte fija "movil/" a la URL
      partesUrl.push(`movil`);

      // if (movilSeleccionado) partesUrl.push(`${movilSeleccionado.movil_id}`);

      // Agregar parte fija "peso/" a la URL
      partesUrl.push(`peso`);

      // if (pesoSeleccionado) partesUrl.push(`${pesoSeleccionado.peso}`);

      // Agregar parte fija "envase/" a la URL
      partesUrl.push(`envase`);

      // if (tipoEnvaseSeleccionado)
      //   partesUrl.push(`${tipoEnvaseSeleccionado.id}`);

      // Agregar las partes acumuladas a la URL base
      if (partesUrl.length > 0) {
        url += `/${partesUrl.join("/")}`;
      }

      // Realizar la solicitud HTTP
      const response = await fetch(url);
      const data = await response.json();

      // Crear un conjunto completo de datos para todos los meses del año
      const mesesCompletos = Array.from({ length: 12 }, (_, i) => i + 1);

      // Transformación de datos
      const formattedData = mesesCompletos.map((mes) => {
        const item = data.find((d) => d.mes === mes);
        return {
          anio: selectedYear,
          mes,
          peso_total_vendido: item
            ? parseFloat(
                item.peso_total_vendido.replace(/\./g, "").replace(",", ".")
              )
            : 0,
        };
      });

      // Actualizar el estado con los datos formateados
      setChartData(formattedData);
      setLoading(false);
      // setFiltrandoDatos(false);
    } catch (error) {
      console.error("Error al obtener datos del gráfico:", error);
      setLoading(false);
      // setFiltrandoDatos(false);
    }
  };

  // En el componente del gráfico, filtrar los datos para los meses disponibles
  const mesesDisponibles = chartData.map((item) => item.mes);
  const seriesData = chartData
    .filter((item) => mesesDisponibles.includes(item.mes))
    .map((item) => item.peso_total_vendido);


  useEffect(() => {
    obtenerDatosGrafico();
    console.log("EJECUTO OBTENER DEL GRAFICO");
  }, []);

  console.log("Datos Deudores:", chartData)

  const series = [
    {
      data: seriesData,
      label: "kg Vendidos",
    },
  ];

  return (
    <>
      <div
        // style={{
        //   display: "flex",
        //   justifyContent: "center",
        //   alignItems: "center",
        //   height: "110%",
        //   width: "200%",
        // }}
      >
        {loading ? (
          <CircularProgress size={60} style={{ margin: "auto" }} />
        ) : (
          <>
            {chartData.length > 0 ? (
              <div 
              style={{ height: "100%", width: "100%" }}>
                {/* Renderizar el gráfico mensual */}
                <BarChart
                  xAxis={[
                    {
                      id: "Mes",
                      data: [
                        "Enero",
                        "Febrero",
                        "Marzo",
                        "Abril",
                        "Mayo",
                        "Junio",
                        "Julio",
                        "Agosto",
                        "Septiembre",
                        "Octubre",
                        "Noviembre",
                        "Diciembre",
                      ],
                      scaleType: "band",
                    },
                  ]}
                  series={series}
                  slotProps={{
                    legend: {
                      direction: "row",
                      position: {
                        vertical: "top",
                        horizontal: "middle",
                      },
                      itemMarkWidth: 10,
                      itemMarkHeight: 6,
                      markGap: 2,
                      itemGap: 5,
                    },
                  }}
                  style={{ height: "100%", width: "100%" }}
                  margin={{ left: 70, bottom: 100 }}
                />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <b>No hay datos disponibles para mostrar la gráfica.</b>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
