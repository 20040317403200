import React, { useState, useEffect, useMemo, useRef } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Snackbar,
  Alert,
  TextField,
  Autocomplete,
  Box,
} from "@mui/material";
import moment from 'moment';
import { logEventos } from "../../services/services";

function DialogEditarVehiculo(props) {
    const [openSnack, setOpenSnack] = React.useState(false);
    const [colormensaje, setColorMensaje] = useState("");
    const [mensaje, setMensaje] = useState("");
    const [abrirEditar, setAbrirEditar] = useState(props.editar);
    const [datosRow, setDatosRow] = useState(props.datosRow);
    const [vehiculos, setVehiculos] = useState(props.vehiculos)
    const [vehiculoSeleccionado, setVehiculoSeleccionado] = useState({})
   // console.log("datosrow", datosRow);

    const guardarVehiculo = async (e) => {
      logEventos("Guardar edición del vehiculo", "Viajes", "Se guardo la edición del vehiculo", localStorage.getItem("user"))
        e.preventDefault();
        await fetch(
            UrlApi +
              "/viajes_abastecimiento/" + datosRow.id_viaje,
            {
              // PUT SI YA EXISTE / POST SI ES NUEVO
              method: "PUT",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                vehiculo: vehiculoSeleccionado.id,
              }),
            }
          )
            .then((res) => {
              if (!res.err) {
                setOpenSnack(true);
                setColorMensaje("#D4EFDF");
                setMensaje("El vehículo se guardó exitosamente !");
              } else {
                setOpenSnack(false);
                setColorMensaje("#F1948A");
                setMensaje("Error al guardar el vehículo");
              }
            })
            .catch((err) => {
              console.log(err.message);
            });

        handleClose()    

    }

    const handleClose = () => {
        setAbrirEditar(!abrirEditar)
          if (props.onClose) {
              props.onClose();
            }
        };  
    
      //Snackbar Notificacion
    const handleCloseSnack = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setOpenSnack(false);
    };
  return (
    <>
    <Dialog
    open={abrirEditar}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    fullWidth
  >
    <DialogTitle id="alert-dialog-title" alignSelf="center">
      {"Editar Vehículo"}
    </DialogTitle>
    <DialogContent>
      <Container maxWidth="md">
        <form onSubmit={guardarVehiculo} >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} m={1} mt={2}>
              <Autocomplete
                // disabled={!props.articulos}
                size="small"
                focused
                disableClearable
                id="vehiculo"
                noOptionsText={"vehiculo INEXISTENTE"}
                options={vehiculos}
                autoHighlight
                getOptionLabel={(vehiculo) =>
                  vehiculo.id + "-" + vehiculo.patente
                }
                onChange={(event, value) =>
                  setVehiculoSeleccionado(value)
                }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    key={option.id}
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.id}-{option.patente}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    // style={style}
                    focused
                    required
                    size="small"
                    placeholder="Tipee para buscar..."
                    {...params}
                    label="Vehiculo"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            </Grid>
            </Grid>
            <Stack direction="row" marginTop={3}>
            <Grid container direction={{ xs: "column", sm: "row" }}>
              <Grid item xs={9}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleClose}
                >
                  Volver
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  fullWidth
                  variant="contained"
                  color="success"
                  type="submit"
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </form>
      </Container>
    </DialogContent>
  </Dialog>
  <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={openSnack}
        autoHideDuration={1500}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnack}
          severity="success"
          sx={{ width: "100%" }}
          style={{ backgroundColor: colormensaje, color: "black" }}
        >
          {mensaje}
        </Alert>
      </Snackbar>
    </Stack>
  </>
  )
}

export default DialogEditarVehiculo