import {useState} from 'react'

const useForm = (initialState ={}) => {

    const [DatosFormulario, setDatosFormulario] = useState(initialState)


    const onChange = ({target}) => {
        const {name ,value} = target
        setDatosFormulario({ ...DatosFormulario, [name]:value })
      };
      const handleChangeCheck = ({target}) => {
        const {name, checked} = target
        setDatosFormulario({ ...DatosFormulario, [name]:checked })
      };
    
  return {DatosFormulario,onChange,handleChangeCheck}
}

export default useForm