import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { UrlApi } from "../../../../../services/apirest";
import InputMask from "react-input-mask";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Notify from "../../../../Notify";
import Alerta from "../../../../Alerta";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AddBateria from "./AddBateria";

function DialogRetirarTanque(props) {
  const [sucursales, setSucursales] = useState([{}]);
  const [dataMovimiento, setDataMovimiento] = useState({
    id_tanque: props.tanque.id_tanque,
    id_sucursal: "",
    nro_remito: "",
    fecha_remito: "",
    id_direccionentrega: "",
    id_bateria: "",
  });
  const [destino, setDestino] = useState("");
  const [direcciones, setDirecciones] = useState(props.direcciones);
  const [baterias, setBaterias] = useState("");
  const [altaBateria, setAltaBateria] = useState(false);
  const [ultimaBateria, setearUltimaBateria] = useState(false);

  //Manejo de errores
  const [disBtnGuardar, setDisBtnGuardar] = useState(false);
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
  const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
  const [mensajeAlerta, setMensajeAlerta] = useState(""); //mensaje que contendrá el componente "alerta"


  const obtenerSucursales = () => {
    fetch(UrlApi + "/sucursales")
      .then((data) => data.json())
      .then((data) => setSucursales(data));
  };

  const obtenerBaterias = () => {
    fetch(UrlApi + "/baterias2/dir/" + dataMovimiento.id_direccionentrega)
      .then((data) => data.json())
      .then((data) => setBaterias(data));
  };

  useEffect(() => {
    obtenerSucursales();
  }, []);

  useEffect(() => {
    if (dataMovimiento.id_direccionentrega !== "") obtenerBaterias();
  }, [dataMovimiento.id_direccionentrega]);

  const almacenarDataMovimiento = (e) => {
    setDataMovimiento({
      ...dataMovimiento,
      [e.target.name]: e.target.value === " " ? null : e.target.value,
    });
  };

  //destino 1 -> sucursal
  //destino 2 -> otro domicilio
  useEffect(() => {
    destino === 1 &&
      setDataMovimiento({
        ...dataMovimiento,
        id_sucursal: props.tanque.id_sucursal,
        id_direccionentrega: "",
        id_bateria: "",
      });
    destino === 2 &&
      setDataMovimiento({
        ...dataMovimiento,
        id_sucursal: "",
        nro_remito: "",
        fecha_remito: "",
      });
  }, [destino]);

  useEffect(() => {
    if (
      ultimaBateria === true &&
      baterias &&
      baterias[0] &&
      baterias[0].idbateria
    ) {
      setDataMovimiento({
        ...dataMovimiento,
        id_bateria: baterias[0].idbateria,
      });
      setearUltimaBateria(false);
    }
  }, [ultimaBateria, baterias]);

  const guardar = (e) => {
    setDisBtnGuardar(true);
    if (destino === 1) {
      bajarRegistro(e);
    } else if (destino === 2) {
      cambiarDomicilio(e);
    } else {
      alert("Error. No hay destino seleccionado.");
    }
  };

  const borrarSel = () => {
    setDataMovimiento({ ...dataMovimiento, id_bateria: null });
    obtenerBaterias();
  };

  const handleClose = () => {
    props.setOpen(false);
  };


  async function cambiarDomicilio(e) {
    console.log(
      "MODIFICO TANQUES_CONTRATOS CAMBIANDO UBNICAMENTE EL DOMICILIO"
    );
    setDisBtnGuardar(true);
    e.preventDefault(e);
    const response = await fetch(
      UrlApi + "/tanques_contratos/" + props.tanque.id_tanquecontrato,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id_direccionentrega: dataMovimiento.id_direccionentrega,
          id_bateria:
            dataMovimiento.id_bateria === "" ? null : dataMovimiento.id_bateria,
          usuario: localStorage.getItem("user"),
        }),
      }
    );
    if (response.ok === false) {
      const error = response && response.json();
      setError(error);
      alert("ERROR AL GUARDAR");
    } else {
      try {
        const json = await response.json();
        if (json.message) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje(
            json.message === "OK"
              ? "El tanque se cambió de domicilio exitosamente!"
              : "El tanque se cambió de domicilio exitosamente! Actualizar RTA Api."
          );
          props.actualizar();
        } else if (json.sqlMessage) {
          props.actualizar();
          json && setError(json);
          alert("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
        } else {
          alert("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          props.actualizar();
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje(
            "El tanque se cambió de domicilio exitosamente! Actualizar RTA Api a Json"
          );
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO: " + error);
        }
      }
    }
  }

  async function bajarRegistro(e) {
    console.log("BAJO TANQUES_CONTRATOS");
    setDisBtnGuardar(true);
    e.preventDefault(e);
    const response = await fetch(
      UrlApi + "/tanques_contratos/" + props.tanque.id_tanquecontrato,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          baja: 1,
          usuario: localStorage.getItem("user"),
        }),
      }
    );
    if (response.ok === false) {
      const error = response && response.json();
      setError(error);
      alert("ERROR AL GUARDAR");
    } else {
      try {
        const json = await response.json();
        if (json.message) {
          updateTk(e);
          json.message !== "OK" &&
            console.log("res en api incorrecto. Revisar que sea 'OK'");
        } else if (json.sqlMessage) {
          json && setError(json);
          alert(
            "SQL erróneo (" +
              json.code +
              " Errno: " +
              json.errno +
              " ). No se pudo retirar el tanque."
          );
        } else {
          alert("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          updateTk(e);
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO: " + error);
        }
      }
    }
  }

  async function updateTk(e) {
    console.log("CAMBIO ESTADO DE TANQUE A DESINSTALADO");
    e.preventDefault(e);
    const response = await fetch(
      UrlApi + "/tanques/" + props.tanque.id_tanque,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id_estado: 2,
          id_sucursal:
            dataMovimiento.id_sucursal === ""
              ? null
              : dataMovimiento.id_sucursal,
          usuario: localStorage.getItem("user"),
        }),
      }
    );
    if (response.ok === false) {
      const error = response && response.json();
      setError(error);
      alert("ERROR AL GUARDAR");
    } else {
      try {
        const json = await response.json();
        if (json.message) {
          guardarMovimiento(e);
        } else if (json.sqlMessage) {
          props.actualizar();
          json && setError(json);
          alert("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
        } else {
          alert("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          guardarMovimiento(e);
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO: " + error);
        }
      }
    }
  }

  async function guardarMovimiento(e) {
    console.log("GUARDO MOVIMIENTO CON TANQUE EN SUCURSAL");
    e.preventDefault(e);
    const response = await fetch(UrlApi + "/movimientos_tanques/", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_tanque: dataMovimiento.id_tanque,
        id_sucursal:
          dataMovimiento.id_sucursal === "" ? null : dataMovimiento.id_sucursal,
        nro_remito:
          dataMovimiento.nro_remito === " " ? null : dataMovimiento.nro_remito,
        fecha:
          dataMovimiento.fecha_remito === ""
            ? null
            : dataMovimiento.fecha_remito,
        usuario: localStorage.getItem("user"),
      }),
    });
    if (response.ok === false) {
      const error = response && response.json();
      setError(error);
      alert("ERROR AL GUARDAR");
    } else {
      try {
        const json = await response.json();
        if (json.message) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje(
            json.message === "OK" ? "Guardado" : "Guardado. Actualizar RTA API."
          );
          props.actualizar();
        } else if (json.sqlMessage) {
          props.actualizar();
          json && setError(json);
          alert("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
        } else {
          alert("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          props.actualizar();
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje("Guardado");
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO: " + error);
        }
      }
    }
  }



  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => props.setOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        {notificacion ? (
          <Notify
            mensaje={mensaje}
            open={openSnack}
            color={"#D4EFDF"}
            severity={"success"}
            handleClose={handleClose}
          />
        ) : null}
        {error && mensajeAlerta ? (
          <Alerta mensaje={mensajeAlerta} severity={"error"} />
        ) : null}

        <Box backgroundColor={"#1F618D"}>
          <DialogTitle>
            <div>
              <Typography variant="h6" color="#ffffff">
                Cambio De Ubicación
              </Typography>
            </div>
          </DialogTitle>
        </Box>
        <DialogContent>
          <DialogContentText>
            Usted está a punto de desvincular el tanque{" "}
            <b>
              {" "}
              <u>N° Serie</u>: {props.tanque.nro_serie}{" "}
            </b>{" "}
            del{" "}
            <b>
              <u>domicilio:</u> {props.direccion.direccion_entrega}
            </b>
            . A continuación, indique el destino del tanque:
          </DialogContentText>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="center"
            p={3}
          >
            <Grid item xs={3}>
              <FormControl fullWidth focused required>
                <InputLabel>Destino</InputLabel>
                <Select
                  value={destino}
                  label="Destino"
                  onChange={(e) => setDestino(e.target.value)}
                >
                  <MenuItem value={1}>Sucursal</MenuItem>
                  <MenuItem value={2}>Otro Domicilio De Entrega</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {destino && destino === 1 ? (
              <>
                <Grid item xs={4}>
                  <FormControl fullWidth focused required>
                    <InputLabel>Sucursal</InputLabel>
                    <Select
                      name="id_sucursal"
                      value={dataMovimiento.id_sucursal}
                      label="Sucursal"
                      onChange={almacenarDataMovimiento}
                    >
                      {sucursales ? (
                        sucursales.map((elemento) => (
                          <MenuItem key={elemento.id} value={elemento.id}>
                            {elemento.nombre}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>Sucursales No Disponibles</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2.5}>
                  <InputMask
                    mask="99999-99999999"
                    value={dataMovimiento.nro_remito}
                    disabled={false}
                    maskChar=" "
                    onChange={almacenarDataMovimiento}
                  >
                    {() => (
                      <TextField
                        // style={style}
                        autoFocus
                        fullWidth
                        focused
                        name="nro_remito"
                        label="Nro Remito"
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={2.5}>
                  <TextField
                    fullWidth
                    type="date"
                    color="primary"
                    focused
                    value={dataMovimiento.fecha_remito}
                    label="Fecha Remito"
                    name="fecha_remito"
                    variant="outlined"
                    onChange={almacenarDataMovimiento}
                  />
                </Grid>
              </>
            ) : null}
            {destino === 2 ? (
              <>
                <Grid item xs={4}>
                  <FormControl fullWidth focused required>
                    <InputLabel>Direccion De Entrega</InputLabel>
                    <Select
                      name="id_direccionentrega"
                      value={dataMovimiento.id_direccionentrega}
                      label="Dirección De Entrega"
                      onChange={almacenarDataMovimiento}
                    >
                      {direcciones ? (
                        direcciones.map((elemento) => (
                          <MenuItem
                            key={elemento.id}
                            value={elemento.id}
                            disabled={props.direccion.id === elemento.id}
                          >
                            {elemento.direccion_entrega} (
                            {elemento.nombre_direccion})
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>Direcciones No Disponibles</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl
                    fullWidth
                    focused
                    disabled={dataMovimiento.id_direccionentrega === ""}
                  >
                    <InputLabel>Batería</InputLabel>
                    <Select
                      name="id_bateria"
                      value={dataMovimiento.id_bateria}
                      label="Batería"
                      onChange={almacenarDataMovimiento}
                    >
                      {baterias !== "" && baterias.length > 0
                        ? baterias.map((elemento) => (
                            <MenuItem
                              key={elemento.idbateria}
                              value={elemento.idbateria}
                            >
                              {elemento.nro} ({elemento.nombre})
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={1}>
                  <Tooltip title="Borrar Selección">
                    <span>
                      <IconButton
                        disabled={!baterias}
                        color="primary"
                        size="large"
                        onClick={() => borrarSel()}
                      >
                        <DeleteIcon fontSize="inherit" />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs={1}>
                  <Tooltip title="Nueva Bateria">
                    <span>
                      <IconButton
                        disabled={!dataMovimiento.id_direccionentrega}
                        color="primary"
                        size="large"
                        onClick={(e) => setAltaBateria(true)}
                      >
                        <AddBoxIcon fontSize="inherit" />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Grid>
              </>
            ) : null}
          </Grid>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="center"
          ></Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item>
              <Button variant="outlined" color="error" onClick={handleClose}>
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="success"
                onClick={(e) => guardar(e)}
                disabled={
                  disBtnGuardar ||
                  !destino ||
                  (destino === 1 && !dataMovimiento.id_sucursal) ||
                  (destino === 2 && !dataMovimiento.id_direccionentrega)
                }
              >
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      {altaBateria ? (
        <>
          <AddBateria
            open={altaBateria}
            setOpen={setAltaBateria}
            datos={dataMovimiento.id_direccionentrega} //el id de la direccion donde ira la bateria
            cliente={props.cliente} //para tomar el sisven_id
            actualizar={obtenerBaterias}
            setearUltimaBateria={setearUltimaBateria}
          />
        </>
      ) : null}
    </div>
  );
}

export default DialogRetirarTanque;
