//----- Component --------------------------------------------------------/
// Developer : Nicolas Pascucci
// Creation Date : 21/05/2024
// Version : 1
// Description : dialog para editar el objetivo mensual del comercial
//------Changes --------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect } from "react";
import { UrlApi } from "../../../services/apirest";
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  Box,
  Grid,
  Stack,
  TextField,
  Autocomplete,
  Snackbar,
  Alert,
} from "@mui/material";
import moment from "moment"; // PARA LA FECHA
//adjuntado
import AttachFileIcon from "@mui/icons-material/AttachFile"; //ADJUNTAR ARCHIVOS
import { grey } from "@mui/material/colors";
import Title from "../../Title";
import { v4 as uuidv4 } from "uuid"; 
import { logEventos } from "../../../services/services";
import "dayjs/locale/es";

function AgregaMonto(props) {
    const [editar, setEditar] = useState(props.editar)
    const [datosRow, setDatosRow] = useState(props.datosRow)
    const [datosObjetivo, setDatosObjetivo] = useState({
        objetivo: null,
    })
    const [openSnack, setOpenSnack] = React.useState(false);
    const [colormensaje, setColorMensaje] = useState("");
    const [mensaje, setMensaje] = useState("");
    //Snackbar Notificacion
    const handleCloseSnack = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setOpenSnack(false);
    };

    const handleClose = () => {
        setEditar(!editar);
      if (props.onClose) {
        props.onClose();
      }
    };

    const almacenar = (e) => {
        setDatosRow({
          ...datosRow,
          [e.target.name]: e.target.value,
        });
      };

      // console.log("DATOS ROW", datosRow);

    const EditarObjetivo = async (e) => {
      logEventos("Editar objetivo de un comercial", "Evaluacion Comerciales", "Edito objetivo de un comercial", localStorage.getItem("user"))
        console.log("Estoy por guardar");
        e.preventDefault();
        await fetch(UrlApi + "/objetivos_comerciales/" + datosRow.id_objetivo, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            monto_pesos: datosRow.monto_pesos
          }),
        })
          .then((res) => {
            if (!res.err) {
              setOpenSnack(true);
              // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
              setColorMensaje("#D4EFDF");
              setMensaje("El objetivo se editó exitosamente !");
              console.log("Guardado");
            } else {
              setOpenSnack(false);
              setColorMensaje("#F1948A");
              setMensaje("Error al editar el objetivo");
              console.log("Error");
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
          handleClose()
      };
  return (
    <>
    <Dialog
      open={editar}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      color="#F4F6F6"
    >
      <Title
        titulo={`Agregar Monto`}
        handleClose={() => {
          handleClose();
        }}
      />
      <Box
        border={0.5}
        borderColor={grey[300]}
        backgroundColor="#F4F6F6"
        p={2}
        py={2}
        mt={1}
      >
        <DialogContent>
          <Container maxWidth="xl">
            <form autoComplete="off" 
            onSubmit={EditarObjetivo}
            >
              <Grid container mb={1} spacing={1}>
                {/* <Grid item xs={12} py={1} mb={1}>
                <TextField
                    label="Objetivo Mensual"
                    name="objetivo"
                    size="small"
                    placeholder="..."
                    onKeyPress={(event) => {
                      if (!/[0-9.]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    inputProps={{
                      maxLength: 10,
                    }}
                    focused
                    required
                    fullWidth
                    value={datosRow.objetivo}
                    onChange={almacenar}
                  />
                </Grid> */}
                <Grid item xs={12} py={1} mb={1}>
                <TextField
                    label="Monto en $"
                    name="monto_pesos"
                    size="small"
                    placeholder="..."
                    onKeyPress={(event) => {
                      if (!/[0-9.]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    inputProps={{
                      maxLength: 10,
                    }}
                    focused
                    required
                    fullWidth
                    value={datosRow.monto_pesos}
                    onChange={almacenar}
                  />
                </Grid>
              </Grid>
              <Stack direction="row">
                <Grid container direction={{ xs: "column", sm: "row" }}>
                  <Grid item xs={8}>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleClose}
                    >
                      Volver
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Stack>
            </form>
          </Container>
        </DialogContent>
      </Box>
    </Dialog>

    <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>
  </>
  )
}

export default AgregaMonto