import React, { useState, useEffect } from 'react'
import { FormControlLabel, Grid, MenuItem, Select, TextField, InputLabel, Container, Box, Button, FormControl } from '@mui/material'
import { UrlApi } from '../../../services/apirest'
import axios from 'axios'
import FileUploadMultiple from '../../../services/UploadFiles';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { getData } from '../../../services/library';
function FormCondicionImp(props) {

  const { onChange, DatosFormulario, almacenarChecks } = props


  const [iva, setiva] = useState()
  const [Motivo, setMotivo] = useState([]);
  const [ImpuestoProv, setImpuestoProv] = useState([])
  const [openclose, setopenclose] = useState(false)
  const [tipoPersona, settipoPersona] = useState([])
  const [tipoOperacion, settipoOperacion] = useState([])

  useEffect(() => {
    getData('/desplegables/impuestoProveedor', setImpuestoProv);
    getData('/desplegables/tipoPersona', settipoPersona)
    getData('/tipo_operacion', settipoOperacion)
    obtenerIva();
  }, []);
  useEffect(() => {
    getData('/desplegables/motivoImpuestos', setMotivo);
  }, [ImpuestoProv]);

  const obtenerIva = async () => {
    await axios.get(UrlApi + '/condiciones_iva').then(response => setiva(response.data))
  };
  const style = {
    backgroundColor: "#0000",
    width: "100%",
    borderRadius: '2px',
    borderColor: '#0000',

  };

  return (
    <>
      <Grid container spacing={2}>



        <Grid item xs={3} >
          <FormControl style={style} focused>
            <InputLabel>I.V.A</InputLabel>
            <Select
              name='id_condicioniva'
              value={DatosFormulario.id_condicioniva}
              onChange={onChange}
              fullWidth
              required
              label="I.V.A"
            >
              {iva ? iva.map(elemento => (
                <MenuItem key={elemento.id} value={elemento.id}>
                  {elemento.descripcion}
                </MenuItem>
              )) : null}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3} >
          <FormControl style={style} focused>
            <InputLabel>Impuesto a la Ganancia</InputLabel>
            <Select
              name='id_impuestoganancia'
              value={DatosFormulario.id_impuestoganancia}
              onChange={onChange}
              fullWidth

              label="Impuesto a la Ganancia"
            >
              {ImpuestoProv ? ImpuestoProv.map(elemento => (
                <MenuItem key={elemento.id} value={elemento.id}>
                  {elemento.valor}
                </MenuItem>
              )) : null}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={3} >
          <FormControl style={style} focused>
            <InputLabel>Impuesto al Valor Agregado</InputLabel>
            <Select
              name='id_impuestoalvalor'
              value={DatosFormulario.id_impuestoalvalor}
              onChange={onChange}
              fullWidth

              label="Impuesto al Valor Agregado"
            >
              {ImpuestoProv ? ImpuestoProv.map(elemento => (
                <MenuItem key={elemento.id} value={elemento.id}>
                  {elemento.valor}
                </MenuItem>
              )) : null}
            </Select>
          </FormControl>
        </Grid>
        {DatosFormulario.id_impuestoalvalor === 206 || DatosFormulario.id_impuestoganancia === 206 ?
          <>

            <Grid item xs={3} >
              <FormControl style={style} focused>
                <InputLabel>Motivo</InputLabel>
                <Select
                  name='id_motivo'
                  value={DatosFormulario.id_motivo}
                  onChange={onChange}
                  fullWidth
                  label="Motivo"
                >
                  {Motivo ? Motivo.map(elemento => (
                    <MenuItem key={elemento.id} value={elemento.id}>
                      {elemento.valor}
                    </MenuItem>
                  )) : null}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={2}
            >
              <TextField
                id="fecha Vto"
                label="Vto Certificado"
                fullWidth
                focused
                name='vto_certificado'
                value={DatosFormulario.vto_certificado}
                onChange={onChange}
                type='date'
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </> : null}
        <Grid item xs={2}>
          <Button
            onClick={() => setopenclose(!openclose)}
            variant="outlined"
            color="primary"
            fullWidth
            startIcon={<AttachFileIcon />}>
            Carga de Documentos
          </Button>
        </Grid>


        <Grid item xs={3} >
          <FormControl style={style} focused>
            <InputLabel>Tipo de Operacion</InputLabel>
            <Select
              name='id_tipo_operacion'
              value={DatosFormulario.id_tipo_operacion}
              onChange={onChange}
              fullWidth

              label="Tipo de Operacion"
            >
              {tipoOperacion ? tipoOperacion.map(elemento => (
                <MenuItem key={elemento.id} value={elemento.id}>
                  {elemento.descripcion}
                </MenuItem>
              )) : null}
            </Select>
          </FormControl>
        </Grid>
        {DatosFormulario.id_tipo_operacion === 5 ? <>
          <Grid item xs={3}>
            <TextField
              focused
              style={style}
              placeholder="Motivo Otro"
              type="text"
              name="motivo_otro"
              label="Motivo Otro"
              onChange={onChange}
              value={DatosFormulario.motivo_otro}
              inputProps={{ maxLength: 22 }}

            />
          </Grid>
        </> : null}
        <Grid item xs={3} >
          <FormControl style={style} focused>
            <InputLabel>Tipo Persona</InputLabel>
            <Select
              name='id_tipopersona'
              value={DatosFormulario.id_tipopersona}
              onChange={onChange}
              fullWidth

              label="Tipo Persona"
            >
              {tipoPersona ? tipoPersona.map(elemento => (
                <MenuItem key={elemento.id} value={elemento.id}>
                  {elemento.valor}
                </MenuItem>
              )) : null}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {openclose ? <FileUploadMultiple fileId={DatosFormulario.uuid_proveedor} openclose={true} /> : null}

    </>

  )
}

export default FormCondicionImp