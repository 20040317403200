// Developer     : Magalí Perea
// Creation Date : 2024-04-22
// Version       : 1
// Description   : Formulario dónde se escoge empresa, fecha de inicio y fecha de fin para poder listar la CANTIDAD de viandas pedidas por cada empleado en esas fechas.

import React, { useEffect, useState } from "react";
import Navbar from "../../Navbar";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { TextField, Button, MenuItem, FormControl, Container } from "@mui/material";
import { UrlApi } from "../../../services/apirest";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import ListaPedidosGeneral from "./ListaPedidosGeneral";

function FormResumenXFecha() {
  const [showForm, setShowForm] = useState(true);
  const [showList, setShowList] = useState(false);
  const [sucursales, setSucursales] = useState([{}]);

  const [reqData, setReqData] = useState({
    id_sucursal: "",
    fecha_inicio: "",
    fecha_fin: "",
  });

  const [pedidos, setPedidos] = useState([{}]);

  const obtenerPedidos = () => {
    fetch(
      UrlApi +
        `/viandas/contadorviandas/${reqData.id_sucursal}/${reqData.fecha_inicio}/${reqData.fecha_fin}`
    )
      .then((data) => data.json())
      .then((data) => {
        if (data.length === 0) {
          alert(
            "No hay registros de viandas pedidas en las fechas seleccionadas."
          );
        } else {
          setPedidos(data);
          setShowForm(false);
          setShowList(true);
        }
      });
  };

  const obtenerSucursales = () => {
    fetch(UrlApi + "/sucursales")
      .then((data) => data.json())
      .then((data) =>
        setSucursales(
          data.filter((sucursal) => sucursal.id === 1000 || sucursal.id === 8)
        )
      );
  };

  useEffect(() => {
    obtenerSucursales();
  }, []);

  return (
    <div>
      <Container maxWidth="xl" >
      <Navbar />
      {showList && <ListaPedidosGeneral setShowList={setShowList} setShowForm={setShowForm}  pedidos={pedidos} reqData={reqData} />}
      {showForm && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          {/* {showForm && ( */}
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              "& > :not(style)": {
                width: "60vh",
                height: "70vh",
              },
            }}
          >
            <Paper elevation={10} sx={{ backgroundColor: "#ebebeb" }}>
              <Grid container spacing={1} direction="row" p={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    color="initial"
                    textAlign="center"
                  >
                    Resumen Viandas Por Fechas
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    required
                    fullWidth
                    style={{ backgroundColor: "#fff" }}
                    focused
                  >
                    <InputLabel>Sucursal</InputLabel>
                    <Select
                      name="tipo"
                      value={reqData.id_sucursal}
                      label="Sucursal"
                      onChange={(e) =>
                        setReqData({ ...reqData, id_sucursal: e.target.value })
                      }
                    >
                      {sucursales &&
                        sucursales.map((sucursal) => (
                          <MenuItem key={sucursal.id} value={sucursal.id}>
                            {sucursal.nombre}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    focused
                    fullWidth
                    sx={{ backgroundColor: "#fff" }}
                    id=""
                    label="Fecha Inicio"
                    type="date"
                    onChange={(e) =>
                      setReqData({ ...reqData, fecha_inicio: e.target.value })
                    }
                    value={reqData.fecha_inicio}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    focused
                    fullWidth
                    sx={{ backgroundColor: "#fff" }}
                    id=""
                    label="Fecha Fin"
                    type="date"
                    onChange={(e) =>
                      setReqData({ ...reqData, fecha_fin: e.target.value })
                    }
                    value={reqData.fecha_fin}
                    inputProps={{
                      min: reqData.fecha_inicio, // Aquí debes especificar la función o valor que defina tu fecha mínima
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="row" justifyContent="center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={obtenerPedidos}
                      disabled={!reqData.fecha_inicio || !reqData.fecha_fin}
                    >
                      consultar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Box>
          {/* )} */}
        </div>
      )}
        
      </Container>
    </div>
  );
}

export default FormResumenXFecha;
