import React, { useEffect, useState } from 'react'
import { UrlApi } from '../../../services/apirest';
import { FormControl, InputLabel, MenuItem, Select, Snackbar, SnackbarContent, Typography, IconButton, Box } from '@mui/material'
import MuiAlert from '@mui/material/Alert';
import { Save } from '@mui/icons-material';

export default function GestionApps(props) {

    console.log('props1', props)
    const [editado, setEditado] = useState(false)
    const [tta, setTta] = useState([]);
    const [transportista, setTransportista] = useState(props.wtta);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        obtenerTransportista()
    }, [])

    const obtenerTransportista = async () => {
        try {
            const response = await fetch(UrlApi + "/transportistas/activos");
            const ttadata = await response.json();
            setTta(ttadata);
        } catch (error) {
            mostrarSnackbar('Error al obtener transportitas', 'error');
            console.error('Error al obtener transportitas:', error);
        }
    };

    const grabarApps = async () => {
        await fetch(UrlApi + "/userdata/" + props.wid, {
            method: 'PUT', headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                { id_transportista: transportista }
            )
        })
            .then(response => {
                if (response.status === 409) {
                    console.log('Hubo un conflicto en el servidor (código 409)');
                    return response.json().then(data => {
                        console.log('Mensaje recibido del servidor:', data.msg);
                        throw new Error(data.msg); // Lanzar un error con el mensaje devuelto por el backend
                    });
                }
                if (!response.ok) {
                    console.log('Hubo un problema al realizar la solicitud (código ' + response.status + ')');
                    throw new Error('Hubo un problema al registrar el usuario.'); // Manejo de otros errores en caso de que la respuesta del servidor no sea exitosa
                }
                console.log('La solicitud fue exitosa.');
                return response.json(); // Convertir la respuesta a JSON
            })
            .then(data => {
                // Manejar la respuesta exitosa del servidor
                console.log('Mensaje recibido del servidor:', data.msg);
                mostrarSnackbar(data.msg, "success")
            })
            .catch(error => {
                console.error('Error:', error.message);
                mostrarSnackbar(error.message, "success")
            })
    }

    const mostrarSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                border: '1px solid violet',
                borderRadius: '5px',
                padding: '0px',
                backgroundColor: 'white',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                height: 40

            }}>
                <Typography variant="h6" mr={1} ml={1}>
                    Configuracion Aplicaciones
                </Typography>
                <Box sx={{ display: 'flex' }}>
                    {editado &&
                        <IconButton onClick={() => grabarApps()}>
                            <Save color='success' />
                        </IconButton>}
                </Box>
            </Box>

            <Box sx={{ marginTop: '10px', height: 40, padding: '5px' }}>
                <FormControl fullWidth>
                    <InputLabel>Transportista</InputLabel>
                    <Select
                        size='small'
                        id="transportista"
                        value={transportista || 0}
                        name='transportista'
                        label='Transportista'
                        onChange={(e) => [setTransportista(e.target.value), setEditado(true)]}
                    >
                        {tta ? tta.map(elemento => (
                            <MenuItem key={elemento.id_transportista} value={elemento.id_transportista}>
                                {elemento.nombre}
                            </MenuItem>
                        )) : null}
                    </Select>
                </FormControl>
            </Box>


            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                sx={{ marginTop: '50px', marginRight: '10px' }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Cambia la posición aquí
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseSnackbar}
                    severity={snackbarSeverity}
                >
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </>
    )
}
