/* eslint-disable no-unreachable */
import React, { useCallback, useEffect, useState } from 'react'
import { UrlApi } from '../../../services/apirest';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { Marker, InfoWindow } from '@react-google-maps/api';
import iconoSucursal from '../../../img/sucursal.png'
import iconoVenta1 from '../../../img/i1.png'
import iconoVenta2 from '../../../img/i2.png'
import iconoVenta3 from '../../../img/i3.png'
import iconoVenta4 from '../../../img/i4.png'
import iconoVenta5 from '../../../img/i5.png'
import iconoVenta6 from '../../../img/i6.png'
import iconoVenta7 from '../../../img/i7.png'
import iconoVenta8 from '../../../img/i8.png'
import Navbar from '../../Navbar';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Checkbox, Container, Divider, Drawer, FormControl, FormControlLabel, FormLabel, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { Person } from '@mui/icons-material';

const containerStyle = {
    width: '100%',
    height: '800px',
    marginTop: '10px',
    marginLeft: '10px'
};

const center = { lat: -36.091844, lng: -65.038840 }

function MapaVentasEnvasado() {
    const [map, setMap] = useState(null)
    const [activeMarker, setActiveMarker] = useState(null)
    const [provincia, setProvincia] = useState("-- TODAS --")
    const [mes, setMes] = useState(new Date().getMonth() + 1);
    const [year, setYear] = useState(new Date().getFullYear());
    const [desde, setDesde] = useState(0)
    const [hasta, setHasta] = useState(10000000)
    const [ventas, setVentas] = useState(true)
    const [hiperGas, setHiperGas] = useState(true);
    const [autoGas, setAutoGas] = useState(true);
    const [filtroopen, setFiltroOpen] = useState(false)
    const [datosmapa, setDatosMapa] = useState()

    var provincias = ["-- TODAS --", "BUENOS AIRES", "CORRIENTES", "CORDOBA", "TUCUMAN", "SANTA FE", "ENTRE RIOS", "CAPITAL", "MISIONES", "SANTIAGO DEL ESTERO", "RIO NEGRO", "SAN JUAN", "LA RIOJA", "CHACO", "FORMOSA", "LA PAMPA", "SALTA", "CHUBUT", "MENDOZA", "JUJUY", "SAN LUIS", "CATAMARCA", "NEUQUEN"].sort();
    var meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

    const generateYears = () => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let i = 0; i < 6; i++) {
            years.push(currentYear - i);
        }
        return years;
    };

    useEffect(() => {
        obtenerDatos()
    }, [mes, year])

    const obtenerDatos = async () => {
        const data = await fetch(`${UrlApi}/mapaventasenvasado/${mes}/${year}`);
        const mapadata = await data.json()
        setDatosMapa(mapadata)
    }


    const handleDrawerToggle = () => {
        //obtenerUsuarios()
        //setNuevo(false)
        console.log(datosmapa)
        setFiltroOpen(!filtroopen);
    };

    const options = {
        mapTypeControl: false,
        fullscreenControl: false,
        //streetViewControl: false,
        //zoomControl: false
    }


    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_Google_maps_key
    })

    const onMarkerClick = (marker) => {
        console.log(marker)

        if (marker === activeMarker) {
            return;
        }
        setActiveMarker(marker);
    }



    const onLoad = useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        //const bounds = new window.google.maps.LatLngBounds(center);
        //map.fitBounds(bounds);
        map.setZoom(5)
        setMap(map)
    }, [])

    const onUnmount = useCallback(function callback(map) {
        setMap(null)
    }, [])

    const iconoVenta = (kilos) => {
        switch (true) {
            case kilos < 1000:
                return iconoVenta1;
                break;
            case kilos < 2000:
                return iconoVenta2;
                break;
            case kilos < 5000:
                return iconoVenta3;
                break;
            case kilos < 7000:
                return iconoVenta4;
                break;
            case kilos < 10000:
                return iconoVenta5
                break;
            case kilos < 20000:
                return iconoVenta6;
                break;
            case kilos < 100000:
                return iconoVenta7;
                break;
            case kilos === 0:
                return iconoVenta8;
                break;
            default:
                return null;
                break;
        }
    }


    let iconoVenta8;
    try {
        iconoVenta8 = require('../../../img/sucursal.png');
        console.log('Imagen de iconoVenta8 cargada correctamente.');
    } catch (error) {
        console.error('Error al cargar la imagen de iconoVenta8:', error);
    }


    return isLoaded ? (
        <>
            <Navbar />
            <div style={{
                position: 'absolute',
                top: '60px',
                left: '15px',
                zIndex: '1000',
                backgroundColor: 'white',
                padding: '0px',
                border: '1px solid #ccc',
                borderRadius: '5px'
            }}>
                <IconButton color='error' onClick={handleDrawerToggle}>
                    <FilterAltIcon />
                </IconButton>
            </div>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={6}
                onLoad={onLoad}
                onUnmount={onUnmount}
                options={options}
            >
                {/* Child components, such as markers, info windows, etc. */}
                {datosmapa && datosmapa.filter(item =>
                    (ventas ? (provincia === "-- TODAS --" || item.provincia === provincia) && (item.butano + item.propano) >= desde && (item.butano + item.propano) <= hasta : (provincia === "-- TODAS --" || item.provincia === provincia) && (item.butano + item.propano) >= desde && (item.butano + item.propano) <= hasta && item.sucursal === "false")
                    && (hiperGas ? true : item.empresa !== "Hipergas")
                    && (autoGas ? true : item.empresa !== "Autogas")
                ).map((dato, index) => (
                    <React.Fragment key={index}>
                        <MarkerF
                            position={{ lat: dato.latitud * 1, lng: dato.longitud * 1 }}
                            icon={dato.sucursal === "false" ? iconoSucursal : iconoVenta(dato.butano + dato.propano)}
                            onClick={() => onMarkerClick(index)}
                        />
                        {activeMarker === index && (
                            <InfoWindow position={{ lat: dato.latitud * 1, lng: dato.longitud * 1 }} onCloseClick={() => setActiveMarker(null)}>
                                <div>
                                    <b>{dato.localidad}/{dato.provincia}</b>
                                    <table border={"1px solid"}>
                                        <tbody>
                                            <tr><th>Producto</th><th>VentaKgrs</th><th>Potencial</th><th>Porcentaje</th></tr>
                                            <tr>
                                                <td>Butano</td>
                                                <td align='right'>{dato.butano}</td>
                                                <td align='right'>{dato.potbutano}</td>
                                                <td align='right'>{isFinite((dato.butano / dato.potbutano * 100)) ? (dato[6] / dato[8] * 100).toFixed(2) : 0}%</td>
                                            </tr>
                                            <tr>
                                                <td>Propano</td>
                                                <td align='right'>{dato.propano}</td>
                                                <td align='right'>{dato.potpropano}</td>
                                                <td align='right'>{isFinite((dato.propano / dato.potpropano * 100)) ? (dato.propano / dato.potpropano * 100).toFixed(2) : 0}%</td>
                                            </tr>
                                            <tr>
                                                <td>Total</td>
                                                <td align='right'>{dato.butano + dato.propano}</td>
                                                <td align='right'>{dato.potbutano + dato.potpropano}</td>
                                                <td align='right'>{isFinite(((dato.butano + dato.propano) / (dato.potbutano + dato.potpropano) * 100)) ? ((dato.butano + dato.propano) / (dato.potbutano + dato.potpropano) * 100).toFixed(2) : 0}%</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </InfoWindow>
                        )}
                    </React.Fragment>
                ))}
            </GoogleMap>
            <Drawer
                anchor="right"
                open={filtroopen}
                onClose={handleDrawerToggle}
                PaperProps={{ style: { width: 200, backgroundColor: 'lightgoldenrodyellow' } }}
                transitionDuration={{ enter: 600, exit: 300 }}
            >
                <Container sx={{ marginTop: '60px' }}>
                    <Typography variant="h5" component="h2" gutterBottom>Filtros</Typography>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="select-mes-label">Mes</InputLabel>
                        <Select
                            size='small'
                            labelId="select-mes-label"
                            id="select-mes"
                            value={mes}
                            onChange={(e) => setMes(e.target.value)}
                            label='Mes'
                        >
                            {meses.map((m, index) => (
                                <MenuItem key={index} value={index + 1}>{m}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="select-year-label">Año</InputLabel>
                        <Select
                            size='small'
                            labelId="select-year-label"
                            id="select-year"
                            value={year}
                            onChange={(e) => setYear(e.target.value)}
                            label='Año'
                        >
                            {generateYears().map((y, index) => (
                                <MenuItem key={index} value={y}>{y}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField size='small' label="Kgrs-Desde" type="number" value={desde} onChange={(e) => setDesde(e.target.value)} fullWidth margin="normal" />
                    <TextField size='small' label="Kgrs-Hasta" type="number" value={hasta} onChange={(e) => setHasta(e.target.value)} fullWidth margin="normal" />
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="select-provincia-label">Provincia</InputLabel>
                        <Select
                            size='small'
                            labelId="select-provincia-label"
                            id="select-provincia"
                            value={provincia}
                            onChange={(e) => setProvincia(e.target.value)}
                            label='Provincia'
                        >
                            {provincias.map((prov, index) => (
                                <MenuItem key={index} value={prov}>{prov}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControlLabel control={<Checkbox checked={hiperGas} onChange={e => setHiperGas(e.target.checked)} />} label="HiperGas" />
                    <FormControlLabel control={<Checkbox checked={autoGas} onChange={e => setAutoGas(e.target.checked)} />} label="NaturalGas" />

                    <FormControlLabel control={<Checkbox checked={ventas} onChange={e => setVentas(!ventas)} />} label="Ventas x localidad" />
                </Container>
            </Drawer>
        </>
    ) : <></>;

}

export default React.memo(MapaVentasEnvasado)