import { Save } from '@mui/icons-material'
import { AppBar, Box, Checkbox, FormControlLabel, IconButton, Snackbar, Tab, Tabs, Typography } from '@mui/material'
import MuiAlert from '@mui/material/Alert';
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { UrlApi } from '../../../services/apirest';

export default function GestionMenues(props) {
    console.log('props1', props)
    const [editado, setEditado] = useState(false)
    const [menus, setMenus] = useState([]);
    const [value, setValue] = useState(0);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        obtenerMenu();
    }, []);

    const Menu = ({ menuData, parentId, updateItem }) => {
        const filteredMenus = menuData.filter(menu => menu.menu_padre_id === parentId);

        return (
            <ul style={{ listStyle: 'none', padding: 0 }}>
                {filteredMenus.map((menu, index) => (
                    <li key={menu.id} sx={{ listStyle: 'none' }}>
                        <FormControlLabel
                            variant="dense"
                            control={
                                <Checkbox
                                    value={menu.id}
                                    checked={menu.permitido === 1}
                                    onChange={e => updateItem(e, menu.id)}
                                />
                            }
                            label={menu.menu}
                            sx={{ margin: '0px' }} // Ajusta el margen aquí
                        />
                        <Menu menuData={menuData} parentId={menu.id} updateItem={updateItem} />
                    </li>
                ))}
            </ul>
        );
    };

    const obtenerMenu = async () => {
        try {
            const response = await fetch(UrlApi + "/menues/todos/" + props.wusername);
            const menuData = await response.json();
            setMenus(menuData);
        } catch (error) {
            mostrarSnackbar('Error al obtener menús', 'error');
            console.error('Error al obtener menús:', error);
        }
    };

    const updateItem = (event, id) => {
        const menuIndex = menus.findIndex(menu => menu.id === id);
        const old = menus[menuIndex];
        const updated = {
            ...old,
            'permitido': event.target.checked ? 1 : 0,
            'username': props.wusername,
            'id_menu': menus[menuIndex].idmenu,
            'usuario_registra': localStorage.getItem("user"),
            'favorito': menus[menuIndex].favorito === null && 0
        };
        const clone = [...menus];
        clone[menuIndex] = updated;

        // Verificar si hay algún hijo seleccionado
        const hasSelectedChild = menus.some(menu => menu.menu_padre_id === id && menu.permitido === 1);

        // Actualizar el menú padre
        const menuPadreIndex = menus.findIndex(menu => menu.id === old.menu_padre_id);
        if (menuPadreIndex !== -1) {
            const menuPadre = menus[menuPadreIndex];
            const hasSelectedChildren = menus.some(menu => menu.menu_padre_id === menuPadre.id && menu.permitido === 1);
            clone[menuPadreIndex] = {
                ...menuPadre,
                'permitido': hasSelectedChildren ? 1 : 0,
                'usuario_registra': localStorage.getItem("user"),
                'username': props.wusername,
                'favorito': 0,
            };
        }

        setMenus(clone);
        setEditado(true)  ///Para habilitar el icono  para grabar
    };

    const grabarMenues = async (e) => {
        e.preventDefault();

        // Filtrar solo los menús seleccionados
        const menusSeleccionados = menus.filter(menu => menu.permitido === 1);

        // Obtener los IDs de los menús seleccionados
        const idsMenusSeleccionados = menusSeleccionados.map(menu => menu.id);

        // Marcar los padres con permitido=1 si tienen al menos un hijo seleccionado
        const menusAInsertar = menus.map(menu => {
            if (menu.menu_padre_id === null) {
                return {
                    ...menu,
                    permitido: menus.some(child => child.menu_padre_id === menu.id && child.permitido === 1) ? 1 : 0,
                };
            }
            return menu;
        });

        // Filtrar solo los menús que se van a insertar
        const menusFiltrados = menusAInsertar.filter(menu => menu.permitido === 1);

        try {
            // Eliminar menús existentes
            const deleteResponse = await fetch(UrlApi + "/menues/" + props.wusername, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' }
            });

            if (!deleteResponse.ok) {
                throw new Error(`Error al borrar menús existentes: ${deleteResponse.statusText}`);
            }

            // Insertar menús seleccionados
            const postResponse = await fetch(UrlApi + "/menues", {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(menusFiltrados)
            });

            if (!postResponse.ok) {
                throw new Error(`Error al enviar menús a la API: ${postResponse.statusText}`);
            }

            mostrarSnackbar('Se guardaron los menús seleccionados', 'success');
        } catch (error) {
            mostrarSnackbar('¡Ups! Algo salió mal', 'error');
            console.error('¡Ups! Algo salió mal:', error);
        }
    };

    const mostrarSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // Filtrar solo los menús padres
    const menuPadres = menus.filter(menu => menu.menu_padre_id === null);



    return (
        <div>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                border: '1px solid violet',
                borderRadius: '5px',
                padding: '0px',
                backgroundColor: 'white',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                height: 40

            }}>
                <Typography variant="h6" mr={1} ml={1}>
                    Menues habilitados
                </Typography>
                <Box sx={{ display: 'flex' }}>
                    {editado &&
                        <IconButton onClick={grabarMenues}>
                            <Save color='success' />
                        </IconButton>}
                </Box>
            </Box>
            <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 600, marginTop: '10px' }}>
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                    textColor="inherit"  // Cambia el color del texto de las pestañas
                    indicatorColor="secondary"  // Cambia el color de la línea indicadora
                >
                    {menuPadres.map((menuPadre, index) => (
                        <Tab key={index} label={menuPadre.menu} sx={{ alignItems: 'start' }} />
                    ))}
                </Tabs>

                {menuPadres.map((menuPadre, index) => (
                    <TabPanel key={index} value={value} index={index}>
                        <Menu menuData={menus} parentId={menuPadre.id} updateItem={updateItem} />
                    </TabPanel>
                ))}
            </Box>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                sx={{ marginTop: '50px', marginRight: '10px' }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Cambia la posición aquí
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseSnackbar}
                    severity={snackbarSeverity}
                >
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </div>
    )
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
            sx={{ marginTop: '0px', p: 0 }} // Ajusta el margen superior aquí
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}