import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { UrlApi } from "../../../../../services/apirest";
import IconButton from "@mui/material/IconButton";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import PropaneIcon from "@mui/icons-material/Propane";
import ForwardIcon from "@mui/icons-material/Forward";
import DialogRetirarTanque from "./DialogRetirarTanque";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import PanToolAltIcon from "@mui/icons-material/PanToolAlt";
import Tooltip from "@mui/material/Tooltip";
import GestionBat from "./GestionBat";
import DeleteIcon from "@mui/icons-material/Delete";
import { putData } from "../../../../../services/library";
import Notify from "../../../../Notify";
import ConfirmDialog from "../../../../Dialogs/ConfirmDialog";
import DialogAsociarTq from "./DialogAsociarTq"

function TanquesEnDireccion(props) {
  // console.log("PROPS QUE ME LLEGAN A TANQUES EN DIRECCION "  , props)
  const [tanques, setTanques] = useState();
  const [retirar, setRetirar] = useState(false);
  const [tqARetirar, setTqARetirar] = useState(false);
  const [dialogBat, setDialogBat] = useState(false);
  const [openDialogEliminar, setOpenDialogEliminar] = useState(false);
  const [registro, setRegistro] = useState({});
  const [showDialogAdd, setShowDialogAdd]=useState(false);

  const obtenerTanques = () => {
    fetch(UrlApi + "/tanques/dirent/" + props.direccion.id)
      .then((data) => data.json())
      .then((data) => setTanques(data));
  };

  useEffect(() => {
    obtenerTanques();
  }, []);

  const handleRetirar = (tanque) => {
    setRetirar(true);
    setTqARetirar(tanque);
  };

  const icon = <DeleteIcon sx={{ fontSize: 50 }} />;

  const [notify, setNotify] = useState({
    open: false,
    severity: "",
    color: "",
    mensaje: "",
  });



  return (
    <div>
      {notify.open ? (
        <Notify
          open={notify.open}
          color={notify.color}
          severity={notify.severity}
          mensaje={notify.mensaje}
          handleClose={() => setOpenDialogEliminar(false)}
        />
      ) : null}
      <Dialog fullScreen open={props.open} onClose={() => props.setOpen(false)}>
        <AppBar sx={{ position: "relative", background: "#1F618D" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => props.setOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Tanques {props.direccion.direccion_entrega}(
              {props.cliente.razon_social})
            </Typography>
            <Button
              variant="outlined"
              sx={{
                backgroundColor: "#fff",
                "&:hover": {
                  backgroundColor: "#ebebeb",
                  borderColor: "#fff",
                },
              }}
              onClick={() => setShowDialogAdd(true)}
            >
              Añadir Tanque 
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Grid item xs={12} md={6}>
            <List>
              {tanques && tanques.length > 0 ? (
                tanques.map((tanque) => (
                  <>
                    <Box
                      border={0.5}
                      borderColor={"#D4E6F1"}
                      borderRadius={2}
                      mb={2}
                      backgroundColor={"#EAF2F8"}
                    >
                      <ListItem
                        secondaryAction={
                          <Grid container spacing={2} px={1}>
                            {(tanque.incompleto === 1 ||
                              tanque.nro_serie.includes("desconocido")) && (
                              <Grid item>
                                <Tooltip title="Eliminar Tanque">
                                  <IconButton
                                    onClick={() => {
                                      setOpenDialogEliminar(true);
                                      setRegistro(tanque);
                                    }}
                                  >
                                    <DeleteIcon color="error" />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            )}

                            <Grid item>
                              <Tooltip title="Cambiar/Asignar/Quitar de Bateria">
                                <IconButton
                                  onClick={() => {
                                    setDialogBat(true);
                                    setRegistro(tanque);
                                  }}
                                >
                                  <PropaneIcon color="#ffffff" />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                            <Grid item>
                              <Tooltip title="Cambiar Ubicación - Retirar A Sucursal - Cambiar De Batería - Quitar De Batería">
                                <Button
                                  color="error"
                                  variant="outlined"
                                  startIcon={<ForwardIcon />}
                                  onClick={() => handleRetirar(tanque)}
                                  style={{
                                    backgroundColor: "#ffffff",
                                  }}
                                >
                                  Cambiar Ubicación/Retirar
                                </Button>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        }
                      >
                        <ListItemAvatar>
                          <Avatar sx={{ bgcolor: "#5499C7" }}>
                            <PropaneIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            tanque.nro_serie +
                            (tanque.id_sisven
                              ? " (Sisven: " + tanque.id_sisven + ")"
                              : "")
                          }
                          secondary={
                            <>
                              {tanque.id_tanque ? (
                                <>
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    Id:{" "}
                                  </Typography>
                                  {tanque.id_tanque}
                                  <br></br>
                                </>
                              ) : null}

                              {tanque.capacidad ? (
                                <>
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    Capacidad:{" "}
                                  </Typography>
                                  {tanque.capacidad} m3
                                  <br></br>
                                </>
                              ) : null}

                              {tanque.fabricante ? (
                                <>
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    Fabricante:{" "}
                                  </Typography>
                                  {tanque.fabricante}
                                  <br></br>
                                </>
                              ) : null}

                              {tanque.duenio ? (
                                <>
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    Dueño:{" "}
                                  </Typography>
                                  {tanque.duenio} m3
                                  <br></br>
                                </>
                              ) : null}
                              {tanque.nro ? (
                                <>
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    Batería:{" "}
                                  </Typography>
                                  {tanque.nro} ({tanque.nombre})<br></br>
                                </>
                              ) : null}
                            </>
                          }
                        />
                      </ListItem>
                    </Box>
                  </>
                ))
              ) : (
                <Alert severity="warning">
                  <AlertTitle>Atención!</AlertTitle>
                  No se encontró ningún tanque en el domicilio.
                </Alert>
              )}
              {retirar ? (
                <DialogRetirarTanque
                  tanque={tqARetirar}
                  direccion={props.direccion}
                  open={retirar}
                  setOpen={setRetirar}
                  actualizar={obtenerTanques}
                  direcciones={props.direcciones}
                  cliente={props.cliente}
                />
              ) : null}
              {dialogBat ? (
                <GestionBat
                  direccion={props.direccion}
                  open={dialogBat}
                  setOpen={setDialogBat}
                  registro={registro}
                  cliente={props.cliente}
                  actualizar={obtenerTanques}
                />
              ) : null}
              {openDialogEliminar && (
                <ConfirmDialog
                  open={openDialogEliminar}
                  icon={icon}
                  title={"Eliminar Tanque"}
                  subtitle={
                    "Una vez eliminado el tanque no podrá recuperar sus datos. Presione 'confirmar' para eliminar."
                  }
                  color="#d32f2f"
                  setOpen={setOpenDialogEliminar}
                  setNotify={setNotify}
                  guardar={(e) => {
                    putData(
                      e,
                      "/tanques/tanquesyvinculos/" +
                        registro.id_tanque +
                        "/" +
                        registro.id_tanquecontrato,
                      { tanques: { baja: 1 }, tanques_contratos: { baja: 1 } },
                      setNotify
                    )
                      .then(() => {
                        obtenerTanques();
                      })
                      .catch((error) => {
                        console.error("Error actualizando los datos:", error);
                      });
                  }}
                />
              )}
              {
                showDialogAdd && (
                  <DialogAsociarTq
                    open={showDialogAdd}
                    setOpen={(open)=>{setShowDialogAdd(open)
                      obtenerTanques()
                    }}
                    direccion={props.direccion}
                    cliente={props.cliente}
                  />
                )
              }
            </List>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default TanquesEnDireccion;
