import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import MapIcon from "@mui/icons-material/Map";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Button from "@mui/material/Button";
import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MailIcon from "@mui/icons-material/Mail";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PropaneIcon from "@mui/icons-material/Propane";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ListAltIcon from "@mui/icons-material/ListAlt";
import Typography from "@mui/material/Typography";
// import DialogEdicion from "./DialogEdicion";
// import DialogBaja from "./DialogBaja";
// import DialogAsociarTq from "./DialogAsociarTq";
// import TanquesEnDireccion from "./TanquesEnDireccion";

function DetalleDir(props) {
  return (
    <div>
      {props.direccion ? (
        <>
          <List
            sx={{
              width: "100%",
              // bgcolor: "#EBF5FB",
              // border:0.5,
              // borderRadius:1,
              // borderColor:"#A9CCE3"
            }}
          >
            <Grid container direction="row" justifyContent="flex-start">
              <Grid item xs={6}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: "#5499C7", width: 30, height: 30 }}>
                      <LocationOnIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${props.direccion.direccion_entrega} (${props.direccion.nombre_direccion}),    ${props.direccion.localidad}, ${props.direccion.provincia}.`}
                    secondary={`Latitud: ${
                      props.direccion.latitud
                        ? props.direccion.latitud
                        : "No Registrada"
                    }. Longitud: ${
                      props.direccion.longitud
                        ? props.direccion.longitud
                        : "No Registrada"
                    }`}
                  />
                </ListItem>
              </Grid>
              {props.direccion.nombre ? (
                <Grid item xs={4}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar
                        sx={{ bgcolor: "#5499C7", width: 30, height: 30 }}
                      >
                        <MapIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={`Sucursal: ${props.direccion.sucursal}`}
                    />
                  </ListItem>
                </Grid>
              ) : null}
              {props.direccion.nombre_producto ? (
                <>
                  <Grid item xs={2}>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar
                          sx={{ bgcolor: "#5499C7", width: 30, height: 30 }}
                        >
                          <PropaneIcon fontSize="small" />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={props.direccion.nombre_producto} />
                    </ListItem>
                  </Grid>
                </>
              ) : null}
              {props.direccion.mails ? (
                <>
                  <Grid item xs={6}>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar
                          sx={{ bgcolor: "#5499C7", width: 30, height: 30 }}
                        >
                          <MailIcon fontSize="small" />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={props.direccion.mails}
                        secondary={
                          props.direccion.contacto
                            ? `Contacto: ${props.direccion.contacto}`
                            : " "
                        }
                      />
                    </ListItem>
                  </Grid>
                </>
              ) : null}
              {props.direccion.telefono1 ||
              props.direccion.telefono2 ||
              props.direccion.telefono3 ? (
                <>
                  <Grid item xs={4}>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar
                          sx={{ bgcolor: "#5499C7", width: 30, height: 30 }}
                        >
                          <LocalPhoneIcon fontSize="small" />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <>
                            {props.direccion.telefono1 ? (
                              <>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                  Teléfono 1: {props.direccion.telefono1}
                                </Typography>
                                <br></br>
                              </>
                            ) : null}
                            {props.direccion.telefono2 ? (
                              <>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                  Teléfono 2: {props.direccion.telefono2}
                                </Typography>
                                <br></br>
                              </>
                            ) : null}
                            {props.direccion.telefono3 ? (
                              <>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                  Teléfono 3: {props.direccion.telefono3}
                                </Typography>
                                <br></br>
                              </>
                            ) : null}
                          </>
                        }
                        // primary={
                        //   (props.direccion.telefono1
                        //     ? `Teléfono 1: ${props.direccion.telefono1}`
                        //     : "") + <><br/></> +
                        //   (props.direccion.telefono2
                        //     ? "Teléfono 2: " + props.direccion.telefono2
                        //     : "") +
                        //   "     " +
                        //   (props.direccion.telefono3
                        //     ? "Teléfono 3: " + props.direccion.telefono3
                        //     : "")
                        // }
                      />
                    </ListItem>
                  </Grid>
                </>
              ) : null}
            </Grid>
          </List>
        </>
      ) : null}
    </div>
  );
}

export default DetalleDir;
