import React, { useState } from "react";
import { Grid, TextField } from "@mui/material";

function Cromatografia(props) {
  const [componentes, setComponentes] = useState(props.componentes); //componentes que conforman el producto

  return (
    <>
      <Grid container mt={-2.5} maxWidth={1200}>
        {componentes
          ? componentes.map((componente, index) => (
              <>
                <form id="formcroma" autoComplete="off">
                  <div key={componente.id}>
                    <Grid
                      container
                      direction="row"
                      // alignItems="center"
                      justifyContent="center"
                      spacing={2}
                      ml={9}
                    >
                      <Grid item xs={6} mt={1}>
                        <TextField
                          size="small"
                          fullWidth
                          name="nombre_componente"
                          value={componente.nombre_componente}
                          disabled
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#000000",
                              background: "#EBEFF1",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={5} mt={1}>
                        <TextField
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          inputProps={{
                            maxLength: 8,
                          }}
                          size="small"
                          placeholder="-"
                          fullWidth
                          focused
                          onChange={(e) => {
                            props.updateItemsCromatografia("valor", e, index);
                          }}
                          value={
                            props.cromatografia &&
                            props.cromatografia[index] &&
                            props.cromatografia[index].valor
                          }
                        />
                      </Grid>
                    </Grid>
                  </div>
                </form>
              </>
            ))
          : null}
        {/* <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <Grid item xs={4} mt={1}>
          <TextField
            size="small"
            fullWidth
            value="Total"
            disabled
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#000000",
                background: "#D3D3D3",
              },
            }}
          />
        </Grid>
        <Grid item xs={4} mt={1}>
          <TextField
            size="small"
            fullWidth
            placeholder="100"
            //PROBANDO
//             value={                          
//               props.cromatografia.valor
// }
            disabled
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#000000",
              },
            }}
          />
        </Grid>
      </Grid> */}
      </Grid>
    </>
  );
}

export default Cromatografia;
