import { UrlApi } from "../../services/apirest";
import { enviarmails } from "../../services/services";

//Post a factibilidades, clientes, domicilios y artefactos:
export async function postFactibilidades(
  e,
  ruta,
  reqBody,
  setNotify,
  mailAutorizante,
  comercial,
  cliente,
  domicilio,
  kganual,
  mailComercial
) {
  e.preventDefault();
  const Url = UrlApi + ruta;
  const response = await fetch(Url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  });
  if (response.ok === false) {
    setNotify({
      open: true,
      severity: "error",
      color: "#FADBD8",
      mensaje: "Error al guardar",
    });
  } else {
    try {
      const json = await response.json();
      if (json.message) {
        setNotify({
          open: true,
          severity: "success",
          color: "#D4EFDF",
          mensaje: "Guardado",
        });
        enviarmails(
          `${mailAutorizante};${mailComercial}`,
          "Nueva Factibilidad Por Revisar",
          `<p style="color: black;">El representante comercial: ${
            // localStorage.getItem("user")
            comercial
          } ha cargado una nueva factibilidad en el sistema. </p>
          <p><b>Cliente:</b> ${cliente} </p>
          <p><b>Domicilio Entrega:</b> ${domicilio} </p>
          <p><b>Consumo Estimado:</b> ${kganual} </p>
          Actualmente, se encuentra pendiente de revisión y requiere evaluación.`
        );
      } else if (json.sqlMessage) {
        setNotify({
          open: true,
          severity: "error",
          color: "#FADBD8",
          mensaje: "Error al guardar",
        });
      } else {
        setNotify({
          open: true,
          severity: "error",
          color: "#FADBD8",
          mensaje: "Error al guardar",
        });
      }
    } catch (error) {
      if (error.toString().includes("is not valid JSON")) {
        setNotify({
          open: true,
          severity: "success",
          color: "#D4EFDF",
          mensaje: "Guardado",
        });
      } else {
        setNotify({
          open: true,
          severity: "error",
          color: "#FADBD8",
          mensaje: "Error al guardar",
        });
      }
    }
  }
}

//PUT factibilidades
export async function putFactibilidades(e, ruta, reqBody, setNotify) {
  e.preventDefault();
  const Url = UrlApi + ruta;
  const response = await fetch(Url, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  });
  // console.log(response)
  if (response.ok === false) {
    setNotify({
      open: true,
      severity: "error",
      color: "#FADBD8",
      mensaje: "Error al guardar",
    });
  } else {
    try {
      const json = await response.json();
      if (json.message) {
        setNotify({
          open: true,
          severity: "success",
          color: "#D4EFDF",
          mensaje: "Guardado",
        });
      } else if (json.sqlMessage) {
        setNotify({
          open: true,
          severity: "error",
          color: "#FADBD8",
          mensaje: "Error al guardar",
        });
      } else {
        setNotify({
          open: true,
          severity: "error",
          color: "#FADBD8",
          mensaje: "Error al guardar",
        });
      }
    } catch (error) {
      if (error.toString().includes("is not valid JSON")) {
        setNotify({
          open: true,
          severity: "success",
          color: "#D4EFDF",
          mensaje: "Guardado",
        });
      } else {
        setNotify({
          open: true,
          severity: "error",
          color: "#FADBD8",
          mensaje: "Error al guardar",
        });
      }
    }
  }
}

//PUT factibilidades
export async function putFactibilidadesClienteExistente(e, ruta, reqBody, setNotify) {
  e.preventDefault();
  // console.log()
  const Url = UrlApi + ruta;
  const response = await fetch(Url, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  });
  if (response.ok === false) {
    setNotify({
      open: true,
      severity: "error",
      color: "#FADBD8",
      mensaje: "Error al guardar",
    });
  } else {
    try {
      const json = await response.json();
      if (json.message) {
        setNotify({
          open: true,
          severity: "success",
          color: "#D4EFDF",
          mensaje: "Guardado",
        });
      } else if (json.sqlMessage) {
        setNotify({
          open: true,
          severity: "error",
          color: "#FADBD8",
          mensaje: "Error al guardar",
        });
      } else {
        setNotify({
          open: true,
          severity: "error",
          color: "#FADBD8",
          mensaje: "Error al guardar",
        });
      }
    } catch (error) {
      if (error.toString().includes("is not valid JSON")) {
        setNotify({
          open: true,
          severity: "success",
          color: "#D4EFDF",
          mensaje: "Guardado",
        });
      } else {
        setNotify({
          open: true,
          severity: "error",
          color: "#FADBD8",
          mensaje: "Error al guardar",
        });
      }
    }
  }
}

export async function aprobarTec(
  e,
  ruta,
  reqBody, //Este req.body es el cuerpo del reclamo que se registra cuando se aprueba una factibilidad, por lo tanto se levanta un reclamo de instalación.
  setNotificacion,
  mail_autorizante_creditos,
  idfactibilidad,
  cliente,
  mailComercial
) {
  const Url = UrlApi + ruta;
  const response = await fetch(Url, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  });
  if (!response.ok) {
    setNotificacion({
      open: true,
      mensaje: "Error al conectar con la base de datos.",
      severity: "error",
      color: "#FADBD8",
    });
    throw new Error(
      `Error al realizar la solicitud: ${response.status} ${response.statusText}`
    );
  } else {
    const json = await response.json();
    if (json.sqlMessage) {
      setNotificacion({
        open: true,
        mensaje: "Error SQL. Contacte a soporte.",
        severity: "error",
        color: "#FADBD8",
      });
      throw new Error(`SQL incorrecto (${json.errno})`);
    } else if (json.message) {
      setNotificacion({
        open: true,
        mensaje: "FACTIBILIDAD APROBADA",
        severity: "success",
        color: "#D4EFDF",
      });
      enviarmails(
        `${mail_autorizante_creditos};${mailComercial}`,
        "Análisis Crediticio Pendiente",
        `<b style="color: black;">La factibilidad ${idfactibilidad} ha sido aprobada técnicamente. Es necesario un previo análisis crediticio antes de registrar el cliente. </b>
        <p><b style="color: black;">Datos del cliente: </b></p>
        <ul>
        ${
          cliente
            ? `<li><b style="color: black;">Razón Social</b>: ${cliente.razon_social}</li>`
            : ""
        }
        </ul>
`
      );
    } else {
      console.log("error desconocido");
    }
  }
}

//<p style="color: black;"> Para ver el listado de análisis crediticio ,<a href="http://192.168.34.72:3000/gestionCrediticia?ruta=${"/gestionCrediticia"}"> HAGA CLICK AQUÍ </a>.</p> 

export async function aprobarFact(
  e,
  ruta,
  reqBody, //Este req.body es el cuerpo del reclamo que se registra cuando se aprueba una factibilidad, por lo tanto se levanta un reclamo de instalación.
  setNotificacion,
  receptor,
  factibilidad,
  cliente,
  autorizante,
  mailSolicitante,
  estado
) {
  // console.log(factibilidad)
  e.preventDefault()
  const Url = UrlApi + ruta;
  const response = await fetch(Url, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ reqBody, estado_fact: estado }),
  });
  if (!response.ok) {
    setNotificacion({
      open: true,
      mensaje: "Error al conectar con la base de datos.",
      severity: "error",
      color: "#FADBD8",
    });
    throw new Error(
      `Error al realizar la solicitud: ${response.status} ${response.statusText}`
    );
  } else {
    const json = await response.json();
    if (json.sqlMessage) {
      setNotificacion({
        open: true,
        mensaje: "Error SQL. Contacte a soporte.",
        severity: "error",
        color: "#FADBD8",
      });
      throw new Error(`SQL incorrecto (${json.errno})`);
    } else if (json.message) {
      setNotificacion({
        open: true,
        mensaje: "FACTIBILIDAD APROBADA",
        severity: "success",
        color: "#D4EFDF",
      });
      enviarmails(
        `${receptor};${autorizante};${mailSolicitante}`,
        "Aviso de factibilidad aprobada",
        `<b style="color: black;">La factibilidad ${
          factibilidad.id
        } ha sido aprobada.</b>
        <p><b style="color: black;">Cliente: </b> ${cliente} </p>
        ${
          !!factibilidad.motivocli1 || !!factibilidad.motivocli2 || !!factibilidad.motivocli3 ? (
            ` <p>Por favor, facturar al cliente los siguientes conceptos: </p>
            <ul>
            ${
              factibilidad.motivocli1
                ? `<li><b style="color: black;">${factibilidad.motivocli1}</b>: ${factibilidad.cantmotivocli1}</li>`
                : ""
            }
            ${
              factibilidad.motivocli2
                ? `<li><b style="color: black;">${factibilidad.motivocli2}</b>: ${factibilidad.cantmotivocli2}</li>`
                : ""
            }
            ${
              factibilidad.motivocli3
                ? `<li><b style="color: black;">${factibilidad.motivocli3}</b>: ${factibilidad.cantmotivocli3}</li>`
                : ""
            }
            </ul>`
          ) : (
            `<p>No hay conceptos para facturar.</p>`
          )
        }
       `
      );
    } else {
      console.log("error desconocido");
    }
  }
}

// ----------------------------------------------------------------------- //
//aprobar fact actualizada, usa magui, la modificacion se debe a que tambien registra quien fue el usuario que aprobó
export async function aprobarFactUser(
  e,
  ruta,
  reqBody, //Este req.body es el cuerpo del reclamo que se registra cuando se aprueba una factibilidad, por lo tanto se levanta un reclamo de instalación.
  setNotificacion,
  receptor,
  factibilidad,
  cliente,
  autorizante,
  mailSolicitante,
  datosFactAprobar
) {
  // console.log("llame a la funcion correspondiente")
  e.preventDefault()
  const Url = UrlApi + ruta;
  const response = await fetch(Url, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ reqBody, datosFactAprobar}),
  });
  if (!response.ok) {
    setNotificacion({
      open: true,
      mensaje: "Error al conectar con la base de datos.",
      severity: "error",
      color: "#FADBD8",
    });
    throw new Error(
      `Error al realizar la solicitud: ${response.status} ${response.statusText}`
    );
  } else {
    const json = await response.json();
    if (json.sqlMessage) {
      setNotificacion({
        open: true,
        mensaje: "Error SQL. Contacte a soporte.",
        severity: "error",
        color: "#FADBD8",
      });
      throw new Error(`SQL incorrecto (${json.errno})`);
    } else if (json.message) {
      setNotificacion({
        open: true,
        mensaje: "FACTIBILIDAD APROBADA",
        severity: "success",
        color: "#D4EFDF",
      });
      enviarmails(
        `${receptor};${autorizante};${mailSolicitante}`,
        "Aviso de factibilidad aprobada",
        `<b style="color: black;">La factibilidad ${
          factibilidad.id
        } ha sido aprobada.</b>
        <p><b style="color: black;">Cliente: </b> ${cliente} </p>
        ${
          !!factibilidad.motivocli1 || !!factibilidad.motivocli2 || !!factibilidad.motivocli3 ? (
            ` <p>Por favor, facturar al cliente los siguientes conceptos: </p>
            <ul>
            ${
              factibilidad.motivocli1
                ? `<li><b style="color: black;">${factibilidad.motivocli1}</b>: ${factibilidad.cantmotivocli1}</li>`
                : ""
            }
            ${
              factibilidad.motivocli2
                ? `<li><b style="color: black;">${factibilidad.motivocli2}</b>: ${factibilidad.cantmotivocli2}</li>`
                : ""
            }
            ${
              factibilidad.motivocli3
                ? `<li><b style="color: black;">${factibilidad.motivocli3}</b>: ${factibilidad.cantmotivocli3}</li>`
                : ""
            }
            </ul>`
          ) : (
            `<p>No hay conceptos para facturar.</p>`
          )
        }
       `
      );
    } else {
      console.log("error desconocido");
    }
  }
}


export async function aprobarFactUser2(
  e,
  ruta,
  reqBody, //Este req.body es el cuerpo del reclamo que se registra cuando se aprueba una factibilidad, por lo tanto se levanta un reclamo de instalación.
  setNotificacion,
  receptor,
  factibilidad,
  cliente,
  autorizante,
  mailSolicitante,
  datosFactAprobar,
  direccionentrega,
  artefactos,
  calculos
) {

/* de aca para abajo, asi tiene que quedar: */  
e.preventDefault();
const Url = UrlApi + ruta;
// console.log(artefactos)

const response = await fetch(Url, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ reqBody, datosFactAprobar}),
  });
  if (!response.ok) {
    setNotificacion({
      open: true,
      mensaje: "Error al conectar con la base de datos.",
      severity: "error",
      color: "#FADBD8",
    });
    throw new Error(
      `Error al realizar la solicitud: ${response.status} ${response.statusText}`
    );
  } else {
    const json = await response.json();
    if (json.sqlMessage) {
      setNotificacion({
        open: true,
        mensaje: "Error SQL. Contacte a soporte.",
        severity: "error",
        color: "#FADBD8",
      });
      throw new Error(`SQL incorrecto (${json.errno})`);
    } else if (json.message) {
      setNotificacion({
        open: true,
        mensaje: "FACTIBILIDAD APROBADA",
        severity: "success",
        color: "#D4EFDF",
      });
// console.log("ACA ABAJO ESTAA LA FUNCION PARA ENVIAR LOS MAILS")
      enviarmails(
        // "magaliperea96@gmail.com",
        `${receptor};${autorizante};${mailSolicitante}`,
        "Aviso de factibilidad aprobada",
        `
        <!DOCTYPE html>
      <html lang="es">
      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Detalle de Factibilidad</title>
          <style>
              body {
                  font-family: Arial, sans-serif;
                  line-height: 1.6;
                  margin: 20px;
                  color: #333;
              }
              .container {
                  border: 1px solid #ccc;
                  padding: 20px;
                  border-radius: 8px;
                  max-width: 600px;
                  margin: 0 auto;
                  background-color: #f9f9f9;
              }
              h2 {
                  color: #4CAF50;
              }
              hr {
                  border: 0;
                  border-top: 1px solid #ccc;
                  margin: 20px 0;
              }
              .section-title {
                  font-size: 1.2em;
                  margin-bottom: 10px;
                  padding: 10px;
                  background-color: #4CAF50;
                  color: white;
                  border-radius: 4px;
              }
              .label {
                  font-weight: bold;
              }
              .artefacto {
                  padding-bottom: 10px;
                  margin-bottom: 10px;
              }
          </style>
      </head>
      <body>
          <div class="container">
              <!-- Encabezado -->
              <h2>La factibilidad ${factibilidad.id} ha sido aprobada.</h2>
              <hr>      
              ${
                factibilidad.id_tipofact===235?(`
                <!-- Datos de Facturación del Cliente -->
                <div>
                  <div class="section-title">Información de facturación:</div>
                  <p><span class="label">Cliente:</span> ${cliente.razon_social}</p>
                  <p><span class="label">Dirección Fiscal:</span> ${cliente.direccion_fiscal}, ${cliente.localidad}, ${cliente.provincia}</p>
                  <p><span class="label">Localidad:</span> ${cliente.localidad}</p>
                  <p><span class="label">Provincia:</span> ${cliente.provincia}</p>
                  <p><span class="label">Mails:</span> ${cliente.mails}</p>
                  <p><span class="label">Teléfono/s:</span> ${cliente.telefono1||""} ${cliente.telefono2||""}  ${cliente.telefono3||""} </p>
                  <p><span class="label">Situación Iva:</span> ${cliente.sit_iva}</p>
                  <p><span class="label">Cuit/DNI:</span> ${cliente.cuit}</p>
                  <p><span class="label">Segmento:</span> ${cliente.segmento}</p>
                  <p><span class="label">Subsegmento:</span> ${cliente.subsegmento}</p>
      
              </div>
              <hr>
                `) : ( `
                <p><span class="label">Cliente:</span> ${cliente.razon_social} (${cliente.sisven_id|| "Sisven ID no registrado"}) </p>
                <p><span class="label">Cuit/DNI:</span> ${cliente.cuit}</p>
                `)
              }
              <!-- Datos de Dirección de Entrega -->
              <div>
                  <div class="section-title">Dirección de entrega:</div>
                  <p><span class="label">Contacto:</span> ${direccionentrega.contacto || "No registrado"}</p>
                  <p><span class="label">Dirección de entrega:</span> ${direccionentrega.direccion_entrega}</p>
                  <p><span class="label">Localidad:</span> ${direccionentrega.localidad}</p>
                  <p><span class="label">Provincia:</span> ${direccionentrega.provincia}</p>
                  <p><span class="label">Teléfono/s:</span> ${direccionentrega.telefono1||""} ${direccionentrega.telefono2||""}  ${direccionentrega.telefono3||""} </p>
                  <p><span class="label">Mails:</span> ${direccionentrega.mails || "No registrado"}</p>
                  <p><span class="label">Latitud:</span> ${direccionentrega.latitud}</p>
                  <p><span class="label">Longitud:</span> ${direccionentrega.latitud}</p>

              </div>
              <hr>
              <!-- Información de la Instalación -->
              <div>
                  <div class="section-title">Información de la instalación:</div>
                  ${
                    typeof calculos.kganual=== "string" ? (
                    ` <p><span class="label">Consumo anual estimado (KGS) :</span> ${calculos.kganual} </p>
                    <hr>` 
                  ):(
                    ` <p><span class="label">Consumo anual estimado:</span> "No disponible, chequear en factibilidad."</p>
                    <hr>`
                  )
                }
                 
      
                  <!-- Artefactos a Abastecer -->
                  <div>
                      <p><span class="label">Artefactos a abastecer:</span></p>
                      <ul>
                          ${artefactos&&artefactos.map(artefacto => `
                              <li>
                                  <span class="label">${artefacto.artefacto}</span> (${artefacto.consumoanual} KG anuales)
                              </li>
                          `).join('')}
                      </ul>
                  </div>
                  <hr>
      
                  <!-- Tanques a Instalar -->
                  <div>
                      <p><span class="label">Tanques a instalar:</span></p>
                      <ul>
                          <li><span class="label">Tanques 0.5 m3:</span> ${factibilidad.canttk_05 || "Ninguno"}</li>
                          <li><span class="label">Tanques 1 m3:</span> ${factibilidad.canttk_1 || "Ninguno"}</li>
                          <li><span class="label">Tanques 2 m3:</span> ${factibilidad.canttk_2 || "Ninguno"}</li>
                          <li><span class="label">Tanques 4 m3:</span> ${factibilidad.canttk_4 || "Ninguno"}</li>
                          <li><span class="label">Tanques 7 m3:</span> ${factibilidad.canttk_7 || "Ninguno"}</li>
                      </ul>
                  </div>
              </div>
      
      
              <!-- Artefactos a Abastecer -->
              <div>
                  <div class="section-title">Conceptos a facturar:</div>
                  ${
                                !!factibilidad.motivocli1 || !!factibilidad.motivocli2 || !!factibilidad.motivocli3 ? (
                                  `
                                  <ul>
                                  ${
                                    factibilidad.motivocli1
                                      ? `<li><b style="color: black;">${factibilidad.motivocli1}</b>: ${factibilidad.cantmotivocli1}</li>`
                                      : ""
                                  }
                                  ${
                                    factibilidad.motivocli2
                                      ? `<li><b style="color: black;">${factibilidad.motivocli2}</b>: ${factibilidad.cantmotivocli2}</li>`
                                      : ""
                                  }
                                  ${
                                    factibilidad.motivocli3
                                      ? `<li><b style="color: black;">${factibilidad.motivocli3}</b>: ${factibilidad.cantmotivocli3}</li>`
                                      : ""
                                  }
                                  </ul>`
                                ) : (
                                  `<p>No hay conceptos para facturar.</p>`
                                )
                              }
      
              </div>
          </div>
      </body>
      </html>
        `
      );
    } else {
      console.log("error desconocido");
    }
  }
  }


// -------------------------------------------------------------------- // 

//PUT factibilidades
export async function deleteFact(e, ruta, setNotify) {
  e.preventDefault();
  const Url = UrlApi + ruta;
  const response = await fetch(Url, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    // body: JSON.stringify(reqBody),
  });
  if (response.ok === false) {
    setNotify({
      open: true,
      severity: "error",
      color: "#FADBD8",
      mensaje: "Error al eliminar",
    });
    // setShowModalError({
    //   open: true,
    //   title: "Error",
    //   description:
    //     "Ocurrió un problema de conexión con la api. No se pudo guardar la factibilidad. Contacte a soporte",
    // });
  } else {
    try {
      const json = await response.json();
      if (json.message) {
        setNotify({
          open: true,
          severity: "success",
          color: "#D4EFDF",
          mensaje: "FACTIBILIDAD ELIMINADA",
        });
      } else if (json.sqlMessage) {
        setNotify({
          open: true,
          severity: "error",
          color: "#FADBD8",
          mensaje: "Error al eliminar",
        });
        // setShowModalError({
        //   open: true,
        //   title: "Error",
        //   description:
        //     "Error SQL. No se pudo guardar la factibilidad. Contacte a soporte",
        // });
      } else {
        setNotify({
          open: true,
          severity: "error",
          color: "#FADBD8",
          mensaje: "Error al eliminar",
        });
        // setShowModalError({
        //   open: true,
        //   title: "Error",
        //   description: "Error desconocido. Contacte a soporte",
        // });
      }
    } catch (error) {
      if (error.toString().includes("is not valid JSON")) {
        setNotify({
          open: true,
          severity: "success",
          color: "#D4EFDF",
          mensaje: "Factibilidad Eliminada",
        });
      } else {
        setNotify({
          open: true,
          severity: "error",
          color: "#FADBD8",
          mensaje: "Error al eliminar",
        });
        // setShowModalError({
        //   open: true,
        //   title: "Error",
        //   description: "Error desconocido",
        // });
      }
    }
  }
}

export async function desaprobarFact(
  e,
  ruta,
  setNotificacion,
  mailSolicitante,
  factid,
  cliente,
  direccion,
  observaciones
) {
  const Url = UrlApi + ruta;
  const response = await fetch(Url, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      id_estado: 212,
      rechazo_observaciones: observaciones,
      usuario_desaprueba:localStorage.getItem("user")
    }),
  });
  if (!response.ok) {
    setNotificacion({
      open: true,
      mensaje: "Error al conectar con la base de datos.",
      severity: "error",
      color: "#FADBD8",
    });
    throw new Error(
      `Error al realizar la solicitud: ${response.status} ${response.statusText}`
    );
  } else {
    const json = await response.json();
    if (json.sqlMessage) {
      setNotificacion({
        open: true,
        mensaje: "Error SQL. Contacte a soporte.",
        severity: "error",
        color: "#FADBD8",
      });
      throw new Error(`SQL incorrecto (${json.errno})`);
    } else if (json.message) {
      setNotificacion({
        open: true,
        mensaje: "FACTIBILIDAD DESAPROBADA",
        severity: "success",
        color: "#D4EFDF",
      });
      enviarmails(
        `${mailSolicitante}`,
        "Factibilidad Desaprobada",
        `<b style="color: black;">Se informa que la factibilidad ${factid} ha sido DESAPROBADA.</b>
        <p><b style="color: black;">Cliente: </b> ${cliente} </p>
        <p><b style="color: black;">Domicilio de entrega: </b> ${direccion} </p>
        <p><b style="color: black;">Observaciones: </b> ${observaciones} </p>
        
        <p>No obstante, la factibilidad permanece disponible para su revisión y modificaciones adicionales en caso de ser necesarias.</p>
        `
      );
    } else {
      alert("Error desconocido.");
      console.log("error desconocido");
    }
  }
}
