import React, { useEffect, useState, useMemo } from 'react';
import { UrlApi } from '../../../services/apirest';
import { Container, Typography, Tooltip, IconButton, Box, Button, Dialog, DialogContent, DialogActions, Grid } from '@mui/material';
import Navbar from '../../Navbar';
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import BlockIcon from '@mui/icons-material/Block';
import EditIcon from '@mui/icons-material/Edit';
import Addsupervisores from './Addsupervisores';
import Notify from '../../Notify';
import Alerta from '../../Alerta';
import Editarsupervisores from './Editarsupervisores';
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export default function Supervisores() {
    const [supervisores, setSupervisores] = useState();
    const [tabla, setTabla] = useState(true);
    const [editar, setEditar] = useState(false);
    const [alta, setAlta] = useState(false);
    const [datosformulario, setDatosformulario] = useState({});
    const [confirmar, setConfirmar] = useState(false);
    const globalTheme = useTheme();
    const [openSnack, setOpenSnack] = React.useState(false);
    const [colormensaje, setColorMensaje] = useState("");
    const [mensaje, setMensaje] = useState("");
    const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
    const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
    const [mensajeAlerta, setMensajeAlerta] = useState(""); //mensaje que contendrá el componente "alerta"
    const [finalizar, setFinalizar] = useState(); //finalizar === 1 lo manda como prop el componente "notify" e indica que la transaccion ha finalizado y debe ejecutarse la funcion para cerrar
    const [severityAlerta, setSeverityAlerta] = useState(""); //define severidad de la alerta (success error o warning)
    const [severitySnack, setSeveritySnack] = useState(""); //define severidad del snackbar (notificación)

    //Obtengo los supervisores de la API
    const obtenerSupervisores = () => {
        fetch(UrlApi + "/supervisores")
            .then((data) => data.json())
            .then((data) => setSupervisores(data));
    };

    //obtengo supervisores cada vez que hay algun cambio en tabla
    useEffect(() => {
        obtenerSupervisores();
    }, [tabla]);

    const handleClickNuevo = () => {
        setAlta(true);
        setTabla(false);
    };

    const mostrareditar = (rowData) => {
        setTabla(false);
        setEditar(true);
        setDatosformulario(rowData);
    };

    const handleVolver = () => {
        setConfirmar(false)
        setTabla(true)
    };

    const abrirModalConfirmar = (datosDeLaLinea) => {
        setConfirmar(true)
        setDatosformulario(datosDeLaLinea)
    };

    const columns = useMemo(() =>
        [
            { header: 'Nombre y Apellido', accessorKey: 'nombre_apellido' },
            { header: 'Telefono', accessorKey: 'telefono' },
            { header: 'Mail', accessorKey: 'mail' },
            { header: 'Sucursal', accessorKey: 'nombre' }
        ], []);

    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: globalTheme.palette.mode,
                    primary: globalTheme.palette.primary, //primary color for the table
                    info: {
                        main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            globalTheme.palette.mode === "light"
                                ? "rgb(234, 239, 241)"
                                : "#000", //pure black table in dark mode for fun
                    },
                },
                typography: {
                    button: {
                        textTransform: "none", //customize typography styles for all buttons in table by default
                        fontSize: "1.2rem",
                    },
                },
                components: {
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                fontSize: "1.1rem", //larger tooltip font size                                
                            },
                        },
                    },
                },

            }), [globalTheme])

    const muiSearchTextFieldProps = {
        placeholder: "Buscar..",
        sx: { minWidth: "18rem" },
        variant: "outlined",
        color: "success",
        size: "small",
    };

    const displayColumnDefOptions = {
        "mrt-row-actions": {
            muiTableHeadCellProps: {
                align: "center",
                sx: {
                    fontSize: 14,
                    fontStyle: "italic",
                }
            },
            size: 100,
        },
    };

    const initialState = {
        density: "compact",
        showGlobalFilter: true,
        pagination: { pageIndex: 0, pageSize: 10 }
    };

    const marcarInactivo = async (e) => {
        e.preventDefault();
        const response = await fetch(UrlApi + "/supervisores/" + datosformulario.id, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                activo: 0,
            }),
        })
        if (response.ok === false) {
            console.log("ERROR EN API");
            const error = response && response.json();
            setError(error);
            setSeverityAlerta("error")
            setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
        } else {
            console.log("Conexión con api OK");
            try {
                //Parseo respuesta de la api a json()
                const json = await response.json();
                //si api me devuelve lo q le puse en message
                if (json.message) {
                    //Si ese mensaje es "OK"
                    if (json.message === "OK") {
                        console.log("Guardado Satisfactoriamente");
                        setNotificacion(true);
                        setOpenSnack(true);
                        setColorMensaje("#D4EFDF");
                        setMensaje("Guardado");
                        setSeveritySnack("success");
                    } else {
                        console.log("res en api incorrecto. Revisar que sea 'OK'")
                        setNotificacion(true);
                        setOpenSnack(true);
                        setColorMensaje("#D4EFDF");
                        setSeveritySnack("error");
                        setMensaje("Registro Guardado. REVISAR RESPUESTA DE API (E_S)");
                    }
                } else if (json.sqlMessage) {
                    json && setError(json);
                    console.log(json);
                    setMensajeAlerta("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
                } else {
                    setMensajeAlerta("ERROR DESCONOCIDO.");
                }
            } catch (error) {
                if (error.toString().includes("is not valid JSON")) {
                    console.log("Guardado. Advertencia: Actualizar respuesta de api. JSON invalido.");
                    setNotificacion(true);
                    setOpenSnack(true);
                    setColorMensaje("#D4EFDF");
                    setMensaje("Guardado");
                    setSeveritySnack("success");
                } else {
                    setMensajeAlerta("ERROR DESCONOCIDO.");
                }
            }
        }
        handleVolver()
        obtenerSupervisores();
    };

    return (
        <div>
            <Navbar />
            <Container maxWidth="xl" mt={1}>
                {supervisores && tabla ? (
                    <Box mt={2}>
                        <ThemeProvider theme={tableTheme}>
                            <MaterialReactTable
                                enableExpandAll={false}
                                enableToolbarInternalActions={true}
                                enableColumnResizing
                                enableGrouping
                                displayColumnDefOptions={displayColumnDefOptions}
                                layoutMode="grid"
                                muiTableHeadCellProps={{
                                    align: "center",
                                }}
                                muiTableBodyCellProps={({ row }) => ({
                                    align: "center",
                                    sx: {
                                        backgroundColor:
                                            row.original.activo === 1 ? "#afdbaf" : "#e39898",
                                        color: "black",
                                        borderColor: "black",
                                    },
                                })}
                                enableColumnActions={false}
                                enableColumnFilters={false} //desactiva filtros x  columna
                                enableHiding={false}
                                enableDensityToggle={false} //
                                localization={MRT_Localization_ES}
                                enableColumnDragging={false} //No permite que se arrastren las columnas.
                                initialState={initialState}
                                muiSearchTextFieldProps={muiSearchTextFieldProps}
                                positionExpandColumn='first'
                                columns={columns}
                                data={supervisores}
                                enableEditing
                                positionActionsColumn="last"
                                renderTopToolbarCustomActions={() => (
                                    <>
                                        <Button
                                            variant="contained"
                                            color="success"
                                            startIcon={<AddBoxTwoToneIcon />}
                                            onClick={handleClickNuevo}
                                        >
                                            Nuevo Registro
                                        </Button>
                                        <Typography variant="poster" fontSize={20}>
                                            LISTADO DE SUPERVISORES
                                        </Typography>
                                    </>
                                )}
                                renderRowActions={({ row, table }) => (
                                    <div>
                                        {(row.original.activo === 1) ?
                                            <Tooltip title="Marcar como inactivo">
                                                <IconButton
                                                    onClick={() => abrirModalConfirmar(row.original)}
                                                    color="error"
                                                >
                                                    <BlockIcon />
                                                </IconButton>
                                            </Tooltip>
                                            :
                                            <Tooltip title="Marcar como activo">
                                                <IconButton
                                                    onClick={() => abrirModalConfirmar(row.original)}
                                                    color="primary"
                                                >
                                                    <CheckCircleOutlineIcon />
                                                </IconButton>
                                            </Tooltip>

                                        }
                                        <Tooltip title="Editar supervisor">
                                            <IconButton
                                                onClick={() => mostrareditar(row.original)}
                                                color="primary"
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>

                                    </div>
                                )}
                            />
                        </ThemeProvider>
                    </Box>
                ) : null}

                {confirmar ?
                    <div>
                        <Dialog open={confirmar} onClose={handleVolver}>
                            <DialogContent>
                                <form onSubmit={marcarInactivo}>
                                    <Typography variant="h6" align="center">
                                        ¿Desea modificar al supervisor{datosformulario.nombre_apellido} ?
                                    </Typography>
                                    <DialogActions>
                                        <Grid mt={2} container direction="row" justifyContent="space-between" alignItemns="center">
                                            <Button
                                                variant="contained"
                                                onClick={handleVolver}
                                                style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
                                            >
                                                Cancelar
                                            </Button>
                                            <Button
                                                style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                            >
                                                Confirmar
                                            </Button>
                                        </Grid>
                                    </DialogActions>
                                </form>
                            </DialogContent>
                        </Dialog>


                    </div>
                    : null}
                {notificacion ? (
                    <Notify
                        mensaje={mensaje}
                        open={openSnack}
                        color={colormensaje}
                        finalizar={setFinalizar}
                        severity={severitySnack}
                    />
                ) : null}
                {/* ALERT --> SE MUESTRA SI HAY UN ERROR EN LA API (NO SE CONECTA O ESTÁ MAL LA RUTA, ETC.) */}
                {error && mensajeAlerta ? (
                    <Alerta mensaje={mensajeAlerta} severity={severityAlerta} />
                ) : null}
                {alta ? <Addsupervisores alta={setAlta} tabla={setTabla} ></Addsupervisores> : null}

                 {editar ? <Editarsupervisores editar={setEditar} tabla={setTabla} datos={datosformulario}></Editarsupervisores> : null} 
            </Container>

            <br />
            <br />
        </div>

    )



}