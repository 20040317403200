import {
  Card,
  CardContent,
  Container,
  Typography,
  CardHeader,
  Avatar,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Button,
} from "@mui/material";
import PageviewIcon from "@mui/icons-material/Pageview";
import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import {UrlApi} from '../../services/apirest';

function InfoUser() {

const [wpass, setWpass] = useState("")
const [wpassConfirm, setWpassConfirm] = useState("")
const [correcto, setCorrecto] = useState(false)
const [userId, setUserId] = useState("")

useEffect(()=>{
    ((wpass.length >=6 && wpassConfirm.length >=6) && wpass === wpassConfirm) ? setCorrecto(true) : setCorrecto(false)
  },[wpass, wpassConfirm])

useEffect(()=>{
    obtenerUsuarios()
},[])

const obtenerUsuarios=  async () =>{
    const data = await fetch(UrlApi+"/userdata/"+localStorage.getItem("user"))
    const userData = await data.json()
    setUserId(userData)
}

const modificaUsuario = (e) => {
    e.preventDefault();
    correcto ? cambiarPassword() : alert("Error al cambiar la password")
}

const handleVolver = () => (alert("se cambio la password"),  window.location.hash = "/")

const cambiarPassword = async () => {
            await fetch(UrlApi+"/userpass/"+userId[0].id,{
            method:'PUT', headers:{'Content-Type':'application/json'},
            body: JSON.stringify({
                password:wpass
            })
        })
        .then(handleVolver)
        .catch(err =>{console.log(err.message)})
}

  return (
    <div>
      <Navbar />
      <Container>
        <br />
        <Typography
          variant="h5"
          component="div"
          style={{ paddingLeft: "15px" }}
          bgcolor="navy"
          color="white"
        >
          Informacion del usuario
        </Typography>
        <br />
        <Grid container spacing={2}>
          <Grid item sx={4}>
            <Card variant="outlined">
              <CardHeader
                avatar={
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "Right",
                      alignItems: "Right",
                      marginLeft: "1px",
                    }}
                  >
                    <Avatar sx={{ bgcolor: "blue" }}>
                      {localStorage.getItem("user")[0].toUpperCase()}
                    </Avatar>
                    <Avatar sx={{ bgcolor: "green" }}>
                      <PageviewIcon />
                    </Avatar>
                  </span>
                }
                titleTypographyProps={{ variant: "h5" }}
                subheaderTypographyProps={{ variant: "h7" }}
                title={"Nombre de usuario : " + localStorage.getItem("user")}
                subheader={
                  "Sucursal principal   : " +
                  localStorage.getItem("sucursal") +
                  " - " +
                  localStorage.getItem("sucursalnombre")
                }
              />
              <CardContent>
                <Typography variant="body1" color="text.secondary">
                  <Table>
                    <TableBody>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          Role principal{" "}
                        </TableCell>
                        <TableCell align="left">
                          {localStorage.getItem("role")}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Sucursales que puede ver en paneles
                        </TableCell>
                        <TableCell align="left">
                          {localStorage.getItem("suc_auth")}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sx={4}>
            <Card variant="outlined">
            <CardContent>
                <Typography variant="h6" component="div">Cambio de contraseña</Typography>
                <TextField type="password" id="pass" label="Nueva Password" variant="outlined" color="secondary"
                onChange={(event)=> {setWpass(event.target.value);}} value={wpass} 
                />
                <TextField type="password" id="passconfirm" label="Confirma Password" variant="outlined" color="info"
                onChange={(event)=> {setWpassConfirm(event.target.value);}} value={wpassConfirm} 
                />
                {correcto ? <p style={{color:"green"}}>Correcto</p>: 
                    <><p style={{color:"red"}}>La password debe tener 6 o mas caracteres <br />y deben coincidir password y confirmacion</p></>}
                <Button variant="outlined"  color='success' onClick={modificaUsuario}>Cambiar</Button>
            </CardContent>
            </Card>
          
        </Grid>
        </Grid>
        

      </Container>
    </div>
  );
}

export default InfoUser;
