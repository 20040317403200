import React, { useState, useEffect } from "react";
import { UrlApi } from "../../../services/apirest";
import Navbar from "../../Navbar";
import {
  Container,
  Stack,
  Box,
  TextField,
  InputAdornment,
  Button,
  IconButton,
  Grid,
  Typography,
} from "@mui/material";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import ContactsIcon from "@mui/icons-material/Contacts";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { grey } from "@mui/material/colors";
import Notify from "../../Notify";
import Alerta from "../../Alerta";

function Editarcertificadora(props) {
  const [datosformulario, setDatosformulario] = useState(props.datos);
  const [disBtn, setDisBtn] = useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
  const [mensajeAlerta, setMensajeAlerta] = useState(""); //mensaje que contendrá el componente "alerta"
  const [finalizar, setFinalizar] = useState(); //finalizar === 1 lo manda como prop el componente "notify" e indica que la transaccion ha finalizado y debe ejecutarse la funcion para cerrar
  const [severityAlerta, setSeverityAlerta] = useState(""); //define severidad de la alerta (success error o warning)
  const [severitySnack, setSeveritySnack] = useState(""); //define severidad del snackbar (notificación)

  const almacenar = (e) => {
    setDatosformulario({ ...datosformulario, [e.target.name]: e.target.value });
  };

  const handleClose = () => {
    props.tabla(true);
    props.editar(false);
  };

  useEffect(() => {
    getCodArg();
  }, []);

  useEffect(() => {
    props.datos &&
      setDatosformulario({
        ...props.datos,
        telefono: props.datos.telefono
          ? props.datos.telefono.replace(/^(\+?549)?(\d{10})$/, "$2")
          : "",
      });
  }, [props.datos]);

  const [codArg, setCodArg] = useState("");
  const getCodArg = () => {
    fetch(UrlApi + "/desplegables/codArg")
      .then((data) => data.json())
      .then((data) => setCodArg(data[0].valor));
  };

  const guardarCertificadora = async (e) => {
    setDisBtn(true);
    e.preventDefault();
    const response = await fetch(
      UrlApi + "/certificadoras/" + datosformulario.id,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          nombre: datosformulario.nombre,
          contacto: datosformulario.contacto,
          usuario: localStorage.getItem("user"),
          telefono: datosformulario.telefono,

        }),
      }
    );
    if (response.ok === false) {
        const error = response && response.json();
        setError(error);
        setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
      } else {
        try {
          const json = await response.json();
          if (json.message) {
            setNotificacion(true);
            setOpenSnack(true);
            setMensaje(
              json.message === "OK" ? "Guardado" : "Guardado. Actualizar RTA API"
            );
          } else if (json.sqlMessage) {
            json && setError(json);
  
            setMensajeAlerta(
              "SQL erróneo (" + json.code + " Errno: " + json.errno + " )"
            );
          } else {
            setMensajeAlerta("ERROR DESCONOCIDO.");
          }
        } catch (error) {
          if (error.toString().includes("is not valid JSON")) {
            setNotificacion(true);
            setOpenSnack(true);
            setMensaje("Guardado.");
          } else {
            setMensajeAlerta("ERROR DESCONOCIDO.");
          }
        }
      }
  };

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  return (
    <div>
      <Container>
      {notificacion ? (
          <Notify
            mensaje={mensaje}
            open={openSnack}
            handleClose={handleClose}
            severity={"success"}
            color={"#D4EFDF"}

          />
        ) : null}
        {/* ALERT --> SE MUESTRA SI HAY UN ERROR EN LA API (NO SE CONECTA O ESTÁ MAL LA RUTA, ETC.) */}
        {error && mensajeAlerta ? (
          <Alerta mensaje={mensajeAlerta} severity={"error"} />
        ) : null}

        <Box sx={{ flexGrow: 1 }} mt={2}>
          <Grid container backgroundColor="#1F618D" p={1.5}>
            <Grid item xs={2}>
              <IconButton onClick={handleClose}>
                <ArrowBackIcon sx={{ color: grey[50] }} />
              </IconButton>
            </Grid>

            <Grid
              item
              xs={8}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h5" color="#ffffff">
                Editar certificadora
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <form onSubmit={guardarCertificadora}>
          <Box
            backgroundColor="#F4F6F6"
            mt={2}
            p={3}
            border={0.5}
            borderColor={"#ABB2B9"}
            borderRadius={4}
          >
            <Grid container spacing={1} direction={{ xs: "column", sm: "row" }}>
              <Grid item xs={6}>
                <TextField
                  placeholder="Certificadora"
                  required
                  focused
                  name="nombre" // importante
                  id="certificadora"
                  label="Certificadora"
                  fullWidth
                  style={style}
                  color="primary"
                  onChange={almacenar}
                  value={datosformulario.nombre.replace(/\b\w/g, (l) =>
                    l.toUpperCase()
                  )}
                  inputProps={{ maxLength: 45 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FactCheckIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  placeholder="Contacto"
                  required
                  focused
                  fullWidth
                  name="contacto"
                  id="contacto"
                  label="Contacto"
                  style={style}
                  color="primary"
                  onChange={almacenar}
                  value={datosformulario.contacto}
                  inputProps={{ maxLength: 100 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <ContactsIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  style={{ backgroundColor: "#FFFFFF" }}
                  fullWidth
                  focused
                  type="text"
                  color="primary"
                  placeholder="Telefono"
                  name="telefono"
                  label="Telefono"
                  onChange={almacenar}
                  value={datosformulario.telefono}
                  inputProps={{
                    maxLength: 10,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">{codArg}</InputAdornment>
                    ),
                  }}
                  helperText="Cód área (sin 0) + número sin el 15. Ej:341xxxxxx "
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Grid>
              <Grid
                mt={2}
                container
                direction="row"
                justifyContent="space-between"
                alignItemns="center"
              >
                <Button
                  disabled={disBtn}
                  variant="contained"
                  color="error"
                  onClick={handleClose}
                >
                  Volver
                </Button>
                <Button
                  disabled={disBtn}
                  variant="contained"
                  type="submit"
                  color="success"
                >
                  Guardar Certificadora
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>

      </Container>
    </div>
  );
}

export default Editarcertificadora;
