//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio
// Creation Date : 05/08/2024
// Version : 1
// Description : tabla de cantidad por instaladores,estados y meses
//------Changes ----------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useEffect, useState } from "react";
import { UrlApi } from "../../../../services/apirest";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import Button from "@mui/material/Button";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Dialog_graf_insta from "./Dialog_graf_insta";


function Tabla_instaladores() {
  const [instaladorXmes, setInstaladorXmes] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    dayjs().subtract(1, "month").startOf("month")
  );

  const obtenerInstaladorXmes = () => {
    const formattedDate = selectedDate.format("YYYY"); // Formatear la fecha seleccionada
    fetch(UrlApi + "/reclamos/cantidadPorInstaladores/fecha/" + formattedDate)
      .then((response) => response.json())
      .then((data) => setInstaladorXmes(data));
  };

  useEffect(() => {
    obtenerInstaladorXmes();
  }, [selectedDate]);

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  //console.log("INSTALADOR X MES", instaladorXmes);
  //------------------ Dialog --------------------------//
  const [abrirDialog, setAbrirDialog] = useState(false);

  const handleOpenDialog = () => {
    setAbrirDialog(true);
  };

  const handleCloseDialog = () => {
    setAbrirDialog(false);
  };
  //-------------------------------------------------//
  const paperStyle3 = {
    padding: "10px",
    margin: "10px",
    width: "1200px",
  };

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const todosLosMeses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const obtenerNombreMes = (fecha) => {
    const mes = dayjs(fecha, "YYYY-MM").format("M") - 1; // Extraer el mes (0-11)
    return todosLosMeses[mes];
  };
  const transformarDatosParaBarChart = (data) => {
    const estados = Array.from(
      new Set(data.map((item) => item.valor_estado_abreviado))
    );
    const proveedores = Array.from(
      new Set(data.map((item) => item.razon_social))
    );

    const acumulador = todosLosMeses.reduce((acc, mes) => {
      acc[mes] = proveedores.reduce((provAcc, proveedor) => {
        provAcc[proveedor] = estados.reduce((estadoAcc, estado) => {
          estadoAcc[estado] = 0;
          return estadoAcc;
        }, {});
        return provAcc;
      }, {});
      return acc;
    }, {});

    data.forEach((item) => {
      const nombreMes = obtenerNombreMes(item.fecha);
      if (acumulador[nombreMes]) {
        acumulador[nombreMes][item.razon_social][item.valor_estado_abreviado] =
          item.cantidad;
      }
    });

    const dataset = todosLosMeses.map((mes) => ({
      mes,
      ...acumulador[mes],
    }));

    return { dataset, estados, proveedores, meses: todosLosMeses };
  };

  const { dataset, estados, proveedores, meses } =
    transformarDatosParaBarChart(instaladorXmes);

  return (
    <div>
      <Paper elevation={10} style={{ ...paperStyle3 }}>
        <TableContainer style={{ maxHeight: "50vh" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 10,
              marginTop: 10,
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
              <DatePicker
                views={["year"]}
                label="Año"
                value={selectedDate}
                onChange={handleDateChange}
                slotProps={{
                  textField: {
                    size: "small",
                    // sx: { width: "150px" },
                  },
                }}
              />
            </LocalizationProvider>
            <Typography
              align="center"
              style={{ fontWeight: "bold", fontSize: 20 }}
            >
              Instaladores por estado {selectedDate.year()}
            </Typography>
            <Button
              variant="contained"
              size="small"
              endIcon={<FullscreenIcon />}
              onClick={handleOpenDialog}
            >
              Expandir
            </Button>
          </div>
          <Table
            sx={{ minWidth: 300, marginTop: 2 }}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    border: "1px solid black",
                    position: "relative",
                    backgroundColor: "#2B76A6",
                    color: "white",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <b>Meses</b>
                  </div>
                </TableCell>
                {meses.map((mes, mesIndex) => (
                  <TableCell
                    key={mesIndex}
                    align="center"
                    colSpan={estados.length}
                    style={{
                      backgroundColor: "#2B76A6",
                      color: "white",
                      border: "2px solid black",
                    }}
                  >
                    {mes}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    border: "1px solid black",
                    backgroundColor: "#2B76A6",
                    color: "white",
                  }}
                >
                  <b>Estado</b>
                  <hr
                    style={{
                      border: "none",
                      borderTop: "1px solid black",
                    }}
                  />
                  <b>Instaladores</b>
                </TableCell>
                {meses.map((mes, mesIndex) =>
                  estados.map((estado, estadoIndex) => (
                    <TableCell
                      key={`${mesIndex}-${estadoIndex}`}
                      align="center"
                      style={{
                        backgroundColor: "#2B76A6",
                        color: "white",
                        border: "2px solid black", // border que tiene problema y desaparece
                        borderBottom: "2px solid black",
                        borderLeft:
                          estadoIndex === 0 ? "2px solid black" : undefined, // Borde izquierdo para el primer estado
                        borderRight:
                          estadoIndex === estados.length - 1
                            ? "2px solid black"
                            : undefined, // Borde derecho para el último estado
                      }}
                    >
                      {estado}
                    </TableCell>
                  ))
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {proveedores.map((proveedor, proveedorIndex) => (
                <TableRow
                  key={proveedorIndex}
                  sx={{
                    border: "1px solid black",
                    backgroundColor:
                      proveedorIndex % 2 === 0 ? "#FFFFFF" : "#8D8D8D", // Alterna colores
                  }}
                >
                  <TableCell align="center" sx={{ border: "1px solid black" }}>
                    {proveedor}
                  </TableCell>
                  {meses.map((mes, mesIndex) =>
                    estados.map((estado, estadoIndex) => (
                      <TableCell
                        key={`${mesIndex}-${estadoIndex}`}
                        align="center"
                        sx={{
                          border: "1px solid black",
                          borderLeft:
                            estadoIndex === 0 ? "2px solid black" : undefined,
                          borderRight:
                            estadoIndex === estados.length - 1
                              ? "2px solid black"
                              : undefined,
                        }}
                      >
                        {dataset.find(
                          (item) =>
                            item.mes === mes &&
                            item[proveedor] &&
                            item[proveedor][estado] !== undefined
                        )?.[proveedor][estado] || 0}
                      </TableCell>
                    ))
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {abrirDialog ? (
          <Dialog_graf_insta
            abrirDialog={abrirDialog}
            onClose={handleCloseDialog}
            dataset={dataset}
            estados={estados}
            proveedores={proveedores}
            meses={meses}
            selectedDate={selectedDate}
          />
        ) : null}
      </Paper>
    </div>
  );
}

export default Tabla_instaladores;
