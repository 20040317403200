//----- Component --------------------------------------------------------/

// Developer :  Daiana Curcio / Candela Grosso

// Creation Date :15/12/2023

// Version : 1

// Description : Para ver el detalle de los reclamos (motivos,detalle,usuario y técnico)

//

//------Changes --------------------------------------------------------------------- -------------//

// Change Date : 12-3

// Change by : Magali Perea

// Change description: Se añadio al detalle el encargado de pagar los costos del reclamo.

//

// Change Date :

// Change by :

// Change description:

//

//========================================================================/
import React, { useState } from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PersonIcon from "@mui/icons-material/Person";
import BuildCircle from "@mui/icons-material/BuildCircle";
import AssignmentIcon from "@mui/icons-material/Assignment";
import HomeIcon from "@mui/icons-material/Home";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
const ListItemComponent = ({ icon, primary, secondary }) => (
  <ListItem>
    <ListItemAvatar>
      <Avatar sx={{ bgcolor: "#EBF5FB", width: 60, height: 30 }}>{icon}</Avatar>
    </ListItemAvatar>
    <ListItemText primary={primary} secondary={secondary} />
  </ListItem>
);

const DetalleViaje = (props) => {
  const { datos } = props;
  //   if (!datos) {
  //     return null;
  //   }
  //   console.log("detalles", datos);
  return (
    <div>
      <Grid container>
        <List
          sx={{
            width: "1250px",
            bgcolor: "#EBF5FB",
            border: 0.5,
            borderRadius: 1,
            borderColor: "#A9CCE3",
            display: "flex",
          }}
        >
          <ListItemComponent
            primary="Origen del Vehiculo"
            secondary={datos.nombre_origen}
          />
          <ListItemComponent
            primary="Vehiculo"
            secondary={datos.patente}
          />
          <ListItemComponent
            primary="Pto. de Venta"
            secondary={datos.nro_remito_puntodeventa}
          />
          <ListItemComponent
            primary="Nro. Remito"
            secondary={datos.nro_remito_numero}
          />
          </List>
          <List
          sx={{
            width: "1250px",
            bgcolor: "#EBF5FB",
            border: 0.5,
            borderRadius: 1,
            borderColor: "#A9CCE3",
            display: "flex",
          }}
          >
          <ListItemComponent
            primary="Kilometraje Inicial"
            secondary={datos.kilometraje_inicial}
          />
          <ListItemComponent
            primary="Kilometraje Final"
            secondary={datos.kilometraje_final}
          />
          <ListItemComponent
            primary="Fecha Real de Llegada"
            secondary={datos.fecha_llegada}
          />
          <ListItemComponent
            primary="Salida de la petrolera"
            secondary={datos.salida_petrolera}
          />

        </List>
        
      </Grid>
    </div>
  );
};

export default DetalleViaje;
