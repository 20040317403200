import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { Alert, AlertTitle, IconButton, Modal } from '@mui/material'
import React, { useState } from 'react'

function ControlCargaDatosStockProducto(props) {
    console.log(props.kontrol)
    const [open,setOpen]=useState(true)
    const handleCerrar=()=>{
        setOpen(false)
        props.open(false)
    }

  return (
    <div>
        <Modal open={open} onClose={handleCerrar}
        sx={{
            position: 'absolute',
            left: 120,
            top: 100,
            color: (theme) => theme.palette.grey[500],
        }}
        >
        <Alert
            severity='error'
            style={{width:'50%'}}
            action={  
                <IconButton onClick={handleCerrar}>
                    <CancelPresentationIcon />
                </IconButton>}
            >
        <AlertTitle>Sucursales Faltantes</AlertTitle>
           {props.kontrol.filter(kont => kont.datos===0).map((key, i) => (
            <p key={i}>
                {key.nombre}
            </p>
      ))}
      </Alert>
      </Modal>
      </div>
  )
}



export default ControlCargaDatosStockProducto