import { useState, useEffect } from 'react';
import mqtt from 'mqtt';

const useMqttSubscription = (topic, onMessage) => {
    const [client, setClient] = useState(null);
    const [connectionStatus, setConnectionStatus] = useState('Connecting...');

    useEffect(() => {
        console.log('Connecting to MQTT broker...');

        const client = mqtt.connect('wss://mqtt.netizar.com', {
            username: process.env.REACT_APP_MQTT_USERNAME,
            password: process.env.REACT_APP_MQTT_PASSWORD,
        });

        client.on('connect', () => {
            console.log('Connected to MQTT broker');
            setConnectionStatus('Connected');

            client.subscribe(topic, (err) => {
                if (err) {
                    console.error(`Failed to subscribe to topic: ${topic}`, err);
                } else {
                    console.log(`Subscribed to topic: ${topic}`);
                }
            });
        });

        client.on('message', (topic, message) => {
            const payload = JSON.parse(message.toString());
            console.log(`Message received from topic: ${topic}`, payload);
            onMessage(payload);
        });

        client.on('error', (err) => {
            console.error('MQTT Connection Error:', err);
            setConnectionStatus('Error');
        });

        client.on('offline', () => {
            console.log('MQTT client is offline');
            setConnectionStatus('Disconnected');
        });

        client.on('reconnect', () => {
            console.log('Reconnecting to MQTT broker...');
        });

        setClient(client);

        return () => {
            console.log('Disconnecting from MQTT broker...');
            if (client) {
                client.end();
            }
        };
    }, [topic]);

    return { client, connectionStatus };
};

export default useMqttSubscription;
