import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material/";
import { makeStyles } from "@mui/styles";
import { getData } from "../../../services/library";

/* const useStyles = makeStyles({
  customFormControlLabel: {
    border: '2px solid',
    borderColor: 'red', // Color inicial del borde
    borderRadius:"5px",
    width:"100%",
    transition: 'border-color 1s ease-in-out',
      animation: '$cambioColor 4s infinite',
  },
  '@keyframes cambioColor': {
    '0%': {
      borderColor: 'red',
    },
    '25%': {
      borderColor: 'blue',
    },
    '50%': {
      borderColor: 'pink',
    },
    '100%': {
      borderColor: 'red',
    },
  },
}); */

function FormCondEsp({ DatosFormulario, almacenarChecks, onChange }) {
  const [usuarios, setusuarios] = useState([]);

  //const classes = useStyles();
  useEffect(() => {
    getData("/userdata/proveedores/usuarioapp", setusuarios);
  }, []);

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
    borderRadius: "5px",
    borderBlock: "2px solid",
    borderColor: "#0766AD",
    color: "#000",
  };

  console.log(DatosFormulario);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <FormControlLabel
            //className={classes.customFormControlLabel}
            style={style}
            control={
              <Checkbox
                name="factura_sin_orden_compra"
                checked={DatosFormulario.factura_sin_orden_compra}
                value={DatosFormulario.factura_sin_orden_compra}
                onClick={almacenarChecks}
                color="primary"
              />
            }
            label="Factura sin Orden de Compra"
          />
        </Grid>

        <Grid item xs={3}>
          <FormControlLabel
            //className={classes.customFormControlLabel}
            style={style}
            control={
              <Checkbox
                name="es_proveedor_de_gas"
                checked={DatosFormulario.es_proveedor_de_gas}
                value={DatosFormulario.es_proveedor_de_gas}
                onClick={almacenarChecks}
                color="primary"
              />
            }
            label="Proveedor de Gas"
          />
        </Grid>

        <Grid item xs={3}>
          <FormControlLabel
            //className={classes.customFormControlLabel}
            style={style}
            value="start"
            control={
              <Checkbox
                name="es_instalador"
                checked={DatosFormulario.es_instalador}
                value={DatosFormulario.es_instalador}
                onClick={almacenarChecks}
                color="primary"
              />
            }
            label="Es Instalador"
          />
        </Grid>
        {DatosFormulario.es_instalador === true ? (
          <Grid item xs={3}>
            <FormControl fullWidth focused>
              <InputLabel>Usuarios</InputLabel>
              <Select
                name="usuarioapp"
                value={DatosFormulario.usuarioapp}
                onChange={onChange}
                fullWidth
                required
                label="Usuarios"
              >
                {usuarios
                  ? usuarios.map((elemento) => (
                      <MenuItem key={elemento.id} value={elemento.username}>
                        {elemento.username}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </FormControl>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
}

export default FormCondEsp;
