import React, { useState, useEffect } from 'react';
import { FormControlLabel, Switch, Autocomplete, FormControl, InputLabel, Select, MenuItem, TextField, Button, DialogActions, DialogContent, Dialog, Typography, Box, Grid } from '@mui/material'
import { v4 as uuidv4 } from "uuid";
import { getData, postData } from '../../../../services/library';
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FileUploadMultiple from '../../../../services/UploadFiles';
import Notify from '../../../Notify';
import { enviarmails } from '../../../../services/services';
import { UrlApi } from '../../../../services/apirest';

const AddArticulosDialog = ({ open, onClose, onAgregarArticulo, actualizar }) => {
    const [uuid, setUuid] = useState(uuidv4());
    const [proveedores, setProveedores] = useState();
    const [openCloseAdj, setOpenCloseAdj] = useState(false);
    const [checked, setChecked] = React.useState(false);
    const [proveedorSeleccionado, setProveedorSeleccionado] = useState({});
    const [familia, setFamilias] = useState([]);
    const [familiaSelected, setfamiliaSelected] = useState({});
    const [unidadMedida, setUnidadMedida] = useState("");
    const [disBtn, setDisBtn] = useState(false);
    const [ultimaRow, setUltimaRow] = useState();
    const [users, setUsers] = useState(null);
    const [lastcode, setlastCode] = useState();

    const [notificacion, setNotificacion] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
    });

    let codigoFamilia =
        familiaSelected &&
        (familiaSelected.nro1 != null ? familiaSelected.nro1.toString() : "") +
        (familiaSelected.nro2 != null ? familiaSelected.nro2.toString() : "") +
        (familiaSelected.nro3 != null ? familiaSelected.nro3.toString() : "");


    let codigoValor = lastcode && lastcode.length > 0 ? (parseInt(lastcode[0].codigo.substring(3)) + 1) : 0;

    let codigoAuto = "";
    if (familiaSelected && codigoFamilia) {
        codigoAuto = codigoFamilia + codigoValor;
    } else if (familiaSelected && familiaSelected.nro1 && familiaSelected.nro2 && familiaSelected.nro3) {
        codigoAuto = familiaSelected.nro1.toString() +
            familiaSelected.nro2.toString() +
            familiaSelected.nro3.toString() +
            (codigoValor + 1).toString();
    };

    useEffect(() => {
        familiaSelected && getData('/stock_articulos/codigo/' + familiaSelected.nro1 + '/' + familiaSelected.nro2 + '/' + familiaSelected.nro3, setlastCode)
    }, [familiaSelected]);

    const [nuevoArticulo, setNuevoArticulo] = useState({
        id_familia: null,
        codigo: "",
        nombre: "",
        descripcion: "",
        id_unidadmedida: null,
        valor_minimo: "",
        alerta_minima: "",
        usuario: localStorage.getItem("user"),
        uuid_articulo: uuid,
        estado: 1,
        aprobado: 0
    });

    const handleChange = (e) => {
        setNuevoArticulo({
            ...nuevoArticulo,
            [e.target.name]: e.target.value,
        });
    };

    const handleAgregar = (e) => {
        // Aquí puedes realizar validaciones antes de agregar el artículo
        // onAgregarArticulo(nuevoArticulo);
        setDisBtn(true)
        postData(e,
            '/stock_articulos',
            {
                id_familia: familiaSelected.id_familia,
                codigo: codigoAuto,
                nombre: nuevoArticulo.nombre,
                descripcion: nuevoArticulo.descripcion,
                id_unidadmedida: nuevoArticulo.id_unidadmedida,
                valor_minimo: nuevoArticulo.valor_minimo,
                alerta_minima: nuevoArticulo.alerta_minima,
                usuario: localStorage.getItem("user"),
                uuid_articulo: uuid,
                estado: 1,
                aprobado: 0,
                stock_variable: checked ? 1 : 0

            },
            setNotificacion,
        )
        actualizar()
        guardarRelacion()
        onClose();
        {
            users && users.length > 0 && users.map((usuario) => {
                // Verificar que el usuario tiene una dirección de correo electrónico
                if (usuario.email) {
                    // Llamar a la función para enviar correos electrónicos
                    enviarmails(usuario.email, "Solicitud de Articulos", `<p>Se ha enviado una solicitud para aprobar un artículo</p>`);
                } else {
                    console.warn(`El usuario con ID ${usuario.id} no tiene una dirección de correo electrónico válida.`);
                }
            })
        }
    };
    
    {/*LA RELACION SE GUARDA CUANDO EL ARTICULO SE APRUEBA */ }
    const guardarRelacion = () => {
        // guardar relacion
        for (let i = 0; i < proveedorSeleccionado.length; i++) {
            const provee = proveedorSeleccionado[i].id;
            fetch(UrlApi + "/stock_articulos_proveedores", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    id_articulo: ultimaRow ? parseInt(ultimaRow[0].id_articulo) + parseInt(1) : 1,
                    id_proveedor: provee,
                    usuario: localStorage.getItem("user"),
                })
            })
        }
    }

    const style = {
        backgroundColor: "#ffffff",
        width: "100%",
    };

    const handleOpenCloseAdj = (value) => {
        setOpenCloseAdj(value);
    };

    useEffect(() => {
        getData("/proveedores", setProveedores)
        getData('/unidad_medida', setUnidadMedida)
        getData('/stock_familias', setFamilias)
        getData('/stock_articulos/ultimarow', setUltimaRow)
        getData('/userdata/sector/4', setUsers)
    }, []);

    const handleChangeSwitch = (e) => {
        setChecked(e.target.checked)
    };

    const handleFamiliaChange = (event) => {
        const familiaId = event.target.value;
        const selectedFamilia = familia.find((elemento) => elemento.id_familia === familiaId);
        setfamiliaSelected(selectedFamilia);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container backgroundColor="#1F618D" p={1.5}>
                    {/* <Grid item xs={2}>
                        <IconButton onClick={handleClose}>
                            <ArrowBackIcon sx={{ color: grey[50] }} />
                        </IconButton>
                    </Grid> */}
                    <Grid
                        item
                        xs={12}
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Typography variant="h5" color="#ffffff">
                            Agregar nuevo articulo
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <TextField
                            disabled
                            label="Código"
                            name="codigo"
                            size="small"
                            placeholder="Ej. 1234"
                            autoComplete="off"
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            InputProps={{
                                readOnly: true
                            }}
                            focused
                            required
                            style={style}
                            value={codigoAuto && codigoAuto ||  "0000"}
                            // value={nuevoArticulo.codigo}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            label="Nombre"
                            name="nombre"
                            size="small"
                            placeholder="..."
                            inputProps={{
                                maxLength: 55,
                            }}
                            focused
                            required
                            style={style}
                            autoComplete="off"
                            value={nuevoArticulo.nombre}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Descripción"
                            name="descripcion"
                            size="small"
                            placeholder="..."
                            inputProps={{
                                maxLength: 60,
                            }}
                            focused
                            required
                            style={style}
                            autoComplete="off"
                            value={nuevoArticulo.descripcion}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl style={style} focused>
                            <InputLabel>Unidad de medida</InputLabel>
                            <Select
                                label="Unidad de medida"
                                required
                                size="small"
                                name="id_unidadmedida"
                                value={nuevoArticulo.id_unidadmedida}
                                onChange={handleChange}
                                inputProps={{ "aria-label": "Without label" }}
                            >
                                {unidadMedida
                                    ? unidadMedida.map((elemento) => (
                                        <MenuItem key={elemento.id} value={elemento.id}>
                                            {elemento.descripcion} - {elemento.abreviatura}
                                        </MenuItem>
                                    ))
                                    : null}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl style={style} focused required>
                            <InputLabel>Familia</InputLabel>
                            <Select
                                label="Familia"
                                size="small"
                                name="id_familia"
                                value={familiaSelected ? familiaSelected.id_familia : ''}
                                onChange={handleFamiliaChange}
                            >
                                {familia
                                    ? familia.map((elemento) => (
                                        <MenuItem key={elemento.id_familia} value={elemento.id_familia}>
                                            {elemento.descripcion}
                                        </MenuItem>
                                    ))
                                    : null}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={9}>
                        {/* {checked ? 
                        <span style={{ color: 'black', fontSize: '18px', margin: '15px' }}>
                            Stock General
                        </span>
                        :
                        <span style={{ color: 'black', fontWeight: 'bold', fontSize: '18px', margin: '15px' }}>
                        Stock General
                        </span>
                        } */}
                        <FormControlLabel control={<Switch
                            checked={checked}
                            onChange={handleChangeSwitch}
                        />} label={checked ? <span style={{ color: 'black', fontWeight: 'bold', fontSize: '18px' }}>Stock Variable</span> : <span style={{ color: 'black', fontSize: '18px' }}>Stock General</span>} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Stock Mínimo"
                            name="valor_minimo"
                            size="small"
                            placeholder="..."
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            inputProps={{
                                maxLength: 6,
                            }}
                            focused
                            required={checked ? false : true}
                            style={style}
                            autoComplete="off"
                            value={nuevoArticulo.valor_minimo}
                            onChange={handleChange}
                            disabled={checked ? true : false}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Punto de Pedido"
                            name="alerta_minima"
                            size="small"
                            placeholder="..."
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            inputProps={{
                                maxLength: 6,
                            }}
                            focused
                            fullWidth
                            autoComplete="off"
                            value={nuevoArticulo.alerta_minima}
                            onChange={handleChange}
                            disabled={checked ? true : false}
                            required={checked ? false : true}
                            // helperText={
                            //     nuevoArticulo.valor_minimo && nuevoArticulo.valor_minimo < nuevoArticulo.alerta_minima ? (
                            //         <>
                            //             "Punto de pedido no puede ser mayor a stok minimo"
                            //         </>
                            //     ) : null
                            // }
                        />
                    </Grid>
                    {/* <Grid item xs={6}>
                        <FormControlLabel control={<Switch
                            checked={checkedConvert}
                            onChange={handleChangeSwitchConvertir}
                        />} label={checkedConvert ? <span style={{ color: 'black', fontWeight: 'bold', fontSize: '18px' }}>Convertible</span> : <span style={{ color: 'black', fontSize: '18px' }}>Convertible</span>} />
                    </Grid>
                    {checkedConvert ?
                        <Grid item xs={6}>
                            <Autocomplete
                                disabled={!articulos}
                                size="small"
                                focused
                                disableClearable
                                id="stock_articulos_elect"
                                noOptionsText={"Artículo INEXISTENTE"}
                                options={articulosElect}
                                autoHighlight
                                getOptionLabel={(articulos) =>
                                    articulos.codigo + "-" + articulos.nombre
                                }
                                onChange={(event, value) =>
                                    setArticuloSeleccionado(value)
                                }
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        key={option.id}
                                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                        {...props}
                                    >
                                        {option.codigo}-{option.nombre}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        focused
                                        required
                                        size="small"
                                        placeholder="Tipee para buscar..."
                                        {...params}
                                        label="Convertible a"
                                        inputProps={{
                                            ...params.inputProps,
                                        }}
                                    />
                                )}
                            />
                        </Grid> : null} */}
                    <Grid item xs={6}>
                        <Button
                            onClick={() => setOpenCloseAdj(!openCloseAdj)}
                            variant="outlined"
                            size="medium"
                            startIcon={<AttachFileIcon />}
                        >
                            ADJUNTAR
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            multiple
                            disabled={!proveedores}
                            size="small"
                            focused
                            required
                            disableClearable
                            id="proveedores"
                            noOptionsText={"Proveedor INEXISTENTE"}
                            options={proveedores}
                            autoHighlight
                            getOptionLabel={(proveedores) =>
                                proveedores.razon_social + "-" + proveedores.nro_cuit
                            }
                            onChange={(event, value) =>
                                setProveedorSeleccionado(value)
                            }
                            renderOption={(props, option) => (
                                <Box
                                    component="li"
                                    key={option.id}
                                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                    {...props}
                                >
                                    {option.razon_social} - {option.nro_cuit}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    focused
                                    size="small"
                                    placeholder="Tipee para buscar..."
                                    {...params}
                                    label="Proveedor"
                                    inputProps={{
                                        ...params.inputProps,
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                {openCloseAdj ? ( // ADJUNTAR IMAGEN
                    <FileUploadMultiple
                        fileId={nuevoArticulo.uuid_articulo}
                        openclose={true}
                        mostrar={true}
                        onClose={() => handleOpenCloseAdj(false)} // Proporciona una función de cierre
                    />
                ) : null}
            </DialogContent>
            <DialogActions>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItemns="center"
                >
                    <Button variant="contained" onClick={onClose} color="error">
                        Volver
                    </Button>
                    <Button disabled={disBtn} variant="contained" onClick={(e) => handleAgregar(e)} color="success">
                        Agregar
                    </Button>
                </Grid>
            </DialogActions>
            {notificacion && notificacion.open ? (
                <Notify
                    mensaje={notificacion.mensaje}
                    open={notificacion.open}
                    color={notificacion.color}
                    handleClose={onClose}
                    severity={notificacion.severity}
                />
            ) : null}
        </Dialog>
    );
};

export default AddArticulosDialog;
