import React, { useEffect, useState, useMemo } from 'react';
import { putData, getData } from '../../../services/library';
import { Container, Typography,  Box, Button,  MenuItem,  ListItemIcon } from '@mui/material';
import Navbar from '../../Navbar';
import MaterialReactTable, { MRT_FullScreenToggleButton } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import EditIcon from '@mui/icons-material/Edit';
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import PeopleIcon from '@mui/icons-material/People';
import AddProductos from './AddProductos';
import EditarProductos from './EditarProductos';

export default function Productos() {
    const globalTheme = useTheme();
    const [productos, setproductos] = useState([{}]);
    const [datosformulario, setDatosformulario] = useState({});
    const [alta, setAlta] = useState(false); //Estado q mostrará o no la pantalla de alta
    const [tabla, setTabla] = useState(true); //Estado q mostrará o no la tabla (pantalla inicial) dnd estan los choferes
    const [editar, setEditar] = useState(false);

    const getProductos = () => {
        getData('/productos', setproductos)
    };

    useEffect(() => {
        getProductos()
    }, [tabla]);

    const handleShowFormAdd = () => {
        setAlta(true);
        // setTabla(true)
    };

    const handleShowFormEdit = (rowData) => {
        setEditar(true);
        setTabla(true)
        setDatosformulario(rowData);
    };

    const columns = useMemo(() =>
        [{ header: '#', accessorKey: 'idproducto', size: 85 },
        { header: 'Código', accessorKey: 'codigoproducto', size: 85 },
        { header: 'Producto', accessorKey: 'nombre' },
        { header: 'Uso', accessorKey: 'valor' },
            // { header: 'Legajo', accessorKey: 'legajo', size: 100 },
            // { header: 'Telefono', accessorKey: 'telefono' },
            // //{ header: 'Nro Licencia', accessorKey: 'nro_licencia' },
            // { header: 'Vencimiento Licencia', accessorFn: (choferes) => !!choferes.vencimiento_licencia ? moment(choferes.vencimiento_licencia).format("DD-MM-YYYY") : "No hay registro" },
            // //{ header: 'Examen Carga', accessorKey: 'examen_carga', type: "date", format:"DD-MM-YYYY" },
            // { header: 'Negocio', accessorKey: 'valor' },
            //     // { header: 'Activo', accessorKey: 'activo', enableGrouping: true },
        ], []);

    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: globalTheme.palette.mode,
                    primary: globalTheme.palette.primary, //primary color for the table
                    info: {
                        main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            globalTheme.palette.mode === "light"
                                ? "rgb(234, 239, 241)"
                                : "#000", //pure black table in dark mode for fun
                    },
                },
                typography: {
                    button: {
                        textTransform: "none", //customize typography styles for all buttons in table by default
                        fontSize: "1rem",
                    },
                },
                components: {
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                fontSize: "0.8rem", //larger tooltip font size                                
                            },
                        },
                    },
                },

            }), [globalTheme]);

    const muiSearchTextFieldProps = {
        placeholder: "Buscar..",
        sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
        variant: "outlined",
        color: "success",
        size: "small",
    };

    const initialState = {
        density: "compact",
        showGlobalFilter: true,
        pagination: { pageIndex: 0, pageSize: 10 }
    };

    const tableHeadCellProps = {
        align: "left",
        sx: {
            fontSize: 16,
            color: "white",
            fontWeight: "normal",
            backgroundColor: "#2471A3",
            borderColor: "black",
        },
    };

    return (
        <div>
            <Navbar />
            <Container maxWidth="xl" mt={1} mb={2}>
                {productos && tabla &&
                    <Box mt={2} boxShadow={3}>
                        <ThemeProvider theme={tableTheme}>
                            <MaterialReactTable
                                columns={columns}
                                data={productos}
                                enableColumnResizing
                                initialState={initialState}
                                positionActionsColumn='last'
                                enableExpandAll={false}
                                enableToolbarInternalActions={true}
                                enableEditing={false}
                                enableRowActions
                                muiTableHeadCellProps={tableHeadCellProps}
                                muiTableBodyCellProps={({ row }) => ({
                                    align: "left",
                                    sx: {
                                        backgroundColor: "#b3e6b3",
                                        color: "black",
                                        borderColor: "black",
                                    },
                                })}
                                enableDensityToggle={false}
                                renderTopToolbarCustomActions={() => (
                                    <>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<AddBoxTwoToneIcon />}
                                            onClick={handleShowFormAdd}>
                                            Nuevo Registro
                                        </Button>
                                        <Typography variant="poster" fontSize={26}>
                                            <PeopleIcon sx={{ size: 20 }} />
                                            Productos
                                        </Typography>
                                    </>
                                )}
                                muiSearchTextFieldProps={muiSearchTextFieldProps}
                                layoutMode='grid'
                                localization={MRT_Localization_ES}
                                enableColumnDragging={false}
                                renderRowActionMenuItems={({ closeMenu, row, table }) => (
                                    <MenuItem
                                        key={0}
                                        onClick={() => {
                                            handleShowFormEdit(row.original);
                                            closeMenu();
                                        }}>
                                        <ListItemIcon>
                                            <EditIcon color="primary" />
                                        </ListItemIcon>
                                        Editar
                                    </MenuItem>
                                )} />
                        </ThemeProvider>
                    </Box>
                }

                {alta && <AddProductos datos={datosformulario} open={setAlta} actualizar={getProductos} tabla={setTabla}/>}
                {editar && <EditarProductos datos={datosformulario} open={setEditar} tabla={setTabla} actualizar={getProductos}/>}
            </Container>
        </div>
    )
}
