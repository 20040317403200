import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import moment from "moment"

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  
function ModalMotivoInactivo(props) {
  return (
    <div>
         <div>
      <Modal
        open={props.open}
        onClose={()=>props.setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Datos Baja Cliente: {props.cliente.razon_social}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Observaciones: {props.cliente.motivo_inactivo}
            <br/>
            Fecha: {props.cliente.fecha_inactivo ? moment(props.cliente.fecha_inactivo).format("YYYY-MM-DD") : "No Registrada" }
          </Typography>
        </Box>
      </Modal>
    </div>
    </div>
  )
}

export default ModalMotivoInactivo