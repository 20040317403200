import React, { useEffect, useState } from "react";
import Title from "../../Title";
import Notify from "../../Notify";
import {
  Box,
  Container,
  Typography,
  IconButton,
  Card,
  CardContent,
  CardActions,
  Tooltip,
} from "@mui/material";
import { getData } from "../../../services/library";
import CommitIcon from "@mui/icons-material/Commit";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import DirectionsBusFilledIcon from "@mui/icons-material/DirectionsBusFilled";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@mui/material";
import VincularVehiculos from "./Acoples/VincularVehiculos";
import DesvincularVehiculos from "./Acoples/DesvincularVehiculos";

const ListItemComponent = ({ icon, secondary, icon2 }) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="space-between"
    gap={2}
    borderBottom={0.5}
    borderColor={"#ABB2B9"}
  >
    {/* Ícono inicial */}
    <Box display="flex" alignItems="center" gap={1} flexGrow={1}>
      <IconButton size="small">{icon}</IconButton>
      <Typography variant="body1" noWrap>
        {secondary}
      </Typography>
    </Box>

    {/* Botón de eliminar */}
    <IconButton size="small">{icon2}</IconButton>
  </Box>
);

function ConjuntoVehiculos(props) {
  const [datosVehiculo, setDatosVehiculo] = useState(props.datos);
  const [datosLinea, setDatosLinea] = useState()
  console.log("props.datos", props.datos);
  const [tabla, setTabla] = useState(true);
  const [disBtn, setDisbtn] = useState(false);
  const [notificacion, setNotificacion] = useState({
    open: false,
    mensaje: "",
    severity: "",
    color: "",
  });
  const [vincular, setVincular] = useState(false)
  const [desvincular, setDesvincular] = useState(false)
  const [conjuntoVehiculos, setConjuntoVehiculos] = useState();
  const obtenerConjuntos = () => {
    getData(
        "/vehiculos_conjuntos/xvehiculo/" + datosVehiculo.idvehiculo,
        setConjuntoVehiculos
      );
  };

  useEffect(() => {
    obtenerConjuntos()
  }, []);

  console.log("conjunto", conjuntoVehiculos);

  const handleClose = () => {
    props.tabla(true);
    props.conjuntos(false);
  };

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  const handleClickVincular = () => {
    setVincular(true);
  };
  const handleClickDesvincular = (datos) => {
    setDatosLinea(datos)
    setDesvincular(true);
  };

  const handleCloseVincular = () => {
    setVincular(false);
    obtenerConjuntos()
  };
  const handleCloseDesvincular = () => {
    setDesvincular(false);
    obtenerConjuntos()
  };
  return (
    <div>
      {tabla ? (
        <Container>
          {notificacion && notificacion.open ? (
            <Notify
              mensaje={notificacion.mensaje}
              open={notificacion.open}
              color={notificacion.color}
              handleClose={handleClose}
              severity={notificacion.severity}
            />
          ) : null}
          <Title titulo="Acoplar Vehículos" handleClose={handleClose} />
          <form autoComplete="off">
            <Box
              mt={2}
              mb={3}
              display="flex"
              alignItems="center"
              gap={2}
              backgroundColor="#F4F6F6"
              p={2}
              border={0.5}
              borderColor={"#ABB2B9"}
              borderRadius={4}
            >
              {/* Tarjeta del vehículo principal */}
              <Card elevation={3} sx={{ flex: 1 }}>
                <CardContent>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    gutterBottom
                    display="flex"
                    alignItems="center"
                    gap={1}
                  >
                    Vehículo Principal
                  </Typography>
                  <List>
                    <ListItemComponent
                      icon={<DirectionsBusFilledIcon />}
                      secondary={datosVehiculo.patente}
                      icon2={
                        <Tooltip
                          arrow
                          placement="left"
                          title={"Acoplar Vehiculo"}
                        >
                          <AddCircleOutlineIcon color="success" onClick={()=>handleClickVincular()}/>
                        </Tooltip>
                      }
                    />
                  </List>
                </CardContent>
              </Card>

              {/* Ícono entre las tarjetas */}
              <CommitIcon fontSize="large" color="primary" />

              {/* Tarjeta de vehículos acoplados */}
              <Card elevation={3} sx={{ flex: 1 }}>
                <CardContent>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    gutterBottom
                    display="flex"
                    alignItems="center"
                    gap={1}
                  >
                    {/* <LocalShippingIcon color="action" /> */}
                    Vehículos Acoplados
                  </Typography>
                  <Box
                    sx={{
                      maxHeight: 200, // Altura máxima para el contenedor desplazable
                      overflow: "auto", // Habilitar scroll si es necesario
                    }}
                  >
                    <List>
                      {conjuntoVehiculos && conjuntoVehiculos.length > 0 ? (
                        conjuntoVehiculos.map((elemento, index) => (
                          <>
                            <ListItemComponent
                              key={index}
                              icon={<LocalShippingIcon />}
                              secondary={elemento.patente_acoplado}
                              icon2={
                                <Tooltip
                                  arrow
                                  placement="left"
                                  title={"Desvincular"}
                                >
                                  <DeleteIcon color="error" onClick={()=>handleClickDesvincular(conjuntoVehiculos[index])}/>
                                </Tooltip>
                              }
                            />
                          </>
                        ))
                      ) : (
                        <Typography variant="body2" color="textSecondary">
                          No hay vehículos acoplados.
                        </Typography>
                      )}
                    </List>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </form>
          {vincular && (
          <VincularVehiculos
            open={vincular}
            setOpen={setVincular}
            onClose={handleCloseVincular}
            datos={datosVehiculo}
            // actualizar={obtenerPedidos(245)}
          />
          )}
          {desvincular && (
          <DesvincularVehiculos
            open={desvincular}
            setOpen={setDesvincular}
            onClose={handleCloseDesvincular}
            datos={datosLinea}
            // actualizar={obtenerPedidos(245)}
          />
          )}
        </Container>
      ) : null}
    </div>
  );
}

export default ConjuntoVehiculos;
