import React from "react";
import Navbar from "../Navbar";
import { Container } from "@mui/material";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
//iconos
import DoneIcon from "@mui/icons-material/Done";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import SettingsIcon from "@mui/icons-material/Settings";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";

import { styled } from "@mui/material/styles";
import { pink, green } from '@mui/material/colors';

//lista
import Romaneo_envases_lista from "./Romaneo_envases_lista";
//carga
import Romaneo_envases_carga from "./Romaneo_envases_carga";
//para habilitar
import Romaneo_envases_parahabilitar from "./Romaneo_envases_parahabilitar";
//habilitados
import Romaneo_envases_rehabilitados from "./Romaneo_envases_rehabilitados";
//rebalanceados
import Romaneo_envases_rebalanceados from "./Romaneo_envases_rebalanceados";
//destruidos
import Romaneo_envases_destruidos from "./Romaneo_envases_destruidos";

function Romaneo_envases_tab() {
  //MANEJA TAB
  const [opcion, setOpcion] = React.useState("para habilitar");

  const handleChange = (event, newValue) => {
    setOpcion(newValue);
  };

  //FIN TAB

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent"
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: "100%",
    width: "100%",
    backgroundColor: "#60B6EA"
  }
});

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),
      color: "rgba(255, 255, 255, 0.7)",
      "&.Mui-selected": {
        color: "#fff"
      },
      "&.Mui-focusVisible": {
        backgroundColor: "rgba(100, 95, 228, 0.32)"
      }
    })
  );

  return (
    <div>
      <Navbar />
      <Container maxWidth="xl">
        <Box sx={{ bgcolor: "#1A2C73", width: "100%", height: "72px" }}>
          <StyledTabs
            value={opcion}
            onChange={handleChange}
            indicatorColor={{color: "#FFFFFF"}}
            aria-label="Envases"
            variant="fullWidth"
          >
            <StyledTab
              icon={<FormatListBulletedIcon />}
              iconPosition="end"
              value="lista"
              label="Lista de Envases y Cilindros"
            />
            <StyledTab
              icon={<SettingsIcon />}
              iconPosition="end"
              value="carga"
              label="Administración de Envases y Cilindros"
            />
            <StyledTab
              icon={<BuildCircleIcon/>}
              iconPosition="end"
              value="rebalanceados"
              label="Cilindros Rebalanceados"
            />
            <StyledTab
              icon={<ChecklistRtlIcon />}
              iconPosition="end"
              value="parahabilitar"
              label="Para Habilitar"
            />
            <StyledTab
              icon={<DoneIcon sx={{ color: green[400] }}/>}
              iconPosition="end"
              value="rehabilitados"
              label="Rehabilitados"
            />
            <StyledTab
              icon={<DeleteForeverIcon sx={{ color: pink[600] }}/>}
              iconPosition="end"
              value="destruidos"
              label="Destruidos"
            />
          </StyledTabs>
  </Box>

        {opcion === "lista" ? (
          <Grid mt={-7}>
            <Romaneo_envases_lista />
          </Grid>
        ) : null}

        {opcion === "carga" ? (
          <Grid mt={-7}>
            <Romaneo_envases_carga />
          </Grid>
        ) : null}

        {opcion === "parahabilitar" ? (
          <Grid mt={-7}>
            <Romaneo_envases_parahabilitar />
          </Grid>
        ) : null}

        {opcion === "rehabilitados" ? (
          <Grid mt={-7}>
            <Romaneo_envases_rehabilitados />
          </Grid>
        ) : null}

        {opcion === "rebalanceados" ? (
          <Grid mt={-7}>
            <Romaneo_envases_rebalanceados />
          </Grid>
        ) : null}

        {opcion === "destruidos" ? (
          <Grid mt={-7}>
            <Romaneo_envases_destruidos />
          </Grid>
        ) : null}
      </Container>
    </div>
  );
}

export default Romaneo_envases_tab;