// Developer     : Magalí Perea
// Creation Date : 2024-04-22
// Version       : 
// Description   : Desarrolla el alta y la modificación de las viandas.


import React, { useEffect, useState } from "react";
import TitleUploaded from "../TitleUploaded";
import Navbar from "../Navbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { postData } from "../../../src/services/library";
import TextField from "@mui/material/TextField";
import moment from "moment";
import Button from "@mui/material/Button";
import { UrlApi } from "../../services/apirest";
import Notify from "../../../src/components/Notify";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

function AltaViandas() {
  const menuFijo = "Ensalada Completa";
  const inicio = moment().day(8).format("YYYY-MM-DD"); // fecha de inicio de la semana siguiente
  const fin = moment().day(12).format("YYYY-MM-DD"); // fecha finalizacion de la semana siguiente
  const [data, setData] = useState(null);
  const [habilitarMod, setHabilitarMod] = useState();
  const [disBtn, setDisBtn] = useState();
  const [notify, setNotify] = useState({
    open: false,
    severity: "",
    color: "",
    mensaje: "",
  });


  const getData = () => {
    fetch(UrlApi + `/viandas/semanal/${inicio}/${fin}`)
      .then((data) => data.json())
      .then((data) =>
        data.length > 0
          ? setData(data)
          : setData([
              {
                dia: "Lunes",
                fecha: moment().day(8).format("YYYY-MM-DD"),
                opcion1: "",
                guarnicion11: "",
                guarnicion12: "",
                opcion2: "",
                guarnicion21: "",
                guarnicion22: "",
                opcion3: menuFijo,
                guarnicion31: "",
                guarnicion32: "",
                feriado: 0,
              },
              {
                dia: "Martes",
                fecha: moment().day(9).format("YYYY-MM-DD"),
                opcion1: "",
                guarnicion11: "",
                guarnicion12: "",
                opcion2: "",
                guarnicion21: "",
                guarnicion22: "",
                opcion3: menuFijo,
                guarnicion31: "",
                guarnicion32: "",
                feriado: 0,
              },
              {
                dia: "Miércoles",
                fecha: moment().day(10).format("YYYY-MM-DD"),
                opcion1: "",
                guarnicion11: "",
                guarnicion12: "",
                opcion2: "",
                guarnicion21: "",
                guarnicion22: "",
                opcion3: menuFijo,
                guarnicion31: "",
                guarnicion32: "",
                feriado: 0,
              },
              {
                dia: "Jueves",
                fecha: moment().day(11).format("YYYY-MM-DD"),
                opcion1: "",
                guarnicion11: "",
                guarnicion12: "",
                opcion2: "",
                guarnicion21: "",
                guarnicion22: "",
                opcion3: menuFijo,
                guarnicion31: "",
                guarnicion32: "",
                feriado: 0,
              },
              {
                dia: "Viernes",
                fecha: moment().day(12).format("YYYY-MM-DD"),
                opcion1: "",
                guarnicion11: "",
                guarnicion12: "",
                opcion2: "",
                guarnicion21: "",
                guarnicion22: "",
                opcion3: menuFijo,
                guarnicion31: "",
                guarnicion32: "",
                feriado: 0,
              },
            ])
      );
  };
  const [horarioLimite, setHorarioLimite]=useState();
  const getHorarioLimiteViandas=()=>{
    fetch(UrlApi+"/desplegables/horarioviandas")
    .then((data)=>data.json())
    .then((data)=>setHorarioLimite(parseInt(data[0].valor)))
  }

  const enableModificacion = () => {
    fetch(UrlApi+"/viandas/fechahora")
    .then((data)=>data.json())
    .then((data)=>{
      setHabilitarMod(data.dia<5 || (data.dia===5&&data.hora < horarioLimite))
    })
  };

  useEffect(() => {
    getData();
    getHorarioLimiteViandas();
  }, []);

  useEffect(()=>{
    horarioLimite&&enableModificacion();
  },[horarioLimite])


  async function guardar(e) {
    setDisBtn(true);
    postData(e, "/viandas/opciones", data, setNotify);
  }

  const handleInputChange = (index, name, value) => {
    const dataCopy = data[index];
    var updated = {};
    if (name === "feriado") {
      if (value === 1) {
        //O sea, si es feriado le ponemos:
        updated = {
          ...dataCopy,
          opcion1: "No Disponible",
          guarnicion11: "No Disponible",
          guarnicion12: "No Disponible",
          opcion2: "No Disponible",
          guarnicion21: "No Disponible",
          guarnicion22: "No Disponible",
          opcion3: "No Disponible",
          guarnicion31: "No Disponible",
          guarnicion32: "No Disponible",
          feriado: value,
        };
      } else {
        updated = {
          ...dataCopy,
          opcion1: "",
          guarnicion11: "",
          guarnicion12: "",
          opcion2: "",
          guarnicion21: "",
          guarnicion22: "",
          opcion3: "",
          guarnicion31: "",
          guarnicion32: "",
          feriado: value,
        };
      }
    } else {
      updated = {
        ...dataCopy,
        [name]: value === "" ? null : value,
      };
    }
    const clone = [...data];
    clone[index] = updated;
    setData(clone);
  };

  const handleClose = () => {
    window.location.replace(`/#/navbar`);
  };

  return (
    <div>
      <Navbar />
      <Container maxWidth="xl">
        <TitleUploaded title="Semana Viandas" />
        {notify.open ? (
          <Notify
            open={notify.open}
            color={notify.color}
            severity={notify.severity}
            mensaje={notify.mensaje}
            handleClose={() => handleClose()}
          />
        ) : null}
        <form onSubmit={guardar}>
          {data &&
            data.map((field, index) => (
              <>
                <Grid
                  container
                  direction="row"
                  my={1}
                  justifyContent="space-around"
                  alignItems="center"
                  px={3}
                  py={1}
                  key={index}
                >
                  <Grid item xs={2}>
                    {
                      <Typography variant="body1" color="initial">
                        {moment(field.fecha).format("dddd DD-MM-YYYY")}{" "}
                      </Typography>
                    }
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={!habilitarMod}
                          checked={data[index].feriado === 1}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "feriado",
                              e.target.checked === true ? 1 : 0
                            )
                          }
                        />
                      }
                      label={"Feriado/No Laborable"}
                    />
                  </Grid>

                  <Grid item xs={10}>
                    <Grid container direction="row" spacing={1}>
                      <Grid item xs={6}>
                        <TextField
                          disabled={field.feriado === 1 || !habilitarMod}
                          required
                          fullWidth
                          size="small"
                          label="Opción 1"
                          value={
                            field.opcion1 &&
                            field.opcion1.charAt(0).toUpperCase() +
                              field.opcion1.slice(1)
                          }
                          name="opcion1"
                          onChange={(e) =>
                            handleInputChange(index, "opcion1", e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          disabled={field.feriado === 1 || !habilitarMod}
                          fullWidth
                          size="small"
                          label="Gurnicion 1"
                          value={
                            field.guarnicion11 &&
                            field.guarnicion11.charAt(0).toUpperCase() +
                              field.guarnicion11.slice(1)
                          }
                          name="guarnicion11"
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "guarnicion11",
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          disabled={field.feriado === 1 || !habilitarMod}
                          fullWidth
                          size="small"
                          label="Guarnición 2"
                          value={
                            field.guarnicion12 &&
                            field.guarnicion12.charAt(0).toUpperCase() +
                              field.guarnicion12.slice(1)
                          }
                          name="guarnicion12"
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "guarnicion12",
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          disabled={field.feriado === 1 || !habilitarMod}
                          required
                          fullWidth
                          size="small"
                          label="Opción 2"
                          value={
                            field.opcion2 &&
                            field.opcion2.charAt(0).toUpperCase() +
                              field.opcion2.slice(1)
                          }
                          name="opcion2"
                          onChange={(e) =>
                            handleInputChange(index, "opcion2", e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          disabled={field.feriado === 1 || !habilitarMod}
                          fullWidth
                          size="small"
                          label="Guarnición 1"
                          value={
                            field.guarnicion21 &&
                            field.guarnicion21.charAt(0).toUpperCase() +
                              field.guarnicion21.slice(1)
                          }
                          name="guarnicion21"
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "guarnicion21",
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          disabled={field.feriado === 1 || !habilitarMod}
                          fullWidth
                          size="small"
                          label="Guarnición 2"
                          value={
                            field.guarnicion22 &&
                            field.guarnicion22.charAt(0).toUpperCase() +
                              field.guarnicion22.slice(1)
                          }
                          name="guarnicion22"
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "guarnicion22",
                              e.target.value
                            )
                          }
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          disabled={field.feriado === 1 || !habilitarMod}
                          required
                          fullWidth
                          size="small"
                          label="Opción 3"
                          value={
                            field.opcion3 &&
                            field.opcion3.charAt(0).toUpperCase() +
                              field.opcion3.slice(1)
                          }
                          name="opcion3"
                          onChange={(e) =>
                            handleInputChange(index, "opcion3", e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={3} spacing={1}>
                        <TextField
                          disabled={field.feriado === 1 || !habilitarMod}
                          fullWidth
                          size="small"
                          label="Guarnición 1"
                          value={
                            field.guarnicion31 &&
                            field.guarnicion31.charAt(0).toUpperCase() +
                              field.guarnicion31.slice(1)
                          }
                          name="guarnicion31"
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "guarnicion31",
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={3} spacing={1}>
                        <TextField
                          disabled={field.feriado === 1 || !habilitarMod}
                          fullWidth
                          size="small"
                          label="Guarnición 2"
                          value={
                            field.guarnicion32 &&
                            field.guarnicion32.charAt(0).toUpperCase() +
                              field.guarnicion32.slice(1)
                          }
                          name="guarnicion32"
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "guarnicion32",
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider />
              </>
            ))}
          <Grid container justifyContent="flex-end" my={1}>
            <Button
              variant="contained"
              color="success"
              type="submit"
              disabled={!habilitarMod || disBtn}
            >
              guardar
            </Button>
          </Grid>
        </form>
        <br />
        <br />
      </Container>
    </div>
  );
}

export default AltaViandas;
