import React, { useEffect, useState, useMemo } from 'react';
import { MenuItem, ListItemIcon, Divider, Box, Grid, Container } from '@mui/material'
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import FormMarcar from './FormMarcar';
import PreviewIcon from '@mui/icons-material/Preview';
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { ThemeProvider, createTheme, useTheme } from '@mui/material';
import { getData } from '../../../../../services/library';
import DatosOrden from '../../../AutorizacionCompras/DatosOrden';
import Openpdf from '../../../OpenPdf';

export default function MarcarEntregados(props) {
    const [req, setReq] = useState();
    const [openModal, setOpenModal] = useState(false)
    const [tabla, setTabla] = useState(true)
    const [datos, setDatos] = useState({})
    const globalTheme = useTheme();
    const [open, setOpen] = useState(false);
    const [openpdf, setOpenpdf] = useState(false);

    const obtenerRequerimientos = () => {
        getData('/ordencompra/all/estado/81', setReq)
    };

    useEffect(() => {
        obtenerRequerimientos()
    }, [tabla])

    const handleClickNuevo = (datosReq) => {
        setOpenModal(true)
        setTabla(true)
        setDatos(datosReq)
    };

    const handleopenpdf = (datos) => {
        setOpenpdf(true)
        setDatos(datos)
        setOpen(false)
        setTabla(false)
        props.tab(false)
    };

    const columns = useMemo(() =>
        [{ header: '#', accessorKey: 'idordencompra', size: 90 },
        { header: 'Proveedor', accessorFn: (oc) => oc.nombre_fantasia ?? oc.razon_social },
        // { header: 'Condición', accessorKey: 'condicion' },
        { header: 'Fecha Autorizada', accessorKey: 'fecha_autorizado', type: "date", format: "DD-MM-YYYY" },
        { header: 'Estado', accessorKey: 'valor_estado' },
            // { header: 'Total', accessorKey: 'total', size: 120 }
        ], []);

    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: globalTheme.palette.mode,
                    primary: globalTheme.palette.primary, //primary color for the table
                    info: {
                        main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            globalTheme.palette.mode === "light"
                                ? "rgb(234, 239, 241)"
                                : "#000", //pure black table in dark mode for fun
                    },
                },
                typography: {
                    button: {
                        textTransform: "none", //customize typography styles for all buttons in table by default
                        fontSize: "1rem",
                    },
                },
                components: {
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                fontSize: "0.8rem", //larger tooltip font size                                
                            },
                        },
                    },
                },

            }), [globalTheme])

    const muiSearchTextFieldProps = {
        placeholder: "Buscar..",
        sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
        variant: "outlined",
        color: "success",
        size: "small",
    };

    const initialState = {
        density: "compact",
        showGlobalFilter: true,
        pagination: { pageIndex: 0, pageSize: 15 }
    };

    const tableHeadCellProps = {
        align: "center",
        sx: {
            fontSize: 16,
            color: "white",
            fontWeight: "normal",
            backgroundColor: "#2471A3",
            borderColor: "black",
        },
    };

    const handleOpenModal = (row) => {
        setDatos(row)
        setOpen(true)
    };

    return (
        <div>
            <Container maxWidth="xl" mt={1} mb={2}>
                {req && tabla ? (
                    <Box mt={2} boxShadow={3}>
                        <ThemeProvider theme={tableTheme}>
                            <MaterialReactTable
                                enableColumnResizing
                                columns={columns}
                                data={req}
                                enableRowActions
                                initialState={initialState}
                                positionActionsColumn="last"
                                muiTableHeadCellProps={tableHeadCellProps}
                                positionExpandColumn='first'
                                muiTableBodyCellProps={({ row }) => ({
                                    align: "center",
                                    sx: {
                                        backgroundColor: "#fff",
                                        // rowBg[row.original.id_estado] ?? "#fff",
                                        color: "black",
                                        borderColor: "black",
                                    },
                                })}
                                enableColumnActions={false}
                                enableDensityToggle={false}
                                muiSearchTextFieldProps={muiSearchTextFieldProps}
                                layoutMode="grid"
                                localization={MRT_Localization_ES}
                                enableColumnDragging={false}
                                renderRowActionMenuItems={({ closeMenu, row }) => [
                                    <MenuItem
                                        key={0}
                                        // disabled={(row.original.estado !== 49) && (row.original.estado) !== 87 && (row.original.estado !== 93)}
                                        onClick={() => {
                                            handleClickNuevo(row.original)
                                            closeMenu();
                                        }}
                                        sx={{ m: 0 }}>
                                        <ListItemIcon>
                                            <TaskAltIcon color="warning" />
                                        </ListItemIcon>

                                        Recibida
                                    </MenuItem>,
                                    <Divider />,
                                    <MenuItem
                                        key={1}
                                        // disabled={(row.original.estado !== 49) && (row.original.estado) !== 87 && (row.original.estado !== 93)}
                                        onClick={() => {
                                            handleopenpdf(row.original)
                                            closeMenu();
                                        }}
                                        sx={{ m: 0 }}>
                                        <ListItemIcon>
                                            <PreviewIcon color="secondary" />
                                        </ListItemIcon>
                                        Visualizar orden
                                    </MenuItem>,
                                ]}
                                enableColumnFilters={false}
                                enableHiding={false}
                                enableMultiRowSelection={false}
                                enableBottomToolbar
                                enablePagination
                                muiToolbarAlertBannerProps={false}
                                muiTableBodyRowProps={({ row }) => ({
                                    onClick: () => {
                                        row.getToggleSelectedHandler()
                                        handleOpenModal(row.original)
                                    },
                                    sx: {
                                        cursor: 'pointer'
                                    }
                                })} />
                        </ThemeProvider>
                    </Box>
                ) : null}
            </Container>
            <Grid key={datos.idordencompra}>
                <br />
                {open ? <DatosOrden datos={datos} setDatosreq={setDatos} anchor={setOpen} /> : null}
            </Grid>

            {openpdf ?
                <Openpdf datos={datos} open={setOpenpdf} tabla={setTabla} tab={props.tab} uuid={datos.uuid_ordencompra} />
                : null}
            {openModal ? <FormMarcar open={setOpenModal} tabla={setTabla} datos={datos} actualizar={obtenerRequerimientos}></FormMarcar> : null}
        </div>
    )
}
