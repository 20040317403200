//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio
// Creation Date : 10/09/2024
// Version : 1
// Description : pantalla de configuración de checklist para agregar grupos e items
// para el checklist.
//------Changes --------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useEffect, useState, useMemo } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Container,
  Typography,
  Tooltip,
  IconButton,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  DialogTitle,
} from "@mui/material";
import Navbar from "../Navbar";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import BlockIcon from "@mui/icons-material/Block";
import EditIcon from "@mui/icons-material/Edit";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Notify from "../Notify";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import ContentPasteOffIcon from "@mui/icons-material/ContentPasteOff";
import { logEventos } from "../../services/services";
import AddGrupoTanque from "./AddGrupoTanque";
import EditarGrupoTanque from "./EditarGrupoTanque";
import Tanque_items from "./Tanque_items";

function Tanque_grupos() {
  const [grupos, setGrupos] = useState(); //tabla
  const [datosGrupos, setDatosGrupos] = useState({});
  const [alta, setAlta] = useState(false); //Estado q mostrará o no la pantalla de alta
  const [altaITEM, setAltaITEM] = useState(false); //Estado q mostrará o no la pantalla de alta
  const [tabla, setTabla] = useState(true);
  const [editar, setEditar] = useState(false); //Estado q mostrará o no el form de edicion de choferes
  const [confirmar, setConfirmar] = useState(false);
  const globalTheme = useTheme();
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
  const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
  const [hiddenmuestrainactivo, setHiddenmuestrainactivo] = useState(false);
  const [hiddenmuestraactivos, setHiddenmuestraactivos] = useState(true);
  const [obt, setObt] = useState(0);

  //  //    //acceso al menu
   useEffect(() => {
      logEventos("Acceso al menú Grupos Cheklist", "Grupos Cheklist", "Agregar,editar,activar o desactivar un grupo o item del grupo", localStorage.getItem("user"))
  }, [])
  //*************************************************************************************************************************************/
  const obtenerGrupos = () => {
    setHiddenmuestraactivos(true);
    setHiddenmuestrainactivo(false);
    fetch(UrlApi + "/tanque_grupos/activos")
      .then((data) => data.json())
      .then((data) => setGrupos(data));
  };
  const obtenerinactivos = () => {
    setHiddenmuestrainactivo(true);
    setHiddenmuestraactivos(false);
    fetch(UrlApi + "/tanque_grupos/inactivos")
      .then((data) => data.json())
      .then((data) => setGrupos(data));
  };
  //ejecuta la funcion de obtener
  useEffect(() => {
    obtenerGrupos();
  }, [tabla, obt, confirmar]);
  //*************************************************************************************************************************************/
  const handleClickNuevo = () => {
    setAlta(true);
    setTabla(false);
  };
  const mostrareditar = (datosDeLaLinea) => {
    setEditar(true);
    setTabla(false);
    setDatosGrupos(datosDeLaLinea);
    // console.log("datos linea", datosDeLaLinea ? datosDeLaLinea : "NO");
  };
  const abrirModalConfirmar = (datosDeLaLinea) => {
    setConfirmar(true);
    setDatosGrupos(datosDeLaLinea);
  };
  const handleVolver = () => {
    setConfirmar(false);
    setTabla(true);
  };
  //*************************************************************************************************************************************/
  // columnas tabla
  const columns = useMemo(
    () => [
      // { header: "ID", accessorKey: "id_grupo", size: 85 },
      {
        header: "Nombre",
        accessorKey: "nombre_grupo",
        size: 200,
      },
      {
        header: "Tipo de grupo",
        accessorKey: "estado_palabra_tipo",
        size: 200,
      },
    ],
    []
  );
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)" //background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
            },
          },
        },
      }),
    [globalTheme]
  );
  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "success",
    size: "small",
  };
  const initialState = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
  };
  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };
  //*************************************************************************************************************************************/
  const marcarInactivo = async (e) => {
     logEventos("Marcar como inactivo un grupo", "Grupos Cheklist", "Se guardo el grupo inactivo", localStorage.getItem("user"))
    e.preventDefault();
    const response = await fetch(
      UrlApi + "/tanque_grupos/" + datosGrupos.id_grupo,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(
          datosGrupos.estado_grupo === 0
            ? { estado_grupo: 1 }
            : { estado_grupo: 0 }
        ),
      }
    );
    if (response.ok === false) {
      const error = response && response.json();
      setError(error);
      alert("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      try {
        const json = await response.json();
        if (json.message) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje(
            json.message === "OK" ? "Guardado" : "Guardado. Actualizar RTA Api."
          );
          obtenerGrupos();
        } else if (json.sqlMessage) {
          json && setError(json);
          alert("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
        } else {
          alert("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje("Guardado");
          obtenerGrupos();
        } else {
          alert("Error: " + error);
        }
      }
    }
    handleVolver();
    obtenerGrupos();
  };

  return (
    <div>
      <Navbar />
      <Container maxWidth="xl" mt={1} mb={2}>
        {grupos && tabla ? (
          <Box mt={2} boxShadow={3}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableColumnResizing
                columns={columns}
                data={grupos}
                initialState={initialState}
                positionActionsColumn="last"
                enableExpandAll={false}
                enableToolbarInternalActions={true}
                muiTableHeadCellProps={tableHeadCellProps}
                muiTableBodyCellProps={({ row }) => ({
                  align: "left",
                  sx: {
                    backgroundColor:
                      row.original.estado_grupo === 0 ? "#efc3c3" : "#b3e6b3",
                    color: "black",
                    borderColor: "black",
                  },
                })}
                enableColumnActions={false}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                enableDensityToggle={false}
                renderTopToolbarCustomActions={() => (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddBoxTwoToneIcon />}
                      onClick={handleClickNuevo}
                    >
                      Nuevo Grupo
                    </Button>

                    <Typography variant="poster" fontSize={26}>
                      <AssignmentIcon sx={{ size: 20 }} />
                      Grupos para checklist
                    </Typography>
                  </>
                )}
                layoutMode="grid"
                localization={MRT_Localization_ES}
                enableColumnDragging={false} //No permite que se arrastren las columnas.
                enableEditing
                // LLAMA A LA TABLA DE ITEMS
                renderDetailPanel={({ row }) => (
                  <div style={{ marginTop: "-50px" }}>
                    <Box
                      sx={{
                        width: "200%",
                      }}
                    >
                      <Tanque_items row={row} />
                    </Box>
                  </div>
                )}
                renderToolbarInternalActions={(
                  { table } // boton para nuevo , al lado del buscar
                ) => (
                  <>
                    {!hiddenmuestraactivos ? (
                      <Tooltip title="Ver grupos activos">
                        <IconButton
                          color="success"
                          onClick={() => {
                            obtenerGrupos();
                          }}
                        >
                          <ContentPasteIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    {!hiddenmuestrainactivo ? (
                      <Tooltip title="Ver grupos inactivos">
                        <IconButton
                          color="error"
                          onClick={() => {
                            obtenerinactivos();
                          }}
                        >
                          <ContentPasteOffIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    <MRT_FullScreenToggleButton table={table} />
                  </>
                )}
                renderRowActions={(
                  { row } //las acciones del costado editar y ver
                ) => (
                  <div>
                    <Tooltip arrow placement="left" title="Editar grupo">
                      <IconButton
                        onClick={() => mostrareditar(row.original)}
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    {row.original.estado_grupo === 0 ? (
                      <Tooltip title="Marcar como activo">
                        <IconButton
                          color="success"
                          onClick={() => abrirModalConfirmar(row.original)}
                        >
                          <CheckCircleOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Marcar como inactivo">
                        <IconButton
                          onClick={() => abrirModalConfirmar(row.original)}
                          color="error"
                        >
                          <BlockIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                )}
              />
            </ThemeProvider>
            <br />
            <br />
          </Box>
        ) : null}

        {confirmar ? (
          <div>
            <Dialog open={confirmar} onClose={handleVolver}>
              <form onSubmit={marcarInactivo}>
                <Box backgroundColor={"#1F618D"}>
                  <DialogTitle>
                    {datosGrupos.estado_grupo === 0 ? (
                      <Typography variant="h6" align="center" color="#ffffff">
                        Activar {datosGrupos.nombre_grupo}
                      </Typography>
                    ) : (
                      <Typography variant="h6" align="center" color="#ffffff">
                        Desactivar {datosGrupos.nombre_grupo}
                      </Typography>
                    )}
                  </DialogTitle>
                </Box>
                <DialogContent>
                  <DialogContentText textAlign={"center"}>
                    {datosGrupos.estado_grupo === 0 ? (
                      <> ¿Desea activar el grupo ? </>
                    ) : (
                      <> ¿Desea desactivar el grupo ? </>
                    )}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItemns="center"
                  >
                    <Button
                      variant="contained"
                      onClick={handleVolver}
                      style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Confirmar
                    </Button>
                  </Grid>
                </DialogActions>
              </form>
            </Dialog>
          </div>
        ) : null}
        {notificacion ? (
          <Notify
            mensaje={mensaje}
            open={openSnack}
            color={"#D4EFDF"}
            severity={"success"}
            handleClose={handleVolver}
          />
        ) : null}

        {alta ? (
          <AddGrupoTanque alta={setAlta} tabla={setTabla}></AddGrupoTanque>
        ) : null}
        {editar ? (
          <EditarGrupoTanque
            editar={setEditar}
            tabla={setTabla}
            datos={datosGrupos}
            actualizar={setObt}
          ></EditarGrupoTanque>
        ) : null}
      </Container>
    </div>
  );
}

export default Tanque_grupos;
