import React, { useState, useEffect } from 'react'
import {
    Card, Grid, Avatar, CardHeader, IconButton, CardContent, Typography, Container,
    TextField, Collapse, Divider, FormControlLabel, Switch, CardActions, CircularProgress
} from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { makeStyles, styled } from '@mui/styles';
//import DateAdapter from '@mui/lab/AdapterMoment';
//import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
//import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import FastForwardIcon from '@mui/icons-material/FastForward';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import UnfoldLessOutlinedIcon from '@mui/icons-material/UnfoldLessOutlined';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import AddTaskIcon from '@mui/icons-material/AddTask';
import Navbar from '../../Navbar'
import moment from 'moment'
import { UrlApi } from '../../../services/apirest';
import ControlCargaDatosStockProducto from './ControlCargaDatosStockProducto';
import Opentanques from './Opentanques';
import { logEventos } from '../../../services/services';
import { Check } from '@mui/icons-material';

const useStyles = makeStyles({
    tarjetaButano: {
        minWidth: 275,
        border: `1px solid red`,
        background: 'linear-gradient(156deg, rgba(252,251,254,1) 61%, rgba(65,143,190,1) 150%, rgba(255,255,255,1) 100%)',
    },
    tarjetaPropano: {
        minWidth: 275,
        border: `1px solid navy`,
        background: 'linear-gradient(156deg, rgba(242,242,242,1) 56%, rgba(70,125,55,1) 150%, rgba(255,255,255,1) 100%)',
        //boxShadow: "0px 3px 1px -2px red,0px 2px 2px 0px rgba(100,0,0,0.9),0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
    tarjetaSucursales: {
        border: `1px solid lightblue`,
    },
    divider: {
        // Theme Color, or use css color in quote
        background: 'grey',
        color: 'white',
        border: '1px solid black',
        borderRadius: 15,
    },
});

function StockTotal() {
    const classes = useStyles();
    const [fecha, setFecha] = useState(moment(new Date()).format("YYYY-MM-DD"))
    const [expandedId, setExpandedId] = React.useState({});
    const [datostotal, setDatosTotal] = useState([])
    const [datostotalsuc, setDatosTotalSuc] = useState([])
    const [control, setControl] = useState([])
    const [opencontrol, setOpencontrol] = useState(false)
    const [opentanques, setOpentanques] = useState(false)
    const [selectTanque, setSelectTanque] = useState("")
    const [selectProducto, setSelectProducto] = useState("")

    ///controla la posicion del mouse
    const [x, setX] = useState(100)
    const [y, setY] = useState(200)

    const update = (e) => {
        setX(e.x)
        setY(e.y)
    }

    window.addEventListener('mousedown', update)
    ///fin control posicion mouse

    /*   const buscatotales = async () => {
          const data1 = await fetch(UrlApi + "/stktotalproducto/" + localStorage.getItem("suc_auth") + "/" + fecha)
          const datastotal = await data1.json()
          setDatosTotal(datastotal)
      } */

    const [cargando, setCargando] = useState(true);
    const [cargandosucursales, setCargandoSucursales] = useState(true);

    useEffect(() => {
        setCargando(true)
        const buscatotales = async () => {
            try {
                const data1 = await fetch(UrlApi + "/stktotalproducto/" + localStorage.getItem("suc_auth") + "/" + fecha);
                const datastotal = await data1.json();
                setDatosTotal(datastotal);
            } catch (error) {
                console.error('Error al obtener los datos:', error);
            } finally {
                // Una vez que los datos se han obtenido, ya no estamos cargando
                setCargando(false);
            }
        };
        buscatotales();
    }, [fecha]);

    useEffect(() => {
        setCargandoSucursales(true)
        const buscatotalessuc = async () => {
            try {
                const data2 = await fetch(UrlApi + "/stktotalproducto/" + fecha + "/x")
                const datastotal2 = await data2.json()
                setDatosTotalSuc(datastotal2)
            } catch (error) {
                console.error('Error al obtener los datos:', error);
            } finally {
                // Una vez que los datos se han obtenido, ya no estamos cargando
                setCargandoSucursales(false);
            }
        };
        buscatotalessuc();
    }, [fecha]);


    const stkcontrol = async () => {
        const data3 = await fetch(UrlApi + "/stkcontrol/" + fecha)
        const datastotal3 = await data3.json()
        setControl(datastotal3)
    }

    const handleChangeMas = () => {
        setFecha(moment(fecha).add('days', 1).format("YYYY-MM-DD"));
    };

    const handleChangeMenos = () => {
        setFecha(moment(fecha).subtract('days', 1).format("YYYY-MM-DD"));
    };

    const handleChangeHoy = () => {
        setFecha(moment(Date()).format("YYYY-MM-DD"));
    };

    useEffect(() => {
        //buscatotales()
        //buscatotalessuc()
        stkcontrol()
    }, [fecha])

    useEffect(() => {
        setExpandedId({})
    }, [fecha])

    useEffect(() => {
        logEventos("Consulta stock total", "Stock Producto", "StockTotal", localStorage.getItem("user"))
    }, [])

    const handleChange = (newValue) => {
        setFecha(moment(newValue).format("YYYY-MM-DD"));
    };

    const openAll = () => {
        for (let index = 0; index < datostotalsuc.length; index++) {
            expandedId[index] = true;
            setExpandedId({ ...expandedId });

        }
    }

    const showControl = () => {
        setOpencontrol(!opencontrol)
    }

    const showTanques = (i) => {
        setOpentanques(!opentanques)
        setSelectTanque(datostotalsuc[i].id)
        setSelectProducto(datostotalsuc[i].producto)
    }
    /* const handleExpandClick = i => {
        setExpandedId( i);
      }; */

    const handleExpandClick = (i) => {
        setExpandedId(expanded => ({
            ...expanded,
            [i]: !expanded[i],
        }));
    };

    const getAvatarColor = (porcentaje) => {
        return (porcentaje < 20 ? "red" : "green")
    }

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: "12px",
            border: '1px solid #dadde9',
        },
    }));

    return (
        <div>
            <Navbar />
            {opentanques ? <Opentanques open={setOpentanques} datFecha={fecha} datSuc={selectTanque} datProd={selectProducto} x={x} y={y} /> : null}
            {opencontrol ? <ControlCargaDatosStockProducto kontrol={control} open={setOpencontrol} /> : null}
            <br />
            <Container>
                <Typography variant="h5" component="div"
                    style={{
                        paddingLeft: '15px', border: "1px solid ",
                        borderColor: 'grey', borderRadius: 5,
                    }}
                    bgcolor='black' color='white'>
                    Stock Fisico Producto
                </Typography>
                <br />
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                >
                    <Grid item xs={12} sm={6} md={4}>


                        <Card sx={{ padding: '5px' }}>
                            <TextField
                                color="primary"
                                label="Fecha Recuento"
                                name="fecha"
                                type="date"
                                variant="outlined"
                                rows={2}
                                value={fecha}
                                onChange={(e) => handleChange(e.target.value)}
                            />
                            <CardActions>
                                <IconButton size='small' onClick={handleChangeMenos}>
                                    <FastRewindIcon />
                                </IconButton>
                                <IconButton onClick={handleChangeMas}>
                                    <FastForwardIcon />
                                </IconButton>
                                <IconButton size='small' onClick={handleChangeHoy}>
                                    Hoy
                                </IconButton>
                                <Divider />
                                <IconButton onClick={() => setExpandedId({})}>
                                    <UnfoldLessOutlinedIcon color='secondary' />
                                </IconButton>
                                <IconButton onClick={openAll}>
                                    <UnfoldMoreIcon color='success' />
                                </IconButton>
                                {localStorage.getItem("stkcontrol") === "1" &&
                                    <IconButton onClick={showControl}>
                                        <AddTaskIcon color='primary' />
                                    </IconButton>}
                                {/* <IconButton>
                                    <PrintIcon color='primary' />
                                </IconButton> */}
                            </CardActions>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {cargando ?
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ marginRight: 'auto' }}>
                                            <CircularProgress color="primary" size={10} />
                                        </div>
                                        <div style={{ marginRight: '10px' }}></div>
                                        <Typography variant='caption'>Totales</Typography>
                                    </div>
                                    : <><Check color='primary' /><Typography variant='caption'>Totales</Typography></>}
                                {cargandosucursales ?
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ marginRight: 'auto' }}>
                                            <CircularProgress color="success" size={10} />
                                        </div>
                                        <div style={{ marginRight: '10px' }}></div>
                                        <Typography variant='caption'>Totales x Sucursal</Typography>
                                    </div>
                                    : <><Check color='success' /><Typography variant='caption'>Totales x Sucursal</Typography></>}
                            </div>
                        </Card>
                    </Grid>

                    {Object.keys(datostotal).length > 1 ? datostotal.map((datax, i) => (

                        <Grid item xs={12} sm={6} md={4} key={i} >
                            <Card className={datax.producto === "Butano" ? classes.tarjetaButano : classes.tarjetaPropano}>
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{ bgcolor: datax.color }} aria-label="producto">
                                            {datax.producto[0]}
                                        </Avatar>
                                    }
                                    action={
                                        <IconButton aria-label="settings">
                                            <MoreVertIcon />
                                        </IconButton>
                                    }
                                    titleTypographyProps={{ variant: 'h7' }}
                                    subheaderTypographyProps={{ variant: 'h4' }}
                                    title={datax.producto}
                                    subheader={(datax.Total / 1000).toFixed(2) + "Tn"}
                                />

                                <CardContent>
                                    <Grid container>
                                        <Grid item xs={6}>Envasado</Grid>
                                        <Grid item xs={6}>Granel</Grid>
                                    </Grid>
                                    <Typography variant="h5">
                                        <Grid container>
                                            <Grid item xs={6} style={{ textAlign: "right", border: "1px solid ", borderColor: 'red', borderRadius: 10, paddingLeft: 5, paddingRight: 5 }}>{(datax.Envasado / 1000).toFixed(2)}</Grid>
                                            <Grid item xs={6} style={{ textAlign: "right", border: "1px solid ", borderColor: 'green', borderRadius: 10, paddingLeft: 5, paddingRight: 5 }}>{(datax.Granel / 1000).toFixed(2)}</Grid>
                                        </Grid>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    )) : <Grid item xs={6}><br /><Typography variant="h6" align='center'>No hay datos para la fecha solicitada</Typography></Grid>}
                </Grid>
                <Divider textAlign="left"> </Divider>

                <Grid container spacing={1}>

                    {Object.keys(datostotalsuc).length > 1 ? datostotalsuc.map((dataxsuc, i) => (
                        localStorage.getItem("suc_auth").split(",").includes(dataxsuc.id.toString()) ? ////si la sucursal esta en las autorizadas a ver por el usuario
                            <Grid item xs={12} sm={6} md={3} key={i} >
                                <Card className={classes.tarjetaSucursales}>

                                    <Grid item xs={6}>
                                        <CardHeader
                                            avatar={
                                                <div>
                                                    <Avatar sx={{ bgcolor: dataxsuc.color_id }} aria-label="producto" >
                                                        {dataxsuc.avatar}
                                                    </Avatar>
                                                    {dataxsuc.CapacidadTotalTks > 0 &&
                                                        <Avatar title="Click para ver tanques"
                                                            onClick={() => showTanques(i)}
                                                            sx={{ bgcolor: getAvatarColor(((dataxsuc.GranelTanques * 100) / dataxsuc.CapacidadTotalTks).toFixed(0)), cursor: 'pointer' }}
                                                            variant="rounded"
                                                        >
                                                            {isNaN(((dataxsuc.GranelTanques * 100) / dataxsuc.CapacidadTotalTks).toFixed(0)
                                                                > 100 ? "?" : ((dataxsuc.GranelTanques * 100) / dataxsuc.CapacidadTotalTks).toFixed(0)) ? "." : ((dataxsuc.GranelTanques * 100) / dataxsuc.CapacidadTotalTks).toFixed(0)}%
                                                        </Avatar>}
                                                </div>
                                            }
                                            action={
                                                <span style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "Right",
                                                    alignItems: "Right",
                                                    marginLeft: "1px"
                                                }}
                                                >
                                                    <IconButton title="Ver detalles" onClick={() => handleExpandClick(i)}>
                                                        <LockOpenIcon />
                                                    </IconButton>
                                                    {dataxsuc.observacion ?
                                                        <HtmlTooltip
                                                            title={
                                                                <div>
                                                                    <Typography color="teal">Observaciones</Typography>
                                                                    <hr />
                                                                    <em><Typography>{dataxsuc.observacion}</Typography></em>
                                                                </div>
                                                            }
                                                        >
                                                            <IconButton><NotificationAddIcon style={{ fill: "green" }} />
                                                            </IconButton>
                                                        </HtmlTooltip>
                                                        : null}
                                                </span>
                                            }
                                            titleTypographyProps={{ variant: 'h7' }}
                                            subheaderTypographyProps={{ variant: 'h4' }}
                                            title={<>{dataxsuc.producto}<br /><b>{dataxsuc.nombre}</b></>}
                                            subheader={(dataxsuc.Total / 1000).toFixed(2) + "Tn"}
                                        />
                                    </Grid>
                                    <Typography variant='h8'>
                                        @{moment(dataxsuc.fecha).format("hh:mm:ss a")}
                                    </Typography>
                                    <Grid container>
                                        <Grid item xs={6}> Envasado</Grid>
                                        <Grid item xs={6}> Granel</Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={6} style={{ textAlign: "right", border: "1px solid ", borderColor: 'red', borderRadius: 10, paddingLeft: 5, paddingRight: 5 }}>
                                            {(dataxsuc.Envasado / 1000).toFixed(2)}
                                        </Grid>
                                        <Grid item xs={6} style={{ textAlign: "right", border: "1px solid ", borderColor: 'green', borderRadius: 10, paddingLeft: 5, paddingRight: 5 }}>
                                            {(dataxsuc.Granel / 1000).toFixed(2)}
                                        </Grid>
                                    </Grid>
                                    <Collapse in={expandedId[i]} timeout="auto" unmountOnExit>
                                        <CardContent>
                                            {dataxsuc.producto === "Butano" ?
                                                <div>
                                                    <Grid container>
                                                        <Grid item xs={6}>llenos x10</Grid>
                                                        <Grid item xs={6} style={{ textAlign: "right", border: "1px solid ", borderRadius: 15, paddingLeft: 5, paddingRight: 5 }}>
                                                            {(dataxsuc.envase10)}
                                                        </Grid>
                                                        <Grid item xs={6}>llenos x15</Grid>
                                                        <Grid item xs={6} style={{ textAlign: "right", border: "1px solid ", borderRadius: 15, paddingLeft: 5, paddingRight: 5 }}>
                                                            {(dataxsuc.envase15)}
                                                        </Grid>
                                                        <Divider textAlign="left" className={classes.divider}>Granel en tanques       </Divider>
                                                        <Grid item xs={6}>Total Tn</Grid>
                                                        <Grid item xs={6} style={{ textAlign: "right", border: "1px solid ", borderRadius: 15, paddingLeft: 5, paddingRight: 5 }}>
                                                            {((dataxsuc.GranelTanques / 1000).toFixed(2))}
                                                        </Grid>
                                                        <Divider textAlign="left" className={classes.divider}>Granel sobre vehiculos </Divider>
                                                        <Grid item xs={6}>Total Tn</Grid>
                                                        <Grid item xs={6} style={{ textAlign: "right", border: "1px solid ", borderRadius: 15, paddingLeft: 5, paddingRight: 5 }}>
                                                            {((dataxsuc.GranelVehiculos / 1000).toFixed(2))}
                                                        </Grid>
                                                        <Grid item xs={6}>En Sucursal Tn</Grid>
                                                        <Grid item xs={6} style={{ textAlign: "right", border: "1px solid ", borderRadius: 15, paddingLeft: 5, paddingRight: 5 }}>
                                                            {((dataxsuc.GranelVehiculosPlanta / 1000).toFixed(2))}
                                                        </Grid>
                                                        <Grid item xs={6}>En Reparto Tn</Grid>
                                                        <Grid item xs={6} style={{ textAlign: "right", border: "1px solid ", borderRadius: 15, paddingLeft: 5, paddingRight: 5 }}>
                                                            {((dataxsuc.GranelVehiculosReparto / 1000).toFixed(2))}
                                                        </Grid>
                                                    </Grid>
                                                </div> :
                                                <div>
                                                    <Grid container>
                                                        <Grid item xs={6}>Llenas x30</Grid>
                                                        <Grid item xs={6} style={{ textAlign: "right", border: "1px solid ", borderRadius: 15, paddingLeft: 5, paddingRight: 5 }}>
                                                            {(dataxsuc.envase30)}
                                                        </Grid>
                                                        <Grid item xs={6}>Llenas x45</Grid>
                                                        <Grid item xs={6} style={{ textAlign: "right", border: "1px solid ", borderRadius: 15, paddingLeft: 5, paddingRight: 5 }}>
                                                            {(dataxsuc.envase45)}
                                                        </Grid>
                                                        <Grid item xs={6}>Llenas AE15</Grid>
                                                        <Grid item xs={6} style={{ textAlign: "right", border: "1px solid ", borderRadius: 15, paddingLeft: 5, paddingRight: 5 }}>
                                                            {(dataxsuc.envaseAE)}
                                                        </Grid>
                                                        <Divider textAlign="left" className={classes.divider}>Granel en tanques</Divider>
                                                        <Grid item xs={6}>Total Tn</Grid>
                                                        <Grid item xs={6} style={{ textAlign: "right", border: "1px solid ", borderRadius: 15, paddingLeft: 5, paddingRight: 5 }}>
                                                            {((dataxsuc.GranelTanques / 1000).toFixed(2))}
                                                        </Grid>
                                                        <Divider textAlign="left" className={classes.divider}>Granel sobre vehiculos</Divider>
                                                        <Grid item xs={6}>Total Tn</Grid>
                                                        <Grid item xs={6} style={{ textAlign: "right", border: "1px solid ", borderRadius: 15, paddingLeft: 5, paddingRight: 5 }}>
                                                            {((dataxsuc.GranelVehiculos / 1000).toFixed(2))}
                                                        </Grid>
                                                        <Grid item xs={6}>En Sucursal Tn</Grid>
                                                        <Grid item xs={6} style={{ textAlign: "right", border: "1px solid ", borderRadius: 15, paddingLeft: 5, paddingRight: 5 }}>
                                                            {((dataxsuc.GranelVehiculosPlanta / 1000).toFixed(2))}
                                                        </Grid>
                                                        <Grid item xs={6}>En Reparto Tn</Grid>
                                                        <Grid item xs={6} style={{ textAlign: "right", border: "1px solid ", borderRadius: 15, paddingLeft: 5, paddingRight: 5 }}>
                                                            {((dataxsuc.GranelVehiculosReparto / 1000).toFixed(2))}
                                                        </Grid>
                                                    </Grid>
                                                </div>}
                                        </CardContent>
                                    </Collapse>
                                </Card>
                            </Grid>
                            : null   //// termina sucursales autorizadas
                    )) : null}
                </Grid>
                <br /> <br />
                <br /> <br />
                <br /> <br />
            </Container>
        </div>
    )
}


export default StockTotal
