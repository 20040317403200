//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio/Nicolas Pascucci
// Creation Date : 25/04/2024
// Version : 1
// Description : pantalla para ver los resultados del checklist
//------Changes --------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect } from "react";
import { UrlApi } from "../../../services/apirest";
import Navbar from "../../Navbar";
import {
  Box,
  Typography,
  Grid,
  Autocomplete,
  TextField,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Stack,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { logEventos } from "../../../services/services";
import Divider from "@mui/material/Divider";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
//ADJUNTAR
import AttachFileIcon from "@mui/icons-material/AttachFile"; //ADJUNTAR IMAGEN
import FileUploadCheck from "./FileUploadCheck";
import { v4 as uuidv4 } from "uuid"; //ADJUNTAR IMAGEN
import ModalCheckAud from "./ModalCheckAud";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Resultados_checklist() {
  const [resultadosCheck, setResultadosCheck] = useState();
  const [selectedDate, setSelectedDate] = useState("");
  const [depositoSeleccionado, setDepositoSeleccionado] = useState("");
  const [comercialSeleccionado, setComercialSeleccionado] = useState("");
  const [uuid, setUuid] = useState(uuidv4()); //ADJUNTAR IMAGEN
  const [openCloseAdj, setOpenCloseAdj] = useState(false); //ADJUNTAR IMAGEN
  const [checkboxStates, setCheckboxStates] = useState({});
  const [observaciones, setObservaciones] = useState({});
  const [fechaResultadoCheck, setFechaResultadoCheck] = useState({});
  const [diasHabiles, setDiasHabiles] = useState({});

  const [openModal, setOpenModal] = useState(false);
  const [itemId, setItemId] = useState("");

  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");

  // const [row, setRow] = useState();
  // -------- fecha -----------//
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };
  const formattedDate = `${selectedDate}`;

  // console.log("Mes seleccionado:", formattedDate);
  // ----------------------------- GET ---------------------- //
  const [gruposEItems, setGruposEItems] = useState([]);
  const [terminado, setTerminado] = useState(false);

  const obtenerGruposEItems = () => {
    fetch(UrlApi + "/checklist_rrhh_grupos/activos")
      .then((response) => response.json())
      .then((grupos) => {
        const promises = grupos.map((grupo) => {
          // Retorna una promesa para cada llamada a la API
          return fetch(
            UrlApi +
              `/checklist_rrhh_grupos/itemxgrupoActivos/${grupo.id_grupo}`
          )
            .then((response) => response.json())
            .then((items) => ({ grupo, items }));
        });
        // Espera a que todas las promesas se resuelvan
        return Promise.all(promises);
      })
      .then((gruposItemsArray) => {
        // Una vez que todas las llamadas a la API se completen con éxito,
        // actualiza el estado con el array completo de grupos e items
        setGruposEItems(gruposItemsArray);
        setTerminado(true);
      })
      .catch((error) =>
        console.error("Error al obtener grupos e items:", error)
      );
  };

  useEffect(() => {
    obtenerGruposEItems();
  }, []);

  // console.log("GRUPOS E ITEMS", gruposEItems);

  // console.log("OBSERVACIONES: ", observaciones);

  const obtenerResultadosCheck = () => {
    fetch(
      UrlApi +
        "/checklist/resultadoCheck/" +
        depositoSeleccionado.id +
        "/" +
        comercialSeleccionado.id +
        "/" +
        formattedDate
    )
      .then((data) => data.json())
      .then((data) => {
        // --------------------- CHECKBOX------------------------------ //
        // Crear un nuevo objeto para almacenar los estados de los checkboxes actualizados
        const newCheckboxStates = { ...checkboxStates }; // Copiar el estado actual de los checkboxes
        // Iterar sobre los resultados obtenidos
        data.forEach((resultado) => {
          // Comprobar si el grupo ya existe en el estado de los checkboxes
          if (!newCheckboxStates[resultado.id_grupo]) {
            newCheckboxStates[resultado.id_grupo] = {};
          }
          // Actualizar el estado de los checkboxes para este grupo y item
          if (resultado.cumple === 1) {
            newCheckboxStates[resultado.id_grupo][resultado.id_item] = "cumple";
          } else if (resultado.no_cumple === 1) {
            newCheckboxStates[resultado.id_grupo][resultado.id_item] =
              "no_cumple";
          } else {
            newCheckboxStates[resultado.id_grupo][resultado.id_item] = "n_a";
          }
        });
        // Actualizar el estado checkboxStates con los nuevos valores obtenidos
        setCheckboxStates(newCheckboxStates);
        // También puedes hacer otras cosas con los resultados si es necesario
        setResultadosCheck(data);
        // -------------------- OBSERVACIONES ---------------------- //
        // Crear un nuevo objeto para almacenar las observaciones de cada ítem
        const newObservaciones = { ...observaciones };

        // console.log("NEW OBSERVACIONES: ", newObservaciones);
        // Iterar sobre los resultados obtenidos
        data.forEach((resultado) => {
          // Actualizar las observaciones para este ítem
          newObservaciones[resultado.id_item] = resultado.observacion || ""; // Asumiendo que la propiedad en los resultados es 'observacion'
        });
        // Actualizar el estado 'observaciones' con los nuevos valores obtenidos
        setObservaciones(newObservaciones);
        // También puedes hacer otras cosas con los resultados si es necesario
        setResultadosCheck(data);
        // -------------------- FECHA ---------------------- //
        // Extraer la fecha de los resultados si está presente en la respuesta de la API
        const fecha = data.length > 0 ? data[0].fecha : null;
        // Guardar la fecha en el estado
        setFechaResultadoCheck(fecha);
        // -------------------- UUID  ---------------------- //
        // Extraer el UUID si está presente en la respuesta de la API
        const uuid = data.length > 0 ? data[0].uuid : null;
        // Guardar el UUID en el estado
        setUuid(uuid);
        // -------------------- DIAS HABILES  ---------------------- //
        const diasHabiles = data.length > 0 ? data[0].dias_habiles : null;
        setDiasHabiles(diasHabiles);
      });
  };

  useEffect(() => {
    obtenerResultadosCheck();
  }, [depositoSeleccionado, comercialSeleccionado, formattedDate]);

  // console.log("OBTENER RESULTADOS CHECK", resultadosCheck);

  const [datosFlota, setDatosFlota] = useState();

  const obtenerResultadosFlota = () => {
    fetch(
      UrlApi +
        "/checklist_chofer_flota/resultadoFlota/" +
        depositoSeleccionado.id +
        "/" +
        comercialSeleccionado.id +
        "/" +
        formattedDate
    )
      .then((data) => data.json())
      .then((data) => setDatosFlota(data));
  };
  useEffect(() => {
    obtenerResultadosFlota();
  }, [depositoSeleccionado, comercialSeleccionado, formattedDate]);

  //console.log("RESULTADOS FLOTA", datosFlota);

  const [deposito, setDeposito] = useState();

  const obtenerDepositos = () => {
    fetch(UrlApi + "/sucursales")
      .then((data) => data.json())
      .then((data) => setDeposito(data));
  };
  useEffect(() => {
    obtenerDepositos();
  }, []);

  // console.log("DEPOSITOS", depositoSeleccionado);

  const [comercial, setComercial] = useState();

  const obtenerComercial = () => {
    fetch(UrlApi + "/representantes/activos/")
      .then((data) => data.json())
      .then((data) => setComercial(data));
  };
  useEffect(() => {
    obtenerComercial();
  }, []);
  //console.log("COMERCIAL", comercialSeleccionado);

  const [responsable, setResponsable] = useState();
  const [responsableSeleccionado, setResponsableSeleccionado] = useState("");

  const obtenerResponsable = () => {
    fetch(UrlApi + "/checklist/userXsuc/" + depositoSeleccionado.id)
      .then((data) => data.json())
      .then((data) => setResponsable(data));
  };
  useEffect(() => {
    obtenerResponsable();
  }, [depositoSeleccionado]);

  // console.log("RESPONSABLE PLANTA", responsable);
  //console.log("RESPONSABLE SELECCIONADO", responsableSeleccionado);

  // ------------------ESTILO -------------------- //
  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  const size = "small";
  //------------------- ADJUNTO ----------------------- //
  const handleOpenCloseAdj = (value) => {
    setOpenCloseAdj(value);
  };

  //-------- Función para contar la cantidad de checks marcados ------------- //
  const contarChecksMarcados = () => {
    let cantidadCumple = 0;
    let cantidadNoCumple = 0;
    let cantidadNA = 0;

    // Iterar sobre los grupos
    for (const grupoId in checkboxStates) {
      // Iterar sobre los items dentro del grupo
      for (const itemId in checkboxStates[grupoId]) {
        const estado = checkboxStates[grupoId][itemId];
        // Contar los estados de los checkboxes
        if (estado === "cumple") {
          cantidadCumple++;
        } else if (estado === "no_cumple") {
          cantidadNoCumple++;
        } else if (estado === "n_a") {
          cantidadNA++;
        }
      }
    }

    return {
      cumple: cantidadCumple,
      no_cumple: cantidadNoCumple,
      n_a: cantidadNA,
    };
  };
  // Llamada a la función para obtener la cantidad de checks marcados
  const cantidadChecks = contarChecksMarcados(checkboxStates);
  // console.log("Cantidad de checks 'Cumple':", cantidadChecks.cumple);
  // console.log("Cantidad de checks 'No cumple':", cantidadChecks.no_cumple);

  // -------- checkbox auditoria ------------ //
  // Agrega un nuevo estado para manejar el estado del checkbox de auditoría
  const [auditoriaCheckboxStates, setAuditoriaCheckboxStates] = useState({});

  //--------------- modal para auditoria ---------------------//
  const [itemIndex, setItemIndex] = useState();
  const [resultadoEncontrado, setResultadoEncontrado] = useState(null);
  // Al abrir el modal, establece el ítem actual seleccionado
  const handleOpenModalCheck = (itemId, itemIndex, grupoId) => {
    setItemId(itemId);
    setItemIndex(itemIndex);
    // console.log("item ID MODAL: ", itemId);
    setOpenModal(true);

    const resultado = resultadosCheck.find(
      (resultado) =>
        resultado.id_item === itemId && resultado.id_grupo === grupoId
    );

    // Verificar si se encontró un resultado
    if (resultado) {
      // Si se encontró, puedes hacer lo que necesites con ese resultado
      // console.log("Resultado encontrado:", resultado.id_check);
      setResultadoEncontrado(resultado);
    } else {
      // Si no se encontró, puedes manejarlo de acuerdo a tus requerimientos
      // console.log(
      //   "No se encontró ningún resultado para itemId:",
      //   itemId,
      //   "y grupoId:",
      //   grupoId
      // );
    }
  };
  // console.log("Index item", itemIndex);

  // console.log("OBSERVACIONES: ", observaciones[itemId]);

  //---------------------------- CHECK DE REVISION ----------------------------//
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = async (event) => {
    const checked = event.target.checked;
    setIsChecked(checked);

    if (checked) {
      await guardarAuditado();
    }
  };
  const [estado, setEstado] = useState();

  const obtenerEstado = () => {
    fetch(
      UrlApi +
        "/estados_evaluacion_comercial/mescomercialsuc/" +
        comercialSeleccionado.id +
        "/" +
        formattedDate +
        "/" +
        depositoSeleccionado.id
    )
      .then((data) => data.json())
      .then((data) => {
        setEstado(data);
        // Actualizar el estado del checkbox basado en la respuesta
        if (data && data.length > 0 && data[0].auditado === 1) {
          setIsChecked(true);
        } else {
          setIsChecked(false);
        }
      });
  };

  useEffect(() => {
    obtenerEstado();
  }, [comercialSeleccionado, formattedDate, depositoSeleccionado]);

  // console.log("ESTADO", estado);

  const guardarAuditado = async (e) => {
    logEventos(
      "Guardar auditado",
      "Resultados de Control",
      "Guardo los auditados en el estado evaluación ",
      localStorage.getItem("user")
    );
    console.log("Estoy por guardar");
    await fetch(UrlApi + "/estados_evaluacion_comercial/" + estado[0].id, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        auditado: 1,
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          setColorMensaje("#D4EFDF");
          setMensaje("La auditoría se guardó exitosamente !");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar la auditoría");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  //--------------------------- NOTIFICACION----------------------------------------------//
  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  //---------------------------------------------------------------------------//
  // console.log(
  //   "RESULTADOS",
  //   resultadoEncontrado ? resultadosCheck[resultadoEncontrado] : null
  // );
  // console.log("RESULTADOS OBSERVACION", resultadoEncontrado);

  return (
    <div>
      <Navbar />
      <br></br>
      <Box
        bgcolor="#2B76A6"
        padding={1}
        borderRadius={0}
        color="white"
        textAlign="center"
        display="flex" // Agregado para que los elementos se coloquen en línea
        alignItems="center"
        marginTop={1}
        width="100%"
      >
        <AssignmentIcon
          style={{ fontSize: 40, marginRight: "20px", marginLeft: "20px" }}
        />
        <Typography variant="h4" component="div">
          Resultados de control de depósito
        </Typography>
      </Box>
      <br></br>
      <Box ml={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            {comercial && ( // Verifica si comercial tiene valores asignados
              <Autocomplete
                disabled={!comercial}
                disableClearable
                size="small"
                required
                noOptionsText={"Comercial INEXISTENTE"}
                options={comercial}
                autoHighlight
                getOptionLabel={(comercial) =>
                  comercial.id + "-" + comercial.nombre_apellido
                }
                onChange={(event, value) => setComercialSeleccionado(value)}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    key={option.id}
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.id} - {option.nombre_apellido}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                    }}
                    focused
                    size="small"
                    placeholder="Tipee para buscar..."
                    label="Comercial"
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {deposito && ( // Verifica si deposito tiene valores asignados
              <Autocomplete
                disabled={!deposito}
                disableClearable
                size="small"
                required
                noOptionsText={"Sucursal INEXISTENTE"}
                options={deposito}
                autoHighlight
                getOptionLabel={(sucursal) =>
                  sucursal.id + "-" + sucursal.nombre
                }
                onChange={(event, value) => setDepositoSeleccionado(value)}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    key={option.id}
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.id} - {option.nombre}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                    }}
                    focused
                    size="small"
                    placeholder="Tipee para buscar..."
                    label="Sucursal"
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {responsable && ( // Verifica si responsable tiene valores asignados
              <Autocomplete
                disabled={!responsable}
                disableClearable
                size="small"
                required
                noOptionsText={"Responsable INEXISTENTE"}
                options={responsable}
                autoHighlight
                getOptionLabel={(users) => users.username}
                onChange={(event, value) => setResponsableSeleccionado(value)}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    key={option.id}
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.username}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                    }}
                    focused
                    size="small"
                    placeholder="Tipee para buscar..."
                    label="Responsable"
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              focused
              size="small"
              label="Período"
              type="month"
              value={selectedDate}
              onChange={handleDateChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <br></br>
      {resultadosCheck && resultadosCheck.length > 0 ? (
        <Paper
          elevation={6}
          style={{ width: "80%", padding: "20px", marginLeft: 70 }}
        >
          <Typography
            variant="poster"
            fontSize={20}
            style={{ textDecoration: "underline" }}
          >
            Control de depósito:
          </Typography>
          <br></br>
          <Typography variant="poster" fontSize={20}>
            {comercialSeleccionado.nombre_apellido}
            <br></br>
            Sucursal: {depositoSeleccionado.nombre}
          </Typography>
          <Table>
            <br></br>
            <TableBody>
              {/* Mapea sobre gruposEItems para renderizar cada grupo y sus items */}
              {terminado === true &&
                gruposEItems.map((grupoItem, grupoIndex) => (
                  <React.Fragment key={grupoItem.grupo.id_grupo}>
                    <TableRow>
                      <TableCell
                        style={{ fontSize: 17, backgroundColor: "#C7C7C7" }}
                        colSpan={3}
                      >
                        <b>{grupoItem.grupo.nombre_grupo}</b>
                      </TableCell>
                    </TableRow>
                    {grupoItem.items.map((item, itemIndex) => (
                      <TableRow key={item.id_item}>
                        <TableCell style={{ fontSize: 16 }}>
                          <b>{item.nombre_item}</b>
                          <br></br>
                          <br></br>
                          {item.definicion_item}
                        </TableCell>
                        <TableCell>
                          <FormGroup
                            aria-label="position"
                            row
                            style={{ marginRight: 220 }}
                          >
                            <FormControlLabel
                              labelPlacement="end"
                              label="Cumple"
                              control={
                                <Checkbox
                                  name="cumple"
                                  checked={
                                    checkboxStates[grupoItem.grupo.id_grupo]?.[
                                      item.id_item
                                    ] === "cumple" || false
                                  }
                                />
                              }
                            />
                            <FormControlLabel
                              labelPlacement="end"
                              label="No cumple"
                              control={
                                <Checkbox
                                  name="no_cumple"
                                  checked={
                                    checkboxStates[grupoItem.grupo.id_grupo]?.[
                                      item.id_item
                                    ] === "no_cumple" || false
                                  }
                                />
                              }
                            />
                            <FormControlLabel
                              labelPlacement="end"
                              label="N/A"
                              control={
                                <Checkbox
                                  name="n_a"
                                  checked={
                                    checkboxStates[grupoItem.grupo.id_grupo]?.[
                                      item.id_item
                                    ] === "n_a" || false
                                  }
                                />
                              }
                            />
                          </FormGroup>
                        </TableCell>
                        <TableCell>
                          <TextField
                            fullWidth
                            size={size}
                            margin="normal"
                            style={{
                              ...style,
                              width: "100%",
                              marginRight: 300,
                            }}
                            focused
                            color="primary"
                            name="observacion"
                            label="Observaciones"
                            variant="outlined"
                            multiline
                            rows={5}
                            value={observaciones[item.id_item]} // Usar la observación correspondiente
                            inputProps={{
                              maxLength: 200,
                              readOnly: true, // Establecer el campo como de solo lecturasa
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Grid
                            container
                            sx={{ justifyContent: "left", mt: 4 }}
                          >
                            <Grid item mr={5} mt={-2}>
                              {resultadosCheck &&
                              resultadosCheck.some(
                                (resultado) =>
                                  resultado.id_grupo ===
                                    grupoItem.grupo.id_grupo &&
                                  resultado.id_item === item.id_item &&
                                  resultado.auditado === 1
                              ) ? (
                                <Button
                                  variant="outlined"
                                  aria-readonly
                                  color={"error"}
                                  size="small"
                                >
                                  Auditado
                                </Button>
                              ) : (
                                <Button
                                  variant="outlined"
                                  onClick={() => {
                                    handleOpenModalCheck(
                                      item.id_item,
                                      itemIndex,
                                      grupoItem.grupo.id_grupo
                                    );
                                  }}
                                  size="small"
                                >
                                  Auditar
                                </Button>
                              )}
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))}
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
          <Box
            sx={{
              textAlign: "center",
              marginTop: 5,
              minHeight: "2vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>
              <Typography
                variant="poster"
                fontSize={19}
                style={{ textDecoration: "underline", marginTop: 20 }}
              >
                Resultados :
              </Typography>
              <br />
              <br />
              <Typography variant="poster" fontSize={19}>
                Cumplidos : {cantidadChecks.cumple} - No cumplidos :{" "}
                {cantidadChecks.no_cumple}
              </Typography>
              <Box mt={3}>
                <Button
                  onClick={() => setOpenCloseAdj(!openCloseAdj)}
                  variant="outlined"
                  size="medium"
                  startIcon={<AttachFileIcon />}
                >
                  VER ARCHIVOS ADJUNTOS
                </Button>
              </Box>
            </div>
          </Box>
          <br></br>
          <Divider style={{ width: "101%", marginTop: 20 }} />
          <br></br>
          <Typography
            variant="poster"
            fontSize={26}
            marginLeft={2}
            style={{ textDecoration: "underline" }}
          >
            Flota de vehículos
          </Typography>
          <br></br>
          <br></br>
          <Grid container spacing={2}>
            <Grid item xs={3} md={3} marginLeft={2}>
              <TextField
                label="Días habiles en el periodo"
                size="small"
                focused
                fullWidth
                inputProps={{
                  readOnly: true, // Establecer el campo como de solo lectura
                }}
                value={diasHabiles}
              />
            </Grid>
          </Grid>
          <br></br>
          <Box sx={{ flexGrow: 1 }} alignItems="flex">
            {datosFlota &&
              datosFlota.map((linea) => (
                <div
                  key={linea.id_flota}
                  style={{
                    paddingBottom: "4px",
                    paddingLeft: "3px",
                    paddingTop: "10px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={3.5} marginLeft={1}>
                      <TextField
                        label="Dominio y Tipo"
                        size="small"
                        focused
                        fullWidth
                        inputProps={{
                          readOnly: true, // Establecer el campo como de solo lectura
                        }}
                        value={`${linea.patente} - ${linea.marca} - ${linea.modelo}`}
                      />
                    </Grid>
                    <Grid item xs={4} md={1.2}>
                      <TextField
                        label="Días inactivos"
                        size="small"
                        focused
                        fullWidth
                        inputProps={{
                          readOnly: true, // Establecer el campo como de solo lectura
                        }}
                        value={linea.dias_inactivos} // Asegúrate de que esta propiedad exista en los datos
                      />
                    </Grid>
                    <Grid item xs={3} md={1.2}>
                      <TextField
                        label="Kg. Vendidos"
                        size="small"
                        focused
                        fullWidth
                        inputProps={{
                          readOnly: true, // Establecer el campo como de solo lectura
                        }}
                        value={linea.kg_vendidos}
                      />
                    </Grid>
                    <Grid item xs={4} md={3}>
                      <TextField
                        label="Zona de reparto"
                        size="small"
                        focused
                        fullWidth
                        inputProps={{
                          readOnly: true, // Establecer el campo como de solo lectura
                        }}
                        value={linea.zona}
                      />
                    </Grid>
                    <Grid item xs={4} md={3}>
                      <TextField
                        label="Chofer y Acompañante"
                        size="small"
                        focused
                        fullWidth
                        inputProps={{
                          readOnly: true, // Establecer el campo como de solo lectura
                        }}
                        value={linea.chofer_acompañante}
                      />
                    </Grid>
                  </Grid>
                </div>
              ))}
            <Divider style={{ width: "101%", marginTop: 20 }} />
            <br></br>
            <Typography
              variant="poster"
              fontSize={26}
              marginLeft={2}
              style={{ textDecoration: "underline" }}
            >
              Revisión de auditoría
            </Typography>
            <FormGroup
              style={{
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    size="large"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    name="revision"
                    disabled={isChecked} // Deshabilita el checkbox si está marcado
                  />
                }
                label="Revisión realizada"
              />
            </FormGroup>

            {/* SNACKBAR QUE SE MOSTRARA AL GUARDAR LOS CAMBIOS */}
            <Stack spacing={2} sx={{ width: "100%" }}>
              <Snackbar
                open={openSnack}
                autoHideDuration={1500}
                onClose={handleCloseSnack}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <Alert
                  onClose={handleCloseSnack}
                  severity="success"
                  sx={{ width: "100%" }}
                  style={{ backgroundColor: colormensaje, color: "black" }}
                >
                  {mensaje}
                </Alert>
              </Snackbar>
            </Stack>
          </Box>
          <br></br>
        </Paper>
      ) : (
        <Paper
          elevation={6}
          style={{ width: "80%", padding: "20px", marginLeft: 70 }}
        >
          <Typography variant="body1" color="textSecondary" align="center">
            <strong>
              {" "}
              No hay ningún resultado de control de depósito para la sucursal o
              comercial o fecha seleccionada.
            </strong>
          </Typography>
        </Paper>
      )}
      {openCloseAdj ? ( // ADJUNTAR IMAGEN
        <FileUploadCheck
          fileId={uuid}
          openclose={true}
          mostrar={false}
          onClose={() => handleOpenCloseAdj(false)} // Proporciona una función de cierre
        />
      ) : null}

      {openModal ? (
        <ModalCheckAud
          open={setOpenModal}
          onClose={() => handleOpenModalCheck(false)} // Proporciona una función de cierre
          responsable={responsableSeleccionado} // Pasar el responsable seleccionado
          sucursal={depositoSeleccionado}
          resultados={resultadoEncontrado || ""}
          observacion={observaciones[itemId] || ""}
          itemId={itemId} // Pasa el itemId como propiedad
          obtenerResultadosDespuesAuditar={obtenerResultadosCheck}
        />
      ) : null}
      <br></br>
    </div>
  );
}

export default Resultados_checklist;
