import React from "react";
import {useState} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Notify from "./Notify";

function ConfirmDialog(props) {
  console.log("props", props);
  const [disBtn, setDisBtn] = useState();

  const handleClose = () => {
    props.setOpen(false);
    props.setNotificacion({open:false})
  };

  return (
    <div>
      {props.notificacion.open && (
        <Notify
          open={props.notificacion.open}
          severity={props.notificacion.severity}
          mensaje={props.notificacion.mensaje}
          handleClose={props.atras? props.atras : handleClose}
          color={props.notificacion.color}
        />
      )}
      <Dialog open={props.open} onClose={handleClose}>
        <DialogTitle backgroundColor="#1F618D" color="#fff">
          {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText my={2}>{props.text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={disBtn} color="error">
            Volver
          </Button>

          <Button
            disabled={disBtn}
            autoFocus
            color="success"
            onClick={(e) => {
              props.guardar(e);
              setDisBtn(true);
              // if(props.actualizar){
              //   props.actualizar()
              // }
            }}
          >
            {props.btnText ? props.btnText : "Guardar"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ConfirmDialog;
