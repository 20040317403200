//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio , Nicolas Pascucci
// Creation Date : 16/04/2024
// Version : 1
// Description : Configuracion de correos para enviar mail por nuevo,cancelado o finalizado un reclamo
//
//------Changes --------------------------------------------------------------------- -------------//
// Change Date :
// Change by :
// Change description:
//
// Change Date :
// Change by :
// Change description:
//
//========================================================================/
import React, { useState, useEffect } from "react";
import { UrlApi } from "../../../services/apirest";
import Navbar from "../../Navbar";
import AppBar from '@mui/material/AppBar';
// import Title from "../../Title";
import {
  Button,
  Box,
  Grid,
  Stack,
  Snackbar,
  TextField,
  Alert,
  Container,
  Toolbar,
  Typography,
} from "@mui/material";
import { logEventos } from "../../../services/services";

function Reclamos_config() {
  const [reclamosConfig_original, setreclamosConfig_original] = useState(null);
  const [reclamosConfig, setreclamosConfig] = useState([
    {
      email_usuario: "",
    },
  ]);
  const [disGuardar, setDisGuardar] = useState(false);
  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");

  useEffect(() => {
    logEventos("Acceso al menú configuración mail-reclamos", "Configuración mail - Reclamos", "Ver las pendientes,aprobadas y desaprobadas", localStorage.getItem("user"))
}, [])

  const getConfig = () => {
    fetch(UrlApi + "/reclamos_config")
      .then((data) => data.json())
      .then((data) => {
        setreclamosConfig(data[0]); // Inicio los datos de reclamosConfig como me los traigo de la bd
        setreclamosConfig_original(data[0]); //Guardo la configuración original para luego compararla y ver si hay modificaciones.
      });
  };

  // console.log("reclamos config", reclamosConfig);
  // console.log("reclamos config original", reclamosConfig_original);

  useEffect(() => {
    getConfig();
  }, []);

  const almacenar = (e) => {
    setreclamosConfig({ ...reclamosConfig, [e.target.name]: e.target.value });
  };

  const guardarConfig = async (e) => {
    // console.log("Estoy por guardar");
    logEventos("Guardar Email", "Configuración mail - Reclamos", "Se guardo la configuración del mail", localStorage.getItem("user"))
    e.preventDefault();
    await fetch(UrlApi + "/reclamos_config/", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email_usuario: reclamosConfig.email_usuario,
        usuario: localStorage.getItem("user"),
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("La configuración se guardó exitosamente !");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar la configuración");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  return (
    <div>
        <Navbar />
      <Container maxWidth="lg">
  <AppBar position="static" sx={{ backgroundColor: '#2B76A6', width: '100%' ,marginTop:2 }}>
    <Toolbar>
    <Typography variant="h5" sx={{ textAlign: 'center', margin: 'auto', width: '100%', }}>
  Configuración de Mail - Reclamos
</Typography>
    </Toolbar>
  </AppBar>
        {/* <Title
          titulo={"Configuración de mail"}
        /> */}
        <form onSubmit={guardarConfig}>
          <Box
            my={1}
            backgroundColor="#F4F6F6"
            p={2}
            border={0.5}
            borderColor={"#ABB2B9"}
            borderRadius={4}
          >
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  name="email_usuario"
                  label="Mail"
                  value={reclamosConfig.email_usuario}
                  onChange={almacenar}
                  // style={{ backgroundColor: "#fff"}}
                  focused
                  color="primary"
                  type="mail"
                  helperText="Para añadir más de un correo, sepárelos con ; (punto y coma)"
                  style={{ width: '270%', marginBottom: '10px',backgroundColor: "#fff" }} 
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="success"
                  type="submit"
                  disabled={disGuardar}
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Container>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
}

export default Reclamos_config;
