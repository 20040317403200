import {UrlApi} from "../../services/apirest"

export function validarHs(index, hs, validacionDiasHs, setValidacionDiasHs) {
  const newValue = parseInt(hs, 10);
  const newErrors = [...validacionDiasHs.errorHs];
  newErrors[index] = newValue > 24;
  setValidacionDiasHs({
    ...validacionDiasHs,
    errorHs: newErrors,
  });
}

export function validarDias(
  index,
  dias,
  validacionDiasHs,
  setValidacionDiasHs
) {
  const newValue = parseInt(dias, 10);
  const newErrors = [...validacionDiasHs.errorHs];
  newErrors[index] = newValue > 365;
  setValidacionDiasHs({
    ...validacionDiasHs,
    errorDias: newErrors,
  });
}

export function validarClientes(cliente, validado, setValidado) {
  if (
    (!!cliente.razon_social || !!cliente.nombre && cliente.apellido) &&
    !!cliente.situacion_iva &&
    !!cliente.direccion_fiscal &&
    !!cliente.id_provincia &&
    !!cliente.id_localidad &&
    !!cliente.id_segmento &&
    !!cliente.id_sucursal &&
    !!cliente.id_comercial && 
    !!cliente.id_empresa &&
    !!cliente.id_condicionpago 
  ) {
    setValidado({ ...validado, cliOK: true });
  } else {
    setValidado({ ...validado, cliOK: false });
  }
}

export function validarDirecciones(direccion, validado, setValidado, tipoFact) {
  if (tipoFact === 236) {
    if (!!direccion.latitud && !!direccion.longitud) {
      setValidado({ ...validado, dirOK: true });
    } else {
      setValidado({ ...validado, dirOK: false });
    }
  } else {
    if (
      !!direccion.direccion_entrega &&
      !!direccion.id_provincia &&
      !!direccion.id_localidad &&
      !!direccion.id_producto &&
      !!direccion.id_sucursal &&
      !!direccion.latitud &&
      !!direccion.longitud &&
      !!direccion.nombre_direccion &&
      !!direccion.id_tipomoneda
    ) {
      setValidado({ ...validado, dirOK: true });
    } else {
      setValidado({ ...validado, dirOK: false });
    }
  }
}

export function validarTanques(factibilidad, validado, setValidado) {
  if (
    !!factibilidad.canttk_05 ||
    !!factibilidad.canttk_1 ||
    !!factibilidad.canttk_2 ||
    !!factibilidad.canttk_4 ||
    !!factibilidad.canttk_7 ||
    !!factibilidad.canttk_otros
  ) {
    setValidado({ ...validado, tksOK: true });
  } else {
    setValidado({ ...validado, tksOK: false });
  }
}

export function validarCuit(e, setDataCliente, dataCliente) {
  const cuit = e.target.value;
  if (cuit.length > 0) {
    const replace = cuit.replace(/-/g, ""); // remplaza los - y los quita
    if (replace.length !== 11) {
    } else {
      let acumulado = 0;
      let digitos = replace.split("");
      let digito = parseInt(digitos.pop());
      for (let i = 0; i < digitos.length; i++) {
        acumulado += digitos[9 - i] * (2 + (i % 6));
      }
      let verif = 11 - (acumulado % 11);
      if (verif === 11) {
        verif = 0;
      } else if (verif === 10) {
        verif = 9;
      }
      if (digito !== verif) {
        alert("El cuit ingresado es inválido. No se guardará.");
        setDataCliente({ ...dataCliente, cuit: "" });
        return false
      } else {
        return true
      }
    }
  }
}

export function validarCostos(factibilidad, validado, setValidado) {
  const condicionesOriginales = [
    (!factibilidad.motivo1 && !factibilidad.cantmotivo1) ||
      (!!factibilidad.motivo1 && !!factibilidad.cantmotivo1),
    // (!factibilidad.motivo2 && !factibilidad.cantmotivo2) || (!!factibilidad.motivo2 && !!factibilidad.cantmotivo2),
    // (!factibilidad.motivo3 && !factibilidad.cantmotivo3) || (!!factibilidad.motivo3 && !!factibilidad.cantmotivo3),
    (!factibilidad.motivocli1 && !factibilidad.cantmotivocli1) ||
      (!!factibilidad.motivocli1 && !!factibilidad.cantmotivocli1),
    (!factibilidad.motivocli2 && !factibilidad.cantmotivocli2) ||
      (!!factibilidad.motivocli2 && !!factibilidad.cantmotivocli2),
    (!factibilidad.motivocli3 && !factibilidad.cantmotivocli3) ||
      (!!factibilidad.motivocli3 && !!factibilidad.cantmotivocli3),
    !!factibilidad.cantmotivo1 
    // || !!factibilidad.cantmotivo2 || !!factibilidad.cantmotivo3,
  ];
  const OK = condicionesOriginales.every((condicion) => condicion);
  setValidado((validado) => ({ ...validado, costosOK: OK }));
}

export function validarArtCompletos(listaArtefactos, validado, setValidado) {
  const todosConConsumoAnual = listaArtefactos.every(
    (artefacto) => artefacto.consumoanual !== "" && artefacto.consumoanual !== 0
  );
  setValidado({ ...validado, artOK: todosConConsumoAnual });
}

/* VALIDACIONES EN LA MODIFICACIÓN, YA QUE FUNCIONAN DE DISTINTA MANERA: */
export function validarCliMod(cliente, setCliOK) {
  if (
    !!cliente.razon_social &&
    !!cliente.situacion_iva &&
    !!cliente.direccion_fiscal &&
    !!cliente.id_provincia &&
    !!cliente.id_localidad &&
    !!cliente.id_segmento &&
    // !!cliente.id_subsegmento &&
    !!cliente.id_comercial &&
    !!cliente.id_empresa
  ) {
    setCliOK(true);
  } else {
    setCliOK(false);
  }
}

export function validarDirMod(direccion, setDirOK) {
  if (
    !!direccion.direccion_entrega &&
    !!direccion.id_provincia &&
    !!direccion.id_localidad &&
    !!direccion.id_producto &&
    !!direccion.id_sucursal &&
    !!direccion.latitud &&
    !!direccion.longitud &&
    !!direccion.nombre_direccion &&
    !!direccion.id_tipomoneda
  ) {
    setDirOK(true);
  } else {
    setDirOK(false);
  }
}

export function validarCostosMod(factibilidad, setCostosOK) {
  const costosEmp1 = (!!factibilidad.motivo1 && (!!factibilidad.cantmotivo1 || factibilidad.cantmotivo1 === 0)) || (!factibilidad.motivo1 && !factibilidad.cantmotivo1);
  // const costosEmp2 = (!!factibilidad.motivo2 && (!!factibilidad.cantmotivo2 || factibilidad.cantmotivo2 === 0)) || (!factibilidad.motivo2 && !factibilidad.cantmotivo2);
  // const costosEmp3 = (!!factibilidad.motivo3 && (!!factibilidad.cantmotivo3 || factibilidad.cantmotivo3 === 0)) || (!fsactibilidad.motivo3 && !factibilidad.cantmotivo3);
  /*---------------------*/

  const costosCli1 = (!!factibilidad.motivocli1 && (!!factibilidad.cantmotivocli1 || factibilidad.cantmotivocli1 === 0)) || (!factibilidad.motivocli1 && !factibilidad.cantmotivocli1);
  const costosCli2 = (!!factibilidad.motivocli2 && (!!factibilidad.cantmotivocli2 || factibilidad.cantmotivocli2 === 0)) || (!factibilidad.motivocli2 && !factibilidad.cantmotivocli2);
  const costosCli3 = (!!factibilidad.motivocli3 && (!!factibilidad.cantmotivocli3 || factibilidad.cantmotivocli3 === 0)) || (!factibilidad.motivocli3 && !factibilidad.cantmotivocli3);

  const OK = 
    costosEmp1 === true &&
    // costosEmp2 === true &&
    // costosEmp3 === true &&
    costosCli1 === true &&
    costosCli2 === true &&
    costosCli3 === true;
  setCostosOK(OK);
}

export function validarTksMod(factibilidad, setTksOK) {
  if (
    !!factibilidad.canttk_05 ||
    !!factibilidad.canttk_1 ||
    !!factibilidad.canttk_2 ||
    !!factibilidad.canttk_4 ||
    !!factibilidad.canttk_7 ||
    !!factibilidad.canttk_otros
  ) {
    setTksOK(true);
  } else {
    setTksOK(false);
  }
}

export async function existeCuit(cuit) {
  const response = await fetch(UrlApi+"/clientes/existecuit/" + cuit)
  const coincidencias= await response.json();
  return coincidencias;
}


export async function existeDni(dni) {
  const response = await fetch(UrlApi+"/clientes/existedni/" + dni)
  const coincidencias= await response.json();
  return coincidencias;
}
