//----- Component --------------------------------------------------------/
// Developer: Magalí Perea
// Creation Date:
// Version:
// Description: Este componente contiene desarrollo exclusivo para el área de compras. Consiste en una pantalla que notifica que se han realizado cambios en la configuración de la factibilidad (precios), y precisan ser revisados.
// Para su funcionamiento se hacen algunas comprobaciones, en pos de determinar que la información que se mostrará. Éstas son:
//  - el usuario tiene acceso permitido a esta informacion y a la función de aprobar estos cambios ? --> se determina comprobando que el rol sea administrador, y el sector sea 4 (compras)
//  - hay modificaciones para aprobar en la configuración de la factibilidad? --> se determina chequeando cual es el estado de la factibilidad. si el estado es 220(pendiente de aprobación) es porque hay datos que debe revisar y aprobar. si no , entonces mostrará una alerta con una leyenda que indica que no hay nada por revisar.

import React, { useEffect, useState } from "react";
import Navbar from "../../Navbar";
import { Container, Grid, Paper, Typography, Button } from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import { getDataIx0, putData } from "../../../services/library";
import Notify from "../../../components/Notify";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CircularProgress from "@mui/material/CircularProgress";
import LockIcon from "@mui/icons-material/Lock";

function AprobarConfig() {
  const [factConfig, setFactConfig] = useState(null);
  const [disBtn, setDisBtn] = useState();
  const [notify, setNotify] = useState({
    open: false,
    severity: "",
    color: "",
    mensaje: "",
  });

  const accesoPermitido =
    localStorage.getItem("role") === "admin" &&
    localStorage.getItem("sector") === "4";

  useEffect(() => {
    getDataIx0("/factconfig", setFactConfig);
  }, []);

  const handleClose = () => {
    window.location.replace(`/#/navbar`);
  };

  return (
    <div style={{ backgroundColor: "#ebebeb", minHeight: "100vh" }}>
      <Navbar />
      <Container maxWidth="lg">
        {notify.open ? (
          <Notify
            open={notify.open}
            color={notify.color}
            severity={notify.severity}
            mensaje={notify.mensaje}
            handleClose={() => handleClose()}
          />
        ) : null}
        {accesoPermitido ? (
          !!factConfig ? (
            factConfig.id_estado === 220 ? (
              <>
                <Paper
                  variant="elevation"
                  elevation={10}
                  style={{ borderRadius: 0 }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    alignContent="stretch"
                    wrap="wrap"
                    my={2}
                    borderRadius={10}
                  >
                    <Grid
                      item
                      xs={1}
                      backgroundColor="#de4331"
                      textAlign="center"
                      p={1}
                    >
                      <VerifiedIcon style={{ color: "#fff", fontSize: 30 }} />
                    </Grid>
                    <Grid item xs={11} backgroundColor="white" p={1}>
                      <Typography
                        variant="body1"
                        color="initial"
                        sx={{ ml: 1 }}
                      >
                        Aprobar Configuración
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>

                <Paper
                  variant="elevation"
                  elevation={10}
                  style={{ borderRadius: 0 }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    wrap="wrap"
                    my={2}
                    borderRadius={10}
                  >
                    <Grid item backgroundColor="white" p={3}>
                      <Typography variant="h6" sx={{ fontSize: 16 }}>
                        Algunos parámetros de la factibilidad han sido
                        modificados y requieren su aprobación:
                      </Typography>
                      <ul>
                        <li>
                          <Typography variant="subtitle1" color="initial">
                            {" "}
                            Costo Transporte Por Km:{" "}
                            <b>
                              {factConfig &&
                                factConfig.costo_transporte_km_pending}{" "}
                              ARS
                            </b>{" "}
                          </Typography>
                        </li>
                      </ul>
                    </Grid>
                  </Grid>
                </Paper>

                <Grid
                  container
                  spacing={1}
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Grid item>
                    <Button
                      disabled={disBtn}
                      variant="contained"
                      color="success"
                      onClick={(e) => {
                        setDisBtn(true);
                        putData(
                          e,
                          "/factConfig",
                          {
                            id_estado: 221,
                            costo_transporte_km:
                              factConfig.costo_transporte_km_pending,
                            costo_transporte_km_pending: null,
                          },
                          setNotify
                        );
                      }}
                    >
                      aprobar
                    </Button>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Paper
                  variant="elevation"
                  elevation={10}
                  style={{ borderRadius: 0 }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    alignContent="stretch"
                    wrap="wrap"
                    my={2}
                    borderRadius={10}
                  >
                    <Grid
                      item
                      xs={1}
                      backgroundColor="#F39C12"
                      textAlign="center"
                      p={1}
                    >
                      <WarningAmberIcon
                        style={{ color: "#fff", fontSize: 30 }}
                      />
                    </Grid>
                    <Grid item xs={11} backgroundColor="white" p={1}>
                      <Typography
                        variant="body1"
                        color="initial"
                        sx={{ ml: 1 }}
                      >
                        No hay configuraciones por aprobar
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </>
            )
          ) : (
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              p={3}
            >
              <Grid item xs>
                <Typography m={6} fontSize={30} variant="h2" color="initial">
                  Cargando...
                </Typography>
              </Grid>
              <Grid item>
                <CircularProgress size="7rem" color="primary" />
              </Grid>
            </Grid>
          )
        ) : (
          <>
            <Paper
              variant="elevation"
              elevation={10}
              style={{ borderRadius: 0 }}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                alignContent="stretch"
                wrap="wrap"
                my={2}
                borderRadius={10}
              >
                <Grid
                  item
                  xs={1}
                  backgroundColor="#F39C12"
                  textAlign="center"
                  p={1}
                >
                  <LockIcon style={{ color: "#fff", fontSize: 30 }} />
                </Grid>
                <Grid item xs={11} backgroundColor="white" p={1}>
                  <Typography variant="body1" color="initial" sx={{ ml: 1 }}>
                    Acceso Denegado
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </>
        )}
      </Container>
    </div>
  );
}

export default AprobarConfig;
