import React, { useEffect, useState } from "react";
import { UrlApi } from "../../services/apirest";
import { useLocation } from "react-router-dom";

// ENVIAR MAILS
import { enviarmails } from "../../services/services";

function Email_delegar_aceptar() {
  const [hallazgo, setHallazgo] = useState();
  const location = useLocation();

  // Obtener el parámetro 'sugerencia' de la URL
  const queryParams = new URLSearchParams(location.search);
  const sugerencia = queryParams.get("sugerencia");
  const sector = queryParams.get("sector");

  useEffect(() => {
    // Mostrar el valor de 'sugerencia' en el elemento 'sugerenciaTexto'
    const elementoSugerencia = document.getElementById("sugerenciaTexto");
    if (sugerencia && elementoSugerencia) {
      elementoSugerencia.textContent = sugerencia;
    }

    const elementoSector = document.getElementById("sectorTexto");
    if (sector && elementoSector) {
      elementoSector.textContent = sector;
    }

    // console.log("SUGERENCIA: ", sugerencia);
    // console.log("SECTOR: ", sector);
 
  }, [location.search]); // Solo location.search, hallazgo se elimina como dependencia

  useEffect(() => {
    const obtenerHallazgo = async () => {
      try {
        const response = await fetch(UrlApi + "/auditorias/" + sugerencia);
        const data = await response.json();
        // console.log("HALLAZGO: ", data);
        setHallazgo(data);
      } catch (error) {
        console.error("Error al obtener el hallazgo:", error);
      }
    };
  
    obtenerHallazgo();
  }, [sugerencia]);

// Usar valores de hallazgo una vez que estén disponibles
useEffect(() => {
  if (hallazgo && hallazgo.email_auditor) {
    // console.log("Valor específico de hallazgo:", hallazgo.email_auditor);
    // Realizar otras acciones necesarias con los valores de 'hallazgo'
  }
}, [hallazgo]);

  //   ----------------------------------------------

useEffect(() => {
  if (sugerencia && sector && hallazgo) {
    const aceptarDelegacion = async () => {
      // console.log("Estoy por guardar");
      try {
        const res = await fetch(
          UrlApi + "/auditorias/aceptar_delegacion/" + sugerencia,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              id_sector_delegado: sector,
            }),
          }
        );
        if (!res.err) {
          console.log("Guardado");
          enviarmails(
            hallazgo.email_auditor,
            "Auditoria: Hallazgo Delegado",
            `<p>La solicitud de delegación para el hallazgo "${hallazgo.problema_sugerencia}" fue aceptada.</p>`
          );
          console.log("Email Enviado");
        } else {
          console.log("Error");
        }
      } catch (err) {
        console.log(err.message);
      }
    };

    // Llamar a la función para realizar el PUT
    aceptarDelegacion();
  }
}, [sugerencia, sector, hallazgo]);

  //   ----------------------------------------------

  // ESTILOS
  const navbarStyle = {
    backgroundColor: "blue",
    textAlign: "center",
    padding: "10px",
  };

  const tituloStyle = {
    color: "white",
  };

  const textoStyle = {
    textAlign: "center",
    marginTop: "50px",
    fontWeight: "bold",
  };

  return (
    <div>
      <nav style={navbarStyle}>
        <h1 style={tituloStyle}>RAI</h1>
      </nav>
      <div style={textoStyle}>
        <p>Se ha aceptado la delegación del hallazgo</p>
      </div>
      <p>ID Hallazgo:</p>{" "}
      <p id="sugerenciaTexto">Aquí se mostrará la sugerencia</p>
      <p>ID Sector:</p> <p id="sectorTexto">Aquí se mostrará el sector</p>
      <div>
        {hallazgo && (
          <div>
            <h2>Detalles del Hallazgo:</h2>
          </div>
        )}
      </div>{" "}
    </div>
  );
}

export default Email_delegar_aceptar;
