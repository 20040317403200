
import React, { useState } from "react";
import { Typography, Grid, Box, Card, TextField, InputAdornment, CardContent } from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PercentIcon from "@mui/icons-material/Percent";
import moment from "moment";

function Actualizar_precio(props) {
  const { datosExisten, datoslista, style, setDatoslista, fechaFinVigenciaFormat } = props;

  const almacenar = (e) => {
    setDatoslista({
      ...datoslista,
      [e.target.name]: e.target.value,
    });
  };

  //AUMENTO EN PESO ******//
  const [desactivarPorcentaje, setDesactivarPorcentaje] = useState(false);
  const [desactivarPesos, setDesactivarPesos] = useState(false);
  const [aumento, setAumento] = useState("");

  const handleAumentoChange = (e) => {
    const nuevoAumento = e.target.value;
    setAumento(nuevoAumento);
    setDesactivarPorcentaje(nuevoAumento !== "");

    if (!isNaN(nuevoAumento) && nuevoAumento !== "") {
      setDatoslista({
        ...datoslista,
        precio_litro_con_iva: datosExisten[0].precio_litro_con_iva + parseFloat(nuevoAumento),
        precio_litro_sin_iva: datosExisten[0].precio_litro_sin_iva + parseFloat(nuevoAumento),
        precio_kilo_con_iva: datosExisten[0].precio_kilo_con_iva + parseFloat(nuevoAumento),
        precio_kilo_sin_iva: datosExisten[0].precio_kilo_sin_iva + parseFloat(nuevoAumento),
      });
    } else {
      setDatoslista({
        ...datoslista,
        precio_litro_con_iva: datosExisten[0].precio_litro_con_iva,
        precio_litro_sin_iva: datosExisten[0].precio_litro_sin_iva,
        precio_kilo_con_iva: datosExisten[0].precio_kilo_con_iva,
        precio_kilo_sin_iva: datosExisten[0].precio_kilo_sin_iva,
      });
    }
  };

  // datosExisten && console.log("datos",datosExisten[0])


  //*AUMENTO EN PORCENTAJE ***************************//
  const [porcentaje, setPorcentaje] = useState("");

  const handlePorcentajeChange = (e) => {
    const nuevoPorcentaje = e.target.value;
    setPorcentaje(nuevoPorcentaje);
    setDesactivarPesos(nuevoPorcentaje !== "");

    if (!isNaN(nuevoPorcentaje) && nuevoPorcentaje !== "") {
      const porcentajeNumerico = parseFloat(nuevoPorcentaje) / 100;

      const nuevoPrecioLitroConIva = datosExisten[0].precio_litro_con_iva * (1 + porcentajeNumerico);
      const nuevoPrecioLitroSinIva = datosExisten[0].precio_litro_sin_iva * (1 + porcentajeNumerico);
      const nuevoPrecioKiloConIva = datosExisten[0].precio_kilo_con_iva * (1 + porcentajeNumerico);
      const nuevoPrecioKiloSinIva = datosExisten[0].precio_kilo_sin_iva * (1 + porcentajeNumerico);

      setDatoslista({
        ...datoslista,
        precio_litro_con_iva: nuevoPrecioLitroConIva.toFixed(4),
        precio_litro_sin_iva: nuevoPrecioLitroSinIva.toFixed(4),
        precio_kilo_con_iva: nuevoPrecioKiloConIva.toFixed(4),
        precio_kilo_sin_iva: nuevoPrecioKiloSinIva.toFixed(4),
      });
    } else {
      setDatoslista({
        ...datoslista,
        precio_litro_con_iva: datosExisten[0].precio_litro_con_iva,
        precio_litro_sin_iva: datosExisten[0].precio_litro_sin_iva,
        precio_kilo_con_iva: datosExisten[0].precio_kilo_con_iva,
        precio_kilo_sin_iva: datosExisten[0].precio_kilo_sin_iva,
      });
    }
  };

  const uniqueDatosExisten = datosExisten && datosExisten.filter(
    (item, index, self) =>
      index ===
      self.findIndex(
        (t) =>
          t.id_empresa === item.id_empresa &&
          t.id_segmento === item.id_segmento &&
          t.id_producto === item.id_producto &&
          t.id_sucursal === item.id_sucursal
      )
  );
  

  // const vigenciaFormatted = datosExisten && moment(datosExisten[0].fin_vigencia).add(1, 'days').format('YYYY-MM-DD');

  return (
    <>
      {datosExisten && (
       <Box sx={{ padding: 2 }}>
          <Typography sx={{ fontSize: 19, mb: 2, textAlign: "center" }}>
          Ya existe una lista de precio para
          </Typography>
       {uniqueDatosExisten.map((d, index) => (
         <React.Fragment key={index}>
           <Typography sx={{ fontSize: 15, mb: 2, textAlign: "center" }}>
             {`${d.empresa}, ${d.nombre} ${d.segmento} ${d.producto}`}
             {index < uniqueDatosExisten.length - 1 ? "," : ""}
           </Typography>
         </React.Fragment>
       ))}
        <Typography sx={{ fontSize: 15, mb: 2, textAlign: "center" }}>
          Solo puede actualizar.
          </Typography>
     </Box>
        // <Box sx={{ padding: 2 }}>
        //   {/* Card para Precio Vigente */}
        //   <Card variant="outlined" sx={{ mb: 1, backgroundColor: "#F4F6F6" }}>
        //     <CardContent>
        //       <Typography sx={{ fontSize: 19, mb: 2, textDecoration: "underline", textAlign: "center" }}>
        //         Precio Vigente
        //       </Typography>
        //       {/* Precios en un solo renglón */}
        //       <Grid container spacing={1} alignItems="center" justifyContent="center">
        //         {[
        //           { label: "Precio litro sin IVA", value: datosExisten[0].precio_litro_sin_iva || 0 },
        //           { label: "Precio litro con IVA", value: datosExisten[0].precio_litro_con_iva || 0 },
        //           { label: "Precio kilo sin IVA", value: datosExisten[0].precio_kilo_sin_iva || 0 },
        //           { label: "Precio kilo con IVA", value: datosExisten[0].precio_kilo_con_iva || 0 },
        //         ].map((item, index) => (
        //           <Grid item xs={12} sm={6} md={2} key={index}>
        //             <TextField
        //               fullWidth
        //               style={style}
        //               focused
        //               size="small"
        //               variant="outlined"
        //               label={item.label}
        //               value={item.value}
        //               InputProps={{ readOnly: true }}
        //             />
        //           </Grid>
        //         ))}
        //         {/* Fechas de Vigencia */}
        //         <Grid item xs={12} sm={6} md={2}>
        //           <TextField
        //             fullWidth
        //             style={style}
        //             focused
        //             size="small"
        //             variant="outlined"
        //             label="Entrada en Vigencia"
        //             value={moment(datosExisten[0].vigencia).format("DD-MM-YYYY") || ""}
        //             InputProps={{ readOnly: true }}
        //           />
        //         </Grid>
        //         <Grid item xs={12} sm={6} md={2}>
        //           <TextField
        //             fullWidth
        //             style={style}
        //             focused
        //             size="small"
        //             variant="outlined"
        //             label="Fin Vigencia"
        //             value={moment(datosExisten[0].fin_vigencia).format("DD-MM-YYYY") || "No hay registro"}
        //             InputProps={{ readOnly: true }}
        //           />
        //         </Grid>
        //         {/* <Grid item xs={12} sm={6} md={2}>
        //           <TextField
        //             fullWidth
        //             style={style}
        //             focused
        //             size="small"
        //             variant="outlined"
        //             label="Fin de Vigencia"
        //             value={fechaFinVigenciaFormat || ""}
        //             InputProps={{ readOnly: true }}
        //           />
        //         </Grid> */}
        //       </Grid>
        //     </CardContent>

        //     <CardContent>
        //       <Typography sx={{ fontSize: 19, mb: 2, textDecoration: "underline", textAlign: "center" }}>
        //         Actualización
        //       </Typography>
        //       <Grid container spacing={1} alignItems="center" justifyContent="center">
        //         <Grid item xs={12} sm={6} md={2}>
        //           <TextField
        //             fullWidth
        //             style={style}
        //             focused
        //             size="small"
        //             color="secondary"
        //             label="Actualización en pesos"
        //             variant="outlined"
        //             value={aumento}
        //             onChange={handleAumentoChange}
        //             disabled={desactivarPesos}
        //             InputProps={{
        //               startAdornment: <InputAdornment position="start"><AttachMoneyIcon /></InputAdornment>,
        //             }}
        //           />
        //         </Grid>
        //         <Grid item xs={12} sm={6} md={2}>
        //           <TextField
        //             fullWidth
        //             size="small"
        //             style={style}
        //             focused
        //             color="secondary"
        //             label="Actualización en porcentaje"
        //             variant="outlined"
        //             value={porcentaje}
        //             onChange={handlePorcentajeChange}
        //             disabled={desactivarPorcentaje}
        //             InputProps={{
        //               startAdornment: <InputAdornment position="start"><PercentIcon /></InputAdornment>,
        //             }}
        //           />
        //         </Grid>
        //       </Grid>
        //     </CardContent>

        //     <CardContent>
        //       <Typography sx={{ fontSize: 19, mb: 2, textDecoration: "underline", textAlign: "center" }}>
        //         Precio actualizado
        //       </Typography>
        //       {/* Precios con aumento en un solo renglón */}
        //       <Grid container spacing={1} alignItems="center" justifyContent="center">
        //         {[
        //           { label: "Precio litro sin IVA", name: "precio_litro_sin_iva", value: datoslista.precio_litro_sin_iva || 0 },
        //           { label: "Precio litro con IVA", name: "precio_litro_con_iva", value: datoslista.precio_litro_con_iva || 0 },
        //           { label: "Precio kilo sin IVA", name: "precio_kilo_sin_iva", value: datoslista.precio_kilo_sin_iva || 0 },
        //           { label: "Precio kilo con IVA", name: "precio_kilo_con_iva", value: datoslista.precio_kilo_con_iva || 0 },
        //         ].map((item, index) => (
        //           <Grid item xs={12} sm={6} md={2} key={index}>
        //             <TextField
        //               fullWidth
        //               style={style}
        //               focused
        //               size="small"
        //               variant="outlined"
        //               label={item.label}
        //               name={item.name}
        //               value={item.value}
        //               onChange={almacenar}
        //               InputProps={{ startAdornment: <InputAdornment position="start"><AttachMoneyIcon /></InputAdornment> }}
        //             />
        //           </Grid>
        //         ))}
        //         {/* Fecha Minima */}
        //         <Grid item xs={12} sm={6} md={2}>
        //           <TextField
        //             fullWidth
        //             style={style}
        //             focused
        //             size="small"
        //             variant="outlined"
        //             label="Fecha de entrada en vigencia"
        //             type="date"

        //             name="vigencia"
        //             value={datoslista.vigencia || ""}
        //             onChange={almacenar}
        //           //   InputProps={{
        //           //     inputProps: {
        //           //         min: vigenciaFormatted
        //           //     }
        //           // }}
        //           />
        //         </Grid>
        //         <Grid item xs={12} sm={6} md={2}>
        //           <TextField
        //             fullWidth
        //             style={style}
        //             focused
        //             size="small"
        //             variant="outlined"
        //             label="Fin vigencia"
        //             type="date"
        //             inputProps={{ min:moment( datosExisten[0].vigencia).format("YYYY-MM-DD") }}
        //             name="fin_vigencia"
        //             value={datoslista.fin_vigencia || ""}
        //             onChange={almacenar}
        //           //   InputProps={{
        //           //     inputProps: {
        //           //         min: vigenciaFormatted
        //           //     }
        //           // }}
        //           />
        //         </Grid>
        //       </Grid>
        //     </CardContent>
        //   </Card>
        // </Box>
      )}
    </>
  );
}


export default Actualizar_precio;
//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio / Nicolas pascucci
// Creation Date :19/06/2024
// Version : 1
// Description : Actualizar precio
//------Changes ----------------------------------------------------------//
// Change Date : 15/8
// Change by : Candela Grosso
// Change description: Saque lso console.log y acmode los tityulos porque quedaban muuy arriba de la pantalla
// Change Date :06/09/2024
// Change by :Candela GRosso
// Change description: modi fique esta pantalla para que se vea cuando se actualiza un precio
//========================================================================/