import Navbar from "../Navbar";
import React, { useEffect, useState, useMemo } from "react";
import { UrlApi } from "../../services/apirest";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Box,
  Typography,
  Stack,
  Grid,
  Tooltip,
  TextField,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import moment from "moment";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// COMPONENTES
import Romaneo_envases_carga_30 from "./Romaneo_envases_carga_30";
import Romaneo_envases_carga_45 from "./Romaneo_envases_carga_45";
import Romaneo_envases_carga_destruido from "./Romaneo_envases_carga_destruido";
import Romaneo_repetido from "./Romaneo_repetido";

// CHECKBOX
import FormControlLabel from "@mui/material/FormControlLabel";

// SWITCH
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

// Notification
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

// ICONOS
import ArticleIcon from "@mui/icons-material/Article";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import EditIcon from "@mui/icons-material/Edit";
import BuildIcon from "@mui/icons-material/Build";
import DeleteIcon from "@mui/icons-material/Delete";
import BadgeIcon from "@mui/icons-material/Badge";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import NumbersIcon from "@mui/icons-material/Numbers";
import PropaneTankIcon from "@mui/icons-material/PropaneTank";
import PinIcon from "@mui/icons-material/Pin";
import RateReviewIcon from "@mui/icons-material/RateReview";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Romaneo_envases_carga() {
  const [romaneo_envases, setRomaneo_envases] = useState([]);
  const [datosRomaneoEnvases, setDatosRomaneoEnvases] = useState({
    id_tipo_reparacion: null,
    id_tipo: null,
    nro_envase: null,
    id_mat_fabricante: null,
    lote: "",
    fecha_fabricacion: "",
    placa_relieve: 2,
    nro_placa: null,
    nro_placa_rect: null,
    ley_placa_rect: null,
    nro_acuniado: null,
    id_mot_baja: null,
    fecha_reparacion: "",
    id_pertenencia: "",
    id_pertenencia2: null,
    id_firma: null,
    rebalanceo: null,
    id_estado: null,
    id_envase: null,
    peso: null,
    usuario: localStorage.getItem("user"),
  });

  console.log("DATOS NO SE QUE ", datosRomaneoEnvases);
  const [tabla, setTabla] = useState(true);
  const [update, setUpdate] = useState(false);
  const [matriculaSeleccionada, setMatriculaSeleccionada] = useState({});
  const [reparacionSeleccionada, setReparacionSeleccionada] = useState({});
  const [tipoEnvaseSeleccionado, setTipoEnvaseSeleccionado] = useState({});
  const [firmaSeleccionado, setFirmaSeleccionado] = useState({});
  const [leyendaSeleccionado, setLeyendaSeleccionado] = useState({});
  const [disBtn, setDisBtn] = useState(false);

  // DIALOG PARA CREAR LOS DATOS
  const [openCrear, setOpenCrear] = React.useState(false);
  const [openCrear45, setOpenCrear45] = React.useState(false);
  const [openCrearDestruido, setOpenCrearDestruido] = React.useState(false);

  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openSnackEditar, setOpenSnackEditar] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");

  // -------------------------- SWITCHS -------------------------------------

  // NUMERO DE ACUÑADO
  const [valorAcuniado, setValorAcuniado] = useState();

  const obtenerValorAcuniado = () => {
    fetch(UrlApi + "/romaneo_envases/valoracuniado")
      .then((data) => data.json())
      .then((data) => setValorAcuniado(data));
  };

  console.log("valor acuñado maximo:", valorAcuniado);

  // -----------------------------------------------------------------------------

  // REBALANCEO
  const [checkSwitchRebalanceo, setCheckSwitchRebalanceo] = useState(
    datosRomaneoEnvases.rebalanceo === 1 ? true : false
  );

  const handleOnChangeSwitchRebalanceo = (e) => {
    setCheckSwitchRebalanceo(e.target.checked);
    obtenerValorAcuniado();
  };

  console.log("checkSwitchRebalanceo", checkSwitchRebalanceo);

  // -----------------------------------------------------------------------------

  // PLACA
  const [checkSwitchPlaca, setCheckSwitchPlaca] = useState(
    datosRomaneoEnvases.placa_relieve === 2 ? true : false
  );

  const handleOnChangeSwitchPlaca = (e) => {
    setCheckSwitchPlaca(e.target.checked);
  };

  console.log("checkSwitchPlaca", checkSwitchPlaca);

  // -----------------------------------------------------------------------------

  // ACUÑADO
  const [checkSwitchAcuniado, setCheckSwitchAcuniado] = useState(
    datosRomaneoEnvases.acuniado === 1 ? true : false
  );

  const handleOnChangeSwitchAcuniado = (e) => {
    setCheckSwitchAcuniado(e.target.checked);
  };

  useEffect(() => {
    datosRomaneoEnvases.nro_envase = null;
  }, [checkSwitchAcuniado]);

  useEffect(() => {
    datosRomaneoEnvases.nro_acuniado = null;
  }, [!checkSwitchAcuniado]);

  console.log("checkSwitchAcuniado", checkSwitchAcuniado);

  // -----------------------------------------------------------------------------

  // CASQUETE
  const [checkSwitchCasquete, setCheckSwitchCasquete] = useState(
    datosRomaneoEnvases.cambio_casquete === 1 ? true : false
  );

  const handleOnChangeSwitchCasquete = (e) => {
    setCheckSwitchCasquete(e.target.checked);
  };

  console.log("checkSwitchCasquete", checkSwitchCasquete);

  // ---------------------------------------------------------------

  //funcion para obtener a los romaneo leyendas, pasarlos a json y guardarlos en data
  const obtenerRomaneoEnvases = () => {
    fetch(UrlApi + "/romaneo_envases/carga")
      .then((data) => data.json())
      .then((data) => setRomaneo_envases(data));
  };

  // ---------------------------------------------------------------

  const [firmas, setFirmas] = useState();

  //funcion para obtener a los romaneo leyendas, pasarlos a json y guardarlos en data
  const obtenerFirmas = () => {
    fetch(UrlApi + "/romaneo_pertenencias/agrupados")
      .then((data) => data.json())
      .then((data) => setFirmas(data));
  };

  console.log("trae las firmas", firmas);

  useEffect(() => {
    obtenerFirmas();
  }, [openCrear]);

  useEffect(() => {
    obtenerFirmas();
  }, [openCrear45]);
  // console.log("romaneo_pertenencias", romaneo_pertenencias);
  // ---------------------------------------------------------------

  //funcion que espera los cambios de la funcion de obtener a los romaneo leyendas
  useEffect(() => {
    obtenerRomaneoEnvases();
  }, [tabla]);

  // FUNCION QUE SE EJECUTA CADA VEZ QUE HAY UN CAMBIO EN LA BASE DE DATOS
  // SIRVE PARA QUE LA TABLA MOSTRADA SE ACTUALICE EN TIEMPO REAL
  useEffect(() => {
    if (update) obtenerRomaneoEnvases();
  }, [update]);

  //*************************************************************************************************************************************/
  //funcion para obtener a los romaneo descripcion_reparacion, pasarlos a json y guardarlos en data

  const [descripcion_reparacion, setDescripcion_reparacion] = useState();

  const obtenerDescripcionReparacion = () => {
    fetch(UrlApi + "/romaneo_reparaciones")
      .then((data) => data.json())
      .then((data) => setDescripcion_reparacion(data));
  };
  // console.log("trae la descripcion de la reparacion", descripcion_reparacion);

  //funcion que espera los cambios de la funcion de obtener a los romaneo descripcion_reparacion
  useEffect(() => {
    obtenerDescripcionReparacion();
  }, []);

  //*************************************************************************************************************************************/
  //funcion para obtener a los romaneo descripcion_tipo_envase, pasarlos a json y guardarlos en data

  const [romaneo_tipo_envase, setRomaneo_Tipo_Envase] = useState();

  const obtenerDescripcionTipoEnvase = () => {
    fetch(UrlApi + "/romaneo_tipos_envases/menos45")
      .then((data) => data.json())
      .then((data) => setRomaneo_Tipo_Envase(data));
  };
  console.log("trae la descripcion de tipo envase", romaneo_tipo_envase);

  // OBTENGO DESCRIPCIONES AL RECARGAR LA CARGA DE ENVASES DE HASTA 30
  useEffect(() => {
    obtenerDescripcionTipoEnvase();
  }, [openCrear]);

  //*************************************************************************************************************************************/
  //funcion para obtener a los romaneo descripcion_tipo_envase, pasarlos a json y guardarlos en data

  const [romaneo_tipo_envase45, setRomaneo_Tipo_Envase45] = useState();

  const obtenerDescripcionTipoEnvase45 = () => {
    fetch(UrlApi + "/romaneo_tipos_envases/45")
      .then((data) => data.json())
      .then((data) => setRomaneo_Tipo_Envase45(data));
  };
  console.log("trae la descripcion de tipo envase", romaneo_tipo_envase45);

  //funcion que espera los cambios de la funcion de obtener a los romaneo descripcion_tipo_envase
  useEffect(() => {
    obtenerDescripcionTipoEnvase45();
  }, []);

  //*************************************************************************************************************************************/
  //funcion para obtener a los romaneo leyendas, pasarlos a json y guardarlos en data

  const [mat_fabricante, setMat_fabricante] = useState();

  const obtenerMatFabricante = () => {
    fetch(UrlApi + "/romaneo_fabricantes")
      .then((data) => data.json())
      .then((data) => setMat_fabricante(data));
  };
  // console.log("trae matricula de fabricante", mat_fabricante);

  //funcion que espera los cambios de la funcion de obtener a los romaneo leyendas
  useEffect(() => {
    obtenerMatFabricante();
  }, []);

  //*************************************************************************************************************************************/

  //funcion para obtener a los romaneo leyendas, pasarlos a json y guardarlos en data

  const [descripcion_motivo, setDescripcion_motivo] = useState();

  const obtenerDescripcionMotivo = () => {
    fetch(UrlApi + "/romaneo_motivos_baja")
      .then((data) => data.json())
      .then((data) => setDescripcion_motivo(data));
  };
  // console.log("trae descripcion motivo", descripcion_motivo);

  //funcion que espera los cambios de la funcion de obtener a los romaneo leyendas
  useEffect(() => {
    obtenerDescripcionMotivo();
  }, []);

  //*************************************************************************************************************************************/

  const [envaseSeleccionado, setEnvaseSeleccionado] = useState({});

  const [envaseYpeso, setEnvaseyPeso] = useState(null);

  const obtenerEnvaseYpeso = async () => {
    const data = await fetch(
      UrlApi + "/romaneo_tipos_envases/envaseXID/" + datosRomaneoEnvases.id_tipo
    );
    const EyPData = await data.json();
    setEnvaseyPeso(EyPData);
  };
  console.log("envase y peso dentro del obtener 1", envaseYpeso);
  // console.log("envase y peso dentro del obtener 2", envaseYpeso.id_tipo);
  // console.log("ARANDANO:", envaseYpeso.peso);

  //obtenemos los pesos y envases UNA VEZ que elegimos el envase seleccionado
  useEffect(() => {
    envaseSeleccionado && obtenerEnvaseYpeso();
  }, [envaseSeleccionado]);

  //*************************************************************************************************************************************/
  const [estadoEnvase, setEstadoEnvase] = useState(null);

  //obtener los tipos de moneda de la tabla desplegables
  const obtenerEstadoEnvase = () => {
    fetch(
      UrlApi + "/desplegables/estadoEnvases/" + datosRomaneoEnvases.id_estado
    )
      .then((data) => data.json())
      .then((data) => setEstadoEnvase(data));
  };
  useEffect(() => {
    obtenerEstadoEnvase();
  }, []);

  console.log("estado de envase", estadoEnvase);

  //*************************************************************************************************************************************/
  const [leyendaXid, setLeyendaXid] = useState(null);

  //obtener las leyendas segun la firma seleccionada
  const obtenerLeyendaXID = () => {
    fetch(
      UrlApi +
        "/romaneo_pertenencias/traeLeyendaxID/" +
        firmaSeleccionado.id_firma
    )
      .then((data) => data.json())
      .then((data) => setLeyendaXid(data));
  };

  useEffect(() => {
    obtenerLeyendaXID();
  }, [firmaSeleccionado.id_firma]);

  console.log("Leyenda x id", leyendaXid);

  //*************************************************************************************************************************************/

  // OBTENER FECHA ACTUAL
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);

  //*************************************************************************************************************************************/

  // REPETICION: PARA VERIFICAR SI AL CARGAR SE ESTÁ REPITIENDO UN DATO

  const [repeticion, setRepeticion] = useState(null); //Guardo el que esté repetido (en caso de que exista)
  //Funcion con la que consulto a la api si ya existe un envase con X firma, X  y X nro de envase, para no cargar repetidos

  const obtenerRepeticion = () => {
    fetch(
      UrlApi +
        "/romaneo_envases/envases_repetidos/" +
        tipoEnvaseSeleccionado.id_tipo +
        "/" +
        firmaSeleccionado.id_firma +
        "/" +
        leyendaSeleccionado.id_pertenencia +
        "/" +
        datosRomaneoEnvases.nro_envase
    )
      .then((data) => data.json())
      .then((data) => setRepeticion(data));
  };

  // Ejecuto la funcion escuchando al proveedor seleccionado , al tipo de comprobante y al numero de comprobante
  // para que haga la busqueda cada vez que elija un proveedor (o lo cambie), y tipee un numero de comprobante

  useEffect(() => {
    firmas && obtenerRepeticion();
  }, [
    tipoEnvaseSeleccionado.id_tipo,
    firmaSeleccionado.id_firma,
    leyendaSeleccionado && leyendaSeleccionado.id_pertenencia,
    datosRomaneoEnvases.nro_envase,
  ]);

  // repeticion && console.log("repeticion", repeticion);
  const [modalRepetido, setModalRepetido] = useState();

  // escuchando a la repeticion, pregunto si lo que me traje de la api tiene longitud > 0
  // (o sea, si hay algo en repeticion)

  useEffect(() => {
    if (repeticion && repeticion.length > 0 && !openEditar) {
      setModalRepetido(true);
      setDatosRomaneoEnvases({
        ...datosRomaneoEnvases,
        ["nro_envase"]: "",
      });
    }
  }, [repeticion, firmas]);

  //*************************************************************************************************************************************/

  // Define una función que se ejecutará antes de cerrar el componente hijo
  function actualizarComponentePrincipal() {
    // Realiza las acciones necesarias para actualizar el componente principal
    // Puedes establecer el estado 'update' como true
    setUpdate(true);
  }

  //*************************************************************************************************************************************/

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  //*************************************************************************************************************************************/

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  //*************************************************************************************************************************************/
  //  DIALOG PARA MENU DE CARGA
  const [openDialogCarga, setOpenDialogCarga] = React.useState(false);

  const handleCloseDialogCarga = () => {
    setOpenDialogCarga(!openDialogCarga);
  };

  const handleOpenDialogCarga = () => {
    setOpenDialogCarga(true);
  };
  //*************************************************************************************************************************************/

  // DIALOG PARA CREAR ENVASES (HASTA 30 KG)
  // USANDO ROW TOMO LOS DATOS DE ESA FILA
  const handleClickOpenCrear = () => {
    setOpenCrear(true);
    setOpenDialogCarga(false);
    setCheckSwitchPlaca(false); // para desactivar el switch cada vez que abre el dialog
    datosRomaneoEnvases.id_tipo_reparacion = null;
    // AL ABRIR EL DIALOG LOS TEXTFIELDS SE VACIAN
    reparacionSeleccionada.id_tipo_reparacion = null;
    tipoEnvaseSeleccionado.id_tipo = null;
    leyendaSeleccionado.leyenda = null;
    firmaSeleccionado.id_firma = null;
    leyendaSeleccionado.id_pertenencia = null;
    leyendaSeleccionado.id_pertenencia2 = null;
    datosRomaneoEnvases.nro_envase = null;
    matriculaSeleccionada.id_mat_fabricante = null;
    datosRomaneoEnvases.lote = "001";
    datosRomaneoEnvases.fecha_fabricacion = "";
    datosRomaneoEnvases.placa_relieve = 1;
    datosRomaneoEnvases.nro_placa = null;
    datosRomaneoEnvases.nro_placa_rect = null;
    datosRomaneoEnvases.ley_placa_rect = null;
    datosRomaneoEnvases.nro_acuniado = null;
    datosRomaneoEnvases.id_mot_baja = null;
    datosRomaneoEnvases.fecha_reparacion = date;
    datosRomaneoEnvases.rebalanceo = null;
    datosRomaneoEnvases.id_estado = null;
  };

  const handleCloseCrear = () => {
    setOpenCrear(false);
    obtenerRomaneoEnvases();
  };

  //*************************************************************************************************************************************/

  // DIALOG PARA CREAR LOS DATOS
  // USANDO ROW TOMO LOS DATOS DE ESA FILA
  const handleClickOpenCrear45 = (row) => {
    setOpenCrear45(true);
    setOpenDialogCarga(false);
    setCheckSwitchPlaca(false); // para apgar el switch cada vez que abre el dialog
    setCheckSwitchRebalanceo(false);
    setCheckSwitchCasquete(false);

    // AL ABRIR EL DIALOG LOS TEXTFIELDS SE VACIAN
    datosRomaneoEnvases.id_tipo_reparacion = null;
    datosRomaneoEnvases.id_tipo = null;
    leyendaSeleccionado.leyenda = null;
    firmaSeleccionado.id_firma = null;

    datosRomaneoEnvases.id_pertenencia = null;
    datosRomaneoEnvases.id_pertenencia2 = null;
    datosRomaneoEnvases.nro_envase = null;
    matriculaSeleccionada.id_mat_fabricante = null;
    datosRomaneoEnvases.lote = "001";
    datosRomaneoEnvases.fecha_fabricacion = "";
    datosRomaneoEnvases.placa_relieve = 1;
    datosRomaneoEnvases.nro_placa = null;
    datosRomaneoEnvases.nro_placa_rect = null;
    datosRomaneoEnvases.ley_placa_rect = null;
    datosRomaneoEnvases.nro_acuniado = null;
    datosRomaneoEnvases.id_mot_baja = null;
    datosRomaneoEnvases.fecha_reparacion = date;
    datosRomaneoEnvases.rebalanceo = null;
    datosRomaneoEnvases.id_estado = null;
  };

  const handleCloseCrear45 = () => {
    setOpenCrear45(false);
    setCheckSwitchRebalanceo(false);
    datosRomaneoEnvases.rebalanceo = null;
    setCheckSwitchPlaca(false);
    datosRomaneoEnvases.placa_relieve = 1;
    setCheckSwitchAcuniado(false);
    setCheckSwitchCasquete(false);
    obtenerRomaneoEnvases();
    // setUpdate(true)
  };

  //*************************************************************************************************************************************/

  // DIALOG PARA DESTRUIR
  const [openDestruir, setOpenDestruir] = React.useState(false);

  // USANDO ROW TOMO LOS DATOS DE ESA FILA
  const handleClickOpenDestruir = (row) => {
    setOpenDestruir(true);
    setOpenDialogCarga(false);
    setCheckSwitchPlaca(false); // para apgar el switch cada vez que abre el dialog
    // AL ABRIR EL DIALOG LOS TEXTFIELDS SE VACIAN
    datosRomaneoEnvases.id_tipo_reparacion = null;
    datosRomaneoEnvases.id_tipo_reparacion = null;
    datosRomaneoEnvases.id_tipo = null;
    datosRomaneoEnvases.leyenda = null;
    datosRomaneoEnvases.id_firma = null;
    datosRomaneoEnvases.id_pertenencia = null;
    datosRomaneoEnvases.nro_envase = null;
    matriculaSeleccionada.id_mat_fabricante = null;
    datosRomaneoEnvases.lote = "";
    datosRomaneoEnvases.fecha_fabricacion = "";
    datosRomaneoEnvases.placa_relieve = 1;
    datosRomaneoEnvases.nro_placa = null;
    datosRomaneoEnvases.id_mot_baja = null;
    datosRomaneoEnvases.fecha_reparacion = date;
    datosRomaneoEnvases.rebalanceo = null;
    datosRomaneoEnvases.id_estado = null;
  };

  const handleCloseDestruir = () => {
    setOpenDestruir(false);
  };

  //*************************************************************************************************************************************/
  // DIALOG PARA EDITAR LOS DATOS
  const [openEditar, setOpenEditar] = React.useState(false);

  // USANDO ROW TOMO LOS DATOS DE ESA FILA
  const handleClickOpenEditar = (row) => {
    setOpenEditar(true);
    setDatosRomaneoEnvases(row);
    setCheckSwitchPlaca(row.placa_relieve === 2 ? true : false);
    setCheckSwitchRebalanceo(row.rebalanceo === 1 ? true : false);
    setCheckSwitchCasquete(row.cambio_casquete === 1 ? true : false);
  };

  const handleCloseEditar = (row) => {
    setOpenEditar(false);
    setDatosRomaneoEnvases(row);
    setCambioFirma(false);
  };

  //*************************************************************************************************************************************/

  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
    setUpdate(false);
    setLimpiarCrear30(false);
    setLimpiarCrear45(false);
    setLimpiarDestruir(false);
    setLimpiarMatricula(false);
    setLimpiarTipoReparacion(false);
    setLimpiarTipoEnvase(false);
    setLimpiarFirma(false);
    setDisBtn(false);
  };

  // EDITAR
  const handleCloseSnackEditar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackEditar(false);
    setUpdate(false);
    setOpenEditar(false);
  };

  //*************************************************************************************************************************************/

  const columns = useMemo(
    () => [
      { header: "N°.Envase", accessorKey: "nro_envase", size: 85 },
      { header: "N°.Acuñado", accessorKey: "nro_acuniado", size: 85 },
      {
        header: "F.Reparación",
        accessorKey: "fecha_reparacion",
        size: 85,
        type: "date",
        format: "DD-MM-YYYY",
      },
      {
        header: "Reparación",
        accessorKey: "descripcion_reparacion",
        size: 114,
      },
      {
        header: "Tipo de Envase",
        accessorKey: "descripcion_tipo_envase",
        size: 168,
      },
      {
        header: "Fabricante",
        accessorKey: "descripcion_fabricante",
        size: 109,
      },
      { header: "Leyenda", accessorKey: "leyenda", size: 105 },
      { header: "Firma", accessorKey: "firma", size: 116 },
      { header: "Peso", accessorKey: "peso", size: 50 },
    ],
    []
  );

  const muiSearchTextFieldProps = {
    // para el buscar
    placeholder: "Buscar...",
    sx: { minWidth: "18rem" },
    variant: "outlined",
    color: "success",
    size: "small",
  };

  //*************************************************************************************************************************************/

  //Funcion para guardar AL EDITAR
  const guardarEditar = async (e) => {
    console.log("Estoy por guardar");
    setDisBtn(true);
    e.preventDefault();
    await fetch(UrlApi + "/romaneo_envases/" + datosRomaneoEnvases.id_envase, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_tipo_reparacion: datosRomaneoEnvases.id_tipo_reparacion,
        id_tipo: datosRomaneoEnvases.id_tipo,
        nro_envase: datosRomaneoEnvases.nro_envase,
        id_mat_fabricante: datosRomaneoEnvases.id_mat_fabricante,
        lote: datosRomaneoEnvases.lote,
        fecha_fabricacion: datosRomaneoEnvases.fecha_fabricacion,
        placa_relieve: checkSwitchPlaca === true ? 2 : 1,
        nro_placa: datosRomaneoEnvases.nro_placa,
        cambio_casquete: checkSwitchCasquete === true ? 1 : null,
        id_mot_baja: datosRomaneoEnvases.id_mot_baja,
        fecha_reparacion: moment(datosRomaneoEnvases.fecha_reparacion).format(
          "YYYY-MM-DD"
        ),
        id_estado: checkSwitchRebalanceo === true ? null : 151,
        rebalanceo: checkSwitchRebalanceo === true ? 1 : 0,
        id_pertenencia: datosRomaneoEnvases.id_pertenencia,
        id_firma: datosRomaneoEnvases.id_firma,
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnackEditar(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          setUpdate(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El envase se editó exitosamente !");
          console.log("Guardado");
        } else {
          setOpenSnackEditar(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al editar el envase");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  //*************************************************************************************************************************************/
  const almacenar = (e) => {
    setDatosRomaneoEnvases({
      ...datosRomaneoEnvases,
      [e.target.name]: e.target.value,
    });
  };

  const [cambioFirma, setCambioFirma] = useState(false);

  const almacenarFirmaEditar = (e) => {
    setDatosRomaneoEnvases({
      ...datosRomaneoEnvases,
      [e.target.name]: e.target.value,
    });
    setCambioFirma(true);
  };

  console.log("CAMBIO FIRMA:", cambioFirma);

  //*************************************************************************************************************************************/

  // ESTILO PARA LA TABLA

  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)" //background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //override to make tooltip font size larger
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const displayColumnDefOptions = {
    "mrt-row-actions": {
      muiTableHeadCellProps: {
        align: "center",
        sx: {
          fontSize: 14,
          fontStyle: "italic",
        },
      },
      size: 90,
    },
  };

  const initialState = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    sorting: [
      {
        id: "fecha_reparacion", //fija que la fecha de reparacion sea desde hoy para atras
        desc: true,
      },
    ],
    pagination: { pageIndex: 0, pageSize: 10 },
  };

  // PARA BORRAR EL VALOR DEL SELECT DE PERTENENCIAS AL CAMBIAR LA FIRMA
  useEffect(() => {
    datosRomaneoEnvases.id_pertenencia = "";
  }, [cambioFirma]);

  // ---------------------------------------------------------------------
  // ------------USE EFFECTS PARA LIMPIAR LOS DATOS AL GUARDAR------------

  // CREAR 30
  const [limpiarCrear30, setLimpiarCrear30] = useState(false);

  useEffect(() => {
    datosRomaneoEnvases.id_tipo = null;
    datosRomaneoEnvases.nro_envase = "";
    setEnvaseSeleccionado({});
  }, [limpiarCrear30]);

  // CREAR 45
  const [limpiarCrear45, setLimpiarCrear45] = useState(false);

  useEffect(() => {
    setCheckSwitchRebalanceo(false);
    setCheckSwitchPlaca(false);
    setCheckSwitchCasquete(false);
    obtenerDescripcionTipoEnvase45();
    datosRomaneoEnvases.rebalanceo = null;
    datosRomaneoEnvases.id_tipo = null;
    datosRomaneoEnvases.nro_envase = "";
  }, [limpiarCrear45]);

  // DESTRUIR
  const [limpiarDestruir, setLimpiarDestruir] = useState(false);

  useEffect(() => {
    datosRomaneoEnvases.nro_envase = "";
  }, [limpiarDestruir]);

  // MATRICULA
  const [limpiarMatricula, setLimpiarMatricula] = useState(false);

  useEffect(() => {
    datosRomaneoEnvases.id_mat_fabricante = null;
  }, [limpiarMatricula]);

  // TIPO DE REPARACIÓN
  const [limpiarTipoReparacion, setLimpiarTipoReparacion] = useState(false);

  useEffect(() => {
    datosRomaneoEnvases.id_tipo_reparacion = null;
  }, [limpiarTipoReparacion]);

  // TIPO DE ENVASE
  const [limpiarTipoEnvase, setLimpiarTipoEnvase] = useState(false);

  useEffect(() => {
    datosRomaneoEnvases.id_tipo = null;
  }, [limpiarTipoEnvase]);

  // FIRMA
  const [limpiarFirma, setLimpiarFirma] = useState(false);

  useEffect(() => {
    datosRomaneoEnvases.id_firma = null;
  }, [limpiarFirma]);

  // ------------------------------------------------------------

  useEffect(() => {
    setCheckSwitchPlaca(true);
  }, [checkSwitchRebalanceo]);

  // ------------------------------------------------------------

  console.log("EDITAR:", datosRomaneoEnvases);
  console.log("ID ARANDA:", datosRomaneoEnvases.id_tipo);
  console.log("ID ARANDA 2:", datosRomaneoEnvases.peso);
  console.log("UPDATE COMPONENTE PRINCIPAL:", update);

  return (
    <div>
      <Navbar />
      <Container maxWidth="xl" mt={1}>
        {romaneo_envases && tabla ? (
          <Box mt={2} mb={4}>
            <ThemeProvider theme={tableTheme}>
              {update ? (
                <MaterialReactTable state={{ isLoading: true }} />
              ) : (
                <MaterialReactTable
                  enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
                  enableGrouping // para permitir agrupar x estado x ej
                  displayColumnDefOptions={displayColumnDefOptions}
                  layoutMode="grid"
                  muiTableHeadCellProps={{
                    align: "left", // los titulos de la tabla
                  }}
                  muiTableBodyCellProps={{
                    align: "left", //el contenido de la tabla
                  }}
                  enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
                  enableColumnFilters={false} //desactiva filtros x  columna
                  enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
                  enableDensityToggle={false} //
                  localization={MRT_Localization_ES} //idioma
                  enableColumnDragging={false} //para arrastrar columnas
                  initialState={initialState}
                  muiSearchTextFieldProps={muiSearchTextFieldProps}
                  columns={columns}
                  data={romaneo_envases} //con lo q la completas (esto cambia siempre)
                  enableStickyFooter
                  enableEditing
                  positionActionsColumn="last" // posicion de la columna de acciones al final
                  // ACCIONES
                  renderToolbarInternalActions={(
                    { table } // boton para nuevo , al lado del buscar
                  ) => (
                    <>
                      <MRT_FullScreenToggleButton table={table} />
                    </>
                  )}
                  renderTopToolbarCustomActions={() => (
                    <>
                      <Typography variant="poster" fontSize={26}>
                        Administrar Envases y Cilindros
                      </Typography>

                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddBoxTwoToneIcon />}
                        onClick={handleOpenDialogCarga}
                        size="small"
                      >
                        CARGAR ENVASE o CILINDRO
                      </Button>
                    </>
                  )}
                  //es el panel de expansion del costado
                  // PANEL DE DETALLES / FICHA
                  renderDetailPanel={({ row }) => (
                    <>
                      <Container maxWidth="xl">
                        <br />
                        <Box
                          sx={{
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: "100%",
                          }}
                        >
                          <DialogTitle
                            style={{
                              color: "white",
                              backgroundColor: "#4abced",
                              height: "10px",
                              marginTop: "-30px",
                              width: "110%",
                            }}
                          >
                            <Grid mt={-1.5}>
                              ID DEL ENVASE: {row.original.id_envase}
                            </Grid>
                          </DialogTitle>
                          <br />
                          <Box display="flex" width="115%">
                            <Box
                              border={2}
                              px={2}
                              pt={2}
                              borderRadius={4}
                              mt={-2}
                            >
                              <Grid
                                container
                                maxWidth="x1"
                                spacing={1}
                                ml={4.5}
                              >
                                <Grid item xs={2.7} py={1} mb={1}>
                                  <TextField
                                    fullWidth
                                    placeholder="No Tiene"
                                    label="Reparación"
                                    name="descripcion_reparacion"
                                    variant="filled"
                                    size="small"
                                    focused
                                    value={row.original.descripcion_reparacion}
                                    InputProps={{
                                      readOnly: true,
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <BuildIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={3} py={1} mb={1}>
                                  <TextField
                                    fullWidth
                                    placeholder="No Tiene"
                                    label="Motivo de Baja"
                                    name="id_mot_baja"
                                    variant="filled"
                                    size="small"
                                    focused
                                    value={row.original.descripcion_motivo}
                                    InputProps={{
                                      readOnly: true,
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <DeleteIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={4} py={1} mb={1}>
                                  <TextField
                                    fullWidth
                                    placeholder="No Tiene"
                                    label="Tipo de Envase"
                                    name="descripcion_tipo_envase"
                                    variant="filled"
                                    size="small"
                                    focused
                                    value={row.original.descripcion_tipo_envase}
                                    InputProps={{
                                      readOnly: true,
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <PropaneTankIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={1.3} py={1} mb={1}>
                                  <TextField
                                    fullWidth
                                    placeholder="No Tiene"
                                    label="Peso"
                                    name="peso"
                                    variant="filled"
                                    size="small"
                                    focused
                                    value={row.original.peso}
                                    InputProps={{
                                      readOnly: true,
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          Kg
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={2} py={1} mb={1}>
                                  <TextField
                                    fullWidth
                                    placeholder="No Tiene"
                                    label="Nro. Envase"
                                    name="nro_envase"
                                    variant="filled"
                                    size="small"
                                    focused
                                    value={row.original.nro_envase}
                                    InputProps={{
                                      readOnly: true,
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <NumbersIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={2} py={1} mb={1}>
                                  <TextField
                                    fullWidth
                                    placeholder="No Tiene"
                                    label="Nro. Acuñado"
                                    name="nro_acuniado"
                                    variant="filled"
                                    size="small"
                                    focused
                                    value={row.original.nro_acuniado}
                                    InputProps={{
                                      readOnly: true,
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <PinIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={4} py={1} mb={1}>
                                  <TextField
                                    fullWidth
                                    placeholder="No Tiene"
                                    label="Matrícula del Fabricante"
                                    name="id_mat_fabricante"
                                    variant="filled"
                                    size="small"
                                    focused
                                    value={row.original.descripcion_fabricante}
                                    InputProps={{
                                      readOnly: true,
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <BadgeIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={1} py={1} mb={1}>
                                  <TextField
                                    fullWidth
                                    placeholder="No Tiene"
                                    label="Lote"
                                    name="lote"
                                    variant="filled"
                                    size="small"
                                    focused
                                    value={row.original.lote}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={2} py={1} mb={1}>
                                  <TextField
                                    fullWidth
                                    placeholder="No Tiene"
                                    label="Fecha de Fabricacion"
                                    name="fecha_fabricacion"
                                    variant="filled"
                                    size="small"
                                    focused
                                    value={row.original.fecha_fabricacion}
                                    InputProps={{
                                      readOnly: true,
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <CalendarMonthIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={2} py={1} mb={1}>
                                  <TextField
                                    fullWidth
                                    placeholder="No Tiene"
                                    label="Fecha de Reparación"
                                    name="fecha_reparacion"
                                    variant="filled"
                                    size="small"
                                    focused
                                    value={row.original.fecha_reparacion}
                                    InputProps={{
                                      readOnly: true,
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <CalendarMonthIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={2.5} py={1} mb={1}>
                                  <TextField
                                    fullWidth
                                    placeholder="No Tiene"
                                    label="Firma"
                                    name="firma"
                                    variant="filled"
                                    size="small"
                                    focused
                                    value={row.original.firma}
                                    InputProps={{
                                      readOnly: true,
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <RateReviewIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={3.25} py={1} mb={1}>
                                  <TextField
                                    fullWidth
                                    placeholder="No Tiene"
                                    label="Leyenda / Leyenda Chapa Circular"
                                    name="leyenda"
                                    variant="filled"
                                    size="small"
                                    focused
                                    value={row.original.leyenda}
                                    InputProps={{
                                      readOnly: true,
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <ArticleIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={3.25} py={1} mb={1}>
                                  <TextField
                                    fullWidth
                                    placeholder="No Tiene"
                                    label="Leyenda Chapa Rectangular"
                                    name="ley_placa_rect"
                                    variant="filled"
                                    size="small"
                                    focused
                                    value={row.original.ley_placa_rect}
                                    InputProps={{
                                      readOnly: true,
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <ArticleIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={2.5} py={1} mb={1}>
                                  <TextField
                                    fullWidth
                                    placeholder="No Tiene"
                                    label="Número Chapa Rectangular"
                                    name="nro_placa_rect"
                                    variant="filled"
                                    size="small"
                                    focused
                                    value={row.original.nro_placa_rect}
                                    InputProps={{
                                      readOnly: true,
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <ArticleIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={2.5} py={1} mb={1}>
                                  <TextField
                                    fullWidth
                                    placeholder="No hay placa"
                                    label="Número de Placa / de Chapa Circular"
                                    name="nro_placa"
                                    variant="filled"
                                    size="small"
                                    focused
                                    value={row.original.nro_placa}
                                    InputProps={{
                                      readOnly: true,
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <PinIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={2.75} py={1} mb={1}>
                                  {row.original.cambio_casquete === 1 ? (
                                    <TextField
                                      fullWidth
                                      placeholder="Sin Cambio"
                                      label="Cambio de Casquete"
                                      name="cambio_casquete"
                                      variant="filled"
                                      size="small"
                                      focused
                                      value="Si"
                                      InputProps={{
                                        readOnly: true,
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <PinIcon />
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  ) : (
                                    <TextField
                                      fullWidth
                                      placeholder="Sin Cambio"
                                      label="Cambio de Casquete"
                                      name="cambio_casquete"
                                      variant="filled"
                                      size="small"
                                      focused
                                      value={row.original.cambio_casquete}
                                      InputProps={{
                                        readOnly: true,
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <PinIcon />
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  )}
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Box>
                      </Container>
                    </>
                  )}
                  renderRowActions={(
                    { row } //las acciones del costado editar y ver
                  ) => (
                    <div>
                      <Tooltip arrow placement="left" title="Editar Envase">
                        <IconButton
                          onClick={() => handleClickOpenEditar(row.original)}
                          color="primary"
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                />
              )}
            </ThemeProvider>
          </Box>
        ) : null}
      </Container>

      {/* AGREGAR ITEM 10 a 30 kg */}
      <Dialog
        open={openCrear}
        onClose={handleCloseCrear}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <Romaneo_envases_carga_30
          openCrear={openCrear}
          datosRomaneoEnvases={datosRomaneoEnvases}
          romaneo_tipo_envase={romaneo_tipo_envase}
          date={date}
          firmas={firmas}
          mat_fabricante={mat_fabricante}
          estadoEnvase={estadoEnvase}
          leyendaXid={leyendaXid}
          // SELECT AUTOCOMPLETE
          tipoEnvaseSeleccionado={tipoEnvaseSeleccionado}
          firmaSeleccionado={firmaSeleccionado}
          leyendaSeleccionado={leyendaSeleccionado}
          matriculaSeleccionada={matriculaSeleccionada}
          // LIMPIAR
          handleClickOpenCrear={handleClickOpenCrear}
          limpiarCrear30={limpiarCrear30}
          limpiarTipoReparacion={limpiarTipoReparacion}
          limpiarTipoEnvase={limpiarTipoEnvase}
          limpiarFirma={limpiarFirma}
          limpiarMatricula={limpiarMatricula}
          // CHECKS
          checkSwitchPlaca={checkSwitchPlaca}
          // NOTIFICACION
          openSnack={openSnack}
          colormensaje={colormensaje}
          mensaje={mensaje}
          disBtn={disBtn}
          // UPDATE
          updateCallback={actualizarComponentePrincipal}
          // CERRAR
          handleCloseCrear={handleCloseCrear}
          // REPETIDO
          modalRepetido={modalRepetido}
        />
      </Dialog>

      {/* AGREGAR ITEM 45 KG */}
      <Dialog
        open={openCrear45}
        onClose={handleCloseCrear45}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <Romaneo_envases_carga_45
          openCrear45={openCrear45}
          datosRomaneoEnvases={datosRomaneoEnvases}
          romaneo_tipo_envase45={romaneo_tipo_envase45}
          date={date}
          firmas={firmas}
          mat_fabricante={mat_fabricante}
          envaseYpeso={envaseYpeso}
          estadoEnvase={estadoEnvase}
          leyendaXid={leyendaXid}
          // SELECT AUTOCOMPLETE
          firmaSeleccionado={firmaSeleccionado}
          tipoEnvaseSeleccionado={tipoEnvaseSeleccionado}
          leyendaSeleccionado={leyendaSeleccionado}
          matriculaSeleccionada={matriculaSeleccionada}
          envaseSeleccionado={envaseSeleccionado}
          // NOTIFICACION
          openSnack={openSnack}
          colormensaje={colormensaje}
          mensaje={mensaje}
          update={setUpdate}
          disBtn={disBtn}
          // CERRAR
          handleCloseCrear45={handleCloseCrear45}
        />
      </Dialog>

      {/* DIALOG PARA VER LOS BOTONES*/}
      <Dialog open={openDialogCarga} onClose={handleCloseDialogCarga}>
        <Box
          style={{
            borderWidth: 2,
            borderStyle: "solid",
          }}
        >
          <DialogTitle>
            <Typography align="center">
              <h3>CARGAR ENVASE</h3>
            </Typography>
          </DialogTitle>
          <DialogContent style={{ marginTop: "-20px" }}>
            <DialogContentText
              textAlign={"center"}
              style={{ marginBottom: "10px" }}
            >
              <Grid container>
                <Grid item xs={12} textAlign={"center"}>
                  <br />
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    startIcon={<AddBoxTwoToneIcon />}
                    onClick={handleClickOpenCrear}
                  >
                    Cargar (10 a 30 Kg)
                  </Button>
                </Grid>
                <Grid item xs={12} textAlign={"center"} mt={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    startIcon={<AddBoxTwoToneIcon />}
                    onClick={handleClickOpenCrear45}
                  >
                    Cargar (45 Kg)
                  </Button>
                </Grid>
                <Grid item xs={12} textAlign={"center"} mt={2}>
                  <Button
                    variant="contained"
                    color="error"
                    fullWidth
                    startIcon={<DeleteForeverIcon />}
                    onClick={handleClickOpenDestruir}
                  >
                    Destruir
                  </Button>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Stack spacing={2} direction="row">
              <Grid container>
                <Grid item alignSelf={"center"}>
                  <Button
                    style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
                    variant="contained"
                    onClick={handleCloseDialogCarga}
                    fullWidth
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Stack>
          </DialogActions>
        </Box>
      </Dialog>

      {/* AGREGAR ITEM (DESTRUIDO) */}
      <Dialog
        open={openDestruir}
        onClose={handleCloseDestruir}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <Romaneo_envases_carga_destruido
          openDestruir={openDestruir}
          datosRomaneoEnvases={datosRomaneoEnvases}
          mat_fabricante={mat_fabricante}
          // SELECT AUTOCOMPLETE
          matriculaSeleccionada={matriculaSeleccionada}
          limpiarMatricula={limpiarMatricula}
          // NOTIFICACION
          openSnack={openSnack}
          colormensaje={colormensaje}
          mensaje={mensaje}
          disBtn={disBtn}
          // CERRAR
          handleCloseDestruir={handleCloseDestruir}
        />
      </Dialog>

      {/* EDITAR ITEM */}
      <Dialog
        open={openEditar}
        onClose={handleCloseEditar}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle alignSelf="center">
          <b>{"Editar Envase"}</b>
        </DialogTitle>
        {datosRomaneoEnvases.id_estado === 152 ? (
          <DialogTitle alignSelf="center">
            <h1 style={{ color: "green" }}>REHABILITADO</h1>
          </DialogTitle>
        ) : null}
        <DialogContent>
          <Container maxWidth="xl">
            <form onSubmit={guardarEditar}>
              <Grid container mb={1} spacing={1}>
                {datosRomaneoEnvases.peso === 45 ? (
                  <>
                    <Grid item xs={6} mb={2} mt={1}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography>Rehabilitar</Typography>
                        <FormControlLabel
                          checked={checkSwitchRebalanceo}
                          onChange={handleOnChangeSwitchRebalanceo}
                          control={<AntSwitch />}
                          style={{ width: "23px", height: "23px" }}
                        />
                        <Typography>Rebalancear</Typography>
                      </Stack>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12} mb={2} mt={1}>
                    <FormControl style={style} focused>
                      <InputLabel>Tipo de Reparación</InputLabel>
                      <Select
                        value={datosRomaneoEnvases.id_tipo_reparacion}
                        label="Tipo de reparación"
                        size="small"
                        name="id_tipo_reparacion"
                        required
                        onChange={almacenar}
                      >
                        {descripcion_reparacion
                          ? descripcion_reparacion.map((elemento) => (
                              <MenuItem
                                key={elemento.id_reparacion}
                                value={elemento.id_reparacion}
                              >
                                {elemento.descripcion_reparacion}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {datosRomaneoEnvases.peso === 45 ? (
                  <>
                    <Grid item xs={12} mb={2}>
                      <FormControl style={style} focused>
                        <InputLabel>Tipo de Envase</InputLabel>
                        <Select
                          name="id_tipo"
                          required
                          label="Tipo de Envase"
                          size="small"
                          value={datosRomaneoEnvases.id_tipo}
                          onChange={almacenar}
                        >
                          {romaneo_tipo_envase45
                            ? romaneo_tipo_envase45.map((elemento) => (
                                <MenuItem
                                  key={elemento.id_tipo}
                                  value={elemento.id_tipo}
                                >
                                  {elemento.descripcion_tipo_envase}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12} mb={2}>
                      <FormControl style={style} focused>
                        <InputLabel>Tipo de Envase</InputLabel>
                        <Select
                          name="id_tipo"
                          required
                          label="Tipo de Envase"
                          size="small"
                          value={datosRomaneoEnvases.id_tipo}
                          onChange={almacenar}
                        >
                          {romaneo_tipo_envase
                            ? romaneo_tipo_envase.map((elemento) => (
                                <MenuItem
                                  key={elemento.id_tipo}
                                  value={elemento.id_tipo}
                                >
                                  {elemento.descripcion_tipo_envase}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                )}

                <Grid item xs={6} mb={2}>
                  <FormControl style={style} focused>
                    <InputLabel>Firma *</InputLabel>
                    <Select
                      label="Firma *"
                      required
                      size="small"
                      name="id_firma"
                      value={datosRomaneoEnvases.id_firma}
                      onChange={almacenarFirmaEditar}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {firmas
                        ? firmas.map((elemento) => (
                            <MenuItem
                              key={elemento.id_firma}
                              value={elemento.id_firma} // Debe coincidir con el tipo de dato de la tabla a la que inserta
                            >
                              {elemento.firma}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6} mb={2}>
                  <FormControl focused fullWidth>
                    <InputLabel>Leyenda *</InputLabel>
                    <Select
                      label="Leyenda *"
                      required
                      size="small"
                      name="id_pertenencia"
                      onChange={almacenar}
                      value={datosRomaneoEnvases.id_pertenencia}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {leyendaXid
                        ? leyendaXid.map((elemento) => (
                            <MenuItem
                              key={elemento.id_pertenencia}
                              value={elemento.id_pertenencia} // Debe coincidir con el tipo de dato de la tabla a la que inserta
                            >
                              {elemento.leyenda}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6} mb={2}>
                  <TextField
                    label="Número de Envase"
                    name="nro_envase"
                    size="small"
                    placeholder="123456789"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    inputProps={{
                      maxLength: 9,
                    }}
                    focused
                    fullWidth
                    value={datosRomaneoEnvases.nro_envase}
                    onChange={almacenar}
                  />
                </Grid>

                <Grid item xs={6} mb={2}>
                  <FormControl style={style} focused>
                    <InputLabel>Matrícula del Fabricante</InputLabel>
                    <Select
                      label="Matrícula del Fabricante"
                      required
                      size="small"
                      name="id_mat_fabricante"
                      value={datosRomaneoEnvases.id_mat_fabricante}
                      onChange={almacenar}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {mat_fabricante
                        ? mat_fabricante.map((elemento) => (
                            <MenuItem
                              key={elemento.id_fabricante}
                              value={elemento.id_fabricante}
                            >
                              {elemento.mat_fabricante} -{" "}
                              {elemento.descripcion_fabricante}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={3} mb={2}>
                  <TextField
                    label="Lote"
                    name="lote"
                    size="small"
                    placeholder="001"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    inputProps={{
                      maxLength: 3,
                    }}
                    focused
                    required
                    fullWidth
                    value={datosRomaneoEnvases.lote}
                    onChange={almacenar}
                  />
                </Grid>

                <Grid item xs={4} mb={2}>
                  <TextField
                    label="Fecha de Fabricación"
                    name="fecha_fabricacion"
                    size="small"
                    placeholder="1234"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    inputProps={{
                      maxLength: 4,
                    }}
                    focused
                    required
                    fullWidth
                    value={datosRomaneoEnvases.fecha_fabricacion}
                    onChange={almacenar}
                  />
                </Grid>

                <Grid item xs={5} mb={2}>
                  <TextField
                    fullWidth={true}
                    style={style}
                    focused
                    size="small"
                    type="date"
                    placeholder="Fecha Reparacion"
                    name="fecha_reparacion"
                    label="Fecha Reparacion"
                    variant="outlined"
                    value={moment(datosRomaneoEnvases.fecha_reparacion).format(
                      "YYYY-MM-DD"
                    )}
                    onChange={almacenar}
                  />
                </Grid>

                {datosRomaneoEnvases.placa_relieve === 2 ? (
                  <>
                    <Grid item xs={6} mb={2}>
                      <TextField
                        label="Núm. de Placa"
                        name="nro_placa"
                        size="small"
                        placeholder="-"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        inputProps={{
                          maxLength: 6,
                        }}
                        focused
                        required
                        fullWidth
                        value={datosRomaneoEnvases.nro_placa}
                        onChange={almacenar}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid xs={4} mb={2} mt={1}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography>Relieve</Typography>
                        <FormControlLabel
                          checked={checkSwitchPlaca}
                          onChange={handleOnChangeSwitchPlaca}
                          control={<AntSwitch />}
                          style={{ width: "23px", height: "23px" }}
                        />
                        <Typography>Placa</Typography>
                      </Stack>
                    </Grid>

                    <Grid item xs={4} mb={2}>
                      {checkSwitchPlaca ? (
                        <TextField
                          label="Núm. de Placa"
                          name="nro_placa"
                          size="small"
                          placeholder="-"
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          inputProps={{
                            maxLength: 6,
                          }}
                          focused
                          required
                          fullWidth
                          value={datosRomaneoEnvases.nro_placa}
                          onChange={almacenar}
                        />
                      ) : (
                        <TextField
                          label="Núm. de Placa"
                          name="nro_placa"
                          size="small"
                          inputProps={{
                            maxLength: 6,
                          }}
                          fullWidth
                          disabled
                          value={(datosRomaneoEnvases.nro_placa = "")}
                        />
                      )}
                    </Grid>
                  </>
                )}

                {datosRomaneoEnvases.peso === 45 ? (
                  <>
                    <Grid item xs={6} mb={2} mt={1}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <FormControlLabel
                          checked={checkSwitchCasquete}
                          onChange={handleOnChangeSwitchCasquete}
                          control={<AntSwitch />}
                          style={{ width: "23px", height: "23px" }}
                        />
                        <Typography>Cambio de Casquete</Typography>
                      </Stack>
                    </Grid>
                  </>
                ) : null}
              </Grid>

              <Stack direction="row">
                <Grid container direction={{ xs: "column", sm: "row" }}>
                  <Grid item xs={9}>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleCloseEditar}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={disBtn}
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Stack>
            </form>
          </Container>
        </DialogContent>
      </Dialog>

      {/* GUARDAR AL CREAR */}
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>

      {/* GUARDAR AL EDITAR */}
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnackEditar}
          autoHideDuration={1500}
          onClose={handleCloseSnackEditar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnackEditar}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>

      {/* {modalRepetido ? (
        <Romaneo_repetido
          alertaRepetido={repeticion}
          open={true}
          modalRepetido={setModalRepetido}
        />
      ) : null} */}
    </div>
  );
}

export default Romaneo_envases_carga;
