import React, { useState } from "react";
import Notify from "../../../Notify";
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Snackbar, Stack, Typography } from "@mui/material";
import { UrlApi } from "../../../../services/apirest";

function DesvincularVehiculos(props) {
    const [openSnack, setOpenSnack] = React.useState(false);
    const [colormensaje, setColorMensaje] = useState("");
    const [mensaje, setMensaje] = useState("");
      console.log("desvincular",props.datos);
      
      const handleBorrarVinculo = async (e) => {
        e.preventDefault();
        await fetch(UrlApi + "/vehiculos_conjuntos/" + props.datos.id, {
          method: 'DELETE', headers: { 'Content-Type': 'application/json' }
        })
        .then((res) => {
          if (!res.err) {
            console.log("Guardado");
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setMensaje("Eliminado");
          } else {
            console.log("Error");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
        // props.onClose(false)
      }

    //Snackbar Notificacion
    const handleCloseSnack = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setOpenSnack(false);
        props.onClose(false)
      };
  return (
    <>
      <Dialog
        fullWidth
        open={props.open}
        onClose={() => props.onClose(false)}
        maxWidth="xs"
      >
        <Box justifyContent="center" backgroundColor="#1F618D">
          <DialogTitle>
            <Typography fontSize={20} align="center" color="#ffffff">
              Desvincular 
              {/* {props.datos.patente_acoplado} del Camión {props.datos.patente_principal} */}
            </Typography>
          </DialogTitle>
        </Box>
        <form onSubmit={handleBorrarVinculo}>
          <DialogContent>
          <DialogContentText textAlign={"center"}>
             ¿Desea Desvincular a {props.datos.patente_acoplado} del Camión {props.datos.patente_principal}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button
                variant="contained"
                onClick={()=>props.onClose(false)}
                style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
              >
                Volver
              </Button>
              <Button
                style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                type="submit"
                variant="contained"
                color="primary"
              >
                Confirmar
              </Button>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}

export default DesvincularVehiculos;
