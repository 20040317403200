import React from 'react'
import { Button, Container, Box, TextField, Typography, Grid, Select, MenuItem, InputLabel, FormControl, IconButton } from '@mui/material'
import { useState, useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import swal from 'sweetalert';
import { UrlApi } from '../../services/apirest';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment'

function StkFisicoEnvases(props) {

  let fechahora = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
  let fechasola = moment(new Date()).format("YYYY-MM-DD")
  let sucursal = localStorage.getItem('sucursal')
  let segmento = "envasado" ///solo grabamos envasado aca
  const [stkuuid, setStkuuid] = useState(uuidv4())
  const [stkuuidOld, setStkuuidOld] = useState("")

  const [sucursales, setSucursales] = useState("");
  //const [sucursalSelected, setSucursalSelected] = useState("");

  const [llenas10, setLlenas10] = useState(0)
  const [vacias10, setVacias10] = useState(0)
  const [cambio10, setCambio10] = useState(0)
  const [tl10, setTL10] = useState(0)
  const [tv10, setTV10] = useState(0)
  const [llenas15, setLlenas15] = useState(0)
  const [vacias15, setVacias15] = useState(0)
  const [cambio15, setCambio15] = useState(0)
  const [tl15, setTL15] = useState(0)
  const [tv15, setTV15] = useState(0)
  const [llenasAE15, setLlenasAE15] = useState(0)
  const [vaciasAE15, setVaciasAE15] = useState(0)
  const [cambioAE15, setCambioAE15] = useState(0)
  const [tlAE15, setTLAE15] = useState(0)
  const [tvAE15, setTVAE15] = useState(0)
  const [llenas30, setLlenas30] = useState(0)
  const [vacias30, setVacias30] = useState(0)
  const [cambio30, setCambio30] = useState(0)
  const [tl30, setTL30] = useState(0)
  const [tv30, setTV30] = useState(0)
  const [llenas45, setLlenas45] = useState(0)
  const [vacias45, setVacias45] = useState(0)
  const [cambio45, setCambio45] = useState(0)

  const [tl45, setTL45] = useState(0)
  const [tv45, setTV45] = useState(0)

  const [totalde10, setTotalde10] = useState(0)
  const [totalde15, setTotalde15] = useState(0)
  const [totaldeAE, setTotaldeAE] = useState(0)
  const [totalde30, setTotalde30] = useState(0)
  const [totalde45, setTotalde45] = useState(0)

  const [transferencia, setTransferencia] = useState([
    {
      sucdestino: "", estado: "", codigoenvase: "", cantidad: 0, producto: null, tanque: "v", usuario: localStorage.getItem('user'),
      sucursal: localStorage.getItem('sucursal'),
      fecharecuento: fechahora,
      segmento: segmento
    },
  ])

  useEffect(() => {
    const buscadatos = async () => {
      const data = await fetch(UrlApi + "/stkfisicototal/" + sucursal + "/" + segmento + "/" + fechasola + "/x")
      const datas = await data.json()
      setLlenas10(datas[0].cantidad)
      setVacias10(datas[10].cantidad)
      setCambio10(datas[20].cantidad)
      setTL10(datas[1].cantidad)
      setTV10(datas[11].cantidad)
      setLlenas15(datas[2].cantidad)
      setVacias15(datas[12].cantidad)
      setCambio15(datas[21].cantidad)
      setTL15(datas[3].cantidad)
      setTV15(datas[13].cantidad)
      setLlenasAE15(datas[4].cantidad)
      setVaciasAE15(datas[14].cantidad)
      setCambioAE15(datas[22].cantidad)
      setTLAE15(datas[5].cantidad)
      setTVAE15(datas[15].cantidad)
      setLlenas30(datas[6].cantidad)
      setVacias30(datas[16].cantidad)
      setCambio30(datas[23].cantidad)
      setTL30(datas[7].cantidad)
      setTV30(datas[17].cantidad)
      setLlenas45(datas[8].cantidad)
      setVacias45(datas[18].cantidad)
      setCambio45(datas[24].cantidad)
      setTL45(datas[9].cantidad)
      setTV45(datas[19].cantidad)
      setStkuuidOld(datas[0].stkuuid)
    }

    const buscardatos2 = async () => {
      const data = await fetch(UrlApi + "/stkfisicototal/" + sucursal + "/" + segmento + "/" + fechasola)
      const datas = await data.json()
      setTransferencia(datas.filter((dat) => dat.tanque === "v"))
      transferencia && console.log("modificar", transferencia)
    }

    props.modificar && buscadatos()
    props.modificar && buscardatos2()
  }, [])



  useEffect(() => {
    setTotalde10(llenas10 * 1 + vacias10 * 1 + cambio10 * 1 + tl10 * 1 + tv10 * 1)
  }, [llenas10, vacias10, cambio10, tl10, tv10])

  useEffect(() => {
    setTotalde15(llenas15 * 1 + vacias15 * 1 + cambio15 * 1 + tl15 * 1 + tv15 * 1)
  }, [llenas15, vacias15, cambio15, tl15, tv15])

  useEffect(() => {
    setTotaldeAE(llenasAE15 * 1 + vaciasAE15 * 1 + cambioAE15 * 1 + tlAE15 * 1 + tvAE15 * 1)
  }, [llenasAE15, vaciasAE15, cambioAE15, tlAE15, tvAE15])

  useEffect(() => {
    setTotalde30(llenas30 * 1 + vacias30 * 1 + cambio30 * 1 + tl30 * 1 + tv30 * 1)
  }, [llenas30, vacias30, cambio30, tl30, tv30])

  useEffect(() => {
    setTotalde45(llenas45 * 1 + vacias45 * 1 + cambio45 * 1 + tl45 * 1 + tv45 * 1)
  }, [llenas45, vacias45, cambio45, tl45, tv45])

  useEffect(() => {
    obtenerSucursales()
  }, [])

  const handleAddTransferencia = () => {
    const values = [...transferencia];
    values.push({
      sucdestino: "", estado: "", codigoenvase: "", cantidad: 0, producto: null, tanque: "v", usuario: localStorage.getItem('user'),
      sucursal: localStorage.getItem('sucursal'),
      fecharecuento: fechahora,
      segmento: segmento
    });
    setTransferencia(values);
  };

  const handleRemoveTransferencia = (index) => {
    const values = [...transferencia];
    values.splice(index, 1);
    setTransferencia(values);
  };

  const handleInputChange = (index, event) => {
    const values = [...transferencia];
    const updatedValue = event.target.name;
    values[index][updatedValue] = event.target.value;
    setTransferencia(values);
  };

  const obtenerSucursales = async () => {
    const data = await fetch(UrlApi + "/sucursales")
    const sucursalesData = await data.json()
    setSucursales(sucursalesData)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    //updateTransferencia();
    handleGrabarstkfisicototal();
    handleVolver()
    swal({
      title: "Recuento Envasado",
      text: "Se registro el recuento correctamente",
      icon: "success",
      closeOnClickOutside: false,
    })
  }

  const handleVolver = () => window.location.hash = "/navbar";

  const handleBorrarstkfisicototal = async () => {
    await fetch(UrlApi + "/stkfisicototal/" + stkuuidOld, {
      method: 'DELETE', headers: { 'Content-Type': 'application/json' }
    })
      .catch(error => {
        console.error('There was an error!', error);
      })
  }

  const result = transferencia.map((o) => ({
    ...o,
    stkuuid: stkuuid,
    idrecuentofisico: null,
    fecharecuento: fechahora,
    capacidadkgrs: o.estado === "llenas" ? o.codigoenvase === "10" ? 10 :
      o.codigoenvase === "15" ? 15 :
        o.codigoenvase === "AE15" ? 15 :
          o.codigoenvase === "30" ? 30 :
            o.codigoenvase === "45" ? 45 :
              null : null,
    producto: o.estado === "llenas" ? (o.codigoenvase === "10" || o.codigoenvase === "15") ? "Butano" : "Propano" : null
  }));

  const handleGrabarTranseferencias = async () => {

    await fetch(UrlApi + "/stkfisicototal", {
      method: 'POST', headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(result.filter((res) => res.cantidad * 1 !== 0 && res.codigoenvase.length > 0 && res.estado.length > 0))
    })
  }

  const handleGrabarstkfisicototal = async () => {
    await fetch(UrlApi + "/stkfisicototal", {
      method: 'POST', headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify([{
        stkuuid: stkuuid,
        codigoenvase: "10",
        estado: "llenas",
        cantidad: llenas10,
        producto: "Butano",
        capacidadkgrs: 10,
        usuario: localStorage.getItem('user'),
        sucursal: localStorage.getItem('sucursal'),
        fecharecuento: fechahora,
        segmento: segmento
      },
      {
        stkuuid: stkuuid,
        codigoenvase: "10",
        estado: "transitollenas",
        cantidad: tl10,
        producto: "Butano",
        capacidadkgrs: 10,
        usuario: localStorage.getItem('user'),
        sucursal: localStorage.getItem('sucursal'),
        fecharecuento: fechahora,
        segmento: segmento
      },
      {
        stkuuid: stkuuid,
        codigoenvase: "15",
        estado: "llenas",
        cantidad: llenas15,
        producto: "Butano",
        capacidadkgrs: 15,
        usuario: localStorage.getItem('user'),
        sucursal: localStorage.getItem('sucursal'),
        fecharecuento: fechahora,
        segmento: segmento
      },
      {
        stkuuid: stkuuid,
        codigoenvase: "15",
        estado: "transitollenas",
        cantidad: tl15,
        producto: "Butano",
        capacidadkgrs: 15,
        usuario: localStorage.getItem('user'),
        sucursal: localStorage.getItem('sucursal'),
        fecharecuento: fechahora,
        segmento: segmento
      },
      {
        stkuuid: stkuuid,
        codigoenvase: "AE15",
        estado: "llenas",
        cantidad: llenasAE15,
        producto: "Propano",
        capacidadkgrs: 15,
        usuario: localStorage.getItem('user'),
        sucursal: localStorage.getItem('sucursal'),
        fecharecuento: fechahora,
        segmento: segmento
      },
      {
        stkuuid: stkuuid,
        codigoenvase: "AE15",
        estado: "transitollenas",
        cantidad: tlAE15,
        producto: "Propano",
        capacidadkgrs: 15,
        usuario: localStorage.getItem('user'),
        sucursal: localStorage.getItem('sucursal'),
        fecharecuento: fechahora,
        segmento: segmento
      },
      {
        stkuuid: stkuuid,
        codigoenvase: "30",
        estado: "llenas",
        cantidad: llenas30,
        producto: "Propano",
        capacidadkgrs: 30,
        usuario: localStorage.getItem('user'),
        sucursal: localStorage.getItem('sucursal'),
        fecharecuento: fechahora,
        segmento: segmento
      },
      {
        stkuuid: stkuuid,
        codigoenvase: "30",
        estado: "transitollenas",
        cantidad: tl30,
        producto: "Propano",
        capacidadkgrs: 30,
        usuario: localStorage.getItem('user'),
        sucursal: localStorage.getItem('sucursal'),
        fecharecuento: fechahora,
        segmento: segmento
      },
      {
        stkuuid: stkuuid,
        codigoenvase: "45",
        estado: "llenas",
        cantidad: llenas45,
        producto: "Propano",
        capacidadkgrs: 45,
        usuario: localStorage.getItem('user'),
        sucursal: localStorage.getItem('sucursal'),
        fecharecuento: fechahora,
        segmento: segmento
      },
      {
        stkuuid: stkuuid,
        codigoenvase: "45",
        estado: "transitollenas",
        cantidad: tl45,
        producto: "Propano",
        capacidadkgrs: 45,
        usuario: localStorage.getItem('user'),
        sucursal: localStorage.getItem('sucursal'),
        fecharecuento: fechahora,
        segmento: segmento
      },
      {
        stkuuid: stkuuid,
        codigoenvase: "10",
        estado: "vacias",
        cantidad: vacias10,
        usuario: localStorage.getItem('user'),
        sucursal: localStorage.getItem('sucursal'),
        fecharecuento: fechahora,
        segmento: segmento
      },
      {
        stkuuid: stkuuid,
        codigoenvase: "10",
        estado: "transitovacias",
        cantidad: tv10,
        usuario: localStorage.getItem('user'),
        sucursal: localStorage.getItem('sucursal'),
        fecharecuento: fechahora,
        segmento: segmento
      },
      {
        stkuuid: stkuuid,
        codigoenvase: "15",
        estado: "vacias",
        cantidad: vacias15,
        usuario: localStorage.getItem('user'),
        sucursal: localStorage.getItem('sucursal'),
        fecharecuento: fechahora,
        segmento: segmento
      },
      {
        stkuuid: stkuuid,
        codigoenvase: "15",
        estado: "transitovacias",
        cantidad: tv15,
        usuario: localStorage.getItem('user'),
        sucursal: localStorage.getItem('sucursal'),
        fecharecuento: fechahora,
        segmento: segmento
      },
      {
        stkuuid: stkuuid,
        codigoenvase: "AE15",
        estado: "vacias",
        cantidad: vaciasAE15,
        usuario: localStorage.getItem('user'),
        sucursal: localStorage.getItem('sucursal'),
        fecharecuento: fechahora,
        segmento: segmento
      },
      {
        stkuuid: stkuuid,
        codigoenvase: "AE15",
        estado: "transitovacias",
        cantidad: tvAE15,
        usuario: localStorage.getItem('user'),
        sucursal: localStorage.getItem('sucursal'),
        fecharecuento: fechahora,
        segmento: segmento
      },
      {
        stkuuid: stkuuid,
        codigoenvase: "30",
        estado: "vacias",
        cantidad: vacias30,
        usuario: localStorage.getItem('user'),
        sucursal: localStorage.getItem('sucursal'),
        fecharecuento: fechahora,
        segmento: segmento
      },
      {
        stkuuid: stkuuid,
        codigoenvase: "30",
        estado: "transitovacias",
        cantidad: tv30,
        usuario: localStorage.getItem('user'),
        sucursal: localStorage.getItem('sucursal'),
        fecharecuento: fechahora,
        segmento: segmento
      },
      {
        stkuuid: stkuuid,
        codigoenvase: "45",
        estado: "vacias",
        cantidad: vacias45,
        usuario: localStorage.getItem('user'),
        sucursal: localStorage.getItem('sucursal'),
        fecharecuento: fechahora,
        segmento: segmento
      },
      {
        stkuuid: stkuuid,
        codigoenvase: "45",
        estado: "transitovacias",
        cantidad: tv45,
        usuario: localStorage.getItem('user'),
        sucursal: localStorage.getItem('sucursal'),
        fecharecuento: fechahora,
        segmento: segmento
      },
      {
        stkuuid: stkuuid,
        codigoenvase: "10",
        estado: "cambio",
        cantidad: cambio10,
        usuario: localStorage.getItem('user'),
        sucursal: localStorage.getItem('sucursal'),
        fecharecuento: fechahora,
        segmento: segmento
      },
      {
        stkuuid: stkuuid,
        codigoenvase: "15",
        estado: "cambio",
        cantidad: cambio15,
        usuario: localStorage.getItem('user'),
        sucursal: localStorage.getItem('sucursal'),
        fecharecuento: fechahora,
        segmento: segmento
      },
      {
        stkuuid: stkuuid,
        codigoenvase: "AE15",
        estado: "cambio",
        cantidad: cambioAE15,
        usuario: localStorage.getItem('user'),
        sucursal: localStorage.getItem('sucursal'),
        fecharecuento: fechahora,
        segmento: segmento
      },
      {
        stkuuid: stkuuid,
        codigoenvase: "30",
        estado: "cambio",
        cantidad: cambio30,
        usuario: localStorage.getItem('user'),
        sucursal: localStorage.getItem('sucursal'),
        fecharecuento: fechahora,
        segmento: segmento
      },
      {
        stkuuid: stkuuid,
        codigoenvase: "45",
        estado: "cambio",
        cantidad: cambio45,
        usuario: localStorage.getItem('user'),
        sucursal: localStorage.getItem('sucursal'),
        fecharecuento: fechahora,
        segmento: segmento
      }])

    })
      .then(handleGrabarTranseferencias())
      .then(handleBorrarstkfisicototal())
      .catch(error => {
        console.error('There was an error!', error);
      })
  }
  console.log(transferencia)
  console.log("filtrado", transferencia.filter((res) => res.cantidad * 1 !== 0 && res.codigoenvase.length > 0 && res.estado.length > 0))
  return (
    <div>
      <Container>
        <br />
        <Typography variant="h5" component="h4" bgcolor='black' color='white'> {props.titulo} Recuento físico de envases -  {localStorage.getItem("sucursal")}-{localStorage.getItem("sucursalnombre")}                  {moment().format("DD-MM-YYYY HH:mm:ss")}</Typography>
        <Grid container direction="row">
          <Grid item xs={5} bgcolor="teal" color="white">  Envases en sucursal</Grid>
          <Grid item xs={3} bgcolor="blue" color="white">  Envases en camiones de reparto</Grid>
        </Grid>
        <form id="myform" autoComplete='off' onSubmit={handleSubmit}>
          {/* de 10 ********************************************************************************/}
          <Box
            sx={{
              '& > :not(style)': { m: 1, width: '17ch' },
            }}
            noValidate
            autoComplete="off"
            alignItems="right"
          >
            <TextField variant="outlined" size="small" label="Llenas de 10kgs" color="success"
              inputProps={{ style: { textAlign: 'right', background: "#f2f7f3", } }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(event) => { setLlenas10(event.target.value); }}
              value={llenas10}
            />
            <TextField variant="outlined" size="small" label="Vacias de 10kgs" color="secondary"
              inputProps={{ style: { textAlign: 'right', background: "#faf7e3", } }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(event) => { setVacias10(event.target.value); }}
              value={vacias10}
            />
            <TextField variant="outlined" size="small" label="Cambios de 10kgs" color="warning"
              inputProps={{ style: { textAlign: 'right', background: "#faf0f8", } }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(event) => { setCambio10(event.target.value); }}
              value={cambio10}
            />
            <TextField variant="outlined" size="small" label="Transito Lleno 10kg" color="secondary"
              inputProps={{ style: { textAlign: 'right', background: "#f2f1f2", } }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(event) => { setTL10(event.target.value); }}
              value={tl10}
            />
            <TextField variant="outlined" size="small" label="Transito Vacio 10kg" color="secondary"
              inputProps={{ style: { textAlign: 'right', background: "#f2ccc9", } }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(event) => { setTV10(event.target.value); }}
              value={tv10}
            />
            <TextField variant="outlined" size="small" label="Total envases 10kgs" color="warning"
              inputProps={{ style: { textAlign: 'right', background: "LightYellow", } }}
              value={totalde10} readOnly={true} disabled={true}
            />
          </Box>
          {/* de 15 ********************************************************************************/}
          <Box
            sx={{
              '& > :not(style)': { m: 1, width: '17ch' },
            }}
            noValidate
            autoComplete="off"
          >

            <TextField variant="outlined" size="small" label="Llenas de 15kgs" color="success"
              inputProps={{ style: { textAlign: 'right', background: "#f2f7f3", } }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(event) => { setLlenas15(event.target.value); }}
              value={llenas15}
            />
            <TextField variant="outlined" size="small" label="Vacias de 15kgs" color="secondary"
              inputProps={{ style: { textAlign: 'right', background: "#faf7e3", } }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(event) => { setVacias15(event.target.value); }}
              value={vacias15}
            />
            <TextField variant="outlined" size="small" label="Cambios de 15kgs" color="warning"
              inputProps={{ style: { textAlign: 'right', background: "#faf0f8", } }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(event) => { setCambio15(event.target.value); }}
              value={cambio15}
            />
            <TextField variant="outlined" size="small" label="Transito Lleno 15kg" color="secondary"
              inputProps={{ style: { textAlign: 'right', background: "#f2f1f2", } }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(event) => { setTL15(event.target.value); }}
              value={tl15}
            />
            <TextField variant="outlined" size="small" label="Transito Vacio 15kg" color="secondary"
              inputProps={{ style: { textAlign: 'right', background: "#f2ccc9", } }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(event) => { setTV15(event.target.value); }}
              value={tv15}
            />
            <TextField variant="outlined" size="small" label="Total envases 15kgs" color="warning"
              inputProps={{ style: { textAlign: 'right', background: "LightYellow", } }}
              value={totalde15} readOnly={true} disabled={true}
            />
          </Box>
          {/* de AE15 ********************************************************************************/}
          <Box
            sx={{
              '& > :not(style)': { m: 1, width: '17ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField variant="outlined" size="small" label="Llenas de AE15kgs" color="success"
              inputProps={{ style: { textAlign: 'right', background: "#f2f7f3", } }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(event) => { setLlenasAE15(event.target.value); }}
              value={llenasAE15}
            />
            <TextField variant="outlined" size="small" label="Vacias de AE15kgs" color="secondary"
              inputProps={{ style: { textAlign: 'right', background: "#faf7e3", } }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(event) => { setVaciasAE15(event.target.value); }}
              value={vaciasAE15}
            />
            <TextField variant="outlined" size="small" label="Cambios de AE15kgs" color="warning"
              inputProps={{ style: { textAlign: 'right', background: "#faf0f8", } }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(event) => { setCambioAE15(event.target.value); }}
              value={cambioAE15}
            />
            <TextField variant="outlined" size="small" label="Transito Lleno AE15kg" color="secondary"
              inputProps={{ style: { textAlign: 'right', background: "#f2f1f2", } }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(event) => { setTLAE15(event.target.value); }}
              value={tlAE15}
            />
            <TextField variant="outlined" size="small" label="Transito Vacio AE15kg" color="secondary"
              inputProps={{ style: { textAlign: 'right', background: "#f2ccc9", } }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(event) => { setTVAE15(event.target.value); }}
              value={tvAE15}
            />
            <TextField variant="outlined" size="small" label="Total envases AE15kgs" color="warning"
              inputProps={{ style: { textAlign: 'right', background: "LightYellow", } }}
              value={totaldeAE} readOnly={true} disabled={true}
            />
          </Box>
          {/* de 30 ********************************************************************************/}
          <Box
            sx={{
              '& > :not(style)': { m: 1, width: '17ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField variant="outlined" size="small" label="Llenas de 30kgs" color="success"
              inputProps={{ style: { textAlign: 'right', background: "#f2f7f3", } }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(event) => { setLlenas30(event.target.value); }}
              value={llenas30}
            />
            <TextField variant="outlined" size="small" label="Vacias de 30kgs" color="secondary"
              inputProps={{ style: { textAlign: 'right', background: "#faf7e3", } }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(event) => { setVacias30(event.target.value); }}
              value={vacias30}
            />
            <TextField variant="outlined" size="small" label="Cambios de 30kgs" color="warning"
              inputProps={{ style: { textAlign: 'right', background: "#faf0f8", } }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(event) => { setCambio30(event.target.value); }}
              value={cambio30}
            />
            <TextField variant="outlined" size="small" label="Transito Lleno 30kg" color="secondary"
              inputProps={{ style: { textAlign: 'right', background: "#f2f1f2", } }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(event) => { setTL30(event.target.value); }}
              value={tl30}
            />
            <TextField variant="outlined" size="small" label="Transito Vacio 30kg" color="secondary"
              inputProps={{ style: { textAlign: 'right', background: "#f2ccc9", } }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(event) => { setTV30(event.target.value); }}
              value={tv30}
            />
            <TextField variant="outlined" size="small" label="Total envases 30kgs" color="warning"
              inputProps={{ style: { textAlign: 'right', background: "LightYellow", } }}
              value={totalde30} readOnly={true} disabled={true}
            />
          </Box>
          {/* de 45 ********************************************************************************/}
          <Box
            sx={{
              '& > :not(style)': { m: 1, width: '17ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField variant="outlined" size="small" label="Llenas de 45kgs" color="success"
              inputProps={{ style: { textAlign: 'right', background: "#f2f7f3", } }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(event) => { setLlenas45(event.target.value); }}
              value={llenas45}
            />
            <TextField variant="outlined" size="small" label="Vacias de 45kgs" color="secondary"
              inputProps={{ style: { textAlign: 'right', background: "#faf7e3", } }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(event) => { setVacias45(event.target.value); }}
              value={vacias45}
            />
            <TextField variant="outlined" size="small" label="Cambios de 45kgs" color="warning"
              inputProps={{ style: { textAlign: 'right', background: "#faf0f8", } }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(event) => { setCambio45(event.target.value); }}
              value={cambio45}
            />
            <TextField variant="outlined" size="small" label="Transito Lleno 45kg" color="secondary"
              inputProps={{ style: { textAlign: 'right', background: "#f2f1f2", } }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(event) => { setTL45(event.target.value); }}
              value={tl45}
            />
            <TextField variant="outlined" size="small" label="Transito Vacio 45kg" color="secondary"
              inputProps={{ style: { textAlign: 'right', background: "#f2ccc9", } }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(event) => { setTV45(event.target.value); }}
              value={tv45}
            />
            <TextField variant="outlined" size="small" label="Total envases 45kgs" color="warning"
              inputProps={{ style: { textAlign: 'right', background: "LightYellow", } }}
              value={totalde45} readOnly={true} disabled={true}
            />
          </Box>

          <Grid container spacing={1} direction="row">
            <Grid item xs={4}>
              <Typography variant='h5' component="h4" bgcolor='teal' color='white'> Transferencias a otras sucursales</Typography>
            </Grid>
            <Grid item xs={2}>
              <IconButton color='success' onClick={handleAddTransferencia}><AddCircleOutlineIcon fontSize="large" /></IconButton>
            </Grid>
          </Grid>
          <hr />
          {transferencia.map((field, index) => (
            <div key={index} style={{ paddingBottom: '5px' }}>
              <FormControl>
                <InputLabel>Para Sucursal</InputLabel>
                <Select
                  size='small'
                  name="sucdestino"
                  onChange={(e) => handleInputChange(index, e)}
                  style={{ width: 200 }}
                  defaultValue=""
                  value={field.sucdestino}
                >
                  {sucursales ? sucursales.map(elemento => (
                    <MenuItem key={elemento.id} value={elemento.id}>
                      {elemento.nombre}
                    </MenuItem>
                  )) : null}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel>Llenas/Vacias</InputLabel>
                <Select
                  size="small"
                  style={{ width: 150 }}
                  value={field.estado}
                  label="Estado"
                  name="estado"
                  onChange={(e) => handleInputChange(index, e)}
                >
                  <MenuItem value={'llenas'}>Llenas</MenuItem>
                  <MenuItem value={'vacias'}>Vacias</MenuItem>
                  <MenuItem value={'cambio'}>Cambio</MenuItem>
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel>Envase</InputLabel>
                <Select
                  size="small"
                  style={{ width: 150 }}
                  value={field.codigoenvase}
                  label="Envase"
                  name="codigoenvase"
                  onChange={(e) => handleInputChange(index, e)}
                >
                  <MenuItem value={'10'}>x10Kg</MenuItem>
                  <MenuItem value={'15'}>x15Kg</MenuItem>
                  <MenuItem value={'AE15'}>xAE15Kg</MenuItem>
                  <MenuItem value={'30'}>x30Kg</MenuItem>
                  <MenuItem value={'45'}>x45Kg</MenuItem>
                </Select>
              </FormControl>

              <TextField variant="outlined" size="small" label="Cantidad" color="secondary"
                inputProps={{ style: { textAlign: 'right', background: "#FAFA60", width: 100 } }}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(e) => handleInputChange(index, e)}
                value={field.cantidad}
                name="cantidad"
              />

              <IconButton
                variant="outlined"
                color='error'
                onClick={() => handleRemoveTransferencia(index)}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          ))}
          <hr />
          <Box
            sx={{
              '& > :not(style)': { m: 1, width: '17ch' },
            }}
            alignItems="flex-end"
            noValidate
            autoComplete="off"
          >
            <Button
              variant="outlined"
              color="success"
              type="submit"
              form="myform">
              Enviar datos
            </Button>
            <Button
              variant="outlined"
              onClick={handleVolver}
              color="warning">
              Cancelar
            </Button>
          </Box>
        </form>

      </Container>
    </div>
  )
}

export default StkFisicoEnvases
