//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio
// Creation Date : 05/08/2024
// Version : 1
// Description : dialog de expancion de los motivos de reclamos
//------Changes ----------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { UrlApi } from "../../../../services/apirest";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";


function Detalle_tabla_motivos(props) {
  const [abrirDialog, setAbrirDialog] = useState(props.abrirDialog);

  console.log("ID MOTIVO DETALLE", props.idElegido);

  const handleClose = () => {
    setAbrirDialog(!abrirDialog);
    if (props.onClose) {
      props.onClose();
    }
  };

  const [detalleMotivo, setDetalleMotivo] = useState([]);

  const obtenerDetalleMotivo = () => {
    fetch(UrlApi + "/reclamos/detallesXmotivo/" + props.idElegido)
      .then((data) => data.json())
      .then((data) => setDetalleMotivo(data));
  };

  useEffect(() => {
    if (props.idElegido) {
      obtenerDetalleMotivo();
    }
  }, [props.idElegido]);

  console.log("DETALLE MOTIVO EN DETALLE", detalleMotivo);

  return (
    <div>
      <Dialog fullScreen open={abrirDialog} onClose={handleClose}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {detalleMotivo.length > 0
                ? `Detalles de los motivos de reclamo por: ${detalleMotivo[0].descripcion_motivo}`
                : "Cargando..."}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Cerrar
            </Button>
          </Toolbar>
        </AppBar>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="flex-start"
          padding={4}
        >
          {detalleMotivo.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#D3D3D3" }}>
                    <TableCell sx={{ borderLeft: "1px solid #ccc" }}>
                      <strong>Cliente</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Dirección</strong>
                    </TableCell>
                    <TableCell sx={{ borderRight: "1px solid #ccc" }}>
                      <strong>N°.Serie del tanque</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {detalleMotivo.map((motivo, index) => (
                    <TableRow key={index}>
                      <TableCell
                        sx={{
                          borderRight: "1px solid #ccc",
                          borderBottom: "1px solid #ccc",
                          borderLeft: "1px solid #ccc",
                        }}
                      >
                        {motivo.razon_social}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderRight: "1px solid #ccc",
                          borderBottom: "1px solid #ccc",
                        }}
                      >
                        {motivo.direccion_entrega}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #ccc",
                          borderRight: "1px solid #ccc",
                        }}
                      >
                        {motivo.nro_serie}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography variant="body1">
              No hay detalles disponibles.
            </Typography>
          )}
        </Box>
      </Dialog>
    </div>
  );
}

export default Detalle_tabla_motivos;
