import React, { useEffect, useState } from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { LinePlot } from "@mui/x-charts/LineChart";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";

// ICONOS
import CircularProgress from "@mui/material/CircularProgress";

// PARA EL IDIOMA DEL CALENDARIO
import "dayjs/locale/es";
import dayjs from "dayjs";

import { UrlApiEnvasado } from "../../../services/apirest";

export default function StackedAreas(props) {
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(false);

  const [drawerOpen, setDrawerOpen] = useState(props.drawerOpen);

  const [chartData, setChartData] = useState([]);
  const [chartData2, setChartData2] = useState([]);
  const [provincias, setProvincias] = useState();
  const [sucursales, setSucursales] = useState();
  const [choferes, setChoferes] = useState();
  const [choferes2, setChoferes2] = useState();
  const [moviles, setMoviles] = useState();
  const [moviles2, setMoviles2] = useState();
  const [pesos, setPesos] = useState();
  const [tipoEnvase, setTipoEnvase] = useState();
  const [tipoEnvase2, setTipoEnvase2] = useState();

  const [filtrandoDatos, setFiltrandoDatos] = useState(false);
  const [filtrandoDatos2, setFiltrandoDatos2] = useState(false);
  const [realizarComparativa, setRealizarComparativa] = useState(false);
  const [verFiltros, setVerFiltros] = useState(false);
  const [comparativaActiva, setComparativaActiva] = useState(false);

  const [selectedYear, setSelectedYear] = useState(
    new Date().getFullYear().toString()
  );
  const [selectedYear2, setSelectedYear2] = useState(
    new Date().getFullYear().toString()
  );

  // FECHA DE HOY
  const hoy = dayjs();

  const [obteniendoChoferes, setObteniendoChoferes] = useState(false);
  const [obteniendoChoferes2, setObteniendoChoferes2] = useState(false);

  const [obteniendoMoviles, setObteniendoMoviles] = useState(false);
  const [obteniendoMoviles2, setObteniendoMoviles2] = useState(false);

  // PARA MOSTRAR LOS DATOS DE LOS FILTROS
  const [nombreSucursalSeleccionada, setNombreSucursalSeleccionada] =
    useState(null);
  const [nombreChoferSeleccionado, setNombreChoferSeleccionado] =
    useState(null);
  const [nombreMovilSeleccionado, setNombreMovilSeleccionado] = useState(null);
  const [nombrePesoSeleccionado, setNombrePesoSeleccionado] = useState(null);
  const [nombreEnvaseSeleccionado, setNombreEnvaseSeleccionado] =
    useState(null);

  // -- PARA SEGUNDOS DATOS:
  const [nombreSucursalSeleccionada2, setNombreSucursalSeleccionada2] =
    useState(null);
  const [nombreChoferSeleccionado2, setNombreChoferSeleccionado2] =
    useState(null);
  const [nombreMovilSeleccionado2, setNombreMovilSeleccionado2] =
    useState(null);
  const [nombrePesoSeleccionado2, setNombrePesoSeleccionado2] = useState(null);
  const [nombreEnvaseSeleccionado2, setNombreEnvaseSeleccionado2] =
    useState(null);

  // ---------------------------------------------------------------------------

  // CHECKBOX MOSTRAR DATOS 2 EN TABLE
  const [mostrarSegundosDatos, setMostrarSegundosDatos] = useState(false);

  const handleMostrarSegundosDatos = () => {
    setMostrarSegundosDatos(!mostrarSegundosDatos);
  };

  // CHECKBOX PARA LOS FILTROS
  const [selectsHabilitados, setSelectsHabilitados] = useState(false);

  const handleHabilitarSelects = () => {
    setSelectsHabilitados(!selectsHabilitados);
    if (selectsHabilitados) {
      // Si deshabilitas los selectores, establece sus valores en null
      setSucursalSeleccionada(null);
      setChoferSeleccionado(null);
      setMovilSeleccionado(null);
      setPesoSeleccionado(null);
      setTipoEnvaseSeleccionado(null);
    }
  };

  // PARA OBTENER AL DESHABILITAR LOS FILTROS
  useEffect(() => {
    // Verifica si selectsHabilitados es falso y luego ejecuta obtenerDatosGrafico
    if (!selectsHabilitados) {
      handleClickObtenerDatosGrafico();
    }
  }, [selectsHabilitados]);

  // CHECKBOX PARA LOS FILTROS 2
  const [selectsHabilitados2, setSelectsHabilitados2] = useState(false);

  const handleHabilitarSelects2 = () => {
    setSelectsHabilitados2(!selectsHabilitados2);
    if (selectsHabilitados2) {
      // Si deshabilitas los selectores, establece sus valores en null
      setSucursalSeleccionada2(null);
      setChoferSeleccionado2(null);
      setMovilSeleccionado2(null);
      setPesoSeleccionado2(null);
      setTipoEnvaseSeleccionado2(null);
    }
  };

  // PARA OBTENER AL DESHABILITAR LOS FILTROS
  useEffect(() => {
    // Verifica si selectsHabilitados es falso y luego ejecuta obtenerDatosGrafico
    if (!selectsHabilitados2) {
      handleClickObtenerDatosGrafico2();
    }
  }, [selectsHabilitados2]);

  // ************************************************************************
  const [sucursalSeleccionada, setSucursalSeleccionada] = useState(null);

  const handleSucursalChange = (sucursal) => {
    setSucursalSeleccionada(sucursal);
    setChoferSeleccionado(null);
    setMovilSeleccionado(null);

    obtenerChoferes();
    obtenerMoviles();
  };

  const [choferSeleccionado, setChoferSeleccionado] = useState(null);

  const handleChoferChange = (chofer) => {
    setChoferSeleccionado(chofer);
    setMovilSeleccionado(null);

    obtenerMoviles();
  };

  const [movilSeleccionado, setMovilSeleccionado] = useState(null);

  const handleMovilChange = (movil) => {
    setMovilSeleccionado(movil);
  };

  const [pesoSeleccionado, setPesoSeleccionado] = useState(null);

  const handlePesoChange = (peso) => {
    setPesoSeleccionado(peso);

    // Verificar si se ha seleccionado un peso
    if (peso) {
      obtenerTiposEnvasePorPeso(peso);
    } else {
      // Si no se ha seleccionado un peso, obtener todos los tipos de envase
      obtenerTipoEnvase();
    }
  };

  const [tipoEnvaseSeleccionado, setTipoEnvaseSeleccionado] = useState(null);

  const handleTipoEnvaseChange = (tipoEnvase) => {
    setTipoEnvaseSeleccionado(tipoEnvase);
  };

  // ---------------------------------------------------------------------------

  // PARA LOS SEGUNDOS DATOS (CHART COMPARATIVO)
  const [sucursalSeleccionada2, setSucursalSeleccionada2] = useState(null);

  const handleSucursalChange2 = (sucursal) => {
    setSucursalSeleccionada2(sucursal);
    setChoferSeleccionado2(null);
    setMovilSeleccionado2(null);

    obtenerChoferes2();
    obtenerMoviles2();
  };

  const [choferSeleccionado2, setChoferSeleccionado2] = useState(null);

  const handleChoferChange2 = (chofer) => {
    setChoferSeleccionado2(chofer);
    setMovilSeleccionado2(null);

    obtenerMoviles2();
  };

  const [movilSeleccionado2, setMovilSeleccionado2] = useState(null);

  const handleMovilChange2 = (movil) => {
    setMovilSeleccionado2(movil);
  };

  const [pesoSeleccionado2, setPesoSeleccionado2] = useState(null);

  const handlePesoChange2 = (peso) => {
    setPesoSeleccionado2(peso);

    // Verificar si se ha seleccionado un peso
    if (peso) {
      obtenerTiposEnvasePorPeso2(peso);
    } else {
      // Si no se ha seleccionado un peso, obtener todos los tipos de envase
      obtenerTipoEnvase2();
    }
  };

  const [tipoEnvaseSeleccionado2, setTipoEnvaseSeleccionado2] = useState(null);

  const handleTipoEnvaseChange2 = (tipoEnvase) => {
    setTipoEnvaseSeleccionado2(tipoEnvase);
  };
  // ************************************************************************

  // Agrega un estado para almacenar la selección del usuario
  const [intervaloTiempo, setIntervaloTiempo] = useState("Mensual");

  // Modifica la función para manejar el cambio del intervalo de tiempo
  const handleIntervaloTiempoChange = (event, newIntervalo) => {
    if (newIntervalo !== null) {
      setIntervaloTiempo(newIntervalo);
    }
  };

  // ************************************************************************

  // Agrega un estado para almacenar la selección del usuario
  const [codigoProBut, setCodigoProBut] = useState("00");

  // Modifica la función para manejar el cambio del intervalo de tiempo
  const handleCodigoProButChange = (event, newCodigo) => {
    if (newCodigo !== null) {
      setCodigoProBut(newCodigo);
    }
  };

  console.log("CODIGO PRO/BUT: ", codigoProBut);

  // ************************************************************************

  // OBTENCION DE DATOS SEGÚN PERIODO DE TIEMPO ELEGIDO
  // Para manejar cambios en el DatePicker mensual
  useEffect(() => {
    setLoading(true);
    if (
      intervaloTiempo === "Mensual"
      // && dayjs(`${selectedYear}`).isValid()
    ) {
      obtenerDatosGrafico();
    }
  }, [selectedYear, intervaloTiempo]);

  // Para manejar cambios en el DatePicker trimestral
  useEffect(() => {
    setLoading(true);
    if (intervaloTiempo === "Trimestral") {
      obtenerDatosTrimestrales();
    }
  }, [selectedYear, intervaloTiempo]);

  console.log("INTERVALO DE TIEMPO: ", intervaloTiempo);

  // AL FILTRAR
  const handleClickObtenerDatosGrafico = () => {
    setLoading(true);
    setFiltrandoDatos(true);
    if (
      intervaloTiempo === "Mensual"
      // && dayjs(`${selectedYear}`).isValid()
    ) {
      obtenerDatosGrafico();
      // obtenerDatosGrafico2();
    } else if (
      intervaloTiempo === "Trimestral"
      // &&
      // dayjs(`${selectedYear}`).isValid()
    ) {
      obtenerDatosTrimestrales();
      // obtenerDatosTrimestrales2();
    }
  };

  // --------------------------------------------------------

  // GRAFICA DE COMPARATIVA
  useEffect(() => {
    if (
      comparativaActiva
      // && dayjs(`${selectedYear2}`).isValid()
    ) {
      if (intervaloTiempo === "Mensual") {
        obtenerDatosGrafico2();
      } else if (intervaloTiempo === "Trimestral") {
        obtenerDatosTrimestrales2();
      }
    }
  }, [comparativaActiva, selectedYear2, intervaloTiempo]);

  const handleClickObtenerDatosGrafico2 = () => {
    // setFiltrandoDatos2(true);
    // setLoading2(true);

    if (
      intervaloTiempo === "Mensual" &&
      comparativaActiva

      // && dayjs(`${selectedYear2}`).isValid()
    ) {
      obtenerDatosGrafico2();
    } else if (
      intervaloTiempo === "Trimestral"
      // && dayjs(`${selectedYear2}`).isValid()
    ) {
      obtenerDatosTrimestrales2();
    }
  };

  console.log("INTERVALO DE TIEMPO: ", intervaloTiempo);

  // **************************************************************************

  // PROVINCIAS
  const obtenerProvincias = () => {
    fetch(UrlApiEnvasado + "/envasado/provincias")
      .then((data) => data.json())
      .then((data) => setProvincias(data));
  };

  useEffect(() => {
    obtenerProvincias();
  }, []);
  console.log("Provincias: ", provincias);

  // ----------------------------------------------

  // SUCURSALES
  const obtenerSucursales = () => {
    fetch(UrlApiEnvasado + "/envasado/sucursales")
      .then((data) => data.json())
      .then((data) => setSucursales(data));
  };

  useEffect(() => {
    obtenerSucursales();
  }, []);

  console.log("Sucursales: ", sucursales);

  // ----------------------------------------------

  // CHOFERES
  const obtenerChoferes = () => {
    setObteniendoChoferes(true); // Se inicia la obtención de choferes
    fetch(UrlApiEnvasado + "/envasado/choferes")
      .then((data) => data.json())
      .then((data) => {
        setChoferes(data);
        setObteniendoChoferes(false); // Finaliza la obtención de choferes
      })
      .catch((error) => {
        console.error("Error al obtener choferes:", error);
        setObteniendoChoferes(false); // Finaliza la obtención de choferes en caso de error
      });
  };

  const obtenerChoferes2 = () => {
    setObteniendoChoferes2(true); // Se inicia la obtención de choferes
    fetch(UrlApiEnvasado + "/envasado/choferes")
      .then((data) => data.json())
      .then((data) => {
        setChoferes2(data);
        setObteniendoChoferes2(false); // Finaliza la obtención de choferes
      })
      .catch((error) => {
        console.error("Error al obtener choferes:", error);
        setObteniendoChoferes2(false); // Finaliza la obtención de choferes en caso de error
      });
  };

  const obtenerChoferesPorSucursal = () => {
    if (sucursalSeleccionada) {
      setObteniendoChoferes(true); // Inicia la obtención de choferes
      fetch(
        UrlApiEnvasado +
          "/envasado/choferes/sucursal/" +
          sucursalSeleccionada.id
      )
        .then((data) => data.json())
        .then((data) => {
          setChoferes(data);
          setObteniendoChoferes(false); // Finaliza la obtención de choferes
        })
        .catch((error) => {
          console.error("Error al obtener choferes por sucursal:", error);
          setObteniendoChoferes(false); // Finaliza la obtención de choferes en caso de error
        });
    }
  };

  const obtenerChoferesPorSucursal2 = () => {
    if (sucursalSeleccionada2) {
      setObteniendoChoferes2(true); // Inicia la obtención de choferes
      fetch(
        UrlApiEnvasado +
          "/envasado/choferes/sucursal/" +
          sucursalSeleccionada2.id
      )
        .then((data) => data.json())
        .then((data) => {
          setChoferes2(data);
          setObteniendoChoferes2(false); // Finaliza la obtención de choferes
        })
        .catch((error) => {
          console.error("Error al obtener choferes por sucursal 2:", error);
          setObteniendoChoferes2(false); // Finaliza la obtención de choferes en caso de error
        });
    }
  };

  // --- UseEffect

  useEffect(() => {
    obtenerChoferes();
  }, []);

  useEffect(() => {
    obtenerChoferes2();
  }, [selectsHabilitados2]);

  useEffect(() => {
    obtenerChoferesPorSucursal();
  }, [sucursalSeleccionada]);

  useEffect(() => {
    obtenerChoferesPorSucursal2();
  }, [sucursalSeleccionada2]);

  console.log("Choferes: ", obtenerChoferes);

  console.log("Choferes 2: ", obtenerChoferes2);

  // ----------------------------------------------

  // MOVILES
  // Obtengo moviles activos
  const obtenerMoviles = () => {
    setObteniendoMoviles(true); // Se inicia la obtención de moviles
    fetch(UrlApiEnvasado + "/envasado/movil/activos")
      .then((data) => data.json())
      .then((data) => {
        setMoviles(data);
        setObteniendoMoviles(false); // Finaliza la obtención de moviles
      })
      .catch((error) => {
        console.error("Error al obtener moviles:", error);
        setObteniendoMoviles(false); // Finaliza la obtención de moviles en caso de error
      });
  };

  const obtenerMoviles2 = () => {
    setObteniendoMoviles2(true); // Se inicia la obtención de moviles
    fetch(UrlApiEnvasado + "/envasado/movil/activos")
      .then((data) => data.json())
      .then((data) => {
        setMoviles2(data);
        setObteniendoMoviles2(false); // Finaliza la obtención de moviles
      })
      .catch((error) => {
        console.error("Error al obtener moviles 2:", error);
        setObteniendoMoviles2(false); // Finaliza la obtención de moviles en caso de error
      });
  };

  // Obtener moviles según el chofer seleccionado
  const obtenerMovilesPorChofer = () => {
    if (choferSeleccionado) {
      setObteniendoMoviles(true); // Se inicia la obtención de moviles
      fetch(UrlApiEnvasado + "/envasado/movil/chofer/" + choferSeleccionado.id)
        .then((data) => data.json())
        .then((data) => {
          setMoviles(data);
          setObteniendoMoviles(false); // Finaliza la obtención de moviles
        })
        .catch((error) => {
          console.error("Error al obtener moviles:", error);
          setObteniendoMoviles(false); // Finaliza la obtención de moviles en caso de error
        });
    }
  };

  const obtenerMovilesPorChofer2 = () => {
    if (choferSeleccionado2) {
      setObteniendoMoviles2(true); // Se inicia la obtención de moviles
      fetch(UrlApiEnvasado + "/envasado/movil/chofer/" + choferSeleccionado2.id)
        .then((data) => data.json())
        .then((data) => {
          setMoviles2(data);
          setObteniendoMoviles2(false); // Finaliza la obtención de moviles
        })
        .catch((error) => {
          console.error("Error al obtener moviles 2:", error);
          setObteniendoMoviles2(false); // Finaliza la obtención de moviles en caso de error
        });
    }
  };

  useEffect(() => {
    obtenerMoviles();
  }, []);

  useEffect(() => {
    obtenerMoviles2();
  }, [selectsHabilitados2]);

  useEffect(() => {
    obtenerMovilesPorChofer();
  }, [choferSeleccionado]);

  useEffect(() => {
    obtenerMovilesPorChofer2();
  }, [choferSeleccionado2]);

  console.log("Moviles: ", obtenerMoviles);

  // ----------------------------------------------

  // PESOS (kg)
  const obtenerPesos = () => {
    fetch(UrlApiEnvasado + "/envasado/stock/peso")
      .then((data) => data.json())
      .then((data) => setPesos(data));
  };

  useEffect(() => {
    obtenerPesos();
  }, []);

  console.log("Pesos: ", obtenerPesos);

  // ----------------------------------------------

  // TIPOS DE ENVASE
  const obtenerTipoEnvase = () => {
    fetch(UrlApiEnvasado + "/envasado/stock")
      .then((data) => data.json())
      .then((data) => setTipoEnvase(data));
  };

  const obtenerTipoEnvase2 = () => {
    fetch(UrlApiEnvasado + "/envasado/stock")
      .then((data) => data.json())
      .then((data) => setTipoEnvase2(data));
  };

  useEffect(() => {
    if (pesoSeleccionado) {
      obtenerTiposEnvasePorPeso(pesoSeleccionado);
      setTipoEnvaseSeleccionado(null);
    } else {
      obtenerTipoEnvase();
    }
  }, [pesoSeleccionado]);

  useEffect(() => {
    if (pesoSeleccionado2) {
      obtenerTiposEnvasePorPeso2(pesoSeleccionado2);
      setTipoEnvaseSeleccionado2(null);
    } else {
      obtenerTipoEnvase2();
    }
  }, [pesoSeleccionado2]);

  console.log("Pesos: ", obtenerTipoEnvase);

  // **************************************************************************

  useEffect(() => {
    setDrawerOpen(props.drawerOpen);
  }, [props.drawerOpen]);

  // CAMBIO DE FECHA DESDE EL DRAWER:
  const handleYearChange = (newDate) => {
    if (dayjs(newDate).isValid()) {
      setSelectedYear(dayjs(newDate).year().toString());
      setChartData([]); // Restablecer chartData a un array vacío
    }
  };

  const handleYearChange2 = (newDate) => {
    if (dayjs(newDate).isValid()) {
      setSelectedYear2(dayjs(newDate).year().toString());
      setChartData2([]); // Restablecer chartData a un array vacío
    }
  };

  // ***************************************************************

  const obtenerDatosGrafico = async () => {
    setLoading(true);
    try {
      // Construir la URL base hasta el año seleccionado
      let url = `${UrlApiEnvasado}/envasado/fac_reng/pesototalvendido/anio/${selectedYear}`;

      // Array para almacenar las partes adicionales de la URL
      const partesUrl = [];

      // Agregar parte fija "sucursal/" a la URL
      partesUrl.push(`sucursal`);

      if (sucursalSeleccionada) partesUrl.push(`${sucursalSeleccionada.id}`);

      // Agregar parte fija "chofer/" a la URL
      partesUrl.push(`chofer`);

      if (choferSeleccionado) partesUrl.push(`${choferSeleccionado.id}`);

      // Agregar parte fija "movil/" a la URL
      partesUrl.push(`movil`);

      if (movilSeleccionado) partesUrl.push(`${movilSeleccionado.movil_id}`);

      // PROPANO BUTANO
      partesUrl.push(`probut`);

      if (codigoProBut !== "00") {
        partesUrl.push(`${codigoProBut}`);
      }

      // Agregar parte fija "peso/" a la URL
      partesUrl.push(`peso`);

      if (pesoSeleccionado) partesUrl.push(`${pesoSeleccionado.peso}`);

      // Agregar parte fija "envase/" a la URL
      partesUrl.push(`envase`);

      if (tipoEnvaseSeleccionado)
        partesUrl.push(`${tipoEnvaseSeleccionado.id}`);

      // Agregar las partes acumuladas a la URL base
      if (partesUrl.length > 0) {
        url += `/${partesUrl.join("/")}`;
      }

      // Realizar la solicitud HTTP
      const response = await fetch(url);
      const data = await response.json();

      // Crear un conjunto completo de datos para todos los meses del año
      const mesesCompletos = Array.from({ length: 12 }, (_, i) => i + 1);

      // Transformación de datos
      const formattedData = mesesCompletos.map((mes) => {
        const item = data.find((d) => d.mes === mes);
        return {
          anio: selectedYear,
          mes,
          peso_total_vendido: item
            ? parseFloat(
                item.peso_total_vendido.replace(/\./g, "").replace(",", ".")
              )
            : 0,
        };
      });

      // Actualizar los nombres visibles de los filtros del gráfico
      // Sucursal:
      const sucursalName = sucursalSeleccionada
        ? sucursalSeleccionada.name
        : null;
      setNombreSucursalSeleccionada(sucursalSeleccionada ? sucursalName : null);

      // Chofer:
      const choferName = choferSeleccionado ? choferSeleccionado.name : null;
      setNombreChoferSeleccionado(choferSeleccionado ? choferName : null);

      // Vehículo:
      const movilName = movilSeleccionado
        ? movilSeleccionado.movil_nombre
        : null;
      setNombreMovilSeleccionado(movilSeleccionado ? movilName : null);

      // Peso y Envase:
      const pesoName = pesoSeleccionado ? pesoSeleccionado.peso : null;
      const envaseName = tipoEnvaseSeleccionado
        ? tipoEnvaseSeleccionado.name
        : null;

      if (tipoEnvaseSeleccionado) {
        setNombreEnvaseSeleccionado(envaseName);
      } else if (pesoSeleccionado) {
        const pesoNameWithUnit = `${pesoName} kg`;
        setNombreEnvaseSeleccionado(pesoNameWithUnit);
      } else {
        setNombreEnvaseSeleccionado(null);
      }

      // Actualizar el estado con los datos formateados
      setChartData(formattedData);
      setLoading(false);
      setFiltrandoDatos(false);
    } catch (error) {
      console.error("Error al obtener datos del gráfico:", error);
      setLoading(false);
      setFiltrandoDatos(false);
    }
  };

  // En el componente del gráfico, filtrar los datos para los meses disponibles
  const mesesDisponibles = chartData.map((item) => item.mes);
  const seriesData = chartData
    .filter((item) => mesesDisponibles.includes(item.mes))
    .map((item) => item.peso_total_vendido);

  // ---------------------------------------------------------------

  const obtenerDatosGrafico2 = async () => {
    setFiltrandoDatos2(true);
    setLoading2(true);

    try {
      // Construir la URL base hasta el año seleccionado
      // let url = `${UrlApiEnvasado}/envasado/fac_reng/pesototalvendido/anio/${selectedYear2}`;
      let url = `${UrlApiEnvasado}/envasado/fac_reng/pesototalvendido/anio/${selectedYear2}`;

      // Array para almacenar las partes adicionales de la URL
      const partesUrl = [];

      // Agregar parte fija "sucursal/" a la URL
      partesUrl.push(`sucursal`);

      if (sucursalSeleccionada2) partesUrl.push(`${sucursalSeleccionada2.id}`);

      // Agregar parte fija "chofer/" a la URL
      partesUrl.push(`chofer`);

      if (choferSeleccionado2) partesUrl.push(`${choferSeleccionado2.id}`);

      // Agregar parte fija "movil/" a la URL
      partesUrl.push(`movil`);

      if (movilSeleccionado2) partesUrl.push(`${movilSeleccionado2.movil_id}`);

      // Agregar parte fija "peso/" a la URL
      partesUrl.push(`peso`);

      if (pesoSeleccionado2) partesUrl.push(`${pesoSeleccionado2.peso}`);

      // Agregar parte fija "envase/" a la URL
      partesUrl.push(`envase`);

      if (tipoEnvaseSeleccionado2)
        partesUrl.push(`${tipoEnvaseSeleccionado2.id}`);

      // Agregar las partes acumuladas a la URL base
      if (partesUrl.length > 0) {
        url += `/${partesUrl.join("/")}`;
      }

      // Realizar la solicitud HTTP
      const response = await fetch(url);
      const data2 = await response.json();

      // Crear un conjunto completo de datos para todos los meses del año
      const mesesCompletos = Array.from({ length: 12 }, (_, i) => i + 1);

      // Transformación de datos
      const formattedData = mesesCompletos.map((mes) => {
        const item = data2.find((d) => d.mes === mes);
        return {
          anio: selectedYear2,
          mes,
          peso_total_vendido: item
            ? parseFloat(
                item.peso_total_vendido.replace(/\./g, "").replace(",", ".")
              )
            : 0,
        };
      });

      // Actualizar los nombres visibles de los filtros del gráfico
      // Sucursal:
      const sucursalName = sucursalSeleccionada2
        ? sucursalSeleccionada2.name
        : null;
      setNombreSucursalSeleccionada2(
        sucursalSeleccionada2 ? sucursalName : null
      );

      // Chofer:
      const choferName = choferSeleccionado2 ? choferSeleccionado2.name : null;
      setNombreChoferSeleccionado2(choferSeleccionado2 ? choferName : null);

      // Vehículo:
      const movilName = movilSeleccionado2
        ? movilSeleccionado2.movil_nombre
        : null;
      setNombreMovilSeleccionado2(movilSeleccionado2 ? movilName : null);

      // Peso y Envase:
      const pesoName = pesoSeleccionado2 ? pesoSeleccionado2.peso : null;
      const envaseName = tipoEnvaseSeleccionado2
        ? tipoEnvaseSeleccionado2.name
        : null;

      if (tipoEnvaseSeleccionado2) {
        setNombreEnvaseSeleccionado2(envaseName);
      } else if (pesoSeleccionado2) {
        const pesoNameWithUnit = `${pesoName} kg`;
        setNombreEnvaseSeleccionado2(pesoNameWithUnit);
      } else {
        setNombreEnvaseSeleccionado2(null);
      }

      // Actualizar el estado con los datos formateados
      setChartData2(formattedData);
      // setLoading(false);
      setLoading2(false);
      setFiltrandoDatos2(false);
    } catch (error) {
      console.error("Error al obtener datos del gráfico:", error);
      // setLoading(false);
      setLoading2(false);
      setFiltrandoDatos2(false);
    }
  };

  // SI NO HAY DATOS, MOSTRAR "-" EN LA TABLA
  const formatImporte = (importe) => {
    if (importe === undefined || importe === null) {
      return "-";
    }

    // Convertir el importe a número y redondear
    const importeNumber = Math.round(parseFloat(importe));

    // Formatear el importe como cadena y mantener los puntos de separación de miles
    const formattedImporte = importeNumber.toLocaleString("es-AR");

    return formattedImporte;
  };

  // ***************************************************************

  // Función para obtener datos trimestrales
  const obtenerDatosTrimestrales = async () => {
    setLoading(true);
    setFiltrandoDatos(true);
    try {
      // Construir la URL base hasta el año seleccionado
      let url = `${UrlApiEnvasado}/envasado/fac_reng/pesototalvendido/trimestral/anio/${selectedYear}`;

      // Array para almacenar las partes adicionales de la URL
      const partesUrl = [];

      // Agregar parte fija "sucursal/" a la URL
      partesUrl.push(`sucursal`);

      if (sucursalSeleccionada) partesUrl.push(`${sucursalSeleccionada.id}`);

      // Agregar parte fija "chofer/" a la URL
      partesUrl.push(`chofer`);

      if (choferSeleccionado) partesUrl.push(`${choferSeleccionado.id}`);

      // Agregar parte fija "movil/" a la URL
      partesUrl.push(`movil`);

      if (movilSeleccionado) partesUrl.push(`${movilSeleccionado.movil_id}`);

      // Agregar parte fija "peso/" a la URL
      partesUrl.push(`peso`);

      if (pesoSeleccionado) partesUrl.push(`${pesoSeleccionado.peso}`);

      // Agregar parte fija "envase/" a la URL
      partesUrl.push(`envase`);

      if (tipoEnvaseSeleccionado)
        partesUrl.push(`${tipoEnvaseSeleccionado.id}`);

      // Agregar las partes acumuladas a la URL base
      if (partesUrl.length > 0) {
        url += `/${partesUrl.join("/")}`;
      }

      // Realizar la solicitud HTTP para obtener datos trimestrales
      const response = await fetch(url);
      const data = await response.json();

      // Conjunto completo de datos para todos los trimestres del año
      const trimestresCompletos = Array.from({ length: 4 }, (_, i) => i + 1);

      // Mapear los datos para formatearlos
      const formattedData = trimestresCompletos.map((trimestre) => {
        const item = data.find((d) => d.trimestre === trimestre);
        return {
          anio: selectedYear,
          trimestre,
          peso_total_vendido: item
            ? parseFloat(
                item.peso_total_vendido.replace(/\./g, "").replace(",", ".")
              )
            : 0,
        };
      });

      // Actualizar el estado con los datos formateados
      setChartData(formattedData);
      setLoading(false);
      setFiltrandoDatos(false);
    } catch (error) {
      console.error("Error al obtener datos del gráfico TRIMESTRE:", error);
      setLoading(false);
      setFiltrandoDatos(false);
    }
  };

  // ---------------------------------------------------------------

  const obtenerDatosTrimestrales2 = async () => {
    setLoading2(true);
    setFiltrandoDatos2(true);
    try {
      // Construir la URL base hasta el año seleccionado
      let url = `${UrlApiEnvasado}/envasado/fac_reng/pesototalvendido/trimestral/anio/${selectedYear2}`;

      // Array para almacenar las partes adicionales de la URL
      const partesUrl = [];

      // Agregar partes adicionales según los elementos seleccionados:
      // Agregar parte fija "sucursal/" a la URL
      partesUrl.push(`sucursal`);

      if (sucursalSeleccionada2) partesUrl.push(`${sucursalSeleccionada2.id}`);

      // Agregar parte fija "chofer/" a la URL
      partesUrl.push(`chofer`);

      if (choferSeleccionado2) partesUrl.push(`${choferSeleccionado2.id}`);

      // Agregar parte fija "movil/" a la URL
      partesUrl.push(`movil`);

      if (movilSeleccionado2) partesUrl.push(`${movilSeleccionado2.movil_id}`);

      // Agregar parte fija "peso/" a la URL
      partesUrl.push(`peso`);

      if (pesoSeleccionado2) partesUrl.push(`${pesoSeleccionado2.peso}`);

      // Agregar parte fija "envase/" a la URL
      partesUrl.push(`envase`);

      if (tipoEnvaseSeleccionado2)
        partesUrl.push(`${tipoEnvaseSeleccionado2.id}`);

      // Agregar las partes acumuladas a la URL base
      if (partesUrl.length > 0) {
        url += `/${partesUrl.join("/")}`;
      }

      // Realizar la solicitud HTTP para obtener datos trimestrales
      const response = await fetch(url);
      const data2 = await response.json();

      // Conjunto completo de datos para todos los trimestres del año
      const trimestresCompletos = Array.from({ length: 4 }, (_, i) => i + 1);

      // Mapear los datos para formatearlos
      const formattedData = trimestresCompletos.map((trimestre) => {
        const item = data2.find((d) => d.trimestre === trimestre);
        return {
          anio: selectedYear2,
          trimestre,
          peso_total_vendido: item
            ? parseFloat(
                item.peso_total_vendido.replace(/\./g, "").replace(",", ".")
              )
            : 0,
        };
      });

      // Actualizar el estado con los datos formateados
      setChartData2(formattedData);
      setLoading2(false);
      setFiltrandoDatos2(false);
    } catch (error) {
      console.error("Error al obtener datos del gráfico TRIMESTRE 2:", error);
      setLoading2(false);
      setFiltrandoDatos2(false);
    }
  };

  // ***************************************************************

  const obtenerTiposEnvasePorPeso = async (peso) => {
    try {
      const response = await fetch(
        `${UrlApiEnvasado}/envasado/stock/peso/${pesoSeleccionado.peso}`
      );
      const data = await response.json();
      setTipoEnvase(data);
    } catch (error) {
      console.error("Error al obtener tipos de envase por peso:", error);
    }
  };

  const obtenerTiposEnvasePorPeso2 = async (peso) => {
    try {
      const response = await fetch(
        `${UrlApiEnvasado}/envasado/stock/peso/${pesoSeleccionado2.peso}`
      );
      const data = await response.json();
      setTipoEnvase2(data);
    } catch (error) {
      console.error("Error al obtener tipos de envase por peso 2:", error);
    }
  };

  // ---------------------------------------------------------------

  const disableSelectedDate1 = (date) => {
    // Deshabilitar solo la fecha seleccionada en el DatePicker1
    return dayjs(date).isSame(selectedYear, "day");
  };

  // ---------------------------------------------------------------

  // DRAWER
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    props.handleDrawer();

    console.log("EJECUTO toggleDrawer");
  };

  // ***************************************************************

  const list = (anchor) => (
    <>
      <Box
        sx={{
          width: anchor === "top" || anchor === "bottom" ? "auto" : 350,
          textAlign: "center",
        }}
        role="presentation"
        onClose={toggleDrawer("right", false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <Typography
          variant="h4"
          gutterBottom
          style={{
            backgroundColor: "#1976D2",
            color: "white",
            padding: "11px",
            fontWeight: "bold",
          }}
        >
          OPCIONES
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column", // Establece la dirección de flexión en columna
          alignItems: "center", // Centra los elementos horizontalmente
          width: anchor === "top" || anchor === "bottom" ? "auto" : 350,
          textAlign: "center",
        }}
        role="presentation"
        onClose={toggleDrawer("right", false)}
      >
        <Divider
          variant="middle"
          style={{ marginBottom: "14px", width: "90%" }}
        >
          Fecha
        </Divider>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginBottom: "6px",
          }}
        >
          <Box style={{ width: "90%" }}>
            <ToggleButtonGroup
              value={intervaloTiempo}
              exclusive
              onChange={handleIntervaloTiempoChange}
              aria-label="Intervalo de tiempo"
              fullWidth // Para que ocupe todo el ancho del contenedor
            >
              <ToggleButton value="Mensual">Mensual</ToggleButton>
              <ToggleButton value="Trimestral">Trimestral</ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>

        {/* DatePicker 1 */}
        <div style={{ marginTop: "5px", marginBottom: "14px" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                label="Año"
                views={["year"]}
                value={dayjs(`${selectedYear}`)}
                onChange={(newDate) => handleYearChange(newDate)}
                slotProps={{ textField: { size: "small" } }}
                disabled={loading}
                maxDate={hoy} // FECHA MAXIMA COMO EL AÑO ACTUAL
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {realizarComparativa ? (
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                setRealizarComparativa(false); // Cancelar comparativa
                setComparativaActiva(false);
                setMostrarSegundosDatos(false);
                setSelectsHabilitados2(false);
              }}
              disabled={loading2}
            >
              Cancelar Comparativa
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={() => {
                setRealizarComparativa(true);
                setComparativaActiva(true);
              }}
            >
              Realizar Comparativa
            </Button>
          )}
        </Box>

        {/* DatePicker 2 */}
        <div style={{ marginTop: "10px" }}>
          {realizarComparativa ? (
            <>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="es"
              >
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Año"
                    views={["year"]}
                    value={dayjs(`${selectedYear2}`)}
                    onChange={(newDate) => handleYearChange2(newDate)}
                    shouldDisableDate={disableSelectedDate1}
                    slotProps={{ textField: { size: "small" } }}
                    disabled={loading2}
                    maxDate={hoy} // FECHA MAXIMA COMO EL AÑO ACTUAL
                  />
                </DemoContainer>
              </LocalizationProvider>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={mostrarSegundosDatos}
                      onChange={handleMostrarSegundosDatos}
                      color="primary"
                    />
                  }
                  label="Mostrar en Tabla"
                  style={{
                    marginLeft: "10px",
                    width: "85%", // Ancho menos el espacio para el margen izquierdo y derecho
                    backgroundColor: "#f2f2f2", // Color de fondo similar al del DatePicker
                    borderRadius: "0px 0px 6px 6px", // Bordes redondeados
                    paddingLeft: "8px", // Espacio interno
                    paddingBottom: "2px",
                  }}
                />
              </div>
            </>
          ) : (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label="Año"
                  views={["year"]}
                  disabled
                  slotProps={{ textField: { size: "small" } }}
                />
              </DemoContainer>
            </LocalizationProvider>
          )}
        </div>

        <Divider variant="middle" style={{ width: "90%", marginTop: "10px" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectsHabilitados}
                onChange={handleHabilitarSelects}
                color="primary"
                disabled={loading}
              />
            }
            label={
              <Typography
                variant={selectsHabilitados ? "body1" : "body1"}
                style={{ fontWeight: selectsHabilitados ? "bold" : "normal" }}
              >
                Filtros
              </Typography>
            }
          />
        </Divider>

        <div style={{ marginTop: "10px", marginBottom: "10px", width: "90%" }}>
          <Autocomplete
            fullWidth
            disablePortal
            options={sucursales || []}
            getOptionLabel={(option) => option.name || ""}
            value={sucursalSeleccionada}
            onChange={(_, sucursal) => handleSucursalChange(sucursal)}
            renderInput={(params) => (
              <TextField {...params} label="Sucursal" size="small" />
            )}
            disabled={!selectsHabilitados}
            style={{ marginBottom: "10px" }}
          />

          <Autocomplete
            fullWidth
            disablePortal
            options={choferes || []}
            getOptionLabel={(option) => option.name || ""}
            value={choferSeleccionado}
            onChange={(_, chofer) => handleChoferChange(chofer)}
            renderInput={(params) => (
              <TextField {...params} label="Chofer" size="small" />
            )}
            disabled={!selectsHabilitados || obteniendoChoferes}
            style={{ marginBottom: "10px" }}
          />

          <Autocomplete
            fullWidth
            disablePortal
            options={moviles || []}
            getOptionLabel={(option) =>
              `${option.movil_nombre} - ${option.patente}` || ""
            }
            value={movilSeleccionado}
            onChange={(_, movil) => handleMovilChange(movil)}
            renderInput={(params) => (
              <TextField {...params} label="Vehículo" size="small" />
            )}
            disabled={!selectsHabilitados || obteniendoMoviles}
            // disabled={!selectsHabilitados}
            style={{ marginBottom: "10px" }}
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <Box style={{ width: "100%" }}>
              <ToggleButtonGroup
                value={codigoProBut}
                exclusive
                onChange={handleCodigoProButChange}
                aria-label="Propano Butano"
                fullWidth
                size="small"
                disabled={!selectsHabilitados}
              >
                <ToggleButton value="01">Butano</ToggleButton>
                <ToggleButton value="02">Propano</ToggleButton>
                <ToggleButton value="00">Ambos</ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Box>

          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Autocomplete
                fullWidth
                style={{ width: "100%" }}
                disablePortal
                options={pesos || []}
                getOptionLabel={(option) => option.peso.toString()}
                value={pesoSeleccionado}
                onChange={(_, peso) => handlePesoChange(peso)}
                renderInput={(params) => (
                  <TextField {...params} label="kg" size="small" />
                )}
                disabled={!selectsHabilitados}
              />
            </Grid>
            <Grid item xs={9}>
              <Autocomplete
                fullWidth
                disablePortal
                options={tipoEnvase || []}
                getOptionLabel={(option) => option.name || ""}
                value={tipoEnvaseSeleccionado}
                onChange={(_, tipoEnvase) => handleTipoEnvaseChange(tipoEnvase)}
                renderInput={(params) => (
                  <TextField {...params} label="Tipo de Envase" size="small" />
                )}
                disabled={!selectsHabilitados}
              />
            </Grid>
          </Grid>

          {/* ********************************************* */}
          {/* **** AGREGAR MAS FILTROS A PARTIR DE ACA **** */}
          {/* ********************************************* */}

          {/* AGREGAR ASI ON EN EL TIPO DE ENVASE (?) */}

          <Button
            variant="contained"
            style={{ marginTop: "10px" }}
            onClick={handleClickObtenerDatosGrafico}
            disabled={!selectsHabilitados || filtrandoDatos}
            fullWidth
          >
            Filtrar Datos
          </Button>
        </div>

        <Divider variant="middle" style={{ width: "90%" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectsHabilitados2}
                onChange={handleHabilitarSelects2}
                color="primary"
                disabled={!realizarComparativa || loading2}
              />
            }
            label={
              <Typography
                variant={selectsHabilitados2 ? "body1" : "body1"}
                style={{ fontWeight: selectsHabilitados2 ? "bold" : "normal" }}
              >
                Filtros 2
              </Typography>
            }
          />
        </Divider>

        <div style={{ marginTop: "10px", marginBottom: "10px", width: "90%" }}>
          <Autocomplete
            fullWidth
            disablePortal
            options={sucursales || []}
            getOptionLabel={(option) => option.name || ""}
            defaultValue={sucursalSeleccionada2}
            value={sucursalSeleccionada2}
            onChange={(_, sucursal) => handleSucursalChange2(sucursal)}
            renderInput={(params) => (
              <TextField {...params} label="Sucursal" size="small" />
            )}
            disabled={!selectsHabilitados2}
            style={{ marginBottom: "10px" }}
          />
          <Autocomplete
            fullWidth
            disablePortal
            options={choferes2 || []}
            getOptionLabel={(option) => option.name || ""}
            value={choferSeleccionado2}
            onChange={(_, chofer) => handleChoferChange2(chofer)}
            renderInput={(params) => (
              <TextField {...params} label="Chofer" size="small" />
            )}
            disabled={!selectsHabilitados2 || obteniendoChoferes2}
            style={{ marginBottom: "10px" }}
          />
          <Autocomplete
            fullWidth
            disablePortal
            options={moviles2 || []}
            getOptionLabel={(option) =>
              `${option.movil_nombre} - ${option.patente}` || ""
            }
            value={movilSeleccionado2}
            onChange={(_, movil) => handleMovilChange2(movil)}
            renderInput={(params) => (
              <TextField {...params} label="Vehículo" size="small" />
            )}
            disabled={!selectsHabilitados2 || obteniendoMoviles2}
            style={{ marginBottom: "10px" }}
          />
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Autocomplete
                fullWidth
                style={{ width: "100%" }}
                disablePortal
                options={pesos || []}
                getOptionLabel={(option) => option.peso.toString()}
                value={pesoSeleccionado2}
                onChange={(_, peso) => handlePesoChange2(peso)}
                renderInput={(params) => (
                  <TextField {...params} label="kg" size="small" />
                )}
                disabled={!selectsHabilitados2}
              />
            </Grid>
            <Grid item xs={9}>
              <Autocomplete
                fullWidth
                disablePortal
                options={tipoEnvase2 || []}
                getOptionLabel={(option) => option.name || ""}
                value={tipoEnvaseSeleccionado2}
                onChange={(_, tipoEnvase) =>
                  handleTipoEnvaseChange2(tipoEnvase)
                }
                renderInput={(params) => (
                  <TextField {...params} label="Tipo de Envase" size="small" />
                )}
                disabled={!selectsHabilitados2}
              />
            </Grid>
          </Grid>

          <Button
            variant="contained"
            style={{ marginTop: "10px", marginBottom: "10px" }}
            onClick={handleClickObtenerDatosGrafico2}
            disabled={!selectsHabilitados2 || filtrandoDatos2}
            fullWidth
          >
            Filtrar Datos 2
          </Button>
        </div>
      </Box>
    </>
  );

  // ---------------------------------------------------------------

  const series = [
    {
      data: seriesData,
      label: "kg Vendidos",
    },
  ];

  if (realizarComparativa) {
    series.push({
      data: chartData2.map((item) => item.peso_total_vendido),
      label: "kg Vendidos 2",
    });
  }

  console.log("REALIZAR COMPARATIVA:", realizarComparativa);
  console.log("VER FILTROS:", verFiltros);

  // ---------------------------------------------------------------

  // Array con los nombres de los meses en español
  const nombresMeses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  // ---------------------------------------------------------------

  console.log("drawerOpen: ", drawerOpen);
  console.log("SUCURSAL SELECCIONADA: ", sucursalSeleccionada);
  console.log("CHOFER SELECCIONADO: ", choferSeleccionado);
  console.log("MOVIL SELECCIONADO: ", movilSeleccionado);
  console.log("PESO SELECCIONADO: ", pesoSeleccionado);
  console.log("TIPO DE ENVASE SELECCIONADO: ", tipoEnvaseSeleccionado);
  console.log("OBTENIENDO CHOFERES: ", obteniendoChoferes);
  console.log("OBTENIENDO MOVILES: ", obteniendoMoviles);

  console.log("NOMBRE SUCURSAL SELECCIONADA: ", nombreSucursalSeleccionada);
  console.log("NOMBRE CHOFER SELECCIONADA: ", nombreChoferSeleccionado);
  console.log("NOMBRE VEHICULO SELECCIONADA: ", nombreMovilSeleccionado);
  console.log("NOMBRE PESO SELECCIONADA: ", nombrePesoSeleccionado);
  console.log("NOMBRE ENVASE SELECCIONADA: ", nombreEnvaseSeleccionado);

  return (
    <>
      <div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div
            style={{
              position: "relative",
              height: "75vh",
              width: "100%",
              border: "1px solid #ddd",
              paddingBottom: "35px",
              backgroundColor: "#FFFFFF",
            }}
          >
            {loading && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(255, 255, 255, 0.5)",
                  zIndex: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress size={60} sx={{ color: "#02B2AF" }} />
              </div>
            )}

            {loading2 && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(255, 255, 255, 0.5)",
                  zIndex: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress size={45} sx={{ color: "#2E96FF" }} />
              </div>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: "#5AB3B1",
              }}
            >
              <div
                style={{
                  flex: 1,
                  border: "1px solid #ddd",
                  padding: "10px",
                }}
              >
                <b>SUCURSAL:</b>{" "}
                {nombreSucursalSeleccionada ? nombreSucursalSeleccionada : "-"}
              </div>

              <div
                style={{
                  flex: 1,
                  border: "1px solid #ddd",
                  padding: "10px",
                }}
              >
                <b>CHOFER:</b>{" "}
                {nombreChoferSeleccionado ? nombreChoferSeleccionado : "-"}
              </div>

              <div
                style={{
                  flex: 1,
                  border: "1px solid #ddd",
                  padding: "10px",
                }}
              >
                <b>VEHÍCULO:</b>{" "}
                {nombreMovilSeleccionado ? nombreMovilSeleccionado : "-"}
              </div>

              <div
                style={{
                  flex: 1,
                  border: "1px solid #ddd",
                  padding: "10px",
                }}
              >
                <b>ENVASE:</b>{" "}
                {nombreEnvaseSeleccionado
                  ? nombreEnvaseSeleccionado
                  : nombrePesoSeleccionado
                  ? nombreEnvaseSeleccionado
                  : "-"}
              </div>
            </div>

            {selectsHabilitados2 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: "#96CBFF",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    border: "1px solid #ddd",
                    padding: "10px",
                  }}
                >
                  <b>SUCURSAL 2:</b>{" "}
                  {nombreSucursalSeleccionada2
                    ? nombreSucursalSeleccionada2
                    : "-"}
                </div>

                <div
                  style={{
                    flex: 1,
                    border: "1px solid #ddd",
                    padding: "10px",
                  }}
                >
                  <b>CHOFER 2:</b>{" "}
                  {nombreChoferSeleccionado2 ? nombreChoferSeleccionado2 : "-"}
                </div>

                <div
                  style={{
                    flex: 1,
                    border: "1px solid #ddd",
                    padding: "10px",
                  }}
                >
                  <b>VEHÍCULO 2:</b>{" "}
                  {nombreMovilSeleccionado2 ? nombreMovilSeleccionado2 : "-"}
                </div>

                <div
                  style={{
                    flex: 1,
                    border: "1px solid #ddd",
                    padding: "10px",
                  }}
                >
                  <b>ENVASE 2:</b>{" "}
                  {nombreEnvaseSeleccionado2
                    ? nombreEnvaseSeleccionado2
                    : nombrePesoSeleccionado2
                    ? nombreEnvaseSeleccionado2
                    : "-"}
                </div>
              </div>
            ) : null}

            {intervaloTiempo === "Mensual" && chartData.length > 0 && (
              <div style={{ height: "100%", width: "100%" }}>
                {/* Renderizar el gráfico mensual */}
                <BarChart
                  xAxis={[
                    {
                      id: "Mes",
                      data: [
                        "Enero",
                        "Febrero",
                        "Marzo",
                        "Abril",
                        "Mayo",
                        "Junio",
                        "Julio",
                        "Agosto",
                        "Septiembre",
                        "Octubre",
                        "Noviembre",
                        "Diciembre",
                      ],
                      scaleType: "band",
                    },
                  ]}
                  series={series}
                  slotProps={{
                    legend: {
                      direction: "row",
                      position: {
                        vertical: "top",
                        horizontal: "middle",
                      },
                      itemMarkWidth: 20,
                      itemMarkHeight: 6,
                      markGap: 5,
                      itemGap: 10,
                    },
                  }}
                  style={{ height: "100%", width: "100%" }}
                  margin={{ left: 100, bottom: 100 }}
                />
              </div>
            )}

            {intervaloTiempo === "Trimestral" && chartData.length > 0 && (
              <div style={{ height: "100%", width: "100%" }}>
                {/* Renderizar el gráfico trimestral */}
                <BarChart
                  xAxis={[
                    {
                      id: "Trimestre",
                      data: [
                        "Trimestre 1",
                        "Trimestre 2",
                        "Trimestre 3",
                        "Trimestre 4",
                      ],
                      scaleType: "band",
                    },
                  ]}
                  series={series}
                  slotProps={{
                    legend: {
                      direction: "row",
                      position: {
                        vertical: "top",
                        horizontal: "middle",
                      },
                      itemMarkWidth: 20,
                      itemMarkHeight: 6,
                      markGap: 5,
                      itemGap: 10,
                    },
                  }}
                  style={{ height: "100%", width: "100%" }}
                  margin={{ left: 100, bottom: 100 }}
                />
              </div>
            )}


            {/* LINEPLOT ?
            PARA MOSTRAR LA CANTIDAD DE VENTAS */}

            {!loading && chartData.length === 0 && (
              <Box
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h2>No hay datos disponibles para mostrar la gráfica.</h2>
              </Box>
            )}
          </div>

          {/* TABLA */}
          <Box>
            {loading ? null : (
              <table
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  border: "1px solid #ddd",
                }}
              >
                <thead>
                  <tr>
                    <th
                      colSpan={mostrarSegundosDatos ? "2" : "3"}
                      style={{
                        backgroundColor: "#f2f2f2",
                        padding: "4px",
                        borderRight: "1px solid #ddd",
                      }}
                    >
                      Año
                    </th>
                    {mostrarSegundosDatos && (
                      <th
                        colSpan={"2"} // Fusionar dos columnas para el segundo año
                        style={{
                          backgroundColor: "#f2f2f2",
                          padding: "4px",
                          borderRight: "1px solid #ddd",
                          width: "auto", // Ajustar el ancho automáticamente
                          whiteSpace: "nowrap", // Evitar el salto de línea
                        }}
                      >
                        Año 2
                      </th>
                    )}
                  </tr>
                  <tr>
                    <td
                      colSpan={mostrarSegundosDatos ? "2" : "3"}
                      style={{
                        padding: "4px",
                        border: "1px solid #ddd",
                        textAlign: "center", // Alinear horizontalmente al centro
                        verticalAlign: "middle", // Alinear verticalmente al centro
                      }}
                    >
                      {chartData.length > 0 && chartData[0].anio}
                    </td>
                    {mostrarSegundosDatos && (
                      <td
                        colSpan={"1"} // Fusionar las celdas para mostrar el segundo año
                        style={{
                          padding: "4px",
                          border: "1px solid #ddd",
                          textAlign: "center", // Alinear horizontalmente al centro
                          verticalAlign: "middle", // Alinear verticalmente al centro
                        }}
                      >
                        {/* Renderizar el valor del segundo año aquí */}
                        {chartData2.length > 0 && chartData2[0].anio}
                      </td>
                    )}
                  </tr>
                  <tr>
                    {intervaloTiempo === "Mensual" && (
                      <th
                        colSpan={"1"} // Fusionar las celdas si hay comparativa activa
                        style={{
                          backgroundColor: "#f2f2f2",
                          padding: "4px",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        Mes
                      </th>
                    )}
                    {intervaloTiempo === "Trimestral" && (
                      <th
                        colSpan={"1"} // Fusionar las celdas si hay comparativa activa
                        style={{
                          backgroundColor: "#f2f2f2",
                          padding: "4px",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        Trimestre
                      </th>
                    )}

                    <th
                      colSpan={"1"} // Fusionar las celdas si hay comparativa activa
                      style={{
                        backgroundColor: "#f2f2f2",
                        padding: "4px",
                        borderRight: "1px solid #ddd",
                      }}
                    >
                      Kilogramos
                    </th>
                    {/* Renderizar las columnas adicionales si la comparativa está activa */}
                    {mostrarSegundosDatos && (
                      <>
                        <th
                          colSpan="2" // Fusionar dos columnas para la segunda comparativa
                          style={{
                            backgroundColor: "#f2f2f2",
                            padding: "4px",
                            borderRight: "1px solid #ddd",
                            width: "auto", // Ajustar el ancho automáticamente
                            whiteSpace: "nowrap", // Evitar el salto de línea
                          }}
                        >
                          Kilogramos 2{" "}
                        </th>
                      </>
                    )}
                  </tr>
                </thead>

                <tbody>
                  {intervaloTiempo === "Mensual" &&
                    nombresMeses.map((nombreMes, index) => {
                      const chartItem = chartData.find(
                        (item) => item.mes === index + 1
                      );
                      const chartItem2 = chartData2
                        ? chartData2.find((item) => item.mes === index + 1)
                        : null;
                      return (
                        <tr
                          key={index}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "#f9f9f9" : "#ffffff",
                            border: "1px solid #ddd",
                          }}
                        >
                          <td
                            colSpan={"1"} // Fusionar las celdas para mostrar el año
                            style={{
                              padding: "6px",
                              borderRight: "1px solid #ddd",
                            }}
                          >
                            {nombreMes}
                          </td>
                          <td
                            colSpan={"1"} // Fusionar las celdas para mostrar el año
                            style={{
                              padding: "6px",
                              borderRight: "1px solid #ddd",
                            }}
                          >
                            {chartItem
                              ? formatImporte(chartItem.peso_total_vendido)
                              : "-"}
                          </td>
                          {/* Renderizar las columnas adicionales si la comparativa está activa */}
                          {mostrarSegundosDatos && (
                            <>
                              <td
                                style={{
                                  padding: "6px",
                                  borderRight: "1px solid #ddd",
                                }}
                              >
                                {chartItem2
                                  ? formatImporte(chartItem2.peso_total_vendido)
                                  : "-"}
                              </td>
                            </>
                          )}
                        </tr>
                      );
                    })}

                  {intervaloTiempo === "Trimestral" &&
                    chartData.map((chartItem, index) => {
                      const chartItem2 = chartData2 ? chartData2[index] : null;
                      return (
                        <tr
                          key={index}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "#f9f9f9" : "#ffffff",
                            border: "1px solid #ddd",
                          }}
                        >
                          <td
                            colSpan={"1"} // Fusionar las celdas para mostrar el trimestre
                            style={{
                              padding: "6px",
                              borderRight: "1px solid #ddd",
                              textAlign: "center",
                            }}
                          >
                            {chartItem.trimestre}
                          </td>
                          <td
                            colSpan={"1"} // Fusionar las celdas para mostrar el peso total vendido
                            style={{
                              padding: "6px",
                              borderRight: "1px solid #ddd",
                            }}
                          >
                            {chartItem
                              ? formatImporte(chartItem.peso_total_vendido)
                              : "-"}
                          </td>
                          {/* Renderizar la columna adicional si la comparativa está activa */}
                          {mostrarSegundosDatos && (
                            <>
                              <td
                                style={{
                                  padding: "6px",
                                  borderRight: "1px solid #ddd",
                                }}
                              >
                                {chartItem2
                                  ? formatImporte(chartItem2.peso_total_vendido)
                                  : "-"}
                              </td>
                            </>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
          </Box>
        </div>

        <div>
          <React.Fragment>
            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={toggleDrawer("right", false)}
              ModalProps={{
                style: { zIndex: 1300 },
              }}
            >
              {list("right")}
            </Drawer>
          </React.Fragment>
        </div>
      </div>
    </>
  );
}
