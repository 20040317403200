// Developer     : Magalí Perea
// Creation Date : Febrero 2024
// Version       : 1
// Description   : Este componente desarrolla la tabla de factibilidades junto con las posibles acciones que se podrán tomar sobre ellas dependiendo del rol del usuario, y el estado en el que se encuentre la factibilidad.
// PERMISOS/REQUERIMIENTOS ACTUALES DE CADA ACCIÓN POSIBLE DESDE ESTE COMPONENTE:
// - Configurar parámetros de factibilidad:
//      - Tener usuario Administrador
// - Crear factibilidad:
//      - En la tabla "users", pertenecer al sector "comercial granel" (id 13)
//      - No tener usuario Administrador
// - Modificar factibilidad:
//      - Ser el usuario que la creó
//      - La factibilidad debe estar en estado "pendiente" o "desaprobada"
// - Eliminar factibilidades:
//      - Ser el usuario que la creó.
//      - La factibilidad debe estar en estado "pendiente"
// - Ver:
//      - Tener usuario administrador O
//      - Tener factibilidades=1 en la tabla "users" O
//      - Tener usuario comun pero que la factibilidad tenga como estado "aprobada" o "pendiente de aprobación crediticia"

//Estados de factibilidad en BD(tabla desplegables):
// # id	valor   	                          modulo
// 198..Pendiente...........................factibilidades
// 199..Aprobada............................factibilidades
// 212..Desaprobada.........................factibilidades
// 219..Pendiente Revisión Crediticia.......factibilidades

import React, { useState, useEffect, useMemo } from "react";
import Navbar from "../../components/Navbar";
import { UrlApi } from "../../services/apirest";
import {
  Container,
  Typography,
  ListItemIcon,
  Button,
  Tooltip,
  MenuItem,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import PeopleIcon from "@mui/icons-material/People";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import AddFact from "./alta/AddFact";
import FactConfig from "./config/FactConfig.js";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ModFact from "./modificacion/ModFact";
import DeleteIcon from "@mui/icons-material/Delete";
import * as Save from "./savefunctions";
import DeleteDialog from "../Dialogs/DeleteDialog";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmDialog from "../Dialogs/ConfirmDialog";
import moment from "moment";
import FormInicioFact from "./alta2/FormInicioFact";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import Stack from "@mui/material/Stack";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";

function FactibilidadesTable() {
  const esAdmin = localStorage.getItem("role") === "admin";
  const localStorageFactib = localStorage.getItem("factib");
  const sector = localStorage.getItem("sector");

  const [factibilidades, setFactibilidades] = useState([{}]);
  const roles = localStorage.getItem("role");
  const usuario_registra = localStorage.getItem("user");
  const [showTable, setShowTable] = useState(true);
  const [showAdd, setShowAdd] = useState(false);
  const [showMod, setShowMod] = useState(false);
  const [showConfig, setShowConfig] = useState(false);
  const [factibilidadSelected, setFactibilidadSelected] = useState({});
  const [showAprobar, setShowAprobar] = useState(false);
  const [showDesaprobar, setShowDesaprobar] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showVer, setShowVer] = useState(false);
  const [notify, setNotify] = useState({
    open: false,
    severity: "",
    color: "",
    mensaje: "",
  });
  const [pendingFactsBadge, setPendingFactBadge] = useState(0);

  const getFactibilidades = () => {
    fetch(
      UrlApi +
        "/factibilidades/" +
        (esAdmin || localStorageFactib === "1" ? "/" : usuario_registra)
    )
      .then((data) => data.json())
      .then((data) => {
        setFactibilidades(data);
        // console.log(data)
        // const algo=data.filter((fact)=>fact.id_estado===198)
        // console.log(algo)
        setPendingFactBadge(
          data.filter(
            (fact) => fact.id_estado === 198 || fact.id_estado === 219
          ).length
        );
      });
  };

  // console.log(pendingFactsBadge);

  useEffect(() => {
    roles && usuario_registra && getFactibilidades();
  }, [
    roles,
    usuario_registra,
    showTable,
    showAprobar,
    showDesaprobar,
    showDelete,
  ]);

  async function eliminar(e) {
    Save.deleteFact(
      e,
      "/factibilidades/eliminarUpdtd/" +
        factibilidadSelected.factuuid +
        "/" +
        factibilidadSelected.id_tipofact,
      setNotify
    );
  }

  // console.log("FACTIBILIDAD SELECCIONADA: " , factibilidadSelected)

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Nro",
        size: 50,
      },
      {
        accessorKey: "registrado_format",
        header: "Fecha Carga",
        size: 50,
        // type:"date"
      },
      {
        accessorKey: "razon_social",
        header: "Cliente",
        size: 400,
      },
      // {
      //   accessorKey: "cli_sucursal",
      //   header: "Sucursal Cliente",
      //   size: 200,
      // },
      {
        accessorKey: "usuario_registra",
        header: "RRCC",
      },
      {
        accessorKey: "estado",
        header: "Estado",
      },
      {
        accessorKey: "tipo_factibilidad",
        header: "Tipo",
      },
    ],
    []
  );

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "primary",
    size: "small",
  };

  const initialState = {
    density: "compact",
    showGlobalFilter: true,
    pagination: {
      pageSize: 15,
      pageIndex: 0,
    },
  };

  const tableHeadCellProps = {
    align: "center",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };

  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem",
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const rowBg = {
    198: "#F0B27A", //pendiente
    199: "#b3e6b3", //revisado -> natural
    212: "#efc3c3",
  };

  return (
    <div>
      <Navbar />
      {factibilidades && showTable ? (
        <Container maxWidth="xl">
          <Box mt={2} boxShadow={3}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableColumnResizing
                columns={columns}
                data={factibilidades}
                enableRowActions
                initialState={initialState}
                positionActionsColumn="last"
                muiTableHeadCellProps={tableHeadCellProps}
                localization={MRT_Localization_ES}
                muiTableBodyCellProps={({ row }) => ({
                  align: "left",
                  sx: {
                    backgroundColor: rowBg[row.original.id_estado] ?? "#fff",
                    color: "black",
                    borderColor: "black",
                  },
                })}
                enableColumnActions={false}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                enableDensityToggle={false}
                layoutMode="grid"
                renderTopToolbarCustomActions={() => (
                  <>
                    {!esAdmin &&
                      sector === "13" && ( //Si el usuario no es administrador, y es del sector "comerciales", puede registrar una nueva factibilidad:
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<AddBoxTwoToneIcon color="asd" />}
                          onClick={() => {
                            setShowAdd(true);
                            setShowTable(false);
                          }}
                        >
                          Nueva Factibilidad
                        </Button>
                      )}
                    <Tooltip
                      title="Factibilidades pendientes de revisión."
                      placement="bottom-end"
                    >
                      <Typography variant="poster" fontSize={26}>
                        {/* <PeopleIcon sx={{ fontSize: 20 }} /> */}
                        Factibilidades
                        {esAdmin && (
                          <Badge
                            color="warning"
                            badgeContent={pendingFactsBadge}
                            sx={{ ml: 1 }}
                          >
                            <PendingActionsIcon
                              color={
                                pendingFactsBadge > 0 ? "warning" : "primary"
                              }
                            />
                          </Badge>
                        )}
                      </Typography>
                    </Tooltip>
                  </>
                )}
                renderToolbarInternalActions={({ table }) => (
                  <>
                    {esAdmin ? ( //Solo puede ver la configuración si es ADMINISTRADOR
                      <Tooltip title="CONFIGURAR FACTIBILIDAD" color="primary">
                        <IconButton
                          onClick={() => {
                            setShowConfig(true);
                            setShowTable(false);
                          }}
                        >
                          <MiscellaneousServicesIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}

                    <MRT_FullScreenToggleButton table={table} />
                  </>
                )}
                renderRowActionMenuItems={({ closeMenu, row, index }) => [
                  // <MenuItem
                  //   style={{
                  //     display: !esAdmin ? "none" : null,
                  //   }}
                  //   disabled={row.original.id_estado === 199}
                  //   onClick={() => {
                  //     closeMenu();
                  //     setShowAprobar(true);
                  //     setFactibilidadSelected(row.original);
                  //   }}
                  //   sx={{ m: 0 }}
                  // >
                  //   <ListItemIcon>
                  //     <ThumbUpIcon color="success" />
                  //   </ListItemIcon>
                  //   Aprobar
                  // </MenuItem>,
                  // <MenuItem
                  //   style={{
                  //     display: !esAdmin ? "none" : null,
                  //   }}
                  //   disabled={row.original.id_estado !== 198}
                  //   onClick={() => {
                  //     closeMenu();
                  //     setShowDesaprobar(true);
                  //     setFactibilidadSelected(row.original);
                  //   }}
                  //   sx={{ m: 0 }}
                  // >
                  //   <ListItemIcon>
                  //     <ThumbDownAltIcon color="warning" />
                  //   </ListItemIcon>
                  //   Desaprobar
                  // </MenuItem>,
                  // SOLO PUEDEN "ELIMINAR" FACTIBILIDADES, USUARIOS QUE NO SEAN ADMINISTRADORES
                  <MenuItem
                  style={{
                    display:
                      (row.original.id_estado === 198 ||
                        row.original.id_estado === 212) &&
                      row.original.usuario_registra ===
                        localStorage.getItem("user")
                        ? null
                        : "none",
                  }}
                  onClick={() => {
                    closeMenu();
                    setShowTable(false);
                    setShowMod(true);
                    setFactibilidadSelected(row.original);
                  }}
                  sx={{ m: 0 }}
                >
                  <ListItemIcon>
                    <EditIcon color="primary" />
                  </ListItemIcon>
                  Editar
                </MenuItem>,
                
                  <MenuItem
                    style={{
                      display:
                        row.original.id_estado === 198 &&
                        row.original.usuario_registra ===
                          localStorage.getItem("user")
                          ? null
                          : "none",
                    }}
                    onClick={() => {
                      closeMenu();
                      setShowDelete(true);
                      setFactibilidadSelected(row.original);
                    }}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <DeleteIcon color="error" />
                    </ListItemIcon>
                    Eliminar
                  </MenuItem>,

                  <MenuItem
                    style={{
                      //Esta opcion estará disponible cuando:
                      display:
                        esAdmin || //El usuario sea administrador
                        localStorageFactib === "1" ||
                        row.original.id_estado === 199 ||
                        row.original.id_estado === 219 //Porque son los estados donde la factibilidad ya no se puede editar, entocnes "ver", es la unica forma de verla para el usuario que la cargó.
                          ? null
                          : "none",
                    }}
                    onClick={() => {
                      closeMenu();
                      setShowTable(false);
                      setShowVer(true);
                      setFactibilidadSelected(row.original);
                    }}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <RemoveRedEyeIcon color="secondary" />
                    </ListItemIcon>
                    Ver
                  </MenuItem>,
                ]}
              />
            </ThemeProvider>
          </Box>
          <br />
          <br />
        </Container>
      ) : null}
      {showConfig ? (
        <FactConfig setShowConfig={setShowConfig} setShowTable={setShowTable} />
      ) : null}
      {/* {showAdd ? (
        <AddFact setShowTable={setShowTable} setShowAdd={setShowAdd} />
      ) : null} */}
      {showAdd ? (
        <FormInicioFact setShowTable={setShowTable} setShowAdd={setShowAdd} />
      ) : null}
      {showMod ? (
        <ModFact
          fact={factibilidadSelected}
          setShowTable={setShowTable}
          setShowMod={setShowMod}
        />
      ) : null}
      {/* {showAprobar ? (
        <ConfirmDialog
          title={"Aprobar factibilidad"}
          subtitle={"Antes de continuar, tenga en cuenta: "}
          open={showAprobar}
          setOpen={setShowAprobar}
          notify={notify}
          setNotify={setNotify}
          btnText={"Aprobar"}
          icon={iconAprobar}
          guardar={aprobar}
          color={"#2ECC71"}
          items={[
            "Se guardará el cliente",
            "Se guardará el domicilio de entrega",
            "Se aprobará la factibilidad",
          ]}
        />
      ) : null}
      {showDesaprobar ? (
        <ConfirmDialog
          title={"Desaprobar Factibilidad"}
          open={showDesaprobar}
          setOpen={setShowDesaprobar}
          notify={notify}
          setNotify={setNotify}
          btnText="Desaprobar"
          icon={iconDesaprobar}
          guardar={desaprobar}
          color={"#f53649"}
          subtitle={`¿Está seguro que desea desaprobar la factibilidad N° ${factibilidadSelected.id}? `}
        />
      ) : null} */}
      {showDelete ? (
        <DeleteDialog
          title={"Eliminar Factibilidad"}
          confirmText={"¿Confirma que desea eliminar la factibilidad?"}
          btnText="Confirmar"
          notify={notify}
          setNotify={setNotify}
          open={showDelete}
          setOpen={setShowDelete}
          guardar={eliminar}
        />
      ) : null}
      {showVer ? (
        <ModFact
          fact={factibilidadSelected}
          setShowTable={setShowTable}
          setShowMod={setShowVer}
          readOnly={true}
        />
      ) : null}
    </div>
  );
}

export default FactibilidadesTable;
