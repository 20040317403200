import React, { useState, useEffect, useMemo } from "react";
import { UrlApi } from "../../../services/apirest";
import Navbar from "../../Navbar";
import Editarfabricante from "./Editarfabricante";
import Addfabricantes from "./Addfabricantes";
import { Container, Typography, Button, Tooltip, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Stack, Box, Grid } from "@mui/material";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import BlockIcon from "@mui/icons-material/Block";
import Notify from "../../Notify";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import MaterialReactTable, { MRT_FullScreenToggleButton } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import EditIcon from '@mui/icons-material/Edit';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';

export default function Fabricantes() {
    const [fabricantes, setFabricantes] = useState(null); //Guarda el listado de fabricantes que viene de la API
    const [tabla, setTabla] = useState(true); // este estado muestra la tabla o no
    const [alta, setAlta] = useState(false); // este estado muestra la pantalla de alta o no
    const [editar, setEditar] = useState(false); // este estado muestra la pantalla de edicion o no
    const [datosformulario, setDatosformulario] = useState({});
    const [open, setOpen] = useState(false); //estado para el dialog para dar de baja
    const globalTheme = useTheme();
    const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
    const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
    const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
    const [error, setError] = useState(null); //guardará el error que lance la api (si existe)  
    const [hiddenmuestrainactivo, setHiddenmuestrainactivo] = useState(false);
    const [hiddenmuestraactivos, setHiddenmuestraactivos] = useState(true);

    //Funcion para traer el listado de fabricantes de la api
    const obtenerFabricantes = () => {
        setHiddenmuestraactivos(true);
        setHiddenmuestrainactivo(false);
        fetch(UrlApi + "/fabricantes") // Hago un fetch para traerme de la api el listado de fabricantes
            .then((data) => data.json()) //luego de que me los traigo , los pongo en formato json
            .then((data) => setFabricantes(data)); // luego, guardo en fabricantes lo que me quedo en data
    };

    const obtenerInactivos = () => {
        setHiddenmuestrainactivo(true);
        setHiddenmuestraactivos(false);
        fetch(UrlApi + '/fabricantes/inactivos')
            .then(data => data.json())
            .then(data => setFabricantes(data))
    };

    //Con este useEffect , ejecuto la funcion "obtener fabricantes", solo una vez. Carga la tabla
    useEffect(() => {
        obtenerFabricantes();
    }, [tabla]);

    const handleClickOpen = (rowData) => {
        setOpen(true);
        setDatosformulario(rowData);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const marcarInactivo = async (e) => {
        e.preventDefault();
        const response = await fetch(UrlApi + "/fabricantes/" + datosformulario.id, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(datosformulario.activo === 0 ? { activo: 1 } : { activo: 0 }
            ),
        })
        if (response.ok === false) {
            const error = response && response.json();
            setError(error);
            alert("Inconvenientes con la API. No se pudo guardar. ");
        } else {
            try {
                const json = await response.json();
                if (json.message) {
                    setNotificacion(true);
                    setOpenSnack(true);
                    setMensaje(
                        json.message === "OK" ? "Guardado" : "Guardado. Actualizar RTA Api."
                    );
                    obtenerFabricantes()
                } else if (json.sqlMessage) {
                    json && setError(json);
                    alert("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
                } else {
                    alert("ERROR DESCONOCIDO.");
                }
            } catch (error) {
                if (error.toString().includes("is not valid JSON")) {
                    setNotificacion(true);
                    setOpenSnack(true);
                    setMensaje("Guardado");
                    obtenerFabricantes()
                } else {
                    alert("Error: " + error);
                }
            }
        }
        handleClose()
    };

    //Funcion que se ejecuta cuando le doy clic a Nuevo
    const handleClickNuevo = (props) => {
        setTabla(false);
        setAlta(true);
    };

    //Funcion que se ejecuta cuando en la tabla le doy clic a Editar
    const handleClickEditar = (rowdata) => {
        setTabla(false);
        setEditar(true);
        setDatosformulario(rowdata);
    };

    const columns = useMemo(() =>
        [{ header: 'Fabricante', accessorKey: 'nombre' },
        { header: 'Contacto', accessorKey: 'contacto' },

        ], []);

    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: globalTheme.palette.mode,
                    primary: globalTheme.palette.primary, //primary color for the table
                    info: {
                        main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            globalTheme.palette.mode === "light"
                                ? "rgb(234, 239, 241)"
                                : "#000", //pure black table in dark mode for fun
                    },
                },
                typography: {
                    button: {
                        textTransform: "none", //customize typography styles for all buttons in table by default
                        fontSize: "1rem",
                    },
                },
                components: {
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                fontSize: "0.8rem", //larger tooltip font size                                
                            },
                        },
                    },
                },

            }), [globalTheme]);

    const muiSearchTextFieldProps = {
        placeholder: "Buscar..",
        sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
        variant: "outlined",
        color: "success",
        size: "small",
    };   

    const tableHeadCellProps = {
        align: "center",
        sx: {
            fontSize: 16,
            color: "white",
            fontWeight: "normal",
            // paddingTop: 1,
            // paddingBottom: 1,
            backgroundColor: "#2471A3",
            borderColor: "black",
        },
    };

    const initialState = {
        density: "compact",
        showGlobalFilter: true,
        pagination: { pageIndex: 0, pageSize: 15 }
    };

    return (
        <div>
            <Navbar />
            <Container maxWidth="xl" mt={1}>
                {fabricantes && tabla ? (
                    <Box mt={2} mb={3} boxShadow={3}>
                        <ThemeProvider theme={tableTheme}>
                            <MaterialReactTable
                                enableColumnResizing
                                columns={columns}
                                data={fabricantes}
                                initialState={initialState}
                                positionActionsColumn="last"
                                enableExpandAll={false}
                                enableToolbarInternalActions={true}
                                muiTableHeadCellProps={tableHeadCellProps}
                                muiTableBodyCellProps={({ row }) => ({
                                    align: "left",
                                    sx: {
                                        backgroundColor:
                                            row.original.activo === 1 ? "#afdbaf" : "#e39898",
                                        color: "black",
                                        borderColor: "black",
                                    },
                                })}
                                enableColumnActions={false}
                                muiSearchTextFieldProps={muiSearchTextFieldProps}
                                enableDensityToggle={false}
                                enableEditing
                                renderToolbarInternalActions={(
                                    { table }) => (
                                    <>
                                        {!hiddenmuestraactivos ?
                                            <Tooltip title="Ver fabricantes activos" >
                                                <IconButton color="success" onClick={() => {
                                                    obtenerFabricantes();
                                                }}>
                                                    <PersonIcon />
                                                </IconButton>
                                            </Tooltip>
                                            : null}

                                        {!hiddenmuestrainactivo ?
                                            <Tooltip title="Ver fabricantes inactivos">
                                                <IconButton color="error" onClick={() => {
                                                    obtenerInactivos();
                                                }}>
                                                    <PersonOffIcon />
                                                </IconButton>
                                            </Tooltip>
                                            : null}

                                        <MRT_FullScreenToggleButton table={table} />
                                    </>
                                )}
                                renderRowActions={({ row, table }) => (
                                    <div>
                                        <Tooltip title="Editar fabricante">
                                            <IconButton
                                                onClick={() => handleClickEditar(row.original)}
                                                color="primary"
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                        {(row.original.activo === 1) ?
                                            <Tooltip title="Marcar como inactivo">
                                                <IconButton
                                                    onClick={() => handleClickOpen(row.original)}
                                                    color="error"
                                                >
                                                    <BlockIcon />
                                                </IconButton>
                                            </Tooltip>
                                            :
                                            <Tooltip title="Marcar como activo">
                                                <IconButton
                                                    onClick={() => handleClickOpen(row.original)}
                                                    color="success"
                                                >
                                                    <CheckCircleOutlineIcon />
                                                </IconButton>
                                            </Tooltip>


                                        }


                                    </div>
                                )}
                                renderTopToolbarCustomActions={() => (
                                    <>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<AddBoxTwoToneIcon />}
                                            onClick={handleClickNuevo}
                                        >
                                            Nuevo Registro
                                        </Button>
                                        <Typography variant="poster" fontSize={26}>
                                            <PeopleIcon sx={{ size: 20 }} />
                                            Fabricantes
                                        </Typography>
                                    </>
                                )}
                                layoutMode="grid"
                                localization={MRT_Localization_ES}
                                enableColumnDragging={false}
                                enableColumnFilters={false} //desactiva filtros x  columna
                                enableHiding={false} />
                        </ThemeProvider>
                    </Box>
                ) : null}
            </Container>
                                    <br/>
            <Dialog open={open} onClose={handleClose}>
                <form onSubmit={marcarInactivo}>
                    <Box backgroundColor={"#1F618D"}>
                        <DialogTitle>
                            {datosformulario.activo === 0 ?
                                <Typography variant="h6" align="center"  color="#ffffff">
                                    Alta del fabricante {datosformulario.nombre} 
                                </Typography>
                                :
                                <Typography variant="h6" align="center" color="#ffffff">
                                    Baja del fabricante {datosformulario.nombre} 
                                </Typography>
                            }
                        </DialogTitle>
                    </Box>
                    <DialogContent>
                        <DialogContentText textAlign={"center"}>
                            {datosformulario.activo === 0 ?
                                <> ¿Desea dar de alta al fabricante + {datosformulario.nombre}? </>
                                :
                                <> ¿Desea dar de baja al fabricante {datosformulario.nombre}? </>
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Grid container direction="row" justifyContent="space-between" alignItemns="center">
                            <Button
                                variant="contained"
                                onClick={handleClose}
                                style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
                            >
                                Cancelar
                            </Button>
                            <Button
                                style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                Confirmar
                            </Button>
                        </Grid>
                    </DialogActions>
                </form>
            </Dialog>
            {
                notificacion ? (
                    <Notify
                        mensaje={mensaje}
                        open={openSnack}
                        color={"#D4EFDF"}
                        severity={"success"}
                        handleClose={handleClose}
                    />
                ) : null
            }
            {
                alta ? (
                    <Addfabricantes alta={setAlta} tabla={setTabla}></Addfabricantes>
                ) : null
            }
            {
                editar ? (
                    <Editarfabricante
                        editar={setEditar}
                        tabla={setTabla}
                        datos={datosformulario}
                    />
                ) : null
            }
        </div >
    )



}
