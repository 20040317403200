import React from "react";
import {
  Grid,
  Box,
  InputAdornment,
  TextField,
  Container,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import PersonIcon from "@mui/icons-material/Person";
import Button from "@mui/material/Button";
import axios from "axios";
import { FormatListBulleted } from "@mui/icons-material";
import { UrlApi } from "../../../services/apirest";
import Inscripciones from "./Inscripciones";
import FileUploadMultiple from "../../../services/UploadFiles";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import Notify from "../../Notify";

import { postData, putData } from "../../../services/library";
import HasInscripcion from "./DialogHasIns";

const IIBB = (props) => {
  const [TablaIIBB, setTablaIIBB] = useState(true);
  const [openclose, setopenclose] = useState(false);
  const [tiposIIBB, setTiposIIBB] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [proveedor, setProveedor] = useState(props.proveedor);
  const [regimenesIIBB, setRegimenesIIBB] = useState([]);
  const [showInscripcionesIIBB, setShowInscripcionesIIBB] = useState(false);

  const [openExisteIns, setopenExisteIns] = useState(false);
  const [InscripcionBefore, setInscripcionBefore] = useState([]);

  const [condicionIIBB, setCondicionIIBB] = useState({
    id: null,
    nro_iibb: null,
    regimen_iibb_id: null,
    id_tipo_iibb: null,
    fecha_vto: null,
    id_proveedor: proveedor.id,
  });

  const [notificacion, setNotificacion] = useState({
    open: null,
    severity: null,
    mensaje: null,
    color: null,
  });

  /**
   * The function updates the state of a variable called "condicionIIBB" based on the key-value pair
   * passed as arguments and handles onChange events for certain inputs.
   * @param key - a string representing the key of the object property to be updated in the state.
   * @param value - The new value that will be assigned to the property with the specified key in the
   * state object.
   */
  const almacenar = (e) => {
    setCondicionIIBB({
      ...condicionIIBB,
      [e.target.name]: e.target.value,
    });
  };

  const guardarCondicionIIBB = async (event) => {
    if (condicionIIBB.id === null) {
      await postData(
        event,
        "/condiciones_iibb/" + proveedor.id,
        condicionIIBB,
        setNotificacion
      );
      props.onSuccess();
    } else {
      await putData(
        event,
        "/condiciones_iibb/" + proveedor.id,
        condicionIIBB,
        setNotificacion
      );
      props.onSuccess();
    }

    setTimeout(() => {
      HandleVolver();
    }, 2000);
  };
  const getTiposIIBB = () => {
    try {
      setisLoading(true);
      axios
        .get(UrlApi + "/tipos_iibb")
        .then((res) => {
          setTiposIIBB(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    } finally {
      setisLoading(false);
    }
  };

  const getRegimenes = () => {
    try {
      setisLoading(true);
      axios
        .get(UrlApi + "/regimenes_iibb/" + condicionIIBB?.id_tipo_iibb)
        .then((res) => {
          setRegimenesIIBB(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    } finally {
      setisLoading(false);
    }
  };

  const getCondicionIIBBProveedor = () => {
    try {
      setisLoading(true);
      axios
        .get(UrlApi + "/condiciones_iibb/" + proveedor.id)
        .then((res) => {
          if (res.data[0]) {
            setCondicionIIBB(res.data[0]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    } finally {
      setisLoading(false);
    }
  };

  useEffect(() => {
    getCondicionIIBBProveedor();
    getTiposIIBB();
  }, [proveedor]);

  useEffect(() => {
    getRegimenes();
  }, [condicionIIBB.id_tipo_iibb]);

  function HandleVolver() {
    props.alta(false);
    props.tabla(true);
  }
  const HandleInscripciones = () => {
    fetch(UrlApi + "/inscripcion_proveedor/" + proveedor.id)
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.length > 0) {
          setInscripcionBefore(data);
          setopenExisteIns(true);
        } else {
          setTablaIIBB(false);
          setShowInscripcionesIIBB(true);
        }
      });
  };

  const AgregarIns = () => {
    setopenExisteIns(false);
    setShowInscripcionesIIBB(true);
    setTablaIIBB(false);
  };
  const closeDialogIns = () => {
    setopenExisteIns(false);
    setTablaIIBB(true);
  };

  console.log("Condiciones IIBB", condicionIIBB);
  // console.log("Regimenes IIBB", regimenesIIBB);
  // console.log("Tipos IIBB", tiposIIBB);

  return (
    <div>
      {notificacion ? (
        <Notify
          mensaje={notificacion.mensaje}
          open={notificacion.open}
          color={notificacion.color}
          severity={notificacion.severity}
        />
      ) : null}

      {TablaIIBB ? (
        <Container maxWidth="xl">
          {" "}
          <Box sx={{ flexGrow: 1, backgroundColor: "#1F618D" }}>
            <Grid container p={1.5} mt={4}>
              <Grid
                // item
                xs={12}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="h5" color="#ffffff">
                  Ingresos Brutos
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box
            mb={3}
            backgroundColor="#F4F6F6"
            p={3}
            border={0.5}
            borderColor={"#ABB2B9"}
            borderRadius={4}
          >
            <form onSubmit={(e) => guardarCondicionIIBB(e)}>
              <Grid
                container
                display="flex"
                alignItems="center"
                justifyContent="center"
                spacing={2}
                mb={1}
              >
                {!isLoading ? (
                  <Grid item xs={2}>
                    <FormControl fullWidth focused>
                      <InputLabel standard> Tipo IIBB </InputLabel>
                      <Select
                        mt={2}
                        id="id_tipo_iibb"
                        focused
                        label="Tipo de IIBB"
                        name="id_tipo_iibb"
                        value={condicionIIBB.id_tipo_iibb}
                        onChange={(e) => almacenar(e)}
                      >
                        {tiposIIBB &&
                          tiposIIBB.map((tipoIIBB) => (
                            <MenuItem key={tipoIIBB.id} value={tipoIIBB.id}>
                              {tipoIIBB.descripcion}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                ) : (
                  <Box justifyContent={"center"} margin={"auto "}>
                    <CircularProgress />
                  </Box>
                )}

                {!isLoading ? (
                  <Grid item xs={2}>
                    <FormControl fullWidth focused>
                      <InputLabel standard> Régimen IIBB </InputLabel>
                      <Select
                        mt={2}
                        id="regimen_iibb_id"
                        focused
                        label="Regimen IIBB"
                        name="regimen_iibb_id"
                        value={condicionIIBB.regimen_iibb_id}
                        onChange={almacenar}
                      >
                        {regimenesIIBB &&
                          regimenesIIBB.map((regimenIIBB) => (
                            <MenuItem
                              key={regimenIIBB.id}
                              value={regimenIIBB.id}
                            >
                              {regimenIIBB.descripcion}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                ) : (
                  <Box justifyContent={"center"} margin={"auto "}>
                    <CircularProgress />
                  </Box>
                )}

                {condicionIIBB?.regimen_iibb_id === 3 ? (
                  <>
                    <Grid item xs={2}>
                      <TextField
                        id="fecha Vto"
                        label="Fecha Vto"
                        fullWidth
                        focused
                        name="fecha_vto"
                        value={condicionIIBB.fecha_vto}
                        onChange={almacenar}
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        onClick={() => setopenclose(!openclose)}
                        variant="outlined"
                        color="primary"
                        fullWidth
                        startIcon={<AttachFileIcon />}
                      >
                        Carga de Documentos
                      </Button>
                    </Grid>
                  </>
                ) : null}
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    mt={2}
                    id="nro_iibb"
                    label="N° IIBB"
                    name="nro_iibb"
                    focused
                    value={condicionIIBB.nro_iibb}
                    onChange={almacenar}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Button
                    variant="contained"
                    fullWidth
                    startIcon={<FormatListBulleted />}
                    disabled={condicionIIBB.regimen_iibb_id !== 3 ?? false}
                    onClick={() => {
                      HandleInscripciones();
                    }}
                  >
                    Inscripciones
                  </Button>
                </Grid>
              </Grid>

              <br />
              <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                alignContent="stretch"
                wrap="wrap"
              >
                <Grid item xs={1}>
                  <Button
                    fullWidth
                    onClick={HandleVolver}
                    color="error"
                    variant="contained"
                  >
                    Volver
                  </Button>
                </Grid>
                <Grid item xs={1}>
                  <Button
                    variant="contained"
                    fullWidth
                    type="submit"
                    color="success"
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Container>
      ) : null}

      {showInscripcionesIIBB ? (
        <Inscripciones
          proveedor={proveedor}
          setTablaiibb={setTablaIIBB}
          showInscripcionesIIBB={showInscripcionesIIBB}
          setShowInscripcionesIIBB={setShowInscripcionesIIBB}
        />
      ) : null}
      {openExisteIns ? (
        <HasInscripcion
          open={openExisteIns}
          close={closeDialogIns}
          agregar={AgregarIns}
          InscripcionesBefore={InscripcionBefore}
        />
      ) : null}
      {openclose ? (
        <FileUploadMultiple
          fileId={proveedor.uuid_proveedor}
          openclose={true}
        />
      ) : null}
    </div>
  );
};
export default IIBB;
