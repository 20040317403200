//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio/Nicolas Pascucci
// Creation Date : 01/02/2024
// Version : 1
// Description : Pantalla de carga de los pedidos por parte de las plantas
//------Changes ----------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect } from "react";
import moment from "moment";
import "moment/locale/es";
import {
  Button,
  Box,
  Stack,
  Snackbar,
  TextField,
  Alert,
  Grid,
  Autocomplete, IconButton,
} from "@mui/material";
import Navbar from "../Navbar";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { UrlApi } from "../../services/apirest";
import Typography from "@mui/material/Typography";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { Container } from "@mui/system";
import SettingsIcon from '@mui/icons-material/Settings';
import ConfigDias from "./ConfigDias";
import Title from "../Title";
import { logEventos } from "../../services/services";

const Item = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  textAlign: "center",
  border: "0px",
}));

const StyledStack = styled(Stack)({
  borderRadius: "8px",
  margin: "20px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
});

function SolicitudSucursal() {
  const [tabla, setTabla] = useState(true)
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [solicitudes, setSolicitudes] = useState();
  const [sucursales, setSucursales] = useState();
  const [sucursalSeleccionada, setSucursalSeleccionada] = useState("")
  // FECHA NUEVA
  const fechaActual = moment();
  const [fechaInicioSemana, setFechaInicioSemana] = useState(
    fechaActual.clone().day(8)
  );
  const diasSemanaActual = [];
  const esAdmin = localStorage.getItem("role") === "admin";
 // console.log("ADMIN", sucursalSeleccionada);
  const [configDias, setConfigDias] = useState(false);
  const handleCloseConfig = () => {
    setConfigDias(false);
    setTabla(true)
  };
  const handleConfig = () => {
    setTabla(false)
    setConfigDias(true);
  };

  useEffect(() => {
    logEventos("Acceso al menú pedidos semanales", "Pedidos Semanales", "Hacer pedidos semanales de bitano y propano", localStorage.getItem("user"))
}, [])

  const obtenerSucursales = () => {
    fetch(UrlApi + "/sucursales/tipoPlanta")
      .then((data) => data.json())
      .then((data) => setSucursales(data));
  };
  useEffect(() => {
    obtenerSucursales();
  }, []);

  const obtenerSolicitudes = () => {
    if (esAdmin === true) {
      fetch(
        UrlApi +
          "/solicitudes_abastecimiento/existentes/" +
          sucursalSeleccionada.id +
          "/'" +
          diasSemanaActual[0].fecha +
          "'/'" +
          diasSemanaActual[5].fecha +
          "'"
      )
        .then((data) => data.json())
        .then((data) => setSolicitudes(data));
    } else{
      fetch(
        UrlApi +
          "/solicitudes_abastecimiento/existentes/" +
          localStorage.getItem("sucursal") +
          "/'" +
          diasSemanaActual[0].fecha +
          "'/'" +
          diasSemanaActual[5].fecha +
          "'"
      )
        .then((data) => data.json())
        .then((data) => setSolicitudes(data));
    }

  };
  useEffect(() => {
    obtenerSolicitudes();
  }, []);
  useEffect(() => {
    obtenerSolicitudes();
  }, [sucursalSeleccionada, fechaInicioSemana]);
 // console.log("SOLICITUDES", solicitudes);
  // Obtén la fecha actual o la fecha deseada
 // console.log(fechaActual.format("dddd"));
  const diaHabilitado = fechaActual.format("dddd");
  // const diaHabilitado = "miercoles";
  // const diaHabilitado = "martes"
  const [configuracionDias, setConfiguracionDias] = useState()
  const obtenerConfiguracion = () => {
    fetch(UrlApi + "/config_diashabilitados/ultimaRow")
      .then((data) => data.json())
      .then((data) => setConfiguracionDias(data));
  };
  useEffect(() => {
    obtenerConfiguracion();
  }, []);
//console.log("CONFIGURACION DIAS", configuracionDias);

  const [habilitado, setHabilitado] = useState(false);
  useEffect(() => {
    // Verifica si hay configuración de días y si el día actual está habilitado
    if (configuracionDias && configuracionDias[0][diaHabilitado.normalize("NFD").replace(/[\u0300-\u036f]/g, "")] === 1) {
    //  console.log("ENTRO", diaHabilitado.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
      setHabilitado(false);
    } else {
      setHabilitado(true);
    }
  }, [configuracionDias, diaHabilitado]);
  // console.log("jhbjhbjhbjh",configuracionDias?configuracionDias[0][diaHabilitado.normalize("NFD").replace(/[\u0300-\u036f]/g, "")]:"nada");

  const [habilitadoAdmin, setHabilitadoAdmin] = useState(false);
  useEffect(() => {
    if (Object.keys(sucursalSeleccionada).length === 0) {
      setHabilitadoAdmin(true);
    } else {
      setHabilitadoAdmin(false);
    }
  }, [sucursalSeleccionada]);
//console.log(diaHabilitado,"hoy");
  // Calcula la fecha del próximo lunes
  const lunesProximo = fechaActual.clone().day(8); // 8 representa el día lunes de la próxima semana

  // Obtén los nombres y fechas de los días de la semana para la próxima semana
  const diasProximaSemana = [];
  for (let i = 0; i < 6; i++) {
    const fechaDia = lunesProximo.clone().add(i, "days");
    diasProximaSemana.push({
      id: i, // Utilizamos la posición en el bucle como id
      nombre: fechaDia.format("dddd"),
      fecha: fechaDia.format("YYYY-MM-DD"),
    });
  }
  //**************************************************************************** //
  const [butanoValues, setButanoValues] = useState(
    Array(diasSemanaActual.length).fill("0")
  );
  const [propanoValues, setPropanoValues] = useState(
    Array(diasSemanaActual.length).fill("0")
  );

  useEffect(() => {
    // Obtener los valores de propano y butano de las solicitudes y actualizar el estado
    if (solicitudes) {
      const butanoData = Array(diasSemanaActual.length).fill("0");
      const propanoData = Array(diasSemanaActual.length).fill("0");

      solicitudes.forEach((solicitud) => {
        const index = diasSemanaActual.findIndex((item) =>
          moment(item.fecha).isSame(solicitud.para_fecha, "day")
        );
      
        if (index !== -1) {
          if (solicitud.butano !== null && solicitud.butano !== "") {
            butanoData[index] = {
              fecha: solicitud.para_fecha,
              value: solicitud.butano,
            };
          }
      
          if (solicitud.propano !== null && solicitud.propano !== "") {
            propanoData[index] = {
              fecha: solicitud.para_fecha,
              value: solicitud.propano,
            };
          }
        }
      });
      
      // Limpiar los campos donde no hay datos en las solicitudes
    for (let i = 0; i < butanoData.length; i++) {
      if (butanoData[i] === "0") {
        butanoData[i] = { fecha: diasSemanaActual[i].fecha, value: "" };
      }
    }

    for (let i = 0; i < propanoData.length; i++) {
      if (propanoData[i] === "0") {
        propanoData[i] = { fecha: diasSemanaActual[i].fecha, value: "" };
      }
    }
      setButanoValues(butanoData);
      setPropanoValues(propanoData);
    }
  }, [solicitudes, sucursalSeleccionada]);

  const handleButanoChange = (index, value, fecha) => {
    const newValues = [...butanoValues];
    newValues[index] = { fecha, value };
    setButanoValues(newValues);
  };

  const handlePropanoChange = (index, value, fecha) => {
    const newValues = [...propanoValues];
    newValues[index] = { fecha, value };
    setPropanoValues(newValues);
  };

  // console.log("ARRAY DE DATOS", propanoValues, butanoValues);

  const guardarSolicitudes = async (index) => {
    logEventos("Guardar solicitud", "Pedidos Semanales", "Se guardo la solicitud", localStorage.getItem("user"))
    setHabilitado(true)
    try {
      const item = diasSemanaActual[index];
      // Verifica si ya existe un registro con la fecha y la sucursal
      const solicitudExistente = solicitudes.find(
        (solicitud) =>
          moment(solicitud.para_fecha).isSame(item.fecha, "day") &&
          solicitud.id_sucursal === parseInt(localStorage.getItem("sucursal"))
      );

      if (solicitudExistente) {
        const response = await fetch(
          UrlApi +
            "/solicitudes_abastecimiento/" +
            solicitudExistente.id_solicitud,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              butano: butanoValues[index].value === ""?null:butanoValues[index].value,
              propano: propanoValues[index].value === ""?null:propanoValues[index].value,
              viaje_butano: butanoValues[index].value === ""?null:butanoValues[index].value,
              viaje_propano: propanoValues[index].value === ""?null:propanoValues[index].value,
              usuario: localStorage.getItem("user"),
            }),
          }
        );

        const diasProximaSemanas = await response.json();

        if (!diasProximaSemanas.err) {
          console.log("Guardado");
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("Las Solicitudes se guardaron exitosamente !");
          //setHabilitado(false)
        } else {
          console.log("Error al guardar");
        }
      } else {
        const response = await fetch(UrlApi + "/solicitudes_abastecimiento", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            id_sucursal: localStorage.getItem("sucursal"),
            para_fecha: butanoValues[index].fecha
              ? moment(butanoValues[index].fecha).format("YYYY-MM-DD")
              : diasSemanaActual[index].fecha,
            butano: butanoValues[index].value === ""?null:butanoValues[index].value,
            propano: propanoValues[index].value === ""?null:propanoValues[index].value,
            viaje_butano: butanoValues[index].value === ""?null:butanoValues[index].value,
            viaje_propano: propanoValues[index].value === ""?null:propanoValues[index].value,
            usuario: localStorage.getItem("user"),
          }),
        });

        const diasProximaSemanas = await response.json();

        if (!diasProximaSemanas.err) {
          console.log("Guardado");
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("Las Solicitudes se guardaron exitosamente !");
          //setHabilitado(false)
        } else {
          console.log("Error al guardar");
        }
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
    obtenerSolicitudes()
  };

  const guardarSolicitudesAdmin = async (index) => {
    logEventos("Guardar solicitud del admin", "Pedidos Semanales", "Se guardo la solicitud del admin", localStorage.getItem("user"))
    setHabilitadoAdmin(true)
    try {
      const item = diasSemanaActual[index];
      // Verifica si ya existe un registro con la fecha y la sucursal
      const solicitudExistente = solicitudes.find(
        (solicitud) =>
          moment(solicitud.para_fecha).isSame(item.fecha, "day") &&
          solicitud.id_sucursal === parseInt(sucursalSeleccionada.id)
      );

      if (solicitudExistente) {
        const response = await fetch(
          UrlApi +
            "/solicitudes_abastecimiento/" +
            solicitudExistente.id_solicitud,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              butano: butanoValues[index].value === ""?null:butanoValues[index].value,
              propano: propanoValues[index].value === ""?null:propanoValues[index].value,
              viaje_butano: butanoValues[index].value === ""?null:butanoValues[index].value,
              viaje_propano: propanoValues[index].value === ""?null:propanoValues[index].value,
              usuario: localStorage.getItem("user"),
            }),
          }
        );

        const diasProximaSemanas = await response.json();

        if (!diasProximaSemanas.err) {
          console.log("Guardado");
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("Las Solicitudes se guardaron exitosamente !");
          //setHabilitadoAdmin(false)
        } else {
          console.log("Error al guardar");
        }
      } else {
        const response = await fetch(UrlApi + "/solicitudes_abastecimiento", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            id_sucursal: sucursalSeleccionada.id,
            para_fecha: butanoValues[index].fecha
              ? moment(butanoValues[index].fecha).format("YYYY-MM-DD")
              : diasSemanaActual[index].fecha,
            butano: butanoValues[index].value === ""?null:butanoValues[index].value,
            propano: propanoValues[index].value === ""?null:propanoValues[index].value,
            viaje_butano: butanoValues[index].value === ""?null:butanoValues[index].value,
            viaje_propano: propanoValues[index].value === ""?null:propanoValues[index].value,
            usuario: localStorage.getItem("user"),
          }),
        });

        const diasProximaSemanas = await response.json();

        if (!diasProximaSemanas.err) {
          console.log("Guardado");
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("Las Solicitudes se guardaron exitosamente !");
          //setHabilitadoAdmin(false)
        } else {
          console.log("Error al guardar");
        }
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
    obtenerSolicitudes()
  };


  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
    setHabilitadoAdmin(false)
    setHabilitado(false)
  };
  // Imprime los nombres y fechas de los días de la próxima semana
  
  //*****************************FECHAS****************************************/
  
  
  const irSemanaAnterior = () => {
    setFechaInicioSemana((prevFecha) => prevFecha.clone().subtract(7, "days"));
    // setCambio(true)
    // obtenerSolicitudes()
  };
  
  const irSemanaSiguiente = () => {
    setFechaInicioSemana((prevFecha) => prevFecha.clone().add(7, "days"));
    // obtenerSolicitudes()
    // setCambio(true)
  };
  
  for (let i = 0; i < 6; i++) {
    const fechaDia = fechaInicioSemana.clone().add(i, "days");
    diasSemanaActual.push({
      id: i,
      nombre: fechaDia.format("dddd"),
      fecha: fechaDia.format("YYYY-MM-DD"),
    });
  }
  
  // console.log(
  //   "Días de la próxima semanaa:",
  //   diasSemanaActual,
  //   solicitudes,
  //   butanoValues,
  //   propanoValues
  // );
  return (
    <>
    {tabla === true ?( 
      <>
      <Navbar />
      <Box
        bgcolor="#2B76A6"
        padding={2}
        borderRadius={0}
        color="white"
        textAlign="center"
        display="flex" // Agregado para que los elementos se coloquen en línea
        alignItems="center"
        marginTop={3}
      >
        <ReceiptLongIcon
          style={{ fontSize: 60, marginRight: "20px", marginLeft: "20px" }}
        />
        <Typography variant="h4" component="div" marginTop={1}>
          Pedidos Semanales
        </Typography>
        {esAdmin === true ? (
    <IconButton style={{ marginLeft: "auto" }} onClick={handleConfig}>
      <SettingsIcon style={{ color: "white", fontSize: 30 }} />
    </IconButton>
  ) : null}
      </Box>      
      <form>
        {esAdmin === true?
        <>
      <Container style={{ marginTop: 12, marginLeft: -2 }}>
          <Grid container spacing={2} direction="row" alignItems="center">
            <Grid item xs={12} md={4}>
              <Autocomplete
                disabled={!sucursales}
                disableClearable
                size="small"
                focused={true}
                required
                id="id_sucursal"
                noOptionsText={"Planta INEXISTENTE"}
                options={sucursales}
                autoHighlight
                getOptionLabel={(sucursal) =>
                  sucursal.id +
                  "-" +
                  sucursal.nombre
                }
                onChange={(event, value) => setSucursalSeleccionada(value)}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    key={option.id}
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.id} - {option.nombre}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                    }}
                    focused
                    // style={style}
                    size="small"
                    placeholder="Tipee para buscar..."
                    label="Planta"
                  />
                )}
              />
            </Grid>
            
             
            <Grid item xs={12} md={8} style={{ display: 'flex', alignItems: 'center'}}>
              <div style={{ textAlign: 'center', marginLeft:16 }}>
              <Button 
                onClick={irSemanaAnterior} 
                variant="contained" size="small">
                &lt; Semana Anterior
              </Button>
              <Button
                onClick={irSemanaSiguiente}
                variant="contained"
                sx={{ marginLeft: 1 }}
                size="small"
              >
                Semana Siguiente &gt;
              </Button>
              </div>
            </Grid>
          </Grid>
        </Container>
        </>
        :null}
        <StyledStack direction="row" spacing={2} mt={4}>
          {diasSemanaActual.map((item, index) => (
            <>
              <Item
                key={index}
                sx={{
                  fontSize: 20,
                  color: "white",
                  backgroundColor: "#2B76A6",
                }}
              >
                <Box backgroundColor="#2B76A6">
                  <div style={{ margin: "10px 0" }}></div>
                  {`${item.nombre.toUpperCase()}`} <br />{" "}
                  {`${moment(item.fecha).format("DD-MM-YYYY")}`}
                  <div style={{ margin: "10px 0" }}></div>
                </Box>
                <Box backgroundColor="#F4F6F6" p={2} borderRadius={2}>
                  <TextField
                    style={{ backgroundColor: "white" }}
                    sx={{ mt: 2 }}
                    id={`butano-${index}`}
                    // type="number"
                    label="Butano"
                    variant="outlined"
                    focused
                    required
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                      }
                    }}
                    value={
                      butanoValues[index] ? butanoValues[index].value : "0"
                    }
                    onChange={(e) =>
                      handleButanoChange(index, e.target.value, item.fecha)
                    }
                  />
                  <TextField
                    style={{ backgroundColor: "white" }}
                    sx={{ mt: 2 }}
                    id={`propano-${index}`}
                    // type="number"
                    label="Propano"
                    variant="outlined"
                    focused
                    required
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                      }
                    }}
                    value={
                      propanoValues[index] ? propanoValues[index].value : "0"
                    }
                    onChange={(e) =>
                      handlePropanoChange(index, e.target.value, item.fecha)
                    }
                  />
                </Box>
              </Item>
            </>
          ))}
        </StyledStack>
        <br />
        <Grid container justifyContent="end">
          <Grid item mr={5}>
            <Button
              variant="contained"
              onClick={() => {
                esAdmin?(
                  diasSemanaActual.forEach((item, index) => {
                  guardarSolicitudesAdmin(index);
                })
                ):(
                  diasSemanaActual.forEach((item, index) => {
                  guardarSolicitudes(index);
                })
                )
              }}
              size="large"
              disabled={esAdmin?habilitadoAdmin:habilitado}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </form>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>
      </>
  ):null}
      {configDias ? 
      <Container maxWidth="lg">
      <Title
        titulo={`Configuración de Días Habilitados`}
        handleClose={() => {
          setConfigDias(false);
          setTabla(true);
        }}
      />
      <ConfigDias 
      configDias={configDias}
      onClose={() => {
        handleCloseConfig(false)
      }}
      />
      </Container>
      :null}
    </>
  );
}

export default SolicitudSucursal;
