import Navbar from "../../Navbar";
import React, { useEffect, useState, useMemo } from "react";
import { Container, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { UrlApi } from "../../../services/apirest";
import {
  Button,
  Dialog,
  Tooltip,
  IconButton,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Box,
} from "@mui/material";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import Editarcertificadora from "./Editarcertificadora";
import Addcertificadora from "./Addcertificadora";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import PersonIcon from "@mui/icons-material/Person";
import BlockIcon from "@mui/icons-material/Block";
import DialogBajaCertificadora from "./DialogBajaCertificadora";

export default function Certificadoras() {
  const [certificadoras, setCertificadoras] = useState();
  const [baja, setBaja] = useState(false);
  const [alta, setAlta] = useState(false); //Estado q mostrará o no la pantalla de alta
  const [tabla, setTabla] = useState(true); //Estado q mostrará o no la tabla (pantalla inicial) dnd estan las certificadoras
  const [editar, setEditar] = useState(false); //Estado q mostrará o no el form de edicion
  const [datosFormulario, setDatosFormulario] = useState({});
  const globalTheme = useTheme();
  const [hiddenmuestrainactivo, setHiddenmuestrainactivo] = useState(false);
  const [hiddenmuestraactivos, setHiddenmuestraactivos] = useState(true);

  const buscarcertificadoras = () => {
    setHiddenmuestraactivos(true);
    setHiddenmuestrainactivo(false);
    fetch(UrlApi + "/certificadoras")
      .then((data) => data.json()) //mando los datos en json
      .then((data) => setCertificadoras(data)); //seteo certificadoras con lo que me trae d la api
  };

  const buscarinactivas = () => {
    setHiddenmuestrainactivo(true);
    setHiddenmuestraactivos(false);
    fetch(UrlApi + "/certificadoras/inactivas")
      .then((data) => data.json()) //mando los datos en json
      .then((data) => setCertificadoras(data)); //seteo certificadoras con lo que me trae d la api
  };

  useEffect(() => {
    buscarcertificadoras();
  }, [tabla]);

  const handleBaja = (rowData) => {
    setBaja(true);
    setDatosFormulario(rowData);
  };

  const handleClickNuevo = () => {
    setAlta(true);
    setTabla(false);
  };

  const handleEditar = (datosDeLaLinea) => {
    setEditar(true);
    setTabla(false);
    setDatosFormulario(datosDeLaLinea);
  };
  const columns = useMemo(
    () => [
      { header: "Nombre", accessorKey: "nombre" },
      { header: "Contacto", accessorKey: "contacto" },
    ],
    []
  );

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "success",
    size: "small",
  };

  const initialState = {
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 15 },
  };

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //larger tooltip font size
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const tableHeadCellProps = {
    align: "center",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };

  return (
    <div>
      <Navbar />
      <Container maxWidth="xl" mt={1}>
        {certificadoras && tabla ? (
          <Box mt={2} boxShadow={3} mb={4}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableColumnResizing
                columns={columns}
                data={certificadoras}
                initialState={initialState}
                positionActionsColumn="last"
                enableExpandAll={false}
                enableToolbarInternalActions={true}
                muiTableHeadCellProps={tableHeadCellProps}
                muiTableBodyCellProps={({ row }) => ({
                  align: "center",
                  sx: {
                    backgroundColor:
                      row.original.activo === 0 ? "#e39898" : "#afdbaf",
                    color: "black",
                    borderColor: "black",
                  },
                })}
                enableColumnActions={false}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                enableDensityToggle={false}
                renderTopToolbarCustomActions={() => (
                  <>
                    <Button
                      variant="contained"
                      color="success"
                      startIcon={<AddBoxTwoToneIcon />}
                      onClick={handleClickNuevo}
                    >
                      Nuevo Registro
                    </Button>
                    <Typography variant="poster" fontSize={26}>
                      Certificadoras
                    </Typography>
                  </>
                )}
                layoutMode="grid"
                localization={MRT_Localization_ES}
                enableColumnDragging={false}
                enableEditing
                renderRowActions={({ row, table }) => (
                  <div>
                    <Tooltip title="Editar certificadora">
                      <IconButton
                        onClick={() => handleEditar(row.original)}
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    {row.original.activo === 1 ? (
                      <Tooltip title="Dar de baja">
                        <IconButton
                          onClick={() => handleBaja(row.original)}
                          color="error"
                        >
                          <BlockIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  </div>
                )}
                enableColumnFilters={false}
                enableHiding={false}
                positionExpandColumn="first"
                renderToolbarInternalActions={({ table }) => (
                  <>
                    {!hiddenmuestraactivos ? (
                      <Tooltip title="Ver certificadoras activas">
                        <IconButton
                          color="success"
                          onClick={() => {
                            buscarcertificadoras();
                          }}
                        >
                          <PersonIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    {!hiddenmuestrainactivo ? (
                      <Tooltip title="Ver certificadoras inactivas">
                        <IconButton
                          color="error"
                          onClick={() => {
                            buscarinactivas();
                          }}
                        >
                          <PersonOffIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  </>
                )}
              />
            </ThemeProvider>
          </Box>
        ) : null}
      </Container>
      {baja ? (
        <DialogBajaCertificadora
          open={baja}
          setOpen={setBaja}
          datosCert={datosFormulario}
          actualizar={buscarcertificadoras}
        />
      ) : null}
      {alta ? (
        <Addcertificadora alta={setAlta} tabla={setTabla}></Addcertificadora>
      ) : null}
      {editar ? (
        <Editarcertificadora
          editar={setEditar}
          tabla={setTabla}
          datos={datosFormulario}
        />
      ) : null}
    </div>
  );
}
