import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  LoadScript,
  StandaloneSearchBox,
  Marker,
  InfoWindow,
  MarkerClusterer,
} from "@react-google-maps/api";
import Box from "@mui/material/Box";
import { BarChart } from "@mui/x-charts/BarChart";
import { Typography } from "@mui/material";

import { UrlApiEnvasado } from "../../../services/apirest";

// ICONOS
import CircularProgress from "@mui/material/CircularProgress";

// PARA EL IDIOMA DEL CALENDARIO
import "dayjs/locale/es";

// -- Tabla
const tablaStyles = {
  width: "100%",
  borderCollapse: "collapse",
};

const thTdStyles = {
  border: "1px solid #ddd",
  padding: "8px",
  textAlign: "left",
};

const thStyles = {
  backgroundColor: "#f2f2f2",
};

export default function ChartsOverviewDemo() {
  const [loading, setLoading] = useState(false);
  const [markers, setMarkers] = useState([]);

  const [selectedYear, setSelectedYear] = useState(
    new Date().getFullYear().toString()
  );

  // ----------------------------------------------------------------------------------

  const obtenerMarkers = () => {
    setLoading(true); // Indicar que se está obteniendo los datos

    fetch(
      UrlApiEnvasado +
        "/envasado/fac_reng/pesototalvendido/soloanio/anio/" +
        selectedYear
    )
      .then((data) => data.json())
      .then((data) => {
        setMarkers(data);
        setLoading(false); // Indicar que se han cargado los datos
      });
  };

  useEffect(() => {
    obtenerMarkers();
  }, []);

  console.log("MARKERS: ", markers)

  return (
    <>
      <div>
      <div style={{ height: "100%", width: "100%" }}>
        <table style={tablaStyles}>
          <thead>
            <tr>
              <th style={{ ...thTdStyles, ...thStyles }}>Nombre de Sucursal</th>
              <th style={{ ...thTdStyles, ...thStyles }}>Peso Total Vendido</th>
            </tr>
          </thead>
          <tbody>
            {markers.map((sucursal, index) => (
              <tr key={index}>
                <td style={thTdStyles}>{sucursal.nombre_sucursal}</td>
                <td style={thTdStyles}>{sucursal.peso_total_vendido}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      </div>
    </>
  );
}
