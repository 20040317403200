import React, { useEffect, useState } from "react";
import { UrlApi } from "../../../services/apirest";
import { getData, putData } from "../../../services/library";
import {
  IconButton,
  Button,
  FormControl,
  Grid,
  Select,
  Container,
  InputLabel,
  MenuItem,
  TextField,
  Tooltip,
  Box,
  Typography,
  Autocomplete,
} from "@mui/material";
import moment from "moment";
import Notify from "../../Notify";
import Alerta from "../../Alerta";
import Title from "../../Title"

//Iconos y boludeces para los textfields
import InputAdornment from "@mui/material/InputAdornment";
import TimeToLeaveIcon from "@mui/icons-material/TimeToLeave";
import EightteenMpIcon from "@mui/icons-material/EightteenMp";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import Battery50Icon from "@mui/icons-material/Battery50";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddBoxIcon from "@mui/icons-material/AddBox";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import AddMarcaVehiculo from "../../vehiculos_nuevo/add/AddMarcaVehiculo";
import AddModeloVehiculo from "../../vehiculos_nuevo/add/AddModeloVehiculo";

function Editarvehiculos(props) {
  const [datosVehiculo, setDatosVehiculo] = useState(props.datos);
  console.log("props.datos", props.datos);
  
    // ********** AGREGADO DE MARCA Y MODELO ********//
    const [tabla, setTabla] = useState(true)
    const [altaMarca, setAltaMarca] = useState(false)
    const [altaModelo, setAltaModelo] = useState(false)
    const [marcas, setMarcas] = useState()
    const [marcaSeleccionada, setMarcaSeleccionada] = useState("")
    const [modelos, setModelos] = useState()
    const [modeloSeleccionado, setModeloSeleccionado] = useState("")
    const [buscando, setBuscando] = useState(false)
    const [tipoVehiculo, setTipoVehiculo] = useState(null);
    const [choferes, setChoferes] = useState(null);
    const [uso, setUso] = useState(null);
    const [sucursales, setSucursales] = useState(null);
    const [transportistas, settransportistas] = useState(null);
    const [duenio, setduenio] = useState(null);
    // const [tipos, setTipos] = useState(null)
    const [choferSeleccionado, setChoferSeleccionado] = useState("")
    const [usoSeleccionado, setUsoSeleccionado] = useState("")
    const [sucursalSeleccionada, setSucursalSeleccionada] = useState("")
    const [transportistaSeleccionada, setTransportistaSeleccionada] = useState("")
    const [tipoSeleccionado, setTipoSeleccionado] = useState("")
    const [duenioSeleccionado, setDuenioSeleccionado] = useState("");
  
    const obtenerMarcas = () => {
      fetch(UrlApi + "/marcas/") 
        .then((data) => data.json())
        .then((data) => setMarcas(data));
    };
  
    useEffect(() => {
        obtenerMarcas();
    }, [tabla]);
  
    const obtenerModelos = () => {
      setBuscando(true)
      fetch(UrlApi + "/modelos/xmarca/"+ marcaSeleccionada.id) 
        .then((data) => data.json())
        .then((data) => setModelos(data))
        .then((data) => setModeloSeleccionado(""))
        .then((data) => setBuscando(false))
    };
  
    useEffect(() => {
        obtenerModelos();
    }, [marcaSeleccionada]);
  
    const handleClickMarca = () => {
      setAltaMarca(true);
      setTabla(false);
    };
  
    const handleClickModelo = (row) => {
      setAltaModelo(true);
      setTabla(false);
    };

    //******** Cambio de modelo seleccionado *******// 

    useEffect(() => {
      if (datosVehiculo.id_marca !== null && marcas) {
        const marcaEncontrada = marcas.find(
          (d) => d.id === datosVehiculo.id_marca
        );
        if (marcaEncontrada) {
          setMarcaSeleccionada(marcaEncontrada);
        }
      }
    }, [datosVehiculo, marcas]);
    // console.log("marca", marcaSeleccionada, "id",datosVehiculo.id_marca,"modelo", modeloSeleccionado);
    useEffect(() => {
      if (modelos && datosVehiculo.id_modelo !== null) {
        const modeloEncontrado = modelos.find(
          (d) => d.id === datosVehiculo.id_modelo
        );
        if (modeloEncontrado) {
          setModeloSeleccionado(modeloEncontrado);
        }
      }
    }, [modelos]);

    useEffect(() => {
      if (choferes && datosVehiculo.id_chofer !== null) {
        const choferEncontrado = choferes.find(
          (d) => d.id === datosVehiculo.id_chofer
        );
        if (choferEncontrado) {
          setChoferSeleccionado(choferEncontrado);
        }
      }
    }, [choferes]);

    useEffect(() => {
      if (uso && datosVehiculo.uso !== null) {
        const usoEncontrado = uso.find(
          (d) => d.id === datosVehiculo.uso
        );
        if (usoEncontrado) {
          setUsoSeleccionado(usoEncontrado);
        }
      }
    }, [uso]);

    useEffect(() => {
      if (sucursales && datosVehiculo.id_sucursal !== null) {
        const sucursalEncontrada = sucursales.find(
          (d) => d.id === datosVehiculo.id_sucursal
        );
        if (sucursalEncontrada) {
          setSucursalSeleccionada(sucursalEncontrada);
        }
      }
    }, [sucursales]);

    useEffect(() => {
      if (duenio && datosVehiculo.duenio !== null) {
        const duenioEncontrado = duenio.find(
          (d) => d.id === datosVehiculo.duenio
        );
        if (duenioEncontrado) {
          setDuenioSeleccionado(duenioEncontrado);
        }
      }
    }, [duenio]);

    useEffect(() => {
      if (transportistas && datosVehiculo.id_transportista !== null) {
        const transportistaEncontrada = transportistas.find(
          (d) => d.id_transportista === datosVehiculo.id_transportista
        );
        if (transportistaEncontrada) {
          setTransportistaSeleccionada(transportistaEncontrada);
        }
      }
    }, [transportistas]);

    useEffect(() => {
      if (tipoVehiculo && datosVehiculo.id_tipo !== null) {
        const tipoEncontrado = tipoVehiculo.find(
          (d) => d.id === datosVehiculo.id_tipo
        );
        if (tipoEncontrado) {
          setTipoSeleccionado(tipoEncontrado);
        }
      }
    }, [tipoVehiculo]);
    //**********************************************//
  
  // console.log(datosVehiculo)
  const [disBtn, setDisbtn] = useState(false);
  // const [tipoVehiculo, setTipoVehiculo] = useState(null);
  // const [choferes, setChoferes] = useState(null);
  // const [uso, setUso] = useState(null);
  // const [duenio, setduenio] = useState(null);
  // const [sucursales, setSucursales] = useState(null);
  // const [transportistas, settransportistas] = useState(null);



  const [notificacion, setNotificacion] = useState({
    open: false,
    mensaje: "",
    severity: "",
    color: "",
  });

  // useEffect(() => {
  //   getData("/desplegables/tipovehiculos", setTipoVehiculo)
  //   getData("/choferes", setChoferes)
  //   getData("/desplegables/usoVehiculo", setUso)
  //   getData("/sucursales", setSucursales)
  //   getData("/desplegables/dueniovehiculo", setduenio)
  //   getData("/transportistas", settransportistas)
  // },
  //   []);
  useEffect(() => {
    getData("/desplegables/tipovehiculos", setTipoVehiculo)
    getData("/choferes", setChoferes)
    getData("/desplegables/usoVehiculo", setUso)
    getData("/sucursales", setSucursales)
    getData("/desplegables/dueniovehiculo", setduenio)
    getData("/transportistas", settransportistas)
    // getData("/tipos_vehiculos", setTipos)
  },
    []);

  //FIN CONSULTAS A LA API-----------------------------

  const handleClose = () => {
    props.tabla(true);
    props.editar(false);
  };

  //funcion para guardar
  // const guardarVehiculos = (e) => {
  //   setDisbtn(true);
  //   putData(e,
  //     "/vehiculos/" + datosVehiculo.idvehiculo,
  //     {
  //       id_sisven: datosVehiculo.id_sisven,
  //       marca: datosVehiculo.marca,
  //       modelo: datosVehiculo.modelo,
  //       capacidad: datosVehiculo.capacidad,
  //       patente: datosVehiculo.patente,
  //       id_tipo: tipoSeleccionado.id,
  //       id_chofer: choferSeleccionado.id,
  //       vencimientovtv: moment(datosVehiculo.vencimientovtv).format("YYYY-MM-DD"),
  //       vencimientohidraulica: moment(datosVehiculo.vencimientohidraulica).format("YYYY-MM-DD"),
  //       tara: datosVehiculo.tara,
  //       uso: usoSeleccionado.id,
  //       ltstkcombustible: datosVehiculo.ltstkcombustible,
  //       id_sucursal: sucursalSeleccionada.id,
  //       usuario: localStorage.getItem("user"),
  //       capacidadtotalm3: datosVehiculo.capacidadtotalm3,
  //       t1: datosVehiculo.t1,
  //       duenio: duenioSeleccionado.id,
  //       id_transportista:transportistaSeleccionada.id_transportista,
  //       id_modelo: modeloSeleccionado.id,
  //     },
  //     setNotificacion
  //   )
  // };

  const guardarVehiculos = (e) => {
    setDisbtn(true);
  
    putData(
      e,
      `/vehiculos/${datosVehiculo.idvehiculo}`,
      {
        id_sisven: datosVehiculo.id_sisven,
        marca: datosVehiculo.marca,
        modelo: datosVehiculo.modelo,
        capacidad: datosVehiculo.capacidad,
        patente: datosVehiculo.patente,
        id_tipo: tipoSeleccionado?.id || null,
        id_chofer: choferSeleccionado?.id || null,
        vencimientovtv: datosVehiculo.vencimientovtv===null? null:moment(datosVehiculo.vencimientovtv).format("YYYY-MM-DD"),
        vencimientohidraulica: datosVehiculo.vencimientohidraulica===null? null: moment(datosVehiculo.vencimientohidraulica).format("YYYY-MM-DD"),
        tara: datosVehiculo.tara,
        uso: usoSeleccionado?.id || null,
        ltstkcombustible: datosVehiculo.ltstkcombustible,
        id_sucursal: sucursalSeleccionada?.id || null,
        usuario: localStorage.getItem("user"),
        capacidadtotalm3: datosVehiculo.capacidadtotalm3,
        t1: datosVehiculo.t1,
        duenio: duenioSeleccionado?.id || null,
        id_transportista: transportistaSeleccionada?.id_transportista || null,
        id_modelo: modeloSeleccionado?.id || null,
        nro_motor: datosVehiculo.nro_motor,
        nro_chasis: datosVehiculo.nro_chasis,
      },
      setNotificacion
    );
  };

  const almacenar = (e) => {
    setDatosVehiculo({
      ...datosVehiculo,
      [e.target.name]: e.target.value === "" ? null : e.target.value,
    });
  };

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  // useEffect(() => {
  //   if (datosVehiculo.id_tipo !== 10 || datosVehiculo.uso !== 66)
  //     setDatosVehiculo({ ...datosVehiculo, t1: 0 });
  // }, [datosVehiculo.id_tipo, datosVehiculo.uso]);

  const [verificarValor, setVerificarValor] = useState(false)
  useEffect(() => {
    if (verificarValor === true) {
      if (tipoSeleccionado.id !== 4 || usoSeleccionado.id !== 66){
        setDatosVehiculo({ ...datosVehiculo, t1: 0 });
      }
    }
    setVerificarValor(false)
  }, [verificarValor]);

console.log("datos vehiculos",datosVehiculo);



const [error, setError] = useState(false);
const [mensajeError, setMensajeError] = useState("")
const [patenteRepetida, setPatenteRepetida] = useState()
useEffect(() => {
  if (error === true) {
    setDisbtn(true)
    setMensajeError("El formato debe ser AB123CD o ABC123")
  } else if(error===false){
    // getData("/vehiculos/patenterepetida/"+datosVehiculo.patente,setPatenteRepetida)
    setDisbtn(false)
    setMensajeError("")
  }

}, [datosVehiculo.patente])
console.log(patenteRepetida);
useEffect(() => {
  if (patenteRepetida && patenteRepetida[0]) {
    setError(true)
    setDisbtn(true)
    setMensajeError("Patente Repetida")
  } else{
    setError(false)
    setDisbtn(false)
    setMensajeError("")
  }
}, [patenteRepetida])

  return (
    <div>
      {tabla? 
      <Container>
        {notificacion && notificacion.open ? (
          <Notify
            mensaje={notificacion.mensaje}
            open={notificacion.open}
            color={notificacion.color}
            handleClose={handleClose}
            severity={notificacion.severity}
          />
        ) : null}
        <Title titulo="Editar Vehículo" handleClose={handleClose} />

        <form onSubmit={guardarVehiculos} autoComplete="off">
        <Box
            mt={2}
            mb={3}
            backgroundColor="#F4F6F6"
            p={2}
            border={0.5}
            borderColor={"#ABB2B9"}
            borderRadius={4}
          >
            <Grid container spacing={2} style={{ padding: 10 }}>
              <Grid item xs={3}>
                <TextField
                  placeholder="ID Sisven"
                  required
                  color="primary"
                  size="small"
                  focused
                  style={style}
                  name="id_sisven"
                  label="ID Sisven"
                  type="text"
                  value={datosVehiculo.id_sisven}
                  onChange={almacenar}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{
                    maxLength: 7,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MeetingRoomIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              {/* AGREGADO DE MARCA Y MODELO */}
              <Grid item xs={2.5}>
                {marcas && (
                  <Autocomplete
                    disabled={!marcas}
                    // size={size}
                    size="small"
                    focused
                    disableClearable
                    id="marcas"
                    noOptionsText={"Marca INEXISTENTE"}
                    options={marcas}
                    autoHighlight
                    // isOptionEqualToValue={(option, value) => option.id === value?.id}
                    getOptionLabel={(marcas) =>
                      marcas.descripcion
                    }
                    value={marcaSeleccionada || null}
                    defaultValue={marcaSeleccionada || null}
                    onChange={(event, value) => {setMarcaSeleccionada(value)}}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        key={option.id}
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.descripcion}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        style={style}
                        required
                        focused
                        size="small"
                        placeholder="Tipee para buscar..."
                        {...params}
                        label="Marca"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={0.5}>
                <Tooltip title="Agregar Marca">
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() => handleClickMarca()}
                  >
                    <AddBoxIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={2.5}>
                {Object.keys(marcaSeleccionada).length === 0 ? (
                  <TextField
                  // value={(bocaSeleccionada.idbocas_carga = null)}
                  disabled
                  focused
                  fullWidth
                  size="small"
                  label= "Seleccione una Marca..."
                  />
                ):( buscando?
                  <TextField
                      // value={(bocaSeleccionada.idbocas_carga = null)}
                      disabled
                      focused
                      fullWidth
                      size="small"
                      label= "Buscando Modelos..."
                      />
                  :
                  <Autocomplete
                    disabled={!modelos}
                    // size={size}
                    size="small"
                    focused
                    disableClearable
                    id="modelos"
                    noOptionsText={"Modelo INEXISTENTE"}
                    options={modelos}
                    autoHighlight
                    isOptionEqualToValue={(option, value) => option.id === value?.id}
                    getOptionLabel={(modelos) =>
                     modelos.descripcion
                    }
                    value={modeloSeleccionado || null}
                    // defaultValue={modeloSeleccionado || null}
                    onChange={(event, value) => {setModeloSeleccionado(value)}}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        key={option.id}
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.descripcion}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        style={style}
                        required
                        focused
                        size="small"
                        placeholder="Tipee para buscar..."
                        {...params}
                        label="Modelo"
                        inputProps={{
                          ...params.inputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <TimeToLeaveIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                  
                )
                }
              </Grid>
              <Grid item xs={0.5}>
                <Tooltip title="Agregar Modelo">
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() => handleClickModelo()}
                  >
                    <AddBoxIcon />
                  </IconButton>
                </Tooltip>
              </Grid>

              {/* <Grid item xs={3}>
                <TextField
                  placeholder="Marca"
                  required
                  inputProps={{ maxLength: 45 }}
                  focused
                  style={style}
                  name="marca"
                  label="Marca"
                  color="primary"
                  value={
                    datosVehiculo.marca &&
                    datosVehiculo.marca.replace(/\b\w/g, (l) => l.toUpperCase())
                  }
                  onChange={almacenar}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <TimeToLeaveIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  placeholder="Modelo"
                  required
                  focused
                  style={style}
                  inputProps={{ maxLength: 45 }}
                  name="modelo"
                  label="Modelo"
                  color="primary"
                  value={datosVehiculo.modelo}
                  onChange={almacenar}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <TimeToLeaveIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid> */}

              {/* <Grid item xs={3}>
                <TextField
                  placeholder="Patente"
                  required
                  focused
                  style={style}
                  name="patente"
                  color="primary"
                  label="Patente"
                  value={datosVehiculo.patente}
                  inputProps={{ maxLength: 9 }}
                  onChange={almacenar}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EightteenMpIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid> */}
              <Grid item xs={3}>
      <TextField
        placeholder="Patente"
        required
        size="small"
        focused
        style={style}
        name="patente"
        color="primary"
        label="Patente"
        value={datosVehiculo.patente}
        error={error} // Indicar si hay error
        helperText={error ? mensajeError : ""} // Mensaje de error
        inputProps={{ maxLength: 7 }} // Máximo de 7 caracteres
        onChange={(e) => {
          // Convertimos a mayúsculas y eliminamos espacios
          let inputValue = e.target.value.toUpperCase().replace(/\s/g, "");

          // Validar contra los dos formatos completos
          const newFormat = /^[A-Z]{2}[0-9]{3}[A-Z]{2}$/; // AB123CD
          const oldFormat = /^[A-Z]{3}[0-9]{3}$/; // ABC123

          // Si cumple con algún formato válido, actualizamos el estado
          if (newFormat.test(inputValue) || oldFormat.test(inputValue)) {
            setError(false); // Sin error
            almacenar({
              target: {
                name: "patente",
                value: inputValue,
              },
            });
          } else {
            setError(true); // Mostrar error
            almacenar({
              target: {
                name: "patente",
                value: inputValue,
              },
            });
          }
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EightteenMpIcon />
            </InputAdornment>
          ),
        }}
      />
    </Grid>

              <Grid item xs={3}>
                <TextField
                  label="Capacidad Tanque Combustible (lts)"
                  color="primary"
                  size="small"
                  focused
                  style={style}
                  name="ltstkcombustible"
                  type="number"
                  value={datosVehiculo.ltstkcombustible}
                  onChange={almacenar}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Lts</InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  placeholder="Capacidad (m3)"
                  // required
                  size="small"
                  focused
                  name="capacidadtotalm3"
                  label="Capacidad (M3)"
                  style={style}
                  color="primary"
                  value={datosVehiculo.capacidadtotalm3}
                  onChange={(e) => {
                    setDatosVehiculo({
                      ...datosVehiculo,
                      capacidadtotalm3: e.target.value,
                      capacidad: e.target.value * 1000 * 0.52 * 0.85,
                    });
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{
                    maxLength: 2,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Battery50Icon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  disabled
                  focused
                  name="capacidad"
                  size="small"
                  label="Capacidad KG (85% llenado)"
                  style={style}
                  color="primary"
                  value={datosVehiculo.capacidad}
                  onChange={almacenar}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Battery50Icon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  placeholder="Tara"
                  // required
                  size="small"
                  color="primary"
                  focused
                  style={style}
                  name="tara"
                  label="Tara"
                  type="number"
                  value={datosVehiculo.tara}
                  onChange={almacenar}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FactCheckIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  style={style}
                  color="primary"
                  focused
                  size="small"
                  // required
                  label="Vencimiento VTV"
                  name="vencimientovtv"
                  type="date"
                  variant="outlined"
                  rows={2}
                  value={moment(datosVehiculo.vencimientovtv).format(
                    "YYYY-MM-DD"
                  )}
                  onChange={almacenar}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  style={style}
                  color="primary"
                  focused
                  size="small"
                  // required
                  label="Vencimiento Hidraulica"
                  name="vencimientohidraulica"
                  type="date"
                  variant="outlined"
                  rows={2}
                  value={moment(datosVehiculo.vencimientohidraulica).format(
                    "YYYY-MM-DD"
                  )}
                  onChange={almacenar}
                />
              </Grid>

              {/* <Grid item xs={3.5}>
                <FormControl style={style} focused color="primary">
                  <InputLabel>Chofer</InputLabel>
                  <Select
                    name="id_chofer"
                    label="Chofer "
                    value={datosVehiculo.id_chofer}
                    onChange={almacenar}
                  >
                    {choferes
                      ? choferes.map((elemento) => (
                        <MenuItem key={elemento.id} value={elemento.id}>
                          {elemento.nombre_apellido}
                        </MenuItem>
                      ))
                      : null}
                  </Select>
                </FormControl>
              </Grid> */}
              <Grid item xs={3.5}>
                <Autocomplete
                    disabled={!choferes}
                    // size={size}
                    size="small"
                    focused
                    disableClearable
                    id="chofer"
                    noOptionsText={"Chofer INEXISTENTE"}
                    options={choferes}
                    autoHighlight
                    isOptionEqualToValue={(option, value) => option.id === value?.id}
                    getOptionLabel={(chofer) =>
                     chofer.nombre_apellido
                    }
                    value={choferSeleccionado || null}
                    // defaultValue={modeloSeleccionado || null}
                    onChange={(event, value) => {setChoferSeleccionado(value)}}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        key={option.id}
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.nombre_apellido}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        style={style}
                        // required
                        focused
                        size="small"
                        placeholder="Tipee para buscar..."
                        {...params}
                        label="Chofer"
                        inputProps={{
                          ...params.inputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <TimeToLeaveIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
              </Grid>

              <Grid item xs={0.5}>
                <Tooltip title="Agregar Chofer">
                  <IconButton
                    color="primary"
                    size="small"
                    // onClick={handleFormChoferes}
                    onClick={() => window.open("/#/choferes")}
                  >
                    <AddBoxIcon />
                  </IconButton>
                </Tooltip>
              </Grid>

              {/* <Grid item xs={4}>
                <FormControl style={style} focused color="primary" required>
                  <InputLabel>Uso</InputLabel>
                  <Select
                    name="uso"
                    label="Uso "
                    value={datosVehiculo.uso}
                    onChange={almacenar}
                  >
                    {uso
                      ? uso.map((elemento) => (
                        <MenuItem key={elemento.id} value={elemento.id}>
                          {elemento.valor}
                        </MenuItem>
                      ))
                      : null}
                  </Select>
                </FormControl>
              </Grid> */}

              <Grid item xs={4}>
              <Autocomplete
                    disabled={!uso}
                    // size={size}
                    size="small"
                    focused
                    disableClearable
                    id="uso"
                    noOptionsText={"Uso INEXISTENTE"}
                    options={uso}
                    autoHighlight
                    isOptionEqualToValue={(option, value) => option.id === value?.id}
                    getOptionLabel={(uso) =>
                     uso.valor
                    }
                    value={usoSeleccionado || null}
                    // defaultValue={modeloSeleccionado || null}
                    onChange={(event, value) => {setUsoSeleccionado(value); setVerificarValor(true)}}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        key={option.id}
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.valor}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        style={style}
                        required
                        focused
                        size="small"
                        placeholder="Tipee para buscar..."
                        {...params}
                        label="Uso"
                        inputProps={{
                          ...params.inputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <TimeToLeaveIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
              </Grid>

              {/* <Grid item xs={4}>
                <FormControl style={style} focused color="primary" required>
                  <InputLabel>Sucursal</InputLabel>
                  <Select
                    name="id_sucursal"
                    label="Sucursal "
                    value={datosVehiculo.id_sucursal}
                    onChange={almacenar}
                  >
                    {sucursales
                      ? sucursales.map((elemento) => (
                        <MenuItem key={elemento.id} value={elemento.id}>
                          {elemento.nombre}
                        </MenuItem>
                      ))
                      : null}
                  </Select>
                </FormControl>
              </Grid> */}
              <Grid item xs={4}>
                <Autocomplete
                    disabled={!sucursales}
                    // size={size}
                    size="small"
                    focused
                    disableClearable
                    id="sucursal"
                    noOptionsText={"Sucursal INEXISTENTE"}
                    options={sucursales}
                    autoHighlight
                    isOptionEqualToValue={(option, value) => option.id === value?.id}
                    getOptionLabel={(sucursal) =>
                     sucursal.nombre
                    }
                    value={sucursalSeleccionada || null}
                    // defaultValue={modeloSeleccionado || null}
                    onChange={(event, value) => {setSucursalSeleccionada(value)}}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        key={option.id}
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.nombre}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        style={style}
                        required
                        focused
                        size="small"
                        placeholder="Tipee para buscar..."
                        {...params}
                        label="Sucursal"
                        inputProps={{
                          ...params.inputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <TimeToLeaveIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
              </Grid>

              {/* <Grid item xs={3}>
                <FormControl style={style} focused color="primary" required>
                  <InputLabel>Dueño</InputLabel>
                  <Select
                    name="duenio"
                    label="Dueño "
                    value={datosVehiculo.duenio}
                    onChange={(e) => {
                      almacenar(e);
                      // setDatosVehiculo({...datosVehiculo, t1:null})
                    }}
                  // onChange={almacenar}
                  >
                    {duenio
                      ? duenio.map((elemento) => (
                        <MenuItem key={elemento.id} value={elemento.id}>
                          {elemento.valor}
                        </MenuItem>
                      ))
                      : null}
                  </Select>
                </FormControl>
              </Grid> */}

              <Grid item xs={3}>
                <Autocomplete
                    disabled={!duenio}
                    // size={size}
                    size="small"
                    focused
                    disableClearable
                    id="duenio"
                    noOptionsText={"Dueño INEXISTENTE"}
                    options={duenio}
                    autoHighlight
                    isOptionEqualToValue={(option, value) => option.id === value?.id}
                    getOptionLabel={(duenio) =>
                     duenio.valor
                    }
                    value={duenioSeleccionado || null}
                    // defaultValue={modeloSeleccionado || null}
                    onChange={(event, value) => {setDuenioSeleccionado(value)}}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        key={option.id}
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.valor}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        style={style}
                        required
                        focused
                        size="small"
                        placeholder="Tipee para buscar..."
                        {...params}
                        label="Dueño"
                        inputProps={{
                          ...params.inputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <TimeToLeaveIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
              </Grid>

              {/* <Grid item xs={3}>
                <FormControl style={style} focused color="primary" required>
                  <InputLabel>Transportista</InputLabel>
                  <Select
                    name="id_transportista"
                    label="Transportista"
                    value={datosVehiculo.id_transportista}
                    onChange={(e) => {
                      almacenar(e);
                    }}
                  >
                    {transportistas
                      ? transportistas.map((elemento) => (
                        <MenuItem key={elemento.id_transportista} value={elemento.id_transportista}>
                          {elemento.nombre}
                        </MenuItem>
                      ))
                      : null}
                  </Select>
                </FormControl>
              </Grid> */}

              <Grid item xs={3}>
                <Autocomplete
                    disabled={!transportistas}
                    // size={size}
                    size="small"
                    focused
                    disableClearable
                    id="transportista"
                    noOptionsText={"Transportista INEXISTENTE"}
                    options={transportistas}
                    autoHighlight
                    isOptionEqualToValue={(option, value) => option.id_transportista === value?.id_transportista}
                    getOptionLabel={(transportista) =>
                     transportista.nombre
                    }
                    value={transportistaSeleccionada || null}
                    // defaultValue={modeloSeleccionado || null}
                    onChange={(event, value) => {setTransportistaSeleccionada(value)}}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        key={option.id_transportista}
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.nombre}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        style={style}
                        required
                        focused
                        size="small"
                        placeholder="Tipee para buscar..."
                        {...params}
                        label="Transportista"
                        inputProps={{
                          ...params.inputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <TimeToLeaveIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
              </Grid>

              {/* <Grid item xs={3}>
                <FormControl style={style} focused color="primary" required>
                  <InputLabel>Tipo</InputLabel>
                  <Select
                    name="id_tipo"
                    label="Tipo "
                    value={datosVehiculo.id_tipo}
                    onChange={(e) => {
                      almacenar(e);
                    }}
                  >
                    {tipoVehiculo
                      ? tipoVehiculo.map((elemento) => (
                        <MenuItem key={elemento.id} value={elemento.id}>
                          {elemento.valor}
                        </MenuItem>
                      ))
                      : null}
                  </Select>
                </FormControl>
              </Grid> */}
              <Grid item xs={3}>
                <Autocomplete
                    disabled={!tipoVehiculo}
                    // size={size}
                    size="small"
                    focused
                    disableClearable
                    id="tipo"
                    noOptionsText={"Tipo INEXISTENTE"}
                    options={tipoVehiculo}
                    autoHighlight
                    isOptionEqualToValue={(option, value) => option.id === value?.id}
                    getOptionLabel={(tipo) =>
                     tipo.valor
                    }
                    value={tipoSeleccionado || null}
                    // defaultValue={modeloSeleccionado || null}
                    onChange={(event, value) => {setTipoSeleccionado(value); setVerificarValor(true)}}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        key={option.id}
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.valor}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        style={style}
                        required
                        focused
                        size="small"
                        placeholder="Tipee para buscar..."
                        {...params}
                        label="Tipo"
                        inputProps={{
                          ...params.inputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <TimeToLeaveIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
              </Grid>


              <Grid item xs={1.5}>
                <FormControlLabel
                  // disabled={datosVehiculo.id_tipo !== 10 || datosVehiculo.uso !== 66}
                  disabled={tipoSeleccionado.id !== 10 || usoSeleccionado.id !== 66}
                  checked={datosVehiculo.t1 === 1}
                  onChange={(e) =>
                    setDatosVehiculo({
                      ...datosVehiculo,
                      ["t1"]: e.target.checked ? 1 : 0,
                    })
                  }
                  control={<Checkbox />}
                  label="Es T1"
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 35 } }}
                />
              </Grid>
              <Grid item xs={6}>
                  <TextField
                    placeholder="N° Motor"
                    // required
                    color="primary"
                    focused
                    size="small"
                    style={style}
                    name="nro_motor"
                    label="N° Motor"
                    type="text"
                    value={datosVehiculo.nro_motor}
                    onChange={almacenar}
                    // onKeyPress={(event) => {
                    //   if (!/[0-9]/.test(event.key)) {
                    //     event.preventDefault();
                    //   }
                    // }}
                    inputProps={{
                      maxLength: 40,
                    }}
                    // InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //       <MeetingRoomIcon />
                    //     </InputAdornment>
                    //   ),
                    // }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    placeholder="N° Chasis"
                    // required
                    color="primary"
                    focused
                    size="small"
                    style={style}
                    name="nro_chasis"
                    label="N° Chasis"
                    type="text"
                    value={datosVehiculo.nro_chasis}
                    onChange={almacenar}
                    // onKeyPress={(event) => {
                    //   if (!/[0-9]/.test(event.key)) {
                    //     event.preventDefault();
                    //   }
                    // }}
                    inputProps={{
                      maxLength: 40,
                    }}
                    // InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //       <MeetingRoomIcon />
                    //     </InputAdornment>
                    //   ),
                    // }}
                  />
                </Grid>
            </Grid>
            <Grid
              //   mt={2}
              container
              direction="row"
              justifyContent="space-between"
              alignItemns="center"
            >
              <Button variant="contained" onClick={handleClose} color="error">
                Volver
              </Button>
              <Button
                variant="contained"
                disabled={disBtn}
                type="submit"
                color="success"
              >
                Guardar Vehiculo
              </Button>
            </Grid>
          </Box>
        </form>
      </Container>
      :null}
      {altaMarca ? (
          <AddMarcaVehiculo
            alta={setAltaMarca}
            tabla={setTabla}
          />
        ) : null}
      {altaModelo ? (
          <AddModeloVehiculo
            alta={setAltaModelo}
            tabla={setTabla}
          />
        ) : null}
    </div>
  );
}

export default Editarvehiculos;
