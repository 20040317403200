import React, { useEffect, useState } from "react";
import { UrlApi } from "../../../../../services/apirest";
import {
  MenuItem,
  IconButton,
  Button,
  Grid,
  InputLabel,
  InputAdornment,
  TextField,
  Select,
  FormControl,
  Container,
  Typography,
} from "@mui/material";
import TimelineIcon from "@mui/icons-material/Timeline";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AssignmentIcon from "@mui/icons-material/Assignment";
import moment from "moment";
import Box from "@mui/material/Box";

import Notify from "../../../../Notify";
import Alerta from "../../../../Alerta";

function AddContrato(props) {
  const [datosformulario, setDatosformulario] = useState(props.datos);
  const [datosContrato, setDatosContrato] = useState({
    id_modalidad: null,
    nro_contrato: null,
    id_estado: null,
    fecha_inicio: null,
    vencimiento_contrato: null,
    consumo_kilos: null,
    id_cliente: datosformulario.id,
    id_factibilidad: null,
    consumo_litros: null,
    id_producto: null,
    usuario: localStorage.getItem("user"),
  });
  const [factibilidades, setFactibilidades] = useState(null);
  const [desplegablesEstado, setDesplegablesEstado] = useState(null); //ACTIVO INACTIVO TERMINADO
  const [desplegablesModalidadC, setDesplegablesModalidadC] = useState(null); //COMODATO, TANTQUE PROPIEDAD DEL CLIENTE : MODALIDAD
  const [productos, setProductos] = useState(null);
  const [disBtn, setDisBtn] = useState(false);
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
  const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
  const [mensajeAlerta, setMensajeAlerta] = useState(""); //mensaje que contendrá el componente "alerta"

  const handleClose = () => {
    props.tabla(true);
    props.nuevoContrato(false);
  };

  //const para obtener los productos
  const obtenerProductos = async () => {
    fetch(UrlApi + "/productos")
      .then((data) => data.json())
      .then((data) => setProductos(data));
  };

  const obtenerFactibilidades = async () => {
    fetch(UrlApi + "/fact")
      .then((data) => data.json())
      .then((data) => setFactibilidades(data));
  };

  const obtenerDesplegableEstado = async () => {
    fetch(UrlApi + "/desplegables/estadocontratos")
      .then((data) => data.json())
      .then((data) => setDesplegablesEstado(data));
  };

  const obtenerDesplegablesModalidadC = async () => {
    fetch(UrlApi + "/desplegables/modalidadcontratos")
      .then((data) => data.json())
      .then((data) => setDesplegablesModalidadC(data));
  };

  useEffect(() => {
    obtenerFactibilidades();
    obtenerDesplegablesModalidadC();
    obtenerDesplegableEstado();
    obtenerProductos();
  }, []);

  const almacenar = (e) => {
    setDatosContrato({
      ...datosContrato,
      [e.target.name]: e.target.value,
    });
  };

  //Funcion para guardar contratos
  async function guardarContratos(e) {
    setDisBtn(true);
    e.preventDefault();
    const response = await fetch(UrlApi + "/contratos", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(datosContrato),
    });
    if (response.ok === false) {
      const error = response && response.json();
      setError(error);
      setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      try {
        const json = await response.json();
        if (json.message) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje(
            json.message === "OK" ? "Guardado" : "Guardado. Actualizar RTA Api"
          );
        } else if (json.sqlMessage) {
          json && setError(json);
          setMensajeAlerta(
            "SQL erróneo (" + json.code + " Errno: " + json.errno + " )"
          );
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje("Guardado. Actualizar RTA A Json");
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      }
    }
  }

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  return (
    <div>
      <Container>
        {notificacion ? (
          <Notify
            mensaje={mensaje}
            open={openSnack}
            color={"#D4EFDF"}
            severity={"success"}
            handleClose={handleClose}
          />
        ) : null}
        {/* ALERT --> SE MUESTRA SI HAY UN ERROR EN LA API (NO SE CONECTA O ESTÁ MAL LA RUTA, ETC.) */}
        {error && mensajeAlerta ? (
          <Alerta mensaje={mensajeAlerta} severity={"error"} />
        ) : null}
        <Box
          backgroundColor="#F4F6F6"
          p={3}
          mb={8}
          border={0.5}
          borderColor={"#ABB2B9"}
          borderRadius={4}
        >
          <Grid
            sx={{
              backgroundColor: "#e0e0e0",
              flexGrow: 1,
              border: 0.5,
              mb: 2,
              borderRadius: 2,
              p: 2,
              textAlign: "center",
              borderColor: "#90a4ae",
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={2} md={1} alignItems="center">
                <IconButton onClick={handleClose}>
                  <ArrowBackIcon />
                </IconButton>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="h6" gutterBottom>
                  Nuevo contrato para {datosformulario.razon_social}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <form onSubmit={guardarContratos} autoComplete="on">
            <Grid container spacing={2} direction="row">
              <Grid item xs={4}>
                <TextField
                  style={style}
                  size="small"
                  placeholder="Número contrato"
                  required
                  focused
                  fullWidth={true}
                  name="nro_contrato"
                  label="Número contrato"
                  color="primary"
                  type="number"
                  value={datosContrato.nro_contrato}
                  onChange={almacenar}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AssignmentIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  style={style}
                  size="small"
                  placeholder="Consumo en kilos"
                  required
                  type="number"
                  focused
                  fullWidth={true}
                  name="consumo_kilos"
                  label="Consumo en kilos"
                  color="primary"
                  value={datosContrato.consumo_kilos}
                  onChange={almacenar}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <TimelineIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  style={style}
                  size="small"
                  placeholder="Consumo en litros"
                  focused
                  name="consumo_litros"
                  type="number"
                  label="Consumo en litros"
                  color="primary"
                  value={datosContrato.consumo_litros}
                  onChange={almacenar}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <TimelineIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl style={style} focused color="primary">
                  <InputLabel>Factibilidad</InputLabel>
                  <Select
                    size="small"
                    name="id_factibilidad"
                    value={datosContrato.id_factibilidad}
                    onChange={almacenar}
                    label="Factibilidad"
                  >
                    {factibilidades
                      ? factibilidades.map((elemento) => (
                          <MenuItem
                            key={elemento.idfactibilidad}
                            value={elemento.idfactibilidad}
                          >
                            {elemento.idfactibilidad}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl
                  style={style}
                  size="small"
                  focused
                  color="primary"
                  required
                >
                  <InputLabel>Modalidad</InputLabel>
                  <Select
                    size="small"
                    name="id_modalidad"
                    value={datosContrato.id_modalidad}
                    onChange={almacenar}
                    label="Modalidad"
                  >
                    {desplegablesModalidadC
                      ? desplegablesModalidadC.map((elemento) => (
                          <MenuItem key={elemento.id} value={elemento.id}>
                            {elemento.valor}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl
                  required
                  size="small"
                  style={style}
                  focused
                  color="primary"
                >
                  <InputLabel>Estado</InputLabel>
                  <Select
                    name="id_estado"
                    value={datosContrato.id_estado}
                    onChange={almacenar}
                    label="Estado"
                  >
                    {desplegablesEstado
                      ? desplegablesEstado.map((elemento) => (
                          <MenuItem key={elemento.id} value={elemento.id}>
                            {elemento.valor}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl
                  size="small"
                  style={style}
                  focused
                  color="primary"
                  required
                >
                  <InputLabel>Producto</InputLabel>
                  <Select
                    size="small"
                    name="id_producto"
                    value={datosContrato.id_producto}
                    onChange={almacenar}
                    label="Producto"
                  >
                    {productos
                      ? productos.map((elemento) => (
                          <MenuItem
                            key={elemento.idproducto}
                            value={elemento.idproducto}
                          >
                            {elemento.nombre}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  size="small"
                  style={style}
                  color="primary"
                  focused
                  required
                  label="Fecha inicio"
                  name="fecha_inicio"
                  type="date"
                  variant="outlined"
                  rows={2}
                  value={moment(datosContrato.fecha_inicio).format(
                    "YYYY-MM-DD"
                  )}
                  onChange={almacenar}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  style={style}
                  size="small"
                  fullWidth={true}
                  label="Vencimiento contrato"
                  color="primary"
                  focused
                  required
                  name="vencimiento_contrato"
                  type="date"
                  variant="outlined"
                  value={moment(datosContrato.vencimiento_contrato).format(
                    "YYYY-MM-DD"
                  )}
                  onChange={almacenar}
                />
              </Grid>
            </Grid>
            <div>
              <Grid
                mt={2}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Button variant="contained" onClick={handleClose} color="error">
                  Volver
                </Button>
                <Button
                  disabled={disBtn}
                  variant="contained"
                  type="submit"
                  color="success"
                >
                  Guardar
                </Button>
              </Grid>
            </div>
          </form>
        </Box>
        <br></br>
      </Container>
      <br></br>
    </div>
  );
}
export default AddContrato;
