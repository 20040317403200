import { useState } from "react";
import {
  Button,
  InputAdornment,
  Grid,
  Divider,
  Snackbar,
  SnackbarContent,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { UrlApi } from "../../../../services/apirest";

const DialogArba = (props) => {
  const [PercepcionArba, setPercepcionArba] = useState([]);
  const [RetencionArba, setRetencionArba] = useState([]);
  const [Open, setOpen] = useState(props.openclose);

  const [snack, setSnack] = useState(false);
  const [mensaje, setMensaje] = useState("Guardando Archivos...");
  const [colortexto, setColorTexto] = useState("white");
  const [colorfondo, setColorFondo] = useState("black");
  const handleFilePercepcionArba = (e) => {
    setPercepcionArba(e.target.files[0]);
  };

  const handleFileRetencionArba = (e) => {
    setRetencionArba(e.target.files[0]);
  };
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnack(false);
  };

  function UploadArba() {
    props.isSubmitting(true);
    const data = new FormData();
    data.append("uid", props.uuid);
    data.append("id_provincia", 2);
    data.append("percepciones", PercepcionArba);
    data.append("retenciones", RetencionArba);

    try {
      fetch(UrlApi + "/uploadfiles/upload-arba", {
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then(
          (dato) => setMensaje("Archivo Guardado"),
          setSnack(true),
          setColorTexto("white"),
          setColorFondo("green"),
          setOpen(!Open)
        )
        .catch((err) => console.error(err));
      handleClose();
    } catch (error) {
      console.error(error);
    }
  }

  const handleClose = () => {
    setOpen(!props.openclose);
  };
  return (
    <div>
      {Open ? (
        <Dialog
          open={props.openclose}
          onClose={handleClose}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Buenos Aires</DialogTitle>
          <Divider />
          <DialogContent>
            {/*   <DialogContentText>
                        To subscribe to this website, please enter your email address here. We
                        will send updates occasionally.
                    </DialogContentText> */}
            <Grid
              container
              spacing={2}
              direction="column"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              wrap="wrap"
            >
              <Grid item>
                <Button variant="outlined" component="label" fullWidth>
                  <input
                    hidden
                    type="file"
                    accept=".txt"
                    onChange={handleFilePercepcionArba}
                  />
                  <ContentPasteSearchIcon size="small" />
                  Percepcion
                </Button>
              </Grid>

              <Grid item>
                {" "}
                {PercepcionArba ? (
                  <TextField
                    disabled
                    value={PercepcionArba.name}
                    id="input-with-icon-textfield"
                    label="Archivo"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CloudUploadIcon color="primary" />
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                ) : null}{" "}
              </Grid>
              <Grid item>
                <Button variant="outlined" component="label" fullWidth>
                  <input
                    hidden
                    type="file"
                    accept=".txt"
                    onChange={handleFileRetencionArba}
                  />
                  <ContentPasteSearchIcon size="small" />
                  Retencion
                </Button>
              </Grid>

              <Grid item>
                {RetencionArba ? (
                  <TextField
                    disabled
                    value={RetencionArba.name}
                    id="input-with-icon-textfield"
                    label="Archivo"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CloudUploadIcon color="primary" />
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                ) : null}
              </Grid>
            </Grid>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={handleClose} color="error" variant="outlined">
              Cerrar
            </Button>
            <Button
              type="submit"
              onClick={UploadArba}
              color="success"
              variant="outlined"
            >
              Subir Archivo
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
      <Snackbar
        open={snack}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <SnackbarContent
          style={{
            backgroundColor: colorfondo,
            color: colortexto,
            fontWeight: "bold",
          }}
          message={mensaje}
        />
      </Snackbar>
    </div>
  );
};

export default DialogArba;
