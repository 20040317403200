import React from "react";
import { useEffect, useState, useMemo } from "react";
import {UrlApi} from "../../../../../services/apirest"
import { Container, Grid, Button } from "@mui/material";
import AddContrato from "./AddContrato";
import FichaContrato from "./FichaContrato";
import DetalleContrato from "./DetalleContrato";

import Box from "@mui/material/Box"
import MaterialReactTable, {
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";

function ListContratos(props) {
  const [dataCliente, setDataCliente] = useState(props.datos);
  const [contratos, setContratos] = useState(null);
  const [tabla, setTabla] = useState(true);
  const [nuevoContrato, setNuevoContrato] = useState(false);
  const [fichaContrato, setFichaContrato] = useState(false);
  const [dataContrato, setDataContrato] = useState(null);

  const obtenerContratos = () => {
    fetch(UrlApi + "/contratos/cliente/" + dataCliente.id)
      .then((data) => data.json())
      .then((data) => setContratos(data));
  };

  useEffect(() => {
    obtenerContratos();
  }, [tabla]);

  const handleClickNuevo = () => {
    setTabla(false);
    setNuevoContrato(true);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "nro_contrato", //access nested data with dot notation
        header: "Nro",
      },
      {
        accessorKey: "modalidad", //access nested data with dot notation
        header: "Modalidad",
      },
      {
        accessorKey: "fecha_inicio", //access nested data with dot notation
        header: "Inicio",
      },
      {
        accessorKey: "vencimiento_contrato", //access nested data with dot notation
        header: "Vencimiento",
      },
      {
        accessorKey: "producto", //access nested data with dot notation
        header: "Producto",
      },
    ],
    []
  );

  // const displayColumnDefOptions = {
  //   "mrt-row-actions": {
  //     muiTableHeadCellProps: {
  //       sx: {
  //         fontSize: 16,
  //         color: "black",
  //         fontWeight: "normal",
  //         paddingTop: 1,
  //         paddingBottom: 1,
  //         backgroundColor: "#D4E6F1",
  //         borderColor: "#FDFEFE",
  //       },
  //     },
  //     size: 60,
  //   },
  // };

  const initialState = {
    density: "compact",
    showGlobalFilter: true,
    pagination: {
      pageSize: contratos && contratos.length > 5 ? 10 : 5,
      pageIndex: 0,
    },
  };

  const tableHeadCellProps = {
    align: "center",
    sx: {
      fontSize: 14,
      color: "black",
      fontWeight: "normal",
      paddingTop: 1,
      paddingBottom: 1,
      backgroundColor: "#D4E6F1",
      borderColor: "#FDFEFE",
    },
  };



  return (
    <div>
      {contratos && tabla ? (
        <>
          <Container maxWidth="xl" disableGutters>
          <Grid
            container
            mb={1}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Button
                onClick={() => {
                  setNuevoContrato(true)
                  setTabla(false)
                }}
                variant="text"
                fullWidth
                // startIcon={<AddLocationAltIcon />}
              >
                Nuevo Contrato
              </Button>
            </Grid>
          </Grid>
            <Box boxShadow={3}>
              <MaterialReactTable
                localization={MRT_Localization_ES}
                enableTopToolbar={false}
                enablePagination={true}
                columns={columns}
                data={contratos}
                enableColumnFilterModes={false}
                enableColumnOrdering={false}
                enableGrouping={false}
                enablePinning
                // enableRowActions
                initialState={initialState}
                enableRowSelection={false}
                positionToolbarAlertBanner="bottom"
                // positionActionsColumn="last"
                enableHiding={false}
                muiTableHeadCellProps={tableHeadCellProps}
                positionExpandColumn="first"
                renderDetailPanel={({ row }) => (
                  <>
                  <DetalleContrato
                    contrato={row.original}
                    actulizar={obtenerContratos}
                  />
                  </>
                )}
                enableExpandAll={false}
                muiTableBodyCellProps={({ row }) => ({
                  align: "center",
                  sx: {
                    backgroundColor: "#ffffff",
                    color: "black",
                    borderColor: "#FDFEFE",
                  },
                })}
                enableColumnActions={false}
                enableColumnFilters={false} //Quita filtros por columna
                // displayColumnDefOptions={displayColumnDefOptions}
                enableDensityToggle={false}
                enableBottomToolbar={false}
                // renderRowActionMenuItems={({ closeMenu, row }) => [
                //   <MenuItem
                //     key={0}
                //     onClick={() => {
                //       closeMenu();
                //       setDireccion(row.original);
                //       setEditar(true);
                //     }}
                //     sx={{ m: 0 }}
                //   >
                //     <ListItemIcon>
                //       <BorderColorIcon color="primary" />
                //     </ListItemIcon>
                //     Editar
                //   </MenuItem>,

                //   <MenuItem
                //     key={1}
                //     onClick={() => {
                //       closeMenu();
                //       setDireccion(row.original);
                //       setBaja(true);
                //     }}
                //     sx={{ m: 0 }}
                //   >
                //     <ListItemIcon>
                //       <Delete color="error" />
                //     </ListItemIcon>
                //     Eliminar
                //   </MenuItem>,

                //   <Divider light />,
                //   <MenuItem
                //     key={1}
                //     onClick={() => {
                //       closeMenu();
                //       setAñadir(true);
                //       setDireccion(row.original);
                //     }}
                //     sx={{ m: 0 }}
                //   >
                //     <ListItemIcon>
                //       <PropaneIcon color="success" />
                //     </ListItemIcon>
                //     Añadir Tanque
                //   </MenuItem>,
                //   <MenuItem
                //     key={1}
                //     onClick={() => {
                //       closeMenu();
                //       setDireccion(row.original);
                //       setVerTanques(true);
                //     }}
                //     sx={{ m: 0 }}
                //   >
                //     <ListItemIcon>
                //       <MiscellaneousServicesIcon color="secondary" />
                //     </ListItemIcon>
                //     Administrar Tanques En Domicilio
                //   </MenuItem>,
                // ]}
              />
            </Box>
            <br />
            <br />
          </Container>

        </>
      ) : null}
      {nuevoContrato ? (
        <AddContrato
          datos={dataCliente}
          tabla={setTabla}
          nuevoContrato={setNuevoContrato}
        />
      ) : null}
      {fichaContrato ? (
        <FichaContrato
          tabla={setTabla}
          ficha={setFichaContrato}
          datos={dataContrato}
        />
      ) : null}
    </div>
  );
}

export default ListContratos;
