//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio
// Creation Date : 22/08/2024
// Version : 1
// Description : para agregar cumpleaños
//------Changes -------------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Container,
  Grid,
  Box,
  TextField,
  Button,
  InputAdornment,
  Dialog,
  DialogContent,
  Stack,
  Autocomplete,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import Notify from "../Notify";
import Alerta from "../Alerta";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import Title from "../Title";
import { logEventos } from "../../services/services";
import moment from "moment";

function Add_cumpleanios(props) {
  const [datosCumpleanios, setDatosCumpleanios] = useState({
    nombre: "",
    sucursal: null,
    funcion: "",
    nacimiento: "",
    email: "",
    estado: 1,
    usuario: localStorage.getItem("user"),
  });
  const [uso, setUso] = useState(null);
  const [usuarios, setUsuarios] = useState(null);
  const [disBtn, setDisBtn] = useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
  const [mensajeAlerta, setMensajeAlerta] = useState(""); //mensaje que contendrá el componente "alerta"
  const [finalizar, setFinalizar] = useState(); //finalizar === 1 lo manda como prop el componente "notify" e indica que la transaccion ha finalizado y debe ejecutarse la funcion para cerrar
  const [severityAlerta, setSeverityAlerta] = useState(""); //define severidad de la alerta (success error o warning)
  const [severitySnack, setSeveritySnack] = useState(""); //define severidad del snackbar (notificación)
  const [alta, setAlta] = useState(props.alta);

  /*   GET     */
  const [sucursal, setSucursal] = useState();
  const [sucursalSeleccionada, setSucursalSeleccionada] = useState("");

  const obtenerSucursales = () => {
    fetch(UrlApi + "/sucursales")
      .then((data) => data.json())
      .then((data) => setSucursal(data));
  };
  useEffect(() => {
    obtenerSucursales();
  }, []);
  const handleClose = () => {
    setAlta(!alta);
    if (props.onClose) {
      props.onClose();
    }
  };
  const almacenar = (e) => {
    setDatosCumpleanios({
      ...datosCumpleanios,
      [e.target.name]: e.target.value === "" ? null : e.target.value,
    });
  };
  const guardarRegistro = async (e) => {
    logEventos(
      "Guardar nuevo registro",
      "Cumpleaños",
      "Se guardo nuevo registro",
      localStorage.getItem("user")
    );
    setDisBtn(true);
    e.preventDefault();
    const response = await fetch(UrlApi + "/rrhh_cumpleanios", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        nombre: datosCumpleanios.nombre,
        sucursal: sucursalSeleccionada.id,
        funcion: datosCumpleanios.funcion,
        nacimiento: datosCumpleanios.nacimiento,
        email: datosCumpleanios.email,
        estado: 1,
        usuario: localStorage.getItem("user"),
      }),
    });
    if (response.ok === false) {
      console.log("ERROR EN API");
      const error = response && response.json();
      setError(error);
      setSeverityAlerta("error");
      setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      console.log("Conexión con api OK");
      try {
        //Parseo respuesta de la api a json()
        const json = await response.json();
        //si api me devuelve lo q le puse en message
        if (json.message) {
          //Si ese mensaje es "OK"
          if (json.message === "OK") {
            console.log("Guardado Satisfactoriamente");
            setNotificacion(true);
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setMensaje("Guardado");
            setSeveritySnack("success");
          } else {
            console.log("res en api incorrecto. Revisar que sea 'OK'");
            setNotificacion(true);
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setSeveritySnack("error");
            setMensaje("Registro Guardado. REVISAR RESPUESTA DE API (E_S)");
          }
        } else if (json.sqlMessage) {
          json && setError(json);
          console.log(json);
          setMensajeAlerta(
            "SQL erróneo (" + json.code + " Errno: " + json.errno + " )"
          );
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          console.log(
            "Guardado. Advertencia: Actualizar respuesta de api. JSON invalido."
          );
          setNotificacion(true);
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("Guardado");
          setSeveritySnack("success");
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      }
    }
    handleClose();
  };
  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };
  const size = "small";

  return (
    <div>
      <Dialog
        open={props.alta}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <Title
          titulo={`Cargar nuevo registro`}
          handleClose={() => {
            handleClose();
          }}
        />
        <Box
          border={0.5}
          borderColor={grey[300]}
          backgroundColor="#F4F6F6"
          p={2}
          py={2}
          mt={1}
        >
          <DialogContent>
            <Container maxWidth="xl">
              <form onSubmit={guardarRegistro} autoComplete="off">
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      size={size}
                      placeholder="....."
                      style={style}
                      focused
                      color="primary"
                      required
                      name="nombre"
                      label="Apellido y Nombre"
                      variant="outlined"
                      value={datosCumpleanios.nombre}
                      inputProps={{
                        maxLength: 60,
                      }}
                      onChange={almacenar}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonOutlineIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6.5}>
                    <Autocomplete
                      disabled={!sucursal}
                      size={size}
                      focused
                      disableClearable
                      id="sucursales"
                      noOptionsText={"Sucursal INEXISTENTE"}
                      options={sucursal}
                      autoHighlight
                      getOptionLabel={(sucursal) =>
                        sucursal.id + "-" + sucursal.nombre
                      }
                      onChange={(event, value) =>
                        setSucursalSeleccionada(value)
                      }
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          key={option.id}
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option.id}-{option.nombre}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          style={style}
                          required
                          focused
                          size="small"
                          placeholder="Tipee para buscar..."
                          {...params}
                          label="Sucursal"
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={5.5}>
                    <TextField
                      fullWidth
                      size={size}
                      placeholder="....."
                      style={style}
                      focused
                      color="primary"
                      required
                      name="funcion"
                      label="Función"
                      variant="outlined"
                      value={datosCumpleanios.funcion}
                      inputProps={{
                        maxLength: 60,
                      }}
                      onChange={almacenar}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <WorkOutlineIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={7} mb={3}>
                    <TextField
                      fullWidth
                      size={size}
                      placeholder="ejemplo@mail.com"
                      type="email"
                      style={style}
                      focused
                      color="primary"
                      required
                      name="email"
                      label="Email"
                      variant="outlined"
                      value={datosCumpleanios.email}
                      inputProps={{
                        maxLength: 60,
                      }}
                      onChange={almacenar}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MailOutlineIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={5} mb={3}>
                    <TextField
                      style={style}
                      label="Nacimiento"
                      name="nacimiento"
                      size="small"
                      type="date"
                      focused
                      required
                      fullWidth
                      value={moment(datosCumpleanios.nacimiento).format("YYYY-MM-DD")
                      }
                      onChange={almacenar}
                    />
                  </Grid>
                </Grid>
                <Stack direction="row">
                  <Grid container direction={{ xs: "column", sm: "row" }}>
                    <Grid item xs={9}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleClose}
                      >
                        Volver
                      </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              </form>
            </Container>
          </DialogContent>
        </Box>
      </Dialog>
      {notificacion ? (
        <Notify
          mensaje={mensaje}
          open={openSnack}
          color={colormensaje}
          finalizar={setFinalizar}
          severity={severitySnack}
        />
      ) : null}
      {/* ALERT --> SE MUESTRA SI HAY UN ERROR EN LA API (NO SE CONECTA O ESTÁ MAL LA RUTA, ETC.) */}
      {error && mensajeAlerta ? (
        <Alerta mensaje={mensajeAlerta} severity={severityAlerta} />
      ) : null}
    </div>
  );
}

export default Add_cumpleanios;
