//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio/Nicolas Pascucci
// Creation Date : 22/01/2024
// Version : 1
// Description : pantalla de los aprobados
//------Changes ----------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect, useMemo } from "react";
import { UrlApi } from "../../../services/apirest";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import { IconButton, Tooltip } from "@mui/material";
import TablaAcciones from "./TablaAcciones";
import AddCommentIcon from "@mui/icons-material/AddComment";

function FactAprobados() {
  const [factAprobadas, setFactAprobadas] = useState();
  const [tabla, setTabla] = useState(true);
  const [update, setUpdate] = useState(false);
  const [openAcciones, setOpenAcciones] = useState(false);
  const [factDatos, setFactDatos] = useState();
  //========================================================================/
  const obtenerFactAprobadas = () => {
    fetch(UrlApi + "/factibilidades/factibilidadxestadouser/199/"+ localStorage.getItem("user")+"/"+localStorage.getItem("user"))
      .then((data) => data.json())
      .then((data) => setFactAprobadas(data));
  };

  useEffect(() => {
    obtenerFactAprobadas();
  }, [tabla]);

  // para actualizar
  useEffect(() => {
    if (update) {
      obtenerFactAprobadas();
      setUpdate(false);
    }
  }, [update]);
  //================================== TABLA ======================================/
  const columns = useMemo(
    () => [
      { header: "ID", accessorKey: "id", size: 60 },
      { header: "Cliente", accessorKey: "razon_social", size: 120 },
      { header: "Sucursal Cliente", accessorKey: "cli_sucursal", size: 120 },
      { header: "RRCC", accessorKey: "usuario_registra", size: 130 },
    ],
    []
  );

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "success",
    size: "small",
  };

  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)" //background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //override to make tooltip font size larger
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const initialState = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
  };

  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };
  //=========================== FUNCIONES =============================================/
  const handleClickOpenAcciones = (row) => {
    setOpenAcciones(true);
    setFactDatos(row);
  };

  const handleOpenCloseAcciones = (value) => {
    setOpenAcciones(false);
  };


  return (
    <div>
      {factAprobadas && tabla ? (
        <>
          <ThemeProvider theme={tableTheme}>
            <MaterialReactTable
              enableRowActions
              enableColumnResizing
              enableExpandAll={false}
              muiTableHeadCellProps={tableHeadCellProps}
              enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
              enableGrouping // para permitir agrupar x estado x ej
              layoutMode="grid"
              muiTableBodyCellProps={{
                align: "left", //el contenido de la tabla
              }}
              enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
              enableColumnFilters={false} //desactiva filtros x  columna
              enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
              enableDensityToggle={false} //
              localization={MRT_Localization_ES} //idioma
              enableColumnDragging={false} //para arrastrar columnas
              initialState={initialState}
              muiSearchTextFieldProps={muiSearchTextFieldProps}
              columns={columns}
              data={factAprobadas} //con lo q la completas (esto cambia siempre)
              enableStickyFooter
              positionActionsColumn="last" // posicion de la columna de acciones al final
              // ACCIONES
              renderToolbarInternalActions={(
                { table } // boton para nuevo , al lado del buscar
              ) => (
                <>
                  <MRT_FullScreenToggleButton table={table} />
                </>
              )}
              renderRowActions={({ row, table }) => (
                //las acciones del costado editar y ver
                <div>
                  <Tooltip arrow placement="left" title="Ver Acciones">
                    <IconButton
                      onClick={() => {
                        handleClickOpenAcciones(row.original);
                      }}
                      color={"primary"}
                    >
                      <AddCommentIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            />
          </ThemeProvider>
          <br />
        </>
      ) : null}

      {openAcciones ? (
        <TablaAcciones
          openAcciones={openAcciones}
          factDatos={factDatos}
          onClose={() => handleOpenCloseAcciones(false)} // Proporciona una función de cierre
        />
      ) : null}
    </div>
  );
}

export default FactAprobados;
