import React, { useState, useEffect } from 'react';
import { getData, putData, } from '../../../../../services/library';
import { FormControl, InputLabel, Select, MenuItem, IconButton, FormControlLabel, Checkbox, Typography, Box, Grid, Button, Dialog, DialogActions, DialogContent, Stack, TextField, Autocomplete } from '@mui/material';
import { grey } from '@mui/material/colors';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Notify from '../../../../Notify';
import { logEventos } from '../../../../../services/services';
export default function FormEditarLista(props) {
    const [disBtn, setDisBtn] = useState(false)
    const [datosLista, setDatosLista] = useState(props.datos)
    const [articulos, setArticulos] = useState('');
    const [articuloSelected, setArticulosSelected] = useState("");
    const [medidas, setmedidas] = useState(null);
    const [checked, setChecked] = useState(datosLista.id_articulo !== null);
    const [notificacion, setNotificacion] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
    });

    useEffect(() => {
        getData("/stock_articulos/allarticles", setArticulos);
        getData("/unidad_medida", setmedidas);
    }, []);

    useEffect(() => {
        if (articulos) {
            let article = articulos.find((art) => art.id_articulo === datosLista.id_articulo)
            // console.log("art", articulos)
            setArticulosSelected(article)
        }
    }, [articulos, datosLista.id_articulo]);

    const handleClose = () => {
        props.open(false)
        props.actualizar()
    };

    async function guardarListaMateriales(e) {
        setDisBtn(true);
        putData(e,
            '/lista_materiales/lista/' + datosLista.idlista,
            {
                cantidad: datosLista.cantidad,
                id_articulo: articuloSelected ? articuloSelected.id_articulo : null,
                material: datosLista.material,
                id_unidad_medida: datosLista.id_unidad_medida
            },
            setNotificacion,
            logEventos("Editar lista", "Lista de materiales", "Lista de materiales", localStorage.getItem("user"))
        );
    };

    const style = {
        backgroundColor: "#ffffff",
        width: "100%"
    };
    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const almacenar = (e) => {
        const { name, value } = e.target;
        setDatosLista((prevDatosLista) => ({
            ...prevDatosLista,
            [name]: value ? value : ""
        }));
    };

    // console.log("Articulo selected", datosLista.id_articulo)

    return (
        <Dialog open={props.open} fullWidth >
            <Box sx={{ flexGrow: 1 }}>
                <Grid container backgroundColor="#1F618D" p={1.5}>
                    <Grid item xs={2}>
                        <IconButton onClick={handleClose}>
                            <ArrowBackIcon sx={{ color: grey[50] }} />
                        </IconButton>
                    </Grid>
                    <Grid
                        item
                        xs={8}
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Typography variant="h5" color="#ffffff">
                            Editar
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <DialogContent>

                <Box>
                    <Stack spacing={1} direction="row" >
                        <FormControlLabel
                            labelPlacement="start"
                            label={"De Stock"}
                            checked={checked}
                            onChange={handleChange}
                            control={<Checkbox />}
                        />
                        <TextField
                            focused color="primary" required size="small"
                            style={style}
                            type="number"
                            name="cantidad"
                            label="Cantidad"
                            value={datosLista.cantidad}
                            onChange={almacenar}
                        />
                        <>
                            {checked ? (
                                <Autocomplete
                                    value={articuloSelected}                                        
                                    clearOnEscape
                                    defaultValue={articuloSelected || null}
                                    onChange={(event, value) => {
                                        setArticulosSelected(value);
                                    }}
                                    options={articulos}
                                    fullWidth
                                    isOptionEqualToValue={(option, value) => option.valueOf === value || value === ""}
                                    getOptionLabel={(option) => option.nombre}
                                    renderInput={(params) => (
                                        <TextField {...params} focused color="primary"  label="Articulos" size="small" style={style} />
                                    )}
                                />
                            ) :
                                (
                                    <>
                                        <FormControl focused color="primary" margin='dense' size='small' style={style}>
                                            <InputLabel id="lbl_autorizante">Unidad de medida</InputLabel>
                                            <Select
                                                value={datosLista.id_unidad_medida}
                                                fullWidth
                                                onChange={almacenar}
                                                name='id_unidad_medida'
                                                label="Unidad de medida"
                                            >
                                                {(medidas ?? []).map(elemento => (
                                                    <MenuItem key={elemento.id} value={elemento.id}>
                                                        {elemento.descripcion}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            focused color="primary"  
                                            size="small"
                                            autoComplete='off'
                                            style={style}
                                            label="Articulo/Servicio"
                                            value={datosLista.material}
                                            name="material"
                                            onChange={almacenar} />
                                    </>
                                )
                            }
                        </>
                    </Stack>
                </Box>
            </DialogContent>
            <DialogActions >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItemns="center"
                >
                    <Button variant="contained" color="error" onClick={handleClose}>VOLVER</Button>
                    <Button disabled={disBtn} color="success" variant="contained" onClick={guardarListaMateriales}>
                        Editar
                    </Button>
                </Grid>
            </DialogActions>
            {notificacion && notificacion.open ? (
                <Notify
                    mensaje={notificacion.mensaje}
                    open={notificacion.open}
                    color={notificacion.color}
                    handleClose={handleClose}
                    severity={notificacion.severity}
                />
            ) : null}
        </Dialog>
    );
};

//========================================================================
/*----- Component --------------------------------------------------------/
// Developer     : Candela Grosso   
// Creation Date : 29/11/2023
// Version       : 1.0  
// Description   :  Con este componente el usuario es capaz de :
        - EDITAR UN ITEM DE LA LISTA DE MATERIALES DE UN REQUERIMIENTO DESDE EL COMPONENTE Editarreq
//------Changes --------------------------------------------------------------------- -------------//
// Change Date   :
// Change by     :
// Change description: 
//========================================================================*/