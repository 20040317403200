import React, { useState, useEffect } from "react";
import {
  Grid,
  MenuItem,
  Select,
  TextField,
  InputLabel,
  FormControl,
} from "@mui/material";
import { UrlApi } from "../../../services/apirest";
import axios from "axios";

//Iconos y boludeces para los textfields
import InputAdornment from "@mui/material/InputAdornment";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import useCP from "../hooks/useCP";

const AddProveedor = ({
  DatosFormulario,
  onChange,
  Cuit,
  handleCuitChange,
  isValid,
}) => {
  const { handleCP, CP } = useCP();

  const [Provincia, setProvincia] = useState();
  const [Localidad, setLocalidad] = useState([]);

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };
  const [CondVenta, setCondVenta] = useState();
  const obtenerCondicion = async () => {
    await axios
      .get(UrlApi + "/condicion_pago")
      .then((res) => setCondVenta(res.data));
  };
  useEffect(() => {
    obtenerCondicion();
    obtenerProvincia();
  }, []);

  useEffect(() => {
    obtenerLocalidad();
  }, [DatosFormulario.id_provincia]);

  const obtenerProvincia = () => {
    axios
      .get(UrlApi + "/provincias")
      .then((response) => setProvincia(response.data));
  };
  const obtenerLocalidad = async () => {
    await axios
      .get(UrlApi + "/localidades/prov/" + DatosFormulario.id_provincia)
      .then((response) => {
        setLocalidad(response.data);
        handleCP(response.data[0]?.cp);
        // eslint-disable-next-line no-useless-computed-key
      });
  };

  return (
    <>
      <Grid container spacing={2} justifyContent={"center"}>
        <Grid item xs={4}>
          <TextField
            style={style}
            value={Cuit}
            type="text"
            focused
            onChange={(e) => handleCuitChange(e)}
            id="cuit"
            name="nro_cuit"
            required
            placeholder="Numero CUIT"
            label="Numero Cuit"
            error={!isValid}
            helperText={!isValid ? "CUIT inválido" : ""}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            focused
            style={style}
            placeholder="Razon Social"
            type="text"
            required
            error={DatosFormulario.razon_social === ""}
            helperText={
              DatosFormulario.razon_social === ""
                ? "Se requiere una razon social"
                : null
            }
            name="razon_social"
            id="razon_social"
            label="Razon Social"
            onChange={onChange}
            value={DatosFormulario.razon_social}
            inputProps={{ maxLength: 200 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            focused
            style={style}
            placeholder="Nombre Contacto"
            type="text"
            name="nombre_contacto"
            id="nombre_contacto"
            label="Nombre Contacto"
            onChange={onChange}
            value={DatosFormulario.nombre_contacto}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle color="info" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            label="Telefono Contacto"
            name="telefono_contacto"
            value={DatosFormulario.telefono_contacto}
            onChange={onChange}
            focused
            type="number"
            style={style}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <ContactPhoneIcon color="info" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            focused
            style={style}
            placeholder="Nombre Fantasia"
            type="text"
            name="nombre_fantasia"
            label="Nombre Fantasia"
            onChange={onChange}
            value={DatosFormulario.nombre_fantasia}
            inputProps={{ maxLength: 200 }}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl style={style} focused>
            <InputLabel>Provincia</InputLabel>
            <Select
              label="Provincia"
              required
              name="id_provincia"
              value={DatosFormulario.id_provincia}
              onChange={onChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              {Provincia
                ? Provincia.map((elemento) => (
                    <MenuItem key={elemento.id} value={elemento.id}>
                      {elemento.provincia}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl style={style} focused>
            <InputLabel>Localidad</InputLabel>
            <Select
              label="Localidad"
              required
              name="id_localidad"
              value={DatosFormulario.id_localidad}
              onChange={onChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              {Localidad
                ? Localidad.map((elemento) => (
                    <MenuItem key={elemento.id} value={elemento.id}>
                      {elemento.localidad}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <TextField
            focused
            style={style}
            disabled
            name="cp"
            label="Codigo Postal"
            value={CP}
            onChange={onChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ maxLength: 200 }}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            focused
            style={style}
            placeholder="Direccion"
            type="text"
            name="direccion"
            label="Direccion"
            onChange={onChange}
            value={DatosFormulario.direccion}
            inputProps={{ maxLength: 200 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationOnIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            label="Telefono"
            name="telefono"
            value={DatosFormulario.telefono}
            onChange={onChange}
            focused
            type="number"
            style={style}
            required
            inputProps={{ maxLength: 200 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <ContactPhoneIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            focused
            style={style}
            placeholder="Email"
            type="email"
            name="email"
            id="email"
            label="Email"
            onChange={onChange}
            value={DatosFormulario.email}
            inputProps={{ maxLength: 200 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <ContactMailIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            focused
            style={style}
            placeholder="C.B.U"
            type="text"
            name="cbu"
            label="C.B.U"
            onChange={onChange}
            value={DatosFormulario.cbu}
            inputProps={{ maxLength: 22 }}
          />
        </Grid>

        <Grid item xs={4}>
          <FormControl style={style} focused>
            <InputLabel>Cond Venta</InputLabel>
            <Select
              label="Cond Venta"
              required
              name="id_condicionventa"
              value={DatosFormulario.id_condicionventa}
              onChange={onChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              {CondVenta
                ? CondVenta.map((elemento) => (
                    <MenuItem key={elemento.id} value={elemento.id}>
                      {elemento.condicion}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <TextField
            focused
            style={style}
            placeholder="Sisven ID"
            type="number"
            name="sisven_id"
            label="Sisven ID"
            onChange={onChange}
            value={DatosFormulario.sisven_id}
            inputProps={{ maxLength: 22 }}
          />
        </Grid>
      </Grid>

      <br></br>
    </>
  );
};
export default AddProveedor;
