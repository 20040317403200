//----- Component --------------------------------------------------------/
// Developer     : Guillermo Sedran
// Creation Date : 14/11/2023
// Version       : 1
// Description   : Dialog para agregar un hallazgo a la tabla principal de auditoria
//
//------Changes --------------------------------------------------------------------- -------------//
// Change Date   : 29/02/2024
// Change by     : Guillermo Sedran
// Change description: Agregado usuario "Controlador", filtrado de sucursal según sucursales autorizadas
//
// Change Date   :
// Change by     :
// Change description:
//
//========================================================================/

import React, { useEffect, useState } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Button,
  Container,
  DialogContent,
  DialogTitle,
  Box,
  Stack,
  Grid,
  TextField,
  Autocomplete,
} from "@mui/material";
import moment from "moment";

// LOG EVENTOS
import { logEventos } from "../../services/services";

// ENVIAR MAILS
import { enviarmails } from "../../services/services";

// Notification
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

// ICONOS
import AttachFileIcon from "@mui/icons-material/AttachFile";

//ADJUNTAR ARCHIVOS
import { v4 as uuidv4 } from "uuid"; //ADJUNTAR ARCHIVOS
import FileUploadHallazgo from "./FileUploadHallazgo";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Auditorias_agregar_hallazgo(props) {
  // ADJUNTOS
  const [uuid_hallazgo, setUuid_hallazgo] = useState(uuidv4()); //ADJUNTAR ARCHIVOS
  const [openCloseAdj, setOpenCloseAdj] = useState(false); //ADJUNTAR COMPROBANTE
  const [newUuid, setNewUuid] = useState(props.newUuid); //ADJUNTAR COMPROBANTE

  const handleOpenCloseAdj = (value) => {
    setOpenCloseAdj(value);
  };

  // console.log("NEW UUID", newUuid);
  // console.log("UUID HALLAZGO", uuid_hallazgo);

  //*************************************************************************************************************************************/

  // OBTENER FECHA ACTUAL
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);

  //*************************************************************************************************************************************/

  // console.log("DATE: ", date);

  const [datosAuditoriaHallazgos, setDatosAuditoriaHallazgos] = useState({
    auditor: localStorage.getItem("user"), // AGREGAR LOCALSTORAGE
    fecha_registro: date,
    fecha_auditoria: date,
    id_sucursal: null,
    problema_sugerencia: "",
    descripcion_acciones: "",
    responsable: null, // SELECT / AUTOCOMPLETE
    vencimiento: date, // DATE PICKER
    uuid_hallazgo: uuid_hallazgo,
    estado: 178,
  });

  const [sucursales, setSucursales] = useState();
  // LOCALSTORAGE GET ITEM "suc_auth"
  const [usuarios, setUsuarios] = useState();

  // NOTIFICACION
  const [openSnack, setOpenSnack] = useState(props.openSnack);
  const [colormensaje, setColorMensaje] = useState(props.colormensaje);
  const [mensaje, setMensaje] = useState(props.mensaje);
  // DESHABILITAR BOTON AL GUARDAR
  const [disBtn, setDisBtn] = useState(props.disBtn);

  // ------------------------------------------------------

  // AUTOCOMPLETE
  const [sucursalSeleccionado, setSucursalSeleccionado] = useState({});
  const [usuarioSeleccionado, setUsuarioSeleccionado] = useState({});

  // -----------------------------------------------------------

  // console.log("AUDITOR: ", localStorage.getItem("user"));

  // ------------------------------------------------------------------------

  const [limpiarAutocomplete, setLimpiarAutocomplete] = useState(false);

  // Función para activar la limpieza del autocompletado
  const activarLimpieza = () => {
    setLimpiarAutocomplete(true);

    // Después este tiempo, cambiar el valor de limpiarAutocomplete a false
    setTimeout(() => {
      setLimpiarAutocomplete(false);
    }, 500); // 1000 milisegundos = 1 segundo
  };

  // ------------------------------------------------------------------------

  const obtenerSucursales = () => {
    const esAuditor = localStorage.getItem("auditor") === "1";
    // SI ES AUDITOR: OBTIENE TODO, SINO SOLO LAS AUTORIZADAS
    let url;
    if (esAuditor) {
      url = UrlApi + "/sucursales";
    } else {
      url = UrlApi + "/sucursales/xsucauth/" + localStorage.getItem("suc_auth").split(",");
    }
    fetch(url)
      .then((data) => data.json())
      .then((data) => setSucursales(data));
  };

  const obtenerUsuarios = () => {
    fetch(UrlApi + "/userdata/xsucursal/" + sucursalSeleccionado.id)
      .then((data) => data.json())
      .then((data) => setUsuarios(data));
  };

  useEffect(() => {
    obtenerSucursales();
  }, []);

  useEffect(() => {
    obtenerUsuarios();
    setUsuarioSeleccionado(null); // Establecer usuarioSeleccionado como null
    // Cambiar el estado de mostrarAutocomplete después de que se ejecute el useEffect
    activarLimpieza();
  }, [sucursalSeleccionado.id]);

  // ------------------------------------------------------------------------

  //Funcion para guardar al crear
  const guardarCrear = async (e) => {
    logEventos("Crear hallazgo", "Auditorias", "Crear hallazgo de auditoria", localStorage.getItem("user"))
    
    // console.log("Estoy por guardar");
    setDisBtn(true);
    e.preventDefault();
    await fetch(UrlApi + "/auditorias", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        auditor: datosAuditoriaHallazgos.auditor,
        fecha_registro: datosAuditoriaHallazgos.fecha_registro,
        fecha_auditoria: datosAuditoriaHallazgos.fecha_auditoria,
        id_sucursal: sucursalSeleccionado.id,
        responsable: usuarioSeleccionado.id,
        problema_sugerencia: datosAuditoriaHallazgos.problema_sugerencia,
        descripcion_acciones: datosAuditoriaHallazgos.descripcion_acciones,
        vencimiento: datosAuditoriaHallazgos.vencimiento,
        uuid_hallazgo: newUuid, //ADJUNTAR ARCHIVOS
        estado: datosAuditoriaHallazgos.estado,
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El hallazgo se guardó exitosamente !");
          console.log("Guardado");
          datosAuditoriaHallazgos &&
            enviarmails(
              usuarioSeleccionado.email,
              "Auditoria: Nuevo Hallazgo",
              `<p>Se agregó un nuevo hallazgo a la sucursal: ${sucursalSeleccionado.nombre} .</p>
            <p><b>Título:</b> "${datosAuditoriaHallazgos.problema_sugerencia}"</p>
            <p><b>Descripción:</b> ${datosAuditoriaHallazgos.descripcion_acciones}</p>
            <p><b>Vencimiento:</b> ${datosAuditoriaHallazgos.vencimiento}</p>`
            );
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el hallazgo");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  //  ------------------------------------------------------------------------

  const almacenar = (e) => {
    setDatosAuditoriaHallazgos({
      ...datosAuditoriaHallazgos,
      [e.target.name]: e.target.value,
    });
  };

  //  ------------------------------------------------------------------------

  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
    setDisBtn(false);

    props.handleCloseCrearHallazgo();
  };

   //*************************************************************************************************************************************/

   useEffect(() => {
    logEventos("Menu agregar hallazgo", "Auditorias", "Apertura del menú para agregar hallazgos de auditorias", localStorage.getItem("user"))
}, [])

   //*************************************************************************************************************************************/

  // AUDITORIA: IGUAL O MENOR A HOY
  // console.log("Datos Hallazgo: ", datosAuditoriaHallazgos);
  // console.log("Sucursal Seleccionado: ", sucursalSeleccionado);
  // console.log("Sucursal Seleccionado Nombre: ", sucursalSeleccionado.nombre);
  // console.log("Usuario Seleccionado: ", usuarioSeleccionado);
// 
  return (
    <>
      <DialogTitle alignSelf="center">
        <b>{"Agregar Hallazgo"}</b>
      </DialogTitle>
      <DialogContent>
        <Container maxWidth="xl">
          <form onSubmit={guardarCrear}>
            <Grid container mt={2} mb={1} spacing={1}>
              <Grid item xs={3} mb={2}>
                <TextField
                  label="Auditor"
                  disabled
                  fullWidth
                  required
                  size="small"
                  name="auditor"
                  value={datosAuditoriaHallazgos.auditor}
                  onChange={almacenar}
                />
              </Grid>
              <Grid item xs={3} mb={2}>
                <TextField
                  fullWidth
                  required
                  size="small"
                  type="date"
                  placeholder="Fecha de Auditoria"
                  name="fecha_auditoria"
                  label="Fecha de Auditoria"
                  variant="outlined"
                  value={moment(datosAuditoriaHallazgos.fecha_auditoria).format(
                    "YYYY-MM-DD"
                  )}
                  onChange={almacenar}
                  inputProps={{
                    max: date,
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <Autocomplete
                  size="small"
                  required
                  disableClearable
                  noOptionsText={"Sucursales No Disponibles"}
                  options={sucursales}
                  autoHighlight
                  getOptionLabel={(sucursales) =>
                    sucursales.id + "-" + sucursales.nombre
                  }
                  onChange={(event, value) => setSucursalSeleccionado(value)}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      key={option.id}
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.id} - {option.nombre}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      placeholder="Tipee para buscar..."
                      {...params}
                      label="Sucursal"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={3}>
                {limpiarAutocomplete === true ? (
                  <TextField
                    fullWidth
                    size="small"
                    disabled
                    label="Responsable"
                  />
                ) : (
                  <Autocomplete
                    size="small"
                    required
                    disableClearable
                    noOptionsText={"Usuarios No Disponibles"}
                    options={usuarios}
                    autoHighlight
                    getOptionLabel={(usuarios) =>
                      usuarios.username +
                      "-" +
                      usuarios.nombrepila +
                      " " +
                      usuarios.apellido
                    }
                    onChange={(event, value) => setUsuarioSeleccionado(value)}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        key={option.id}
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.username} - {option.nombrepila}{" "}
                        {option.apellido}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        placeholder="Tipee para buscar..."
                        {...params}
                        label="Responsable"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                )}
              </Grid>

              <Grid item xs={6} mb={2}>
                <TextField
                  label="Problema / Sugerencia"
                  fullWidth
                  required
                  size="small"
                  name="problema_sugerencia"
                  value={datosAuditoriaHallazgos.problema_sugerencia}
                  onChange={almacenar}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  fullWidth
                  required
                  size="small"
                  type="date"
                  placeholder="Vencimiento"
                  name="vencimiento"
                  label="Vencimiento"
                  variant="outlined"
                  value={moment(datosAuditoriaHallazgos.vencimiento).format(
                    "YYYY-MM-DD"
                  )}
                  onChange={almacenar}
                  inputProps={{
                    min: datosAuditoriaHallazgos.fecha_auditoria,
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <Button
                  onClick={() => setOpenCloseAdj(!openCloseAdj)}
                  variant="outlined"
                  size="medium"
                  fullWidth
                  startIcon={<AttachFileIcon />}
                  style={{ marginTop: "1.5px"}}
                >
                  ADJUNTAR
                </Button>
              </Grid>

              <Grid item xs={12} mb={2}>
                <TextField
                  label="Descripción / Acciones a Tomar"
                  fullWidth
                  required
                  multiline
                  maxRows={4}
                  size="small"
                  name="descripcion_acciones"
                  value={datosAuditoriaHallazgos.descripcion_acciones}
                  onChange={almacenar}
                />
              </Grid>
            </Grid>

            <Stack direction="row">
              <Grid container direction={{ xs: "column", sm: "row" }}>
                <Grid item xs={9}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={props.handleCloseCrearHallazgo}
                  >
                    Volver
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={disBtn}
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </Stack>
          </form>
        </Container>
      </DialogContent>

      {openCloseAdj ? ( // ADJUNTAR
        <FileUploadHallazgo
          fileId={newUuid}
          openclose={true}
          mostrar={true}
          onClose={() => handleOpenCloseAdj(false)} // Proporciona una función de cierre
        />
      ) : null}

      {/* MENSAJE AL GUARDAR */}
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}

export default Auditorias_agregar_hallazgo;
