import React from 'react'
import { useParams } from 'react-router-dom';

function PerfilCli() {
    // console.log("Hola")
    const { idcliente } = useParams();
    console.log("el id de cliente q paso como parametro es: " + idcliente)

    // const { idcliente } = match.params;

  return (
    <div>PerfilCli</div>
  )
}

export default PerfilCli