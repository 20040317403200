//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio / Nicolas pascucci
// Creation Date :13/06/2024
// Version : 1
// Description : Cambio de punto de venta
//------Changes ----------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect } from "react";
import { UrlApi } from "../../../services/apirest";
import {
  Container,
  Typography,
  Grid,
  Box,
  TextField,
  IconButton,
  Button,
  Autocomplete,
  Stack,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { grey } from "@mui/material/colors";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { logEventos } from "../../../services/services";
//notificacion
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function CambioPtoVenta(props) {
  const [disBtn, setDisBtn] = useState(false);
   //Notification
   const [openSnack, setOpenSnack] = React.useState(false);
   const [colormensaje, setColorMensaje] = useState("");
   const [mensaje, setMensaje] = useState("");
 
  /*   GET     */
  const [chofer, setChofer] = useState();
  const [choferSeleccionado, setChoferSeleccionado] = useState("");

  const obtenerChoferesXusername = () => {
    fetch(UrlApi + "/choferes/activoXusername")
      .then((data) => data.json())
      .then((data) => setChofer(data));
  };
  useEffect(() => {
    obtenerChoferesXusername();
  }, []);

 // console.log("ChoferSeleccionadoAnterior", choferSeleccionado);
 // console.log("choferes", chofer);

  const [choferCambio, setChoferCambio] = useState();
  const [choferSeleccionadoCambio, setChoferSeleccionadoCambio] = useState("");

  const obtenerChoferes = () => {
    fetch(UrlApi + "/choferes/cambioXusername")
      .then((data) => data.json())
      .then((data) => setChoferCambio(data));
  };
  useEffect(() => {
    obtenerChoferes();
  }, []);

 // console.log("ChoferSeleccionadoCambio", choferSeleccionadoCambio);
 // console.log("choferes", choferCambio);

  // console.log(
  //   "IDS",
  //   chofer ? chofer.id : null,
  //   choferCambio ? choferCambio.id : null
  // );

  const handleClose = () => {
    props.cambio(false);
    props.tabla(true);
  };

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };
  const size = "small";

  const guardarCambio = async (e) => {
    logEventos(
      "Guardar cambio de punto de venta",
      "puntos de venta",
      "Se guardo el cambio de punto de venta",
      localStorage.getItem("user")
    );
    e.preventDefault();
    const response = await fetch(
      UrlApi + "/choferes/" + choferSeleccionado.id,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          nro_puntoventa: choferSeleccionadoCambio.nro_puntoventa,
          usuario: localStorage.getItem("user"),
        }),
      }
    )
    .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          setColorMensaje("#D4EFDF");
          setMensaje("Se guardó exitosamente !");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
      const response2 = await fetch(
        UrlApi + "/choferes/" + choferSeleccionadoCambio.id,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            nro_puntoventa: choferSeleccionado.nro_puntoventa,
            usuario: localStorage.getItem("user"),
          }),
        }
      )
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          setColorMensaje("#D4EFDF");
          setMensaje("Se guardó exitosamente !");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    if (
      Object.keys(choferSeleccionado).length === 0 ||
      Object.keys(choferSeleccionadoCambio).length === 0
    ) {
      setDisBtn(true);
    } else {
      setDisBtn(false);
    }
  }, [choferSeleccionado, choferSeleccionadoCambio]);

    //--------------------------- NOTIFICACION----------------------------------------------//
  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
    handleClose();
  };

  return (
    <div>
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container backgroundColor="#1F618D" p={1.5}>
            <Grid item xs={2}>
              <IconButton onClick={handleClose}>
                <ArrowBackIcon sx={{ color: grey[50] }} />
              </IconButton>
            </Grid>
            <Grid
              item
              xs={8}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h5" color="#ffffff">
                Cambiar Punto de Venta
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <form onSubmit={guardarCambio} autoComplete="off">
          <Box
            backgroundColor="#F4F6F6"
            mt={2}
            p={3}
            border={0.5}
            borderColor={"#ABB2B9"}
            borderRadius={4}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Card sx={{ minWidth: 300, marginTop: 2 }}>
                <CardContent>
                  <Typography
                    sx={{ fontSize: 20 }}
                    variant="h5"
                    component="div"
                    gutterBottom
                    align="center"
                  >
                    Del Chofer :
                  </Typography>
                  <Grid item xs={12} marginTop={2}>
                    <Autocomplete
                      disabled={!chofer}
                      size={size}
                      focused
                      disableClearable
                      id="choferes"
                      noOptionsText={"Chofer INEXISTENTE"}
                      options={chofer}
                      autoHighlight
                      getOptionLabel={(chofer) => chofer.username}
                      onChange={(event, value) => setChoferSeleccionado(value)}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          key={option.id}
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option.username}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          style={style}
                          required
                          focused
                          size="small"
                          placeholder="Tipee para buscar..."
                          {...params}
                          label="Chofer"
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}
                    />
                  </Grid>

                  {choferSeleccionado ? (
                    <>
                      <Grid item xs={12} marginTop={2}>
                        <TextField
                          fullWidth
                          size={size}
                          style={style}
                          focused
                          color="primary"
                          label="Código"
                          variant="filled"
                          name="id"
                          value={choferSeleccionado.id}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} marginTop={2}>
                        <TextField
                          fullWidth
                          size={size}
                          style={style}
                          focused
                          color="primary"
                          label="Nombre y Apellido"
                          name="nombre_apellido"
                          variant="filled"
                          value={choferSeleccionado.nombre_apellido}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} marginTop={2}>
                        <TextField
                          fullWidth
                          size={size}
                          style={style}
                          focused
                          color="primary"
                          label="N° punto venta"
                          name="nro_puntoventa"
                          variant="filled"
                          value={choferSeleccionado.nro_puntoventa}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    </>
                  ) : null}
                </CardContent>
              </Card>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CompareArrowsIcon style={{ fontSize: 70, color: "#1F618D" }} />
              </div>

              <Card sx={{ minWidth: 300, marginTop: 2 }}>
                <CardContent>
                  <Typography
                    sx={{ fontSize: 20 }}
                    variant="h5"
                    component="div"
                    gutterBottom
                    align="center"
                  >
                    Al Chofer :
                  </Typography>

                  <Grid item xs={12} marginTop={2}>
                    <Autocomplete
                      disabled={!choferCambio}
                      size={size}
                      focused
                      disableClearable
                      id="choferes"
                      noOptionsText={"Chofer INEXISTENTE"}
                      options={choferCambio}
                      autoHighlight
                      getOptionLabel={(chofer) => chofer.username}
                      onChange={(event, value) =>
                        setChoferSeleccionadoCambio(value)
                      }
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          key={option.id}
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option.username}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          style={style}
                          required
                          focused
                          size="small"
                          placeholder="Tipee para buscar..."
                          {...params}
                          label="Chofer"
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}
                    />
                  </Grid>
                  {choferSeleccionadoCambio ? (
                    <>
                      <Grid item xs={12} marginTop={2}>
                        <TextField
                          fullWidth
                          size={size}
                          style={style}
                          focused
                          color="primary"
                          label="Código"
                          variant="filled"
                          name="id"
                          value={choferSeleccionadoCambio.id}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} marginTop={2}>
                        <TextField
                          fullWidth
                          size={size}
                          style={style}
                          focused
                          color="primary"
                          label="Nombre y Apellido"
                          name="nombre_apellido"
                          variant="filled"
                          value={choferSeleccionadoCambio.nombre_apellido}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} marginTop={2}>
                        <TextField
                          fullWidth
                          size={size}
                          style={style}
                          focused
                          color="primary"
                          label="N° punto venta"
                          name="nro_puntoventa"
                          variant="filled"
                          value={
                            choferSeleccionadoCambio.nro_puntoventa != null
                              ? choferSeleccionadoCambio.nro_puntoventa
                              : "Sin punto de venta"
                          }
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    </>
                  ) : null}
                </CardContent>
              </Card>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-end"
              mt={2}
            >
              <Button variant="contained" onClick={handleClose} color="error">
                Volver
              </Button>

              <Button
                disabled={disBtn}
                variant="contained"
                type="submit"
                color="success"
              >
                Guardar Cambio
              </Button>
            </Grid>
          </Box>
        </form>
         {/* SNACKBAR QUE SE MOSTRARA AL GUARDAR LOS CAMBIOS */}
         <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={openSnack}
            autoHideDuration={1500}
            onClose={handleCloseSnack}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseSnack}
              severity="success"
              sx={{ width: "100%" }}
              style={{ backgroundColor: colormensaje, color: "black" }}
            >
              {mensaje}
            </Alert>
          </Snackbar>
        </Stack>
      </Container>
    </div>
  );
}

export default CambioPtoVenta;
