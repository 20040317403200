import { Navigate } from 'react-router-dom';
import { isAuthenticated } from '../services/services';


const Private = ({ Component }) => {
    const isAuth = isAuthenticated();
    return isAuth ? <Component /> : <Navigate to="/" />
}

export default Private;
