import React, { useEffect, useState, useMemo } from 'react';
import { putData, getData } from '../../../services/library';
import { Container, Typography, Tooltip, IconButton, Box, Button, Dialog, DialogContent, DialogContentText, DialogActions, Grid, DialogTitle, MenuItem, Divider, ListItemIcon } from '@mui/material';
import Navbar from '../../Navbar';
import MaterialReactTable, { MRT_FullScreenToggleButton } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import BlockIcon from '@mui/icons-material/Block';
import EditIcon from '@mui/icons-material/Edit';
import Addchofer from './AddChofer';
import Notify from '../../Notify';
import Editarchofer from './Editarchofer';
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonIcon from '@mui/icons-material/Person';
import CheckIcon from '@mui/icons-material/Check';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import PeopleIcon from '@mui/icons-material/People';
import moment from 'moment';
import { logEventos } from '../../../services/services';
import ConfirmDialog from '../../Dialogs/ConfirmDialog';

export default function Choferes() {
    const globalTheme = useTheme();
    const [choferes, setChoferes] = useState(); //aca se almacenan los datos que me trae la api
    const [alta, setAlta] = useState(false); //Estado q mostrará o no la pantalla de alta
    const [tabla, setTabla] = useState(true); //Estado q mostrará o no la tabla (pantalla inicial) dnd estan los choferes
    const [editar, setEditar] = useState(false);  //Estado q mostrará o no el form de edicion de choferes
    // const [confirmar, setConfirmar] = useState(false);
    const [confirmarActivo, setConfirmarActivo] = useState(false);
    const [confirmarInactivo, setConfirmarInactivo] = useState(false);
    const [datosformulario, setDatosformulario] = useState({});
    const iconRemove = <RemoveCircleOutlineIcon sx={{ fontSize: 40 }} />;
    const [hiddenmuestrainactivo, setHiddenmuestrainactivo] = useState(false);
    const [hiddenmuestraactivos, setHiddenmuestraactivos] = useState(true);
    const iconCheck = <CheckIcon sx={{ fontSize: 40 }} />;
    const [notify, setNotify] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
    });

    const obtenerchoferes = () => {
        setHiddenmuestraactivos(true);
        setHiddenmuestrainactivo(false);
        getData('/choferes', setChoferes)
    };

    const obtenerinactivos = () => {
        setHiddenmuestrainactivo(true);
        setHiddenmuestraactivos(false);
        getData('/choferes/inactivos', setChoferes)
    };

    useEffect(() => {
        obtenerchoferes();
    }, [tabla]);

    const handleClickNuevo = () => {
        setAlta(true)
        setTabla(false)
    };

    const mostrareditar = (datosDeLaLinea) => {
        setEditar(true);
        setTabla(false);
        setDatosformulario(datosDeLaLinea);
    };

    const handleClose = () => {
        // setConfirmar(false)
        setTabla(true)
        setConfirmarActivo(false);
        setConfirmarInactivo(false);
        confirmarActivo && obtenerinactivos()
        confirmarInactivo && obtenerchoferes()
    };

    // const abrirModalConfirmar = (datosDeLaLinea) => {
    //     setConfirmar(true)
    //     setDatosformulario(datosDeLaLinea)
    // };

    const abrirModalConfirmarActivo = (datosDeLaLinea) => {
        setConfirmarActivo(true);
        setDatosformulario(datosDeLaLinea);
    };

    const abrirModalConfirmarInactivo = (datosDeLaLinea) => {
        setConfirmarInactivo(true);
        setDatosformulario(datosDeLaLinea);
    };

    const columns = useMemo(() =>
        [{ header: 'ID sisven', accessorKey: 'id_sisven', size: 85 },
        { header: 'Nombre y Apellido', accessorKey: 'nombre_apellido' },
        { header: 'DNI', accessorKey: 'dni' },
        { header: 'Legajo', accessorKey: 'legajo', size: 100 },
        { header: 'Telefono', accessorKey: 'telefono' },
        { header: 'Vencimiento Licencia', accessorFn: (choferes) => !!choferes.vencimiento_licencia ? moment(choferes.vencimiento_licencia).format("DD-MM-YYYY") : "No hay registro" },
        { header: 'Sucursal', accessorKey: 'sucursal' },
        ], []);

    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: globalTheme.palette.mode,
                    primary: globalTheme.palette.primary, //primary color for the table
                    info: {
                        main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            globalTheme.palette.mode === "light"
                                ? "rgb(234, 239, 241)"
                                : "#000", //pure black table in dark mode for fun
                    },
                },
                typography: {
                    button: {
                        textTransform: "none", //customize typography styles for all buttons in table by default
                        fontSize: "1rem",
                    },
                },
                components: {
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                fontSize: "0.8rem", //larger tooltip font size                                
                            },
                        },
                    },
                },

            }), [globalTheme])

    const muiSearchTextFieldProps = {
        placeholder: "Buscar..",
        sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
        variant: "outlined",
        color: "success",
        size: "small",
    };

    const initialState = {
        density: "compact",
        showGlobalFilter: true,
        pagination: { pageIndex: 0, pageSize: 10 }
    };

    async function marcarActivo(e) {
        putData(e,
            "/choferes/" + datosformulario.id,
            {
                activo: 1
            },
            setNotify,
            obtenerinactivos(),
            logEventos("Activarchofer", "choferes", "choferes", localStorage.getItem("user"))
        )
    };

    async function marcarInactivo(e) {
        putData(e,
            "/choferes/" + datosformulario.id,
            {
                activo: 0
            },
            setNotify,
            obtenerchoferes(),
            logEventos("Desactivar chofer", "choferes", "choferes", localStorage.getItem("user"))
        )
    };

    const tableHeadCellProps = {
        align: "left",
        sx: {
            fontSize: 16,
            color: "white",
            fontWeight: "normal",
            backgroundColor: "#2471A3",
            borderColor: "black",
        },
    };

    return (
        <div>
            <Navbar />
            <Container maxWidth="xl" mt={1} mb={2}>
                {choferes && tabla ? (
                    <>

                        <ThemeProvider theme={tableTheme}>
                            <Box mt={2} boxShadow={3}>
                                <MaterialReactTable
                                    enableColumnResizing
                                    columns={columns}
                                    data={choferes}
                                    initialState={initialState}
                                    positionActionsColumn="last"
                                    enableExpandAll={false}
                                    enableToolbarInternalActions={true}
                                    enableEditing={false}
                                    enableRowActions
                                    // enableColumnActions={false}
                                    muiTableHeadCellProps={tableHeadCellProps}
                                    muiTableBodyCellProps={({ row }) => ({
                                        align: "left",
                                        sx: {
                                            backgroundColor:
                                                row.original.activo === 1 ? "#b3e6b3" : "#efc3c3",
                                            color: "black",
                                            borderColor: "black",
                                        },
                                    })}
                                    enableColumnActions={false}
                                    muiSearchTextFieldProps={muiSearchTextFieldProps}
                                    enableDensityToggle={false}
                                    renderTopToolbarCustomActions={() => (
                                        <>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                startIcon={<AddBoxTwoToneIcon />}
                                                onClick={handleClickNuevo}
                                            >
                                                Nuevo Registro
                                            </Button>
                                            <Typography variant="poster" fontSize={26}>
                                                <PeopleIcon sx={{ size: 20 }} />
                                                Choferes
                                            </Typography>
                                        </>
                                    )}
                                    layoutMode="grid"
                                    localization={MRT_Localization_ES}
                                    enableColumnDragging={false} //No permite que se arrastren las columnas.
                                    renderToolbarInternalActions={({ table }) => (
                                        <>
                                            {!hiddenmuestraactivos ?
                                                <Tooltip title="Ver choferes activos" >
                                                    <IconButton
                                                        color="success"
                                                        onClick={() => {
                                                            obtenerchoferes();
                                                        }}
                                                    >
                                                        <PersonIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                : null}
                                            {!hiddenmuestrainactivo ?
                                                <Tooltip title="Ver choferes inactivos" >
                                                    <IconButton
                                                        color="error"
                                                        onClick={() => {
                                                            obtenerinactivos();
                                                        }}
                                                    >
                                                        <PersonOffIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                : null}

                                            <MRT_FullScreenToggleButton table={table} />
                                        </>
                                    )}
                                    renderRowActionMenuItems={({ closeMenu, row, table }) => (
                                        <>
                                            <MenuItem
                                                key={0}
                                                onClick={() => {
                                                    mostrareditar(row.original)
                                                    closeMenu()
                                                }}>
                                                <ListItemIcon>
                                                    <EditIcon color="primary" />
                                                </ListItemIcon>
                                                Editar Chofer
                                            </MenuItem>
                                            <Divider />
                                            {(row.original.activo === 0) ?
                                                <MenuItem
                                                    key={1}
                                                    onClick={() => {
                                                        abrirModalConfirmarActivo(row.original)
                                                        closeMenu()
                                                    }}>
                                                    <ListItemIcon>
                                                        <CheckCircleOutlineIcon color="success" />
                                                    </ListItemIcon>
                                                    Marcar como activo
                                                </MenuItem>
                                                :
                                                <MenuItem
                                                    key={2}
                                                    onClick={() => {
                                                        abrirModalConfirmarInactivo(row.original)
                                                        closeMenu()
                                                    }}>
                                                    <ListItemIcon>
                                                        <BlockIcon color="error" />
                                                    </ListItemIcon>
                                                    Marcar como inactivo
                                                </MenuItem>
                                            }
                                        </>
                                    )}
                                />
                            </Box>
                        </ThemeProvider>
                        <br />
                        <br />

                    </>
                ) : null}

                {/* {confirmar ?
                    <>
                        {datosformulario.activo === 0 ? (
                            <ConfirmDialog
                                title={"Activar chofer"}
                                subtitle={"¿Desea cambiar el estado del chofer como activo?"}
                                open={confirmar}
                                setOpen={setConfirmar}
                                notify={notify}
                                setNotify={setNotify}
                                btnText={"Aprobar"}
                                guardar={marcarActivo}
                                color={"#2ECC71"}
                                icon={iconCheck} />
                        ) : (
                            <ConfirmDialog
                                title={"Desactivar chofer"}
                                subtitle={"¿Desea cambiar el estado del chofer como inactivo?"}
                                open={confirmar}
                                setOpen={setConfirmar}
                                notify={notify}
                                setNotify={setNotify}
                                btnText={"Confirmar"}
                                guardar={marcarInactivo}
                                color={"#f53649"}
                                icon={iconRemove} />
                        )}

                    </> : null} */}

                {confirmarActivo && (
                    <ConfirmDialog
                        title={"Activar chofer"}
                        subtitle={"¿Desea cambiar el estado del chofer como activo?"}
                        open={confirmarActivo}
                        setOpen={setConfirmarActivo}
                        notify={notify}
                        setNotify={setNotify}
                        btnText={"Aprobar"}
                        guardar={marcarActivo}
                        color={"#2ECC71"}
                        icon={iconCheck}
                    // actualizar={obtenerchoferes}
                    />
                )}

                {confirmarInactivo && (
                    <ConfirmDialog
                        title={"Desactivar chofer"}
                        subtitle={"¿Desea cambiar el estado del chofer como inactivo?"}
                        open={confirmarInactivo}
                        setOpen={setConfirmarInactivo}
                        notify={notify}
                        setNotify={setNotify}
                        btnText={"Confirmar"}
                        guardar={marcarInactivo}
                        color={"#f53649"}
                        icon={iconRemove}
                    // actualizar={obtenerinactivos}
                    />
                )}

                {notify && notify.open ? (
                    <Notify
                        mensaje={notify.mensaje}
                        open={notify.open}
                        color={notify.color}
                        handleClose={handleClose}
                        severity={notify.severity}
                    />
                ) : null}

                {alta ? <Addchofer alta={setAlta} tabla={setTabla} ></Addchofer> : null}

                {editar ? <Editarchofer editar={setEditar} tabla={setTabla} datos={datosformulario} actualizar={obtenerchoferes}></Editarchofer> : null}
            </Container >
        </div >

    )

}