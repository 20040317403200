import React, { useEffect, useState } from 'react'
import Navbar from '../../Navbar'
import {
    Button, Container, Divider, TextField, Typography,
    Grid, TableBody, TableRow, TableCell, Table, TableHead
} from '@mui/material'
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import { UrlApi } from '../../../services/apirest';
import moment from 'moment'
//var _ = require('lodash');
import _ from 'lodash';
import { logEventos } from '../../../services/services';



function Soloenvases() {
    let sucursalesAutorizadas = localStorage.getItem("suc_auth").split(',')
    let segmento = "envasado"
    const [datos, setDatos] = useState([])
    const [datostotal, setDatosTotal] = useState([])
    const [datosubicacion, setDatosUbicacion] = useState([])

    const [fecha, setFecha] = useState(moment(new Date()).format("YYYY-MM-DD"))

    useEffect(() => {
        buscadatos()
        buscatotales()
        buscadatosubicacion()
    }, [fecha])

    useEffect(() => {
        logEventos("Consulta stock envases", "Stock Envases", "Soloenvases", localStorage.getItem("user"))
    }, [])

    const buscadatos = async () => {
        const data = await fetch(UrlApi + "/stkfisicototal/" + segmento + "/" + fecha)
        const datas = await data.json()
        setDatos(datas)
    }

    const buscatotales = async () => {
        const data1 = await fetch(UrlApi + "/envasestotal/" + localStorage.getItem("suc_auth") + "/" + fecha, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token"),
            },
        })
        const datastotal = await data1.json()
        setDatosTotal(datastotal)
    }

    const buscadatosubicacion = async () => {
        const data2 = await fetch(UrlApi + "/stkfisicototal/" + segmento + "/" + fecha + "/por/ubicacion")
        const datas2 = await data2.json()
        setDatosUbicacion(datas2)
    }

    const handleChange = (newValue) => {
        setFecha(moment(newValue).format("YYYY-MM-DD"));
    };

    const handleChangeMas = () => {
        setFecha(moment(fecha).add(1, 'days').format("YYYY-MM-DD"));
    };
    const handleChangeMenos = () => {
        setFecha(moment(fecha).subtract(1, 'days').format("YYYY-MM-DD"));
    };
    const handleChangeHoy = () => {
        setFecha(moment(Date()).format("YYYY-MM-DD"));
    };

    const sucs = _.groupBy(datos, 'sucursal',);   //agrupa por sucursal
    const tots = _.groupBy(datosubicacion, 'sucursal',);   //agrupa por sucursal

    return (
        <div>
            <Navbar />
            <Container>
                <br />
                <Typography variant="h5" component="div"
                    style={{ paddingLeft: '15px' }} bgcolor='black' color='white'>Recuento Envases Sucursales</Typography>

                <Grid container spacing={1} paddingTop='15px' direction="row">

                    <Grid item xs={6} sm={6} md={6}>
                        <TextField
                            color="primary"
                            label="Fecha Recuento"
                            name="fecha"
                            type="date"
                            variant="outlined"
                            rows={2}
                            value={fecha}
                            onChange={(e) => handleChange(e.target.value)}
                        />
                        <p>
                            <Button variant="outlined" size="small" onClick={handleChangeMenos} startIcon={<FastRewindIcon />}></Button>
                            <Button variant="outlined" size="small" onClick={handleChangeMas} endIcon={<FastForwardIcon />}></Button>
                            <Button variant="outlined" size="small" color="error" onClick={handleChangeHoy} >Hoy</Button>
                        </p>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} paddingTop={'10px'}>
                        <Typography variant="h6" component="div"
                            style={{ paddingLeft: '15px' }} bgcolor="black" color="white">Total Sucursales al  {moment(fecha).format("DD/MM/yyyy")}</Typography>
                        {Object.keys(datostotal).length > 1 ?
                            <Table border="1" size="small">
                                <TableHead>
                                    <TableRow bgcolor="#ed7f42">
                                        <TableCell>Estado</TableCell>
                                        <TableCell align="right">10Kgs</TableCell>
                                        <TableCell align="right">15Kgs</TableCell>
                                        <TableCell align="right">AE15Kgs</TableCell>
                                        <TableCell align="right">30Kgs</TableCell>
                                        <TableCell align="right">45Kgs</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {datostotal.map((dats, index) =>
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {dats.estado}
                                            </TableCell>
                                            <TableCell align="right">{dats.x10}</TableCell>
                                            <TableCell align="right">{dats.x15}</TableCell>
                                            <TableCell align="right">{dats.xAE15}</TableCell>
                                            <TableCell align="right">{dats.x30}</TableCell>
                                            <TableCell align="right">{dats.x45}</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                            : <Typography variant="h6" align='center' bgcolor="lightyellow">No hay datos para la fecha</Typography>}
                    </Grid>
                    <br /><Divider> </Divider>
                    <Grid container spacing={1} direction="row" >
                        {Object.keys(sucs).length > 0 && Object.keys(tots).length > 0 ? Object.keys(sucs).map((cat) => (
                            localStorage.getItem("suc_auth").split(",").includes(cat) ?     ////si la sucursal esta en las autorizadas a ver por el usuario
                                <Grid item xs={12} sm={6} md={6}>
                                    <Typography variant="h6" component="div"
                                        style={{ paddingLeft: '15px' }} bgcolor={sucs[cat][0].color_id} color='white'>{sucs[cat][0].sucursal} {sucs[cat][0].nombre} @{moment(sucs[cat][0].fecharecuento).format("hh:mm:ss a")}</Typography>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Estado</TableCell>
                                                <TableCell align="right">10Kgs</TableCell>
                                                <TableCell align="right">15Kgs</TableCell>
                                                <TableCell align="right">AE15Kgs</TableCell>
                                                <TableCell align="right">30Kgs</TableCell>
                                                <TableCell align="right">45Kgs</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {sucs[cat].map(cats =>
                                                <TableRow
                                                    key={cats.idrecuentofisico}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {cats.estado}
                                                    </TableCell>
                                                    <TableCell align="right">{cats.x10}</TableCell>
                                                    <TableCell align="right">{cats.x15}</TableCell>
                                                    <TableCell align="right">{cats.xAE15}</TableCell>
                                                    <TableCell align="right">{cats.x30}</TableCell>
                                                    <TableCell align="right">{cats.x45}</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                        {tots ? console.log("sonlostotales", tots[cat]) : console.log("nada")}
                                        {tots[cat] ?
                                            <TableBody>
                                                {tots[cat].map(ubics =>
                                                    <TableRow sx={{ backgroundColor: ubics.totales[0] % 2 ? "lightblue" : "lightyellow" }}>
                                                        <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
                                                            {ubics.totales}
                                                        </TableCell>
                                                        <TableCell align="right" sx={{ borderBottom: "none" }}>{ubics.x10}</TableCell>
                                                        <TableCell align="right" sx={{ borderBottom: "none" }}>{ubics.x15}</TableCell>
                                                        <TableCell align="right" sx={{ borderBottom: "none" }}>{ubics.xAE15}</TableCell>
                                                        <TableCell align="right" sx={{ borderBottom: "none" }}>{ubics.x30}</TableCell>
                                                        <TableCell align="right" sx={{ borderBottom: "none" }}>{ubics.x45}</TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody> : null}
                                    </Table>
                                </Grid> : null
                        ))
                            : null}
                    </Grid>

                </Grid>
                <br /> <br />
                <br /> <br />
                <br /> <br />
            </Container>
        </div >
    )
}

export default Soloenvases