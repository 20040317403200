import Navbar from "../Navbar";
import React, { useEffect, useState, useMemo } from "react";
import { UrlApi } from "../../services/apirest";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  Typography,
  Stack,
  Grid,
  Tooltip,
  TextField,
} from "@mui/material";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddBoxIcon from "@mui/icons-material/AddBox";

//Notification
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Romaneo_reparaciones() {
  const [romaneo_reparaciones, setRomaneo_reparaciones] = useState();
  const [datosRomaneoReparaciones, setDatosRomaneoReparaciones] = useState({});
  const [tabla, setTabla] = useState(true);
  const [update, setUpdate] = useState(false);

  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");

  //funcion para obtener a los romaneo leyendas, pasarlos a json y guardarlos en data
  const obtenerRomaneoReparaciones = () => {
    fetch(UrlApi + "/romaneo_reparaciones")
      .then((data) => data.json())
      .then((data) => setRomaneo_reparaciones(data));
  };
  // console.log("romaneo_reparaciones", setRomaneo_reparaciones)

  //funcion que espera los cambios de la funcion de obtener a los romaneo leyendas
  useEffect(() => {
    obtenerRomaneoReparaciones();
  }, [tabla]);

  // FUNCION QUE SE EJECUTA CADA VEZ QUE HAY UN CAMBIO EN LA BASE DE DATOS
  // SIRVE PARA QUE LA TABLA MOSTRADA SE ACTUALICE EN TIEMPO REAL
  useEffect(() => {
    if (update) obtenerRomaneoReparaciones();
  }, [update]);
  //*************************************************************************************************************************************/

  // DIALOG PARA CREAR LOS DATOS
  const [openCrear, setOpenCrear] = React.useState(false);

  // USANDO ROW TOMO LOS DATOS DE ESA FILA
  const handleClickOpenCrear = (row) => {
    setOpenCrear(true);
    // AL ABRIR EL DIALOG El TEXTFIELDS SE VACIAN
    datosRomaneoReparaciones.tipo_reparacion = "";
    datosRomaneoReparaciones.descripcion_reparacion = "";
  };

  const handleCloseCrear = () => {
    setOpenCrear(false);
  };

  //*************************************************************************************************************************************/
  // DIALOG PARA EDITAR LOS DATOS
  const [openEditar, setOpenEditar] = React.useState(false);

  // USANDO ROW TOMO LOS DATOS DE ESA FILA
  const handleClickOpenEditar = (row) => {
    setOpenEditar(true);
    setDatosRomaneoReparaciones(row);
  };

  const handleCloseEditar = (row) => {
    setOpenEditar(false);
    setDatosRomaneoReparaciones(row);
  };

  //*************************************************************************************************************************************/

  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
    setUpdate(false);
    setOpenCrear(false);
    setOpenEditar(false);
  };

  //*************************************************************************************************************************************/
  const columns = useMemo(
    () => [
      {
        header: "Descripción de la Reparación",
        accessorKey: "descripcion_reparacion",
        size: 200,
      },
    ],
    []
  );

  const muiSearchTextFieldProps = {
    // para el buscar
    placeholder: "Buscar...",
    sx: { minWidth: "18rem" },
    variant: "outlined",
    color: "success",
    size: "small",
  };

  //*************************************************************************************************************************************/
  //Funcion para guardar al crear
  const guardarCrear = async (e) => {
    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(UrlApi + "/romaneo_reparaciones", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        descripcion_reparacion: datosRomaneoReparaciones.descripcion_reparacion,
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          setUpdate(true);
          setColorMensaje("#D4EFDF");
          setMensaje("La reparación se guardó exitosamente !");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar la reparación");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  console.log(datosRomaneoReparaciones);

  //*************************************************************************************************************************************/
  //Funcion para guardar AL EDITAR
  const guardarEditar = async (e) => {
    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(
      UrlApi + "/romaneo_reparaciones/" + datosRomaneoReparaciones.id_reparacion,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            descripcion_reparacion: datosRomaneoReparaciones.descripcion_reparacion,
        }),
      }
    )
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          setUpdate(true);
          setColorMensaje("#D4EFDF");
          setMensaje("La reparación se guardó exitosamente !");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar la reparación");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    console.log(datosRomaneoReparaciones);
  };

  //*************************************************************************************************************************************/
  const almacenar = (e) => {
    setDatosRomaneoReparaciones({
      ...datosRomaneoReparaciones,
      [e.target.name]: e.target.value,
    });
  };

  // ESTILO PARA LA TABLA

  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)" //background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //override to make tooltip font size larger
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const displayColumnDefOptions = {
    "mrt-row-actions": {
      muiTableHeadCellProps: {
        align: "center",
        sx: {
          fontSize: 14,
          fontStyle: "italic",
        },
      },
      size: 100,
    },
  };

  const initialState = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
  };

  return (
    <div>
        <Navbar />
      <Container maxWidth="xl" mt={1}>
        {romaneo_reparaciones && tabla ? (
          <Box mt={2}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableExpandAll={false}
                enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
                enableGrouping // para permitir agrupar x estado x ej
                displayColumnDefOptions={displayColumnDefOptions}
                layoutMode="grid"
                muiTableHeadCellProps={{
                  align: "left", // los titulos de la tabla
                }}
                muiTableBodyCellProps={{
                  align: "left", //el contenido de la tabla
                }}
                enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
                enableColumnFilters={false} //desactiva filtros x  columna
                enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
                enableDensityToggle={false} //
                localization={MRT_Localization_ES} //idioma
                enableColumnDragging={false} //para arrastrar columnas
                initialState={initialState}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                columns={columns}
                data={romaneo_reparaciones} //con lo q la completas (esto cambia siempre)
                enableStickyFooter
                enableEditing
                positionActionsColumn="last" // posicion de la columna de acciones al final
                // ACCIONES
                renderToolbarInternalActions={(
                  { table } // boton para nuevo , al lado del buscar
                ) => (
                  <>
                    <Tooltip arrow placement="right" title="Nueva Reparación">
                      <IconButton color="primary">
                        <AddBoxIcon
                          onClick={() => {
                            handleClickOpenCrear();
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                    <MRT_FullScreenToggleButton table={table} />
                  </>
                )}
                renderTopToolbarCustomActions={() => (
                  <>
                    <Typography variant="poster" fontSize={26}>
                      Reparaciones
                    </Typography>
                  </>
                )}
                renderRowActions={(
                  { row } //las acciones del costado editar y ver
                ) => (
                  <div>
                    <Tooltip arrow placement="left"title="Editar Reparación">
                      <IconButton
                        onClick={() => handleClickOpenEditar(row.original)}
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              />
            </ThemeProvider>
          </Box>
        ) : null}
      </Container>

      {/* AGREGAR ITEM */}
      <Dialog
        open={openCrear}
        onClose={handleCloseCrear}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle marginLeft={3}>
          <b>{"Agregar Reparación"}</b>
        </DialogTitle>
        <DialogContent>
          <Container maxWidth="xl">
            <form onSubmit={guardarCrear}>
              <Grid container mt={2} mb={1} spacing={1}>
                <Grid item xs={12} mb={2}>
                  <TextField
                    label="Descripción"
                    name="descripcion_reparacion"
                    size="small"
                    placeholder="..."
                    focused
                    required
                    fullWidth
                    value={datosRomaneoReparaciones.descripcion_reparacion}
                    onChange={almacenar}
                  />
                </Grid>
              </Grid>

              <Stack direction="row">
                <Grid container direction={{ xs: "column", sm: "row" }}>
                  <Grid item xs={9}>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleCloseCrear}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Stack>
            </form>
          </Container>
        </DialogContent>
      </Dialog>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>

      {/* EDITAR ITEM */}
      <Dialog
        open={openEditar}
        onClose={handleCloseEditar}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle marginLeft={3}>
          <b>{"Editar Reparación"}</b>
        </DialogTitle>
        <DialogContent>
          <Container maxWidth="xl">
            <form onSubmit={guardarEditar}>
              <Grid container mt={2} mb={1} spacing={1}>
                <Grid item xs={12} mb={2}>
                  <TextField
                    label="Descripción reparación"
                    name="descripcion_reparacion"
                    size="small"
                    placeholder="..."
                    focused
                    required
                    fullWidth
                    value={datosRomaneoReparaciones.descripcion_reparacion}
                    onChange={almacenar}
                  />
                </Grid>
              </Grid>

              <Stack direction="row">
                <Grid container direction={{ xs: "column", sm: "row" }}>
                  <Grid item xs={9}>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleCloseEditar}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Stack>
            </form>
          </Container>
        </DialogContent>
      </Dialog>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  )
}

export default Romaneo_reparaciones