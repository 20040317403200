/*

Asi me traigo los clientes que tienen alguna factura pendiente
SELECT * from clientes where id IN (
select id_cliente 
from pedidos
where id_estado=248
group by id_cliente)



*/

import React, { useEffect, useState, useMemo } from "react";
import {
  Container,
  FormControl,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Box,
  TextField,
  Stack,
  DialogTitle,
  Autocomplete,
  IconButton,
  Button,
  InputAdornment,
  Tooltip,
  createTheme,
  ThemeProvider,
  useTheme, Fab,
} from "@mui/material";
import { getData } from "../../services/library.js";
import ResumenFacturacion from "./ResumenFacturacion";

import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function NuevaFacturacion() {
  const [clientes, setClientes] = useState();
  const [dataCliente, setDataCliente] = useState();
  const [pedidosCliente, setPedidosCliente] = useState();
  const [rowSelection, setRowSelection] = useState({});
  const [pedidosAFacturar, setPedidosAFacturar] = useState();
  const [totales, setTotales] = React.useState({});
  const [precioUnitario, setPrecioUnitario]=useState(0);

  useEffect(() => {
    getData("/clientes/facturacion_especial/pedidos ", setClientes);
  }, []);

  useEffect(() => {
    const selectedRows = Object.keys(rowSelection)
      .filter((key) => rowSelection[key])
      .map((key) => pedidosCliente[parseInt(key)]);
    setPedidosAFacturar(selectedRows);

  }, [rowSelection]);

  /*
   ---------------------------------------------------------------------------------- 
  //Facturacion:
  1- lo primero que voy a hacer es calcular la cantidad entregada:
    Necesito: 
      - unidad de facturacion, 
      - empresa a la que pertenece el cliente
      - producto: ¿ que pasa si en un domicilio se entrego un producto y en otro, otro producto. sucede eso?? porqe de ser asi las densidades no serian las mismas. tendri q agrupar los pedidos por producto para poder facturarlos juntos ? 
      - densidad del producto: la obtengo con el producto de la direccion de entrega. tomo el producto del primer pedido y la densidades tb.
  // no estaaria bueno que este el producto en el pedido ?? porqe si lo obtengo uniendome con la direccion de entrega, pero cambia en el medio?? 
  */
  //quilmes factura en lts
  //tengo 2 pedidos entregaods en kgs
  // tengo 3 pedidos entregados en lts
  // la empresa es autogas por lo oque la adensidad es 0.52

  const { unidad_fact, id_empresa, unidad_facturacion } = !!dataCliente && dataCliente;

  //AGRUPA los pedidos por id_producto:
  const pedidosPorProducto =
    pedidosAFacturar &&
    pedidosAFacturar.reduce((grupo, item) => {
      // Si el id_producto no existe en el grupo, inicialízalo como un array vacío
      if (!grupo[item.id_producto]) {
        grupo[item.id_producto] = [];
      }
      // Agrega el objeto actual al grupo correspondiente
      grupo[item.id_producto].push(item);
      return grupo;
    }, {});

  // Aca abajo, segun el producto, se calculan los totales entregados: lts o kgs
  const calcularTotalesPorProducto = (pedidos) => {
    const pedidosPorProducto = pedidos.reduce((grupo, item) => {
      if (!grupo[item.id_producto]) grupo[item.id_producto] = [];
      grupo[item.id_producto].push(item);
      return grupo;
    }, {});

    return Object.entries(pedidosPorProducto).reduce(
      (resultado, [idProducto, registros]) => {
        let totalKg = 0,
          totalLts = 0;
        let producto = "";
        let detalle_factura = "";
        let densidad_autogas = "";
        let densidad_hipergas = "";

        registros.forEach((registro) => {
          totalKg += registro.kgentregados || 0;
          totalLts += registro.ltsentregados || 0;

          // Capturamos el nombre del producto del primer registro
          if (!producto) {
            producto = registro.producto;
          }

          if (!detalle_factura) {
            detalle_factura = registro.detalle_factura;
          }

          if (!densidad_autogas) {
            densidad_autogas = registro.densidad_autogas;
          }

          if (!densidad_hipergas) {
            densidad_hipergas = registro.densidad_hipergas;
          }
        });
        resultado[idProducto] = {
          totalKg,
          totalLts,
          producto,
          detalle_factura,
          densidad_autogas,
          densidad_hipergas,
        };
        return resultado;
      },
      {}
    );
  };

  //Cada vez q hay cambios en pedidos a facturar, calculo los totales.
  useEffect(() => {
    if (pedidosAFacturar) {
      const nuevosTotales = calcularTotalesPorProducto(pedidosAFacturar);
      setTotales(nuevosTotales);

      const precioMasAlto = pedidosAFacturar.reduce((max, item) => {
        return item.precio_siniva > max ? item.precio_siniva : max;
      }, 0);

      // const precios = pedidosAFacturar.map((item) => item.precio_siniva);
      // console.log(precios)
      // const precioMasAlto = precios&&Math.max(precios);
      setPrecioUnitario(precioMasAlto)
    }
  }, [pedidosAFacturar]);
  // console.log(pedidosAFacturar)




  const items = useMemo(() => {
    // Determina la densidad según la empresa
    const densidad_empresa =
      id_empresa === 4 ? "densidad_autogas" : "densidad_hipergas";

    // Recorre el objeto de totales
    return Object.entries(totales).map(
      ([
        idProducto,
        {
          totalKg,
          totalLts,
          producto,
          detalle_factura,
          densidad_autogas,
          densidad_hipergas,
        },
      ]) => {
        // Inicializa la facturación en KGS y LTS
        let facturacionKg = totalKg;
        let facturacionLts = totalLts;

        if (unidad_fact === 215) {
          // Si la unidad es 215 (KGS), convierte los LTS a KGS usando la densidad de la empresa
          const densidad =
            id_empresa === 4 ? densidad_autogas : densidad_hipergas;
          facturacionKg = parseFloat((totalLts * densidad + totalKg).toFixed(1)); // Convierte LTS a KGS y suma los KGS entregados
          facturacionLts = null; // No facturar en LTS si la unidad es KGS
        } else if (unidad_fact === 216) {
          // Si la unidad es 216 (LTS), convierte los KGS a LTS usando la densidad
          const densidad =
            id_empresa === 4 ? densidad_autogas : densidad_hipergas;
          facturacionLts = parseFloat((totalKg / densidad + totalLts).toFixed(2)); // Convierte KGS a LTS y suma los LTS entregados
          facturacionKg = null; // No facturar en KGS si la unidad es LTS
        }

        return {
          idProducto,
          unidad_fact,
          producto,
          detalle_factura,
          facturacionKg,
          facturacionLts,
          unidad_facturacion,
        };
      }
    );
  }, [totales, unidad_fact, id_empresa]);

  const columns = useMemo(
    () => [
      {
        header: "Datos Pedido ",
        columns: [
          {
            accessorKey: "id",
            header: "#",
            enableColumnActions: false,
            enableColumnFilter: false,
            size: 5,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                color: "black",
                maxWidth: 5,
              },
            }),
          },
          {
            accessorKey: "id_reparto",
            header: "Reparto",
            enableColumnActions: false,
            enableColumnFilter: false,
            size: 5,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                color: "black",
                maxWidth: 5,
              },
            }),
          },
          {
            accessorKey: "sucursal_cliente",
            header: "Sucursal Cliente ",
            enableColumnActions: false,
            maxSize: 5,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                color: "black",
              },
            }),
          },
          {
            accessorKey: "sucursal_atiende",
            header: "Atendido Por ",
            enableColumnActions: false,
            maxSize: 5,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                color: "black",
              },
            }),
          },
          {
            accessorKey: "empresa_cliente",
            header: "Empresa",
            enableColumnActions: false,
            enableColumnFilter: false,
            size: 10,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                color: "black",
              },
            }),
          },
        ],
      },
      {
        header: "Datos Entrega",
        muiTableHeadCellProps: {
          sx: {
            borderLeft: 1,
            borderRight: 1,
            borderColor: "#fff",
            backgroundColor: "#2471A3",
            color: "white", // Color del texto
            fontSize: 16,
            fontWeight: "normal",
            pt: 1,
          },
        },
        columns: [
          {
            accessorKey: "producto",
            header: "Producto",
            enableColumnActions: false,
            enableColumnFilter: false,
            size: 5,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                color: "black",
                width: "20px",
                maxWidth: "20px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
            }),
            muiTableHeadCellProps: {
              sx: {
                fontSize: 16,
                fontWeight: "normal",
                pt: 1,
                backgroundColor: "#2471A3",
                borderLeft: 1,
                borderRight: 1,
                borderColor: "#fff",
                color: "white",
              },
            },
          },

          {
            accessorKey: "kgentregados",
            header: "Kgs",
            enableColumnActions: false,
            enableColumnFilter: false,
            size: 5,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                color: "black",
                width: "20px",
                maxWidth: "20px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
            }),
            muiTableHeadCellProps: {
              sx: {
                fontSize: 16,
                fontWeight: "normal",
                pt: 1,
                backgroundColor: "#2471A3",
                borderLeft: 1,
                borderRight: 1,
                borderColor: "#fff",
                color: "white",
              },
            },
          },
          {
            accessorKey: "ltsentregados",
            header: "Lts",
            enableColumnActions: false,
            enableColumnFilter: false,
            size: 5,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                color: "black",
                width: "20px",
                maxWidth: "20px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
            }),
            muiTableHeadCellProps: {
              sx: {
                fontSize: 16,
                fontWeight: "normal",
                pt: 1,
                borderRight: 1,
                backgroundColor: "#2471A3",
                color: "white",
              },
            },
          },
          {
            accessorKey: "fecha_real_entrega_formato",
            header: "Fecha Entrega",
            enableColumnActions: false,
            enableColumnFilter: false,
            size: 125,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                color: "black",
                width: "80px",
                maxWidth: "80px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
            }),
            muiTableHeadCellProps: {
              sx: {
                fontSize: 16,
                fontWeight: "normal",
                pt: 1,
                borderRight: 1,
                backgroundColor: "#2471A3",
                color: "white",
                width: "80px",
                maxWidth: "80px",
              },
            },
          },
          {
            accessorKey: "nro_remito",
            header: "Remito",
            enableColumnActions: false,
            enableColumnFilter: false,
            size: 100,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                color: "black",
                width: "130px",
                maxWidth: "130px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
            }),
            muiTableHeadCellProps: {
              sx: {
                fontSize: 16,
                fontWeight: "normal",
                pt: 1,
                borderRight: 1,
                backgroundColor: "#2471A3",
                color: "white",
                width: "150px",
                maxWidth: "150px",
              },
            },
          },
        ],
      },
    ],
    []
  );

  const [initialState, setInitialState] = useState({
    density: "compact",
    showGlobalFilter: true,
    showColumnFilters: false,
    pagination: {
      pageSize: 5,
      pageIndex: 0,
    },
  });

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "primary",
    size: "small",
  };

  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      pt: 1,
    },
  };

  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary,
          info: {
            main: "rgb(255,122,0)",
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000",
          },
        },
        typography: {
          button: {
            textTransform: "none",
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem",
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const customLocalization = {
    ...MRT_Localization_ES,
    expandAll: false,
    expand: <ExpandMoreIcon />,
  };

  // pedidosAFacturar&&console.log(pedidosAFacturar.id)
  // pedidosAFacturar&&console.log(pedidosAFacturar.map((pedido)=>pedido.id))

  async function enviar () {
    const pedido = {
      pedido: {
        razon_social:  dataCliente.razon_social,
        id_empresa:  dataCliente.id_empresa,
        id_suc_cliente:  dataCliente.id_sucursal,
        afip_tipodoc_cli:  dataCliente.tipo_doc_afip,
        situacion_iva:  parseInt(dataCliente.situacion_iva),
        // cliente_cuit: dataCliente.cuit, //aca hay q sacarle los guiones
        cliente_cuit: "33508358259",
        uni_fact:  dataCliente.unidad_fact,
        id_segmento:  dataCliente.id_segmento,
        sisven_id:  dataCliente.sisven_id,
        cuit:  dataCliente.cuit.trim(),
        id_condicionpago:  parseInt(dataCliente.id_condicionpago),
        faturacion_especial:  0, //lo dejo n 0 porque si no no lo va a validar.
        factura_consfinal:  0, //la facturacion especial esta solamente disponible para clientes responsables inscriptos, asi que la dejo en 0 porque seguramente no le vamos a hacer factura a consumidor final
        porc_perc_iva:  dataCliente.porc_perc_iva,
        vto_exc_perc_iva:  dataCliente.vto_exc_perc_iva,
        porc_exc_perc_iva:  dataCliente.porc_exc_perc_iva,
        nro_cliente: dataCliente.nro_cliente,
        direccion_entrega: dataCliente.direccion_fiscal, //aca va a ir la direccion fiscal.
        id_tipomoneda: pedidosAFacturar[0].id_tipomoneda, //aca no se eque le voy a poner
        id_producto: pedidosAFacturar[0].id_producto,
        provincia: dataCliente.provincia, //NOMBRE provincia de la direccion d entrega, que en este caso, seria la dir fiscal
        afip_condiva_cliente: dataCliente.afip_condiva_cliente, //codigo AFIP de condicion de iva del cliente
        descr_cond_iva: dataCliente.descr_cond_iva,
        // pto_vta_autogas:"", con esto como hago porqe lo obtengo con el chofer. tendria q pasarle los puntos de venta de la sucursal ??
        // pto_vta_hipergas:"",
        pto_vta_autogas: 5000,
        pto_vta_hipergas: 4000,
        localidad: dataCliente.localidad,
        densidad_hipergas: pedidosAFacturar[0]?.densidad_hipergas, //como la densidad depende del producto, y el producto siempre va a ser el mismo, tomo la densidad del primer registro.
        densidad_autogas: pedidosAFacturar[0]?.densidad_autogas,
        producto: pedidosAFacturar[0]?.producto,
        producto_detalle_factura: pedidosAFacturar[0]?.detalle_factura,
        iva: pedidosAFacturar[0]?.iva, // se toma por producto y como siempre va a ser el mismo(producto), uso el del primer pedido
        iva_cod_afip: pedidosAFacturar[0].iva_cod_afip,
        cuit_empresa: dataCliente.cuit_empresa,
        path_certificado_afip: dataCliente.path_certificado_afip,
        path_key_afip: dataCliente.path_key_afip,
        path_auth_file_afip: dataCliente.path_auth_file_afip,
        provincias_a_percibir: dataCliente.provincias_a_percibir,
        pyme: dataCliente.pyme,
        cbu: dataCliente.cbu,
        tipo_moneda:  pedidosAFacturar[0].tipo_moneda,
        moneda:  pedidosAFacturar[0].moneda,
        ltsentregados: items[0].facturacionLts,
        kgentregados: items[0].facturacionKg,
        precio_siniva: precioUnitario,
        pedidosAFacturar:pedidosAFacturar.map((pedido)=>pedido.id)
      },
    };
    console.log(pedido.pedido)
    console.log("entro en la funcion para enviar")
    // console.log(reqBody);
    //ya se q aca hay q hacer un punto intermedio en en el backend de murcielago pero necesito probarlo rapido
    const response = await fetch("http://localhost:3001/facturar/facturacion_especial", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(pedido),
    });
    // console.log(response)
  }

// console.log(items)






// async function enviar(){
//     console.log("pedidos a facturar: " , pedidosAFacturar, )
//     const response= await fetch('http://localhost:3001/facturar/facturacion_especial',{
//         method: "PUT",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify(pedidosAFacturar),
//       })
// }





  return (
    <div>
      <Container maxWidth="xl" sx={{ p: 2 }} disableGutters>
        <Grid container my={2}>
          <Autocomplete
            size="small"
            fullWidth
            disabled={!clientes}
            focused
            disableClearable
            noOptionsText={"No tiene pedidos pendientes para facturar"}
            options={clientes}
            autoHighlight
            getOptionLabel={(clientes) =>
              `${clientes.razon_social.trim()} (${clientes.sisven_id})`
            }
            onChange={(event, value) => {
              setDataCliente(value);
              getData(
                "/pedidos/facturacion_especial/" + value.id,
                setPedidosCliente
              );
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                key={option.id}
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option.razon_social} ({option.sisven_id})
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                focused
                color="primary"
                variant="outlined"
                placeholder="Tipee para buscar..."
                {...params}
                label="Cliente"
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />
        </Grid>
        {dataCliente && (
          <ResumenFacturacion cliente={dataCliente} items={items} precio={precioUnitario} setPrecio={setPrecioUnitario}  />
        )}

        {pedidosCliente && (
          <>
            <Container disableGutters maxWidth="xl" sx={{ mt: 1 }}>
              <ThemeProvider theme={tableTheme}>
                <MaterialReactTable
                  columns={columns}
                  data={pedidosCliente}
                  enableTopToolbar={false}
                  muiTableHeadCellProps={tableHeadCellProps}
                  initialState={initialState}
                  enableRowSelection
                  onRowSelectionChange={setRowSelection}
                  state={{ rowSelection }}
                  muiTableBodyRowProps={({ row }) => ({
                    onClick: row.getToggleSelectedHandler(),
                    sx: { cursor: "pointer" },
                  })}
                />
              </ThemeProvider>
              <Button variant="text" color="primary" onClick={enviar}>
                enviar
              </Button>
              {/* <Box
                sx={{
                  position: "fixed",
                  bottom: 16,
                  right: 16,
                  mb: 3,
                }}
              >
                <Tooltip title="Guardar">
                  <Fab
                    //   disabled={disGuardar}
                    color="success"
                    aria-label="add"
                    //   onClick={guardar}
                    sx={{ mr: 1 }}
                  >
                    <ExpandMoreIcon />
                  </Fab>
                </Tooltip>
              </Box> */}
            </Container>
          </>
        )}
      </Container>
    </div>
  );
}

export default NuevaFacturacion;
