/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { UrlApi } from "../../../services/apirest";
import {
  Dialog,
  DialogContent,
  Box,
  Grid,
  TextField,
  Typography,
  Container,
  DialogTitle,
  Button,
  InputLabel,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import { grey } from "@mui/material/colors";
import { toast } from "sonner";
import moment from "moment";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FileUploadMultiple from "../../../services/UploadFiles";
const EditarInscripcion = (props) => {
  const {
    DatosInscripcion,
    idproveedor,
    modalEditar,
    onSuccess,
    Onchange,
    OnchangeCheck,
  } = props;

  const [Disabled, setDisabled] = useState(false);
  const [openclose, setopenclose] = useState(false);
  const guardarDatosInscripcion = async () => {
    let response = await fetch(UrlApi + "/inscripciones_iibb/" + idproveedor, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        idprovincias: DatosInscripcion.idprovincia,
        porc_percep: DatosInscripcion.porc_percepcion,
        porc_retencion: DatosInscripcion.porc_retencion,
        exencion: DatosInscripcion.exencion,
        vto_exento: moment(DatosInscripcion.vto_exento).format("YYYY-MM-DD"),
        id_proveedor: idproveedor,
        coeficiente_unificado: DatosInscripcion.coeficiente_unificado,
        certificado_exclusion: DatosInscripcion.certificado_exclusion,
      }),
    });
    onSuccess();
    if (response.ok === false) {
      console.log("ERROR EN API");
      const error = response && response.json();
      toast.error(`${error} "Inconvenientes con la API. No se pudo guardar. "`);
    } else {
      console.log("Conexión con api OK");
      try {
        //Parseo respuesta de la api a json()
        const json = await response.json();
        //si api me devuelve lo q le puse en message
        if (json.message) {
          //Si ese mensaje es "OK"
          if (json.message === "OK") {
            console.log("Guardado Satisfactoriamente");
            toast.success("Guardado");
          } else {
            console.log("res en api incorrecto. Revisar que sea 'OK'");
            toast.success("Registro Guardado. REVISAR RESPUESTA DE API (E_S)");
          }
        } else if (json.sqlMessage) {
          console.log(json);
          toast.error(`SQL erróneo ( ${json.code} Erroneo: ${json.error} )`);
        } else {
          toast.error("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          console.log(
            "Guardado. Advertencia: Actualizar respuesta de api. JSON invalido."
          );
          toast.success("Guardado.");
        }
      }
    }
    handleClose();
  };

  const handleClose = () => {
    modalEditar(false);
  };

  const handleDisabled = () => {
    const { iibb_auto } = DatosInscripcion;
    if (iibb_auto === 1) {
      return setDisabled(true);
    }
    return setDisabled(false);
  };

  useEffect(() => {
    handleDisabled();
  }, [DatosInscripcion]);

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  return (
    <Dialog
      open={modalEditar}
      onClose={handleClose}
      fullWidth
      maxWidth="xl"
      aria-labelledby=""
    >
      <DialogTitle sx={{ flexGrow: 1, backgroundColor: "#1F618D" }}>
        <Grid container mt={1}>
          <Grid item xs={2}>
            <IconButton onClick={handleClose}>
              <ArrowBackIcon sx={{ color: grey[50] }} />
            </IconButton>
          </Grid>
          <Grid
            // item
            xs={8}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h5" color="#ffffff">
              Editar Inscripcion
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box
          mb={3}
          mt={2}
          backgroundColor="#F4F6F6"
          p={3}
          border={0.5}
          borderColor={"#ABB2B9"}
          borderRadius={4}
        >
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <TextField
                id=""
                fullWidth
                label="Provincia"
                name="provincia"
                value={DatosInscripcion.provincia}
                disabled
                style={style}
              />
            </Grid>

            {Disabled ? (
              <>
                <Grid item xs={2}>
                  <TextField
                    id=""
                    label="Percepcion"
                    name="porc_percepcion"
                    value={DatosInscripcion.porc_percepcion}
                    disabled
                    style={style}
                  />
                </Grid>

                <Grid item xs={2}>
                  <TextField
                    id=""
                    label="Retencion"
                    name="porc_retencion"
                    value={DatosInscripcion.porc_retencion}
                    disabled
                    style={style}
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={2}>
                  <TextField
                    id=""
                    label="Percepcion"
                    name="porc_percepcion"
                    fullWidth
                    value={DatosInscripcion.porc_percepcion}
                    onChange={(e) => Onchange(e)}
                    style={style}
                  />
                </Grid>

                <Grid item xs={2}>
                  <TextField
                    id=""
                    label="Retencion"
                    focused
                    fullWidth
                    name="porc_retencion"
                    value={DatosInscripcion.porc_retencion}
                    onChange={(e) => Onchange(e)}
                    style={style}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={2}>
              <TextField
                id=""
                fullWidth
                label="Exencion"
                name="exencion"
                type="number"
                value={DatosInscripcion.exencion}
                onChange={(e) => Onchange(e)}
                style={style}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                id=""
                label="Vto Exento"
                focused
                fullWidth
                name="vto_exento"
                type="date"
                value={DatosInscripcion.vto_exento}
                onChange={(e) => Onchange(e)}
                style={style}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                id=""
                label="Coeficiente Unificado"
                focused
                fullWidth
                name="coeficiente_unificado"
                type="number"
                value={DatosInscripcion.coeficiente_unificado}
                onChange={(e) => Onchange(e)}
                style={style}
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                label="Certificado Exclusion"
                sx={{
                  borderBlock: "3px solid",
                  borderColor: "black",
                  borderRadius: 5,
                  borderWidth: 2,
                  padding: 1,
                }}
                control={
                  <Checkbox
                    name="certificado_exclusion"
                    value={DatosInscripcion.certificado_exclusion}
                    checked={DatosInscripcion.certificado_exclusion}
                    onChange={(e) => OnchangeCheck(e)}
                    color="primary"
                  />
                }
              />
            </Grid>
            {DatosInscripcion.certificado_exclusion === 1 ||
            DatosInscripcion.certificado_exclusion === true ? (
              <>
                {" "}
                <Grid item xs={2}>
                  <Button
                    onClick={() => setopenclose(!openclose)}
                    variant="outlined"
                    color="primary"
                    fullWidth
                    startIcon={<AttachFileIcon />}
                  >
                    Subir Certificado
                  </Button>
                </Grid>
              </>
            ) : null}
            <Grid item xs={2}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                sx={{ p: 2 }}
                onClick={guardarDatosInscripcion}
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Box>
        {openclose ? (
          <FileUploadMultiple
            fileId={props.uuid}
            //   setActualizar={setActualizar}
            openclose={true}
          />
        ) : null}
      </DialogContent>
    </Dialog>
  );
};

export default EditarInscripcion;
