import React, { useState } from 'react';
import { Container, Box } from '@mui/material';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MarcarEntregados from './ordenes_pago_anticipado/MarcarEntregados';
import AutorizarRequerimientos from './AutorizarRequerimientos';
import Navbar from '../../../Navbar';

export default function VistaJefeDePlanta() {
    const [opcion, setOpcion] = useState("pendientes_autorizados")
    const [tab, setTabs] = useState(true)

    const handleChange = (event, newValue) => {
        setOpcion(newValue);
    };

    return (
        <div>
            <Navbar/>
            <Box sx={{ borderBottom: 1, borderColor: "divider", marginTop:1 }}>
            {tab ?
                <Tabs
                    value={opcion}
                    onChange={handleChange}
                    textColor="primary"
                    indicatorColor="primary"
                    aria-label="Requerimientos pendientes/autorizados"
                    variant="fullWidth"
                >
                    <Tab value="pendientes_autorizados" label="Requerimientos pendientes/autorizados" />
                    <Tab value="entregar" label="Ordenes pendiente de entrega" />
                </Tabs>
                 : null}
            </Box>
            {opcion === "pendientes_autorizados" ? <AutorizarRequerimientos /> : null}
            {opcion === "entregar" ? <MarcarEntregados tab={setTabs} /> : null}
        </div>
    )
}
