import React, { useEffect, useState, useMemo } from "react";
import moment from "moment";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import { createTheme, TextField, ThemeProvider, useTheme } from "@mui/material";
import Navbar from "../../../Navbar";
import { Container, Typography, Box, Button, MenuItem, ListItemIcon } from "@mui/material";
import { UrlApi } from "../../../../services/apirest";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import Actualizar_precio_especial from "./Actualizar_precio_especial";
import AddPrecioEspecial from "./AddPrecioEspecial";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import Actualizar_selection from "./Actualizar_selection";
import Editar_lista from "./Editar_lista";
import { Edit } from "@mui/icons-material";
function Lista_preciosEspeciales() {
  const [lista, setLista] = useState(); // p/tabla
  const [tabla, setTabla] = useState(true);
  const [alta, setAlta] = useState(false); //Estado q mostrará o no la pantalla de alta
  const [actualizar, setActualizar] = useState(false);
  const [actualizarTodos, setActualizarTodos] = useState(false);
  const [datoslista, setDatoslista] = useState({});
  const globalTheme = useTheme();
  const [clienteSeleccionado, setclienteSeleccionado] = useState([]);
  const [rowSelection, setrowSelection] = useState({});
  const [editar, setEditar] = useState(false);


  //*********************************** OBTENER **************************************************************************************************/
  const obtenerLista = async () => {
    await fetch(UrlApi + "/precios_especiales/lista_vigente")
      .then((data) => data.json())
      .then((data) => setLista(data));
  };

  //ejecuta la funcion de obtener
  useEffect(() => {
    obtenerLista();
  }, [tabla]);

  useEffect(() => {
    // Crear el array de clientes seleccionados
    const clienteSeleccionado = Object.keys(rowSelection)
      .filter((key) => rowSelection[key]) // Solo incluir las filas seleccionadas
      .map((key) => ({
        id_precioespecial: lista[key].id_precioespecial,
        id_cliente: lista[key].id_cliente,
        lt_sin_iva: lista[key].lt_sin_iva,
        lt_con_iva: lista[key].lt_con_iva,
        kg_sin_iva: lista[key].kg_sin_iva,
        kg_con_iva: lista[key].kg_con_iva,
        vigencia: lista[key].vigencia,
        fin_vigencia: lista[key].fin_vigencia,
        id_producto: lista[key].id_producto,
        densidad_autogas: lista[key].densidad_autogas,
        densidad_hipergas: lista[key].densidad_hipergas,
        id_empresa: lista[key].id_empresa
      }));

    setclienteSeleccionado(clienteSeleccionado);
  }, [rowSelection, lista]);

  //********************************** TABLA ***************************************************************************************************/
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)" //background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const columns = useMemo(
    () => [
      // { header: "ID lista", accessorKey: "id_lista", size: 100 },
      {
        header: " ",
        columns: [
          {
            header: "Cliente",
            accessorKey: "razon_social",
            size: 200,

          },
          // {
          //   header: "Dirección Entrega",
          //   accessorKey: "direccion_entrega",
          //   size: 200,
          // },
          // {
          //   header: "Sucursal",
          //   accessorKey: "nombre",
          //   size: 100,
          // },
        ],
      },
      {
        header: "Precio del Litro",
        columns: [
          {
            header: "Sin IVA",
            accessorKey: "lt_sin_iva",
            size: 80,
          },
          {
            header: "Con IVA",
            accessorKey: "lt_con_iva",
            size: 80,
          },

        ],
      },
      {
        header: "Precio del Kilo",
        columns: [
          {
            header: "Sin IVA",
            accessorKey: "kg_sin_iva",
            size: 80,
          },
          {
            header: "Con IVA",
            accessorKey: "kg_con_iva",
            size: 80,
          },

        ],
      },
      {
        header: "Vigencia",
        columns: [
          {
            header: "Inicio",
            accessorFn: (fecha) => moment(fecha.vigencia).format("DD-MM-YYYY"),
            size: 100,
          },
          {
            header: "Fin",
            accessorFn: (fecha) => fecha.fin_vigencia ? moment(fecha.fin_vigencia).format("DD-MM-YYYY") : "Sin registrar",
            size: 100,
          },
        ],
      },
    ],
    []
  );

  const initialState = {
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
  };

  const tableHeadCellProps = {
    align: "center",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "success",
    size: "small",
  };

  //*************************************************************************************************************************************/
  const handleClickActualizar = (rowData) => {
    setActualizar(true);
    setTabla(true);
    setDatoslista(rowData)

  };

  const handleActualizarTodos = (rowData) => {
    setActualizarTodos(true);
    setTabla(true);
  };

  const handleEditar = (rowData) => {
    setEditar(true);
    setTabla(true);
    setDatoslista(rowData)
  };

  const handleClickNuevo = () => {
    setAlta(true);
    setTabla(false);
  };
  
  return (
    <div>
      <Navbar />
      <Container maxWidth="xl" mt={1} >
        {lista && tabla ? (
          <Box mt={2} boxShadow={3} mb={4}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
                enableColumnResizing
                columns={columns}
                enableRowActions
                state={{ rowSelection }}
                onRowSelectionChange={setrowSelection}
                enableRowSelection
                data={lista}
                initialState={initialState}
                positionActionsColumn="last"
                enableExpandAll={false}
                enableToolbarInternalActions={true}
                muiTableHeadCellProps={tableHeadCellProps}
                muiTableBodyCellProps={({ row }) => ({
                  align: "left",
                  sx: {
                    color: "black",
                    borderColor: "black",
                    backgroundColor: "white"
                  },
                })}
                enableColumnActions={false}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                enableDensityToggle={false}
                positionToolbarAlertBanner="top"
                // enableMultiRowSelection
                enableSelectAll
                renderTopToolbarCustomActions={() => (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddBoxTwoToneIcon />}
                      onClick={handleClickNuevo}
                    >
                      Nuevo Precio
                    </Button>
                    <Typography variant="poster" fontSize={26}>
                      Lista de Precios Especiales
                    </Typography>

                    {clienteSeleccionado.length ? (
                      <Button variant="contained" color="secondary" onClick={handleActualizarTodos}>
                        Actualizar Precio
                      </Button>
                    ) : null}
                  </>
                )}

                layoutMode="grid"
                localization={MRT_Localization_ES}
                enableColumnDragging={false} //No permite que se arrastren las columnas.
                renderRowActionMenuItems={({ closeMenu, row, }) => [
                  <MenuItem
                    key={0}
                    sx={{ m: 0 }}
                    disabled={row.original.fin_vigencia}
                    onClick={() => {

                      // console.log("Open editar precio");
                      handleClickActualizar(row.original)
                      closeMenu();
                    }}>
                    <ListItemIcon>
                      <AutorenewIcon color="secondary" />
                    </ListItemIcon>
                    Actualizar precio
                  </MenuItem>,
                  <MenuItem
                    key={0}
                    sx={{ m: 0 }}
                    disabled={row.original.fin_vigencia}
                    onClick={() => {

                      handleEditar(row.original)
                      closeMenu();
                    }}>
                    <ListItemIcon>
                      <Edit color="primary" />
                    </ListItemIcon>
                     Editar
                  </MenuItem>
                ]} //No permite que se arrastren las columnas.
              />
            </ThemeProvider>

          </Box>
        ) : null}
        {actualizar ? (
          <Actualizar_precio_especial
            open={setActualizar}
            tabla={setTabla}
            datosExisten={datoslista}

          />
        ) : null}

        {alta ? (
          <AddPrecioEspecial
            alta={setAlta}
            tabla={setTabla}
          ></AddPrecioEspecial>
        ) : null}

        {actualizarTodos ?
          <Actualizar_selection
            open={setActualizarTodos}
            tabla={setTabla}
            datosExisten={clienteSeleccionado}
            setDatosExisten={setclienteSeleccionado}
            actualizar={obtenerLista}
          /> : null}

        {editar ? (
          <Editar_lista
            open={setEditar}
            tabla={setTabla}
            datosExisten={datoslista}
            actualizar={obtenerLista}

          ></Editar_lista>) : null}

      </Container>
    </div>
  )
}

export default Lista_preciosEspeciales
//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio /Nicolas Pascucci
// Creation Date : 19/6/2024
// Version : 1
// Description : Tabla de lista de precios
//------Changes ----------------------------------------------------------//
// Change by : Candela Grosso
// Change description:  modificaciones varias. modificar directamente des de la tabla

// Change Date :11/09/2024
//========================================================================/