import Navbar from "../../../Navbar";
import React, { useState, useEffect, useMemo } from "react";
import { UrlApi } from "../../../../services/apirest";
import {
  Box,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Tooltip,
  TextField,
  Stack,
  IconButton,
  Button,
  InputAdornment,
  Typography,
} from "@mui/material";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //idioma
import { createTheme, ThemeProvider, useTheme } from "@mui/material";

// ICONOS
import AddBoxIcon from "@mui/icons-material/AddBox";
import BlockIcon from "@mui/icons-material/Block";
import EditIcon from "@mui/icons-material/Edit";

//Notification
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ComponentesPropelentes() {
  const [componentesPropelentes, setComponentesPropelentes] = useState();
  const [tabla, setTabla] = useState(true);
  const [update, setUpdate] = useState(false);
  const [datosformulario, setDatosformulario] = useState({
    nombre_componente: "",
    estado: "1",
  });

  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");

  const obtenerComponentesPropelentes = () => {
    fetch(UrlApi + "/propelente_componentes") //fetch con la urp de api q esta en services/apirest + la ruta
      .then((data) => data.json()) //mando los datos que recibo en json
      .then((data) => setComponentesPropelentes(data)); //seteo clientes
  };

  useEffect(() => {
    obtenerComponentesPropelentes();
  }, [tabla]);

  // FUNCION QUE SE EJECUTA CADA VEZ QUE HAY UN CAMBIO EN LA BASE DE DATOS
  // SIRVE PARA QUE LA TABLA MOSTRADA SE ACTUALICE EN TIEMPO REAL
  useEffect(() => {
    if (update) obtenerComponentesPropelentes();
  }, [update]);

  //*************************************************************************************************************************************/

  // DIALOG PARA CREAR LOS DATOS
  const [openCrear, setOpenCrear] = React.useState(false);

  const handleClickOpenCrear = () => {
    setOpenCrear(true);
    // AL ABRIR EL DIALOG El TEXTFIELDS SE VACIAN
    datosformulario.nombre_componente = "";
  };

  const handleCloseCrear = () => {
    setOpenCrear(false);
  };

  //**********************************************************************************************/

  // DIALOG PARA ACTIVAR/DESACTIVAR LA RELACIÓN
  const [openDialogOnOff, setOpenDialogOnOff] = React.useState(false);

  const handleCloseDialogOnOff = () => {
    setOpenDialogOnOff(!openDialogOnOff);
  };

  const dialogActivar = (row) => {
    setDatosformulario(row);
    setOpenDialogOnOff(true);
    setTabla(true);
  };

  //**********************************************************************************************/
  // FUNCIONES PARA GUARDAR:
  // ACTIVAR RELACION
  const guardarActivo = async (e) => {
    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(
      UrlApi + "/propelente_componentes/" + datosformulario.id_componente,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          estado: 1,
        }),
      }
    )
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El componente se guardó exitosamente!");
          setUpdate(true);
          //console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el componente");
          //console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  // -----------------------------------------------------------------------

  // DESACTIVAR RELACION
  const guardarInactivo = async (e) => {
    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(
      UrlApi + "/propelente_componentes/" + datosformulario.id_componente,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          estado: 2,
        }),
      }
    )
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El componente se guardó exitosamente!");
          setUpdate(true);
          //console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el componente");
          //console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  //*************************************************************************************************************************************/

  // DIALOG PARA EDITAR LOS DATOS
  const [openEditar, setOpenEditar] = React.useState(false);

  // USANDO ROW TOMO LOS DATOS DE ESA FILA
  const handleClickOpenEditar = (row) => {
    setOpenEditar(true);
    setDatosformulario(row);
  };

  const handleCloseEditar = (row) => {
    setOpenEditar(false);
    setDatosformulario(row);
  };

  //**********************************************************************************************/
  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
    setUpdate(false);
    setOpenEditar(false);
    setOpenCrear(false);
    setOpenDialogOnOff(false);
  };

  //*************************************************************************************************************************************/

  const columns = [
    {
      header: "ID",
      accessorKey: "id_componente",
      minSize: 50,
      size: 80,
      maxSize: 100,
    },
    {
      header: "Nombre",
      accessorKey: "nombre_componente",
    },
    {
      header: "Estado",
      accessorKey: "valor",
    },
  ];

  //*************************************************************************************************************************************/

  //Funcion para guardar AL CREAR
  const guardarCrear = async (e) => {
    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(UrlApi + "/propelente_componentes", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        nombre_componente: datosformulario.nombre_componente,
        estado: datosformulario.estado,
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          setUpdate(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El componente se guardó exitosamente !");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el componente");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  console.log(datosformulario);

  //Funcion para guardar AL EDITAR
  const guardarEditar = async (e) => {
    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(
      UrlApi + "/propelente_componentes/" + datosformulario.id_componente,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          nombre_componente: datosformulario.nombre_componente,
          estado: datosformulario.estado,
        }),
      }
    )
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          setUpdate(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El componente se guardó exitosamente !");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el componente");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    console.log(datosformulario);
  };

  //*************************************************************************************************************************************/
  const almacenar = (e) => {
    setDatosformulario({
      ...datosformulario,
      [e.target.name]: e.target.value,
    });
  };

  // ESTILO PARA LA TABLA
  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)" //random light yellow color for the background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //override to make tooltip font size larger
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  return (
    <>
      <Navbar />
      <br />
      {componentesPropelentes && tabla ? (
        <Container maxWidth="xl" style={{ marginBottom: "30px" }}>
          <ThemeProvider theme={tableTheme}>
            <MaterialReactTable
              columns={columns}
              data={componentesPropelentes}
              initialState={{
                showGlobalFilter: true,
                density: "compact",
              }}
              localization={MRT_Localization_ES} //idioma ES
              // COLORES PARA LAS CELDAS SEGUN ACTIVO/INACTIVO
              muiTableBodyCellProps={({ row }) => ({
                sx: {
                  backgroundColor:
                    row.original.estado === 1 ? "#66FF66" : "#FF5050",
                  color: "black",
                  borderColor: "black",
                  //COLOR AL PASAR EL MOUSE POR ENCIMA
                  "&:hover": {
                    backgroundColor:
                      row.original.estado === 1 ? "#3ad63a" : "#f22929",
                  },
                  "& .MuiTableCell-root:first-child": {
                    border: "none",
                  },
                },
              })}
              renderTopToolbarCustomActions={({ table }) => (
                <Grid item mt={-2.5}>
                  <h2>COMPONENTES PARA PROPELENTES</h2>
                </Grid>
              )}
              renderToolbarInternalActions={({ table }) => (
                <>
                  <Tooltip arrow placement="bottom" title="Agregar Componente">
                    <IconButton color="primary">
                      <AddBoxIcon
                        onClick={() => {
                          handleClickOpenCrear();
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <MRT_FullScreenToggleButton table={table} />
                </>
              )}
              enableRowActions
              renderRowActions={({ row }) => (
                <>
                  <Tooltip arrow placement="left" title="Editar Componente">
                    <IconButton
                      onClick={() => handleClickOpenEditar(row.original)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip
                    arrow
                    placement="left"
                    title={
                      row.original.estado === 1
                        ? "Anular Componente"
                        : "Activar Componente"
                    }
                  >
                    <IconButton onClick={() => dialogActivar(row.original)}>
                      <BlockIcon
                        sx={{
                          color:
                            row.original.estado === 1 ? "red" : "lightgreen",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </>
              )}
              positionActionsColumn="last"
              // TRUE
              enableTopToolbar={true}
              // FALSE
              enableColumnFilters={false} //opcion de filttrado por columnas
              enableDensityToggle={false} //para que no se vea pero esta activo en el estado
              enableHiding={false} //oculta la propiedad show hide columns
            />
          </ThemeProvider>
        </Container>
      ) : null}

      {/* AGREGAR COMPONENTE */}
      <Dialog
        open={openCrear}
        onClose={handleCloseCrear}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          {"Agregar Componente"}
        </DialogTitle>
        <DialogContent>
          <Container maxWidth="xl">
            <form onSubmit={guardarCrear}>
              <Grid container>
                <Grid item xs={12} mt={2} mb={2}>
                  <TextField
                    id="nombre_componente"
                    label="Nombre"
                    name="nombre_componente"
                    placeholder="Etano, Propileno..."
                    onChange={almacenar}
                    value={datosformulario.nombre_componente}
                    fullWidth
                    focused
                    required
                  />
                </Grid>
              </Grid>
              <Grid
                mt={2}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              />
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleCloseCrear}
                >
                  Cancelar
                </Button>
                <Button variant="contained" color="success" type="submit">
                  Guardar
                </Button>
              </Stack>
              <Stack spacing={2} sx={{ width: "100%" }}>
                <Snackbar
                  open={openSnack}
                  autoHideDuration={1500}
                  onClose={handleCloseSnack}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                  <Alert
                    onClose={handleCloseSnack}
                    severity="success"
                    sx={{ width: "100%" }}
                    style={{ backgroundColor: colormensaje, color: "black" }}
                  >
                    {mensaje}
                  </Alert>
                </Snackbar>
              </Stack>
            </form>
          </Container>
        </DialogContent>
      </Dialog>

      {/* EDITAR COMPONENTE */}
      <Dialog
        open={openEditar}
        onClose={handleCloseEditar}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <Box
          style={{
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: "#1976D2",
          }}
        >
          <DialogTitle id="alert-dialog-title">
            {"Editar Componente"}
          </DialogTitle>
          <DialogContent>
            <Container maxWidth="xl">
              <form onSubmit={guardarEditar}>
                <Grid container>
                  <Grid item xs={12} mt={2} mb={2}>
                    <TextField
                      id="nombre_componente"
                      label="Nombre"
                      name="nombre_componente"
                      placeholder="Etano, Propileno..."
                      onChange={almacenar}
                      value={datosformulario.nombre_componente}
                      fullWidth
                      focused
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EditIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  mt={2}
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                />

                <Stack spacing={2} direction="row">
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleCloseEditar}
                  >
                    Cancelar
                  </Button>

                  <Button variant="contained" color="primary" type="submit">
                    Guardar
                  </Button>
                </Stack>

                <Stack spacing={2} sx={{ width: "100%" }}>
                  <Snackbar
                    open={openSnack}
                    autoHideDuration={1500}
                    onClose={handleCloseSnack}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  >
                    <Alert
                      onClose={handleCloseSnack}
                      severity="success"
                      sx={{ width: "100%" }}
                      style={{ backgroundColor: colormensaje, color: "black" }}
                    >
                      {mensaje}
                    </Alert>
                  </Snackbar>
                </Stack>
              </form>
            </Container>
          </DialogContent>
        </Box>
      </Dialog>

      {/* DIALOG PARA BLOQUEAR/DESBLOQUEAR LA RELACION */}
      <Dialog open={openDialogOnOff} onClose={handleCloseDialogOnOff}>
        <Box
          style={{
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: datosformulario.estado === 1 ? "#e32712" : "#24ff1c",
            color: datosformulario.estado === 1 ? "red" : "green",
          }}
        >
          <DialogTitle>
            <Typography align="center">
              <b>
                SE {datosformulario.estado === 1 ? "DESACTIVARÁ" : "ACTIVARÁ"}{" "}
                EL COMPONENTE:
              </b>
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText textAlign={"center"}>
              <Grid item mb={4}>
                <br />
                <b>{datosformulario.nombre_componente}</b>
              </Grid>
            </DialogContentText>
            <DialogContentText textAlign={"center"}>
              {" "}
              {"¿Confirma que quiere " +
                (datosformulario.estado === 1 ? "desactivar" : "activar") +
                " este componente?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Stack spacing={4} direction="row">
              <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="center"
                // style={{ minHeight: '10vh' }}
              >
                <Grid item mr={22}>
                  <Button
                    style={{
                      backgroundColor: "#d32f2f",
                      color: "#ffffff",
                    }}
                    variant="contained"
                    onClick={handleCloseDialogOnOff}
                  >
                    Cancelar
                  </Button>
                </Grid>{" "}
                {datosformulario.estado === 1 ? (
                  <Button
                    style={{ backgroundColor: "#fc7a23", color: "#ffffff" }}
                    onClick={guardarInactivo}
                    variant="contained"
                  >
                    Desactivar
                  </Button>
                ) : (
                  <Button
                    style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                    onClick={guardarActivo}
                    variant="contained"
                  >
                    Activar
                  </Button>
                )}{" "}
              </Grid>
            </Stack>
          </DialogActions>
        </Box>
      </Dialog>

      {/* SNACKBAR QUE SE MOSTRARA AL GUARDAR LOS CAMBIOS */}
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}

export default ComponentesPropelentes;
