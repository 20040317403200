import React from "react";

export class FichaAnalisisCalidad extends React.PureComponent {
  render() {
    return (
      <div>
        <table
          border="1"
          position="absolute"
          width="500px"
          margin="auto"
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "20px",
            borderCollapse: "collapse",
            marginBottom: "20px",
          }}
        >
          <table
            border="1"
            style={{
              borderCollapse: "collapse",
              width: "100%",
              height: "90px",
            }}
          >
            <tr>
              <th>
                <img src="/Italgas.jpeg" alt="ITALGAS" height={90}></img>
              </th>
              <th style={{ fontSize: 22 }}>Certificado de Calidad: 17590</th>
              {/* <th style={{ fontSize: 22 }}>Certificado de Calidad: 17590</th> */}
            </tr>
          </table>
          <table
            border="1"
            style={{
              borderCollapse: "collapse",
              width: "100%",
              height: "55px",
            }}
          >
            <tr style={{ fontSize: 14 }}>
              <td style={{ textAlign: "center" }}>
                <b>PROPELENTE IGP 42</b>
                {/* <b>PROPELENTE IGP 42</b> */}
              </td>
              <td style={{ textAlign: "center" }}>
                <b>09:30 - 02/02/2023</b>
                {/* <b>09:30 - 02/02/2023</b> */}
              </td>
              <td>
                {/* <b>Oper. Físico: </b>CASTRO OMAR JULIO ALBERTO */}
                <b>Oper. Físico: </b>CASTRO OMAR JULIO ALBERTO
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }}>
                {/* <b>Tanque: 11</b> */}
                <b>Tanque: 11</b>
              </td>
              <td style={{ textAlign: "center" }}>
                {/* <b>Vencimiento: </b>02/08/2023 */}
                <b>Vencimiento: </b>02/08/2023
              </td>
              <td style={{ fontSize: 14 }}>
                {/* <b>Oper. Cromat.: </b>CORDOBA MANUEL FELIPE */}
                <b>Oper. Cromat.: </b>CORDOBA MANUEL FELIPE
              </td>
            </tr>
          </table>

          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              height: "15%",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <tr>
              <th style={{ backgroundColor: "#afafaf", height: "45px" }}>
                Ensayos Físicos de GLP
              </th>
            </tr>
          </table>

          <table
            border="1"
            style={{
              borderCollapse: "collapse",
              width: "90%",
              height: "15%",
              marginLeft: "auto",
              marginRight: "auto",
              fontSize: 14,
            }}
          >
            <tr style={{ backgroundColor: "#afafaf" }}>
              <th style={{ textAlign: "left" }}>Elemento</th>
              <th>Unidad</th>
              <th>Valor</th>
            </tr>
            <tr>
              <td>
                <b>Presión de vapor a 70 F (ASTM-D-6897)</b>
              </td>
              <td>(psig)</td>
              <td style={{ textAlign: "right" }}>44</td>
            </tr>
            <tr>
              <td>
                <b>Densidad (ASTM-D-2598)</b>
              </td>
              <td>(kg/l)</td>
              {/* <td style={{ textAlign: "right" }}>0,564</td> */}
              <td style={{ textAlign: "right" }}>0,564</td>
            </tr>
            <tr>
              <td>
                <b>Panel de Olor</b>
              </td>
              <td></td>
              {/* <td style={{ textAlign: "right" }}>Ideal</td> */}
              <td style={{ textAlign: "right" }}>Ideal</td>
            </tr>
          </table>

          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              height: "15%",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <tr>
              <th style={{ backgroundColor: "#afafaf", height: "45px" }}>
                Ensayos Cromatográficos
              </th>
            </tr>
          </table>

          <table
            border="1"
            style={{
              borderCollapse: "collapse",
              width: "90%",
              height: "15%",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "30px",
              fontSize: 14,
            }}
          >
            {/* MAPEAR (???) */}
            <tr style={{ backgroundColor: "#afafaf", border: 10 }}>
              <th>Elemento</th>
              <th colspan="2">Valor</th>
              <th>Especificación</th>
              <th>Met. Ensayado</th>
            </tr>
            <tr>
              <td>
                <b>Metano</b>
              </td>
              <td style={{ textAlign: "right" }}></td>
              <td style={{ textAlign: "right" }}>0.00% Molar</td>
              <td style={{ textAlign: "right" }}>0,0</td>
              <td style={{ textAlign: "right" }}>ASTM-2163-82</td>
            </tr>
            <tr>
              <td>
                <b>Propano</b>
              </td>
              <td style={{ textAlign: "right" }}></td>
              <td style={{ textAlign: "right" }}>22.19% Molar</td>
              <td style={{ textAlign: "right" }}>17-24</td>
              <td style={{ textAlign: "right" }}>ASTM-2163-82</td>
            </tr>
            <tr>
              <td border="0">
                <b>Butanos</b>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }}>
                <b>I-Butano</b>
              </td>
              <td style={{ textAlign: "right" }}>29.47% Molar</td>
              <td style={{ textAlign: "right" }}></td>
              <td style={{ textAlign: "right" }}></td>
              <td style={{ textAlign: "right" }}>ASTM-2163-82</td>
            </tr>
          </table>
          <table
            border="1"
            style={{
              borderCollapse: "collapse",
              width: "95%",
              height: "20%",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "30px",
              fontSize: 14,
            }}
          >
            <tr border="2">
              <td
                style={{
                  borderCollapse: "collapse",
                  paddingLeft: "20px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                {/* <b>Aspecto:</b> Inodoro. */}
                <b>Aspecto:</b> Inodoro.
              </td>
            </tr>
          </table>

          <table style={{ marginTop: "10px", fontSize: 14 }}>
            <tr>
              <td style={{ paddingLeft: "20px", paddingRight: "250px" }}>
                Laboratorista
              </td>
              <td style={{ paddingRight: "250px", textAlign: "left" }}>
                Despachante
              </td>
            </tr>
          </table>

          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "30px",
              marginBottom: "10px",
              fontSize: 14,
            }}
          >
            <tr>
              <td style={{ textAlign: "center" }}>
                Planta Procesadora Las Heras - Ruta 200 Km. 70 Parque Ind. Las
                Heras - Tel. (0220) 4763035
              </td>
            </tr>
          </table>
        </table>
      </div>
    );
  }
}
export default FichaAnalisisCalidad;