import React, { useState, useEffect, useMemo } from "react";
import { UrlApi } from "../../../services/apirest";
import Navbar from "../../Navbar";
import { Container, Typography, Box, DialogContentText, Stack, Button, Dialog, DialogTitle, DialogContent, DialogActions, Tooltip, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Notify from "../../Notify";
import Alerta from "../../Alerta";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import AddDirecciones from "./Adddirecciones";
import Editardireccion from "./Editardirecciones";
import Fichadireccion from "./FichaDireccion";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";

export default function Direcciones() {
    const [tabla, setTabla] = useState(true);
    const [alta, setAlta] = useState(false);
    const [editar, setEditar] = useState(false);
    const [ficha, setFicha] = useState(false);
    const [direcciones, setDirecciones] = useState(null);
    const [datosformulario, setDatosformulario] = useState(null);
    const [open, setOpen] = useState(false); //estado para el dialog para dar de baja
    const [openDialog, setOpenDialog] = useState(false); //estado para el dialog para dar de baja
    const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
    const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
    const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
    const [error, setError] = useState(null); //guardará el error que lance la api (si existe)  
    const globalTheme = useTheme();

    const obtenerDirecciones = () => {
        fetch(UrlApi + "/direccionesentrega/tabla")
            .then((data) => data.json())
            .then((data) => setDirecciones(data));
    };

    useEffect(() => {
        obtenerDirecciones();
    }, [tabla]);

    const handleClickOpen = (rowData) => {
        setOpen(true);
        setDatosformulario(rowData);
        console.log("datosf", datosformulario);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickHabilitar = (rowData) => {
        setOpenDialog(true);
        setDatosformulario(rowData);
        console.log(rowData)
    };

    const handleClickNuevo = () => {
        setTabla(false);
        setAlta(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleEditar = (rowData) => {
        setTabla(false);
        setEditar(true);
        setDatosformulario(rowData);
    };

    const mostrarficha = (rowData) => {
        setTabla(false);
        setFicha(true);
        setDatosformulario(rowData);
    };

    const marcarInactivo = async (e) => {
        e.preventDefault();
        const response = await fetch(
            UrlApi + "/direccionesentrega/" + datosformulario.id,
            {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(datosformulario.activo === 0 ? { activo: 1 } : { activo: 0 }),
            })
        if (response.ok === false) {
            const error = response && response.json();
            setError(error);
            alert("Inconvenientes con la API. No se pudo guardar. ");
        } else {
            try {
                const json = await response.json();
                if (json.message) {
                    setNotificacion(true);
                    setOpenSnack(true);
                    setMensaje(
                        json.message === "OK" ? "Guardado" : "Guardado. Actualizar RTA Api."
                    );
                    obtenerDirecciones();
                } else if (json.sqlMessage) {
                    json && setError(json);
                    alert("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
                } else {
                    alert("ERROR DESCONOCIDO.");
                }
            } catch (error) {
                if (error.toString().includes("is not valid JSON")) {
                    setNotificacion(true);
                    setOpenSnack(true);
                    setMensaje("Guardado");
                    obtenerDirecciones();
                } else {
                    alert("Error: " + error);
                }
            }
        }
        setOpen(false)
    };

    const columns = useMemo(() =>
        [{ header: 'Nro Cliente', accessorKey: 'id_cliente', size: 70 },
        // { header: 'Sisven', accessorKey: 'sisven_id' },
        { header: 'Cliente', accessorKey: 'razon_social' },
        { header: 'Lugar', accessorKey: 'nombre_direccion' },
        { header: 'Provincia', accessorKey: 'provincia' },
        { header: 'Localidad', accessorKey: 'localidad' },
        { header: 'Direccion', accessorKey: 'direccion_entrega' }
        ], []);

    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: globalTheme.palette.mode,
                    primary: globalTheme.palette.primary, //primary color for the table
                    info: {
                        main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            globalTheme.palette.mode === "light"
                                ? "rgb(234, 239, 241)"
                                : "#000", //pure black table in dark mode for fun
                    },
                },
                typography: {
                    button: {
                        textTransform: "none", //customize typography styles for all buttons in table by default
                        fontSize: "1rem",
                    },
                },
                components: {
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                fontSize: "0.8rem", //larger tooltip font size                                
                            },
                        },
                    },
                },

            }), [globalTheme]);

    const muiSearchTextFieldProps = {
        placeholder: "Buscar..",
        sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
        variant: "outlined",
        color: "success",
        size: "small",
    };

    const initialState = {
        density: "compact",
        showGlobalFilter: true,
        pagination: { pageIndex: 0, pageSize: 15 }
    };

    const tableHeadCellProps = {
        align: "center",
        sx: {
            fontSize: 16,
            color: "white",
            fontWeight: "normal",
            // paddingTop: 1,
            // paddingBottom: 1,
            backgroundColor: "#2471A3",
            borderColor: "black",
        },
    };

    return (
        <div>
            <Navbar></Navbar>
            <Container maxWidth="xl" mt={1}>
                {direcciones && tabla ? (
                    <Box mt={2} boxShadow={3}>
                        <ThemeProvider theme={tableTheme}>
                            <MaterialReactTable
                                enableColumnResizing
                                columns={columns}
                                data={direcciones}
                                initialState={initialState}
                                positionActionsColumn="last"
                                enableExpandAll={false}
                                enableToolbarInternalActions={true}
                                muiTableHeadCellProps={tableHeadCellProps}
                                muiTableBodyCellProps={({ row }) => ({
                                    align: "center",
                                    sx: {
                                        backgroundColor:
                                            row.original.activo === 1 ? "#afdbaf" : "#e39898",
                                        color: "black",
                                        borderColor: "black",
                                    },
                                })}
                                enableColumnActions={false}
                                muiSearchTextFieldProps={muiSearchTextFieldProps}
                                enableDensityToggle={false}
                                enableEditing
                                renderTopToolbarCustomActions={() => (
                                    <>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<AddBoxTwoToneIcon />}
                                            onClick={handleClickNuevo}
                                        >
                                            Nuevo Registro
                                        </Button>
                                        <Typography variant="poster" fontSize={26}>
                                            Direcciones
                                        </Typography>
                                    </>
                                )}
                                renderRowActions={({ row, table }) => (
                                    <div>
                                        <Tooltip title="Editar direccion de entrega">
                                            <IconButton
                                                onClick={() => handleEditar(row.original)}
                                                color="primary"
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title="Ver Ficha">
                                            <IconButton
                                                onClick={() => mostrarficha(row.original)}
                                                color="success"
                                            >
                                                <RemoveRedEyeIcon />
                                            </IconButton>
                                        </Tooltip>


                                        {(row.original.activo === 1) ?
                                            <Tooltip title="Hablitar direccion">
                                                <IconButton
                                                    onClick={() => [handleClickOpen(row.original), console.log("row data", row.original)]}
                                                    color="primary"
                                                >
                                                    <CheckCircleOutlineIcon />
                                                </IconButton>
                                            </Tooltip>
                                            :
                                            <Tooltip title="Dar de baja direccion">
                                                <IconButton
                                                    onClick={() => [handleClickOpen(row.original), console.log("entre en dar de baja")]}
                                                    color="error"
                                                >
                                                    <BlockIcon />
                                                </IconButton>
                                            </Tooltip>
                                        }


                                    </div>
                                )}
                                layoutMode="grid"
                                localization={MRT_Localization_ES}
                                enableColumnDragging={false}
                                enableColumnFilters={false}
                                enableHiding={false} />
                        </ThemeProvider>
                    </Box>
                )
                    : null}
                <br />
                <br />
            </Container>
            <Dialog open={open} onClose={handleClose}>
                <Box backgroundColor={"#1F618D"}>
                    <DialogTitle>
                        {datosformulario && datosformulario.activo === 0 ?
                            <Typography variant="h6" align="center" color="#ffffff">
                                Confirma que desea dar de alta esta dirección?
                            </Typography>
                            :
                            <Typography variant="h6" align="center" color="#ffffff">
                                Confirma que desea dar de baja esta dirección?
                            </Typography>
                        }
                    </DialogTitle>
                </Box>
                <DialogContent>
                    <DialogContentText textAlign={"center"}>
                        {datosformulario && datosformulario.activo === 0 ?
                            " Usted está por dar de alta la dirección de entrega seleccionada"
                            :
                            " Usted está por dar de baja la dirección de entrega seleccionada "
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <br></br>
                    <Stack spacing={45} direction="row">
                        <Button
                            variant="contained"
                            onClick={handleClose}
                            style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
                        >
                            Cancelar
                        </Button>
                        <Button
                            onClick={marcarInactivo}
                            variant="contained"
                            style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                        >
                            Confirmar
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
            {alta ? <AddDirecciones alta={setAlta} tabla={setTabla} /> : null}
            {editar ? (
                <Editardireccion
                    datos={datosformulario}
                    editar={setEditar}
                    tabla={setTabla}
                />
            ) : null}
            {ficha ? (
                <Fichadireccion
                    datos={datosformulario}
                    ficha={setFicha}
                    tabla={setTabla}
                />
            ) : null}

            {
                notificacion ? (
                    <Notify
                        mensaje={mensaje}
                        open={openSnack}
                        color={"#D4EFDF"}
                        severity={"success"}
                        handleClose={handleClose}
                    />
                ) : null
            }
        </div>

    )

}
