import React, { useEffect, useState, useMemo } from "react";
import {
  Grid,
  Typography,
  Button,
  Box,
  Container,
  Divider,
} from "@mui/material";
import axios from "axios";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { UrlApi } from "../../../services/apirest";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";

import Notify from "../../Notify";
import { getData } from "../../../services/library";

const Imputaciones = (props) => {
  const { proveedor, alta, tabla } = props;
  const [Imputaciones, setImputaciones] = useState([]);

  const [Notificacion, setNotificacion] = useState({
    open: null,
    mensaje: null,
    severity: null,
    color: null,
  });
  function getImputaciones() {
    axios.get(UrlApi + "/imputacion_gastos").then((res) => {
      setImputaciones(res.data);
    });
  }

  useEffect(() => {
    getImputaciones();
  }, []);

  const HandleVolver = () => {
    alta(false);
    tabla(true);
  };

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "primary",
    size: "small",
  };

  const initialState = {
    density: "compact",
    showGlobalFilter: true,
    pagination: {
      pageSize: 15,
      pageIndex: 0,
    },
  };

  const tableHeadCellProps = {
    align: "center",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };

  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //larger tooltip font size
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const [ImputacionSeleccionada, setImputacionselected] = useState(null);
  const [rowSelection, setrowSelection] = useState({});

  useEffect(() => {
    const { ImputacionSeleccionada } = Object.keys(rowSelection).reduce(
      (result, key) => {
        if (rowSelection[key]) {
          result.ImputacionSeleccionada.push({
            uuidproveedor: proveedor.uuid_proveedor,
            idimputacion: Imputaciones[key].idimputacion,
          });
        }
        return result;
      },
      { ImputacionSeleccionada: [] }
    );
    setImputacionselected(ImputacionSeleccionada);
  }, [Imputaciones, proveedor.uuid_proveedor, rowSelection]);

  const guardarImputacionProveedor = async () => {
    let response = await fetch(UrlApi + "/imputacion_proveedores/", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(ImputacionSeleccionada),
    });
    props.onSuccess();
    if (response.ok === false) {
      console.log("ERROR EN API");
      const error = response && response.json();
      console.error(error);
    } else {
      console.log("Conexión con api OK");
      try {
        //Parseo respuesta de la api a json()
        const json = await response.json();
        //si api me devuelve lo q le puse en message
        if (json.message) {
          //Si ese mensaje es "OK"
          if (json.message === "OK") {
            console.log("Guardado Satisfactoriamente");
            setNotificacion({
              open: true,
              mensaje: "Guardado Satisfactoriamente",
              severity: "success",
              color: "#D4EFDF",
            });
          } else {
            console.log("res en api incorrecto. Revisar que sea 'OK'");
          }
        } else if (json.sqlMessage) {
          json && console.error(json);
          console.log(
            "SQL erróneo (" + json.code + " Errno: " + json.errno + " )"
          );
          setNotificacion({
            open: true,
            mensaje: "Error SQL. Contacte a soporte.",
            severity: "error",
            color: "#FADBD8",
          });
        } else {
          console.error("Error Desconocido");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          setNotificacion({
            open: true,
            mensaje:
              "Guardado. Advertencia: Actualizar respuesta de api. JSON invalido.",
            severity: "success",
            color: "#D4EFDF",
          });
        } else {
          console.error("Error Desconocido");
        }
      }
    }
  };

  const columns = useMemo(
    () => [
      { header: "Descripcion", accessorKey: "descripcion" },
      { header: "Cuenta Contable", accessorKey: "cuenta_contable" },
      { header: "Alicuota", accessorKey: "alicuota" },
    ],
    []
  );

  return (
    <Container maxWidth="xl">
      <br />
      <Box
        mb={3}
        backgroundColor="#F4F6F6"
        p={3}
        mt={2}
        border={0.5}
        borderColor={"#ABB2B9"}
        borderRadius={4}
      >
        {Notificacion && Notificacion.open ? (
          <Notify
            open={Notificacion.open}
            mensaje={Notificacion.mensaje}
            severity={Notificacion.severity}
            color={Notificacion.color}
          />
        ) : null}

        <ThemeProvider theme={tableTheme}>
          <MaterialReactTable
            columns={columns}
            data={Imputaciones}
            localization={MRT_Localization_ES}
            muiSearchTextFieldProps={muiSearchTextFieldProps}
            // displayColumnDefOptions={displayColumnDefOptions}
            muiTableHeadCellProps={tableHeadCellProps}
            enableExpandAll={false}
            // enableGlobalFilter={true}
            enableToolbarInternalActions={true}
            //enableTopToolbar={false} // habilita o deshabilita TODA la barra de herramientas de arriba
            enableColumnResizing
            layoutMode="grid"
            muiTableBodyCellProps={{
              align: "center",
              sx: {
                backgroundColor: "#FFFFFF",
                color: "black",
                borderColor: "black",
              },
            }}
            enableColumnActions={false}
            enableColumnFilters={false} //desactiva filtros x  columna
            enableHiding={false}
            enableDensityToggle={false} //
            enableColumnDragging={false} //No permite que se arrastren las columnas.
            initialState={initialState}
            enableStickyFooter
            renderTopToolbarCustomActions={() => (
              <>
                <Typography
                  variant="poster"
                  fontSize={26}
                  sx={{ marginLeft: "auto", marginRight: "auto" }}
                >
                  <AssignmentIcon color="warning" sx={{ fontSize: 20 }} />
                  Imputaciones
                </Typography>
              </>
            )}
            enableRowSelection
            onRowSelectionChange={setrowSelection}
            state={{ rowSelection }}
            positionActionsColumn="last"
          />
        </ThemeProvider>
        <br />
        <Grid
          container
          spacing={2}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <Grid item>
            <Button onClick={HandleVolver} color="error" variant="contained">
              Volver
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={(event) => {
                guardarImputacionProveedor();
                setTimeout(() => HandleVolver(), 2000);
              }}
              color="success"
              variant="contained"
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Box>
      <br />
    </Container>
  );
};

export default Imputaciones;
