import React from "react"; import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

export default function Comprobanteexiste(props) {

    const handleClose = () => {
        props.setOpen(false);
    };

    return (
        <div>
            <Dialog open={props.open} onClose={handleClose}>
                <DialogTitle borderTop={6} borderColor={props.color}></DialogTitle>
                <DialogContent>
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        wrap="wrap"
                    >
                        <Grid item xs={3}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="flex-start"
                            >
                                <Grid item>
                                    <Avatar sx={{ bgcolor: props.color, width: 80, height: 80 }}>
                                        {props.icon}
                                    </Avatar>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={9}>
                            <Grid container direction="column">
                                <Grid item>
                                    <Typography
                                        variant="body1"
                                        color="#2E4053"
                                        gutterBottom
                                        fontWeight="bold"
                                    >
                                        {" "}
                                        {props.title}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body1" color="textSecondary">
                                        {props.subtitle}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <ul>
                                        {props.items && props.items.map((item) => (
                                            <li>
                                                <Typography variant="body1" color="textSecondary">
                                                    {item}
                                                </Typography>
                                            </li>
                                        ))}
                                    </ul>

                                </Grid>
                                <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    style={{ marginTop: 8 }}
                                >
                                    {props.confirmText}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={0} justifyContent="space-between">
                        <Button
                            onClick={handleClose}
                            color="error"
                            variant="contained"
                        >
                            Volver
                        </Button>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    )
}

//========================================================================
/*----- Component --------------------------------------------------------/
// Developer     : Candela Grosso   
// Creation Date :12/12/2023
// Version       : 1.0  
// Description   :  Componente con el díalogo que se abrirá en caso de que se quiere cargar un comprobante existente
//------Changes --------------------------------------------------------------------- -------------//
// Change Date   :
// Change by     :
// Change description: 
//========================================================================*/

