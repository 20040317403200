import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { UrlApi } from "../../../../../services/apirest";
import Notify from "../../../../Notify";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import FormControl from "@mui/material/FormControl";
import AddBoxIcon from "@mui/icons-material/AddBox";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AddBateria from "./AddBateria";

function GestionBat(props) {
  const [bateriaSel, setBateriaSel] = useState(props.registro.idbateria);
  const [baterias, setBaterias] = useState([{}]);
  const [altaBateria, setAltaBateria] = useState(false);
  const [ultimaBateria, setearUltimaBateria] = useState(false);

  //Manejo de errores
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
  const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
  const [disBtn, setDisBtn] = useState(false);

  const borrarSel = () => {
    setBateriaSel(null);
    obtenerBaterias();
  };

  const obtenerBaterias = () => {
    fetch(UrlApi + "/baterias2/dir/" + props.direccion.id)
      .then((data) => data.json())
      .then((data) => setBaterias(data));
  };

  useEffect(() => {
    obtenerBaterias();
  }, []);

  useEffect(() => {
    if (
      ultimaBateria === true &&
      baterias &&
      baterias[0] &&
      baterias[0].idbateria
    ) {
      setBateriaSel(baterias[0].idbateria);
      setearUltimaBateria(false);
    }
  }, [baterias]);

  const handleClose = () => {
    props.setOpen(false);
  };

  async function guardarRegistro(e) {
    setDisBtn(true);
    e.preventDefault();
    const response = await fetch(
      UrlApi + "/tanques_contratos/" + props.registro.id_tanquecontrato,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          usuario: localStorage.getItem("user"),
          id_bateria: bateriaSel ? bateriaSel : null,
        }),
      }
    );
    if (response.ok === false) {
      console.log("ERROR EN API");
      const error = response && response.json();
      setError(error);
      alert("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      console.log("Conexión con api OK");
      try {
        const json = await response.json();
        if (json.message) {
          // alert("Guardado")
          // handleClose()
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje(
            json.message === "OK" ? "Guardado" : "Guardado. Actualizar RTA Api"
          );
          props.actualizar();
        } else if (json.sqlMessage) {
          json && setError(json);
          alert("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
        } else {
          alert("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje("Guardado. Actualizar RTA Api a Json");
          props.actualizar();
        } else {
          alert("ERROR DESCONOCIDO.");
        }
      }
    }
  }

  return (
    <div>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={props.open}
        onClose={() => handleClose}
      >
        {notificacion ? (
          <Notify
            mensaje={mensaje}
            open={openSnack}
            color={"#D4EFDF"}
            severity={"success"}
            handleClose={handleClose}
          />
        ) : null}
        <Box justifyContent="center" backgroundColor="#1F618D">
          <DialogTitle>
            <Typography align="center" color="#ffffff">
              Gestión Batería
            </Typography>
          </DialogTitle>
        </Box>
        <form onSubmit={guardarRegistro}>
          <DialogContent>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
            >
              <Grid item xs={8}>
                <FormControl fullWidth focused>
                  <InputLabel>Bateria</InputLabel>
                  <Select
                    // name="bateriaSel"
                    focused
                    value={bateriaSel}
                    label="Bateria"
                    onChange={(e) => setBateriaSel(e.target.value)}
                  >
                    {baterias
                      ? baterias.map((elemento) => (
                          <MenuItem
                            key={elemento.idbateria}
                            value={elemento.idbateria}
                          >
                            {elemento.nro} - {elemento.nombre}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <Tooltip title="Borrar Selección">
                  <span>
                    <IconButton
                      disabled={!baterias}
                      color="primary"
                      size="large"
                      onClick={() => borrarSel()}
                    >
                      <DeleteIcon fontSize="inherit" />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
              <Grid item xs={2}>
                <Tooltip title="Nueva Bateria">
                  <span>
                    <IconButton
                      disabled={!props.direccion.id}
                      color="primary"
                      size="large"
                      onClick={(e) => setAltaBateria(true)}
                    >
                      <AddBoxIcon fontSize="inherit" />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid
              mt={2}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button
                disabled={disBtn}
                variant="contained"
                onClick={() => props.setOpen(false)}
                color="error"
              >
                Volver
              </Button>
              <Button
                disabled={disBtn}
                variant="contained"
                type="submit"
                color="success"
              >
                Guardar En Batería
              </Button>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
      {altaBateria ? (
        <>
          <AddBateria
            open={altaBateria}
            setOpen={setAltaBateria}
            datos={props.direccion.id} //el id de la direccion donde ira la bateria
            cliente={props.cliente} //para tomar el sisven_id
            actualizar={obtenerBaterias}
            setearUltimaBateria={setearUltimaBateria}
          />
        </>
      ) : null}
    </div>
  );
}

export default GestionBat;
