import React, { useEffect, useState } from "react";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import { UrlApiEnvasado } from "../../../services/apirest";

// ICONOS
import CircularProgress from "@mui/material/CircularProgress";

export default function BasicPie() {
  const [loading, setLoading] = useState(true);
  const [datosGrafico, setDatosGrafico] = useState([]);

  const obtenerDatosGrafico = async (endpoint, label) => {
    try {
      setLoading(true);
      const response = await fetch(`${UrlApiEnvasado}${endpoint}`);
      const data = await response.json();

      console.log(`Nuevos datos del gráfico (${label}):`, data);

      setDatosGrafico((prevData) => [...prevData, { label, data }]);
      setLoading(false);
    } catch (error) {
      console.error(`Error al obtener datos del gráfico (${label}):`, error);
      setLoading(false);
    }
  };

  // Organizar datos
  // PARA EL CHART
  const datosParaGrafico = datosGrafico.map((conjunto) => ({
    label: conjunto.label,
    value: conjunto.data.length, // Obtener la cantidad de elementos en el conjunto
  }));

  useEffect(() => {
    // Obtener (Paso el parametro de la URL y el "Label")
    obtenerDatosGrafico("/envasado/movil/mbenz", "Mercedes Benz");
    obtenerDatosGrafico("/envasado/movil/hyundai", "Hyundai");
    obtenerDatosGrafico("/envasado/movil/ford", "Ford");
    obtenerDatosGrafico("/envasado/movil/chevrolet", "Chevrolet");
    obtenerDatosGrafico("/envasado/movil/inactivos", "Inactivos");
  }, []); // Se ejecuta solo una vez al montar el componente

  return (
    <>
      {loading ? (
      <div style={{ position: "relative", minHeight: "200px" }}>
      <CircularProgress size={60}      style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}/>
        </div>
      ) : (
        <div>

        <PieChart
          series={[
            {
              arcLabel: (item) => `${item.label} (${item.value})`,
              arcLabelMinAngle: 45,
              data: datosParaGrafico,
              highlightScope: { faded: "global", highlighted: "item" },
              faded: { innerRadius: 30, additionalRadius: -30, color: "gray" },
            },
          ]}
          sx={{
            [`& .${pieArcLabelClasses.root}`]: {
              fill: "white",
              fontWeight: "bold",
            },
          }}
          // OCULTO LA LEYENDA
          // slotProps={{ legend: { hidden: true } }}
  //  ESTILO DEFAULT
          // slotProps={{
          //   legend: {
          //     direction: "column",
          //     position: { vertical: "middle", horizontal: "right" },
          //     padding: -35,
          //   },
          // }}

          slotProps={{
            legend: {
              // direction: props.direction,
              position: {
                vertical: 'middle',
                horizontal: 'right',
              },
              padding: -35,
              itemMarkWidth: 14,
              itemMarkHeight: 6,
              markGap: 6,
              itemGap: 7,
            }
          }}

          width={500}
          height={350}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
        </div>
      )}
    </>
  );
}
