//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio/Nicolas Pascucci
// Creation Date : 24/01/2024
// Version : 1
// Description : Dialog para la desaprobación con motivo y adjunto
//------Changes --------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect } from "react";
import { UrlApi } from "../../../services/apirest";
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  Box,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import moment from "moment"; // PARA LA FECHA
//adjuntado
import AttachFileIcon from "@mui/icons-material/AttachFile"; //ADJUNTAR ARCHIVOS
import FileUploadStock from "../../stock_almacen/FileUploadStock";
import { grey } from "@mui/material/colors";
import Title from "../../Title";
import { desaprobarFact } from "../savefunctions";
import Notify from "../../Notify";
import { logEventos } from "../../../services/services";

function Dialog_desaprobar(props) {
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);
  const [dialogDesaprobar, setDialogDesaprobar] = useState(
    props.dialogDesaprobar
  );
  const [openCloseAdj, setOpenCloseAdj] = useState(false); //ADJUNTAR IMAGEN
  const [datosAccion, setDatosAccion] = useState({
    accion: "",
    fecha: date,
    // uuid_accion:""
  });
  const [factDatos, setFactDatos] = useState(props.factDatos);
  const [update, setUpdate] = useState(false);
  const [desaprueba, setDesaprueba] = useState(false);
  const [newUuid, setNewUuid] = useState(props.uuid);

  // const handleClickSetearDatosDesaprobar = () => {
  //   setNewUuid(props.uuid);
  //   setDesaprueba(true);
  // };

  // useEffect(() => {
  //   if (desaprueba === true) {
  //     desaprobar();
  //     setDesaprueba(false);
  //   }
  // }, [desaprueba]);

  const observaciones = "Factibilidad Desaprobada";
  async function desaprobar(e) {
    logEventos("Desaprobar factibilidad", "Gestión Crediticia", "Desaprueba una factibilidad", localStorage.getItem("user"))
    e.preventDefault(e)
    desaprobarFact(
      e,
      "/factibilidades/desaprobar/" + factDatos.factuuid,
      setNotify,
      factDatos.mail_comercial,
      factDatos.id_fact,
      factDatos.razon_social,
      factDatos.id_direccion,
      observaciones
    );
    await fetch(UrlApi + "/acciones_fact", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_factibilidad: factDatos.id_fact,
        usuario: localStorage.getItem("user"),
        fecha: moment(new Date()).format("YYYY-MM-DD"),
        accion: datosAccion.accion,
        uuid_accion: newUuid,
      }),
    })
      .then((res) => {
        if (!res.err) {
          setUpdate(true);
        } else {
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    handleClose();
  }

  const [notify, setNotify] = useState({
    open: false,
    mensaje: "",
    severity: "",
    color: "",
  });

  const handleClose = () => {
    setDialogDesaprobar(!dialogDesaprobar);
    if (props.onClose) {
      props.onClose();
    }
  };

  const handleOpenCloseAdj = (value) => {
    setOpenCloseAdj(value);
  };

  const almacenar = (e) => {
    setDatosAccion({
      ...datosAccion,
      [e.target.name]: e.target.value,
    });
  };

  const [disabledGuardar, setDisabledGuardar] = useState(false);
  useEffect(() => {
    if (datosAccion.accion.length === 0) {
      setDisabledGuardar(true);
    } else {
      setDisabledGuardar(false);
    }
  }, [datosAccion.accion]);

  return (
    <div>
      <Dialog
        open={dialogDesaprobar}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        color="#F4F6F6"
      >
        <Title
          titulo={`Desaprobar Factibilidad`}
          handleClose={() => {
            handleClose();
          }}
        />
        <Box
          border={0.5}
          borderColor={grey[300]}
          backgroundColor="#F4F6F6"
          p={2}
          py={2}
          mt={1}
        >
          <DialogContent>
            <Container maxWidth="xl">
              <form
                autoComplete="off"
                onSubmit={desaprobar}
              >
                <Grid container mb={1} spacing={1}>
                  <Grid item xs={12} py={1} mb={1}>
                    <TextField
                      label="Motivo de Desaprobación"
                      name="accion"
                      size="small"
                      placeholder="..."
                      inputProps={{
                        maxLength: 200,
                      }}
                      focused
                      required
                      fullWidth
                      value={datosAccion.accion}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={4} py={1} mb={1}>
                    <Button
                      onClick={() => setOpenCloseAdj(!openCloseAdj)}
                      variant="outlined"
                      size="medium"
                      startIcon={<AttachFileIcon />}
                    >
                      ADJUNTAR DOCUMENTACIÓN
                    </Button>
                  </Grid>
                </Grid>
                {openCloseAdj ? ( // ADJUNTAR COMPROBANTES
                  <FileUploadStock
                    fileId={props.uuid}
                    openclose={true}
                    mostrar={true}
                    onClose={() => handleOpenCloseAdj(false)} // Proporciona una función de cierre
                  />
                ) : null}
                <Stack direction="row">
                  <Grid container direction={{ xs: "column", sm: "row" }}>
                    <Grid item xs={8}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleClose}
                      >
                        Volver
                      </Button>
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={disabledGuardar}
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              </form>
            </Container>
          </DialogContent>
        </Box>
      </Dialog>

      <Notify
        open={notify.open}
        severity={notify.severity}
        mensaje={notify.mensaje}
        color={notify.color}
      />
    </div>
  );
}

export default Dialog_desaprobar;
