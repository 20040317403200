/* eslint-disable react/prop-types */
import { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import {
  Toolbar,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Button from "@mui/material/Button";
import axios from "axios";
import { UrlApi } from "../../../services/apirest";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import moment from "moment";
import FileUploadMultipleDocu from "./UploadFilesDocu";

const DialogEditDocumento = ({
  uuid,
  onSuccess,
  handleChange,
  open,
  close,
  rowData,
}) => {
  const [openclose, setopenclose] = useState(false);

  console.log(rowData);
  const guardarDocumentacion = async (e) => {
    e.preventDefault();
    if (rowData.id_documento !== null) {
      axios
        .put(UrlApi + "/documentacion_proveedores/" + rowData.id_documento, {
          estado: rowData.estado,
          vencimiento: rowData.vencimiento
            ? moment(rowData.vencimiento).format("YYYY-MM-DD")
            : null,
          id_archivo: rowData.idarchivo,
          idclasedocumento: rowData.id,
          uuidproveedor: uuid,
        })
        .then((response) => console.log(response.data))
        .catch((error) => console.error(error));
      onSuccess();
    } else {
      fetch(UrlApi + "/documentacion_proveedores", {
        estado: rowData.estado,
        vencimiento: rowData.vencimiento
          ? moment(rowData.vencimiento).format("YYYY-MM-DD")
          : null,
        idclasedocumento: rowData.id,
        id_archivo: rowData.idarchivo,
        uuidproveedor: uuid,
      })
        .then((response) => console.log(response.data))
        .catch((error) => console.error(error));
      onSuccess();
    }
    close();
  };

  const handleOpenUpload = () => {
    setopenclose(!openclose);
  };
  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  const estado = ["PRELIMINAR", "APROBADO", "VENCIDO", "RECHAZADO"];
  return (
    <div>
      <Dialog open={open} onClose={close} maxWidth="md" fullWidth>
        <DialogTitle sx={{ flexGrow: 1, backgroundColor: "#1F618D" }}>
          <Grid container mt={1}>
            <Grid
              // item
              xs={12}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h5" color="#ffffff">
                Documentacion
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <br />
          <Grid
            container
            spacing={2}
            direction="column"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            wrap="wrap"
          >
            <Grid item xs={4}>
              <FormControl style={style} fullWidth focused>
                <InputLabel>Estado</InputLabel>
                <Select
                  label="Estado"
                  required
                  fullWidth
                  name="estado"
                  value={rowData.estado}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {estado
                    ? estado.map((elemento) => (
                        <MenuItem key={elemento} value={elemento}>
                          {elemento}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              {" "}
              <TextField
                id=""
                required
                type="date"
                label="Vencimiento"
                fullWidth
                name="vencimiento"
                onChange={handleChange}
                value={moment(rowData.vencimiento).format("YYYY-MM-DD")}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={handleOpenUpload}
                color="secondary"
                startIcon={<AttachFileIcon />}
              >
                Modificar/Ver Documento
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => close()} color="error">
            VOLVER
          </Button>
          <Button onClick={(e) => guardarDocumentacion(e)} color="success">
            GUARDAR
          </Button>
        </DialogActions>
      </Dialog>

      {openclose ? (
        <FileUploadMultipleDocu
          fileId={uuid}
          idclasedocumento={rowData.id}
          //   setActualizar={setActualizar}
          openclose={true}
        />
      ) : null}
    </div>
  );
};

export default DialogEditDocumento;
