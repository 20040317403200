import React, { useState } from 'react';
import useMqttSubscription from '../../Hooks/useMqttSubscription';
import Navbar from '../Navbar';

const MqttTest = () => {
    const [data, setData] = useState(null);

    const handleMessage = async (message) => {
        // Realizar el fetch a la API y actualizar el estado con los datos devueltos
        try {
            /*  const response = await fetch('https://api.example.com/data', {
               method: 'POST',
               body: JSON.stringify({ data: message }),
               headers: { 'Content-Type': 'application/json' },
             });
             const result = await response.json(); */
            setData(message);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const { connectionStatus } = useMqttSubscription('netizar/test', handleMessage);

    return (
        <div>
            <Navbar />
            <p>MQTT Status: {connectionStatus}</p>
            <pre>{JSON.stringify(data, null, 2)}</pre>
        </div>
    );
};

export default MqttTest;
