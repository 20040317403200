import React, { useEffect, useState, useMemo } from 'react';
import Navbar from '../../Navbar';
import { Container, Typography, Tooltip, IconButton, Box, Button,MenuItem, ListItemIcon, Divider } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import MaterialReactTable, { MRT_FullScreenToggleButton } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import Addsegmentos from './Addsegmentos';
import Editarsegmentos from './Editarsegmentos';
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Notify from '../../Notify';
import BlockIcon from '@mui/icons-material/Block';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CheckIcon from '@mui/icons-material/Check';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ConfirmDialog from '../../Dialogs/ConfirmDialog';
import { getData, putData } from '../../../services/library';
import { logEventos } from '../../../services/services';

export default function Segmentos() {
    const [segmentos, setSegmentos] = useState();
    const [alta, setAlta] = useState(false)
    const [tabla, setTabla] = useState(true)
    const [editar, setEditar] = useState(false)
    const globalTheme = useTheme();
    const [datosformulario, setDatosformulario] = useState({});
    const [hiddenmuestrainactivo, setHiddenmuestrainactivo] = useState(false);
    const [hiddenmuestraactivos, setHiddenmuestraactivos] = useState(true);
    const [confirmarActivo, setConfirmarActivo] = useState(false);
    const [confirmarInactivo, setConfirmarInactivo] = useState(false);
    const iconCheck = <CheckIcon sx={{ fontSize: 40 }} />;
    const iconRemove = <RemoveCircleOutlineIcon sx={{ fontSize: 40 }} />;
    const [notify, setNotify] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
    });

    const obtenersegmentos = () => {
        setHiddenmuestraactivos(true);
        setHiddenmuestrainactivo(false);
        getData("/segmentos", setSegmentos)
    };

    const obtenerInactivos = () => {
        setHiddenmuestrainactivo(true);
        setHiddenmuestraactivos(false);
        getData("/segmentos/inactivos", setSegmentos);
    };

    useEffect(() => {
        obtenersegmentos();
    }, [tabla]);

    //Funcion q se ejecuta cuando damos clic a nuevo:
    const handleClickNuevo = () => {
        setAlta(true);
        setTabla(false);
    };

    //Funcion q se ejecuta cuando damos clic a editar
    const mostrareditar = (datosDeLaLinea) => {
        setEditar(true);
        setTabla(false);
        setDatosformulario(datosDeLaLinea);
    };

    const handleClose = () => {
        setConfirmarActivo(false);
        setConfirmarInactivo(false);
        confirmarActivo && obtenerInactivos()
        confirmarInactivo && obtenersegmentos()
        setTabla(true)
    };

    const abrirModalConfirmarActivo = (datosDeLaLinea) => {
        setConfirmarActivo(true);
        setDatosformulario(datosDeLaLinea);
    };

    const abrirModalConfirmarInactivo = (datosDeLaLinea) => {
        setConfirmarInactivo(true);
        setDatosformulario(datosDeLaLinea);
    };

    const columns = useMemo(() =>
        [
        // { header: 'Código', accessorKey: 'codigo' },
        { header: 'Segmento', accessorKey: 'segmento' }

        ], []);

    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: globalTheme.palette.mode,
                    primary: globalTheme.palette.primary, //primary color for the table
                    info: {
                        main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            globalTheme.palette.mode === "light"
                                ? "rgb(234, 239, 241)"
                                : "#000", //pure black table in dark mode for fun
                    },
                },
                typography: {
                    button: {
                        textTransform: "none", //customize typography styles for all buttons in table by default
                        fontSize: "1rem",
                    },
                },
                components: {
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                fontSize: "0.8rem", //larger tooltip font size                                
                            },
                        },
                    },
                },

            }), [globalTheme]);

    const muiSearchTextFieldProps = {
        placeholder: "Buscar..",
        sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
        variant: "outlined",
        color: "success",
        size: "small",
    };

    const initialState = {
        density: "compact",
        showGlobalFilter: true,
        pagination: { pageIndex: 0, pageSize: 10 }
    };

    const tableHeadCellProps = {
        align: "center",
        sx: {
            fontSize: 16,
            color: "white",
            fontWeight: "normal",
            backgroundColor: "#2471A3",
            borderColor: "black",
        },
    };

    async function marcarActivo(e) {
        putData(e,
            "/segmentos/" + datosformulario.id,
            {
                activo: 1
            },
            setNotify,
            logEventos("Activar segmentos", "segmentos", "segmentos", localStorage.getItem('user'))
        )
    };

    async function marcarInactivo(e) {
        putData(e,
            "/segmentos/" + datosformulario.id,
            {
                activo: 0
            },
            setNotify,
            logEventos("Inactivar segmentos", "segmentos", "segmentos", localStorage.getItem('user'))
        )
    };

    return (
        <div>
            <Navbar />
            <Container maxWidth="xl" mt={1}>
                {segmentos && tabla ? (
                    <Box mt={2} boxShadow={3}>
                        <ThemeProvider theme={tableTheme}>
                            <MaterialReactTable
                                enableColumnResizing
                                columns={columns}
                                data={segmentos}
                                initialState={initialState}
                                positionActionsColumn="last"
                                enableExpandAll={false}
                                enableToolbarInternalActions={true}
                                muiTableHeadCellProps={tableHeadCellProps}
                                muiTableBodyCellProps={({ row }) => ({
                                    align: "left",
                                    sx: {
                                        backgroundColor:
                                            row.original.activo === 0 ? "#e39898" : "#afdbaf",
                                        color: "black",
                                        borderColor: "black",
                                    },
                                })}
                                enableColumnActions={false}
                                muiSearchTextFieldProps={muiSearchTextFieldProps}
                                enableDensityToggle={false}
                                renderTopToolbarCustomActions={() => (
                                    <>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<AddBoxTwoToneIcon />}
                                            onClick={handleClickNuevo}
                                        >
                                            Nuevo Registro
                                        </Button>
                                        <Typography variant="poster" fontSize={26}>
                                            <AssignmentIcon sx={{ size: 19 }} />
                                            Segmentos
                                        </Typography>
                                    </>
                                )}
                                layoutMode="grid"
                                localization={MRT_Localization_ES}
                                enableColumnDragging={false}
                                enableEditing={false}
                                renderToolbarInternalActions={({ table }) => (
                                    <>
                                        {!hiddenmuestraactivos ?
                                            <Tooltip arrow placement="right" title="Ver segmentos activos" >
                                                <IconButton onClick={() => {
                                                    obtenersegmentos();
                                                }} color="success">
                                                    <PersonIcon />
                                                </IconButton>
                                            </Tooltip>
                                            : null}
                                        {!hiddenmuestrainactivo ? <Tooltip arrow placement="right" title="Ver segmentos inactivos" >
                                            <IconButton color="error"
                                                onClick={() => {
                                                    obtenerInactivos();
                                                }}>
                                                <PersonOffIcon

                                                />
                                            </IconButton>
                                        </Tooltip> : null}


                                        <MRT_FullScreenToggleButton table={table} />
                                    </>
                                )}
                                renderRowActionMenuItems={({ closeMenu, row }) => (
                                    <>
                                        <MenuItem
                                            key={0}
                                            onClick={() => {
                                                mostrareditar(row.original)
                                                closeMenu()
                                            }}>
                                            <ListItemIcon>
                                                <EditIcon color="primary" />
                                            </ListItemIcon> Editar segmento
                                        </MenuItem>
                                        <Divider />
                                        {(row.original.activo === 0) ?
                                            <MenuItem
                                                key={1}
                                                onClick={() => {
                                                    abrirModalConfirmarActivo(row.original)
                                                    closeMenu()
                                                }}>
                                                <ListItemIcon>
                                                    <CheckCircleOutlineIcon color="success" />
                                                </ListItemIcon>
                                                Marcar como activo
                                            </MenuItem>
                                            :
                                            <MenuItem
                                                key={2}
                                                onClick={() => {
                                                    abrirModalConfirmarInactivo(row.original)
                                                    closeMenu()
                                                }}>
                                                <ListItemIcon>
                                                    <BlockIcon color="error" />
                                                </ListItemIcon>
                                                Marcar como inactivo
                                            </MenuItem>
                                        }
                                    </>
                                )}
                            />
                        </ThemeProvider>
                    </Box>
                ) : null}
            </Container>
            {alta ? (
                <Addsegmentos alta={setAlta} tabla={setTabla}></Addsegmentos>
            ) : null}
            {editar ? (
                <Editarsegmentos
                    editar={setEditar}
                    tabla={setTabla}
                    datos={datosformulario}
                ></Editarsegmentos>
            ) : null}

            {notify && notify.open ? (
                <Notify
                    mensaje={notify.mensaje}
                    open={notify.open}
                    color={notify.color}
                    handleClose={handleClose}
                    severity={notify.severity}
                />
            ) : null}

            {confirmarActivo && (
                <ConfirmDialog
                    title={"Activar segmento"}
                    subtitle={"¿Desea cambiar el estado del segmento como activo?"}
                    open={confirmarActivo}
                    setOpen={setConfirmarActivo}
                    notify={notify}
                    setNotify={setNotify}
                    btnText={"Aprobar"}
                    guardar={marcarActivo}
                    color={"#2ECC71"}
                    icon={iconCheck}
                // actualizar={obtenerchoferes}
                />
            )}

            {confirmarInactivo && (
                <ConfirmDialog
                    title={"Desactivar segmento"}
                    subtitle={"¿Desea cambiar el estado del segmento como inactivo?"}
                    open={confirmarInactivo}
                    setOpen={setConfirmarInactivo}
                    notify={notify}
                    setNotify={setNotify}
                    btnText={"Confirmar"}
                    guardar={marcarInactivo}
                    color={"#f53649"}
                    icon={iconRemove}
                // actualizar={obtenerinactivos}
                />
            )}
        </div >
    )

}