//----- Component --------------------------------------------------------/

// Developer : Daiana Curcio / Nicolas Pascucci

// Creation Date : 21/11/2023

// Version : 1

// Description : Es la lista de artículos que tienen las sucursales y con 4 botones con la posibilidad de : *Ingresar uno nuevo * Ver los art por recibir ,
// los enviados y el historial de todos los movimientos de ese artículo (salida,transferencia,ingreso y reubicaciones)
// Los artículos de la lista pueden ser: *Transferidos entre sucursales *Sacados de la sucursal mediante una salida *Reubicados para pasarlo entre almacenes de la
// misma sucursal *Ver los adjuntos de cada artículo.
//

//------Changes --------------------------------------------------------------------- -------------//

// Change Date :

// Change by :

// Change description:

//

// Change Date :

// Change by :

// Change description:

//
//========================================================================/
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UrlApi } from "../../services/apirest";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import {
  Button,
  Container,
  Box,
  Grid,
  Typography,
  IconButton,
  ListItemIcon,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

// ICONOS
import InputIcon from "@mui/icons-material/Input";
import OutputIcon from "@mui/icons-material/Output";
import AddBoxIcon from "@mui/icons-material/AddBox";
import HistoryIcon from "@mui/icons-material/History";
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import SettingsIcon from "@mui/icons-material/Settings";
import PrintIcon from "@mui/icons-material/Print";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import AutorenewIcon from "@mui/icons-material/Autorenew";

//Dialog de almacen
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";

// boton por enviar / recibido
import ButtonGroup from "@mui/material/ButtonGroup";
import { lightBlue, blueGrey } from "@mui/material/colors";

//adjuntado
import AttachFileIcon from "@mui/icons-material/AttachFile"; //ADJUNTAR ARCHIVOS
import FileUploadStock from "./FileUploadStock";

//ALERTA
import Badge from "@mui/material/Badge";
// import { cuentamensajes } from "../../services/services";
import { v4 as uuidv4 } from "uuid"; //ADJUNTAR IMAGEN
import Dialog_consumo from "./Dialog_consumo";

//CSV
import { ExportToCsv } from "export-to-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ImprimirPDF from "./ImprimirPDF";

import Dialog_conversion from "./Dialog_conversion";
import Dialog_ajuste_stock from "./Dialog_ajuste_stock";
import Dialog_transito_envios from "./Dialog_transito_envios";
import Dialog_transito_recibir from "./Dialog_transito_recibir";
import Dialog_reubicar from "./Dialog_reubicar";
import Dialog_salida from "./Dialog_salida";
import Dialog_tranferir from "./Dialog_tranferir";
import Dialog_ingresar from "./Dialog_ingresar";
import moment from "moment";
import { postData } from "../../services/library";
import { enviarmails } from "../../services/services";
import Title from "../Title";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Lista_articulos(props) {
  let navigate = useNavigate();
  let navigate2 = useNavigate();
  const location = useLocation();
  const [artSuc, setArtSuc] = useState();
  const [tabla, setTabla] = useState(true);
  const [tabla2, setTabla2] = useState(true);
  const [tabla3, setTabla3] = useState(true);
  const [transito, setTransito] = useState();
  const [transitoE, setTransitoE] = useState();
  const [update, setUpdate] = useState(false);
  const [abrirDialogAjusteStock, setAbrirDialogAjusteStock] = useState(false);
  const [abrirDialogConversion, setAbrirDialogConversion] = useState(false);

  const [datosArt, setDatosArt] = useState({
    id_accion: null,
    id_transaccion: null,
    id_proveedor: null,
    id_sucursal: null,
    id_articulo: null,
    almacen_origen: null,
    almacen_destino: null,
    cantidad: 0,
    cantidad_enviada: null,
    cantidad_recibida: null,
    detalle_transaccion: "",
    nro_remito_puntodeventa: "",
    nro_remito_numero: null,
    fecha_transaccion: date,
    fecha_recibido: date,
    usuario: localStorage.getItem("user"),
    id_familia: null,
    id_motivo: null,
    en_transito: null,
    cantidad_pendiente: null,
    sucursal_destino: null,
    total: null,
    alerta_minima: null,
    valor_minimo: null,
    estado_stock: null,
    detalle_confirmacion: "",
    uuid_documentacion: "",
  });


//***********************************************************************************************//
  // console.log("location", location.id);
  const [newUuid, setNewUuid] = useState(""); //ADJUNTAR COMPROBANTE
  const [openCloseAdj, setOpenCloseAdj] = useState(false); //ADJUNTAR COMPROBANTE

  const [uuid_documentacion, setUuid_documentacion] = useState(uuidv4()); //ADJUNTAR IMAGEN
  const [openCloseAdjDoc, setOpenCloseAdjDoc] = useState(false); //ADJUNTAR IMAGEN
  const [newUuidDoc, setNewUuidDoc] = useState(""); //ADJUNTAR IMAGEN

  const handleOpenCloseAdj = (value) => {
    setOpenCloseAdj(value);
  };

  const handleOpenCloseAdjDoc = (value) => {
    setOpenCloseAdjDoc(value);
  };

  // OBTENER FECHA ACTUAL
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);

  //DIALOGS
  // CREAR
  const [dialogCrearArticulo, setDialogCrearArticulo] = useState(false);
  const handleCloseDialogCrearArticulo = () => {
    setDialogCrearArticulo(false);
    setUpdate(true)
  };
  // TRANSFERENCIA
  const [dialogTransferirArticulo, setDialogTransferirArticulo] =
    useState(false);
  const handleCloseDialogTransferirArticulo = () => {
    setDialogTransferirArticulo(false);
    setUpdate(true)
  };

  //CONSUMO
  const [dialogConsumo, setDialogConsumo] = useState(false);
  const handleCloseDialogConsumo = (row) => {
    setDialogConsumo(false);
    setUpdate(true);
  };
  // SALIDA
  const [dialogEliminarArticulo, setDialogEliminarArticulo] = useState(false);
  const handleCloseDialogEliminarArticulo = () => {
    setDialogEliminarArticulo(false);
    setUpdate(true)
  };
  // REUBICACION
  const [dialogReubicarArticulo, setDialogReubicarArticulo] = useState(false);
  const handleCloseDialogReubicarArticulo = () => {
    setDialogReubicarArticulo(false);
    setUpdate(true)
  };
  //En transito
  const [abrirDialogTr, setAbrirDialogTr] = useState(false);
  const handleCloseDialogEnTransito = () => {
    setAbrirDialogTr(false);
    setUpdate(true)
  };

  //En transito enviados
  const [abrirDialogTrEnviado, setAbrirDialogTrEnviado] = useState(false);
  const handleCloseDialogEnTransitoEnviado = () => {
    setAbrirDialogTrEnviado(false);
    setUpdate(true)
  };

  //Ajuste de stock dialog
  const handleCloseAjusteStock = () => {
    setAbrirDialogAjusteStock(false);
    setUpdate(true);
  };

  //Conversion
  const handleCloseConversion = () => {
    setAbrirDialogConversion(false);
    setUpdate(true);
  };

  //*************************************************************************************************************************************/
  //funcion para obtener almacenes
  const obtenerArtxSuc = () => {
    fetch(UrlApi + "/stock_inventarios/xsucursal/" + location.state.id)
      .then((data) => data.json())
      .then((data) => setArtSuc(data));
  };

  useEffect(() => {
    obtenerArtxSuc();
  }, [tabla]);
  //*************************************************************************************************************************************/
  //CSV
  const [lista_articulosCSV, setLista_articulosCSV] = useState();

  const obtenerListaArtCSV = () => {
    fetch(
      UrlApi + "/stock_inventarios/csv_lista_articulos/" + location.state.id
    )
      .then((data) => data.json())
      .then((data) => setLista_articulosCSV(data));
  };
  // CSV
  useEffect(() => {
    obtenerListaArtCSV();
  }, [tabla]);
  //*************************************************************************************************************************************/
  // FUNCION QUE SE EJECUTA CADA VEZ QUE HAY UN CAMBIO EN LA BASE DE DATOS
  // SIRVE PARA QUE LA TABLA MOSTRADA SE ACTUALICE EN TIEMPO REAL
  useEffect(() => {
    if (update) {
      obtenerArtxSuc();
      obtenerTransito();
      obtenerTransitoEnv();
      obtenerInventarioTrResta();
      obtenerListaArtCSV();
      obtenerCuentaMensajes();
      setUpdate(false);
    }
  }, [update]);

  /*----------------------------------------------------------------------------------------------*/
  const [almacenes, setAlmacenes] = useState();

  const obtenerAlmacenes = () => {
    fetch(UrlApi + "/stock_almacenes/xsucursal/" + location.state.id)
      .then((data) => data.json())
      .then((data) => setAlmacenes(data));
  };

  useEffect(() => {
    obtenerAlmacenes();
  }, []);

  // const [almacenSeleccionado, setAlmacenSeleccionado] = useState({});
  const [almacenesActual, setAlmacenesActual] = useState();
  const obtenerAlmacenesActual = () => {
    fetch(
      UrlApi +
        "/stock_almacenes/xsucursalactual/" +
        location.state.id +
        "/" +
        datosArt.id_almacen
    )
      .then((data) => data.json())
      .then((data) => setAlmacenesActual(data));
  };
  useEffect(() => {
    obtenerAlmacenesActual();
  }, [dialogReubicarArticulo]);


  const [articulos, setArticulos] = useState();

  //funcion para obtener articulos
  const obtenerArticulos = () => {
    fetch(UrlApi + "/stock_articulos")
      .then((data) => data.json())
      .then((data) => setArticulos(data));
  };

  useEffect(() => {
    obtenerArticulos();
  }, []);

  //*************************************************************************************************************************************/
  const [proveedores, setProveedores] = useState();

  //funcion para obtener proveedores
  const obtenerProveedores = () => {
    fetch(UrlApi + "/proveedores")
      .then((data) => data.json())
      .then((data) => setProveedores(data));
  };

  useEffect(() => {
    obtenerProveedores();
  }, []);

  //*************************************************************************************************************************************/
  const [sucursales, setSucursales] = useState();
  //funcion para obtener sucursales
  const obtenerSucursales = () => {
    fetch(UrlApi + "/sucursales/actual/" + location.state.id)
      .then((data) => data.json())
      .then((data) => setSucursales(data));
  };

  useEffect(() => {
    obtenerSucursales();
  }, []);

  // ***********************************************************************************************************************************//
  const [inventarioTrResta, setInventarioTrResta] = useState([]);

  //funcion para obtener sucursales
  const obtenerInventarioTrResta = () => {
    fetch(
      UrlApi +
        "/stock_inventarios/xalmart/" +
        datosArt.id_almacen +
        "/" +
        datosArt.id_articulo
    )
      .then((data) => data.json())
      .then((data) => setInventarioTrResta(data));
  };

  useEffect(() => {
    obtenerInventarioTrResta();
  }, [datosArt.almacen_origen, datosArt.id_articulo]);

  // ************************************************************************************************************************************//
  const [motivos, setMotivos] = useState();
  //funcion para obtener sucursales
  const obtenerMotivos = () => {
    fetch(UrlApi + "/stock_motivos")
      .then((data) => data.json())
      .then((data) => setMotivos(data));
  };

  useEffect(() => {
    obtenerMotivos();
  }, []);

  //******************************************************************************************************************************************* //
  const obtenerTransito = () => {
    fetch(UrlApi + "/stock_transacciones/xsucursalr/" + location.state.id)
      .then((data) => data.json())
      .then((data) => setTransito(data));
  };

  useEffect(() => {
    obtenerTransito();
  }, [tabla2]);
  //*************************************************************************************************************************************//
  const obtenerTransitoEnv = () => {
    fetch(UrlApi + "/stock_transacciones/xsucursale/" + location.state.id)
      .then((data) => data.json())
      .then((data) => setTransitoE(data));
  };

  useEffect(() => {
    obtenerTransitoEnv();
  }, [tabla3]);

  //*******************************************************************/
  const [usuarioCompras, setUsuarioCompras] = useState([]);

  const obtenerUsuarioCompras = () => {
    fetch(UrlApi + "/userdata/sector/4/")
      .then((data) => data.json())
      .then((data) => setUsuarioCompras(data));
  };

  useEffect(() => {
    obtenerUsuarioCompras();
  }, [tabla]);


  //*************************************************************************************************************************************//
  // OBTENER FECHA ACTUAL
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);
  //*************************************************************************************************************************************************//
  // VER ALMACENES POR SUCURSAL (CREAR)
  const handleClickOpenVerArtSuc = (row) => {
    setDialogCrearArticulo(true);
    setUuid_documentacion(uuidv4());
    setNewUuidDoc(uuid_documentacion);
    datosArt.uuid_documentacion = "";
  };

  // TRANSFERIR ARTICULO
  const handleClickOpenTransferirArtSuc = (row) => {
    setDialogTransferirArticulo(true);
    setDatosArt(row);
    setUuid_documentacion(uuidv4());
    setNewUuidDoc(uuid_documentacion);
    datosArt.uuid_documentacion = "";
  };

  // CONSUMO ARTICULO
  const handleClickOpenConsumo = (row) => {
    setDialogConsumo(true);
    setDatosArt(row);
  };


  // ELIMINAR ARTICULO
  const handleClickOpenEliminarArtSuc = async (row) => {
    setDialogEliminarArticulo(true);
    setDatosArt(row);
    datosArt.cantidad_enviada = 0;
    setUuid_documentacion(uuidv4());
    setNewUuidDoc(uuid_documentacion);
    datosArt.uuid_documentacion = "";
    // setEnvioRequerimiento({
    //   id_movil: null,
    // id_sucursal: location.state.id,
    // fecha_entrega: moment(datosArt.fecha_transaccion).format(
    //   "YYYY-MM-DD HH:mm:ss"
    // ),
    // usuario_registra: localStorage.getItem('user'),
    // usuario_autoriza: null,
    // estado: 49, //cuando se carga el pedido se pone en estado "A autorizar"
    // para_comprar: 1,
    // observaciones: "",
    // uuid_requerimiento: uuid,
    // comentarios_internos: "",
    // para_compras: 0,
    // para_rrhh: 0
    // })
    // setDatosLista([{
    //   material: null,
    //   usuario: localStorage.getItem('user'),
    //   cantidad: 1,
    //   id_unidad_medida: datosArt.id_unidad_medida,
    //   uuid_requerimiento: envioRequerimiento.uuid_requerimiento,
    //   id_articulo: datosArt.id_articulo
    // }])
  };

  // REUBICAR ARTICULO
  const handleClickOpenReubicarArtSuc = async (row) => {
    setDialogReubicarArticulo(true);
    setDatosArt(row);
    datosArt.cantidad_enviada = 0;
    setUuid_documentacion(uuidv4());
    setNewUuidDoc(uuid_documentacion);
    datosArt.uuid_documentacion = "";
  };

  // CALCULO DEL TOTAL FINAL /salida
  const [cantEnviada, setCantEnviada] = useState(datosArt.cantidad_enviada);
  
  //******************************************************************************************************************** //
  //ABRIR DIALOG TRANSITO ENVIADAS
  const handleClickOpenVerTransitoEnv = (row) => {
    setAbrirDialogTrEnviado(true);
  };

  //ABRIR DIALOG TRANSITO X RECIBIR tabla
  const handleClickOpenVerTransito = () => {
    setAbrirDialogTr(true);
  };

  //ABRIR DIALOG AJUSTE DE STOCK
  const handleClickOpenVerAjuste = (row) => {
    setAbrirDialogAjusteStock(true);
    setDatosArt(row);
  };

  //ABRIR DIALOG AJUSTE DE STOCK
  const handleClickOpenVerConversion = (row) => {
    setAbrirDialogConversion(true);
    setDatosArt(row);
  };

  //*************************************************************************************************************************************/

  const columns = useMemo(
    () => [
      { header: "id_articulo", accessorKey: "id_articulo", size: 130, enableHiding: true, },
      { header: "Artículo", accessorKey: "articulo", size: 130 },
      // { header: "Descripción", accessorKey: "descripcion_articulo", size: 130 },
      {
        header: "Descripción",
        accessorKey: "descripcion_articulo",
        size: 130,
        aggregationFn: "max", //show the max age in the group (lots of pre-built aggregationFns to choose from)
        //required to render an aggregated cell
        AggregatedCell: ({ cell, table }) => (
          <>
            {" "}
            {/* {table.getColumn(cell.row.groupingColumnId ?? '').columnDef.header}:{' '} */}
            <Box sx={{ color: "black", display: "inline" }}>
              {cell.getValue()}
            </Box>
          </>
        ),
      },
      {
        header: "Cantidad Inventario",
        accessorKey: "cantidad",
        size: 110,
        aggregationFn: "sum", //show the max age in the group (lots of pre-built aggregationFns to choose from)
        //required to render an aggregated cell
        AggregatedCell: ({ cell, table }) => (
          <>
            {" "}
            {/* {table.getColumn(cell.row.groupingColumnId ?? '').columnDef.header}:{' '} */}
            <Box sx={{ color: "black", display: "inline", fontWeight: "bold" }}>
              {cell.getValue()}
            </Box>
          </>
        ),
      },
      {
        header: "Medida",
        accessorKey: "descripcion",
        size: 60,
        aggregationFn: "max", //show the max age in the group (lots of pre-built aggregationFns to choose from)
        //required to render an aggregated cell
        AggregatedCell: ({ cell, table }) => (
          <>
            {" "}
            {/* {table.getColumn(cell.row.groupingColumnId ?? '').columnDef.header}:{' '} */}
            <Box sx={{ color: "black", display: "inline" }}>
              {cell.getValue()}
            </Box>
          </>
        ),
      },
      { header: "Almacén", accessorKey: "almacen", size: 120 },
      {
        header: "Alerta Mínima",
        accessorKey: "alerta_minima",
        size: 100,
        enableHiding: true,
      },
      {
        header: "Valor Mínimo",
        accessorKey: "valor_minimo",
        size: 100,
        enableHiding: true,
      },
      {
        header: "Estado Stock",
        accessorKey: "estado_stock",
        size: 100,
        enableHiding: true,
      },
      {
        header: "uuid_articulo",
        accessorKey: "uuid_articulo",
        size: 100,
        enableHiding: true,
      },
      {
        header: "Sucursal",
        accessorKey: "id_sucursal",
        size: 120,
        enableHiding: true,
      },
      {
        header: "convertible",
        accessorKey: "convertible",
        size: 120,
        enableHiding: true,
      },
      {
        header: "convertible_a",
        accessorKey: "convertible_a",
        size: 120,
        enableHiding: true,
      },
      {
        header: "nombre_convertible",
        accessorKey: "nombre_convertible",
        size: 120,
        enableHiding: true,
      },
      {
        header: "pedido_automatico",
        accessorKey: "pedido_automatico",
        size: 120,
        enableHiding: true,
      },
    ],
    []
  );

  const columnsCSV = useMemo(
    () => [
      { header: "Artículo", accessorKey: "articulo", size: 130 },
      {
        header: "Descripción",
        accessorKey: "descripcion_articulo",
        size: 130,
      },
      {
        header: "Cantidad Inventario",
        accessorKey: "cantidad",
        size: 110,
      },
      {
        header: "Medida",
        accessorKey: "descripcion",
        size: 60,
      },
      { header: "Almacén", accessorKey: "almacen", size: 120 },
      { header: "Usuario", accessorKey: "usuario", size: 120 },
    ],
    []
  );

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "primary",
    size: "small",
  };
  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };
  //*************************************************************************************************************************************/

  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem",
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const initialStateArticulos = {
    //para inicializar la tabla, y la cantidad_enviada de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
    grouping: ["articulo"],
    columnVisibility: {
      id_articulo: false,
      id_transaccion: false,
      alerta_minima: false,
      valor_minimo: false,
      estado_stock: false,
      uuid_articulo: false,
      id_sucursal: false,
      convertible: false,
      convertible_a: false,
      nombre_convertible: false,
      pedido_automatico: false,
    },
  };

  //ALERTA
  const [cantmensajes, setCantMensajes] = useState();

  const obtenerCuentaMensajes = () => {
    fetch(
      UrlApi + "/stock_transacciones/mensajesPorRecibir/" + location.state.id
    )
      .then((data) => data.json())
      .then((data) => setCantMensajes(data));
  };

  useEffect(() => {
    obtenerCuentaMensajes();
  }, [tabla]);

  //PDF
  const [imprimirPdf, setImprimirPdf] = useState(false);
  const handleCloseDialogImprimir = () => {
    setImprimirPdf(false);
    setUpdate(true);
  };

  const componentRef = useRef();

  const handleClickOpenPdf = () => {
    setImprimirPdf(true);
  };


  // ---------------------------------------------------------------
  //CSV
  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    filename: `Lista de Artículos de la sucursal ${location.state.nombre}`,
    headers: columnsCSV.map((c) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportData = () => {
    csvExporter.generateCsv(lista_articulosCSV);
  };
  // --------------------------------------------------------------------------------------------------------------------------------- //
  return (
    <>
    {tabla? 
      <AppBar
        sx={{ position: "relative" }}
        style={{ backgroundColor: "#0979b0" }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => navigate("/sucursales_cargas")}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Artículos de la Sucursal: {location.state.nombre}
          </Typography>
          <Grid item xs={6} mr={5}>
            <Button
              type="primary"
              variant="contained"
              style={{ backgroundColor: blueGrey[400], color: "white" }}
              onClick={handleClickOpenPdf}
              size="medium"
              endIcon={<PrintIcon />}
            >
              Planilla de Inventario
            </Button>
          </Grid>
          <Button
            type="primary"
            variant="contained"
            style={{ backgroundColor: blueGrey[400], color: "white" }}
            onClick={handleExportData}
            size="medium"
            endIcon={<FileDownloadIcon />}
          >
            Exportar a CSV
          </Button>
        </Toolbar>
      </AppBar>
      : null}
      <Container maxWidth="xl" mt={1}>
        {artSuc && tabla ? (
          <Box mt={2} mb={4}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
                enableGrouping // para permitir agrupar x estado x ej
                layoutMode="grid"
                muiTableHeadCellProps={tableHeadCellProps}
                muiTableBodyCellProps={({ row }) => ({
                  align: "left",
                  sx: {
                    backgroundColor:
                      row.getValue("estado_stock") === 0
                        ? ""
                        : row.getValue("estado_stock") === 1
                        ? "rgb(255,105,97)"
                        : "rgb(253,253,150)",
                    color: "black",
                    borderColor: "black",
                  },
                })}
                enableColumnFilters={false} //desactiva filtros x  columna
                enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
                enableDensityToggle={false} //
                localization={MRT_Localization_ES} //idioma
                enableColumnDragging={false} //para arrastrar columnas
                initialState={initialStateArticulos}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                columns={columns}
                data={artSuc} //con lo q la completas (esto cambia siempre)
                enableStickyFooter
                enableRowActions
                // enableEditing  //para que se pueda editar desde tabla
                positionActionsColumn="last" // posicion de la columna de acciones al final
                // ACCIONES
                renderToolbarInternalActions={(
                  { table } // boton para nuevo , al lado del buscar
                ) => (
                  <>
                    <MRT_FullScreenToggleButton table={table} />
                  </>
                )}
                renderTopToolbarCustomActions={(row, table) => (
                  <>
                    <Typography variant="poster" fontSize={26}>
                      Lista de Artículos
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddBoxIcon color="asd" />}
                      onClick={() => {
                        handleClickOpenVerArtSuc()
                        // setTabla(false)
                      }}
                      size="small"
                    >
                      ENTRADA DE ARTÍCULOS
                    </Button>
                    <ButtonGroup
                      disableElevation
                      variant="contained"
                      aria-label="Disabled elevation buttons"
                    >
                      <Badge
                        color="secondary"
                        badgeContent={cantmensajes ? cantmensajes.length : null}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        <Button
                          style={{
                            backgroundColor: lightBlue[500],
                            color: "white",
                          }}
                          startIcon={<InputIcon color="asd"/>}
                          onClick={() => handleClickOpenVerTransito()}
                          size="small"
                        >
                          POR RECIBIR
                        </Button>
                      </Badge>
                      <Button
                        color="primary"
                        style={{
                          // backgroundColor: primary,
                          color: "white",
                        }}
                        endIcon={<OutputIcon color="asd"/>}
                        onClick={() => handleClickOpenVerTransitoEnv()}
                        size="small"
                      >
                        ENVIADOS
                      </Button>
                    </ButtonGroup>
                    <Button
                      onClick={() =>
                        navigate2("/articulos_salidas/" + location.state.id, {
                          state: location.state,
                        })
                      }
                      style={{ backgroundColor: blueGrey[700], color: "white" }}
                      variant="contained"
                      size="small"
                      endIcon={<HistoryIcon color="asd"/>}
                    >
                      HISTORIAL
                    </Button>
                  </>
                )}
                renderRowActionMenuItems={(
                  { closeMenu, row } //las acciones del costado editar y ver
                ) => (
                  <>
                    <MenuItem
                      onClick={() => {
                        handleClickOpenConsumo(row.original);
                        // setTabla(false)
                        closeMenu();
                      }}
                      color={
                        row.getValue("estado_stock") === 0
                          ? "primary"
                          : row.getValue("estado_stock") === 1
                          ? "rgb(255,105,97)"
                          : "rgb(253,253,150)"
                      }
                    >
                      <ListItemIcon>
                        <LocalMallIcon color="error" />
                      </ListItemIcon>
                      Consumo
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClickOpenTransferirArtSuc(row.original);
                        // setTabla(false)
                        closeMenu();
                      }}
                      color={
                        row.getValue("estado_stock") === 0
                          ? "primary"
                          : row.getValue("estado_stock") === 1
                          ? "rgb(255,105,97)"
                          : "rgb(253,253,150)"
                      }
                    >
                      <ListItemIcon>
                        <SwapHorizontalCircleIcon color="inherit" />
                      </ListItemIcon>
                      Transferencia
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClickOpenEliminarArtSuc(row.original);
                        // setTabla(false)
                        closeMenu();
                      }}
                      color={
                        row.getValue("estado_stock") === 0
                          ? "primary"
                          : row.getValue("estado_stock") === 1
                          ? "rgb(255,105,97)"
                          : "rgb(253,253,150)"
                      }
                    >
                      <ListItemIcon>
                        <DeleteForeverIcon color="error" />
                      </ListItemIcon>
                      Salida
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClickOpenReubicarArtSuc(row.original);
                        // setTabla(false)
                        closeMenu();
                      }}
                      color={
                        row.getValue("estado_stock") === 0
                          ? "primary"
                          : row.getValue("estado_stock") === 1
                          ? "rgb(255,105,97)"
                          : "rgb(253,253,150)"
                      }
                    >
                      <ListItemIcon>
                        <MoveDownIcon color="primary" />
                      </ListItemIcon>
                      Reubicación
                    </MenuItem>
                    {/* ADJUNTAR ARCHIVOS */}
                    <MenuItem
                      onClick={() => {
                        setNewUuid(row.original.uuid_articulo);
                        setOpenCloseAdj(!openCloseAdj);
                        closeMenu();
                      }}
                      color={
                        row.getValue("estado_stock") === 0
                          ? "primary"
                          : row.getValue("estado_stock") === 1
                          ? "rgb(255,105,97)"
                          : "rgb(253,253,150)"
                      }
                    >
                      <ListItemIcon>
                        <AttachFileIcon color="action" />
                      </ListItemIcon>
                      Ver Adjunto
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClickOpenVerAjuste(row.original);
                        // setTabla(false)
                        closeMenu();
                      }}
                      color={
                        row.getValue("estado_stock") === 0
                          ? "primary"
                          : row.getValue("estado_stock") === 1
                          ? "rgb(255,105,97)"
                          : "rgb(253,253,150)"
                      }
                    >
                      <ListItemIcon>
                        <SettingsIcon color="info" />
                      </ListItemIcon>
                      Ajuste de Stock
                    </MenuItem>
                    {row.original.convertible === 1 ? (
                      <MenuItem
                        onClick={() => {
                          handleClickOpenVerConversion(row.original);
                          // setTabla(false)
                          closeMenu();
                        }}
                        color={
                          row.getValue("estado_stock") === 0
                            ? "primary"
                            : row.getValue("estado_stock") === 1
                            ? "rgb(255,105,97)"
                            : "rgb(253,253,150)"
                        }
                      >
                        <ListItemIcon>
                          <AutorenewIcon color="primary" />
                        </ListItemIcon>
                        Conversión de Artículo
                      </MenuItem>
                    ) : null}
                  </>
                )}
              />
            </ThemeProvider>
          </Box>
        ) : null}

        {/* ------ DIALOG INGRESAR ARTICULO ------ */}
        {dialogCrearArticulo ? (          
        <Dialog_ingresar
          dialogCrearArticulo={dialogCrearArticulo}
          datosArt={datosArt}
          sucursal={location.state.id}
          usuarioCompras={usuarioCompras}
          articulos={articulos}
          proveedores={proveedores}
          almacenes={almacenes}
          newUuidDoc={newUuidDoc}
          onClose={() => {
            handleCloseDialogCrearArticulo(false)
          }}
        />
      ) : null}

        {/* ------ DIALOG TRANSFERIR ARTICULO ------ */}
        {dialogTransferirArticulo ? (
        <Dialog_tranferir
          dialogTransferirArticulo={dialogTransferirArticulo}
          datosArt={datosArt}
          usuarioCompras={usuarioCompras}
          sucursales={sucursales}
          newUuidDoc={newUuidDoc}
          onClose={() => {
            handleCloseDialogTransferirArticulo(false)
          }}
          sucursal={location.state.id}
        />
      ) : null}

        {/* ------ DIALOG ELIMINAR ARTICULO ------ */}
        {dialogEliminarArticulo ? (
        <Dialog_salida
          dialogEliminarArticulo={dialogEliminarArticulo}
          datosArt={datosArt}
          motivos={motivos}
          usuarioCompras={usuarioCompras}
          newUuidDoc={newUuidDoc}
          onClose={() => {
            handleCloseDialogEliminarArticulo(false)
          }}
          sucursal={location.state.id}
        />
      ) : null}

        {/*---------- DIALOG REUBICACION ARTICULO ------------------*/}
        {dialogReubicarArticulo ? (
        <Dialog_reubicar
          dialogReubicarArticulo={dialogReubicarArticulo}
          datosArt={datosArt}
          almacenesActual={almacenesActual}
          cantEnviada={cantEnviada}
          newUuidDoc={newUuidDoc}
          onClose={() => {
            handleCloseDialogReubicarArticulo(false)
          }}
        />
      ) : null}

        {/* ------ DIALOG EN TRANSITO X RECIBIR ------ */}
        {abrirDialogTr?
        <Dialog_transito_recibir
        abrirDialogTr={abrirDialogTr}
        datosArt={datosArt}
        tabla2={tabla2}
        transito={transito}
        sucursal={location.state.id}
        almacenes={almacenes}
        onClose={() => handleCloseDialogEnTransito(false)}
        />
        :null}

        {/* ------ DIALOG EN TRANSITO ENVIADO ------ */}
        {abrirDialogTrEnviado?
        <Dialog_transito_envios
        abrirDialogTrEnviado={abrirDialogTrEnviado}
        datosArt={datosArt}
        tabla3={tabla3}
        transitoE={transitoE}
        sucursal={location.state.id}
        onClose={() => handleCloseDialogEnTransitoEnviado(false)}
        />
        :null}
      </Container>

      {abrirDialogAjusteStock ? (
        <Dialog_ajuste_stock
        abrirDialogAjusteStock={abrirDialogAjusteStock}
        datosArt={datosArt}
        onClose={() => {
          handleCloseAjusteStock(false)
        }}
        />
        ) : null}

      {openCloseAdj ? ( // ADJUNTAR COMPROBANTES
        <FileUploadStock
          fileId={newUuid}
          openclose={true}
          mostrar={false}
          onClose={() => handleOpenCloseAdj(false)} // Proporciona una función de cierre
        />
      ) : null}

      {openCloseAdjDoc ? ( // ADJUNTAR COMPROBANTES
        <FileUploadStock
          fileId={newUuidDoc}
          openclose={true}
          mostrar={true}
          onClose={() => handleOpenCloseAdjDoc(false)} // Proporciona una función de cierre
        />
      ) : null}

      {dialogConsumo ? (
        <Dialog_consumo
          dialogConsumo={dialogConsumo}
          datosArt={datosArt}
          onClose={() => {
            handleCloseDialogConsumo(false)
          }}
          sucursal={location.state.id}
        />
      ) : null}


      {abrirDialogConversion ? (
        <Dialog_conversion
          abrirDialogConversion={abrirDialogConversion}
          datosArt={datosArt}
          almacenes={almacenes}
          usuarioCompras={usuarioCompras}
          inventarioTrResta={inventarioTrResta}
          onClose={() => {
            handleCloseConversion(false)
          }}
          sucursal={location.state.id}
        />
      ) : null}

      {imprimirPdf ? (
        <ImprimirPDF
          imprimirPdf={imprimirPdf}
          datosArt={datosArt}
          artSuc={artSuc}
          tabla={tabla}
          sucursal={location.state.nombre}
          componentRef={componentRef}
          onClose={() => handleCloseDialogImprimir(false)}
        />
      ) : null}
    </>
  );
}

export default Lista_articulos;
