/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useState, useEffect, useMemo } from "react";
import { MaterialReactTable } from "material-react-table";
import axios from "axios";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { UrlApi } from "../../../services/apirest";
import {
  MenuItem,
  ListItemIcon,
  Grid,
  Button,
  Stack,
  Skeleton,
  Box,
  Tooltip,
  IconButton,
  Container,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DialogEditDocumento from "./DialogEditDocumento";
import moment from "moment";
import Navbar from "../../Navbar";
import { useCallback } from "react";

const EstadoDocumentacion = (props) => {
  const [Data, setData] = useState([]);

  const [RowData, setRowData] = useState();
  const [open, setopen] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  const obtenerData = useCallback(() => {
    try {
      setisLoading(true);
      axios
        .get(
          UrlApi +
            "/documentacion_proveedores/" +
            props.proveedor.uuid_proveedor
        )
        .then((response) => setData(response.data));
    } catch (error) {
      console.log(error);
    } finally {
      setisLoading(false);
    }
  }, [props.proveedor.uuid_proveedor]);

  useEffect(() => {
    obtenerData();
  }, [obtenerData]);

  const columns = useMemo(
    () => [
      {
        header: "Clase Documento",
        accessorKey: "descripcion",
        enableEditing: false,
      },
      { header: "Revision", accessorKey: "revision", enableEditing: false },
      {
        header: "Vencimiento",
        accessorKey: "vencimiento",
        muiEditTextFieldProps: ({ cell, row }) => ({
          type: "date",
        }),
        accessorFn: (data) =>
          data.vencimiento
            ? moment(data.vencimiento).format("YYYY-MM-DD")
            : null,
      },

      { header: "Estado", accessorKey: "estado", enableEditing: false },
      { header: "Archivo Subido", accessorKey: "nombre", enableEditing: false },
    ],
    []
  );

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "primary",
    size: "small",
  };

  const initialState = {
    density: "compact",
    showGlobalFilter: true,
    pagination: {
      pageSize: 15,
      pageIndex: 0,
    },
  };

  const tableHeadCellProps = {
    align: "center",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };

  const handleEditar = (row) => {
    setopen(true);
    setRowData(row.original);
  };
  const handleClose = () => setopen(false);
  const handleChange = (event) => {
    setRowData({ ...RowData, [event.target.name]: event.target.value });
  };
  const handleVolver = () => {
    props.alta(false);
    props.tabla(true);
  };
  return (
    <>
      <Container maxWidth="xl">
        <Navbar />

        {isLoading && (
          <>
            <Stack spacing={1} maxHeight maxWidth alignContent={"center"}>
              <Skeleton
                variant="table"
                height={100}
                rows={5}
                columns={4}
                sx={{ border: "1px solid #ccc" }}
              />

              <Skeleton variant="rectangular" animation="wave" />
              <Skeleton variant="rectangular" animation="wave" />
              <Skeleton variant="rectangular" animation="wave" />

              <Skeleton
                variant="table"
                height={100}
                rows={5}
                columns={4}
                sx={{ border: "1px solid #ccc" }}
              />
            </Stack>{" "}
          </>
        )}
        {Data && !isLoading ? (
          <Box
            mb={3}
            backgroundColor="#F4F6F6"
            p={3}
            mt={2}
            border={0.5}
            borderColor={"#ABB2B9"}
            borderRadius={4}
          >
            <MaterialReactTable
              columns={columns}
              data={Data}
              localization={MRT_Localization_ES}
              muiSearchTextFieldProps={muiSearchTextFieldProps}
              //  displayColumnDefOptions={displayColumnDefOptions}
              enableExpandAll={false}
              muiTableHeadCellProps={tableHeadCellProps}
              // enableGlobalFilter={true}
              enableToolbarInternalActions={true}
              //enableTopToolbar={false} // habilita o deshabilita TODA la barra de herramientas de arriba
              enableColumnResizing
              layoutMode="grid"
              enableColumnActions={false}
              enableColumnFilters={false} //desactiva filtros x  columna
              enableHiding={false}
              muiTableBodyCellProps={({ row }) => ({
                align: "left",
              })}
              enableDensityToggle={false} //
              enableColumnDragging={false} //No permite que se arrastren las columnas.
              initialState={initialState}
              enableStickyFooter
              enableRowActions
              enableEditing
              positionActionsColumn="last"
              renderTopToolbarCustomActions={() => (
                <>
                  <Typography
                    variant="poster"
                    fontSize={26}
                    sx={{ marginLeft: "auto", marginRight: "auto" }}
                  >
                    Documentacion
                  </Typography>
                </>
              )}
              renderRowActions={({ closeMenu, row }) => (
                <Box sx={{ display: "flex", gap: "1rem" }}>
                  <Tooltip title="Editar" color="primary">
                    <IconButton
                      onClick={() => {
                        handleEditar(row);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            />

            <Grid
              container
              spacing={2}
              mt={2}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Grid item>
                <Button
                  onClick={handleVolver}
                  color="error"
                  variant="contained"
                >
                  Volver
                </Button>
              </Grid>
            </Grid>
          </Box>
        ) : null}
        {open ? (
          <DialogEditDocumento
            uuid={props.proveedor.uuid_proveedor}
            open={open}
            handleChange={handleChange}
            close={handleClose}
            OnSuccess={obtenerData}
            rowData={RowData}
          />
        ) : null}
      </Container>
    </>
  );
};

export default EstadoDocumentacion;
