import React, { useEffect, useState, useRef } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Button,
  Container,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Stack,
  Grid,
  TextField,
  Autocomplete,
} from "@mui/material";
import moment from "moment";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import Romaneo_repetido from "./Romaneo_repetido";

// CHECKBOX
import FormControlLabel from "@mui/material/FormControlLabel";

// SWITCH
import Switch from "@mui/material/Switch";

// Notification
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Romaneo_envases_carga_30(props) {
  const [openCrear, setOpenCrear] = useState(props.openCrear);
  const [datosRomaneoEnvases, setDatosRomaneoEnvases] = useState(
    props.datosRomaneoEnvases
  );

  const [date, setDate] = useState(props.date);
  const [firmas, setFirmas] = useState(props.firmas);
  const [descripcion_reparacion, setDescripcion_Reparacion] = useState();

  const [romaneo_tipo_envase, setRomaneo_Tipo_Envase] = useState();

  const [mat_fabricante, setMat_fabricante] = useState(props.mat_fabricante);
  const [estadoEnvase, setEstadoEnvase] = useState(props.estadoEnvase);
  const [leyendaXid, setLeyendaXid] = useState(null);

  // ------------------ PARA EL FOCUS ------------------

  const select1Ref = useRef(null); // TIPO DE REPARACION
  const autocomplete1Ref = useRef(null); // TIPO DE ENVASE
  const autocomplete2Ref = useRef(null); // FIRMA
  const select2Ref = useRef(null); // LEYENDA
  const textfield1Ref = useRef(null); // NUMERO DE ENVASE
  const autocomplete3Ref = useRef(null); // MATRICULA DE FABRICANTE
  const textfield2Ref = useRef(null); // FECHA DE FABRICACION
  const switchRef = useRef(null); // RELIEVE O PLACA
  const textfield3Ref = useRef(null); // NUMERO DE PLACA
  const buttonRef = useRef(null); // BOTON DE GUARDAR

  const [focusedElement, setFocusedElement] = useState(null);

  const focusElement = (elementRef) => {
    if (elementRef.current) {
      elementRef.current.focus();
    }
  };

  const handleFocusChange = (elementName) => {
    setFocusedElement(elementName);
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Enter" && !e.repeat) {
        e.preventDefault(); // Prevent default tab behavior
        switch (focusedElement) {
          case "select1": // Elijo cuando ocurre
            focusElement(autocomplete1Ref); // El nuevo elemento al que voy a enfocar
            setFocusedElement("autocomplete1"); // el nombre del elemento
            break;
          case "autocomplete1": // Llamo al elemento que anteriormente era el nuevo focus y repito
            focusElement(autocomplete2Ref);
            setFocusedElement("autocomplete2");
            break;
          case "autocomplete2":
            focusElement(select2Ref);
            setFocusedElement("select2");
            break;
          case "select2":
            focusElement(textfield1Ref);
            setFocusedElement("textfield1");
            break;
          case "textfield1":
            focusElement(autocomplete3Ref);
            setFocusedElement("autocomplete3");
            break;
          case "autocomplete3":
            focusElement(textfield2Ref);
            setFocusedElement("textfield2");
            break;
          case "textfield2":
            focusElement(switchRef);
            setFocusedElement("switchRef");
            break;
          case "switchRef" && checkSwitchPlaca:
            focusElement(textfield3Ref);
            setFocusedElement("textfield3");
            break;
          case "switchRef":
            if (buttonRef.current) {
              buttonRef.current.focus();
            }
            setFocusedElement("buttonRef");
            break;
          case "textfield3":
            // Enfocar el botón
            if (buttonRef.current) {
              buttonRef.current.focus();
            }
            setFocusedElement("buttonRef");
            break;
          default:
            break;
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [focusedElement]);

  // ------------------------------------------------------

  // AUTOCOMPLETE
  const [reparacionSeleccionada, setReparacionSeleccionada] = useState({});

  const [tipoEnvaseSeleccionado, setTipoEnvaseSeleccionado] = useState({});
  const [firmaSeleccionado, setFirmaSeleccionado] = useState(
    props.firmaSeleccionado
  );
  const [leyendaSeleccionado, setLeyendaSeleccionado] = useState({});

  const [matriculaSeleccionada, setMatriculaSeleccionada] = useState(
    props.matriculaSeleccionada
  );
  const [envaseSeleccionado, setEnvaseSeleccionado] = useState(
    props.envaseSeleccionado
  );

  // LIMPIAR
  const [limpiarCrear30, setLimpiarCrear30] = useState(props.limpiarCrear30);

  useEffect(() => {
    datosRomaneoEnvases.id_tipo = null;
    datosRomaneoEnvases.nro_envase = "";
    setEnvaseSeleccionado({});
  }, [limpiarCrear30]);

  const [limpiarTipoReparacion, setLimpiarTipoReparacion] = useState(
    props.limpiarTipoReparacion
  );
  const [limpiarTipoEnvase, setLimpiarTipoEnvase] = useState(
    props.limpiarTipoEnvase
  );
  const [limpiarFirma, setLimpiarFirma] = useState(props.limpiarFirma);
  const [limpiarMatricula, setLimpiarMatricula] = useState(
    props.limpiarMatricula
  );
  // CHECKS
  const [checkSwitchPlaca, setCheckSwitchPlaca] = useState(
    props.checkSwitchPlaca
  );
  const handleOnChangeSwitchPlaca = (e) => {
    setCheckSwitchPlaca(e.target.checked);
  };
  // NOTIFICACION
  const [openSnack, setOpenSnack] = useState(props.openSnack);
  const [colormensaje, setColorMensaje] = useState(props.colormensaje);
  const [mensaje, setMensaje] = useState(props.mensaje);
  //
  const [disBtn, setDisBtn] = useState(props.disBtn);

  // DIALOG PARA CREAR ENVASES (HASTA 30 KG)
  // USANDO ROW TOMO LOS DATOS DE ESA FILA
  const handleClickOpenCrear = () => {
    setOpenCrear(true);
    setCheckSwitchPlaca(false); // para apgar el switch cada vez que abre el dialog
    // AL ABRIR EL DIALOG LOS TEXTFIELDS SE VACIAN
    datosRomaneoEnvases.id_tipo_reparacion = null;
    tipoEnvaseSeleccionado.id_tipo = null;
    datosRomaneoEnvases.leyenda = null;
    firmaSeleccionado.id_firma = null;
    datosRomaneoEnvases.nro_envase = null;
    matriculaSeleccionada.id_mat_fabricante = null;
    datosRomaneoEnvases.lote = "001";
    datosRomaneoEnvases.fecha_fabricacion = "";
    datosRomaneoEnvases.placa_relieve = 1;
    datosRomaneoEnvases.nro_placa = null;
    datosRomaneoEnvases.nro_placa_rect = null;
    datosRomaneoEnvases.ley_placa_rect = null;
    datosRomaneoEnvases.nro_acuniado = null;
    datosRomaneoEnvases.id_mot_baja = null;
    datosRomaneoEnvases.fecha_reparacion = date;
    datosRomaneoEnvases.rebalanceo = null;
    datosRomaneoEnvases.id_estado = null;
  };

  // ------------------------------- REPETIDOS ------------------------------- 

  // MODAL DE REPETIDOS
  const [modalRepetido, setModalRepetido] = useState(false);

    // REPETICION: PARA VERIFICAR SI AL CARGAR SE ESTÁ REPITIENDO UN DATO

    const [repeticion, setRepeticion] = useState(null); //Guardo el que esté repetido (en caso de que exista)
    //Funcion con la que consulto a la api si ya existe un envase con X firma, X  y X nro de envase, para no cargar repetidos
  
    const obtenerRepeticion = () => {
      fetch(
        UrlApi +
          "/romaneo_envases/envases_repetidos/" +
          tipoEnvaseSeleccionado.id_tipo +
          "/" +
          firmaSeleccionado.id_firma +
          "/" +
          datosRomaneoEnvases.id_pertenencia +
          "/" +
          datosRomaneoEnvases.nro_envase
      )
        .then((data) => data.json())
        .then((data) => setRepeticion(data));
    };

    console.log("REPETICION DESDE ADETRO DEL COMP: ", repeticion)
    console.log("MODAL REP: ", modalRepetido)

      // escuchando a la repeticion, pregunto si lo que me traje de la api tiene longitud > 0
  // (o sea, si hay algo en repeticion)

  useEffect(() => {
    if (repeticion && repeticion.length > 0) {
      setModalRepetido(true);
      setDatosRomaneoEnvases({
        ...datosRomaneoEnvases,
        ["nro_envase"]: "",
      });
    }
  }, [repeticion, firmas]);
    
    // Ejecuto la funcion escuchando al proveedor seleccionado , al tipo de comprobante y al numero de comprobante
    // para que haga la busqueda cada vez que elija un proveedor (o lo cambie), y tipee un numero de comprobante
    
    useEffect(() => {
      firmas && obtenerRepeticion();
    }, [
      tipoEnvaseSeleccionado.id_tipo,
      firmaSeleccionado.id_firma,
      datosRomaneoEnvases.id_pertenencia,
      datosRomaneoEnvases.nro_envase,
    ]);

  // ------------------------------------------------------------------------

  const obtenerFirmas = () => {
    fetch(UrlApi + "/romaneo_pertenencias/agrupados")
      .then((data) => data.json())
      .then((data) => setFirmas(data));
  };

  const obtenerDescripcionReparacion = () => {
    fetch(UrlApi + "/romaneo_reparaciones")
      .then((data) => data.json())
      .then((data) => setDescripcion_Reparacion(data));
  };

  //funcion que espera los cambios de la funcion de obtener a los romaneo descripcion_reparacion
  useEffect(() => {
    obtenerDescripcionReparacion();
  }, [openCrear]);

  // --------------------

  const obtenerDescripcionTipoEnvase = () => {
    fetch(UrlApi + "/romaneo_tipos_envases/menos45")
      .then((data) => data.json())
      .then((data) => setRomaneo_Tipo_Envase(data));
  };
  useEffect(() => {
    obtenerDescripcionTipoEnvase();
  }, [openCrear]);

  const obtenerLeyendaXID = () => {
    fetch(
      UrlApi +
        "/romaneo_pertenencias/traeLeyendaxID/" +
        firmaSeleccionado.id_firma
    )
      .then((data) => data.json())
      .then((data) => setLeyendaXid(data));
  };

  useEffect(() => {
    obtenerLeyendaXID();
  }, [firmaSeleccionado.id_firma]);

  console.log("LEYENDA X ID: ", leyendaXid);
  // ------------------------------------------------------------------------

  //Funcion para guardar al crear
  const guardarCrear = async (e) => {
    console.log("Estoy por guardar");
    setDisBtn(true);
    e.preventDefault();
    await fetch(UrlApi + "/romaneo_envases", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_tipo_reparacion: datosRomaneoEnvases.id_tipo_reparacion,
        id_tipo: tipoEnvaseSeleccionado.id_tipo,
        nro_envase: datosRomaneoEnvases.nro_envase,
        id_mat_fabricante: matriculaSeleccionada.id_fabricante,
        lote: datosRomaneoEnvases.lote,
        fecha_fabricacion: datosRomaneoEnvases.fecha_fabricacion,
        placa_relieve: checkSwitchPlaca === true ? 2 : 1,
        nro_placa: datosRomaneoEnvases.nro_placa,
        id_mot_baja: datosRomaneoEnvases.id_mot_baja,
        fecha_reparacion: moment(datosRomaneoEnvases.fecha_reparacion).format(
          "YYYY-MM-DD"
        ),
        id_estado: datosRomaneoEnvases.id_estado,
        id_pertenencia: datosRomaneoEnvases.id_pertenencia,
        id_firma: firmaSeleccionado.id_firma,
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El envase se guardó exitosamente !");
          obtenerDescripcionTipoEnvase();
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el envase");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    // PARA LIMPIAR LOS DATOS
    handleClickOpenCrear();
    setLimpiarCrear30(true);
    setLimpiarMatricula(true);
    setLimpiarFirma(true);
    setLimpiarTipoReparacion(true);
    setLimpiarTipoEnvase(true);
    // OBTENGO FIRMAS AL GUARDAR (SINO SE ROMPE EL ID EN EL AUTOCOMPLETE)
    obtenerFirmas();
  };
  console.log("POST CREAR", datosRomaneoEnvases);
  console.log("POST CREAR 2", envaseSeleccionado);

  //  ------------------------------------------------------------------------

  const almacenar = (e) => {
    setDatosRomaneoEnvases({
      ...datosRomaneoEnvases,
      [e.target.name]: e.target.value,
    });
  };

  //  ------------------------------------------------------------------------

  const handleParaHabilitar = (setParaHabilitar) => {
    setParaHabilitar(true);
  };

  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
    setLimpiarCrear30(false);
    setLimpiarMatricula(false);
    setLimpiarTipoReparacion(false);
    setLimpiarTipoEnvase(false);
    setLimpiarFirma(false);
    setDisBtn(false);
  };

  //  ------------------------------------------------------------------------

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  // ------------- CAMBIO DE FOCUS AUTOMATICO AL COMPLETAR CAMPOS -----------------

  // AL ESCRIBIR EL NUMERO DE ENVASE, FOCUS A LA MATRICULA
  useEffect(() => {
    if (datosRomaneoEnvases.nro_envase.length === 9) {
      focusElement(autocomplete3Ref);
    }
  }, [datosRomaneoEnvases.nro_envase]);

  // AL ESCRIBIR LA FECHA DE FABRICACION, FOCUS AL SWITCH
  useEffect(() => {
    if (datosRomaneoEnvases.fecha_fabricacion.length === 4) {
      focusElement(switchRef);
    }
  }, [datosRomaneoEnvases.fecha_fabricacion]);

  // CUANDO SE ACTIVA EL SWITCH DE PLACA, SE HACE FOCUS AL TEXTFIELD "NUMERO DE PLACA"
  useEffect(() => {
    focusElement(textfield3Ref);
  }, [checkSwitchPlaca]);

  //
  useEffect(() => {
    if (datosRomaneoEnvases.nro_placa.length === 6) {
      focusElement(buttonRef);
    }
  }, [datosRomaneoEnvases.nro_placa]);

    // -----------------------------------------------------------------------------

    const boxStyles = {
      backgroundColor: "#EAEFF1", // Color grisaceo de fondo
      borderRadius: "5px", // Bordes redondeados
      padding: "20px", // Espacio interno
      width: "120px", // Ancho
      height: "1px" // Alto
    };
  
      // -----------------------------------------------------------------------------
  
  console.log("TIPOS DE REPARACION", descripcion_reparacion);
  console.log("LEYENDAS X ID: ", leyendaXid);
  console.log("LEYENDA SELECCIONADA: ", leyendaSeleccionado);
  console.log("ARANDA: ", datosRomaneoEnvases.id_pertenencia);
  console.log("ARANDA Version 2: ", leyendaSeleccionado.id_pertenencia);

  return (
    <>
      <DialogTitle alignSelf="center">
        <b>{"Agregar Envase (10 a 30 KG)"}</b>
      </DialogTitle>

      <DialogContent>
        <Container maxWidth="xl">
          <form onSubmit={guardarCrear}>
            <Grid container mt={2} mb={1} spacing={1}>
              <Grid item xs={5} mb={2}>
                {limpiarTipoReparacion ? (
                  <>
                    <TextField
                      value={(reparacionSeleccionada.id_reparacion = null)}
                      disabled
                      size="small"
                      label="Tipo de Reparación"
                      fullWidth
                    />
                  </>
                ) : (
                  <FormControl style={style}>
                    <InputLabel>Tipo de Reparación</InputLabel>
                    <Select
                      autoFocus // FOCUS AL INICIAR EL COMPONENTE
                      inputRef={select1Ref}
                      onFocus={() => handleFocusChange("select1")}
                      label="Tipo de reparación"
                      required
                      size="small"
                      name="id_tipo_reparacion"
                      value={datosRomaneoEnvases.id_tipo_reparacion}
                      onChange={almacenar}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {descripcion_reparacion
                        ? descripcion_reparacion.map((elemento) => (
                            <MenuItem
                              key={elemento.id_reparacion}
                              value={elemento.id_reparacion}
                            >
                              {elemento.descripcion_reparacion}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                )}
              </Grid>

              <Grid item xs={7}>
                {limpiarTipoEnvase ? (
                  <>
                    <TextField
                      value={(tipoEnvaseSeleccionado.id_tipo = null)}
                      disabled
                      size="small"
                      label="Tipo de Envase"
                      fullWidth
                    />
                  </>
                ) : (
                  <Autocomplete
                    id="autocomplete"
                    size="small"
                    disableClearable
                    noOptionsText={"Tipo de envase INEXISTENTE"}
                    options={romaneo_tipo_envase}
                    autoHighlight
                    getOptionLabel={(romaneo_tipo_envase) =>
                      romaneo_tipo_envase.id_tipo +
                      "-" +
                      romaneo_tipo_envase.descripcion_tipo_envase
                    }
                    onChange={(event, value) =>
                      setTipoEnvaseSeleccionado(value)
                    }
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        key={option.id}
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.id_tipo} - {option.descripcion_tipo_envase}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        inputRef={autocomplete1Ref}
                        onFocus={() => handleFocusChange("autocomplete1")}
                        required
                        size="small"
                        placeholder="Tipee para buscar..."
                        {...params}
                        label="Tipo de Envase"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                )}
              </Grid>

              <Grid item xs={6} mb={2}>
                {limpiarFirma ? (
                  <>
                    <TextField
                      value={(firmaSeleccionado.id_firma = null)}
                      disabled
                      size="small"
                      label="Firma"
                      fullWidth
                    />
                  </>
                ) : (
                  <Autocomplete
                    id="autocomplete2"
                    disabled={!firmas}
                    key={firmas}
                    size="small"
                    disableClearable
                    noOptionsText={"Firma INEXISTENTE"}
                    options={firmas}
                    autoHighlight
                    getOptionLabel={(firmas) =>
                      firmas.id_firma + "-" + firmas.firma
                    }
                    onChange={(event, value) => setFirmaSeleccionado(value)}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        key={option.id}
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.id_firma} - {option.firma}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        inputRef={autocomplete2Ref}
                        onFocus={() => handleFocusChange("autocomplete2")}
                        required
                        size="small"
                        placeholder="Tipee para buscar..."
                        {...params}
                        label="Firma"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                )}
              </Grid>

              <Grid item xs={6} mb={2}>
                <FormControl fullWidth>
                  <InputLabel>Leyenda *</InputLabel>
                  <Select
                    inputRef={select2Ref}
                    onFocus={() => handleFocusChange("select2")}
                    label="Leyenda *"
                    required
                    size="small"
                    name="id_pertenencia"
                    value={datosRomaneoEnvases.id_pertenencia}
                    onChange={almacenar}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {leyendaXid
                      ? leyendaXid.map((elemento) => (
                          <MenuItem
                            key={elemento.id_pertenencia}
                            value={elemento.id_pertenencia} // Debe coincidir con el tipo de dato de la tabla a la que inserta
                          >
                            {elemento.leyenda}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6} mb={2}>
                <TextField
                  inputRef={textfield1Ref}
                  onFocus={() => handleFocusChange("textfield")}
                  label="Número de Envase"
                  name="nro_envase"
                  size="small"
                  placeholder="123456789"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{
                    maxLength: 9,
                  }}
                  required
                  fullWidth
                  value={datosRomaneoEnvases.nro_envase}
                  onChange={almacenar}
                />
              </Grid>

              <Grid item xs={6}>
                {limpiarMatricula ? (
                  <>
                    <TextField
                      value={(matriculaSeleccionada.id_mat_fabricante = null)}
                      disabled
                      size="small"
                      label="Matrícula Fabricante"
                      fullWidth
                    />
                  </>
                ) : (
                  <>
                    <Autocomplete
                      id="autocomplete2"
                      disabled={!mat_fabricante}
                      size="small"
                      required
                      disableClearable
                      noOptionsText={"Matrícula Fabricante INEXISTENTE"}
                      options={mat_fabricante}
                      autoHighlight
                      getOptionLabel={(mat_fabricante) =>
                        mat_fabricante.id_fabricante +
                        "-" +
                        mat_fabricante.descripcion_fabricante
                      }
                      onChange={(event, value) =>
                        setMatriculaSeleccionada(value)
                      }
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          key={option.id}
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option.id_fabricante} -{" "}
                          {option.descripcion_fabricante}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          inputRef={autocomplete3Ref}
                          onFocus={() => handleFocusChange("autocomplete3")}
                          size="small"
                          placeholder="Tipee para buscar..."
                          {...params}
                          label="Matrícula Fabricante"
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}
                    />
                  </>
                )}
              </Grid>

              <Grid item xs={3} mb={2}>
                <TextField
                  label="Lote"
                  name="lote"
                  size="small"
                  placeholder="001"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{
                    maxLength: 3,
                  }}
                  required
                  fullWidth
                  value={datosRomaneoEnvases.lote}
                  onChange={almacenar}
                />
              </Grid>

              <Grid item xs={4} mb={2}>
                <TextField
                  inputRef={textfield2Ref}
                  onFocus={() => handleFocusChange("textfield2")}
                  label="Fecha de Fabricación"
                  name="fecha_fabricacion"
                  size="small"
                  placeholder="1234"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{
                    maxLength: 4,
                  }}
                  required
                  fullWidth
                  value={datosRomaneoEnvases.fecha_fabricacion}
                  onChange={almacenar}
                />
              </Grid>
              <Grid item xs={5} mb={2}>
                <TextField
                  fullWidth
                  style={style}
                  required
                  size="small"
                  type="date"
                  placeholder="Fecha Reparacion"
                  name="fecha_reparacion"
                  label="Fecha Reparacion"
                  variant="outlined"
                  value={moment(datosRomaneoEnvases.fecha_reparacion).format(
                    "YYYY-MM-DD"
                  )}
                  onChange={almacenar}
                />
              </Grid>

              <Grid xs={4} mb={2} mt={1}>
              <Box style={boxStyles} marginLeft={1}>
                <Stack direction="row" spacing={1} alignItems="center">
                  {checkSwitchPlaca && switchRef ? (
                    <Typography        style={{ marginLeft: "-11px", marginTop: "-10px" }}                 
                    >Relieve</Typography>
                  ) : (
                    <Typography      style={{ marginLeft: "-11px", marginTop: "-10px" }}                      
                    >
                      <b>Relieve</b>
                    </Typography>
                  )}
                  <FormControlLabel
                    inputRef={switchRef}
                    onFocus={() => handleFocusChange("switchRef")}
                    checked={checkSwitchPlaca}
                    onChange={handleOnChangeSwitchPlaca}
                    control={<Switch />}
                    style={{
                      height: "23px",
                      width: "23px",
                      marginTop: "-10px",
                      marginLeft: "-4px",
                    }}
                  />
                  {checkSwitchPlaca ? (
                    <Typography    style={{ marginLeft: "28px", marginTop: "-10px" }}>
                      <b>Placa</b>
                    </Typography>
                  ) : (
                    <Typography    style={{ marginLeft: "28px", marginTop: "-10px" }}>Placa</Typography>
                  )}
                </Stack>
                </Box>
              </Grid>

              <Grid item xs={4} mb={2}>
                {checkSwitchPlaca ? (
                  <TextField
                    inputRef={textfield3Ref}
                    onFocus={() => handleFocusChange("textfield3")}
                    label="Núm. de Placa"
                    name="nro_placa"
                    size="small"
                    placeholder="-"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    inputProps={{
                      maxLength: 6,
                    }}
                    required
                    fullWidth
                    value={datosRomaneoEnvases.nro_placa}
                    onChange={almacenar}
                  />
                ) : (
                  <TextField
                    label="Núm. de Placa"
                    name="nro_placa"
                    size="small"
                    inputProps={{
                      maxLength: 6,
                    }}
                    fullWidth
                    disabled
                    value={(datosRomaneoEnvases.nro_placa = "")}
                  />
                )}
              </Grid>

              <Grid item xs={4} mb={2}>
                <FormControl style={style}>
                  <InputLabel style={{ color: "gray" }}>Estado</InputLabel>
                  <Select
                    label="Estado"
                    required
                    disabled
                    size="small"
                    name="id_estado"
                    value={(datosRomaneoEnvases.id_estado = 151)}
                    onChange={handleParaHabilitar}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {estadoEnvase
                      ? estadoEnvase.map((elemento) => (
                          <MenuItem key={elemento.id} value={elemento.id}>
                            {elemento.valor}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Stack direction="row">
              <Grid container direction={{ xs: "column", sm: "row" }}>
                <Grid item xs={9}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={props.handleCloseCrear}
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    ref={buttonRef}
                    onFocus={() => handleFocusChange("buttonRef")}
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={disBtn}
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </Stack>
          </form>
        </Container>
      </DialogContent>

      {/* MENSAJE AL GUARDAR */}
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>

      {modalRepetido ? (
        <Romaneo_repetido
          alertaRepetido={repeticion}
          open={true}
          modalRepetido={setModalRepetido}
        />
      ) : null}
    </>
  );
}

export default Romaneo_envases_carga_30;
