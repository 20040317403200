//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio
// Creation Date : 29/10/2024
// Version : 1
// Description : pantalla para tabla de vehiculos inactivos
//------Changes --------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect } from "react";
import { UrlApi } from "../../../services/apirest";
import {
  Typography,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
} from "@mui/material";
import Navbar from "../../Navbar";
import { styled } from "@mui/system";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import SearchIcon from "@mui/icons-material/Search";

function VehiculosInactividad() {
  const [vehiculoInactivo, setVehiculoInactivo] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [selectedDate, setSelectedDate] = useState(
    dayjs().subtract(1, "month").startOf("month")
  );

  const obtenerVehiculosInactivos = () => {
    const formattedDate = selectedDate.format("YYYY"); // Formatear la fecha seleccionada
    fetch(
      UrlApi + "/checklist_chofer_flota/vehiculosInactivos/" + formattedDate
    )
      .then((data) => data.json())
      .then((data) => setVehiculoInactivo(data));
  };

  useEffect(() => {
    obtenerVehiculosInactivos();
  }, [selectedDate]); // Actualizar los datos cuando se cambie la fecha

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  //console.log("Vehiculo inactivo", vehiculoInactivo);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const todosLosMeses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  // Función para obtener el nombre del mes y el año a partir de la fecha en formato YYYY-MM
  const obtenerNombreMes = (fecha) => {
    const mes = dayjs(fecha, "YYYY-MM").format("M") - 1; // Extraer el mes (0-11)
    return todosLosMeses[mes];
  };

  const transformarDatosParaBarChart = (data) => {
    // Agrupamos los datos por patente
    const grupos = data.reduce((acc, item) => {
      const { patente, marca, modelo, sucursal, total_dias_inactivos, fecha } =
        item;
      const nombreMes = obtenerNombreMes(fecha);

      if (!acc[patente]) {
        acc[patente] = {
          marca,
          modelo,
          sucursal,
          inactividad: { [nombreMes]: total_dias_inactivos },
        };
      } else {
        acc[patente].inactividad[nombreMes] = total_dias_inactivos;
      }
      return acc;
    }, {});

    const dataset = Object.keys(grupos).map((patente) => ({
      patente,
      marca: grupos[patente].marca,
      modelo: grupos[patente].modelo,
      sucursal: grupos[patente].sucursal,
      ...grupos[patente].inactividad,
    }));

    return { dataset, meses: todosLosMeses };
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  // Filtrar los datos basados en la búsqueda
  const filteredVehiculos = vehiculoInactivo.filter(
    (vehiculo) =>
      (vehiculo.patente?.toLowerCase() || "").includes(searchQuery) ||
      (vehiculo.marca?.toLowerCase() || "").includes(searchQuery) ||
      (vehiculo.modelo?.toLowerCase() || "").includes(searchQuery) ||
      (vehiculo.sucursal?.toLowerCase() || "").includes(searchQuery)
  );

  const { dataset, meses } = transformarDatosParaBarChart(filteredVehiculos);

  const HeaderCell = styled(TableCell)({
    marginTop: "2px",
    fontSize: 16,
    color: "white",
    fontWeight: "normal",
    backgroundColor: "#2471A3",
    borderColor: "black",
    padding: "6.3px",
  });

  // *********************************************************** Tabla ********************************************************************** //

  return (
    <div>
      <Navbar />
      <Box
        bgcolor="#2B76A6"
        padding={1}
        borderRadius={0}
        color="white"
        textAlign="center"
        display="flex" // Agregado para que los elementos se coloquen en línea
        alignItems="center"
        justifyContent="space-between" // Distribuir espacio entre elementos
        marginTop={1}
        fullwidth
      >
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="h4" component="div">
              Vehiculos Inactivos {selectedDate.year()}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "6vh" }}
        >
          <Grid item sx={{ marginRight: 4 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
              <DatePicker
                views={["year"]}
                value={selectedDate}
                onChange={handleDateChange}
                slotProps={{
                  textField: {
                    size: "small",
                    sx: {
                      "& .MuiInputBase-root": {
                        color: "black", // Color del texto
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "white", // Color del borde
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black", // Color del borde al pasar el mouse
                      },
                      "& .MuiInputLabel-root": {
                        color: "white", // Color del label
                      },
                      backgroundColor: "white",
                    },
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
          {/* TextField para búsqueda */}
          <Grid item>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Buscar ..."
              value={searchQuery}
              onChange={handleSearchChange}
              sx={{
                width: "250px",
                backgroundColor: "white",
                "& .MuiInputLabel-root": {
                  color: "black",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: "gray" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <TableContainer sx={{ maxHeight: "500px", overflow: "auto" }}>
        <Table
          sx={{ maxWidth: "100%", marginTop: 3 }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow
              sx={{
                position: "sticky",
                top: 0,
                backgroundColor: "#fff",
                zIndex: 1,
                border: "1px solid black",
              }}
            >
              <HeaderCell align="center" sx={{ border: "1px solid black" }}>
                <b>Patente</b>
              </HeaderCell>
              <HeaderCell align="center" sx={{ border: "1px solid black" }}>
                <b>Marca</b>
              </HeaderCell>
              <HeaderCell align="center" sx={{ border: "1px solid black" }}>
                <b>Modelo</b>
              </HeaderCell>
              <HeaderCell
                align="center"
                sx={{
                  border: "1px solid black",
                  borderRight: "2px solid black",
                }}
              >
                <b>Sucursal</b>
              </HeaderCell>
              {meses.map((mes, index) => (
                <TableCell
                  key={index}
                  align="center"
                  style={{
                    backgroundColor: "#2B76A6",
                    color: "white",
                    border: "1px solid black",
                  }}
                >
                  {mes}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataset.map((vehiculo, index) => (
              <TableRow
                key={index}
                sx={{
                  border: "1px solid black",
                  backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#DADADA", // Alterna colores por línea
                }}
              >
                <TableCell align="center" sx={{ border: "1px solid black" }}>
                  {vehiculo.patente}
                </TableCell>
                <TableCell align="center" sx={{ border: "1px solid black" }}>
                  {vehiculo.marca}
                </TableCell>
                <TableCell align="center" sx={{ border: "1px solid black" }}>
                  {vehiculo.modelo}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    border: "1px solid black",
                    borderRight: "2px solid black",
                  }}
                >
                  {vehiculo.sucursal}
                </TableCell>
                {meses.map((mes, mesIndex) => (
                  <TableCell
                    key={mesIndex}
                    align="center"
                    sx={{ border: "1px solid black" }}
                  >
                    {vehiculo[mes] || 0}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
}

export default VehiculosInactividad;
