import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Typography, TextField,
} from "@mui/material";
import { getData } from "../../../../../../services/library";

function Impuestos(props) {
  // const [impuestos, setImpuestos] = useState(null);
  const [porcentajesPercepcion, setPorcentajesPercepcion] = useState(null);

  const almacenarPercIva = (e) => {
    props.setDataCliente({ ...props.dataCliente, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    getData("/desplegables/porcentajesPercepcion", setPorcentajesPercepcion);
  }, []);

  const size = "small";
  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  return (
    <div>
      <Container maxWidth="xl" sx={{py:1}}>
      <Box boxShadow={3} px={2} py={1} backgroundColor="#ebebeb">

      <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6" sx={{ fontSize: 16 }} color="primary">
            Impuestos Nacionales (Percepción de Iva)
          </Typography>
        </Grid>

        <Grid
            container
            spacing={1}
            direction="row"
            alignItems="flex-start"
            justifyContent="flex-start"
            py={1}
          >
            <Grid item xs={4}>
              {porcentajesPercepcion && (
                <FormControl
                  // disabled={!editarPercIva}
                  focused
                  color="primary"
                  required
                  size="small"
                  fullWidth
                >
                  <InputLabel>% Percepción</InputLabel>
                  <Select
                    style={{ backgroundColor: "#fff" }}
                    name="porc_perc_iva"
                    label=">% Percepción"
                    value={props.dataCliente.porc_perc_iva}
                    onChange={almacenarPercIva}
                  >
                    {porcentajesPercepcion
                      ? porcentajesPercepcion.map((elemento) => (
                          <MenuItem key={elemento.id} value={elemento.valor}>
                            {elemento.valor}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid item xs={4}>
              <TextField
                error={props.dataCliente.porc_exc_perc_iva > 100}
                helperText={props.dataCliente.porc_exc_perc_iva > 100 ? "El % no puede ser mayor a 100" : ""}
                style={{ backgroundColor: "#fff" }}
                size="small"
                fullWidth
                focused
                label="% Excención"
                value={props.dataCliente.porc_exc_perc_iva}
                name="porc_exc_perc_iva"
                onChange={almacenarPercIva}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                style={{ backgroundColor: "#fff" }}
                type="date"
                size="small"
                fullWidth
                focused
                label="Vto Excención"
                value={props.dataCliente.vto_exc_perc_iva}
                name="vto_exc_perc_iva"
                onChange={almacenarPercIva}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}

export default Impuestos;
