import React, { useState, useEffect, useMemo, useRef } from "react";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import {
  Container,
  Dialog,
  Box,
  Tooltip,
  Typography,
  IconButton,
} from "@mui/material";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import BlockIcon from "@mui/icons-material/Block";
import Dialog_cancelar_envio from './Dialog_cancelar_envio';
import { UrlApi } from "../../services/apirest";
import Title from "../Title";

function Dialog_transito_envios(props) {
  const [datosArt, setDatosArt] = useState(props.datosArt)
  const [abrirDialogTrEnviado, setAbrirDialogTrEnviado] = useState(props.abrirDialogTrEnviado)
  const [transitoE, setTransitoE] = useState(props.transitoE);
  const [tabla3, setTabla3] = useState(props.tabla3)
  const [transferRow, setTransferRow] = useState([]);
  const [abrirDialogTrEnvi, setAbrirDialogTrEnvi] = useState(false);

  const obtenerTransitoEnv = () => {
    fetch(UrlApi + "/stock_transacciones/xsucursale/" + props.sucursal)
      .then((data) => data.json())
      .then((data) => setTransitoE(data));
  };

  useEffect(() => {
    obtenerTransitoEnv();
  }, [tabla3]);
  const [update, setUpdate] = useState(false);
  useEffect(() => {
    if (update) {
      obtenerTransitoEnv();
      setUpdate(false);
    }
  }, [update]);

  const columns3 = useMemo(
    () => [
      {
        header: "Fecha de Envío",
        accessorKey: "fecha_transaccion",
        size: 80,
        type: "date",
        format: "YYYY-MM-DD",
      },
      { header: "Artículo", accessorKey: "nombre_articulo", size: 80 },
      { header: "Almacén de Origen", accessorKey: "nombre_origen", size: 80 },
      {
        header: "Sucursal de Destino",
        accessorKey: "nombre",
        size: 80,
      },
      { header: "Cantidad", accessorKey: "cantidad_enviada", size: 80 },
    ],
    []
  );

  const handleClose = () => {
    setAbrirDialogTrEnviado(!abrirDialogTrEnviado)
      if (props.onClose) {
          props.onClose();
        }
    };

  const handleClickOpenAlertCancelar = (row) => {
      setAbrirDialogTrEnvi(true);
      setTransferRow(row);
    };

    //En transito enviado
  const handleCloseAlertTrEnvi = () => {
      setAbrirDialogTrEnvi(false);
      setUpdate(true)
    };
 
  const initialState = {
      //para inicializar la tabla, y la cantidad_enviada de paginas q se ven ej 5
      density: "compact",
      showGlobalFilter: true,
      pagination: { pageIndex: 0, pageSize: 10 },
      sorting: [
        {
          id: "fecha_transaccion",
          desc: true,
        },
      ],
      columnVisibility: {
        id_articulo: false,
        id_transaccion: false,
        alerta_minima: false,
        valor_minimo: false,
        estado_stock: false,
        uuid_articulo: false,
        convertible_a: false,
      },
    };
  // const displayColumnDefOptions = {
  //     "mrt-row-actions": {
  //       muiTableHeadCellProps: {
  //         align: "center",
  //         sx: (theme) => ({
  //           fontSize: 14,
  //           fontStyle: "italic",
  //           background: "#4da8cf",
  //           borderRight: "1px solid rgba(224,224,224,1)",
  //           color: theme.palette.text.primary,
  //         }),
  //       },
  //       size: 100,
  //       muiTableBodyCellProps: { align: "center" },
  //     },
  //   };
  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "primary",
    size: "small",
  };
  
  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };

  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem",
              },
            },
          },
        },
      }),
    [globalTheme]
  );
  return (
    <>
    <Dialog
      open={abrirDialogTrEnviado}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xl"
    >
      <Title
            titulo={`Transferencias Enviadas`}
            handleClose={() => {
              handleClose()
            }}
          />
      <Container maxWidth="xl">
        {transitoE && tabla3 ? (
          <Box>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
                enableGrouping // para permitir agrupar x estado x ej
                layoutMode="grid"
                muiTableHeadCellProps={tableHeadCellProps}
                muiTableBodyCellProps={({ row }) => ({
                  align: "left",
                  sx: {
                    color: "black",
                    borderColor: "black",
                  },
                })}
                enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
                enableColumnFilters={false} //desactiva filtros x  columna
                enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
                enableDensityToggle={false} //
                localization={MRT_Localization_ES} //idioma
                enableColumnDragging={false} //para arrastrar columnas
                initialState={initialState}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                columns={columns3}
                data={transitoE} //con lo q la completas (esto cambia siempre)
                enableStickyFooter
                enableEditing
                positionActionsColumn="last" // posicion de la columna de acciones al final
                // ACCIONES
                renderToolbarInternalActions={(
                  { table } // boton para nuevo , al lado del buscar
                ) => (
                  <>
                    <MRT_FullScreenToggleButton table={table} />
                  </>
                )}
                renderTopToolbarCustomActions={() => (
                  <>
                    
                  </>
                )}
                renderRowActions={(
                  { row } //las acciones del costado editar y ver
                ) => (
                  <>
                    <Tooltip
                      arrow
                      placement="right"
                      title="Cancelar Transferencia"
                    >
                      <IconButton
                        onClick={() =>
                          handleClickOpenAlertCancelar(row.original)
                        }
                        color="error"
                      >
                        <BlockIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              />
            </ThemeProvider>
          </Box>
        ) : null}
      </Container>
    </Dialog>

    {abrirDialogTrEnvi? 
    <Dialog_cancelar_envio
    abrirDialogTrEnvi={abrirDialogTrEnvi}
    transferRow={transferRow}
    onClose={() => handleCloseAlertTrEnvi(false)}
    />
    :null}
    </>
  )
}

export default Dialog_transito_envios