//----- Component --------------------------------------------------------/

// Developer : Nicolas Pascucci

// Creation Date : 21/05/2024

// Version : 1

// Description : tercer paso de la evaluacion del comercial donde se ingresan los montos vencidos en el mes
//

//------Changes --------------------------------------------------------------------- -------------//

// Change Date :

// Change by :

// Change description:

//

// Change Date :

// Change by :

// Change description:

//

//========================================================================/
import React, { useEffect, useState } from "react";
import Navbar from "../../Navbar";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import AssignmentIcon from "@mui/icons-material/Assignment";
import {
  Button,
  Box,
  Grid,
  Typography,
  Stack,
  TextField,
} from "@mui/material";
import { UrlApi } from "../../../services/apirest";
import moment from "moment";
import { logEventos } from "../../../services/services";


function TercerStep(props) {
  const [fecha, setFecha] = useState(props.fecha)
  const [subitem, setSubitem] = useState(null);
  const [datosVendido, setDatosVendido] = useState({
    vencidos: props.vencidos,
  });

  const obtenerSubitems = () => {
    fetch(UrlApi + "/subitems_comerciales/xiditem/" + 4)
      .then((data) => data.json())
      .then((data) => setSubitem(data));
  };

  useEffect(() => {
    obtenerSubitems();
  }, []);

  const [subitemResultado, setSubitemResultado] = useState(null); // Agrega estado para subitemResultado

  const handleSubitemResultado = (resultado) => {
    setSubitemResultado(resultado);
  };
  // console.log("SUBITEMRESULTADO TRES", subitemResultado);
  const calcularResultado = () => {
    if (datosVendido.vencidos === 0 || datosVendido.vencidos === "0") {
      handleSubitemResultado(subitem.filter(item => item.id_subitem === 10))
      // console.log("hay adicional");
    }else{
      handleSubitemResultado(subitem.filter(item => item.id_subitem === 11))
      // console.log("no hay adicional");
    }
  };

  useEffect(() => {
    if (subitemResultado !== null) {
      props.handleNext(
        datosVendido.vencidos,
        subitemResultado?subitemResultado[0].porcentaje:null
      );
    }
  }, [subitemResultado]);
// console.log("subitem de vencidos",subitemResultado);
  const almacenar = (e) => {
    setDatosVendido({
      ...datosVendido,
      [e.target.name]: e.target.value,
    }); 
  };

  const [deshabilitado, setDeshabilitado] = useState(false)
  useEffect(() => {
    if (datosVendido.vencidos === null ) {
      setDeshabilitado(true)
    } else{
      setDeshabilitado(false)
    }
  }, [datosVendido])

  useEffect(() => {
    logEventos("Tercer paso de evaluacion", "Evaluacion Comerciales", "Se ingresan los montos vencidos en el mes", localStorage.getItem("user"))
}, [])

  return (
    <div> {props.activeStep === 3 && (
      <div style={{ marginTop: 20, alignItems: "center", display: "flex", flexDirection: "column" }}>
        <Grid container spacing={1}>
        <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Total de Monto Vencido del Mes de {moment(fecha).format("MMMM")}: 
              </Typography>
            </Grid>
          <Grid item xs={4} py={1} mb={1}>
            <TextField
              label="Monto vencido"
              name="vencidos"
              size="small"
              placeholder="..."
              onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              inputProps={{
                maxLength: 200,
              }}
              focused
              value={datosVendido.vencidos}
              onChange={almacenar}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={10}>
            <Button
            variant="contained"
              color="error"
              // disabled={activeStep === 0}
              onClick={props.handleBack}
              sx={{ mr: 1 }}
            >
              Volver
            </Button>
            </Grid>
            <Grid item xs={2}>
              <Button onClick={calcularResultado} fullWidth variant="contained" disabled={deshabilitado}>SIGUIENTE</Button>
            </Grid>
        </Grid>

      </div>
    )}    </div>
  )
}

export default TercerStep