import React, { useRef } from "react";
import { useEffect, useState } from "react";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Autocomplete,
  createFilterOptions,
  Tooltip,
  IconButton,
  styled,
  FormHelperText,
  Grid,
  Stack,
  Snackbar,
  Alert,
  useForkRef,
} from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  Delete,
  AddCircleOutline,
  DisabledByDefaultRounded,
} from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import Notify from "../../Notify";
import { getData } from "../../../services/library";

function DialogSeguimiento(props) {
  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [openSnackEditar, setOpenSnackEditar] = React.useState(false);
  const [disabledGuardarTr, setDisabledGuardarTr] = useState(false);
  const [colorTextField, setcolorTextField] = useState();
  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };
  const [notificacion, setNotificacion] = useState({
    open: false,
    mensaje: "",
    severity: "",
    color: "",
  });
  const [historial, setHistorial] = useState([]);
  // console.log("historial", historial);
  const obtenerHistorial = () => {
    getData(
      `/stock_seguimiento/historial/${encodeURIComponent(
        props.row.identificador
      )}/${props.row.articulo}`,
      setHistorial
    );
  };
  useEffect(() => {
    obtenerHistorial();
  }, []);

  return (
    <>
      <Dialog
        fullWidth
        open={props.open}
        onClose={() => props.onClose(false)}
        maxWidth="xl"
      >
        {notificacion && notificacion.open ? (
          <Notify
            mensaje={notificacion.mensaje}
            open={notificacion.open}
            color={notificacion.color}
            // handleClose={() => {
            //   props.onClose(false);
            //   // props.actualizar();
            // }}
            severity={notificacion.severity}
          />
        ) : null}
        <Box justifyContent="center" backgroundColor="#1F618D">
          <DialogTitle>
            <Typography fontSize={20} align="center" color="#ffffff">
              Historial de {props.row.nombre_articulo} -{" "}
              {props.row.identificador}
            </Typography>
          </DialogTitle>
        </Box>
        <form>
          <DialogContent>
            {historial.length > 0 ? (
              <Box display="flex" flexWrap="wrap" gap={2}>
                {historial.map((entry, index) => (
                  <Box
                    key={index}
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    p={2}
                    border="1px solid #e0e0e0"
                    borderRadius="8px"
                    boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
                    bgcolor="#ffffff"
                    minWidth="200px"
                  >
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      textAlign="center"
                    >
                      {entry.baja === 0
                        ? "Estado Actual"
                        : `Estado Anterior (${index + 1})`}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Patente:</strong> {entry.patente}
                    </Typography>
                    {entry.baja === 1 && entry.updated_at && (
                      <Typography variant="body2">
                        <strong>Fecha de Baja:</strong>{" "}
                        {new Date(entry.updated_at).toLocaleString()}
                      </Typography>
                    )}
                    <Typography variant="body2">
                      <strong>Fecha de Ingreso:</strong>{" "}
                      {new Date(entry.created_at).toLocaleString()}
                    </Typography>
                  </Box>
                ))}
              </Box>
            ) : (
              <Typography variant="body2" align="center" color="textSecondary">
                No hay datos de historial disponibles.
              </Typography>
            )}
          </DialogContent>
        </form>
      </Dialog>
    </>
  );
}

export default DialogSeguimiento;
