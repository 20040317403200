//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio
// Creation Date : 22/08/2024
// Version : 1
// Description : Tabla de cumpleaños
//------Changes -------------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useEffect, useState, useMemo } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Container,
  Typography,
  Tooltip,
  IconButton,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  DialogTitle,
} from "@mui/material";
import Navbar from "../Navbar";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import BlockIcon from "@mui/icons-material/Block";
import EditIcon from "@mui/icons-material/Edit";
import Notify from "../Notify";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { grey } from "@mui/material/colors";
import { logEventos } from "../../services/services";
import CakeIcon from "@mui/icons-material/Cake";
import PersonIcon from "@mui/icons-material/Person";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import Add_cumpleanios from "./Add_cumpleanios";
import moment from "moment";
import Editar_cumpleanios from "./Editar_cumpleanios";
import AttachFileIcon from "@mui/icons-material/AttachFile"; //ADJUNTAR IMAGEN
import FileUploadCumples from "./FileUploadCumples";

function Rrhh_cumpleanios() {
  const [cumpleanios, setCumpleanios] = useState(); // p/tabla
  const [alta, setAlta] = useState(false); //Estado q mostrará o no la pantalla de alta
  const [tabla, setTabla] = useState(true);
  const [editar, setEditar] = useState(false); //Estado q mostrará o no el form de edicion de choferes
  const [confirmar, setConfirmar] = useState(false);
  const [datosCumpleanios, setDatosCumpleanios] = useState({});
  const globalTheme = useTheme();
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
  const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
  const [hiddenmuestrainactivo, setHiddenmuestrainactivo] = useState(false);
  const [hiddenmuestraactivos, setHiddenmuestraactivos] = useState(true);
  const [obt, setObt] = useState(0); // es para actualizar,en el editar actualizar={setObt} y  props.actualizar(1)
  const [openCloseAdj, setOpenCloseAdj] = useState(false); //ADJUNTAR IMAGEN

  //adjunto
  const handleOpenCloseAdj = (value) => {
    setOpenCloseAdj(value);
  };
  useEffect(() => {
    logEventos(
      "Acceso al menú cumpleaños",
      "Cumpleaños",
      "Agregar nuevo registro,editarlo y activarlo o inactivarlo",
      localStorage.getItem("user")
    );
  }, []);
  //*************************************************************************************************************************************/
  const obtenerActivos = () => {
    setHiddenmuestraactivos(true);
    setHiddenmuestrainactivo(false);
    fetch(UrlApi + "/rrhh_cumpleanios/activos")
      .then((data) => data.json())
      .then((data) => setCumpleanios(data));
  };
  const obtenerinactivos = () => {
    setHiddenmuestrainactivo(true);
    setHiddenmuestraactivos(false);
    fetch(UrlApi + "/rrhh_cumpleanios/inactivos")
      .then((data) => data.json())
      .then((data) => setCumpleanios(data));
  };
  //console.log("CUMPLES",cumpleanios);
  //ejecuta la funcion de obtener
  useEffect(() => {
    obtenerActivos();
  }, [tabla, obt, confirmar]);
  //*************************************************************************************************************************************/
  const [cumple, setCumple] = useState();

  const obtenerCumpleHoy = () => {
    fetch(UrlApi + "/rrhh_cumpleanios/cumple")
      .then((data) => data.json())
      .then((data) => setCumple(data));
  };
  useEffect(() => {
    obtenerCumpleHoy();
  }, [tabla, obt]);
  //console.log("CUMPLE HOY", cumple);

  //*************************************************************************************************************************************/
  const handleClickNuevo = () => {
    setAlta(true);
  };
  const mostrareditar = (datosDeLaLinea) => {
    setEditar(true);
    setDatosCumpleanios(datosDeLaLinea);
    //console.log("datos linea", datosDeLaLinea ? datosDeLaLinea : "NO");
  };
  const handleVolver = () => {
    setConfirmar(false);
    setTabla(true);
  };
  const abrirModalConfirmar = (datosDeLaLinea) => {
    setConfirmar(true);
    setDatosCumpleanios(datosDeLaLinea);
  };
  const handleCloseAdd = () => {
    setAlta(false);
    obtenerActivos();
  };
  const handleCloseEditar = () => {
    setEditar(false);
    obtenerActivos();
  };
  //*************************************************************************************************************************************/
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)" //background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
            },
          },
        },
      }),
    [globalTheme]
  );
  const columns = useMemo(
    () => [
      {
        header: "Apellido y Nombre",
        accessorKey: "nombre",
        size: 150,
        sortDescFirst: true,
      },
      {
        header: "Nacimiento",
        accessorFn: (nacimiento) =>
          moment.utc(nacimiento.nacimiento).format("DD-MM-YYYY"),
        size: 80,
      },
      {
        header: "Email",
        accessorKey: "email",
        size: 110,
      },
      {
        header: "Sucursal",
        accessorKey: "nombre_sucursal",
        size: 110,
      },
      {
        header: "Función",
        accessorKey: "funcion",
        size: 110,
      },
    ],
    []
  );
  const initialState = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
    sorting: [{ id: "nombre", desc: false }],
  };
  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };
  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "success",
    size: "small",
  };
  //*************************************************************************************************************************************/
  const marcarInactivo = async (e) => {
    logEventos(
      "Guardar registro inactivo",
      "Cumpleaños",
      "Se guardo registro inactivo",
      localStorage.getItem("user")
    );
    e.preventDefault();
    const response = await fetch(
      UrlApi + "/rrhh_cumpleanios/" + datosCumpleanios.id,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(
          datosCumpleanios.estado === 0 ? { estado: 1 } : { estado: 0 }
        ),
      }
    );
    if (response.ok === false) {
      const error = response && response.json();
      setError(error);
      alert("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      try {
        const json = await response.json();
        if (json.message) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje(
            json.message === "OK" ? "Guardado" : "Guardado. Actualizar RTA Api."
          );
          obtenerActivos();
        } else if (json.sqlMessage) {
          json && setError(json);
          alert("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
        } else {
          alert("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje("Guardado");
          obtenerActivos();
        } else {
          alert("Error: " + error);
        }
      }
    }
    handleVolver();
    obtenerActivos();
  };

  return (
    <div>
      <Navbar />
      <Container maxWidth="xl" mt={1} mb={2}>
        {cumpleanios && tabla ? (
          <Box mt={2} boxShadow={3}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
                enableColumnResizing
                columns={columns}
                data={cumpleanios}
                initialState={initialState}
                positionActionsColumn="last"
                enableExpandAll={false}
                enableToolbarInternalActions={true}
                muiTableHeadCellProps={tableHeadCellProps}
                muiTableBodyCellProps={({ row }) => ({
                  align: "left",
                  sx: {
                    backgroundColor:
                      row.original.estado === 0 ? "#efc3c3" : "#b3e6b3",
                    color: "black",
                    borderColor: "black",
                  },
                })}
                enableColumnActions={true}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                enableDensityToggle={false}
                enableGrouping
                renderTopToolbarCustomActions={() => (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddBoxTwoToneIcon />}
                      onClick={handleClickNuevo}
                    >
                      Agendar Nuevo
                    </Button>
                    <Button
                      onClick={() => setOpenCloseAdj(!openCloseAdj)}
                      variant="outlined"
                      size="medium"
                      startIcon={<AttachFileIcon />}
                    >
                      Configuración
                    </Button>
                    <Typography variant="poster" fontSize={26}>
                      <CakeIcon sx={{ size: 20 }} />
                      Lista de cumpleaños
                    </Typography>
                  </>
                )}
                layoutMode="grid"
                localization={MRT_Localization_ES}
                enableColumnDragging={false} //No permite que se arrastren las columnas.
                enableEditing
                // ACCIONES
                renderToolbarInternalActions={(
                  { table } // boton para nuevo , al lado del buscar
                ) => (
                  <>
                    {!hiddenmuestraactivos ? (
                      <Tooltip title="Ver personas activas">
                        <IconButton
                          color="success"
                          onClick={() => {
                            obtenerActivos();
                          }}
                        >
                          <PersonIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    {!hiddenmuestrainactivo ? (
                      <Tooltip title="Ver personas inactivas">
                        <IconButton
                          color="error"
                          onClick={() => {
                            obtenerinactivos();
                          }}
                        >
                          <PersonOffIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    <MRT_FullScreenToggleButton table={table} />
                  </>
                )}
                renderRowActions={(
                  { row } //las acciones del costado editar y ver
                ) => (
                  <div>
                    <Tooltip arrow placement="left" title="Editar Registro">
                      <IconButton
                        onClick={() => mostrareditar(row.original)}
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    {row.original.estado === 0 ? (
                      <Tooltip title="Marcar como activo">
                        <IconButton
                          color="success"
                          onClick={() => abrirModalConfirmar(row.original)}
                        >
                          <CheckCircleOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Marcar como inactivo">
                        <IconButton
                          onClick={() => abrirModalConfirmar(row.original)}
                          color="error"
                        >
                          <BlockIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                )}
              />
            </ThemeProvider>
            <br />
            <br />
          </Box>
        ) : null}

        {confirmar ? (
          <div>
            <Dialog
              open={confirmar}
              onClose={handleVolver}
              maxWidth="xs"
              fullWidth
            >
              <Box mt={1} backgroundColor={"#1F618D"}>
                <DialogTitle>
                  {datosCumpleanios.estado === 0 ? (
                    <Typography variant="h6" align="center" color="#ffffff">
                      Activar el registro : {datosCumpleanios.nombre}
                    </Typography>
                  ) : (
                    <Typography variant="h6" align="center" color="#ffffff">
                      Desactivar el registro : {datosCumpleanios.nombre}
                    </Typography>
                  )}
                </DialogTitle>
              </Box>
              <form onSubmit={marcarInactivo}>
                <Box
                  border={0.5}
                  borderColor={grey[300]}
                  backgroundColor="#F4F6F6"
                  p={2}
                  py={2}
                  mt={1}
                >
                  <DialogContent>
                    <DialogContentText textAlign={"center"}>
                      {datosCumpleanios.estado === 0 ? (
                        <>
                          {" "}
                          <b>¿Desea activar el registro?</b>{" "}
                        </>
                      ) : (
                        <>
                          {" "}
                          <b>¿Desea desactivar el registro?</b>{" "}
                        </>
                      )}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItemns="center"
                    >
                      <Button
                        variant="contained"
                        onClick={handleVolver}
                        style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
                      >
                        Volver
                      </Button>
                      <Button
                        style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Confirmar
                      </Button>
                    </Grid>
                  </DialogActions>
                </Box>
              </form>
            </Dialog>
          </div>
        ) : null}

        {notificacion ? (
          <Notify
            mensaje={mensaje}
            open={openSnack}
            color={"#D4EFDF"}
            severity={"success"}
            handleClose={handleVolver}
          />
        ) : null}
        <Dialog open={openCloseAdj} onClose={() => handleOpenCloseAdj(false)} fullWidth maxWidth="sm">
          <FileUploadCumples open={openCloseAdj} handleClose={() => handleOpenCloseAdj(false)} />
        </Dialog>
        {alta ? (
          <Add_cumpleanios
            alta={alta}
            tabla={setTabla}
            onClose={handleCloseAdd}
          ></Add_cumpleanios>
        ) : null}
        {editar ? (
          <Editar_cumpleanios
            editar={editar}
            tabla={setTabla}
            datos={datosCumpleanios}
            onClose={handleCloseEditar}
          ></Editar_cumpleanios>
        ) : null}
      </Container>
    </div>
  );
}

export default Rrhh_cumpleanios;
