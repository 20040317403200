import { UrlApi } from "../../../../services/apirest";
import React, { useState, useEffect } from "react";
import { Grid, TextField, Select, FormControl, MenuItem } from "@mui/material";

function Compuestos(props) {
  const [itemsPropelenteCompEF, setItemsPropelenteCompEF] = useState(
    props.itemsPropelenteCompEF
  );
  const [cosas, setCosas] = useState();

  const obtenerCosas = () => {
    fetch(UrlApi + "/desplegables/modulo/propelenteComp")
      .then((data) => data.json())
      .then((data) => setCosas(data));
    console.log(UrlApi + "/desplegables/modulo/propelenteComp");
  };

  useEffect(() => {
    obtenerCosas();
  }, []);

  // const label = { inputProps: { "aria-label": "Checkbox demo" } };

  return (
    <>
      <Grid container spacing={1} mt={-4} direction={{ xs: "column", sm: "row" }}>
        <Grid item xs={2.2} mt={1} ml={12}>
          <b>ANÁLISIS</b>
        </Grid>
        <Grid item xs={1.2} mt={1} ml={4}>
          <b>ND</b>
        </Grid>
        <Grid item xs={1.3} mt={1}>
          <b>{"<"}</b>
        </Grid>
        <Grid item xs={2} mt={1}>
          <b>CANTIDAD</b>
        </Grid>
        <Grid item xs={1.9} mt={1}>
          <b>METODO</b>
        </Grid>
        <Grid item xs={1} mt={1}>
          <b>VISIBLE</b>
        </Grid>
      </Grid>
      {itemsPropelenteCompEF
        ? itemsPropelenteCompEF.map((compuesto, index) => (
            <>
              <Grid
                container
                spacing={1}
                direction={{ xs: "column", sm: "row" }}
              >
                <Grid item xs={2.1} mt={1}  ml={12}>
                  <TextField
                    size="small"
                    fullWidth
                    name="nombre_compuesto"
                    value={compuesto.nombre_compuesto}
                    disabled
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                        background: "#EBEFF1"
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={0.8} mt={1}  ml={4}>
                {/* <Checkbox {...label} defaultChecked /> */}

                  <input
                    type="checkbox"
                    // checked={habilitarHumedad}
                    id="toggle-switch-2"
                    label="#2"
                    style={{
                      width: "23px",
                      height: "23px",
                      marginTop: "8px",
                      marginLeft: "8px",
                    }}
                    onChange={(e) => {
                      localStorage.setItem(
                        "toggle-switch-2",
                        `${e.target.checked}`
                      );
                      // setHabilitarHumedad(e.target.checked);
                      console.log(e.target.checked);
                    }}
                  />{" "}

                </Grid>
                <Grid item xs={1.7} mt={1} >
                  <FormControl fullwidth size="small">
                    <Select
                      name="signo"
                      style={{ width: 110 }}
                      InputLabelProps={{ shrink: true }}
                    >
                      {cosas
                        ? cosas.map((cosa) => (
                            <MenuItem key={cosa.id} value={cosa.id}>
                              {cosa.valor}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2} mt={1} >
                  <TextField
                    variant="outlined"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    inputProps={{
                      maxLength: 6,
                    }}
                    style={{
                      width: 85,
                      marginRight: 10,
                      verticalAlign: 2,
                    }}
                    size="small"
                    focused
                    onChange={(e) => {
                      props.updateItemsCompuestos("cantidad", e, index);
                    }}
                    value={
                      props.compuestos &&
                      props.compuestos[index] &&
                      props.compuestos[index].cantidad
                    }
                  />
                  ppm
                </Grid>

                {/* <Grid item xs={1.7} mt={1} mb={1} mr={4}>
                  <TextField
                    size="small"
                    fullWidth
                    name="nombre_compuesto"
                    value={compuesto.nombre_metodo}
                    disabled
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      },
                    }}
                  />
                </Grid> */}

                {/* METODO */}
                <Grid item xs={1.7} mt={1}  mr={4}>
                  <TextField
                    size="small"
                    fullWidth
                    name="nombre_compuesto"
                    value={compuesto.nombre_metodo}
                    disabled
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={0.5} mt={1}  ml={1}>
                  {/* <Checkbox {...label} defaultChecked /> */}

                  <input
                    type="checkbox"
                    // checked={habilitarHumedad}
                    id="toggle-switch-2"
                    label="#2"
                    style={{
                      width: "23px",
                      height: "23px",
                      marginTop: "8px",
                      marginLeft: "8px",
                    }}
                    onChange={(e) => {
                      localStorage.setItem(
                        "toggle-switch-2",
                        `${e.target.checked}`
                      );
                      // setHabilitarHumedad(e.target.checked);
                      console.log(e.target.checked);
                    }}
                  />{" "}

                </Grid>
              </Grid>
            </>
          ))
        : null}
    </>
  );
}

export default Compuestos;
