import React, { useState, useEffect, useMemo, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UrlApi } from "../../../services/apirest";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import {
  Button,
  Container,
  Box,
  Grid,
  Typography,
  IconButton,
  ListItemIcon,
  Tooltip,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

// ICONOS
import HistoryIcon from "@mui/icons-material/History";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
//Dialog de almacen
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";

// boton por enviar / recibido
import ButtonGroup from "@mui/material/ButtonGroup";
import { lightBlue, blueGrey } from "@mui/material/colors";

//adjuntado
import AttachFileIcon from "@mui/icons-material/AttachFile"; //ADJUNTAR ARCHIVOS

//ALERTA
import Badge from "@mui/material/Badge";
// import { cuentamensajes } from "../../services/services";
import { v4 as uuidv4 } from "uuid"; //ADJUNTAR IMAGEN

//CSV
import { ExportToCsv } from "export-to-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import moment from "moment";
import { getData } from "../../../services/library";
import AsignarAVehiculo from "./AsignarAVehiculo";
import BajarStockVehiculo from "./BajarStockVehiculo";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function HistorialVehiculo() {
  let navigate = useNavigate();
  const location = useLocation();
  const [historial, setHistorial] = useState();
  const [tabla, setTabla] = useState(true);
  const [asignar, setAsignar] = useState(false);
  const [artSuc, setArtSuc] = useState();
  const [bajaStock, setBajaStock] = useState(false);
  const [row, setRow] = useState();
  // console.log("props", location.state);

  const obtenerHistorial = () => {
    getData(
      "/stock_vehiculo_historial/xvehiculo/" + location.state.idvehiculo,
      setHistorial
    );
  };

  useEffect(() => {
    obtenerHistorial();
  }, [tabla]);
  const columns = useMemo(
    () => [
      { header: "Articulo", accessorKey: "nombre_articulo", size: 95 },
      { header: "Cantidad", accessorKey: "cantidad", size: 100 },
      { header: "Tipo", accessorKey: "tipo", size: 100 },
      { header: "Sucursal", accessorKey: "nombre_sucursal", size: 100 },
      { header: "Fecha", accessorKey: "fecha", size: 100 },
      { header: "Detalle", accessorKey: "detalle", size: 100 },
      { header: "N° Artículo", accessorKey: "identificador", size: 100 },
    ],
    []
  );

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "primary",
    size: "small",
  };
  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };
  //*************************************************************************************************************************************//
  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem",
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const initialState = {
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
    sorting: [
      {
        id: "fecha",
        desc: true,
      },
    ],
  };
  const volverAtras = () => {
    navigate(-1);
  };
  return (
    <>
      {tabla ? (
        <AppBar
          sx={{ position: "relative" }}
          style={{ backgroundColor: "#0979b0" }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={volverAtras}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Historial Del Vehiculo {location.state.patente}
            </Typography>
          </Toolbar>
        </AppBar>
      ) : null}
      <Container maxWidth="xl" mt={1}>
        {historial && tabla ? (
          <Box mt={2} mb={4}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
                enableGrouping // para permitir agrupar x estado x ej
                layoutMode="grid"
                muiTableHeadCellProps={tableHeadCellProps}
                muiTableBodyCellProps={({ row }) => ({
                  align: "left",
                })}
                enableColumnFilters={false} //desactiva filtros x  columna
                enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
                enableDensityToggle={false} //
                localization={MRT_Localization_ES} //idioma
                enableColumnDragging={false} //para arrastrar columnas
                initialState={initialState}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                columns={columns}
                data={historial} //con lo q la completas (esto cambia siempre)
                enableStickyFooter
                // enableRowActions
                // enableEditing  //para que se pueda editar desde tabla
                positionActionsColumn="last" // posicion de la columna de acciones al final
                // ACCIONES
                renderToolbarInternalActions={(
                  { table } // boton para nuevo , al lado del buscar
                ) => (
                  <>
                    <MRT_FullScreenToggleButton table={table} />
                  </>
                )}
                renderTopToolbarCustomActions={(row, table) => (
                  <>
                    <Typography variant="poster" fontSize={26}>
                      Historial
                    </Typography>
                  </>
                )}
              />
            </ThemeProvider>
          </Box>
        ) : null}
      </Container>
    </>
  );
}

export default HistorialVehiculo;
