import React, { useEffect, useState, useMemo } from "react";
import { getData } from "../../../../../services/library";
import Container from "@mui/material/Container";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import MaterialReactTable from "material-react-table";
import DetailPanel from "./DetailPanel";

function ReclamosCli(props) {
  const [reclamos, setReclamos] = useState(null);

  const [showTable, setShowTable] = useState(true);
  // console.log(props.cliente)

  useEffect(() => {
    getData("/reclamos/cliente/" + props.cliente.id, setReclamos);
  }, []);

  // TABLA:
  const columns = useMemo(
    () => [
      {
        accessorKey: "id_reclamo", //access nested data with dot notation
        header: "N°",
        maxSize: 7,
        // size: 200,
      },
      {
        accessorKey: "descripcion_motivo", //access nested data with dot notation
        header: "Motivo",
        minSize: 200,
      },
      {
        accessorKey: "fecha_abierto", //access nested data with dot notation
        header: "Fecha Apertura",
        // size: 200,
        minSize: 200,
      },
      {
        accessorKey: "descr_estado", //access nested data with dot notation
        header: "Estado",
        // size: 200,
        minSize: 200,
      },

      //   {
      //     accessorKey: "fecha_abierto", //access nested data with dot notation
      //     header: "Fecha Apertura",
      //     // size: 200,
      //   },
    ],
    []
  );

  const displayColumnDefOptions = {
    "mrt-row-actions": {
      muiTableHeadCellProps: {
        sx: {
          fontSize: 14,
          color: "black",
          fontWeight: "normal",
          paddingTop: 1,
          paddingBottom: 1,
          backgroundColor: "#D4E6F1",
          borderColor: "#FDFEFE",
        },
      },
      size: 60,
    },
  };

  const initialState = {
    density: "compact",
    showGlobalFilter: true,
    pagination: {
      pageSize: 8,
      pageIndex: 0,
    },
  };

  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 14,
      color: "black",
      fontWeight: "normal",
      //   paddingTop: 1,
      //   paddingBottom: 1,
      backgroundColor: "#D4E6F1",
      borderColor: "#FDFEFE",
    },
  };

  //   console.log(reclamos)
  return (
    <div>
      {showTable && reclamos && (
        <Container maxWidth="xl" disableGutters>
          <MaterialReactTable
          
            localization={MRT_Localization_ES}
            enableTopToolbar={false}
            columns={columns}
            data={reclamos}
            enableColumnFilterModes={false}
            enableColumnOrdering={false}
            enableGrouping={false}
            enablePinning
            initialState={initialState}
            enableRowSelection={false}
            positionActionsColumn="last"
            enableHiding={false}
            muiTableHeadCellProps={tableHeadCellProps}
            positionExpandColumn="first"
            // muiTableBodyRowProps={{
            //   onClick: () => {
            //     //   alert('row click');
            //   },
            // }}
            renderDetailPanel={({ row }) => (
              <DetailPanel reclamo={row.original} />
              // <>hoola como va</>
            )}

            enableExpandAll={false}
            muiTableBodyCellProps={({ row }) => ({
              align: "left",
              sx: {
                backgroundColor: "#ffffff",
                color: "black",
                borderColor: "#FDFEFE",
              },
            })}
            enableColumnActions={false}
            enableColumnFilters={false} //Quita filtros por columna
            displayColumnDefOptions={displayColumnDefOptions}
            enableDensityToggle={false}
            // renderRowActionMenuItems={({ closeMenu, row }) => [
            //   <MenuItem
            //     key={0}
            //     onClick={() => {
            //       closeMenu();
            //       setDireccion(row.original);
            //       setEditar(true);
            //     }}
            //     sx={{ m: 0 }}
            //   >
            //     <ListItemIcon>
            //       <BorderColorIcon color="primary" />
            //     </ListItemIcon>
            //     Editar
            //   </MenuItem>,

            //   <MenuItem
            //     key={1}
            //     onClick={() => {
            //       closeMenu();
            //       setDireccion(row.original);
            //       setBaja(true);
            //     }}
            //     sx={{ m: 0 }}
            //   >
            //     <ListItemIcon>
            //       <Delete color="error" />
            //     </ListItemIcon>
            //     Eliminar
            //   </MenuItem>,

            //   <Divider light />,
            //   <MenuItem
            //     key={1}
            //     onClick={() => {
            //       closeMenu();
            //       setAñadir(true);
            //       setDireccion(row.original);
            //     }}
            //     sx={{ m: 0 }}
            //   >
            //     <ListItemIcon>
            //       <PropaneIcon color="success" />
            //     </ListItemIcon>
            //     Añadir Tanque
            //   </MenuItem>,
            //   <MenuItem
            //     key={1}
            //     onClick={() => {
            //       closeMenu();
            //       setDireccion(row.original);
            //       setVerTanques(true);
            //     }}
            //     sx={{ m: 0 }}
            //   >
            //     <ListItemIcon>
            //       <MiscellaneousServicesIcon color="secondary" />
            //     </ListItemIcon>
            //     Administrar Tanques En Domicilio
            //   </MenuItem>,
            //   <MenuItem
            //     key={1}
            //     onClick={() => {
            //       closeMenu();
            //       setDireccion(row.original);
            //       setDialogCarpTec(true)
            //     }}
            //     sx={{ m: 0 }}
            //   >
            //     <ListItemIcon>
            //       <FolderSpecialIcon color="primary" />
            //     </ListItemIcon>
            //     Carpeta Técnica
            //   </MenuItem>,
            // ]}
          />
        </Container>
      )}
    </div>
  );
}

export default ReclamosCli;
