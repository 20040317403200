import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import MapaDirecciones from "./MapaDirecciones";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -48%)",
  width: "650px",
  height: "515px",
  bgcolor: "#fff",
  border: "0.5px solid #000",
  boxShadow: 24,
};

function ModalMapa(props) {
  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => props.setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ zIndex: 2 }}
      >
        <Box sx={{ ...style2 }}>
          <Box
            backgroundColor="#1F618D"
            justifyContent="center"
            alignItems="center"
            p={1}
          >
            <Typography fontSize={16} variant="caption" color="#ffffff">
              Ubicación de los pedidos
            </Typography>
          </Box>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <MapaDirecciones
              nro_reparto={props.datos.nro_reparto}
              idsucursal={props.datos.id_sucursal}
              handleClose={() => props.setOpen(false)}
            />
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}

export default ModalMapa