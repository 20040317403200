//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio/Nicolas Pascucci
// Creation Date : 23/01/2024
// Version : 1
// Description : Agregar nueva acción
//------Changes ------------------------------------------------------------//
// Change Date :
// Change by :
// Change description: Agregue deshabilitar el botón con descripción vacia
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useEffect, useState } from "react";
import { UrlApi } from "../../../../services/apirest";
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  Box,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import moment from "moment"; // PARA LA FECHA
//adjuntado
import AttachFileIcon from "@mui/icons-material/AttachFile"; //ADJUNTAR ARCHIVOS
//enviar mails
import { grey } from "@mui/material/colors";
import Title from "../../../Title";
import FileUploadStock from "../../../stock_almacen/FileUploadStock";
import { logEventos } from "../../../../services/services";

function AddAccion(props) {
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);

  const [dialogNuevaAccion, setDialogNuevaAccion] = useState(
    props.dialogNuevaAccion
  );
  const [openCloseAdj, setOpenCloseAdj] = useState(false); //ADJUNTAR IMAGEN
  const [datosAccion, setDatosAccion] = useState({
    accion: "",
    fecha: date,
    // uuid_accion:""
  });

  const guardarCrear = async (e) => {
    logEventos("Agregar acción a factibilidad", "Gestión Crediticia", "Agrego acción a una factibilidad", localStorage.getItem("user"))
    e.preventDefault();
    await fetch(UrlApi + "/acciones_fact", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_factibilidad: props.factDatos.id_fact,
        usuario: localStorage.getItem("user"),
        fecha: moment(datosAccion.fecha).format("YYYY-MM-DD"),
        accion: datosAccion.accion,
        uuid_accion: props.uuid,
      }),
    })
      .then((res) => {
        if (!res.err) {
          console.log("Guardado");
        } else {
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    // PARA LIMPIAR LOS DATOS
    handleClose();
  };

  const handleOpenCloseAdj = (value) => {
    setOpenCloseAdj(value);
  };

  const handleClose = () => {
    setDialogNuevaAccion(!dialogNuevaAccion);
    if (props.onClose) {
      props.onClose();
    }
  };

  const almacenar = (e) => {
    setDatosAccion({
      ...datosAccion,
      [e.target.name]: e.target.value,
    });
  };

  const [disabledGuardar, setDisabledGuardar] = useState(false);
  useEffect(() => {
    if (datosAccion.accion.length === 0) {
      setDisabledGuardar(true);
    } else {
      setDisabledGuardar(false);
    }
  }, [datosAccion.accion]);

  return (
    <>
      <Dialog
        open={dialogNuevaAccion}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        color="#F4F6F6"
      >
        <Title
          titulo={`Nueva Acción`}
          handleClose={() => {
            handleClose();
          }}
        />
        <Box
          border={0.5}
          borderColor={grey[300]}
          backgroundColor="#F4F6F6"
          p={2}
          py={2}
          mt={1}
        >
          <DialogContent>
            <Container maxWidth="xl">
              <form autoComplete="off" onSubmit={guardarCrear}>
                <Grid container mb={1} spacing={1}>
                  <Grid item xs={12} py={1} mb={1}>
                    <TextField
                      label="Descripción"
                      name="accion"
                      size="small"
                      placeholder="..."
                      inputProps={{
                        maxLength: 200,
                      }}
                      focused
                      required
                      fullWidth
                      value={datosAccion.accion}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={4} py={1} mb={1}>
                    <Button
                      onClick={() => setOpenCloseAdj(!openCloseAdj)}
                      variant="outlined"
                      size="medium"
                      startIcon={<AttachFileIcon />}
                    >
                      ADJUNTAR DOCUMENTACIÓN
                    </Button>
                  </Grid>
                </Grid>
                {openCloseAdj ? ( // ADJUNTAR COMPROBANTES
                  <FileUploadStock
                    fileId={props.uuid}
                    openclose={true}
                    mostrar={true}
                    onClose={() => handleOpenCloseAdj(false)} // Proporciona una función de cierre
                  />
                ) : null}
                <Stack direction="row">
                  <Grid container direction={{ xs: "column", sm: "row" }}>
                    <Grid item xs={8}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleClose}
                      >
                        Volver
                      </Button>
                    </Grid>
                     <Grid item xs={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={disabledGuardar}
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              </form>
            </Container>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
}

export default AddAccion;
