//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio/Nicolas Pascucci
// Creation Date : 22/01/2024
// Version : 1
// Description : pantalla de los tabs de pendientes/aprobados/desaprobados
//------Changes -----------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React from "react";
import { useState, useEffect } from "react";
import Navbar from "../../Navbar";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { UrlApi } from "../../../services/apirest";
import { logEventos } from "../../../services/services";
// ICONOS
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import CheckIcon from "@mui/icons-material/Check";
import BlockIcon from "@mui/icons-material/Block";
//Pestañas
import PendientesAprobacion from "./PendientesAprobacion";
import FactAprobados from "./FactAprobados";
import FactDesaprobados from "./FactDesaprobados";

function GestionCrediticia() {
  //MANEJA TAB
  const [opcion, setOpcion] = React.useState("pendientes");

  const handleChange = (event, newValue) => {
    setOpcion(newValue);
  };

  useEffect(() => {
    logEventos("Acceso al menú gestión crediticia", "Gestión Crediticia", "Ver las pendientes,aprobadas y desaprobadas", localStorage.getItem("user"))
}, [])

  //FIN TAB
  const [factConfig, setFactConfig] = useState();
  const obtenerFactConfig = () => {
    fetch(UrlApi + "/factconfig/")
      .then((data) => data.json())
      .then((data) => setFactConfig(data));
  };
  useEffect(() => {
    obtenerFactConfig();
  }, []);

  return (
    <div>
      <Navbar />
      <TabContext value={opcion}>
        <Box sx={{ borderBottom: 0, borderColor: "divider", marginTop: 0 }}>
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            variant="fullWidth"
          >
            <Tab
              icon={<ContentPasteIcon style={{ fontSize: "18px" }} />}
              iconPosition="end"
              value="pendientes"
              label="Pendientes de Revisión"
            />
            <Tab
              icon={<CheckIcon style={{ fontSize: "18px" }} />}
              iconPosition="end"
              value="aprobados"
              label="Aprobadas"
            />
            <Tab
              icon={<BlockIcon style={{ fontSize: "18px" }} />}
              iconPosition="end"
              value="desaprobados"
              label="Desaprobadas"
            />
          </TabList>
        </Box>
      </TabContext>

      {opcion === "pendientes" ? (
        <Grid mt={0}>
          <PendientesAprobacion factConfig={factConfig} />
        </Grid>
      ) : null}

      {opcion === "aprobados" ? (
        <Grid mt={0}>
          <FactAprobados />
        </Grid>
      ) : null}

      {opcion === "desaprobados" ? (
        <Grid mt={0}>
          <FactDesaprobados />
        </Grid>
      ) : null}
    </div>
  );
}

export default GestionCrediticia;
