import React, { useState, useEffect, useMemo, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UrlApi } from "../../../services/apirest";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import {
  Button,
  Container,
  Box,
  Grid,
  Typography,
  IconButton,
  ListItemIcon,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

// ICONOS
import InputIcon from "@mui/icons-material/Input";
import OutputIcon from "@mui/icons-material/Output";
import AddBoxIcon from "@mui/icons-material/AddBox";
import HistoryIcon from "@mui/icons-material/History";
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import SettingsIcon from "@mui/icons-material/Settings";
import PrintIcon from "@mui/icons-material/Print";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import AutorenewIcon from "@mui/icons-material/Autorenew";

//Dialog de almacen
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";

// boton por enviar / recibido
import ButtonGroup from "@mui/material/ButtonGroup";
import { lightBlue, blueGrey } from "@mui/material/colors";

//adjuntado
import AttachFileIcon from "@mui/icons-material/AttachFile"; //ADJUNTAR ARCHIVOS

//ALERTA
import Badge from "@mui/material/Badge";
// import { cuentamensajes } from "../../services/services";
import { v4 as uuidv4 } from "uuid"; //ADJUNTAR IMAGEN

//CSV
import { ExportToCsv } from "export-to-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import moment from "moment";
import Asignar_equipo from "./Asignar_equipo";
import Ficha_imprimir from "./Ficha_imprimir";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Rrhh_stock(props) {
  let navigate = useNavigate();
  const location = useLocation();
  const [artSuc, setArtSuc] = useState();
  const [tabla, setTabla] = useState(true);
  const [asignar, setAsignar] = useState(false);

  const obtenerArtxSuc = () => {
    fetch(UrlApi + "/stock_inventarios/xsucursalrrhh/" + location.state.id)
      .then((data) => data.json())
      .then((data) => setArtSuc(data));
  };

  useEffect(() => {
    obtenerArtxSuc();
  }, [tabla]);

  const handleClickAsignar = () => {
    setAsignar(true);
  };

  const handleCloseAsignar = () => {
    setAsignar(false);
    obtenerArtxSuc();
  };

  const columns = useMemo(
    () => [
      {
        header: "id_articulo",
        accessorKey: "id_articulo",
        size: 130,
        enableHiding: true,
      },
      { header: "Artículo", accessorKey: "articulo", size: 130 },
      // { header: "Descripción", accessorKey: "descripcion_articulo", size: 130 },
      {
        header: "Descripción",
        accessorKey: "descripcion_articulo",
        size: 130,
        aggregationFn: "max", //show the max age in the group (lots of pre-built aggregationFns to choose from)
        //required to render an aggregated cell
        AggregatedCell: ({ cell, table }) => (
          <>
            {" "}
            {/* {table.getColumn(cell.row.groupingColumnId ?? '').columnDef.header}:{' '} */}
            <Box sx={{ color: "black", display: "inline" }}>
              {cell.getValue()}
            </Box>
          </>
        ),
      },
      {
        header: "Cantidad Inventario",
        accessorKey: "cantidad",
        size: 110,
        aggregationFn: "sum", //show the max age in the group (lots of pre-built aggregationFns to choose from)
        //required to render an aggregated cell
        AggregatedCell: ({ cell, table }) => (
          <>
            {" "}
            {/* {table.getColumn(cell.row.groupingColumnId ?? '').columnDef.header}:{' '} */}
            <Box sx={{ color: "black", display: "inline", fontWeight: "bold" }}>
              {cell.getValue()}
            </Box>
          </>
        ),
      },
      {
        header: "Medida",
        accessorKey: "descripcion",
        size: 60,
        aggregationFn: "max", //show the max age in the group (lots of pre-built aggregationFns to choose from)
        //required to render an aggregated cell
        AggregatedCell: ({ cell, table }) => (
          <>
            {" "}
            {/* {table.getColumn(cell.row.groupingColumnId ?? '').columnDef.header}:{' '} */}
            <Box sx={{ color: "black", display: "inline" }}>
              {cell.getValue()}
            </Box>
          </>
        ),
      },
      { header: "Almacén", accessorKey: "almacen", size: 120 },
      {
        header: "Alerta Mínima",
        accessorKey: "alerta_minima",
        size: 100,
        enableHiding: true,
      },
      {
        header: "Valor Mínimo",
        accessorKey: "valor_minimo",
        size: 100,
        enableHiding: true,
      },
      {
        header: "Estado Stock",
        accessorKey: "estado_stock",
        size: 100,
        enableHiding: true,
      },
      {
        header: "uuid_articulo",
        accessorKey: "uuid_articulo",
        size: 100,
        enableHiding: true,
      },
      {
        header: "Sucursal",
        accessorKey: "id_sucursal",
        size: 120,
        enableHiding: true,
      },
      {
        header: "convertible",
        accessorKey: "convertible",
        size: 120,
        enableHiding: true,
      },
      {
        header: "convertible_a",
        accessorKey: "convertible_a",
        size: 120,
        enableHiding: true,
      },
      {
        header: "nombre_convertible",
        accessorKey: "nombre_convertible",
        size: 120,
        enableHiding: true,
      },
      {
        header: "pedido_automatico",
        accessorKey: "pedido_automatico",
        size: 120,
        enableHiding: true,
      },
    ],
    []
  );

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "primary",
    size: "small",
  };
  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };
  //*************************************************************************************************************************************/

  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem",
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const initialStateArticulos = {
    //para inicializar la tabla, y la cantidad_enviada de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
    grouping: ["articulo"],
    columnVisibility: {
      id_articulo: false,
      id_transaccion: false,
      alerta_minima: false,
      valor_minimo: false,
      estado_stock: false,
      uuid_articulo: false,
      id_sucursal: false,
      convertible: false,
      convertible_a: false,
      nombre_convertible: false,
      pedido_automatico: false,
    },
  };

  //PDF
  const [fichaImprimir, setFichaImprimir] = useState(false);
  const handleCloseDialogImprimir = () => {
    setFichaImprimir(false);
    // setUpdate(true);
  };

  const componentRef = useRef();

  const handleClickOpenPdf = () => {
    setFichaImprimir(true);
  };

  return (
    <>
      {tabla ? (
        <AppBar
          sx={{ position: "relative" }}
          style={{ backgroundColor: "#0979b0" }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => navigate("/sucursales_cargas")}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Artículos de RRHH de la Sucursal: {location.state.nombre}
            </Typography>
          </Toolbar>
        </AppBar>
      ) : null}
      <Container maxWidth="xl" mt={1}>
        {artSuc && tabla ? (
          <Box mt={2} mb={4}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
                enableGrouping // para permitir agrupar x estado x ej
                layoutMode="grid"
                muiTableHeadCellProps={tableHeadCellProps}
                muiTableBodyCellProps={({ row }) => ({
                  align: "left",
                  sx: {
                    backgroundColor:
                      row.getValue("estado_stock") === 0
                        ? ""
                        : row.getValue("estado_stock") === 1
                        ? "rgb(255,105,97)"
                        : "rgb(253,253,150)",
                    color: "black",
                    borderColor: "black",
                  },
                })}
                enableColumnFilters={false} //desactiva filtros x  columna
                enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
                enableDensityToggle={false} //
                localization={MRT_Localization_ES} //idioma
                enableColumnDragging={false} //para arrastrar columnas
                initialState={initialStateArticulos}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                columns={columns}
                data={artSuc} //con lo q la completas (esto cambia siempre)
                enableStickyFooter
                // enableRowActions
                // enableEditing  //para que se pueda editar desde tabla
                positionActionsColumn="last" // posicion de la columna de acciones al final
                // ACCIONES
                renderToolbarInternalActions={(
                  { table } // boton para nuevo , al lado del buscar
                ) => (
                  <>
                    <MRT_FullScreenToggleButton table={table} />
                  </>
                )}
                renderTopToolbarCustomActions={(row, table) => (
                  <>
                    <Typography variant="poster" fontSize={26}>
                      Lista de Artículos RR.HH.
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddBoxIcon color="asd" />}
                      onClick={() => {
                        handleClickAsignar();
                        // setTabla(false)
                      }}
                      size="small"
                    >
                      ASIGNAR A EMPLEADO
                    </Button>
                    {/* <Button
                      type="primary"
                      variant="contained"
                      style={{ backgroundColor: blueGrey[400], color: "white" }}
                      onClick={handleClickOpenPdf}
                      size="medium"
                      endIcon={<PrintIcon />}
                    >
                      Planilla de Inventario
                    </Button> */}
                    <Button
                      onClick={() =>
                        navigate("/historial_fichas/" + location.state.id, {
                          state: location.state,
                        })
                      }
                      style={{ backgroundColor: blueGrey[700], color: "white" }}
                      variant="contained"
                      size="small"
                      endIcon={<HistoryIcon color="asd" />}
                    >
                      HISTORIAL
                    </Button>
                  </>
                )}
              />
            </ThemeProvider>
          </Box>
        ) : null}
        {asignar && (
          <Asignar_equipo
            open={asignar}
            setOpen={setAsignar}
            sucursal={location.state.id}
            artSuc={artSuc}
            onClose={handleCloseAsignar}
            // actualizar={obtenerPedidos(245)}
          />
        )}
      </Container>
    </>
  );
}

export default Rrhh_stock;
