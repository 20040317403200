//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio/Nicolas Pascucci
// Creation Date : 24/01/2024
// Version : 1
// Description : Dialog para la aprobación con motivo y adjunto
//------Changes --------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect } from "react";
import { UrlApi } from "../../../services/apirest";
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  Box,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import moment from "moment"; // PARA LA FECHA
//adjuntado
import AttachFileIcon from "@mui/icons-material/AttachFile"; //ADJUNTAR ARCHIVOS
import FileUploadStock from "../../stock_almacen/FileUploadStock";
import { grey } from "@mui/material/colors";
import Title from "../../Title";
import { aprobarFact, aprobarFactUser2 } from "../savefunctions";
import Notify from "../../Notify";
import { v4 as uuidv4 } from "uuid"; 
import { logEventos } from "../../../services/services";

function Dialog_aprobar(props) {
  // console.log("props en dialog_aprobar" , props)
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);
  const [dialogAprobar, setDialogAprobar] = useState(props.dialogAprobar);
  const [openCloseAdj, setOpenCloseAdj] = useState(false); //ADJUNTAR IMAGEN
  const [datosAccion, setDatosAccion] = useState({
    accion: "",
    fecha: date,
    // uuid_accion:""
  });
  const [factDatos, setFactDatos] = useState(props.factDatos);
  const [newUuid, setNewUuid] = useState(props.uuid);
  const [aprueba, setAprueba] = useState(false);
  const [update, setUpdate] = useState(false);

  const [uuidReclamo, setUuidReclamo]=useState(uuidv4())
  const reqBody = {
    id_cliente: factDatos ? factDatos.id_cliente : null, //pueden traerlo con la consulta inicial
    id_motivo: 19, // instalación
    estado: 192, //abierto
    prioridad: 195,
    fecha_abierto: moment(new Date()).format("YYYY-MM-DD"),
    detalle: "Instalación para cliente por factibilidad aprobada.",
    direccion_entrega: factDatos ? factDatos.id_direccion : null, // lo pueden traer de la consulta inicial que trae las factibilidades pendientes de aprobacion crediticia
    usuario: localStorage.getItem("user"),
    id_fact: factDatos.id_fact,
    uuid:uuidReclamo,
  };

  const [notify, setNotify] = useState({
    open: false,
    mensaje: "",
    severity: "",
    color: "",
  });
  const datosFactAprobar={
    id_estado: 199,
    usuario_aprueba_creditos:localStorage.getItem("user"), 
    fecha_aprobado_creditos:moment(new Date()).format("YYYY-MM-DD")
  }

  //Estoy lo armo para pasarselo a la funcion como "cliente", para que despues pueda acceder a estos datos cuando envíe el correspondiente mail.
  // console.log(props.factDatos)
  const datosCliente={
    razon_social:props.factDatos.razon_social,
    direccion_fiscal:props.factDatos.direccion_fiscal,
    localidad:props.factDatos.cli_localidad,
    provincia:props.factDatos.cli_provincia,
    mails:props.factDatos.mails,
    telefono1:props.factDatos.telefono1,
    telefono2:props.factDatos.telefono2,
    telefono3:props.factDatos.telefono3,
    sit_iva:props.factDatos.cli_situacioniva,
    cuit:props.factDatos.cuit,
    segmento:props.factDatos.cli_segmento,
    subsegmento:props.factDatos.cli_subsegmento,
  }

  const datosDirEntrega={
    contacto:props.factDatos.contacto_direntrega,
    direccion_entrega:props.factDatos.direccion_entrega,
    localidad:props.factDatos.diren_localidad, 
    provincia:props.factDatos.diren_provincia,
    telefono1:props.factDatos.diren_telefono1,
    telefono2:props.factDatos.diren_telefono2,
    telefono3:props.factDatos.diren_telefono3,
    mails:props.factDatos.diren_mails,
    latitud:props.factDatos.latitud,
    longitud:props.factDatos.longitud
  }

  // console.log("artefactos en dialogo para aprobar: " , props.artefactos)
  const calculos={"kganual":"No disponible, verificar en factibilidad."}

  async function aprobar(e) {
    logEventos("Aprobar factibilidad", "Gestión Crediticia", "Aprueba una factibilidad", localStorage.getItem("user"))
    e.preventDefault(e)
    aprobarFactUser2(
      e,
      "/factibilidades/aprobaryusuarios2/" + factDatos.factuuid + "/" + factDatos.id_tipofact,
      reqBody,
      setNotify,
      props.factConfig[0].mail_administracion,
      factDatos,
      datosCliente,
      // factDatos.razon_social, //Esto es lo que la funcion recibe como "cliente"
      // datosCliente, //Esto s lo que la funcion recibe como "cliente"
      props.factConfig[0].mail_autorizante,
      factDatos.mail_comercial,
      datosFactAprobar,
      //aca tengo que agregar:
      datosDirEntrega,
      props.artefactos,
      calculos
      // calculos:{
      //   kganual:100,
      // }  
      //calculos
    );
    await fetch(UrlApi + "/acciones_fact", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_factibilidad: factDatos.id_fact,
        usuario: localStorage.getItem("user"),
        fecha: moment(new Date()).format("YYYY-MM-DD"),
        accion: datosAccion.accion, //cambie esto
        uuid_accion: newUuid,
      }),
    })
      .then((res) => {
        if (!res.err) {
          setUpdate(true);
        } else {
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
      handleClose()
  }

  const handleClose = () => {
    // console.log("Esto es lo que se ejecuta")
    props.setArtefactos(null)
    setDialogAprobar(!dialogAprobar);
    if (props.onClose) {
      props.onClose();
    }
  };

  const handleOpenCloseAdj = (value) => {
    setOpenCloseAdj(value);
  };

  const almacenar = (e) => {
    setDatosAccion({
      ...datosAccion,
      [e.target.name]: e.target.value,
    });
  };

  const [disabledGuardar, setDisabledGuardar] = useState(false);
  useEffect(() => {
    if (datosAccion.accion.length === 0) {
      setDisabledGuardar(true);
    } else {
      setDisabledGuardar(false);
    }
  }, [datosAccion.accion]);
  return (
    <>
      <Dialog
        open={dialogAprobar}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        color="#F4F6F6"
      >
        <Title
          titulo={`Aprobar Factibilidad`}
          handleClose={() => {
            handleClose();
          }}
        />
        <Box
          border={0.5}
          borderColor={grey[300]}
          backgroundColor="#F4F6F6"
          p={2}
          py={2}
          mt={1}
        >
          <DialogContent>
            <Container maxWidth="xl">
              <form autoComplete="off" 
              onSubmit={aprobar}
              >
                <Grid container mb={1} spacing={1}>
                  <Grid item xs={12} py={1} mb={1}>
                    <TextField
                      label="Motivo de Aprobación"
                      name="accion"
                      size="small"
                      placeholder="..."
                      inputProps={{
                        maxLength: 200,
                      }}
                      focused
                      required
                      fullWidth
                      value={datosAccion.accion}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={4} py={1} mb={1}>
                    <Button
                      onClick={() => setOpenCloseAdj(!openCloseAdj)}
                      variant="outlined"
                      size="medium"
                      startIcon={<AttachFileIcon />}
                    >
                      ADJUNTAR DOCUMENTACIÓN
                    </Button>
                  </Grid>
                </Grid>
                {openCloseAdj ? ( // ADJUNTAR COMPROBANTES
                  <FileUploadStock
                    fileId={props.uuid}
                    openclose={true}
                    mostrar={true}
                    onClose={() => handleOpenCloseAdj(false)} // Proporciona una función de cierre
                  />
                ) : null}
                <Stack direction="row">
                  <Grid container direction={{ xs: "column", sm: "row" }}>
                    <Grid item xs={8}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleClose}
                      >
                        Volver
                      </Button>
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={disabledGuardar}
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              </form>
            </Container>
          </DialogContent>
        </Box>
      </Dialog>

      <Notify
        open={notify.open}
        severity={notify.severity}
        mensaje={notify.mensaje}
        color={notify.color}
      />
    </>
  );
}

export default Dialog_aprobar;
