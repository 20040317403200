//----- Component --------------------------------------------------------/

// Developer : Nicolas Pascucci

// Creation Date : 21/05/2024

// Version : 1

// Description : configuracion de la evaluacion donde se decide que se le muestra y que no al comercial en la evaluacion (objetivo mensual / ganancias / clientes del mes anterior  )
//

//------Changes --------------------------------------------------------------------- -------------//

// Change Date :

// Change by :

// Change description:

//

// Change Date :

// Change by :

// Change description:

//

//========================================================================/
import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Button,
  Box,
  Grid,
  Stack,
  Snackbar,
  TextField,
  Autocomplete,
  Alert,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment"; // PARA LA FECHA
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { grey } from "@mui/material/colors";
import { size } from "lodash";
import Navbar from "../../Navbar";
import Title from "../../Title";
import { UrlApi } from "../../../services/apirest";
import { logEventos } from "../../../services/services";

function Config_evaluacion() {
    const [openSnack, setOpenSnack] = React.useState(false);
    const [colormensaje, setColorMensaje] = useState("");
    const [mensaje, setMensaje] = useState("");
    const [objetivo, setObjetivo] = useState(false)
    const [clientes, setClientes] = useState(false)
    const [porcentaje, setPorcentaje] = useState(false)

    let objetivoValor= objetivo?1:0
    let clientesValor= clientes?1:0
    let porcentajeValor= porcentaje?1:0

    const handleChangeObjetivos = (event) => {
      setObjetivo(event.target.checked);
    };
    const handleChangeClientes = (event) => {
      setClientes(event.target.checked);
    };
    const handleChangePorcentaje = (event) => {
      setPorcentaje(event.target.checked);
    };


    const guardarConfiguracion = async (e) => {
        logEventos("Guardar configuración de evaluacion", "Evaluacion Comerciales", "Se guardo la configuración", localStorage.getItem("user"))
        e.preventDefault();
        await fetch(UrlApi + "/config_evaluacion", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            objetivo: objetivoValor,
            clientes: clientesValor,
            porcentaje: porcentajeValor,
            usuario: localStorage.getItem("user"),
          }),
        })
          .then((res) => {
            if (!res.err) {
              setOpenSnack(true);
              setColorMensaje("#D4EFDF");
              setMensaje("La configuración se guardó exitosamente !");
            } else {
              setOpenSnack(false);
              setColorMensaje("#F1948A");
              setMensaje("Error al guardar la configuración");
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      }

    //Estados para pantalla
    const style = {
      backgroundColor: "#ffffff",
      width: "100%",
    };
    //Snackbar Notificacion
    const handleCloseSnack = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setOpenSnack(false);
    };
  return (
    <>
    <Navbar/>
    <>
    <Box sx={{ flexGrow: 1, marginTop:2 }}>
      <Grid container backgroundColor="#1F618D" p={1.5}>
        {/* <Grid item xs={2}>
          <IconButton onClick={handleClose}>
            <ArrowBackIcon sx={{ color: grey[50] }} />
          </IconButton>
        </Grid> */}
        <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h5" color="#ffffff">
            ¿Qué Desea Mostrar al Representante Comercial?
          </Typography>
        </Grid>
      </Grid>
    </Box>
      <form onSubmit={guardarConfiguracion}>
        <Box
          border={0.5}
          borderColor={grey[300]}
          backgroundColor="#F4F6F6"
          p={2}
          py={2}
          mt={1}
          style={{display: "flex", justifyContent:"center"}}
        >
         <FormControl component="fieldset">
            <FormGroup aria-label="position" row >
              <FormControlLabel
                value="objetivo"
                control={<Checkbox
                   checked={objetivo}
                   onChange={handleChangeObjetivos}  
                />}
                label={<span style={{fontSize:"1.2rem"}}>Objetivo Mensual del Comercial</span>}
                labelPlacement="top"
              />
              <FormControlLabel
                value="clientes"
                control={<Checkbox
                   checked={clientes} 
                   onChange={handleChangeClientes}
                />}
                label={<span style={{fontSize:"1.2rem"}}>Clientes del Mes Anterior</span>}
                labelPlacement="top"
              />
              <FormControlLabel
                value="porcentaje"
                control={<Checkbox
                   checked={porcentaje}
                   onChange={handleChangePorcentaje}  
                />}
                label={<span style={{fontSize:"1.2rem"}}>Porcentaje de Ganancia</span>}
                labelPlacement="top"
              />
            </FormGroup>
          </FormControl>
        </Box>
        <Box>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            mt={2}
            display={"flex"}
          >
            <Button variant="contained" type="submit" color="success" >
              Guardar Configuración
            </Button>
          </Grid>
        </Box>
      </form>
    </>
    <>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  </>
  )
}

export default Config_evaluacion