//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio
// Creation Date : 1/10/2024
// Version : 1
// Description : tabla de reclamos por motivos
//------Changes ----------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useEffect, useState } from "react";
import { UrlApi } from "../../../../services/apirest";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/system";


function Tabla_motivos_mobile() {
  const [cantidadXmotivo, setCantidadXmotivo] = useState([]);

  const obtenerCantidadXmotivo = () => {
    fetch(UrlApi + "/reclamos/cantidadPorMotivo")
      .then((data) => data.json())
      .then((data) => setCantidadXmotivo(data));
  };

  useEffect(() => {
    obtenerCantidadXmotivo();
  }, []);

  //  console.log("CANTIDAD X MOTIVO", cantidadXmotivo);

  // Ordenar los datos de mayor a menor por cantidad de reclamos
  const cantidadXmotivoOrdenado = [...cantidadXmotivo].sort(
    (a, b) => b.cantidad_reclamos - a.cantidad_reclamos
  );

  // Calcular el total de reclamos
  const totalReclamos = cantidadXmotivoOrdenado.reduce(
    (total, item) => total + item.cantidad_reclamos,
    0
  );
  // Transformar los datos para el PieChart, incluyendo el porcentaje
  const data = (cantidadXmotivoOrdenado || []).map((item) => ({
    label: `${item.descripcion_motivo} (${(
      (item.cantidad_reclamos / totalReclamos) *
      100
    ).toFixed(2)}%)`,
    value: item.cantidad_reclamos,
  }));

  const data2 = (cantidadXmotivoOrdenado || []).map((item) => ({
    descripcion: item.descripcion_motivo,
    cantidad: item.cantidad_reclamos,
    porcentaje: ((item.cantidad_reclamos / totalReclamos) * 100).toFixed(2), // Calcular el porcentaje
  }));

  // Estilo ajustado para pantallas móviles
  const paperStyle = {
    padding: "10px",
    margin: "10px",
    width: "100%", // Usar el ancho completo disponible
    maxWidth: "800px", // Limitar el ancho máximo a 430px
  };

  const HeaderCell = styled(TableCell)({
    fontSize: 12,
    color: "white",
    fontWeight: "normal",
    backgroundColor: "#2471A3",
    padding: "4px",
  });

  return (
    <div style={{ width: "100%", height: "100px" }}>
      {" "}
      {/* Ajuste de la altura del contenedor principal */}
      <Paper elevation={10} style={paperStyle}>
        <TableContainer style={{ maxHeight: "100vh" }}>
          {" "}
          {/* Ajustar la altura de la tabla */}
          <Typography
            align="center"
            style={{ fontWeight: "bold", fontSize: 18 }}
          >
            Motivos de los reclamos
          </Typography>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <HeaderCell align="center">Motivos</HeaderCell>
                <HeaderCell
                  align="center"
                  style={{ backgroundColor: "#2B76A6" }}
                >
                  Cantidad
                </HeaderCell>
                <HeaderCell
                  align="center"
                  style={{ backgroundColor: "#2B76A6" }}
                >
                  Porcentaje
                </HeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data2.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.descripcion}</TableCell>
                  <TableCell align="center">{row.cantidad}</TableCell>
                  <TableCell align="center">{row.porcentaje}%</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default Tabla_motivos_mobile;
