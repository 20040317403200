import React, { useEffect, useState } from "react";
import { getData, postData } from "../services/library";
import { Divider, Paper, TextField, Button, Skeleton, Tabs, Tab, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { Person, Send } from '@mui/icons-material';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { io } from 'socket.io-client';
import { MessageLeft, MessageRight } from "./Messages";
import {Typography} from "@mui/material";
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
};

const useStyles = makeStyles((theme) =>
    createStyles({
        paper: {
            width: "80vw",
            height: "80vh",
            maxWidth: "500px",
            maxHeight: "700px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            position: "relative"
        },
        paper2: {
            width: "80vw",
            maxWidth: "500px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            position: "relative"
        },
        container: {
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        messagesBody: {
            width: "calc( 100% - 20px )",
            margin: 10,
            overflowY: "scroll",
            height: "calc( 100% - 80px )"
        },
        messageTimeStamp: {
            fontSize: '0.8em', // Tamaño de fuente más pequeño
            color: '#888', // Color de texto gris para un estilo más discreto
        },
    })
);


export default function Chat() {
    const [users, setUsers] = useState([]);
    const [userSelected, setuserSelected] = useState(null);
    const [messages, setMessages] = useState([]);
    const [circular, setCircular] = useState(false);
    const [newMessage, setNewMessage] = useState('');
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [conectado, setIsConnected] = useState(false);
    const [sortedMessages, setSorted] = useState([]);
    const [usuariosConectados, setUsuariosConectados] = useState([]);


    const getMensajes = () => {
        getData('/chat/mensajes/' + localStorage.getItem('user') + '/' + userSelected, setMessages)
        setCircular(true);
        setTimeout(() => {
            setCircular(false);
        }, 500);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getUsers = () => {
        getData('/userdata', setUsers)
    };

    useEffect(() => {
        getUsers()
    }, []);

    // const socket = io.connect("http://localhost:27020");

    useEffect(() => {
        // Lógica de Socket.IO que necesitas ejecutar cuando la aplicación se monta
        // io.on('connection', () => {
        //   console.log('Conexión establecida con el servidor de Socket.IO');
        // });
      }, []);



    const setearUsuario = (user) => {
        setuserSelected(user)
    };

    // const handleSendMessage = async (e) => {
    //     socket.emit('chat_message', {
    //         sender_id: localStorage.getItem('user'),
    //         recipient_id: userSelected,
    //         contenido: newMessage,
    //         visto: 0
    //     })
    //     // postData(e,
    //     //     '/chat',
    //     //     {
    //     //         sender_id: localStorage.getItem('user'),
    //     //         recipient_id: userSelected,
    //     //         contenido: newMessage,
    //     //         visto: 0
    //     //     },
    //     //     setNotificacion,
    //     //     setNewMessage(""),
    //     //     getMensajes()
    //     // );
    // };

    return (
        <div>
            <Box sx={{ display: 'flex' }}>
                <Tabs orientation="vertical"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{ borderRight: 1, borderColor: 'divider' }}
                >
                    {users && users.map((user, index) => (
                        <Tab
                            key={index}
                            label={user.nombrepila + ' ' + user.apellido}
                            {...a11yProps(index)}
                            onClick={() => setearUsuario(user.username)}
                            icon={
                                <Person
                                    color={usuariosConectados.includes(user.username) ? "success" : "error"} // Cambia el color del icono basado en si el usuario está conectado
                                />
                            }
                        />
                    ))}
                </Tabs>
                            <p>{JSON.stringify(usuariosConectados)}</p>
                {/* Renderizar paneles para usuarios conectados */}
                {/* {connectedUsers.map((user, index) => (
                    <TabPanel key={index} value={value} index={index}>
                        {circular ? <Skeleton variant="rounded" width={"100%"} height={118} /> : null}
                        <Paper className={classes.paper}>
                            <div className={classes.messagesBody}>
                                {!circular && userSelected && sortedMessages.map((msg, index) => (
                                    msg.sender_id === userSelected ? (
                                        <>
                                            <MessageLeft key={index} message={msg.contenido} />
                                            <p className={`${classes.messageTimeStamp} ${classes.messageTimeStampLeft}`}>{msg.timestamp}</p>
                                        </>
                                    ) : (
                                        <MessageRight key={index} message={msg.contenido} />
                                    )
                                ))}
                            </div>
                        </Paper>
                        <TextField
                            label="Escribe tu mensaje"
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            fullWidth
                            variant="outlined"
                            margin="normal"
                        />
                        <Button onClick={handleSendMessage} variant="contained" color="primary">
                            Enviar mensaje <Send sx={{ ml: 2 }} />
                        </Button>

                    </TabPanel>
                ))}
                <Divider />
                {/* Renderizar paneles para usuarios no conectados 
                {disconnectedUsers.map((user, index) => (
                    <TabPanel key={index + connectedUsers.length} value={value} index={index + connectedUsers.length}>
                        <Typography variant="h6">{user.nombrepila}</Typography>
                        <Typography variant="body1">{user.apellido}</Typography>
                        {/* Agregar más información del usuario si es necesario 
                    </TabPanel>
                ))} */}
            </Box>
            {/* <List>
                    {messages.map((message, index) => (
                        <ListItem key={index}>
                            <ListItemText primary={message.content} />
                        </ListItem>
                    ))}
                </List>
                <TextField
                    label="Mensaje"
                    variant="outlined"
                    value={messageInput}
                    onChange={(e) => setMessageInput(e.target.value)}
                />
                <Button variant="contained" color="primary" onClick={sendMessage}>
                    Enviar
                </Button> */}
            {/* </Box> */}


        </div >
    );
}