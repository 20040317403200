import React, { useState, useEffect } from "react";
import { Box, TextField, Grid, Button, DialogContent, Typography, Divider, Container } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FileUploadMultiple from "../../../../../services/UploadFiles";
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Drawer from '@mui/material/Drawer';
import { getData } from "../../../../../services/library";
import EditIcon from '@mui/icons-material/Edit';

function VerDatosReq(props) {
    const [datos, setDatos] = useState(props.datosreq)
    const [openclose, setOpenClose] = useState(false);
    const [lista, setLista] = useState();
    const [datosOrden, setDatosOrden] = useState(null)
    const [circular, setCircular] = useState(false);

    const obtenerListaMateriales = () => {
        getData('/lista_materiales/req/' + datos.uuid_requerimiento, setLista)
        setCircular(true);
        setTimeout(() => {
            setCircular(false);
        }, 500);
    };

    useEffect(() => {
        obtenerListaMateriales()
        getData('/requerimientos_materiales/ordencompra/' + datos.uuid_ordencompra, setDatosOrden);
    }, []);

    const style = {
        backgroundColor: "#ffffff",
        width: "100%"
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#92a898",
            color: theme.palette.common.white,
            fontSize: 14,
            padding: 5
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            padding: 5,
            // backgroundColor: lista.aprobado === 0 ? "#f7f7a6" : theme.palette.action.hover,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: "white",
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const rowBg = {
        0: "#ffff80"
    };

    // console.log("props", props);

    return (
        <>
            <Drawer open={props.anchor} onClose={() => props.anchor(false)} anchor="right">
                <Container maxWidth="xl">
                    {/* {circular ? <CircularProgress width={"100%"} height={118} /> : null} */}
                    {!circular && props.datosreq && props.anchor &&

                        <>

                            <Box
                                key={datos.idrequerimiento}
                                width="100%"
                                role="presentation" onClick={() => props.anchor(false)}
                                backgroundColor="#CCD1D1"
                                alignItems="center"
                                justifyContent="center"
                                mt={7}
                            >
                                <Typography
                                    variant="overline"
                                    display="block"
                                    gutterBottom
                                    textAlign="center"
                                >
                                    Visualización de requerimiento N°: {datos.idrequerimiento}
                                </Typography>
                            </Box>
                            <Box
                                backgroundColor="#F4F6F6"
                                mt={1}
                                p={2}
                                border={0.5}
                                borderColor={"#ABB2B9"}
                                borderRadius={4}
                            >
                                <Grid container spacing={1} alignItems="center" justifyContent="space-between">

                                    <Grid item xs={3}>
                                        <TextField
                                            focused
                                            color="primary"
                                            size="small"
                                            value={datos.nombre_sucursal}
                                            style={style}
                                            label="Sucursal"
                                            InputProps={{
                                                readOnly: true,
                                            }} />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextField
                                            focused
                                            color="primary"
                                            size="small"
                                            value={datos.patente !== null ? datos.patente : "No hay registro"}
                                            style={style}
                                            label="Patente"
                                            InputProps={{
                                                readOnly: true,
                                            }} />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextField
                                            focused
                                            color="primary"
                                            size="small"
                                            value={moment(datos.fecha_entrega).format("DD-MM-YYYY")}
                                            style={style}
                                            label="Fecha de entrega"
                                            InputProps={{
                                                readOnly: true,
                                            }} />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextField
                                            focused
                                            color="primary"
                                            size="small"
                                            value={datos.usuario_autoriza ? `${datos.nombrepila + datos.apellido}` : "No hay registro"}
                                            style={style}
                                            label="Autoriza"
                                            InputProps={{
                                                readOnly: true,
                                            }} />
                                    </Grid>

                                </Grid>
                            </Box>

                            <Box style={{ width: "100%" }} >
                                <TableContainer component={Paper}>
                                    <Table sx={{ mt: 1 }} aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell align="left">Cantidad</StyledTableCell>
                                                <StyledTableCell align="left">Unidad</StyledTableCell>
                                                <StyledTableCell align="left">Articulo</StyledTableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {lista && lista.map((lista) => (
                                                <StyledTableRow key={lista.idlista} sx={{ backgroundColor: rowBg[lista.aprobado] }}>
                                                    <StyledTableCell align="left">{lista.cantidad}</StyledTableCell>
                                                    <StyledTableCell align="left">{lista.descripcion ?? lista.descripcion_material}</StyledTableCell>
                                                    <StyledTableCell align="left">{lista.nombre ?? lista.material}</StyledTableCell>
                                                </StyledTableRow>

                                            ))}

                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </Box>

                            {datos.observaciones_entregado !== null ? (
                                <>
                                    <Box>
                                        <TextField
                                            focused
                                            margin="dense"
                                            variant="filled"
                                            size="small"
                                            value={datos.observaciones_entregado}
                                            style={style}
                                            multiline
                                            label="Observaciones de entrega"
                                            InputProps={{
                                                readOnly: true,
                                            }} />
                                    </Box>
                                </>
                            ) :
                                <>
                                    <Box>
                                        <TextField
                                            focused
                                            margin="dense"
                                            variant="filled"
                                            size="small"
                                            value={datos.comentarios_internos ?? "No hay registro"}
                                            style={style}
                                            multiline
                                            label="Comentarios internos"
                                            InputProps={{
                                                readOnly: true,
                                            }} />

                                        <TextField
                                            focused
                                            margin="dense"
                                            variant="filled"
                                            size="small"
                                            value={datos.observaciones ? datos.observaciones : "No hay registro"}
                                            style={style}
                                            multiline
                                            label="Observaciones para la orden de compra"
                                            InputProps={{
                                                readOnly: true,
                                            }} />
                                    </Box>
                                </>
                            }

                            {props.datosreq && props.datosreq.observaciones_baja !== null ?
                                <>
                                    <Box>
                                        <TextField
                                            focused
                                            margin="dense"
                                            variant="filled"
                                            size="small"
                                            value={props.datosreq.observaciones_baja}
                                            style={style}
                                            multiline
                                            color="error"
                                            label="Motivos de rechazo"
                                            InputProps={{
                                                readOnly: true,
                                            }} />
                                    </Box>
                                </>
                                : null}

                            {props.datosreq && (props.datosreq.observaciones_jefe !== null) && (props.datosreq.observaciones_jefe !== undefined) ?
                                <>
                                    <Box>
                                        <TextField
                                            focused
                                            margin="dense"
                                            variant="filled"
                                            size="small"
                                            value={props.datosreq.observaciones_jefe}
                                            style={style}
                                            multiline
                                            // color="error"
                                            label="OBSERVACIONES DE JEFE COMPRAS"
                                            InputProps={{
                                                readOnly: true,
                                            }} />
                                        <TextField
                                            focused
                                            margin="dense"
                                            variant="filled"
                                            size="small"
                                            // color="error"
                                            value={props.datosreq.usuario_gestiona}
                                            style={style}
                                            multiline
                                            label="Usuario"
                                            InputProps={{
                                                readOnly: true,
                                            }} />
                                    </Box>
                                </>
                                : null}

                            <Grid item xs={8} mb={3}>
                                <Button
                                    variant="outlined"
                                    ml={5}
                                    color="warning"
                                    size="small"
                                    onClick={() => setOpenClose(!openclose)}
                                > Visualizar presupuestos <AttachFileIcon /></Button>
                            </Grid>

                        </>
                    }
                    {datos.uuid_ordencompra && datosOrden ? (
                        <>
                            <Box
                                width="100%"
                                mt={1}
                                backgroundColor="#CCD1D1"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Typography
                                    variant="overline"
                                    display="block"
                                    gutterBottom
                                    textAlign="center"
                                >
                                    Datos de la orden de compra {datosOrden[0].idordencompra}
                                </Typography>
                            </Box>
                            <Box>
                                <TextField
                                    focused
                                    margin="dense"
                                    variant="filled"
                                    size="small"
                                    value={datosOrden[0].valor_estado}
                                    style={style}
                                    multiline
                                    label="Estado"
                                    InputProps={{
                                        readOnly: true,
                                    }} />

                                <TextField
                                    focused
                                    margin="dense"
                                    variant="filled"
                                    size="small"
                                    value={datosOrden[0].comentarios_internos}
                                    style={style}
                                    multiline
                                    label="Comentarios internos orden compra"
                                    InputProps={{
                                        readOnly: true,
                                    }} />
                            </Box>
                        </>
                    ) : null}
                    <Divider/>
                </Container>
            </Drawer>
            <br />
            {openclose ? <FileUploadMultiple fileId={datos.uuid_requerimiento} openclose={true} /> : null}
        </>
    )
}

export default VerDatosReq
//========================================================================
/*----- Component --------------------------------------------------------/
// Developer     : Candela Grosso   
// Creation Date : 30/11/2023
// Version       : 1.0  
// Description   :  Con este componente el usuario es capaz de :
    - VISUSALIZAR LOS DATOS DEL REQUERIMIENTO
    - VISUALIZAR LOS DATOS DE LA ORDEN DE COMPRA EN CASO DE QUE SE HAYA GENERADO UNA
//------Changes --------------------------------------------------------------------- -------------//
// Change Date   :
// Change by     :
// Change description: 
//========================================================================*/
