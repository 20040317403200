import React, { useEffect, useState } from "react";
import { UrlApi } from "../../services/apirest";
import { useLocation } from "react-router-dom";

function Email_delegar_aceptar() {
  const [hallazgo, setHallazgo] = useState();
  const location = useLocation();

  useEffect(() => {
    // Obtener el parámetro 'sugerencia' de la URL
    const queryParams = new URLSearchParams(location.search);
    const sugerencia = queryParams.get("sugerencia");

    // Mostrar el valor de 'sugerencia' en el elemento 'sugerenciaTexto'
    const elementoSugerencia = document.getElementById("sugerenciaTexto");
    if (sugerencia && elementoSugerencia) {
      elementoSugerencia.textContent = sugerencia;
    }

    // console.log("SUGERENCIA: ", sugerencia);

    const obtenerHallazgo = () => {
      fetch(UrlApi + "/auditorias/" + sugerencia)
        .then((data) => data.json())
        .then((data) => {
          setHallazgo(data);
        });
    };

    obtenerHallazgo();

    // console.log("HALLAZGO: ", hallazgo);
  }, [location.search]);

  //   ----------------------------------------------

  // ESTILOS
  const navbarStyle = {
    backgroundColor: "orange",
    textAlign: "center",
    padding: "10px",
  };

  const tituloStyle = {
    color: "white",
  };

  const textoStyle = {
    textAlign: "center",
    marginTop: "50px",
    fontWeight: "bold",
  };

  return (
    <div>
      <nav style={navbarStyle}>
        <h1 style={tituloStyle}>RAI</h1>
      </nav>
      <div style={textoStyle}>
        <p>Se ha rechazado la solicitud de delegación del hallazgo</p>
      </div>

      <p id="sugerenciaTexto">Aquí se mostrará la sugerencia</p>

      <div>
        <p>Ver hallazgo</p>
      </div>
    </div>
  );
}

export default Email_delegar_aceptar;
