import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box } from '@mui/material';
import Navbar from '../Navbar';
import { UrlApi } from '../../services/apirest';

const ApiCoboltest = () => {
    const [inputValue, setInputValue] = useState('');
    const [apiResponse, setApiResponse] = useState(null);
    const [error, setError] = useState(null);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);

        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${UrlApi}/apicobol?parametro=${inputValue}`, {
                headers: {
                    'Authorization': token
                }
            });
            if (!response.ok) {
                throw new Error('Error fetching data');
            }
            const data = await response.json();
            setApiResponse(data);
        } catch (err) {
            setError('Error fetching data from the API');
        }
    };

    return (
        <Container maxWidth="sm">
            <Navbar />
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 15 }}>
                <TextField
                    fullWidth
                    label="Introduce nro cliente"
                    value={inputValue}
                    onChange={handleInputChange}
                />
                <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
                    Enviar
                </Button>
            </Box>
            {error && (
                <Typography color="error" sx={{ mt: 2 }}>
                    {error}
                </Typography>
            )}
            {apiResponse && (
                <Box sx={{ mt: 4 }}>
                    <Typography variant="h6">Respuesta de la API:</Typography>
                    <pre>{JSON.stringify(apiResponse, null, 2)}</pre>
                </Box>
            )}
        </Container>
    );
};

export default ApiCoboltest;
