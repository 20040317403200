//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio
// Creation Date : 04/09/2024
// Version : 1
// Description : agregar servicio a tanque
//------Changes -------------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Container,
  Grid,
  Box,
  TextField,
  Button,
  Dialog,
  DialogContent,
  Stack,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import Notify from "../Notify";
import Alerta from "../Alerta";
import Title from "../Title";
import { logEventos } from "../../services/services";
import moment from "moment";

function Agregar_servicio(props) {
  const [disBtn, setDisBtn] = useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
  const [mensajeAlerta, setMensajeAlerta] = useState(""); //mensaje que contendrá el componente "alerta"
  const [finalizar, setFinalizar] = useState(); //finalizar === 1 lo manda como prop el componente "notify" e indica que la transaccion ha finalizado y debe ejecutarse la funcion para cerrar
  const [severityAlerta, setSeverityAlerta] = useState(""); //define severidad de la alerta (success error o warning)
  const [severitySnack, setSeveritySnack] = useState(""); //define severidad del snackbar (notificación)
  const [datosTanque, setDatosTanque] = useState(props.datos); // viene del motivos_salida {editar}
  const [servicio, setServicio] = useState(props.servicio);
  const [disabledGuardar, setDisabledGuardar] = useState(false);
  const [datosServicio, setDatosServicio] = useState({
    id_tanque: props.datos.id_tanque || null,
    servicio_desde: "",
    servicio_hasta: "",
    detalle_servicio: "",
    motivo_servicio: "",
    lugar_servicio: "",
    usuario: localStorage.getItem("user"),
  });
  useEffect(() => {
    setDatosTanque({
      ...props.datos,
    });
    setDatosServicio((prev) => ({
      ...prev,
      id_tanque: props.datos.id_tanque || null, // Asigna el id_tanque cuando cambian los datos del tanque
    }));
  }, [props.datos]);

  const handleClose = () => {
    setServicio(!servicio);
    if (props.onClose) {
      props.onClose();
    }
  };

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };
  const size = "small";

  const almacenar = (e) => {
    const { name, value } = e.target;
    setDatosServicio((prev) => ({
      ...prev,
      [name]: value || null,
    }));
  };

  const guardarServicio = async (e) => {
    logEventos(
      "Guardar nuevo servicio",
      "Tanques Moviles",
      "Se guardo nuevo servicio",
      localStorage.getItem("user")
    );
    setDisabledGuardar(true);
    e.preventDefault();
    const response = await fetch(UrlApi + "/tanque_servicio", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_tanque: datosServicio.id_tanque,
        servicio_desde: datosServicio.servicio_desde,
        servicio_hasta: datosServicio.servicio_hasta,
        detalle_servicio: datosServicio.detalle_servicio,
        motivo_servicio: datosServicio.motivo_servicio,
        lugar_servicio: datosServicio.lugar_servicio,
        usuario: localStorage.getItem("user"),
      }),
    });
    if (response.ok === false) {
      console.log("ERROR EN API");
      const error = response && response.json();
      setError(error);
      setSeverityAlerta("error");
      setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      console.log("Conexión con api OK");
      try {
        //Parseo respuesta de la api a json()
        const json = await response.json();
        //si api me devuelve lo q le puse en message
        if (json.message) {
          //Si ese mensaje es "OK"
          if (json.message === "OK") {
            console.log("Guardado Satisfactoriamente");
            setNotificacion(true);
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setMensaje("Guardado");
            setSeveritySnack("success");
          } else {
            console.log("res en api incorrecto. Revisar que sea 'OK'");
            setNotificacion(true);
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setSeveritySnack("error");
            setMensaje("Registro Guardado. REVISAR RESPUESTA DE API (E_S)");
          }
        } else if (json.sqlMessage) {
          json && setError(json);
          console.log(json);
          setMensajeAlerta(
            "SQL erróneo (" + json.code + " Errno: " + json.errno + " )"
          );
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          console.log(
            "Guardado. Advertencia: Actualizar respuesta de api. JSON invalido."
          );
          setNotificacion(true);
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("Guardado");
          setSeveritySnack("success");
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      }
    }
    handleClose();
  };

  // console.log("SERVICIO", datosServicio);
  // console.log("TANQUE DATOS EN SERVICIO", datosTanque);

  return (
    <div>
      <Dialog
        open={props.servicio}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <Title
          titulo={`Cargar nuevo servicio`}
          handleClose={() => {
            handleClose();
          }}
        />
        <Box
          border={0.5}
          borderColor={grey[300]}
          backgroundColor="#F4F6F6"
          p={2}
          py={2}
          mt={1}
        >
          <DialogContent>
            <Container maxWidth="xl">
              <form onSubmit={guardarServicio} autoComplete="off">
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={6}>
                    <TextField
                      style={style}
                      label="Servicio desde"
                      name="servicio_desde"
                      size="small"
                      type="date"
                      focused
                      required
                      fullWidth
                      value={moment(datosServicio.servicio_desde).format(
                        "YYYY-MM-DD"
                      )}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      style={style}
                      label="Servicio hasta"
                      name="servicio_hasta"
                      size="small"
                      type="date"
                      focused
                      required
                      fullWidth
                      value={moment(datosServicio.servicio_hasta).format(
                        "YYYY-MM-DD"
                      )}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      size={size}
                      placeholder="....."
                      style={style}
                      focused
                      color="primary"
                      required
                      name="lugar_servicio"
                      label="Lugar"
                      variant="outlined"
                      value={datosServicio.lugar_servicio}
                      inputProps={{
                        maxLength: 45,
                      }}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      size={size}
                      placeholder="....."
                      style={style}
                      focused
                      color="primary"
                      required
                      name="motivo_servicio"
                      label="Motivo"
                      variant="outlined"
                      value={datosServicio.motivo_servicio}
                      inputProps={{
                        maxLength: 45,
                      }}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={12} mb={2}>
                    <TextField
                      fullWidth
                      size={size}
                      placeholder="....."
                      style={style}
                      focused
                      color="primary"
                      required
                      name="detalle_servicio"
                      label="Detalle"
                      variant="outlined"
                      value={datosServicio.detalle_servicio}
                      inputProps={{
                        maxLength: 45,
                      }}
                      onChange={almacenar}
                    />
                  </Grid>
                </Grid>
                <Stack direction="row">
                  <Grid container direction={{ xs: "column", sm: "row" }}>
                    <Grid item xs={9}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleClose}
                      >
                        Volver
                      </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={disabledGuardar}
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              </form>
            </Container>
          </DialogContent>
        </Box>
      </Dialog>
      {notificacion ? (
        <Notify
          mensaje={mensaje}
          open={openSnack}
          color={colormensaje}
          finalizar={setFinalizar}
          severity={severitySnack}
        />
      ) : null}
      {/* ALERT --> SE MUESTRA SI HAY UN ERROR EN LA API (NO SE CONECTA O ESTÁ MAL LA RUTA, ETC.) */}
      {error && mensajeAlerta ? (
        <Alerta mensaje={mensajeAlerta} severity={severityAlerta} />
      ) : null}
    </div>
  );
}

export default Agregar_servicio;
