import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import { getData, putData } from "../../../../services/library";
import { createTheme,  Divider, ThemeProvider, useTheme } from "@mui/material";
import { Container,  Grid, Box, MenuItem, ListItemIcon,  } from '@mui/material';
import VerDatosReq from '../../requerimientos/ver_requerimientos/lista_requerimientos/VerDatosReq';
import { RemoveCircleOutline, } from '@mui/icons-material';
import  CheckIcon from "@mui/icons-material/Check";
import Notify from "../../../Notify";
import { logEventos } from "../../../../services/services";
import DialogConTextField from "../../../Dialogs/DialogConTextField";

export default function Requerimientosinternos(props) {
    const iconCheck = <CheckIcon sx={{ fontSize: 40 }} />;
    const globalTheme = useTheme();
    const [tabla, settabla] = useState(true);
    const [requerimientos, setrequerimientos] = useState();
    const [open, setOpen] = useState(false);
    const [modalAutorizar, setmodalAutorizar] = useState(false);
    const [modalRechazar, setmodalRechazar] = useState(false);
    const [datos, setDatos] = useState({});
    const [observaciones, setObservaciones] = useState("");
    const [disbtn, setdisbtn] = useState(false);
    const [notificacion, setNotificacion] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
    });

    const getRequerimientosinternos = () => {
        getData('/requerimientos_materiales/interno_compras', setrequerimientos)
    };

    useEffect(() => {
        getRequerimientosinternos()
    }, [tabla])

    const handleOpenModal = (datos) => {
        setOpen(true);
        setDatos(datos)
    };

    const handleOpenAutorizar = (datos) => {
        settabla(true)
        setmodalAutorizar(true)
        setDatos(datos)
    };

    const handleOpenRechazar = (datos) => {
        setmodalRechazar(true)
        setDatos(datos)
    };

    const handleClose = () => {
        modalRechazar ? setmodalRechazar(false) : setmodalAutorizar(false)
        getRequerimientosinternos()
    }

    const columns = useMemo(() =>
        [{ header: '#', accessorKey: 'idrequerimiento', size: 100 },
        { header: 'Fecha', accessorFn: (req) => moment(req.fecha_entrega).format("DD-MM-YYYY"), size: 100 },
        { header: 'Sucursal', accessorKey: 'nombre_sucursal', size: 130 },
        // { header: 'Estado', accessorKey: 'valor_estado', size: 130 },
        // { header: 'Solicita', accessorKey: 'usuario_registra', size: 130 },
        { header: 'Movil', accessorKey: 'patente', size: 100 },
        { header: 'Requerimientos', accessorFn: (req) => req.para_comprar === 1 ? "Para comprar" : " Interno", size: 100 }
        ]
        , []);

    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: globalTheme.palette.mode,
                    primary: globalTheme.palette.primary, //primary color for the table
                    info: {
                        main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            globalTheme.palette.mode === "light"
                                ? "rgb(234, 239, 241)"
                                : "#000", //pure black table in dark mode for fun
                    },
                },
                typography: {
                    button: {
                        textTransform: "none", //customize typography styles for all buttons in table by default
                        fontSize: "1rem",
                    },
                },
                components: {
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                fontSize: "0.8rem", //larger tooltip font size                                
                            },
                        },
                    },
                },

            }), [globalTheme])

    const muiSearchTextFieldProps = {
        placeholder: "Buscar..",
        sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
        variant: "outlined",
        color: "success",
        size: "small",
    };

    const initialState = {
        density: "compact",
        showGlobalFilter: true,
        enableGrouping: true,
        pagination: { pageIndex: 0, pageSize: 15 },
    };

    const tableHeadCellProps = {
        align: "left",
        sx: {
            fontSize: 16,
            color: "white",
            fontWeight: "normal",
            backgroundColor: "#2471A3",
            borderColor: "black",
        },
    };

    const rowBg = {
        1: "#abebc6",
        0: "#85c1e9",
    };

    async function autorizar(e, row) {
        setdisbtn(true)
        putData(e,
            '/requerimientos_materiales/' + datos.idrequerimiento, {
            autorizado_jefe_compras: 1,
            observaciones_jefe: observaciones,
            usuario_gestiona: localStorage.getItem('user'),
            estado: 50
        },
            setNotificacion,
        
      logEventos("Autorizar orden compra ", "Ordenes compra", "Ordenes compra", localStorage.getItem("user")))
    };

    const rechazar = (e) => {
        putData(e,
            '/requerimientos_materiales/' + datos.idrequerimiento,
            {
                autorizado_jefe_compras: 0,
                observaciones_jefe: observaciones,
                estado: 93,
                usuario_gestiona: localStorage.getItem('user')
            },
            setNotificacion,

            logEventos("Rechazar requerimiento interno", "Ordenes compra", "Ordenes compra", localStorage.getItem("user")))
    };

    return (
        <div>
            <Container maxWidth="xl" mt={1} mb={2} >
                {requerimientos && tabla ? (
                    <Box mt={2} boxShadow={3}>
                        <ThemeProvider theme={tableTheme}>
                            <MaterialReactTable
                                enableRowVirtualization
                                enableColumnVirtualization
                                columns={columns}
                                data={requerimientos}
                                enableRowActions
                                initialState={initialState}
                                positionActionsColumn="last"
                                muiTableHeadCellProps={tableHeadCellProps}
                                muiTableBodyCellProps={({ row }) => ({
                                    align: "left",
                                    sx: {
                                        backgroundColor:
                                            rowBg[row.original.para_comprar] ?? "#fff",
                                        color: "black",
                                        borderColor: "black",
                                    },
                                })}
                                muiSearchTextFieldProps={muiSearchTextFieldProps}
                                localization={MRT_Localization_ES}
                                enableColumnDragging={false}
                                renderRowActionMenuItems={({ closeMenu, row }) => [
                                    <MenuItem
                                        key={2}
                                        onClick={() => {
                                            handleOpenAutorizar(row.original);
                                            closeMenu();
                                        }}
                                        sx={{ m: 0 }}>
                                        <ListItemIcon>
                                            <CheckIcon color="success" />
                                        </ListItemIcon>
                                        Autorizar requerimiento
                                    </MenuItem>,
                                    <Divider />,
                                    <MenuItem
                                        disabled={row.original.para_comprar === 1}
                                        key={2}
                                        onClick={() => {
                                            handleOpenRechazar(row.original);
                                            closeMenu();
                                        }}
                                        sx={{ m: 0 }}>
                                        <ListItemIcon>
                                            <RemoveCircleOutline color="error" />
                                        </ListItemIcon>
                                        Rechazar requerimiento
                                    </MenuItem>
                                ]}
                                enableMultiRowSelection={false}
                                muiToolbarAlertBannerProps={false}
                                muiTableBodyRowProps={({ row }) => ({
                                    onClick: () => {
                                        row.getToggleSelectedHandler()
                                        handleOpenModal(row.original)
                                    },
                                    sx: {
                                        cursor: 'pointer'
                                    }
                                })}
                                enablePagination={false}
                                enableBottomToolbar={false}
                                columnFilterDisplayMode="popover"
                            />
                        </ThemeProvider>
                    </Box>
                ) : null}
                <Grid key={datos.idrequerimiento}>
                    {open ?
                        <VerDatosReq
                            datosreq={datos}
                            anchor={setOpen}
                            actualizar={getRequerimientosinternos} /> : null}
                </Grid>

            </Container>
            {modalAutorizar ? (
                <DialogConTextField
                    title={"Autorizar requerimiento"}
                    open={modalAutorizar}
                    setOpen={setmodalAutorizar}
                    notify={notificacion}
                    setNotify={setNotificacion}
                    btnText={"Autorizar"}
                    guardar={autorizar}
                    color={"#28a745 "}
                    subtitle={`¿Desea autorizar el requerimiento N° ${datos.idrequerimiento}?`}
                    value={observaciones}
                    setValue={setObservaciones}
                    icon={iconCheck}
                    />
            )
                : null}


            {modalRechazar ? (
              <DialogConTextField
              title={"Rechazar requerimiento"}
              open={modalRechazar}
              setOpen={setmodalRechazar}
              notify={notificacion}
              setNotify={setNotificacion}
              btnText={"Rechazar"}
              guardar={rechazar}
              color={"#f53649"}
              subtitle={`¿Desea rechazar el requerimiento N° ${datos.idrequerimiento}?`}
              value={observaciones}
              setValue={setObservaciones}
              icon={iconCheck}
              />
            )
                : null}

            {notificacion && notificacion.open ? (
                <Notify
                    mensaje={notificacion.mensaje}
                    open={notificacion.open}
                    color={notificacion.color}
                    handleClose={handleClose}
                    severity={notificacion.severity}
                />
            ) : null}
        </div>
    )
}

//========================================================================
/*----- Component --------------------------------------------------------/
// Developer     : Candela Grosso   
// Creation Date : 5/1/2024
// Version       : 1.0  
// Description   :  Con este componente el usuario es capaz de :
    - Visualizar todos los requerimientos internos cargados con la posibilidad de:
        * autorizar, rechazar y en caso de que lo requiera 
        * ver los datos de los requerimientos haciendo click en la fila correspondiente
//------Changes --------------------------------------------------------------------- -------------//
// Change Date   :
// Change by     :
// Change description: 
//========================================================================*/