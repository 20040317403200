//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio/Nicolas Pascucci
// Creation Date : 13/03/2024
// Version : 1
// Description : Tabla para ver los viajes pendientes y recibidos de las plantas
//------Changes ----------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useEffect, useState, useMemo } from "react";
import Navbar from "../Navbar";
import { UrlApi } from "../../services/apirest";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import { Container, Box, Typography, Tooltip, IconButton } from "@mui/material";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import Dialog_recibido from "./Dialog_recibido";
import CloseIcon from "@mui/icons-material/Close";
import DetalleViaje from "./DetalleViaje";
import AttachFileIcon from "@mui/icons-material/AttachFile"; //ADJUNTAR IMAGEN
import FileUploadStock from "../stock_almacen/FileUploadStock"; //ADJUNTAR IMAGEN
import Button from "@mui/material/Button";
import { logEventos } from "../../services/services";

function Sucursal_viajes() {
  const [newUuid, setNewUuid] = useState(""); //ADJUNTAR IMAGEN
  const [openCloseAdj, setOpenCloseAdj] = useState(false); //ADJUNTAR IMAGEN
  const [datosViaje, setDatosViaje] = useState({
    nro_remito_puntodeventa: "",
    nro_remito_numero: null,
  });
  const [tabla, setTabla] = useState(true);
  const [update, setUpdate] = useState(false);
  const [row, setRow] = useState();

  useEffect(() => {
    logEventos("Acceso al menú viajes a sucursales", "Viajes a Sucursales", "Ver los viajes pendientes a las sucursales autorizadas", localStorage.getItem("user"))
}, [])
  //--------------------------- OBTENER VIAJES RECIBIDOS/NO ---------------------------------//
  const [viajes, setViajes] = useState();
  const [hiddenmuestraNoRecibido, setHiddenmuestraNoRecibido] = useState(false);
  const [hiddenmuestraRecibidos, setHiddenhiddenmuestraRecibidos] =
    useState(true);

  const obtenerViajesNoRecibidos = () => {
    setHiddenmuestraNoRecibido(true);
    setHiddenhiddenmuestraRecibidos(false);
    fetch(
      UrlApi +
        "/viajes_abastecimiento/viajes_suc/No_Rec/" +
        localStorage.getItem("suc_auth")
    )
      .then((data) => data.json())
      .then((data) => setViajes(data));
  };

  useEffect(() => {
    obtenerViajesNoRecibidos();
  }, [tabla]);

  useEffect(() => {
    if (update === true) {
      obtenerViajesNoRecibidos();
      setUpdate(false)
    }
  }, [update]);

  //--------------------------- MARCAR RECIBIDO  ---------------------------------//
  const [abrirDialogRecibido, setAbrirDialogRecibido] = useState(false);

  //Cerrar el dialog
  const handleCloseRecibido = () => {
    setAbrirDialogRecibido(false);
    setUpdate(true);
    //para limpiar los datos
    datosViaje.nro_remito_numero = null;
    datosViaje.nro_remito_puntodeventa = "";
  };

  //Abrir el dialog
  const handleClickOpenRecibido = (row) => {
    //para limpiar los datos
    datosViaje.nro_remito_numero = null;
    datosViaje.nro_remito_puntodeventa = "";
    setAbrirDialogRecibido(true);
    setRow(row);
  };

  const handleOpenCloseAdj = (value) => {
    setOpenCloseAdj(false);
  };

  //--------------------------- TABLA -----------------------------------------//
  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem",
              },
            },
          },
        },
      }),
    [globalTheme]
  );
  const initialState = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
    // columnVisibility: { id_familia: false },
  };
  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };
  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "primary",
    size: "small",
  };
  const columns = useMemo(
    () => [
      { header: "ID", accessorKey: "id_viaje", size: 20 },
      {
        header: "Salida",
        accessorKey: "fecha",
        size: 65,
        type: "date",
        format: "DD-MM-YYYY",
      },
      {
        header: "Llegada",
        accessorKey: "fecha_llegada",
        size: 100,
        type: "date",
        format: "DD-MM-YYYY",
      },
      { header: "Producto", accessorKey: "tipo_viaje", size: 100 },
      { header: "Proveedor", accessorKey: "prov_boca", size: 115 },
      { header: "Ubicación Prov.", accessorKey: "nombre_boca", size: 155 },
      { header: "Empresa", accessorKey: "empresa_nombre", size: 110 },
      { header: "Destino", accessorKey: "nombre_sucursal", size: 120 },
      { header: "Estado", accessorKey: "estado_palabra", size: 120}
    ],
    []
  );
  return (
    <div>
      <Navbar />
      <Container maxWidth="xl" mt={1}>
        {viajes && tabla ? (
          <Box mt={2} mb={4}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
                enableGrouping // para permitir agrupar x estado x ej
                layoutMode="grid"
                muiTableHeadCellProps={tableHeadCellProps}
                muiTableBodyCellProps={({ row }) => ({
                  align: "left",
                  sx: {
                    color: "black",
                    borderColor: "black",
                  },
                })}
                enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
                enableColumnFilters={false} //desactiva filtros x  columna
                enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
                enableDensityToggle={false} //
                localization={MRT_Localization_ES} //idioma
                enableColumnDragging={false} //para arrastrar columnas
                initialState={initialState}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                columns={columns}
                data={viajes} //con lo q la completas (esto cambia siempre)
                enableStickyFooter
                enableEditing
                displayColumnDefOptions={{
                  'mrt-row-actions': {
                    header: 'Acciones', //change header text
                    size: 80, //make actions column wider
                  },
                }}
                renderDetailPanel={({ row }) => (
                  <DetalleViaje datos={row.original} />
                )}
                positionActionsColumn="last" // posicion de la columna de acciones al final
                // ACCIONES
                // renderToolbarInternalActions={(
                //   { table } // boton para nuevo , al lado del buscar
                // ) => (
                //   <>
                //     {!hiddenmuestraNoRecibido ? (
                //       <Tooltip title="Ver Viajes Pendientes">
                //         <IconButton
                //           color="error"
                //           onClick={() => {
                //             obtenerViajesNoRecibidos();
                //           }}
                //         >
                //           <CloseIcon />
                //         </IconButton>
                //       </Tooltip>
                //     ) : null}
                //     <MRT_FullScreenToggleButton table={table} />
                //   </>
                // )}
                renderTopToolbarCustomActions={() => (
                  <>
                    {!hiddenmuestraRecibidos ? (
                      <Typography variant="poster" fontSize={26}>
                        Viajes Pendientes a Sucursales Autorizadas
                      </Typography>
                    ) : null}
                  </>
                )}
                renderRowActions={({ row, table }) => (
                  //las acciones del costado editar y ver
                  <div>
                    {!hiddenmuestraRecibidos ? (
                      <div>
                      <Tooltip arrow placement="bottom" title="Ver adjunto">
                      <Button
                        backgroundColor="#ffffff"
                        size="small"
                        onClick={() => {
                          setNewUuid(row.original.uuid);
                          setOpenCloseAdj(!openCloseAdj);
                        }}
                      >
                        <AttachFileIcon />
                      </Button>
                    </Tooltip>
                    
                     <Tooltip
                     arrow
                     placement="bottom"
                     title="Finalizar viaje"
                   >
                     <IconButton
                       onClick={() => handleClickOpenRecibido(row.original)}
                       style={{ color: "green" }}
                     >
                       <DoneOutlineIcon />
                     </IconButton>
                   </Tooltip>
                   </div>
                    ) : null}
                  </div>
                )}
              />
            </ThemeProvider>
          </Box>
        ) : null}
      </Container>

      {abrirDialogRecibido ? (
        <Dialog_recibido
          abrirDialogRecibido={abrirDialogRecibido}
          row={row}
          onClose={() => {
            handleCloseRecibido(false);
          }}
        />
      ) : null}

      {openCloseAdj ? ( // ADJUNTAR IMAGEN
        <FileUploadStock
          fileId={newUuid}
          openclose={true}
          mostrar={false}
          onClose={() => handleOpenCloseAdj(false)} // Proporciona una función de cierre
        />
      ) : null}
    </div>
  );
}

export default Sucursal_viajes;
