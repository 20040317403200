//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio/Nicolas Pascucci
// Creation Date : 23/01/2024
// Version : 1
// Description : Tabla de acciones para que las factibilidades aprobadas y desaprobadas puedan ver
// las acciones y los adjuntos de las mismas.
//------Changes ------------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect, useMemo, useRef } from "react";
import { UrlApi } from "../../../services/apirest";
import {
  Dialog,
  createTheme,
  IconButton,
  ThemeProvider,
  Tooltip,
  useTheme,
} from "@mui/material";
import MaterialReactTable, {
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import { Box } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import FileUploadStock from "../../stock_almacen/FileUploadStock";
import Title from "../../Title";

function TablaAcciones(props) {
  const [accionesFact, setAccionesFact] = useState();
  const [factDatos, setFacDatos] = useState(props.factDatos);
  const [openAcciones, setOpenAcciones] = useState(props.openAcciones);
  const [openAdjunto, setOpenAdjunto] = useState(false);
  const [datosRow, setDatosRow] = useState({});
  //========================================================================/
  const obtenerAcciones = () => {
    fetch(UrlApi + "/acciones_fact/xidfactibilidad/" + factDatos.id_fact)
      .then((data) => data.json())
      .then((data) => setAccionesFact(data));
  };


  useEffect(() => {
    obtenerAcciones();
  }, [factDatos]);
  //================================== TABLA ======================================/
  const columns = useMemo(
    () => [
      { header: "ID", accessorKey: "id_accion", size: 30 },
      {
        header: "Fecha",
        accessorKey: "fecha_format",
        size: 50,
        type: "date",
        format: "DD-MM-YYYY",
      },
      { header: "Descripción", accessorKey: "accion", size: 250 },
      { header: "Usuario", accessorKey: "usuario", size: 100 },
      {
        header: "Uuid",
        accessorKey: "uuid_accion",
        size: 100,
        enableHiding: true,
      },
    ],
    []
  );
  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "success",
    size: "small",
  };

  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)" //background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //override to make tooltip font size larger
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const initialState = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 5 },
    columnVisibility: {
      uuid_accion: false,
    },
  };

  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };
  //=============================FUNCIONES===========================================/
  const handleClose = () => {
    setOpenAcciones(!openAcciones);
    if (props.onClose) {
      props.onClose();
    }
  };

  const handleClickOpenAdjuntos = (row) => {
    setOpenAdjunto(true);
    setDatosRow(row);
  };

  const handleOpenCloseAdj = (value) => {
    setOpenAdjunto(value);
  };

  return (
    <div>
      <Dialog open={openAcciones} onClose={handleClose} maxWidth="xl" fullWidth>
        <Title
          titulo={`Acciones de la factibilidad : ` + factDatos.id_fact}
          handleClose={() => {
            handleClose();
          }}
        />
        {accionesFact ? (
          <Box boxShadow={3} marginTop={-0}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableColumnResizing
                enableExpandAll={false}
                muiTableHeadCellProps={tableHeadCellProps}
                enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
                enableGrouping // para permitir agrupar x estado x ej
                layoutMode="grid"
                muiTableBodyCellProps={{
                  align: "left", //el contenido de la tabla
                }}
                enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
                enableColumnFilters={false} //desactiva filtros x  columna
                enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
                enableDensityToggle={false} //
                localization={MRT_Localization_ES} //idioma
                enableColumnDragging={false} //para arrastrar columnas
                initialState={initialState}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                columns={columns}
                data={accionesFact} //con lo q la completas (esto cambia siempre)
                enableStickyFooter
                enableRowActions
                positionActionsColumn="last" // posicion de la columna de acciones al final
                renderRowActions={({ row, table }) => (
                  //las acciones del costado editar y ver
                  <div>
                    <Tooltip arrow placement="left" title="Ver Adjuntos">
                      <IconButton
                        onClick={() => handleClickOpenAdjuntos(row.original)}
                        color="secondary"
                      >
                        <RemoveRedEyeIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              />
            </ThemeProvider>
          </Box>
        ) : null}
      </Dialog>

      {openAdjunto ? (
        <FileUploadStock
          fileId={datosRow.uuid_accion}
          openclose={true}
          mostrar={false}
          onClose={() => handleOpenCloseAdj(false)} // Proporciona una función de cierre
        />
      ) : null}
    </div>
  );
}

export default TablaAcciones;
