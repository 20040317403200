import React from "react";
import { useEffect, useState } from "react";
import { UrlApi } from "../../../services/apirest";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputMask from "react-input-mask";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import { validarCuit, existeCuit, existeDni } from "../validaciones";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { getData } from "../../../services/library";
import ErrorDialog from "../../Dialogs/ErrorDialog";

function FormCliente(props) {
  const [localidades, setLocalidades] = useState(null);
  const [segmentos, setSegmentos] = useState(null);
  const [subsegmentos, setSubsegmentos] = useState(null);
  const [provincias, setProvincias] = useState(null);
  const [sitIva, setSitIva] = useState(null);
  const [empresas, setEmpresas] = useState();
  const [condicionesPago, setCondicionesPago] = useState(null);
  const [unidadesFact, setUnidadesFact] = useState(null);
  const [sucursales, setSucursales] = useState();
  const [errorDialog, setErrorDialog] = useState(false);
  const [cambieProv, setCambieProv] = useState(false);

  //Fetchs para traer datos de la api a los desplegables
  const obtenerSit = () => {
    fetch(UrlApi + "/condiciones_iva")
      .then((data) => data.json())
      .then((data) => setSitIva(data));
  };

  const obtenerlocalidades = () => {
    fetch(UrlApi + "/localidades/prov/" + props.dataCliente.id_provincia) //Esta ruta trae las localidades segun la prov seleccionada
      .then((data) => data.json())
      .then((data) => setLocalidades(data));
  };

  const obtenerEmpresas = () => {
    fetch(UrlApi + "/empresas/granel") //Esta ruta trae las localidades segun la prov seleccionada
      .then((data) => data.json())
      .then((data) => setEmpresas(data));
  };

  const obtenersegmentos = () => {
    fetch(UrlApi + "/segmentos")
      .then((data) => data.json())
      .then((data) => setSegmentos(data));
  };

  const obtenersubsegmentos = () => {
    fetch(UrlApi + "/subsegmentos/seg/" + props.dataCliente.id_segmento) //Esta ruta trae los subsegmentos segun segmento seleccionado
      .then((data) => data.json()) //mando los datos que recibo en json
      .then((data) => setSubsegmentos(data)); //seteo choferes los json
  };

  const obtenerprovincias = () => {
    fetch(UrlApi + "/provincias")
      .then((data) => data.json())
      .then((data) => setProvincias(data));
  };

  const obtenerSucursales = () => {
    fetch(UrlApi + "/sucursales/empresayotra/" + props.dataCliente.id_empresa)
      .then((data) => data.json())
      .then((data) => setSucursales(data));
  };

  const almacenar = (e) => {
    props.setDataCliente({
      ...props.dataCliente,
      [e.target.name]: e.target.value === "" ? null : e.target.value,
    });
  };

  useEffect(() => {
    obtenersegmentos();
    obtenerprovincias();
    obtenerSit();
    obtenerEmpresas();
    getData("/condicion_pago", setCondicionesPago);
    getData("/desplegables/unidadFact", setUnidadesFact);
  }, []);

  useEffect(() => {
    obtenerSucursales();
  }, [props.dataCliente.id_empresa]);

  useEffect(() => {
    !!props.dataCliente.id_provincia && obtenerlocalidades();
  }, [props.dataCliente.id_provincia]);

  useEffect(() => {
    !!props.dataCliente.id_segmento && obtenersubsegmentos();
  }, [props.dataCliente.id_segmento]);

  useEffect(() => {
    if (cambieProv) {
      props.dataCliente.id_localidad = "";
    }
    obtenerlocalidades();
  }, [props.dataCliente.id_provincia, cambieProv]);

  const [cambieSeg, setCambieSeg] = useState(false);

  useEffect(() => {
    if (cambieSeg) {
      props.dataCliente.id_subsegmento = "";
    }
    props.dataCliente.id_segmento && obtenersubsegmentos();
  }, [props.dataCliente.id_segmento, cambieSeg]);

  const [cambieEmpresa, setCambieEmpresa] = useState(false);

  useEffect(() => {
    if (cambieEmpresa) {
      props.dataCliente.id_sucursal = "";
    }
    obtenerSucursales();
  }, [props.dataCliente.id_empresa, cambieEmpresa]);

  const style = {
    backgroundColor: "#fff",
    width: "100%",
  };

  const variant = "standard";
  const readOnly = props.readOnly;
  const txtProps = {};

  useEffect(() => {
    if (!props.edicion) {
      if (props.esDomEntrega === true) {
        props.setDataDir((prevDataDir) => ({
          ...prevDataDir,
          id_provincia: props.dataCliente.id_provincia,
          id_localidad: props.dataCliente.id_localidad,
          telefono1: props.dataCliente.telefono1,
          telefono2: props.dataCliente.telefono2,
          telefono3: props.dataCliente.telefono3,
          mails: props.dataCliente.mails,
          id_sucursal: props.dataCliente.id_sucursal,
        }));
      } else {
        props.setDataDir({
          ...props.dataDir,
          // id_provincia: props.dataDir.id_provincia || "",
          // id_localidad: props.dataDir.id_localidad || "",
          // telefono1: props.dataDir.telefono1 || "",
          // telefono2: props.dataDir.telefono2 || "",
          // telefono3: props.dataDir.telefono3 || "",
          // mails: props.dataDir.mails || "",
          // id_sucursal:props.dataDir.id_sucursal || ""
          id_provincia: "",
          id_localidad: "",
          telefono1: "",
          telefono2: "",
          telefono3: "",
          mails: "",
          id_sucursal: "",
        });
      }
    }
  }, [
    props.esDomEntrega,
    props.dataCliente.id_provincia,
    props.dataCliente.id_localidad,
    props.dataCliente.telefono1,
    props.dataCliente.telefono2,
    props.dataCliente.telefono3,
    props.dataCliente.mails,
    props.dataCliente.id_sucursal,
  ]);

  //Si no me fijo si hay algo en el campo dataCliente.cuit, y paso por el txtfield, y se dispara el "onblur", dice que ya existe el DNI o CUIT (cuando en realidad, el campo está vacío). Ergo: antes de verificar si ese cuit o dni, voy a fijarme si props.dataCliente.cuit, tiene algun digito que no sea "0". No lo puedo hacer con .length porque al utilizar una mascara, el campo ya arranca teniendo un length de 10(caso dni) o mas(caso cuit). Entonces lo unico que me queda es verificar si alguno de esos digitos no es 0. Si props.dataCliente.cuit contiene digitos que no son "0", entonces lo verifica.

  async function verificarCuit(e, setDataCliente, dataCliente) {
    const hayCuit = /[1-9]/.test(props.dataCliente.cuit);
    if (hayCuit) {
      const validado = validarCuit(e, props.setDataCliente, props.dataCliente);
      if (validado) {
        const coincidencias = await existeCuit(props.dataCliente.cuit);
        if (coincidencias.length > 0) {
          setErrorDialog(true);
          props.setDataCliente({ ...props.dataCliente, cuit: "" });
        }
      }
    }
  }

  async function verificarDni(e, setDataCliente, dataCliente) {
    const hayDni = /[1-9]/.test(props.dataCliente.cuit);

    if (hayDni) {
      const coincidencias = await existeDni(props.dataCliente.cuit);
      if (coincidencias.length > 0) {
        setErrorDialog(true);
        props.setDataCliente({ ...props.dataCliente, cuit: "" });
      }
    }
  }

  const edicion = props.edicion || false;

  return (
    <div>
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        alignContent="stretch"
        wrap="wrap"
        p={1}
        maxWidth="xl"
      >
        <Grid item xs={edicion ? 12 : 6}>
          <TextField
            autoFocus
            focused={txtProps.focused}
            disabled={props.dataCliente.apellido || props.dataCliente.apellido}
            required={!props.dataCliente.apellido && !props.dataCliente.nombre}
            autoComplete="off"
            color="primary"
            variant={variant}
            style={style}
            type="text"
            name="razon_social"
            label="Razon Social"
            size="small"
            onChange={almacenar}
            value={
              props.dataCliente.razon_social &&
              props.dataCliente.razon_social.toUpperCase()
            }
            inputProps={{ maxLength: 200, readOnly: readOnly }}
          />
        </Grid>
        {!edicion && (
          <>
            <Grid item xs={3}>
              <TextField
                focused={txtProps.focused}
                disabled={!!props.dataCliente.razon_social}
                required={!props.dataCliente.razon_social}
                autoComplete="off"
                color="primary"
                variant={variant}
                style={style}
                type="text"
                name="apellido"
                label="Apellido"
                size="small"
                onChange={almacenar}
                value={
                  props.dataCliente.apellido &&
                  props.dataCliente.apellido.toUpperCase()
                }
                inputProps={{ maxLength: 200, readOnly: readOnly }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                focused={txtProps.focused}
                disabled={!!props.dataCliente.razon_social}
                required={!props.dataCliente.razon_social}
                autoComplete="off"
                color="primary"
                variant={variant}
                style={style}
                type="text"
                name="nombre"
                label="Nombre"
                size="small"
                onChange={almacenar}
                inputProps={{ maxLength: 200, readOnly: readOnly }}
                value={
                  props.dataCliente.nombre &&
                  props.dataCliente.nombre.toUpperCase()
                }
              />
            </Grid>
          </>
        )}

        <Grid
          item
          xs={
            parseInt(props.dataCliente.situacion_iva) === 1 ||
            parseInt(props.dataCliente.situacion_iva) === 4
              ? 2
              : 3
          }
        >
          <FormControl
            required
            style={style}
            variant={variant}
            size="small"
            disabled={readOnly}
            focused={txtProps.focused}
          >
            <InputLabel>Cuit/Dni</InputLabel>
            <Select
              name="tipo_doc_afip"
              value={props.dataCliente.tipo_doc_afip}
              label="Cuit/Dni"
              onChange={almacenar}
            >
              <MenuItem key={1} value={80}>
                Cuit
              </MenuItem>
              <MenuItem key={2} value={96}>
                Dni
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {props.dataCliente.tipo_doc_afip === 80 ? (
          <Grid
            item
            xs={
              parseInt(props.dataCliente.situacion_iva) === 1 ||
              parseInt(props.dataCliente.situacion_iva) === 4
                ? 2
                : 3
            }
          >
            <InputMask
              mask="99-99999999-9"
              value={props.dataCliente.cuit}
              maskChar=" "
              onChange={(e) =>
                props.setDataCliente({
                  ...props.dataCliente,
                  cuit: e.target.value,
                })
              }
              onBlur={(e) =>
                verificarCuit(e, props.setDataCliente, props.dataCliente)
              }
              disabled={readOnly}
            >
              {() => (
                <TextField
                  variant={variant}
                  size="small"
                  style={style}
                  type="text"
                  name="cuit"
                  label="Numero Cuit"
                  focused={txtProps.focused}
                />
              )}
            </InputMask>
          </Grid>
        ) : (
          <Grid
            item
            xs={
              parseInt(props.dataCliente.situacion_iva) === 1 ||
              parseInt(props.dataCliente.situacion_iva) === 4
                ? 2
                : 3
            }
          >
            <InputMask
              mask="99.999.999"
              value={props.dataCliente.cuit}
              disabled={readOnly}
              maskChar=" "
              onChange={almacenar}
              onBlur={(e) =>
                verificarDni(e, props.setDataCliente, props.dataCliente)
              }
            >
              {() => (
                <TextField
                  variant={variant}
                  size="small"
                  style={style}
                  type="text"
                  name="cuit"
                  label="Numero Dni"
                  focused={txtProps.focused}
                />
              )}
            </InputMask>
          </Grid>
        )}
        <Grid item xs={3}>
          <FormControl required style={style} size="small">
            <InputLabel>Condición de pago</InputLabel>
            <Select
              name="id_condicionpago"
              value={props.dataCliente.id_condicionpago}
              label="Condición de pago"
              onChange={almacenar}
              variant="standard"
              disabled={readOnly}
            >
              {condicionesPago
                ? condicionesPago.map((elemento) => (
                    <MenuItem key={elemento.id} value={elemento.id}>
                      {elemento.condicion}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl
            style={style}
            size="small"
            variant={variant}
            disabled={readOnly}
            focused={txtProps.focused}
            required
          >
            <InputLabel> Situacion Iva</InputLabel>
            <Select
              required
              label="Situacion Iva"
              name="situacion_iva"
              value={props.dataCliente.situacion_iva}
              onChange={almacenar}
            >
              {sitIva
                ? sitIva.map((elemento) => (
                    <MenuItem key={elemento.id} value={elemento.id}>
                      {elemento.descripcion}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
        </Grid>
        {parseInt(props.dataCliente.situacion_iva) === 4 && (
          <Grid item xs={2}>
            <FormControlLabel
              sx={{ "& .MuiFormControlLabel-label": { fontSize: 13 } }}
              labelPlacement="right"
              control={
                <Checkbox
                  disabled={readOnly}
                  checked={props.dataCliente.factura_consfinal === 1}
                  onChange={(e) =>
                    props.setDataCliente({
                      ...props.dataCliente,
                      factura_consfinal: e.target.checked ? 1 : 0,
                    })
                  }
                />
              }
              label="FACTURAR A CONS. FINAL"
            />
          </Grid>
        )}
        {parseInt(props.dataCliente.situacion_iva) === 1 && (
          <Grid item xs={2}>
            <FormControlLabel
              sx={{ "& .MuiFormControlLabel-label": { fontSize: 13 } }}
              style={{ fontSize: 10 }}
              labelPlacement="center"
              control={
                <Checkbox
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                  disabled={readOnly}
                  checked={props.dataCliente.facturacion_especial === 1}
                  onChange={(e) =>
                    props.setDataCliente({
                      ...props.dataCliente,
                      facturacion_especial: e.target.checked ? 1 : 0,
                    })
                  }
                />
              }
              label="FACTURACIóN ESPECIAL"
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <TextField
            focused={txtProps.focused}
            autoComplete="off"
            variant={variant}
            size="small"
            required
            style={style}
            placeholder="Direccion Fiscal"
            id="direccion_fiscal"
            name="direccion_fiscal"
            label="Direccion Fiscal"
            color="primary"
            // value={
            //   props.dataCliente.direccion_fiscal &&
            //   props.dataCliente.direccion_fiscal.replace(/\b\w/g, (l) =>
            //     l.toUpperCase()
            //   )
            // }
            value={
              props.dataCliente.direccion_fiscal &&
              props.dataCliente.direccion_fiscal.toUpperCase()
            }
            onChange={almacenar}
            inputProps={{ maxLength: 120, readOnly: readOnly }}
          />
        </Grid>

        <Grid item xs={3}>
          <FormControl
            focused={txtProps.focused}
            required
            style={style}
            variant={variant}
            size="small"
            disabled={readOnly}
          >
            <InputLabel> Empresa </InputLabel>
            <Select
              label="Empresa"
              color="primary"
              name="id_empresa"
              value={props.dataCliente.id_empresa}
              onChange={(e) => {
                almacenar(e);
                setCambieEmpresa(true);
              }}
            >
              {empresas
                ? empresas.map((elemento) => (
                    <MenuItem
                      key={elemento.idempresa}
                      value={elemento.idempresa}
                    >
                      {elemento.empresa}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <FormControl
            focused={txtProps.focused}
            required
            style={style}
            variant={variant}
            size="small"
            disabled={readOnly}
          >
            <InputLabel> Sucursal </InputLabel>
            <Select
              label="Sucursal"
              color="primary"
              name="id_sucursal"
              value={props.dataCliente.id_sucursal}
              onChange={(e) => {
                almacenar(e);
              }}
            >
              {sucursales
                ? sucursales.map((elemento) => (
                    <MenuItem key={elemento.id} value={elemento.id}>
                      {elemento.nombre}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={!props.edicion ? 4.5 : 6}>
          <FormControl
            focused={txtProps.focused}
            required
            style={style}
            variant={variant}
            size="small"
            disabled={readOnly}
          >
            <InputLabel> Provincia </InputLabel>
            <Select
              label="Provincia"
              color="primary"
              name="id_provincia"
              value={props.dataCliente.id_provincia}
              onChange={(e) => {
                almacenar(e);
                setCambieProv(true);
              }}
              // onChange={(e) => {
              //   almacenar(e);
              //   setCambieProv(true);
              //   if (props.esDomEntrega) {
              //     props.setDataDir((prevDataDir) => ({
              //       ...prevDataDir,
              //       id_provincia: e.target.value
              //     }));
              //   } else {
              //     props.setDataDir((prevDataDir) => ({
              //       ...prevDataDir,
              //       id_provincia: ""
              //     }));
              //   }
              // }}
            >
              {provincias
                ? provincias.map((elemento) => (
                    <MenuItem key={elemento.id} value={elemento.id}>
                      {elemento.provincia}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={!props.edicion ? 4.5 : 6}>
          <FormControl
            focused={txtProps.focused}
            required
            style={style}
            variant={variant}
            size="small"
            disabled={readOnly}
          >
            <InputLabel>Localidad</InputLabel>
            <Select
              label="Localidad"
              name="id_localidad"
              value={props.dataCliente.id_localidad}
              onChange={almacenar}
              // onChange={(e)=>{
              //   almacenar(e)
              //   if (props.esDomEntrega) {
              //     props.setDataDir((prevDataDir) => ({
              //       ...prevDataDir,
              //       id_localidad: e.target.value
              //     }));
              //   } else {
              //     props.setDataDir((prevDataDir) => ({
              //       ...prevDataDir,
              //       id_localidad: ""
              //     }));
              //   }
              // }}
            >
              {localidades
                ? localidades.map((elemento) => (
                    <MenuItem key={elemento.id} value={elemento.id}>
                      {elemento.localidad}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
        </Grid>

        {!props.edicion && (
          <Grid item xs={3}>
            <FormControlLabel
              sx={{ "& .MuiFormControlLabel-label": { fontSize: 13 } }}
              labelPlacement="right"
              control={
                <Checkbox
                  checked={props.esDomEntrega}
                  onChange={(e) => props.setEsDomEntrega(e.target.checked)}
                />
              }
              label="ES DOMICILIO DE ENTREGA"
            />
          </Grid>
        )}

        <Grid item xs={5}>
          <FormControl
            focused={txtProps.focused}
            required
            style={style}
            variant={variant}
            size="small"
            disabled={readOnly}
          >
            <InputLabel>Segmento</InputLabel>
            <Select
              label="Segmento"
              name="id_segmento"
              value={props.dataCliente.id_segmento}
              onChange={(e) => {
                almacenar(e);
                setCambieSeg(true);
              }}
            >
              {segmentos
                ? segmentos.map((elemento) => (
                    <MenuItem key={elemento.id} value={elemento.id}>
                      {elemento.segmento} 
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={5}>
          <FormControl
            focused={txtProps.focused}
            style={style}
            variant={variant}
            size="small"
            disabled={readOnly}
          >
            <InputLabel>Subsegmento</InputLabel>
            <Select
              label="Subsegmento"
              name="id_subsegmento"
              value={props.dataCliente.id_subsegmento}
              onChange={almacenar}
            >
              {subsegmentos
                ? subsegmentos.map((elemento) => (
                    <MenuItem key={elemento.id} value={elemento.id}>
                      {elemento.subsegmento} - {elemento.codigo_subsegmento}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl
            style={style}
            size="small"
            disabled={readOnly}
            variant={variant}
            focused={txtProps.focused}
          >
            <InputLabel> Unidad Fact: </InputLabel>
            <Select
              label="Facturacion En: "
              name="unidad_fact"
              value={props.dataCliente.unidad_fact}
              onChange={almacenar}
            >
              {unidadesFact
                ? unidadesFact.map((elemento) => (
                    <MenuItem key={elemento.id} value={elemento.id}>
                      {elemento.valor}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            focused={txtProps.focused}
            autoComplete="off"
            size="small"
            variant={variant}
            style={style}
            placeholder="Mails"
            name="mails"
            label="Mails"
            color="primary"
            value={props.dataCliente.mails}
            onChange={almacenar}
            inputProps={{ maxLength: 500, readOnly: readOnly }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            focused={txtProps.focused}
            autoComplete="off"
            size="small"
            variant={variant}
            fullWidth
            type="text"
            color="primary"
            placeholder="Telefono 1"
            name="telefono1"
            label="Telefono 1"
            onChange={almacenar}
            value={props.dataCliente.telefono1}
            inputProps={{
              maxLength: 10,
              readOnly: readOnly,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{props.codArg}</InputAdornment>
              ),
            }}
            helperText="Complete código de área (sin 0) + el número sin el 15. Ej:341xxxxxx "
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            focused={txtProps.focused}
            autoComplete="off"
            size="small"
            variant={variant}
            fullWidth
            type="text"
            color="primary"
            placeholder="Telefono 2"
            name="telefono2"
            label="Telefono 2"
            onChange={almacenar}
            value={props.dataCliente.telefono2}
            inputProps={{
              maxLength: 10,
              readOnly: readOnly,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{props.codArg}</InputAdornment>
              ),
            }}
            helperText="Complete código de área (sin 0) + el número sin el 15. Ej:341xxxxxx "
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            onKeyDown={(e) => {
              if (e.key === "Tab") {
                props.setActiveTab("domicilio");
              }
            }}
            focused={txtProps.focused}
            autoComplete="off"
            size="small"
            variant={variant}
            fullWidth
            type="text"
            color="primary"
            placeholder="Telefono 3"
            name="telefono3"
            label="Telefono 3"
            onChange={almacenar}
            value={props.dataCliente.telefono3}
            inputProps={{
              maxLength: 10,
              readOnly: readOnly,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{props.codArg}</InputAdornment>
              ),
            }}
            helperText="Complete código de área (sin 0) + el número sin el 15. Ej:341xxxxxx "
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </Grid>
      </Grid>
      {errorDialog && (
        <ErrorDialog
          open={errorDialog}
          setOpen={setErrorDialog}
          title={"CLIENTE EXISTENTE"}
          subtitle={
            "El CUIT/DNI que intenta ingresar YA FUE REGISTRADO bajo otra razón social. Revise el listado de clientes."
          }
        />
      )}
    </div>
  );
}

export default FormCliente;
