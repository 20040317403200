import React from "react";
import { useEffect, useState } from "react";
import { UrlApi } from "../../../services/apirest";
import { Container } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import moment from "moment";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import PersonSearchSharpIcon from "@mui/icons-material/PersonSearchSharp";
import Grid from "@mui/material/Grid";
import InputMask from "react-input-mask";
import { v4 as uuidv4 } from "uuid";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

//Para el Snackbar
import Notify from "../../Notify";
import Alerta from "../../Alerta";
import Title from "../../Title";
import DatosTanques from "./DatosTanques";
import DialogError from "../../Dialogs/ErrorDialog"

function Addtanque(props) {
  const [datosformulario, setDatosformulario] = useState({
    nro_serie: "",
    id_sisven: "",
    id_fabricante: null,
    fecha_fabricacion: null,
    fecha_compra: null,
    estado_fisico: null,
    capacidad: null,
    observaciones: null,
    baja: 0, //indica que el tanque no esta dado de baja
    fecha_ubicacion: null,
    id_estado: 2,
    fecha_estado: null,
    duenio: null,
    delivery_stop: "0",
    fecha_deliverystop: null,
    usuario: localStorage.getItem("user"),
    id_sucursal: null,
    vto_pruebahidraulica: "",
    incompleto: "",
    fecha_pruebahidraulica: "",
  });

  const [fabricantes, setFabricantes] = useState();
  const [sucursales, setSucursales] = useState();
  const [estados, setEstados] = useState();
  const [duenios, setDuenios] = useState();
  const [estadofisico, setEstadofisico] = useState();
  const [uuidTk, setUuidTk] = useState("");
  const [disBtn, setDisBtn] = useState(false);
  const [existe, setExiste] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  //estados para manejo de errores
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
  const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
  const [mensajeAlerta, setMensajeAlerta] = useState(""); //mensaje que contendrá el componente "alerta"
  const [finalizar, setFinalizar] = useState(); //finalizar === 1 lo manda como prop el componente "notify" e indica que la transaccion ha finalizado y debe ejecutarse la funcion para cerrar
  const [severityAlerta, setSeverityAlerta] = useState(""); //define severidad de la alerta (success error o warning)

  const almacenar = (e) => {
    setDatosformulario({
      ...datosformulario,
      [e.target.name]: e.target.value,
    });
  };

  const obtenerEstados = () => {
    fetch(UrlApi + "/estados_tanques")
      .then((data) => data.json())
      .then((data) => setEstados(data));
  };

  const obtenerEstadoFisico = () => {
    fetch(UrlApi + "/desplegables/estadoFisicoTq")
      .then((data) => data.json())
      .then((data) => setEstadofisico(data));
  };

  const obtenerDuenios = () => {
    fetch(UrlApi + "/desplegables/dueniotanque")
      .then((data) => data.json())
      .then((data) => setDuenios(data));
  };

  const obtenerFabricantes = () => {
    fetch(UrlApi + "/fabricantes")
      .then((data) => data.json())
      .then((data) => setFabricantes(data));
  };

  const obtenerSucursales = () => {
    fetch(UrlApi + "/sucursales")
      .then((data) => data.json())
      .then((data) => setSucursales(data));
  };

  const obtenerRegistro = () => {
    fetch(
      UrlApi +
        `/tanques/existe/${datosformulario.nro_serie}/${datosformulario.id_fabricante}`
    )
      .then((data) => data.json())
      .then((data) => setExiste(data));
  };

  useEffect(() => {
    obtenerFabricantes();
    obtenerSucursales();
    obtenerEstados();
    obtenerEstadoFisico();
    obtenerDuenios();
    setUuidTk(uuidv4());
  }, []);

  useEffect(() => {
    if (datosformulario.nro_serie && datosformulario.id_fabricante) {
      obtenerRegistro();
    }
  }, [datosformulario.nro_serie, datosformulario.id_fabricante]);

  const handleClose = () => {
    props.alta(false);
    props.tabla(true);
  };

  const guardarTanque = async (e) => {
    e.preventDefault();
    if (existe.length > 0) {
      handleDatosExisten(e);
      setDisBtn(true);
    } else {
      setDisBtn(true);

      const response = await fetch(UrlApi + "/tanques", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          nro_serie: datosformulario.nro_serie,
          id_sisven:
            datosformulario.id_sisven === "" ? null : datosformulario.id_sisven,
          id_fabricante: datosformulario.id_fabricante,
          fecha_fabricacion:
            datosformulario.fecha_fabricacion === ""
              ? null
              : datosformulario.fecha_fabricacion,
          fecha_compra:
            datosformulario.fecha_compra === ""
              ? null
              : datosformulario.fecha_compra,
          estado_fisico: datosformulario.estado_fisico,
          capacidad:
            datosformulario.capacidad === "" ? null : datosformulario.capacidad,
          observaciones: datosformulario.observaciones,
          baja: 0,
          fecha_ubicacion:
            datosformulario.fecha_ubicacion === ""
              ? null
              : datosformulario.fecha_ubicacion,
          id_estado: datosformulario.id_estado,
          fecha_estado:
            datosformulario.fecha_estado === ""
              ? null
              : datosformulario.fecha_estado,
          duenio: datosformulario.duenio,
          delivery_stop: 0,
          usuario: localStorage.getItem("user"),
          id_sucursal: datosformulario.id_sucursal,
          vto_pruebahidraulica:
            datosformulario.vto_pruebahidraulica === ""
              ? null
              : datosformulario.vto_pruebahidraulica,
          nro_tanque: uuidTk,
          incompleto: 0,
          fecha_pruebahidraulica:
            datosformulario.fecha_pruebahidraulica === ""
              ? null
              : datosformulario.fecha_pruebahidraulica,
        }),
      });
      if (response.ok === false) {
        console.log("ERROR EN API");
        const error = response && response.json();
        setError(error);
        setSeverityAlerta("error");
        setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
      } else {
        console.log("Conexión con api OK");
        try {
          const json = await response.json();
          if (json.message) {
            guardarMovimiento(e);
            json.message !== "OK" &&
              console.log("RES EN API INCORRECTO. REVISAR QUE SEA 'OK'-");
          } else if (json.sqlMessage) {
            json && setError(json);
            setMensajeAlerta(
              "SQL erróneo (" + json.code + " Errno: " + json.errno + " )"
            );
          } else {
            setMensajeAlerta("ERROR DESCONOCIDO.");
          }
        } catch (error) {
          if (error.toString().includes("is not valid JSON")) {
            guardarMovimiento(e);
            console.log("ACTUALIZAR RESPUESTA DE API A JSON.");
          } else {
            setMensajeAlerta("ERROR DESCONOCIDO.");
          }
        }
      }
    }
  };

  const guardarMovimiento = async (e) => {
    setDisBtn(true);
    e.preventDefault();
    const response = await fetch(UrlApi + "/movimientos_tanques", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        // id_tanque:null,
        id_sucursal: datosformulario.id_sucursal,
        usuario: localStorage.getItem("user"),
        uuid_tanque: uuidTk,
      }),
    });
    if (response.ok === false) {
      console.log("ERROR EN API");
      const error = response && response.json();
      setError(error);
      setSeverityAlerta("error");
      setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      console.log("Conexión con api OK");
      try {
        const json = await response.json();
        if (json.message) {
          //Si ese mensaje es "OK"
          if (json.message === "OK") {
            console.log("Guardado Satisfactoriamente");
            setNotificacion(true);
            setOpenSnack(true);
            setMensaje("Guardado");
          } else {
            console.log("res en api incorrecto. Revisar que sea 'OK'");
            setNotificacion(true);
            setOpenSnack(true);
            setMensaje("Registro Guardado. REVISAR RESPUESTA DE API (E_S)");
          }
        } else if (json.sqlMessage) {
          json && setError(json);
          setMensajeAlerta(
            "SQL erróneo (" + json.code + " Errno: " + json.errno + " )"
          );
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje("Guardado");
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      }
    }
  };

  useEffect(() => {
    if (finalizar === 1) handleClose();
  }, [finalizar]);

  //************************************************************************************************** */

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  const handleFechaPH = (e) => {
    const fecha = moment(e.target.value).format("YYYY-MM-DD");
    const vencimiento = moment(moment(fecha).add(10, "y")._d).format(
      "YYYY-MM-DD"
    );
    setDatosformulario({
      ...datosformulario,
      fecha_pruebahidraulica: e.target.value,
      vto_pruebahidraulica: vencimiento,
    });
  };

  const handleDatosExisten = (e) => {
    e.preventDefault();
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDisBtn(false);
  };

  // const [showErrorDialog, setShowErrorDialog]=useState(false);

  // //Esto va a validar que no exista un tanque cargado del mismo fabricante con el mismo nro de serie.
  // async function buscaRepeticion() {
  //   if (!!datosformulario.nro_serie && !!datosformulario.id_fabricante) {
  //     const response = await fetch(
  //       UrlApi +
  //         "/tanques/fabricanteyserie/" +
  //         datosformulario.id_fabricante +
  //         "/" +
  //         datosformulario.nro_serie
  //     );
  //     const repeticion = await response.json();
  //     if(repeticion.length>0){
  //       alert("No puede registrarse dos veces el mismo N° de serie bajo el mismo fabricante.")
  //       setShowErrorDialog(true)
  //       setDatosformulario({...datosformulario, nro_serie:"", id_fabricante:null})
  //     } else {
  //       return
  //     }
  //   }
  // }

  return (
    <div>
      {notificacion ? (
        <Notify
          mensaje={mensaje}
          open={openSnack}
          color={"#D4EFDF"}
          finalizar={setFinalizar}
          severity={"success"}
        />
      ) : null}
      {error && mensajeAlerta ? (
        <Alerta mensaje={mensajeAlerta} severity={"error"} />
      ) : null}

      <Container>
        <Title titulo={"Nuevo Tanque"} handleClose={handleClose} />
        <form onSubmit={guardarTanque}>
          <Box
            mt={1}
            mb={3}
            backgroundColor="#F4F6F6"
            p={3}
            border={0.5}
            borderColor={"#ABB2B9"}
            borderRadius={4}
          >
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <TextField
                  // onBlur={() => buscaRepeticion()}
                  autoFocus
                  style={style}
                  placeholder="Numero de serie"
                  inputProps={{
                    maxLength: 15,
                  }}
                  type="text"
                  required
                  focused
                  name="nro_serie"
                  id="nro_serie"
                  label="N° de Serie"
                  color="primary"
                  onChange={almacenar}
                  value={datosformulario.nro_serie}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <StickyNote2Icon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  placeholder="Id Sisven"
                  style={style}
                  focused
                  name="id_sisven"
                  label="Número Sisven"
                  color="primary"
                  onChange={almacenar}
                  value={datosformulario.id_sisven}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{
                    maxLength: 8,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <StickyNote2Icon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <InputMask
                  mask="9.99"
                  value={datosformulario.capacidad}
                  disabled={false}
                  maskChar="0"
                  onChange={almacenar}
                >
                  {() => (
                    <TextField
                      style={style}
                      required
                      type="text"
                      focused
                      name="capacidad"
                      label="Capacidad (m3)"
                    />
                  )}
                </InputMask>
              </Grid>

              <Grid item xs={3}>
                <FormControl fullWidth focused required style={style}>
                  <InputLabel>Nuevo / Usado</InputLabel>
                  <Select
                    name="estado_fisico"
                    focused
                    InputLabelProps={{ shrink: true, required: true }}
                    value={datosformulario.estado_fisico}
                    label="Nuevo / Usado"
                    onChange={almacenar}
                  >
                    {estadofisico
                      ? estadofisico.map((elemento) => (
                          <MenuItem key={elemento.id} value={elemento.id}>
                            {elemento.valor}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl focused style={style}>
                  <InputLabel>Dueño</InputLabel>
                  <Select
                    name="duenio"
                    focused
                    InputLabelProps={{ shrink: true, required: true }}
                    value={datosformulario.duenio}
                    label="Dueño"
                    onChange={almacenar}
                  >
                    {duenios
                      ? duenios.map((elemento) => (
                          <MenuItem key={elemento.id} value={elemento.id}>
                            {elemento.valor}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl
                  focused
                  required
                  style={style}
                  // onBlur={() => buscaRepeticion()}
                >
                  <InputLabel>Fabricante</InputLabel>
                  <Select
                    name="id_fabricante"
                    focused
                    InputLabelProps={{ shrink: true, required: true }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={datosformulario.id_fabricante}
                    label="Fabricante"
                    onChange={almacenar}
                  >
                    {fabricantes
                      ? fabricantes.map((elemento) => (
                          <MenuItem key={elemento.id} value={elemento.id}>
                            {elemento.nombre}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  style={style}
                  focused
                  color="primary"
                  id="date"
                  label="Fecha Fabricacion"
                  name="fecha_fabricacion"
                  type="date"
                  onChange={almacenar}
                  value={moment(datosformulario.fecha_fabricacion).format(
                    "YYYY-MM-DD"
                  )}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    max: moment(new Date()).format("YYYY-MM-DD"),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  style={style}
                  focused
                  color="primary"
                  label="Fecha de compra"
                  name="fecha_compra"
                  type="date"
                  onChange={almacenar}
                  value={moment(datosformulario.fecha_compra).format(
                    "YYYY-MM-DD"
                  )}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: datosformulario.fecha_fabricacion,
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <FormControl style={style} focused required>
                  <InputLabel>Sucursal</InputLabel>
                  <Select
                    name="id_sucursal"
                    focused
                    InputLabelProps={{ shrink: true, required: true }}
                    labelId="demo-simple-select-label"
                    value={datosformulario.id_sucursal}
                    label="Sucursal"
                    onChange={almacenar}
                  >
                    {sucursales
                      ? sucursales.map((elemento) => (
                          <MenuItem key={elemento.id} value={elemento.id}>
                            {elemento.nombre}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  style={style}
                  focused
                  color="primary"
                  label="Fecha Ingreso Sucursal"
                  name="fecha_ubicacion"
                  type="date"
                  onChange={almacenar}
                  value={moment(datosformulario.fecha_ubicacion).format(
                    "YYYY-MM-DD"
                  )}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: datosformulario.fecha_compra,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <FormControl style={style} focused>
                  <InputLabel disabled>Estado</InputLabel>
                  <Select
                    disabled
                    required
                    name="id_estado"
                    focused
                    InputLabelProps={{ shrink: true, required: true }}
                    value={datosformulario.id_estado}
                    label="Estado"
                    onChange={almacenar}
                  >
                    {estados
                      ? estados.map((elemento) => (
                          <MenuItem key={elemento.id} value={elemento.id}>
                            {elemento.estado}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  style={style}
                  focused
                  color="primary"
                  label="Fecha Estado"
                  name="fecha_estado"
                  type="date"
                  onChange={almacenar}
                  value={moment(datosformulario.fecha_estado).format(
                    "YYYY-MM-DD"
                  )}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: datosformulario.fecha_compra,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  style={style}
                  focused
                  color="primary"
                  label="Fecha Prueba Hidráulica"
                  name="fecha_pruebahidraulica"
                  type="date"
                  onChange={(e) => handleFechaPH(e)}
                  value={moment(datosformulario.fecha_pruebahidraulica).format(
                    "YYYY-MM-DD"
                  )}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  style={style}
                  focused
                  color="primary"
                  label="Vencimiento Prueba Hidráulica"
                  name="vto_pruebahidraulica"
                  type="date"
                  onChange={almacenar}
                  value={moment(datosformulario.vto_pruebahidraulica).format(
                    "YYYY-MM-DD"
                  )}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  style={style}
                  focused
                  color="primary"
                  id="date"
                  label="Observaciones"
                  name="observaciones"
                  onChange={almacenar}
                  value={datosformulario.observaciones}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonSearchSharpIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              mt={2}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button
                variant="contained"
                color="error"
                onClick={handleClose}
                disabled={disBtn}
              >
                Volver
              </Button>
              <Button
                disabled={
                  disBtn ||
                  datosformulario.capacidad === "0.00" ||
                  !datosformulario.capacidad
                }
                variant="contained"
                type="submit"
                color="success"
              >
                Guardar Tanque
              </Button>
            </Grid>
          </Box>
        </form>
        <br></br>
      </Container>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Registro existente</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Existe un registro de un tanque con el mismo número de serie y
            fabricante.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Addtanque;
