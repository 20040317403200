import React, { useState, useEffect, useMemo } from "react";
import { UrlApi } from "../../../services/apirest";
import Navbar from "../../Navbar";
import { Container, Typography, Box, Tooltip, IconButton } from "@mui/material";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import EditIcon from "@mui/icons-material/Edit";
import AlertaCambio from "./AlertaCambio";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import moment from "moment";

function Afacturar(props) {
  const [pedidosafacturar, setAfacturar] = useState(); //--> guarda los pedidos de la consulta
  const [datosPedidos, setDatosPedidos] = useState({});
  const [tablaAfacturar, setTablaafacturar] = useState(true);
  const [modalCambio, setModalCambio] = useState(null);
  const globalTheme = useTheme();

  //CONSULTAS A LA API ---------------------------------------------------
  const obtenerPedidosafacturar = () => {
    fetch(UrlApi + "/pedidos/afacturar")
      .then((data) => data.json())
      .then((data) => setAfacturar(data));
  };
  //------------------------------------------------------------------------

  useEffect(() => {
    obtenerPedidosafacturar();
  }, [tablaAfacturar]);

  //Funcion q se ejecuta cuando damos clic a editar
  const handleEditar = (datosDeLaLinea) => {
    setModalCambio(true); //modal en true -> muestra pantalla de edicion
    setTablaafacturar(false); // tabla en false -> oculta tabla
    setDatosPedidos(datosDeLaLinea); //meto los datos de la linea q quiero editar
    // datosDeLaLinea && console.log("datos de la linea ", datosDeLaLinea);
  };

  const columns = useMemo(
    () => [
      { header: "N°", accessorKey: "id_pedido", size: 10 },
      { header: "Cliente", accessorKey: "cliente", size: 150 },
      {
        header: "Fecha de entrega",
        accessorFn :(pedidos) => moment(pedidos.fecha_real_entrega).format("DD-MM-YYYY"),
        // accessorKey: "fecha_real_entrega",
        type: "date",
        format: "DD-MM-YYYY",
        size: 100,
      },
      { header: "Kgs. Entregados", accessorKey: "kgentregados",size: 100  },
      { header: "Nro.Remito", accessorKey: "nro_remito", },
    ],
    []
  );

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //larger tooltip font size                                
              },
            },
          },
        },

      }), [globalTheme]);

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "success",
    size: "small",
  };

  const initialState = {
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 }
  };

  const tableHeadCellProps = {
    align: "center",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };


  // FIN DE TABLA
  return (
    <div>
      {/* <Navbar /> */}
      <Container maxWidth="xl" mt={1}>
        {pedidosafacturar && tablaAfacturar ? (
          <Box mt={2} boxShadow={3}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableColumnResizing
                columns={columns}
                data={pedidosafacturar}
                initialState={initialState}
                positionActionsColumn="last"
                enableExpandAll={false}
                enableToolbarInternalActions={true} 
                muiTableHeadCellProps={tableHeadCellProps}
                muiTableBodyCellProps={{
                  align: "left",
                  sx: {
                    backgroundColor: "#ffffff",
                    color: "black",
                    borderColor: "black"
                  },
                }}
                enableColumnActions={false}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                enableDensityToggle={false}
                // renderTopToolbarCustomActions={() => (
                //   <>
                //   <Box m={1} ml={2}>
                //     <Typography variant="poster" fontSize={26}>
                //     <LocalShippingIcon sx={{ fontSize: 20 }} />
                //       Listado de pedidos a facturar
                //     </Typography>
                //     </Box>
                //   </>                  
                // )}
                layoutMode="grid"
                localization={MRT_Localization_ES}
                enableColumnDragging={false}
                enableEditing
                renderToolbarInternalActions={({ table } ) => (
                  <>
                    <MRT_FullScreenToggleButton table={table} />
                  </>
                )}
                renderRowActions={({ row, table }) => (
                  <div>
                    <Tooltip title="Marcar pedido como facturado">
                      <IconButton
                        onClick={() => handleEditar(row.original)}
                        color="primary"
                      >
                        <EditIcon /> 
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              />
            </ThemeProvider>
          </Box>
        ) : null}
      </Container>
      {modalCambio ? (
        <AlertaCambio
          datos={datosPedidos}
          modalCambio={setModalCambio}
          tabla={setTablaafacturar}
        />
      ) : null}
    </div>
  );
}

export default Afacturar;
