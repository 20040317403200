import React from 'react'
import { useEffect, useState } from 'react';
import { UrlApi } from '../../../services/apirest';
import Navbar from '../../Navbar';
import { Container, Box, IconButton, TextField, FormControl, Select, Grid, Typography, SnackbarContent, Stack, DialogTitle, Button, InputLabel, MenuItem } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import InputAdornment from '@mui/material/InputAdornment';
import PinIcon from '@mui/icons-material/Pin';
import { grey } from '@mui/material/colors';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Notify from '../../Notify';
import Alerta from '../../Alerta';
import { LocalConvenienceStoreOutlined } from '@mui/icons-material';

function Editarlocalidades(props) {
    const [datosFormularios, setDatosFormularios] = useState(props.datos);
    const [provincia, setProvincia] = useState(null);
    const [disBtn, setDisBtn] = useState(false)
    const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
    const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
    const [mensajeAlerta, setMensajeAlerta] = useState(""); //mensaje que contendrá el componente "alerta"
    const [finalizar, setFinalizar] = useState(); //finalizar === 1 lo manda como prop el componente "notify" e indica que la transaccion ha finalizado y debe ejecutarse la funcion para cerrar
    const [severityAlerta, setSeverityAlerta] = useState(""); //define severidad de la alerta (success error o warning)
    const [severitySnack, setSeveritySnack] = useState(""); //define severidad del snackbar (notificación)
    const [openSnack, setOpenSnack] = React.useState(false);
    const [colormensaje, setColorMensaje] = useState("");
    const [mensaje, setMensaje] = useState("");

    const obtenerProvincias = () => {
        fetch(UrlApi + '/provincias')
            .then(data => data.json())
            .then(data => setProvincia(data))
    };

    useEffect(() => {
        obtenerProvincias()
    }, []);

    const almacenar = (e) => {
        setDatosFormularios({ ...datosFormularios, [e.target.name]: e.target.value });
    };

    const handleClose = () => {
        return (
            props.tabla(true),
            props.editar(false)
        )
    };

    const guardarLocalidades = async (e) => {
        setDisBtn(true)
        e.preventDefault();
        const response = await fetch(UrlApi + "/localidades/" + datosFormularios.id, {
            method: 'PUT', headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    localidad: datosFormularios.localidad,
                    cp: datosFormularios.cp,
                    id_provincia: datosFormularios.id_provincia,
                    usuario: localStorage.getItem('user')
                }
            )
        })
        if (response.ok === false) {
            console.log("ERROR EN API");
            const error = response && response.json();
            setError(error);
            setSeverityAlerta("error")
            setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
        } else {
            console.log("Conexión con api OK");
            try {
                //Parseo respuesta de la api a json()
                const json = await response.json();
                //si api me devuelve lo q le puse en message
                if (json.message) {
                    //Si ese mensaje es "OK"
                    if (json.message === "OK") {
                        console.log("Guardado Satisfactoriamente");
                        setNotificacion(true);
                        setOpenSnack(true);
                        setColorMensaje("#D4EFDF");
                        setMensaje("Guardado");
                        setSeveritySnack("success");
                    } else {
                        console.log("res en api incorrecto. Revisar que sea 'OK'")
                        setNotificacion(true);
                        setOpenSnack(true);
                        setColorMensaje("#D4EFDF");
                        setSeveritySnack("error");
                        setMensaje("Registro Guardado. REVISAR RESPUESTA DE API (E_S)");
                    }
                } else if (json.sqlMessage) {
                    json && setError(json);
                    console.log(json);
                    setMensajeAlerta("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
                } else {
                    setMensajeAlerta("ERROR DESCONOCIDO.");
                }
            } catch (error) {
                if (error.toString().includes("is not valid JSON")) {
                    console.log("Guardado. Advertencia: Actualizar respuesta de api. JSON invalido.");
                    setNotificacion(true);
                    setOpenSnack(true);
                    setColorMensaje("#D4EFDF");
                    setMensaje("Guardado");
                    setSeveritySnack("success");
                } else {
                    setMensajeAlerta("ERROR DESCONOCIDO.");
                }
            }
        }
        handleClose()
        console.log(datosFormularios)
    };

    const style = {
        backgroundColor: "#ffffff",
        width: "100%"
    };

    return (
        <div>
            <Navbar />
            <Container>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container backgroundColor="#1F618D" p={1.5}>
                        <Grid item xs={2}>
                            <IconButton onClick={handleClose}>
                                <ArrowBackIcon sx={{ color: grey[50] }} />
                            </IconButton>
                        </Grid>

                        <Grid
                            item
                            xs={8}
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography variant="h5" color="#ffffff">
                                Editar localidad
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <form onSubmit={guardarLocalidades} autoComplete='off'>
                    <Box
                        backgroundColor="#F4F6F6"
                        mt={2}
                        p={3}
                        border={0.5}
                        borderColor={"#ABB2B9"}
                        borderRadius={4}
                    >
                    <Grid container spacing={1} style={{ padding: 10 }}>
                        <Grid item xs={6} >
                            <TextField placeholder="Localidad"
                                required
                                margin="dense"
                                focused
                                color="primary"
                                style={style}
                                fullWidth={true}
                                name="localidad"
                                label="Localidad"
                                id="localidad"
                                value={datosFormularios.localidad.replace(/\b\w/g, (l) =>
                                    l.toUpperCase()
                                )}
                                onChange={almacenar}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LocationOnIcon />
                                        </InputAdornment>
                                    ),
                                }} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField placeholder="Código Postal"
                                required
                                style={style}
                                focused
                                margin="dense"
                                color="primary"
                                fullWidth={true}
                                name="cp"
                                label="Código Postal"
                                id="cp"
                                value={datosFormularios.cp}
                                onChange={almacenar}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PinIcon />
                                        </InputAdornment>
                                    ),
                                }} />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth={true} style={style} required focused color="primary" margin="dense">
                                <InputLabel id="lblid_provincia">Provincia</InputLabel>
                                <Select
                                    name='id_provincia'
                                    labelId="id_provincia"
                                    onChange={almacenar}
                                    value={datosFormularios.id_provincia}
                                    label="Provincia">
                                    {provincia ? provincia.map(elemento => (
                                        <MenuItem key={elemento.id} value={elemento.id}>
                                            {elemento.provincia}
                                        </MenuItem>
                                    )) : null}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid mt={2} container direction="row" justifyContent="space-between" alignItemns="center">
                        <Button variant="contained" onClick={handleClose} color="error">Volver</Button>
                        <Button variant="contained" type="submit" disbled={disBtn} color="success">Guardar localidad</Button>
                    </Grid>
                    </Box>
                </form>
            </Container>
            {notificacion ? (
                <Notify
                    mensaje={mensaje}
                    open={openSnack}
                    color={colormensaje}
                    finalizar={setFinalizar}
                    severity={severitySnack}
                />
            ) : null}
            {/* ALERT --> SE MUESTRA SI HAY UN ERROR EN LA API (NO SE CONECTA O ESTÁ MAL LA RUTA, ETC.) */}
            {error && mensajeAlerta ? (
                <Alerta mensaje={mensajeAlerta} severity={severityAlerta} />
            ) : null}
        </div>
    )
}

export default Editarlocalidades