import React, { useEffect, useState, useMemo } from "react";
import { sumarTotales } from "../../helpers/sumarTotales";
import Navbar from "../../../Navbar";
import { v4 as uuidv4 } from "uuid";
import useFetchFacturas from "../../hooks/useFetchFacturas";
import useAddFacturas from "../../hooks/useAddFacturas";
import Notify from "../../../Notify";
//MUI
import {
  Container,
  Typography,
  Paper,
  styled,
  Toolbar,
  CircularProgress,
} from "@mui/material";

//Icons
import AssignmentIcon from "@mui/icons-material/Assignment";

//MUI TABLE
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import {
  createTheme,
  ThemeProvider,
  useTheme,
  ButtonGroup,
  Button,
  Grid,
  TextField,
} from "@mui/material";
import moment from "moment";
import TableSkeleton from "../SkeletonTable";

const TablaFacturas = () => {
  const [uuid] = useState(uuidv4());

  const [rowSelection, setrowSelection] = useState({});
  const [facturaseleccionada, setfacturaselected] = useState(null);
  const [totalFinal, settotalFinal] = useState(0);
  const { facturas: Facturas, isLoading, error, refetch } = useFetchFacturas();
  const { postFacturasLotes, postLotesPago, isSubmiting, notificacion } =
    useAddFacturas(refetch);
  useEffect(() => {
    const { facturaseleccionada } = Object.keys(rowSelection).reduce(
      (result, key) => {
        if (rowSelection[key]) {
          result.facturaseleccionada.push({
            uuid_lote: uuid,
            uuid_comprobante: Facturas[key].uuid_comprobante,
            total: Facturas[key].total,
          });
        }
        return result;
      },
      { facturaseleccionada: [] }
    );
    setfacturaselected(facturaseleccionada);
    const total_final = sumarTotales(facturaseleccionada);
    settotalFinal(total_final);
  }, [Facturas, rowSelection, uuid]);

  //TABLA

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "primary",
    size: "small",
  };

  const initialState = {
    density: "compact",
    showGlobalFilter: true,
    pagination: {
      pageSize: 15,
      pageIndex: 0,
    },
  };

  const tableHeadCellProps = {
    align: "center",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };

  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //larger tooltip font size
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const columns = [
    { header: "NRO comprobante", accessorKey: "nro_comprobante" },
    {
      header: "Fecha Factura",
      accessorKey: "fecha_factura",
      accessorFn: (data) =>
        data.fecha_factura
          ? moment(data.fecha_factura).format("YYYY-MM-DD")
          : null,
    },
    {
      header: "Vencimiento Factura",
      accessorKey: "vto_factura",
      accessorFn: (data) =>
        data.vto_factura ? moment(data.vto_factura).format("YYYY-MM-DD") : null,
    },
    { header: "Total", accessorKey: "total" },
    { header: "Estado", accessorKey: "valor" },
  ];

  //logs
  console.log(Facturas);
  console.log("factura seleccionada", facturaseleccionada);
  console.log(totalFinal);

  return (
    <div>
      <Navbar />

      <Container maxWidth="lg">
        {isLoading && <TableSkeleton />}
        {Facturas && !isLoading ? (
          <>
            <Toolbar />
            <Notify
              open={notificacion.open}
              mensaje={notificacion.mensaje}
              severity={notificacion.severity}
              color={notificacion.color}
            />
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                columns={columns}
                data={Facturas}
                localization={MRT_Localization_ES}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                // displayColumnDefOptions={displayColumnDefOptions}
                muiTableHeadCellProps={tableHeadCellProps}
                enableExpandAll={false}
                // enableGlobalFilter={true}
                enableToolbarInternalActions={true}
                //enableTopToolbar={false} // habilita o deshabilita TODA la barra de herramientas de arriba
                enableColumnResizing
                layoutMode="grid"
                muiTableBodyCellProps={{
                  align: "center",
                  sx: {
                    backgroundColor: "#FFFFFF",
                    color: "black",
                    borderColor: "black",
                  },
                }}
                enableColumnActions={false}
                enableColumnFilters={false} //desactiva filtros x  columna
                enableHiding={false}
                enableDensityToggle={false} //
                enableColumnDragging={false} //No permite que se arrastren las columnas.
                initialState={initialState}
                enableStickyFooter
                renderTopToolbarCustomActions={() => (
                  <>
                    <Typography
                      variant="poster"
                      fontSize={26}
                      sx={{ marginLeft: "auto", marginRight: "auto" }}
                    >
                      <AssignmentIcon color="warning" sx={{ fontSize: 20 }} />
                      Facturas
                    </Typography>
                  </>
                )}
                enableRowSelection
                onRowSelectionChange={setrowSelection}
                state={{ rowSelection, isLoading }}
                positionActionsColumn="last"
              />
            </ThemeProvider>
            <br />
            <Grid container justifyContent={"space-between"}>
              <Grid item>
                <Button variant="contained" color="error">
                  Volver
                </Button>
              </Grid>
              <Grid item>
                <TextField
                  disabled
                  id=""
                  label="Total Final"
                  value={totalFinal}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => {
                    postFacturasLotes(facturaseleccionada);
                    postLotesPago(uuid, totalFinal);
                  }}
                  disabled={isSubmiting}
                  color="success"
                >
                  {isSubmiting ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Guardar"
                  )}
                </Button>
              </Grid>
            </Grid>
          </>
        ) : null}
      </Container>
    </div>
  );
};

export default TablaFacturas;
