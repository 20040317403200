import React, { useEffect, useState } from 'react';
import EventIcon from '@mui/icons-material/Event';
import { Box, Grid, Typography, Container, TextField, InputAdornment, IconButton, Button } from '@mui/material';
import TimelineIcon from '@mui/icons-material/Timeline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import moment from 'moment'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { grey } from '@mui/material/colors';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import BallotIcon from '@mui/icons-material/Ballot';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import FileUploadMultiple from '../../../services/UploadFiles';
import AttachFileIcon from '@mui/icons-material/AttachFile';

function Fichacontratos(props) {
    const [datosformulario, setDatosformulario] = useState(props.datos);
    const [color, setColor] = useState("primary");
    const [colorborde, setColorborde] = useState("");
    const [opcion, setOpcion] = React.useState("datos");
    const [openclose, setOpenClose] = useState(false);

    const handleClose = () => {
        props.ficha(false)
        props.tabla(true)
    };

    const definirColores = () => {
        datosformulario.activo === 0 ? setColor("error") : setColor("primary")
        datosformulario.activo === 0 ? setColorborde("error.main") : setColorborde("primary.main")
    };

    useEffect(() => {
        definirColores();
    }, []);

    const style = {
        backgroundColor: "#ffffff",
        width: "100%"
    };

    return (
        <div>
            <Container>
                {opcion == "datos" ?
                    <>
                        <Box sx={{ flexGrow: 1 }} mt={2}>
                            <Grid container backgroundColor="#1F618D" p={1.5}>
                                <Grid item xs={2}>
                                    <IconButton onClick={handleClose}>
                                        <ArrowBackIcon sx={{ color: grey[50] }} />
                                    </IconButton>
                                </Grid>
                                <Grid
                                    item
                                    xs={8}
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Typography variant="h5" color="#ffffff">
                                        Contrato Nro: {datosformulario.nro_contrato}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box
                            backgroundColor="#F4F6F6"
                            mt={2}
                            p={3}
                            border={0.5}
                            borderColor={"#ABB2B9"}
                            borderRadius={4}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        placeholder="No hay registro"
                                        label="Razon social"
                                        name="razon_social"
                                        color={color}
                                        focused
                                        style={style}
                                        //variant="standard"
                                        value={datosformulario.razon_social}
                                        InputProps={{
                                            readOnly: true,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AccountCircleIcon />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        placeholder="No hay registro"
                                        label="Número Contrato"
                                        name="nro_contrato"
                                        color={color}
                                        focused
                                        style={style}
                                        //variant="standard"
                                        value={datosformulario.nro_contrato}
                                        InputProps={{
                                            readOnly: true,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <NoteAltIcon />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <TextField
                                        placeholder="No hay registro"
                                        label="Estado del contrato"
                                        name="estado"
                                        style={style}
                                        color={color}
                                        focused
                                        //variant="standard"
                                        value={datosformulario.estado}
                                        InputProps={{
                                            readOnly: true,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <ToggleOnIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        placeholder="No hay registro"
                                        label="Modalidad"
                                        name="modalidad"
                                        style={style}
                                        color={color}
                                        focused
                                        //variant="standard"
                                        value={datosformulario.modalidad}
                                        InputProps={{
                                            readOnly: true,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <BallotIcon />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        placeholder="No hay registro"
                                        label="Inicio contrato"
                                        name="fecha_inicio"
                                        color={color}
                                        focused
                                        style={style}
                                        //variant="standard"
                                        value={moment(datosformulario.fecha_inicio).format("DD-MM-YYYY")}
                                        InputProps={{
                                            readOnly: true,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <EventIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        placeholder="No hay registro"
                                        label="Vencimiento contrato"
                                        name="vencimiento_contrato"
                                        color={color}
                                        style={style}
                                        focused
                                        //variant="standard"
                                        value={moment(datosformulario.vencimiento_contrato).format("DD-MM-YYYY")}
                                        InputProps={{
                                            readOnly: true,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <EventIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        placeholder="No hay registro"
                                        label="Factibilidad"
                                        name="factibilidad"
                                        style={style}
                                        color={color}
                                        focused
                                        //variant="standard"
                                        value={datosformulario.factibilidad}
                                        InputProps={{
                                            readOnly: true,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AssessmentIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        placeholder="No hay registro"
                                        label="Consumo en kilos"
                                        name="consumo_kilos"
                                        style={style}
                                        color={color}
                                        focused
                                        //variant="standard"
                                        value={datosformulario.consumo_kilos}
                                        InputProps={{
                                            readOnly: true,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <TimelineIcon />|
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        placeholder="No hay registro"
                                        label="Consumo en litros"
                                        name="consumo_litros"
                                        color={color}
                                        focused
                                        style={style}
                                        //variant="standard"
                                        value={datosformulario.consumo_litros}
                                        InputProps={{
                                            readOnly: true,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <TimelineIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        placeholder="No hay registro"
                                        label="Producto"
                                        name="producto"
                                        color={color}
                                        style={style}
                                        focused
                                        //variant="standard"
                                        value={datosformulario.producto}
                                        InputProps={{
                                            readOnly: true,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <ProductionQuantityLimitsIcon />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid mt={2} container direction="row" justifyContent="space-between" alignItemns="center">
                                <Button onClick={() => setOpenClose(!openclose)} variant="outlined" size="small" startIcon={<AttachFileIcon />} >Ver contrato adjunto</Button>
                            </Grid>
                        </Box>
                    </>
                    : null}

            </Container>
            {openclose ? (
                <FileUploadMultiple
                    openclose={true}
                />
            ) : null}
        </div>
    )
}

export default Fichacontratos