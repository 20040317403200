//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio/Nicolas Pascucci
// Creation Date : 22/01/2024
// Version : 1
// Description : pantalla de los pendientes de aprobación
//------Changes --------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect, useMemo } from "react";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import { Container, ListItemIcon, Box, Grid, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import BlockIcon from "@mui/icons-material/Block";
import AddCommentIcon from "@mui/icons-material/AddComment";
import CheckIcon from "@mui/icons-material/Check";
import { UrlApi } from "../../../services/apirest";
import FactAcciones from "./acciones/FactAcciones";
import Title from "../../Title";
import { v4 as uuidv4 } from "uuid"; //ADJUNTAR IMAGEN
//dialogs
import Dialog_aprobar from "./Dialog_aprobar";
import Dialog_desaprobar from "./Dialog_desaprobar";
import { logEventos } from "../../../services/services";
import {getData} from "../../../services/library"

function PendientesAprobacion(props) {
  const [uuid, setUuid] = useState(uuidv4()); //ADJUNTAR IMAGEN
  const [tabla, setTabla] = useState(true);
  const [acciones, setAcciones] = useState(false);
  const [dialogAprobar, setDialogAprobar] = useState(false);
  const [factDatos, setFactDatos] = useState();
  const [pendientes, setPendientes] = useState();
  const [update, setUpdate] = useState(false);
  const [dialogDesaprobar, setDialogDesaprobar] = useState(false);

  // console.log("tabla de gestion crediticia")
  // para actualizar
  useEffect(() => {
    if (update) {
      obtenerPendientes();
      setUpdate(false);
    }
  }, [update]);
  //========================================================================/
  const obtenerPendientes = () => {
    fetch(UrlApi + "/factibilidades/factibilidadxestado/219")
      .then((data) => data.json())
      .then((data) => setPendientes(data));
  };

  useEffect(() => {
    obtenerPendientes();
  }, [tabla]);
  // console.log("PENDIENTES", pendientes);
  const [artefactos, setArtefactos]=useState(null);
  //===========================================   APROBAR   ===============================================//
  const handleClickOpenAprobar = (row) => {
    setFactDatos(row);
    getData(`/fact_artefactos/${row.factuuid}`,setArtefactos)
    console.log(row)
    setDialogAprobar(true);
    setUuid(uuidv4());
    // if (row.id_tipofact===235){
    //   console.log("es un nuevo cliente")
    // } else if(row.id_tipofact===236) {
    //   console.log("es una ampliacion para un domicilio existente")
    // } else {
    //   console.log("es una ampliacion para un domicilio nuevo")
    // }
  };

  const handleCloseAprobar = () => {
    setDialogAprobar(false);
    setUpdate(true);
  };

  //==========================================   DESAPROBAR   ===============================================//
  const handleClickSetearDatosDesaprobar = (row) => {
    setDialogDesaprobar(true);
    setFactDatos(row);
    setUuid(uuidv4());
  };

  const handleCloseDesaprobar = () => {
    setDialogDesaprobar(false);
    setUpdate(true);
  };
  //==========================================    ACCIONES    ==============================================//
  const handleClickOpenAcciones = (row) => {
    setAcciones(true);
    setFactDatos(row);
  };
  // console.log(factDatos);
  //==========================================     TABLA      ==============================================//
  const initialState = {
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
    columnVisibility: {
      factuuid: false,
      cuit: false,
      mails: false,
      telefono1: false,
      telefono2: false,
      telefono3: false,
    },
  };

  // const getDatos=()=>{

  // }


  const columns = useMemo(
    () => [
      {
        header: "ID",
        accessorKey: "id_fact",
        size: 60,
      },
      { header: "Cliente", accessorKey: "razon_social", size: 120 },
      { header: "Sucursal Cliente", accessorKey: "cli_sucursal", size: 120 },
      {
        header: "RRCC",
        accessorKey: "usuario_registra",
        size: 120,
      },
      {
        header: "uuid",
        accessorKey: "factuuid",
        size: 100,
        enableHiding: true,
      },
      { header: "cuit", accessorKey: "cuit", size: 100, enableHiding: true },
      { header: "mails", accessorKey: "mails", size: 100, enableHiding: true },
      {
        header: "telefono1",
        accessorKey: "telefono1",
        size: 100,
        enableHiding: true,
      },
      {
        header: "telefono2",
        accessorKey: "telefono2",
        size: 100,
        enableHiding: true,
      },
      {
        header: "telefono3",
        accessorKey: "telefono3",
        size: 100,
        enableHiding: true,
      },
    ],
    []
  );

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "success",
    size: "small",
  };

  const tableHeadCellProps = {
    align: "center",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };

  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem",
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  return (
    <>
      {pendientes && tabla ? (
        <ThemeProvider theme={tableTheme}>
          <MaterialReactTable
            enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
            enableColumnResizing
            enableGrouping // para permitir agrupar x estado x ej
            layoutMode="grid"
            muiTableHeadCellProps={tableHeadCellProps}
            muiTableBodyCellProps={({ row }) => ({
              align: "left",
              sx: {
                backgroundColor: "#ffffff",
                color: "black",
                borderColor: "black",
              },
            })}
            enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
            enableColumnFilters={false} //desactiva filtros x  columna
            enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
            enableDensityToggle={false} //
            localization={MRT_Localization_ES} //idioma
            enableColumnDragging={false} //para arrastrar columnas
            initialState={initialState}
            muiSearchTextFieldProps={muiSearchTextFieldProps}
            columns={columns}
            data={pendientes} //con lo q la completas (esto cambia siempre)
            enableRowActions
            positionActionsColumn="last" // posicion de la columna de acciones al final
            // ACCIONES
            renderToolbarInternalActions={(
              { table } // boton para nuevo , al lado del buscar
            ) => (
              <>
                <MRT_FullScreenToggleButton table={table} />
              </>
            )}
            renderRowActionMenuItems={(
              { closeMenu, row } //las acciones del costado editar y ver
            ) => (
              <>
                <MenuItem
                  onClick={() => {
                    handleClickOpenAcciones(row.original);
                    setTabla(false);
                    closeMenu();
                  }}
                  color={"primary"}
                >
                  <ListItemIcon>
                    <AddCommentIcon color="primary" />
                  </ListItemIcon>
                  Acciones
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClickOpenAprobar(row.original);
                    closeMenu();
                  }}
                >
                  <ListItemIcon>
                    <CheckIcon color="success" />
                  </ListItemIcon>
                  Aprobar
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClickSetearDatosDesaprobar(row.original);
                    closeMenu();
                  }}
                  color={"success"}
                >
                  <ListItemIcon>
                    <BlockIcon color="error" />
                  </ListItemIcon>
                  Desaprobar
                </MenuItem>
              </>
            )}
            renderDetailPanel={({ row }) => (
              <>
                <Container>
                  <Box display="block" width="250%">
                    <Box>
                      <Grid container maxWidth="x1" spacing={1}>
                        <Grid item xs={4} py={1} mb={1}>
                          <TextField
                            fullWidth
                            placeholder="Pendiente"
                            label="Cuit/Dni"
                            name="cuit"
                            variant="filled"
                            size="small"
                            focused
                            value={row.original.cuit}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={4} py={1} mb={1}>
                          <TextField
                            fullWidth
                            placeholder="Pendiente"
                            label="Mails"
                            name="mails"
                            variant="filled"
                            size="small"
                            focused
                            value={row.original.mails}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                        {row.original.telefono1 !== "" ? (
                          <Grid item xs={4} py={1} mb={1}>
                            <TextField
                              fullWidth
                              placeholder="..."
                              label="Telefono 1"
                              name="telefono1"
                              variant="filled"
                              size="small"
                              focused
                              value={row.original.telefono1}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                        ) : null}
                        {row.original.telefono2 !== "" ? (
                          <Grid item xs={4} py={1} mb={1}>
                            <TextField
                              fullWidth
                              placeholder="..."
                              label="Telefono 2"
                              name="telefono2"
                              variant="filled"
                              size="small"
                              focused
                              value={row.original.telefono2}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                        ) : null}
                        {row.original.telefono3 !== "" ? (
                          <Grid item xs={4} py={1} mb={1}>
                            <TextField
                              fullWidth
                              placeholder="..."
                              label="Telefono 3"
                              name="telefono3"
                              variant="filled"
                              size="small"
                              focused
                              value={row.original.telefono3}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                        ) : null}
                      </Grid>
                    </Box>
                  </Box>
                </Container>
              </>
            )}
          />
        </ThemeProvider>
      ) : null}

      {acciones ? (
        <Container maxWidth="lg">
          <Title
            titulo={`Acciones`}
            handleClose={() => {
              setAcciones(false);
              setTabla(true);
            }}
          />
          <FactAcciones factDatos={factDatos} />
        </Container>
      ) : null}

      {dialogAprobar ? (
        <Dialog_aprobar
          dialogAprobar={dialogAprobar}
          factDatos={factDatos}
          uuid={uuid}
          factConfig={props.factConfig}
          onClose={() => handleCloseAprobar(false)}
          artefactos={artefactos}
          setArtefactos={setArtefactos}

        />
      ) : null}

      {dialogDesaprobar ? (
        <Dialog_desaprobar
          dialogDesaprobar={dialogDesaprobar}
          factDatos={factDatos}
          uuid={uuid}
          onClose={() => handleCloseDesaprobar(false)}
        />
      ) : null}
    </>
  );
}

export default PendientesAprobacion;
