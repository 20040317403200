import React, { useMemo, useEffect, useState } from "react";
import MaterialReactTable from "material-react-table";
import { UrlApi } from "../../../../services/apirest";
import {
  Box,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
//   import { data, states } from './makeData';
import Container from "@mui/material/Container";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import { Typography } from "@mui/material";

function TanquesFijos() {
  const [tanquesFijos, setTanquesFijos] = useState([{}]);

  const obtenerTanquesFijos = () => {
    fetch(UrlApi + "/tanquesfijospropelente/activos")
      .then((data) => data.json())
      .then((data) => setTanquesFijos(data));
  };

  useEffect(() => {
    obtenerTanquesFijos();
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "nro_serie", //access nested data with dot notation
        header: "Nro Serie",
        //size:80
      },
      {
        accessorKey: "capacidad", //access nested data with dot notation
        header: "Capacidad",
      },
    ],
    []
  );

  const displayColumnDefOptions = {
    "mrt-row-actions": {
      muiTableHeadCellProps: {
        sx: {
          fontSize: 14,
          fontStyle: "italic",
        },
      },
      size: 20,
    },
  };

  const muiSearchTextFieldProps ={
    placeholder: "Buscar..",
    sx: { minWidth: "18rem" },
    variant: "outlined",
    color: "success",
    size: "small",
  }

  return (
    <div>
      <>
        <Container maxWidth>
          <Box mt={2}>
            <MaterialReactTable
              //enableToolbarInternalActions={false}//Deshabilita todas las acciones de toolbar
              //enableFilters={false} //deshabilita filtros
              //positionGlobalFilter="left" //  posicion del textfield para buscar
              // enableColumnFilterModes={false}
              // editingMode="modal" //default
              //onEditingRowSave={handleSaveRowEdits}
              //onEditingRowCancel={handleCancelRowEdits}
              // positionExpandColumn="first"

              displayColumnDefOptions={displayColumnDefOptions}
              layoutMode="grid"
              muiTableHeadCellProps={{
                align: "center",
              }}
              muiTableBodyCellProps={{
                align: "center",
              }}
              enableColumnActions={false}
              enableColumnFilters={false} //desactiva filtros x  columna
              enableHiding={false}
              enableDensityToggle={false} //
              localization={MRT_Localization_ES}
              enableColumnDragging={false} //No permite que se arrastren las columnas.
              initialState={{
                density: "compact",
              }}
              muiSearchTextFieldProps={muiSearchTextFieldProps}
              columns={columns}
              data={tanquesFijos}
              enableStickyFooter
              enableEditing
              positionActionsColumn="last"
              renderRowActions={({ row, table }) => (
                <Box sx={{ display: "flex", gap: "1rem", align: "center" }}>
                  <Tooltip arrow placement="left" title="Edit">
                    <IconButton
                      color="primary"
                      onClick={() => console.log("quiero editar")}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip arrow placement="right" title="Delete">
                    <IconButton
                      color="error"
                      onClick={() => console.log("quiero borrar algo")}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
              renderTopToolbarCustomActions={() => (
                <>
                  <Button
                    variant="contained"
                    color="success"
                    startIcon={<AddBoxTwoToneIcon color="asd" />}
                  >
                    Nuevo Tanque
                  </Button>
                  <Typography variant="poster" fontSize={20}>
                    TANQUES FIJOS
                  </Typography>
                </>
              )}
            />
          </Box>
        </Container>
      </>
    </div>
  );
}

export default TanquesFijos;
