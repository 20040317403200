//----- Component --------------------------------------------------------/

// Developer : Nicolas Pascucci

// Creation Date : 21/05/2024

// Version : 1

// Description : Eleccion de sucursal para iniciar la evaluacion mensual de comercial
//

//------Changes --------------------------------------------------------------------- -------------//

// Change Date :

// Change by :

// Change description:

//

// Change Date :

// Change by :

// Change description:

//

//========================================================================/
import React, { useEffect, useState } from 'react'
import {
    Button,
    Container,
    Dialog,
    DialogContent,
    Box,
    Grid,
    Stack,
    TextField,
    Autocomplete,
    Snackbar,
    Alert,
    Typography,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
  } from "@mui/material";
import { UrlApi } from '../../../services/apirest';
import Navbar from '../../Navbar';
import moment from 'moment';
import EvaluacionComercial from './EvaluacionComercial';
import { logEventos } from '../../../services/services';

function Eleccion_sucursal() {
    const [distribuidores, setDistribuidores] = useState(null)
    const [comercio, setComercio] = useState(null)
    const [usuarios, setUsuarios] = useState(null)
    const [vendido, setVendido] = useState(null)
    const [distribuidoresButano, setDistribuidoresButano] = useState(null)
    const [comerciosButano, setComerciosButano] = useState(null)
    const [usuariosButano, setUsuariosButano] = useState(null)
    const [clientes, setClientes] = useState(null)
    const [vencidos, setVencidos] = useState(null)
    const [modificar, setModificar] = useState(false)
    const [estados, setEstados] = useState()
    const [eleccionSuc, setEleccionSuc] = useState(true)
    const [resultados, setResultados] = useState()
    const [fecha, setFecha] = useState(moment(new Date()).format("YYYY-MM"+"-01"))
    const [mes, setMes] = useState(moment(new Date()).format("YYYY-MM"))
    const [sucursales, setSucursales] = useState()
    const [sucursalSeleccionada, setSucursalSeleccionada] = useState({})
    const [empezarPrueba, setEmpezarPrueba] = useState(false)
    // const [selectedDate, setSelectedDate] = useState(moment(new Date()).format("YYYY-MM"));
    const [selectedDate, setSelectedDate] = useState(moment(new Date()).subtract(1, 'months').format("YYYY-MM"));

    const handleDateChange = (event) => {
      setSelectedDate(event.target.value);
    };
    const formattedDate = `${selectedDate}-01`

    // console.log("mes", formattedDate, selectedDate, resultados, estados);
    useEffect(() => {
        if (resultados && resultados.length === 1) {
        //   setHizoChecklist(true)
          setModificar(true)
          setDistribuidores(resultados && resultados[0]? resultados[0].distribuidores:null)
          setVendido(resultados && resultados[0]?resultados[0].vendido:null)
          setComercio(resultados && resultados[0]?resultados[0].comercio:null)
          setUsuarios(resultados && resultados[0]?resultados[0].usuarios:null)
          setClientes(resultados && resultados[0]?resultados[0].clientes:null)
          setVencidos(resultados && resultados[0]?resultados[0].vencidos:null)
          setDistribuidoresButano(resultados && resultados[0]?resultados[0].distribuidores_butano:null)
          setComerciosButano(resultados && resultados[0]?resultados[0].comercios_butano:null)
          setUsuariosButano(resultados && resultados[0]?resultados[0].usuarios_butano:null)
        }  
      }, [resultados, sucursalSeleccionada, selectedDate])

      
// console.log("distribuidores", distribuidores);
// console.log("comercio", comercio);
// console.log("usuarios", usuarios);
// console.log("Clientes", clientes);
// console.log("Vencidos", vencidos);
// console.log("distri. butano", distribuidoresButano);
// console.log("comercio butano", comerciosButano);
// console.log("usuarios butano", usuariosButano);


    const obtenerSucursales = () => {
        fetch(UrlApi + "/sucursales/xsucauth/"+localStorage.getItem("suc_auth"))
          .then((data) => data.json())
          .then((data) => setSucursales(data));
      };
          
      useEffect(() => {
        obtenerSucursales();
      }, []);

      const obtenerResultados = () => {
        fetch(UrlApi + "/resultados_comerciales/mesusersuc/" + localStorage.getItem("user") + "/" + formattedDate + "/" + sucursalSeleccionada.id)
          .then((data) => data.json())
          .then((data) => setResultados(data));
      };
          
      useEffect(() => {
        setVendido(null)
        setDistribuidores(null)
        setComercio(null)
        setUsuarios(null)
        setClientes(null)
        setVencidos(null)
        setDistribuidoresButano(null)
        setComerciosButano(null)
        setUsuariosButano(null)
        obtenerResultados();
      }, [sucursalSeleccionada, selectedDate]);

      // console.log("RESULTADOS ANTERIORES", resultados, vendido);

      const obtenerEstados = () => {
        fetch(UrlApi + "/estados_evaluacion_comercial/mesusersuc/" + localStorage.getItem("user") + "/" + selectedDate + "/" + sucursalSeleccionada.id)
          .then((data) => data.json())
          .then((data) => setEstados(data));
      };
          
      useEffect(() => {
        obtenerEstados();
      }, [sucursalSeleccionada, selectedDate]);

      // console.log("estados",estados);

      const handleEmpezarPrueba = () => {
        setEmpezarPrueba(true)
        setEleccionSuc(false)
      };

      const [disabled, setDisabled] = useState(true)
      useEffect(() => {
        if (Object.keys(sucursalSeleccionada).length === 0 || selectedDate === '' || resultados && resultados[0] && resultados[0].estado === 1) {
          setDisabled(true)
          // console.log("Aprobado");
        }else if(Object.keys(sucursalSeleccionada).length === 0 || selectedDate === '' || resultados && resultados[0] && resultados[0].estado === 2){
          // console.log("Desaprobado");
          setDisabled(true)
        } else{
          // console.log("pendiente");
          setDisabled(false)
        }

      }, [resultados,sucursalSeleccionada])
      // console.log(disabled);
      
      const [hizoChecklist, setHizoChecklist] = useState(false)
      useEffect(() => {
        if (estados && estados[0] && estados[0].checklist === 1) {
          setHizoChecklist(true)
        } else{
          setHizoChecklist(false)
        }
      }, [estados,sucursalSeleccionada, selectedDate])
      
      useEffect(() => {
        logEventos("Eleccion de sucursal", "Evaluacion Comerciales", "Elegir sucursal para iniciar la evaluacion mensual del comercial", localStorage.getItem("user"))
    }, [])

    const currentMonth = moment().subtract(1, 'months');
    const monthOptions = Array.from({ length: 4 }, (_, i) => {
        const month = currentMonth.clone().subtract(i, 'months').format('YYYY-MM');
        return { value: month, label: currentMonth.clone().subtract(i, 'months').format('MMMM YYYY') };
    });
 
    return (
        <>
        {eleccionSuc ?
        <>
        <Navbar/>
        <div style={styles.container}>
          <h1 style={styles.title}>SELECCIONE LA SUCURSAL Y EL PERIODO</h1>
          <Grid container spacing={2}>
          <Grid item xs={12} py={1}>
            <FormControl focused color="primary" required size="small">
                <InputLabel id="mes">Período</InputLabel>
                <Select
                    required
                    variant='outlined'
                    name='mes'
                    value={selectedDate}
                    onChange={handleDateChange}
                    label="Período"
                    labelId="mes"  // Asegúrate de que labelId coincida con el InputLabel
                >
                    {monthOptions.map(elemento => (
                        <MenuItem key={elemento.value} value={elemento.value}>
                            {elemento.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
            <Grid item xs={5}></Grid>
            <Grid item xs={2}>
                <Autocomplete
                    disabled={!sucursales}
                    size="small"
                    focused
                    required
                    disableClearable
                    id="sucursal"
                    noOptionsText={"Sucursal INEXISTENTE"}
                    options={sucursales}
                    autoHighlight
                    getOptionLabel={(sucursal) =>
                        sucursal.id + "-" + sucursal.nombre
                    }
                    onChange={(event, value) =>
                        setSucursalSeleccionada(value)
                    }
                    renderOption={(props, option) => (
                        <Box
                            component="li"
                            key={option.id}
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                        >
                            {option.id} - {option.nombre}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            focused
                            size="small"
                            placeholder="Tipee para buscar..."
                            {...params}
                            label="SUCURSAL"
                            inputProps={{
                                ...params.inputProps,
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={5}></Grid>
            <Grid item xs={12}>
                <Button variant="contained" onClick={handleEmpezarPrueba} disabled={disabled}>CONFIRMAR</Button>
            </Grid>
            {resultados && resultados[0] && resultados[0].estado === 1?
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom style={{ color: 'red' }}>
                  Sus datos ya no se pueden modificar 
                  </Typography>
                </Grid>
              :null}
            {resultados && resultados[0] && resultados[0].estado === 2 ?
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom style={{ color: 'red' }}>
                   Sus datos ya no se pueden modificar
                </Typography>
              </Grid>
            :null}
        </Grid>
        </div>
        </>
        :null}
        {empezarPrueba?
        <EvaluacionComercial
            hizoChecklist={hizoChecklist}
            sucursalSeleccionada={sucursalSeleccionada}
            resultados={resultados}
            vendido={vendido}
            distribuidores={distribuidores}
            comercio={comercio}
            usuarios={usuarios}
            distribuidoresButano={distribuidoresButano}
            comerciosButano={comerciosButano}
            usuariosButano={usuariosButano}
            clientes={clientes}
            vencidos={vencidos}
            modificar={modificar}
            estados={estados}
            fecha={formattedDate}
        />
        :null}
        </>
      );
    }
    
    const styles = {
      container: {
        textAlign: 'center',
        marginTop: '50px',
        backgroundColor: '#f9f9f9',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      },
      title: {
        color: '#333',
      },
      text: {
        color: '#666',
        marginBottom: '10px',
      },
}

export default Eleccion_sucursal