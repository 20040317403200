import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'





function ResumenFacturacion(props) {
    // console.log("ITEMS:" , props.items)
  return (
    <div>
      <Container maxWidth="xl" disableGutters>
        <Grid
          container
          backgroundColor="#ebebeb"
          my={1}
          border={1}
          padding={1}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item xs={10}>
            <Grid container>
              <Grid item xs={4}>
                <Typography variant="body1" color="initial">
                  {props?.cliente?.empresa_cliente}
                </Typography>
              </Grid>
              {/* <Grid item xs={4}>
                <Typography variant="body1" color="initial">
                  unidad fact: {props?.cliente?.unidad_facturacion}
                </Typography>
              </Grid> */}
              {props.items.map((item) => (
                <Grid item xs={12}>
                  <Typography variant="body1" color="initial">
                    {item.facturacionKg || item.facturacionLts}{" "}
                    {item.unidad_facturacion} {item.detalle_factura}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={2}>
              <TextField
                size="small"
                fullWidth
                sx={{backgroundColor:"#fff"}}
                label="Precio "
                value={props.precio}
                onChange={(e)=>props.setPrecio(e.target.value)}
                focused
              />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default ResumenFacturacion