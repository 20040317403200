import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import { Typography, Grid, Button, Tab } from "@mui/material";
import EditDatosProveedor from "./EditDatosProveedor";
import FormEditCondicionImp from "./FormEditCondicionImp";
import FormEditCondEsp from "./FormEditCondEsp";
import Container from "@mui/material/Container";
import useForm from "../../../Hooks/UseForm";
import UseFetchProveedor from "../hooks/UseFetchProveedor";
import useCuit from "../hooks/useCuit";
import { logEventos } from "../../../services/services";
const EditProveedor = ({
  setNotificacion,
  alta,
  onSuccess,
  tabla,
  proveedor,
}) => {
  const [opcion, setopcion] = useState("datos");

  const { DatosFormulario, onChange, handleChangeCheck } = useForm(proveedor);
  const { Cuit, handleCuitChange, isValid } = useCuit();

  const { ActualizarProveedor } = UseFetchProveedor();
  //******************************************************************************************************** */

  const HandleVolver = () => {
    tabla(true);
    alta(false);
  };

  const handleChange = (event, newopcion) => {
    setopcion(newopcion);
  };
  const HandleSubmit = async (event) => {
    await ActualizarProveedor(
      DatosFormulario,
      setNotificacion,
      onSuccess,
      event
    );

    HandleVolver();
  };
  useEffect(() => {
    logEventos(
      "Editar Proveedor",
      "Proveedores",
      "proveedores",
      localStorage.getItem("user")
    );
  }, []);

  console.log("DatosfORM en edit", DatosFormulario);
  return (
    <div>
      <Container maxWidth="lg">
        <Box sx={{ flexGrow: 1, backgroundColor: "#1F618D" }} mt={2}>
          <Grid container p={1.5}>
            <Grid
              // item
              xs={12}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h5" color="#ffffff">
                Editar Proveedor
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ width: "100%" }} mt={2}>
          <Tabs value={opcion} onChange={handleChange} variant="fullWidth">
            <Tab label="Datos" value="datos" />
            <Tab label="Condicion Impositiva" value="condicion_impositiva" />
            <Tab
              label="Condiciones Especiales"
              value="condiciones_especiales"
            />
          </Tabs>
        </Box>

        <form onSubmit={(event) => HandleSubmit(event)} fullWidth>
          <Box
            mb={3}
            mt={2}
            backgroundColor="#F4F6F6"
            p={3}
            border={0.5}
            borderColor={"#ABB2B9"}
            borderRadius={4}
            width="100%"
          >
            {opcion === "datos" ? (
              <EditDatosProveedor
                proveedor={DatosFormulario}
                almacenar={onChange}
              />
            ) : null}

            {opcion === "condicion_impositiva" ? (
              <FormEditCondicionImp
                handleCuitChange={handleCuitChange}
                Cuit={Cuit}
                isValid={isValid}
                almacenar={onChange}
                proveedor={DatosFormulario}
              />
            ) : null}

            {opcion === "condiciones_especiales" ? (
              <FormEditCondEsp
                proveedor={DatosFormulario}
                almacenarChecks={handleChangeCheck}
                onChange={onChange}
              />
            ) : null}
          </Box>

          <Grid
            container
            spacing={2}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Grid item>
              <Button onClick={HandleVolver} color="error" variant="contained">
                Volver
              </Button>
            </Grid>
            <Grid item>
              <Button type="submit" color="success" variant="contained">
                Guardar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
      <br />
      <br />
    </div>
  );
};

export default EditProveedor;
