import React from "react";
import { UrlApi } from "../../../services/apirest";

const useAddFacturas = (onSuccess) => {
  const [isSubmiting, setisSubmiting] = React.useState(false);
  const [notificacion, setNotificacion] = React.useState({
    open: null,
    mensaje: null,
    severity: null,
    color: null,
  });
  const postFacturasLotes = async (facturaseleccionada) => {
    try {
      setisSubmiting(true);
      const response = await fetch(UrlApi + "/facturas", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(facturaseleccionada),
      });
      if (response.ok === false) {
        console.log("ERROR EN API");
        const error = response && response.json();
        console.error(error);
      } else {
        console.log("Conexión con api OK");
        try {
          //Parseo respuesta de la api a json()
          const json = await response.json();
          //si api me devuelve lo q le puse en message
          if (json.message) {
            //Si ese mensaje es "OK"
            if (json.message === "OK") {
              console.log("Guardado Satisfactoriamente");
              setNotificacion({
                open: true,
                mensaje: "Guardado Satisfactoriamente",
                severity: "success",
                color: "#D4EFDF",
              });
            } else {
              console.log("res en api incorrecto. Revisar que sea 'OK'");
            }
          } else if (json.sqlMessage) {
            json && console.error(json);
            console.log(
              "SQL erróneo (" + json.code + " Errno: " + json.errno + " )"
            );
            setNotificacion({
              open: true,
              mensaje: "Error SQL. Contacte a soporte.",
              severity: "error",
              color: "#FADBD8",
            });
          } else {
            console.error("Error Desconocido");
          }
        } catch (error) {
          if (error.toString().includes("is not valid JSON")) {
            setNotificacion({
              open: true,
              mensaje:
                "Guardado. Advertencia: Actualizar respuesta de api. JSON invalido.",
              severity: "success",
              color: "#D4EFDF",
            });
          } else {
            console.error("Error Desconocido");
          }
        }
      }
      onSuccess();
    } catch (error) {
    } finally {
      setisSubmiting(false);
    }
  };
  const postLotesPago = async (uuid, totalFinal) => {
    try {
      setisSubmiting(true);
      const response = await fetch(UrlApi + "/lotes_pago", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ uuid_lote: uuid, total_final: totalFinal }),
      });
      if (response.ok === false) {
        console.log("ERROR EN API");
        const error = response && response.json();
        console.error(error);
      } else {
        console.log("Conexión con api OK");
        try {
          //Parseo respuesta de la api a json()
          const json = await response.json();
          //si api me devuelve lo q le puse en message
          if (json.message) {
            //Si ese mensaje es "OK"
            if (json.message === "OK") {
              console.log("Guardado Satisfactoriamente");
              setNotificacion({
                open: true,
                mensaje: "Guardado Satisfactoriamente",
                severity: "success",
                color: "#D4EFDF",
              });
            } else {
              console.log("res en api incorrecto. Revisar que sea 'OK'");
            }
          } else if (json.sqlMessage) {
            json && console.error(json);
            console.log(
              "SQL erróneo (" + json.code + " Errno: " + json.errno + " )"
            );
            setNotificacion({
              open: true,
              mensaje: "Error SQL. Contacte a soporte.",
              severity: "error",
              color: "#FADBD8",
            });
          } else {
            console.error("Error Desconocido");
          }
        } catch (error) {
          if (error.toString().includes("is not valid JSON")) {
            setNotificacion({
              open: true,
              mensaje:
                "Guardado. Advertencia: Actualizar respuesta de api. JSON invalido.",
              severity: "success",
              color: "#D4EFDF",
            });
          } else {
            console.error("Error Desconocido");
          }
        }
      }
      onSuccess();
    } catch (error) {
    } finally {
      setisSubmiting(false);
    }
  };
  return { postFacturasLotes, postLotesPago, isSubmiting, notificacion };
};

export default useAddFacturas;
