import { useState, useEffect, useCallback } from "react";
import {
  Divider,
  Container,
  Toolbar,
  Card,
  CardActions,
  CardContent,
  TextField,
  InputAdornment,
  Button,
  Box,
  Typography,
  LinearProgress,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import UploadPadron from "./components/Uploadpadron";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import axios from "axios";
import GrandesEmpresas from "./components/GrandesEmpresas";
import { v4 as uuid } from "uuid";

import moment from "moment";

import DialogArba from "./components/DialogArba";
import DialogCdba from "./components/DIalogCdba";
import { UrlApi } from "../../../services/apirest";
import Navbar from "../../Navbar";

const Padrones = () => {
  const [Open, setOpen] = useState(false);
  const [OpenArba, setOpenArba] = useState(false);
  const [OpenCdba, setOpenCdba] = useState(false);
  const [OpenGrandes, setOpenGrandes] = useState(false);
  const [isSubmitting, setisSubmitting] = useState(false);
  const [provinciaselected, setprovinciaselected] = useState({
    id_provincia: null,
    provincia: null,
  });

  const [Padprovincia, setPadprovincia] = useState([]);
  const [Provincias, setProvincias] = useState([]);

  const [isLoading, setisLoading] = useState(false);

  const getProvincias = () => {
    axios
      .get(UrlApi + "/provincias/padron")
      .then((res) => setProvincias(res.data));
  };
  const getPadronProvincia = useCallback(async () => {
    if (!Provincias || Provincias.length === 0) {
      console.error("Provincias no está definido o está vacío.");
      return;
    }

    setisLoading(true);
    try {
      const response = await axios.post(UrlApi + "/padron/obt-padronfechas", {
        provincias: Provincias, // Envolver en un objeto con la clave `provincias`
      });
      setPadprovincia(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setisLoading(false);
    }
  }, [Provincias]);

  useEffect(() => {
    getProvincias(); // Primero obtenemos las provincias
  }, []);

  useEffect(() => {
    if (Provincias && Provincias.length > 0) {
      getPadronProvincia(); // Luego obtenemos los datos del padrón una vez que las provincias estén cargadas
    }
  }, [Provincias]); // Esto se ejecuta solo cuando `Provincias` cambia

  const onclick = (event, id_provincia, provincia) => {
    setprovinciaselected({ id_provincia: id_provincia, provincia: provincia });
    setOpen(!Open);
  };
  const openGrandesEmp = (e) => {
    setOpenGrandes(!OpenGrandes);
  };
  const onclickArba = () => {
    setOpenArba(!OpenArba);
  };
  const onclickCdba = () => {
    setOpenCdba(!OpenCdba);
  };
  const fechaActual = moment().format("YYYY-MM-DD");

  const fecha_vigencia_desde = moment(
    Padprovincia?.fecha_vigencia_desde
  ).format("YYYY-MM-DD");

  const fecha_vigencia_hasta = moment(
    Padprovincia?.fecha_vigencia_hasta
  ).format("YYYY-MM-DD");

  if (isSubmitting === true) {
    setTimeout(() => {
      setisSubmitting(false);
    }, 120000);
  }
  return (
    <div>
      <Navbar />
      <Container fixed maxWidth={"xl"}>
        <Box sx={{ flexGrow: 1, backgroundColor: "#1F618D", marginTop: 2 }}>
          <Grid container p={1.5}>
            <Grid
              // item
              size={12}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                variant="h5"
                fontSize={"3rem"}
                fontFamily={"initial"}
                color="#ffffff"
              >
                Padrones
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Toolbar />
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="stretch"
          wrap="wrap"
        >
          <Grid size={5} md={5}>
            <Card
              sx={{
                backgroundColor: "#DDDDDD",
                borderRadius: 16,
                padding: 5,
              }}
            >
              <CardContent
                sx={{
                  fontSize: 18,
                  fontWeight: "bold",
                  marginBottom: 2,
                  alignItems: "center",
                }}
              >
                Buenos Aires
              </CardContent>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                {/*    {isSubmiting && (
                  <LinearProgress
                    variant="determinate"
                    value={uploadPercentageArba}
                  />
                )} */}
                Subir Archivo de Percepcion y Retencion
              </CardContent>
              <CardActions sx={{ alignSelf: "flex-end" }}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={onclickArba}
                  startIcon={<CloudUploadIcon />}
                >
                  Subir Archivos
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item size={5} md={5}>
            <Card
              sx={{
                backgroundColor: "#DDDDDD",
                borderRadius: 16,
                padding: 5,
              }}
            >
              <CardContent
                sx={{
                  fontSize: 18,
                  fontWeight: "bold",
                  marginBottom: 2,
                  alignItems: "center",
                }}
              >
                Caba
              </CardContent>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                Subir el archivo txt
              </CardContent>
              <CardActions sx={{ alignSelf: "flex-end" }}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={(e) => onclick(e, 3, "caba")}
                  startIcon={<CloudUploadIcon />}
                >
                  Subir Archivos
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item size={5} md={5}>
            <Card
              sx={{
                backgroundColor: "#DDDDDD",
                borderRadius: 16,
                padding: 5,
              }}
            >
              <CardContent
                sx={{
                  fontSize: 18,
                  fontWeight: "bold",
                  marginBottom: 2,
                  alignItems: "center",
                }}
              >
                Cordoba
              </CardContent>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                Subir Archivo de Percepcion y Retencion
              </CardContent>
              <CardActions sx={{ alignSelf: "flex-end" }}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={onclickCdba}
                  startIcon={<CloudUploadIcon />}
                >
                  Subir Archivos
                </Button>
                {/*     {isSubmiting && (
                  <LinearProgress
                    variant="determinate"
                    value={uploadPercentageCbda}
                  />
                )} */}
              </CardActions>
            </Card>
          </Grid>
          <Grid item size={5} md={5}>
            <Card
              sx={{
                backgroundColor: "#DDDDDD",
                borderRadius: 16,
                padding: 5,
              }}
            >
              <CardContent
                sx={{
                  fontSize: 18,
                  fontWeight: "bold",
                  marginBottom: 2,
                  alignItems: "center",
                }}
              >
                Entre Rios
              </CardContent>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                Subir el archivo csv
              </CardContent>
              <CardActions sx={{ alignSelf: "flex-end" }}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={(e) => onclick(e, 8, "entre-rios")}
                >
                  Subir Archivos
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item size={5} md={5}>
            <Card
              sx={{
                backgroundColor: "#DDDDDD",
                borderRadius: 16,
                padding: 5,
              }}
            >
              <CardContent
                sx={{
                  fontSize: 18,
                  fontWeight: "bold",
                  marginBottom: 2,
                  alignItems: "center",
                }}
              >
                Jujuy
              </CardContent>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                Subir el archivo txt
              </CardContent>
              <CardActions sx={{ alignSelf: "flex-end" }}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={(e) => onclick(e, 9, "jujuy")}
                >
                  Subir Archivos
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item size={5} md={5}>
            <Card
              sx={{
                backgroundColor: "#DDDDDD",
                borderRadius: 16,
                padding: 5,
              }}
            >
              <CardContent
                sx={{
                  fontSize: 18,
                  fontWeight: "bold",
                  marginBottom: 2,
                  alignItems: "center",
                }}
              >
                Grandes Empresas
              </CardContent>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                Subir el archivo .csv
              </CardContent>
              <CardActions sx={{ alignSelf: "flex-end" }}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={(e) => openGrandesEmp(e)}
                >
                  Subir Archivos
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
        <Toolbar />
        {Open ? (
          <UploadPadron
            openclose={true}
            fileId={uuid()}
            isSubmitting={setisSubmitting}
            provinciaselected={provinciaselected}
            refetch={getPadronProvincia}
          />
        ) : null}
        {OpenArba ? (
          <DialogArba
            openclose={true}
            uuid={uuid()}
            isSubmitting={setisSubmitting}
            refetch={getPadronProvincia}
          />
        ) : null}
        {OpenCdba ? (
          <DialogCdba
            openclose={true}
            uuid={uuid()}
            isSubmitting={setisSubmitting}
            refetch={getPadronProvincia}
          />
        ) : null}
        {OpenGrandes ? (
          <GrandesEmpresas
            openclose={true}
            fileId={uuid()}
            tituloDialogo={"Padron Grandes Empresas"}
          />
        ) : null}
      </Container>
    </div>
  );
};

export default Padrones;
