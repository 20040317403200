//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio
// Creation Date : 05/09/2024
// Version : 1
// Description : Tabla de proximas fechas de revision
//------Changes -------------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import {React,useState,useEffect} from "react";
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { UrlApi } from "../../services/apirest";

function RepuestosTareas({ tareasXtanque }) {
  console.log("TAREAS X TANQUE DESDE REPUESTOS",tareasXtanque);

  const [datosRepuestos, setDatosRepuestos] = useState([])
  
  const [tabla, setTabla] = useState(true);

  const obtenerRepuestosXtarea = () => {
    fetch(UrlApi + "/tanque_tarea/repuestoXTanqueyTarea/" + tareasXtanque.id_tanque + '/' + tareasXtanque.uuid_tarea)
      .then((data) => data.json())
      .then((data) => setDatosRepuestos(data));
  };
  
  console.log("DATOS REPUESTOS DEL OBTENER", datosRepuestos);
  //ejecuta la funcion de obtener
  useEffect(() => {
    obtenerRepuestosXtarea();
  }, [tabla]);

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };
  const size = "small";

  return (
    <div>
      <br></br>
      <br></br>
      <Box
        my={1}
        backgroundColor="#F4F6F6"
        p={2}
        border={0.5}
        borderColor={"#ABB2B9"}
        borderRadius={4}
        maxWidth={600} // Ajusta el tamaño máximo del Box
        margin="auto" // Centra el Box en su contenedor
      >
         <TableContainer component={Paper}>
          <Table size="small">
            <TableHead sx={{ backgroundColor: '#2B76A6' }}>
              <TableRow>
                <TableCell  sx={{ color: 'white' }}>Artículo</TableCell>
                <TableCell  sx={{ color: 'white' }}>Cantidad</TableCell>
                <TableCell  sx={{ color: 'white' }}>Almacén</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {datosRepuestos.map((repuesto) => (
                <TableRow key={repuesto.id_repuesto}>
                  <TableCell>{repuesto.articulo_nombre}</TableCell>
                  <TableCell>{repuesto.cantidad}</TableCell>
                  <TableCell>{repuesto.almacen_nombre}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
}

export default RepuestosTareas;
