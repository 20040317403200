import React, { useState, useEffect } from "react";
import { UrlApi } from "../../../services/apirest";
import {
  Container,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Box,
  TextField,
  IconButton,
  Button,
  InputAdornment,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import { grey } from "@mui/material/colors";
import PinIcon from "@mui/icons-material/Pin";
import moment from "moment";
import Notify from "../../Notify";
import Alerta from "../../Alerta";
import CallIcon from "@mui/icons-material/Call";
import { getData, getCodeArg, putData } from "../../../services/library";

function Editarchofer(props) {
  const [datosformulario, setDatosformulario] = useState(props.datos);
  const [negocio, setnegocio] = useState(null);
  const [usuarios, setUsuarios] = useState(null);
  const [disBtn, setDisBtn] = useState(false);
  const [ptosVtaHiper, setPtosVtaHiper] = useState();
  const [ptosVtaAuto, setPtosVtaAuto] = useState();
  const [codArg, setCodArg] = useState("");

  // console.log("DATOS FORMULARIO: " , datosformulario)

  const [notificacion, setNotificacion] = useState({
    open: false,
    mensaje: "",
    severity: "",
    color: "",
  });

  useEffect(() => {
    setDatosformulario({
      ...props.datos,
      telefono: props.datos.telefono
        ? props.datos.telefono.replace(/^(\+?549)?(\d{10})$/, "$2")
        : "",
    });
  }, [props.datos]);

  const obtenerPtosVtaHiperYAuto = async () => {
    try {
      const [hiperResponse, autoResponse] = await Promise.all([
        fetch(
          UrlApi +
            "/puntos_venta/autocompleteed/1/" +
            props.datos.pto_vta_hipergas
        ),
        fetch(
          UrlApi +
            "/puntos_venta/autocompleteed/4/" +
            props.datos.pto_vta_autogas
        ),
      ]);

      if (!hiperResponse.ok || !autoResponse.ok) {
        throw new Error("Error en la respuesta de la API");
      }

      const hiperData = await hiperResponse.json();
      const autoData = await autoResponse.json();

      if (!!hiperData && !!autoData) {
        setPtosVtaHiper(hiperData);
        setPtosVtaAuto(autoData);

        setDatosformulario((prevState) => ({
          ...prevState,
          pto_vta_hipergas:
            hiperData.find(
              (pto) => parseInt(pto.id_punto) === prevState.pto_vta_hipergas
            ) || null,
          pto_vta_autogas:
            autoData.find(
              (pto) => parseInt(pto.id_punto) === prevState.pto_vta_autogas
            ) || null,
        }));
      } else {
        console.error("Error: Los datos de puntos de venta no son válidos");
      }
    } catch (error) {
      console.error("Error al obtener puntos de venta:", error);
    }
  };

  useEffect(() => {
    obtenerPtosVtaHiperYAuto();
    getData("/desplegables/usoVehiculo", setnegocio);
    getData(
      "/choferes/xusernameypuesto/" + datosformulario.username,
      setUsuarios
    );
    getCodeArg(setCodArg);
  }, []);

  const handleClose = () => {
    return props.editar(false), props.tabla(true), props.actualizar(1);
  };

  // console.log(datosformulario?.pto_vta_autogas?.id_punto)
  // console.log(datosformulario?.pto_vta_hipergas?.id_punto)

  async function guardarChofer(e) {
    e.preventDefault();
    setDisBtn(true);
    putData(
      e,
      "/choferes/updatechofyptovta/" + datosformulario.id,
      {
        reqBody: {
          id_sisven: datosformulario.id_sisven,
          nombre_apellido: datosformulario.nombre_apellido,
          dni: datosformulario.dni,
          usuario: localStorage.getItem("user"),
          legajo: datosformulario.legajo,
          nro_licencia: datosformulario.nro_licencia,
          vencimiento_licencia:
            datosformulario.vencimiento_licencia !== ""
              ? datosformulario.vencimiento_licencia
              : null,
          examen_carga:
            datosformulario.examen_carga !== ""
              ? datosformulario.examen_carga
              : null,
          negocio: datosformulario.negocio,
          username: datosformulario.username,
          telefono: datosformulario.telefono
            ? codArg + datosformulario.telefono
            : null,
          pto_vta_autogas:
            parseInt(datosformulario?.pto_vta_autogas?.id_punto) || null,
          pto_vta_hipergas:
            parseInt(datosformulario?.pto_vta_hipergas?.id_punto) || null,
        },
        ptos_anteriores: {
          pto_anterior_hiper: props.datos.pto_vta_hipergas,
          pto_anterior_auto: props.datos.pto_vta_autogas,
        },
      },
      setNotificacion
    );
  }

  //funcion para almacenar
  const almacenar = (e) => {
    setDatosformulario({
      ...datosformulario,
      [e.target.name]: e.target.value === "" ? null : e.target.value,
    });
  };

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  const size = "small";

  return (
    <div>
      <br></br>
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container backgroundColor="#1F618D" p={1.5}>
            <Grid item xs={2}>
              <IconButton onClick={handleClose}>
                <ArrowBackIcon sx={{ color: grey[50] }} />
              </IconButton>
            </Grid>

            <Grid
              item
              xs={8}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h5" color="#ffffff">
                Editar chofer
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <form onSubmit={guardarChofer} autoComplete="off">
          <Box
            backgroundColor="#F4F6F6"
            mt={2}
            p={3}
            border={0.5}
            borderColor={"#ABB2B9"}
            borderRadius={4}
          >
            <Grid container spacing={1} direction={{ xs: "column", sm: "row" }}>
              <Grid item xs={6}>
                <TextField
                  size={size}
                  placeholder="Nombre completo"
                  style={style}
                  focused
                  color="primary"
                  required
                  name="nombre_apellido"
                  label="Nombre completo"
                  variant="outlined"
                  value={
                    datosformulario.nombre_apellido &&
                    datosformulario.nombre_apellido.replace(/\b\w/g, (l) =>
                      l.toUpperCase()
                    )
                  }
                  inputProps={{ maxLength: 100 }}
                  onChange={almacenar}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccessibilityNewIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  size={size}
                  style={style}
                  focused
                  required
                  color="primary"
                  placeholder="ID de Sisven"
                  type="number"
                  name="id_sisven"
                  label="ID SISVEN"
                  variant="outlined"
                  value={datosformulario.id_sisven}
                  onChange={almacenar}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountBoxIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  size={size}
                  focused
                  placeholder="DNI"
                  color="primary"
                  style={style}
                  type="number"
                  required
                  name="dni"
                  label="DNI"
                  variant="outlined"
                  value={datosformulario.dni}
                  onChange={almacenar}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircleIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  size={size}
                  style={style}
                  focused
                  required
                  placeholder="Legajo"
                  type="number"
                  name="legajo"
                  label="Legajo"
                  variant="outlined"
                  value={datosformulario.legajo}
                  onChange={almacenar}
                  inputProps={{ maxLength: 45 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircleIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  size={size}
                  style={style}
                  focused
                  placeholder="Nro licencia"
                  type="number"
                  required
                  name="nro_licencia"
                  label="Nro licencia"
                  variant="outlined"
                  value={datosformulario.nro_licencia}
                  onChange={almacenar}
                  inputProps={{ maxLength: 45 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PinIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  size={size}
                  style={style}
                  focused
                  type="date"
                  placeholder="Vencimiento licencia"
                  name="vencimiento_licencia"
                  label="Vencimiento licencia"
                  variant="outlined"
                  value={moment(datosformulario.vencimiento_licencia).format(
                    "YYYY-MM-DD"
                  )}
                  onChange={almacenar}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <InsertInvitationIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  size={size}
                  style={style}
                  focused
                  type="date"
                  placeholder="Exámen cargas"
                  name="examen_carga"
                  label="Examen cargas"
                  variant="outlined"
                  value={moment(datosformulario.examen_carga).format(
                    "YYYY-MM-DD"
                  )}
                  onChange={almacenar}
                />
              </Grid>

              <Grid item xs={6}>
                <FormControl
                  style={style}
                  size={size}
                  focused
                  color="primary"
                  required
                >
                  <InputLabel id="lbl_negocio">Negocio</InputLabel>
                  <Select
                    name="negocio"
                    label="Negocio "
                    value={datosformulario.negocio}
                    onChange={almacenar}
                  >
                    {negocio
                      ? negocio.map((elemento) => (
                          <MenuItem key={elemento.id} value={elemento.id}>
                            {elemento.valor}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl style={style} focused color="primary" size={size}>
                  <InputLabel id="username">Username</InputLabel>
                  <Select
                    name="username"
                    label="Username "
                    value={datosformulario.username}
                    onChange={almacenar}
                  >
                    {usuarios
                      ? usuarios.map((elemento) => (
                          <MenuItem
                            key={elemento.username}
                            value={elemento.username}
                          >
                            {elemento.username}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              {ptosVtaHiper && (
                <Grid item xs={3}>
                  <Autocomplete
                    disabled={!ptosVtaHiper}
                    size={size}
                    focused
                    disableClearable
                    value={datosformulario.pto_vta_hipergas}
                    noOptionsText={"Punto de Venta NO DISPONIBLE"}
                    options={ptosVtaHiper}
                    autoHighlight
                    getOptionLabel={(ptosVtaHiper) => ptosVtaHiper.id_punto}
                    onChange={(event, value) =>
                      setDatosformulario({
                        ...datosformulario,
                        pto_vta_hipergas: value,
                      })
                    }
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        key={option.id}
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.id_punto}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        focused
                        size={size}
                        color="primary"
                        style={style}
                        variant="outlined"
                        placeholder="Tipee para buscar..."
                        {...params}
                        label="Punto de Venta HIPERGAS"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
              )}
              {ptosVtaAuto && (
                <Grid item xs={3}>
                  <Autocomplete
                    disabled={!ptosVtaAuto}
                    value={datosformulario.pto_vta_autogas}
                    size={size}
                    focused
                    disableClearable
                    noOptionsText={"Punto de Venta NO DISPONIBLE"}
                    options={ptosVtaAuto}
                    autoHighlight
                    getOptionLabel={(ptosVtaAuto) => ptosVtaAuto.id_punto}
                    onChange={(event, value) =>
                      setDatosformulario({
                        ...datosformulario,
                        pto_vta_autogas: value,
                      })
                    }
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        key={option.id}
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.id_punto}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        focused
                        size={size}
                        color="primary"
                        style={style}
                        variant="outlined"
                        placeholder="Tipee para buscar..."
                        {...params}
                        label="Punto de Venta AUTOGAS"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <TextField
                  size={size}
                  focused
                  placeholder="Teléfono"
                  color="primary"
                  style={style}
                  type="number"
                  name="telefono"
                  label="Teléfono"
                  variant="outlined"
                  value={datosformulario.telefono}
                  onChange={almacenar}
                  inputProps={{
                    maxLength: 10,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">{codArg}</InputAdornment>
                    ),
                  }}
                  helperText="Cód área (sin 0) + número sin el 15. Ej:341xxxxxx "
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-end"
              mt={2}
            >
              <Button variant="contained" onClick={handleClose} color="error">
                Volver
              </Button>

              <Button
                disabled={disBtn}
                variant="contained"
                type="submit"
                color="success"
              >
                Guardar Chofer
              </Button>
            </Grid>
          </Box>
        </form>
      </Container>
      {notificacion && notificacion.open ? (
        <Notify
          mensaje={notificacion.mensaje}
          open={notificacion.open}
          color={notificacion.color}
          handleClose={() => {
            handleClose();
          }}
          severity={notificacion.severity}
          duracion={1000}
        />
      ) : null}
    </div>
  );
}

export default Editarchofer;
