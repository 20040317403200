import React, { useState, useEffect, useMemo, useRef } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Snackbar,
  Alert,
} from "@mui/material";
import moment from 'moment';
import { logEventos } from "../../services/services";

function DialogCancelarViaje(props) {
const [openSnack, setOpenSnack] = React.useState(false);
const [colormensaje, setColorMensaje] = useState("");
const [mensaje, setMensaje] = useState("");
const [abrirCancelar, setAbrirCancelar] = useState(props.cancelar);
const [datosRow, setDatosRow] = useState(props.datosRow);

//*************************************************** Descontar proveedores****************************************************************** */

const [paraDescontar, setParaDescontar] = useState();

//funcion para obtener articulos
const obtenerParaDescontar = () => {
  fetch(UrlApi + "/proveedores_abastecimiento/paradescontar/"+datosRow.empresa+"/"+datosRow.proveedor+"/"+datosRow.fecha)
    .then((data) => data.json())
    .then((data) => setParaDescontar(data));
};

useEffect(() => {
  obtenerParaDescontar();
}, []);

//console.log("para Descontar", paraDescontar, datosRow.tipo_viaje);

let clave = null
let tipoViaje = "viaje_"+datosRow.tipo_viaje;


if (paraDescontar && tipoViaje) {
  // Verificar si paraDescontar es un array y tiene al menos un elemento
  if (Array.isArray(paraDescontar) && paraDescontar.length > 0) {
    // Obtener el primer elemento del array
    const primerElemento = paraDescontar[0];
    // Iterar sobre las claves del primer elemento
    for (let columna in primerElemento) {
      // Verificar si la columna coincide con tipoViaje
      if (columna === tipoViaje) {
        // Obtener el valor correspondiente
        clave = primerElemento[columna];
        break; // Romper el bucle una vez encontrado el valor
      }
    }
  }
}

let clavebd = tipoViaje

let valor = clave + parseInt(1);
//console.log("Valor correspondiente:", clave, valor, clavebd);
// Crear un objeto con la clave variable y el valor deseado
let bodyData = {};
bodyData[clavebd] = valor;

//*************************************************** Descontar solicitudes ****************************************************************** */

const [paraDescontarSoli, setParaDescontarSoli] = useState();

//funcion para obtener articulos
const obtenerParaDescontarSoli = () => {
  fetch(UrlApi + "/solicitudes_abastecimiento/paradescontar/"+datosRow.destino+"/"+datosRow.fecha_llegada)
    .then((data) => data.json())
    .then((data) => setParaDescontarSoli(data));
};

useEffect(() => {
  obtenerParaDescontarSoli();
}, []);

//console.log("para Descontar", paraDescontarSoli, datosRow.tipo_viaje);

let claveSoli = null
let tipoViajeSoli = "viaje_"+datosRow.tipo_viaje;


if (paraDescontarSoli && tipoViajeSoli) {
  // Verificar si paraDescontar es un array y tiene al menos un elemento
  if (Array.isArray(paraDescontarSoli) && paraDescontarSoli.length > 0) {
    // Obtener el primer elemento del array
    const primerElemento = paraDescontarSoli[0];
    // Iterar sobre las claves del primer elemento
    for (let columna in primerElemento) {
      // Verificar si la columna coincide con tipoViaje
      if (columna === tipoViajeSoli) {
        // Obtener el valor correspondiente
        claveSoli = primerElemento[columna];
        break; // Romper el bucle una vez encontrado el valor
      }
    }
  }
}

let clavebdsoli = tipoViajeSoli

let valorSoli = claveSoli + parseInt(1);
//console.log("Valor correspondiente Soli:", claveSoli, valorSoli, clavebdsoli);
// Crear un objeto con la clave variable y el valor deseado
let bodyDataSoli = {};
bodyDataSoli[clavebdsoli] = valorSoli;


const guardarConfirmacionCancelacion = async (e) => {
  logEventos("Guardar viaje cancelado", "Viajes", "Se guardo la cancelación del viaje", localStorage.getItem("user"))
    e.preventDefault();
    // setDisabledGuardar(true);
      // PUT DE DESCUENTO DE PROVEEDORES
      await fetch(UrlApi + "/proveedores_abastecimiento/descuento/"+datosRow.empresa+"/"+datosRow.proveedor+"/"+datosRow.fecha, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(bodyData),
      })
        .then((res) => {
          if (!res.err) {
            console.log("Guardado");
          } else {
            console.log("Error");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
  
      (datosRow.tipo_viaje === 'butano' || datosRow.tipo_viaje === 'propano'?( 
        // PUT DE DESCUENTO DE SOLICITUDES
      await fetch(UrlApi + "/solicitudes_abastecimiento/descuento/"+datosRow.destino+"/"+datosRow.fecha_llegada, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(bodyDataSoli),
      })
        .then((res) => {
          if (!res.err) {
            console.log("Guardado");
          } else {
            console.log("Error");
          }
        })
        .catch((err) => {
          console.log(err.message);
        }))
        :console.log("NOOOOOO"))

      await fetch(UrlApi + '/viajes_abastecimiento/' + datosRow.id_viaje, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' }
      })

    handleClose();
  };

const handleClose = () => {
    setAbrirCancelar(!abrirCancelar)
      if (props.onClose) {
          props.onClose();
        }
    };  

  //Snackbar Notificacion
const handleCloseSnack = (event, reason) => {
  if (reason === "clickaway") {
    return;
  }
  setOpenSnack(false);
};

  return (
    <>
    <Dialog
    open={abrirCancelar}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title" alignSelf="center">
      {"Confirmar Cancelación"}
    </DialogTitle>
    <DialogContent>
      <Container maxWidth="x1">
        <form onSubmit={guardarConfirmacionCancelacion}>
          <p style={{ fontSize: "20px" }}>
            ¿Desea cancelar el viaje N° {datosRow.id_viaje}?
          </p>

          <Stack direction="row">
            <Grid container direction={{ xs: "column", sm: "row" }}>
              <Grid item xs={9}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleClose}
                >
                  No
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  fullWidth
                  variant="contained"
                  color="success"
                  type="submit"
                >
                  Si
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </form>
      </Container>
    </DialogContent>
  </Dialog>
  <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={openSnack}
        autoHideDuration={1500}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnack}
          severity="success"
          sx={{ width: "100%" }}
          style={{ backgroundColor: colormensaje, color: "black" }}
        >
          {mensaje}
        </Alert>
      </Snackbar>
    </Stack>
  </>
  )
}

export default DialogCancelarViaje