import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { UrlApi } from "../../../../../services/apirest";
import moment from "moment";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function ModalUltCert(props) {
  const [datosTanque, setDatosTanque] = useState(props.datos);
  const [certificacion, setCertificacion] = useState([{}]);
  const [open, setOpen] = useState(true);

  const obtenerCertificaciones = () => {
    fetch(UrlApi + "/certificaciones/ultima/" + datosTanque.id) //Todavia falta crear esta ruta en la api, que deberia traernos cual es la ultima certificación de este tanque (seria la q tiene la ultima fecha de vencimiento???)
      .then((data) => data.json())
      .then((data) => setCertificacion(data));
  };

  useEffect(() => {
    obtenerCertificaciones();
  }, [])

  certificacion&&console.log(certificacion[0])

  const handleClose = () => {
    setOpen(false);
    props.modalCert(false);
  };

  return (
    <div>
      {certificacion&&certificacion.length > 0 ? (
        <>
          <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
              <Typography variant="h6" component="h2">
                Última Certificación
              </Typography>
              <hr></hr>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <b>Fecha Certificacion: </b> {moment(certificacion&&certificacion[0].fecha_certificacion).format("DD-MM-YYYY")}<br></br>
            <b>Fecha Vencimiento: </b> {moment(certificacion&&certificacion[0].vencimiento_certificacion).format("DD-MM-YYYY")}<br></br>
            <b>Certificadora: </b> {certificacion&&certificacion[0].certificadora}<br></br>
              </Typography>
            </Box>
          </Modal>
        </>
      ) : 
      <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
              <Typography variant="h6" component="h2">
                Este tanque no tiene certificaciones registradas!
              </Typography>            
            </Box>
          </Modal>}
    </div>
  );
}

export default ModalUltCert;
