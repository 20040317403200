//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio
// Creation Date : 12/09/2024
// Version : 1
// Description : tabla de grupos del tanque
//------Changes --------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useEffect, useState, useMemo } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Container,
  Typography,
  Tooltip,
  IconButton,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  DialogTitle,
} from "@mui/material";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import BlockIcon from "@mui/icons-material/Block";
import Notify from "../Notify";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { logEventos } from "../../services/services";

function TablaPertenencias2(props) {
  const [grupos, setGrupos] = useState(); //tabla
  const [datosGrupos, setDatosGrupos] = useState({});
  const [tabla, setTabla] = useState(true);
  const globalTheme = useTheme();
  const [row, setRow] = useState(props.row);
  const [confirmar, setConfirmar] = useState(false);
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
  const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)

  const [update, setUpdate] = useState(false);

  const obtenerGrupos = () => {
    fetch(
      UrlApi + "/tanque_pertenencia/gruposXtanque/" + row.original.id_tanque
    )
      .then((data) => data.json())
      .then((data) => setGrupos(data));
  };

  useEffect(() => {
    obtenerGrupos();
  }, []);

  //  console.log("GRUPOS X TANQUE", grupos);

  // para actualizar
  useEffect(() => {
    if (update) {
      obtenerGrupos();
      setUpdate(false);
    }
  }, [update]);

  const abrirModalConfirmar = (datosDeLaLinea) => {
    setConfirmar(true);
    setDatosGrupos(datosDeLaLinea);
  };
  const handleVolver = () => {
    setConfirmar(false);
    setTabla(true);
  };

  // columnas tabla
  const columns = useMemo(
    () => [
      {
        header: "Nombre del grupo",
        accessorKey: "nombre_grupo",
        size: 200,
      },
      {
        header: "Tipo de grupo",
        accessorKey: "estado_palabra_tipo",
        size: 200,
      },
    ],
    []
  );
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)" //background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
            },
          },
        },
      }),
    [globalTheme]
  );
  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "success",
    size: "small",
  };
  const initialState = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
  };
  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };

  const marcarInactivo = async (e) => {
     logEventos("Marca inactivo la pertenencia", "Pertenencias Cheklist", "Se inactivo la pertenencia", localStorage.getItem("user"))
    e.preventDefault();
    // console.log("ESTADO", datosGrupos.estado_pertenencia);
    const response = await fetch(
      UrlApi + "/tanque_pertenencia/" + datosGrupos.id_pertenencia,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(
          datosGrupos.estado_pertenencia === 0
            ? { estado_pertenencia: 1 }
            : { estado_pertenencia: 0 }
        ),
      }
    );
    if (response.ok === false) {
      const error = response && response.json();
      setError(error);
      alert("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      try {
        const json = await response.json();
        if (json.message) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje(
            json.message === "OK" ? "Guardado" : "Guardado. Actualizar RTA Api."
          );
          obtenerGrupos();
        } else if (json.sqlMessage) {
          json && setError(json);
          alert("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
        } else {
          alert("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje("Guardado");
          obtenerGrupos();
        } else {
          alert("Error: " + error);
        }
      }
    }
    handleVolver();
    obtenerGrupos();
  };

  // console.log(
  //   "id y estado ",
  //   datosGrupos.id_pertenencia,
  //   datosGrupos.estado_pertenencia
  // );
  // console.log("ROW ORIGINAL", row.original);

  return (
    <div>
      <br></br>
      <br></br>
      <Container maxWidth="xl" mt={1} mb={2}>
        {grupos && tabla ? (
          <Box mt={2} boxShadow={3}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableColumnResizing
                columns={columns}
                data={grupos}
                initialState={initialState}
                positionActionsColumn="last"
                enableExpandAll={false}
                enableToolbarInternalActions={true}
                muiTableHeadCellProps={tableHeadCellProps}
                muiTableBodyCellProps={({ row }) => ({
                  align: "left",
                  sx: {
                    backgroundColor:
                      row.original.estado_pertenencia === 0
                        ? "#efc3c3"
                        : "#b3e6b3",
                    color: "black",
                    borderColor: "black",
                  },
                })}
                enableColumnActions={false}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                enableDensityToggle={false}
                renderTopToolbarCustomActions={() => (
                  <>
                    <Typography variant="poster" fontSize={20}>
                      Grupos que apareceran en el checklist para este tanque
                    </Typography>
                  </>
                )}
                layoutMode="grid"
                localization={MRT_Localization_ES}
                enableColumnDragging={false} //No permite que se arrastren las columnas.
                enableEditing
                // ACCIONES
                renderToolbarInternalActions={(
                  { table } // boton para nuevo , al lado del buscar
                ) => (
                  <>
                    <MRT_FullScreenToggleButton table={table} />
                  </>
                )}
                renderRowActions={(
                  { row } //las acciones del costado editar y ver
                ) => (
                  <div>
                    {row.original.estado_pertenencia === 0 ? (
                      <Tooltip title="Marcar como activo">
                        <IconButton
                          color="success"
                          onClick={() => abrirModalConfirmar(row.original)}
                        >
                          <CheckCircleOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Marcar como inactivo">
                        <IconButton
                          onClick={() => abrirModalConfirmar(row.original)}
                          color="error"
                        >
                          <BlockIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                )}
              />
            </ThemeProvider>
            <br />
            <br />
          </Box>
        ) : null}

        {confirmar ? (
          <div>
            <Dialog open={confirmar} onClose={handleVolver}>
              <form onSubmit={marcarInactivo}>
                <Box backgroundColor={"#1F618D"}>
                  <DialogTitle>
                    {datosGrupos.estado_pertenencia === 0 ? (
                      <Typography variant="h6" align="center" color="#ffffff">
                        Agregar {datosGrupos.nombre_grupo}
                      </Typography>
                    ) : (
                      <Typography variant="h6" align="center" color="#ffffff">
                        Quitar {datosGrupos.nombre_grupo}
                      </Typography>
                    )}
                  </DialogTitle>
                </Box>
                <DialogContent>
                  <DialogContentText textAlign={"center"}>
                    {datosGrupos.estado_pertenencia === 0 ? (
                      <> ¿Desea agregar la pertenencia ? </>
                    ) : (
                      <> ¿Desea quitar la pertenencia ? </>
                    )}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItemns="center"
                  >
                    <Button
                      variant="contained"
                      onClick={handleVolver}
                      style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Confirmar
                    </Button>
                  </Grid>
                </DialogActions>
              </form>
            </Dialog>
          </div>
        ) : null}
        {notificacion ? (
          <Notify
            mensaje={mensaje}
            open={openSnack}
            color={"#D4EFDF"}
            severity={"success"}
            handleClose={handleVolver}
          />
        ) : null}
      </Container>
    </div>
  );
}

export default TablaPertenencias2;
