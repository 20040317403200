import React, { useRef } from "react";
import { useEffect, useState } from "react";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Autocomplete,
  createFilterOptions,
  Tooltip,
  IconButton,
  styled,
  FormHelperText,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CardContent,
  Card,
  Divider,
} from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  Delete,
  AddCircleOutline,
  DisabledByDefaultRounded,
} from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { useStaticPicker } from "@mui/x-date-pickers/internals";
import { UrlApi } from "../../../services/apirest";
import PeopleIcon from "@mui/icons-material/People";
import StoreIcon from "@mui/icons-material/Store";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";

function DialogObjetivosComerciales(props) {
  const [sucursalAuth, setSucursalAuth] = useState()
  const [sucursales, setSucursales] = useState()
  const [sucursalSeleccionada, setSucursalSeleccionada] = useState({})
  const [objetivos, setObjetivos] = useState()

  const [selectedDate, setSelectedDate] = useState(
    moment(new Date()).format("YYYY-MM")
  );

  const obtenerSucAuth = () => {
    fetch(UrlApi + "/representantes/suc_authxcomercial/"+props.representante[0].id)
      .then((data) => data.json())
      .then((data) => setSucursalAuth(data));
  };
    
  useEffect(() => {
    obtenerSucAuth();
  }, []);

  const obtenerSucursales = () => {
    if (sucursalAuth && sucursalAuth.length > 0) {
      fetch(UrlApi + "/sucursales/xsucauth/"+sucursalAuth[0].suc_auth)
        .then((data) => data.json())
        .then((data) => setSucursales(data));
    }
    };
        
    useEffect(() => {
      obtenerSucursales();
    }, [sucursalAuth]);
    
    
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };
  const formattedDate = `${selectedDate}-01`;

  const currentMonth = moment();
  const monthOptions = Array.from({ length: 12 }, (_, i) => {
    const month = currentMonth.clone().subtract(i, "months").format("YYYY-MM");
    return {
      value: month,
      label: currentMonth.clone().subtract(i, "months").format("MMMM YYYY"),
    };
  });

  const obtenerObjetivos = () => {
    fetch(UrlApi + "/objetivos_comerciales/vistacomerciales/"+props.representante[0].id+"/"+formattedDate+"/"+sucursalSeleccionada.id)
      .then((data) => data.json())
      .then((data) => setObjetivos(data));
  };
    
  useEffect(() => {
      obtenerObjetivos();
  }, [formattedDate, sucursalSeleccionada ]);
  console.log("obj",objetivos);
  return (
    <>
      <Dialog
        fullWidth
        open={props.open}
        onClose={() => props.onClose(false)}
        maxWidth="sm"
      >
        <Box justifyContent="center" backgroundColor="#1F618D">
          <DialogTitle>
            <Typography fontSize={20} align="center" color="#ffffff">
              Tus Objetivos
            </Typography>
          </DialogTitle>
        </Box>
        <form>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl focused color="primary" required size="small" fullWidth>
                  <InputLabel id="mes">Período</InputLabel>
                  <Select
                    required
                    variant="outlined"
                    fullWidth
                    name="mes"
                    value={selectedDate}
                    onChange={handleDateChange}
                    label="Período"
                    labelId="mes" // Asegúrate de que labelId coincida con el InputLabel
                  >
                    {monthOptions.map((elemento) => (
                      <MenuItem key={elemento.value} value={elemento.value}>
                        {elemento.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
              <Autocomplete
                    disabled={!sucursales}
                    size="small"
                    focused
                    required
                    disableClearable
                    id="sucursal"
                    noOptionsText={"Sucursal INEXISTENTE"}
                    options={sucursales}
                    autoHighlight
                    getOptionLabel={(sucursal) =>
                        sucursal.id + "-" + sucursal.nombre
                    }
                    onChange={(event, value) =>
                        setSucursalSeleccionada(value)
                    }
                    renderOption={(props, option) => (
                        <Box
                            component="li"
                            key={option.id}
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                        >
                            {option.id} - {option.nombre}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            focused
                            size="small"
                            placeholder="Tipee para buscar..."
                            {...params}
                            label="SUCURSAL"
                            inputProps={{
                                ...params.inputProps,
                            }}
                        />
                    )}
                />
              </Grid>
            </Grid>
            <Box mt={2}>
            {objetivos && objetivos.length > 0 ? (
        objetivos.map((objetivo, index) => (
          <Card
            key={index}
            sx={{
              mb: 2,
              p: 3,
              backgroundColor: "#f8f9fa", // Fondo gris muy claro
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Sombra suave
              borderRadius: 8,
              border: "1px solid #dee2e6", // Borde gris claro para separar suavemente
            }}
          >
            <CardContent>
              {/* Título del objetivo */}
              <Typography
                variant="h6"
                sx={{
                  color: "#1F618D", // Color principal para el título
                  fontWeight: "bold",
                  textAlign: "center",
                }}
                gutterBottom
              >
                {objetivo.nombre_comercial} - {objetivo.nombre_sucursal} -{" "}
                {moment(objetivo.fecha).format("MM/YYYY")}
              </Typography>

              <Divider sx={{ my: 2, borderColor: "#dee2e6" }} />

              {/* Objetivo Mensual */}
              <Typography 
              variant="body1"
              sx={{ color: "#1F618D" }} // Gris oscuro para subtítulo
              gutterBottom
              fontWeight="medium"
              >
                Objetivo Mensual:
              </Typography>
              <Typography
                variant="h6"
                sx={{ color: "#343a40", fontWeight: "bold" }} // Color gris oscuro para el objetivo mensual
              >
                {objetivo.objetivo.toLocaleString()}
              </Typography>

              <Divider sx={{ my: 2, borderColor: "#dee2e6" }} />

              {/* Objetivos de Butano */}
              <Typography
                variant="body1"
                sx={{ color: "#1F618D" }} // Gris oscuro para subtítulo
                gutterBottom
                fontWeight="medium"
              >
                Objetivos de Butano
              </Typography>

              <Grid container spacing={2} alignItems="center">
                {/* Usuarios */}
                <Grid item xs={4}>
                  <Box display="flex" alignItems="center">
                    <PeopleIcon sx={{ color: "#1F618D" }} />
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      ml={1}
                      fontWeight="medium"
                    >
                      Usuarios:
                    </Typography>
                  </Box>
                  <Typography variant="h6" sx={{ color: "#343a40", fontWeight: "bold" }}>
                    {objetivo.usuarios_butano ?? "N/A"}
                  </Typography>
                </Grid>

                {/* Comercios */}
                <Grid item xs={4}>
                  <Box display="flex" alignItems="center">
                    <StoreIcon sx={{ color: "#1F618D" }} />
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      ml={1}
                      fontWeight="medium"
                    >
                      Comercios:
                    </Typography>
                  </Box>
                  <Typography variant="h6" sx={{ color: "#343a40", fontWeight: "bold" }}>
                    {objetivo.comercios_butano ?? "N/A"}
                  </Typography>
                </Grid>

                {/* Distribuidores */}
                <Grid item xs={4}>
                  <Box display="flex" alignItems="center">
                    <LocalShippingIcon sx={{ color: "#1F618D" }} />
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      ml={1}
                      fontWeight="medium"
                    >
                      Distribuidores:
                    </Typography>
                  </Box>
                  <Typography variant="h6" sx={{ color: "#343a40", fontWeight: "bold" }}>
                    {objetivo.distribuidores_butano ?? "N/A"}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))
      ) : (
        <Typography align="center" color="textSecondary">
          No se encontraron objetivos para este período y sucursal seleccionada.
        </Typography>
      )}
    </Box>
          </DialogContent>
        </form>
      </Dialog>
    </>
  );
}

export default DialogObjetivosComerciales;
