//----- Component --------------------------------------------------------/

// Developer :  Nicolas Pascucci

// Creation Date : 21/05/2023

// Version : 1

// Description : Detalle de los resultados de los comerciales

//

//------Changes --------------------------------------------------------------------- -------------//

// Change Date :

// Change by :

// Change description:

//

// Change Date :

// Change by :

// Change description:

//

//========================================================================/
import React, { useEffect, useState } from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  Button,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PersonIcon from "@mui/icons-material/Person";
import BuildCircle from "@mui/icons-material/BuildCircle";
import AssignmentIcon from "@mui/icons-material/Assignment";
import HomeIcon from "@mui/icons-material/Home";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PercentIcon from "@mui/icons-material/Percent";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import StoreIcon from "@mui/icons-material/Store";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import FormatListNumberedRtlIcon from "@mui/icons-material/FormatListNumberedRtl";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import PropaneIcon from "@mui/icons-material/Propane";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AddTaskIcon from "@mui/icons-material/AddTask";
import SellIcon from "@mui/icons-material/Sell";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Rechazar_checklist from "./Rechazar_checklist";
import Autorizar_checklist from "./Autorizar_checklist";
import { UrlApi } from "../../../services/apirest";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

const ListItemComponent = ({ icon, primary, secondary }) => (
  <ListItem>
    <ListItemAvatar>
      <Avatar sx={{ bgcolor: "#EBF5FB", width: 30, height: 30 }}>{icon}</Avatar>
    </ListItemAvatar>
    <ListItemText
      primary={primary}
      secondary={secondary}
      primaryTypographyProps={{ variant: "h6", fontSize: "13px" }} // Tamaño de fuente para el texto primario
      secondaryTypographyProps={{
        variant: "h5",
        fontSize: "18px",
        fontWeight: "bold",
      }} // Tamaño de fuente más grande y negrita para el texto secundario
    />
  </ListItem>
);

const ListItemComponentDos = ({ icon, primary, secondary }) => (
  <ListItem>
    <ListItemAvatar>
      <Avatar sx={{ bgcolor: "#EBF5FB", width: 20, height: 30 }}>{icon}</Avatar>
    </ListItemAvatar>
    <ListItemText
      primary={primary}
      secondary={secondary}
      primaryTypographyProps={{ variant: "h5", fontSize: "25px" }} // Tamaño de fuente para el texto primario
      secondaryTypographyProps={{
        variant: "h5",
        fontSize: "18px",
        fontWeight: "bold",
      }} // Tamaño de fuente más grande y negrita para el texto secundario
    />
  </ListItem>
);

function DetalleResultado(props) {
  const [datos, setDatos] = useState(props.datos);
  const montoAPagar = (
    (datos.monto_pesos * datos.suma_resultado_porcentaje) /
    100
  ).toFixed(2);
  console.log("row", datos, props.esComercial);
  const [datosRow, setDatosRow] = useState({});
  const [autorizar, setAutorizar] = useState(false);
  const [rechazar, setRechazar] = useState(false);
  const handleClickRechazar = (row) => {
    setDatosRow(row);
    setRechazar(true);
  };
  const handleVolver = () => {
    setRechazar(false);
    props.obtenerResultados()
    props.obtenerResultadosAprobados()
    props.obtenerResultadosDesaprobados()
  };

  const handleClickAutorizar = (row) => {
    setDatosRow(row);
    setAutorizar(true);
  };
  const handleVolverAutorizar = () => {
    setAutorizar(false);
    props.obtenerResultados()
    props.obtenerResultadosAprobados()
    props.obtenerResultadosDesaprobados()
  };

    // *** USAR USEEFFECT PARA ESCUCHAR CAMBIOS Y RE-RENDERIZAR ***
    useEffect(() => {
      if (!autorizar && !rechazar) {
        setDatos(props.datos); // Actualiza datos cuando se desautoriza o rechaza
      }
    }, [autorizar, rechazar, props.datos]);

  // ********** AGREGADO PARA GUARDAR EN ESTADO EVALUACION ************* //
  const [subitemEvaluacion, setSubitemEvaluacion] = useState(null);

  const obtenerSubitems = () => {
    fetch(UrlApi + "/subitems_comerciales/xiditem/" + 5)
      .then((data) => data.json())
      .then((data) => setSubitemEvaluacion(data));
  };

  useEffect(() => {
    obtenerSubitems();
  }, []);
  const porcentajeChecklist =
    subitemEvaluacion && subitemEvaluacion[0]
      ? subitemEvaluacion[0].porcentaje
      : null;
      
      
  console.log("checklist:", datos.checklist, "esComercial:", props.esComercial, "userLog:", props.userLog, "checklist_rechazado:", datos.checklist_rechazado);

  
  
  

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <List
            sx={{
              width: "100%",
              bgcolor: "#EBF5FB",
              border: 0.5,
              borderRadius: 1,
              borderColor: "#A9CCE3",
              display: "flex",
              margin: "auto",
              justifyContent: "space-around",
            }}
          >
            <ListItemComponent
              icon={<PercentIcon color="primary" />}
              primary="Resultado (%)"
              secondary={datos.suma_resultado_porcentaje}
            />
            <ListItemComponent
              icon={<AddTaskIcon color="primary" />}
              primary="Objetivo del Periodo"
              secondary={datos.objetivo}
            />
            <ListItemComponent
              icon={<SellIcon color="primary" />}
              primary={`Vendido en el Periodo (+${datos.porcentaje_vendido}%)`}
              secondary={datos.vendido}
            />
            <ListItemComponent
              icon={<SupportAgentIcon color="primary" />}
              primary={`Clientes del Mes (+${datos.porcentaje_clientes}%)`}
              secondary={datos.clientes}
            />
            <ListItemComponent
              icon={<MoneyOffIcon color="primary" />}
              primary={`Montos Vencidos (+${datos.porcentaje_vencidos}%)`}
              secondary={datos.vencidos}
            />
            <ListItemComponent
              icon={<AttachMoneyIcon color="primary" />}
              primary="Monto a Pagar"
              secondary={montoAPagar}
            />
          </List>
        </Grid>
        <Grid item xs={12}>
          <List
            sx={{
              width: "100%",
              bgcolor: "#EBF5FB",
              border: 0.5,
              borderRadius: 1,
              borderColor: "#A9CCE3",
              display: "flex",
              margin: "auto",
              marginTop: "2",
            }}
          >
            <ListItemComponent
              icon={<FormatListNumberedRtlIcon color="primary" />}
              primary={`Checklist (+${datos.porcentaje_checklist}%)`}
              secondary={`${datos.checklist} ${datos.checklist_rechazado === 1?("(Autorizado)"):(datos.checklist_rechazado === 2?("(Rechazado)"):(""))}`}
            />
            <ListItemComponent
              icon={<CheckCircleOutlineIcon color="primary" />}
              primary="Items Cumplidos"
              secondary={datos.cumple === null ? "N/A" : datos.cumple}
            />
            <ListItemComponent
              icon={<DoDisturbAltIcon color="primary" />}
              primary="Items Incumplidos"
              secondary={datos.nocumple === null ? "N/A" : datos.nocumple}
            />
            {/* {datos.checklist === "Incompleto" ? (null) : (props.esComercial === false && props.userLog === 1)? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center", // Centrar los botones horizontalmente
                  alignItems: "center", // Alinear los botones verticalmente si es necesario
                  gap: "16px", // Espacio entre los botones
                }}
              >
                {datos.checklist_rechazado === 0?(
                  <>
                <Button
                  variant="contained"
                  color="error"
                  sx={{
                    borderRadius: "8px",
                    fontWeight: "bold",
                    height: "40px",
                    width: "110px",
                    marginRight: "1px",
                  }}
                  startIcon={<CloseIcon/>}
                  onClick={() => handleClickRechazar(datos)}
                >
                  Rechazar
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  sx={{
                    borderRadius: "8px",
                    fontWeight: "bold",
                    height: "40px",
                    width: "110px",
                    marginRight: "5px",
                  }}
                  startIcon={<CheckIcon/>}
                  onClick={() => handleClickAutorizar(datos)}
                >
                  Autorizar
                </Button>
                </>
            ):(datos.checklist_rechazado === 1?(
              <Button
                  variant="contained"
                  color="error"
                  sx={{
                    // padding: "8px 16px",
                    borderRadius: "8px",
                    fontWeight: "bold",
                    height: "40px",
                    width: "110px",
                    marginRight: "5px",
                  }}
                  startIcon={<CloseIcon/>}
                  onClick={() => handleClickRechazar(datos)}
                >
                  Rechazar
                </Button>
            ):(datos.checklist_rechazado===2?(
              <Button
                  variant="contained"
                  color="success"
                  sx={{
                    // padding: "8px 16px",
                    borderRadius: "8px",
                    fontWeight: "bold",
                    height: "40px",
                    width: "110px",
                    marginRight: "5px",
                  }}
                  startIcon={<CheckIcon/>}
                  onClick={() => handleClickAutorizar(datos)}
                >
                  Autorizar
                </Button>
            ):null))}
              </div>
            ) : null} */}
            {datos.checklist !== "Incompleto" && props.esComercial === false && props.userLog === "1" && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "16px",
        }}
      >
        {/* Botón "Rechazar" */}
        {datos.checklist_rechazado === 0 || datos.checklist_rechazado === 1 ? (
          <Button
            variant="contained"
            color="error"
            sx={{
              borderRadius: "8px",
              fontWeight: "bold",
              height: "40px",
              width: "110px",
            }}
            startIcon={<CloseIcon />}
            onClick={() => handleClickRechazar(datos)}
          >
            Rechazar
          </Button>
         ) : null}

        {/* Botón "Autorizar" */}
        {datos.checklist_rechazado === 0 || datos.checklist_rechazado === 2 ? (
          <Button
            variant="contained"
            color="success"
            sx={{
              borderRadius: "8px",
              fontWeight: "bold",
              height: "40px",
              width: "110px",
            }}
            startIcon={<CheckIcon />}
            onClick={() => handleClickAutorizar(datos)}
          >
            Autorizar
          </Button>
        ) : null} 
      </div>
     )} 
          </List>
        </Grid>
        <Grid item xs={12}>
          <List
            sx={{
              width: "100%",
              bgcolor: "#EBF5FB",
              border: 0.5,
              borderRadius: 1,
              borderColor: "#A9CCE3",
              display: "flex",
              margin: "auto",
              marginTop: "2",
            }}
          >
            <ListItemComponentDos
              icon={<PropaneIcon color="primary" />}
              primary="Butano"
            />
            <ListItemComponent
              icon={<PersonAddIcon color="primary" />}
              primary={
                <>
                  <Typography variant="h6" fontSize="15px">
                    Usuarios (+{datos.porcentaje_usuarios}%)
                  </Typography>
                  <Typography variant="h6" fontSize="13px">
                    Objetivo: {datos.objetivo_usuarios}
                  </Typography>
                  <Typography variant="h6" fontSize="13px">
                    Resultado: {datos.usuarios_butano}
                  </Typography>
                </>
              }
            />
            <ListItemComponent
              icon={<AddBusinessIcon color="primary" />}
              primary={
                <>
                  <Typography variant="h6" fontSize="15px">
                    Comercios (+{datos.porcentaje_comercios}%)
                  </Typography>
                  <Typography variant="h6" fontSize="13px">
                    Objetivo: {datos.objetivo_comerciales}
                  </Typography>
                  <Typography variant="h6" fontSize="13px">
                    Resultado: {datos.comercios_butano}
                  </Typography>
                </>
              }
            />
            <ListItemComponent
              icon={<LocalShippingIcon color="primary" />}
              primary={
                <>
                  <Typography variant="h6" fontSize="15px">
                    Distribuidores (+{datos.porcentaje_distribuidores}%)
                  </Typography>
                  <Typography variant="h6" fontSize="13px">
                    Objetivo: {datos.objetivo_distribuidores}
                  </Typography>
                  <Typography variant="h6" fontSize="13px">
                    Resultado: {datos.distribuidores_butano}
                  </Typography>
                </>
              }
            />
          </List>
        </Grid>
      </Grid>
      {rechazar ? (
        <Rechazar_checklist
          rechazar={rechazar}
          datosRow={datosRow}
          onClose={() => handleVolver()}
        />
      ) : null}
      {autorizar ? (
        <Autorizar_checklist
          autorizar={autorizar}
          datosRow={datosRow}
          porcentaje={porcentajeChecklist}
          onClose={() => handleVolverAutorizar()}
        />
      ) : null}
    </div>
  );
}

export default DetalleResultado;
