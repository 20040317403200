import React, { useEffect, useState, useMemo } from 'react';
import { Container, Typography, Tooltip, IconButton, DialogContentText, Box, Button, Dialog, DialogContent, DialogActions, Grid, DialogTitle, MenuItem, ListItemIcon, Divider } from '@mui/material';
import Navbar from '../../Navbar';
import MaterialReactTable, { MRT_FullScreenToggleButton } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import BlockIcon from '@mui/icons-material/Block';
import EditIcon from '@mui/icons-material/Edit';
import Addcomerciales from './Addcomerciales';
import Editarcomerciales from './Editarcomerciales';
import Notify from '../../Notify';
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonIcon from '@mui/icons-material/Person';
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PeopleIcon from '@mui/icons-material/People';
import { getData, putData } from '../../../services/library';
import { logEventos } from '../../../services/services';
import CheckIcon from '@mui/icons-material/Check';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ConfirmDialog from '../../Dialogs/ConfirmDialog';

export default function Comerciales() {
    const [comercial, setComercial] = useState();
    const [alta, setAlta] = useState(false)
    const [tabla, setTabla] = useState(true)
    const [editar, setEditar] = useState(false)
    const [confirmarActivo, setConfirmarActivo] = useState(false);
    const [confirmarInactivo, setConfirmarInactivo] = useState(false);
    const [datosformulario, setDatosformulario] = useState({})
    const globalTheme = useTheme();
    const iconCheck = <CheckIcon sx={{ fontSize: 40 }} />;
    const iconRemove = <RemoveCircleOutlineIcon sx={{ fontSize: 40 }} />;
    const [notify, setNotify] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
    });
    const [hiddenmuestrainactivo, setHiddenmuestrainactivo] = useState(false);
    const [hiddenmuestraactivos, setHiddenmuestraactivos] = useState(true);

    const obtenerComerciales = () => {
        setHiddenmuestraactivos(true);
        setHiddenmuestrainactivo(false);
        getData("/representantes/activos", setComercial)
    };

    const obtenerInactivos = () => {
        setHiddenmuestrainactivo(true);
        setHiddenmuestraactivos(false);
        getData("/representantes/inactivos", setComercial)
    };

    useEffect(() => {
        obtenerComerciales()
    }, [tabla]);

    const handleClickNuevo = () => {
        setAlta(true)
        setTabla(false)
    };

    const mostrareditar = (datosDeLaLinea) => {
        setTabla(false)
        setEditar(true)
        setDatosformulario(datosDeLaLinea)
    };

    const abrirModalConfirmarActivo = (datosDeLaLinea) => {
        setConfirmarActivo(true);
        setDatosformulario(datosDeLaLinea);
    };

    const abrirModalConfirmarInactivo = (datosDeLaLinea) => {
        setConfirmarInactivo(true);
        setDatosformulario(datosDeLaLinea);
    };

    const handleClose = () => {
        setConfirmarActivo(false);
        setConfirmarInactivo(false);
        confirmarActivo && obtenerInactivos()
        confirmarInactivo && obtenerComerciales()
        setTabla(true)
    };

    const columns = useMemo(() =>
        [
            { header: 'Nombre y Apellido', accessorKey: 'nombre_apellido' },
            // { header: 'Telefono', accessorKey: 'telefono' },
            { header: 'Legajo', accessorKey: 'legajo' },
            { header: 'Mail', accessorKey: 'mail' },
            // { header: 'Supervisor', accessorKey: 'supervisor' },
            { header: 'Sucursal', accessorKey: 'sucursal' },
            { header: 'Username', accessorKey: 'username' }
        ], []);

    const muiSearchTextFieldProps = {
        placeholder: "Buscar..",
        sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
        variant: "outlined",
        color: "success",
        size: "small",
    };

    const initialState = {
        density: "compact",
        showGlobalFilter: true,
        pagination: { pageIndex: 0, pageSize: 15 }
    };

    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: globalTheme.palette.mode,
                    primary: globalTheme.palette.primary, //primary color for the table
                    info: {
                        main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            globalTheme.palette.mode === "light"
                                ? "rgb(234, 239, 241)"
                                : "#000", //pure black table in dark mode for fun
                    },
                },
                typography: {
                    button: {
                        textTransform: "none", //customize typography styles for all buttons in table by default
                        fontSize: "1rem",
                    },
                },
                components: {
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                fontSize: "0.8rem", //larger tooltip font size                                
                            },
                        },
                    },
                },

            }), [globalTheme]);

    const tableHeadCellProps = {
        align: "center",
        sx: {
            fontSize: 16,
            color: "white",
            fontWeight: "normal",
            // paddingTop: 1,
            // paddingBottom: 1,
            backgroundColor: "#2471A3",
            borderColor: "black",
        },
    };

    async function marcarActivo(e) {
        putData(e,
            '/representantes/' + datosformulario.id,
            {
                activo: 1
            },
            setNotify,
            logEventos("Marcar comercial como activo", "Comerciales", "comerciales", localStorage.getItem('user'))
        )
    };

    async function marcarInactivo(e) {
        putData(e,
            '/representantes/' + datosformulario.id,
            {
                activo: 0
            },
            setNotify,
            logEventos("Marcar comercial como inactivo", "Comerciales", "comerciales", localStorage.getItem('user'))
        )
    };

    return (
        <div>
            <Navbar />
            <Container maxWidth="xl" mt={1}>
                {comercial && tabla ? (
                    <Box mt={2} boxShadow={3}>
                        <ThemeProvider theme={tableTheme}>
                            <MaterialReactTable
                                enableColumnResizing
                                columns={columns}
                                data={comercial}
                                initialState={initialState}
                                positionActionsColumn="last"
                                enableRowActions
                                enableExpandAll={false}
                                enableToolbarInternalActions={true}
                                muiTableHeadCellProps={tableHeadCellProps}
                                muiTableBodyCellProps={({ row }) => ({
                                    align: "left",
                                    sx: {
                                        backgroundColor:
                                            row.original.activo === 1 ? "#afdbaf" : "#e39898",
                                        color: "black",
                                        borderColor: "black",
                                    },
                                })}
                                enableColumnActions={false}
                                muiSearchTextFieldProps={muiSearchTextFieldProps}
                                enableDensityToggle={false}
                                renderTopToolbarCustomActions={() => (
                                    <>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<AddBoxTwoToneIcon />}
                                            onClick={handleClickNuevo}
                                        >
                                            Nuevo Registro
                                        </Button>
                                        <Typography variant="poster" fontSize={26}>
                                            <PeopleIcon sx={{ size: 20 }} />
                                            Comerciales
                                        </Typography>
                                    </>
                                )}
                                layoutMode="grid"
                                localization={MRT_Localization_ES}
                                enableColumnDragging={false}
                                enableEditing={false}
                                renderToolbarInternalActions={(
                                    { table }) => (
                                    <>
                                        {!hiddenmuestraactivos ?
                                            <Tooltip title="Ver comerciales activos">
                                                <IconButton color="success" onClick={() => {
                                                    obtenerComerciales();
                                                }}>
                                                    <PersonIcon />
                                                </IconButton>
                                            </Tooltip>
                                            : null}
                                        {!hiddenmuestrainactivo ?
                                            <Tooltip title="Ver comerciales inactivos">
                                                <IconButton color="error"
                                                    onClick={() => {
                                                        obtenerInactivos();
                                                    }}>
                                                    <PersonOffIcon />
                                                </IconButton>
                                            </Tooltip>
                                            : null}
                                        <MRT_FullScreenToggleButton table={table} />
                                    </>
                                )}
                                renderRowActionMenuItems={({ closeMenu, row }) => (
                                    <>
                                        <MenuItem
                                            key={0}
                                            onClick={() => {
                                                mostrareditar(row.original)
                                                closeMenu()
                                            }}>
                                            <ListItemIcon>
                                                <EditIcon color="primary" />
                                            </ListItemIcon> Editar comercial
                                        </MenuItem>
                                        <Divider />
                                        {(row.original.activo === 0) ?
                                            <MenuItem
                                                key={1}
                                                onClick={() => {
                                                    abrirModalConfirmarActivo(row.original)
                                                    closeMenu()
                                                }}>
                                                <ListItemIcon>
                                                    <CheckCircleOutlineIcon color="success" />
                                                </ListItemIcon>
                                                Marcar como activo
                                            </MenuItem>
                                            :
                                            <MenuItem
                                                key={2}
                                                onClick={() => {
                                                    abrirModalConfirmarInactivo(row.original)
                                                    closeMenu()
                                                }}>
                                                <ListItemIcon>
                                                    <BlockIcon color="error" />
                                                </ListItemIcon>
                                                Marcar como inactivo
                                            </MenuItem>
                                        }
                                    </>
                                )}
                            />
                        </ThemeProvider>
                    </Box>
                ) : null}

                {notify && notify.open ? (
                    <Notify
                        mensaje={notify.mensaje}
                        open={notify.open}
                        color={notify.color}
                        handleClose={handleClose}
                        severity={notify.severity}
                    />
                ) : null}

                {confirmarActivo && (
                    <ConfirmDialog
                        title={"Activar comercial"}
                        subtitle={"¿Desea cambiar el estado del comercial como activo?"}
                        open={confirmarActivo}
                        setOpen={setConfirmarActivo}
                        notify={notify}
                        setNotify={setNotify}
                        btnText={"Aprobar"}
                        guardar={marcarActivo}
                        color={"#2ECC71"}
                        icon={iconCheck}
                    // actualizar={obtenerchoferes}
                    />
                )}

                {confirmarInactivo && (
                    <ConfirmDialog
                        title={"Desactivar comercial"}
                        subtitle={"¿Desea cambiar el estado del comercial como inactivo?"}
                        open={confirmarInactivo}
                        setOpen={setConfirmarInactivo}
                        notify={notify}
                        setNotify={setNotify}
                        btnText={"Confirmar"}
                        guardar={marcarInactivo}
                        color={"#f53649"}
                        icon={iconRemove}
                    // actualizar={obtenerinactivos}
                    />
                )}


                {alta ? <Addcomerciales alta={setAlta} tabla={setTabla} ></Addcomerciales> : null}

                {editar ? <Editarcomerciales editar={setEditar} tabla={setTabla} datos={datosformulario}></Editarcomerciales> : null}
            </Container >

            <br />
            <br />
        </div >

    )
}