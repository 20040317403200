//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio
// Creation Date : 04/09/2024
// Version : 1
// Description : Tabla de tanques
//------Changes -------------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useEffect, useState, useMemo } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Container,
  Typography,
  Tooltip,
  IconButton,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  DialogTitle,
  ListItemIcon,
} from "@mui/material";
import Navbar from "../Navbar";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import BlockIcon from "@mui/icons-material/Block";
import EditIcon from "@mui/icons-material/Edit";
import Notify from "../Notify";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { grey } from "@mui/material/colors";
import { logEventos } from "../../services/services";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import NoTransferIcon from "@mui/icons-material/NoTransfer";
import MenuItem from "@mui/material/MenuItem";
import AddTanque from "./AddTanque";
import EditarTanque from "./EditarTanque";
import Prox_fechas_revisiones from "./Prox_fechas_revisiones";
import InfoIcon from "@mui/icons-material/Info";
import Info_tanque from "./Info_tanque";
import AttachFileIcon from "@mui/icons-material/AttachFile"; //ADJUNTAR IMAGEN
import FileUploadTanque from "./FileUploadTanque";
import BuildIcon from "@mui/icons-material/Build";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Agregar_servicio from "./Agregar_servicio";
import Agregar_tarea from "./Agregar_tarea";
import Checklist from "./Checklist";

function Tanque_rehab() {
  const [tanque, setTanque] = useState(); // p/tabla
  const [alta, setAlta] = useState(false); //Estado q mostrará o no la pantalla de alta
  const [tabla, setTabla] = useState(true);
  const [editar, setEditar] = useState(false); //Estado q mostrará o no el form de edicion
  const [info, setInfo] = useState(false);
  const [servicio, setServicio] = useState(false);
  const [tarea, setTarea] = useState(false);
  const [checklist, setChecklist] = useState(false);
  const [confirmar, setConfirmar] = useState(false);
  const [datosTanque, setDatosTanque] = useState({});
  const globalTheme = useTheme();
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
  const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
  const [hiddenmuestrainactivo, setHiddenmuestrainactivo] = useState(false);
  const [hiddenmuestraactivos, setHiddenmuestraactivos] = useState(true);
  const [obt, setObt] = useState(0); // es para actualizar,en el editar actualizar={setObt} y  props.actualizar(1)
  const [newUuid, setNewUuid] = useState(""); //ADJUNTAR IMAGEN
  const [openCloseAdj, setOpenCloseAdj] = useState(false); //ADJUNTAR IMAGEN
    useEffect(() => {
      logEventos("Acceso al menú Tanques Moviles", "Tanques Moviles", "Varias funciones con los tanques", localStorage.getItem("user"))
  }, [])
  //*************************************************************************************************************************************/
  const obtenerActivos = () => {
    setHiddenmuestraactivos(true);
    setHiddenmuestrainactivo(false);
    fetch(UrlApi + "/tanque_rehabilitacion/funciona")
      .then((data) => data.json())
      .then((data) => setTanque(data));
  };
  const obtenerinactivos = () => {
    setHiddenmuestrainactivo(true);
    setHiddenmuestraactivos(false);
    fetch(UrlApi + "/tanque_rehabilitacion/en_reparacion")
      .then((data) => data.json())
      .then((data) => setTanque(data));
  };
  // console.log("TANQUES", tanque);
  //ejecuta la funcion de obtener
  useEffect(() => {
    obtenerActivos();
  }, [tabla, obt, confirmar]);
  //*************************************************************************************************************************************/
  const handleClickNuevo = () => {
    setAlta(true);
  };
  const mostrareditar = (datosDeLaLinea) => {
    setEditar(true);
    setDatosTanque(datosDeLaLinea);
    //console.log("datos linea", datosDeLaLinea ? datosDeLaLinea : "NO");
  };
  const mostrarInfo = (datosDeLaLinea) => {
    setInfo(true);
    setDatosTanque(datosDeLaLinea);
  };
  const mostrarServicio = (datosDeLaLinea) => {
    setServicio(true);
    setDatosTanque(datosDeLaLinea);
  };
  const mostrarTarea = (datosDeLaLinea) => {
    setTarea(true);
    setDatosTanque(datosDeLaLinea);
  };
  const mostrarChecklist = (datosDeLaLinea) => {
    setChecklist(true);
    setDatosTanque(datosDeLaLinea);
  };
  const handleVolver = () => {
    setConfirmar(false);
    setTabla(true);
  };
  const abrirModalConfirmar = (datosDeLaLinea) => {
    setConfirmar(true);
    setDatosTanque(datosDeLaLinea);
  };
  const handleCloseAdd = () => {
    setAlta(false);
    obtenerActivos();
  };
  const handleCloseEditar = () => {
    setEditar(false);
    obtenerActivos();
  };
  const handleCloseInfo = () => {
    setInfo(false);
    obtenerActivos();
  };
  const handleOpenCloseAdj = (value) => {
    setOpenCloseAdj(value);
  };
  const handleCloseServicio = () => {
    setServicio(false);
    obtenerActivos();
  };
  const handleCloseTarea = () => {
    setTarea(false);
    obtenerActivos();
  };
  const handleCloseChecklist = () => {
    setChecklist(false);
    obtenerActivos();
  };
  //*************************************************************************************************************************************/
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)" //background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
            },
          },
        },
      }),
    [globalTheme]
  );
  const columns = useMemo(
    () => [
      {
        header: "Matrícula",
        accessorKey: "matricula",
        size: 100,
      },
      {
        header: "N° Auditoría",
        accessorKey: "num_auditoria",
        size: 100,
      },
      {
        header: "Fabricante",
        accessorKey: "fabricante",
        size: 100,
      },
      {
        header: "Patente",
        accessorKey: "patente",
        size: 100,
      },
      {
        header: "Volumen",
        accessorKey: "volumen",
        size: 100,
      },
    ],
    []
  );
  const initialState = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
  };
  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };
  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "success",
    size: "small",
  };
  //*************************************************************************************************************************************/
  // console.log("datos tanque id", datosTanque.id_tanque);
  const marcarInactivo = async (e) => {
    logEventos(
      "Guardar tanque inactivo",
      "Tanques Moviles",
      "Se guardo tanque inactivo",
      localStorage.getItem("user")
    );
    e.preventDefault();
    const response = await fetch(
      UrlApi + "/tanque_rehabilitacion/" + datosTanque.id_tanque,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(
          datosTanque.estado === 0 ? { estado: 1 } : { estado: 0 }
        ),
      }
    );
    if (response.ok === false) {
      const error = response && response.json();
      setError(error);
      alert("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      try {
        const json = await response.json();
        if (json.message) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje(
            json.message === "OK" ? "Guardado" : "Guardado. Actualizar RTA Api."
          );
          obtenerActivos();
        } else if (json.sqlMessage) {
          json && setError(json);
          alert("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
        } else {
          alert("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje("Guardado");
          obtenerActivos();
        } else {
          alert("Error: " + error);
        }
      }
    }
    handleVolver();
    obtenerActivos();
  };

  return (
    <div>
      <Navbar />
      <Container maxWidth="xl" mt={1} mb={2}>
        {tanque && tabla ? (
          <Box mt={2} boxShadow={3}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
                enableColumnResizing
                columns={columns}
                data={tanque}
                initialState={initialState}
                positionActionsColumn="last"
                enableExpandAll={false}
                enableToolbarInternalActions={true}
                muiTableHeadCellProps={tableHeadCellProps}
                muiTableBodyCellProps={({ row }) => ({
                  align: "left",
                  sx: {
                    backgroundColor:
                      row.original.estado === 0 ? "#efc3c3" : "#b3e6b3",
                    color: "black",
                    borderColor: "black",
                  },
                })}
                enableColumnActions={true}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                enableDensityToggle={false}
                enableGrouping
                renderTopToolbarCustomActions={() => (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddBoxTwoToneIcon />}
                      onClick={handleClickNuevo}
                    >
                      Nuevo tanque
                    </Button>
                    <Typography variant="poster" fontSize={26}>
                      <LocalShippingIcon sx={{ size: 20 }} />
                      Rehabilitación tanques moviles
                    </Typography>
                  </>
                )}
                layoutMode="grid"
                localization={MRT_Localization_ES}
                enableColumnDragging={false} //No permite que se arrastren las columnas.
                // enableEditing
                enableRowActions
                // ACCIONES
                renderDetailPanel={({ row }) => (
                  <div style={{ marginTop: "-50px" }}>
                    <Box
                      sx={{
                        width: "200%",
                      }}
                    >
                      {/*PANTALLA DE LAS PROXIMAS FECHAS */}
                      <Prox_fechas_revisiones datosTanque={row.original} />
                    </Box>
                  </div>
                )}
                renderToolbarInternalActions={(
                  { table } // boton para nuevo , al lado del buscar
                ) => (
                  <>
                    {!hiddenmuestraactivos ? (
                      <Tooltip title="Ver tanques funcionando">
                        <IconButton
                          color="success"
                          onClick={() => {
                            obtenerActivos();
                          }}
                        >
                          <LocalShippingIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    {!hiddenmuestrainactivo ? (
                      <Tooltip title="Ver tanques en reparación">
                        <IconButton
                          color="error"
                          onClick={() => {
                            obtenerinactivos();
                          }}
                        >
                          <NoTransferIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    <MRT_FullScreenToggleButton table={table} />
                  </>
                )}
                renderRowActionMenuItems={(
                  { closeMenu, row } //las acciones del costado editar y ver
                ) => (
                  <>
                    <MenuItem
                      onClick={() => {
                        mostrareditar(row.original);
                        closeMenu();
                      }}
                    >
                      <ListItemIcon>
                        <EditIcon color="primary" />
                      </ListItemIcon>
                      Editar
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        abrirModalConfirmar(row.original);
                        closeMenu();
                      }}
                    >
                      <ListItemIcon>
                        {row.original.estado === 0 ? (
                          <Tooltip title="Marcar como funcionando">
                            <IconButton color="success">
                              <CheckCircleOutlineIcon />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Marcar como en reparación">
                            <IconButton color="error">
                              <BlockIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </ListItemIcon>
                      {row.original.estado === 0 ? "Funciona" : "En reparación"}
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        mostrarInfo(row.original);
                        closeMenu();
                      }}
                    >
                      <ListItemIcon>
                        <InfoIcon color="primary" />
                      </ListItemIcon>
                      Información
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setNewUuid(row.original.uuid);
                        setOpenCloseAdj(!openCloseAdj);
                        closeMenu();
                      }}
                    >
                      <ListItemIcon>
                        <AttachFileIcon color="secondary" />
                      </ListItemIcon>
                      Ver adjunto
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        mostrarServicio(row.original);
                        closeMenu();
                      }}
                    >
                      <ListItemIcon>
                        <BuildIcon color="inherit" />
                      </ListItemIcon>
                      Agregar servicio
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        mostrarTarea(row.original);
                        closeMenu();
                      }}
                    >
                      <ListItemIcon>
                        <ContentPasteIcon color="warning" />
                      </ListItemIcon>
                      Agregar tarea
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        mostrarChecklist(row.original);
                        closeMenu();
                      }}
                    >
                      <ListItemIcon>
                        <CheckBoxIcon color="success" />
                      </ListItemIcon>
                      Checklist
                    </MenuItem>
                  </>
                )}
              />
            </ThemeProvider>
            <br />
            <br />
          </Box>
        ) : null}

        {confirmar ? (
          <div>
            <Dialog
              open={confirmar}
              onClose={handleVolver}
              maxWidth="xs"
              fullWidth
            >
              <Box mt={1} backgroundColor={"#1F618D"}>
                <DialogTitle>
                  {datosTanque.estado === 0 ? (
                    <Typography variant="h6" align="center" color="#ffffff">
                      Tanque en reparación : {datosTanque.matricula}
                    </Typography>
                  ) : (
                    <Typography variant="h6" align="center" color="#ffffff">
                      Tanque funcional : {datosTanque.matricula}
                    </Typography>
                  )}
                </DialogTitle>
              </Box>
              <form onSubmit={marcarInactivo}>
                <Box
                  border={0.5}
                  borderColor={grey[300]}
                  backgroundColor="#F4F6F6"
                  p={2}
                  py={2}
                  mt={1}
                >
                  <DialogContent>
                    <DialogContentText textAlign={"center"}>
                      {datosTanque.estado === 0 ? (
                        <>
                          {" "}
                          <b>
                            ¿Desea marcar el tanque en estado "funcionando"?
                          </b>{" "}
                        </>
                      ) : (
                        <>
                          {" "}
                          <b>
                            ¿Desea marcar el tanque en estado "en reparación"?
                          </b>{" "}
                        </>
                      )}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItemns="center"
                    >
                      <Button
                        variant="contained"
                        onClick={handleVolver}
                        style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
                      >
                        Volver
                      </Button>
                      <Button
                        style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Confirmar
                      </Button>
                    </Grid>
                  </DialogActions>
                </Box>
              </form>
            </Dialog>
          </div>
        ) : null}
        {notificacion ? (
          <Notify
            mensaje={mensaje}
            open={openSnack}
            color={"#D4EFDF"}
            severity={"success"}
            handleClose={handleVolver}
          />
        ) : null}
        {alta ? (
          <AddTanque
            alta={alta}
            tabla={setTabla}
            onClose={handleCloseAdd}
          ></AddTanque>
        ) : null}
        {editar ? (
          <EditarTanque
            editar={editar}
            tabla={setTabla}
            datos={datosTanque}
            onClose={handleCloseEditar}
          ></EditarTanque>
        ) : null}
        {info ? (
          <Info_tanque
            info={info}
            tabla={setTabla}
            datos={datosTanque}
            onClose={handleCloseInfo}
          ></Info_tanque>
        ) : null}
        {servicio ? (
          <Agregar_servicio
            servicio={servicio}
            tabla={setTabla}
            datos={datosTanque}
            onClose={handleCloseServicio}
          ></Agregar_servicio>
        ) : null}
        {tarea ? (
          <Agregar_tarea
            tarea={tarea}
            tabla={setTabla}
            datos={datosTanque}
            onClose={handleCloseTarea}
          ></Agregar_tarea>
        ) : null}
        {checklist ? (
          <Checklist
            checklist={checklist}
            tabla={setTabla}
            datos={datosTanque}
            onClose={handleCloseChecklist}
          ></Checklist>
        ) : null}
        {openCloseAdj ? ( // ADJUNTAR IMAGEN
          <FileUploadTanque
            fileId={newUuid}
            openclose={true}
            mostrar={true}
            onClose={() => handleOpenCloseAdj(false)} // Proporciona una función de cierre
          />
        ) : null}
      </Container>
    </div>
  );
}

export default Tanque_rehab;
