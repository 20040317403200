import React, { useEffect, useState, useMemo } from "react";
import Navbar from "../../Navbar";
import { UrlApi } from "../../../services/apirest";
import {
    Container,
    Typography,
    ListItemIcon,
    Button,
    Tooltip,
    MenuItem,
} from "@mui/material";
import Box from "@mui/material/Box";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Divider from "@mui/material/Divider";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import MaterialReactTable from "material-react-table";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import AssignmentIcon from '@mui/icons-material/Assignment';
import Fichacomprobante from "./Fichacomprobante";
import moment from 'moment';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DeleteDialog from "../../Dialogs/DeleteDialog";
import { logEventos } from "../../../services/services";
import Addcomprobantes2 from "./altacomprobantes/Addcomprobantes2";

export default function Comprobantes() {
    const [comprobantes, setComprobantes] = useState();
    const [tabla, setTabla] = useState(true);
    const [alta, setAlta] = useState(false);
    const [ficha, setFicha] = useState(false);
    const [anular, setAnular] = useState(false);
    const globalTheme = useTheme();
    const [datosComprobantes, setDatosComprobantes] = useState({});
    const [notify, setNotify] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
    });

    const obtenerComprobantes = () => {
        fetch(UrlApi + "/comprobantes_cabecera")
            .then((data) => data.json())
            .then((data) => setComprobantes(data));
    };

    useEffect(() => {
        obtenerComprobantes();
    }, [tabla]);

    const handleClickNuevo = () => {
        setAlta(true);
        setTabla(false);
    };

    const columns = useMemo(
        () => [
            { header: "Tipo", accessorKey: "tipo_comprobante", size: 100 },
            { header: "Núm.", accessorKey: "nro_comprobante", size: 85 },
            // { header: "CUIT", accessorKey: "nro_cuit", size: 85 },
            { header: "Proveedor", accessorKey: "razon_social", size: 200 },
            // { header: "Orden", accessorKey: "id_oc", size: 85 },
            // { header: "Domicilio", accessorKey: "direccion",size:150},
            // { header: "Tel", accessorKey: "telefono",size:100},
            { header: "Sucursal", accessorKey: "nombre", size: 150 },
            // { header: "vehiculo", accessorKey: "vehiculo",size:100},
            {
                header: "Fecha",
                accessorFn: (c) => moment(c.fecha_factura).format("DD-MM-YYYY"),
                size: 80,
            },
            // { header: "IVA (Proveedor)", accessorKey: "descripcion_iva", size: 143 },
            // { header: "Neto G.", accessorKey: "neto_gravado", size: 89 },
            // { header: "Sub.", accessorKey: "subtotal", size: 71 },
            {
                header: "Total",
                accessorFn: (c) => c.total === null ? "No corresponde" : parseFloat(c.total).toFixed(2),
                size: 75
            },
            { header: "Estado", accessorKey: "estado", size: 87 },
            // { header: "Condición venta", accessorKey: "condicion_pago",size:200 },
            // { header: "Detalles", accessorKey: "referencia" },
            // { header: "Núm. de CAE", accessorKey: "numero_cae",size:150 },
            // {
            //   header: "Vto.CAE",
            //   accessorKey: "vencimiento_cae",
            //   type: "date",
            //   format: "DD-MM-YYYY",
            //   size:100,
            // },
            {
                header: "Vencimiento",
                accessorFn: (c) =>c.vto_factura === null ? "No corresponde" :
                    moment(c.vto_factura).format("DD-MM-YYYY"),
                size: 100,
            },
            // {
            //   header: "Contable",
            //   accessorKey: "fecha_contable",
            //   type: "date",
            //   format: "DD-MM-YYYY",
            //   size:100,
            // },
            // { header: "Divisa", accessorKey: "tipo_moneda",size:90 },
            // { header: "Per.Ing.B.", accessorKey: "per_ing_brutos",size:100  },
            // { header: "No G.", accessorKey: "imp_VsyNoGrav",size:90  },
            // { header: "Exento", accessorKey: "exento",size:90 },
            // { header: "Descuentos", accessorKey: "descuentos",size:110 },
            // { header: "Saldo no aplicado", accessorKey: "saldo_no_aplicado",size:150 },
            // { header: "Otros tributos", accessorKey: "otros_atributos",size:130 },
        ],
        []
    );

    const muiSearchTextFieldProps = {
        placeholder: "Buscar..",
        sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
        variant: "outlined",
        color: "primary",
        size: "small",
    };

    const initialState = {
        density: "compact",
        showGlobalFilter: true,
        pagination: {
            pageSize: 15,
            pageIndex: 0,
        },
    };

    const tableHeadCellProps = {
        align: "center",
        sx: {
            fontSize: 16,
            color: "white",
            fontWeight: "normal",
            backgroundColor: "#2471A3",
            borderColor: "black",
        },
    };

    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: globalTheme.palette.mode,
                    primary: globalTheme.palette.primary, //primary color for the table
                    info: {
                        main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            globalTheme.palette.mode === "light"
                                ? "rgb(234, 239, 241)"
                                : "#000", //pure black table in dark mode for fun
                    },
                },
                typography: {
                    button: {
                        textTransform: "none", //customize typography styles for all buttons in table by default
                        fontSize: "1rem",
                    },
                },
                components: {
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                fontSize: "0.8rem",
                            },
                        },
                    },
                },
            }),
        [globalTheme]
    );

    const handleAnular = async (e) => {
        try {
            e.preventDefault();
        let response = await fetch(UrlApi + '/comprobantes_cabecera/' + datosComprobantes.idcomprobante, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' }
        });
        // let response2 = await fetch(UrlApi + '/comprobantes_cuerpo/xcomprobante/'+ datosComprobantes.uuid_comprobante,{
        //     method:'DELETE',
        //     headers:{'Content-Type':'application/json'}
        // });
        // let response3 = await fetch(UrlApi + '/comprobantes_pie/xcomprobante/'+ datosComprobantes.uuid_comprobante,{
        //     method:'DELETE',
        //     headers:{'Content-Type':'application/json'}
        // })
        }catch (error) {
                console.error('Error al guardar los datos:', error);
                alert('Hubo un error al guardar los datos');
        }
        setAnular(false)
        setTabla(true)
        obtenerComprobantes()        
        logEventos("Anular comprobantes", "Ingreso facturas", "Compras", localStorage.getItem("user"))
    };

    return (
        <div>
            <Navbar />
            {tabla && comprobantes ? (
                <Container maxWidth="xl">
                    <Box mt={2} boxShadow={3}>
                        <ThemeProvider theme={tableTheme}>
                            <MaterialReactTable
                                enableColumnResizing
                                columns={columns}
                                data={comprobantes}
                                initialState={initialState}
                                positionActionsColumn="last"
                                enableExpandAll={false}
                                enableToolbarInternalActions={true}
                                muiTableHeadCellProps={tableHeadCellProps}
                                localization={MRT_Localization_ES}
                                muiTableBodyCellProps={{
                                    align: "left",
                                    sx: {
                                        backgroundColor: '#FFFFFF',
                                        color: "black",
                                        borderColor: "black",
                                    }
                                }}
                                enableColumnActions={false}
                                muiSearchTextFieldProps={muiSearchTextFieldProps}
                                enableDensityToggle={false}
                                renderTopToolbarCustomActions={() => (
                                    <>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<AddBoxTwoToneIcon color="asd" />}
                                            onClick={handleClickNuevo}
                                        >
                                            Nuevo Comprobante
                                        </Button>
                                        <Typography variant="poster" fontSize={26}>
                                            <AssignmentIcon sx={{ fontSize: 20 }} />
                                            Comprobantes
                                        </Typography>
                                    </>
                                )}
                                layoutMode='grid'
                                enableRowActions
                                enableColumnDragging={false}
                                renderRowActionMenuItems={({ closeMenu, row, index }) => [
                                    <MenuItem
                                        key={0}
                                        onClick={() => {
                                            closeMenu();
                                            setDatosComprobantes(row.original);
                                            setAnular(true);
                                            setTabla(false);
                                        }}
                                        sx={{ m: 0 }}
                                    >
                                        <ListItemIcon>
                                            <HighlightOffIcon color="error" />
                                        </ListItemIcon>
                                        Anular
                                    </MenuItem>,
                                    <Divider />,
                                    <MenuItem
                                        style={{
                                            display: row.original.activo === 0 ? "none" : null,
                                        }}
                                        disabled={row.original.activo === 0}
                                        key={1}
                                        onClick={() => {
                                            closeMenu();
                                            setFicha(true);
                                            setTabla(false);
                                            setDatosComprobantes(row.original);
                                        }}
                                        sx={{ m: 0 }}
                                    >
                                        <ListItemIcon>
                                            <RemoveRedEyeIcon color="success" />
                                        </ListItemIcon>
                                        Ver ficha comprobante
                                    </MenuItem>
                                ]}
                                enableColumnFilters={false}
                                enableHiding={false}
                                positionExpandColumn='first'
                            />

                        </ThemeProvider>
                    </Box>
                </Container>
            )
                : null}
                {alta ? <Addcomprobantes2 alta={setAlta} tabla={setTabla}  datos={datosComprobantes} /> : null} 
            {/* {alta ? <Addcomprobantes alta={setAlta} tabla={setTabla}  datos={datosComprobantes} /> : null} */}
            {ficha ? <Fichacomprobante ficha={setFicha} tabla={setTabla} datos={datosComprobantes}/> : null}
            {anular ? 

            <DeleteDialog 
            title={"Anular comprobante"}
            confirmText={"¿Confirma que desea anular el comprobante?"}
            btnText={"Confirmar"}
            notify={notify}
            setNotify={setNotify}
            open={anular}
            setOpen={setAnular}
            guardar={handleAnular}            
            />: null}
        </div>
    )
}

//========================================================================
/*----- Component --------------------------------------------------------/
// Developer     : Candela Grosso   
// Creation Date : 29/5/2024
// Version       : 1.0  
// Description   : Componente padre de comprobantes. DEsde la tabla se puede dirigir hacia el alta, visualización de ficha y anuluar.
//------Changes --------------------------------------------------------------------- -------------//
// Change Date   :
// Change by     :
// Change description: 
 ----> datosFormulario.fecha_entrega es una instancia válida de Date antes de intentar formatearla con moment. Si no es una fecha válida, se establece el valor del TextField como una cadena vacía (''). Esto debería ayudar a evitar el mensaje de error "Invalid date"
//========================================================================*/