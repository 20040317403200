import axios from "axios";
import React from "react";
import { UrlApi } from "../../../services/apirest";

const useFetchFacturas = () => {
  const [facturas, setfacturas] = React.useState([]);
  const [isLoading, setisLoading] = React.useState();
  const [error, seterror] = React.useState();
  const getFacturas = React.useCallback(() => {
    try {
      setisLoading(true);
      axios.get(UrlApi + "/facturas").then((response) => {
        setfacturas(response.data);
      });
    } catch (error) {
      seterror(error);
    } finally {
      setisLoading(false);
    }
  }, []);
  React.useEffect(() => {
    getFacturas();
  }, [getFacturas]);
  return { facturas, isLoading, error, refetch: getFacturas };
};

export default useFetchFacturas;
