import React from 'react'
import Navbar from '../Navbar';
import {Container} from '@mui/material'


function PanelUser() {
    return (
        <div>
            <Navbar />
            <Container>
                <br />
                Panel de usuario
            </Container>

        </div>
    )
}

export default PanelUser
