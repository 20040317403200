import React, { useState, useEffect, useMemo } from "react";
import AppBar from "@mui/material/AppBar";
import Navbar from "../Navbar";
import { UrlApi } from "../../services/apirest";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Stack,
  Grid,
  Tooltip,
  IconButton,
  TextField,
  FormControlLabel,
  Toolbar,
} from "@mui/material";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
//Notification
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { logEventos } from "../../services/services";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import EditIcon from "@mui/icons-material/Edit";
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import CloseIcon from "@mui/icons-material/Close";
import AddModelo from "./add/AddModelo";
import EditarModelo from "./edit/EditarModelo";


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

function Modelos(props) {
    const [update, setUpdate] = useState(false);
    const [tabla, setTabla] = useState(true);
    const [marca, setMarca] = useState(props.marca)
    const [alta, setAlta] = useState(false)
    const [editar, setEditar] = useState(false)
    const [modelo, setModelo] = useState()
  
    useEffect(() => {
      logEventos("Avista de marcas y modelos de vehiculos", "Vehiculos", "Ver las Marcas y modelos de vehiculos registrados", localStorage.getItem("user"))
  }, [])
  
    //funcion para obtener STOCK POR SUCURSAL
    const [registro, setRegistro] = useState();
  
    const obtenerRegistro = () => {
      fetch(UrlApi + "/modelos/xmarca/"+ props.marca.id) //cambiar fetch
        .then((data) => data.json())
        .then((data) => setRegistro(data));
    };
  
    //funcion que espera los cambios de la funcion de obtener familias
    useEffect(() => {
        obtenerRegistro();
    }, [tabla]);
  
    //funcion de actualizar la tabla
    useEffect(() => {
      if (update) obtenerRegistro();
    }, [update]);

    const handleClickNuevo = () => {
      setAlta(true);
      setTabla(false);
    };

    const handleClickEditar = (row) => {
      setModelo(row)
      setEditar(true);
      setTabla(false);
    };

    const columns = useMemo(
        () => [
            {
              header: "ID",
              accessorKey: "id",
              size: 150,
            },
          { header: "Modelo", accessorKey: "descripcion", size: 150 },
        ],
        []
      );

    // -------------------------------------------------------------------- //
  
    const muiSearchTextFieldProps = {
        placeholder: "Buscar..",
        sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
        variant: "outlined",
        color: "primary",
        size: "small",
      };
      const tableHeadCellProps = {
        align: "left",
        sx: {
          fontSize: 16,
          color: "white",
          fontWeight: "normal",
          backgroundColor: "#2471A3",
          borderColor: "black",
        },
      };
      //*************************************************************************************************************************************/
    
      const globalTheme = useTheme();
      const tableTheme = useMemo(
        () =>
          createTheme({
            palette: {
              mode: globalTheme.palette.mode,
              primary: globalTheme.palette.primary, //primary color for the table
              info: {
                main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
              },
              background: {
                default:
                  globalTheme.palette.mode === "light"
                    ? "rgb(234, 239, 241)"
                    : "#000", //pure black table in dark mode for fun
              },
            },
            typography: {
              button: {
                textTransform: "none", //customize typography styles for all buttons in table by default
                fontSize: "1rem",
              },
            },
            components: {
              MuiTooltip: {
                styleOverrides: {
                  tooltip: {
                    fontSize: "0.8rem",
                  },
                },
              },
            },
          }),
        [globalTheme]
      );
    
      const initialState = {
        //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
        density: "compact",
        showGlobalFilter: true,
        pagination: { pageIndex: 0, pageSize: 10 },
      };

      const handleClose = () => {
        props.modelos(false);
        props.tabla(true);
      };

  return (
    <div>
        <AppBar
        sx={{ position: "relative" }}
        style={{ backgroundColor: "#0979b0" }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => handleClose()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Modelos de la Marca {props.marca.descripcion}
          </Typography>
        </Toolbar>
      </AppBar>
        <Container maxWidth="xl" mt={1}>
          {registro && tabla ? (
            <Box mt={2} mb={4}>
              <ThemeProvider theme={tableTheme}>
                <MaterialReactTable
                  enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
                  enableGrouping // para permitir agrupar x estado x ej
                  layoutMode="grid"
                  muiTableHeadCellProps={tableHeadCellProps}
                  muiTableBodyCellProps={({ row }) => ({
                    align: "left",
                    sx: {
                      color: "black",
                      borderColor: "black",
                    },
                  })}
                  enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
                  enableColumnFilters={false} //desactiva filtros x  columna
                  enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
                  enableDensityToggle={false} //
                  localization={MRT_Localization_ES} //idioma
                  enableColumnDragging={false} //para arrastrar columnas
                  initialState={initialState}
                  muiSearchTextFieldProps={muiSearchTextFieldProps}
                  columns={columns}
                  data={registro} //con lo q la completas (esto cambia siempre)
                  enableStickyFooter
                  enableEditing
                  positionActionsColumn="last" // posicion de la columna de acciones al final
                  // ACCIONES
                  renderToolbarInternalActions={(
                    { table } // boton para nuevo , al lado del buscar
                  ) => (
                    <>
                     {/* <Grid item xs={6} ml={2}>
                      <Button
                        type="primary"
                        variant="contained"
                        style={{ backgroundColor: blueGrey[700], color: "white" }}
                        onClick={handleExportData}
                        size="small"
                        endIcon={<FileDownloadIcon />}
                      >
                        Exportar a CSV
                      </Button>
                     </Grid> */}
                      <MRT_FullScreenToggleButton table={table} />
                    </>
                  )}
                  renderTopToolbarCustomActions={() => (
                    <>
                      <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddBoxTwoToneIcon />}
                      onClick={handleClickNuevo}
                    >
                      Nuevo Modelo
                    </Button>
                    </>
                  )}
                  renderRowActions={(
                    { row } //las acciones del costado editar y ver
                  ) => (
                    <div>
                      <Tooltip arrow placement="left" title="Editar Modelo">
                        <IconButton
                          onClick={() => handleClickEditar(row.original)}
                          color="primary"
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      {/* <Tooltip arrow placement="right" title="Modelos">
                        <IconButton
                        //   onClick={() => mostrareditar(row.original)}
                          color="primary"
                        >
                          <DirectionsCarIcon />
                        </IconButton>
                      </Tooltip> */}
                      
                    </div>
                  )}
                />
              </ThemeProvider>
            </Box>
          ) : null}
        </Container>
        {alta ? (
          <AddModelo
            marca={marca}
            alta={setAlta}
            tabla={setTabla}
          />
        ) : null}
        {editar ? (
          <EditarModelo
            editar={setEditar}
            tabla={setTabla}
            modelo={modelo}
          />
        ) : null}
      </div>
  )
}

export default Modelos