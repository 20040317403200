import React, { useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";

function FormArtefactos(props) {
  const variant = "standard";
  const readOnly = props.readOnly;
  const addRef = useRef();

  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        px={1}
      >
        <Grid
          container
          direction="row"
          backgroundColor="#E5E8E8"
          borderRadius={5}
          my={1}
          p={0.5}
          border={0.5}
          borderColor={"#AEB6BF"}
          alignItems="center"
        >
          <Grid item xs={2} textAlign="center">
            <Typography variant="body2" color="initial">
              Artefacto
            </Typography>
          </Grid>
          <Grid item xs={1.5} textAlign="center">
            <Typography variant="body2" color="initial">
              Cantidad
            </Typography>
          </Grid>
          <Grid item xs={1.5} textAlign="center">
            <Typography variant="body2" color="initial">
              Kcal/h
            </Typography>
          </Grid>
          <Grid item xs={1.5} textAlign="center">
            <Typography variant="body2" color="initial">
              Hrs/Dia
            </Typography>
          </Grid>
          <Grid item xs={1.5} textAlign="center">
            <Typography variant="body2" color="initial">
              Dias/Año
            </Typography>
          </Grid>
          <Grid item xs={1.5} textAlign="center">
            <Typography variant="body2" color="initial">
              Kcals Anuales
            </Typography>
          </Grid>
          <Grid item xs={1.5} textAlign="center">
            <Typography variant="body2" color="initial">
              Kgrs Anuales
            </Typography>
          </Grid>
          {/* <Grid item xs={0.5} textAlign="center">
            <IconButton
              size="small"
              style={{ padding: 0 }}
              onClick={() => props.almacenarArtefactos()}
              disabled={props.listaArtefactos.length >= 6 || props.edicion}
            >
              <AddCircleOutlineIcon sx={{ fontSize: 24 }} />
            </IconButton>
          </Grid> */}
        </Grid>

        {props.listaArtefactos &&
          props.listaArtefactos.map((field, index) => (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-end"
              mb={1}
              key={index} 
              spacing={1}
            >
              <Grid item xs={2}>
                <TextField
                  autoFocus={props.listaArtefactos.length > 1}
                  autoComplete="off"
                  fullWidth
                  label="Artefacto"
                  variant={variant}
                  size="small"
                  name="artefacto"
                  value={
                    field.artefacto &&
                    field.artefacto.replace(/\b\w/g, (l) => l.toUpperCase())
                  }
                  onChange={(e) => props.handleInputChange(index, e)}
                  inputProps={{ maxLength: 100, readOnly: readOnly }}
                />
              </Grid>
              <Grid item xs={1.5}>
                <TextField
                  fullWidth
                  label="Cantidad"
                  size="small"
                  name="cantidad"
                  value={field.cantidad}
                  onChange={(e) => props.handleInputChange(index, e)}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{ maxLength: 4, readOnly: readOnly }}
                />
              </Grid>
              <Grid item xs={1.5}>
                <TextField
                  fullWidth
                  label="Kcal/H"
                  size="small"
                  name="kcalh"
                  onChange={(e) => props.handleInputChange(index, e)}
                  value={field.kcalh}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{ maxLength: 10, readOnly: readOnly }}
                />
              </Grid>
              <Grid item xs={1.5}>
                <TextField
                  fullWidth
                  label="Hrs/Dia"
                  size="small"
                  name="horasdia"
                  value={field.horasdia}
                  onChange={(e) => {
                    props.handleInputChange(index, e);
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{ maxLength: 2, readOnly: readOnly }}
                  error={
                    props.validacionDiasHs &&
                    props.validacionDiasHs.errorHs[index]
                  }
                />
              </Grid>
              <Grid item xs={1.5}>
                <TextField
                  fullWidth
                  label="Dias/Año"
                  size="small"
                  name="diasanio"
                  value={field.diasanio}
                  onChange={(e) => {
                    props.handleInputChange(index, e);
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{ maxLength: 3, readOnly: readOnly }}
                  error={
                    props.validacionDiasHs &&
                    props.validacionDiasHs.errorDias[index]
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Tab") {
                      e.preventDefault(); // Evitar el comportamiento por defecto de la tecla Enter
                      addRef.current.focus(); // Enfocar en el botón
                    }
                  }}
                  // onKeyDown={(e) => {
                  //   if (e.key !== 'Tab') {
                  //     e.stopPropagation(); // Detiene la propagación solo si la tecla no es Tab
                  //   }
                  //   if (e.key === 'Enter') {
                  //     addRef.current.focus(); // Enfoca en el botón solo si se presiona Enter
                  //   }
                  // }}
                  // onKeyDown={(e)=>{
                  //   e.preventDefault()
                  //   addRef.current.focus();
                  // }}
                />
              </Grid>
              <Grid item xs={1.5}>
                <TextField
                  focused
                  fullWidth
                  label="Kcals Anual"
                  size="small"
                  name="kcalsanual"
                  value={field.kcalsanual}
                  onChange={(e) => props.handleInputChange(index, e)}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{ maxLength: 10, readOnly: readOnly }}
                />
              </Grid>
              <Grid item xs={1.5}>
                <TextField
                  focused
                  fullWidth
                  label="Consumo Kgrs Anual"
                  size="small"
                  name="consumoanual"
                  value={field.consumoanual}
                  onChange={(e) => props.handleInputChange(index, e)}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{ maxLength: 10, readOnly: readOnly }}
                />
              </Grid>
              <Grid item xs={0.5}>
                {index === props.listaArtefactos.length - 1 && (
                  <IconButton
                    ref={addRef}
                    color="success"
                    size="medium"
                    onClick={() => props.almacenarArtefactos()}
                    disabled={
                      props.edicion || props.listaArtefactos.length >= 7
                    }
                    onKeyDown={(e) => {
                      if (
                        props.listaArtefactos.length === 1 &&
                        e.key === "Tab"
                      ) {
                        props.setActiveTab("tanques");
                      }
                    }}
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                )}
              </Grid>
              {/* {props.listaArtefactos.length < 1 ? ( */}
              <Grid item xs={0.5}>
                <IconButton
                  disabled={props.listaArtefactos.length === 1 || props.edicion}
                  color="error"
                  size="medium"
                  onClick={() => props.deshacerAlmacenar(index)}
                  onKeyDown={(e) => {
                    if (e.key === "Tab") {
                      props.setActiveTab("tanques");
                    }
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
              {/* ) : null} */}
            </Grid>
          ))}
      </Grid>
    </div>
  );
}

export default FormArtefactos;
