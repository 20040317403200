//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio
// Creation Date : 30/09/2024
// Version : 1
// Description : Pantalla principal de estadisticas de reclamos mobile
//------Changes ----------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React from "react";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@mui/material";
import Tabla_motivos_mobile from "./Tabla_motivos_mobile";
import Grafico_motivos_mobile from "./Grafico_motivos_mobile";
import Tabla_estados_mobile from "./Tabla_estados_mobile";
import Grafico_estados_mobile from "./Grafico_estados_mobile";
import Tabla_instaladores_mobile from "./Tabla_instaladores_mobile";
import Navbar from "../../../Navbar";


function Estadisticas_reclamos_mobile() {
  return (
    <div>
      <Navbar />
      <Box
        bgcolor="#2B76A6"
        color="white"
        marginBottom="1em"
        borderRadius="0.5em"
        marginTop={1}
        width="90%" // Ajusta esto según el ancho deseado
        mx="auto" // Centrar horizontalmente
      >
        <Typography variant="h6" component="div" sx={{ marginLeft: "1em" }}>
          Panel de estadísticas de reclamos
        </Typography>
      </Box>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Tabla_motivos_mobile />
        </Grid>
        <Grid item xs={12}>
          <Grafico_motivos_mobile />
        </Grid>
        <Grid item xs={12}>
          <Tabla_estados_mobile />
        </Grid>
        <Grid item xs={12}>
          <Grafico_estados_mobile />
        </Grid>
        <Grid item xs={12}>
          <Tabla_instaladores_mobile />
        </Grid>
      </Grid>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
}

export default Estadisticas_reclamos_mobile;
