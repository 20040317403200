import React, { useRef } from "react";
import { useEffect, useState } from "react";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Autocomplete,
  createFilterOptions,
  Tooltip,
  IconButton,
  styled,
  FormHelperText,
  Grid,
} from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Notify from "../../Notify";
import {
  Delete,
  AddCircleOutline,
  DisabledByDefaultRounded,
} from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import { getData, postData, putData } from "../../../services/library";
import { UrlApi } from "../../../services/apirest";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { useStaticPicker } from "@mui/x-date-pickers/internals";
import { enviarmails } from "../../../services/services";

function Asignar_equipo(props) {
  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };
  const [notificacion, setNotificacion] = useState({
    open: false,
    mensaje: "",
    severity: "",
    color: "",
  });
  const [uuid, setUuid] = useState(uuidv4());
  const [datos, setDatos] = useState({
    empleado: null,
    desc_trabajo: "",
    elementos_trabajo: "",
    fecha: moment(new Date()).format("YYYY-MM-DD"),
    uuid_ficha: uuid,
  });
  const [artSuc, setArtSuc] = useState(props.artSuc);
  const [articuloSeleccionado, setArticuloSeleccionado] = useState("");
  const [articulos, setArticulos] = useState();
  const [empleados, setEmpleados] = useState([]);
  const [empleadoSeleccionado, setEmpleadoSeleccionado] = useState("");
  const [datosRenglon, setDatosRenglon] = useState([
    {
      uuid_ficha: uuid,
      id_articulo: null,
      id_almacen: null,
      cantidad: 1,
      fecha: moment(new Date()).format("YYYY-MM-DD"),
    },
  ]);
  const [almacenes, setAlmacenes] = useState(props.almacenes);
  const [almacenSeleccionado, setAlmacenSeleccionado] = useState({});
  const [cantidadesActualizadas, setCantidadesActualizadas] = useState(
    datosRenglon.map((linea) => linea.cantidad)
  );
  const [usuarioCompras, setUsuarioCompras] = useState([]);
  const [reqExiste, setReqExiste] = useState([]);
  const [empleadosSeleccionados, setEmpleadosSeleccionados] = useState([]);
  const [envioRequerimiento, setEnvioRequerimiento] = useState({
    id_movil: null,
    id_sucursal: props.sucursal,
    fecha_entrega: moment(datos.fecha).format("YYYY-MM-DD HH:mm:ss"),
    usuario_registra: localStorage.getItem("user"),
    usuario_autoriza: null,
    estado: 50, //cuando se carga el pedido se pone en estado "A autorizar"
    para_comprar: 1,
    observaciones: "",
    uuid_requerimiento: uuid,
    comentarios_internos: "Pedido Automático - Definir Cantidad",
    para_compras: 0,
    para_rrhh: 0,
    autorizado_jefe_compras: 0,
  });
  const [datosLista, setDatosLista] = useState([
    {
      material: null,
      usuario: localStorage.getItem("user"),
      cantidad: 1,
      // id_unidad_medida: datosArt.id_unidad_medida,
      uuid_requerimiento: envioRequerimiento.uuid_requerimiento,
      id_articulo: null,
    },
  ]);
  const [reqExistente, setReqExistente] = useState({});
  const [hayError, setHayError] = useState(false); // Para habilitar/deshabilitar el botón de guardar
  const [erroresStock, setErroresStock] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filtrando, setFiltrando] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const obtenerUsuarioCompras = () => {
    fetch(UrlApi + "/userdata/sector/4/")
      .then((data) => data.json())
      .then((data) => setUsuarioCompras(data));
  };

  useEffect(() => {
    obtenerUsuarioCompras();
  }, []);

  // envio de mail
  // console.log(artSuc);

  // const envioMail = async (renglon) => {
  //   // Buscar el artículo y almacén correspondiente en artsuc
  //   const artSucMatch = artSuc.find(
  //     (item) =>
  //       item.id_articulo === renglon.id_articulo &&
  //       item.id_almacen === renglon.id_almacen
  //   );

  //   if (!artSucMatch) return; // Si no se encuentra, no enviar correo

  //   // Extraer valores de alerta mínima y valor mínimo del artículo
  //   const { alerta_minima, valor_minimo, articulo, almacen, enviamail } = artSucMatch;

  //   for (let usuario of usuarioCompras) {
  //     if (usuario.email) {
  //       if (
  //         renglon.cantidadSalida < alerta_minima &&
  //         renglon.cantidadSalida > valor_minimo
  //       ) {
  //         if (enviamail === 1) {
  //           enviarmails(
  //             usuario.email,
  //             "Advertencia de Nivel de Stock",
  //             `<p>El artículo <b>${articulo}</b> del almacén <b>${almacen}</b> está por debajo del punto de pedido.<br/></p>`
  //           );
  //         }
  //       } else if (renglon.cantidadSalida < valor_minimo) {
  //         if (enviamail === 1) {
  //         enviarmails(
  //           usuario.email,
  //           "Alerta de Stock por Debajo del Nivel Mínimo",
  //           `<p>El artículo <b>${articulo}</b> del almacén <b>${almacen}</b> está por debajo del stock mínimo.<br/></p>`
  //         );
  //       }
  //       }
  //     }
  //   }
  // };

  // Agregar linea de articulos
  const agregarLinea = () => {
    const nuevaLinea = {
      uuid_ficha: uuid,
      id_articulo: null,
      id_almacen: null,
      cantidad: 1,
      fecha: moment(new Date()).format("YYYY-MM-DD"),
    };
    setDatosRenglon((prevLineas) => [...prevLineas, nuevaLinea]);
    verificarStock();
  };

  //********************************* REQUERIMIENTOS *****************************//
  const verificarRequerimientosExistentes = async () => {
    let reqExistentes = {};

    for (let renglon of datosRenglon) {
      if (renglon.id_articulo) {
        try {
          const response = await fetch(
            `${UrlApi}/requerimientos_materiales/reqexiste/${props.sucursal}/${renglon.id_articulo}`
          );
          const data = await response.json();

          // Almacenar el resultado para cada id_articulo
          reqExistentes[renglon.id_articulo] = data.length > 0;
        } catch (error) {
          console.error(
            "Error al verificar el requerimiento existente:",
            error
          );
        }
      }
    }

    // Actualizar el estado con los requerimientos existentes
    setReqExistente(reqExistentes);
  };
  // Llamar a verificarRequerimientosExistentes en useEffect cuando datosRenglon cambie
  useEffect(() => {
    verificarRequerimientosExistentes();
  }, [datosRenglon]);

  useEffect(() => {
    // Actualizar datosLista solo con los artículos que requieren un nuevo requerimiento y cumplen con los niveles de stock
    const nuevosDatosLista = datosRenglon
      .filter((renglon) => {
        // Verificar si no existe requerimiento y si está en el rango de alerta
        if (!reqExistente[renglon.id_articulo]) {
          const artSucMatch = artSuc.find(
            (item) =>
              item.id_articulo === renglon.id_articulo &&
              item.id_almacen === renglon.id_almacen
          );

          if (artSucMatch) {
            const { alerta_minima, valor_minimo } = artSucMatch;
            return renglon.cantidadSalida < alerta_minima;
          }
        }
        return false;
      })
      .map((renglon) => ({
        material: null,
        usuario: localStorage.getItem("user"),
        cantidad: 1,
        uuid_requerimiento: envioRequerimiento.uuid_requerimiento,
        id_articulo: renglon.id_articulo,
      }));

    setDatosLista(nuevosDatosLista);
  }, [reqExistente, datosRenglon]);

  const guardarListaMateriales = (e) => {
    postData(
      e,
      "/lista_materiales",
      {
        datosLista,
      },
      setNotificacion
    );
  };

  // const guardar = async (e) => {
  //   await fetch(UrlApi + "/stock_ficha_rrhh", {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify({
  //       empleado: empleadoSeleccionado.id,
  //       empresa: empleadoSeleccionado.idempresa,
  //       sucursal: empleadoSeleccionado.sucursal,
  //       desc_trabajo: datos.desc_trabajo,
  //       elementos_trabajo: datos.elementos_trabajo,
  //       fecha: datos.fecha,
  //       uuid_ficha: uuid,
  //       usuario: localStorage.getItem("user"),
  //     }),
  //   })
  //   .then((res) => {
  //     if (!res.err) {
  //       // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
  //       // setUpdate(true);
  //       console.log("Guardado");
  //     } else {
  //       console.log("Error");
  //     }
  //   })
  //   .catch((err) => {
  //     console.log(err.message);
  //   });

  //   for (let i = 0; i < datosRenglon.length; i++) {
  //     const element = datosRenglon[i];
  //     await fetch(UrlApi + "/stock_ficha_renglon", {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({
  //         uuid_ficha: uuid,
  //         id_articulo: datosRenglon[i].id_articulo,
  //         cantidad: datosRenglon[i].cantidad,
  //         fecha: datosRenglon[i].fecha,
  //         usuario: localStorage.getItem("user"),
  //       }),
  //     })
  //       .then((res) => {
  //         if (!res.err) {
  //           // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
  //           // setUpdate(true);
  //           console.log("Guardado");
  //         } else {
  //           console.log("Error");
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err.message);
  //       });
  //   }

  //   //requerimientos
  //   if (datosLista.length > 0) {
  //     try {
  //       await postData(
  //         e,
  //         "/requerimientos_materiales",
  //         {
  //           id_sucursal: props.sucursal,
  //           id_movil: envioRequerimiento.id_movil,
  //           estado: 50, //cuando se carga el pedido se pone en estado "Pendiente autorizacion"
  //           para_comprar: envioRequerimiento.para_comprar,
  //           para_compras: envioRequerimiento.para_compras,
  //           para_rrhh: envioRequerimiento.para_rrhh,
  //           fecha_entrega: envioRequerimiento.fecha_entrega,
  //           usuario_registra: envioRequerimiento.usuario_registra,
  //           usuario_autoriza: envioRequerimiento.username,
  //           observaciones: envioRequerimiento.observaciones,
  //           uuid_requerimiento: uuid,
  //           comentarios_internos: envioRequerimiento.comentarios_internos,
  //           autorizado_jefe_compras: 0,
  //         },
  //         setNotificacion,
  //         guardarListaMateriales(e)
  //       );

  //       console.log("Requerimiento creado con éxito.");
  //     } catch (error) {
  //       console.error("Error al crear el requerimiento:", error);
  //     }
  //   } else {
  //     console.log("No se creará el requerimiento porque datosLista está vacío.");
  //   }

  //   try {
  //     // Iterar sobre cada renglón en datosRenglon
  //     for (let renglon of datosRenglon) {
  //       if (
  //         renglon.id_articulo &&
  //         renglon.id_almacen &&
  //         renglon.cantidad &&
  //         renglon.cantidad > 0
  //       ) {
  //         // Guardar la transacción para cada renglón
  //         const transaccionRes = await fetch(UrlApi + "/stock_transacciones", {
  //           method: "POST",
  //           headers: { "Content-Type": "application/json" },
  //           body: JSON.stringify({
  //             id_accion: 200, // código para el tipo de consumo
  //             id_articulo: renglon.id_articulo,
  //             almacen_origen: renglon.id_almacen,
  //             cantidad_enviada: renglon.cantidad,
  //             fecha_transaccion: moment().format("YYYY-MM-DD HH:mm:ss"),
  //             usuario: localStorage.getItem("user"),
  //           }),
  //         });

  //         if (!transaccionRes.ok) {
  //           throw new Error("Error al guardar la transacción");
  //         }

  //         // Actualizar el stock después de guardar la transacción
  //         const stockRes = await fetch(
  //           UrlApi + "/stock_inventarios/" + renglon.id_inventario,
  //           {
  //             method: "PUT",
  //             headers: { "Content-Type": "application/json" },
  //             body: JSON.stringify({
  //               cantidad: renglon.cantidadSalida, // Restar esta cantidad del stock actual
  //               usuario: localStorage.getItem("user"),
  //             }),
  //           }
  //         );

  //         if (!stockRes.ok) {
  //           throw new Error("Error al actualizar el stock");
  //         }
  //         // Llamada a envioMail para verificar si se necesita enviar correo
  //         await envioMail(renglon);
  //       }
  //     }

  //     // Mostrar mensaje de éxito
  //     console.log("Guardado");
  //   } catch (error) {
  //     console.error(error.message);

  //   }
  //   handleClose();
  // };

  const guardar = async (e) => {
    setDisabled(true);
    try {
      for (const empleado of empleadosSeleccionados) {
        const nuevoUuid = uuidv4();
        // Guardar datos generales de RRHH para cada empleado
        const responseFicha = await fetch(UrlApi + "/stock_ficha_rrhh", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            empleado: empleado.id,
            empresa: empleado.idempresa,
            sucursal: empleado.sucursal,
            desc_trabajo: datos.desc_trabajo,
            elementos_trabajo: datos.elementos_trabajo,
            fecha: datos.fecha,
            uuid_ficha: nuevoUuid,
            usuario: localStorage.getItem("user"),
          }),
        });

        if (!responseFicha.ok) {
          console.error(
            "Error al guardar ficha de RRHH para empleado:",
            empleado
          );
          continue;
        }

        // console.log(
        //   `Ficha guardada para empleado: ${empleado.nombre} ${empleado.apellido}`
        // );

        // Guardar renglones relacionados con cada ficha
        for (const renglon of datosRenglon) {
          const responseRenglon = await fetch(UrlApi + "/stock_ficha_renglon", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              uuid_ficha: nuevoUuid,
              id_articulo: renglon.id_articulo,
              cantidad: renglon.cantidad,
              fecha: renglon.fecha,
              usuario: localStorage.getItem("user"),
            }),
          });

          if (!responseRenglon.ok) {
            console.error(
              "Error al guardar renglón de ficha para empleado:",
              empleado
            );
          } else {
            console.log(
              `Renglón guardado para ficha de empleado: ${empleado.nombre}`
            );
          }
        }

        // Guardar requerimientos si existen datos en datosLista
        if (datosLista.length > 0) {
          const responseRequerimientos = await postData(
            e,
            "/requerimientos_materiales",
            {
              id_sucursal: props.sucursal,
              id_movil: envioRequerimiento.id_movil,
              estado: 50, // Estado pendiente de autorización
              para_comprar: envioRequerimiento.para_comprar,
              para_compras: envioRequerimiento.para_compras,
              para_rrhh: envioRequerimiento.para_rrhh,
              fecha_entrega: envioRequerimiento.fecha_entrega,
              usuario_registra: envioRequerimiento.usuario_registra,
              usuario_autoriza: envioRequerimiento.username,
              observaciones: envioRequerimiento.observaciones,
              uuid_requerimiento: nuevoUuid,
              comentarios_internos: envioRequerimiento.comentarios_internos,
              autorizado_jefe_compras: 0,
            },
            setNotificacion,
            guardarListaMateriales(e)
          );

          if (!responseRequerimientos.ok) {
            console.error("Error al guardar requerimientos.");
          } else {
            console.log("Requerimientos guardados con éxito.");
          }
        } else {
          console.log(
            "No se creará el requerimiento porque datosLista está vacío."
          );
        }

        // Guardar transacciones y actualizar stock
        for (const renglon of datosRenglon) {
          if (
            renglon.id_articulo &&
            renglon.id_almacen &&
            renglon.cantidad > 0
          ) {
            // Guardar transacción
            const responseTransaccion = await fetch(
              UrlApi + "/stock_transacciones",
              {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  id_accion: 200, // Código para el tipo de consumo
                  id_articulo: renglon.id_articulo,
                  almacen_origen: renglon.id_almacen,
                  cantidad_enviada: renglon.cantidad,
                  fecha_transaccion: moment().format("YYYY-MM-DD HH:mm:ss"),
                  usuario: localStorage.getItem("user"),
                }),
              }
            );

            if (!responseTransaccion.ok) {
              console.error(
                "Error al guardar transacción para artículo:",
                renglon.id_articulo
              );
              continue;
            }

            // Actualizar stock
            // const responseStock = await fetch(
            //   UrlApi + "/stock_inventarios/" + renglon.id_inventario,
            //   {
            //     method: "PUT",
            //     headers: { "Content-Type": "application/json" },
            //     body: JSON.stringify({
            //       cantidad: renglon.cantidadSalida,
            //       usuario: localStorage.getItem("user"),
            //     }),
            //   }
            // );

            // if (!responseStock.ok) {
            //   console.error("Error al actualizar stock para artículo:", renglon.id_articulo);
            // } else {
            //   console.log("Stock actualizado para artículo:", renglon.id_articulo);
            // }

            // Enviar correo si aplica
            // await envioMail(renglon);
          }
        }
        // console.log("Todos los datos se guardaron correctamente.");
      }
    } catch (error) {
      console.error("Error general al guardar:", error.message);
    }
    for (const renglon of datosRenglon) {
      const restaCantidades =
        parseInt(renglon.cantidadInventario) -
        parseInt(renglon.cantidad) * parseInt(empleadosSeleccionados.length);
      // Actualizar stock
      const responseStock = await fetch(
        UrlApi + "/stock_inventarios/" + renglon.id_inventario,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            cantidad: restaCantidades,
            usuario: localStorage.getItem("user"),
          }),
        }
      );

      if (!responseStock.ok) {
        console.error(
          "Error al actualizar stock para artículo:",
          renglon.id_articulo
        );
      } else {
        // console.log("Stock actualizado para artículo:", renglon.id_articulo);
      }
    }

    handleClose();
  };

  const handleProductoChange = (index, value) => {
    const nuevasLineas = [...datosRenglon];
    nuevasLineas[index].id_articulo = value ? value.id_articulo : null;
    setDatosRenglon(nuevasLineas);

    if (nuevasLineas[index].id_almacen !== null) {
      verificarStock(nuevasLineas[index], index);
    }
    resta();
  };

  const handleAlmacenChange = (index, value) => {
    const nuevasLineas = [...datosRenglon];
    nuevasLineas[index].id_almacen = value ? value.id_almacen : null;
    setDatosRenglon(nuevasLineas);

    verificarStock(nuevasLineas[index], index);
    resta();
  };

  const handleCantidadChange = (index, value) => {
    const nuevasLineas = [...datosRenglon];
    nuevasLineas[index].cantidad = value;
    setDatosRenglon(nuevasLineas);

    verificarStock(nuevasLineas[index], index);
    resta();
  };

  const handleFechaChange = (index, value) => {
    const nuevasLineas = [...datosRenglon];
    nuevasLineas[index].fecha = value;
    setDatosRenglon(nuevasLineas);
  };

  const almacenar = (e) => {
    setDatos({
      ...datos,
      [e.target.name]: e.target.value,
    });
  };

  const deshacerAlmacenar = (index) => {
    const nuevasLineas = datosRenglon.filter((_, i) => i !== index);

    const itemEliminado = datosRenglon[index]; // Aquí capturamos el item que se va a eliminar
    verificarStock(itemEliminado, index);

    // Actualiza el estado con las nuevas líneas
    setDatosRenglon(nuevasLineas);
    const nuevasCantidades = nuevasLineas.map((linea) => linea.cantidadSalida);
    setCantidadesActualizadas(nuevasCantidades);
    // Si necesitas hacer una resta o cualquier otra acción aquí, puedes hacerlo
    setErroresStock((prev) => {
      const { [index]: _, ...rest } = prev;
      return rest;
    });
    setHayError(false);
  };

  useEffect(() => {
    getData(
      "/stock_ficha_rrhh/empleadosxsucursal/" + props.sucursal,
      setEmpleados
    );
    getData("/stock_almacenes/xsucursal/" + props.sucursal, setAlmacenes);
    getData("/stock_articulos/articulosxsector/3", setArticulos);
  }, []);

  const handleClose = () => {
    props.onClose(false);
  };

  // ************************** Baja de inventario **********************************//
  const verificarStock = (linea, index) => {
    const itemInventario = artSuc.find(
      (item) =>
        item.id_articulo === linea.id_articulo &&
        item.id_almacen === linea.id_almacen
    );

    if (itemInventario) {
      const cantidadDisponible = itemInventario.cantidad;
      const cantidadesMultiplicadas =
        parseInt(linea.cantidad) * parseInt(empleadosSeleccionados.length);
      if (cantidadDisponible < cantidadesMultiplicadas) {
        // Si no hay suficiente stock, se marca el error
        setErroresStock((prev) => ({
          ...prev,
          [index]: `Stock insuficiente: ${cantidadDisponible} disponible.`,
        }));
        setHayError(true);
      } else {
        // Si hay suficiente stock, se limpia el error
        setErroresStock((prev) => {
          const { [index]: _, ...rest } = prev;
          return rest;
        });
        if (Object.keys(erroresStock).length <= 1) {
          setHayError(false); // No hay más errores
        }
      }
    } else {
      // Si el artículo no existe en el inventario, marcar error
      setErroresStock((prev) => ({
        ...prev,
        [index]: "Sin Stock.",
      }));
      setHayError(true);
    }
  };
  const hayErrorRenglones = datosRenglon.some(
    (renglon) =>
      !renglon.id_articulo || !renglon.id_almacen || !renglon.cantidad
  );

  const resta = (linea, index) => {
    const nuevasCantidades = datosRenglon.map((linea, index) => {
      const itemInventario = artSuc.find(
        (item) =>
          item.id_articulo === linea.id_articulo &&
          item.id_almacen === linea.id_almacen
      );

      // Si existe el artículo en el inventario, actualizamos la cantidad
      if (itemInventario) {
        return itemInventario.cantidad - linea.cantidad; // Aquí puedes ajustar la lógica según tus necesidades
      }
      return linea.cantidad; // Devuelve la cantidad original si no se encuentra el artículo
    });

    setCantidadesActualizadas(nuevasCantidades);

    const nuevasLineas = datosRenglon.map((linea, index) => {
      const itemInventario = artSuc.find(
        (item) =>
          item.id_articulo === linea.id_articulo &&
          item.id_almacen === linea.id_almacen
      );

      // Si existe el artículo en el inventario, actualizar la cantidad
      if (itemInventario) {
        return {
          ...linea,
          cantidadSalida: itemInventario.cantidad - linea.cantidad,
          id_inventario: itemInventario.id_inventario, // Ajustar según la lógica que necesites
          cantidadInventario: itemInventario.cantidad,
        };
      }
      return linea; // Devuelve el renglón original si no se encuentra el artículo
    });

    setDatosRenglon(nuevasLineas);
  };

  const handleEmpleadoSeleccionado = (value) => {
    // Función que retorna una promesa
    const agregarEmpleado = () => {
      return new Promise((resolve, reject) => {
        // Verificamos si el empleado ya está en los seleccionados
        if (
          value &&
          !empleadosSeleccionados.some((emp) => emp.id === value.id)
        ) {
          // Agregar al array si no está duplicado
          setEmpleadosSeleccionados((prev) => [...prev, value]);
          resolve(); // Resolvemos la promesa cuando se haya agregado
        } else {
          reject("Empleado ya seleccionado o inválido"); // Si no es válido, rechazamos la promesa
        }
      });
    };

    // Ejecutamos la promesa
    agregarEmpleado()
      .then(() => {
        // Limpiar el Autocomplete después de agregar
        setEmpleadoSeleccionado(null);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        // Una vez que todo haya terminado, cambiamos el estado de 'filtrando'
        setFiltrando(false);
      });
  };

  useEffect(() => {
    if (
      empleadosSeleccionados.length !== 0 &&
      datosRenglon[0].id_articulo !== null
    ) {
      for (let i = 0; i < datosRenglon.length; i++) {
        const element = datosRenglon[i];
        verificarStock(element, i);
      }
    }
  }, [empleadosSeleccionados]);

  const handleEliminarEmpleado = (index) => {
    setEmpleadosSeleccionados(
      (prev) => prev.filter((_, i) => i !== index) // Elimina el empleado en la posición 'index'
    );
  };

  // Filtrar empleados seleccionados para mostrar en el buscador
  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const empleadosFiltrados = empleadosSeleccionados.filter((empleado) =>
    `${empleado.nombre} ${empleado.apellido}`.toLowerCase().includes(searchTerm)
  );

  useEffect(() => {
    const hasErrors =
      hayError ||
      Object.keys(erroresStock).length > 0 ||
      empleadosSeleccionados.length === 0 ||
      !datos.desc_trabajo ||
      !datos.elementos_trabajo ||
      hayErrorRenglones;

    setDisabled(hasErrors);
  }, [
    hayError,
    erroresStock,
    empleadosSeleccionados,
    datos,
    hayErrorRenglones,
  ]);

  return (
    <>
      <Dialog
        fullWidth
        open={props.open}
        onClose={() => props.onClose(false)}
        maxWidth="xl"
      >
        {notificacion && notificacion.open ? (
          <Notify
            mensaje={notificacion.mensaje}
            open={notificacion.open}
            color={notificacion.color}
            handleClose={() => {
              props.onClose(false);
              // props.actualizar();
            }}
            severity={notificacion.severity}
          />
        ) : null}
        <Box justifyContent="center" backgroundColor="#1F618D">
          <DialogTitle>
            <Typography fontSize={20} align="center" color="#ffffff">
              Asignar Articulos a Empleado
            </Typography>
          </DialogTitle>
        </Box>
        <form>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {filtrando === true ? (
                  <TextField
                    // value={(proveedorSeleccionadoEditar.id = null)}
                    disabled
                    focused
                    fullWidth
                    size="small"
                    label="Actualizando..."
                  />
                ) : (
                  <Autocomplete
                    disabled={!empleados}
                    required
                    disableClearable
                    id="empleado"
                    style={style}
                    size="small"
                    noOptionsText={"Empleado INEXISTENTE"}
                    options={empleados.filter(
                      (empleado) =>
                        !empleadosSeleccionados.some(
                          (selectedEmpleado) =>
                            selectedEmpleado.id === empleado.id
                        )
                    )}
                    autoHighlight
                    getOptionLabel={(empleado) =>
                      `${empleado.nro_legajo} - ${empleado.nombre} ${empleado.apellido}`
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.id === value?.id
                    }
                    value={empleadoSeleccionado || null}
                    onChange={(event, value) => {
                      setFiltrando(true);
                      handleEmpleadoSeleccionado(value);
                    }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        key={option.id}
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.nro_legajo} - {option.nombre} {option.apellido}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        placeholder="Tipee para buscar..."
                        {...params}
                        label="Empleado"
                        focused
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                )}
              </Grid>

              <Grid item xs={12}>
                {empleadosSeleccionados.length > 0 ? (
                  <Box
                    sx={{
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      padding: "10px",
                      backgroundColor: "#f9f9f9",
                      maxHeight: "150px", // Altura máxima para mostrar tres líneas
                      overflowY: "auto", // Scroll vertical
                    }}
                  >
                    <Grid container>
                      <Grid item xs={6} mb={1}>
                        <Typography
                          fontSize={18}
                          fontWeight="bold"
                          color="#1F618D"
                        >
                          Empleados Seleccionados:
                        </Typography>
                      </Grid>
                      <Grid item xs={6} mb={1}>
                        <TextField
                          fullWidth
                          size="small"
                          type="search"
                          placeholder="Buscar empleado..."
                          value={searchTerm}
                          onChange={handleSearch}
                        />
                      </Grid>
                    </Grid>

                    {empleadosFiltrados.map((empleado, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Typography fontSize={16}>
                          {empleado.nombre} {empleado.apellido} - Legajo:{" "}
                          {empleado.nro_legajo}
                        </Typography>
                        <Button
                          size="small"
                          variant="outlined"
                          color="error"
                          onClick={() => handleEliminarEmpleado(index)}
                        >
                          Eliminar
                        </Button>
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <Typography
                    fontSize={16}
                    color="gray"
                    justifyContent={"center"}
                    display={"flex"}
                  >
                    No hay empleados seleccionados.
                  </Typography>
                )}
              </Grid>

              <Grid item xs={6} mb={2}>
                <TextField
                  label="Descripción del trabajo"
                  name="desc_trabajo"
                  size="small"
                  placeholder="..."
                  inputProps={{
                    maxLength: 100,
                  }}
                  focused
                  required
                  fullWidth
                  value={datos.desc_trabajo}
                  onChange={almacenar}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Elementos Necesarios"
                  name="elementos_trabajo"
                  size="small"
                  placeholder="..."
                  inputProps={{
                    maxLength: 200,
                  }}
                  focused
                  required
                  fullWidth
                  value={datos.elementos_trabajo}
                  onChange={almacenar}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {empleadosSeleccionados.length !== 0 ? (
                  <Box
                    sx={{
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      // padding: "5px",
                      // alignSelf: "center",
                      // justifyContent: "center",
                      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                      backgroundColor: "#f9f9f9",
                      width: "100%",
                    }}
                  >
                    <Typography
                      fontSize={18}
                      marginTop={1}
                      marginLeft={2}
                      align="left"
                      fontWeight={"bold"}
                    >
                      Seleccione los articulos
                    </Typography>
                    {datosRenglon
                      ? datosRenglon.map((linea, index) => (
                          <div
                            key={index}
                            style={{
                              paddingBottom: "4px",
                              // paddingLeft: "3px",
                              paddingTop: "10px",
                            }}
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={4} ml={1}>
                                {articulos && (
                                  <Autocomplete
                                    disabled={!articulos}
                                    disableClearable
                                    size="small"
                                    required
                                    noOptionsText={"Articulo INEXISTENTE"}
                                    options={articulos}
                                    autoHighlight
                                    getOptionLabel={(articulo) =>
                                      articulo.id_articulo +
                                      "-" +
                                      articulo.nombre +
                                      "-" +
                                      articulo.descripcion
                                    }
                                    //   value={getProductoById(linea.producto)}
                                    onChange={(event, value) => {
                                      handleProductoChange(index, value);
                                      setArticuloSeleccionado(value);
                                    }}
                                    renderOption={(props, option) => (
                                      <Box
                                        component="li"
                                        key={option.id}
                                        sx={{
                                          "& > img": { mr: 2, flexShrink: 0 },
                                        }}
                                        {...props}
                                      >
                                        {option.id_articulo} - {option.nombre} -{" "}
                                        {option.descripcion}
                                      </Box>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        inputProps={{
                                          ...params.inputProps,
                                        }}
                                        focused
                                        size="small"
                                        placeholder="Tipee para buscar..."
                                        label="Articulo"
                                        error={!!erroresStock[index]}
                                      />
                                    )}
                                  />
                                )}
                              </Grid>
                              <Grid item xs={3.5}>
                                {almacenes && (
                                  <Autocomplete
                                    disabled={!almacenes}
                                    size="small"
                                    focused
                                    disableClearable
                                    id="stock_almacenes"
                                    noOptionsText={"Almacén INEXISTENTE"}
                                    options={almacenes}
                                    autoHighlight
                                    getOptionLabel={(almacenes) =>
                                      almacenes.id_almacen +
                                      "-" +
                                      almacenes.nombre
                                    }
                                    onChange={(event, value) => {
                                      handleAlmacenChange(index, value);
                                      setAlmacenSeleccionado(value);
                                    }}
                                    renderOption={(props, option) => (
                                      <Box
                                        component="li"
                                        key={option.id}
                                        sx={{
                                          "& > img": { mr: 2, flexShrink: 0 },
                                        }}
                                        {...props}
                                      >
                                        {option.id_almacen} - {option.nombre}
                                      </Box>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        inputProps={{
                                          ...params.inputProps,
                                        }}
                                        focused
                                        size="small"
                                        placeholder="Tipee para buscar..."
                                        label="Almacén"
                                        helperText={erroresStock[index] || ""} // Muestra el mensaje de error si hay
                                        error={!!erroresStock[index]}
                                      />
                                    )}
                                  />
                                )}
                              </Grid>
                              <Grid item xs={1}>
                                <TextField
                                  label="Cantidad"
                                  name="cantidad"
                                  size="small"
                                  type="number"
                                  focused
                                  required
                                  fullWidth
                                  autoComplete="off"
                                  value={linea.cantidad || ""}
                                  onChange={(e) =>
                                    handleCantidadChange(index, e.target.value)
                                  }
                                  error={!!erroresStock[index]} // Cambia a rojo si hay error
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <TextField
                                  label="Fecha"
                                  name="fecha"
                                  size="small"
                                  focused
                                  type="date"
                                  required
                                  fullWidth
                                  autoComplete="off"
                                  value={linea.fecha || ""}
                                  onChange={(e) =>
                                    handleFechaChange(index, e.target.value)
                                  }
                                  error={!!erroresStock[index]}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={1}
                                // md={2}
                                // style={{ textAlign: "right", marginTop: -40 }}
                                fullWidth
                              >
                                {" "}
                                {/* Columna para los iconos */}
                                {index !== 0 && ( // Solo mostrar el botón si el índice no es 0
                                  <Tooltip title="Eliminar línea">
                                    <IconButton
                                      variant="outlined"
                                      color="error"
                                      onClick={() => deshacerAlmacenar(index)}
                                    >
                                      <Delete />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                {index === datosRenglon.length - 1 && (
                                  <Tooltip title="Agregar línea">
                                    <IconButton
                                      variant="outlined"
                                      color="primary"
                                      onClick={() => {
                                        agregarLinea();
                                      }}
                                    >
                                      <AddCircleOutline />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </Grid>
                            </Grid>
                          </div>
                        ))
                      : null}
                  </Box>
                ) : null}
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              mt={1}
            >
              <Grid item>
                <Button
                  color="error"
                  variant="outlined"
                  onClick={() => props.onClose(false)}
                  style={{ backgroundColor: "#fff" }}
                >
                  Volver
                </Button>
              </Grid>
              <Grid item>
                <Button
                  // type="submit"
                  //   disabled={disabled}
                  color="success"
                  variant="contained"
                  disabled={disabled}
                  // endIcon={<SaveIcon />}
                  onClick={guardar}
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </form>
      </Dialog>
    </>
  );
}

export default Asignar_equipo;
