import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { UrlApi } from "../../../services/apirest";
import TextField from "@mui/material/TextField";

import Notify from "../../Notify";
import Alerta from "../../Alerta";

function BajaTanque(props) {
  const handleClose = () => {
    props.setOpen(false);
  };

  const [obs, setObs] = useState();
  const [disBtnGuardar, setDisBtnGuardar] = useState(false);
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
  const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
  const [mensajeAlerta, setMensajeAlerta] = useState(""); //mensaje que contendrá el componente "alerta"
  const [finalizar, setFinalizar] = useState(); //finalizar === 1 lo manda como prop el componente "notify" e indica que la transaccion ha finalizado y debe ejecutarse la funcion para cerrar

  const bajarTanque = async (e) => {
    e.preventDefault();
    const response = await fetch(
      UrlApi + "/tanques/" + props.datos.id,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          baja: 1,
          motivo_baja: obs,
        }),
      }
    )
    if (response.ok === false) {
        console.log("ERROR EN API");
        const error = response && response.json();
        setError(error);
        setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
      } else {
        console.log("Conexión con api OK");
        try {
          const json = await response.json();
          if (json.message) {
            if (json.message === "OK") {
              props.actualizar()
              console.log("Guardado Satisfactoriamente");
              setNotificacion(true);
              setOpenSnack(true);
              setMensaje("Guardado");
            } else {
              props.actualizar()
	            console.log("res en api incorrecto. Revisar que sea 'OK'")
              props.actualizar()
              setNotificacion(true);
              setOpenSnack(true);
              setMensaje("Registro Guardado. REVISAR RESPUESTA DE API (E_S)");
            }
          } else if(json.sqlMessage) {
            json && setError(json);
            console.log(json);
            setMensajeAlerta("SQL erróneo (" + json.code + " Errno: " + json.errno + " )" );
          } else {
            setMensajeAlerta("ERROR DESCONOCIDO.");
          }
        } catch (error) {
          if (error.toString().includes("is not valid JSON")) {
            props.actualizar()
            console.log("Guardado. Advertencia: Actualizar respuesta de api. JSON invalido.");
            setNotificacion(true);
            setOpenSnack(true);
            setMensaje("Guardado");
          } else {
            setMensajeAlerta("ERROR DESCONOCIDO.");
          }
        }
      }
  };

  
  useEffect(() => {
    if (finalizar === 1) handleClose();
  }, [finalizar]);
 

  return (
    <div>
      {notificacion ? (
        <Notify
          mensaje={mensaje}
          open={openSnack}
          color={"#D4EFDF"}
          finalizar={setFinalizar}
          severity={"success"}
        />
      ) : null}
      {error && mensajeAlerta ? (
        <Alerta mensaje={mensajeAlerta} severity={"error"} />
      ) : null}
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              minWidth: "600px",
            },
          },
        }}
        open={props.open}
        onClose={() => props.setOpen(false)}
      >
        <Box backgroundColor={"#1F618D"}>
          <DialogTitle>
            <Typography variant="h6" color="#ffffff">
              Baja De Tanque
            </Typography>
          </DialogTitle>
        </Box>
        <form onSubmit={bajarTanque}>
          <DialogContent>
            <DialogContentText>
              Motivo de la baja del tanque serie: {props.datos.nro_serie}
            </DialogContentText>
            <Grid container>
              <Grid item mt={2} xs={12}>
                <TextField
                  multiline
                  focused
                  fullWidth
                  color="primary"
                  label="Observaciones"
                  variant="outlined"
                  inputProps={{
                    maxLength: 500,
                  }}
                  onChange={(e) => setObs(e.target.value)}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item>
                <Button variant="outlined" color="error" onClick={handleClose}>
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="success"
                  type="submit"
                  disabled={disBtnGuardar}
                >
                  Confirmar
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default BajaTanque;
