import React, { useState, useEffect } from 'react';
import { getData, postData, putData } from '../../../../../services/library';
import { FormControlLabel, Checkbox, Button, Box, Grid, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Tooltip, alertClasses } from '@mui/material';
import Notify from "../../../../Notify";
import Alerta from "../../../../Alerta";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FileUploadMultiple from '../../../../../services/UploadFiles';
import { enviarmails } from '../../../../../services/services';
import { UrlApi } from '../../../../../services/apirest';
import { logEventos } from '../../../../../services/services';

export default function FormMarcar(props) {
  var fechaActual = new Date();
  var fechaISO = fechaActual.toISOString().substring(0, 10);
  var horaActual = fechaActual.toLocaleTimeString('es-ES', { hour12: false });  // Combinar la fecha y hora.
  var fechaYHora = fechaISO + ' ' + horaActual;
  const [datosOrden, setdatosorden] = useState(props.datos);
  const [inventario, setInventario] = useState([]);
  const [listamateriales, setlistamateriales] = useState([]);
  const [inventariosExistentes, setInventariosExistentes] = useState([]);
  const [almacenes, setAlmacenes] = useState();
  const [users, setUsers] = useState();
  const [openclose, setOpenClose] = useState(false);
  const [disBtn, setDisBtn] = useState(false);
  const [notificacion, setNotificacion] = useState({
    open: false,
    mensaje: "",
    severity: "",
    color: "",
  });

  const obtenerLista = () => {
    getData('/lista_materiales/ordencompra/' + datosOrden.uuid_ordencompra, setlistamateriales)
  };

  const getAlmacenes = () => {
    getData("/stock_almacenes/xsucursal/" + datosOrden.id_sucursal, setAlmacenes)
  }

  useEffect(() => {
    obtenerLista()
    getData('/userdata/sector/4', setUsers)
  }, []);

  useEffect(() => {
    getAlmacenes()
  }, [datosOrden.id_sucursal]);

  useEffect(() => {
    getInventariosExistentes();
  }, [almacenes, listamateriales]);

  const getInventariosExistentes = async () => {
    const inventarios = [];

    for (let datosTransferencias of listamateriales) {
      for (let almacen of almacenes) {
        if (datosTransferencias.id_articulo !== null) {
          try {
            const response = await fetch(UrlApi + `/stock_inventarios/xalmart/${almacen.id_almacen}/${datosTransferencias.id_articulo}`);
            const ll = await response.json();
            // console.log("ll", ll);
            // setResultado(ll);

            if (ll) {
              inventarios.push(ll);
              // console.log("inventarios", inventarios);
            }
          } catch (error) {
            console.error("Error en la consulta:", error);
            // Manejar el error según sea necesario
          }
        }
      }
    }

    // Trabajar con 'inventarios' después de que el bucle haya terminado
    // console.log("Inventarios finales:", inventarios);
    setInventariosExistentes(inventarios);
  };

  const handleClose = () => {
    props.open(false);
    props.actualizar()
  };

  const marcarEntregado = (e) => {
    putData(e,
      '/ordencompra/' + datosOrden.idordencompra, {
      id_estado: 186
    },
      postTransferencia(e),
      setNotificacion,
      logEventos("Recibir ordencompra", "Ordenes compra", "Ordenes compra", localStorage.getItem("user"))
    )
  };
  // console.log("almacenes", almacenes)
  // console.log("inventariosExistentes", inventariosExistentes)

  const postTransferencia = (e) => {
    for (let articulo of listamateriales) {
      if (articulo.id_articulo != null) {
        postData(e,
          "/stock_transacciones",
          {
            id_accion: 158,
            id_articulo: articulo.id_articulo,
            almacen_destino: almacenes && almacenes[0].id_almacen,
            detalle_transaccion: "Proveniente de orden de compra Nro°" + datosOrden.idordencompra,
            usuario: localStorage.getItem('user'),
            sucursal_destino: datosOrden.id_sucursal,
            cantidad_recibida: articulo.cantidad_actualizada !== null ? articulo.cantidad_actualizada : articulo.cantidad,
            fecha_transaccion: fechaYHora
          },
          setNotificacion
        )
        props.actualizar()
      }
    }
    post2(e)
  }


  const post2 = async (e) => {
    try {
      if (inventariosExistentes.length === 0) {

        for (let articulo of listamateriales) {
          if (articulo.id_articulo !== null) {
            postData(e,
              "/stock_inventarios",
              {
                id_articulo: articulo.id_articulo,
                cantidad: articulo.cantidad_actualizada !== null ? articulo.cantidad_actualizada : articulo.cantidad,
                id_almacen: almacenes[0].id_almacen,
                usuario: localStorage.getItem('user')
              }, setNotificacion
            )
          }
        }
      } else {
        for (let inventariosArray of inventariosExistentes) {
          for (let inventario of inventariosArray) {
            for (let articulo of listamateriales) {
              if (articulo.id_articulo !== null && inventario.id_articulo === articulo.id_articulo) {
                const cantidadArticulo = articulo.cantidad_actualizada !== null ? articulo.cantidad_actualizada : articulo.cantidad;

                if (typeof inventario.cantidad === 'number') {
                  // Solo continuar si la propiedad 'cantidad' en inventario es un número
                  const cantidadActualizada = inventario.cantidad + cantidadArticulo;
                  const url = `/stock_inventarios/${almacenes[0].id_almacen}/${articulo.id_articulo}`;

                  putData(
                    e,
                    url,
                    {
                      cantidad: cantidadActualizada,
                      usuario: localStorage.getItem('user')
                    },
                    setNotificacion
                  );
                } else {
                  console.error("Error: La propiedad 'cantidad' en inventario no es un número.");
                }
              }
            }
          }
        }
      }
    } catch (error) {
      console.log("Error", error);
    }
  };



  return (
    <Dialog open={props.open} onClose={handleClose} fullWidth>
      <form onSubmit={marcarEntregado}>
        {/* <form onSubmit={post2}> */}
        <Box backgroundColor={"#1F618D"}>
          <DialogTitle>
            <Typography fontSize={23} align="center" color="#ffffff">
              Desea marcar la orden como entregada ?
            </Typography>
          </DialogTitle>
        </Box>
        <DialogContent align="center">

          <Button onClick={() => setOpenClose(!openclose)} variant="outlined" size="small" ><AttachFileIcon />Adjuntar Remito</Button>

        </DialogContent>
        <DialogActions>
          <Grid mr={2}
            container
            direction="row"
            justifyContent="space-between"
          // alignItemns="center"
          >
            <Button
              mr={2}
              variant="contained"
              onClick={handleClose}
              color="error">
              Volver
            </Button>



            <Button
              mr={2}
              disabled={disBtn}
              variant="contained"
              type="submit"
              // onClick={marcarEntregado}
              color="success">
              Aceptar
            </Button>

          </Grid>
        </DialogActions>
      </form>



      {notificacion && notificacion.open ? (
        <Notify
          mensaje={notificacion.mensaje}
          open={notificacion.open}
          color={notificacion.color}
          handleClose={handleClose}
          severity={notificacion.severity}
        />
      ) : null}

      {openclose ? <FileUploadMultiple fileId={datosOrden.idordencompra} openclose={true} /> : null}
    </Dialog>

  )
}