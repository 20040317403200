import React, { useRef, useState, useEffect } from "react";
import {
    GoogleMap,
    LoadScript,
    Marker,
    StandaloneSearchBox,
    InfoWindow
} from "@react-google-maps/api";
import { Button, Grid, Box } from "@mui/material";
import i8 from '../../../../img/i8.png'
import { getData } from "../../../../services/library";
const containerStyle = {
    width: "600px",
    height: "330px",
};
const libraries = ["places", "geometry"];

export default function MapaDirecciones(props) {
    const [direcciones, setDirecciones] = useState([]);
    const [datosSucursal, setDatosSucursal] = useState();
    const [selectedPlace, setselectedPlace] = useState()
    const [center, setCenter] = useState({ lat: "", lng: "" })
    const inputRef = useRef();

    useEffect(() => {
        getData('/direccionesentrega/xnro_reparto/' + props.nro_reparto, setDirecciones)
        getData('/sucursales/latitudlongitud/' + props.idsucursal, setDatosSucursal)
    }, []);

    useEffect(() => {
        datosSucursal && setCenter({ lat: datosSucursal[0].latitud * 1, lng: datosSucursal[0].longitud * 1 })
    }, [datosSucursal]);

    return (
        <div>
            <Box py={2}>
                <LoadScript
                    googleMapsApiKey={process.env.REACT_APP_Google_maps_key}
                    libraries={libraries}>
                    <StandaloneSearchBox
                        onLoad={(ref) => (inputRef.current = ref)}>

                        <Grid container direction="row" mb={2}>
                            
                        </Grid>
                    </StandaloneSearchBox>
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={18}
                    >
                        <Marker                            
                            position={datosSucursal && { lat: datosSucursal[0].latitud * 1, lng: datosSucursal[0].longitud * 1 }}
                            icon={i8}                           

                        ></Marker>

                        {/* {direcciones && direcciones.map(place => console.log("place",place.latitud *1 , place.longitud *1))} */}
                        {direcciones && direcciones.map(place => (
                            <>
                                <Marker
                                    key={place.id}
                                    position={{ lat: place.latitud * 1, lng: place.longitud * 1 }}
                                    title={place.razon_social}
                                    onClick={() => {
                                        setselectedPlace(place)
                                        setCenter({ lat: place.latitud * 1, lng: place.longitud * 1 })
                                    }}
                                />

                            </>
                        ))}
                        {selectedPlace && (
                            <InfoWindow
                                position={{ lat: selectedPlace.latitud * 1, lng: selectedPlace.longitud * 1 }}
                                onCloseClick={() => { setselectedPlace(null) }}
                            >
                                <div>
                                    <h4>
                                        {selectedPlace.razon_social}
                                    </h4>                                    
                                </div>
                            </InfoWindow>
                        )}

                    </GoogleMap>
                    <Grid container direction="row" justifyContent="space-between" mt={2}>
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={props.handleClose}
                        >
                            Cerrar
                        </Button>
                    </Grid>
                </LoadScript>
            </Box>
        </div>
    )


}