import React, { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { UrlApi } from "../../../services/apirest";
import { Button, Stack, InputAdornment, Box, Container, TextField, DialogTitle, IconButton, Grid, Typography, Snackbar } from "@mui/material";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import ContactsIcon from "@mui/icons-material/Contacts";
import { grey } from "@mui/material/colors";
import Alerta from "../../Alerta";
import Notify from "../../Notify";

function Addfabricante(props) {
    const [datosformulario, setDatosformulario] = useState(props.datos);
    const [disBtn, setDisBtn] = useState(false);
    const [openSnack, setOpenSnack] = React.useState(false);
    const [colormensaje, setColorMensaje] = useState("");
    const [mensaje, setMensaje] = useState("");
    const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
    const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
    const [mensajeAlerta, setMensajeAlerta] = useState(""); //mensaje que contendrá el componente "alerta"
    const [finalizar, setFinalizar] = useState(); //finalizar === 1 lo manda como prop el componente "notify" e indica que la transaccion ha finalizado y debe ejecutarse la funcion para cerrar
    const [severityAlerta, setSeverityAlerta] = useState(""); //define severidad de la alerta (success error o warning)
    const [severitySnack, setSeveritySnack] = useState(""); //define severidad del snackbar (notificación)

    //Funcion para almacenar los datos (se ejecuta en el onchange de los textfield/select)
    const almacenar = (e) => {
        setDatosformulario({
            ...datosformulario,
            [e.target.name]: e.target.value,
        }); // almacena datos del formulario y lo q se guarda en las constantees usuario y activo
    };

    const handleClose = () => {
        props.editar(false);
        props.tabla(true);
    };

    const guardarFabricante = async (e) => {
        setDisBtn(true);
        e.preventDefault();
        let response = await fetch(UrlApi + "/fabricantes/" + datosformulario.id, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                usuario: localStorage.getItem("user"),
                nombre: datosformulario.nombre,
                contacto: datosformulario.contacto,
            }
            ),
        })
        //response&&console.log(response)
        if (response.ok === false) {
            console.log("ERROR EN API");
            const error = response && response.json();
            setError(error);
            setSeverityAlerta("error")
            setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
        } else {
            console.log("Conexión con api OK");
            try {
                //Parseo respuesta de la api a json()
                const json = await response.json();
                //si api me devuelve lo q le puse en message
                if (json.message) {
                    //Si ese mensaje es "OK"
                    if (json.message === "OK") {
                        console.log("Guardado Satisfactoriamente");
                        setNotificacion(true);
                        setOpenSnack(true);
                        setColorMensaje("#D4EFDF");
                        setMensaje("Guardado");
                        setSeveritySnack("success");
                    } else {
                        console.log("res en api incorrecto. Revisar que sea 'OK'")
                        setNotificacion(true);
                        setOpenSnack(true);
                        setColorMensaje("#D4EFDF");
                        setSeveritySnack("error");
                        setMensaje("Registro Guardado. REVISAR RESPUESTA DE API (E_S)");
                    }
                } else if (json.sqlMessage) {
                    json && setError(json);
                    console.log(json);
                    setMensajeAlerta("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
                } else {
                    setMensajeAlerta("ERROR DESCONOCIDO.");
                }
            } catch (error) {
                if (error.toString().includes("is not valid JSON")) {
                    console.log("Guardado. Advertencia: Actualizar respuesta de api. JSON invalido.");
                    setNotificacion(true);
                    setOpenSnack(true);
                    setColorMensaje("#D4EFDF");
                    setMensaje("Guardado");
                    setSeveritySnack("success");
                } else {
                    setMensajeAlerta("ERROR DESCONOCIDO.");
                }
            }
        }
        handleClose();
    };

    const style = {
        backgroundColor: "#ffffff",
        width: "100%"
    };


    return (
        <div>
            <Container>
                <Box sx={{ flexGrow: 1 }} mt={2}>
                    <Grid container backgroundColor="#1F618D" p={1.5}>
                        <Grid item xs={2}>
                            <IconButton onClick={handleClose}>
                                <ArrowBackIcon sx={{ color: grey[50] }} />
                            </IconButton>
                        </Grid>
                        <Grid
                            item
                            xs={8}
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography variant="h5" color="#ffffff">
                                Editar fabricante
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <form onSubmit={guardarFabricante}>
                        <Box
                            backgroundColor="#F4F6F6"
                            mt={2}
                            p={3}
                            border={0.5}
                            borderColor={"#ABB2B9"}
                            borderRadius={4}
                        >
                            <Grid container spacing={1} direction={{ xs: 'column', sm: 'row' }}>
                                <Grid item xs={6}>
                                    <TextField
                                        placeholder="Fabricante"
                                        required
                                        focused
                                        fullWidth
                                        name="nombre"
                                        style={style}
                                        id="fabricante"
                                        label="Fabricante"
                                        color="primary"
                                        onChange={almacenar}
                                        value={datosformulario.nombre.replace(/\b\w/g, (l) =>
                                            l.toUpperCase()
                                        )}
                                        inputProps={{ maxLength: 45 }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <BuildCircleIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        placeholder="Contacto"
                                        //required
                                        focused
                                        fullWidth
                                        name="contacto"
                                        id="contacto"
                                        style={style}
                                        label="Contacto"
                                        color="primary"
                                        onChange={almacenar}
                                        value={datosformulario.contacto}
                                        inputProps={{ maxLength: 100 }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <ContactsIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid mt={2} container direction="row" justifyContent="space-between" alignItemns="center">
                                    <Button
                                        disabled={disBtn}
                                        variant="contained"
                                        color="error"
                                        onClick={handleClose}
                                    >
                                        Volver
                                    </Button>
                                    <Button
                                        disabled={disBtn}
                                        variant="contained"
                                        type="submit"
                                        color="success"
                                    >
                                        Guardar Fabricante
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </form>
                </Box>
                {notificacion ? (
                    <Notify
                        mensaje={mensaje}
                        open={openSnack}
                        color={colormensaje}
                        finalizar={setFinalizar}
                        severity={severitySnack}
                    />
                ) : null}
                {/* ALERT --> SE MUESTRA SI HAY UN ERROR EN LA API (NO SE CONECTA O ESTÁ MAL LA RUTA, ETC.) */}
                {error && mensajeAlerta ? (
                    <Alerta mensaje={mensajeAlerta} severity={severityAlerta} />
                ) : null}
            </Container>
        </div>
    );
}

export default Addfabricante;
