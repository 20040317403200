import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import { Grid, TextField } from "@mui/material";
import { UrlApi } from "../../../../../services/apirest";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";

import Notify from "../../../../Notify";

function AddBateria(props) {
  //   const [idDir, setIdDir] = useState(props.datos);

  const [datosBateria, setDatosBateria] = useState({
    nro:
      props.cliente && props.cliente.sisven_id ? props.cliente.sisven_id : "",
    nombre: "",
    id_direccionentrega: props.datos,
  });
  const [baterias, setBaterias] = useState(null); //Para buscar si ese nro de bateria ya se cargó.

  //estados para manejo de errores
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
  const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
  const [disBtn, setDisBtn] = useState(false);

  const obtenerBaterias = () => {
    fetch(
      UrlApi +
        "/baterias2/dirnro/" +
        datosBateria.nro +
        "/" +
        datosBateria.id_direccionentrega
    )
      .then((data) => data.json())
      .then((data) => setBaterias(data));
    console.log(
      UrlApi +
        "/baterias2/dirnro/" +
        datosBateria.nro +
        "/" +
        datosBateria.id_direccionentrega
    );
  };

  const almacenar = (e) => {
    setDatosBateria({
      ...datosBateria,
      [e.target.name]: e.target.value,
    });
    // console.log(datosBateria);
  };

  async function guardarBateria(e) {
    setDisBtn(true);
    e.preventDefault();
    const response = await fetch(UrlApi + "/baterias2", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        nro: datosBateria.nro,
        nombre: datosBateria.nombre,
        id_direccionentrega: datosBateria.id_direccionentrega,
      }),
    });
    if (response.ok === false) {
      const error = response && response.json();
      setError(error);
      alert("ERROR AL GUARDAR BATERIA");
    } else {
      try {
        const json = await response.json();
        if (json.message) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje(
            json.message === "OK" ? "Guardado" : "Guardado. Actualizar RTA Api"
          );
          props.actualizar();
          props.setearUltimaBateria(true);
        } else if (json.sqlMessage) {
          json && setError(json);
          alert(`SQL ERRONEO ${json.code} / ERRNO: ${json.errno}`);
        } else {
          alert("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje("Guardado. Actualizar RTA API a Json");
          props.actualizar();
          props.setearUltimaBateria(true);
        } else {
          alert("ERROR DESCONOCIDO: " + error);
        }
      }
    }
  }

  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <div>
      {/* comentario random */}
      <Dialog open={props.open} onClose={handleClose}>
        {notificacion ? (
          <Notify
            mensaje={mensaje}
            open={openSnack}
            color={"#D4EFDF"}
            handleClose={handleClose}
            severity={"success"}
          />
        ) : null}
        <Box backgroundColor="#1F618D" color="white">
          <DialogTitle>
            Nueva Batería De Tanques{" "}
            {props.cliente && props.cliente.sisven_id
              ? `(Cliente: ${props.cliente.sisven_id})`
              : ""}{" "}
          </DialogTitle>
        </Box>
        <DialogContent>
          <form onSubmit={guardarBateria}>
            <Grid container direction="row" spacing={2} px={2} pb={2}>
              {baterias && baterias.length > 0 ? (
                <Grid item xs={12} spacing={2}>
                  <Alert severity="warning">
                    Una batería ya ha sido cargada con este número. Por favor
                    ingrese uno nuevo.
                  </Alert>
                </Grid>
              ) : null}
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  size="small"
                  onChange={almacenar}
                  onBlur={() => obtenerBaterias()}
                  name="nro"
                  value={datosBateria.nro}
                  color="primary"
                  label="Numero"
                  variant="outlined"
                  fullWidth
                  type="number"
                  focused
                  helperText={
                    props.cliente && props.cliente.sisven_id
                      ? "Sugiere N° Sisven Cliente"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  size="small"
                  onChange={almacenar}
                  name="nombre"
                  value={datosBateria.nombre}
                  color="primary"
                  label="Nombre/Descripción"
                  variant="outlined"
                  fullWidth
                  type="text"
                  focused
                />
              </Grid>
            </Grid>
            <Grid
              p={2}
              container
              direction="row"
              justifyContent="space-between"
            >
              <Button variant="contained" color="error" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="success"
                disabled={disBtn || (baterias && baterias.length > 0)}
              >
                Guardar
              </Button>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddBateria;
