//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio
// Creation Date : 07/11/2024
// Version : 1
// Description : pantalla para ver los datos del check cargado (x el de higiene)
//------Changes --------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect } from "react";
import { UrlApi } from "../../../services/apirest";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Divider from "@mui/material/Divider";
//ADJUNTAR
import AttachFileIcon from "@mui/icons-material/AttachFile"; //ADJUNTAR IMAGEN
import FileUploadHS from "./FileUploadHS";
import { v4 as uuidv4 } from "uuid"; //ADJUNTAR IMAGEN
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

function VerResultadoCheck(props) {
  const {
    responsable,
    sucursal,
    fecha,
    mostrarResultadoCheck,
    tabla,
    estados,
  } = props;

  //console.log("ESTADO COMPLETO",estados);
  // console.log("ESTADOS",estados[0].auditado,estados[0].auditor,estados[0].fecha_auditado);
  const fechaFormat = moment(fecha).format("YYYY-MM");
  const [resultadosCheck, setResultadosCheck] = useState();
  const [selectedDate, setSelectedDate] = useState("");
  const [depositoSeleccionado, setDepositoSeleccionado] = useState("");
  const [uuid, setUuid] = useState(uuidv4()); //ADJUNTAR IMAGEN
  const [openCloseAdj, setOpenCloseAdj] = useState(false); //ADJUNTAR IMAGEN
  const [checkboxStates, setCheckboxStates] = useState({});
  const [observaciones, setObservaciones] = useState({});
  const [fechaResultadoCheck, setFechaResultadoCheck] = useState({});
  const [diasHabiles, setDiasHabiles] = useState({});
  // Estado para almacenar las fechas de vencimiento de cada item
  const [fechasVencimiento, setFechasVencimiento] = useState({});
  const [adjuntos, setAdjuntos] = useState({});

  // -------- fecha -----------//
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };
  const formattedDate = `${selectedDate}`;
  // ----------------------------- GET ---------------------- //
  const [gruposEItems, setGruposEItems] = useState([]);
  const [terminado, setTerminado] = useState(false);

  const obtenerGruposEItems = () => {
    fetch(UrlApi + "/seg_hig_grupos/activos")
      .then((response) => response.json())
      .then((grupos) => {
        const promises = grupos.map((grupo) => {
          // Retorna una promesa para cada llamada a la API
          return fetch(
            UrlApi + `/seg_hig_grupos/itemxgrupoActivos/${grupo.id_grupo}`
          )
            .then((response) => response.json())
            .then((items) => ({ grupo, items }));
        });
        // Espera a que todas las promesas se resuelvan
        return Promise.all(promises);
      })
      .then((gruposItemsArray) => {
        // Una vez que todas las llamadas a la API se completen con éxito,
        // actualiza el estado con el array completo de grupos e items
        setGruposEItems(gruposItemsArray);
        setTerminado(true);
      })
      .catch((error) =>
        console.error("Error al obtener grupos e items:", error)
      );
  };

  useEffect(() => {
    obtenerGruposEItems();
  }, []);

  // console.log("GRUPOS E ITEMS", gruposEItems);

  //console.log("OBSERVACIONES: ", observaciones);

  const obtenerResultadosCheck = () => {
    fetch(
      UrlApi +
        "/seg_hig_checklist/verResultadoCheck/" +
        localStorage.getItem("user") +
        "/" +
        fechaFormat +
        "/" +
        sucursal.id
    )
      .then((data) => data.json())
      .then((data) => {
        // --------------------- CHECKBOX------------------------------ //
        // Crear un nuevo objeto para almacenar los estados de los checkboxes actualizados
        const newCheckboxStates = { ...checkboxStates }; // Copiar el estado actual de los checkboxes
        // Iterar sobre los resultados obtenidos
        data.forEach((resultado) => {
          // Comprobar si el grupo ya existe en el estado de los checkboxes
          if (!newCheckboxStates[resultado.id_grupo]) {
            newCheckboxStates[resultado.id_grupo] = {};
          }
          // Actualizar el estado de los checkboxes para este grupo y item
          if (resultado.cumple === 1) {
            newCheckboxStates[resultado.id_grupo][resultado.id_item] = "cumple";
          } else if (resultado.no_cumple === 1) {
            newCheckboxStates[resultado.id_grupo][resultado.id_item] =
              "no_cumple";
          } else {
            newCheckboxStates[resultado.id_grupo][resultado.id_item] = "n_a";
          }
        });
        // Actualizar el estado checkboxStates con los nuevos valores obtenidos
        setCheckboxStates(newCheckboxStates);
        // También puedes hacer otras cosas con los resultados si es necesario
        setResultadosCheck(data);
        // -------------------- OBSERVACIONES ---------------------- //
        // Crear un nuevo objeto para almacenar las observaciones de cada ítem
        const newObservaciones = { ...observaciones };

        // console.log("NEW OBSERVACIONES: ", newObservaciones);
        // Iterar sobre los resultados obtenidos
        data.forEach((resultado) => {
          // Actualizar las observaciones para este ítem
          newObservaciones[resultado.id_item] = resultado.observacion || ""; // Asumiendo que la propiedad en los resultados es 'observacion'
        });
        // Actualizar el estado 'observaciones' con los nuevos valores obtenidos
        setObservaciones(newObservaciones);
        // También puedes hacer otras cosas con los resultados si es necesario
        setResultadosCheck(data);
        // -------------------- FECHA ---------------------- //
        // Extraer la fecha de los resultados si está presente en la respuesta de la API
        const fecha = data.length > 0 ? data[0].fecha : null;
        // Guardar la fecha en el estado
        setFechaResultadoCheck(fecha);
        // -------------------- UUID DE ADJUNTOS ---------------------- //
        const newAdjuntos = {};
        data.forEach((resultado) => {
          // Imprime el resultado para asegurarte de que el uuid_adjunto existe
          console.log("Resultado item:", resultado);

          if (resultado.uuid) {
            newAdjuntos[resultado.id_item] = {
              uuid: resultado.uuid,
              open: false,
            };
          }
        });
        setAdjuntos(newAdjuntos);
        console.log("Adjuntos almacenados:", newAdjuntos);
        // -------------------- FECHAS DE VENCIMIENTO ---------------------- //
        // Crear un nuevo objeto para almacenar fechas de vencimiento actualizadas
        const newFechasVencimiento = {}; // Empezar con un objeto vacío para evitar arrastrar datos anteriores

        data.forEach((resultado) => {
          // Si el item tiene una fecha de vencimiento, la almacenamos
          if (resultado.fecha_vencimiento) {
            newFechasVencimiento[resultado.id_item] =
              resultado.fecha_vencimiento || "";
          }
        });
        // Actualizar el estado con las nuevas fechas de vencimiento
        setFechasVencimiento(newFechasVencimiento);

        // Guardar la fecha general del resultado del checklist si está presente
        const fecha2 = data.length > 0 ? data[0].fecha2 : null;
        setFechaResultadoCheck(fecha2);
      });
  };

  useEffect(() => {
    obtenerResultadosCheck();
  }, []);

  //console.log("RESULTADOS FLOTA", datosFlota);
  const [deposito, setDeposito] = useState();

  const obtenerDepositos = () => {
    fetch(UrlApi + "/sucursales")
      .then((data) => data.json())
      .then((data) => setDeposito(data));
  };
  useEffect(() => {
    obtenerDepositos();
  }, []);

  // console.log("DEPOSITOS", depositoSeleccionado);
  const [comercial, setComercial] = useState();

  const obtenerComercial = () => {
    fetch(UrlApi + "/representantes/activos/")
      .then((data) => data.json())
      .then((data) => setComercial(data));
  };
  useEffect(() => {
    obtenerComercial();
  }, []);

  const [representante, setRepresentante] = useState();

  const obtenerRepresentante = () => {
    fetch(UrlApi + "/representantes/getid/" + localStorage.getItem("user"))
      .then((data) => data.json())
      .then((data) => setRepresentante(data));
  };
  useEffect(() => {
    obtenerRepresentante();
  }, []);

  // ------------------ESTILO -------------------- //
  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  const size = "small";
  //-------- Función para contar la cantidad de checks marcados ------------- //
  const contarChecksMarcados = () => {
    let cantidadCumple = 0;
    let cantidadNoCumple = 0;
    let cantidadNA = 0;

    // Iterar sobre los grupos
    for (const grupoId in checkboxStates) {
      // Iterar sobre los items dentro del grupo
      for (const itemId in checkboxStates[grupoId]) {
        const estado = checkboxStates[grupoId][itemId];
        // Contar los estados de los checkboxes
        if (estado === "cumple") {
          cantidadCumple++;
        } else if (estado === "no_cumple") {
          cantidadNoCumple++;
        } else if (estado === "n_a") {
          cantidadNA++;
        }
      }
    }

    return {
      cumple: cantidadCumple,
      no_cumple: cantidadNoCumple,
      n_a: cantidadNA,
    };
  };
  // Llamada a la función para obtener la cantidad de checks marcados
  const cantidadChecks = contarChecksMarcados(checkboxStates);
  // console.log("Cantidad de checks 'Cumple':", cantidadChecks.cumple);
  // console.log("Cantidad de checks 'No cumple':", cantidadChecks.no_cumple);

  // -------- checkbox auditoria ------------ //
  // Agrega un nuevo estado para manejar el estado del checkbox de auditoría
  const [auditoriaCheckboxStates, setAuditoriaCheckboxStates] = useState({});
  //   console.log("RESPONSABLE",representante[0].nombre_apellido);
  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  //-------------------- ADJUNTO -------------------------- //
  // Función para manejar la apertura/cierre del diálogo de adjuntar
  const handleOpenCloseAdj = (itemId, isOpen) => {
    setAdjuntos((prev) => ({
      ...prev,
      [itemId]: {
        ...prev[itemId],
        open: isOpen,
        uuid: prev[itemId]?.uuid, // Usa el UUID existente
      },
    }));
    console.log("UUID", [itemId]?.uuid);
  };

  // Función para obtener el UUID del archivo
  const getUuidForItem = (itemId) => adjuntos[itemId]?.uuid || "";

  // Función para verificar si el diálogo está abierto para un ítem
  const isAdjDialogOpen = (itemId) => adjuntos[itemId]?.open || false;

  return (
    <div>
      <Box
        bgcolor="#2B76A6"
        padding={1}
        borderRadius={0}
        color="white"
        textAlign="center"
        display="flex" // Agregado para que los elementos se coloquen en línea
        alignItems="center"
        marginTop={1}
        width="100%"
      >
        <AssignmentIcon
          style={{ fontSize: 40, marginRight: "20px", marginLeft: "20px" }}
        />
        <Typography variant="h4" component="div">
          Ver el control de seguridad e higiene completado
        </Typography>
      </Box>
      <br></br>
      <Box>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          ml={2}
        >
          <Grid item xs={4} md={4}>
            <TextField
              label="Responsable"
              name="responsable"
              size="small"
              focused
              required
              fullWidth
              value={responsable}
            />
          </Grid>
          <Grid item xs={4} md={3}>
            <TextField
              label="Sucursal"
              name="deposito"
              size="small"
              focused
              required
              fullWidth
              value={sucursal.nombre}
            />
          </Grid>
          <Grid item xs={4} md={3}>
            <TextField focused size="small" label="Período" value={fecha} />
          </Grid>
        </Grid>
      </Box>
      <br></br>
      {resultadosCheck && resultadosCheck.length > 0 ? (
        <Paper
          elevation={6}
          style={{ width: "80%", padding: "20px", marginLeft: 70 }}
        >
          <Typography
            variant="poster"
            fontSize={20}
            style={{ textDecoration: "underline" }}
          >
            Control de seguridad e higiene:
          </Typography>
          <br></br>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="poster" fontSize={20}>
                Responsable: {responsable}
                {/* Responsable:{representante[0].nombre_apellido} */}
                <br></br>
                Sucursal: {sucursal.nombre}
                <br></br>
              </Typography>
            </Grid>

            {estados[0].auditado === 1 ? (
              <Grid item xs={6}>
                <Typography variant="poster" fontSize={20}>
                  Auditor: {estados[0].auditor}
                  <br></br>
                  Fecha de auditoría:{" "}
                  {moment(estados[0].fecha_auditado).format("DD-MM-YYYY")}
                </Typography>
              </Grid>
            ) : null}
          </Grid>
          <Table>
            <br></br>
            <TableBody>
              {/* Mapea sobre gruposEItems para renderizar cada grupo y sus items */}
              {terminado === true &&
                gruposEItems.map((grupoItem, grupoIndex) => (
                  <React.Fragment key={grupoItem.grupo.id_grupo}>
                    <TableRow>
                      <TableCell
                        style={{ fontSize: 17, backgroundColor: "#C7C7C7" }}
                        colSpan={3}
                      >
                        <b>{grupoItem.grupo.nombre_grupo}</b>
                      </TableCell>
                    </TableRow>
                    {grupoItem.items.map((item, itemIndex) => (
                      <TableRow key={item.id_item}>
                        <TableCell style={{ fontSize: 16 }}>
                          <b>{item.nombre_item}</b>
                          <br></br>
                          <br></br>
                          {item.definicion_item}
                        </TableCell>
                        <TableCell>
                          <FormGroup
                            aria-label="position"
                            row
                            style={{ marginRight: 100 }}
                          >
                            <FormControlLabel
                              labelPlacement="end"
                              label="Cumple"
                              control={
                                <Checkbox
                                  name="cumple"
                                  checked={
                                    checkboxStates[grupoItem.grupo.id_grupo]?.[
                                      item.id_item
                                    ] === "cumple" || false
                                  }
                                />
                              }
                            />
                            <FormControlLabel
                              labelPlacement="end"
                              label="No cumple"
                              control={
                                <Checkbox
                                  name="no_cumple"
                                  checked={
                                    checkboxStates[grupoItem.grupo.id_grupo]?.[
                                      item.id_item
                                    ] === "no_cumple" || false
                                  }
                                />
                              }
                            />
                            <FormControlLabel
                              labelPlacement="end"
                              label="N/A"
                              control={
                                <Checkbox
                                  name="n_a"
                                  checked={
                                    checkboxStates[grupoItem.grupo.id_grupo]?.[
                                      item.id_item
                                    ] === "n_a" || false
                                  }
                                />
                              }
                            />
                          </FormGroup>
                        </TableCell>
                        <TableCell>
                          {fechasVencimiento[item.id_item] ? (
                            <TextField
                              fullWidth
                              size={size}
                              color="primary"
                              name="fecha_vencimiento"
                              label="Fecha de Vencimiento"
                              variant="outlined"
                              value={moment(
                                fechasVencimiento[item.id_item]
                              ).format("DD-MM-YYYY")}
                              inputProps={{
                                readOnly: true, // Campo de solo lectura
                              }}
                            />
                          ) : (
                            " * No tiene fecha de vencimiento *"
                          )}

                          <TextField
                            fullWidth
                            size={size}
                            margin="normal"
                            style={{
                              ...style,
                              width: "100%",
                              marginRight: 300,
                            }}
                            focused
                            color="primary"
                            name="observacion"
                            label="Observaciones"
                            variant="outlined"
                            multiline
                            rows={5}
                            value={observaciones[item.id_item]} // Usar la observación correspondiente
                            inputProps={{
                              maxLength: 200,
                              readOnly: true, // Establecer el campo como de solo lectura
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Adjuntar archivo" arrow>
                            <IconButton
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                handleOpenCloseAdj(item.id_item, true)
                              }
                            >
                              <AttachFileIcon />
                            </IconButton>
                          </Tooltip>
                          {isAdjDialogOpen(item.id_item) && (
                            <>
                              {console.log(
                                "Mostrando adjunto para item:",
                                item.id_item,
                                "con UUID:",
                                getUuidForItem(item.id_item)
                              )}
                              <FileUploadHS
                                fileId={getUuidForItem(item.id_item)}
                                openclose={true}
                                mostrar={false}
                                onClose={() =>
                                  handleOpenCloseAdj(item.id_item, false)
                                }
                              />
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
          <Box
            sx={{
              textAlign: "center",
              marginTop: 5,
              minHeight: "2vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>
              <Typography
                variant="poster"
                fontSize={19}
                style={{ textDecoration: "underline", marginTop: 20 }}
              >
                Resultados :
              </Typography>
              <br />
              <br />
              <Typography variant="poster" fontSize={19}>
                Cumplidos : {cantidadChecks.cumple} - No cumplidos :{" "}
                {cantidadChecks.no_cumple}
              </Typography>
            </div>
          </Box>
          <br></br>
          <Divider style={{ width: "101%", marginTop: 20 }} />
          <br></br>
          <Grid
            container
            justifyContent="space-between"
            alignItems="flex-end"
            mt={2}
          >
            <Button variant="contained" onClick={handleClose} color="error">
              Volver
            </Button>
          </Grid>
        </Paper>
      ) : (
        <Paper
          elevation={6}
          style={{ width: "80%", padding: "20px", marginLeft: 70 }}
        >
          <Typography variant="body1" color="textSecondary" align="center">
            <strong>
              {" "}
              No hay ningún resultado de control de higiene y seguridad para la
              sucursal o fecha seleccionada.
            </strong>
          </Typography>
        </Paper>
      )}
      <br></br>
    </div>
  );
}

export default VerResultadoCheck;
