import React, { useEffect, useState, useMemo } from "react";
import { UrlApi } from "../../services/apirest";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Stack,
  Snackbar
} from "@mui/material";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";

// ICONOS
import BlockIcon from "@mui/icons-material/Block";

//Notification
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Romaneo_pertenencias_2(props) {
  const [romaneo_pertenencias, setRomaneo_pertenencias] = useState();
  const [datosRomaneoPertenencias, setDatosRomaneoPertenencias] = useState({});
  const [tabla, setTabla] = useState(true);
  const [update, setUpdate] = useState(false);
  const [row, setRow] = useState(props.row);

  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");

  //funcion para obtener a los romaneo leyendas, pasarlos a json y guardarlos en data

  const obtenerRomaneoPertenencias = () => {
    fetch(UrlApi + "/romaneo_pertenencias/firma/" + row.original.id_firma)
      .then((data) => data.json())
      .then((data) => setRomaneo_pertenencias(data));
  };
  console.log("trae las pertenencias", setRomaneo_pertenencias);

  //funcion que espera los cambios de la funcion de obtener a los romaneo leyendas
  useEffect(() => {
    obtenerRomaneoPertenencias();
  }, [tabla]);

  // FUNCION QUE SE EJECUTA CADA VEZ QUE HAY UN CAMBIO EN LA BASE DE DATOS
  // SIRVE PARA QUE LA TABLA MOSTRADA SE ACTUALICE EN TIEMPO REAL
  useEffect(() => {
    if (update) obtenerRomaneoPertenencias();
  }, [update]);

  //*************************************************************************************************************************************/

  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
    setUpdate(false);
    setOpenDialogOnOff(false);
  };

  //*************************************************************************************************************************************/
  const columns = useMemo(
    () => [{ header: "Leyenda", accessorKey: "leyenda", size: 700 }],
    []
  );

  const muiSearchTextFieldProps = {
    // para el buscar
    placeholder: "Buscar...",
    sx: { minWidth: "18rem" },
    variant: "outlined",
    color: "success",
    size: "small",
  };

  //**********************************************************************************************/

  // DIALOG PARA ACTIVAR/DESACTIVAR LA RELACIÓN
  const [openDialogOnOff, setOpenDialogOnOff] = React.useState(false);

  const handleCloseDialogOnOff = () => {
    setOpenDialogOnOff(!openDialogOnOff);
  };

  const dialogActivar = (row) => {
    setDatosRomaneoPertenencias(row);
    setOpenDialogOnOff(true);
    setTabla(true);
  };

  //**********************************************************************************************/

  // FUNCIONES PARA GUARDAR:
  // ACTIVAR TIPO DE ENVASE
  const guardarActivo = async (e) => {
    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(
      UrlApi +
        "/romaneo_pertenencias/" +
        datosRomaneoPertenencias.id_pertenencia,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          estado: 1,
        }),
      }
    )
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("La pertenencia se guardó exitosamente !");
          setUpdate(true);
          //console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar la pertenencia");
          //console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  // DESACTIVAR TIPO DE ENVASE
  const guardarInactivo = async (e) => {
    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(
      UrlApi +
        "/romaneo_pertenencias/" +
        datosRomaneoPertenencias.id_pertenencia,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          estado: 2,
        }),
      }
    )
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("La pertenencia se guardó exitosamente !");
          setUpdate(true);
          //console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar la pertenencia");
          //console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  //*************************************************************************************************************************************/

  // ESTILO PARA LA TABLA

  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)" //background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //override to make tooltip font size larger
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const displayColumnDefOptions = {
    "mrt-row-actions": {
      muiTableHeadCellProps: {
        align: "center",
        sx: {
          fontSize: 14,
          fontStyle: "italic",
        },
      },
    },
  };

  const initialState = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
  };

  //*********************************************************************************************************************************************/
  return (
    <>
      <Container maxWidth="xl">
        {romaneo_pertenencias && tabla ? (
          <Box>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
                enableGrouping // para permitir agrupar x estado x ej
                displayColumnDefOptions={displayColumnDefOptions}
                layoutMode="grid"
                // COLORES PARA LAS CELDAS SEGUN ACTIVO/INACTIVO
                muiTableHeadCellProps={{
                  align: "left", // los titulos de la tabla
                }}
                muiTableBodyCellProps={({ row }) => ({
                  align: "left", //el contenido de la tabla
                  sx: {
                    backgroundColor:
                      row.original.estado === 1 ? "#66FF66" : "#FF5050",
                    color: "black",
                    borderColor: "black",
                    //COLOR AL PASAR EL MOUSE POR ENCIMA
                    "&:hover": {
                      backgroundColor:
                        row.original.estado === 1 ? "#3ad63a" : "#f22929",
                    },
                    "& .MuiTableCell-root:first-child": {
                      border: "none",
                    },
                  },
                })}
                enableRowActions
                renderRowActions={({ row }) => (
                  <>
                    <Tooltip
                      arrow
                      placement="left"
                      title={
                        row.original.estado === 1
                          ? "Anular Pertenencia"
                          : "Activar Pertenencia"
                      }
                    >
                      <IconButton onClick={() => dialogActivar(row.original)}>
                        <BlockIcon
                          sx={{
                            color: row.original.estado === 1 ? "red" : "lightgreen",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
                enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
                enableColumnFilters={false} //desactiva filtros x  columna
                enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
                enableDensityToggle={false} //
                localization={MRT_Localization_ES} //idioma
                enableColumnDragging={false} //para arrastrar columnas
                initialState={initialState}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                columns={columns}
                data={romaneo_pertenencias} //con lo q la completas (esto cambia siempre)
                enableStickyFooter
                positionActionsColumn="last" // posicion de la columna de acciones al final
                // TITULO
                renderTopToolbarCustomActions={({ table }) => (
                  <Grid item mt={-2.5}>
                    <h2>Fabricante: {row.original.firma} </h2>
                  </Grid>
                )}
              />
            </ThemeProvider>
          </Box>
        ) : null}
      </Container>

      {/* DIALOG PARA BLOQUEAR/DESBLOQUEAR LA RELACION */}
      <Dialog open={openDialogOnOff} onClose={handleCloseDialogOnOff}>
        <Box
          style={{
            borderWidth: 2,
            borderStyle: "solid",
            borderColor:
              datosRomaneoPertenencias.estado === 1 ? "#e32712" : "#24ff1c",
            color: datosRomaneoPertenencias.estado === 1 ? "red" : "green",
          }}
        >
          <DialogTitle>
            <Typography align="center">
              <b>
                SE{" "}
                {datosRomaneoPertenencias.estado === 1
                  ? "BLOQUEARÁ"
                  : "DESBLOQUEARÁ"}{" "}
                LA PERTENENCIA:
              </b>
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              textAlign={"center"}
              style={{ marginBottom: "40px" }}
            >
              <Grid item textAlign={"center"} ml={2}>
                <br />
                <b>{datosRomaneoPertenencias.leyenda}</b>
              </Grid>
            </DialogContentText>
            <DialogContentText
              textAlign={"center"}
              style={{ marginBottom: "-10px" }}
            >
              {"¿Confirma que quiere " +
                (datosRomaneoPertenencias.estado === 1
                  ? "bloquear"
                  : "desbloquear") +
                " esta pertenencia?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Stack spacing={4} direction="row">
              <Grid item mr={16}>
                <Button
                  style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
                  variant="contained"
                  onClick={handleCloseDialogOnOff}
                >
                  Cancelar
                </Button>
              </Grid>
              {datosRomaneoPertenencias.estado === 1 ? (
                <Button
                  style={{ backgroundColor: "#fc7a23", color: "#ffffff" }}
                  onClick={guardarInactivo}
                  variant="contained"
                >
                  Bloquear
                </Button>
              ) : (
                <Button
                  style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                  onClick={guardarActivo}
                  variant="contained"
                >
                  Desbloquear
                </Button>
              )}
            </Stack>
          </DialogActions>
        </Box>
      </Dialog>

        {/* SNACKBAR QUE SE MOSTRARA AL GUARDAR LOS CAMBIOS */}
        <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}

export default Romaneo_pertenencias_2;
