//----- Component --------------------------------------------------------/

// Developer : Nicolas Pascucci

// Creation Date : 21/05/2024

// Version : 1

// Description : tabla de subitems por item 
//

//------Changes --------------------------------------------------------------------- -------------//

// Change Date :

// Change by :

// Change description:

//

// Change Date :

// Change by :

// Change description:

//

//========================================================================/
import React, { useState, useEffect, useMemo } from "react";
import { Container, IconButton, TextField, Tooltip, createTheme, useTheme } from "@mui/material";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import { Box, Typography, Grid } from "@mui/material";
import { ThemeProvider } from "@mui/material";
// Boton desplegable
import Button from "@mui/material/Button";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import EditIcon from "@mui/icons-material/Edit";
import "dayjs/locale/es";
import { UrlApi } from "../../../services/apirest";
import Navbar from "../../Navbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { grey } from "@mui/material/colors";
import AddSubitemEvaluacion from "./AddSubitemEvaluacion";
import EditarSubitemEvaluacion from "./EditarSubitemEvaluacion";
import { logEventos } from "../../../services/services";


function Subitems_comerciales(props) {
    const [datos, setDatos] = useState(props.datos)
    const [subitems, setSubitems] = useState({})
    const [alta, setAlta] = useState(false); //Estado q mostrará o no la pantalla de alta
    const [tabla, setTabla] = useState(true);
    const [editar, setEditar] = useState(false);
    const [datosRow, setDatosRow] = useState({})
    const [obt, setObt] = useState(0);

    const obtenerSubitems = () => {
      fetch(UrlApi + "/subitems_comerciales/xiditem/"+datos.nro_item)
        .then((data) => data.json())
        .then((data) => setSubitems(data));
    };
      
    useEffect(() => {
        obtenerSubitems();
      }, [tabla, obt]);

    // console.log("subitems",subitems);

    const handleClose = () => {
        return props.verSubitems(false), props.tabla(true), props.actualizar(1);
    };
    
    const handleClickNuevo = (row) => {
        setAlta(true);
        setTabla(false);
      };

    const handleClickEditar = (row) => {
      setDatosRow(row)
      setEditar(true);
      setTabla(false);
    };
// *********************************************************** Tabla ********************************************************************** //
const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "primary",
    size: "small",
  };
  
  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };

  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem",
              },
            },
          },
        },
      }),
    [globalTheme]);

  const initialState = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
    columnVisibility: {
        id_item:false,
        activo:false,
        id_subitem:false,
        relacion_item:false
    }
  };

  const columns = useMemo(
    // TABLA Objetivos
    () => [
      { header: "id_subitem", accessorKey: "id_subitem", size: 70, enableHiding: true },
      {
        header: "Descripcion",
        accessorKey: "descripcion",
        size: 160,
      },
      { header: "desde %", accessorKey: "desde", size: 70 },
      { header: "hasta %", accessorKey: "hasta", size: 70 },
      { header: "Adicional %", accessorKey: "porcentaje", size: 70 },
    ],
    []
  );

  useEffect(() => {
    logEventos("Tabla de subitems de evaluacion", "Evaluacion Comerciales", "Se visualizan los subitems en evaluacion", localStorage.getItem("user"))
}, [])

  return (
    <div>
           <br></br>
        <Container>
        {subitems && tabla?(
            <>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container backgroundColor="#1F618D" p={1.5}>
              <Grid item xs={2}>
                <IconButton onClick={handleClose}>
                  <ArrowBackIcon sx={{ color: grey[50] }} />
                </IconButton>
              </Grid>

              <Grid
                item
                xs={8}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="h5" color="#ffffff">
                  Parametros del item "{datos.descripcion}"
                </Typography>
              </Grid>
            </Grid>
          </Box>
      <Box mt={2} mb={4}>
        <ThemeProvider theme={tableTheme}>
          <MaterialReactTable
            enableExpandAll={false}
            enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
            enableGrouping // para permitir agrupar x estado x ej
            layoutMode="grid"
            muiTableHeadCellProps={tableHeadCellProps}
            muiTableBodyCellProps={({ row }) => ({
              align: "left",
              sx: {
                color: "black",
                borderColor: "black",
              },
            })}
            enableEditing
            showGlobalFilter={false}
            enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
            enableColumnFilters={false} //desactiva filtros x  columna
            enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
            enableDensityToggle={false} //
            localization={MRT_Localization_ES} //idioma
            enableColumnDragging={false} //para arrastrar columnas
            initialState={initialState}
            muiSearchTextFieldProps={muiSearchTextFieldProps}
            columns={columns}
            data={subitems} //con lo q la completas (esto cambia siempre)
            enableStickyFooter
            // enableEditing
            positionActionsColumn="last" // posicion de la columna de acciones al final
            // ACCIONES
            renderToolbarInternalActions={(
              { table } // boton para nuevo , al lado del buscar
            ) => (
              <>
                <MRT_FullScreenToggleButton table={table} />
              </>
            )}
            renderTopToolbarCustomActions={() => (
              <>
                {/* <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddBoxTwoToneIcon />}
                  onClick={handleClickNuevo}
                >
                  Nuevo Parametro
                </Button> */}
              </>
            )}
            renderRowActions={({ row, table }) => (
              //las acciones del costado editar y ver
                <Tooltip arrow placement="top" title="Editar Parametro">
                  <IconButton
                    onClick={() =>
                      handleClickEditar(row.original)
                    }
                    color="primary"
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
            )}
          />
        </ThemeProvider>
      </Box>
      </>
      ):null}
      {alta ? <AddSubitemEvaluacion alta={setAlta} tabla={setTabla} datosItem={datos}></AddSubitemEvaluacion> : null}
      {editar ? <EditarSubitemEvaluacion editar={setEditar} tabla={setTabla} datos={datosRow} actualizar={setObt}></EditarSubitemEvaluacion> : null}
      </Container>
    </div>
  )
}

export default Subitems_comerciales