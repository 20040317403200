/**** Produccion */

export const AuthApi = "https://prodauthapi.netizar.com/api/";
export const UrlApi = "https://prodapi.netizar.com/api";
export const MailApi = "https://uservices.netizar.com";
export const SmsApi = "https://sms.netizar.com/api";
export const titulonavbar = "Producción"
export const UrlApp = "https://prod.netizar.com/"
export const UrlApiEnvasado = "http://localhost:9000/api";
export const UrlFacturacion = "http://192.168.34.102:8120"  /// 8120 es el puerto para produccion 

