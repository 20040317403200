import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  MenuItem,
  Select,
  TextField,
  Slide,
  InputLabel,
  Container,
  Stack,
  Box,
  Button,
  FormControl,
  Tab,
  Tabs,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { getData } from "../../../services/library";

function FormEditCondEsp({ proveedor, almacenarChecks, onChange }) {
  const [usuarios, setusuarios] = useState([]);

  //const classes = useStyles();
  useEffect(() => {
    getData("/userdata/proveedores/usuarioapp", setusuarios);
  }, []);

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
    borderRadius: "5px",
    borderBlock: "2px solid",
    borderColor: "#0766AD",
    color: "#000",
  };

  console.log("proveedor", proveedor);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <FormControlLabel
            style={style}
            control={
              <Checkbox
                name="factura_sin_orden_compra"
                checked={proveedor.factura_sin_orden_compra}
                value={proveedor.factura_sin_orden_compra}
                onClick={almacenarChecks}
                color="primary"
              />
            }
            label="Factura sin Orden de Compra"
          />
        </Grid>

        <Grid item xs={3}>
          <FormControlLabel
            style={style}
            control={
              <Checkbox
                name="es_proveedor_de_gas"
                checked={proveedor.es_proveedor_de_gas}
                value={proveedor.es_proveedor_de_gas}
                onClick={almacenarChecks}
                color="primary"
              />
            }
            label="Proveedor de Gas"
          />
        </Grid>

        <Grid item xs={3}>
          <FormControlLabel
            //className={classes.customFormControlLabel}
            style={style}
            value="start"
            control={
              <Checkbox
                name="es_instalador"
                checked={proveedor.es_instalador}
                value={proveedor.es_instalador}
                onClick={almacenarChecks}
                color="primary"
              />
            }
            label="Es Instalador"
          />
        </Grid>

        {proveedor.es_instalador === 1 || proveedor.es_instalador === true ? (
          <Grid item xs={3}>
            <FormControl fullWidth focused>
              <InputLabel>Usuarios</InputLabel>
              <Select
                name="usuarioapp"
                value={proveedor?.usuarioapp ?? console.log("No hay usuario")}
                onChange={onChange}
                fullWidth
                label="Usuarios"
              >
                {usuarios
                  ? usuarios.map((elemento) => (
                      <MenuItem key={elemento.id} value={elemento.username}>
                        {elemento.username}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </FormControl>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
}

export default FormEditCondEsp;
