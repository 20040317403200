import React, { useMemo, useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import moment from "moment";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Title from "../../TitleUploaded";
import MaterialReactTable from "material-react-table";
import DeleteIcon from "@mui/icons-material/Delete";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import {
  createTheme,
  ThemeProvider,
  useTheme,
  IconButton,
} from "@mui/material";
import { putData, getData } from "../../../services/library";
import DeleteDialog from "../../Dialogs/DeleteDialog";

function ListaPedidosPorEmpleado(props) {
  const [pedidos, setPedidos] = useState([{}]);
  const [dataPedido, setDataPedido] = useState();
  const [showDel, setShowDel] = useState(false);
  const [notify, setNotify] = useState({
    open: false,
    severity: "",
    color: "",
    mensaje: "",
  });
  const esAdmin = localStorage.getItem("adminrrhh") === "1";

  const handleClose = () => {
    props.setOpen(false);
    props.setShowForm(true);
    props.setReqData({
      usuario: "",
      fecha_inicio: "",
      fecha_fin: "",
    });
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "fecha", //access nested data with dot notation
        header: "Fecha",
      },
      {
        accessorKey: "opcion_seleccionada", //access nested data with dot notation
        header: "Viandas Pedidas",
      },
      {
        accessorKey: "guarnicion", //access nested data with dot notation
        header: "Guarnición",
      },
      {
        accessorKey: "observaciones", //access nested data with dot notation
        header: "Observaciones",
      },
    ],
    []
  );

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "primary",
    size: "small",
  };

  const initialState = {
    density: "compact",
    showGlobalFilter: true,
    pagination: {
      pageSize: 10,
      pageIndex: 0,
    },
  };

  const tableHeadCellProps = {
    align: "center",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "#B2BABB",
      "& .Mui-TableHeadCell-Content": { justifyContent: "space-between" },
    },
  };

  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem",
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const displayColumnDefOptions = {
    "mrt-row-actions": {
      muiTableHeadCellProps: {
        sx: {
          borderColor: "#B2BABB",

          fontSize: 16,
          fontWeight: "normal",
          color: "#fff",
          backgroundColor: "#2471A3",
          alignItems: "center",
        },
      },
      size: 1,
    },
  };

  const guardar = (e) => {
    putData(
      e,
      "/viandas/viandas_pedidos/" + dataPedido.id_viandaspedido,
      { opcion: 0 },
      setNotify
    );
  };

  const getDatos = () => {
    getData(
      `/viandas/resumen/xempleado/${props.reqData.usuario}/${props.reqData.fecha_inicio}/${props.reqData.fecha_fin}`,
      setPedidos
    );
  };

  useEffect(() => {
    getDatos();
  }, []);

  useEffect(() => {
    getDatos();
  }, [showDel]);

  console.log(pedidos);

  return (
    <div>
      <Container maxWidth="xl" sx={{ my: 2 }}>
        <Title
          title={`Pedidos Viandas:  ${props.empleado} `}
          handleClose={handleClose}
        />
        {pedidos && (
          <Box sx={{ my: 1 }}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableRowActions
                renderRowActions={({ row }) => (
                  <Box>
                    <IconButton
                      disabled={!esAdmin}
                      onClick={() => {
                        setDataPedido(row.original);
                        setShowDel(true);
                      }}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                )}
                positionActionsColumn="last"
                enableColumnFilterModes={false}
                enableColumnFilters={false}
                enableFullScreenToggle={false}
                enableColumnVirtualization={false}
                enableHiding={false}
                enableColumnResizing={false}
                columns={columns}
                data={pedidos}
                initialState={initialState}
                muiTableHeadCellProps={tableHeadCellProps}
                localization={MRT_Localization_ES}
                displayColumnDefOptions={displayColumnDefOptions}
                muiTableBodyCellProps={({ row }) => ({
                  align: "left",
                  sx: {
                    backgroundColor: "#ebebeb",
                    color: "black",
                    borderColor: "#B2BABB",
                  },
                })}
                enableColumnActions={false}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                enableDensityToggle={false}
                layoutMode="grid"
                renderTopToolbarCustomActions={() => (
                  <>
                    <Typography
                      variant="subtitle1"
                      style={{ fontSize: 18 }}
                      color="initial"
                      mt={1}
                    >
                      Período:{" "}
                      {moment(props.reqData.fecha_inicio).format("DD-MM-YYYY")}{" "}
                      a {moment(props.reqData.fecha_fin).format("DD-MM-YYYY")}{" "}
                    </Typography>
                  </>
                )}
              />
            </ThemeProvider>
          </Box>
        )}
        <DeleteDialog
          btnText={"Eliminar"}
          open={showDel}
          setOpen={setShowDel}
          notify={notify}
          setNotify={setNotify}
          guardar={guardar}
          actualizar={getDatos}
        />
      </Container>
    </div>
  );
}

export default ListaPedidosPorEmpleado;
