import React, { useRef } from "react";
import { useEffect, useState } from "react";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Autocomplete,
  createFilterOptions,
  Tooltip,
  IconButton,
  styled,
  FormHelperText,
  Grid,
  Stack,
  Snackbar,
  Alert,
  useForkRef,
} from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Notify from "../../Notify";
import {
  Delete,
  AddCircleOutline,
  DisabledByDefaultRounded,
} from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import { getData, postData, putData } from "../../../services/library";
import { UrlApi } from "../../../services/apirest";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { useStaticPicker } from "@mui/x-date-pickers/internals";
import { enviarmails } from "../../../services/services";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import InputMask from "react-input-mask";

function AsignarAVehiculo(props) {
  //Notification
  const [errorSeguimiento, setErrorSeguimiento] = useState(false);
  const [mensajeSeguimiento, setMensajeSeguimiento] = useState("");
  const [mostrarCambio, setMostrarCambio] = useState(false);
  const [mismoVehiculo, setMismoVehiculo] = useState(false);
  const [articuloEspecifico, setArticuloEspecifico] = useState();
  const [articuloEncontrado, setArticuloEncontrado] = useState(null); // Guarda el artículo encontrado.
  const [cantidadNoUnitaria, setCantidadNoUnitaria] = useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [openSnackEditar, setOpenSnackEditar] = React.useState(false);
  const [disabledGuardarTr, setDisabledGuardarTr] = useState(false);
  const [colorTextField, setcolorTextField] = useState();
  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };
  const [notificacion, setNotificacion] = useState({
    open: false,
    mensaje: "",
    severity: "",
    color: "",
  });
  const [uuid, setUuid] = useState(uuidv4());
  const [datos, setDatos] = useState({
    vehiculo: null,
    articulo: null,
    cantidad: 1,
    fecha: moment(new Date()).format("YYYY-MM-DD"),
    detalle: "",
    identificador: "",
  });
  const [uuidDoc, setUuidDoc] = useState(uuidv4());
  const [artSuc, setArtSuc] = useState(props.artSuc);
  const [deshabilitado, setDeshabilitado] = useState(false);
  const [articuloSeleccionado, setArticuloSeleccionado] = useState({});
  const [articulos, setArticulos] = useState();
  const [almacenes, setAlmacenes] = useState("");
  const [buscandoAlmacen, setBuscandoAlmacen] = useState(false);
  const [buscandoSucursales, setBuscandoSucursales] = useState(false);
  const [almacenSeleccionado, setAlmacenSeleccionado] = useState({});
  const [sucursales, setSucursales] = useState();
  const [sucursalSeleccionada, setSucursalSeleccionada] = useState({});
  const [inventario, setInventario] = useState([]);
  const [inventarioAlmacen, setInventarioAlmacen] = useState([]);

  //  sumar cantidad
  const [cantidadInventario, setCantidadInventario] = useState(0);
  useEffect(() => {
    setCantidadInventario(
      inventario[0]
        ? parseInt(inventario[0].cantidad) + parseInt(datos.cantidad)
        : null
    );
  }, [inventario, datos.cantidad]);

  const [cantidadAlmacen, setCantidadAlmacen] = useState(0);
  useEffect(() => {
    setCantidadAlmacen(
      inventarioAlmacen[0]
        ? parseInt(inventarioAlmacen[0].cantidad) - parseInt(datos.cantidad)
        : "null"
    );
  }, [inventarioAlmacen, datos.cantidad]);
  // console.log(
  //   "guarda",
  //   "vehiculo:",
  //   props.vehiculo,
  //   "articulo:",
  //   articuloSeleccionado.id_articulo,
  //   "cantidad:",
  //   datos.cantidad,
  //   "tipo:",
  //   1,
  //   "fecha:",
  //   datos.fecha,
  //   "detalle",
  //   datos.detalle,
  //   "fuente",
  //   props.fuente
  // );
  // console.log("inventario", inventario);
  // console.log("cantidad inventario", cantidadInventario);
  // console.log("Inventario almacen", inventarioAlmacen);
  // console.log(
  //   "cantidad almacen",
  //   inventarioAlmacen[0] ? parseInt(inventarioAlmacen[0].cantidad) : null,
  //   "-",
  //   parseInt(datos.cantidad),
  //   "=",
  //   cantidadAlmacen
  // );
  // console.log("articuloSeleccionado", articuloSeleccionado);
  // console.log("datos", datos);

  // ******************** REQUERIMIENTOS Y MAIL ******************** //
  const [usuarioCompras, setUsuarioCompras] = useState([]);

  const obtenerUsuarioCompras = () => {
    fetch(UrlApi + "/userdata/sector/4/")
      .then((data) => data.json())
      .then((data) => setUsuarioCompras(data));
  };

  useEffect(() => {
    obtenerUsuarioCompras();
  }, []);

  const [reqExiste, setReqExiste] = useState([]);

  const obtenerRequerimientoExistente = () => {
    fetch(
      UrlApi +
        "/requerimientos_materiales/reqexiste/" +
        sucursalSeleccionada.id +
        "/" +
        articuloSeleccionado.id_articulo
    )
      .then((data) => data.json())
      .then((data) => setReqExiste(data));
  };

  useEffect(() => {
    obtenerRequerimientoExistente();
  }, [sucursalSeleccionada, articuloSeleccionado]);
  console.log(articuloSeleccionado);

  // const envioMail = async (e) => {
  //   usuarioCompras &&
  //     usuarioCompras.length > 0 &&
  //     usuarioCompras.forEach((usuario) => {
  //       if (usuario.email) {
  //         if (
  //           cantidadAlmacen < articuloSeleccionado.alerta_minima &&
  //           cantidadAlmacen > articuloSeleccionado.valor_minimo
  //         ) {
  //           //  console.log("EMAIL de CONSUMO", usuario.email);
  //           if (articuloSeleccionado.enviamail === 1) {
  //             enviarmails(
  //               usuario.email,
  //               "Advertencia de Nivel de Stock ",
  //               `<p>El artículo <b>${articuloSeleccionado.nombre}</b> del almacén <b>${almacenSeleccionado.nombre}</b> esta por debajo del punto de pedido.<br/></p>`
  //             );
  //           }
  //         } else if (cantidadAlmacen < articuloSeleccionado.valor_minimo) {
  //           if (articuloSeleccionado.enviamail === 1) {
  //           enviarmails(
  //             usuario.email,
  //             "Alerta de Stock por Debajo del Nivel Mínimo",
  //             `<p>El artículo <b>${articuloSeleccionado.nombre}</b> del almacén <b>${almacenSeleccionado.nombre}</b> esta por debajo del stock mínimo.<br/></p>`
  //           );
  //           }
  //         }
  //       }
  //     });
  // };

  const [envioRequerimiento, setEnvioRequerimiento] = useState({
    id_movil: null,
    id_sucursal: sucursalSeleccionada.id,
    fecha_entrega: moment(datos.fecha).format("YYYY-MM-DD HH:mm:ss"),
    usuario_registra: localStorage.getItem("user"),
    usuario_autoriza: null,
    estado: 50, //cuando se carga el pedido se pone en estado "A autorizar"
    para_comprar: 1,
    observaciones: "",
    uuid_requerimiento: uuid,
    comentarios_internos: "Pedido Automático - Definir Cantidad",
    para_compras: 0,
    para_rrhh: 0,
    autorizado_jefe_compras: 0,
  });

  const [datosLista, setDatosLista] = useState([
    {
      material: null,
      usuario: localStorage.getItem("user"),
      cantidad: 1,
      // id_unidad_medida: datosArt.id_unidad_medida,
      uuid_requerimiento: envioRequerimiento.uuid_requerimiento,
      id_articulo: articuloSeleccionado.id_articulo,
    },
  ]);

  useEffect(() => {
    setDatosLista([
      {
        material: null,
        usuario: localStorage.getItem("user"),
        cantidad: 1,
        // id_unidad_medida: datosArt.id_unidad_medida,
        uuid_requerimiento: envioRequerimiento.uuid_requerimiento,
        id_articulo: articuloSeleccionado.id_articulo,
      },
    ]);
    setEnvioRequerimiento({
      id_movil: null,
      id_sucursal: sucursalSeleccionada.id,
      fecha_entrega: moment(datos.fecha).format("YYYY-MM-DD HH:mm:ss"),
      usuario_registra: localStorage.getItem("user"),
      usuario_autoriza: null,
      estado: 50, //cuando se carga el pedido se pone en estado "A autorizar"
      para_comprar: 1,
      observaciones: "",
      uuid_requerimiento: uuid,
      comentarios_internos: "Pedido Automático - Definir Cantidad",
      para_compras: 0,
      para_rrhh: 0,
      autorizado_jefe_compras: 0,
    });
  }, [datos, sucursalSeleccionada, articuloSeleccionado]);

  const guardarListaMateriales = (e) => {
    postData(
      e,
      "/lista_materiales",
      {
        datosLista,
      },
      setNotificacion
    );
  };
  // *************************************************************** //

  const guardarCrear = async (e) => {
    e.preventDefault();
    await fetch(UrlApi + "/stock_vehiculo_historial", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        vehiculo: props.vehiculo,
        articulo: articuloSeleccionado.id_articulo,
        cantidad: datos.cantidad,
        tipo: 1,
        fecha: datos.fecha,
        almacen_origen: almacenSeleccionado.id_almacen,
        usuario: localStorage.getItem("user"),
        detalle: datos.detalle,
        identificador: datos.identificador,
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El artículo se guardó exitosamente !");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el artículo");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });

    // ------ { AGREGAR SEGUNDO GUARDAR (?) } ------
    e.preventDefault();
    if (inventario.length === 0) {
      await fetch(UrlApi + "/stock_vehiculos", {
        // PUT SI YA EXISTE / POST SI ES NUEVO
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          vehiculo: props.vehiculo,
          articulo: articuloSeleccionado.id_articulo,
          cantidad: datos.cantidad,
          usuario: localStorage.getItem("user"),
        }),
      })
        .then((res) => {
          if (!res.err) {
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setMensaje("El artículo se asignó exitosamente !");
          } else {
            setOpenSnack(false);
            setColorMensaje("#F1948A");
            setMensaje("Error al asignar el artículo");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
      // handleClickOpenCrear();
    } else {
      await fetch(UrlApi + "/stock_vehiculos/" + inventario[0].id, {
        // PUT SI YA EXISTE / POST SI ES NUEVO
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          cantidad: cantidadInventario,
          usuario: localStorage.getItem("user"),
        }),
      })
        .then((res) => {
          if (!res.err) {
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setMensaje("El artículo se asignó exitosamente !");
          } else {
            setOpenSnack(false);
            setColorMensaje("#F1948A");
            setMensaje("Error al asignar el artículo");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }

    //********* guardar transaccion e inventario en el almacen (salida) *********//
    await fetch(UrlApi + "/stock_transacciones", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_accion: 159,
        id_articulo: articuloSeleccionado.id_articulo,
        almacen_origen: almacenSeleccionado.id_almacen,
        cantidad_enviada: datos.cantidad,
        // detalle_transaccion: datosArt.detalle_transaccion,
        // nro_remito_puntodeventa: datosArt.nro_remito_puntodeventa,
        // nro_remito_numero: datosArt.nro_remito_numero,
        fecha_transaccion: moment(datos.fecha).format("YYYY-MM-DD HH:mm:ss"),
        id_motivo: 10,
        uuid_documentacion: uuidDoc, //ADJUNTAR IMAGEN
        usuario: localStorage.getItem("user"),
      }),
    })
      .then((res) => {
        if (!res.err) {
          // setOpenSnack(true);
          // setColorMensaje("#D4EFDF");
          // setMensaje("La salida se guardó exitosamente !");
        } else {
          // setOpenSnack(false);
          // setColorMensaje("#F1948A");
          // setMensaje("Error al guardar la salida");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    await fetch(
      UrlApi + "/stock_inventarios/" + inventarioAlmacen[0].id_inventario,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          cantidad: cantidadAlmacen,
          usuario: localStorage.getItem("user"),
        }),
      }
    )
      .then((res) => {
        if (!res.err) {
          // setOpenSnack(true);
          // setColorMensaje("#D4EFDF");
          // setMensaje("La salida se guardó exitosamente !");
        } else {
          // setOpenSnack(false);
          // setColorMensaje("#F1948A");
          // setMensaje("Error al guardar la salida");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    if (
      cantidadAlmacen < articuloSeleccionado.alerta_minima &&
      cantidadAlmacen >= articuloSeleccionado.valor_minimo
    ) {
      //  console.log("ADVERTENCIA AMARILLA");
      if (
        articuloSeleccionado.pedido_automatico === 1 &&
        reqExiste.length === 0
      ) {
        //    console.log("ES AUTOMATICO AMARILLO");
        postData(
          e,
          "/requerimientos_materiales",
          {
            id_sucursal: envioRequerimiento.id_sucursal,
            id_movil: envioRequerimiento.id_movil,
            estado: 50, //cuando se carga el pedido se pone en estado "Pendiente autorizacion"
            para_comprar: envioRequerimiento.para_comprar,
            para_compras: envioRequerimiento.para_compras,
            para_rrhh: envioRequerimiento.para_rrhh,
            fecha_entrega: envioRequerimiento.fecha_entrega,
            usuario_registra: envioRequerimiento.usuario_registra,
            usuario_autoriza: envioRequerimiento.username,
            observaciones: envioRequerimiento.observaciones,
            uuid_requerimiento: uuid,
            comentarios_internos: envioRequerimiento.comentarios_internos,
            autorizado_jefe_compras: 0,
          },
          setNotificacion,
          guardarListaMateriales(e)
        )
          .then((response) => {
            console.log("Respuesta de postData en guardarCabecera:", response);
          })
          .catch((error) => {
            console.error("Error en postData en guardarCabecera:", error);
          });
      }
    } else if (cantidadAlmacen < articuloSeleccionado.valor_minimo) {
      //   console.log("ADVERTENCIA ROJA");
      if (
        articuloSeleccionado.pedido_automatico === 1 &&
        reqExiste.length === 0
      ) {
        //    console.log("ES AUTOMATICO ROJO");
        postData(
          e,
          "/requerimientos_materiales",
          {
            id_sucursal: envioRequerimiento.id_sucursal,
            id_movil: envioRequerimiento.id_movil,
            estado: 50, //cuando se carga el pedido se pone en estado "Pendiente autorizacion"
            para_comprar: envioRequerimiento.para_comprar,
            para_compras: envioRequerimiento.para_compras,
            para_rrhh: envioRequerimiento.para_rrhh,
            fecha_entrega: envioRequerimiento.fecha_entrega,
            usuario_registra: envioRequerimiento.usuario_registra,
            usuario_autoriza: envioRequerimiento.username,
            observaciones: envioRequerimiento.observaciones,
            uuid_requerimiento: uuid,
            comentarios_internos: envioRequerimiento.comentarios_internos,
            autorizado_jefe_compras: 0,
          },
          setNotificacion,
          guardarListaMateriales(e)
        )
          .then((response) => {
            console.log("Respuesta de postData en guardarCabecera:", response);
          })
          .catch((error) => {
            console.error("Error en postData en guardarCabecera:", error);
          });
      }
    }
    // envioMail();
    //**************************** SEGUIMIENTO DE ARTICULO ************************//
    if (cantidadNoUnitaria === false) {
      // console.log("la cantidad es 1");
      if (mismoVehiculo === true) {
        // console.log("deshabilitar pq no se puede ingresar en el mismo vehiculo");
        setDeshabilitado(true);
      } else if (mismoVehiculo === false) {
        // console.log("no es el mismo vehiculo");
        setDeshabilitado(false);
        if (mostrarCambio === true) {
          // console.log("tiene que sacarlo de otro vehiculo, pq ya existe y esta  en otro vehiculo");
          // setDeshabilitado(true)
        } else if (mostrarCambio === false) {
          if (datos.identificador === "") {
            // console.log("no hay identificador, no hago nada");
          } else if (datos.identificador !== "") {
            // console.log("post para crear el articulo");
            postData(
              e,
              "/stock_seguimiento",
              {
                identificador: datos.identificador,
                articulo: articuloSeleccionado.id_articulo,
                vehiculo: props.vehiculo,
                usuario: localStorage.getItem("user"),
              }
              // setNotificacion
            );
          }
        }
      }
    } else {
      // console.log("la cantidad NO es 1, se bloquea seguimiento");
    }

    //***************************************************************************//
    // // PARA LIMPIAR LOS DATOS
    // handleClose();
    setArticuloSeleccionado({});
    setAlmacenSeleccionado({});
    setSucursalSeleccionada({});
    setDatos({
      vehiculo: null,
      articulo: null,
      cantidad: 1,
      fecha: moment(new Date()).format("YYYY-MM-DD"),
      detalle: "",
      identificador: "",
    });
  };

  const almacenar = (e) => {
    setDatos({
      ...datos,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    // getData("/stock_articulos/articulosxsector/3", setArticulos);
    getData("/stock_articulos/", setArticulos);
  }, []);

  useEffect(() => {
    setBuscandoSucursales(true);
    getData(
      "/sucursales/xinventario/" +
        articuloSeleccionado.id_articulo +
        "/" +
        localStorage.getItem("suc_auth").split(","),
      setSucursales,
      setBuscandoSucursales(false)
    );
  }, [articuloSeleccionado]);

  useEffect(() => {
    setBuscandoAlmacen(true);
    getData(
      "/stock_almacenes/xsucursalarticulo/" +
        sucursalSeleccionada.id +
        "/" +
        articuloSeleccionado.id_articulo,
      setAlmacenes,
      setBuscandoAlmacen(false)
    );
  }, [sucursalSeleccionada, articuloSeleccionado]);

  useEffect(() => {
    getData(
      "/stock_vehiculos/artyvehic/" +
        articuloSeleccionado.id_articulo +
        "/" +
        props.vehiculo,
      setInventario
    );
  }, [articuloSeleccionado]);

  //funcion para obtener sucursales
  const obtenerInventario = () => {
    fetch(
      UrlApi +
        "/stock_inventarios/xalmart/" +
        almacenSeleccionado.id_almacen +
        "/" +
        articuloSeleccionado.id_articulo
    )
      .then((data) => data.json())
      .then((data) => setInventarioAlmacen(data));
  };

  useEffect(() => {
    obtenerInventario();
  }, [almacenSeleccionado.id_almacen, articuloSeleccionado.id_articulo]);

  useEffect(() => {
    if (
      Object.keys(articuloSeleccionado).length === 0 ||
      Object.keys(sucursalSeleccionada).length === 0 ||
      Object.keys(almacenSeleccionado).length === 0 ||
      isNaN(parseInt(datos.cantidad)) ||
      cantidadAlmacen < 0 ||
      mismoVehiculo === true ||
      mostrarCambio === true
    ) {
      setDeshabilitado(true);
    } else {
      setDeshabilitado(false);
    }
  }, [
    articuloSeleccionado,
    sucursalSeleccionada,
    almacenSeleccionado,
    datos.cantidad,
    cantidadAlmacen,
    mismoVehiculo,
    mostrarCambio,
  ]);

  const handleClose = () => {
    props.onClose(false);
  };
  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  useEffect(() => {
    setAlmacenSeleccionado({});
  }, [sucursalSeleccionada]);
  useEffect(() => {
    setSucursalSeleccionada({});
    setAlmacenSeleccionado({});
  }, [articuloSeleccionado]);
  // console.log("buscandoSucursales", buscandoSucursales);
  // console.log("buscandoAlmacen", buscandoAlmacen);

  // SEGUIMIENTO DE ARTICULO ESPECIFICO
  useEffect(() => {
    getData(
      "/stock_seguimiento/xarticulo/" + articuloSeleccionado.id_articulo,
      setArticuloEspecifico
    );
  }, [articuloSeleccionado]);

  useEffect(() => {
    // Segundo useEffect: Busca el artículo específico cuando cambie datos.identificador.
    if (datos.identificador && articuloEspecifico.length > 0) {
      const encontrado = articuloEspecifico.find(
        (articulo) => articulo.identificador === datos.identificador
      );

      if (encontrado) {
        // console.log("Elemento encontrado:", encontrado);
        setArticuloEncontrado(encontrado); // Actualiza el estado con el artículo encontrado.
      } else {
        // console.log("No se encontró el elemento con el identificador:", datos.identificador);
        setArticuloEncontrado(null);
      }
    }
  }, [datos.identificador, articuloEspecifico]);
  // console.log("articulo", articuloEspecifico, articuloEncontrado);

  useEffect(() => {
    if (articuloEncontrado) {
      if (articuloEncontrado.vehiculo === props.vehiculo) {
        setErrorSeguimiento(true);
        setMensajeSeguimiento(
          "Este artículo ya esta ingresado en este vehículo"
        );
        setMostrarCambio(false);
        setMismoVehiculo(true);
      } else {
        setErrorSeguimiento(true);
        setMensajeSeguimiento(
          `Este artículo esta ingresado en el vehiculo ${articuloEncontrado.patente}`
        );
        setMostrarCambio(true);
        setMismoVehiculo(false);
      }
    } else {
      setErrorSeguimiento(false);
      setMensajeSeguimiento("");
      setMismoVehiculo(false);
      setMostrarCambio(false);
    }
  }, [datos.identificador, articuloEncontrado, articuloSeleccionado]);

  // console.log("vehiculo", articuloEspecifico && articuloEspecifico[0]?articuloEspecifico[0].vehiculo:null, props.vehiculo);
  useEffect(() => {
    if (datos.cantidad === 1 || datos.cantidad === "1") {
      setCantidadNoUnitaria(false);
    } else {
      setCantidadNoUnitaria(true);
    }
  }, [datos.cantidad]);

  useEffect(() => {
    if (cantidadNoUnitaria === false) {
      // console.log("la cantidad es 1");
      if (mismoVehiculo === true) {
        // console.log("deshabilitar pq no se puede ingresar en el mismo vehiculo");
      } else if (mismoVehiculo === false) {
        // console.log("no es el mismo vehiculo");
        if (mostrarCambio === true) {
          // console.log("tiene que sacarlo de otro vehiculo, pq ya existe y esta  en otro vehiculo");
        } else if (mostrarCambio === false) {
          if (datos.identificador === "") {
            // console.log("no hay identificador, no hago nada");
          } else if (datos.identificador !== "") {
            // console.log("post para crear el articulo");
          }
        }
      }
    } else {
      // console.log("la cantidad NO es 1, se bloquea seguimiento");
    }
  }, [mismoVehiculo, mostrarCambio, cantidadNoUnitaria, datos.identificador]);

  return (
    <>
      <Dialog
        fullWidth
        open={props.open}
        onClose={() => props.onClose(false)}
        maxWidth="xl"
      >
        {notificacion && notificacion.open ? (
          <Notify
            mensaje={notificacion.mensaje}
            open={notificacion.open}
            color={notificacion.color}
            // handleClose={() => {
            //   props.onClose(false);
            //   // props.actualizar();
            // }}
            severity={notificacion.severity}
          />
        ) : null}
        <Box justifyContent="center" backgroundColor="#1F618D">
          <DialogTitle>
            <Typography fontSize={20} align="center" color="#ffffff">
              Asignar Articulo a {props.patente}
            </Typography>
          </DialogTitle>
        </Box>
        <form>
          <DialogContent>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={2.5}>
                  {openSnack ? (
                    <TextField
                      disabled
                      focused
                      fullWidth
                      size="small"
                      label="Guardando..."
                    />
                  ) : (
                    <Autocomplete
                      disabled={!articulos}
                      disableClearable
                      size="small"
                      required
                      noOptionsText={"Articulo INEXISTENTE"}
                      options={articulos}
                      autoHighlight
                      getOptionLabel={(articulo) =>
                        articulo.nombre + "-" + articulo.descripcion
                      }
                      //   value={getProductoById(linea.producto)}
                      onChange={(event, value) => {
                        setArticuloSeleccionado(value);
                        setBuscandoSucursales(true);
                        setBuscandoAlmacen(true);
                      }}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          key={option.id}
                          sx={{
                            "& > img": { mr: 2, flexShrink: 0 },
                          }}
                          {...props}
                        >
                          {option.nombre} - {option.descripcion}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                          }}
                          focused
                          size="small"
                          placeholder="Tipee para buscar..."
                          label="Articulo"
                          // error={!!erroresStock[index]}
                        />
                      )}
                    />
                  )}
                </Grid>
                <Grid item xs={2.5}>
                  {openSnack ? (
                    <TextField
                      disabled
                      focused
                      fullWidth
                      size="small"
                      label="Guardando..."
                    />
                  ) : buscandoSucursales ? (
                    <TextField
                      disabled
                      focused
                      fullWidth
                      size="small"
                      label="Buscando Sucursales..."
                    />
                  ) : (
                    <Autocomplete
                      disabled={!sucursales}
                      size="small"
                      focused
                      disableClearable
                      id="sucursal"
                      noOptionsText={"Sucursal INEXISTENTE"}
                      options={sucursales}
                      autoHighlight
                      getOptionLabel={(sucursal) =>
                        sucursal.id + "-" + sucursal.nombre
                      }
                      onChange={(event, value) => {
                        setSucursalSeleccionada(value);
                        setBuscandoAlmacen(true);
                      }}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          key={option.id}
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option.id} - {option.nombre}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                          }}
                          focused
                          size="small"
                          placeholder="Tipee para buscar..."
                          label="Sucursal"
                          //   helperText={erroresStock[index] || ""}  // Muestra el mensaje de error si hay
                          //   error={!!erroresStock[index]}
                        />
                      )}
                    />
                  )}
                </Grid>
                <Grid item xs={2.5}>
                  {openSnack ? (
                    <TextField
                      disabled
                      focused
                      fullWidth
                      size="small"
                      label="Guardando..."
                    />
                  ) : buscandoAlmacen ? (
                    <TextField
                      disabled
                      focused
                      fullWidth
                      size="small"
                      label="Buscando Almacenes..."
                    />
                  ) : (
                    <Autocomplete
                      disabled={almacenes === "" ? true : false}
                      size="small"
                      focused
                      disableClearable
                      id="stock_almacenes"
                      noOptionsText={"Almacén INEXISTENTE"}
                      options={almacenes}
                      autoHighlight
                      getOptionLabel={(almacenes) =>
                        almacenes.id_almacen + "-" + almacenes.nombre
                      }
                      onChange={(event, value) => {
                        setAlmacenSeleccionado(value);
                      }}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          key={option.id}
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option.id_almacen} - {option.nombre}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                          }}
                          focused
                          size="small"
                          placeholder="Tipee para buscar..."
                          label="Almacén"
                          //   helperText={erroresStock[index] || ""}  // Muestra el mensaje de error si hay
                          //   error={!!erroresStock[index]}
                        />
                      )}
                    />
                  )}
                </Grid>
                <Grid item xs={1.25}>
                  <TextField
                    label="Existencia"
                    name="existencia"
                    size="small"
                    type="number"
                    focused
                    required
                    fullWidth
                    autoComplete="off"
                    value={
                      inventarioAlmacen[0]
                        ? parseInt(inventarioAlmacen[0].cantidad)
                        : "-"
                    }
                    // onChange={almacenar}
                    disabled
                    //   error={!!erroresStock[index]}  // Cambia a rojo si hay error
                  />
                </Grid>
                <Grid item xs={1.25}>
                  <TextField
                    label="Cantidad"
                    name="cantidad"
                    size="small"
                    type="number"
                    focused
                    required
                    fullWidth
                    autoComplete="off"
                    value={datos.cantidad}
                    onChange={almacenar}
                    error={cantidadAlmacen < 0 ? true : false} // Cambia a rojo si hay error
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="Fecha"
                    name="fecha"
                    size="small"
                    focused
                    type="date"
                    required
                    fullWidth
                    autoComplete="off"
                    value={datos.fecha}
                    onChange={almacenar}
                    //   error={!!erroresStock[index]}
                  />
                </Grid>
                <Grid item xs={12} mt={1}>
                  <TextField
                    label="Detalle"
                    name="detalle"
                    size="small"
                    focused
                    // type="date"
                    // required
                    fullWidth
                    autoComplete="off"
                    value={datos.detalle}
                    onChange={almacenar}
                    //   error={!!erroresStock[index]}
                  />
                </Grid>
                <Grid item xs={6} mt={1}>
                  <TextField
                    label="Identificador"
                    name="identificador"
                    size="small"
                    focused
                    // type="date"
                    // required
                    fullWidth
                    autoComplete="off"
                    value={datos.identificador}
                    onChange={almacenar}
                    error={errorSeguimiento}
                    // onKeyPress={(event) => {
                    //   if (!/[-0-9/]/.test(event.key)) {
                    //     event.preventDefault();
                    //   }
                    // }}
                    // value={datos.identificador}
                    disabled={cantidadNoUnitaria}
                    // helperText={mensajeSeguimiento}
                  />
                </Grid>
                {/* <Grid item xs={6}>
                </Grid> */}
                {mostrarCambio && (
                  <Grid
                    container
                    item
                    xs={6}
                    mt={1}
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>
                      <WarningAmberIcon color="warning" />
                    </Grid>
                    <Grid item>
                      <Typography color="warning" variant="body1">
                        El artículo con el identificador{" "}
                        <b>{datos.identificador}</b> está en el vehículo{" "}
                        <b>
                          {articuloEncontrado
                            ? articuloEncontrado.patente
                            : null}
                        </b>
                        .
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {mismoVehiculo && (
                  <Grid
                    container
                    item
                    xs={6}
                    mt={1}
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>
                      <WarningAmberIcon color="warning" />
                    </Grid>
                    <Grid item>
                      <Typography color="warning" variant="body1">
                        El artículo con ese identificador ya está ingresado en
                        este vehículo
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              mt={3}
            >
              <Grid item>
                <Button
                  color="error"
                  variant="outlined"
                  onClick={() => props.onClose(false)}
                  style={{ backgroundColor: "#fff" }}
                >
                  Volver
                </Button>
              </Grid>
              <Grid item>
                <Button
                  // type="submit"
                  //   disabled={disabled}
                  color="success"
                  variant="contained"
                  disabled={deshabilitado}
                  // endIcon={<SaveIcon />}
                  onClick={guardarCrear}
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </form>
      </Dialog>
      {/* GUARDAR AL CREAR */}
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}

export default AsignarAVehiculo;
