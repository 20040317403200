import {useState} from 'react'


const useCuit = () => {
    const [Cuit, setCuit] = useState()
    const [isValid, setisValid] = useState(true)



    const validarCuit = (cuit) => {
        // Elimina guiones y espacios en blanco
        cuit = cuit.replace(/[-\s]/g, '');
      
        // Comprueba si el CUIT tiene la longitud correcta
        if (cuit.length !== 11) {
          console.log('El CUIT debe tener 11 caracteres');
          return false;
        }
      
        // Extrae el dígito verificador
        const digitoVerificador = parseInt(cuit.charAt(10));
      
        // Calcula el dígito verificador esperado
        const coeficientes = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
        let acumulado = 0;
      
        for (let i = 0; i < 10; i++) {
          acumulado += parseInt(cuit.charAt(i)) * coeficientes[i];
        }
      
        let verif = 11 - (acumulado % 11);
        if (verif === 11) {
          verif = 0;
        } else if (verif === 10) {
          verif = 9;
        }
      
        if (digitoVerificador === verif) {
          console.log('CUIT VERIFICADO');
          return true;
        } 
        else {
          console.log('CUIT INVALIDO');
          return false;
        }
    
      };
      const handleCuitChange = (event) => {
        const nuevoCuit = event.target.value;
        setCuit(nuevoCuit);
        
        const cuitValido = validarCuit(nuevoCuit);
        setisValid(cuitValido);
      };
    
  return {Cuit,handleCuitChange,isValid}
}

export default useCuit