import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Paper,
} from "@mui/material";
import { Inventory } from "@mui/icons-material"; // Icono de Material-UI
import React, { useEffect, useState } from "react";
import Notify from "../../Notify";
import { getData } from "../../../services/library";

function ArticulosEspecificos(props) {
  const [notificacion, setNotificacion] = useState({
    open: false,
    mensaje: "",
    severity: "",
    color: "",
  });
  const [detalleArticulo, setDetalleArticulo] = useState([]);

  // Fetch de datos del artículo
  useEffect(() => {
    getData(
      `/stock_seguimiento/detalle/${props.row.articulo}/${props.row.vehiculo}`,
      setDetalleArticulo
    );
  }, [props.row]);

  // Obtener el nombre del artículo (suponiendo que todos tienen el mismo nombre)
  const nombreArticulo =
    detalleArticulo.length > 0 ? detalleArticulo[0].nombre_articulo : "";

  return (
    <>
      <Dialog
        fullWidth
        open={props.open}
        onClose={() => props.onClose(false)}
        maxWidth="sm"
      >
        {notificacion.open && (
          <Notify
            mensaje={notificacion.mensaje}
            open={notificacion.open}
            color={notificacion.color}
            severity={notificacion.severity}
          />
        )}

        {/* Título del diálogo */}
        <Box justifyContent="center" backgroundColor="#1F618D">
          <DialogTitle>
            <Typography fontSize={20} align="center" color="#ffffff">
              Detalle de Artículos Registrados
            </Typography>
          </DialogTitle>
        </Box>

        {/* Contenido del diálogo */}
        <DialogContent>
          {/* Mostrar nombre del artículo */}

          {/* Mostrar lista de identificadores */}

          <Box
            component={Paper}
            elevation={3}
            sx={{
              borderRadius: "8px",
              padding: "16px",
              backgroundColor: "#f9f9f9",
              marginTop: "16px",
            }}
          >
            {nombreArticulo ? (
              <Typography
                variant="h6"
                align="center"
                gutterBottom
                style={{ fontWeight: "bold" }}
              >
                {nombreArticulo}
              </Typography>
            ) : null}
            {detalleArticulo.length > 0 ? (
              <List
                sx={{
                  width: "100%",
                  maxWidth: 400,
                  margin: "0 auto",
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  backgroundColor: "#ffffff",
                }}
              >
                {detalleArticulo.map((articulo, index) => (
                  <React.Fragment key={articulo.id}>
                    <ListItem
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "center",
                        padding: "12px 16px",
                      }}
                    >
                      <ListItemIcon>
                        <Inventory style={{ color: "#1F618D" }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={articulo.identificador}
                        primaryTypographyProps={{
                          style: { fontWeight: "bold", color: "#1F618D" },
                        }}
                      />
                    </ListItem>
                    {/* Divider entre elementos excepto el último */}
                    {index < detalleArticulo.length - 1 && (
                      <Divider variant="middle" />
                    )}
                  </React.Fragment>
                ))}
              </List>
            ) : (
              <Typography align="center" color="textSecondary">
                No se encontraron identificadores registrados.
              </Typography>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ArticulosEspecificos;
