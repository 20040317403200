import React, { useState, useEffect, useMemo } from "react";
import Navbar from "../../Navbar";
import { UrlApi } from "../../../services/apirest";
import { Container, Typography, Box } from "@mui/material";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import { createTheme, ThemeProvider, useTheme } from "@mui/material";

function Facturados() {
  const [pedidosfacturados, setFacturados] = useState();
  const [tablaFacturados, setTablafacturados] = useState(true);
  const globalTheme = useTheme();

  //CONSULTAS A LA API--------------------------------------------------------
  const obtenerFacturados = () => {
    fetch(UrlApi + "/pedidos/facturados")
      .then((data) => data.json())
      .then((data) => setFacturados(data));
  };
  //--------------------------------------------------------------------

  useEffect(() => {
    obtenerFacturados();
  }, [tablaFacturados]);

  //PARA LA TABLA DE PEDIDOS-------------------------------------------------------
  //Columnas de la tabla
  const columns = useMemo(
    () => [
      { header: "N°", accessorKey: "id", size: 10 },
      { header: "Cliente", accessorKey: "razon_social", size: 100 },
      { header: "ID SISVEN", accessorKey: "IDsisven", size: 100 },
      {
        header: "Fecha de entrega",
        accessorKey: "fecha_real_entrega",
        type: "date",
        format: "DD-MM-YYYY",
        size: 100,
      },
      { header: "Kgs. Entregados", accessorKey: "kgentregados", size: 100 },
      { header: "Nro.Remito", accessorKey: "nro_remito", size: 100 },
    ],
    []
  );

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //larger tooltip font size                                
              },
            },
          },
        },

      }), [globalTheme]);

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "success",
    size: "small",
  };

  const initialState = {
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 }
  };

  const tableHeadCellProps = {
    align: "center",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };

  //FIN DE LA TABLA DE PEDIDOS-----------------------------------------------
  return (
    <div>
      {/* <Navbar /> */}
      <Container maxWidth="xl" mt={1}>
        {pedidosfacturados && tablaFacturados ? (
          <Box mt={2} boxShadow={3}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableColumnResizing
                columns={columns}
                data={pedidosfacturados}
                initialState={initialState}
                positionActionsColumn="last"
                enableExpandAll={false}
                enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
                muiTableHeadCellProps={tableHeadCellProps}
                muiTableBodyCellProps={{
                  align: "left",
                  sx: {
                    backgroundColor: "#ffffff",
                    color: "black",
                    borderColor: "black"
                  },
                }}
                enableColumnActions={false}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                enableDensityToggle={false}
                // renderTopToolbarCustomActions={() => (
                //   <>
                //   <Box m={1} ml={2}>
                //     <Typography variant="poster" fontSize={26}>
                //     <LocalShippingIcon sx={{ fontSize: 20 }} />
                //       Listado de pedidos a facturar
                //     </Typography>
                //     </Box>
                //   </>                  
                // )}
                layoutMode="grid"
                localization={MRT_Localization_ES}
                enableColumnDragging={false}
                enableGrouping 
                renderToolbarInternalActions={(
                  { table } // boton para nuevo , al lado del buscar
                ) => (
                  <>
                    <MRT_FullScreenToggleButton table={table} />
                  </>
                )}                
              />
            </ThemeProvider>
          </Box>
        ) : null}
      </Container>
    </div>
  );
}
export default Facturados;
