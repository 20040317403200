import React from "react";
import { useEffect, useState } from "react";
import { UrlApi } from "../../../services/apirest";
import { Container, Box, Grid, Typography, Stack, Button, IconButton, FormControl, InputLabel, Select, MenuItem, TextField, FormControlLabel, Checkbox, Switch } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { grey } from "@mui/material/colors";
import InputAdornment from "@mui/material/InputAdornment";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import BadgeIcon from "@mui/icons-material/Badge";
import Alerta from "../../Alerta";
import Notify from "../../Notify";
import ContactsIcon from "@mui/icons-material/Contacts";
import PushPinIcon from "@mui/icons-material/PushPin";

function AddDireccion(props) {
    const [checked, setChecked] = useState(false);
    const [precioEspecial, setPrecioEspecial] = useState(0)
    const [datosformulario, setDatosformulario] = useState({
        direccion_entrega: "",
        contacto: "",
        telefono1: "",
        telefono2: "",
        telefono3: "",
        mails: "",
        latitud: "0",
        longitud: "0",
        id_cliente: "",
        id_localidad: "",
        usuario: localStorage.getItem("user"),
        activo: "1",
        id_sucursal: "",
        id_ruta: "",
        nombre_direccion: "",
        id_provincia: "",
        id_producto: null, // agregue producto 27/4
        precio_especial:precioEspecial
    });
    const [clientes, setClientes] = useState(null);
    const [localidades, setLocalidades] = useState(null);
    const [rutas, setRutas] = useState(null);
    const [provincias, setProvincias] = useState(null);
    const [sucursales, setSucursales] = useState(null);
    const [openSnack, setOpenSnack] = React.useState(false);
    const [colormensaje, setColorMensaje] = useState("");
    const [mensaje, setMensaje] = useState("");
    const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
    const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
    const [mensajeAlerta, setMensajeAlerta] = useState(""); //mensaje que contendrá el componente "alerta"
    const [finalizar, setFinalizar] = useState(); //finalizar === 1 lo manda como prop el componente "notify" e indica que la transaccion ha finalizado y debe ejecutarse la funcion para cerrar
    const [severityAlerta, setSeverityAlerta] = useState(""); //define severidad de la alerta (success error o warning)
    const [severitySnack, setSeveritySnack] = useState(""); //define severidad del snackbar (notificación)
    const [disBtn, setDisBtn] = useState(false);
    const [productos, setProductos] = useState(null);


    const obtenerClientes = () => {
        fetch(UrlApi + "/clientes/activos") //fetch con la urp de api q esta en services/apirest + la ruta
            .then((data) => data.json()) //mando los datos que recibo en json
            .then((data) => setClientes(data)); //seteo Sucursales
    };

    const obtenerSuc = () => {
        fetch(UrlApi + "/sucursales") //fetch con la urp de api q esta en services/apirest + la ruta
            .then((data) => data.json()) //mando los datos que recibo en json
            .then((data) => setSucursales(data)); //seteo Sucursales
    };

    const obtenerProvincias = () => {
        fetch(UrlApi + "/provincias") //fetch con la urp de api q esta en services/apirest + la ruta
            .then((data) => data.json()) //mando los datos que recibo en json
            .then((data) => setProvincias(data)); //seteo Sucursales
    };

    const obtenerRutas = () => {
        fetch(UrlApi + "/rutas/suc/" + datosformulario.id_sucursal) //fetch con la urp de api q esta en services/apirest + la ruta
            .then((data) => data.json()) //mando los datos que recibo en json
            .then((data) => setRutas(data)); //seteo Sucursales
    };

    //Dejo esto preparado para cuando traiga las localidades por provincia
    const obtenerLocalidades = () => {
        fetch(UrlApi + "/localidades/prov/" + datosformulario.id_provincia)
            .then((data) => data.json())
            .then((data) => setLocalidades(data));
    };

    const obtenerProductos = () => {
        fetch(UrlApi + '/productos')
            .then(data => data.json())
            .then(data => setProductos(data))
    };

    useEffect(() => {
        obtenerClientes();
        obtenerProvincias();
        obtenerSuc();
        obtenerProductos();
    }, []);

    useEffect(() => {
        obtenerRutas();
    }, [datosformulario.id_sucursal])

    useEffect(() => {
        obtenerLocalidades();
    }, [datosformulario.id_provincia]);

    const guardarDireccion = async (e) => {
        setDisBtn(true)
        e.preventDefault();
        const response = await fetch(UrlApi + "/direccionesentrega", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(datosformulario),
        })
        //response&&console.log(response)
        if (response.ok === false) {
            console.log("ERROR EN API");
            const error = response && response.json();
            setError(error);
            setSeverityAlerta("error")
            setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
        } else {
            console.log("Conexión con api OK");
            try {
                //Parseo respuesta de la api a json()
                const json = await response.json();
                //si api me devuelve lo q le puse en message
                if (json.message) {
                    //Si ese mensaje es "OK"
                    if (json.message === "OK") {
                        console.log("Guardado Satisfactoriamente");
                        setNotificacion(true);
                        setOpenSnack(true);
                        setColorMensaje("#D4EFDF");
                        setMensaje("Guardado");
                        setSeveritySnack("success");
                    } else {
                        console.log("res en api incorrecto. Revisar que sea 'OK'")
                        setNotificacion(true);
                        setOpenSnack(true);
                        setColorMensaje("#D4EFDF");
                        setSeveritySnack("error");
                        setMensaje("Registro Guardado. REVISAR RESPUESTA DE API (E_S)");
                    }
                } else if (json.sqlMessage) {
                    json && setError(json);
                    console.log(json);
                    setMensajeAlerta("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
                } else {
                    setMensajeAlerta("ERROR DESCONOCIDO.");
                }
            } catch (error) {
                if (error.toString().includes("is not valid JSON")) {
                    console.log("Guardado. Advertencia: Actualizar respuesta de api. JSON invalido.");
                    setNotificacion(true);
                    setOpenSnack(true);
                    setColorMensaje("#D4EFDF");
                    setMensaje("Guardado");
                    setSeveritySnack("success");
                } else {
                    setMensajeAlerta("ERROR DESCONOCIDO.");
                }
            }
        }
    };

    //Funcion para almacenar los datos (se ejecuta en el onchange de los textfield/select)
    const almacenar = (e) => {
        setDatosformulario({
            ...datosformulario,
            [e.target.name]: e.target.value,
        });
    };

    const handleClose = () => {
        props.tabla(true);
        props.alta(false);
    };


    const style = {
        backgroundColor: "#ffffff",
        width: "100%"
    };

    // CHECK PARA PRECIO ESPECIAL

    const handleChangeCheck = (event) => {
      setChecked(event.target.checked);
    };
    useEffect(() => {
        if (checked === true) {
            setPrecioEspecial(1)
        } else if (checked === false) {
            setPrecioEspecial(0)
        }
    }, [checked])

    useEffect(() => {
        setDatosformulario((prevDatos) => ({
            ...prevDatos,
            precio_especial: precioEspecial
        }));
    }, [precioEspecial]);
    
    console.log("check", checked, datosformulario);

    return (
        <div>
            <Container>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container backgroundColor="#1F618D" p={1.5}>
                        <Grid item xs={2}>
                            <IconButton onClick={handleClose}>
                                <ArrowBackIcon sx={{ color: grey[50] }} />
                            </IconButton>
                        </Grid>

                        <Grid
                            item
                            xs={8}
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography variant="h5" color="#ffffff">
                                Cargar nueva direccion de entrega
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <form onSubmit={guardarDireccion}>
                    <Box backgroundColor="#F4F6F6"
                        mt={2}
                        p={3}
                        border={0.5}
                        borderColor={"#ABB2B9"}
                        borderRadius={4}
                    >
                        <Grid container spacing={1} direction={{ xs: 'column', sm: 'row' }}>
                            <Grid item xs={12}>
                                <FormControl fullWidth style={style} color="primary" focused>
                                    <InputLabel>Cliente</InputLabel>
                                    <Select
                                        label="Cliente"
                                        required
                                        name="id_cliente"
                                        labelId="id_cliente"
                                        value={datosformulario.id_cliente}
                                        onChange={almacenar}
                                    >
                                        {clientes
                                            ? clientes.map((elemento) => (
                                                <MenuItem key={elemento.id} value={elemento.id}>
                                                    {elemento.razon_social}
                                                </MenuItem>
                                            ))
                                            : null}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth style={style} focused margin="dense">
                                    <InputLabel id="demo-simple-select-helper-label">
                                        Provincia
                                    </InputLabel>
                                    <Select
                                        required
                                        name="id_provincia"
                                        labelId="id_provincia"
                                        value={datosformulario.id_provincia}
                                        label="Provincia"
                                        onChange={almacenar}
                                    >
                                        {provincias
                                            ? provincias.map((elemento) => (
                                                <MenuItem key={elemento.id} value={elemento.id}>
                                                    {elemento.provincia}
                                                </MenuItem>
                                            ))
                                            : null}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth style={style} focused margin="dense">
                                    <InputLabel id="demo-simple-select-helper-label">
                                        Localidad
                                    </InputLabel>
                                    <Select
                                        focused
                                        required
                                        name="id_localidad"
                                        label="Localidad"
                                        value={datosformulario.id_localidad}
                                        onChange={almacenar}
                                    >
                                        {localidades
                                            ? localidades.map((elemento) => (
                                                <MenuItem key={elemento.id} value={elemento.id}>
                                                    {elemento.localidad}
                                                </MenuItem>
                                            ))
                                            : null}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth style={style} focused margin="dense">
                                    <InputLabel id="demo-simple-select-helper-label">
                                        Sucursal
                                    </InputLabel>
                                    <Select
                                        focused
                                        required
                                        name="id_sucursal"
                                        label="Sucursal"
                                        value={datosformulario.id_sucursal}
                                        onChange={almacenar}
                                    >
                                        {sucursales
                                            ? sucursales.map((elemento) => (
                                                <MenuItem key={elemento.id} value={elemento.id}>
                                                    {elemento.nombre}
                                                </MenuItem>
                                            ))
                                            : null}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth style={style} focused margin="dense">
                                    <InputLabel id="demo-simple-select-helper-label">Ruta</InputLabel>
                                    <Select
                                        required
                                        name="id_ruta"
                                        value={datosformulario.id_ruta}
                                        label="Ruta"
                                        onChange={almacenar}
                                    >
                                        {rutas
                                            ? rutas.map((elemento) => (
                                                <MenuItem key={elemento.id} value={elemento.id}>
                                                    {elemento.nombre}
                                                </MenuItem>
                                            ))
                                            : null}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    placeholder="Ejemplo: Casa Central"
                                    type="text"
                                    style={style}
                                    required
                                    margin="dense"
                                    focused
                                    fullWidth
                                    name="nombre_direccion"
                                    id="nombre_direccion"
                                    label="Lugar de entrega"
                                    color="primary"
                                    onChange={almacenar}
                                    value={datosformulario.nombre_direccion}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <BadgeIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                /></Grid>
                            <Grid item xs={6}>
                                <TextField
                                    style={style}
                                    placeholder="Direccion de entrega"
                                    type="text"
                                    required
                                    focused
                                    margin="dense"
                                    fullWidth
                                    name="direccion_entrega"
                                    id="direccion_entrega"
                                    label="Direccion de entrega"
                                    color="primary"
                                    onChange={almacenar}
                                    value={datosformulario.direccion_entrega.replace(/\b\w/g, (l) =>
                                        l.toUpperCase()
                                    )}
                                    inputProps={{ maxLength: 200 }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LocationOnIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    style={style}
                                    placeholder="Sr .. "
                                    type="text"
                                    focused
                                    margin="dense"
                                    fullWidth
                                    name="contacto"
                                    id="contacto"
                                    label="Contacto"
                                    color="primary"
                                    onChange={almacenar}
                                    value={datosformulario.contacto}
                                    inputProps={{ maxLength: 200 }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <ContactsIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth style={style} focused margin="dense">
                                    <InputLabel id="demo-simple-select-helper-label">Producto</InputLabel>
                                    <Select
                                        required
                                        name="id_producto"
                                        value={datosformulario.id_producto}
                                        label="Producto"
                                        onChange={almacenar}
                                    >
                                        {productos
                                            ? productos.map((elemento) => (
                                                <MenuItem key={elemento.id} value={elemento.id}>
                                                    {elemento.nombre}
                                                </MenuItem>
                                            ))
                                            : null}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    style={style}
                                    type="number"
                                    focused
                                    fullWidth
                                    margin="dense"
                                    name="telefono1"
                                    id="telefono1"
                                    label="Telefono 1"
                                    color="primary"
                                    onChange={almacenar}
                                    value={datosformulario.telefono1}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <ContactPhoneIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    style={style}
                                    type="number"
                                    margin="dense"
                                    focused
                                    fullWidth
                                    name="telefono2"
                                    id="telefono2"
                                    label="Telefono 2"
                                    color="primary"
                                    onChange={almacenar}
                                    value={datosformulario.telefono2}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <ContactPhoneIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    type="number"
                                    style={style}
                                    focused
                                    fullWidth
                                    name="telefono3"
                                    margin="dense"
                                    id="telefono3"
                                    label="Telefono 3"
                                    color="primary"
                                    onChange={almacenar}
                                    value={datosformulario.telefono3}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <ContactPhoneIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    placeholder="ejemplo@mail.com"
                                    type="email"
                                    focused
                                    fullWidth
                                    style={style}
                                    name="mails"
                                    id="mails"
                                    label="Mails"
                                    color="primary"
                                    margin="dense"
                                    onChange={almacenar}
                                    value={datosformulario.mails}
                                    inputProps={{ maxLength: 300 }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <ContactMailIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                /></Grid>
                            <Grid item xs={6}>
                                <TextField
                                    placeholder="Latitud"
                                    style={style}
                                    focused
                                    fullWidth
                                    margin="dense"
                                    name="latitud"
                                    id="latitud"
                                    label="Latitud"
                                    color="primary"
                                    //helperText="Latitud Incorrecta"
                                    onChange={almacenar}
                                    value={datosformulario.latitud}
                                    inputProps={{ maxLength: 45 }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PushPinIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    placeholder="Longitud"
                                    focused
                                    fullWidth
                                    style={style}
                                    name="longitud"
                                    margin="dense"
                                    id="longitud"
                                    label="Longitud"
                                    color="primary"
                                    onChange={almacenar}
                                    value={datosformulario.longitud}
                                    inputProps={{ maxLength: 45 }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PushPinIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                                <FormControlLabel
                                  labelPlacement="end"
                                  label={<Typography variant="button">Precio Especial</Typography>}
                                  control={
                                    <Switch

                                      name="precio_especial"
                                      checked={checked}
                                      onChange={handleChangeCheck}
                                    />
                                  }
                                />
                            </Grid>
                            <Grid mt={2} container direction="row" justifyContent="space-between" alignItemns="center">
                                <Button disabled={disBtn} variant="contained" color="error" onClick={handleClose}>
                                    Volver
                                </Button>
                                <Button disabled={disBtn} variant="contained" color="success" type="submit">
                                    Guardar Direccion
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                    {notificacion ? (
                        <Notify
                            mensaje={mensaje}
                            open={openSnack}
                            color={colormensaje}
                            finalizar={setFinalizar}
                            severity={severitySnack}
                        />
                    ) : null}
                    {/* ALERT --> SE MUESTRA SI HAY UN ERROR EN LA API (NO SE CONECTA O ESTÁ MAL LA RUTA, ETC.) */}
                    {error && mensajeAlerta ? (
                        <Alerta mensaje={mensajeAlerta} severity={severityAlerta} />
                    ) : null}
                </form>
            </Container>
            <br></br>
            <br></br>
            <br></br>
        </div>
    );
}

export default AddDireccion;
