//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio
// Creation Date : 09/09/2024
// Version : 1
// Description : pantalla de vencimientos de tanques
//------Changes -------------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect, useMemo } from "react";
import Typography from "@mui/material/Typography";
import {
  Box,
  Container,
} from "@mui/material";
import moment from "moment";
import { UrlApi } from "../../services/apirest";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import Navbar from "../Navbar";
import BusAlertIcon from "@mui/icons-material/BusAlert";
import { logEventos } from "../../services/services";

function VencimientosTanques() {
  const [tanquesVencimiento, setTanquesVencimiento] = useState();
  const globalTheme = useTheme();
  const [tabla, setTabla] = useState(true);

  const obtenerTanques = () => {
    fetch(UrlApi + "/tanque_rehabilitacion/todos")
      .then((data) => data.json())
      .then((data) => setTanquesVencimiento(data));
  };
 // console.log("TANQUES", tanquesVencimiento);
  //ejecuta la funcion de obtener
  useEffect(() => {
    obtenerTanques();
  }, []);

  useEffect(() => {
    logEventos("Acceso al menú Vencimientos Tanques", "Vencimientos Tanques", "Ver los vencimientos de tanques", localStorage.getItem("user"))
}, [])
  //*************************************************************************************************************************************/
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)" //background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
            },
          },
        },
      }),
    [globalTheme]
  );
  const columns = useMemo(
    () => [
      {
        header: "Datos del tanque", // Puedes cambiar el nombre del encabezado si lo prefieres
        columns: [
          {
            header: "Patente",
            accessorKey: "patente",
            size: 40,
          },
          {
            header: "Matrícula",
            accessorKey: "matricula",
            size: 40,
          },
          {
            header: "N° Auditoría",
            accessorKey: "num_auditoria",
            size: 40,
          },
          {
            header: "Fabricante",
            accessorKey: "fabricante",
            size: 40,
          },
        ],
      },
      {
        header: "Revisión ( c/5 años, c/2años ,c/1año ,c/6 meses)", // Puedes cambiar el nombre del encabezado si lo prefieres
        columns: [
          {
            header: "Quinquenal",
            accessorFn: (ultima) =>
              moment.utc(ultima.prox_ultima).format("DD-MM-YYYY"),
            size: 40,
          },
          {
            header: "Válvulas seguridad",
            accessorFn: (seguridad) =>
              moment.utc(seguridad.prox_rev_val_seg).format("DD-MM-YYYY"),
            size: 55,
          },
          {
            header: "Válvulas exceso",
            accessorFn: (exceso) =>
              moment.utc(exceso.prox_rev_val_exc).format("DD-MM-YYYY"),
            size: 50,
          },
          {
            header: "Mangueras",
            accessorFn: (manguera) =>
              moment.utc(manguera.prox_rev_man).format("DD-MM-YYYY"),
            size: 45,
          },
        ],
      },
    ],
    []
  );
  const initialState = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
  };
  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };
  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "success",
    size: "small",
  };
  //*************************************************************************************************************************************/

  return (
    <div>
      <Navbar />
      <Container maxWidth="xl" mt={1} mb={2}>
        {tanquesVencimiento && tabla ? (
          <Box mt={2} boxShadow={3}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
                enableColumnResizing
                columns={columns}
                data={tanquesVencimiento}
                initialState={initialState}
                positionActionsColumn="last"
                enableExpandAll={false}
                enableToolbarInternalActions={true}
                muiTableHeadCellProps={tableHeadCellProps}
                muiTableBodyCellProps={({ row }) => {
                    const today = moment.utc(); // Fecha actual
                    const oneWeekFromNow = moment.utc().add(1, 'weeks'); // 1 semana desde hoy
                    const twoWeeksFromNow = moment.utc().add(2, 'weeks'); // 2 semanas desde hoy
                  
                    const fechas = [
                      moment.utc(row.original.prox_ultima),
                      moment.utc(row.original.prox_rev_val_seg),
                      moment.utc(row.original.prox_rev_val_exc),
                      moment.utc(row.original.prox_rev_man),
                    ];
                  
                    // Filtrar fechas válidas
                    const fechasValidas = fechas.filter(fecha => fecha.isValid());
                  
                    // Obtener la fecha más próxima
                    const fechaMasCercana = fechasValidas.length > 0 ? moment.min(fechasValidas) : null;
                  
                    let backgroundColor = "#FFFFFF"; // Color por defecto (blanco)

                    if (fechaMasCercana) {
                        if (fechaMasCercana.isBefore(today)) {
                            backgroundColor = "#ff4c4c"; // Rojo si la fecha es anterior a la actual
                        } else if (fechaMasCercana.isBefore(oneWeekFromNow)) {
                          backgroundColor = "#ffff99";  // amarillo si está a menos de una semana
                        } else if (fechaMasCercana.isBefore(twoWeeksFromNow)) {
                          backgroundColor = "#ffcc80"; // Naranja si está a menos de dos semanas
                        }
                      }

                    return {
                      align: "left",
                      sx: {
                        backgroundColor,
                        color: "black",
                        borderColor: "black",
                      },
                    };
                  }}
                enableColumnActions={true}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                enableDensityToggle={false}
                enableGrouping
                renderTopToolbarCustomActions={() => (
                  <>
                    <Typography variant="poster" fontSize={26}>
                      <BusAlertIcon sx={{ size: 20 }} />
                   Vencimiento de tanques moviles
                    </Typography>
                  </>
                )}
                layoutMode="grid"
                localization={MRT_Localization_ES}
                enableColumnDragging={false} //No permite que se arrastren las columnas.
                // ACCIONES
                renderToolbarInternalActions={(
                  { table } // boton para nuevo , al lado del buscar
                ) => (
                  <>
                    <MRT_FullScreenToggleButton table={table} />
                  </>
                )}
              />
            </ThemeProvider>
            <br />
            <br />
          </Box>
        ) : null}
      </Container>
    </div>
  );
}

export default VencimientosTanques;
