import React, { useState, useEffect, useMemo, useRef } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Grid,
  Stack,
  Snackbar,
  TextField,
  Alert,
  Autocomplete,
} from "@mui/material";
import moment from "moment"; // PARA LA FECHA
//adjuntado
import AttachFileIcon from "@mui/icons-material/AttachFile"; //ADJUNTAR ARCHIVOS
import FileUploadStock from "./FileUploadStock";
//enviar mails
import { enviarmails } from "../../services/services";
import { grey } from "@mui/material/colors";
import Title from "../Title";
import { postData } from "../../services/library";
import { v4 as uuidv4 } from "uuid"; //ADJUNTAR IMAGEN
import { logEventos } from "../../services/services";

function Dialog_tranferir(props) {
      //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [openSnackEditar, setOpenSnackEditar] = React.useState(false);
  const [disabledGuardarTr, setDisabledGuardarTr] = useState(false);
  const [colorTextField, setcolorTextField] = useState();
  const [datosArt, setDatosArt] = useState(props.datosArt)
  const [dialogTransferirArticulo, setDialogTransferirArticulo] = useState(props.dialogTransferirArticulo);
  const [openCloseAdjDoc, setOpenCloseAdjDoc] = useState(false); //ADJUNTAR IMAGEN
  const [sucursalSeleccionada, setSucursalSeleccionada] = useState([]);

  const [cantTransf, setCantTransf] = useState(datosArt.cantidad);
  const [cantEnviada, setCantEnviada] = useState(null);
  const [cantidadInventarioTrResta, setCantidadInventarioTrResta] = useState(0);
  const [inventarioTrResta, setInventarioTrResta] = useState([]);

  const [usuarioCompras, setUsuarioCompras] = useState([]);
console.log(datosArt);

  const obtenerUsuarioCompras = () => {
    fetch(UrlApi + "/userdata/sector/4/")
      .then((data) => data.json())
      .then((data) => setUsuarioCompras(data));
  };
  
  useEffect(() => {
    obtenerUsuarioCompras();
  }, []);

  // const [usuarioAdmin, setUsuarioAdmin] = useState([]);

  // const obtenerUsuarioAdmin = () => {
  //   fetch(UrlApi + "/userdata/sector/5/")
  //     .then((data) => data.json())
  //     .then((data) => setUsuarioAdmin(data));
  // };
  // useEffect(() => {
  //   obtenerUsuarioAdmin();
  // }, []);
  
 // console.log("USUARIO COMPRAS", usuarioCompras);

  const [reqExiste, setReqExiste] = useState([]);

const obtenerRequerimientoExistente = () => {
  fetch(UrlApi + "/requerimientos_materiales/reqexiste/" + props.sucursal + "/" + datosArt.id_articulo)
    .then((data) => data.json())
    .then((data) => setReqExiste(data));
};

useEffect(() => {
  obtenerRequerimientoExistente();
}, [datosArt]);

//console.log("REQUERIMIENTO", reqExiste, reqExiste.length);

  useEffect(() => {
    setCantTransf(datosArt.cantidad - datosArt.cantidad_enviada);
  }, [datosArt.cantidad, datosArt.cantidad_enviada]);

  const handleCantidadTransf = (e) => {
    setCantTransf(e.target.value);
  };

  useEffect(() => {
    cantEnviada === null
      ? setCantTransf(datosArt.cantidad)
      : setCantTransf(datosArt.cantidad - datosArt.cantidad_enviada);
  }, [dialogTransferirArticulo]);

    // CALCULO DEL TOTAL FINAL
  useEffect(() => {
      setCantidadInventarioTrResta(
        inventarioTrResta[0]
          ? parseInt(inventarioTrResta[0].cantidad) -
              parseInt(datosArt.cantidad_enviada)
          : null
      );
    }, [datosArt.cantidad_enviada]);

  //funcion para obtener sucursales
  const obtenerInventarioTrResta = () => {
    fetch(
      UrlApi +
        "/stock_inventarios/xalmart/" +
        datosArt.id_almacen +
        "/" +
        datosArt.id_articulo
    )
      .then((data) => data.json())
      .then((data) => setInventarioTrResta(data));
  };
  
  useEffect(() => {
    obtenerInventarioTrResta();
  }, [datosArt.almacen_origen, datosArt.id_articulo]);

//  const envioMail = async (e) =>{
//   const usuariosNotificar = [...(usuarioCompras || []), ...(usuarioAdmin || [])]; // Combinar ambos arrays
//   // console.log("usuarios notificar",usuariosNotificar);

//    usuariosNotificar &&
//      usuariosNotificar.length > 0 &&
//      usuariosNotificar.forEach((usuario) => {
//     if (usuario.email) {
//       if (cantidadInventarioTrResta<datosArt.alerta_minima && cantidadInventarioTrResta>datosArt.valor_minimo ) {
//        // console.log("EMAIL de CONSUMO", usuario.email);
//         enviarmails(
//           usuario.email,
//           "Advertencia de Nivel de Stock ",
//           `<p>El artículo <b>${datosArt.articulo}</b> del almacén <b>${datosArt.almacen}</b> esta por debajo del punto de pedido.<br/></p>`
//         );
//       } else if (cantidadInventarioTrResta<datosArt.valor_minimo) {
//         enviarmails(
//           usuario.email,
//           "Alerta de Stock por Debajo del Nivel Mínimo",
//           `<p>El artículo <b>${datosArt.articulo}</b> del almacén <b>${datosArt.almacen}</b> esta por debajo del stock mínimo.<br/></p>`
//         );
        
//       }
//     }
//   });
//  }

//  const envioMail = async (e) =>{
//    usuarioCompras &&
//      usuarioCompras.length > 0 &&
//      usuarioCompras.forEach((usuario) => {
//     if (usuario.email) {
//       if (cantidadInventarioTrResta<datosArt.alerta_minima && cantidadInventarioTrResta>datosArt.valor_minimo ) {
//        // console.log("EMAIL de CONSUMO", usuario.email);
//         enviarmails(
//           usuario.email,
//           "Advertencia de Nivel de Stock ",
//           `<p>El artículo <b>${datosArt.articulo}</b> del almacén <b>${datosArt.almacen}</b> esta por debajo del punto de pedido.<br/></p>`
//         );
//       } else if (cantidadInventarioTrResta<datosArt.valor_minimo) {
//         enviarmails(
//           usuario.email,
//           "Alerta de Stock por Debajo del Nivel Mínimo",
//           `<p>El artículo <b>${datosArt.articulo}</b> del almacén <b>${datosArt.almacen}</b> esta por debajo del stock mínimo.<br/></p>`
//         );
        
//       }
//     }
//   });
//  }
// *********************************REQUERIMIENTO***************************************** //
const [notificacion, setNotificacion] = useState({
  open: false,
  mensaje: "",
  severity: "",
  color: "",
});
const [uuid, setUuid] = useState(uuidv4());

const [envioRequerimiento, setEnvioRequerimiento] = useState({
  id_movil: null,
  id_sucursal: props.sucursal,
  fecha_entrega: moment(datosArt.fecha_transaccion).format(
    "YYYY-MM-DD HH:mm:ss"
  ),
  usuario_registra: localStorage.getItem('user'),
  usuario_autoriza: null,
  estado: 50, //cuando se carga el pedido se pone en estado "A autorizar"
  para_comprar: 1,
  observaciones: "",
  uuid_requerimiento: uuid,
  comentarios_internos: "Pedido Automático - Definir Cantidad",
  para_compras: 0,
  para_rrhh: 0,
  autorizado_jefe_compras: 0,
})

const [datosLista, setDatosLista] = useState([{
  material: null,
  usuario: localStorage.getItem('user'),
  cantidad: 1,
  // id_unidad_medida: datosArt.id_unidad_medida,
  uuid_requerimiento: envioRequerimiento.uuid_requerimiento,
  id_articulo: datosArt.id_articulo
}]);


useEffect(() => {
setDatosLista([{
  material: null,
  usuario: localStorage.getItem('user'),
  cantidad: 1,
  // id_unidad_medida: datosArt.id_unidad_medida,
  uuid_requerimiento: envioRequerimiento.uuid_requerimiento,
  id_articulo: datosArt.id_articulo
}])
setEnvioRequerimiento({
id_movil: null,
id_sucursal: props.sucursal,
fecha_entrega: moment(datosArt.fecha_transaccion).format(
  "YYYY-MM-DD HH:mm:ss"
),
usuario_registra: localStorage.getItem('user'),
usuario_autoriza: null,
estado: 50, //cuando se carga el pedido se pone en estado "A autorizar"
para_comprar: 1,
observaciones: "",
uuid_requerimiento: uuid,
comentarios_internos: "Pedido Automático - Definir Cantidad",
para_compras: 0,
para_rrhh: 0,
autorizado_jefe_compras: 0,
})
}, [datosArt])

const guardarListaMateriales = (e) => {
postData(e,
    "/lista_materiales",
    {
        datosLista
    },
    setNotificacion
);
};
// ************************************************************************** //

  const guardarTransferencia = async (e) => {
    logEventos("Guardar transferencia", "Stock Sucursales", "Se guardo la transferencia", localStorage.getItem("user"))
    e.preventDefault();
    setDisabledGuardarTr(true);
    await fetch(UrlApi + "/stock_transacciones", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_accion: 160,
        id_articulo: datosArt.id_articulo,
        almacen_origen: datosArt.id_almacen,
        sucursal_destino: sucursalSeleccionada.id,
        cantidad_enviada: datosArt.cantidad_enviada,
        detalle_transaccion: datosArt.detalle_transaccion,
        nro_remito_puntodeventa: datosArt.nro_remito_puntodeventa,
        nro_remito_numero: datosArt.nro_remito_numero,
        fecha_transaccion: moment(datosArt.fecha_transaccion).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        en_transito: 1,
        uuid_documentacion: props.newUuidDoc, //ADJUNTAR IMAGEN
        usuario: localStorage.getItem("user"),
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("La transferencia se guardó exitosamente !");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar la transferencia");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    setDisabledGuardarTr(true);
    // restar cantidad
    await fetch(
      UrlApi +
        "/stock_inventarios/" +
        datosArt.id_almacen +
        "/" +
        datosArt.id_articulo,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          cantidad: cantidadInventarioTrResta,
          usuario: localStorage.getItem("user"),
        }),
      }
    )
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el artículo");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
      if (cantidadInventarioTrResta<datosArt.alerta_minima && cantidadInventarioTrResta>=datosArt.valor_minimo ) {
       // console.log("ADVERTENCIA AMARILLA");
        if (datosArt.pedido_automatico === 1 && reqExiste.length === 0) {
       //   console.log("ES AUTOMATICO AMARILLO");
          postData(e,
            '/requerimientos_materiales',
            {
                id_sucursal: envioRequerimiento.id_sucursal,
                id_movil: envioRequerimiento.id_movil,
                estado: 50, //cuando se carga el pedido se pone en estado "Pendiente autorizacion"
                para_comprar: envioRequerimiento.para_comprar,
                para_compras: envioRequerimiento.para_compras,
                para_rrhh: envioRequerimiento.para_rrhh,
                fecha_entrega: envioRequerimiento.fecha_entrega,
                usuario_registra: envioRequerimiento.usuario_registra,
                usuario_autoriza: envioRequerimiento.username,
                observaciones: envioRequerimiento.observaciones,
                uuid_requerimiento: uuid,
                comentarios_internos: envioRequerimiento.comentarios_internos,
                autorizado_jefe_compras: 0,
            },
            setNotificacion,
            guardarListaMateriales(e),
            )
            .then((response) => {
              console.log("Respuesta de postData en guardarCabecera:", response);
            })
            .catch((error) => {
              console.error("Error en postData en guardarCabecera:", error);
            });
        }
      } else if (cantidadInventarioTrResta<datosArt.valor_minimo) {
      //  console.log("ADVERTENCIA ROJA");
        if (datosArt.pedido_automatico === 1 && reqExiste.length === 0) {
       //   console.log("ES AUTOMATICO ROJO");
          postData(e,
            '/requerimientos_materiales',
            {
                id_sucursal: envioRequerimiento.id_sucursal,
                id_movil: envioRequerimiento.id_movil,
                estado: 50, //cuando se carga el pedido se pone en estado "Pendiente autorizacion"
                para_comprar: envioRequerimiento.para_comprar,
                para_compras: envioRequerimiento.para_compras,
                para_rrhh: envioRequerimiento.para_rrhh,
                fecha_entrega: envioRequerimiento.fecha_entrega,
                usuario_registra: envioRequerimiento.usuario_registra,
                usuario_autoriza: envioRequerimiento.username,
                observaciones: envioRequerimiento.observaciones,
                uuid_requerimiento: uuid,
                comentarios_internos: envioRequerimiento.comentarios_internos,
                autorizado_jefe_compras: 0
            },
            setNotificacion,
            guardarListaMateriales(e),
            )
            .then((response) => {
              console.log("Respuesta de postData en guardarCabecera:", response);
            })
            .catch((error) => {
              console.error("Error en postData en guardarCabecera:", error);
            });
        }
      }
      // if (datosArt.enviamail === 1) {
      //   envioMail();
      // }
    handleClose();
  };
 // console.log("cantidad de cantidadInventarioTrResta" ,cantidadInventarioTrResta, "punto de pedido ",datosArt.alerta_minima, "stock min" ,datosArt.valor_minimo,"almacen",datosArt.almacen);

  const handleClose = () => {
    setDialogTransferirArticulo(!dialogTransferirArticulo)
      if (props.onClose) {
          props.onClose();
        }
    };
  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setOpenSnack(false);
    };

  const handleOpenCloseAdjDoc = (value) => {
      setOpenCloseAdjDoc(value);
    };

  const almacenar = (e) => {
      setDatosArt({
        ...datosArt,
        [e.target.name]: e.target.value,
      });
    };

  useEffect(() => {
      if (cantTransf < 0) {
        setDisabledGuardarTr(true);
        setcolorTextField("error");
      } else {
        setDisabledGuardarTr(false);
        setcolorTextField("primary");
      }
    }, [cantTransf, datosArt.cantidad_enviada]);

    const style = {
      backgroundColor: "#ffffff",
      width: "100%",
    };
  return (
    <>
        <Dialog
    open={dialogTransferirArticulo}
    onClose={handleClose}
    maxWidth="md"
    fullWidth
  >
    <Title
            titulo={`Transferir Artículo a Otra Sucursal`}
            handleClose={() => {
              handleClose()
            }}
          />
      <form onSubmit={guardarTransferencia} autoComplete="off">
          <Box
            border={0.5}
            borderColor={grey[300]}
            backgroundColor="#F4F6F6"
            p={2}
            py={2}
            mt={1}
          >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={3} py={1} mb={1}>
                      <TextField
                        style={style}
                        label="Artículo"
                        required
                        size="small"
                        name="nombre_articulo"
                        value={datosArt.articulo}
                        onChange={almacenar}
                        inputProps={{
                          readOnly: true,
                          "aria-label": "Without label",
                        }}
                      />
                    </Grid>
                    <Grid item xs={3} py={1} mb={1}>
                      <TextField
                        style={style}
                        label="Almacen de Origen"
                        size="small"
                        name="almacen"
                        value={datosArt.almacen}
                        onChange={almacenar}
                        inputProps={{
                          readOnly: true,
                          "aria-label": "Without label",
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} py={1} mb={1}>
                      <Autocomplete
                        disabled={!props.sucursales}
                        size="small"
                        focused
                        disableClearable
                        id="sucursales"
                        noOptionsText={"Sucursal INEXISTENTE"}
                        options={props.sucursales}
                        autoHighlight
                        getOptionLabel={(sucursales) =>
                          sucursales.id + "-" + sucursales.nombre
                        }
                        onChange={(event, value) =>
                          setSucursalSeleccionada(value)
                        }
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            key={option.id}
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.id}-{option.nombre}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            style={style}
                            required
                            focused
                            size="small"
                            placeholder="Tipee para buscar..."
                            {...params}
                            label="Sucursal de Destino"
                            inputProps={{
                              ...params.inputProps,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} mb={2}>
                      <TextField
                        style={style}
                        label="Detalle Transaccion"
                        name="detalle_transaccion"
                        size="small"
                        placeholder="..."
                        inputProps={{
                          maxLength: 60,
                        }}
                        focused
                        fullWidth
                        value={datosArt.detalle_transaccion}
                        onChange={almacenar}
                      />
                    </Grid>
                    <Grid item xs={2.8} mb={2}>
                      <TextField
                        style={style}
                        label="N° Pto.Venta"
                        name="nro_remito_puntodeventa"
                        size="small"
                        placeholder="0000"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        inputProps={{
                          maxLength: 4,
                        }}
                        focused
                        required
                        fullWidth
                        value={datosArt.nro_remito_puntodeventa}
                        onChange={almacenar}
                      />
                    </Grid>
                    <Grid item xs={2.7} py={1} mb={1}>
                      <TextField
                        style={style}
                        label="N° Remito"
                        name="nro_remito_numero"
                        size="small"
                        placeholder="00000123"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        inputProps={{
                          maxLength: 8,
                        }}
                        focused
                        required
                        fullWidth
                        value={datosArt.nro_remito_numero}
                        onChange={almacenar}
                      />
                    </Grid>
                    <Grid item xs={3.3} py={1} mb={1}>
                      <TextField
                        style={style}
                        label="Cantidad en el Inventario"
                        size="small"
                        value={cantTransf}
                        onChange={handleCantidadTransf}
                        inputProps={{
                          readOnly: true,
                          "aria-label": "Without label",
                        }}
                      />
                    </Grid>
                    <Grid item xs={3.2} py={1} mb={1}>
                      <TextField
                        style={style}
                        label="Cantidad a Transferir"
                        name="cantidad_enviada"
                        size="small"
                        placeholder="..."
                        onKeyPress={(event) => {
                          if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        inputProps={{
                          maxLength: 6,
                        }}
                        focused
                        required
                        fullWidth
                        color={colorTextField}
                        value={datosArt.cantidad_enviada}
                        onChange={almacenar}
                      />
                    </Grid>
                    <Grid item xs={3.7} py={1} mb={1}>
                      <TextField
                        style={style}
                        label="Fecha Transacción"
                        name="fecha_transaccion"
                        size="small"
                        type="date"
                        focused
                        required
                        fullWidth
                        value={moment(datosArt.fecha_transaccion).format(
                          "YYYY-MM-DD"
                        )}
                        onChange={almacenar}
                      />
                    </Grid>
                    <Grid item xs={4} py={1} mb={1}>
                      <Button
                        onClick={() => setOpenCloseAdjDoc(!openCloseAdjDoc)}
                        variant="outlined"
                        size="medium"
                        startIcon={<AttachFileIcon />}
                      >
                        ADJUNTAR DOCUMENTACIÓN
                      </Button>
                    </Grid>
                  </Grid>

                  <Stack direction="row">
                    <Grid container direction={{ xs: "column", sm: "row" }}>
                      <Grid item xs={8}>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={handleClose}
                        >
                          Volver
                        </Button>
                      </Grid>

                      <Grid item xs={4}>
                        <Button
                          disabled={disabledGuardarTr}
                          fullWidth
                          variant="contained"
                          color="success"
                          type="submit"
                        >
                          Guardar
                        </Button>
                      </Grid>
                    </Grid>
                  </Stack>
          </Box>
                </form>
                </Dialog>

    {openCloseAdjDoc ? ( // ADJUNTAR COMPROBANTES
    <FileUploadStock
      fileId={props.newUuidDoc}
      openclose={true}
      mostrar={true}
      onClose={() => handleOpenCloseAdjDoc(false)} // Proporciona una función de cierre
    />
  ) : null}
  <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={openSnack}
        autoHideDuration={1500}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnack}
          severity="success"
          sx={{ width: "100%" }}
          style={{ backgroundColor: colormensaje, color: "black" }}
        >
          {mensaje}
        </Alert>
      </Snackbar>
    </Stack>
    </>
  )
}

export default Dialog_tranferir