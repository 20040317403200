import React, { useState, useEffect } from "react";
import { UrlApi } from "../../../../services/apirest";
import { Checkbox, Grid, TextField } from "@mui/material";
import { Select, InputLabel, FormControl, MenuItem } from "@mui/material";

function EnsayoFisicoGLP(props) {
  // ITEMS ENSAYO FISICO
  const [itemsEnsayoFisico, setItemsEnsayoFisico] = useState(
    props.itemsEnsayoFisico
  );

  const [cosas, setCosas] = useState();

  const obtenerCosas = () => {
    fetch(UrlApi + "/desplegables/modulo/propelenteComp")
      .then((data) => data.json())
      .then((data) => setCosas(data));
    console.log(UrlApi + "/desplegables/modulo/propelenteComp");
  };

  const [olores, setOlores] = useState([{}]);

  const obtenerOlores = () => {
    fetch(UrlApi + "/desplegables/modulo/analisisPropelentesOlor")
      .then((data) => data.json())
      .then((data) => setOlores(data));
  };

  useEffect(() => {
    obtenerCosas();
    obtenerOlores();
  }, []);

  // CHECKBOX
  const [check, setCheck] = useState(false);

  const checkactive = () => {
    setCheck(true);
    console.log("VISIBLE: ", check);
  };

  const checkoff = () => {
    setCheck(false);
    console.log("VISIBLE: ", check);
  };

  const [alternarVisibilidad, setAlternarVisibilidad] = useState(false);

  const checkonvisibilidad = () => {
    setAlternarVisibilidad(true);
    console.log("ALTERNAR VISIBILIDAD: ", check);
  };

  const checkoffvisibilidad = () => {
    setAlternarVisibilidad(false);
    console.log("ALTERNAR VISIBILIDAD: ", check);
  };

  // -----------------PROBANDO-----------------------

  // CONSTANTES QUE PERMITEN UTILIZAR LOS CHECKBOX
  const [habilitarResiduos, setHabilitarResiduos] = React.useState(
    localStorage.getItem("toggle-switch-1") === "true"
  );
  const [habilitarHumedad, setHabilitarHumedad] = React.useState(
    localStorage.getItem("toggle-switch-2") === "true"
  );

  // ------------------TRAIGO EL LOCALSTORAGE COMO PROP------------------------------

  //   const [habilitarResiduos, setHabilitarResiduos] = React.useState(
  // props.habilitarResiduos(false)  );
  //   const [habilitarHumedad, setHabilitarHumedad] = React.useState(
  // props.habilitarHumedad(true)  );

  return (
    <>
      <form id="formcroma" autoComplete="off">
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          wrap="wrap"
          marginTop={-3}
        >
          {itemsEnsayoFisico
            ? itemsEnsayoFisico.map((item, index) => (
                <>
                  <Grid
                    m={0.8}
                    container
                    spacing={0.5}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                    wrap="wrap"
                  >
                    {/* NOMBRE ITEM */}
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        size="small"
                        name="item"
                        value={item.item}
                        disabled
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                            background: "#EBEFF1",
                          },
                        }}
                      />
                    </Grid>

                    {/* VISIBILIDAD */}
                    {item.visibilidad === 1 ? (
                      <Grid item xs={0.4}>
                        {/* <>VISIBILIDAD</> */}
                        {alternarVisibilidad ? (
                          <input
                            fullWidth
                            type="checkbox"
                            name="visible"
                            value="1"
                            onClick={() => {
                              checkoffvisibilidad();
                            }}
                            style={{
                              width: "23px",
                              height: "23px",
                              marginTop: "5px",
                              marginLeft: "5px",
                            }}
                          />
                        ) : (
                          <input
                            fullWidth
                            type="checkbox"
                            name="visible"
                            value="0"
                            onClick={() => {
                              checkonvisibilidad();
                            }}
                            style={{
                              width: "23px",
                              height: "23px",
                              marginTop: "5px",
                              marginLeft: "5px",
                            }}
                          />
                        )}{" "}
                      </Grid>
                    ) : // <>NO VISIBILIDAD</>
                    null}

                    {/* CHECKBOX VISIBLE */}
                    {/* {alternarVisibilidad & (item.visible === 1) ? ( */}
                    {item.visible === 1 ? (
                      <>
                        <Grid item xs={0.5}>
                          <b>Visible</b>
                        </Grid>

                        {/* ------ PROBANDO ------ */}
                        <Grid item xs={0.5}>
                          <input
                            fullWidth
                            type="checkbox"
                            name="visible"
                            value="1"
                            onClick={() => {
                              checkoff();
                            }}
                            style={{
                              width: "23px",
                              height: "23px",
                              marginTop: "5px",
                              marginLeft: "5px",
                            }}
                          />
                        </Grid>
                      </>
                    ) : (
                    null
                    )}

                    {/* SIGNOS (<,>,=) */}
                    {item.signo === 1 ? (
                      <>
                        <Grid item xs={1}>
                          <FormControl fullWidth focused size="small">
                            <InputLabel>Signo</InputLabel>
                            <Select
                              name="signo"
                              label="Signo"
                              required
                              InputLabelProps={{ shrink: true }}
                            >
                              {cosas
                                ? cosas.map((cosa) => (
                                    <MenuItem key={cosa.id} value={cosa.id}>
                                      {cosa.valor}
                                    </MenuItem>
                                  ))
                                : null}
                            </Select>
                          </FormControl>
                        </Grid>
                      </>
                    ) : // <>- NO SIGNO</>
                    null}

                    {/* OLOR */}
                    {item.olor === 1 ? (
                      <>
                        <Grid item xs={1.5}>
                          <FormControl fullWidth focused size="small">
                            <InputLabel>Olor</InputLabel>
                            <Select
                              name=""
                              label="Olor"
                              InputLabelProps={{ shrink: true }}
                              disabled={
                                (item.id_item === 6 && !habilitarResiduos) ||
                                (item.id_item === 8 && !habilitarHumedad)
                              }
                            >
                              {olores
                                ? olores.map((olor) => (
                                    <MenuItem key={olor.id} value={olor.id}>
                                      {olor.valor}
                                    </MenuItem>
                                  ))
                                : null}
                            </Select>
                          </FormControl>
                        </Grid>
                      </>
                    ) : // <>- NO OLOR</>
                    null}

                    {/* TEXTFIELD */}
                    {item.texto === 1 ? (
                      <>
                        <Grid item xs={1.1}>
                          <TextField
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            inputProps={{
                              maxLength: 8,
                            }}
                            size="small"
                            placeholder="-"
                            fullWidth
                            focused
                            onChange={(e) => {
                              props.updateItemsEnsayoFisico("valor", e, index);
                            }}
                            // DESHABILITAR/HABILITAR DE ACUERDO AL ESTADO DE LOS SIGUIENTES CHECKBOX:
                            disabled={
                              (item.id_item === 6 && !habilitarResiduos) ||
                              (item.id_item === 8 && !habilitarHumedad)
                            }
                            // VALOR DEL TEXTFIELD: SI EL CHECKBOX DE DISABLED ES TRUE, SU VALOR ES 0
                            // SINO, TIENE EL VALOR QUE SE LE COLOCA
                            value={
                              (item.id_item === 6 && !habilitarResiduos) ||
                              (item.id_item === 8 && !habilitarHumedad)
                                ? (props.ensayoFisico[index].valor = 0)
                                : props.ensayoFisico &&
                                  props.ensayoFisico[index] &&
                                  props.ensayoFisico[index].valor
                            }
                          />
                        </Grid>
                      </>
                    ) : // <>- NO TEXTFIELD</>
                    null}
                  </Grid>
                </>
              ))
            : null}
        </Grid>
      </form>
    </>
  );
}

export default EnsayoFisicoGLP;
