import React, { useEffect, useState, useMemo } from 'react';
import { getData, putData } from '../../../../../services/library';
import { UrlApi } from '../../../../../services/apirest';
import moment from 'moment';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import Title from '../../../../Title';
import Notify from '../../../../Notify';
import FormEditarLista from './FormEditarLista';
import AgregarMateriales from './AgregarMateriales';
import FileUploadMultiple from '../../../../../services/UploadFiles';
import { IconButton, Grid, Box, Select, FormControl, TextField, Tooltip, MenuItem, InputLabel, Container, FormControlLabel, Button, Checkbox } from '@mui/material';
import { Delete, Edit, AttachFile } from '@mui/icons-material';
import { logEventos } from '../../../../../services/services';
import DeleteDialog from '../../../../Dialogs/DeleteDialog';

export default function Editarreq1(props) {
    const globalTheme = useTheme();
    const [datosFormulario, setDatosFormulario] = useState(props.datos);
    const [listamateriales, setlistamateriales] = useState([{}])
    const [datosLista, setdatosLista] = useState({})
    const [disBtn, setDisBtn] = useState(false);
    const [openclose, setOpenClose] = useState(false);
    const [modalEditar, setmodalEditar] = useState(false)
    const [createModalOpen, setCreateModalOpen] = useState(false)
    const [eliminarModalOpen, seteliminarModalOpen] = useState(false)
    const [movil, setMovil] = useState(null);
    const [sucursales, setSucursales] = useState(null);
    const [usuarios, setUsuarios] = useState();
    const [usuariosPiden, setusuariosPiden] = useState();
    const [notificacion, setNotificacion] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
    });

    const getListaMateriales = () => {
        getData('/lista_materiales/req/' + datosFormulario.uuid_requerimiento, setlistamateriales)
    };

    useEffect(() => {
        getData("/sucursales", setSucursales);
        getData("/vehiculos", setMovil);
        getData("/userdata/autorizantes1", setUsuarios);
    }, []);

    useEffect(() => {
        getData('/userdata/noautorizantes/' + datosFormulario.id_sucursal, setusuariosPiden)
    }, [datosFormulario.id_sucursal]);

    useEffect(() => {
        getListaMateriales()
    }, []);

    const openEditar = (datosMateriales) => {
        setmodalEditar(true);
        setdatosLista(datosMateriales);
    };

    //FUNCION PARA VOLVER A LA TABLA DE REQUERIMIENTOS
    const handleClose = () => {
        props.editar(false)
        props.tabla(true)
    };

    const almacenarRequerimientos = (e) => {
        setDatosFormulario({
            ...datosFormulario,
            [e.target.name]: e.target.value ? e.target.value : ""
        })
    };

    async function guardarCabecera(e) {
        setDisBtn(true);
        putData(e,
            '/requerimientos_materiales/' + datosFormulario.idrequerimiento,
            {
                id_movil: datosFormulario.id_movil,
                id_sucursal: datosFormulario.id_sucursal,
                fecha_entrega: datosFormulario.fecha_entrega,
                usuario_registra: datosFormulario.usuario_registra,
                usuario_autoriza: datosFormulario.usuario_autoriza,
                estado: 49, //cuando se carga el pedido se pone en estado "A autorizar"
                para_comprar: datosFormulario.para_comprar === 1 ? 1 : 0,
                para_compras: datosFormulario.para_compras === 1 ? 1 : 0,
                para_rrhh: datosFormulario.para_rrhh === 1 ? 1 : 0,
                observaciones: datosFormulario.observaciones,
                comentarios_internos: datosFormulario.comentarios_internos,
                autorizado_jefe_compras: 0,
                observaciones_baja: null
            },
            setNotificacion,
            logEventos("Editar requerimientos", "Requerimientos materiales", "Requerimientos materiales", localStorage.getItem("user"))
        );
    };

    //ACA ADENTRO ESTAN LAS DOS FUNCIONES PARA GUARDAR
    const handleSubmit = (e) => {
        e.preventDefault();
        guardarCabecera(e);
    };

    const columns = useMemo(() =>
        [{ header: 'Cantidad', accessorKey: 'cantidad', size: 80 },
        { header: 'Unidad', accessorFn: (lm) => lm.descripcion ?? lm.descripcion_material },
        { header: 'Articulo', accessorFn: (lm) => lm.nombre ?? lm.material }
        ], []);

    const handleDeleteRow = async (row) => {
        seteliminarModalOpen(true)
        setdatosLista(row)
    };

    const handleCreateNewRow = (values) => {
        setCreateModalOpen(true);
    };

    const style = {
        backgroundColor: "#ffffff",
        width: "100%"
    };

    const tableHeadCellProps = {
        align: "center",
        sx: {
            fontSize: 14,
            color: "black",
            fontWeight: "normal",
            paddingTop: 1,
            paddingBottom: 1,
            backgroundColor: "#D4E6F1",
            borderColor: "#FDFEFE",
        },
    };

    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: globalTheme.palette.mode,
                    primary: globalTheme.palette.primary, //primary color for the table
                    info: {
                        main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            globalTheme.palette.mode === "light"
                                ? "rgb(234, 239, 241)"
                                : "#000", //pure black table in dark mode for fun
                    },
                },
                typography: {
                    button: {
                        textTransform: "none", //customize typography styles for all buttons in table by default
                        fontSize: "1rem",
                    },
                },
                components: {
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                fontSize: "0.8rem", //larger tooltip font size                                
                            }
                        }
                    }
                }
            }), [globalTheme]);

    const functionDeleteRow = async (e) => {
        e.preventDefault();
        await fetch(UrlApi + '/lista_materiales/' + datosLista.idlista, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' }
        })
        getListaMateriales()
        logEventos("Eliminar articulo", "Lista de materiales", "Lista de materiales", localStorage.getItem("user"))
    };

    return (
        <div>
            <Container >
                <Title
                    titulo={`Editar requerimiento nro° ` + `${datosFormulario.idrequerimiento}`}
                    handleClose={handleClose}
                />

                <form onSubmit={handleSubmit} autoComplete="off">
                    <Box
                        backgroundColor="#F4F6F6"
                        mt={2}
                        p={3}
                        mb={6}
                        border={0.5}
                        borderColor={"#ABB2B9"}
                        borderRadius={4}
                    >
                        <Grid container spacing={1} style={{ padding: 5 }} direction={{ xs: 'column', sm: 'row' }}>
                            <Grid item xs={4}>
                                <TextField
                                    name='id_sucursal'
                                    select
                                    required
                                    style={style}
                                    focused
                                    color="primary"
                                    placeholder='Elija una sucursal'
                                    value={datosFormulario.id_sucursal}
                                    onChange={almacenarRequerimientos}
                                    label="Sucursal"
                                    size='small'
                                >
                                    {(sucursales ?? []).map(elemento => (
                                        <MenuItem key={elemento.id} value={elemento.id}>
                                            {elemento.nombre}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid item xs={4}>
                                <FormControl style={style} size='small' focused color="primary">
                                    <InputLabel id="lbl_movil">Movil</InputLabel>
                                    <Select
                                        name='id_movil'
                                        value={datosFormulario.id_movil ? datosFormulario.id_movil : 'No hay registro'}
                                        onChange={almacenarRequerimientos}
                                        label="Movil">
                                        {movil ? movil.map(elemento => (
                                            <MenuItem key={elemento.idvehiculo} value={elemento.idvehiculo}>
                                                {elemento.modelo}  -  {elemento.patente}
                                            </MenuItem>
                                        )) : null}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={4}>
                                <TextField label="Fecha de entrega"
                                    required
                                    size='small'
                                    focused
                                    color="primary"
                                    style={style}
                                    name='fecha_entrega'
                                    type="date"
                                    value={moment(datosFormulario.fecha_entrega).format("YYYY-MM-DD")}
                                    onChange={almacenarRequerimientos}
                                // inputProps={{
                                //     min: moment(new Date()).format("YYYY-MM-DD")
                                // }}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <TextField
                                    style={style}
                                    size='small'
                                    name='usuario_registra'
                                    // select
                                    required
                                    margin='dense'
                                    focused
                                    color="primary"
                                    placeholder='Elija un usuario'
                                    value={datosFormulario.usuario_registra || ''}
                                    // onChange={almacenarRequerimientos}
                                    label="Registra"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                >
                                    {(usuariosPiden ?? []).map(elemento => (
                                        <MenuItem key={elemento.username} value={elemento.username}>
                                            {elemento.nombrepila}   {elemento.apellido}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            {/* <Grid item xs={3}>
                                <FormControl size='small' style={style} focused color="primary" required margin='dense' disabled>
                                    <InputLabel id="lbl_autorizante">Registra</InputLabel>
                                    <Select
                                        name='usuario_registra'
                                        value={datosFormulario.usuario_registra}
                                        onChange={almacenarRequerimientos}
                                        label="Registra">
                                        {usuariosPiden ? usuariosPiden.map(elemento => (
                                            <MenuItem key={elemento.username} value={elemento.username}>
                                                {elemento.nombrepila}   {elemento.apellido}
                                            </MenuItem>
                                        )) : null}
                                    </Select>
                                </FormControl>
                            </Grid> */}

                            <Grid item xs={3}>
                                <FormControl size='small' style={style} focused color="primary" required margin='dense'>
                                    <InputLabel id="lbl_autorizante">Autoriza</InputLabel>
                                    <Select
                                        name='usuario_autoriza'
                                        value={datosFormulario.usuario_autoriza}
                                        onChange={almacenarRequerimientos}
                                        label="Autoriza">
                                        {usuarios ? usuarios.map(elemento => (
                                            <MenuItem key={elemento.username} value={elemento.username}>
                                                {elemento.nombrepila} {elemento.apellido}
                                            </MenuItem>
                                        )) : null}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={2}>
                                <FormControlLabel
                                    disabled={datosFormulario.para_rrhh === 1 || datosFormulario.para_compras === 1}
                                    labelPlacement="bottom"
                                    label={"Para comprar"}
                                    required={(datosFormulario.para_compras === 0 || null && datosFormulario.para_rrhh === 0 || null)}
                                    checked={datosFormulario.para_comprar === 1}
                                    onChange={(e) => setDatosFormulario({ ...datosFormulario, "para_comprar": e.target.checked ? 1 : 0 })}
                                    control={<Checkbox />}
                                />
                            </Grid>
                            {datosFormulario && datosFormulario.para_comprar !== 1 ?
                                <>
                                    <Grid item xs={2}>
                                        <FormControlLabel
                                            required={(datosFormulario.para_comprar === 0 || null && datosFormulario.para_rrhh === 0 || null)}
                                            disabled={datosFormulario.para_rrhh === 1}
                                            labelPlacement="bottom"
                                            label="Interno Compras"
                                            checked={datosFormulario.para_compras === 1}
                                            onChange={(e) => setDatosFormulario({ ...datosFormulario, "para_compras": e.target.checked ? 1 : 0 })}
                                            control={<Checkbox />}
                                        />
                                    </Grid>

                                    <Grid item xs={2}>
                                        <FormControlLabel
                                            disabled={datosFormulario.para_compras === 1}
                                            required={(datosFormulario.para_comprar === 0 || null && datosFormulario.para_compras === 0 || null)}
                                            labelPlacement="bottom"
                                            label="Interno RRHH"
                                            checked={datosFormulario.para_rrhh === 1}
                                            onChange={(e) => setDatosFormulario({ ...datosFormulario, "para_rrhh": e.target.checked ? 1 : 0 })}
                                            control={<Checkbox />}
                                        />
                                    </Grid>
                                </> : null}

                            <Grid container spacing={2} alignItems='center' mt={0.5} ml={1}>
                                <Button size="small" onClick={handleCreateNewRow} color="primary" variant="contained" > Agregar articulo</Button>
                            </Grid>

                            <Box sx={{ flexGrow: 1 }} alignItems="flex" mt={2} mb={2} boxShadow={3}>
                                <ThemeProvider theme={tableTheme}>
                                    <MaterialReactTable
                                        enableColumnResizing
                                        columns={columns}
                                        data={listamateriales}
                                        enableRowActions
                                        positionActionsColumn='last'
                                        muiTableHeadCellProps={tableHeadCellProps}
                                        localization={MRT_Localization_ES}
                                        muiTableBodyCellProps={({ row }) => ({
                                            align: "center",
                                            sx: {
                                                backgroundColor: "#ffffff",
                                                color: "black",
                                                borderColor: "black",
                                            },
                                        })}
                                        enableColumnActions={false}
                                        enableDensityToggle={false}
                                        layoutMode="grid"
                                        renderRowActions={({ row, table }) => (
                                            <Box sx={{ display: 'flex', gap: '1rem' }}>
                                                <Tooltip arrow placement="left" title="Edit">
                                                    <IconButton onClick={() => openEditar(row.original)}>
                                                        <Edit />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip arrow placement="right" title="Delete">
                                                    <IconButton color="error" onClick={() => handleDeleteRow(row.original)}>
                                                        <Delete />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        )}
                                        enableExpandAll={false}
                                        enableToolbarInternalActions={true}
                                        editingMode='modal'
                                        enablePagination={false}
                                        enableFullScreenToggle={false}
                                        enableColumnFilters={false}
                                        enableHiding={false}
                                        enableBottomToolbar={false}
                                        enableTopToolbar={false}
                                        enableColumnDragging={false}
                                        positionExpandColumn='first'
                                        enableStickyFooter
                                        enableEditing
                                        enableGlobalFilter={false}
                                        initialState={{ density: "compact", }}
                                    />
                                </ThemeProvider>
                            </Box>

                            {/*OBSERVACIONES*/}
                            <Grid item xs={12}>
                                <TextField
                                    name="observaciones"
                                    size='small'
                                    type="text"
                                    inputProps={{ maxLength: 700 }}
                                    focused
                                    label="Observaciones"
                                    multiline
                                    style={style} x
                                    maxRows={4}
                                    value={datosFormulario.observaciones}
                                    onChange={almacenarRequerimientos}
                                />
                            </Grid>

                            <Grid item xs={10}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    name="comentarios_internos"
                                    type="text"
                                    inputProps={{ maxLength: 700 }}
                                    focused
                                    style={style}
                                    label="Comentarios internos"
                                    multiline
                                    value={datosFormulario.comentarios_internos}
                                    onChange={almacenarRequerimientos}
                                />
                            </Grid>

                            <Grid item xs={2}>
                                <Button onClick={() => setOpenClose(!openclose)} variant="outlined" size="small" style={{
                                    borderColor: "#13406e",
                                    backgroundColor: "#ffffff",
                                }}
                                    startIcon={<AttachFile />} >Adjuntar presupuestos</Button>
                            </Grid>

                            <Grid mt={2}
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItemns="center"
                            >
                                <Button
                                    variant="contained"
                                    onClick={handleClose}
                                    color="error">
                                    Volver
                                </Button>
                                <Button
                                    disabled={disBtn}
                                    variant="contained"
                                    type="submit"
                                    color="success">
                                    Guardar requerimiento
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            </Container>

            {openclose ? <FileUploadMultiple fileId={datosFormulario.uuid_requerimiento} openclose={true} /> : null}

            {modalEditar ? <FormEditarLista
                datos={datosLista}
                open={setmodalEditar}
                actualizar={getListaMateriales}
            /> : null}

            {createModalOpen ? <AgregarMateriales
                open={setCreateModalOpen}
                actualizar={getListaMateriales}
                uuidreq={datosFormulario.uuid_requerimiento} /> : null}

            {eliminarModalOpen ? (
                <DeleteDialog
                    title={"Eliminar Articulo"}
                    confirmText={"¿Confirma que desea eliminar el articulo de la lista?"}
                    btnText="Confirmar"
                    notify={notificacion}
                    setNotify={setNotificacion}
                    open={eliminarModalOpen}
                    setOpen={seteliminarModalOpen}
                    guardar={functionDeleteRow}
                />
            )
                : null}
            {notificacion && notificacion.open ? (
                <Notify
                    mensaje={notificacion.mensaje}
                    open={notificacion.open}
                    color={notificacion.color}
                    handleClose={handleClose}
                    severity={notificacion.severity}
                />
            ) : null}
        </div>
    );
};

//========================================================================
/*----- Component --------------------------------------------------------/
// Developer     : Candela Grosso   
// Creation Date : 29/11/2023
// Version       : 1.0  
// Description   :  Con este componente el usuario es capaz de :
    - VISUSALIZAR LOS REQUERIMIENTOS QUE HAYA CARGADO, TANTO COMO INTERNO PARA RRHH Y COMPRAS COMO PARA COMPRAR
    - EDITAR LOS REQUERIMIENTOS ANTES DE SU AUTORIZACION O UNA VEZ QUE ESTOS ESTÉN RECHAZADOS.
    - ELIMINAR REQUERIMIENTOS ANTES DE QUE ESTEN AUTORIZADOS 
    - VER EL ESTADO DE LA ORDEN DE COMPRA EN CASO DE HABER SIDO ASIGNADA A UNA.
    - VISUALIZAR UN PDF CON LA ORDEN DE COMPRA.
//------Changes --------------------------------------------------------------------- -------------//
// Change Date   : 18/4/2024
// Change by     : Candela Grosso
// Change description: agregue DeleteDialog para eliminar un articulo de la lista
//========================================================================*/
