import React, { useState, useEffect, useMemo, useRef } from "react";
import { UrlApi } from "../../../services/apirest";
import {
  Container,
  Typography,
  IconButton,
  Grid,
  Box,
  ThemeProvider,
  Tooltip,
} from "@mui/material";
import { createTheme, useTheme } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";

// Boton desplegable
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import { useLocation, useNavigate } from "react-router-dom";
import { lightBlue } from "@mui/material/colors";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import DetalleFicha from "./DetalleFicha";
import Ficha_imprimir from "./Ficha_imprimir";
import FileUploadStock from "../FileUploadStock";
import AttachFileIcon from "@mui/icons-material/AttachFile"; //ADJUNTAR IMAGEN

function Historial_fichas() {
  const [fichaImprimir, setFichaImprimir] = useState(false);
  const [rowFicha, setRowFicha] = useState();
  const [openCloseAdj, setOpenCloseAdj] = useState(false); //ADJUNTAR IMAGEN

  const handleOpenCloseAdj = (value) => {
    setOpenCloseAdj(value);
  };

  let navigate = useNavigate();
  //botones
  const location = useLocation();
  const volverAtras = () => {
    navigate(-1);
  };
  const [fichas, setFichas] = useState([]);
  const obtenerFichas = () => {
    fetch(UrlApi + "/stock_ficha_rrhh/fichasxsuc/" + location.state.id)
      .then((data) => data.json())
      .then((data) => setFichas(data));
  };

  useEffect(() => {
    obtenerFichas();
  }, []);
  //************************************TABLA**********************************************************************************/

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "primary",
    size: "small",
  };

  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };

  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem",
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const initialState = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
  };

  const columns = useMemo(
    // TABLA SALIDA
    () => [
      {
        header: "Fecha",
        accessorKey: "fecha_format",
        size: 60,
        type: "date",
        format: "YYYY-MM-DD",
      },
      { header: "Nombre", accessorKey: "nombre_empleado", size: 100 },
      { header: "Apellido", accessorKey: "apellido_empleado", size: 100 },
      // { header: "Domicilio", accessorKey: "domicilio", size: 110 },
      {
        header: "Empresa",
        accessorKey: "nombre_empresa",
        size: 100,
      },
      { header: "Sucursal", accessorKey: "nombre_sucursal", size: 65 },
    ],
    []
  );

  //PDF
  const handleCloseDialogImprimir = () => {
    setFichaImprimir(false);
    // setUpdate(true);
  };

  const componentRef = useRef();

  const handleClickOpenPdf = (row) => {
    setFichaImprimir(true);
    setRowFicha(row);
  };
  const handleClickOpenAdjuntar = (row) => {
    setOpenCloseAdj(true);
    setRowFicha(row);
  };

  // console.log("ficharow", rowFicha);

  return (
    <div>
      <AppBar
        sx={{ position: "relative" }}
        style={{ backgroundColor: "#0979b0" }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={volverAtras}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Grid container>
            <Grid item xs={4.56}>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Historial de Fichas de {location.state.nombre}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Container maxWidth="xl" mt={1}>
        <Box mt={2} mb={4}>
          <ThemeProvider theme={tableTheme}>
            <MaterialReactTable
              enableExpandAll={false}
              enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
              enableGrouping // para permitir agrupar x estado x ej
              layoutMode="grid"
              muiTableHeadCellProps={tableHeadCellProps}
              muiTableBodyCellProps={({ row }) => ({
                align: "left",
                sx: {
                  color: "black",
                  borderColor: "black",
                },
              })}
              enableRowActions={true} // Esto debe estar habilitado
              positionActionsColumn="last"
              enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
              enableColumnFilters={false} //desactiva filtros x  columna
              enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
              enableDensityToggle={false} //
              localization={MRT_Localization_ES} //idioma
              enableColumnDragging={false} //para arrastrar columnas
              initialState={initialState}
              muiSearchTextFieldProps={muiSearchTextFieldProps}
              columns={columns}
              data={fichas} //con lo q la completas (esto cambia siempre)
              enableStickyFooter
              // enableEditing
              // ACCIONES
              renderToolbarInternalActions={(
                { table } // boton para nuevo , al lado del buscar
              ) => (
                <>
                  <MRT_FullScreenToggleButton table={table} />
                </>
              )}
              renderTopToolbarCustomActions={() => <></>}
              renderDetailPanel={({ row }) => (
                <DetalleFicha row={row.original} />
              )}
              renderRowActions={({ row, table }) => (
                //las acciones del costado editar y ver
                <>
                  <div>
                    <Tooltip arrow placement="top" title="Descargar Ficha">
                      <IconButton
                        onClick={() => handleClickOpenPdf(row.original)}
                        color="primary"
                      >
                        <DownloadIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip arrow placement="top" title="Adjuntar">
                      <IconButton
                        onClick={() => handleClickOpenAdjuntar(row.original)}
                        color="primary"
                      >
                        <AttachFileIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </>
              )}
            />
          </ThemeProvider>
        </Box>
      </Container>
      {fichaImprimir ? (
        <Ficha_imprimir
          fichaImprimir={fichaImprimir}
          componentRef={componentRef}
          row={rowFicha}
          onClose={() => handleCloseDialogImprimir(false)}
        />
      ) : null}
      {openCloseAdj ? ( // ADJUNTAR IMAGEN
        <FileUploadStock
          fileId={rowFicha.uuid_ficha}
          openclose={true}
          mostrar={true}
          onClose={() => handleOpenCloseAdj(false)} // Proporciona una función de cierre
        />
      ) : null}
    </div>
  );
}

export default Historial_fichas;
