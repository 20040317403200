import React, { useEffect, useState } from "react";
import { Box, Grid, TextField, Typography } from '@mui/material';

export default function Extra(props) {

    const { total, datosCuerpo, neto, setnetoGravado, otrostributos, setotrosTributos, tiposIvaSeleccionados, saldonoaplic, setsaldonoaplic, datosImpuestos, valorPorcentajes, datosPie, setdatosPie } = props;

    const [diferenciasPorIva, setDiferenciasPorIva] = useState([{}]);

    const netoGravado = (datosCuerpo, setnetoGravado) => {
        const netoGravado1 = datosCuerpo.reduce((acc, lista) => {
            const gravado = parseFloat(lista.subtotal) || 0;
            return isNaN(gravado) ? acc : acc + gravado;
        }, 0);
        setnetoGravado(netoGravado1);
    };

    const calcularExtras = (datosImpuestos, setotrosTributos) => {
        const calcular = datosImpuestos.reduce((acc, lista) => {
            const ot = parseFloat(lista.importe) || 0;
            return isNaN(ot) ? acc : acc + ot;
        }, 0);
        setotrosTributos(calcular)
    };

    useEffect(() => {
        netoGravado(datosCuerpo, setnetoGravado, datosPie, setdatosPie);
    }, [datosCuerpo, neto]);

    useEffect(() => {
        calcularExtras(datosImpuestos, setotrosTributos)
    }, [datosImpuestos, otrostributos, datosPie]);

    useEffect(() => {
        calcularAcumuladoDiferencias()
    }, [datosCuerpo, tiposIvaSeleccionados])

    const calcularAcumuladoDiferencias = () => {
        const acumuladoDiferencias = {};

        const roundUpToTwoDecimals = (num) => {
            return Math.ceil(num * 100) / 100;
        };

        datosCuerpo.forEach(linea => {
            const idPorcentajeIva = linea.id_porcentajeiva;
            const subtotalIva = parseFloat(linea.subtotal_iva) || 0;
            const subtotal = parseFloat(linea.subtotal) || 0;
            const diferencia = subtotalIva - subtotal;

            const diferenciaRedondeada = roundUpToTwoDecimals(diferencia);

            acumuladoDiferencias[idPorcentajeIva] = (acumuladoDiferencias[idPorcentajeIva] || 0) + diferenciaRedondeada;
        });

        setDiferenciasPorIva(acumuladoDiferencias);

        const nuevosDatosPie = [];

        datosCuerpo.forEach(linea => {
            const idPorcentajeIva = linea.id_porcentajeiva;
            const existingIndex = nuevosDatosPie.findIndex(item => item.id_porcentajeiva === idPorcentajeIva);
            if (existingIndex !== -1) {
                nuevosDatosPie[existingIndex] = {
                    ...nuevosDatosPie[existingIndex],
                    importe: roundUpToTwoDecimals(acumuladoDiferencias[idPorcentajeIva]),
                    importe_neto_gr: roundUpToTwoDecimals(neto),
                    otros_tributos: roundUpToTwoDecimals(otrostributos) // Actualizar el campo importe con la diferencia por IVA
                };
            } else {
                nuevosDatosPie.push({
                    id_porcentajeiva: idPorcentajeIva,
                    importe_neto_gr: roundUpToTwoDecimals(neto),
                    otros_tributos: roundUpToTwoDecimals(otrostributos),
                    uuid_comprobante: linea.uuid_comprobante,
                    usuario: linea.usuario,
                    importe: roundUpToTwoDecimals(acumuladoDiferencias[idPorcentajeIva]) // Actualizar el campo importe con la diferencia por IVA
                });
            }
        });
        setdatosPie(nuevosDatosPie);
    };

    const setearSaldo = () => {
        let sumatoria = 0;
        datosCuerpo && datosCuerpo.forEach((item) => {
            if (parseFloat(item.subtotal_iva)) {
                sumatoria += parseFloat(item.subtotal_iva);
            }
        });
        let saldoNoAplic = total - parseFloat(sumatoria + otrostributos);

        saldoNoAplic = Math.ceil(saldoNoAplic * 100) / 100;

        setsaldonoaplic(saldoNoAplic);
    };

    useEffect(() => {
        setearSaldo()
    }, [total, datosCuerpo, otrostributos])

    const style = {
        backgroundColor: "#ffffff",
        width: "100%"
    };

    return (
        <div>
            <Box
                backgroundColor="#F4F6F6"
                mt={2}
                p={3}
                border={0.5}
                borderColor={"#ABB2B9"}
                borderRadius={4}
            >
                <Grid container spacing={1} mb={1} direction={{ xs: 'row', sm: 'row' }}>
                    <Box
                        width="100%"
                        role="presentation"
                        backgroundColor="#CCD1D1"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Typography
                            variant="overline"
                            display="block"
                            gutterBottom
                            textAlign="center"
                        >
                            Importes
                        </Typography>
                    </Box>
                    <Grid item xs={6} >
                        <TextField style={style} focused size="small" label="Importe total" value={isNaN(total) ? 0 : parseFloat(total).toFixed(2)} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField type="number" style={style} focused size="small" label="Otros tributos" value={otrostributos} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField type="number" style={style} focused size="small" label="Importe Neto Gravado" value={neto} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField style={style} focused size="small" label="Saldo no aplicado" value={isNaN(saldonoaplic) ? 0 : saldonoaplic.toFixed(2)} />
                    </Grid>

                    {diferenciasPorIva && Object.keys(diferenciasPorIva).map(idPorcentajeIva => {
                        const detalle = (valorPorcentajes && valorPorcentajes.find(item => item.idporcentaje_iva == idPorcentajeIva)?.descripcion) || '';
                        return (
                            <Grid key={idPorcentajeIva} item xs={12} justifyContent="flex-end" alignItems="center">
                                <TextField
                                    style={style}
                                    focused
                                    size="small"
                                    label={`IVA ${detalle}%`} // Usar el detalle en el label
                                    value={parseFloat(diferenciasPorIva[idPorcentajeIva]).toFixed(2)}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>

        </div >
    )
};

//========================================================================
/*----- Component --------------------------------------------------------/
// Developer     : Candela Grosso   
// Creation Date : 23/05/2024
// Version       : 1.0  
// Description   :  Componente con el formulario con los calculos para  guardar en la tabla pie_comprobante
//------Changes --------------------------------------------------------------------- -------------//
// Change Date   :
// Change by     :
// Change description: 
//========================================================================*/