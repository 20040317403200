import { Box, Checkbox, FormControlLabel, IconButton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { UrlApi } from '../../../services/apirest'
import { Save } from '@mui/icons-material';

export default function SucursalesAuth({ datos, userid, cerrarcajon, cerrarcajondatos }) {
    const [sucursales, setSucursal] = useState([])
    const [suc, setSuc] = useState(datos.split(","));
    const [sucselected, setSucSelected] = useState("");
    const [editado, setEditado] = useState(false)


    useEffect(() => {
        ///Traer sucursales para los selects
        const obtenerSucursales = async () => {
            const data = await fetch(UrlApi + "/sucursales")
            const sucursalesData = await data.json()
            setSucursal(sucursalesData)
        }
        obtenerSucursales()
    }, [])

    useEffect(() => {
        suc.length === 0 ? setSucSelected("") : setSucSelected(suc.join(","))
    }, [suc]);

    const handleSucursales = (e, value) => {
        setEditado(true)
        if (e.target.checked) {
            setSuc([...suc, e.target.value]);
        } else {
            setSuc(suc.filter((id) => id !== e.target.value));
        }
    };

    const cerrarCajon = () => {
        cerrarcajon()
        cerrarcajondatos()  ///viene del componente padre DatosDelUsuario.js
    }

    const grabarSucursalesAutorizadas = async () => {
        const data = await fetch(UrlApi + "/userdata/" + userid, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                suc_auth: sucselected,
            }),
        })
            .then(cerrarCajon)
            .catch((err) => {
                console.log(err.message);
            });
    };

    return (
        <div>
            {console.log(editado)}
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                border: '1px solid red',
                borderRadius: '5px',
                padding: '0px',
                backgroundColor: 'white',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                height: 40

            }}>
                <Typography variant="h6" mr={1} ml={1}>
                    Sucursales habilitadas
                </Typography>
                <Box sx={{ display: 'flex' }}>
                    {editado &&
                        <IconButton onClick={grabarSucursalesAutorizadas}>
                            <Save color='success' />
                        </IconButton>}
                </Box>
            </Box>


            <Box
                sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    border: '1px solid lightgrey',
                    borderRadius: '5px',
                    padding: '5px',
                    marginTop: '10px',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                }}
                component="fieldset"
            >
                {sucursales
                    ? sucursales.map((xxx) => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    color="primary"
                                    value={xxx.id}
                                    onChange={handleSucursales}
                                    keys={xxx.id}
                                    defaultChecked={datos
                                        .split(",")
                                        .includes(xxx.id.toString())}
                                />
                            }
                            label={xxx.id + "-" + xxx.nombre}
                            labelPlacement="end"
                        ></FormControlLabel>
                    ))
                    : null}

            </Box>
        </div>
    )
}
