import { UrlApi } from "../../../services/apirest";

const UseFetchProveedor = () => {
  const nuevoProveedor = async (
    DatosFormulario,
    Cuit,
    setNotificacion,
    onSuccess,
    event
  ) => {
    event.preventDefault();
    let response = await fetch(UrlApi + "/proveedores", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        razon_social: DatosFormulario.razon_social,
        nombre_fantasia: DatosFormulario.nombre_fantasia,
        direccion: DatosFormulario.direccion,
        telefono: DatosFormulario.telefono,
        email: DatosFormulario.email,
        cbu: DatosFormulario.cbu,
        id_provincia: DatosFormulario.id_provincia,
        id_localidad: DatosFormulario.id_localidad,
        observaciones: DatosFormulario.observaciones,
        sisven_id: DatosFormulario.sisven_id,
        inactivo: 0,
        nro_cuit: Number(Cuit),
        saldo_en_pesos: DatosFormulario.saldo_en_pesos,
        saldo_en_dolares: DatosFormulario.saldo_en_dolares,
        factura_sin_orden_compra: DatosFormulario.factura_sin_orden_compra,
        // orden_de_compra_obligatoria_siempre: DatosFormulario.orden_de_compra_obligatoria_siempre,
        inscripto_ganancias: DatosFormulario.inscripto_ganancias,
        es_proveedor_de_gas: DatosFormulario.es_proveedor_de_gas,
        id_condicioniva: DatosFormulario.id_condicioniva,
        id_condicionventa: DatosFormulario.id_condicionventa,
        nombre_contacto: DatosFormulario.nombre_contacto,
        telefono_contacto: DatosFormulario.telefono_contacto,
        es_instalador: DatosFormulario.es_instalador,
        usuarioapp: DatosFormulario.usuarioapp,
        id_impuestoalvalor: DatosFormulario.id_impuestoalvalor,
        id_impuestoganancia: DatosFormulario.id_impuestoganancia,
        id_motivo: DatosFormulario.id_motivo,
        vto_certificado: DatosFormulario.vto_certificado,
        id_tipopersona: DatosFormulario.id_tipopersona,
        id_tipo_operacion: DatosFormulario.id_tipo_operacion,
        motivo_otro: DatosFormulario.motivo_otro,
        usuario: localStorage.getItem("user"),
        uuid_proveedor: DatosFormulario.uuid_proveedor,
      }),
    });
    onSuccess();
    if (response.ok === false) {
      console.log("ERROR EN API");
      const error = response && response.json();
      console.error(error);
    } else {
      console.log("Conexión con api OK");
      try {
        //Parseo respuesta de la api a json()
        const json = await response.json();
        //si api me devuelve lo q le puse en message
        if (json.message) {
          //Si ese mensaje es "OK"
          if (json.message === "OK") {
            console.log("Guardado Satisfactoriamente");
            setNotificacion({
              open: true,
              mensaje: "Guardado Satisfactoriamente",
              severity: "success",
              color: "#D4EFDF",
            });
          } else {
            console.log("res en api incorrecto. Revisar que sea 'OK'");
          }
        } else if (json.sqlMessage) {
          json && console.error(json);
          console.log(
            "SQL erróneo (" + json.code + " Errno: " + json.errno + " )"
          );
          setNotificacion({
            open: true,
            mensaje: "Error SQL. Contacte a soporte.",
            severity: "error",
            color: "#FADBD8",
          });
        } else {
          console.error("Error Desconocido");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          setNotificacion({
            open: true,
            mensaje:
              "Guardado. Advertencia: Actualizar respuesta de api. JSON invalido.",
            severity: "success",
            color: "#D4EFDF",
          });
        } else {
          console.error("Error Desconocido");
        }
      }
    }
    // HandleVolver()
  };

  const ActualizarProveedor = async (
    Proveedor,
    setNotificacion,
    onSuccess,
    event
  ) => {
    event.preventDefault();
    let response = await fetch(UrlApi + "/proveedores/" + Proveedor.id, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        razon_social: Proveedor.razon_social,
        nombre_fantasia: Proveedor.nombre_fantasia,
        nombre_contacto: Proveedor.nombre_contacto,
        telefono_contacto: Proveedor.telefono_contacto,
        sisven_id: Proveedor.sisven_id,
        direccion: Proveedor.direccion,
        telefono: Proveedor.telefono,
        email: Proveedor.email,
        cbu: Proveedor.cbu,
        id_provincia: Proveedor.id_provincia,
        id_localidad: Proveedor.id_localidad,
        observaciones: Proveedor.observaciones,
        inactivo: 0,
        saldo_en_pesos: Proveedor.saldo_en_pesos,
        nro_cuit: Proveedor.nro_cuit,
        saldo_en_dolares: Proveedor.saldo_en_dolares,
        factura_sin_orden_compra: Proveedor.factura_sin_orden_compra,
        inscripto_ganancias: Proveedor.inscripto_ganancias,
        es_proveedor_de_gas: Proveedor.es_proveedor_de_gas,
        id_condicioniva: Proveedor.id_condicioniva,
        id_condicionventa: Proveedor.id_condicionventa,
        es_instalador: Proveedor.es_instalador,
        usuarioapp: Proveedor.usuarioapp,
        id_impuestoalvalor: Proveedor.id_impuestoalvalor,
        id_impuestoganancia: Proveedor.id_impuestoganancia,
        id_motivo: Proveedor.id_motivo,
        vto_certificado: Proveedor.vto_certificado,
        id_tipopersona: Proveedor.id_tipopersona,
        id_tipo_operacion: Proveedor.id_tipo_operacion,
        motivo_otro: Proveedor.motivo_otro,
        usuario: localStorage.getItem("user"),
      }),
    });
    onSuccess();
    if (response.ok === false) {
      console.log("ERROR EN API");
      const error = response && response.json();
      console.error(error);
    } else {
      console.log("Conexión con api OK");
      try {
        //Parseo respuesta de la api a json()
        const json = await response.json();
        //si api me devuelve lo q le puse en message
        if (json.message) {
          //Si ese mensaje es "OK"
          if (json.message === "OK") {
            console.log("Guardado Satisfactoriamente");
            setNotificacion({
              open: true,
              mensaje: "Guardado Satisfactoriamente",
              severity: "success",
              color: "#D4EFDF",
            });
          } else {
            console.log("res en api incorrecto. Revisar que sea 'OK'");
          }
        } else if (json.sqlMessage) {
          json && console.error(json);
          console.log(
            "SQL erróneo (" + json.code + " Errno: " + json.errno + " )"
          );
          setNotificacion({
            open: true,
            mensaje: "Error SQL. Contacte a soporte.",
            severity: "error",
            color: "#FADBD8",
          });
        } else {
          console.error("Error Desconocido");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          setNotificacion({
            open: true,
            mensaje:
              "Guardado. Advertencia: Actualizar respuesta de api. JSON invalido.",
            severity: "success",
            color: "#D4EFDF",
          });
        } else {
          console.error("Error Desconocido");
        }
      }
    }

    // HandleVolver()
  };
  // HandleVolver()

  return {
    nuevoProveedor,
    ActualizarProveedor,
  };
};

export default UseFetchProveedor;
