import React,{useState,useEffect} from 'react'
import {Container} from '@mui/material'
import Navbar from '../Navbar';
const Rendiciones = () => {

  const [Habilitado, setHabilitado] = useState(true)
    
  return (
  <>
    <Navbar />
    <Container maxWidth="xl">
      
    </Container>
    </>
  )
}

export default Rendiciones