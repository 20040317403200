//----- Component --------------------------------------------------------/
// Developer     : Guillermo Sedran
// Creation Date : 13/11/2023
// Version       : 1
// Description   : Menú con las acciones de un hallazgo y sus estados
//
//------Changes --------------------------------------------------------------------- -------------//
// Change Date   :
// Change by     :
// Change description:
//
// Change Date   :
// Change by     :
// Change description:
//
//========================================================================/
import React, { useEffect, useState } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  Stack,
  Grid,
  Tooltip,
  TextField,
} from "@mui/material";

// LOG EVENTOS
import { logEventos } from "../../services/services";

// ENVIAR MAILS
import { enviarmails } from "../../services/services";

// ICONOS
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ErrorIcon from "@mui/icons-material/Error";

// Notification
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import Auditorias_agregar_accion from "./Auditorias_agregar_accion";

//ADJUNTAR ARCHIVOS
import { v4 as uuidv4 } from "uuid"; //ADJUNTAR ARCHIVOS
import FileUploadAccion from "./FileUploadAccion";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Auditorias_acciones(props) {
  const [auditoriasAcciones, setAuditoriasAcciones] = useState([]);
  const [auditoriasAccionesFecha, setAuditoriasAccionesFecha] = useState([]);
  // Obtengo el estado (Iniciado, finalizado)
  const [auditoriasAccionesEstado, setAuditoriasAccionesEstado] = useState([]);
  // Obtengo el ID del estado
  const [auditoriasAccionesEstado2, setAuditoriasAccionesEstado2] = useState(
    []
  );
  const [auditoriasAccionesID, setAuditoriasAccionesID] = useState([]);

  const [auditoriasAccionesUUID, setAuditoriasAccionesUUID] = useState([]);
  const [
    auditoriasAccionesUsuarioQueRegistro,
    setAuditoriasAccionesUsuarioQueRegistro,
  ] = useState([]);
  const [
    auditoriasAccionesUsuarioQueFinalizo,
    setAuditoriasAccionesUsuarioQueFinalizo,
  ] = useState([]);

  const [accionSeleccionada, setAccionSeleccionada] = useState("");
  const [idSeleccionado, setIdSeleccionado] = useState(""); // Estado para almacenar el ID seleccionado

  const [datosAuditoriaAcciones, setDatosAuditoriaAcciones] = useState({});

  const [row, setRow] = useState(props.row);

  // console.log("ROW", row);

  // PARA ACTIVAR/DESACTIVAR BOTÓN DE GUARDAR CAMBIOS AL EDITAR
  const [valorInicial, setValorInicial] = useState("");
  const [hayCambios, setHayCambios] = useState(false);
  const [valoresListos, setValoresListos] = useState(false);

  //*************************************************************************************************************************************/

  // ADJUNTOS
  const [uuid_accion, setUuid_accion] = useState(uuidv4()); //ADJUNTAR ARCHIVOS
  const [openCloseAdj, setOpenCloseAdj] = useState(false); //ADJUNTAR COMPROBANTE
  const [newUuid, setNewUuid] = useState(""); //ADJUNTAR COMPROBANTE

  const handleOpenCloseAdj = (value) => {
    setOpenCloseAdj(value);
  };

  //*************************************************************************************************************************************/

  // Función para volver a obtener las auditorías
  const actualizarAuditorias = () => {
    props.obtenerAuditorias(); // Llama a la función que realiza el fetch
    // console.log("PROP PARA ACTUALIZACION DE TABLA PRINCIPAL");
  };

  // OBTENER FECHA ACTUAL
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);

  //*************************************************************************************************************************************/

  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");

  //*************************************************************************************************************************************/

  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
    setOpenEditar(false);
    setOpenDialogEliminar(false);
    setOpenDialogFinalizar(false);
    obtenerAuditoriasAcciones();
  };

  // ---------------------------------------------------------------

  const obtenerAuditoriasAcciones = () => {
    fetch(UrlApi + "/auditorias_acciones/hallazgo/" + row.original.id_hallazgo)
      .then((data) => data.json())
      .then((data) => {
        const accionesRealizadas = data.map(
          (accion) => accion.accion_realizada
        );
        const accionesRealizadasFecha = data.map((fecha) => fecha.fecha);
        const accionesRealizadasEstado = data.map((estado) => estado.valor);
        const accionesRealizadasEstado2 = data.map((estado) => estado.estado);
        const accionesRealizadasID = data.map(
          (id_accion) => id_accion.id_accion
        );
        const accionesRealizadasUUID = data.map(
          (uuid_accion) => uuid_accion.uuid_accion
        );
        const auditoriasAccionesUsuarioQueRegistro = data.map(
          (usuario_que_registra) => usuario_que_registra.usuario_que_registra
          // (username) => username.username

        );
        const auditoriasAccionesUsuarioQueFinalizo = data.map(
          (usuario_que_finaliza) => usuario_que_finaliza.usuario_que_finaliza,

          // (username) => username.username
        );
        setAuditoriasAcciones(accionesRealizadas);
        setAuditoriasAccionesFecha(accionesRealizadasFecha);
        setAuditoriasAccionesEstado(accionesRealizadasEstado);
        setAuditoriasAccionesEstado2(accionesRealizadasEstado2);
        setAuditoriasAccionesID(accionesRealizadasID);
        setAuditoriasAccionesUUID(accionesRealizadasUUID);
        setAuditoriasAccionesUsuarioQueRegistro(
          auditoriasAccionesUsuarioQueRegistro
        );
        setAuditoriasAccionesUsuarioQueFinalizo(
          auditoriasAccionesUsuarioQueFinalizo
        );
      });
  };


// console.log("auditoriasAccionesUsuarioQueRegistro: ", auditoriasAccionesUsuarioQueRegistro)
// console.log("auditoriasAccionesUsuarioQueFinalizo: ", auditoriasAccionesUsuarioQueFinalizo)

  // ---------------------------------------------------------------

  useEffect(() => {
    obtenerAuditoriasAcciones();
  }, []);

  // ---------------------------------------------------------------
  //  DIALOG PARA CARGA DE ACCION
  const [openDialogAgregarAccion, setOpenDialogAgregarAccion] =
    React.useState(false);

  const handleCloseDialogCrearAccion = () => {
    setOpenDialogAgregarAccion(!openDialogAgregarAccion);
    // RECARGAR TABLA
    obtenerAuditoriasAcciones();
  };

  const handleOpenDialogCrearAccion = () => {
    setOpenDialogAgregarAccion(true);
    // GENERAR UUID PARA ARCHIVOS ADJUNTOS
    setUuid_accion(uuidv4());
    setNewUuid(uuid_accion);
  };

  //*************************************************************************************************************************************/

  // DIALOG PARA EDITAR LOS DATOS
  const [openEditar, setOpenEditar] = React.useState(false);

  // TOMO LOS DATOS DE ESA FILA
  const handleClickOpenEditar = (accion, id) => {
    setOpenEditar(true);
    setValorInicial(accion);
    setAccionSeleccionada(accion);
    setIdSeleccionado(id);
    setValoresListos(true);

    // console.log("ACCION REALIZADA / VALOR INICIAL: ", accion);
    // console.log("ID SELECCIONADO: ", id);
  };

  const handleCloseEditar = () => {
    setOpenEditar(false);
    setAccionSeleccionada("");
    setValorInicial("");
    setValoresListos(false);
  };

  //**********************************************************************************************/

  // DIALOG PARA ELIMINAR LA ACCIÓN
  const [openDialogEliminar, setOpenDialogEliminar] = React.useState(false);

  const dialogEliminar = (id, accion) => {
    setOpenDialogEliminar(true);
    setAccionSeleccionada(accion);
    // console.log("ACCION REALIZADA: ", accionSeleccionada);

    setIdSeleccionado(id);
    // console.log("ID SELECCIONADO: ", id);
  };

  const handleCloseDialogEliminar = () => {
    setOpenDialogEliminar(!openDialogEliminar);
  };

  //**********************************************************************************************/

  // DIALOG PARA FINALIZAR LA ACCIÓN
  const [openDialogFinalizar, setOpenDialogFinalizar] = React.useState(false);

  const dialogFinalizar = (id) => {
    setOpenDialogFinalizar(true);
    setIdSeleccionado(id);
    // console.log("ID SELECCIONADO: ", id);
  };

  const handleCloseDialogFinalizar = () => {
    setOpenDialogFinalizar(!openDialogFinalizar);
  };

  //**********************************************************************************************/

  const guardarEditar = async (e) => {
    logEventos(
      "Guardar editar accion",
      "Auditorias",
      "Se guardo la edicion de una accion",
      localStorage.getItem("user")
    );
    // console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(
      UrlApi + "/auditorias_acciones/" + idSeleccionado, // Usar idSeleccionado en lugar de datosAuditoriaAcciones.id_accion
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          accion_realizada: accionSeleccionada,
        }),
      }
    )
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("La acción se guardó exitosamente !");
          console.log("Guardado");
          enviarmails(
            row.original.email_auditor,
            "Auditoria: Acción Editada",
            `
            <div style="background-color: #f2f2f2; border-radius: 10px; padding: 20px; text-align: center;">
            <p>Se editó una acción del hallazgo "${row.original.problema_sugerencia}" de la sucursal: ${row.original.sucursal} .</p>
            <p><b>Descripción de la acción:</b> "${accionSeleccionada}" .</p>
            </div>`
          );
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar la acción");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    console.log(datosAuditoriaAcciones);
  };

  //*************************************************************************************************************************************/

  const guardarFinalizar = async (e) => {
    logEventos(
      "Finalizar accion",
      "Auditorias",
      "Finalizar accion de hallazgo",
      localStorage.getItem("user")
    );

    // console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(UrlApi + "/auditorias_acciones/" + idSeleccionado, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        // 183 = FINALIZADO
        estado: 183,
        usuario_que_finaliza: localStorage.getItem("user")
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("La acción se guardó exitosamente !");
          console.log("Guardado");
          props.closeSnackPrincipal();
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar la acción");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    // console.log(datosAuditoriaAcciones);

    await fetch(
      UrlApi +
        "/auditorias_acciones/hallazgo/sincancelado/" +
        row.original.id_hallazgo
    )
      .then((data) => data.json())
      .then(async (data) => {
        const todasAccionesFinalizadas = data.every(
          // 183 = FINALIZADO
          (accion) => accion.estado === 183
        );

        if (todasAccionesFinalizadas) {
          fetch(UrlApi + "/auditorias/" + row.original.id_hallazgo, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              estado: 180,
            }),
          });
          // console.log(
          //   "TODAS LAS ACCIONES DEL HALLAZGO " +
          //     row.original.id_hallazgo +
          //     " FINALIZADAS"
          // );
          enviarmails(
            row.original.email_auditor,
            "Auditoria: Hallazgo Finalizado",
            `
            <div style="background-color: #f2f2f2; border-radius: 10px; padding: 20px; text-align: center;">

            <p>Se finalizó el hallazgo "${row.original.problema_sugerencia}" de la sucursal: ${row.original.sucursal} .</p>
            </div>`
          );
        } else {
          fetch(UrlApi + "/auditorias/" + row.original.id_hallazgo, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              estado: 179,
            }),
          });
        }
      })

      .catch((err) => {
        console.log(err.message);
      });
    // Después de actualizar el estado de la auditoría, Llamo a la función que actualiza las auditorías
    actualizarAuditorias();
  };

  //*************************************************************************************************************************************/

  const guardarEliminar = async (e) => {
    logEventos(
      "Eliminar accion",
      "Auditorias",
      "Eliminar accion de hallazgo",
      localStorage.getItem("user")
    );

    // console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(
      UrlApi + "/auditorias_acciones/" + idSeleccionado, // Usar idSeleccionado en lugar de datosAuditoriaAcciones.id_accion
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          estado: 185,
          usuario_que_finaliza: localStorage.getItem("user")
        }),
      }
    ).then((res) => {
      if (!res.err) {
        setOpenSnack(true);
        setColorMensaje("#D4EFDF");
        setMensaje("La acción se guardó exitosamente !");
        // console.log("Guardado");
      } else {
        setOpenSnack(false);
        setColorMensaje("#F1948A");
        setMensaje("Error al guardar la acción");
        // console.log("Error");
      }
    });

    await fetch(
      UrlApi +
        "/auditorias_acciones/hallazgo/sincancelado/" +
        row.original.id_hallazgo
    )
      .then((data) => data.json())
      .then(async (data) => {
        const todasAccionesFinalizadas = data.every(
          // 183 = FINALIZADO
          (accion) => accion.estado === 183
        );

        if (todasAccionesFinalizadas) {
          fetch(UrlApi + "/auditorias/" + row.original.id_hallazgo, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              // 180 = FINALIZADO
              estado: 180,
            }),
          });
          // console.log(
          //   "TODAS LAS ACCIONES DEL HALLAZGO " +
          //     row.original.id_hallazgo +
          //     " FINALIZADAS"
          // );
        } else {
          fetch(UrlApi + "/auditorias/" + row.original.id_hallazgo, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              // 179 = EN PROCESO
              estado: 179,
            }),
          });
        }
      })

      .catch((err) => {
        console.log(err.message);
      });
    // console.log(datosAuditoriaAcciones);
    // Después de actualizar el estado de la auditoría, Llamo a la función que actualiza las auditorías
    await actualizarAuditorias();
  };

  //*************************************************************************************************************************************/

  const almacenar = (e) => {
    setDatosAuditoriaAcciones({
      ...datosAuditoriaAcciones,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    actualizarAuditorias();
  }, []);

  //*************************************************************************************************************************************/

  const formatFecha = (fecha) => {
    const date = new Date(fecha);
    const dia = date.getDate() + 1; // Sumamos 1 porque los dias empiezan con 0
    const mes = date.getMonth() + 1; // Sumamos 1 porque los meses empiezan con 0
    const año = date.getFullYear();

    // Formateo para obtener "DD/MM/AAAA"
    const fechaFormateada = `${dia}/${mes}/${año}`;
    return fechaFormateada;
  };

  //*************************************************************************************************************************************/

  // console.log("datosAuditoriaAcciones", datosAuditoriaAcciones);
  // console.log("Auditorias Acciones", auditoriasAcciones);
  // console.log("ACCION DEL EDITAR: ", accionSeleccionada);
  // console.log("ACCION DEL EDITAR 2: ", auditoriasAccionesID);
  // console.log("UUID DE LA ACCION: ", auditoriasAccionesUUID);

  //*************************************************************************************************************************************/

  // Comparar el valor inicial con el valor actual del Textfield
  React.useEffect(() => {
    if (accionSeleccionada !== "" && valorInicial !== "" && valoresListos) {
      if (accionSeleccionada !== valorInicial) {
        setHayCambios(true);
        // console.log("HAY CAMBIOS!");
      } else {
        setHayCambios(false);
        // console.log("NO HAY CAMBIOS");
      }
    }
  }, [accionSeleccionada, valorInicial, valoresListos]);

  return (
    <div>
      <Container maxWidth="xl">
        <Box mt={1} mb={1}>
          <Grid container mb={1}>
            {
              // ESTÁ APROBADO?
              row.original.estado === 181 ? (
                // ES AUDITOR?
                localStorage.getItem("auditor") === 1 ? (
                  // SI ES ADMIN Y ESTÄ EL HALLAZGO APROBADO
                  <>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddBoxTwoToneIcon />}
                        onClick={handleOpenDialogCrearAccion}
                        size="small"
                      >
                        Agregar Acción
                      </Button>
                    </Grid>
                  </>
                ) : // SI EL HALLAZGO ESTÁ APROBADO Y NO ES AUDITOR
                null
              ) : (
                // SI EL HALLAZGO NO ESTÁ APROBADO
                <Grid item>
                  {parseInt(localStorage.getItem("sector")) ===
                    parseInt(row.original.id_sector_delegado) &&
                  row.original.estado_delegacion === 1 ? (
                    // Verifica si el sector coincide y el estado_delegacion es 1 (DELEGACIÓN ACEPTADA)
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddBoxTwoToneIcon />}
                      onClick={handleOpenDialogCrearAccion}
                      size="small"
                    >
                      Agregar Acción
                    </Button>
                  ) : parseInt(localStorage.getItem("sector")) ===
                      parseInt(row.original.id_sector_delegado) &&
                    row.original.estado_delegacion !== 1 ? (
                    // Si hay sector delegado pero estado_delegacion no es 1 (TODAVIA NO ACEPTÓ/RECHAZÓ)
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <ErrorIcon
                        style={{
                          color: "orange",
                          marginRight: "5px",
                          marginBottom: "2px",
                        }}
                      />
                      <strong>
                        Debe aceptar la delegación para agregar una acción
                      </strong>
                    </span>
                  ) : (
                    <>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<AddBoxTwoToneIcon />}
                          onClick={handleOpenDialogCrearAccion}
                          size="small"
                        >
                          Agregar Acción
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>
              )
            }
          </Grid>

          {auditoriasAcciones.map((accion, index) => (
            <>
              <div
                style={{
                  padding: "5px",
                  borderRadius: "10px",
                  width: "170%", // Ocupa todo el ancho disponible
                  textAlign: "left", // Alinea el contenido a la izquierda
                  marginLeft: "-5px",
                }}
              >
                {/* PARA CADA ACCIÓN */}
                <div
                  style={{
                    backgroundColor:
                      auditoriasAccionesEstado2[index] === 185
                        ? // ? "#FF5050" // Rojo para id 185
                          "#DE0B0B" // Rojo para id 185
                        : auditoriasAccionesEstado2[index] === 183
                        ? // ? "#66FF66" // Verde para id 183
                          "#2ADC2A" // Verde para id 183
                        : "#F0F0F0", // Gris para cualquier otro id
                    padding: "5px",
                    borderRadius: "10px",
                    display: "flex", // Alinear los elementos en una fila
                    alignItems: "center", // Centra verticalmente los elementos
                    gap: "10px", // Espacio entre los elementos
                    border: "1px solid black", // Borde negro
                  }}
                >
                  <p key={index}>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        marginBottom: "5px",
                        marginTop: "-10px",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "rgba(255, 255, 255, 0.7)",
                          padding: "8px",
                          borderRadius: "12px",
                        }}
                      >
                        <b>Realización:</b>{" "}
                        {formatFecha(auditoriasAccionesFecha[index])}
                      </div>
                      <div
                        style={{
                          backgroundColor: "rgba(255, 255, 255, 0.7)",
                          padding: "8px",
                          borderRadius: "12px",
                        }}
                      >
                        <b>Estado:</b> {auditoriasAccionesEstado[index]}
                      </div>
                      <div
                        style={{
                          backgroundColor: "rgba(255, 255, 255, 0.7)",
                          padding: "8px",
                          borderRadius: "12px",
                        }}
                      >
                        <b>Registrado Por:</b>{" "}
                        {auditoriasAccionesUsuarioQueRegistro[index]}
                      </div>
                      <div
                        style={{
                          backgroundColor: "rgba(255, 255, 255, 0.7)",
                          padding: "8px",
                          borderRadius: "12px",
                        }}
                      >
                        <b>Finalizado Por:</b>{" "}
                        {auditoriasAccionesUsuarioQueFinalizo[index]}
                      </div>
                    </div>
                    <p style={{ marginTop: "10px", marginBottom: "-2px" }}>
                      <b style={{ marginLeft: "8px" }}>{accion}</b>
                    </p>
                  </p>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginLeft: "auto",
                      borderRadius: "10px",
                      backgroundColor: "#e0e0e0", // Color grisáceo
                      borderRight: "3px solid black", // Línea negra a la izquierda
                      borderBottom: "2px solid black", // Línea negra a la izquierda
                    }}
                  >
                    {/* Si el estado es "Iniciado" muestro los botones para editar y cancelar acción */}
                    {auditoriasAccionesEstado2[index] === 182 ? (
                      <>
                        <Tooltip arrow placement="bottom" title={"Editar"}>
                          <IconButton
                            style={{ padding: "6px", minWidth: "auto" }}
                            color="primary"
                            onClick={() =>
                              handleClickOpenEditar(
                                accion,
                                auditoriasAccionesID[index]
                              )
                            }
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>

                        {/* ---------- PARA AUDITOR / CONTROLADOR ---------- */}
                        {parseInt(localStorage.getItem("auditor")) === 1 ||
                        parseInt(localStorage.getItem("controlador")) === 1 ? (
                          <Tooltip
                            arrow
                            placement="bottom"
                            title={"Cancelar Acción"}
                          >
                            <IconButton
                              style={{ padding: "6px", minWidth: "auto" }}
                              color="error"
                              onClick={() =>
                                dialogEliminar(auditoriasAccionesID[index])
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        ) : null}
                        {/* ----------------------------------------------- */}

                        {/* ADJUNTAR ARCHIVOS */}
                        {auditoriasAccionesUUID[index] !== null && (
                          <Tooltip arrow placement="bottom" title="Ver adjunto">
                            <Button
                              style={{ padding: "6px", minWidth: "auto" }}
                              backgroundColor="#ffffff"
                              size="small"
                              onClick={() => {
                                setNewUuid(auditoriasAccionesUUID[index]);
                                setOpenCloseAdj(!openCloseAdj);
                              }}
                            >
                              <AttachFileIcon />
                            </Button>
                          </Tooltip>
                        )}
                      </>
                    ) : (
                      <>
                        {/* ADJUNTAR ARCHIVOS */}
                        {auditoriasAccionesUUID[index] !== null && (
                          <Tooltip arrow placement="bottom" title="Ver adjunto">
                            <Button
                              style={{ padding: "6px", minWidth: "auto" }}
                              backgroundColor="#ffffff"
                              size="small"
                              onClick={() => {
                                setNewUuid(auditoriasAccionesUUID[index]);
                                setOpenCloseAdj(!openCloseAdj);
                              }}
                            >
                              <AttachFileIcon />
                            </Button>
                          </Tooltip>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          ))}
        </Box>
      </Container>

      {openCloseAdj ? ( // ADJUNTAR
        <FileUploadAccion
          fileId={newUuid}
          openclose={true}
          mostrar={true}
          onClose={() => handleOpenCloseAdj(false)} // Proporciona una función de cierre
        />
      ) : null}

      {/* AGREGAR ACCION */}
      <Dialog
        open={openDialogAgregarAccion}
        onClose={handleCloseDialogCrearAccion}
        maxWidth="md"
        fullWidth
      >
        <Box
          style={{
            borderWidth: 2,
            borderStyle: "solid",
          }}
        >
          <Auditorias_agregar_accion
            row={row}
            openCrearAccion={openDialogAgregarAccion}
            date={date}
            // NOTIFICACION
            openSnack={openSnack}
            colormensaje={colormensaje}
            mensaje={mensaje}
            // ADJUNTOS
            newUuid={newUuid}
            // ACTUALIZAR
            actualizarAuditorias={actualizarAuditorias}
            // CERRAR
            handleCloseDialogCrearAccion={handleCloseDialogCrearAccion}
          />
        </Box>
      </Dialog>

      {/* EDITAR ITEM */}
      <Dialog
        open={openEditar}
        onClose={handleCloseEditar}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle marginLeft={3}>
          <b>{"Editar Acción"}</b>
        </DialogTitle>
        <DialogContent>
          <Container maxWidth="xl">
            <form onSubmit={guardarEditar}>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={12} mb={3}>
                  <TextField
                    fullWidth
                    size="small"
                    value={accionSeleccionada}
                    onChange={(e) =>
                      setAccionSeleccionada(e.currentTarget.value)
                    }
                  />
                </Grid>
              </Grid>

              <Stack direction="row">
                <Grid container direction={{ xs: "column", sm: "row" }}>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleCloseEditar}
                    >
                      Volver
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => dialogFinalizar(idSeleccionado)}
                      disabled={hayCambios} // Deshabilitar el botón si hay cambios en el Textfield
                    >
                      Finalizar Acción
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!hayCambios} // Deshabilitar el botón si no hay cambios en el Textfield
                    >
                      Guardar Cambios
                    </Button>
                  </Grid>
                </Grid>
              </Stack>
            </form>
          </Container>
        </DialogContent>
      </Dialog>

      {/* DIALOG PARA CANCELAR LA ACCIÓN */}
      <Dialog open={openDialogEliminar} onClose={handleCloseDialogEliminar}>
        <Box
          style={{
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: "#e32712",
            color: "red",
          }}
        >
          <DialogContent>
            <DialogTitle textAlign={"center"}>
              {"¿Confirma que quiere cancelar esta acción?"}
            </DialogTitle>
          </DialogContent>
          <DialogActions>
            <Stack spacing={4} direction="row">
              <Grid item mr={16}>
                <Button
                  style={{ backgroundColor: "#D0D3D4", color: "#000000" }}
                  variant="contained"
                  onClick={handleCloseDialogEliminar}
                >
                  Volver
                </Button>
              </Grid>

              <Button
                style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
                onClick={guardarEliminar}
                variant="contained"
              >
                Confirmar
              </Button>
            </Stack>
          </DialogActions>
        </Box>
      </Dialog>

      {/* DIALOG PARA BLOQUEAR/DESBLOQUEAR LA RELACION */}
      <Dialog open={openDialogFinalizar} onClose={handleCloseDialogFinalizar}>
        <Box
          style={{
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: "#24ff1c",
            color: "green",
          }}
        >
          <DialogContent>
            <DialogTitle textAlign={"center"}>
              {"¿Confirma que quiere finalizar esta acción?"}
            </DialogTitle>
          </DialogContent>
          <DialogActions>
            <Stack spacing={4} direction="row">
              <Grid item mr={16}>
                <Button
                  style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
                  variant="contained"
                  onClick={handleCloseDialogFinalizar}
                >
                  Volver
                </Button>
              </Grid>

              <Button
                style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                onClick={guardarFinalizar}
                variant="contained"
              >
                Confirmar
              </Button>
            </Stack>
          </DialogActions>
        </Box>
      </Dialog>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
}

export default Auditorias_acciones;
