//----- Component --------------------------------------------------------/
// Developer: Magalí Perea
// Creation Date:
// Version:
// Description: Desarrollo de los tabs mostrados en el alta/modificacion/visualización de la factibilidad.

import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import CheckIcon from "@mui/icons-material/Check";

function TabPanel(props) {


  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <div>
      <Box sx={{ borderBottom: 1, borderColor: "divider", maxHeight: "50px" }}>
        <Tabs
          value={props.activeTab}
          onChange={(event, newValue) => props.setActiveTab(newValue)}
          aria-label="basic tabs example"
          centered
          variant="fullWidth"
          sx={{ maxHeight: "50px" }}
        >
          {props.dataFact.id_tipofact === 235 && (
            <Tab
              value="cliente"
              label="cliente"
              icon={
                props.validado.cliOK ? (
                  <CheckIcon sx={{ fontSize: 25 }} color="primary" />
                ) : null
              }
              iconPosition="end"
              sx={{
                marginTop: props.validado.cliOK ? -1 : 0,
                maxHeight: "50px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
          )}
          {/* {props.id_tipofact!== && ( */}
            <Tab
            value="domicilio"
            label="Domicilio"
            {...a11yProps(1)}
            icon={
              props.validado.dirOK ? (
                <CheckIcon sx={{ fontSize: 25 }} color="primary" />
              ) : null
            }
            iconPosition="end"
            sx={{
              marginTop: props.validado.dirOK ? -1 : 0,
              maxHeight: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
          {/* )} */}
          
          <Tab
            value="artefactos"
            label="Artefactos"
            {...a11yProps(2)}
            icon={
              props.validado.artOK ? (
                <CheckIcon sx={{ fontSize: 25 }} color="primary" />
              ) : null
            }
            iconPosition="end"
            sx={{
              marginTop: props.validado.artOK ? -1 : 0,
              maxHeight: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
          <Tab
            value="tanques"
            label="Tanques"
            {...a11yProps(3)}
            icon={
              props.validado.tksOK ? (
                <CheckIcon sx={{ fontSize: 25 }} color="primary" />
              ) : null
            }
            iconPosition="end"
            sx={{
              marginTop: props.validado.tksOK ? -1 : 0,
              maxHeight: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
          />
          <Tab
            value="costos"
            label="Costos"
            {...a11yProps(4)}
            icon={
              props.validado.costosOK ? <CheckIcon color="primary" /> : null
            }
            iconPosition="end"
            sx={{
              marginTop: props.validado.costosOK ? -1 : 0,
              maxHeight: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        </Tabs>
      </Box>
    </div>
  );
}

export default TabPanel;
