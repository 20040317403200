import React from "react";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";


function TitleUploaded(props) {
  return (
    <div>
      <Grid
        container
        backgroundColor="#1f618d"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        borderRadius={10}
        mt={1.5}
        mb={0.5}
        p={0.5}
      >
        {props.handleClose?(
          <IconButton ml={2} onClick={props.handleClose}>
          <ArrowBackIosIcon sx={{ fontSize: 16, color: "#fff" }} />
        </IconButton>
        ):null}
        <Typography
          variant="body2"
          color="white"
          sx={{ fontSize: 20, margin: "auto", textAlign: "center" }}
        >
            {props.title}
        </Typography>
      </Grid>
    </div>
  );
}

export default TitleUploaded;
