import React from "react";
import { useState } from "react";
import { UrlApi } from "../../../../../services/apirest";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import CheckIcon from "@mui/icons-material/Check";
import ArticleIcon from "@mui/icons-material/Article";
import moment from "moment";
import TimelineIcon from "@mui/icons-material/Timeline";
import EventIcon from "@mui/icons-material/Event";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import {
  MenuItem,
  IconButton,
  Button,
  Grid,
  InputLabel,
  InputAdornment,
  TextField,
  Select,
  FormControl,
  Container,
} from "@mui/material";
import Alerta from "../../../../Alerta"
import Notify from "../../../../Notify"

function FichaContrato(props) {
  const [dataContrato, setDataContrato] = useState(props.datos);
  const [edicion, setEdicion] = useState(false);
  const [ro, setRo] = useState(true);
  const [variant, setVariant] = useState("filled");
  const [disBtnEditar, setDisBtnEditar] = useState(false);
  const [modalidades, setModalidades] = useState(null);
  const [factibilidades, setFactibilidades] = useState(null);
  const [estados, setEstados] = useState(null);
  const [productos, setProductos] = useState(null);
  const [disGuardar, setDisGuardar] = useState(false);

  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
  const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
  const [mensajeAlerta, setMensajeAlerta] = useState(""); //mensaje que contendrá el componente "alerta"


  const handleClose = () => {
    props.ficha(false);
    props.tabla(true);
  };

  const mostrarEdicion = () => {
    setEdicion(true);
    setRo(false);
    setVariant("outlined");
    setDisBtnEditar(true);
    obtenerModalidad();
    obtenerEstados();
    obtenerFactibilidades();
    obtenerProductos();
  };

  const almacenar = (e) => {
    setDataContrato({
      ...dataContrato,
      [e.target.name]: e.target.value,
    });
  };

  const obtenerModalidad = () => {
    // fetch(UrlApi + "/desplegables/modulo/modalidadcontratos")
    fetch(UrlApi + "/desplegables/modalidadcontratos")
      .then((data) => data.json())
      .then((data) => setModalidades(data));
  };

  const obtenerProductos = async () => {
    fetch(UrlApi + "/productos")
      .then((data) => data.json())
      .then((data) => setProductos(data));
  };

  const obtenerFactibilidades = async () => {
    fetch(UrlApi + "/fact")
      .then((data) => data.json())
      .then((data) => setFactibilidades(data));
  };

  const obtenerEstados = async () => {
    // fetch(UrlApi + "/desplegables/modulo/estadocontratos")
    fetch(UrlApi + "/desplegables/estadocontratos")
      .then((data) => data.json())
      .then((data) => setEstados(data));
  };

  //Funcion para guardar contratos
  async function guardarContratos(e){
    setDisGuardar(true);
    e.preventDefault();
    const response = await fetch(UrlApi + "/contratos/" + dataContrato.id_contrato, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        nro_contrato: dataContrato.nro_contrato,
        id_modalidad: dataContrato.id_modalidad,
        id_estado: dataContrato.id_estado,
        id_factibilidad: dataContrato.id_factibilidad,
        consumo_kilos: dataContrato.consumo_kilos,
        consumo_litros: dataContrato.consumo_litros,
        id_producto: dataContrato.id_producto,
        fecha_inicio:
          dataContrato.fecha_inicio === "" ? null : dataContrato.fecha_inicio,
        vencimiento_contrato:
          dataContrato.vencimiento_contrato === ""
            ? null
            : dataContrato.vencimiento_contrato,
        usuario: localStorage.getItem("user"),
      }),
    })
    if (response.ok === false) {
      const error = response && response.json();
      setError(error);
      setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      try {
        const json = await response.json();
        if (json.message) {
            setNotificacion(true);
            setOpenSnack(true);
            setMensaje(json.message==="OK" ? "Guardado": "Guardado. Actualizar RTA Api" );
        } else if(json.sqlMessage) {
          json && setError(json);
          setMensajeAlerta("SQL erróneo (" + json.code + " Errno: " + json.errno + " )" );
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje("Guardado. Actualizar RTA A Json");
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      }
    }
  };

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };
  
  return (
    <div>
      <Container>
      {notificacion ? (
          <Notify
            mensaje={mensaje}
            open={openSnack}
            color={"#D4EFDF"}
            severity={"success"}
            handleClose={handleClose}
          />
        ) : null}
        {/* ALERT --> SE MUESTRA SI HAY UN ERROR EN LA API (NO SE CONECTA O ESTÁ MAL LA RUTA, ETC.) */}
        {error && mensajeAlerta ? (
          <Alerta mensaje={mensajeAlerta} severity={"error"} />
        ) : null}


        <Box
          backgroundColor="#F4F6F6"
          p={3}
          mb={8}
          border={0.5}
          borderColor={"#ABB2B9"}
          borderRadius={4}
        >
          <Grid
            container
            xs={12}
            sx={{
              backgroundColor: "#e0e0e0",
              flexGrow: 1,
              border: 0.5,
              mb: 2,
              borderRadius: 2,
              p: 1,
              textAlign: "center",
              borderColor: "#90a4ae",
            }}
          >
            <Grid item xs={1}>
              <IconButton>
                <ArrowBackIcon onClick={handleClose} />
              </IconButton>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" gutterBottom>
                Contrato número: {dataContrato.nro_contrato}
              </Typography>
            </Grid>
            <Grid item xs={4} textAlign="right">
              <Button
                disabled={disBtnEditar}
                onClick={mostrarEdicion}
                variant="outlined"
                startIcon={<EditIcon />}
                style={{
                  backgroundColor: "#ffffff",
                }}
              >
                Editar Contrato
              </Button>{" "}
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={4}>
              <TextField
                style={style}
                fullWidth
                placeholder="No registrado"
                label="Numero de contrato"
                name="nro_contrato"
                focused
                variant={variant}
                value={dataContrato.nro_contrato}
                onChange={almacenar}
                InputProps={{
                  readOnly: ro,
                  startAdornment: (
                    <InputAdornment position="start">
                      <AssignmentIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {edicion ? (
              <>
                <Grid item xs={4}>
                  <FormControl style={style} focused color="primary">
                    <InputLabel>Modalidad</InputLabel>
                    <Select
                      required
                      name="id_modalidad"
                      value={dataContrato.id_modalidad}
                      onChange={almacenar}
                      label="Modalidad"
                    >
                      {modalidades
                        ? modalidades.map((elemento) => (
                            <MenuItem key={elemento.id} value={elemento.id}>
                              {elemento.valor}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl style={style} focused color="primary">
                    <InputLabel>Estado</InputLabel>
                    <Select
                      required
                      name="id_estado"
                      value={dataContrato.estado}
                      onChange={almacenar}
                      label="Estado"
                    >
                      {estados
                        ? estados.map((elemento) => (
                            <MenuItem key={elemento.id} value={elemento.id}>
                              {elemento.valor}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl style={style} focused color="primary">
                    <InputLabel>Factibilidad</InputLabel>
                    <Select
                      name="id_factibilidad"
                      value={dataContrato.id_factibilidad}
                      onChange={almacenar}
                      label="Factibilidad"
                    >
                      {factibilidades
                        ? factibilidades.map((elemento) => (
                            <MenuItem
                              key={elemento.idfactibilidad}
                              value={elemento.idfactibilidad}
                            >
                              {elemento.idfactibilidad}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    placeholder="No registrado"
                    label="Modalidad"
                    name="modalidad"
                    focused
                    variant={variant}
                    value={dataContrato.modalidad}
                    InputProps={{
                      readOnly: ro,
                      startAdornment: (
                        <InputAdornment position="start">
                          <ArticleIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    placeholder="No hay registro"
                    label="Estado"
                    name="estado"
                    focused
                    variant={variant}
                    value={dataContrato.descr_estado}
                    InputProps={{
                      readOnly: ro,
                      startAdornment: (
                        <InputAdornment position="start">
                          <PlaylistAddCheckIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    placeholder="No registrado"
                    label="Factibilidad"
                    name="factibilidad"
                    focused
                    variant={variant}
                    value={dataContrato.id_factibilidad}
                    InputProps={{
                      readOnly: ro,
                      startAdornment: (
                        <InputAdornment position="start">
                          <AssignmentIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={3}>
              <TextField
                onChange={almacenar}
                style={style}
                placeholder="No hay registro"
                label="Consumo en kilos"
                name="consumo_kilos"
                focused
                variant={variant}
                value={dataContrato.consumo_kilos}
                InputProps={{
                  readOnly: ro,
                  startAdornment: (
                    <InputAdornment position="start">
                      <TimelineIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                onChange={almacenar}
                style={style}
                placeholder="No hay registro"
                label="Consumo en litros"
                name="consumo_litros"
                focused
                variant={variant}
                value={dataContrato.consumo_litros}
                InputProps={{
                  readOnly: ro,
                  startAdornment: (
                    <InputAdornment position="start">
                      <TimelineIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {edicion ? (
              <>
                <Grid item xs={3}>
                  <FormControl style={style} focused color="primary">
                    <InputLabel>Producto</InputLabel>
                    <Select
                      required
                      name="id_producto"
                      value={dataContrato.id_producto}
                      onChange={almacenar}
                      label="Producto"
                    >
                      {productos
                        ? productos.map((elemento) => (
                            <MenuItem
                              key={elemento.idproducto}
                              value={elemento.idproducto}
                            >
                              {elemento.nombre}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    style={style}
                    color="primary"
                    focused
                    required
                    label="Fecha inicio"
                    name="fecha_inicio"
                    type="date"
                    variant="outlined"
                    rows={2}
                    value={moment(dataContrato.fecha_inicio).format(
                      "YYYY-MM-DD"
                    )}
                    onChange={almacenar}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    style={style}
                    label="Vencimiento contrato"
                    color="primary"
                    focused
                    required
                    name="vencimiento_contrato"
                    type="date"
                    variant="outlined"
                    rows={2}
                    value={moment(dataContrato.vencimiento_contrato).format(
                      "YYYY-MM-DD"
                    )}
                    onChange={almacenar}
                  />
                </Grid>
                <Grid
                  ml={2}
                  mt={2}
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Button
                    variant="contained"
                    onClick={handleClose}
                    color="error"
                  >
                    Volver
                  </Button>
                  <Button
                    disabled={disGuardar}
                    variant="contained"
                    onClick={guardarContratos}
                    color="success"
                  >
                    Guardar
                  </Button>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    placeholder="No hay registro"
                    label="Producto"
                    name="producto"
                    focused
                    variant={variant}
                    value={dataContrato.producto}
                    InputProps={{
                      readOnly: ro,
                      startAdornment: (
                        <InputAdornment position="start">
                          <CheckIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    placeholder="No hay registro"
                    label="Inicio contrato"
                    name="fecha_inicio"
                    focused
                    variant={variant}
                    value={moment(dataContrato.fecha_inicio).format(
                      "YYYY-MM-DD"
                    )}
                    InputProps={{
                      readOnly: ro,
                      startAdornment: (
                        <InputAdornment position="start">
                          <EventIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    placeholder="No hay registro"
                    label="Vencimiento contrato"
                    name="vencimiento_contrato"
                    focused
                    variant={variant}
                    value={moment(dataContrato.vencimiento_contrato).format(
                      "YYYY-MM-DD"
                    )}
                    InputProps={{
                      readOnly: ro,
                      startAdornment: (
                        <InputAdornment position="start">
                          <EventIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Container>
    </div>
  );
}

export default FichaContrato;
