import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";

function ModalCapacidades(props) {
  const [open, setOpen] = React.useState(props.open);
  const [scroll, setScroll] = React.useState("paper");

  // const handleClickOpen = (scrollType) => () => {
  //   setOpen(true);
  //   setScroll(scrollType);
  // };

  const handleClose = () => {
    setOpen(false);
    props.setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          Capacidades De Tanques
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          {props.tanquesxbat &&
            props.tanquesxbat.map((tanque) => (
              <>
                <Typography variant="body1" color="initial">
                  <b>N° Serie:</b> {tanque.nro_serie} - <b>Capacidad:</b>{" "}
                  {tanque.capacidad} m3
                </Typography>
              </>
            ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ModalCapacidades;
