//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio
// Creation Date : 18/09/2024
// Version : 1
// Description : Pantalla de alta de proveedores tanque
//------Changes ----------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
//
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Container,
  Typography,
  Grid,
  Box,
  TextField,
  IconButton,
  Button,
  Autocomplete,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { grey } from "@mui/material/colors";
import Notify from "../Notify";
import Alerta from "../Alerta";
import { logEventos } from "../../services/services";

function AddProveedorTanque(props) {
  const [datosProveedor, setDatosProveedor] = useState({
    id_proveedor: null,
    usuario: localStorage.getItem("user"),
  });

  const [proveedorElegido, setProveedorElegido] = useState();
  const [proveedorTraido, setProveedorTraido] = useState("");

  const obtenerProveedor = () => {
    fetch(UrlApi + "/proveedores")
      .then((data) => data.json())
      .then((data) => setProveedorTraido(data));
  };
  useEffect(() => {
    obtenerProveedor();
  }, []);

  const [proveedorExiste, setProveedorExiste] = useState(false);

  const verificarProveedor = async (id_proveedor) => {
    const response = await fetch(
      UrlApi + `/tanque_proveedor/existe/${id_proveedor}`
    );
    const data = await response.json();
    if (data.length > 0) {
      // El proveedor ya existe
      setProveedorExiste(true);
    } else {
      // El proveedor no existe
      setProveedorExiste(false);
    }
  };

  useEffect(() => {
    if (proveedorElegido) {
      verificarProveedor(proveedorElegido.id);
    }
  }, [proveedorElegido]);

  console.log("VERIFICACION SI EXISTE", proveedorExiste);

  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [notificacion, setNotificacion] = useState(false);
  const [error, setError] = useState(null);
  const [mensajeAlerta, setMensajeAlerta] = useState("");
  const [finalizar, setFinalizar] = useState();
  const [severityAlerta, setSeverityAlerta] = useState("");
  const [severitySnack, setSeveritySnack] = useState("");

  const handleClose = () => {
    props.alta(false);
    props.tabla(true);
  };

  const almacenar = (e) => {
    setDatosProveedor({
      ...datosProveedor,
      [e.target.name]: e.target.value === "" ? null : e.target.value,
    });
  };

  const guardarProveedor = async (e) => {
     logEventos("Guardar nuevo Proveedor Tanque", "Proveedores Tanques", "Se guardo el Proveedore Tanque", localStorage.getItem("user"))
    e.preventDefault();
    const response = await fetch(UrlApi + "/tanque_proveedor", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_proveedor: proveedorElegido.id,
        estado: 1,
        usuario: localStorage.getItem("user"),
      }),
    });
    if (response.ok === false) {
      console.log("ERROR EN API");
      const error = response && response.json();
      setError(error);
      setSeverityAlerta("error");
      setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      console.log("Conexión con api OK");
      try {
        //Parseo respuesta de la api a json()
        const json = await response.json();
        //si api me devuelve lo q le puse en message
        if (json.message) {
          //Si ese mensaje es "OK"
          if (json.message === "OK") {
            console.log("Guardado Satisfactoriamente");
            setNotificacion(true);
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setMensaje("Guardado");
            setSeveritySnack("success");
          } else {
            console.log("res en api incorrecto. Revisar que sea 'OK'");
            setNotificacion(true);
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setSeveritySnack("error");
            setMensaje("Registro Guardado. REVISAR RESPUESTA DE API (E_S)");
          }
        } else if (json.sqlMessage) {
          json && setError(json);
          console.log(json);
          setMensajeAlerta(
            "SQL erróneo (" + json.code + " Errno: " + json.errno + " )"
          );
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          console.log(
            "Guardado. Advertencia: Actualizar respuesta de api. JSON invalido."
          );
          setNotificacion(true);
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("Guardado");
          setSeveritySnack("success");
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      }
    }
    handleClose();
  };

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  const size = "small";

  const [disabledGuardar, setDisabledGuardar] = useState(true);

  // Efecto para habilitar/deshabilitar el botón
  useEffect(() => {
    setDisabledGuardar(!proveedorElegido); // Deshabilitar si no hay proveedor seleccionado
  }, [proveedorElegido]);

  return (
    <div>
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container backgroundColor="#1F618D" p={1.5}>
            <Grid item xs={2}>
              <IconButton onClick={handleClose}>
                <ArrowBackIcon sx={{ color: grey[50] }} />
              </IconButton>
            </Grid>

            <Grid
              item
              xs={8}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h5" color="#ffffff">
                Cargar nuevo proveedor de tanque
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <form autoComplete="off">
          <Box
            backgroundColor="#F4F6F6"
            mt={2}
            p={3}
            border={0.5}
            borderColor={"#ABB2B9"}
            borderRadius={4}
          >
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12} sm={10} md={8} lg={6}>
                <Autocomplete
                  disableClearable
                  size="small"
                  required
                  style={style}
                  noOptionsText={"Proveedor INEXISTENTE"}
                  options={proveedorTraido}
                  autoHighlight
                  getOptionLabel={(users) => users.razon_social}
                  onChange={(event, value) => setProveedorElegido(value)}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      key={option.id}
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.razon_social} - {option.nro_cuit}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                      }}
                      focused
                      size="small"
                      placeholder="Tipee para buscar..."
                      label="Proveedores de Tanque"
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-end"
              mt={2}
            >
              <Button variant="contained" onClick={handleClose} color="error">
                Volver
              </Button>

              <Button
                variant="contained"
                onClick={guardarProveedor}
                disabled={proveedorExiste || disabledGuardar} // Deshabilitar si el proveedor existe o si no se ha seleccionado uno
                color="success"
              >
                Guardar Proveedor
              </Button>
            </Grid>
          </Box>
        </form>
      </Container>
      {notificacion ? (
        <Notify
          mensaje={mensaje}
          open={openSnack}
          color={colormensaje}
          finalizar={setFinalizar}
          severity={severitySnack}
        />
      ) : null}
      {/* ALERT --> SE MUESTRA SI HAY UN ERROR EN LA API (NO SE CONECTA O ESTÁ MAL LA RUTA, ETC.) */}
      {error && mensajeAlerta ? (
        <Alerta mensaje={mensajeAlerta} severity={severityAlerta} />
      ) : null}
    </div>
  );
}

export default AddProveedorTanque;
