import React, { useState, useEffect, useMemo, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UrlApi } from "../../../services/apirest";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import {
  Button,
  Container,
  Box,
  Grid,
  Typography,
  IconButton,
  ListItemIcon,
  Tooltip,
} from "@mui/material";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

// ICONOS
import HistoryIcon from "@mui/icons-material/History";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import Navbar from "../../Navbar";
import { getData } from "../../../services/library";
import DialogSeguimiento from "./DialogSeguimiento";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Seguimiento() {
  const [verHistorial, setVerHistorial] = useState(false);
  const [row, setRow] = useState();
  const [articulos, setArticulos] = useState();
  const [tabla, setTabla] = useState(true);

  const obtenervehiculos = () => {
    getData("/stock_seguimiento/activos", setArticulos);
  };

  useEffect(() => {
    obtenervehiculos();
  }, [tabla]);

  const handleOpenHistorial = (row) => {
    setRow(row);
    setVerHistorial(true);
    // obtenervehiculos()
  };

  const handleCloseHistorial = () => {
    setVerHistorial(false);
    // obtenervehiculos()
  };

  const columns = useMemo(
    () => [
      { header: "Identificador", accessorKey: "identificador", size: 95 },
      { header: "Articulo", accessorKey: "nombre_articulo", size: 95 },
      { header: "Vehiculo", accessorKey: "patente", size: 100 },
    ],
    []
  );
  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "primary",
    size: "small",
  };
  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };
  //*************************************************************************************************************************************//
  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem",
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const initialState = {
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
    grouping: ["patente", "nombre_articulo"],
  };

  return (
    <>
      <Navbar />
      {articulos && tabla ? (
        <Box mt={2} mb={4}>
          <ThemeProvider theme={tableTheme}>
            <MaterialReactTable
              enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
              enableGrouping // para permitir agrupar x estado x ej
              layoutMode="grid"
              muiTableHeadCellProps={tableHeadCellProps}
              muiTableBodyCellProps={({ row }) => ({
                align: "left",
                //   sx: {
                //     backgroundColor:
                //       row.getValue("estado_stock") === 0
                //         ? ""
                //         : row.getValue("estado_stock") === 1
                //         ? "rgb(255,105,97)"
                //         : "rgb(253,253,150)",
                //     color: "black",
                //     borderColor: "black",
                //   },
              })}
              enableColumnFilters={false} //desactiva filtros x  columna
              enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
              enableDensityToggle={false} //
              localization={MRT_Localization_ES} //idioma
              enableColumnDragging={false} //para arrastrar columnas
              initialState={initialState}
              muiSearchTextFieldProps={muiSearchTextFieldProps}
              columns={columns}
              data={articulos} //con lo q la completas (esto cambia siempre)
              enableStickyFooter
              enableRowActions
              // enableEditing  //para que se pueda editar desde tabla
              positionActionsColumn="last" // posicion de la columna de acciones al final
              // ACCIONES
              renderToolbarInternalActions={(
                { table } // boton para nuevo , al lado del buscar
              ) => (
                <>
                  <MRT_FullScreenToggleButton table={table} />
                </>
              )}
              renderTopToolbarCustomActions={(row, table) => (
                <>
                  <Typography variant="poster" fontSize={26}>
                    Seguimiento de Artículos
                  </Typography>
                </>
              )}
              renderRowActions={(
                { row } //las acciones del costado editar y ver
              ) => (
                <div>
                  <Tooltip arrow placement="left" title="Historial de Artículo">
                    <IconButton
                      onClick={() => handleOpenHistorial(row.original)}
                      color="primary"
                    >
                      <HistoryIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            />
          </ThemeProvider>
        </Box>
      ) : null}
      {verHistorial && (
        <DialogSeguimiento
          open={verHistorial}
          setOpen={setVerHistorial}
          row={row}
          // patente={location.state.patente}
          // artSuc={artSuc}
          // fuente={location.state.fuente}
          onClose={handleCloseHistorial}
          // actualizar={obtenerPedidos(245)}
        />
      )}
    </>
  );
}

export default Seguimiento;
