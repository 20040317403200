// Developer     : Magalí Perea
// Creation Date : 2024-04-22
// Version       : 
// Description   : Es el resumen del pedido que debe hacerse para la semana que viene, teniendo en cuenta la cantidad de cada menú que han pedido los empleados/usuarios.


import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import Container from "@mui/material/Container";
import { getData } from "../../services/library";
import moment from "moment";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
// import UrlApi from "../../services/apirest"
import {UrlApi} from "../../services/apirest"


function Resumen() {
  // const [resumen, setResumen] = useState([]);
  const [resumenCasaCentral, setResumenCasaCentral] = useState(null);
  const [resumenNetizar, setResumenNetizar] = useState(null);

  const [viandasSemana, setViandasSemana] = useState(null);

  //cambios
  async function search(){
    //Dias de inicio de la semana siguiente
    const nextWeekStart=moment().day(8).format("YYYY-MM-DD")
    const nextWeekEnd=moment().day(13).format("YYYY-MM-DD")

    //Busco si hay opciones de menú cargados para la semana q sigue
    const searchNextWeekOptions = await fetch(UrlApi+`/viandas/semanal/${nextWeekStart}/${nextWeekEnd}`)
    const nextWeekOptions = await searchNextWeekOptions.json() //se gua

    //si NO hay viandas para la semana siguiente:
    if(nextWeekOptions.length===0){
      //Si no están los de la semana q viene, busco los de la semana anterior.
      const currentWeekStart=moment().day(1).format("YYYY-MM-DD")
      const currentWeekEnd=moment().day(5).format("YYYY-MM-DD")

      const searchCurrentWeekOptions = await fetch(UrlApi+`/viandas/semanal/${currentWeekStart}/${currentWeekEnd}`)
      const currentWeekOptions = await searchCurrentWeekOptions.json()
      if (currentWeekOptions.length>0){ //Si tengo opciones de menú de la semana anterior, voy a ir a buscar el resumen.
        const searchResumenCC=await fetch(UrlApi+`/viandas/resumen/casacentral/${currentWeekStart}/${currentWeekEnd}`)
        const resumenCC=await searchResumenCC.json()

        const searchResumenNetizar=await fetch(UrlApi+`/viandas/resumen/netizar/${currentWeekStart}/${currentWeekEnd}`)
        const resumenNetizar= await searchResumenNetizar.json()

        setResumenCasaCentral(resumenCC)
        setResumenNetizar(resumenNetizar)
      } 
    } else { //aca defino lo que pasa si las viandas de la semana q viene fueron cargadas
      const searchResumenCC=await fetch(UrlApi+`/viandas/resumen/casacentral/${nextWeekStart}/${nextWeekEnd}`)
      const resumenCC=await searchResumenCC.json()
      const searchResumenNetizar=await fetch(UrlApi+`/viandas/resumen/netizar/${nextWeekStart}/${nextWeekEnd}`)
      const resumenNetizar= await searchResumenNetizar.json()
      setResumenCasaCentral(resumenCC)
      setResumenNetizar(resumenNetizar)
    } 
  }
  useEffect(()=>{
    search();
  },[])


  // Función para agrupar los pedidos por fecha
  const groupByDate = (data) => {
    return data.reduce((acc, item) => {
      const date = moment(item.fecha).format("dddd DD-MM-YYYY");
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
  };

  return (
    <div>
      <Navbar />
      <Container maxWidth="xl">
        <Box sx={{ p: 2 }}>
          <Grid
            container
            direction="row"
            borderBottom={1}
            justifyContent="space-arround"
            border={1}
            backgroundColor="#ebebeb"
          >
            <Grid item xs={12} borderBottom={1} backgroundColor="#2980B9">
              <Typography variant="h6" textAlign="center" color="white">
                RESUMEN SEMANAL VIANDAS CASA CENTRAL 
              </Typography>
            </Grid>
            {resumenCasaCentral&&resumenCasaCentral.length > 0 ? (
              <>
                <Grid item xs={2} p={1}>
                  Fecha
                </Grid>
                <Grid item xs={1} p={1}>
                  Cantidad
                </Grid>
                <Grid item xs={4} p={1}>
                  Opción
                </Grid>
                <Grid item xs={2.4} p={1}>
                  Guarnición
                </Grid>
                <Grid item xs={2.4} p={1}>
                  Observaciones
                </Grid>
              </>
            ) : (
              <Grid item xs={12} p={1}>
                <i>No hay pedidos registrados aún</i>
              </Grid>
            )}
          </Grid>
          {/* )} */}

          {resumenCasaCentral &&
            Object.entries(groupByDate(resumenCasaCentral)).map(
              ([fecha, pedidos]) => (
                <React.Fragment key={fecha}>
                  <Grid
                    container
                    direction="row"
                    borderBottom={1}
                    borderLeft={1}
                    borderRight={1}
                  >
                    <Grid item xs={2} p={1}>
                      {fecha.charAt(0).toUpperCase() + fecha.slice(1)}
                    </Grid>
                    <Grid item xs={10}>
                      {pedidos.map((pedido, index) => (
                        <Grid container direction="row" key={index}>
                          <Grid item xs={1.3} p={1}>
                            {pedido.cantidad_pedidos}
                          </Grid>
                          <Grid item xs={4.7} p={1}>
                            {pedido.opcion === 1
                              // ? pedido.opcion1.charAt(0).toUpperCase() + pedido.opcion1.slice(1)
                              ? pedido.opcion1.charAt(0).toUpperCase() + pedido.opcion1.slice(1)
                              : pedido.opcion === 2
                              ? pedido.opcion2.charAt(0).toUpperCase() + pedido.opcion2.slice(1)
                              : pedido.opcion === 3
                              ? pedido.opcion3.charAt(0).toUpperCase() + pedido.opcion3.slice(1)
                              : null}
                          </Grid>
                          <Grid item xs={3} p={1}>
                            {pedido.guarnicion}
                          </Grid>
                          <Grid item xs={3} p={1}>
                            {pedido.observaciones}
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </React.Fragment>
              )
            )}
        </Box>

        <Divider />

        <Box sx={{ p: 2 }}>
          <Grid
            container
            direction="row"
            borderBottom={1}
            justifyContent="space-arround"
            border={1}
            backgroundColor="#ebebeb"
          >
            <Grid item xs={12} borderBottom={1} backgroundColor="#A569BD">
              <Typography variant="h6" textAlign="center" color="white">
                RESUMEN SEMANAL VIANDAS NETIZAR
              </Typography>
            </Grid>
            {resumenNetizar&&resumenNetizar.length > 0 ? (
              <>
                <Grid item xs={1.7} p={1}>
                  Fecha
                </Grid>
                <Grid item xs={0.85} p={1}>
                  Cantidad
                </Grid>
                <Grid item xs={3.4} p={1}>
                  Opción
                </Grid>
                <Grid item xs={2} p={1}>
                  Guarnición
                </Grid>
                <Grid item xs={2.4} p={1}>
                  Observaciones
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <i>No hay pedidos registrados aún.</i>
              </Grid>
            )}
          </Grid>

          {resumenNetizar &&
            Object.entries(groupByDate(resumenNetizar)).map(
              ([fecha, pedidos]) => (
                <React.Fragment key={fecha}>
                  <Grid
                    container
                    direction="row"
                    borderBottom={1}
                    borderLeft={1}
                    borderRight={1}
                  >
                    <Grid item xs={1.7} p={1}>
                      {/* {fecha} */}
                      {fecha.charAt(0).toUpperCase() + fecha.slice(1)}
                    </Grid>
                    <Grid item xs={10.3}>
                      {pedidos.map((pedido, index) => (
                        <Grid container direction="row" key={index}>
                          <Grid item xs={1} p={1}>
                            {pedido.cantidad_pedidos}
                          </Grid>
                          <Grid item xs={3.95} p={1}>
                            {pedido.opcion === 1
                              ? pedido.opcion1.charAt(0).toUpperCase() + pedido.opcion1.slice(1)
                              : pedido.opcion === 2
                              ? pedido.opcion2.charAt(0).toUpperCase() + pedido.opcion2.slice(1)
                              : pedido.opcion === 3
                              ? pedido.opcion3.charAt(0).toUpperCase() + pedido.opcion3.slice(1)
                              : null}
                          </Grid>
                          <Grid item xs={2.33} p={1}>
                            {pedido.guarnicion.charAt(0).toUpperCase() + pedido.guarnicion.slice(1)}
                          </Grid>
                          <Grid item xs={3} p={1}>
                            {pedido.observaciones}
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </React.Fragment>
              )
            )}
        </Box>
      </Container>
      <br />
      <br />
      <br />
    </div>
  );
}

export default Resumen;
