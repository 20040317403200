import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  fitBounds,
  LoadScript,
  StandaloneSearchBox,
  Marker,
  InfoWindow,
  MarkerClusterer,
  DirectionsService,
  DirectionsRenderer,
} from "@react-google-maps/api";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { UrlApiEnvasado } from "../../../services/apirest";

// SLIDER
import Slider from "@mui/material/Slider";

// ICONOS
import CircularProgress from "@mui/material/CircularProgress";
import ReplyIcon from "@mui/icons-material/Reply";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import VolumeDown from "@mui/icons-material/VolumeDown";
import VolumeUp from "@mui/icons-material/VolumeUp";
import RouteIcon from "@mui/icons-material/Route";

// PARA EL IDIOMA DEL CALENDARIO
import "dayjs/locale/es";
import dayjs from "dayjs";

// MAPA
const libraries = ["places"];

const infoWindowStyle = {
  backgroundColor: "#fff",
  padding: "10px",
  borderRadius: "8px",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
};

// -- Tabla
const tablaStyles = {
  width: "100%",
  borderCollapse: "collapse",
};

const thTdStyles = {
  border: "1px solid #ddd",
  padding: "8px",
  textAlign: "left",
};

const thStyles = {
  backgroundColor: "#f2f2f2",
};

const thStyles2 = {
  backgroundColor: "#e5e5e5",
};

const thStyles3 = {
  backgroundColor: "#f2f2f2",
};

const centerAlign = {
  textAlign: "center",
};

const boldText = {
  fontWeight: "bold",
};

export default function AreaExpandida(props) {
  const [loading, setLoading] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(props.drawerOpen);
  const [markers, setMarkers] = useState([]);
  const [direcciones, setDirecciones] = useState();
  const [origen, setOrigen] = useState();
  const [destino, setDestino] = useState();

  const [ubicacionSeleccionada, setUbicacionSeleccionada] = useState();

  const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(false);

  // **************************************************************************

  // FUNCIONES PARA CAMBIAR LOS VALORES DE ORIGEN Y DESTINO
  const handleOrigenChange = (value) => {
    setOrigen(value);
  };

  const handleDestinoChange = (value) => {
    setDestino(value);
  };

  // ***************************************************************

  const [rutaInfo, setRutaInfo] = useState(null);

  console.log("ROUTE INFO: ", rutaInfo);

  // **************************************************************************

  const [sliderValue, setSliderValue] = React.useState([20, 37]);

  const handleChangeSliderValue = (event, newSliderValue) => {
    setSliderValue(newSliderValue);
  };

  // **************************************************************************
  // Estado para controlar la visibilidad del tooltip
  // const [showTooltip, setShowTooltip] = useState(false);

  // Estado para almacenar la distancia y tiempo
  // const [rutaInfo, setRutaInfo] = useState({ distancia: "", tiempo: "" });

  // Función para calcular la distancia y el tiempo
  // const calcularInfoRuta = () => {
  //   // Lógica para calcular la distancia y el tiempo aquí...
  //   // Puedes usar la respuesta de la API de direcciones o cualquier otra fuente de datos que tengas para calcular esto.
  //   const distanciaCalculada = "..."; // Calcula la distancia aquí
  //   const tiempoCalculado = "..."; // Calcula el tiempo aquí

  //   setRutaInfo({ distancia: distanciaCalculada, tiempo: tiempoCalculado });
  // };

  // Función para mostrar el tooltip cuando se coloca el puntero sobre la ruta
  // const handleMouseOverRoute = () => {
  //   setShowTooltip(true);
  //   calcularInfoRuta(); // Calcular la información de la ruta cuando el usuario coloca el puntero sobre ella
  // };

  // Función para ocultar el tooltip cuando el puntero sale de la ruta
  // const handleMouseOutRoute = () => {
  //   setShowTooltip(false);
  // };

  // **************************************************************************
  // DIRECCIONES, ORIGEN Y DESTINO

  // Restablecer las direcciones cuando cambien los puntos de origen o destino
  useEffect(() => {
    setDirecciones(null);
  }, [origen, destino]);

  // Función para calcular la ruta entre dos puntos
  // const calcularDirecciones = () => {
  //   if (origen && destino) {
  //     const directionsService = new window.google.maps.DirectionsService();
  //     directionsService.route(
  //       {
  //         origin: origen,
  //         destination: destino,
  //         travelMode: window.google.maps.TravelMode.DRIVING,
  //       },
  //       (result, status) => {
  //         if (status === window.google.maps.DirectionsStatus.OK) {
  //           setDirecciones(result);
  //         } else {
  //           console.error("Error al obtener las direcciones:", status);
  //         }
  //       }
  //     );
  //   }
  // };

  // const calcularDirecciones = () => {
  //   if (origen && destino) {
  //     const directionsService = new window.google.maps.DirectionsService();
  //     directionsService.route(
  //       {
  //         origin: origen,
  //         destination: destino,
  //         travelMode: window.google.maps.TravelMode.DRIVING,
  //       },
  //       (result, status) => {
  //         if (status === window.google.maps.DirectionsStatus.OK) {
  //           setDirecciones(result);
  //           const route = result.routes[0];
  //           const distancia = route.legs[0].distance.text;
  //           const duracion = route.legs[0].duration.text;
  //           setRutaInfo(`Distancia: ${distancia}, Duración: ${duracion}`);
  //         } else {
  //           console.error("Error al obtener las direcciones:", status);
  //         }
  //       }
  //     );
  //   }
  // };

  // const calcularDirecciones = () => {
  //   if (origen && destino) {
  //     const directionsService = new window.google.maps.DirectionsService();
  //     directionsService.route(
  //       {
  //         origin: { lat: origen.latitud, lng: origen.longitud }, // Uso las propiedades latitud y longitud del origen
  //         destination: { lat: destino.latitud, lng: destino.longitud }, // Uso las propiedades latitud y longitud del destino
  //         travelMode: window.google.maps.TravelMode.DRIVING,
  //       },
  //       (result, status) => {
  //         if (status === window.google.maps.DirectionsStatus.OK) {
  //           setDirecciones(result);
  //           const route = result.routes[0];
  //           const distancia = route.legs[0].distance.text;
  //           const duracion = route.legs[0].duration.text;
  //           setRutaInfo(`Distancia: ${distancia}, Duración: ${duracion}`);
  //         } else {
  //           console.error("Error al obtener las direcciones:", status);
  //         }
  //       }
  //     );
  //   }
  // };

  useEffect(() => {
    if (!origen || !destino) {
      setRutaInfo(null);
    }
  }, [origen, destino]);

  const calcularDirecciones = () => {
    if (!origen || !destino) {
      setRutaInfo(null); // Si no hay origen o destino, establece routeInfo como null
      return;
    }

    const directionsService = new window.google.maps.DirectionsService();
    directionsService.route(
      {
        origin: { lat: origen.latitud, lng: origen.longitud }, // Propiedades latitud y longitud del origen
        destination: { lat: destino.latitud, lng: destino.longitud }, // Propiedades latitud y longitud del destino
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirecciones(result);
          const route = result.routes[0];
          const distancia = route.legs[0].distance.text;
          const duracion = route.legs[0].duration.text;
          setRutaInfo(`Distancia: ${distancia}, Duración: ${duracion}`);
        } else {
          console.error("Error al obtener las direcciones:", status);
        }
      }
    );
  };

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
    setIsInfoWindowOpen(true);
  };

  // Restablecer el punto de origen y destino cuando se cierre la ventana de información
  const handleCloseInfoWindow = () => {
    setIsInfoWindowOpen(false);
    setOrigen(null);
    setDestino(null);
  };

  // Restablecer el punto de origen y destino cuando se carguen nuevos marcadores
  useEffect(() => {
    setOrigen(null);
    setDestino(null);
  }, [markers]);

  // **************************************************************************

  const [selectedYear, setSelectedYear] = useState(
    new Date().getFullYear().toString()
  );

  // FECHA DE HOY
  const hoy = dayjs();

  // CAMBIO DE FECHA DESDE EL DRAWER:
  const handleYearChange = (newDate) => {
    if (dayjs(newDate).isValid()) {
      setSelectedYear(dayjs(newDate).year().toString());
      // Restablecer el punto de origen y destino cuando se cambie el año seleccionado
      setOrigen(null);
      setDestino(null);
    }
  };

  console.log("AÑO SELECCIONADO: ", selectedYear);

  // ----------------------------------------------------------------------------------

  const obtenerMarkers = () => {
    setLoading(true); // Indicar que se está obteniendo los datos

    fetch(
      UrlApiEnvasado +
        "/envasado/fac_reng/pesototalvendido/soloanio/anio/" +
        selectedYear
    )
      .then((data) => data.json())
      .then((data) => {
        setMarkers(data);
        setLoading(false); // Indicar que se han cargado los datos
      });
  };

  // useEffect(() => {
  //   obtenerMarkers();
  // }, [selectedYear]);

  useEffect(() => {
    obtenerMarkers();
  }, []);

  useEffect(() => {
    obtenerMarkers();
  }, [selectedYear]);

  console.log("MARKERS: ", markers);

  // No tengo en cuenta aquellos datos con lat y lon = 0
  const filteredMarkers = markers.filter(
    (sucursal) => sucursal.latitud !== 0 && sucursal.longitud !== 0
  );

  // **************************************************************************

  const [mapCenter, setMapCenter] = useState({
    lat: -38.416097,
    lng: -63.616672,
  });
  const [zoom, setZoom] = useState(7); // Zoom inicial

  // Función para actualizar el zoom del mapa
  const handleZoomChange = (newZoom) => {
    setZoom(newZoom);

    console.log("CAMBIO EL ZOOM: ", newZoom);
  };

  // const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState(null);

  // Colores para los marcadores en función de la cantidad de peso total vendido
  const getColorForMarker = (pesoTotalVendido) => {
    // Elimino los puntos y comas, luego lo convierto en un número
    const pesoNumerico = parseFloat(pesoTotalVendido.replace(/[.,]/g, ""));
    console.log("Peso numérico:", pesoNumerico);

    if (pesoNumerico >= 200000000) {
      console.log("Rojo");
      return "#F11313"; // Para valores mayores o iguales a 2.000.000
    } else if (pesoNumerico >= 100000000) {
      console.log("Naranja");
      return "#F18913"; // Para valores mayores o iguales a 1.000.000
    } else if (pesoNumerico >= 50000000) {
      console.log("Amarillo");
      return "#F7E800"; // Para valores mayores o iguales a 500.000
    } else if (pesoNumerico >= 10000000) {
      console.log("Verde");
      return "#27F113"; // Para valores mayores o iguales a 100.000
    } else {
      console.log("Azul");
      return "#1396F1"; // Para valores menores a 100.000
    }
  };

  // GRADIENTE
  const gradientWidth = 300; // Ancho del gradiente
  const numSteps = 5; // Número de pasos en el gradiente
  const stepSize = gradientWidth / (numSteps - 1); // Tamaño de cada paso

  // ICONO
  // Marker SVG: https://material.io/tools/icons/?icon=place&style=baseline
  const MAP_MARKER =
    "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z";

  console.log("MAP CENTER: ", mapCenter);

  // **************************************************************************

  const handleUbicacionChange = (value) => {
    if (value) {
      setMapCenter({
        lat: value.latitud,
        lng: value.longitud,
      });
  
      // Actualiza el estado del zoom
      setZoom(8);
    }
  };
  
  console.log("UBI SELECCIONADA: ", ubicacionSeleccionada)

  // **************************************************************************

  useEffect(() => {
    setDrawerOpen(props.drawerOpen);
  }, [props.drawerOpen]);

  // ---------------------------------------------------------------

  // DRAWER
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    props.handleDrawer();

    console.log("EJECUTO toggleDrawer");
  };

  // **************************************************************************

  // Función para encontrar la sucursal que más vendió en el año
  const encontrarSucursalMax = () => {
    if (filteredMarkers.length === 0) return null;

    let maxVentas = -Infinity;
    let maxSucursal = null;

    filteredMarkers.forEach((sucursal) => {
      const ventas = parseFloat(
        sucursal.peso_total_vendido.replace(/[.,]/g, "")
      );

      if (!isNaN(ventas) && ventas > maxVentas) {
        maxVentas = ventas;
        maxSucursal = sucursal;
      }
    });

    return maxSucursal;
  };

  // Función para encontrar la sucursal que menos vendió en el año
  const encontrarSucursalMin = () => {
    if (filteredMarkers.length === 0) return null;

    let minVentas = Infinity;
    let minSucursal = null;

    filteredMarkers.forEach((sucursal) => {
      const ventas = parseFloat(
        sucursal.peso_total_vendido.replace(/[.,]/g, "")
      );

      if (!isNaN(ventas) && ventas < minVentas) {
        minVentas = ventas;
        minSucursal = sucursal;
      }
    });

    return minSucursal;
  };

  // Llama a estas funciones en tu componente y utiliza los datos obtenidos donde desees mostrarlos, por ejemplo, en el Drawer

  const sucursalMax = encontrarSucursalMax();
  const sucursalMin = encontrarSucursalMin();

  console.log("SUC MAX: ", sucursalMax);
  console.log("SUC MIN: ", sucursalMin);

  // **************************************************************************

  const list = (anchor) => (
    <>
      <Box
        sx={{
          width: anchor === "top" || anchor === "bottom" ? "auto" : 350,
          textAlign: "center",
        }}
        role="presentation"
        onClose={toggleDrawer("right", false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <Typography
          variant="h4"
          gutterBottom
          style={{
            backgroundColor: "#1976D2",
            color: "white",
            padding: "11px",
            fontWeight: "bold",
          }}
        >
          OPCIONES
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column", // Establece la dirección de flexión en columna
          alignItems: "center", // Centra los elementos horizontalmente
          width: anchor === "top" || anchor === "bottom" ? "auto" : 350,
          textAlign: "center",
        }}
        role="presentation"
        onClose={toggleDrawer("right", false)}
      >
        <Divider
          variant="middle"
          style={{ marginBottom: "14px", width: "90%" }}
        >
          Fecha
        </Divider>

        <div style={{ marginTop: "5px", marginBottom: "14px" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                label="Año"
                views={["year"]}
                value={dayjs(`${selectedYear}`)}
                onChange={(newDate) => handleYearChange(newDate)}
                slotProps={{ textField: { size: "small" } }}
                maxDate={hoy} // FECHA MAXIMA COMO EL AÑO ACTUAL
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
      </Box>

      <div style={{ width: "300px" }}>
        <Box sx={{ m: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Origen y Destino
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={filteredMarkers}
                getOptionLabel={(option) => option.nombre_sucursal}
                size="small"
                value={origen} // Utilizamos el estado origen como valor del Autocomplete
                onChange={(event, value) => handleOrigenChange(value)}
                renderInput={(params) => (
                  <TextField {...params} label="Origen" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={filteredMarkers}
                getOptionLabel={(option) => option.nombre_sucursal}
                size="small"
                value={destino}
                onChange={(event, value) => handleDestinoChange(value)}
                renderInput={(params) => (
                  <TextField {...params} label="Destino" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={calcularDirecciones}
                fullWidth
              >
                Obtener Ruta
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ width: 200 }}>
          <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
            <VolumeDown />
            <Slider
              aria-label="Volume"
              valueLabelDisplay="auto"
              value={sliderValue}
              onChange={handleChangeSliderValue}
            />
            <VolumeUp />
          </Stack>
        </Box>
      </div>
    </>
  );

  console.log("ORIGEN: ", origen);
  console.log("DESTINO: ", destino);

  console.log("IsInfoWindowOpen: ", isInfoWindowOpen);

  return (
    <>
      <div>
        <Box>
          <div style={{ height: "85vh", width: "100%" }}>
            <LoadScript
              googleMapsApiKey={process.env.REACT_APP_Google_maps_key}
              libraries={libraries}
            >
              <GoogleMap
                mapContainerStyle={{ height: "100%", width: "100%" }}
                center={mapCenter}
                zoom={zoom}
                options={{
                  gestureHandling: "greedy", // Permite scrollear solo utilizando la rueda del mouse
                  fullscreenControl: false, // Para no mostrar el botón de mapa FullScreen
                  streetViewControl: false,
                  showLabels: false, // Desactivar las etiquetas de los locales y tiendas
                  mapTypeControl: false, // Desactivar el control de tipo de mapa (Mapa, Sátelite)
                  styles: [
                    {
                      featureType: "poi",
                      elementType: "labels",
                      stylers: [{ visibility: "off" }],
                    },
                    {
                      featureType: "transit",
                      elementType: "labels",
                      stylers: [{ visibility: "off" }],
                    },
                  ],
                }}
                // onMouseOver={handleMouseOverRoute}
                // onMouseOut={handleMouseOutRoute}
              >
                <MarkerClusterer averageCenter enableRetinaIcons gridSize={60}>
                  {(clusterer) =>
                    filteredMarkers.map((sucursal, index) => (
                      <Marker
                        key={index}
                        position={{
                          lat: sucursal.latitud,
                          lng: sucursal.longitud,
                        }}
                        icon={{
                          fillColor: getColorForMarker(
                            sucursal.peso_total_vendido
                          ),
                          fillOpacity: 1,
                          strokeWeight: 0,
                          path: MAP_MARKER,
                          scale: 1.4,
                          anchor: { x: 12, y: 24 },
                        }}
                        clusterer={clusterer}
                        onClick={() => handleMarkerClick(sucursal)}
                        title={sucursal.nombre_sucursal}
                      />
                    ))
                  }
                </MarkerClusterer>

                {selectedMarker && isInfoWindowOpen && (
                  <InfoWindow
                    position={{
                      lat: selectedMarker.latitud,
                      lng: selectedMarker.longitud,
                    }}
                    onCloseClick={handleCloseInfoWindow}
                  >
                    <div style={infoWindowStyle}>
                      <div
                        style={{
                          fontSize: 20,
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                      >
                        {selectedMarker.nombre_sucursal}
                      </div>

                      {loading ? (
                        <div style={{ textAlign: "center" }}>
                          <CircularProgress />
                        </div>
                      ) : (
                        <>
                          <table style={tablaStyles}>
                            <thead>
                              <tr>
                                <th style={{ ...thTdStyles, ...thStyles }}>
                                  Tipo de Envase
                                </th>
                                <th style={{ ...thTdStyles, ...thStyles }}>
                                  Ventas (kg)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td style={{ ...thTdStyles, ...centerAlign }}>
                                  10kg
                                </td>
                                <td style={thTdStyles}>
                                  {selectedMarker.peso_vendido_10kg}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ ...thTdStyles, ...centerAlign }}>
                                  12kg
                                </td>
                                <td style={thTdStyles}>
                                  {selectedMarker.peso_vendido_12kg}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ ...thTdStyles, ...centerAlign }}>
                                  15kg
                                </td>
                                <td style={thTdStyles}>
                                  {selectedMarker.peso_vendido_15kg}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ ...thTdStyles, ...centerAlign }}>
                                  30kg
                                </td>
                                <td style={thTdStyles}>
                                  {selectedMarker.peso_vendido_30kg}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ ...thTdStyles, ...centerAlign }}>
                                  45kg
                                </td>
                                <td style={thTdStyles}>
                                  {selectedMarker.peso_vendido_45kg}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    ...thTdStyles,
                                    ...thStyles2,
                                    ...centerAlign,
                                    ...boldText,
                                  }}
                                >
                                  Total
                                </td>
                                <td
                                  style={{
                                    // ...thTdStyles,
                                    // ...thStyles3,
                                    // ...boldText,
                                    ...thTdStyles,
                                    ...thStyles2,
                                    ...centerAlign,
                                    ...boldText,
                                  }}
                                >
                                  {selectedMarker.peso_total_vendido}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </>
                      )}

                      {/* <Button
                        size="small"
                        variant="outlined"
                        // onClick={() => handleExpandirClick(index, false)}
                      >
                        Ver Detalles
                      </Button> */}
                    </div>
                  </InfoWindow>
                )}

                {/* PARA GENERAR LA RUTA */}
                {direcciones && (
                  <DirectionsRenderer
                    directions={direcciones}
                    options={{
                      polylineOptions: {
                        strokeColor: "red",
                        strokeOpacity: 0.8,
                        strokeWeight: 4,
                      },
                    }}
                  />
                )}

                <div style={{ position: "relative" }}>
                  {loading ? (
                    <div
                      style={{
                        position: "absolute",
                        top: 10,
                        left: 10,
                        backgroundColor: "white",
                        padding: 5,
                        borderRadius: 5,
                        zIndex: 1000,
                      }}
                    >
                      <Typography variant="caption">
                        <b>Obteniendo Datos...</b>
                      </Typography>
                    </div>
                  ) : (
                    <div
                      style={{
                        position: "absolute",
                        top: 10,
                        left: 10,
                        backgroundColor: "white",
                        padding: 5,
                        borderRadius: 5,
                        zIndex: 1000,
                      }}
                    >
                      <Typography variant="caption">
                        <b>Fecha:</b> {selectedYear}
                      </Typography>
                    </div>
                  )}

                  <div
                    style={{
                      position: "absolute",
                      top: 10,
                      left: 160,
                      // backgroundColor: "white",
                      // padding: 5,
                      // paddingLeft: 15,
                      // paddingRight: 15,
                      // paddingBottom: 22,
                      borderRadius: 5,
                      zIndex: 1000,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      // width:"250px"
                    }}
                  >
                    <Autocomplete
                      options={filteredMarkers}
                      getOptionLabel={(option) => option.nombre_sucursal}
                      size="small"
                      value={origen} // Utilizamos el estado origen como valor del Autocomplete
                      onChange={(event, value) => handleOrigenChange(value)}
                      // renderInput={(params) => (
                      //   <TextField
                      //   {...params}
                      //   label="Origen"
                      //   variant="outlined"
                      //   />
                      // )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Origen"
                          // label="Search users..."
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "50px",
                              background: "white",

                              legend: {
                                marginLeft: "30px",
                              },
                            },
                            "& .MuiAutocomplete-inputRoot": {
                              paddingLeft: "20px !important",
                              borderRadius: "50px 10px 10px 50px",
                            },
                            "& .MuiInputLabel-outlined": {
                              paddingLeft: "20px",
                            },
                            "& .MuiInputLabel-shrink": {
                              marginLeft: "20px",
                              paddingLeft: "10px",
                              paddingRight: 0,
                              background: "white",
                            },
                          }}
                        />
                      )}
                      style={{
                        width: "250px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: 15,
                      left: 416,
                      borderRadius: 5,
                      zIndex: 1000,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {/* <Button
                    color="primary"
                      variant="contained"
                      size="small"
                      style={{ width: "10px" }}
                      onClick={calcularDirecciones}

                      // onClick={handleClick}
                    >
                      <RouteIcon />
                    </Button> */}
                    {rutaInfo === null ? (
                      <Tooltip title="Obtener Ruta" placement="bottom">
                        <Button
                          color="primary"
                          variant="contained"
                          size="small"
                          style={{ width: "10px" }}
                          onClick={calcularDirecciones}
                        >
                          <RouteIcon />
                        </Button>
                      </Tooltip>
                    ) : (
                      <Tooltip title={rutaInfo} placement="bottom">
                        <Button
                          color="primary"
                          variant="contained"
                          size="small"
                          style={{ width: "10px" }}
                          onClick={calcularDirecciones}
                        >
                          <RouteIcon />
                        </Button>
                      </Tooltip>
                    )}
                  </div>

                  <div
                    style={{
                      position: "absolute",
                      top: 10,
                      left: 485,
                      borderRadius: 5,
                      zIndex: 1000,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Autocomplete
                      options={filteredMarkers}
                      getOptionLabel={(option) => option.nombre_sucursal}
                      size="small"
                      value={destino}
                      onChange={(event, value) => handleDestinoChange(value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Destino"
                          // label="Search users..."
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "50px",
                              background: "white",

                              legend: {
                                marginLeft: "30px",
                              },
                            },
                            "& .MuiAutocomplete-inputRoot": {
                              paddingLeft: "20px !important",
                              borderRadius: "10px 50px 50px 10px",
                            },
                            "& .MuiInputLabel-outlined": {
                              paddingLeft: "20px",
                            },
                            "& .MuiInputLabel-shrink": {
                              marginLeft: "20px",
                              paddingLeft: "10px",
                              paddingRight: 0,
                              background: "white",
                            },
                          }}
                        />
                      )}
                      style={{
                        width: "250px",
                      }}
                    />
                  </div>

                  <div
                    style={{
                      position: "absolute",
                      top: 10,
                      left: 760,
                      borderRadius: 5,
                      zIndex: 1000,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <Autocomplete
                      options={filteredMarkers}
                      getOptionLabel={(option) => option.nombre_sucursal}
                      size="small"
                      value={ubicacionSeleccionada}
                      onChange={(event, value) => handleUbicacionChange(value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Buscar ubicación"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "50px",
                              background: "white",
                            },
                            "& .MuiAutocomplete-inputRoot": {
                              paddingLeft: "20px !important",
                              borderRadius: "50px",
                            },
                            "& .MuiInputLabel-outlined": {
                              paddingLeft: "20px",
                            },
                            "& .MuiInputLabel-shrink": {
                              marginLeft: "20px",
                              paddingLeft: "10px",
                              paddingRight: 0,
                              background: "white",
                            },
                          }}
                        />
                      )}
                      style={{
                        width: "250px",
                      }}
                    />
                  </div>

                  <div
                    style={{
                      position: "absolute",
                      top: 10,
                      right: 10,
                      backgroundColor: "white",
                      padding: 5,
                      paddingLeft: 15,
                      paddingRight: 15,
                      paddingBottom: 22,
                      borderRadius: 5,
                      zIndex: 1000,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="caption">
                      <b>Ventas</b> (en kg)
                    </Typography>
                    <div
                      style={{
                        width: gradientWidth,
                        height: 10,
                        background:
                          "linear-gradient(to right, #1396F1, #27F113, #F7E800, #F18913, #F11313)",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: gradientWidth,
                      }}
                    >
                      {/* {[0, 100000, 500000, 1000000, 2000000].map((value, index) => ( */}
                      {["0", "100k", "500k", "1m", "+2m"].map(
                        (value, index) => (
                          <Typography
                            key={index}
                            variant="caption"
                            marginLeft={0.5}
                            style={{
                              position: "absolute",
                              left: index * stepSize,
                            }}
                          >
                            {value}
                          </Typography>
                        )
                      )}
                    </div>
                  </div>
                </div>

                {/* Botón Ir a la sucursal que más vendió */}
                {/* Recuadro para mostrar el botón de la sucursal que más vendió */}
                {sucursalMax && (
                  <Tooltip
                    title="Sucursal con más ventas"
                    placement="top"
                    arrow
                  >
                    <div
                      style={{
                        position: "absolute",
                        bottom: 10,
                        left: 420,
                        backgroundColor: "white",
                        borderRadius: "10px",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                        zIndex: 1000,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <TrendingUpIcon
                        style={{ color: "green", padding: "3px" }}
                      />
                      <div
                        style={{
                          width: "1px",
                          height: "20px",
                          backgroundColor: "black",
                          margin: "0 5px",
                        }}
                      />
                      <div style={{ flexGrow: 1, padding: "3px" }}>
                        <Typography
                          variant="body1"
                          style={{ fontSize: "14px", marginRight: "5px" }}
                        >
                          <b>{sucursalMax.nombre_sucursal}: </b>
                          {sucursalMax.peso_total_vendido}kg
                        </Typography>
                      </div>
                      <Button
                        variant="contained"
                        startIcon={<ReplyIcon />}
                        size="small"
                        onClick={() => {
                          const maxSucursal = encontrarSucursalMax();
                          if (maxSucursal) {
                            setMapCenter({
                              lat: maxSucursal.latitud,
                              lng: maxSucursal.longitud,
                            });
                            handleZoomChange(10);
                          }
                        }}
                        style={{
                          borderRadius: "0px 10px 10px 0px",
                        }}
                      >
                        Ir
                      </Button>
                    </div>
                  </Tooltip>
                )}

                {/* Recuadro para mostrar el botón de la sucursal que menos vendió */}
                {sucursalMin && (
                  <Tooltip
                    title="Sucursal con menos ventas"
                    placement="top"
                    arrow
                  >
                    <div
                      style={{
                        position: "absolute",
                        bottom: 10,
                        left: 70,
                        backgroundColor: "white",
                        // padding: "5px",
                        borderRadius: "10px",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                        zIndex: 1000,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <TrendingDownIcon
                        style={{ color: "red", padding: "3px" }}
                      />
                      <div
                        style={{
                          width: "1px",
                          height: "20px",
                          backgroundColor: "black",
                          margin: "0 5px",
                        }}
                      />
                      <div style={{ flexGrow: 1, padding: "3px" }}>
                        <Typography
                          variant="body1"
                          style={{ fontSize: "14px", marginRight: "5px" }}
                        >
                          <b>{sucursalMin.nombre_sucursal}: </b>
                          {sucursalMin.peso_total_vendido}kg
                        </Typography>
                      </div>
                      <Button
                        variant="contained"
                        startIcon={<ReplyIcon />}
                        size="small"
                        onClick={() => {
                          const minSucursal = encontrarSucursalMin();
                          if (minSucursal) {
                            setMapCenter({
                              lat: minSucursal.latitud,
                              lng: minSucursal.longitud,
                            });
                          }
                        }}
                        style={{
                          borderRadius: "0px 10px 10px 0px",
                        }}
                      >
                        Ir
                      </Button>
                    </div>
                  </Tooltip>
                )}

              </GoogleMap>
            </LoadScript>
          </div>
        </Box>
      </div>

      <div>
        <React.Fragment>
          <Drawer
            anchor="right"
            open={drawerOpen}
            onClose={toggleDrawer("right", false)}
            ModalProps={{
              style: { zIndex: 1300 },
            }}
          >
            {list("right")}
          </Drawer>
        </React.Fragment>
      </div>
    </>
  );
}
