import * as React from 'react';
import { useState, useEffect, Fragment } from 'react';
import Navbar from '../Navbar'
import {UrlApi} from '../../services/apirest';
import {checkExisteRecuentoFisico} from '../../services/services';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment'
import ConfirmDialog from '../../services/ConfirmDialog';
import StkFisicoGranel from '../stockfisicogranel/StkFisicoGranel';

export default function StkFisicoG() {

  let fechahora = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
  //let fechasola = moment(new Date()).subtract(4, "days").format("YYYY-MM-DD")
  let fechasola = moment(new Date()).format("YYYY-MM-DD")
 
  let sucursal = localStorage.getItem('sucursal')
  let segmento = "granel"

  const [haydatos, setHayDatos] = useState(null)
  const [abrirdialogo, setAbrirDialogo] = useState(false)
  
  useEffect(()=>{
    console.log(fechasola, fechahora)
    checkExisteRecuentoFisico(UrlApi,sucursal,segmento,fechasola)
    .then(dataresult =>{dataresult[0] ? setHayDatos(true) : setAbrirDialogo(false)} )
  },[])

  return (
    <div>
    <Navbar />
    {haydatos ?  
        <ConfirmDialog 
              open={true}
              botonconfirmar="Modificar" 
              botoncancelar="Cancelar" 
              titulo="Existe recuento" 
              onclick={setAbrirDialogo}
              errores={"Hola, encontramos un recuento registrado para el dia de hoy para la sucursal "+sucursal}
        />
        : <StkFisicoGranel titulo="Registro de " modificar={false} />
    }

    {abrirdialogo && <StkFisicoGranel titulo="Modificacion de " modificar={true}/>}
    <br /><br /><br />
    </div>
  );
}