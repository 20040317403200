import React from "react";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import AlertTitle from "@mui/material/AlertTitle";
import Grid from "@mui/material/Grid";

function AlertRepartoAsignado(props) {
  // console.log(props)
  console.log(props.dataDelivery.id_reparto)
  return (
    <div>
      <Grid
        my={1}
        p={2}
        container
        alignItems="center"
        justifyContent="space-around"
      >
        <Grid item xs={12} spacing={0}>
          <Alert
            action={
              <Button
                color="inherit"
                size="small"
                onClick={() => {props.setDataDelivery({})
                 props.setIsNewDelivery(false)}} // aca deberia reiniciar los datos del dataDelivery
              >
                Cancelar Asignación
              </Button>
            }
            fullWidth
            severity="info"
          >
            <AlertTitle>Su pedido ha sido asignado a una rendición</AlertTitle>
            {
              props.dataDelivery.id_reparto!==""  ? `Su pedido ha sido asignado a la rendición nro ${props.dataDelivery.id_reparto}, con fecha de salida el día ${props.dataDelivery.fecha_salida_reparto}` : `Se creará una nueva rendición con fecha de salida el día ${props.dataDelivery.fecha_salida_reparto}, para la sucursal ${props.sucursal }`
            }
          </Alert>
        </Grid>
      </Grid>
    </div>
  );
}

export default AlertRepartoAsignado;
