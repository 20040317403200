import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import { grey } from "@mui/material/colors";

function Title(props) {
  return (
    <div>
      <Box sx={{ flexGrow: 1, backgroundColor: "#1F618D", mt: props.pt??2 }}>
        <Grid
          container
          p={1}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={1}>
            <IconButton onClick={() => props.handleClose()}>
              <ArrowBackIcon sx={{ color: grey[50] }} />
            </IconButton>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="h5" color="#fff" textAlign="center">
              {props.titulo}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default Title;
