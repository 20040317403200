import React, { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import PropaneIcon from "@mui/icons-material/Propane";
import ListItemIcon from "@mui/material/ListItemIcon";
import ScaleIcon from "@mui/icons-material/Scale";
import ConstructionIcon from "@mui/icons-material/Construction";
import DescriptionIcon from "@mui/icons-material/Description";
import Grid from '@mui/material/Grid'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EventIcon from '@mui/icons-material/Event';

function DetalleTanque(props) {
  const [dataTanque, setDataTanque] = useState(props.datos);
  // console.log(dataTanque);

  return (
    <div>
      {dataTanque ? (
        <>
          <List sx={{ width: "100%" }}>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
              <Grid item xs={5}>
                <ListItem>
                  <ListItemIcon>
                    <PropaneIcon color="primary"sx={{fontSize:30}} />
                  </ListItemIcon>
                  <ListItemText
                    primary={`N° Serie: ${dataTanque.nro_serie} ${
                      dataTanque.id_sisven
                        ? `(N° Sisven: ${dataTanque.id_sisven})`
                        : ""
                    }`}
                    secondary={
                      <>
                        <b>Fabricante: </b>{" "}
                        {!dataTanque.fabricante_nombre ||
                        dataTanque.id_fabricante !== 1
                          ? dataTanque.fabricante_nombre
                          : "No Registrado"}{" "}
                        <br />
                        <b>Capacidad:</b>{" "}
                        {dataTanque.capacidad
                          ? dataTanque.capacidad + " m3"
                          : "No Registrada"}
                      </>
                    }
                  />
                </ListItem>
              </Grid>

              <Grid item xs={4}>
                <ListItem>
                  <ListItemIcon>
                    <LocationOnIcon color="primary" sx={{fontSize:35}} />
                  </ListItemIcon>
                  <ListItemText
                    // primary={`N° Serie: ${dataTanque.nro_serie} ${
                    //   dataTanque.id_sisven
                    //     ? `(N° Sisven: ${dataTanque.id_sisven})`
                    //     : ""
                    // }`}
                    secondary={
                      <>
                        <b>Sucursal: </b>{" "}
                        {dataTanque.sucursal ? dataTanque.sucursal
                          : "No Registrada"}{" "}
                        <br />
                        <b>Dueño:</b>{" "}
                        {dataTanque.duenio
                          ? dataTanque.descr_duenio  
                          : "No Registrada"}
                        <br />

                        <b>Estado Físico:</b>{" "}
                        {dataTanque.descr_estado
                          ? dataTanque.descr_estado  
                          : "No Registrada"}
                        <br />

                        <b>Vto Prueba Hidráulica:</b>{" "}
                        {dataTanque.vto_pruebahidraulica
                          ? dataTanque.vto_pruebahidraulica  
                          : "No Registrada"}
                        <br />
                      </>
                    }
                  />
                </ListItem>
              </Grid>

              <Grid item xs={3}>
                <ListItem>
                  <ListItemIcon>
                    <EventIcon color="primary" sx={{fontSize:35}} />
                  </ListItemIcon>
                  <ListItemText
                  
                    secondary={
                      <>
                        <b>Vto Prueba Hidráulica:</b>{" "}
                        {dataTanque.vto_pruebahidraulica
                          ? dataTanque.vto_pruebahidraulica  
                          : "No Registrada"}
                        <br />
                      </>
                    }
                  />
                </ListItem>
              </Grid>





              {dataTanque.observaciones ? (
                <ListItem>
                  <ListItemIcon>
                    <DescriptionIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Observaciones"}
                    secondary={dataTanque.observaciones}
                  />
                </ListItem>
              ) : null}
            </Grid>
          </List>
        </>
      ) : null}
    </div>
  );
}

export default DetalleTanque;
