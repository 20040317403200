/*
## Netizar
Component: 
Autor:
Date:
Description:

## Modifications history 
Date:
Modification description:

Date:
Modification description:

*/
import { Popover, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { UrlApi } from '../../../services/apirest';

function Opentanques(props) {
    const [open,setOpen]=useState(true)
    const [datos, setDatos] = useState()

    const handleCerrar=()=>{
        setOpen(false)
        props.open(false)
    }

    useEffect(() => {
        buscatanques();
    },[])

    const buscatanques = async () => {
        const data1 = await fetch(UrlApi + "/stktotalproducto/"+props.datSuc +"/"+props.datFecha + "/" +props.datProd)
        const datastotal = await data1.json()
        setDatos(datastotal)
    }

  return (
    <div>
        <Popover 
            PaperProps= {{
                    sx:{backgroundColor: "lightgreen", width:'350px', paddingLeft: '0px'}}
            }
            open={open}
            onClose={handleCerrar}
            onClick={handleCerrar}
            anchorPosition={{left: props.x, top: props.y }}
            anchorReference='anchorPosition'
        >
            <TableContainer>
                <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Tanque</TableCell>
                        <TableCell>%</TableCell>
                        <TableCell>KgActual</TableCell>
                        <TableCell>CapTotal</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {datos && datos.map((dato, i) => (
                    <TableRow>
                        <TableCell>
                            {dato.tanque}
                        </TableCell>
                        <TableCell>
                            {dato.porcentaje}
                        </TableCell>
                        <TableCell>
                            {dato.cantidad.toFixed(2).toLocaleString('es-AR')}
                        </TableCell>
                        <TableCell>
                            {(dato.capacidadltrs * dato.densidad).toFixed(2)}
                        </TableCell> 
                    </TableRow>
                    ))
                } 
                </TableBody>
                </Table>
            </TableContainer>
        </Popover>
    </div>
  )
}

export default Opentanques