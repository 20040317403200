//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio
// Creation Date : 18/09/2024
// Version : 1
// Description : Pantalla de AMB proveedores de tanque
//------Changes ----------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
//
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useEffect, useState, useMemo } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Container,
  Typography,
  Tooltip,
  IconButton,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  DialogTitle,
} from "@mui/material";
import Navbar from "../Navbar";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import EditIcon from "@mui/icons-material/Edit";
import Notify from "../Notify";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import BlockIcon from "@mui/icons-material/Block";
import { logEventos } from "../../services/services";
import PersonIcon from "@mui/icons-material/Person";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import AddProveedorTanque from "./AddProveedorTanque";
import EditarProveedorTanque from "./EditarProveedorTanque";

function Tanque_proveedor() {
  const [proveedor, setProveedor] = useState();
  const [alta, setAlta] = useState(false);
  const [tabla, setTabla] = useState(true);
  const [editar, setEditar] = useState(false);
  const [confirmar, setConfirmar] = useState(false);
  const [datosProveedor, setDatosProveedor] = useState({});
  const globalTheme = useTheme();
  const [notificacion, setNotificacion] = useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [mensaje, setMensaje] = useState("");
  const [error, setError] = useState(null);
  const [hiddenmuestrainactivo, setHiddenmuestrainactivo] = useState(false);
  const [hiddenmuestraactivos, setHiddenmuestraactivos] = useState(true);

      useEffect(() => {
        logEventos("Acceso al menú Proveedores Tanques", "Proveedores Tanques", "Agregar nuevo proveedor,editarlo,activarlo o inactivarlo", localStorage.getItem("user"))
    }, [])
  //*************************************************************************************************************************************/
  const obtenerProveedoresActivos = () => {
    setHiddenmuestraactivos(true);
    setHiddenmuestrainactivo(false);
    fetch(UrlApi + "/tanque_proveedor/activos")
      .then((data) => data.json())
      .then((data) => setProveedor(data));
  };

  const obtenerProveedoresInactivos = () => {
    setHiddenmuestrainactivo(true);
    setHiddenmuestraactivos(false);
    fetch(UrlApi + "/tanque_proveedor/inactivos")
      .then((data) => data.json())
      .then((data) => setProveedor(data));
  };

  //ejecuta la funcion de obtener
  useEffect(() => {
    obtenerProveedoresActivos();
  }, [tabla, confirmar]);

  const handleClickNuevo = () => {
    setAlta(true);
    setTabla(false);
  };

  const mostrareditar = (datosDeLaLinea) => {
    setEditar(true);
    setTabla(false);
    setDatosProveedor(datosDeLaLinea);
    // console.log("datos linea", datosDeLaLinea ? datosDeLaLinea : "NO");
  };

  const handleVolver = () => {
    setConfirmar(false);
    setTabla(true);
  };

  const abrirModalConfirmar = (datosDeLaLinea) => {
    setConfirmar(true);
    setDatosProveedor(datosDeLaLinea);
  };

  const columns = useMemo(
    () => [
      { header: "Razón social", accessorKey: "razon_social", size: 200 },
      {
        header: "Nombre de contacto",
        accessorKey: "nombre_contacto",
        size: 200,
      },
      {
        header: "Num.de contacto",
        accessorKey: "telefono_contacto",
        size: 150,
      },
      {
        header: "Num.CUIT",
        accessorKey: "nro_cuit",
        size: 100,
      },
      {
        header: "Dirección",
        accessorKey: "direccion",
        size: 200,
      },
      {
        header: "Email",
        accessorKey: "email",
        size: 200,
      },
    ],
    []
  );
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)" //background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
            },
          },
        },
      }),
    [globalTheme]
  );
  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "success",
    size: "small",
  };
  const initialState = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
  };
  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };

  const marcarInactivo = async (e) => {
    logEventos("Guardar proveedor inactivo", "Proveedores Tanques", "Se guardo proveedor inactivo", localStorage.getItem("user"))
    e.preventDefault();
    const response = await fetch(
      UrlApi + "/tanque_proveedor/" + datosProveedor.id_tanque_proveedor,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(
          datosProveedor.estado === 0 ? { estado: 1 } : { estado: 0 }
        ),
      }
    );
    if (response.ok === false) {
      const error = response && response.json();
      setError(error);
      alert("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      try {
        const json = await response.json();
        if (json.message) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje(
            json.message === "OK" ? "Guardado" : "Guardado. Actualizar RTA Api."
          );
          obtenerProveedoresActivos();
        } else if (json.sqlMessage) {
          json && setError(json);
          alert("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
        } else {
          alert("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje("Guardado");
          obtenerProveedoresActivos();
        } else {
          alert("Error: " + error);
        }
      }
    }
    handleVolver();
    obtenerProveedoresActivos();
  };

  return (
    <div>
      <Navbar />
      <Container maxWidth="xl" mt={1} mb={2}>
        {proveedor && tabla ? (
          <Box mt={2} boxShadow={3}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableColumnResizing
                columns={columns}
                data={proveedor}
                initialState={initialState}
                positionActionsColumn="last"
                enableExpandAll={false}
                enableToolbarInternalActions={true}
                muiTableHeadCellProps={tableHeadCellProps}
                muiTableBodyCellProps={({ row }) => ({
                  align: "left",
                  sx: {
                    backgroundColor:
                      row.original.estado === 0 ? "#efc3c3" : "#b3e6b3",
                    color: "black",
                    borderColor: "black",
                  },
                })}
                enableColumnActions={false}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                enableDensityToggle={false}
                renderTopToolbarCustomActions={() => (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddBoxTwoToneIcon />}
                      onClick={handleClickNuevo}
                    >
                      Nuevo Proveedor
                    </Button>
                    <Typography variant="poster" fontSize={26}>
                      <LocalShippingIcon sx={{ size: 20 }} />
                      Proveedores de tanque
                    </Typography>
                  </>
                )}
                layoutMode="grid"
                localization={MRT_Localization_ES}
                enableColumnDragging={false} //No permite que se arrastren las columnas.
                enableEditing
                // ACCIONES
                renderToolbarInternalActions={(
                  { table } // boton para nuevo , al lado del buscar
                ) => (
                  <>
                    {!hiddenmuestraactivos ? (
                      <Tooltip title="Ver proveedores activos">
                        <IconButton
                          color="success"
                          onClick={() => {
                            obtenerProveedoresActivos();
                          }}
                        >
                          <PersonIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    {!hiddenmuestrainactivo ? (
                      <Tooltip title="Ver proveedores inactivos">
                        <IconButton
                          color="error"
                          onClick={() => {
                            obtenerProveedoresInactivos();
                          }}
                        >
                          <PersonOffIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    <MRT_FullScreenToggleButton table={table} />
                  </>
                )}
                renderRowActions={(
                  { row } //las acciones del costado editar y ver
                ) => (
                  <div>
                    <Tooltip arrow placement="left" title="Editar proveedor">
                      <IconButton
                        onClick={() => mostrareditar(row.original)}
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    {row.original.estado === 0 ? (
                      <Tooltip title="Marcar como activo">
                        <IconButton
                          color="success"
                          onClick={() => abrirModalConfirmar(row.original)}
                        >
                          <CheckCircleOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Marcar como inactivo">
                        <IconButton
                          onClick={() => abrirModalConfirmar(row.original)}
                          color="error"
                        >
                          <BlockIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                )}
              />
            </ThemeProvider>
            <br />
            <br />
          </Box>
        ) : null}

        {confirmar ? (
          <div>
            <Dialog open={confirmar} onClose={handleVolver}>
              <form onSubmit={marcarInactivo}>
                <Box backgroundColor={"#1F618D"}>
                  <DialogTitle>
                    {datosProveedor.estado === 0 ? (
                      <Typography variant="h6" align="center" color="#ffffff">
                        Activar {datosProveedor.razon_social}
                      </Typography>
                    ) : (
                      <Typography variant="h6" align="center" color="#ffffff">
                        Desactivar {datosProveedor.razon_social}
                      </Typography>
                    )}
                  </DialogTitle>
                </Box>
                <DialogContent>
                  <DialogContentText textAlign={"center"}>
                    {datosProveedor.estado === 0 ? (
                      <> ¿Desea activar el proveedor ? </>
                    ) : (
                      <> ¿Desea desactivar el proveedor ? </>
                    )}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItemns="center"
                  >
                    <Button
                      variant="contained"
                      onClick={handleVolver}
                      style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Confirmar
                    </Button>
                  </Grid>
                </DialogActions>
              </form>
            </Dialog>
          </div>
        ) : null}

        {notificacion ? (
          <Notify
            mensaje={mensaje}
            open={openSnack}
            color={"#D4EFDF"}
            severity={"success"}
            handleClose={handleVolver}
          />
        ) : null}

        {alta ? (
          <AddProveedorTanque
            alta={setAlta}
            tabla={setTabla}
          ></AddProveedorTanque>
        ) : null}

        {editar ? (
          <EditarProveedorTanque
            editar={setEditar}
            tabla={setTabla}
            datos={datosProveedor}
            // actualizar={setObt}
          ></EditarProveedorTanque>
        ) : null}
      </Container>
    </div>
  );
}

export default Tanque_proveedor;
