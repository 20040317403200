//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio
// Creation Date : 14/10/2024
// Version : 1
// Description : Grafico de reclamos en el año (por meses) por estado
//------Changes ----------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useEffect, useState } from "react";
import { UrlApi } from "../../../../services/apirest";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";


const colores = ["#6BDD3F", "#FF4D4D", "#FF811D", "#FFFF00"];

const getColor = (index) => colores[index % colores.length];

function Grafico_estados_desconexion() {
  const [cantidadXmes, setCantidadXmes] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    dayjs().subtract(1, "month").startOf("month")
  );

  const obtenerCantidadXmes = () => {
    const formattedDate = selectedDate.format("YYYY"); // Formatear la fecha seleccionada
    fetch(UrlApi + "/reclamos/cantidadPorMesDesconexion/fecha/" + formattedDate)
      .then((data) => data.json())
      .then((data) => setCantidadXmes(data));
  };

  useEffect(() => {
    obtenerCantidadXmes();
  }, [selectedDate]); // Actualizar los datos cuando se cambie la fecha

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };
  // console.log("CANTIDAD X MES", cantidadXmes);

  const paperStyle4 = {
    padding: "10px",
    margin: "10px",
    width: "1200px",
  };

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  //  console.log("AÑO ACTUAL: ", currentYear);

  const todosLosMeses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  // Función para obtener el nombre del mes a partir de la fecha en formato YYYY-MM
  const obtenerNombreMes = (fecha) => {
    const mes = dayjs(fecha, "YYYY-MM").format("M") - 1; // Extraer el mes (0-11)
    return todosLosMeses[mes];
  };

  const transformarDatosParaBarChart = (data) => {
    const estados = Array.from(new Set(data.map((item) => item.valor_estado)));

    // Crear un objeto para acumular los datos por mes y estado
    const acumulador = todosLosMeses.reduce((acc, mes) => {
      acc[mes] = estados.reduce((estadoAcc, estado) => {
        estadoAcc[estado] = 0;
        return estadoAcc;
      }, {});
      return acc;
    }, {});

    // Rellenar el acumulador con los datos recibidos
    data.forEach((item) => {
      const nombreMes = obtenerNombreMes(item.fecha);
      if (acumulador[nombreMes]) {
        acumulador[nombreMes][item.valor_estado] = item.cantidad;
      }
    });

    // Convertir el objeto acumulador en un array de datos para el gráfico
    const dataset = todosLosMeses.map((mes) => ({
      mes,
      ...acumulador[mes],
    }));

    return { dataset, estados, meses: todosLosMeses };
  };

  const { dataset, estados, meses } =
    transformarDatosParaBarChart(cantidadXmes);

  const chartSetting = {
    yAxis: [
      {
        label: "Cantidad",
      },
    ],
    width: 500,
    height: 300,
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: "translate(-20px, 0)",
      },
    },
  };

  const valueFormatter = (value) => `${value}`;

  return (
    <div>
      <Paper elevation={10} style={{ ...paperStyle4 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
            <DatePicker
              views={["year"]}
              label="Año"
              value={selectedDate}
              onChange={handleDateChange}
              slotProps={{
                textField: {
                  size: "small",
                  // sx: { width: "150px" },
                },
              }}
            />
          </LocalizationProvider>
          <Typography
            align="center"
            style={{
              fontWeight: "bold",
              fontSize: 20,
              flexGrow: 1,
              textAlign: "center",
            }}
          >
            Reclamos en el año {selectedDate.year()}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: 300,
            width: "100%",
          }}
        >
          <BarChart
            dataset={dataset}
            xAxis={[{ scaleType: "band", dataKey: "mes" }]}
            series={estados.map((estado, index) => ({
              dataKey: estado,
              label: estado,
              valueFormatter,
              highlightScope: {
                highlighted: "series",
                faded: "global",
              },
              color: getColor(index), // Añadido color para cada serie
            }))}
            {...chartSetting}
            width={1000}
          />
        </div>
      </Paper>
    </div>
  );
}

export default Grafico_estados_desconexion;
