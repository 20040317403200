import React,{useState} from 'react'

const useCP = () => {
const [CP, setCP] = useState()
   function handleCP(dato){
    setCP(dato)
   }
  return {handleCP,CP}
}

export default useCP