//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio
// Creation Date : 05/08/2024
// Version : 1
// Description : Grafico de tanques por año
//------Changes ----------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useEffect, useState } from "react";
import { UrlApi } from "../../../../services/apirest";
import { PieChart } from "@mui/x-charts/PieChart";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";


function Graf_motiv_inst() {
  const [cantidadTanques, setCantidadTanques] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    dayjs().subtract(1, "month").startOf("month")
  );

  const obtenerCantidadTanque = () => {
    const formattedDate = selectedDate.format("YYYY"); // Formatear la fecha seleccionada
    fetch(UrlApi + "/reclamos/cantidadTanquesPorcentaje/fecha/" + formattedDate)
      .then((response) => response.json())
      .then((data) => {
        setCantidadTanques(data);
      });
  };

  useEffect(() => {
    obtenerCantidadTanque();
  }, [selectedDate]);

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  // Ordenar los datos de mayor a menor por cantidad de tanques
  const cantidadOrdenada = [...cantidadTanques].sort(
    (a, b) => b.cantidad - a.cantidad
  );

  // Calcular el total de tanques
  const totalTanques = cantidadOrdenada.reduce(
    (total, item) => total + item.cantidad,
    0
  );

  // Colores para cada segmento del gráfico
  const colors = [
    "#90B2EE",
    "#A8CEF2",
    "#F1D295",
    "#A8CEF2",
    "#F1A095",
    "#A5E59D",
  ];

  // Transformar los datos para el PieChart, incluyendo el porcentaje
  const data = (cantidadOrdenada || []).map((item, index) => ({
    label: `${item.tipo} (${((item.cantidad / totalTanques) * 100).toFixed(
      2
    )}%)`,
    value: item.cantidad,
    color: colors[index % colors.length], // Asignar color basado en el índice
  }));

  const size = {
    width: 400,
    height: 300,
  };

  // Estilo para el contenedor del gráfico
  const paperStyle = {
    padding: "10px",
    margin: "10px auto",
    width: "80%",
    maxWidth: "600px",
  };

  // Estilo para el contenedor del título
  const headerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
  };

  return (
    <div>
      <Paper
        elevation={3}
        style={{
          ...paperStyle,
          flex: 1,
          marginRight: 600,
          marginTop: 20,
        }}
      >
        <div style={headerStyle}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
            <DatePicker
              views={["year"]}
              label="Año"
              value={selectedDate}
              onChange={handleDateChange}
              slotProps={{
                textField: {
                  size: "small",
                  // sx: { width: "150px" },
                },
              }}
            />
          </LocalizationProvider>
          <Typography
            style={{ fontWeight: "bold", fontSize: 24, color: "#333" }}
          >
            Porcentaje de tanques {selectedDate.year()}
          </Typography>
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", height: "200px" }}
        >
          <PieChart
            style={{ width: "100%", height: "100%" }}
            series={[
              {
                highlightScope: { faded: "global", highlighted: "item" },
                faded: {
                  innerRadius: 30,
                  additionalRadius: -30,
                  color: "#ddd",
                },
                data,
              },
            ]}
            color={data.map((d) => d.color)} // Asigna los colores al gráfico
            slotProps={{
              legend: {
                hidden: false, // Oculta la leyenda predeterminada
              },
            }}
          />
        </div>
      </Paper>
    </div>
  );
}

export default Graf_motiv_inst;
