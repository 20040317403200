import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import MaterialReactTable, { MRT_FullScreenToggleButton, } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import { getData, putData } from '../../../services/library';
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import { Divider, Grid, Box, Tooltip, IconButton, MenuItem, ListItemIcon, Container } from '@mui/material';
import VerDatosReq from '../requerimientos/ver_requerimientos/lista_requerimientos/VerDatosReq';
import Notify from '../../Notify';
import GenerarOrdenes from './GenerarOrdenes';
import AddOrdenCompra from './AddOrdenCompra';
import { RemoveCircleOutline, Article, TaskAlt, AddBoxTwoTone, FileDownload } from '@mui/icons-material';
import FormPrepararReq from './FormPrepararReq';
import DialogConTextField from '../../Dialogs/DialogConTextField';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { enviarmails } from '../../../services/services';
import { logEventos } from '../../../services/services';
import { ExportToCsv } from 'export-to-csv';

function AutorizacionCompras(props) {
  const globalTheme = useTheme();
  const [datosDelinea, setdatosDelinea] = useState({})
  const [AutorizJefe, setAutorizJefe] = useState(null)// guardamos los requerimientos_materiales
  const [tabla, setTabla] = useState(true); //Estado q mostrará o no la tabla (pantalla inicial) 
  const [altaOrden, setAltaOrden] = useState(false); //Estado q mostrará o no la pantalla de alta
  const [rechazarOrden, setrechazarOrden] = useState(false);
  const [generar, setGenerarordenes] = useState(false)
  const [entregado, setEntregado] = useState(false);
  const [open, setOpen] = useState(false);
  const [observaciones, setObs] = useState("");
  const iconRemove = <RemoveCircleOutlineIcon sx={{ fontSize: 40 }} />;
  const [notificacion, setNotificacion] = useState({
    open: false,
    mensaje: "",
    severity: "",
    color: "",
  });

  const obtenerRequerimientos = () => {
    getData('/requerimientos_materiales/autorizados', setAutorizJefe)
  };

  useEffect(() => {
    obtenerRequerimientos()
  }, [tabla]);


  const handleCrearOrden = (datosDeLaLinea) => {
    setAltaOrden(true);
    setTabla(false);
    setdatosDelinea(datosDeLaLinea)
    setOpen(false)
    props.tab(false)
  };

  const handleRechazarOrden = (datosDeLaLinea) => {
    setrechazarOrden(true)
    setdatosDelinea(datosDeLaLinea)
  };

  const handleMarcarEntregado = (datosDeLaLinea) => {
    setEntregado(true);
    setdatosDelinea(datosDeLaLinea)
  };

  const toggleDrawer = (row, newOpen) => () => {
    setOpen(true);
    setdatosDelinea(row)
  };

  const columns = useMemo(() =>
    [{ header: '#', accessorKey: 'idrequerimiento', size: 100 },
    { header: 'Fecha', accessorFn: (req) => moment(req.fecha_entrega).format("DD-MM-YYYY"), size: 100 },
    { header: 'Sucursal', accessorKey: 'nombre_sucursal', size: 130 },
    // { header: 'Estado', accessorKey: 'valor_estado', size: 130 },
    { header: 'Solicita', accessorKey: 'usuario_registra', size: 130 },
    { header: 'Movil', accessorKey: 'patente', size: 100 },
    { header: 'Requerimiento', accessorFn: (req) => req.para_comprar === 1 ? "Para comprar" : " Interno", size: 100 }
    ]
    , []);


  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //larger tooltip font size                                
              },
            },
          },
        },

      }), [globalTheme])

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "success",
    size: "small",
  };

  const initialState = {
    density: "compact",
    showGlobalFilter: true,
    enableGrouping: true,
    pagination: { pageIndex: 0, pageSize: 15 },
    // columnVisibility:{
    //   id_movil:false,
    //   estado:false,
    //   valor_estado:false,
    //   created_at:false,
    //   updated_at:false,
    //   fecha_entrega:false,
    //   usuario_registra:false,
    //   usuario_autoriza:false,
    //   fecha_autorizado:false,
    //   observaciones:false,
    //   usuario_gestiona:false,
    //   comentarios_internos:false,
    //   uuid_requerimiento:false,
    //   observaciones_baja:false,
    //   uuid_ordencompra:false,
    //   observaciones_entregado:false,
    //   observaciones_jefe:false,
    //   para_compras:false,
    //   para_rrhh:false,
    //   email_registrante:false,
    //   autorizado_jefe_compras:false,
    //   nombrepila:false,
    //   apellido:false
    // }
  };

  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };

  const rowBg = {
    1: "#abebc6",
    0: "#85c1e9",
  };

  async function rechazarRequerimiento(e) {
    await putData(e,
      '/requerimientos_materiales/' + datosDelinea.idrequerimiento,
      {
        estado: 93,
        observaciones_baja: observaciones
      },
      setNotificacion
    );
    enviarmails(datosDelinea.email_registrante, "Requerimiento rechazado por el departamento de compras", `<p>El requerimiento ${datosDelinea.idrequerimiento} fue rechazado por el usuario ${localStorage.getItem('user')}.</p>`)
    logEventos("Rechazar requerimientos", "Generar ordenes", "Generar ordenes", localStorage.getItem("user"))
  };

  const handleCloseModal = () => {
    setEntregado(false)
    setTabla(true)
    obtenerRequerimientos()
  };

  const columnstoCsv = useMemo(() =>
    [{ header: '#', accessorKey: 'idrequerimiento', size: 100 },
    { header: 'Fecha de entrega', accessorFn: (req) => moment(req.fecha_entrega).format("DD-MM-YYYY"), size: 100 },
    { header: 'Sucursal', accessorKey: 'nombre_sucursal', size: 130 },
    { header: 'Patente  ', accessorKey: 'patente', size: 130 },
    { header: 'Estado', accessorKey: 'valor_estado', size: 130 },
    { header: 'Solicita', accessorKey: 'usuario_registra', size: 130 },
    { header: 'Autoriza', accessorKey: 'usuario_autoriza', size: 130 },
    { header: 'Movil', accessorKey: 'patente', size: 100 },
    { header: 'Fecha autorizado', accessorFn: (req) => moment(req.fecha_autorizado).format("DD-MM-YYYY"), size: 100 },
    { header: 'Observaciones', accessorKey: 'observaciones', size: 100 },
    { header: 'Comentarios internos', accessorKey: 'comentarios_internos', size: 100 },
    { header: 'Requerimiento', accessorFn: (req) => req.para_comprar === 1 ? "Para comprar" : " Interno", size: 100 }
    ]
    , []);

  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    filename: `Listado de requerimientos autorizados`,
    headers: columnstoCsv.map((c) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportData = () => {
    csvExporter.generateCsv(AutorizJefe);
  };  

  

  return (
    <div>
      <Container maxWidth="xl" mt={1} mb={2}>
        {AutorizJefe && tabla ? (
          <Box mt={2} boxShadow={3}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableRowVirtualization
                enableColumnVirtualization
                columns={columns}
                data={AutorizJefe}
                enableRowActions
                initialState={initialState}
                positionActionsColumn="last"
                muiTableHeadCellProps={tableHeadCellProps}
                muiTableBodyCellProps={({ row }) => ({
                  align: "left",
                  sx: {
                    backgroundColor:
                      rowBg[row.original.para_comprar] ?? "#fff",
                    color: "black",
                    borderColor: "black",
                  },
                })}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                renderToolbarInternalActions={({ table }) => (
                  <>
                    <Tooltip placement="right" title="Generar orden desde cero">
                      <IconButton color="primary" onClick={() => {
                        setGenerarordenes(true)
                        setTabla(false)
                        setOpen(false)
                        props.tab(false)
                      }}>
                        <AddBoxTwoTone />
                      </IconButton>
                    </Tooltip>,
                    <Tooltip title="Descargar lista">
                      <IconButton color="primary" onClick={handleExportData}>
                        <FileDownload />
                      </IconButton>
                    </Tooltip>

                    <MRT_FullScreenToggleButton table={table} />
                  </>
                )}
                localization={MRT_Localization_ES}
                enableColumnDragging={false}
                renderRowActionMenuItems={({ closeMenu, row }) => [
                  <MenuItem
                    disabled={row.original.para_comprar === 0}
                    key={0}
                    onClick={() => {
                      closeMenu();
                      handleCrearOrden(row.original);
                    }}
                    sx={{ m: 0 }}>
                    <ListItemIcon>
                      <Article color="primary" />
                    </ListItemIcon>
                    Generar orden de compra
                  </MenuItem>,
                  <Divider />,
                  <MenuItem
                    key={1}
                    onClick={() => {
                      closeMenu();
                      handleRechazarOrden(row.original);
                    }}
                    sx={{ m: 0 }}>
                    <ListItemIcon>
                      <RemoveCircleOutline color="error" />
                    </ListItemIcon>
                    Rechazar
                  </MenuItem>,
                  <Divider />,
                  <MenuItem
                    disabled={row.original.para_comprar === 1}
                    key={2}
                    onClick={() => {
                      handleMarcarEntregado(row.original);
                      closeMenu();
                    }}
                    sx={{ m: 0 }}>
                    <ListItemIcon>
                      <TaskAlt color="secondary" />
                    </ListItemIcon>
                    Preparar
                  </MenuItem>
                ]}
                enableMultiRowSelection={false}
                muiToolbarAlertBannerProps={false}
                muiTableBodyRowProps={({ row }) => ({
                  onClick: () => {
                    row.getToggleSelectedHandler()
                    toggleDrawer(row.original)()
                  },
                  sx: {
                    cursor: 'pointer'
                  }
                })}
                enablePagination={false}
                enableBottomToolbar={false}
                columnFilterDisplayMode="popover"
              />
            </ThemeProvider>
          </Box>
        )
          : null}

        <Grid key={datosDelinea.idrequerimiento}>
          {open ?
            <VerDatosReq
              datosreq={datosDelinea}
              setDatosreq={setdatosDelinea}
              anchor={setOpen}
              tabla={setTabla}
              actualizar={obtenerRequerimientos} /> : null}
        </Grid>


      </Container>
      {altaOrden ? (
        <AddOrdenCompra
          alta={setAltaOrden}
          tabla={setTabla}
          datos={datosDelinea}
          tab={props.tab}
        />
      ) : null}

      {rechazarOrden ? (
        <DialogConTextField
          title={"Desaprobar requerimiento"}
          open={rechazarOrden}
          setOpen={setrechazarOrden}
          notify={notificacion}
          setNotify={setNotificacion}
          btnText={"Desaprobar"}
          guardar={rechazarRequerimiento}
          color={"#f53649"}
          subtitle={`¿Está seguro que desea desaprobar el requerimiento N° ${datosDelinea.idrequerimiento}`}
          value={observaciones}
          setValue={setObs}
          icon={iconRemove}
        />
      ) : null}

      {generar ? (
        <GenerarOrdenes
          tabla={setTabla}
          alta={setGenerarordenes}
          tab={props.tab} />
      ) : null}

      {entregado ? (
        <FormPrepararReq
          datos={datosDelinea}
          open={setEntregado}
          actualizar={obtenerRequerimientos}
          dialog={setOpen}
        />
      ) : null}

      {notificacion && notificacion.open ? (
        <Notify
          mensaje={notificacion.mensaje}
          open={notificacion.open}
          color={notificacion.color}
          handleClose={handleCloseModal}
          severity={notificacion.severity}
        />
      ) : null}
    </div>
  )
}

export default AutorizacionCompras

//========================================================================
/*----- Component --------------------------------------------------------/
// Developer     : Candela Grosso   
// Creation Date : 30/11/2023
// Version       : 1.0  
// Description   :  Con este componente el usuario es capaz de :
    - VISUALIZAR LOS REQUERIMIENTOS TANTO INTERNOS COMO PARA COMPRAR CON SUS DATOS
    - GESTIONAR UNA ORDEN DE COMPRA DESDE 0
    - APROBAR UN REQUERIMIENTO (NTERNO O NO)
    - RECHAZAR UN REQUERIMIENTO (INTERNO O NO)
    - GENERAR UNA ORDEN DE COMPRA A PARTIR DE UN REQUERIMIENTO
//------Changes --------------------------------------------------------------------- -------------//
// Change Date   :
// Change by     :
// Change description: 
//========================================================================*/