// Developer     : Magalí Perea
// Creation Date : 2024-04-22
// Version       : 1
// Description   : Listado de cantidad de pedidos por empleado, entre fecha y por empresa.

import React, { useMemo } from "react";
import {
  createTheme,
  ThemeProvider,
  useTheme,
  Container,
  Typography,
} from "@mui/material";
import MaterialReactTable from "material-react-table";
import Navbar from "../../Navbar";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import moment from "moment";
import TitleUploaded from "../../../components/TitleUploaded";
import Box from "@mui/material/Box";

function ListaPedidosGeneral(props) {
  const pedidos = props.pedidos;

  const columns = useMemo(
    () => [
      {
        accessorKey: "nombre_completo", //access nested data with dot notation
        header: "Empleado",
      },
      {
        accessorKey: "cantidad", //access nested data with dot notation
        header: "Viandas Pedidas",
      },
    ],
    []
  );

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "primary",
    size: "small",
  };

  const initialState = {
    density: "compact",
    showGlobalFilter: true,
    pagination: {
      pageSize: 10,
      pageIndex: 0,
    },
  };

  const tableHeadCellProps = {
    align: "center",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "#B2BABB",
    },
  };

  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem",
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const handleClose = () => {
    props.setShowList(false);
    props.setShowForm(true);
  };

  return (
    <div>
      {pedidos && (
        <Container maxWidth="xl">
          <TitleUploaded
            title={"Resumen Cantidad Viandas Por Empleado"}
            handleClose={handleClose}
          />
          <Box sx={{ my: 1 }}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableColumnFilterModes={false}
                enableColumnFilters={false}
                enableFullScreenToggle={false}
                enableColumnVirtualization={false}
                enableHiding={false}
                enableColumnResizing={false}
                columns={columns}
                data={pedidos}
                initialState={initialState}
                muiTableHeadCellProps={tableHeadCellProps}
                localization={MRT_Localization_ES}
                muiTableBodyCellProps={({ row }) => ({
                  align: "left",
                  sx: {
                    backgroundColor: "#ebebeb",
                    color: "black",
                    borderColor: "#B2BABB",
                  },
                })}
                enableColumnActions={false}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                enableDensityToggle={false}
                layoutMode="grid"
                renderTopToolbarCustomActions={() => (
                  <>
                    <Typography
                      variant="subtitle1"
                      style={{ fontSize: 18 }}
                      color="initial"
                      mt={1}
                    >
                      Período:{" "}
                      {moment(props.reqData.fecha_inicio).format("DD-MM-YYYY")}{" "}
                      a {moment(props.reqData.fecha_fin).format("DD-MM-YYYY")}{" "}
                    </Typography>
                  </>
                )}
              />
            </ThemeProvider>
          </Box>
        </Container>
      )}
    </div>
  );
}

export default ListaPedidosGeneral;
