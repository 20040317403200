//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio
// Creation Date : 07/11/2024
// Version : 1
// Description : pantalla de agregar item
//------Changes --------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Container,
  Grid,
  Box,
  TextField,
  Button,
  Dialog,
  DialogContent,
  Stack,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import Notify from "../Notify";
import Alerta from "../Alerta";
import { logEventos } from "../../services/services";
import Title from "../Title";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";

function AddItem(props) {
  const [datosItems, setDatosItems] = useState({
    id_item: null,
    nombre_item: "",
    definicion_item: "",
    estado_item: 1,
    id_grupo: null,
    fecha_vencimiento: null,
  });
  const [rowGrupo, setRowGrupo] = useState(props.row);
  const [disBtn, setDisBtn] = useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
  const [mensajeAlerta, setMensajeAlerta] = useState(""); //mensaje que contendrá el componente "alerta"
  const [finalizar, setFinalizar] = useState(); //finalizar === 1 lo manda como prop el componente "notify" e indica que la transaccion ha finalizado y debe ejecutarse la funcion para cerrar
  const [severityAlerta, setSeverityAlerta] = useState(""); //define severidad de la alerta (success error o warning)
  const [severitySnack, setSeveritySnack] = useState(""); //define severidad del snackbar (notificación)

  const [grupo, setGrupo] = useState();
  const [grupoSeleccionado, setGrupoSeleccionado] = useState("");
  const [disabledGuardar, setDisabledGuardar] = useState(false);
  const [showTable, setShowTable] = useState(false); // Nuevo estado para mostrar o no la tabla de items

  const [dialogAdd, setDialogAdd] = useState(props.dialogAdd);

  const [obsObligatoria, setObsObligatoria] = useState(1); // Inicialmente en 'obligatorio'
  const [fechaObligatoria, setFechaObligatoria] = useState(1); // Inicialmente en 'obligatorio'

  const obtenerGrupo = () => {
    fetch(UrlApi + "/seg_hig_grupos/activos")
      .then((data) => data.json())
      .then((data) => {
        setGrupo(data);
        // Verificar si hay datos en la tabla de items
        if (data && data.length > 0) {
          setShowTable(true);
        } else {
          setShowTable(false);
        }
      });
  };

  useEffect(() => {
    obtenerGrupo();
  }, []);

  const handleClose = () => {
    setDialogAdd(!dialogAdd);
    if (props.onClose) {
      props.onClose();
    }
  };

  const almacenar = (e, value) => {
    if (value) {
      setGrupoSeleccionado(value);
    }
    setDatosItems({
      ...datosItems,
      [e.target.name]: e.target.value === "" ? null : e.target.value,
    });
  };
  // console.log("grupo seleccionado", grupoSeleccionado);
  // console.log("rowGrupo", rowGrupo);

  const guardarItem = async (e) => {
    logEventos(
      "Guardar un nuevo item",
      "Configuración de checklist",
      "Se guardo el item creado",
      localStorage.getItem("user")
    );
    setDisBtn(true);
    e.preventDefault();
    const response = await fetch(UrlApi + "/seg_hig_items", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        nombre_item: datosItems.nombre_item,
        definicion_item: datosItems.definicion_item,
        estado_item: 1,
        id_grupo: rowGrupo.id_grupo,
        obs_oblig: obsObligatoria,
        fecha_venc_oblig: fechaObligatoria,
        usuario: localStorage.getItem("user"),
      }),
    });
    if (response.ok === false) {
      console.log("ERROR EN API");
      const error = response && response.json();
      setError(error);
      setSeverityAlerta("error");
      setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      console.log("Conexión con api OK");
      try {
        //Parseo respuesta de la api a json()
        const json = await response.json();
        //si api me devuelve lo q le puse en message
        if (json.message) {
          //Si ese mensaje es "OK"
          if (json.message === "OK") {
            console.log("Guardado Satisfactoriamente");
            setNotificacion(true);
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setMensaje("Guardado");
            setSeveritySnack("success");
          } else {
            console.log("res en api incorrecto. Revisar que sea 'OK'");
            setNotificacion(true);
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setSeveritySnack("error");
            setMensaje("Registro Guardado. REVISAR RESPUESTA DE API (E_S)");
          }
        } else if (json.sqlMessage) {
          json && setError(json);
          console.log(json);
          setMensajeAlerta(
            "SQL erróneo (" + json.code + " Errno: " + json.errno + " )"
          );
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          console.log(
            "Guardado. Advertencia: Actualizar respuesta de api. JSON invalido."
          );
          setNotificacion(true);
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("Guardado");
          setSeveritySnack("success");
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      }
    }
    handleClose();
  };

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  const size = "small";

  useEffect(() => {
    if (!datosItems.nombre_item || !datosItems.definicion_item) {
      setDisabledGuardar(true);
    } else {
      setDisabledGuardar(false);
    }
  }, [datosItems]);

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "#1890ff",
          ...theme.applyStyles("dark", {
            backgroundColor: "#177ddc",
          }),
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: "rgba(0,0,0,.25)",
      boxSizing: "border-box",
      ...theme.applyStyles("dark", {
        backgroundColor: "rgba(255,255,255,.35)",
      }),
    },
  }));

  return (
    <div>
      <Dialog
        open={dialogAdd}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        color="#F4F6F6"
      >
        <Title
          titulo={`Agregar Item`}
          handleClose={() => {
            handleClose();
          }}
        />
        <Box
          border={0.5}
          borderColor={grey[300]}
          backgroundColor="#F4F6F6"
          p={2}
          py={2}
          mt={1}
        >
          <DialogContent>
            <Container maxWidth="xl">
              <form autoComplete="off" onSubmit={guardarItem}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={12} sm={10} md={8} lg={6}>
                    <TextField
                      fullWidth
                      size={size}
                      margin="normal"
                      placeholder="..."
                      style={style}
                      focused
                      color="primary"
                      required
                      name="nombre_item"
                      label="Nombre del item"
                      variant="outlined"
                      multiline
                      rows={4}
                      value={datosItems.nombre_item}
                      inputProps={{
                        maxLength: 130,
                      }}
                      onChange={almacenar}
                    />
                  </Grid>

                  <Grid item xs={12} sm={10} md={8} lg={6}>
                    <TextField
                      fullWidth
                      size={size}
                      margin="normal"
                      placeholder="..."
                      style={style}
                      focused
                      color="primary"
                      required
                      name="definicion_item"
                      label="Definición del item"
                      variant="outlined"
                      value={datosItems.definicion_item}
                      multiline
                      rows={4}
                      inputProps={{
                        maxLength: 185,
                      }}
                      onChange={almacenar}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography sx={{ textDecoration: "underline" }}>
                      Obligatoriedad de la observación:
                    </Typography>
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ alignItems: "center" }}
                      marginTop={1}
                    >
                      <Typography>No Obligatorio</Typography>
                      <AntSwitch
                        checked={obsObligatoria === 1} // El switch estará en 'checked' si obsObligatoria es 1
                        onChange={(e) => {
                          const nuevoEstado = e.target.checked ? 1 : 0;
                          setObsObligatoria(nuevoEstado);
                          setDatosItems({
                            ...datosItems,
                            obs_oblig: nuevoEstado,
                          });
                        }}
                        inputProps={{ "aria-label": "ant design" }}
                      />
                      <Typography>Obligatorio</Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography sx={{ textDecoration: "underline" }}>
                      Obligatoriedad de la fecha de vencimiento:
                    </Typography>
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ alignItems: "center" }}
                      marginTop={1}
                    >
                      <Typography>No Obligatorio</Typography>
                      <AntSwitch
                        checked={fechaObligatoria === 1} // El switch estará en 'checked' si fechaObligatoria es 1
                        onChange={(e) => {
                          const nuevoEstado = e.target.checked ? 1 : 0;
                          setFechaObligatoria(nuevoEstado);
                          setDatosItems({
                            ...datosItems,
                            fecha_venc_oblig: nuevoEstado,
                          });
                        }}
                        inputProps={{ "aria-label": "ant design" }}
                      />
                      <Typography>Obligatorio</Typography>
                    </Stack>
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="flex-end"
                  mt={2}
                >
                  <Button
                    variant="contained"
                    onClick={handleClose}
                    color="error"
                  >
                    Volver
                  </Button>

                  <Button
                    disabled={disabledGuardar}
                    variant="contained"
                    type="submit"
                    color="success"
                  >
                    Guardar item
                  </Button>
                </Grid>
              </form>
            </Container>
          </DialogContent>
        </Box>
      </Dialog>

      {notificacion ? (
        <Notify
          mensaje={mensaje}
          open={openSnack}
          color={colormensaje}
          finalizar={setFinalizar}
          severity={severitySnack}
        />
      ) : null}
      {/* ALERT --> SE MUESTRA SI HAY UN ERROR EN LA API (NO SE CONECTA O ESTÁ MAL LA RUTA, ETC.) */}
      {error && mensajeAlerta ? (
        <Alerta mensaje={mensajeAlerta} severity={severityAlerta} />
      ) : null}
    </div>
  );
}

export default AddItem;
