//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio
// Creation Date : 11/09/2024
// Version : 1
// Description : traigo los grupos e items para el checklist
//------Changes -------------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useEffect, useState, useMemo } from "react";
import { UrlApi } from "../../services/apirest";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  Grid,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
} from "@mui/material";
import Agregar_tarea_check from "./Agregar_tarea_check";

function Checklist(props) {
  const [datosTanque, setDatosTanque] = useState(props.datos);
  const [checklist, setChecklist] = useState(props.checklist); //Estado q mostrará o no el form
  const [tabla, setTabla] = useState(true);
  const [tarea, setTarea] = useState(false);
  const [tipoSeleccionado, setTipoSeleccionado] = useState(); // Estado para el tipo seleccionado

  const [desplegableTipos, setDesplegableTipos] = useState();

  const obtenerTipos = () => {
    fetch(UrlApi + "/tanque_grupos/desplegable")
      .then((data) => data.json())
      .then((data) => setDesplegableTipos(data));
  };
  useEffect(() => {
    obtenerTipos();
  }, []);

 // console.log("TIPOS", desplegableTipos);

  const [datosPertenencia, setDatosPertenencia] = useState();

  const obtenerGrupoEitems = () => {
    if (!tipoSeleccionado) return; // Evita la llamada si no hay tipo seleccionado
    fetch(
      UrlApi +
        "/tanque_pertenencia/gruposEitemsXtanque/" +
        datosTanque.id_tanque +
        "/" +
        tipoSeleccionado
    )
      .then((data) => data.json())
      .then((data) => setDatosPertenencia(data));
  };

  const handleTipoChange = (event) => {
    setTipoSeleccionado(event.target.value); // Actualiza el tipo seleccionado
  };
  //ejecuta la funcion de obtener
  useEffect(() => {
    obtenerGrupoEitems();
  }, [tipoSeleccionado]);

  useEffect(() => {
    setDatosTanque({
      ...props.datos,
    });
  }, [props.datos]);

  const handleClose = () => {
    setChecklist(!checklist);
    if (props.onClose) {
      props.onClose();
    }
  };

  const gruposConItems = useMemo(() => {
    if (!datosPertenencia) return [];

    return datosPertenencia.reduce((acc, item) => {
      const grupoExistente = acc.find(
        (grupo) => grupo.id_grupo === item.id_grupo
      );

      if (grupoExistente) {
        // Si el grupo ya existe, añadimos el item a su lista de items
        grupoExistente.items.push(item);
      } else {
        // Si el grupo no existe, creamos un nuevo grupo con su primer item
        acc.push({
          id_grupo: item.id_grupo,
          nombre_grupo: item.nombre_grupo,
          items: [item],
        });
      }

      return acc;
    }, []);
  }, [datosPertenencia]);

  const mostrarTarea = (item) => {
    setTarea(true);
    setDatosTanque(item); // Ahora guarda la fila del ítem seleccionado
  };

  const handleCloseTarea = () => {
    setTarea(false);
  };

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  const size = "small";

  return (
    <div>
      <Dialog
        open={props.checklist}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            {/* Left Section: Close Icon and Typography */}
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2 }} variant="h6" component="div">
                Checklist para generar tareas:
              </Typography>
            </Box>

            {/* Center Section: FormControl */}
            <FormControl
              variant="filled"
              size={size}
              sx={{
                minWidth: 200,
                backgroundColor: "white",
              }}
            >
              <InputLabel id="lbl_estado" style={{ color: "#040404" }}>
                Tipo
              </InputLabel>
              <Select
                required
                name="tipo"
                label="Tipo"
                labelId="lbl_estado"
                value={tipoSeleccionado || ""}
                onChange={handleTipoChange}
                MenuProps={{
                  PaperProps: {
                    style: {
                      backgroundColor: "white",
                    },
                  },
                }}
                inputProps={{
                  style: { color: "#040404" },
                }}
              >
                {desplegableTipos
                  ? desplegableTipos.map((elemento) => (
                      <MenuItem key={elemento.id} value={elemento.id}>
                        {elemento.valor}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </FormControl>

            {/* Right Section: Close Button */}
            <Button color="inherit" onClick={handleClose}>
              Cerrar
            </Button>
          </Toolbar>
        </AppBar>

        <Grid container spacing={2}>
          {gruposConItems && gruposConItems.length > 0 ? (
            gruposConItems.map((grupo, index) => (
              <Grid item xs={12} key={index}>
                <Box sx={{ padding: 2, borderBottom: "1px solid #ccc" }}>
                  {/* Nombre del grupo como título */}
                  <Typography variant="h6" gutterBottom>
                    {grupo.nombre_grupo}
                  </Typography>
                  <Divider />
                  {/* Tabla para los items */}
                  <Table>
                    <TableHead>
                      <TableRow sx={{ backgroundColor: "#C0C0C0" }}>
                        <TableCell>
                          <strong>Nombre del Ítem</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Definición</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Acciones</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {grupo.items.map((item, itemIndex) => (
                        <React.Fragment key={itemIndex}>
                          <TableRow>
                            <TableCell>{item.nombre_item}</TableCell>
                            <TableCell>{item.definicion_item}</TableCell>
                            <TableCell>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => mostrarTarea(item)}
                              >
                                Agregar Tarea
                              </Button>
                            </TableCell>{" "}
                            {/* Celda con el botón */}
                          </TableRow>
                          {/* Divider horizontal entre items */}
                          {itemIndex < grupo.items.length - 1 && (
                            <TableRow>
                              <TableCell colSpan={2}>
                                <Divider />
                              </TableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Grid>
            ))
          ) : (
            <Typography variant="h6" color="textSecondary" mt={6} ml={10}>
              No hay datos de grupos e items disponibles.
            </Typography>
          )}
        </Grid>
      </Dialog>
      {tarea ? (
        <Agregar_tarea_check
          tarea={tarea}
          tabla={setTabla}
          datos={datosTanque}
          onClose={handleCloseTarea}
        ></Agregar_tarea_check>
      ) : null}
    </div>
  );
}

export default Checklist;
