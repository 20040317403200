import React, { useState, useEffect, useMemo } from 'react'
import { getData } from '../../../services/library';
import MaterialReactTable from "material-react-table";
import { Typography, AppBar, Toolbar, Button, Dialog, Box, IconButton, Slide, Grid, DialogContent } from '@mui/material'
import ListaMaterialesPanel from './ListaMaterialesPanel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import moment from 'moment';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormAgregarPedido(props) {
    const [reqmaterial, setreqMaterial] = useState(props.reqmaterial);
    const [materiales, setMateriales] = useState();
    const [openLista, setOpenLista] = useState(false);
    const [DatosReq, setDatosReq] = useState({});
    const globalTheme = useTheme();

    const obtenerReq = () => {
        getData('/requerimientos_materiales/reqpanelcompras/' + reqmaterial.idrequerimiento, setMateriales)
    };

    useEffect(() => {
        obtenerReq()
    }, []);

    const handleClose = () => {
        props.open(false)
        props.tabla(true)
    };

    const columns = useMemo(() =>
        [{ header: '#', accessorKey: 'idrequerimiento' },
        { header: 'Fecha ', accessorFn: (rq) => moment(rq.created_at).format("DD-MM-YYYY") },
        { header: 'Sucursal', accessorKey: 'nombre_sucursal' },
        { header: 'Estado', accessorKey: 'valor_estado' },
        { header: 'Solicita', accessorKey: 'usuario_registra' },
        { header: 'Movil', accessorKey: 'patente' }
        ], []);

    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: globalTheme.palette.mode,
                    primary: globalTheme.palette.primary, //primary color for the table
                    info: {
                        main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            globalTheme.palette.mode === "light"
                                ? "rgb(234, 239, 241)"
                                : "#000", //pure black table in dark mode for fun
                    },
                },
                typography: {
                    button: {
                        textTransform: "none", //customize typography styles for all buttons in table by default
                        fontSize: "1rem",
                    },
                },
                components: {
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                fontSize: "0.8rem", //larger tooltip font size                                
                            },
                        },
                    },
                },

            }), [globalTheme]);

    const tableHeadCellProps = {
        align: "left",
        sx: {
            fontSize: 16,
            color: "white",
            fontWeight: "normal",
            backgroundColor: "#2471A3",
            borderColor: "black",
        },
    };

    const initialState = {
        density: "compact",
        showGlobalFilter: true,
        pagination: { pageIndex: 0, pageSize: 10 }
    };

    const muiSearchTextFieldProps = {
        placeholder: "Buscar..",
        sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
        variant: "outlined",
        color: "success",
        size: "small",
    };

    const dialogListaMateriales = (datosReq) => {
        setOpenLista(true)
        setDatosReq(datosReq)
    };

    return (
        <>
            <Dialog
                open={props.open}
                onClose={handleClose}
                fullScreen
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <ArrowBackIcon color="inherit" />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" textAlign="center">
                            LISTADO DE REQUERIMIENTOS
                        </Typography>

                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Box
                        width="100%"
                        backgroundColor="#CCD1D1"
                        alignItems="center"
                        justifyContent="center">
                        {materiales &&
                            <ThemeProvider theme={tableTheme}>
                                <MaterialReactTable
                                    enableRowVirtualization
                                    enableColumnVirtualization
                                    enableColumnResizing
                                    columns={columns}
                                    data={materiales}
                                    initialState={initialState}
                                    enableExpandAll={false}
                                    enableToolbarInternalActions={true}
                                    muiTableHeadCellProps={tableHeadCellProps}
                                    muiTableBodyCellProps={{
                                        align: "left",
                                        sx: {
                                            backgroundColor: "#fff",
                                            color: "black",
                                            borderColor: "black"
                                        }
                                    }}
                                    enableColumnActions={false}
                                    enableDensityToggle={false}
                                    enableRowActions={false}
                                    layoutMode="grid"
                                    localization={MRT_Localization_ES}
                                    enableColumnDragging={false}
                                    enableColumnFilters={false}
                                    enableHiding={false}
                                    positionExpandColumn='first'
                                    muiSearchTextFieldProps={muiSearchTextFieldProps}
                                    enableStickyFooter={false}
                                    enablePagination={true}
                                    enableMultiRowSelection={false}
                                    muiToolbarAlertBannerProps={false}
                                    muiTableBodyRowProps={({ row }) => ({
                                        onClick: () => {
                                            row.getToggleSelectedHandler()
                                            dialogListaMateriales(row.original)
                                        },
                                        sx: {
                                            cursor: 'pointer'
                                        }
                                    })}
                                    renderTopToolbarCustomActions={() => (
                                        <Typography variant="poster" fontSize={20} textAlign='center'>
                                            Seleccione un requerimiento para ver la lista de materiales
                                        </Typography>
                                    )}
                                />
                            </ThemeProvider>
                        }
                        <Grid key={reqmaterial.idrequerimiento}>
                            {openLista ?
                                <ListaMaterialesPanel
                                    open={setOpenLista}
                                    datos={DatosReq}
                                    uuid={props.uuid}
                                    actualizar={props.actualizar}
                                    obtenerReq={obtenerReq} />
                                : null}
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

