import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

function FormTanques(props) {
  const almacenar = (e) => {
    props.setDataFact({
      ...props.dataFact,
      [e.target.name]: e.target.value || null,
    });
  };

  const readOnly = props.readOnly;

  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        px={1}
      >
        <Grid
          container
          direction="row"
          backgroundColor="#E5E8E8"
          borderRadius={5}
          my={1}
          px={0.5}
          border={0.5}
          borderColor={"#AEB6BF"}
        >
          <Grid
            item
            xs={3.2}
            style={{ borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }}
            textAlign="center"
          >
            <Typography variant="body2" color="initial">
              Capacidad
            </Typography>
          </Grid>
          <Grid
            item
            xs={8.8}
            style={{ borderTopRightRadius: 10, borderBottomRightRadius: 10 }}
            textAlign="center"
          >
            <Typography variant="body2" color="initial">
              Cantidad
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={3} px={2} mb={1.5}>
          <TextField
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#000",
                fontSize: "15px", // Aquí puedes ajustar el tamaño de la fuente según tus necesidades
              },
              "& .MuiFilledInput-root": {
                height: "40px", // Aquí puedes ajustar la altura según tus necesidades
              },
            }}
            fullWidth
            disabled
            focused={false}
            variant="filled"
            label=""
            value={"0.5 m3"}
            InputProps={{
              readOnly: true,
            }}
            size="small"
          />
        </Grid>
        <Grid item xs={9} px={2} mb={1.5}>
          <TextField
            // autoFocus={props.calculos.costototaltks===0}
            fullWidth
            variant="standard"
            label=""
            name="canttk_05"
            value={props.dataFact.canttk_05}
            onChange={almacenar}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            inputProps={{
              maxLength: 2,
              readOnly: readOnly,
            }}
            size="small"
          />
        </Grid>

        <Grid item xs={3} px={2} mb={1.5}>
          <TextField
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#000",
                fontSize: "15px", // Aquí puedes ajustar el tamaño de la fuente según tus necesidades
              },
              "& .MuiFilledInput-root": {
                height: "40px", // Aquí puedes ajustar la altura según tus necesidades
              },
            }}
            fullWidth
            disabled
            focused={false}
            variant="filled"
            label=""
            value={"1 m3"}
            InputProps={{
              readOnly: true,
            }}
            size="small"
          />
        </Grid>
        <Grid item xs={9} px={2} mb={1.5}>
          <TextField
            // autoFocus={props.calculos.costototaltks===0}
            fullWidth
            variant="standard"
            label=""
            name="canttk_1"
            value={props.dataFact.canttk_1}
            onChange={almacenar}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            inputProps={{
              maxLength: 2,
              readOnly: readOnly,
            }}
            size="small"
          />
        </Grid>

        <Grid item xs={3} px={2} mb={1.5}>
          <TextField
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#000",
                fontSize: "15px", // Aquí puedes ajustar el tamaño de la fuente según tus necesidades
              },
              "& .MuiFilledInput-root": {
                height: "40px", // Aquí puedes ajustar la altura según tus necesidades
              },
            }}
            fullWidth
            disabled
            focused={false}
            variant="filled"
            label=""
            value={"2 m3"}
            InputProps={{
              readOnly: true,
            }}
            size="small"
          />
        </Grid>
        <Grid item xs={9} px={2} mb={1.5}>
          <TextField
            fullWidth
            variant="standard"
            label=""
            name="canttk_2"
            value={props.dataFact.canttk_2}
            onChange={almacenar}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            inputProps={{
              maxLength: 2,
              readOnly: readOnly,
            }}
            size="small"
          />
        </Grid>
        <Grid item xs={3} px={2} mb={1.5}>
          <TextField
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#000",
                fontSize: "15px", // Aquí puedes ajustar el tamaño de la fuente según tus necesidades
              },
              "& .MuiFilledInput-root": {
                height: "40px", // Aquí puedes ajustar la altura según tus necesidades
              },
            }}
            fullWidth
            disabled
            focused={false}
            variant="filled"
            label=""
            value={"4 m3"}
            InputProps={{
              readOnly: true,
            }}
            size="small"
          />
        </Grid>
        <Grid item xs={9} px={2} mb={1.5}>
          <TextField
            fullWidth
            variant="standard"
            label=""
            name="canttk_4"
            value={props.dataFact.canttk_4}
            onChange={almacenar}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            inputProps={{
              maxLength: 2,
              readOnly: readOnly,
            }}
            size="small"
          />
        </Grid>
        <Grid item xs={3} px={2} mb={1.5}>
          <TextField
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#000",
                fontSize: "15px", // Aquí puedes ajustar el tamaño de la fuente según tus necesidades
              },
              "& .MuiFilledInput-root": {
                height: "40px", // Aquí puedes ajustar la altura según tus necesidades
              },
            }}
            fullWidth
            disabled
            focused={false}
            variant="filled"
            label=""
            value={"7 m3"}
            InputProps={{
              readOnly: true,
            }}
            size="small"
          />
        </Grid>

        <Grid item xs={9} px={2} mb={1.5}>
          <TextField
            fullWidth
            variant="standard"
            label=""
            name="canttk_7"
            value={props.dataFact.canttk_7}
            onChange={almacenar}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            inputProps={{
              maxLength: 2,
              readOnly: readOnly,
            }}
            size="small"
          />
        </Grid>
        <Grid item xs={3} px={2} mb={1.5}>
          <TextField
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#000",
                fontSize: "15px", // Aquí puedes ajustar el tamaño de la fuente según tus necesidades
              },
              "& .MuiFilledInput-root": {
                height: "40px", // Aquí puedes ajustar la altura según tus necesidades
              },
            }}
            fullWidth
            disabled
            focused={false}
            variant="filled"
            label=""
            value={"Otros (valor tanque en U$S)"}
            InputProps={{
              readOnly: true,
            }}
            size="small"
          />
        </Grid>
        <Grid item xs={9} px={2} mb={1.5}>
          <TextField
            fullWidth
            variant="standard"
            label=""
            name="canttk_otros"
            value={props.dataFact.canttk_otros}
            onChange={almacenar}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            inputProps={{
              maxLength: 10,
              readOnly: readOnly,
            }}
            size="small"
            onKeyDown={() => props.setActiveTab("costos")}
          />
        </Grid>
        <Grid item xs={3} px={2} mb={1.5}>
          <TextField
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#000",
                fontSize: "15px", // Aquí puedes ajustar el tamaño de la fuente según tus necesidades
              },
              "& .MuiFilledInput-root": {
                height: "40px", // Aquí puedes ajustar la altura según tus necesidades
              },
            }}
            fullWidth
            disabled
            focused={false}
            variant="filled"
            label=""
            value={"COSTO TOTAL: "}
            InputProps={{
              readOnly: true,
            }}
            size="small"
          />
        </Grid>

        <Grid item xs={9} px={2} mb={1.5}>
          <TextField
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#000",
              },
              "& .MuiFilledInput-root": {
                height: "40px", // Aquí puedes ajustar la altura según tus necesidades
              },
            }}
            disabled
            variant="filled"
            fullWidth
            label="COSTO TOTAL DE TANQUES"
            name="costototaltks"
            value={props.calculos.costototaltks}
            InputProps={{
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start">ARS</InputAdornment>
              ),
            }}
            size="small"
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default FormTanques;
