import React, { useState, useEffect } from "react";
import { getData, postData } from "../../../services/library";
import { UrlApi } from "../../../services/apirest";
import { TextField, Box, Container, Grid, Typography, Button, IconButton, FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import InputAdornment from "@mui/material/InputAdornment";
import NoteIcon from "@mui/icons-material/Note";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { grey } from "@mui/material/colors";
import Notify from '../../Notify';
import Alerta from '../../Alerta';

function Addsegmento(props) {
    const [datosFormulario, setDatosformulario] = useState({
        subsegmento: null,
        id_segmento: null,
        codigo_subsegmento: null,
        usuario: localStorage.getItem('user')
    });
    const [segmentos, setSegmentos] = useState(null)
    const [notify, setNotify] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
    });
    const [disBtn, setDisBtn] = useState(false)


    useEffect(() => {
        getData('/segmentos', setSegmentos)
    }, []);

    const almacenar = (e) => {
        setDatosformulario({ ...datosFormulario, [e.target.name]: e.target.value });
    };

    const handleClose = () => {
        props.alta(false);
        props.tabla(true);
        props.actualizar();
    };

    const guardarSubsegmento = async (e) => {
        setDisBtn(true);
        postData(e,
            '/subsegmentos',
            {
                subsegmento: datosFormulario.subsegmento,
                id_segmento: datosFormulario.id_segmento,
                codigo_subsegmento: datosFormulario.codigo_subsegmento,
                usuario: localStorage.getItem('user'),
                activo:1
            },
            setNotify
        )
    };

    const style = {
        backgroundColor: "#ffffff",
        width: "100%"
    };

    return (
        <div>
            <Container>
                <Box sx={{ flexGrow: 1 }} mt={2}>
                    <Grid container backgroundColor="#1F618D" p={1.5}>
                        <Grid item xs={2}>
                            <IconButton onClick={handleClose}>
                                <ArrowBackIcon sx={{ color: grey[50] }} />
                            </IconButton>
                        </Grid>
                        <Grid
                            item
                            xs={8}
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography variant="h5" color="#ffffff">
                                Cargar nuevo subsegmento
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <form onSubmit={guardarSubsegmento}>
                    <Box
                        backgroundColor="#F4F6F6"
                        mt={2}
                        p={3}
                        border={0.5}
                        borderColor={"#ABB2B9"}
                        borderRadius={4}
                    >
                        <Grid container spacing={1} direction={{ xs: 'column', sm: 'row' }}>
                            <Grid item xs={6}>
                                <FormControl fullWidth={true} style={style} required focused color="primary" margin="dense">
                                    <InputLabel id="lblid_segmento">Segmento</InputLabel>
                                    <Select
                                        name="id_segmento"
                                        labelId='id_segmento'
                                        onChange={almacenar}
                                        value={datosFormulario.id_segmento}
                                        label="Segmento">
                                        {segmentos ? segmentos.map(elemento => (
                                            <MenuItem key={elemento.id} value={elemento.id}>
                                                {elemento.segmento}
                                            </MenuItem>
                                        )) : null}
                                    </Select>
                                </FormControl>

                            </Grid>
                            <Grid item xs={6}>
                                <TextField placeholder="Subsegmento"
                                    required
                                    margin="dense"
                                    focused
                                    fullWidth={true}
                                    inputProps={{ maxLength: 45 }}
                                    name="subsegmento"
                                    label="Subsegmento"
                                    color='primary'
                                    style={style}
                                    value={datosFormulario.subsegmento}
                                    onChange={almacenar}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <ContentPasteIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    placeholder="Codigo de Subsegmento"
                                    focused
                                    fullWidth
                                    style={style}
                                    name="codigo_subsegmento"
                                    id="codigo"
                                    label="Codigo"
                                    color="primary"
                                    onChange={almacenar}
                                    value={datosFormulario.codigo_subsegmento}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <NoteIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid mt={2} container direction="row" justifyContent="space-between" alignItemns="center">
                                <Button
                                    disabled={disBtn}
                                    variant="contained"
                                    color="error"
                                    onClick={handleClose}
                                >
                                    Volver
                                </Button>
                                <Button
                                    disabled={disBtn}
                                    variant="contained"
                                    type="submit"
                                    color="success"
                                >
                                    Guardar Subsegmento
                                </Button>
                            </Grid>
                        </Grid>

                    </Box>
                    {notify && notify.open ? (
                        <Notify
                            mensaje={notify.mensaje}
                            open={notify.open}
                            color={notify.color}
                            handleClose={handleClose}
                            severity={notify.severity}
                        />
                    ) : null}

                </form>
            </Container>
        </div>
    );
}

export default Addsegmento;
