import React from 'react'
import { useEffect, useState } from 'react';
import { UrlApi } from '../../../services/apirest';
import { Container, Box, Grid, InputAdornment, Button, Stack, DialogTitle, IconButton, Typography, SnackbarContent, Menu, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import TodayIcon from '@mui/icons-material/Today';
import DescriptionIcon from '@mui/icons-material/Description';
import RoomIcon from '@mui/icons-material/Room';
import { grey } from '@mui/material/colors';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Autocomplete from '@mui/material/Autocomplete';
import Notify from '../../Notify';
import Alerta from '../../Alerta';

function Addrutas(props) {
    const [datosFormulario, setDatosFormulario] = useState({
        nombre: null,
        descripcion: null,
        dia_asignado: null,
        geocerca: null,
        id_vehiculo: null,
        usuario: localStorage.getItem("user"),
        activa: 1, // damos por hecho que la ruta esta activa
        id_sucursal: null
    });

    const [vehiculos, setVehiculos] = useState();
    const [sucursal, setSucursal] = useState();
    const [disBtn, setdisBtn] = useState(false);
    const [suc, setSuc] = useState();
    const [openSnack, setOpenSnack] = React.useState(false);
    const [colormensaje, setColorMensaje] = useState("");
    const [mensaje, setMensaje] = useState("");
    const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
    const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
    const [mensajeAlerta, setMensajeAlerta] = useState(""); //mensaje que contendrá el componente "alerta"
    const [finalizar, setFinalizar] = useState(); //finalizar === 1 lo manda como prop el componente "notify" e indica que la transaccion ha finalizado y debe ejecutarse la funcion para cerrar
    const [severityAlerta, setSeverityAlerta] = useState(""); //define severidad de la alerta (success error o warning)
    const [severitySnack, setSeveritySnack] = useState(""); //define severidad del snackbar (notificación)

    //CONSULTAS A LA API------------------------------------------
    const obtenerVehiculos = () => {
        fetch(UrlApi + '/vehiculos/tipo')
            .then(data => data.json())
            .then(data => setVehiculos(data))
    };

    const obtenerSucursales = () => {
        fetch(UrlApi + '/sucursales')
            .then(data => data.json())
            .then(data => setSucursal(data))
    };
    //FIN CONSULTAS--------------------------------------------------

    useEffect(() => {
        obtenerVehiculos()
        obtenerSucursales()
    }, []);;

    const almacenar = (e) => {
        setDatosFormulario({ ...datosFormulario, [e.target.name]: e.target.value })
    };

    const guardarRutas = async () => {
        setdisBtn(true)
        const response = await fetch(UrlApi + '/rutas', {
            method: 'POST', headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    nombre: datosFormulario.nombre,
                    descripcion: datosFormulario.descripcion,
                    dia_asignado: datosFormulario.dia_asignado,
                    geocerca: datosFormulario.geocerca,
                    id_vehiculo: datosFormulario.id_vehiculo,
                    usuario: localStorage.getItem("user"),
                    activa: 1, // damos por hecho que la ruta esta activa
                    id_sucursal: suc.id
                }
            )
        })
        if (response.ok === false) {
            console.log("ERROR EN API");
            const error = response && response.json();
            setError(error);
            setSeverityAlerta("error")
            setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
        } else {
            console.log("Conexión con api OK");
            try {
                //Parseo respuesta de la api a json()
                const json = await response.json();
                //si api me devuelve lo q le puse en message
                if (json.message) {
                    //Si ese mensaje es "OK"
                    if (json.message === "OK") {
                        console.log("Guardado Satisfactoriamente");
                        setNotificacion(true);
                        setOpenSnack(true);
                        setColorMensaje("#D4EFDF");
                        setMensaje("Guardado");
                        setSeveritySnack("success");
                    } else {
                        console.log("res en api incorrecto. Revisar que sea 'OK'")
                        setNotificacion(true);
                        setOpenSnack(true);
                        setColorMensaje("#D4EFDF");
                        setSeveritySnack("error");
                        setMensaje("Registro Guardado. REVISAR RESPUESTA DE API (E_S)");
                    }
                } else if (json.sqlMessage) {
                    json && setError(json);
                    console.log(json);
                    setMensajeAlerta("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
                } else {
                    setMensajeAlerta("ERROR DESCONOCIDO.");
                }
            } catch (error) {
                if (error.toString().includes("is not valid JSON")) {
                    console.log("Guardado. Advertencia: Actualizar respuesta de api. JSON invalido.");
                    setNotificacion(true);
                    setOpenSnack(true);
                    setColorMensaje("#D4EFDF");
                    setMensaje("Guardado");
                    setSeveritySnack("success");
                } else {
                    setMensajeAlerta("ERROR DESCONOCIDO.");
                }
            }
        }
        handleClose()
    };

    const handleClose = () => {
        props.tabla(true)
        props.alta(false)
    };

    const style = {
        backgroundColor: "#ffffff",
        width: "100%"
    };

    return (
        <div>
            <br />
            <Container>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container backgroundColor="#1F618D" p={1.5}>
                        <Grid item xs={2}>
                            <IconButton onClick={handleClose}>
                                <ArrowBackIcon sx={{ color: grey[50] }} />
                            </IconButton>
                        </Grid>

                        <Grid
                            item
                            xs={8}
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography variant="h5" color="#ffffff">
                                Cargar nueva ruta
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <form onSubmit={guardarRutas} autoComplete='off'>
                    <Box backgroundColor="#F4F6F6"
                        mt={2}
                        p={3}
                        border={0.5}
                        borderColor={"#ABB2B9"}
                        borderRadius={4}>
                        <Grid container spacing={1} direction={{ xs: 'column', sm: 'row' }}>
                            <Grid item xs={6}>
                                <TextField placeholder="Nombre"
                                    required
                                    style={style}
                                    margin="dense"
                                    focused
                                    fullWidth={true}
                                    inputProps={{ maxLength: 45 }}
                                    name="nombre"
                                    label="Nombre"
                                    color="primary"
                                    value={datosFormulario.nombre && datosFormulario.nombre.replace(/\b\w/g, (l) =>
                                        l.toUpperCase())}
                                    onChange={almacenar}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <DescriptionIcon />
                                            </InputAdornment>
                                        )
                                    }} />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField placeholder="Descripcion"
                                    required
                                    style={style}
                                    margin="dense"
                                    focused
                                    fullWidth={true}
                                    inputProps={{ maxLength: 300 }}
                                    name="descripcion"
                                    label="Descripción"
                                    color="primary"
                                    value={datosFormulario.descripcion}
                                    onChange={almacenar}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <DescriptionIcon />
                                            </InputAdornment>
                                        )
                                    }} />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField placeholder="Día Asignado "
                                    required
                                    margin="dense"
                                    style={style}
                                    focused
                                    fullWidth={true}
                                    name="dia_asignado"
                                    inputProps={{ maxLength: 45 }}
                                    label="Día Asignado"
                                    color="primary"
                                    value={datosFormulario.dia_asignado}
                                    onChange={almacenar}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <TodayIcon />
                                            </InputAdornment>
                                        )
                                    }} />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField placeholder="Geocerca"
                                    //required
                                    margin="dense"
                                    focused
                                    fullWidth={true}
                                    name="geocerca"
                                    label="Geocerca"
                                    inputProps={{ maxLength: 500 }}
                                    color="primary"
                                    style={style}
                                    value={datosFormulario.geocerca}
                                    onChange={almacenar}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <RoomIcon />
                                            </InputAdornment>
                                        )
                                    }} />
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl fullWidth={true} style={style} margin='dense' focused color="primary" required>
                                    <InputLabel id="lbl_vehiculo">Vehiculo</InputLabel>
                                    <Select
                                        name='id_vehiculo'
                                        value={datosFormulario.id_vehiculo}
                                        onChange={almacenar}
                                        label="Vehiculo ">
                                        {vehiculos ? vehiculos.map(elemento => (
                                            <MenuItem key={elemento.id} value={elemento.id}>
                                                {elemento.patente}
                                            </MenuItem>
                                        )) : null}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <Autocomplete
                                    focused
                                    id="country-select-demo"
                                    noOptionsText={"Sucursal INEXISTENTE"}
                                    options={sucursal}
                                    autoHighlight
                                    style={style}
                                    margin="dense"
                                    getOptionLabel={(sucursal) => sucursal.nombre}
                                    onChange={(event, value) => setSuc(value)}
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            key={option.id}
                                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                            {...props}
                                        >
                                            {option.nombre}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            focused
                                            margin="dense"
                                            placeholder="Tipee para buscar sucursal.."
                                            {...params}
                                            label="Sucursal "
                                            inputProps={{
                                                ...params.inputProps,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid container
                            justifyContent="space-between"
                            alignItems="flex-end"
                            mt={2}>
                            <Button variant="contained"
                                onClick={handleClose}
                                color="error">
                                Volver
                            </Button>
                            <Button
                                variant="contained"
                                type="submit"
                                color="success"
                                disabled={disBtn}>
                                Guardar Ruta
                            </Button>
                        </Grid>
                    </Box>
                </form>
                {notificacion ? (
                    <Notify
                        mensaje={mensaje}
                        open={openSnack}
                        color={colormensaje}
                        finalizar={setFinalizar}
                        severity={severitySnack}
                    />
                ) : null}
                {/* ALERT --> SE MUESTRA SI HAY UN ERROR EN LA API (NO SE CONECTA O ESTÁ MAL LA RUTA, ETC.) */}
                {error && mensajeAlerta ? (
                    <Alerta mensaje={mensajeAlerta} severity={severityAlerta} />
                ) : null}
            </Container>
        </div>
    )
}

export default Addrutas
