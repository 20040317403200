
// Developer     : Magalí Perea
// Creation Date : 27-2-2024
// Version       : 1
// Description   : Este componente contiene el mapa que se muestra en cada formulario correspondiente a las direcciones de entrega. Reemplaza el loadscript por otro fragmento de código porque LoadScript no funcionaba correctamente cuando se trataba de múltiples renders.

import React, { useRef, useState } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  StandaloneSearchBox,
  useJsApiLoader 
} from "@react-google-maps/api";
import { TextField, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";

const containerStyle = {
  height:"232px"
};
function MapaCliente(props) {
  const mapRef = useRef(null);
  const inputRef = useRef();

  const center = {
    lat: props.datosDir.latitud || -38.416097,
    lng: props.datosDir.longitud || -63.616672,
  };

  //------------------- FUNCIONES -------------------------//

  const handlecambiolugar = async (e) => {
    const [places] = inputRef.current.getPlaces();
    if (places) {
      props.actualizarCoordenadas(
        props.index,
        places.geometry.location.lat(),
        places.geometry.location.lng()
      );
      props.handleInputChange(
        "direccion_entrega",
        places.formatted_address,
        props.index
      );
    }
  };

  //Usé esto en lugar de loadScript porque con loadScript no me funcionaba para renders multiples. 
  //La explicacion la debo.
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey:'AIzaSyA-7YA3-3nUx6kbQnhJCONfAnc5SaJb6Y4', //esta puesto aca porque si lo tomo de .env me da un error la api
    libraries: ['places'],
  });

  return (
    <div>
      {isLoaded && (
        <>
          <StandaloneSearchBox
            onLoad={(ref) => (inputRef.current = ref)}
            onPlacesChanged={(e) => handlecambiolugar(e)}
          >
            <Grid container direction="row">
              <Grid item xs={12} mb={1}>
                <TextField
                  style={{backgroundColor:"#fff"}}
                  label="Buscar Dirección"
                  fullWidth
                  placeholder="Dirección"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <TravelExploreIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                  value={props.datosDir.direccion_entrega}
                  onChange={(e) => {
                    props.handleInputChange(
                      "direccion_entrega",
                      e,
                      props.index
                    );
                  }}
                />
              </Grid>
            </Grid>
          </StandaloneSearchBox>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={!props.datosDir.latitud ? 3 : 15}
            onLoad={(map) => (mapRef.current = map)}
          >
            <Marker 
              position={center} 
              draggable={true}
              onDragEnd={(p)=>{
                props.actualizarCoordenadas(
                  props.index,
                  p.latLng.lat(),
                  p.latLng.lng(),
                );
              }}
            />
          </GoogleMap>
        </>
      )}
    </div>
  );
}

export default MapaCliente;
