import React, { useEffect, useState } from 'react';
import { IconButton, FormControl, InputAdornment, Tooltip, Grid, Select, Box, Container, Typography, InputLabel, Snackbar, SnackbarContent, Stack, Menu, MenuItem, DialogTitle, Button, TextField } from '@mui/material';
import { AccountCircle, Phone, Mail } from '@mui/icons-material';
import Title from '../../Title';
import Notify from '../../Notify';
import { getData, postData, putData } from '../../../services/library';
import { logEventos } from '../../../services/services';

function Editarcomerciales(props) {
    const [datosFormulario, setDatosFormulario] = useState(props.datos);
    const [supervisor, setSupervisor] = useState(null);
    const [sucursal, setSucursal] = useState(null);
    const [disBtn, setDisBtn] = useState(false);
    const [usernames, setUsernames] = useState(null);
    const [notificacion, setNotificacion] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
    });

    useEffect(() => {
        getData('/supervisores', setSupervisor)
        getData('/sucursales', setSucursal)
        getData('/representantes/xusername', setUsernames)
    }, []);

    //FIN CONSULTAS---------------------------------------------

    const almacenar = (e) => {
        setDatosFormulario({ ...datosFormulario, [e.target.name]: e.target.value })
    };

    const handleClose = () => {
        return (
            props.tabla(true),
            props.editar(false)
        )
    };

    //ir al formulario de supervisores 
    const handleFormSupervisores = () => {
        window.location.hash = "/supervisores"
    };

    const guardarComerciales = async (e) => {
        setDisBtn(true)
        e.preventDefault();
        putData(e,
            '/representantes/' + datosFormulario.id,
            {
                nombre_apellido: datosFormulario.nombre_apellido,
                telefono: datosFormulario.telefono,
                mail: datosFormulario.mail,
                id_supervisor: datosFormulario.id_supervisor,
                id_sucursal: datosFormulario.id_sucursal,
                usuario: localStorage.getItem("user"),
                username: datosFormulario.username
            },
            setNotificacion,
            logEventos("Editar comercial", "comerciales", "Comerciales", localStorage.getItem('user'))
            )

            console.log("datos", datosFormulario)
    };

    console.log("datos", datosFormulario)

    const style = {
        backgroundColor: "#ffffff",
        width: "100%"
    };

    return (
        <div>
            <br />
            <Container>
                <Title titulo={"Editar Comercial"} handleClose={handleClose} />
                <form onSubmit={guardarComerciales} autoComplete='off'>
                    <Box
                        backgroundColor="#F4F6F6"
                        mt={2}
                        p={3}
                        border={0.5}
                        borderColor={"#ABB2B9"}
                        borderRadius={4}
                    >
                        <Grid container spacing={1} direction={{ xs: 'column', sm: 'row' }}>
                            <Grid item xs={6}>
                                <FormControl style={style} focused color="primary" required margin="dense">
                                    <InputLabel id="lblid_supervisor">Supervisor</InputLabel>
                                    <Select
                                        name="id_supervisor"
                                        onChange={almacenar}
                                        value={datosFormulario.id_supervisor}
                                        label="Supervisor">
                                        {supervisor ? supervisor.map(elemento => (
                                            <MenuItem key={elemento.id} value={elemento.id}>
                                                {elemento.nombre_apellido}
                                            </MenuItem>
                                        )) : null}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {/* <Grid item xs={4}>
                                <Tooltip title="Agregar Supervisor">
                                    <IconButton edge='end' color="primary" size="small" onClick={handleFormSupervisores}>
                                        <ControlPointIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid> */}
                            <Grid item xs={6}>
                                <FormControl style={style} required focused color="primary" margin="dense">
                                    <InputLabel id="lblid_sucursal">Sucursal</InputLabel>
                                    <Select
                                        margin="normal"
                                        name="id_sucursal"
                                        onChange={almacenar}
                                        value={datosFormulario.id_sucursal}
                                        label="Sucursal">
                                        {sucursal ? sucursal.map(elemento => (
                                            <MenuItem key={elemento.id} value={elemento.id}>
                                                {elemento.nombre}
                                            </MenuItem>
                                        )) : null}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField placeholder='Nombre y apellido'
                                    required
                                    focused
                                    style={style}
                                    name="nombre_apellido"
                                    label="Nombre y apellido"
                                    color="primary"
                                    inputProps={{ maxLength: 45 }}
                                    margin="dense"
                                    value={datosFormulario.nombre_apellido.replace(/\b\w/g, (l) =>
                                        l.toUpperCase()
                                    )}

                                    onChange={almacenar}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccountCircle />
                                            </InputAdornment>
                                        )
                                    }} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField placeholder='Teléfono'
                                    required
                                    focused
                                    style={style}
                                    type="number"
                                    name="telefono"
                                    label="Teléfono"
                                    color="primary"
                                    margin="dense"
                                    value={datosFormulario.telefono}
                                    onChange={almacenar}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Phone />
                                            </InputAdornment>
                                        )
                                    }} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField placeholder='E-mail'
                                    required
                                    focused
                                    style={style}
                                    type="email"
                                    name="mail"
                                    label="E-mail"
                                    color="primary"
                                    inputProps={{ maxLength: 45 }}
                                    margin="dense"
                                    value={datosFormulario.mail}
                                    onChange={almacenar}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Mail />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    style={style}
                                    focused
                                    color="primary"
                                    required
                                    margin="dense"
                                // size={size}
                                >
                                    <InputLabel id="lbl_username">Username</InputLabel>
                                    <Select
                                        margin="dense"
                                        name="username"
                                        label="Username"
                                        value={datosFormulario.username}
                                        onChange={almacenar}
                                    >
                                        {usernames
                                            ? usernames.map((elemento) => (
                                                <MenuItem
                                                    key={elemento.username}
                                                    value={elemento.username}
                                                >
                                                    {elemento.username}
                                                </MenuItem>
                                            ))
                                            : null}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid mt={2} container direction="row" justifyContent="space-between" alignItemns="center">
                                <Button variant="contained" onClick={handleClose} color="error">Volver</Button>
                                <Button disabled={disBtn} variant="contained" type="submit" color="success" >Guardar comercial</Button>
                            </Grid>
                        </Grid>
                    </Box>

                </form>
            </Container>
            {notificacion && notificacion.open ? (
                <Notify
                    mensaje={notificacion.mensaje}
                    open={notificacion.open}
                    color={notificacion.color}
                    handleClose={handleClose}
                    severity={notificacion.severity}
                />
            ) : null}
        </div>
    )
}

export default Editarcomerciales