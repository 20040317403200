import React, { useEffect, useState, useMemo } from "react";
import Navbar from "../Navbar";
import { getData } from "../../services/library";
import { Delete, Edit } from "@mui/icons-material";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import CircularProgress from "@mui/material/CircularProgress";
import InputMask from "react-input-mask";
import InputAdornment from "@mui/material/InputAdornment";
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Box,
  Button,
  Typography,
  Container,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";

function MontosMinimosTable() {
  const [montosMinimos, setMontosMinimos] = useState();

  useEffect(() => {
    getData("/montos_minimos_percepciones", setMontosMinimos);
  }, []);

  return (
    <div>
      <Navbar />
      {montosMinimos && (
        <>
          <Container
            maxWidth="xl"
            sx={{ p: 2, backgroundColor: "#ebebeb", height: "90vh" }}
          >
            <Grid container>
              <Grid
                container
                backgroundColor="#fff"
                p={1}
                py={1.7}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                borderRadius={1}
              >
                <Grid item xs={4.5}>
                  <Typography variant="button" color="primary">
                    Provinciaa
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="button" color="primary">
                    Monto Neto Minimo
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="button" color="primary">
                    Monto Percepción Mínimo
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="button" color="primary">
                    Acciones
                  </Typography>
                </Grid>
              </Grid>

              {montosMinimos && montosMinimos.length > 0 ? (
                <>
                  <Grid item xs={12}>
                    {montosMinimos &&
                      montosMinimos.map((field, index) => (
                        <>
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                            p={1}
                            backgroundColor="#fff"
                            mt={1}
                            borderRadius={1}
                          >
                            <Grid
                              item
                              xs={4.5}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <LocationOnIcon color="primary" />
                              <Typography variant="subtitle" color="initial">
                                {field.provincia}
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <TextField
                                variant="filled"
                                size="small"
                                fullWidth
                                // type="date"
                                name="monto_neto_minimo"
                                value={field.monto_neto_minimo}
                                // onChange={(e) => handleInputChange(index, e)}
                                // InputProps={inputProps}
                                // sx={inputsx}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <TextField
                                variant="filled"
                                size="small"
                                fullWidth
                                // type="date"
                                name="monto_neto_minimo"
                                value={field.monto_percepcion_minimo}
                                // onChange={(e) => handleInputChange(index, e)}
                                // InputProps={inputProps}
                                // sx={inputsx}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                pr: 3,
                              }}
                            >
                              <Tooltip title="Eliminar ">
                                <IconButton
                                //   onClick={() => deshacerAlmacenar(index)}
                                  color="error"
                                  sx={{ p: 0.5 }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                              {index === montosMinimos.length - 1 && (
                                <Tooltip title="Agregar ">
                                  <IconButton
                                    // onClick={() => almacenarImpuesto()}
                                    color="success"
                                    sx={{ p: 0.5 }}
                                  >
                                    <AddCircleOutlineIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                              </Grid>
                              

                          </Grid>
                        </>
                      ))}
                  </Grid>
                </>
              ) : (
                <></>
              )}
            </Grid>
          </Container>
        </>
      )}
    </div>
  );
}

export default MontosMinimosTable;
