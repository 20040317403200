//----- Component --------------------------------------------------------/

// Developer : Nicolas Pascucci

// Creation Date : 21/05/2024

// Version : 1

// Description : primer paso de la evaluacion del comercial donde se ingresan las ventas mensuales
//

//------Changes --------------------------------------------------------------------- -------------//

// Change Date :

// Change by :

// Change description:

//

// Change Date :

// Change by :

// Change description:

//

//========================================================================/
import React, { useState, useEffect } from "react";
import Navbar from "../../Navbar";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import AssignmentIcon from "@mui/icons-material/Assignment";
import {
  Button,
  Box,
  Grid,
  Typography,
  TextField,
  Stack,
} from "@mui/material";
import { UrlApi } from "../../../services/apirest";
import moment from "moment";
import { logEventos } from "../../../services/services";

function PrimerStep(props) {
  const [subitem, setSubitem] = useState(null)
  const [subitemDos, setSubitemDos] = useState(null)
  const [objetivos, setObjetivos] = useState(null)
  const [fecha, setFecha] = useState(props.fecha)
  const [porcentajeGanadoDistribuidores, setPorcentajeGanadoDistribuidores] = useState(null)
  const [porcentajeGanadoComercio, setPorcentajeGanadoComercio] = useState(null)
  const [porcentajeGanadoUsuarios, setPorcentajeGanadoUsuarios] = useState(null)
  const [datosVendido, setDatosVendido] = useState({
    vendido: props.vendido,
  })
  // console.log("vendido", datosVendido.vendido);
  const [vendidoxmes, setVendidoxmes] = useState("")

  const obtenerSubitems = () => {
    fetch(UrlApi + "/subitems_comerciales/xiditem/"+1)
      .then((data) => data.json())
      .then((data) => setSubitem(data));
  };

  useEffect(() => {
    obtenerSubitems()
  }, [])

  
  // const obtenerSubitemsDos = () => {
  //   fetch(UrlApi + "/subitems_comerciales/xiditem/"+2)
  //     .then((data) => data.json())
  //     .then((data) => setSubitemDos(data));
  // };

  // useEffect(() => {
  //   obtenerSubitemsDos()
  // }, [])
  // console.log("subitems", subitem);

  const [subitemResultado, setSubitemResultado] = useState(null); // Agrega estado para subitemResultado

  const handleSubitemResultado = (resultado) => {
    setSubitemResultado(resultado);
  };
  const calcularResultado = () => {
    const objetivoComercial = objetivos ? objetivos[0].objetivo : null;
    // const vendidoMes = parseFloat(datosVendido.distribuidores) + parseFloat(datosVendido.comercio) + parseFloat(datosVendido.usuarios)
    const vendidoMes = parseFloat(datosVendido.vendido);
    const porcentaje = ((vendidoMes / objetivoComercial) * 100).toFixed(2);
    setVendidoxmes(vendidoMes)
    // Función para determinar en qué subitem cae el porcentaje
    function determinarSubitem(porcentaje, subitems) {
      for (let i = 0; i < subitems.length; i++) {
        const subitem = subitems[i];
        if (subitem.hasta === null) {
          if (porcentaje >= subitem.desde) {
            return subitem;
          }
        } else {
          if (porcentaje >= subitem.desde && porcentaje <= subitem.hasta) {
            return subitem;
          }
        }
      }
      return null; // Si no cae en ningún subitem
    }
    if (subitem && porcentaje) {
      const subitemResultado = determinarSubitem(parseFloat(porcentaje), subitem);
      handleSubitemResultado(subitemResultado)
    }
    // props.handleNext(
    // datosVendido.distribuidores,
    // datosVendido.comercio,
    // datosVendido.usuarios,
    // subitemResultado?subitemResultado.porcentaje:null
    // );
    // const porcentajeDistribuidores = (parseFloat(datosVendido.distribuidores) / vendidoMes) * 100;
    // const porcentajeComercio = (parseFloat(datosVendido.comercio) / vendidoMes) * 100;
    // const porcentajeUsuarios = (parseFloat(datosVendido.usuarios) / vendidoMes) * 100;
    // const subitemDistribuidores = subitemDos.find(item => item.id_subitem === 5);
    // const subitemComercio = subitemDos.find(item => item.id_subitem === 6);
    // const subitemUsuarios = subitemDos.find(item => item.id_subitem === 7);

    // if (porcentajeDistribuidores < subitemDistribuidores.hasta && porcentajeDistribuidores > subitemDistribuidores.desde) {
    //   // console.log("Si le damos el adicional, su porcentaje es", porcentajeDistribuidores, "y el rango va de",subitemDistribuidores.desde, "hasta", subitemDistribuidores.hasta); 
    //   setPorcentajeGanadoDistribuidores(subitemDistribuidores.porcentaje)
    // }else{
    //   // console.log("No le damos el adicional, su porcentaje es", porcentajeDistribuidores, "y el rango va de",subitemDistribuidores.desde, "hasta", subitemDistribuidores.hasta); 
    //   setPorcentajeGanadoDistribuidores(0)
    // }
    // if (porcentajeComercio < subitemComercio.hasta && porcentajeComercio > subitemComercio.desde) {
    //   // console.log("Si le damos el adicional, su porcentaje es", porcentajeComercio, "y el rango va de",subitemComercio.desde, "hasta", subitemComercio.hasta); 
    //   setPorcentajeGanadoComercio(subitemComercio.porcentaje)
    // }else{
    //   // console.log("No le damos el adicional, su porcentaje es", porcentajeComercio, "y el rango va de",subitemComercio.desde, "hasta", subitemComercio.hasta); 
    //   setPorcentajeGanadoComercio(0)
    // }
    // if (porcentajeUsuarios < subitemUsuarios.hasta && porcentajeUsuarios> subitemUsuarios.desde) {
    //   // console.log("Si le damos el adicional, su porcentaje es", porcentajeUsuarios, "y el rango va de",subitemUsuarios.desde, "hasta", subitemUsuarios.hasta); 
    //   setPorcentajeGanadoUsuarios(subitemUsuarios.porcentaje)
    // }else{
    //   // console.log("No le damos el adicional, su porcentaje es", porcentajeUsuarios, "y el rango va de",subitemUsuarios.desde, "hasta", subitemUsuarios.hasta); 
    //   setPorcentajeGanadoUsuarios(0)
    // }

    // console.log("Porcentaje de distribuidores:", porcentajeDistribuidores.toFixed(2) + "%");
    // console.log("Porcentaje de comercio:", porcentajeComercio.toFixed(2) + "%");
    // console.log("Porcentaje de usuarios:", porcentajeUsuarios.toFixed(2) + "%");
    
    // console.log("SUBITEM", porcentaje ,subitemResultado ? subitemResultado : null);
  };
  useEffect(() => {
    if (subitemResultado !== null) {
      props.handleNext(
        datosVendido.vendido,
        datosVendido.distribuidores,
        datosVendido.comercio,
        datosVendido.usuarios,
        subitemResultado ? subitemResultado.porcentaje : null,
        porcentajeGanadoDistribuidores,
        porcentajeGanadoComercio,
        porcentajeGanadoUsuarios,
        objetivos?objetivos[0].comercial: null,
      );
    }
  }, [subitemResultado]);



  const obtenerObjetivo = () => {
    fetch(UrlApi + "/objetivos_comerciales/mesyuserysuc/"+localStorage.getItem("user")+"/"+fecha+"/"+props.sucursalSeleccionada.id)
      .then((data) => data.json())
      .then((data) => setObjetivos(data));
  };

  useEffect(() => {
    obtenerObjetivo()
  }, [])

  // console.log("objetivos", objetivos);
  const almacenar = (e) => {
    setDatosVendido({
      ...datosVendido,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setVendidoxmes((datosVendido.distribuidores?parseFloat(datosVendido.distribuidores):parseFloat(0))+(datosVendido.comercio?parseFloat(datosVendido.comercio):parseFloat(0))+(datosVendido.usuarios?parseFloat(datosVendido.usuarios):parseFloat(0)))
  }, [datosVendido])
  
  const [deshabilitado, setDeshabilitado] = useState(false)
  useEffect(() => {
    if (datosVendido.distribuidores === null || datosVendido.comercio === null || datosVendido.usuarios === null ) {
      setDeshabilitado(true)
    } else{
      setDeshabilitado(false)
    }
  }, [datosVendido])

  useEffect(() => {
    logEventos("Primer paso de evaluacion", "Evaluacion Comerciales", "Se ingresan las ventas mensuales", localStorage.getItem("user"))
}, [])

  return (
    <div> {props.activeStep === 0 && (
        <div style={{ marginTop: 20, alignItems: "center", display: "flex", flexDirection: "column" }}>
          <Grid container spacing={2}>
            {props.configuracionVista && props.configuracionVista[0].objetivo === 1? 
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                {objetivos && objetivos[0] ? `${objetivos[0].nombre_apellido}, su objetivo mensual en ${props.sucursalSeleccionada.nombre} es: ${objetivos[0].objetivo} kgs.` : null}
              </Typography>
            </Grid>
              :null}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
              {`Ingrese los kgs. Vendidos Totales del Mes de ${moment(fecha).format("MMMM")} (Butano + Propano Sin Dexs) :`}
              </Typography>
            </Grid>
            <Grid item xs={4} >
              <TextField
                label="Kgs. Totales Vendidos (kg)"
                name="vendido"
                size="small"
                placeholder="..."
                onKeyPress={(event) => {
                  if (!/[0-9.]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                inputProps={{
                  maxLength: 10,
                }}
                focused
                required
                fullWidth
                value={datosVendido.vendido}
                onChange={almacenar}
              />
            </Grid>
            {/* <Grid item xs={4} >
              <TextField
                label="Distribuidores (kg)"
                name="distribuidores"
                size="small"
                placeholder="..."
                onKeyPress={(event) => {
                  if (!/[0-9.]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                inputProps={{
                  maxLength: 10,
                }}
                focused
                required
                fullWidth
                value={datosVendido.distribuidores}
                onChange={almacenar}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Comercios (kg)"
                name="comercio"
                size="small"
                placeholder="..."
                onKeyPress={(event) => {
                  if (!/[0-9.]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                inputProps={{
                  maxLength: 10,
                }}
                focused
                required
                fullWidth
                value={datosVendido.comercio}
                onChange={almacenar}
              />
            </Grid>
            <Grid item xs={4} >
              <TextField
                label="Usuarios (kg)"
                name="usuarios"
                size="small"
                placeholder="..."
                onKeyPress={(event) => {
                  if (!/[0-9.]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                inputProps={{
                  maxLength: 10,
                }}
                focused
                required
                fullWidth
                value={datosVendido.usuarios}
                onChange={almacenar}
              />
            </Grid>
            <Grid item xs={12} justifyContent={"center"} textAlign={"center"}>
              <Typography variant="h5" gutterBottom>
                Total: {vendidoxmes} kgs.
              </Typography>
            </Grid> */}


            <Grid item xs={10}>
            {/* <Button
              variant="contained"
              color="error"
              // disabled={activeStep === 0}
              onClick={props.handleBack}
              sx={{ mr: 1 }}
            >
              Volver
            </Button> */}
            </Grid>  
          
              <Grid item xs={2}>
                <Button onClick={calcularResultado} fullWidth variant="contained" disabled={deshabilitado}>SIGUIENTE</Button>
              </Grid>
          </Grid>
        </div>
      )}
    </div>
  )
}

export default PrimerStep;
