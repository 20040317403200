import React, { useEffect, useState, useMemo } from "react";
import { UrlApi } from "../../../services/apirest";
import { Container, ListItemIcon, MenuItem } from "@mui/material";
import WhereToVoteTwoToneIcon from "@mui/icons-material/WhereToVoteTwoTone";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Delete, Edit } from "@mui/icons-material";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import {
  createTheme,
  ThemeProvider,
  useTheme,
  IconButton,
  Tooltip,
} from "@mui/material";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import Divider from "@mui/material/Divider";
import ReceiptIcon from '@mui/icons-material/Receipt';
// //Componentes PROPIOS.
import EditarPedido5 from "./EditarPedido5";
import DetallePedido from "./DetallePedido";
import Title from "../../Title";
import FormBajaPedido2 from "./FormBajaPedido2";
import DialogEntregado from "./DialogEntregado";
import FormPending from "./FormPending";
// import ConfirmDialog from "../../../components/ConfirmDialog";
import ModalInfoErrorFacturacion from "./ModalInfoErrorFacturacion";
import ConfirmDialog from "../../Dialogs/ConfirmDialog"

import { getData, putData } from "../../../services/library";
import { verEnviarPDF } from "../../../services/services";

function TablaPedidos(props) {
  const [pedidos, setPedidos] = useState(null); // Acá me trigo los pedidos que existen
  const sucAuth = localStorage.getItem("suc_auth");
  const icon = <ReceiptIcon sx={{fontSize:50}}/>

  const handleCloseEdicion = () => {
    setEditar(false);
    setTabla(true);
    props.setShowTabs(true);
  };

  const [tabla, setTabla] = useState(true);
  const [editar, setEditar] = useState(false);
  const [baja, setBaja] = useState();
  const [formEntregado, setFormEntregado] = useState(false);
  const [pedido, setPedido] = useState({});
  const [showFormPending, setShowFormPending] = useState(false);
  // const [showDialogMarcarFact, setShowDialogMarcarFact] = useState();
  const [showDialogFacturar, setShowDialogFacturar] = useState();
  const [showErrorFacturacion, setShowErrorFacturacion] = useState(false);

  useEffect(() => {
    getGlobalOrders();
  }, [tabla]);

  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);

  const [initialState, setInitialState] = useState({
    density: "compact",
    showGlobalFilter: true,
    showColumnFilters: false,
    pagination: {
      pageSize: 10,
      pageIndex: 0,
    },
  });

  // console.log(props.activeTab)

  const getGlobalOrders = () => {
    switch (props.activeTab) {
      case 0:
        getData("/pedidos/all/" + sucAuth, setPedidos);
        break;

      case 1:
        getData("/pedidos/estado/19/" + sucAuth, setPedidos);
        break;

      case 2:
        getData("/pedidos/estado/21/" + sucAuth, setPedidos);
        break;

      case 3:
        getData("/pedidos/estado/23/" + sucAuth, setPedidos);
        break;

      case 4:
        const estados = [40, 243, 244, 263, 248]; // Estados que quieres consultar // 243 error de facturacion
        const estadosStr = estados.join(",");
        getData("/pedidos/estado/" + estadosStr + "/" + sucAuth, setPedidos);
        break;

      case 5:
        getData(
          "/pedidos/estado/facturados2/" + sucAuth + "/" + date,
          setPedidos
        ); // aca tengo que poner los facturados
        break;

      default:
        console.log("Error");
        break;
    }
  };

  useEffect(() => {
    getGlobalOrders();
  }, [props.activeTab]);

  /* PARA MARCAR EL PEDIDO COMO FACTURADO USANDO UN DIALOG GENÉRICO: */
  //NOTIFICACION
  //defino acá la notificacion porque necesito pasarla como parámetro a la función de guardado que está en library
  const [notificacion, setNotificacion] = useState({
    open: false,
    mensaje: "",
    severity: "",
    color: "",
  });

  //facturar el pedido
  async function facturar(){
    try{
      const respuesta = await fetch (UrlApi+"/pedidos/facturar/" + pedido.id_pedido)
      const json= await respuesta.json();
      alert(json.message)
    } catch (error){
      alert("Error de conexión." , error)
    }
    setShowDialogFacturar(false)
    getGlobalOrders();
  }

  const columns = useMemo(
    () => [
      {
        header: "Datos Pedido ",
        columns: [
          {
            accessorKey: "id_pedido", //access nested data with dot notation
            header: "#",
            enableColumnActions: false,
            enableColumnFilter: false,
            size: 10,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                backgroundColor: rowBg[row.original.id_estado] ?? "#fff",
                color: "black",
                maxWidth: 10,
              },
            }),
          },
          {
            accessorKey: "cliente",
            header: "Cliente",
            enableColumnActions: false,
            showColumnFilters: false,
            enableColumnFilter: false,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                backgroundColor: rowBg[row.original.id_estado] ?? "#fff",
                color: "black",
                maxWidth: "250px",
              },
            }),
            Cell: ({ row }) => (
              <Tooltip title={row.original.cliente}>
                <span>
                  {row.original.sisven_id ? `${row.original.sisven_id} -` : ""}{" "}
                  {row.original.cliente}
                </span>
              </Tooltip>
            ),
          },
          {
            accessorKey: "localidad_direccionentrega", //access nested data with dot notation
            header: "Localidad Domicilio",
            enableColumnActions: false,
            enableColumnFilter: false,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                backgroundColor: rowBg[row.original.id_estado] ?? "#fff",
                color: "black",
                width: "100px",
                maxWidth: "100px",
              },
            }),
            Cell: ({ row }) => (
              <Tooltip title={row.original.localidad_direccionentrega}>
                <span>{row.original.localidad_direccionentrega}</span>
              </Tooltip>
            ),
          },
          {
            accessorKey: "fecha_requerida",
            header: "Fecha Req",
            enableColumnActions: false,
            enableColumnFilter: false,
            size: 10,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                backgroundColor: rowBg[row.original.id_estado] ?? "#fff",
                color: "black",
              },
            }),
          },
          {
            accessorKey: "sucursal_atiende",
            header: "Sucursal Atiende",
            enableColumnActions: false,
            maxSize: 5,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                backgroundColor: rowBg[row.original.id_estado] ?? "#fff",
                color: "black",
              },
            }),
          },
          {
            accessorKey: "empresa",
            header: "Empresa",
            enableColumnActions: false,
            enableColumnFilter: false,
            size: 10,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                backgroundColor: rowBg[row.original.id_estado] ?? "#fff",
                color: "black",
              },
            }),
          },
          {
            accessorKey: "valor",
            header: "Estado",
            enableColumnActions: false,
            enableColumnFilter: false,
            size: 20,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                backgroundColor: rowBg[row.original.id_estado] ?? "#fff",
                color: "black",
                width: "20px",
                maxWidth: "20px",
              },
            }),
            Cell: ({ row }) => (
              <Tooltip title={row.original.valor} arrow>
                <span>{row.original.valor}</span>
              </Tooltip>
            ),
          },
        ],
      },
      {
        header: "Entregado",
        muiTableHeadCellProps: {
          sx: {
            borderLeft: 1,
            borderRight: 1,
            borderColor: "#fff",
            backgroundColor: "#2471A3",
            color: "white", // Color del texto
            fontSize: 16,
            fontWeight: "normal",
            pt: 1,
          },
        },
        columns: [
          {
            accessorKey: "kgentregados",
            header: "Kgs",
            enableColumnActions: false,
            enableColumnFilter: false,
            size: 5,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                backgroundColor: rowBg[row.original.id_estado] ?? "#fff",
                color: "black",
                width: "20px",
                maxWidth: "20px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
            }),
            muiTableHeadCellProps: {
              sx: {
                fontSize: 16,
                fontWeight: "normal",
                pt: 1,
                backgroundColor: "#2471A3",
                borderLeft:1,
                borderRight:1,
                borderColor:"#fff",
                color: "white", // Puedes ajustar el color del texto también
              },
            },
          },
          {
            accessorKey: "ltsentregados",
            header: "Lts",
            enableColumnActions: false,
            enableColumnFilter: false,
            size: 5,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                backgroundColor: rowBg[row.original.id_estado] ?? "#fff",
                color: "black",
                width: "20px",
                maxWidth: "20px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
            }),
            muiTableHeadCellProps: {
              sx: {
                fontSize: 16,
                fontWeight: "normal",
                pt: 1,
                borderRight:1,
                backgroundColor: "#2471A3",
                color: "white", // Puedes ajustar el color del texto también
              },
            },
          },
        ],
      },
    ],
    []
  );

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "primary",
    size: "small",
  };

  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      pt: 1,
    },
  };

  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000",
          },
        },
        typography: {
          button: {
            textTransform: "none",
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //larger tooltip font size
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const rowBg = {
    19: "#F5CBA7", //Lo voy a poner en amarillo
    21: "#ABEBC6", //aprobado -> verde clarito
    22: "#EC7063", //rechazado -> rojo
    23: "#85C1E9", //asignado -> celestito
    24: "#138D75", //entregado
    25: "#D7BDE2", //Facturado -> violeta
    40: "#D6DBDF",
    41: "#EC7063", //CANCELADO -> BORDÓ
    243: "#f29696", //ERROR DE FACTURACION
    244: "#f8c471", //ERROR DE FACTURACION
  };

  //Hago esto POR SI LAS MOSCAS, haya +1 comprobante con el mismo id de pedido. En ese caso,muestro una alerta.
  // pero antes de mandar a ver la factura, me voy a asegurar de que haya solo un comprobante con ese id_pedido
  async function buscarFactura(reg) {
    const idpedido = reg.id_pedido;
    try {
      const response = await fetch(`${UrlApi}/comprobantes/pedido/${idpedido}`);
      if (!response.ok) {
        throw new Error("Error en la solicitud: " + response.statusText);
      }
      const factura = await response.json();
      if (factura.length === 1) {
        verEnviarPDF(factura[0].id_factura, 0);
      } else {
        alert(
          "Existe mas de un comprobante para este pedido. Contacte a sistemas."
        );
      }
    } catch (error) {
      console.error("Error al obtener la factura:", error);
      alert(error);
    }
  }
  const customLocalization = {
    ...MRT_Localization_ES,
    expandAll: false,
    expand: <ExpandMoreIcon />,
  };

  return (
    <div>
      {tabla && pedidos && columns ? (
        <ThemeProvider theme={tableTheme}>
          <MaterialReactTable
            enableFacetedValues
            enablePinning={false}
            enableDensityToggle={false}
            enableExpandAll={false}
            columns={columns}
            data={pedidos}
            enableRowActions
            initialState={initialState}
            positionActionsColumn="last"
            muiTableHeadCellProps={tableHeadCellProps}
            displayColumnDefOptions={{
              "mrt-row-expand": {
                size: 5,
              },
            }}
            localization={customLocalization}
            muiTableBodyCellProps={({ row }) => ({
              sx: {
                backgroundColor: rowBg[row.original.id_estado] ?? "#fff",
                color: "black",
              },
            })}
            renderDetailPanel={({ row }) => (
              <DetallePedido datos={row.original} />
            )}
            muiSearchTextFieldProps={muiSearchTextFieldProps}
            renderRowActionMenuItems={({ closeMenu, row }) => [
              <MenuItem
                style={{
                  display:
                    row.original.id_estado === 19 &&
                    row.original.id_estado === 21
                      ? null
                      : "none",
                }}
                key={0}
                onClick={() => {
                  props.setShowTabs(false);
                  closeMenu();
                  setTabla(false);
                  setPedido(row.original);
                  setEditar(true);
                }}
                sx={{ m: 0 }}
              >
                <ListItemIcon>
                  <BorderColorIcon color="primary" />
                </ListItemIcon>
                Editar
              </MenuItem>,

              <MenuItem
                style={{
                  display:
                    row.original.id_estado === 19 ||
                    row.original.id_estado === 21 ||
                    row.original.id_estado === 23
                      ? null
                      : "none",
                }}
                key={1}
                onClick={() => {
                  closeMenu();
                  setPedido(row.original);
                  setBaja(true);
                }}
                sx={{ m: 0 }}
              >
                <ListItemIcon>
                  <Delete color="error" />
                </ListItemIcon>
                Cancelar Pedido
              </MenuItem>,
              <MenuItem
                style={{
                  display: row.original.id_estado === 23 ? null : "none",
                }} //display "none" es para que no se vea
                key={2}
                onClick={() => {
                  closeMenu();
                  setPedido(row.original);
                  setFormEntregado(true);
                }}
                sx={{ m: 0 }}
              >
                <ListItemIcon>
                  <WhereToVoteTwoToneIcon color="success" />
                </ListItemIcon>
                Registrar Visita
              </MenuItem>,

              <MenuItem
                style={{
                  display:
                    row.original.id_estado === 25 ||
                    row.original.id_estado === 263
                      ? null
                      : "none",
                }} //display "none" es para que no se vea
                key={2}
                onClick={async () => {
                  closeMenu();
                  buscarFactura(row.original);
                }}
                sx={{ m: 0 }}
              >
                <ListItemIcon>
                  <ReceiptLongIcon color="primary" />
                </ListItemIcon>
                Ver Factura
              </MenuItem>,
              <MenuItem
              style={{
                display:!(
                  row.original.id_estado === 41 ||
                  row.original.id_estado === 32
                ) ? "none" : null
              }} //display "none" es para que no se vea
                key={3}
                onClick={() => {
                  closeMenu();
                  setPedido(row.original);
                  setShowFormPending(true);
                }}
                sx={{ m: 0 }}
              >
                <ListItemIcon>
                  <PendingActionsIcon color="warning" />
                </ListItemIcon>
                Marcar Aprobado
              </MenuItem>,

              <MenuItem
              
                key={4}
                onClick={() => {
                  closeMenu();
                  setPedido(row.original);
                  setShowDialogFacturar(true)
                }}
                sx={{ m: 0 }}
                style={{
                  display:(row.original.id_estado===40 ||  row.original.id_estado===243 || row.original.id_estado===244) ? null : "none" 
                }} 
              >
                <ListItemIcon>
                  <AssignmentTurnedInIcon
                    color="warning"
                    sx={{ color: "#BB8FCE" }}
                  />
                </ListItemIcon>
                Facturar
              </MenuItem>,

              <MenuItem
                style={{
                  display:
                    row.original.id_estado === 243 ||
                    row.original.id_estado === 244
                      ? null
                      : "none",
                }} //display "none" es para que no se vea
                key={5}
                onClick={() => {
                  closeMenu();
                  setPedido(row.original);
                  setShowErrorFacturacion(true);
                  // setShowDialogMarcarFact(true);
                }}
                sx={{ m: 0 }}
              >
                <ListItemIcon>
                  <QueryStatsIcon color="error" />
                </ListItemIcon>
                Ver Error
              </MenuItem>,
            ]}
          />
        </ThemeProvider>
      ) : null}
      <br />
      {editar ? (
        // <>
        <Container maxWidth="lg">
          <Title
            titulo={"Editar Pedido para " + pedido.cliente}
            handleClose={handleCloseEdicion}
          />
          <EditarPedido5
            datos={pedido}
            editar={setEditar}
            tabla={setTabla}
            setShowTabs={props.setShowTabs}
          />
        </Container>
      ) : // </>
      null}
      {baja ? (
        <FormBajaPedido2
          datos={pedido}
          open={baja}
          setOpen={setBaja}
          actualizar={getGlobalOrders}
        />
      ) : null}
      {formEntregado ? (
        <>
          <DialogEntregado
            pedido={pedido}
            open={formEntregado}
            setOpen={setFormEntregado}
            actualizar={getGlobalOrders}
          />
        </>
      ) : null}
      {showFormPending ? (
        <FormPending
          pedido={pedido}
          open={showFormPending}
          setOpen={setShowFormPending}
          actualizar={getGlobalOrders}
        />
      ) : null}
      {showDialogFacturar ? (
        <ConfirmDialog
          open={showDialogFacturar}
          setOpen={setShowDialogFacturar}
          title={"Facturar Pedido"}
          subtitle={"Confirme que desea enviar a facturar este pedido."}
          notificacion={notificacion} //Estos parámetros que seteo en la función en "library", los tengo que pasar porque determinan si se mostrará o no la notificación, que está ubicada en "notify"
          setNotificacion={setNotificacion}
          icon={icon}
          color={"#16a085"}
          guardar={facturar}
          btnText={"facturar"}
        />
      ) : null}
      {showErrorFacturacion && (
        <ModalInfoErrorFacturacion
          open={showErrorFacturacion}
          setOpen={setShowErrorFacturacion}
          datos={pedido}
        />
      )}
    </div>
  );
}

export default TablaPedidos;
