import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { UrlApi } from '../../../services/apirest';
import Navbar from '../../Navbar';
import { Container } from '@mui/system';
import { Typography } from '@mui/material';
import '../old/stilos.css'


function Factibilidades2() {

    let navigate = useNavigate();
    let roles = localStorage.getItem("role")
    const [facts, setFacts] = useState(null)

    useEffect(() => {
        obtenerDatos()
    }, [])

    // consulta de todos las factibilidades                           //
    // si es admin les trae todas sino filtra por el usuario logueado //
    const obtenerDatos = () => {
        fetch(UrlApi + "/fact/" + '')
            .then((data) => data.json())
            .then((data) => setFacts(data))
    }


    const columnas = useMemo(
        () => [
            {
                accessorKey: 'idfactibilidad',
                header: 'NroFact',
                size: 0,
            },
            {
                accessorKey: 'usuario_registra',
                header: 'RRCC',
                size: 0,
            },
            {
                accessorKey: 'nombrecliente', //normal accessorKey
                header: 'Cliente',
                size: 0,
            },
            {
                accessorKey: 'provincia',
                header: 'Provincia',
                size: 0,
            },
            {
                accessorKey: 'localidad',
                header: 'Localidad',
            },
            {
                accessorKey: 'aprobada',
                header: 'Estado',
            },
        ],
        [],
    );

    return (
        <div>
            <Navbar />
            <Container sx={{ marginTop: '60px' }} maxWidth={false}>
                <MaterialReactTable
                    data={facts ?? []} //fallback to array if data is undefined
                    state={{ showProgressBars: facts ? false : true }} //or showSkeletons
                    columns={columnas}
                    localization={MRT_Localization_ES}
                    enableDensityToggle={false}
                    enableColumnFilters={false}
                    enableTableFooter={false}
                    enableHiding={false}
                    enableColumnActions={false}
                    enableStickyHeader
                    muiTableContainerProps={{ sx: { maxHeight: '500px' } }}
                    enablePagination={false}
                    enableBottomToolbar={false}
                    globalFilterFn="contains"

                    renderTopToolbarCustomActions={() => (
                        <>
                            <Typography variant="h5" sx={{ display: 'flex', justifyContent: "end" }}>Factibilidades sistema anterior</Typography>
                        </>

                    )}

                    initialState={{
                        density: 'compact'
                    }}
                    memoMode="cells"
                    muiTableBodyCellProps={({ row }) => ({
                        sx: {
                            background:
                                row.original.aprobada === 'Aprobada' ? "lightgreen" : "#D98880",
                            color:
                                row.original.aprobada === 'Aprobada' ? "navy" : "black",
                            borderColor: 'blue'

                        },
                    })}

                    renderDetailPanel={({ row }) => (
                        <div>
                            <table class="tg">
                                <thead>
                                    <tr>
                                        <th class="tg-4ngr">Direccion</th>
                                        <th class="tg-7612">{row.original.domicilio}</th>
                                        <th class="tg-4ngr">Localidad</th>
                                        <th class="tg-7612">{row.original.localidad}</th>
                                        <th class="tg-4ngr">Provincia</th>
                                        <th class="tg-7612">{row.original.provincia}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="tg-4ngr">Rubro</td>
                                        <td class="tg-7612">{row.original.rubro}</td>
                                        <td class="tg-4ngr">Actividad</td>
                                        <td class="tg-7612">{row.original.actividad}</td>
                                        <td class="tg-4ngr"></td>
                                        <td class="tg-7612"></td>
                                    </tr>
                                    <tr>
                                        <td class="tg-4ngr">Estimado anual</td>
                                        <td class="tg-7612">{row.original.volestimadocompraanual}</td>
                                        <td class="tg-4ngr">Precio por Kgr</td>
                                        <td class="tg-7612">{row.original.precioporkg}</td>
                                        <td class="tg-4ngr">Cierre Estimado (años)</td>
                                        <td class="tg-7612">{row.original.cierrevolumenestimado}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="tg">
                                <thead>
                                    <tr>
                                        <td class="tg-cly1" rowspan="2">Tanques</td>
                                        <td class="tg-4ngr">0.5m3</td>
                                        <td class="tg-4ngr">2m3</td>
                                        <td class="tg-4ngr">4m3</td>
                                        <td class="tg-4ngr">7m3</td>
                                        <td class="tg-4ngr">Otros</td>
                                    </tr>
                                    <tr>
                                        <td class="tg-7612">{row.original.cantidadtk05m}</td>
                                        <td class="tg-7612">{row.original.canttk2m}</td>
                                        <td class="tg-7612">{row.original.canttk4m}</td>
                                        <td class="tg-7612">{row.original.canttk7m}</td>
                                        <td class="tg-7612">{row.original.canttkotros}</td>
                                    </tr>
                                </thead>
                            </table>
                            <table class="tg">
                                <thead>
                                    <tr>
                                        <td class="tg-cly1" rowspan="3">Costos</td>
                                        <td class="tg-4ngr" colspan="3">Empresa</td>
                                        <td class="tg-4ngr" colspan="3">Cliente</td>
                                    </tr>
                                    <tr>
                                        <td class="tg-0lax">{row.original.motivo1}</td>
                                        <td class="tg-0lax">{row.original.motivo2}</td>
                                        <td class="tg-0lax">{row.original.motivo3}</td>
                                        <td class="tg-0lax">{row.original.motivoc1}</td>
                                        <td class="tg-0lax">{row.original.motivoc2}</td>
                                        <td class="tg-0lax">{row.original.motivoc3}</td>
                                    </tr>
                                    <tr>
                                        <td class="tg-7612">{row.original.cantidadmotivo1}</td>
                                        <td class="tg-7612">{row.original.cantidadmotivo2}</td>
                                        <td class="tg-7612">{row.original.cantidadmotivo3}</td>
                                        <td class="tg-7612">{row.original.cantidadmotivoc1}</td>
                                        <td class="tg-7612">{row.original.cantidadmotivoc2}</td>
                                        <td class="tg-7612">{row.original.cantidadmotivoc3}</td>
                                    </tr>
                                </thead>
                            </table>

                        </div>
                    )}
                />
            </Container>



        </div>
    )
}

export default Factibilidades2