//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio
// Creation Date : 1/10/2024
// Version : 1
// Description : tabla de estados de reclamos
//------Changes ----------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useEffect, useState } from "react";
import { UrlApi } from "../../../../services/apirest";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/system";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";


function Tabla_estados_mobile() {
  const [cantidadXmes, setCantidadXmes] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    dayjs().subtract(1, "month").startOf("month")
  );

  const obtenerCantidadXmes = () => {
    const formattedDate = selectedDate.format("YYYY"); // Formatear la fecha seleccionada
    fetch(UrlApi + "/reclamos/cantidadPorMes/fecha/" + formattedDate)
      .then((data) => data.json())
      .then((data) => setCantidadXmes(data));
  };

  useEffect(() => {
    obtenerCantidadXmes();
  }, [selectedDate]); // Actualizar los datos cuando se cambie la fecha

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  const todosLosMeses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  // Función para obtener el nombre del mes a partir de la fecha en formato YYYY-MM
  const obtenerNombreMes = (fecha) => {
    const mes = dayjs(fecha, "YYYY-MM").format("M") - 1; // Extraer el mes (0-11)
    return todosLosMeses[mes];
  };

  const transformarDatosParaBarChart = (data) => {
    const estados = Array.from(new Set(data.map((item) => item.valor_estado)));

    const acumulador = todosLosMeses.reduce((acc, mes) => {
      acc[mes] = estados.reduce((estadoAcc, estado) => {
        estadoAcc[estado] = 0; // Inicializar con 0
        return estadoAcc;
      }, {});
      return acc;
    }, {});

    data.forEach((item) => {
      const nombreMes = obtenerNombreMes(item.fecha); // Cambiado de item.mes a item.fecha
      if (acumulador[nombreMes]) {
        acumulador[nombreMes][item.valor_estado] = item.cantidad;
      }
    });

    const dataset = todosLosMeses.map((mes) => ({
      mes,
      ...acumulador[mes],
    }));

    return { dataset, estados, meses: todosLosMeses };
  };

  const { dataset, estados, meses } =
    transformarDatosParaBarChart(cantidadXmes);

  const paperStyle3 = {
    padding: "10px",
    margin: "10px",
    width: "100%", // Usar el ancho completo disponible
    maxWidth: "800px", // Limitar el ancho máximo a 430px
  };

  const HeaderCell = styled(TableCell)({
    fontSize: 13,
    color: "white",
    fontWeight: "normal",
    backgroundColor: "#2471A3",
    borderColor: "white",
    padding: "6.3px",
  });

  return (
    <div style={{ width: "100%", marginTop: 300 }}>
      {" "}
      {/* Ajuste de ancho completo */}
      <Paper elevation={10} style={{ ...paperStyle3 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
            <DatePicker
              views={["year"]}
              label="Año"
              value={selectedDate}
              onChange={handleDateChange}
              slotProps={{
                textField: {
                  size: "small",
                },
              }}
            />
          </LocalizationProvider>
          <Typography
            align="center"
            style={{
              fontWeight: "bold",
              fontSize: 20,
              flexGrow: 1,
              textAlign: "center",
            }}
          >
            Estado de los reclamos {selectedDate.year()}
          </Typography>
        </div>
        <TableContainer>
          <Table
            sx={{ minWidth: 300, marginTop: 2 }}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <HeaderCell align="center" sx={{ border: "1px solid black" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <b>Meses</b>
                    <hr
                      style={{
                        width: "100%",
                        border: "none",
                        borderTop: "1px solid black",
                      }}
                    />
                    <b>Estados</b>
                  </div>
                </HeaderCell>
                {meses.map((mes, index) => (
                  <TableCell
                    key={index}
                    align="center"
                    style={{
                      backgroundColor: "#2B76A6",
                      color: "white",
                      border: "1px solid black",
                    }}
                  >
                    {mes}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {estados.map((estado, index) => (
                <TableRow key={index} sx={{ border: "1px solid black" }}>
                  <TableCell align="center" sx={{ border: "1px solid black" }}>
                    {estado}
                  </TableCell>
                  {meses.map((mes, mesIndex) => (
                    <TableCell
                      key={mesIndex}
                      align="center"
                      sx={{ border: "1px solid black" }}
                    >
                      {dataset.find(
                        (item) => item.mes === mes && item[estado] !== undefined
                      )?.[estado] || 0}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default Tabla_estados_mobile;
