import React, { useEffect, useState, useMemo } from 'react';
import { UrlApi } from '../../../services/apirest';
import Navbar from '../../Navbar';
import { Container, Typography, Box, Button, Tooltip, IconButton, Grid, TableRow, Dialog, DialogTitle, DialogContent, TableContainer, Paper, Table, TableHead, TableBody, TextField, DialogActions } from '@mui/material';
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Modalobservaciones from './Modalobservaciones';
import AssignmentIcon from '@mui/icons-material/Assignment';
import moment from 'moment';

export default function Gestiones(props) {
    const [pedidos, setPedidos] = useState(); //trae todos los pedidos  
    const [tabla, settabla] = useState(true);
    const [openModal, setopenModal] = useState(false);
    const globalTheme = useTheme();
    const [datosformulario, setdatosformulario] = useState({})

    const obtenerPedidos = (estado) => {
        fetch(UrlApi + '/pedidos/xestado/' + estado)
            .then(data => data.json())
            .then(data => setPedidos(data))
    };

    const getPedidos = () => {
        switch (props.activeTab) {
            case 0:
                obtenerPedidos(19);
                break;

            case 1:
                obtenerPedidos(21);
                break;

            // case 2:
            //     obtenerPedidos(22);
            //     break;

            default:
                console.error("Error")
                break;
        }
    };

    console.log("pedidos.", pedidos);
    

    useEffect(() => {
        getPedidos();
    }, [props.activeTab, tabla, openModal]);

    const handleClickOpen = async (datos) => {
        setopenModal(true);
        setdatosformulario(datos);
    };

    //TABLA PEDIDOS--------------------------------------------------------------------------
    const columns = useMemo(() =>
        [{ header: '#', accessorKey: 'id', size: 45  },
        { header: 'Cliente', accessorKey: 'cliente' },
        { header: 'ID sisven', accessorKey: 'sisven_id', size: 100 },
        { header: 'Fecha', accessorFn: (p) => moment(p.registrado).format("DD-MM-YYYY") ?? " ", size: 120 },
        // { header: 'Requerido', accessorFn: (p) => p.registrado ? moment(p.registrado).format("DD-MM-YYYY") : "No hay registro", size: 150 },
         { header: 'Condicion pago',accessorKey:'condicion', size: 150 },
        { header: 'Empresa', accessorKey: 'empresa', size: 100 },
        { header: 'Obs', accessorKey: 'cantidad_observaciones', size: 50 }
        ], []);

    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: globalTheme.palette.mode,
                    primary: globalTheme.palette.primary, //primary color for the table
                    info: {
                        main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            globalTheme.palette.mode === "light"
                                ? "rgb(234, 239, 241)"
                                : "#000", //pure black table in dark mode for fun
                    },
                },
                typography: {
                    button: {
                        textTransform: "none", //customize typography styles for all buttons in table by default
                        fontSize: "1rem",
                    },
                },
                components: {
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                fontSize: "0.8rem", //larger tooltip font size                                
                            },
                        },
                    },
                },

            }), [globalTheme]);

    const muiSearchTextFieldProps = {
        placeholder: "Buscar..",
        sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
        variant: "outlined",
        color: "success",
        size: "small",
    };

    const initialState = {
        density: "compact",
        showGlobalFilter: true,
        pagination: { pageIndex: 0, pageSize: 8 }
    };

    const tableHeadCellProps = {
        align: "left",
        sx: {
            fontSize: 16,
            color: "white",
            fontWeight: "normal",
            // paddingTop: 1,
            // paddingBottom: 1,
            backgroundColor: "#2471A3",
            borderColor: "black",
        },
    };

    const rowBg = {
        19: "#FFFFFF",
        21: "#ABEBC6",
    };

    return (
        <div>
            {pedidos && tabla ? (
                <ThemeProvider theme={tableTheme}>
                    <MaterialReactTable
                        enableExpandAll={false}
                        enableToolbarInternalActions={true}
                        enableColumnResizing
                        layoutMode="grid"
                        enableFacetedValues
                        muiTableHeadCellProps={tableHeadCellProps}
                        muiTableBodyCellProps={({ row }) => ({
                            align: "left",
                            sx: {
                                backgroundColor: rowBg[row.original.id_estado] ?? "#fff",
                                color: "black",
                                borderColor: "black",
                            },
                        })}
                        enableColumnActions={false}
                        enableColumnFilters={false} //desactiva filtros x  columna
                        enableHiding={false}
                        enableDensityToggle={false} //
                        localization={MRT_Localization_ES}
                        enableColumnDragging={false} //No permite que se arrastren las columnas.
                        initialState={initialState}
                        muiSearchTextFieldProps={muiSearchTextFieldProps}
                        positionExpandColumn='first'
                        columns={columns}
                        data={pedidos}
                        enableEditing
                        positionActionsColumn="last"
                        muiTableBodyProps={{
                            align: "center",
                        }}
                        renderTopToolbarCustomActions={() => (
                            <>
                                <Typography variant="poster" fontSize={26}>
                                    <AssignmentIcon sx={{ size: 20 }} ></AssignmentIcon>
                                    Pedidos
                                </Typography>
                            </>
                        )}
                        renderRowActions={({ row }) => (
                            <div>
                                <Tooltip title="Agregar observaciones">
                                    <IconButton
                                        onClick={() => handleClickOpen(row.original)}
                                        color="primary"
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        )}
                    />
                </ThemeProvider>
            ) : null}
            {openModal ?
                <Modalobservaciones open={setopenModal} datos={datosformulario} actualizar={getPedidos}></Modalobservaciones>
                : null}
        </div>
    )
}