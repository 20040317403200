import React, { useState, useEffect, useMemo, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UrlApi } from "../../../services/apirest";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import {
  Button,
  Container,
  Box,
  Grid,
  Typography,
  IconButton,
  ListItemIcon,
  Tooltip,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

// ICONOS
import HistoryIcon from "@mui/icons-material/History";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import CommitIcon from "@mui/icons-material/Commit";
//Dialog de almacen
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";

// boton por enviar / recibido
import ButtonGroup from "@mui/material/ButtonGroup";
import { lightBlue, blueGrey } from "@mui/material/colors";

//adjuntado
import AttachFileIcon from "@mui/icons-material/AttachFile"; //ADJUNTAR ARCHIVOS

//ALERTA
import Badge from "@mui/material/Badge";
// import { cuentamensajes } from "../../services/services";
import { v4 as uuidv4 } from "uuid"; //ADJUNTAR IMAGEN

//CSV
import { ExportToCsv } from "export-to-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import moment from "moment";
import { getData } from "../../../services/library";
import AsignarAVehiculo from "./AsignarAVehiculo";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Automotores_stock(props) {
  let navigate = useNavigate();
  const location = useLocation();
  const [vehiculos, setVehiculos] = useState();
  const [tabla, setTabla] = useState(true);
  const [asignar, setAsignar] = useState(false);
  // console.log(location.state.id);

  const obtenervehiculos = () => {
    if (location.state.id === 1001) {
      getData("/vehiculos/propiosactivos", setVehiculos);
    } else {
      getData(
        "/vehiculos/vehiculosauth/" +
          localStorage.getItem("suc_auth").split(","),
        setVehiculos
      );
    }
  };

  useEffect(() => {
    obtenervehiculos();
  }, [tabla]);

  // console.log("vehiculos", vehiculos);

  const columns = useMemo(
    () => [
      { header: "Patente", accessorKey: "patente", size: 95 },
      { header: "Marca", accessorKey: "nombre_marca", size: 95 },
      { header: "Modelo", accessorKey: "nombre_modelo", size: 100 },
      { header: "Chofer", accessorKey: "chofer", size: 100 },
      {
        header: "Vto VTV",
        accessorKey: "vencimientovtv",
        type: "date",
        format: "DD-MM-YYYY",
        size: 100,
      },
      {
        header: "Vto Hidraulica",
        accessorKey: "vencimientohidraulica",
        type: "date",
        format: "DD-MM-YYYY",
        size: 100,
      },
      { header: "Sucursal", accessorKey: "nombre_sucursal", size: 100 },
      { header: "Dueño", accessorKey: "dueniovehiculo", size: 100 },
      { header: "Tipo", accessorKey: "tipovehiculos", size: 100 },
    ],
    []
  );

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "primary",
    size: "small",
  };
  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };
  //*************************************************************************************************************************************//
  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem",
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const initialState = {
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
  };

  return (
    <>
      {tabla ? (
        <AppBar
          sx={{ position: "relative" }}
          style={{ backgroundColor: "#0979b0" }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => navigate("/sucursales_cargas")}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Automotores
            </Typography>
          </Toolbar>
        </AppBar>
      ) : null}
      <Container maxWidth="xl" mt={1}>
        {vehiculos && tabla ? (
          <Box mt={2} mb={4}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
                enableGrouping // para permitir agrupar x estado x ej
                layoutMode="grid"
                muiTableHeadCellProps={tableHeadCellProps}
                muiTableBodyCellProps={({ row }) => ({
                  align: "left",
                  //   sx: {
                  //     backgroundColor:
                  //       row.getValue("estado_stock") === 0
                  //         ? ""
                  //         : row.getValue("estado_stock") === 1
                  //         ? "rgb(255,105,97)"
                  //         : "rgb(253,253,150)",
                  //     color: "black",
                  //     borderColor: "black",
                  //   },
                })}
                enableColumnFilters={false} //desactiva filtros x  columna
                enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
                enableDensityToggle={false} //
                localization={MRT_Localization_ES} //idioma
                enableColumnDragging={false} //para arrastrar columnas
                initialState={initialState}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                columns={columns}
                data={vehiculos} //con lo q la completas (esto cambia siempre)
                enableStickyFooter
                enableRowActions
                // enableEditing  //para que se pueda editar desde tabla
                positionActionsColumn="last" // posicion de la columna de acciones al final
                // ACCIONES
                renderToolbarInternalActions={(
                  { table } // boton para nuevo , al lado del buscar
                ) => (
                  <>
                    <MRT_FullScreenToggleButton table={table} />
                  </>
                )}
                renderTopToolbarCustomActions={(row, table) => (
                  <>
                    <Typography variant="poster" fontSize={26}>
                      Inventario En Vehiculos
                    </Typography>
                    {/* <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddBoxIcon color="asd" />}
                      onClick={() => {
                        handleClickAsignar()
                        // setTabla(false)
                      }}
                      size="small"
                    >
                      ASIGNAR A VEHICULO
                    </Button> */}
                    {/* <Button
                    //   onClick={() =>
                    //     navigate("/historial_fichas/" + location.state.id, {
                    //       state: location.state,
                    //     })
                    //   }
                      style={{ backgroundColor: blueGrey[700], color: "white" }}
                      variant="contained"
                      size="small"
                      endIcon={<HistoryIcon color="asd"/>}
                    >
                      HISTORIAL
                    </Button> */}
                  </>
                )}
                renderRowActions={({ row, table }) => (
                  //las acciones del costado editar y ver
                  <>
                    <div>
                      <Tooltip arrow placement="left" title="Inventario">
                        <IconButton
                          onClick={() => {
                            navigate("/inventario_vehiculo", {
                              state: row.original,
                            });
                          }}
                          color="primary"
                        >
                          <HomeRepairServiceIcon />
                        </IconButton>
                      </Tooltip>
                      {/* {row.original.id_tipo === 10? 
                        <Tooltip arrow placement="right" title="Inventario Conjunto">
                          <IconButton
                            onClick={() => {
                             navigate("/inventario_conjunto", { state: row.original });
                            }}
                            color="secondary"
                          >
                            <CommitIcon />
                          </IconButton>
                        </Tooltip>
                        :null} */}
                      {/* <Tooltip arrow placement="left" title="Historial">
                          <IconButton
                        //   onClick={() => handleClickOpenAdjuntar(row.original)}
                          color="primary"
                          >
                            <HistoryIcon />
                          </IconButton>
                        </Tooltip> */}
                    </div>
                  </>
                )}
              />
            </ThemeProvider>
          </Box>
        ) : null}
      </Container>
    </>
  );
}

export default Automotores_stock;
