import React, { useRef, useState, useEffect } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
// import { UrlApi } from "../../src/services/apirest";
import InputAdornment from "@mui/material/InputAdornment";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";

const containerStyle = {
  width: "480px",
  height: "280px",
};

function UbicarDomMapaAlta(props) {

    const center = {
        lat: props.datosDir.latitud * 1,
        lng: props.datosDir.longitud * 1,
      };

      const inputRef = useRef();

      const handlecambiolugar = () => {
        const [places] = inputRef.current.getPlaces();
        if (places) {
          props.setDatosDir({...props.datosDir,
            latitud: places.geometry.location.lat(),
            longitud: places.geometry.location.lng(),
          });
        }
      };
  return (
    <div>
        <Box>
        <LoadScript
          googleMapsApiKey={process.env.REACT_APP_Google_maps_key}
          libraries={["places"]}
        >
          <StandaloneSearchBox
            onLoad={(ref) => (inputRef.current = ref)}
            onPlacesChanged={handlecambiolugar}
          >
            <Box mb={1}>
            <TextField
                  focused
                  label="Buscar Dirección.."
                  fullWidth
                  placeholder="Dirección"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <TravelExploreIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
            </Box>
          </StandaloneSearchBox>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={15}
          >
            <Marker
              position={center}
              draggable={true}
              onDragEnd={(p) => {
                props.setDatosDir({...props.datosDir,
                  latitud: p.latLng.lat(),
                  longitud: p.latLng.lng(),
                });
                // getAddressFromCoordinates(p.latLng.lat(), p.latLng.lng())
              }}
            />
          </GoogleMap>
          {/* <Grid container direction="row" justifyContent="space-between" mt={2}>
            <Button
              variant="outlined"
              color="error"
              onClick={props.handleClose}
            >
              Cerrar
            </Button>
            <Button
              color="success"
              variant="contained"
            //   onClick={(e) => updateAdress(e)}
            >
              Guardar Cambios
            </Button>
          </Grid> */}
        </LoadScript>
      </Box>

    </div>
  )
}

export default UbicarDomMapaAlta