import * as React from "react";
import { useState } from "react";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { Box, DialogTitle, Button } from "@mui/material";

function AlertaRepetido(props) {
  const [open, setOpen] = useState(props.open);

  const cerrarAlerta = () => {
    setOpen(false);
    props.modalRepetido(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={cerrarAlerta}>
      <Box
          style={{
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: "#e32712",
          }}
        >
        <DialogTitle textAlign={"center"}>
          {"Ya fue cargado ese número de envase"}
        </DialogTitle>
        <DialogActions style={{ justifyContent: "center" }}>
          <Stack direction="row">
            <Button
              variant="contained"
              onClick={cerrarAlerta}
              style={{
                backgroundColor: "#d32f2f",
                color: "#ffffff",
                alignSelf: "center"
              }}
            >
              Continuar
            </Button>
          </Stack>
        </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}

export default AlertaRepetido;