import React, { useEffect, useState } from "react";
import Notify from "../../../Notify";
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, Typography } from "@mui/material";
import CommitIcon from "@mui/icons-material/Commit";
import { getData, postData } from "../../../../services/library";

function VincularVehiculos(props) {
    const [notificacion, setNotificacion] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
      });
      const [disbtn, setDisbtn] = useState(false)
      const [encontrados, setEncontrados] = useState()
      const [vehiculos, setVehiculos] = useState()
      const [vehiculoSeleccionado, setVehiculoSeleccionado] = useState({})
      useEffect(() => {
        if (props.datos.uso === 65) {
          // console.log("Mostrar Jaulas");
          getData("/vehiculos/seguntipo/"+268, setVehiculos)
        } else if ( props.datos.uso === 66 || props.datos.uso === 67) {
          // console.log("Mostrar tanques");
          getData("/vehiculos/seguntipo/"+64, setVehiculos)
        }
      }, [])
console.log("vincular prop",props.datos);

      const guardarConjunto = (e) => {
        setDisbtn(true);
        postData(e,
          '/vehiculos_conjuntos',
          {
           vehiculo_principal:props.datos.idvehiculo,
           vehiculo_acoplado: vehiculoSeleccionado.id,
           usuario:localStorage.getItem("user"),
          },
          setNotificacion)
      }
      
      console.log("vincular",encontrados);

      useEffect(() => {
        if (
          Object.keys(vehiculoSeleccionado).length === 0 ||
          encontrados[0]
        ) {
          // console.log("DESHABILITADO");
          setDisbtn(true);
        } else {
          //console.log("HABILITADO")
          setDisbtn(false);
        }
      }, [vehiculoSeleccionado,encontrados]);

      useEffect(() => {
        getData("/vehiculos_conjuntos/buscaacoplados/"+vehiculoSeleccionado.id, setEncontrados)
      }, [vehiculoSeleccionado])
      
  return (
    <>
      <Dialog
        fullWidth
        open={props.open}
        onClose={() => props.onClose(false)}
        maxWidth="sm"
      >
        {notificacion && notificacion.open ? (
          <Notify
            mensaje={notificacion.mensaje}
            open={notificacion.open}
            color={notificacion.color}
            handleClose={() => {
              props.onClose(false);
              // props.actualizar();
            }}
            severity={notificacion.severity}
          />
        ) : null}
        <Box justifyContent="center" backgroundColor="#1F618D">
          <DialogTitle>
            <Typography fontSize={20} align="center" color="#ffffff">
              Vincular Vehiculo al Camion {props.datos.patente}
            </Typography>
          </DialogTitle>
        </Box>
        <form onSubmit={guardarConjunto} autoComplete="off">
          <DialogContent>
            <Grid container spacing={1}>
            <Grid item xs={5}>
             <TextField
               size="small"
               placeholder="Patente"
               required
               focused
               name="Camion"
               color="primary"
               label="Camion"
               value={props.datos.patente}
             />
            </Grid>
            <Grid
              item
              xs={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
                <CommitIcon color="primary" fontSize="medium"/>
            </Grid>
            <Grid item xs={5}>
            {vehiculos && (
                <Autocomplete
                  disabled={!vehiculos}
                  size={"small"}
                  focused
                  disableClearable
                  id="acoplado"
                  noOptionsText={"Vehiculo INEXISTENTE"}
                  options={vehiculos}
                  autoHighlight
                  getOptionLabel={(vehiculo) =>
                    // chofer.id + "-" + 
                    vehiculo.patente
                  }
                  onChange={(event, value) => { setVehiculoSeleccionado(value) }}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      key={option.id}
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {/* {option.id}- */}
                      {option.patente}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                    //   style={style}
                      required
                      focused
                      size="small"
                      placeholder="Tipee para buscar..."
                      {...params}
                      label="Acoplado"
                      inputProps={{
                        ...params.inputProps,
                      }}
                      helperText={encontrados && encontrados[0]?`Este vehiculo está acoplado al camion ${encontrados[0].patente_principal}`:""} 
                      error={encontrados && encontrados[0]?true:false} 
                    />
                  )}
                />
            )}
            </Grid>
            </Grid>

          </DialogContent>
          <DialogActions>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button
                variant="contained"
                onClick={()=> props.onClose(false)}
                style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
              >
                Volver
              </Button>
              <Button
                // style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                color="success"
                type="submit"
                variant="contained"
                // color="primary"
                disabled={disbtn}
              >
                Confirmar
              </Button>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default VincularVehiculos