import React, { useState } from "react";
import { Grid, Dialog, Box, Typography, IconButton, Button,DialogActions, DialogContentText } from "@mui/material";
import Notify from "../../../Notify";
import { grey } from "@mui/material/colors";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { putData } from "../../../../services/library";
import { logEventos } from "../../../../services/services";

export default function Modalquitarpedido(props) {
    const [notificacion, setNotificacion] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
    });

    const handleClose = () => {
        props.open(false);
        props.actualizar()
    };

    const handleGuardar =(e) => {
        putData(e,
            '/pedidos/delivery/' + props.datos.id,{
                id_estado: 21,
                nro_reparto: null,
                usuario: localStorage.getItem('user')
            },
            setNotificacion,
            logEventos("Quitar pedido de reparto", "Pedidos", "Repartos", localStorage.getItem("user"))
        )
    };
    
    return (
        <Dialog open={props.open} fullWidth>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container backgroundColor="#1F618D" p={0.5}>
                    <Grid item xs={2}>
                        <IconButton onClick={handleClose}>
                            <ArrowBackIcon sx={{ color: grey[50] }} />
                        </IconButton>
                    </Grid>
                    <Grid
                        item
                        xs={8}
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Typography variant="h6" color="#ffffff">
                            Desea retirar el pedido del reparto
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <br />
            <DialogContentText textAlign={"center"}>
                El pedido se registrará como pendiente
            </DialogContentText>
            <DialogActions>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItemns="center"
                >
                    <Button size="medium" variant='contained' color="error" onClick={handleClose}>Volver</Button>

                    <Button size="medium" variant='contained' color="success" onClick={handleGuardar}>Guardar Cambios</Button>

                </Grid>
            </DialogActions>
            {notificacion && notificacion.open ? (
                <Notify
                    mensaje={notificacion.mensaje}
                    open={notificacion.open}
                    color={notificacion.color}
                    handleClose={handleClose}
                    severity={notificacion.severity}
                />
            ) : null}
        </Dialog>

    )

}