import React, { useEffect, useState, useMemo } from 'react';
import ReactSpeedometer from "react-d3-speedometer";
import { Divider, TextField, Grid, Dialog, Box, Alert, Select, Checkbox, Stack, InputLabel, Typography, Button, MenuItem, FormControl, AlertTitle, FormControlLabel, ListItemIcon, DialogContent } from '@mui/material';
import moment from 'moment' // con esto manejamos las fechas bien
import { v4 as uuidv4 } from 'uuid';   ///me da un unico id para poner en los pedidos y repartos
import { UrlApi } from '../../../../services/apirest';
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import ModalVencimientos from './ModalVencimientos';
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import Notify from '../../../Notify';
import { grey } from "@mui/material/colors";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Preview } from '@mui/icons-material';
import { getData, postData, putData } from '../../../../services/library';
import { logEventos } from '../../../../services/services';
import DialogConTextField from '../../../Dialogs/DialogConTextField';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import ModalEditar from './ModalEditar';

export default function CrearRepartos2(props) {
    const [fechaseleccionada, setFechaSeleccionada] = useState("");
    const [textotitulo, setTextoTitulo] = useState("");
    const [repartoexiste, setRepartoExiste] = useState(false);
    const [modalVencimientos, setmodalVencimientos] = useState(false);
    const [todoOk, setTodoOk] = useState(false);
    const [reparto_uuid, setRepartotUuid] = useState(uuidv4());
    const [pedidos, setPedidos] = useState();
    const [repartos, setRepartos] = useState([]);
    const [vehiculo, setVehiculo] = useState();
    const [deSucursal, setDeSucursal] = useState();
    const [vehiculoSeleccionado, setVehiculoSeleccionado] = useState(0);
    const globalTheme = useTheme();
    const [rowSelection, setRowSelection] = useState({});
    const [datos, setDatos] = useState({})
    const [modalEdit, setmodalEdit] = useState(false);
    const [totalkilos, setTotalkilo] = useState(0);
    const [datosSeleccionados, setDatosSeleccionados] = useState(null);
    const [openObservaciones, setopenObservaciones] = useState(false);
    const [observaciones, setObs] = useState("");
    const iconRemove = <RemoveCircleOutlineIcon sx={{ fontSize: 40 }} />;
    let usuario = localStorage.getItem("user");
    let fecha = moment(new Date()).format("YYYY-MM-DD");
    let vtvOk = vehiculoSeleccionado && vehiculoSeleccionado.vencimientovtv > fecha ? true : false;
    let hidraulicaOk = vehiculoSeleccionado && vehiculoSeleccionado.vencimientohidraulica > fecha ? true : false;
    const [notificacion, setNotificacion] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
    });

    const obtenerPedidos = () => {
        getData('/pedidos/sucursales/' + props.sucursal[0].id_sucursal, setPedidos)
    };

    const obtenerVehiculos = () => {
        // fetch(UrlApi + '/vehiculos/sucursal/' + props.sucursal[0].id_sucursal)
        //     .then(data => data.json())
        //     .then(data => {
        //         let usoGranel = data.filter((item) => item.uso === 66)
        //         setVehiculo(usoGranel)
        //     })
        fetch(UrlApi + '/vehiculos/granelsucursal/' + props.sucursal[0].id_sucursal)
            .then(data => data.json())
            .then(data => setVehiculo(data))
    };

    // console.log("pedidos,",pedidos);


    const obtenerAllVehiculos = () => {
        getData("/vehiculos/granel", setVehiculo)
    };

    useEffect(() => {
        obtenerPedidos()
        obtenerVehiculos()
    }, []);

    const handleClose = () => {
        props.setOpen(false)
        props.setMostrarSucursales(true)
        props.actualizar()
    };

    //Cuando cambiamos la fecha para la salida, necesitamos saber si no hay un reparto 
    //asignado para ese dia
    useEffect(() => {
        const obtenerRepartos = async () => {
            await fetch(UrlApi + '/repartos/sucursalfecha/' + fechaseleccionada + '/' + props.sucursal[0].id_sucursal + "/" + vehiculoSeleccionado.id).then(res => {
                if (res.ok) {
                    return res.json();
                } else {
                    console.log('Error obteniendo datos')
                }
            }).then(data => {
                data && setRepartos(data)
                data && setRepartoExiste(Object.keys(data).length > 0 ? true : false)
            })
        };
        fechaseleccionada && vehiculoSeleccionado && obtenerRepartos()
    }, [fechaseleccionada, vehiculoSeleccionado, setFechaSeleccionada]);


    useEffect(() => {
        totalkilos === 0 ? setTextoTitulo("Pedidos sin asignar (Seleccionar al menos uno)") : setTextoTitulo("Pedidos sin asignar")
        fechaseleccionada && totalkilos > 0 && vtvOk && hidraulicaOk && !repartoexiste ? setTodoOk(true) : setTodoOk(false)
    }, [datosSeleccionados, fechaseleccionada, repartoexiste, vehiculoSeleccionado,]);

    useEffect(() => {
        if (fecha &&
            (vehiculoSeleccionado && vehiculoSeleccionado.vencimientovtv && vehiculoSeleccionado.vencimientohidraulica)) {
            if (!vtvOk || !hidraulicaOk) {
                setmodalVencimientos(true)
            }
            else {
                setmodalVencimientos(false)
            }
        }
    }, [fecha, vehiculoSeleccionado, vtvOk, hidraulicaOk, setVehiculoSeleccionado,]);

    /// aca grabamos el reparto, si existia un reparto para la fecha seleccionada solo modificamos los pedidos
    const handleSaveDelivery = (e) => (
        [e.preventDefault(),
        !repartoexiste ? (repartonuevo(e), ponernumerodereparto(e)) : ponernumerodereparto(e),
        handleClose()]
    );

    async function repartonuevo(e) {
        postData(e,
            '/repartos',
            {
                id_sucursal: props.sucursal[0].id_sucursal,
                id_vehiculo: vehiculoSeleccionado.id,
                usuario: usuario,
                nro_reparto: reparto_uuid,
                estado: 6,
                fecha_salida_reparto: fechaseleccionada
            },
            setNotificacion,
            logEventos("Crear rendicion/reparto", "Repartos", "Repartos", localStorage.getItem("user")))
    };

    useEffect(() => {
        const { datosSeleccionados, totalkilos } = Object.keys(rowSelection).reduce((result, key) => {
            if (rowSelection[key]) {
                result.datosSeleccionados.push({ id: pedidos[key].idpedido, "id_estado": 23, "nro_reparto": reparto_uuid })
                result.totalkilos += pedidos[key].kgacargar;
            }
            return result;
        }, { datosSeleccionados: [], totalkilos: 0 });
        setDatosSeleccionados(datosSeleccionados);
        setTotalkilo(totalkilos);
    }, [rowSelection, totalkilos, reparto_uuid]);

    async function ponernumerodereparto(e) {
        await putData(e,
            '/pedidos/x/delivery1',
            (datosSeleccionados),
            setNotificacion
        )
    };

    const tableHeadCellProps = {
        align: "left",
        sx: {
            fontSize: 16,
            color: "white",
            fontWeight: "normal",
            backgroundColor: "#2471A3",
            borderColor: "black",
        },
    };

    const columns = useMemo(() =>
        [{ header: '#', accessorKey: 'idpedido', size: 20, enableEditing: false },
        { header: 'Cliente', accessorFn: (c) => `${c.razon_social} (${c.empresa})`, enableEditing: false, },
        { header: 'Localidad', accessorKey: 'localidad_direccion', enableEditing: false, size: 70 },
        { header: 'Dir.Entrega', accessorKey: 'nombre_direccion', enableEditing: false, },
        { header: 'Requerido', accessorKey: 'kgacargar', size: 100, enableEditing: false, },
        { header: 'Porcentaje (%)', accessorKey: 'porcentaje', size: 100, enableEditing: false, },
        { header: 'Fecha requerida', accessorKey: 'fecha_requerida', type: "date", format: "YYYY-MM-DD", enableEditing: false, },
        { header: 'Orden', accessorKey: 'orden_prioridad', type: 'numeric', size: 50 }
        ], []);

    const muiSearchTextFieldProps = {
        placeholder: "Buscar..",
        sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
        variant: "outlined",
        color: "success",
        size: "small",
    };

    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: globalTheme.palette.mode,
                    primary: globalTheme.palette.primary, //primary color for the table
                    info: {
                        main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            globalTheme.palette.mode === "light"
                                ? "rgb(234, 239, 241)"
                                : "#000", //pure black table in dark mode for fun
                    },
                },
                typography: {
                    button: {
                        textTransform: "none", //customize typography styles for all buttons in table by default
                        fontSize: "1.2rem",
                    },
                },
                components: {
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                fontSize: "1.1rem", //larger tooltip font size                                
                            },
                        },
                    },
                },

            }), [globalTheme]);

    const initialState = {
        density: "compact",
        showGlobalFilter: true,
        pagination: { pageIndex: 0, pageSize: 10 }
    };

    const guardarOrdenPrioridad = async ({ exitEditingMode, row, values }) => {
        // console.log("guardar orden prioridad")
        let response = await fetch(UrlApi + '/pedidos/' + row.original.idpedido, {
            method: 'PUT',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                orden_prioridad: values.orden_prioridad
            })
        })
        obtenerPedidos()
        exitEditingMode();
        logEventos("Orden de prioridad de pedido en reparto", "Repartos", "Crear repartos", localStorage.getItem("user"))

    };

    const saveObs = (e) => {
        putData(e,
            `/pedidos/${datos.idpedido}`,
            {
                observaciones: observaciones
            },
            setNotificacion
        )
    };

    const editRow = (e) => {
        setmodalEdit(true)
        setDatos(e)
    };

    return (
        <div>
            {pedidos &&
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={4} sx={{ width: 100, height: 210 }}>
                            {vehiculoSeleccionado === 0 ?
                                <Grid
                                    p={2}
                                    mt={2}
                                    container
                                    backgroundColor={grey[100]}
                                    border={1}
                                    borderColor={"steelblue"}
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Grid item xs={12}>
                                        < AccessTimeIcon />
                                        <Typography> Seleccione un vehiculo para empezar la rendición</Typography>
                                    </Grid>
                                </Grid>
                                :
                                <ReactSpeedometer
                                    maxValue={vehiculoSeleccionado.capacidad}
                                    value={totalkilos}
                                    currentValueText="${value} Kgrs"
                                    needleColor="steelblue"
                                    needleTransitionDuration={4000}
                                    needleTransition='easeElastic'
                                    customSegmentStops={[0, (vehiculoSeleccionado.capacidad * 0.75).toFixed(0), (vehiculoSeleccionado.capacidad * 0.85).toFixed(0), vehiculoSeleccionado.capacidad]}
                                    segmentColors={['lightgreen', 'orange', 'red']}
                                    startColor='green'
                                    endColor='red'
                                />
                            }
                        </Grid>
                        <Grid item xs={12} md>
                            <Grid item xs container direction="column" spacing={2}>
                                <Grid item>
                                    <Grid container spacing={1} direction={{ xs: 'column', sm: 'row' }} mt={0.5} mb={1}>
                                        <Grid item xs={9}>
                                            <FormControl fullWidth focused size="small">
                                                <InputLabel>Vehiculo</InputLabel>
                                                <Select
                                                    label="Vehiculo"
                                                    value={vehiculoSeleccionado}
                                                    onChange={(e) => setVehiculoSeleccionado(e.target.value)}
                                                    sx={{ "& .MuiInputBase-input.Mui-disabled": { WebkitTextFillColor: "teal", }, }}
                                                    InputLabelProps={{ style: { color: 'black' } }}
                                                    inputProps={{ min: 0, style: { textAlign: 'center', fontSize: 25, fontFamily: '"Comic Sans MS"', width: 300, padding: 0 } }} >
                                                    {vehiculo ? vehiculo.map(elemento => (
                                                        <MenuItem key={elemento.id} value={elemento}>
                                                            {elemento.patente}
                                                        </MenuItem>
                                                    )) : null}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControlLabel
                                                checked={deSucursal}
                                                onChange={(e) => {
                                                    setDeSucursal(e.target.checked)
                                                    e.target.checked ? obtenerAllVehiculos() : obtenerVehiculos()
                                                }}
                                                control={<Checkbox />}
                                                label="Otra Sucursal"
                                                sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                                            />
                                        </Grid>
                                        {/* <Grid item xs={6}>
                                            <FormControl fullWidth focused size="small">
                                                <InputLabel>Chofer</InputLabel>
                                                <Select
                                                    label="Chofer"
                                                    variant='outlined'
                                                    onChange={(e) => setChoferseleccionado(e.target.value)}
                                                    // value={choferSeleccionado}
                                                    sx={{ "& .MuiInputBase-input.Mui-disabled": { WebkitTextFillColor: "teal", }, }}
                                                    InputLabelProps={{ style: { color: 'black' }, }}
                                                    inputProps={{ min: 0, style: { textAlign: 'center', width: 300, padding: 6 } }}>
                                                    {chofer ? chofer.map(elemento => (
                                                        <MenuItem key={elemento.id} value={elemento}>
                                                            {elemento.nombre_apellido}
                                                        </MenuItem>
                                                    )) : null}
                                                </Select>
                                            </FormControl>
                                        </Grid> */}
                                        <Grid container mt={1} ml={1}>
                                            {vehiculoSeleccionado ?
                                                <>
                                                    <TextField
                                                        label="Vto VTV"
                                                        variant='outlined'
                                                        value={vehiculoSeleccionado ? moment(vehiculoSeleccionado.vencimientovtv).format("DD-MM-YYYY") : null} disabled={true}
                                                        sx={{ "& .MuiInputBase-input.Mui-disabled": { WebkitTextFillColor: vtvOk ? "black" : "black", }, }}
                                                        InputLabelProps={{ style: { color: 'black' }, }}
                                                        inputProps={{ min: 0, style: { textAlign: 'center', backgroundColor: vtvOk ? "#afdbaf" : " #de8787", width: 290, padding: 6, } }}
                                                    />
                                                    <TextField
                                                        label="Vto PH"
                                                        variant='outlined'
                                                        value={vehiculoSeleccionado ? moment(vehiculoSeleccionado.vencimientohidraulica).format("DD-MM-YYYY") : null} disabled={true}
                                                        sx={{ "& .MuiInputBase-input.Mui-disabled": { WebkitTextFillColor: hidraulicaOk ? "black" : "black", }, }}
                                                        InputLabelProps={{ style: { color: 'black' }, }}
                                                        inputProps={{ min: 0, style: { textAlign: 'center', backgroundColor: hidraulicaOk ? "#afdbaf" : " #de8787", width: 290, padding: 6, marginLeft: 6 } }}
                                                    />
                                                </>
                                                :
                                                <>
                                                    <TextField
                                                        label="Vto VTV"
                                                        variant='outlined'
                                                        value={moment(fecha).format("DD-MM-YYYY")} disabled={true}
                                                        sx={{ "& .MuiInputBase-input.Mui-disabled": { WebkitTextFillColor: vtvOk ? "black" : "black", }, }}
                                                        InputLabelProps={{ style: { color: 'black' }, }}
                                                        inputProps={{ min: 0, style: { textAlign: 'center', backgroundColor: "#afdbaf", width: 290, padding: 6, } }}
                                                    />
                                                    <TextField
                                                        label="Vto PH"
                                                        variant='outlined'
                                                        value={moment(fecha).format("DD-MM-YYYY")} disabled={true}
                                                        sx={{ "& .MuiInputBase-input.Mui-disabled": { WebkitTextFillColor: hidraulicaOk ? "black" : "black", }, }}
                                                        InputLabelProps={{ style: { color: 'black' }, }}
                                                        inputProps={{ min: 0, style: { textAlign: 'center', backgroundColor: "#afdbaf", width: 290, padding: 6, marginLeft: 6 } }}
                                                    /></>}
                                            {/* {choferSeleccionado ?
                                                <>
                                                    <TextField
                                                        label="Vto Licencia"
                                                        variant='outlined'
                                                        value={choferSeleccionado ? moment(choferSeleccionado.vencimiento_licencia).format("DD-MM-YYYY") : " "} disabled={true}
                                                        sx={{ "& .MuiInputBase-input.Mui-disabled": { WebkitTextFillColor: licenciaOk ? "black" : "black", }, }}
                                                        InputLabelProps={{ style: { color: 'black' }, }}
                                                        inputProps={{ min: 0, style: { textAlign: 'center', backgroundColor: licenciaOk ? "#afdbaf" : " #de8787", width: 137, padding: 6, marginLeft: 6 } }}
                                                    />

                                                    <TextField
                                                        label="Vto CP"
                                                        variant='outlined'
                                                        value={choferSeleccionado ? moment(choferSeleccionado.examen_carga).format("DD-MM-YYYY") : " "} disabled={true}
                                                        sx={{ "& .MuiInputBase-input.Mui-disabled": { WebkitTextFillColor: cargaOk ? "black" : "black", }, }}
                                                        InputLabelProps={{ style: { color: 'black' }, }}
                                                        inputProps={{ min: 0, style: { textAlign: 'center', backgroundColor: "#afdbaf", width: 137, padding: 6, marginLeft: 6 } }}
                                                    />
                                                </> :
                                                <>
                                                    <TextField
                                                        label="Vto Licencia"
                                                        variant='outlined'
                                                        value={moment(fecha).format("DD-MM-YYYY")} disabled={true}
                                                        sx={{ "& .MuiInputBase-input.Mui-disabled": { WebkitTextFillColor: licenciaOk ? "black" : "black", }, }}
                                                        InputLabelProps={{ style: { color: 'black' }, }}
                                                        inputProps={{ min: 0, style: { textAlign: 'center', backgroundColor: "#afdbaf", width: 137, padding: 6, marginLeft: 6 } }}
                                                    />

                                                    <TextField
                                                        label="Vto CP"
                                                        variant='outlined'
                                                        value={moment(fecha).format("DD-MM-YYYY")} disabled={true}
                                                        sx={{ "& .MuiInputBase-input.Mui-disabled": { WebkitTextFillColor: cargaOk ? "black" : "black", }, }}
                                                        InputLabelProps={{ style: { color: 'black' }, }}
                                                        inputProps={{ min: 0, style: { textAlign: 'center', backgroundColor: "#afdbaf", width: 137, padding: 6, marginLeft: 6 } }}
                                                    />
                                                </>} */}

                                        </Grid>
                                    </Grid>
                                    <TextField
                                        required
                                        label="Fecha de salida"
                                        variant='outlined'
                                        size='small'
                                        borderColor='white'
                                        name="fecha_salida_reparto"
                                        value={moment(fechaseleccionada).format("YYYY-MM-DD")}
                                        // onChange={handelRadioChange}
                                        onChange={(e) => setFechaSeleccionada(e.target.value)}
                                        fullWidth
                                        // color={fechaseleccionada && !fechaseleccionada ? "pink" : "#afdbaf"}
                                        type="date"
                                        inputProps={{
                                            min: moment(new Date()).format("YYYY-MM-DD")
                                        }}
                                    />
                                </Grid>
                                <Grid container mt={0.5} xs={12} md >
                                    {Object.keys(repartos).length > 0 ?
                                        <Stack sx={{ width: '145%' }} spacing={2}>
                                            <Alert severity='info'>
                                                Ya existe una rendición para esa fecha Número <strong>{repartos[0].idreparto}</strong>
                                                <br />
                                                Capacidad libre en vehiculo:<strong>{repartos[0].capacidad - repartos[0].kgacargar}</strong>
                                                <AlertTitle>
                                                    Por favor elija otro vehiculo u otra fecha
                                                </AlertTitle>
                                            </Alert>
                                        </Stack>
                                        : null}
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item flex={0} container direction="column">
                            <Grid item xs={4}>
                                <Button disabled={!todoOk} onClick={handleSaveDelivery} variant="outlined" color="success" >
                                    Confirmar
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button onClick={handleClose} variant="outlined" color="error" fullWidth>Volver</Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Box mt={2} boxShadow={3}>
                        <ThemeProvider theme={tableTheme}>
                            <MaterialReactTable
                                positionActionsColumn="last"
                                enableColumnResizing
                                columns={columns}
                                enableColumnFilters={false} //desactiva filtros x  columna
                                data={pedidos}
                                initialState={initialState}
                                state={{ rowSelection }}
                                muiTableHeadCellProps={tableHeadCellProps}
                                enableToolbarInternalActions={true}
                                enableRowSelection
                                enableRowActions
                                onRowSelectionChange={setRowSelection}
                                // editingMode="" //aca lo tengo que dejar en row, si lo pongo en cell no me va a dejar cargar o visualizar observaciones :(
                                // enableEditing
                                muiTableBodyCellProps={({ row }) => ({
                                    align: "left",
                                    sx: {
                                        backgroundColor: "#ffffff",
                                        color: "black",
                                        borderColor: "black",
                                    },
                                })}
                                renderTopToolbarCustomActions={() => (
                                    <>
                                        <Typography variant="poster" fontSize={20}>
                                            {textotitulo}
                                        </Typography>
                                    </>
                                )}
                                enableExpandAll={false} enableColumnActions={false}
                                muiSearchTextFieldProps={muiSearchTextFieldProps}
                                enableDensityToggle={false}
                                layoutMode="grid"
                                localization={MRT_Localization_ES}
                                enableColumnDragging={false}
                                enableHiding={false}
                                positionExpandColumn='first'
                                enablePagination={false}
                                // onEditingRowSave={guardarOrdenPrioridad}
                                renderRowActionMenuItems={({ closeMenu, row }) => [
                                    <MenuItem
                                        key={0}
                                        onClick={() => {
                                            closeMenu();
                                            setDatos(row.original)
                                            setopenObservaciones(true);
                                        }}
                                        sx={{ m: 0 }}>
                                        <ListItemIcon>
                                            <Preview color="secondary" />
                                        </ListItemIcon>
                                        Observaciones
                                    </MenuItem>,
                                    <MenuItem
                                        key={1}
                                        onClick={() => {
                                            closeMenu();
                                            editRow(row.original)
                                        }}
                                        sx={{ m: 0 }}>
                                        <ListItemIcon>
                                            <EditIcon color="primary" />
                                        </ListItemIcon>
                                        Editar
                                    </MenuItem>,
                                ]}
                            />
                        </ThemeProvider>
                    </Box>
                </>
            }

            {openObservaciones ?
                <DialogConTextField
                    title={`Observaciones`}
                    open={openObservaciones}
                    setOpen={setopenObservaciones}
                    notify={notificacion}
                    setNotify={setNotificacion}
                    btnText={"Guardar"}
                    guardar={saveObs}
                    color={"#e89d25"}
                    subtitle={<Typography>
                        {datos.observaciones ? (
                            <>
                                {datos.observaciones}
                                <br />
                                En caso de ser necesario puede ingresar nuevas
                            </>
                        ) : (
                            "No hay observaciones. Puede guardar alguna en caso de ser necesario"
                        )}
                    </Typography>}
                    value={observaciones}
                    setValue={setObs}
                    icon={iconRemove}
                /> : null}

            {/* {openObservaciones ?
                <Dialog open={openObservaciones} fullWidth onClose={() => setopenObservaciones(false)}>
                    <DialogContent>
                        <Box>
                            <TextField
                                focused
                                fullWidth
                                margin="dense"
                                variant="filled"
                                value={datos.observaciones ? datos.observaciones : "No hay observaciones cargadas"}
                                multiline
                                label="Observaciones del pedido"
                                InputProps={{
                                    readOnly: true,
                                }} />
                        </Box>
                    </DialogContent>
                </Dialog>
                : null} */}

            {modalVencimientos ?
                <ModalVencimientos
                    open={setmodalVencimientos}
                    vtv={vtvOk}
                    hidraulica={hidraulicaOk}
                />
                : null}

            {notificacion && notificacion.open ? (
                <Notify
                    mensaje={notificacion.mensaje}
                    open={notificacion.open}
                    color={notificacion.color}
                    handleClose={handleClose}
                    severity={notificacion.severity}
                />
            ) : null}

            {modalEdit ?
                <ModalEditar
                    open={modalEdit}
                    datos={datos}
                    setOpen={setmodalEdit}
                    actualizar={obtenerPedidos} /> : null}
        </div>
    )
}