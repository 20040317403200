import React, { useEffect } from 'react'
import mqtt from 'mqtt'
import { useSnackbar } from 'notistack'

const MqttSubscriber = () => {
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    const client = mqtt.connect('wss://mqtt.netizar.com', {
      username: 'netizar',
      password: 'LaClaveDeLaLora',
    })

    client.on('connect', () => {
      console.log('Conectado al broker MQTT')
      client.subscribe(['netizar/sistemas', 'netizar/produccion'], err => {
        if (!err) {
          console.log('Suscripción exitosa')
        }
      })
    })

    client.on('message', (topic, message) => {
      const messageText = message.toString().trim()

      // Verificar si el mensaje es JSON
      let isJson = false
      let parsedMessage = messageText

      try {
        parsedMessage = JSON.parse(messageText)
        isJson = true
      } catch (e) {
        isJson = false // Si no se puede parsear, no es JSON
      }

      // Condición para texto plano o JSON válido
      if (messageText && (!isJson || (isJson && Object.keys(parsedMessage).length > 0))) {
        enqueueSnackbar(`${topic}: ${isJson ? JSON.stringify(parsedMessage) : messageText}`, {
          variant: topic === 'netizar/sistemas' ? 'info' : 'error',
        })
      } else {
        console.log('Mensaje vacío o JSON inválido recibido, no se muestra Snackbar.')
      }
    })

    client.on('error', err => {
      enqueueSnackbar(`Error: ${err.message}`, { variant: 'error' })
    })

    // Escuchar cuando se desconecta del broker
    client.on('close', () => {
      console.log('Desconectado del broker MQTT')
    })

    // Limpiar la conexión cuando el componente se desmonta
    return () => {
      client.end()
    }
  }, [enqueueSnackbar])

  return <div></div>
}

export default MqttSubscriber
