//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio
// Creation Date : 05/08/2024
// Version : 1
// Description : tabla de reclamos por motivos
//------Changes ----------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useEffect, useState } from "react";
import { UrlApi } from "../../../../services/apirest";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Detalle_tabla_motivos from "./Detalle_tabla_motivos";


function Tabla_motivos() {
  const [cantidadXmotivo, setCantidadXmotivo] = useState([]);
  const [idElegido, setIdElegido] = useState(null);

  const obtenerCantidadXmotivo = () => {
    fetch(UrlApi + "/reclamos/cantidadPorMotivo")
      .then((data) => data.json())
      .then((data) => setCantidadXmotivo(data));
  };
  useEffect(() => {
    obtenerCantidadXmotivo();
  }, []);

  console.log("CANTIDAD X MOTIVO", cantidadXmotivo);
  console.log("ID ELEGIDO TABLA", idElegido);

  // Ordenar los datos de mayor a menor por cantidad de reclamos
  const cantidadXmotivoOrdenado = [...cantidadXmotivo].sort(
    (a, b) => b.cantidad_reclamos - a.cantidad_reclamos
  );

  // Calcular el total de reclamos
  const totalReclamos = cantidadXmotivoOrdenado.reduce(
    (total, item) => total + item.cantidad_reclamos,
    0
  );

  // Transformar los datos para el PieChart, incluyendo el porcentaje
  const data = (cantidadXmotivoOrdenado || []).map((item) => ({
    label: `${item.descripcion_motivo} (${(
      (item.cantidad_reclamos / totalReclamos) *
      100
    ).toFixed(2)}%)`,
    value: item.cantidad_reclamos,
  }));

  const data2 = (cantidadXmotivoOrdenado || []).map((item) => ({
    id_motivo: item.id_motivo,
    descripcion: item.descripcion_motivo,
    cantidad: item.cantidad_reclamos,
    porcentaje: ((item.cantidad_reclamos / totalReclamos) * 100).toFixed(2), // Calcular el porcentaje
  }));

  //------------------ Dialog --------------------------//
  const [abrirDialog, setAbrirDialog] = useState(false);

  const handleOpenDialog = (row) => {
    setAbrirDialog(true);
    setIdElegido(row);
  };

  const handleCloseDialog = () => {
    setAbrirDialog(false);
    setIdElegido(null);
  };

  // tabla 1
  const paperStyle1 = {
    padding: "10px",
    margin: "20px",
    width: "500px",
  };

  const HeaderCell = styled(TableCell)({
    marginTop: "2px",
    fontSize: 13,
    color: "white",
    fontWeight: "normal",
    backgroundColor: "#2471A3",
    borderColor: "white",
    padding: "6.3px",
  });

  return (
    <div>
      <Paper
        elevation={10}
        style={{
          ...paperStyle1,
          flex: 1,
          marginLeft: 370,
        }}
      >
        <TableContainer style={{ maxHeight: "40vh" }}>
          <Typography
            align="center"
            style={{ fontWeight: "bold", fontSize: 20 }}
          >
            Motivos de los reclamos
          </Typography>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <HeaderCell align="center" sx={{ border: "1px solid black" }}>
                  <b>Motivos</b>
                </HeaderCell>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: "#2B76A6",
                    color: "white",
                    border: "1px solid black",
                  }}
                >
                  Cantidad
                </TableCell>
                <HeaderCell
                  align="center"
                  style={{
                    backgroundColor: "#2B76A6",
                    color: "white",
                    border: "1px solid black",
                  }}
                >
                  Porcentaje
                </HeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data2.map((row, index) => (
                <TableRow key={index} sx={{ border: "1px solid black" }}>
                  <TableCell sx={{ border: "1px solid black" }}>
                    {row.descripcion}
                    <IconButton
                      aria-label="add"
                      size="small"
                      onClick={() => handleOpenDialog(row.id_motivo)} // Pasar la descripción al hacer clic
                    >
                      <AddCircleIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align="center" sx={{ border: "1px solid black" }}>
                    {row.cantidad}
                  </TableCell>
                  <TableCell align="center" sx={{ border: "1px solid black" }}>
                    {row.porcentaje}%
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {abrirDialog ? (
          <Detalle_tabla_motivos
            abrirDialog={abrirDialog}
            onClose={handleCloseDialog}
            cantidadXmotivo={cantidadXmotivo}
            idElegido={idElegido}
          />
        ) : null}
      </Paper>
    </div>
  );
}

export default Tabla_motivos;
