import Navbar from '../../Navbar'
import React, { useEffect, useState, useMemo } from 'react';
import { UrlApi } from '../../../services/apirest'
import { Container, Typography, Box, Button, Tooltip, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import Addlocalidades from './Addlocalidades';
import Editarlocalidades from './Editarlocalidades';
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import PlaceIcon from '@mui/icons-material/Place';

export default function Localidades() {
    const [localidades, setLocalidades] = useState()
    const [alta, setAlta] = useState(false)
    const [tabla, setTabla] = useState(true)
    const [editar, setEditar] = useState(false)
    const [datosformulario, setDatosformulario] = useState({})
    const globalTheme = useTheme();

    const obtenerLocalidades = () => {
        fetch(UrlApi + '/localidades')
            .then(data => data.json())
            .then(data => setLocalidades(data))
    };

    useEffect(() => {
        obtenerLocalidades()
    }, [tabla]);

    const handleClickNuevo = () => {
        setAlta(true)
        setTabla(false)
    };

    const mostrareditar = (datosDeLaLinea) => {
        setEditar(true)
        setTabla(false)
        setDatosformulario(datosDeLaLinea)
    };

    const columns = useMemo(() =>
        [{ header: 'Localidad', accessorKey: 'localidad' },
        { header: 'Código Postal', accessorKey: 'cp' },
        { header: 'Provincia', accessorKey: 'provincia' }
        ], []);

        const tableTheme = useMemo(
            () =>
                createTheme({
                    palette: {
                        mode: globalTheme.palette.mode,
                        primary: globalTheme.palette.primary, //primary color for the table
                        info: {
                            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
                        },
                        background: {
                            default:
                                globalTheme.palette.mode === "light"
                                    ? "rgb(234, 239, 241)"
                                    : "#000", //pure black table in dark mode for fun
                        },
                    },
                    typography: {
                        button: {
                            textTransform: "none", //customize typography styles for all buttons in table by default
                            fontSize: "1rem",
                        },
                    },
                    components: {
                        MuiTooltip: {
                            styleOverrides: {
                                tooltip: {
                                    fontSize: "0.8rem", //larger tooltip font size                                
                                },
                            },
                        },
                    },
    
                }), [globalTheme]);

    const muiSearchTextFieldProps = {
        placeholder: "Buscar..",
        sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
        variant: "outlined",
        color: "success",
        size: "small",
    };

    const tableHeadCellProps = {
        align: "center",
        sx: {
            fontSize: 16,
            color: "white",
            fontWeight: "normal",
            backgroundColor: "#2471A3",
            borderColor: "black",
        },
    };

    const initialState = {
        density: "compact",
        showGlobalFilter: true,
        pagination: { pageIndex: 0, pageSize: 10 },
       
    };

    return (
        <div>
            <Navbar />
            <Container maxWidth="xl" mt={1}>
                {localidades && tabla ? (
                    <Box mt={2} boxShadow={3}>
                        <ThemeProvider theme={tableTheme}>
                            <MaterialReactTable
                                enableColumnResizing
                                columns={columns}
                                data={localidades}
                                initialState={initialState}
                                positionActionsColumn="last"
                                enableExpandAll={false}
                                enableToolbarInternalActions={true}
                                muiTableHeadCellProps={tableHeadCellProps}
                                muiTableBodyCellProps={({ row }) => ({
                                    align: "left",
                                    sx: {
                                        backgroundColor: "#afdbaf",
                                        color: "black",
                                        borderColor: "black",
                                    },
                                })}
                                enableColumnActions={false}
                                muiSearchTextFieldProps={muiSearchTextFieldProps}
                                enableDensityToggle={false}
                                enableEditing
                                renderRowActions={({ row, table }) => (
                                    <div>
                                        <Tooltip title="Editar localidad">
                                            <IconButton
                                                onClick={() => mostrareditar(row.original)}
                                                color="primary"
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>

                                    </div>
                                )}
                                renderTopToolbarCustomActions={() => (
                                    <>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<AddBoxTwoToneIcon />}
                                            onClick={handleClickNuevo}
                                        >
                                            Nuevo Registro
                                        </Button>
                                        <Typography variant="poster" fontSize={20}>
                                            <PlaceIcon sx={{ size: 20 }} />
                                            Localidades
                                        </Typography>
                                    </>
                                )}
                                layoutMode="grid"
                                localization={MRT_Localization_ES}
                                enableColumnDragging={false}
                                enableColumnFilters={false}                        
                                enableHiding={false}
                                
                                />
                        </ThemeProvider>
                    </Box>
                ) : null}
            </Container>
            {alta ? <Addlocalidades alta={setAlta} tabla={setTabla}></Addlocalidades> : null}
            {editar ? <Editarlocalidades editar={setEditar} tabla={setTabla} datos={datosformulario}></Editarlocalidades> : null}
            <br />
        </div>
    )
}