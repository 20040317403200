import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { DialogTitle, Grid, TextField, Button } from "@mui/material";
import InputMask from "react-input-mask";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";

import moment from "moment";

import { UrlApi } from "../../../services/apirest";

function FormMovimientoTanque(props) {
  const [open, setOpen] = useState(props.open); // Esto abre o no el modal

  const [datosMovimiento, setDatosMovimiento] = useState({
    id_tanque: props.datosTanque.id,
    id_sucursal: props.datosTanque.id_sucursal,
    nro_remito: null,
    fecha: null,
    usuario: localStorage.getItem("user"),
  });
  const [sucursales, setSucursales] = useState();

  const almacenarDatosMovimiento = (e) => {
    setDatosMovimiento({ ...datosMovimiento, [e.target.name]: e.target.value ==="" ? null : e.target.value });
  };


  const obtenerSucursales = () => {
    fetch(UrlApi + "/sucursales")
      .then((data) => data.json())
      .then((data) => setSucursales(data));
  };

  useEffect(() => {
    obtenerSucursales();
  }, []);

  const handleClose = () => {
    setOpen(false);
    props.showForm(false);
    props.flagCancel(1);
  };

  const handleGuardar = () => {
    setOpen(false);
    props.showForm(false);
    props.datosMovimiento(datosMovimiento);
    props.datosFormulario({
      ...props.datos,
      ["fecha_ubicacion"]: datosMovimiento.fecha,
    });
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <Box backgroundColor="#1f618d" color="white">
          <DialogTitle>
            ¡Atención! Está por cambiar de sucursal el tanque N° Serie:{" "}
            {props.datos.nro_serie}.
          </DialogTitle>
        </Box>
        <DialogContent>
          <form>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              wrap="wrap"
            >
              <Grid item xs={4}>
                <FormControl fullWidth focused disabled>
                  <InputLabel>Sucursal</InputLabel>
                  <Select
                    required
                    name="id_sucursal"
                    InputLabelProps={{ shrink: true, required: true }}
                    value={props.datosTanque.id_sucursal}
                    label="Sucursal"
                  >
                    {sucursales
                      ? sucursales.map((elemento) => (
                          <MenuItem key={elemento.id} value={elemento.id}>
                            {elemento.nombre}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <InputMask
                  mask="99999-99999999"
                  // value={props.datosMovimiento.nro_remito}
                  value={datosMovimiento.nro_remito}
                  disabled={false}
                  maskChar=" "
                  // onChange={props.almacenarDatosMovimiento}
                  onChange={almacenarDatosMovimiento}
                >
                  {() => (
                    <TextField
                      autoFocus
                      fullWidth
                      // required
                      defaultValue=""
                      type="text"
                      focused
                      name="nro_remito"
                      label="Numero Remito"
                    />
                  )}
                </InputMask>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  // required
                  type="date"
                  fullWidth
                  focused
                  label="Fecha Remito"
                  variant="outlined"
                  color="primary"
                  margin="none"
                  sizes="small"
                  name="fecha"
                  onChange={almacenarDatosMovimiento}
                  value={moment(datosMovimiento.fecha).format("YYYY-MM-DD")}
                />
              </Grid>
            </Grid>
            <Grid
              mt={2}
              container
              spacing={1}
              direction="row"
              justifyContent="space-between"
            >
              <Grid item>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => handleClose()}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  // disabled={
                  //   !datosMovimiento.fecha || !datosMovimiento.nro_remito
                  // } 
                  //no permito guardar a menos que tenga el nro de remito y la fecha
                  // type="submit"
                  variant="outlined"
                  color="success"
                  onClick={() => handleGuardar()}
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default FormMovimientoTanque;
