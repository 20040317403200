import React, { useState } from "react";
import { Box, Grid, FormControl, MenuItem, Select, InputLabel, TextField, Tooltip, IconButton } from "@mui/material";
import { AddCircleOutline, Delete } from "@material-ui/icons";

export default function Cuerpo2(props) {
    const { datosCuerpo, setdatosCuerpo, uuid, valorPorcentajes } = props;

    const [formValues, setFormValues] = useState(datosCuerpo);

    const handleAddLinea = () => {
        setFormValues(prevState => [
            ...prevState,
            {
                subtotal: 0,
                subtotal_iva: 0,
                id_porcentajeiva: null,
                detalle_productos: null,
                uuid_comprobante: uuid, // Reemplaza con tu método de generación de UUID
                usuario: localStorage.getItem('user')
            }
        ]);
    };

    const updateDatosCuerpo = (updatedValues) => {
        const updatedDatosCuerpo = Object.keys(updatedValues).map((key) => ({
            id_porcentajeiva: updatedValues[key].id_porcentajeiva,
            subtotal: updatedValues[key].subtotal,
            subtotal_iva: updatedValues[key].subtotal_iva,
            detalle_productos:updatedValues[key].detalle_productos,
            usuario: localStorage.getItem('user'),
            uuid_comprobante: uuid
        }));

        setdatosCuerpo(updatedDatosCuerpo);
    };

    const calculateSubtotalIva = (subtotal, id_porcentajeiva) => {
        const porcentajeItem = valorPorcentajes.find(item => item.idporcentaje_iva === id_porcentajeiva);
        const porcentajeIva = porcentajeItem ? porcentajeItem.valor : 0;
        const subtotalIva = (subtotal * (porcentajeIva)).toFixed(2);
        // const valorIva = (subtotal * porcentajeIva).toFixed(2);

        return {
            subtotalIva,
            // valorIva 
        };
    };

    const handleInputChange = (index, field, value) => {
        setFormValues((prevValues) => {
            const updatedValues = [...prevValues];
            const updatedItem = { ...updatedValues[index], [field]: value };

            if (field === 'subtotal') {
                const { subtotalIva, } = calculateSubtotalIva(value, updatedItem.id_porcentajeiva);
                updatedItem.subtotal_iva = subtotalIva;
                // updatedItem.valorIva = valorIva;
            }

            updatedValues[index] = updatedItem;

            updateDatosCuerpo(updatedValues);
            return updatedValues;
        });
    };

    const handlePorcentajeIvaChange = (index, id_porcentajeiva) => {
        setFormValues((prevValues) => {
            const updatedValues = [...prevValues];
            const updatedItem = { ...updatedValues[index], id_porcentajeiva };

            const { subtotalIva, valorIva } = calculateSubtotalIva(updatedItem.subtotal, id_porcentajeiva);
            updatedItem.subtotal_iva = subtotalIva;
            // updatedItem.valorIva = valorIva;

            updatedValues[index] = updatedItem;

            updateDatosCuerpo(updatedValues);
            return updatedValues;
        });
    };

    const deshacerLinea = (index) => {
        setFormValues(prevState => {
            if (prevState.length === 1) {
                return prevState;
            }

            const newState = prevState.filter((_, i) => i !== index);
            return newState;
        });
    };



    const style = {
        backgroundColor: "#ffffff",
        width: "100%"
    };

    return (
        <div>
            <Box
                backgroundColor="#F4F6F6"
                mt={2}
                p={2}
                border={0.5}
                borderColor={"#ABB2B9"}
                borderRadius={4}
            >

                {Object.keys(formValues).map((key, index) => (
                    <div key={index}>
                        <Grid container spacing={1} alignItems="center" direction={{ xs: 'column', sm: 'row' }}>
                            <Grid item xs={5} mb={1}>
                                <TextField
                                    label="Descripción"
                                    autoComplete="off"
                                    type="text"
                                    style={style}
                                    value={formValues[key].detalle_productos || ''}
                                    onChange={(e) => handleInputChange(index, 'detalle_productos', e.target.value)}
                                    readOnly
                                    size="small"
                                    focused
                                    name="detalle_productos"
                                />
                            </Grid>
                            <Grid item xs={2} mb={1}>
                                <TextField
                                    label="Subtotal"
                                    autoComplete="off"
                                    type="number"
                                    style={style}
                                    value={formValues[key].subtotal || ''}
                                    onChange={(e) => handleInputChange(index, 'subtotal', parseFloat(e.target.value))}
                                    size="small"
                                    focused
                                />
                            </Grid>
                            <Grid item xs={2} mb={1}>
                                <FormControl fullWidth size="small" focused style={style}>
                                    <InputLabel>Valor IVA (%)</InputLabel>
                                    <Select
                                        value={formValues[key].id_porcentajeiva || ''}
                                        // onChange={(e) => handleInputChange(index, 'id_porcentajeiva', (e.target.value))}
                                        onChange={(e) => handlePorcentajeIvaChange(index, (e.target.value))}
                                        label="Valor IVA (%)"
                                        focused
                                        name='id_porcentajeiva'
                                    >
                                        {valorPorcentajes.map(({ idporcentaje_iva, descripcion }) => (
                                            <MenuItem key={idporcentaje_iva} value={idporcentaje_iva}>
                                                {descripcion}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2} mb={1}>
                                <TextField
                                    label="Subtotal con IVA"
                                    name='subtotal_iva'
                                    type="text"
                                    autoComplete="off"
                                    value={formValues[key].subtotal_iva || ''}
                                    onChange={(e) => handleInputChange(index, 'subtotal_iva', e.target.value)}
                                    readOnly
                                    size="small"
                                    style={style}
                                    focused
                                />
                            </Grid>
                            <Tooltip title="Eliminar linea">
                                <IconButton variant="outlined" color='error' onClick={() => deshacerLinea(index)}>
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                            {index === formValues.length - 1 && (
                                <Tooltip title="Agregar linea">
                                    <IconButton variant="outlined" color="primary" onClick={() => handleAddLinea()}>
                                        <AddCircleOutline />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Grid>
                    </div>
                ))}

            </Box>
        </div>
    )
}