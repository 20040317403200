import React, { useEffect, useState, useMemo } from "react";
import { getDataIx0 } from "../../../services/library";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function ModalInfoErrorFacturacion(props) {
  const [error, setError] = useState(null);

  useEffect(() => {
    getDataIx0("/errores_facturacion/pedido/" + props.datos.id_pedido, setError);
  }, []);

  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Error de facturacion
          </Typography>
          {error && (
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {error.observaciones}
            </Typography>
          )}
        </Box>
      </Modal>
    </div>
  );
}

export default ModalInfoErrorFacturacion;
