// Developer     : Magalí Perea
// Creation Date : 2024-04-22
// Version       : 
// Description   : Excepcionalmente, trae la seleccion de las viandas de la semana corriente, bloqueando los días pasados.

import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import moment from "moment";
import { getData, postData } from "../../services/library";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import SaveIcon from "@mui/icons-material/Save";
import Fab from "@mui/material/Fab";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Notify from "../../../src/components/Notify";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import Divider from "@mui/material/Divider";
import { UrlApi } from "../../services/apirest";
import Alert from "@mui/material/Alert";

function SeleccionExcepcional() {
  const [viandasSemana, setViandasSemana] = useState();
  const [seleccion, setSeleccion] = useState([]);
  const [alta, setAlta] = useState(true);
  const [habilitarMod, setHabilitarMod] = useState();

  const getSeleccion = () => {
    fetch(UrlApi + `/viandas/user/${usuario}/${inicio}/${fin}`)
      .then((data) => data.json())
      .then((data) =>
        data.length > 0
          ? setSeleccion(data)
          : setSeleccion([
              {
                usuario: localStorage.getItem("user"),
                fecha: viandasSemana[0].fecha,
                opcion: 0,
                guarnicion: "",
                observaciones: "",
              },
              {
                usuario: localStorage.getItem("user"),
                fecha: viandasSemana[1].fecha,
                opcion: 0,
                guarnicion: "",
                observaciones: "",
              },
              {
                usuario: localStorage.getItem("user"),
                fecha: viandasSemana[2].fecha,
                opcion: 0,
                guarnicion: "",
                observaciones: "",
              },
              {
                usuario: localStorage.getItem("user"),
                fecha: viandasSemana[3].fecha,
                opcion: 0,
                guarnicion: "",
                observaciones: "",
              },
              {
                usuario: localStorage.getItem("user"),
                fecha: viandasSemana[4].fecha,
                opcion: 0,
                guarnicion: "",
                observaciones: "",
              },
            ])
      );
  };

  useEffect(() => {
    getData(`/viandas/semanal/${inicio}/${fin}`, setViandasSemana);
    // enableModificacion();
    getHorarioLimiteViandas();
    getServerDate();
  }, []);

  //Obtengo las viandas de la semana
  useEffect(() => {
    getSeleccion();
  }, [viandasSemana]);

  const inicio = moment().day(1).format("YYYY-MM-DD"); // fecha de inicio de la semana siguiente
  const fin = moment().day(5).format("YYYY-MM-DD"); // fecha finalizacion de la semana siguiente
  const [serverDate, setServerDate]=useState();
//   const today = moment(new Date()).day()
//   console.log("Hoy es: " + today)

  const usuario = localStorage.getItem("user");
  const [disBtn, setDisBtn] = useState(false);

  async function guardar(e) {
    setDisBtn(true);
    postData(e, "/viandas/pedidos", seleccion, setNotify);
  }

  const handleInputChange = (index, name, value, fecha, id_opcion) => {
    const dataCopy = seleccion[index];
    var updated = {};
    if (name === "opcion") {
      updated = {
        ...dataCopy,
        [name]: value,
        fecha: !!fecha ? fecha : dataCopy.fecha,
        guarnicion: "",
        id_opcion: id_opcion,
      };
    } else {
      updated = {
        ...dataCopy,
        [name]: value,
      };
    }

    const clone = [...seleccion];
    clone[index] = updated;
    setSeleccion(clone);
  };

  const [notify, setNotify] = useState({
    open: false,
    severity: "",
    color: "",
    mensaje: "",
  });

  const handleClose = () => {
    window.location.replace(`/#/navbar`);
  };
  const [horarioLimite, setHorarioLimite] = useState();
  
  const getHorarioLimiteViandas = () => {
    fetch(UrlApi + "/desplegables/horarioviandas")
      .then((data) => data.json())
      .then((data) => setHorarioLimite(parseInt(data[0].valor)));
  };

  const getServerDate = () => {
    fetch(UrlApi + "/viandas/fechahora")
      .then((data) => data.json())
      .then((data) => setServerDate(data));
  };









  

//   const [serverDate, setServerDate] = useState();


  


  
//   const enableModificacion = () => {
//     fetch(UrlApi + "/viandas/fechahora")
//       .then((data) => data.json())
//       .then((data) => {
//         setServerDate(data)
//         // setHabilitarMod(
//         //   data.dia < 5 || (data.dia === 5 && data.hora < horarioLimite)
//         // );
//       });
//   };

//   useEffect(() => {
//     horarioLimite && enableModificacion();
//   }, [horarioLimite]);

//   const habilitar=(fecha)=>{
//     console.log("Voy a revisar la hora y la fecha para habilitar")
//     var server
//     fetch(UrlApi+"/viandas/fechahora")
//     .then((data)=>data.json())
//     .then((data)=>{
//         server=data
//         // console.log(data)
//     })
//     console.log(server)
//     // console.log(serverDate)
//     // console.log("Entro en funcion habilitar")
//     // console.log(fecha)
//   }

// async function habilitar(fecha){
//     // var req=await fetch(UrlApi + "/viandas/fechahora")
//     // var serverDate= await req.json()
//     // console.log(serverDate)
//     // var diamenu=moment(fecha).day()
//     return false
    
// }


// async function habilitar(fecha){
//     const data=await fetch(UrlApi + "/viandas/fechahora")
//     const serverDate=await data.json();
//     if (!!serverDate.dia&&serverDate.dia > moment(fecha).day()){
//         // console.log(typeof serverDate.dia)
//         // console.log(typeof(moment(fecha).day()))
//         // console
//         console.log("hola")
//         return true
//     } else {
//         console.log("hola")

//         return false
//     }
//     // console.log(serverDate.dia)
//     // if(moment(fecha).day() < parseInt(serverDate.dia)){
//     //     return false
//     // } else {
//     //     return true
//     // }
    
// }

// const habilitar = (fecha) => {
//     if (moment(fecha).day() > 2) {
//         return false;
//     } else {
//         return true; // O simplemente "return moment(fecha).day() <= 2;"
//     }
// };


  return (
    <div>
      <Navbar />
      {!!viandasSemana && viandasSemana.length === 0 ? (
        <Container maxWidth="xl" sx={{ p: 3 }}>
          <Alert variant="outlined" severity="warning" color="warning">
            Las viandas de la semana aún no han sido cargadas
          </Alert>
        </Container>
      ) : null}

      {alta && (
        <Container maxWidth="xl" sx={{ py: 2 }}>
          {seleccion && seleccion.length > 0 ? (
            <form onSubmit={guardar}>
              {notify.open ? (
                <Notify
                  open={notify.open}
                  color={notify.color}
                  severity={notify.severity}
                  mensaje={notify.mensaje}
                  handleClose={() => handleClose()}
                />
              ) : null}
              {viandasSemana &&
                viandasSemana.map((opcion, index) => (
                  <>
                    <Grid container direcion="column">
                      <FormControl
                        sx={{ m: 3 }}
                        component="fieldset"
                        variant="standard"
                      >
                        <FormLabel component="legend">
                          {`${moment(opcion.fecha)
                            .format("dddd DD-MM-YYYY")
                            .toUpperCase()} ${
                            opcion.feriado === 1 ? "(Feriado/No Laborable)" : ""
                          } `}
                        </FormLabel>
                        <FormGroup>
                            {/* {console.log(moment(opcion.fecha).day())} */}
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={opcion.feriado===1 || (moment(opcion.fecha).day() < serverDate.dia )}
                                // disabled={habilitar(opcion.fecha)}
                                // disabled={opcion.feriado === 1 || !habilitarMod}
                                // disabled={opcion.feriado===1 
                                // || moment(opcion.fecha).day()<today 
                                // }
                                checked={
                                  seleccion[index] &&
                                  seleccion[index].opcion === 1
                                }
                                onChange={(e) => {
                                  handleInputChange(
                                    index,
                                    "opcion",
                                    e.target.checked ? 1 : null,
                                    opcion.fecha,
                                    opcion.id_opcion
                                  );
                                }}
                              />
                            }
                            label={opcion.opcion1.toUpperCase()}
                          />
                          {opcion.guarnicion11 ? (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                ml: 3,
                              }}
                            >
                              <FormControlLabel
                                label={opcion.guarnicion11}
                                control={
                                  <Checkbox
                                    disabled={
                                      (seleccion[index] &&
                                        seleccion[index].opcion !== 1) ||
                                      opcion.feriado === 1 
                                      || (moment(opcion.fecha).day() < serverDate.dia )
                                    }
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "guarnicion",
                                        e.target.checked
                                          ? opcion.guarnicion11
                                          : null,
                                        null
                                      )
                                    }
                                    checked={
                                      seleccion[index] &&
                                      seleccion[index].opcion === 1 &&
                                      seleccion[index].guarnicion ===
                                        opcion.guarnicion11
                                    }
                                  />
                                }
                              />
                            </Box>
                          ) : null}
                          {opcion.guarnicion12 ? (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                ml: 3,
                              }}
                            >
                              <FormControlLabel
                                label={opcion.guarnicion12}
                                control={
                                  <Checkbox
                                    disabled={
                                      (seleccion[index] &&
                                        seleccion[index].opcion !== 1) ||
                                      opcion.feriado === 1 
                                      || (moment(opcion.fecha).day() < serverDate.dia )

                                    }
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "guarnicion",
                                        e.target.checked
                                          ? opcion.guarnicion12
                                          : null
                                      )
                                    }
                                    checked={
                                      seleccion[index] &&
                                      seleccion[index].opcion === 1 &&
                                      seleccion[index].guarnicion ===
                                        opcion.guarnicion12
                                    }
                                  />
                                }
                              />
                            </Box>
                          ) : null}

                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={opcion.feriado === 1 || (moment(opcion.fecha).day() < serverDate.dia )
                                }
                                checked={
                                  seleccion[index] &&
                                  seleccion[index].opcion === 2
                                }
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "opcion",
                                    e.target.checked ? 2 : null,
                                    opcion.fecha,
                                    opcion.id_opcion
                                  )
                                }
                              />
                            }
                            label={opcion.opcion2.toUpperCase()}
                          />
                          {opcion.guarnicion21 ? (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                ml: 3,
                              }}
                            >
                              <FormControlLabel
                                label={opcion.guarnicion21}
                                control={
                                  <Checkbox
                                    disabled={
                                      (seleccion[index] &&
                                        seleccion[index].opcion !== 2) ||
                                      opcion.feriado === 1 
                                      || (moment(opcion.fecha).day() < serverDate.dia )

                                    }
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "guarnicion",
                                        e.target.checked
                                          ? opcion.guarnicion21
                                          : null
                                      )
                                    }
                                    checked={
                                      seleccion[index] &&
                                      seleccion[index].opcion === 2 &&
                                      seleccion[index].guarnicion ===
                                        opcion.guarnicion21
                                    }
                                  />
                                }
                              />
                            </Box>
                          ) : null}
                          {opcion.guarnicion22 ? (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                ml: 3,
                              }}
                            >
                              <FormControlLabel
                                label={opcion.guarnicion22}
                                control={
                                  <Checkbox
                                    disabled={
                                      (seleccion[index] &&
                                        seleccion[index].opcion !== 2) ||
                                      opcion.feriado === 1 
                                      || (moment(opcion.fecha).day() < serverDate.dia )
                                      
                                    }
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "guarnicion",
                                        e.target.checked
                                          ? opcion.guarnicion22
                                          : null
                                      )
                                    }
                                    checked={
                                      seleccion[index] &&
                                      seleccion[index].opcion === 2 &&
                                      seleccion[index].guarnicion ===
                                        opcion.guarnicion22
                                    }
                                  />
                                }
                              />
                            </Box>
                          ) : null}

                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={opcion.feriado === 1 || (moment(opcion.fecha).day() < serverDate.dia )
                                }
                                checked={
                                  seleccion[index] &&
                                  seleccion[index].opcion === 3
                                }
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "opcion",
                                    e.target.checked ? 3 : null,
                                    opcion.fecha,
                                    opcion.id_opcion
                                  )
                                }
                              />
                            }
                            label={opcion.opcion3.toUpperCase()}
                          />
                          {opcion.guarnicion31 ? (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                ml: 3,
                              }}
                            >
                              <FormControlLabel
                                label={opcion.guarnicion31}
                                control={
                                  <Checkbox
                                    disabled={
                                      (seleccion[index] &&
                                        seleccion[index].opcion !== 3) ||
                                      opcion.feriado === 1 
                                      || (moment(opcion.fecha).day() < serverDate.dia )
                                      
                                    }
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "guarnicion",
                                        e.target.checked
                                          ? opcion.guarnicion31
                                          : null
                                      )
                                    }
                                    checked={
                                      seleccion[index] &&
                                      seleccion[index].opcion === 3 &&
                                      seleccion[index].guarnicion ===
                                        opcion.guarnicion31
                                    }
                                  />
                                }
                              />
                            </Box>
                          ) : null}
                          {opcion.guarnicion32 ? (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                ml: 3,
                              }}
                            >
                              <FormControlLabel
                                label={opcion.guarnicion32}
                                disabled={
                                  (seleccion[index] &&
                                    seleccion[index].opcion !== 3) ||
                                  opcion.feriado === 1
                                  || (moment(opcion.fecha).day() < serverDate.dia )
                                  
                                }
                                control={
                                  <Checkbox
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "guarnicion",
                                        e.target.checked
                                          ? opcion.guarnicion32
                                          : null
                                      )
                                    }
                                    checked={
                                      seleccion[index] &&
                                      seleccion[index].opcion === 3 &&
                                      seleccion[index].guarnicion ===
                                        opcion.guarnicion32
                                    }
                                  />
                                }
                              />
                            </Box>
                          ) : null}
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={opcion.feriado === 1 || (moment(opcion.fecha).day() < serverDate.dia )
                                }
                                checked={
                                  seleccion[index] &&
                                  seleccion[index].opcion === 0
                                }
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "opcion",
                                    e.target.checked ? 0 : null,
                                    opcion.fecha,
                                    opcion.id_opcion
                                  )
                                }
                              />
                            }
                            label={"NINGUNO"}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid container px={2} pb={1}>
                      <Grid item xs={12}>
                        <TextField
                          inputProps={{
                            maxLength: 50,
                          }}
                          disabled={
                            opcion.feriado === 1 ||
                            (seleccion[index] &&
                              seleccion[index].opcion === 0)
                              || (moment(opcion.fecha).day() < serverDate.dia )

                          }
                          fullWidth
                          label="Observaciones"
                          value={
                            seleccion[index] && seleccion[index].observaciones
                          }
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "observaciones",
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                    </Grid>

                    <Divider />
                  </>
                ))}
              <Box
                sx={{
                  position: "fixed",
                  bottom: 16,
                  right: 16,
                  mb: 3,
                }}
              >
                <Tooltip title="Guardar">
                  <Fab
                    color="success"
                    aria-label="add"
                    sx={{ mr: 1 }}
                    type="submit"
                    disabled={
                      !(
                        seleccion &&
                        seleccion.every(
                          (item) => !!item.opcion || item.opcion === 0
                        )
                      ) ||
                      disBtn 
                    //   || (moment(new Date).day() < serverDate.dia )

                    }
                  >
                    <SaveIcon />
                  </Fab>
                </Tooltip>
              </Box>
            </form>
          ) : null}
        </Container>
      )}
      <br />
    </div>
  );
}

export default SeleccionExcepcional;
