import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Notify from "../../../../../components/Notify"
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

function DialogConfirmDir(props) {
  const [disBtn, setDisBtn] = useState();

  const handleClose = () => {
    props.setOpen(false);
    props.setNotify({ open: false });
  };

  return (
    <div>
      {props.notify.open && (
        <Notify
          open={props.notify.open}
          severity={props.notify.severity}
          mensaje={props.notify.mensaje}
          handleClose={props.atras ? props.atras : handleClose}
          color={props.notify.color}
        />
      )}
      <Dialog open={props.open} onClose={handleClose}>
        <DialogTitle borderTop={6} borderColor={props.color}></DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={2.5}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={3}>
                  <Avatar sx={{ bgcolor: props.color, width: 80, height: 80 }}>
                    {props.icon}
                  </Avatar>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={9.5}>
              <Grid container direction="column">
                <Grid item>
                  <Typography
                    variant="body1"
                    color="#2E4053"
                    gutterBottom
                    style={{fontSize:16}}
                  >
                    {props.title}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" color="textSecondary" style={{fontSize:14}}>
                    {props.subtitle}
                  </Typography>
                </Grid>
                <Grid item>
                    <ul>
                      {props.items.map((elemento, index) => (
                        <>
                          <li key={index}>
                            <Typography variant="body1" color="initial" sx={{fontSize:14}}>
                              {" "}
                              Dirección de entrega {elemento.index + 1}{" "}
                            </Typography>
                            <ul>
                              {Object.keys(elemento).map(
                                (key, innerIndex) =>
                                  key !== "index" &&
                                  elemento[key] && (
                                    <li style={{ fontSize: 14 }} key={innerIndex}>{key}</li>
                                  )
                              )}
                            </ul>
                          </li>
                        </>
                      ))}
                    </ul>
                </Grid>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  style={{ marginTop: 8, fontSize:12 }}
                >
                  {props.confirmText}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        {/* <DialogActions>
          <Grid container spacing={0} justifyContent="space-between">
            <Button
              onClick={handleClose}
              disabled={disBtn}
              color="error"
              variant="contained"
            >
              Volver
            </Button>

            <Button
              variant="contained"
              disabled={disBtn}
              autoFocus
              color="success"
              onClick={(e) => {
                props.guardar(e);
                setDisBtn(true);
              }}
            >
              {props.btnText ?? "Guardar"}
            </Button>
          </Grid>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}

export default DialogConfirmDir;
