import React, { useEffect, useState, useMemo } from 'react';
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { Container, Typography, Box, MenuItem, Divider, ListItemIcon, Grid, } from '@mui/material';
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import AssignmentIcon from '@mui/icons-material/Assignment';
import DeleteIcon from '@mui/icons-material/Delete';
import PreviewIcon from '@mui/icons-material/Preview';
import CheckIcon from "@material-ui/icons/Check";
import moment from 'moment';
import Notify from '../../../Notify';
import { getData, putData } from '../../../../services/library';
import DatosOrden from '../../AutorizacionCompras/DatosOrden';
import Openpdf from '../../OpenPdf';
import ConfirmDialog from '../../../Dialogs/ConfirmDialog';
import DialogConTextField from '../../../Dialogs/DialogConTextField';
import { logEventos } from '../../../../services/services';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

export default function OrdenesCompra(props) {
    const globalTheme = useTheme();
    const [datosOrden, setDatosOrden] = useState({})
    const [fecha, setFecha] = useState(moment(new Date()).format("YYYYY-MM-DD"));
    const [ordenesCompra, setOrdenesCompra] = useState(null);
    const [tabla, setTabla] = useState(true);
    const [aprobar, setAprobar] = useState(false);
    const [rechazar, setRechazar] = useState(false);
    const [open, setOpen] = useState(false);
    const [openpdf, setOpenpdf] = useState(false);
    const iconCheck = <CheckIcon sx={{ fontSize: 40 }} />;
    const iconRemove = <RemoveCircleOutlineIcon sx={{ fontSize: 40 }} />;
    const [observaciones, setObservaciones] = useState("");
    const [notificacion, setNotificacion] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
    });

    const obtenerOrdenesCompra = () => {
        getData('/ordencompra/generadas', setOrdenesCompra)
    };

    useEffect(() => {
        obtenerOrdenesCompra()
    }, [tabla]);

    const handleopenpdf = (datos) => {
        setOpenpdf(true)
        setDatosOrden(datos)
        setOpen(false)
        setTabla(false)
        props.tab(false)
    };

    const columns = useMemo(() =>
        [{ header: '#', accessorKey: 'idordencompra', size: 90 },
        { header: 'Proveedor', accessorFn: (oc) => oc.nombre_fantasia ? oc.nombre_fantasia : oc.razon_social },
        { header: 'Total', accessorKey: 'total', size: 120 },
        // { header: 'Pago Anticipado', accessorKey: 'pago_anticipado', size: 120 },
        { header: 'Sucursal', accessorKey: 'nombre_sucursal' },
        { header: 'Entrega', accessorFn: (oc) => moment(oc.fecha_entrega).format("DD-MM-YYYY"), size: 120, type: "date", format: "YYYYY-MM-DD" },
        { header: 'Estado', accessorKey: 'valor_estado' }
        ], []);

    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: globalTheme.palette.mode,
                    primary: globalTheme.palette.primary, //primary color for the table
                    info: {
                        main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            globalTheme.palette.mode === "light"
                                ? "rgb(234, 239, 241)"
                                : "#000", //pure black table in dark mode for fun
                    },
                },
                typography: {
                    button: {
                        textTransform: "none", //customize typography styles for all buttons in table by default
                        fontSize: "1rem",
                    },
                },
                components: {
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                fontSize: "0.8rem", //larger tooltip font size                                
                            },
                        },
                    },
                },

            }), [globalTheme]);

    const muiSearchTextFieldProps = {
        placeholder: "Buscar..",
        sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
        variant: "outlined",
        color: "success",
        size: "small",
    };

    const tableHeadCellProps = {
        align: "center",
        sx: {
            fontSize: 16,
            color: "white",
            fontWeight: "normal",
            // paddingTop: 1,
            // paddingBottom: 1,
            backgroundColor: "#2471A3",
            borderColor: "black",
        },
    };

    const initialState = {
        density: "compact",
        showGlobalFilter: true,
        pagination: { pageIndex: 0, pageSize: 10 }
    };

    const rowBg = {
        81: "#abebc6",
        102: "#ec7063",
    };

    const handleAutorizar = (rowData) => {
        setDatosOrden(rowData)
        setAprobar(true)
    };

    const handleRechazar = (rowData) => {
        setDatosOrden(rowData)
        setRechazar(true)
    };

    async function autorizarOrden(e, row) {
        // setdisbtn(true)
        await putData(e,
            '/ordencompra/' + datosOrden.idordencompra,
            {
                id_estado: 81,
                fecha_autorizado: fecha && moment(fecha).format("YYYY-MM-DD")
            }
            , setNotificacion
        )
        setAprobar(false)
        obtenerOrdenesCompra()
    };

    const handleOpenModal = (row) => {
        setDatosOrden(row)
        setOpen(true)
    };

    async function rechazarOrden(e, row) {
        await putData(e,
            '/ordencompra/' + datosOrden.idordencompra,
            {
                id_estado: 102,
                observacion_baja: observaciones
            }
            , setNotificacion
        )
        logEventos("Rechazar orden compra", "Orden compra", "Orden compra", localStorage.getItem("user"))
    }

    return (
        <div>
            <Container maxWidth="xl" mt={1} mb={2} >
                {ordenesCompra && tabla ? (
                    <Box mt={2} boxShadow={3}>
                        <ThemeProvider theme={tableTheme}>
                            <MaterialReactTable
                                enableColumnResizing
                                columns={columns}
                                data={ordenesCompra}
                                enableRowActions
                                initialState={initialState}
                                positionActionsColumn="last"
                                muiTableHeadCellProps={tableHeadCellProps}
                                muiTableBodyCellProps={({ row }) => ({
                                    align: "left",
                                    sx: {
                                        backgroundColor:
                                            rowBg[row.original.id_estado] ?? "#fff",
                                        color: "black",
                                        borderColor: "black",
                                    },
                                })}
                                enableColumnActions={false}
                                enableDensityToggle={false}
                                muiSearchTextFieldProps={muiSearchTextFieldProps}
                                renderTopToolbarCustomActions={() => (
                                    <Typography variant="poster" fontSize={26}>
                                        <AssignmentIcon sx={{ size: 19 }} ></AssignmentIcon>
                                        Ordenes
                                    </Typography>
                                )}
                                layoutMode="grid"
                                localization={MRT_Localization_ES}
                                enableColumnDragging={false}
                                renderRowActionMenuItems={({ closeMenu, row }) => [

                                    <MenuItem
                                        key={1}
                                        disabled={(row.original.id_estado !== 89)}
                                        onClick={() => {
                                            handleAutorizar(row.original)
                                            closeMenu();
                                        }}
                                        sx={{ m: 0 }}>
                                        <ListItemIcon>
                                            <CheckIcon color="primary" />
                                        </ListItemIcon>
                                        Autorizar orden
                                    </MenuItem>,
                                    <Divider />,
                                    <MenuItem
                                        key={2}
                                        disabled={(row.original.estado === 102)}
                                        onClick={() => {
                                            handleRechazar(row.original)
                                            closeMenu();
                                        }}
                                        sx={{ m: 0 }}>
                                        <ListItemIcon>
                                            <DeleteIcon color="error" />
                                        </ListItemIcon>
                                        Rechazar orden
                                    </MenuItem>,
                                    <Divider />,
                                    <MenuItem
                                        key={3}
                                        onClick={() => {
                                            handleopenpdf(row.original)
                                            closeMenu();
                                        }}
                                        sx={{ m: 0 }}>
                                        <ListItemIcon>
                                            <PreviewIcon color="secondary" />
                                        </ListItemIcon>
                                        Visualizar
                                    </MenuItem>


                                ]}
                                enableColumnFilters={false}
                                enableHiding={false}
                                positionExpandColumn='first'
                                enableExpandAll={false}
                                enableMultiRowSelection={false}
                                enableBottomToolbar
                                enablePagination
                                muiToolbarAlertBannerProps={false}
                                muiTableBodyRowProps={({ row }) => ({
                                    onClick: () => {
                                        row.getToggleSelectedHandler()
                                        handleOpenModal(row.original)
                                    },
                                    sx: {
                                        cursor: 'pointer'
                                    }
                                })}
                            />
                        </ThemeProvider>
                    </Box>
                ) : null}

                <Grid key={datosOrden.idordencompra}>
                    <br />
                    {open ? <DatosOrden datos={datosOrden} setDatosreq={setDatosOrden} anchor={setOpen} /> : null}
                </Grid>

            </Container>
            {openpdf ? (
                <Openpdf
                    uuid={datosOrden.uuid_ordencompra}
                    open={setOpenpdf} tabla={setTabla}
                    datos={datosOrden}
                    tab={props.tab}
                />)
                : null}

            {aprobar ?
                <ConfirmDialog
                    title={"Aprobar orden de compra"}
                    subtitle={"¿Desea aprobar la orden de compra ?"}
                    open={aprobar}
                    setOpen={setAprobar}
                    notify={notificacion}
                    setNotify={setNotificacion}
                    btnText={"Aprobar"}
                    guardar={autorizarOrden}
                    color={"#2ECC71"}
                    icon={iconCheck}
                />
                : null}

            {notificacion && notificacion.open ? (
                <Notify
                    mensaje={notificacion.mensaje}
                    open={notificacion.open}
                    color={notificacion.color}
                    handleClose={() => setAprobar(false)}
                    severity={notificacion.severity}
                />
            ) : null}

            {rechazar ?
                <DialogConTextField
                    title={"Rechazar Orden de compra "}
                    open={rechazar}
                    setOpen={setRechazar}
                    notify={notificacion}
                    setNotify={setNotificacion}
                    btnText={"Desaprobar"}
                    guardar={rechazarOrden}
                    color={"#f53649"}
                    subtitle={`¿Está seguro que desea rechazar la orden de compra N° ${datosOrden.idordencompra}?`}
                    value={observaciones}
                    setValue={setObservaciones}
                    icon={iconRemove}
                />
                : null}

        </div>
    )
}

//========================================================================
/*----- Component --------------------------------------------------------/
// Developer     : Candela Grosso   
// Creation Date : 06/12/2023
// Version       : 1.0  
// Description   :  Con este componente el usuario es capaz de :
    - VISUALIZAR UN LISTADO DE LAS ORDENES DE COMPRA
    - HACER CLICK Y VER LOS DATOS DE LA ORDEN
    - APROBAR, RECHAZAR UNA ORDEN DE COMPRA
    - VISUALIZAR FORMATO PDF PARA IMPRIMIR O GUARDAR LA ORDEN DE COMPRA
//------Changes --------------------------------------------------------------------- -------------//
// Change Date   :
// Change by     :
// Change description: 
//========================================================================*/