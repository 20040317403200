import React, { useRef, useState, useEffect } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import { TextField } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";
import Box from "@mui/material/Box";
// import { UrlApi } from "../../src/services/apirest";
import {UrlApi} from "../../../../../../services/apirest"
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import { Button } from "@mui/material";

const containerStyle = {
  width: "480px",
  height: "330px",
};

function MapaDomPedido(props) {
    const [coordinates, setCoordinates] = useState({
        latitud: "",
        longitud: "",
        verificada: null,
        direccion_entrega: "",
      });
    
      const getCoordinates = () => {
        fetch(UrlApi + "/direccionesentrega/latlong/" + props.idDireccion)
          .then((data) => data.json())
          .then((data) => setCoordinates(data[0]));
      };
    
      useEffect(() => {
        getCoordinates();
      }, []);
    
      const center = {
        lat: coordinates && coordinates.latitud * 1,
        lng: coordinates && coordinates.longitud * 1,
      };
    
      const inputRef = useRef();
    
      const handlecambiolugar = () => {
        const [places] = inputRef.current.getPlaces();
        if (places) {
          setCoordinates({
            latitud: places.geometry.location.lat(),
            longitud: places.geometry.location.lng(),
          });
        }
      };
    
      async function updateAdress(e) {
        e.preventDefault();
        const response = await fetch(
          UrlApi + "/direccionesentrega/" + props.idDireccion,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              latitud: coordinates.latitud,
              longitud: coordinates.longitud,
              verificada: 1,
              // direccion_entrega: coordinates.direccion_entrega,
            }),
          }
        );
        if (response.ok === false) {
          const error = response && response.json();
          alert("Error al guardar. " + error);
        } else {
          try {
            const json = await response.json();
            if (json.message) {
              alert("Ubicación guardada exitosamente!");
              props.handleClose();
            } else if (json.sqlMessage) {
              alert("Error al guardar.");
            } else {
              alert("Error desconocido.");
            }
          } catch (error) {
            if (error.toString().includes("is not valid JSON")) {
              alert("Ubicación guardada exitosamente!");
              props.handleClose();
            } else {
              alert("Error desconocido.");
            }
          }
        }
      }
  return (
    <div>
      <Box p={2}>
        <LoadScript
          googleMapsApiKey={process.env.REACT_APP_Google_maps_key}
          libraries={["places"]}
        >
          <StandaloneSearchBox
            onLoad={(ref) => (inputRef.current = ref)}
            onPlacesChanged={handlecambiolugar}
          >
            <Box mb={1}>
            <TextField
                  focused
                  label="Dirección"
                  fullWidth
                  placeholder="Ingrese direccion"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <TravelExploreIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
            </Box>
            {/* <Grid container direction="row" mb={2} alignItems="center" justifyContent="center">
              <Grid item xs={11}>
                <TextField
                  focused
                  label="Dirección"
                  fullWidth
                  placeholder="Ingrese direccion"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <TravelExploreIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid> */}
          </StandaloneSearchBox>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={15}
          >
            <Marker
              position={center}
              draggable={true}
              onDragEnd={(p) => {
                setCoordinates({
                  latitud: p.latLng.lat(),
                  longitud: p.latLng.lng(),
                });
                // getAddressFromCoordinates(p.latLng.lat(), p.latLng.lng())
              }}
            />
          </GoogleMap>
          <Grid container direction="row" justifyContent="space-between" mt={2}>
            <Button
              variant="outlined"
              color="error"
              onClick={props.handleClose}
            >
              Cerrar
            </Button>
            <Button
              color="success"
              variant="contained"
              onClick={(e) => updateAdress(e)}
            >
              Guardar Cambios
            </Button>
          </Grid>
        </LoadScript>
      </Box>
    
    </div>
  )
}

export default MapaDomPedido