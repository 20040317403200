import Navbar from "../Navbar";
import React, { useEffect, useState, useMemo } from "react";
import { UrlApi } from "../../services/apirest";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Typography,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import moment from "moment";

// EXPORTAR DATOS
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv";

function Romaneo_rehabilitados_30() {
  const [romaneo_envases, setRomaneo_envases] = useState();
  const [datosExportados, setDatosExportados] = useState({
    peso: 10,
    fecha: null,
    firma: null,
  });

  // console.log("DATOS NO SE QUE ", datosRomaneoEnvases);
  const [tabla, setTabla] = useState(true);

  const [exportarEnvases, setExportarEnvases] = useState(false);
  const handleDesactivarExportar = () => {
    setExportarEnvases(false);
  };

  const obtenerRomaneoEnvases = () => {
    fetch(UrlApi + "/romaneo_envases/rehabilitados/30")
      .then((data) => data.json())
      .then((data) => setRomaneo_envases(data));
  };

  //funcion que espera los cambios de la funcion de obtener a los romaneos
  useEffect(() => {
    obtenerRomaneoEnvases();
  }, []);

  console.log("ENVASES:", romaneo_envases);
  //*************************************************************************************************************************************/

  // OBTENER FECHA ACTUAL
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);

  //*************************************************************************************************************************************/

  const columns = useMemo(
    () => [
      { header: "N°.Envase", accessorKey: "nro_envase", size: 80 },
      { header: "Leyenda", accessorKey: "leyenda", size: 100 },
      {
        header: "Fabricante",
        accessorKey: "descripcion_fabricante",
        size: 110,
      },
      { header: "Peso", accessorKey: "peso", size: 72 },
      { header: "Lote", accessorKey: "lote", size: 47 },
      { header: "Fe.Fabricación", accessorKey: "fecha_fabricacion", size: 80 },
      { header: "N° Placa", accessorKey: "nro_placa", size: 80 },
      {
        header: "F.Habilitación",
        accessorKey: "fecha_habilitacion",
        size: 108,
        type: "date",
        valueFormatter: (params) => moment(params?.value).format("DD-MM-YYYY"),
      },
      {
        header: "Tipo de Envase",
        accessorKey: "descripcion_tipo_envase",
        size: 150,
      },
      { header: "Firma", accessorKey: "firma", size: 135 },
    ],
    []
  );

  const muiSearchTextFieldProps = {
    // para el buscar
    placeholder: "Buscar...",
    sx: { minWidth: "18rem" },
    variant: "outlined",
    color: "success",
    size: "small",
  };

  // ---------------------------------------------------------------

  // const [titulo, setTitulo] = useState("METALGAR SRL" + datosExportados.fecha)
  const [titulo, setTitulo] = useState(null);

  const obtenerTitulo = () => {
    setTitulo(
      "METALGAR S.R.L. - " +
        "FECHA: " +
        moment(datosExportados.fecha).format("DD-MM-YYYY") +
        " - " +
        "ENVASES DE " +
        datosExportados.peso +
        " KG " +
        "REPARADOS INTEGRALMENTE DE " +
        datosExportados.firma
    );
  };

  useEffect(() => {
    obtenerTitulo();
    console.log("SE OBTUVO EL TITULO - TITULO OBTENIOD:", titulo);
  }, [datosExportados]);

  console.log("TITULO:", titulo);

  // EXPORTAR DATOS
  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    showTitle: true,
    title: titulo,
    filename: "Rehabilitado (10 a 30 KG)",
    headers: columns.map((c) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(romaneo_envases);
  };

  //*************************************************************************************************************************************/

  // ESTILO PARA LA TABLA

  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)" //background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //override to make tooltip font size larger
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const displayColumnDefOptions = {
    "mrt-row-actions": {
      muiTableHeadCellProps: {
        align: "center",
        sx: {
          fontSize: 14,
          fontStyle: "italic",
        },
      },
      size: 100,
    },
  };

  const initialState = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 5 },
    grouping: ["fecha_habilitacion", "firma", "peso"],
    sorting: [
      {
        id: "fecha_habilitacion", //fija que la fecha de reparacion sea desde hoy para atras
        desc: true,
      },
    ],
  };

  const almacenar = (e) => {
    setDatosExportados({
      ...datosExportados,
      [e.target.name]: e.target.value,
    });
  };

  // console.log("DATOS EXPORTADOS:", datosExportados);

  return (
    <>
      <Navbar />
      <Container maxWidth="xl" mt={1}>
        {romaneo_envases && tabla ? (
          <Box mt={2} mb={4}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
                enableGrouping // para permitir agrupar x estado x ej
                displayColumnDefOptions={displayColumnDefOptions}
                layoutMode="grid"
                muiTableHeadCellProps={{
                  align: "left", // los titulos de la tabla
                }}
                muiTableBodyCellProps={{
                  align: "left", //el contenido de la tabla
                }}
                enableRowVirtualization
                enableRowSelection
                enableSelectAll={false}
                enableSubRowSelection={true}
                getRowId={(row) => row.userId} //give each row a more useful id
                enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
                enableColumnFilters={false} //desactiva filtros x  columna
                enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
                enableDensityToggle={false} //
                localization={MRT_Localization_ES} //idioma
                enableColumnDragging={false} //para arrastrar columnas
                initialState={initialState}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                columns={columns}
                data={romaneo_envases} //con lo q la completas (esto cambia siempre)
                enableStickyFooter
                positionActionsColumn="last" // posicion de la columna de acciones al final
                // ACCIONES
                renderTopToolbarCustomActions={({ table }) => {
                  const handleExportarEnvases = () => {
                    setExportarEnvases(true);
                    datosExportados.peso = null;
                    datosExportados.fecha = date;
                    datosExportados.firma = null;
                  };

                  return (
                    <>
                      <Typography
                        variant="poster"
                        fontSize={26}
                        color={"#2b8305"}
                      >
                        <b>Envases Rehabilitados (10 a 30 Kg)</b>
                      </Typography>
                      <Button
                        style={{ marginLeft: "15px" }}
                        onClick={handleExportarEnvases}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                        size="small"
                      >
                        Exportar Filas Seleccionadas
                      </Button>

                      <Dialog open={exportarEnvases} fullWidth maxWidth={"lg"}>
                        <DialogTitle marginLeft={3}>
                          <b>{"EXPORTAR ENVASES - ENCABEZADO"}</b>
                        </DialogTitle>

                        <DialogContent>
                          <Container maxWidth="xl">
                            <Grid container mt={1} mb={-1}>
                              <Grid item mt={1}>
                                <DialogContentText>
                                  <b>FECHA:</b>
                                </DialogContentText>
                              </Grid>

                              <Grid item xs={2} mb={2} ml={2}>
                                <TextField
                                  fullWidth
                                  focused
                                  required
                                  size="small"
                                  type="date"
                                  placeholder="fecha"
                                  variant="outlined"
                                  name="fecha"
                                  value={moment(datosExportados.fecha).format(
                                    "YYYY-MM-DD"
                                  )}
                                  onChange={almacenar}
                                />
                              </Grid>

                              <Grid item ml={2} mt={1}>
                                <DialogContentText>
                                  <b>ENVASES DE</b>{" "}
                                </DialogContentText>
                              </Grid>

                              <Grid item ml={1} xs={1.2}>
                                <TextField
                                  fullWidth
                                  placeholder="-"
                                  label="Peso"
                                  variant="outlined"
                                  size="small"
                                  focused
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  InputProps={{
                                    maxLength: 2,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        KG
                                      </InputAdornment>
                                    ),
                                  }}
                                  style={{ maxWidth: "100px" }}
                                  name="peso"
                                  value={datosExportados.peso}
                                  onChange={almacenar}
                                />
                              </Grid>

                              <Grid item ml={1} mt={1}>
                                <DialogContentText>
                                  <b>REPARADOS INTEGRALMENTE DE:</b>
                                </DialogContentText>
                              </Grid>

                              <Grid item ml={2} xs={3.5}>
                                <TextField
                                  fullWidth
                                  placeholder="-"
                                  variant="outlined"
                                  size="small"
                                  focused
                                  InputProps={{
                                    maxLength: 24,
                                  }}
                                  label="Firma"
                                  name="firma"
                                  value={datosExportados.firma}
                                  onChange={almacenar}
                                />
                              </Grid>
                            </Grid>
                          </Container>
                        </DialogContent>

                        <Stack direction="row">
                          <Grid
                            container
                            ml={5}
                            mb={3}
                            direction={{ xs: "column", sm: "row" }}
                          >
                            <Grid item xs={8}>
                              <Button
                                variant="contained"
                                color="error"
                                onClick={handleDesactivarExportar}
                              >
                                Cancelar
                              </Button>
                            </Grid>

                            <Grid item xs={4}>
                              <Button
                                onClick={() =>
                                  handleExportRows(
                                    table.getSelectedRowModel().rows,
                                    console.log(
                                      "CSV DATOS EXPORTADOS:",
                                      datosExportados
                                    ),
                                    console.log(
                                      "CSV DATOS EXPORTADOS FECHA:",
                                      datosExportados.fecha
                                    ),
                                    console.log(
                                      "CSV DATOS EXPORTADOS FIRMA:",
                                      datosExportados.firma
                                    ),
                                    console.log(
                                      "CSV DATOS EXPORTADOS PESO:",
                                      datosExportados.peso
                                    ),
                                    obtenerTitulo(),
                                    console.log("CSV DATOS EXPORTADOS:", titulo)
                                  )
                                }
                                startIcon={<FileDownloadIcon />}
                                variant="contained"
                                disabled={
                                  !datosExportados.firma ||
                                  !datosExportados.peso
                                }
                              >
                                Exportar Filas Seleccionadas
                              </Button>
                            </Grid>
                          </Grid>
                        </Stack>
                      </Dialog>
                    </>
                  );
                }}
              />
            </ThemeProvider>
          </Box>
        ) : null}
      </Container>
    </>
  );
}

export default Romaneo_rehabilitados_30;
