import Navbar from "../Navbar";
import React, { useEffect, useState, useMemo } from "react";
import { UrlApi } from "../../services/apirest";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Box,
  Typography,
  Stack,
  Grid,
  Tooltip,
  TextField,
} from "@mui/material";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";

// ICONOS
import AddBoxIcon from "@mui/icons-material/AddBox";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";

//Notification
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Romaneo_tipos_envases() {
  const [romaneo_tipos_envases, setRomaneo_tipos_envases] = useState();
  const [datosRomaneoTiposEnvases, setDatosRomaneoTiposEnvases] = useState({});
  const [tabla, setTabla] = useState(true);
  const [update, setUpdate] = useState(false);
  const [disBtn, setDisBtn] = useState(false);

  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");

  //funcion para obtener a los tipos de envases, pasarlos a json y guardarlos en data
  const obtenerRomaneoTiposEnvases = () => {
    fetch(UrlApi + "/romaneo_tipos_envases")
      .then((data) => data.json())
      .then((data) => setRomaneo_tipos_envases(data));
  };

  useEffect(() => {
    obtenerRomaneoTiposEnvases();
  }, [tabla]);

  // FUNCION QUE SE EJECUTA CADA VEZ QUE HAY UN CAMBIO EN LA BASE DE DATOS
  // SIRVE PARA QUE LA TABLA MOSTRADA SE ACTUALICE EN TIEMPO REAL
  useEffect(() => {
    if (update) obtenerRomaneoTiposEnvases();
  }, [update]);

  //*************************************************************************************************************************************/

  // DIALOG PARA CREAR LOS DATOS
  const [openCrear, setOpenCrear] = React.useState(false);

  // USANDO ROW TOMO LOS DATOS DE ESA FILA
  const handleClickOpenCrear = (row) => {
    setOpenCrear(true);
    // AL ABRIR EL DIALOG El TEXTFIELDS SE VACIAN
    datosRomaneoTiposEnvases.descripcion_tipo_envase = "";
    datosRomaneoTiposEnvases.peso = "";
  };

  const handleCloseCrear = () => {
    setOpenCrear(false);
  };

  //*************************************************************************************************************************************/

  // DIALOG PARA EDITAR LOS DATOS
  const [openEditar, setOpenEditar] = React.useState(false);

  // USANDO ROW TOMO LOS DATOS DE ESA FILA
  const handleClickOpenEditar = (row) => {
    setOpenEditar(true);
    setDatosRomaneoTiposEnvases(row);
  };

  const handleCloseEditar = (row) => {
    setOpenEditar(false);
    setDatosRomaneoTiposEnvases(row);
  };

  //*************************************************************************************************************************************/

  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
    setUpdate(false);
    setOpenCrear(false);
    setOpenEditar(false);
    setOpenDialogOnOff(false);
    setDisBtn(false);
  };

  //*************************************************************************************************************************************/

  const columns = useMemo(
    () => [
      {
        header: "Descripción",
        accessorKey: "descripcion_tipo_envase",
        size: 200,
      },
      { header: "Peso (Kg)", accessorKey: "peso", size: 200 },
    ],
    []
  );

  const muiSearchTextFieldProps = {
    // para el buscar
    placeholder: "Buscar...",
    sx: { minWidth: "18rem" },
    variant: "outlined",
    color: "success",
    size: "small",
  };

  //*************************************************************************************************************************************/

  //Funcion para guardar al crear
  const guardarCrear = async (e) => {
    console.log("Estoy por guardar");
    setDisBtn(true);
    e.preventDefault();
    await fetch(UrlApi + "/romaneo_tipos_envases", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_tipo: datosRomaneoTiposEnvases.id_tipo,
        descripcion_tipo_envase:
          datosRomaneoTiposEnvases.descripcion_tipo_envase,
        peso: datosRomaneoTiposEnvases.peso, //respeta la tabla
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          setUpdate(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El tipo de envase se guardó exitosamente !");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el tipo de envase");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  console.log(datosRomaneoTiposEnvases);

  //*************************************************************************************************************************************/

  //Funcion para guardar AL EDITAR
  const guardarEditar = async (e) => {
    console.log("Estoy por guardar");
    setDisBtn(true);
    e.preventDefault();
    await fetch(
      UrlApi + "/romaneo_tipos_envases/" + datosRomaneoTiposEnvases.id_tipo,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          descripcion_tipo_envase:
            datosRomaneoTiposEnvases.descripcion_tipo_envase,
          peso: datosRomaneoTiposEnvases.peso,
        }),
      }
    )
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          setUpdate(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El tipo de envase se guardó exitosamente !");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el tipo de envase");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    console.log(datosRomaneoTiposEnvases);
  };

   //**********************************************************************************************/

  // DIALOG PARA ACTIVAR/DESACTIVAR LA RELACIÓN
  const [openDialogOnOff, setOpenDialogOnOff] = React.useState(false);

  const handleCloseDialogOnOff = () => {
    setOpenDialogOnOff(!openDialogOnOff);
  };

  const dialogActivar = (row) => {
    setDatosRomaneoTiposEnvases(row);
    setOpenDialogOnOff(true);
    setTabla(true);
  };
 
    // FUNCIONES PARA GUARDAR:
  // ACTIVAR TIPO DE ENVASE
  const guardarActivo = async (e) => {
    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(
      UrlApi + "/romaneo_tipos_envases/" + datosRomaneoTiposEnvases.id_tipo,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          estado: 1,
        }),
      }
    )
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El tipo de envase se guardó exitosamente !");
          setUpdate(true);
          //console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el tipo de envase");
          //console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  // DESACTIVAR TIPO DE ENVASE
  const guardarInactivo = async (e) => {
    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(
      UrlApi + "/romaneo_tipos_envases/" + datosRomaneoTiposEnvases.id_tipo,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          estado: 2,
        }),
      }
    )
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El tipo de envase se guardó exitosamente !");
          setUpdate(true);
          //console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el tipo de envase");
          //console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  //**********************************************************************************************/

  const almacenar = (e) => {
    setDatosRomaneoTiposEnvases({
      ...datosRomaneoTiposEnvases,
      [e.target.name]: e.target.value,
    });
  };

  //**********************************************************************************************/

  // ESTILO PARA LA TABLA

  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)" //background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //override to make tooltip font size larger
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const displayColumnDefOptions = {
    "mrt-row-actions": {
      muiTableHeadCellProps: {
        align: "center",
        sx: {
          fontSize: 14,
          fontStyle: "italic",
        },
      },
      size: 100,
    },
  };

  const initialState = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
  };

  return (
    <>
      <Navbar />
      <Container maxWidth="xl" mt={1}>
        {romaneo_tipos_envases && tabla ? (
          <Box mt={2}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableExpandAll={false}
                enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
                // enableColumnResizing
                enableGrouping // para permitir agrupar x estado x ej
                displayColumnDefOptions={displayColumnDefOptions}
                layoutMode="grid"
                muiTableHeadCellProps={{
                  align: "left", // los titulos de la tabla
                }}
                muiTableBodyCellProps={({ row }) => ({
                  align: "left", //el contenido de la tabla
                  sx: {
                    backgroundColor:
                      row.original.estado === 1 ? "#66FF66" : "#FF5050",
                    color: "black",
                    borderColor: "black",
                    //COLOR AL PASAR EL MOUSE POR ENCIMA
                    "&:hover": {
                      backgroundColor:
                        row.original.estado === 1 ? "#3ad63a" : "#f22929",
                    },
                    "& .MuiTableCell-root:first-child": {
                      border: "none",
                    },
                  },
                })}
                enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
                enableColumnFilters={false} //desactiva filtros x  columna
                enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
                enableDensityToggle={false} //
                localization={MRT_Localization_ES} //idioma
                enableColumnDragging={false} //para arrastrar columnas
                initialState={initialState}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                columns={columns}
                data={romaneo_tipos_envases} //con lo q la completas (esto cambia siempre)
                enableStickyFooter
                enableEditing
                positionActionsColumn="last" // posicion de la columna de acciones al final
                
                // ACCIONES
                renderToolbarInternalActions={(
                  { table } // boton para nuevo , al lado del buscar
                ) => (
                  <>
                    <Tooltip
                      arrow
                      placement="right"
                      title="Nuevo Tipo de Envase"
                    >
                      <IconButton color="primary">
                        <AddBoxIcon
                          onClick={() => {
                            handleClickOpenCrear();
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                    <MRT_FullScreenToggleButton table={table} />
                  </>
                )}
                renderTopToolbarCustomActions={() => (
                  <>
                    <Typography variant="poster" fontSize={26}>
                      Tipos de Envases
                    </Typography>
                  </>
                )}
                renderRowActions={(
                  { row } //las acciones del costado editar y ver
                ) => (
                  <div>
                    <Tooltip arrow placement="left" title="Editar Tipo de Envase">
                      <IconButton
                        onClick={() => handleClickOpenEditar(row.original)}
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                arrow
                placement="left"
                title={
                  row.original.estado === 1
                    ? "Anular Envase"
                    : "Activar Envase"
                }
              >
                <IconButton onClick={() => dialogActivar(row.original)}>
                  <BlockIcon
                    sx={{
                      color: row.original.estado === 1 ? "red" : "lightgreen",
                    }}
                  />
                </IconButton>
              </Tooltip>
                  </div>
                )}
              />
            </ThemeProvider>
          </Box>
        ) : null}
      </Container>

      {/* AGREGAR TIPO DE ENVASE */}
      <Dialog
        open={openCrear}
        onClose={handleCloseCrear}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle marginLeft={3}>
          <b>{"Agregar Tipo de Envase"}</b>
        </DialogTitle>
        <DialogContent>
          <Container maxWidth="xl">
            <form onSubmit={guardarCrear}>
              <Grid container mt={2} mb={1} spacing={1}>
                <Grid item xs={9} mb={2}>
                  <TextField
                    label="Descripción"
                    name="descripcion_tipo_envase"
                    size="small"
                    placeholder="..."
                    inputProps={{
                      maxLength: 40,
                    }}
                    focused
                    required
                    fullWidth
                    value={datosRomaneoTiposEnvases.descripcion_tipo_envase}
                    onChange={almacenar}
                  />
                </Grid>

                <Grid item xs={3} mb={2}>
                  <TextField
                    label="Peso"
                    name="peso"
                    size="small"
                    placeholder="10"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    inputProps={{
                      maxLength: 2,
                    }}
                    focused
                    required
                    fullWidth
                    value={datosRomaneoTiposEnvases.peso}
                    onChange={almacenar}
                  />
                </Grid>
              </Grid>

              <Stack direction="row">
                <Grid container direction={{ xs: "column", sm: "row" }}>
                  <Grid item xs={9}>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleCloseCrear}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={disBtn}
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Stack>
            </form>
          </Container>
        </DialogContent>
      </Dialog>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>

      {/* EDITAR ITEM */}
      <Dialog
        open={openEditar}
        onClose={handleCloseEditar}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle marginLeft={3}>
          <b>{"Editar Tipo de Envase"}</b>
        </DialogTitle>
        <DialogContent>
          <Container maxWidth="xl">
            <form onSubmit={guardarEditar}>
              <Grid container mt={2} mb={1} spacing={1}>
                <Grid item xs={9} mb={2}>
                  <TextField
                    label="Descripción"
                    name="descripcion_tipo_envase"
                    size="small"
                    placeholder="..."
                    inputProps={{
                      maxLength: 40,
                    }}
                    focused
                    required
                    fullWidth
                    value={datosRomaneoTiposEnvases.descripcion_tipo_envase}
                    onChange={almacenar}
                  />
                </Grid>
                <Grid item xs={3} mb={2}>
                  <TextField
                    label="Peso"
                    name="peso"
                    size="small"
                    placeholder="10"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    inputProps={{
                      maxLength: 2,
                    }}
                    focused
                    required
                    fullWidth
                    value={datosRomaneoTiposEnvases.peso}
                    onChange={almacenar}
                  />
                </Grid>
              </Grid>

              <Stack direction="row">
                <Grid container direction={{ xs: "column", sm: "row" }}>
                  <Grid item xs={9}>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleCloseEditar}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={disBtn}
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Stack>
            </form>
          </Container>
        </DialogContent>
      </Dialog>

        {/* DIALOG PARA BLOQUEAR/DESBLOQUEAR LA RELACION */}
        <Dialog open={openDialogOnOff} onClose={handleCloseDialogOnOff}>
        <Box
          style={{
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: datosRomaneoTiposEnvases.estado === 1 ? "#e32712" : "#24ff1c",
            color: datosRomaneoTiposEnvases.estado === 1 ? "red" : "green",
          }}
        >
          <DialogTitle>
            <Typography align="center">
              <b>
                SE {datosRomaneoTiposEnvases.estado === 1 ? "BLOQUEARÁ" : "DESBLOQUEARÁ"}{" "}
                EL TIPO DE ENVASE:
              </b>
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              textAlign={"center"}
              style={{ marginBottom: "40px" }}
            >
              <Grid item textAlign={"center"} ml={2}>
                <br />
                <b>{datosRomaneoTiposEnvases.descripcion_tipo_envase}</b>
              </Grid>
            </DialogContentText>
            <DialogContentText
              textAlign={"center"}
              style={{ marginBottom: "-10px" }}
            >
              {"¿Confirma que quiere " +
                (datosRomaneoTiposEnvases.estado === 1 ? "bloquear" : "desbloquear") +
                " este tipo de envase?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Stack spacing={4} direction="row">
              <Grid item mr={16}>
                <Button
                  style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
                  variant="contained"
                  onClick={handleCloseDialogOnOff}
                >
                  Cancelar
                </Button>
              </Grid>
              {datosRomaneoTiposEnvases.estado === 1 ? (
                <Button
                  style={{ backgroundColor: "#fc7a23", color: "#ffffff" }}
                  onClick={guardarInactivo}
                  variant="contained"
                >
                  Bloquear
                </Button>
              ) : (
                <Button
                  style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                  onClick={guardarActivo}
                  variant="contained"
                >
                  Desbloquear
                </Button>
              )}
            </Stack>
          </DialogActions>
        </Box>
      </Dialog>

      {/* SNACKBAR QUE SE MOSTRARA AL GUARDAR LOS CAMBIOS */}
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}

export default Romaneo_tipos_envases;