import React, { useState, useRef } from "react";
import { Button, Dialog, Typography, IconButton } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import { lightBlue, blueGrey } from "@mui/material/colors";
import ReactToPrint from "react-to-print";

function Ficha_imprimir(props) {
  const [imprimirPdf, setImprimirPdf] = useState(props.fichaImprimir || false);
  const [datosArt, setDatosArt] = useState(props.datosArt);
  const [artSuc, setArtSuc] = useState(props.artSuc);
  const [tabla, setTabla] = useState(props.tabla);
  const [sucursal, setSucursal] = useState(props.sucursal);
  const [row, setRow] = useState(props.row);
  const componentRef = useRef();

  const datePdf = new Date();
  const datePdfFormat = datePdf.toLocaleDateString();
  const timePdfFormat = datePdf.toLocaleTimeString();

  const handleClose = () => {
    setImprimirPdf(false);
    if (props.onClose) {
      props.onClose();
    }
  };
  // console.log("row", row);
  const equipoItems = (row.equipo || "").split("--").map((item) => {
    // Divide por " X " para obtener cantidad y el resto del texto
    const [cantidad, resto] = item.trim().split(" X ");

    // Divide por "fecha de Entrega:" para obtener el texto antes y después de la fecha
    const [productoYDescripcion, fechaEntrega] = (resto || "").split(
      "fecha de Entrega:"
    );

    // Dentro de productoYDescripcion, divide por "descripción:" para obtener el producto y la descripción
    const [producto, descripcion] = (productoYDescripcion || "").split(
      "descripción:"
    );

    return {
      cantidad: cantidad?.trim() || "N/A",
      producto: producto?.trim() || "N/A",
      descripcion: descripcion?.trim() || "N/A", // Nueva propiedad para la descripción
      fechaEntrega: fechaEntrega?.trim() || "N/A", // Nueva propiedad para la fecha de entrega
    };
  });

  return (
    <>
      <Dialog fullScreen open={imprimirPdf} onClose={handleClose}>
        <AppBar
          sx={{ position: "relative" }}
          style={{ backgroundColor: "#0979b0" }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Ficha de equipamiento de {row.nombre_empleado}{" "}
              {row.apellido_empleado}
            </Typography>
            <ReactToPrint
              trigger={() => (
                <Button
                  autoFocus
                  style={{ backgroundColor: blueGrey[400], color: "white" }}
                  endIcon={<PrintIcon />}
                >
                  Imprimir
                </Button>
              )}
              content={() => componentRef.current}
            />
          </Toolbar>
        </AppBar>

        <div
          ref={componentRef}
          style={{
            // marginTop: "20px",
            margin: "20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* <Typography variant="h5" style={{ marginBottom: "10px", marginLeft: "20px", textAlign: "left" }}>
          {datePdfFormat} -- {timePdfFormat}
        </Typography> */}

          {/* <div 
        // style={{ 
        //   margin: "20px" 
        // }}
        > */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              border: "1px solid black",
              padding: "0",
            }}
          >
            <h1
              style={{
                textAlign: "left",
                fontSize: "12px",
                marginTop: "1px",
                marginLeft: "3px",
                // marginBottom: "1px",
                // backgroundColor: "black",
                marginBottom: "1px",
                color: "black",
                // padding: "2px" // Añadí padding para asegurar que el texto no esté pegado al borde
              }}
            >
              Nro: {row.id}
            </h1>
            <h1
              style={{
                textAlign: "right",
                fontSize: "12px",
                marginRight: "3px",
                marginBottom: "1px",
                marginTop: "1px",
                // marginBottom: "1px",
                // backgroundColor: "black",
                color: "black",
                // padding: "2px" // Añadí padding para asegurar que el texto no esté pegado al borde
              }}
            >
              Resolución 299/11, Anexo
            </h1>
          </div>
          <h1
            style={{
              textAlign: "center",
              fontSize: "20px",
              // marginBottom: "20px",
              backgroundColor: "black",
              color: "white",
              padding: "10px", // Añadí padding para asegurar que el texto no esté pegado al borde
              marginTop: "0px",
            }}
          >
            ENTREGA DE ROPA DE TRABAJO Y ELEMENTOS DE PROTECCIÓN PERSONAL
          </h1>

          {/* Encabezado estilo tabla */}
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              border: "1px solid black",
            }}
          >
            <tbody>
              <tr>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    fontSize: "12px",
                  }}
                  colSpan="3"
                >
                  Razón Social:
                  <strong style={{ fontSize: "14px", marginLeft: "5px" }}>
                    {row.nombre_empresa}
                  </strong>
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    fontSize: "12px",
                  }}
                >
                  C.U.I.T:
                  <strong style={{ fontSize: "14px", marginLeft: "5px" }}>
                    {row.cuit_empresa}
                  </strong>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    fontSize: "12px",
                  }}
                  colSpan="1"
                >
                  Dirección:
                  <strong style={{ fontSize: "14px", marginLeft: "5px" }}>
                    {row.nombre_sucursal}
                  </strong>
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    fontSize: "12px",
                  }}
                  colSpan="1"
                >
                  Localidad:
                  <strong style={{ fontSize: "14px", marginLeft: "5px" }}>
                    {row.ciudad}
                  </strong>
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    fontSize: "12px",
                  }}
                  colSpan="1"
                >
                  C.P.:
                  <strong style={{ fontSize: "14px", marginLeft: "5px" }}>
                    {row.cod_post}
                  </strong>
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    fontSize: "12px",
                  }}
                  colSpan="1"
                >
                  Provincia:
                  <strong style={{ fontSize: "14px", marginLeft: "5px" }}>
                    {row.provincia}
                  </strong>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    fontSize: "12px",
                  }}
                  colSpan="3"
                >
                  Nombre y Apellido del Trabajador:
                  <strong style={{ fontSize: "14px", marginLeft: "5px" }}>
                    {row.nro_legajo} - {row.nombre_empleado}{" "}
                    {row.apellido_empleado}
                  </strong>
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    fontSize: "12px",
                  }}
                  colSpan="1"
                >
                  D.N.I.:
                  <strong style={{ fontSize: "14px", marginLeft: "5px" }}>
                    {row.dni}
                  </strong>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    height: "50px",
                    verticalAlign: "top",
                    fontSize: "11px",
                  }}
                  colSpan="2"
                >
                  Descripción breve del puesto de trabajo:
                  <br />
                  <strong style={{ fontSize: "14px" }}>
                    {row.desc_trabajo}
                  </strong>
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    height: "50px",
                    verticalAlign: "top",
                    fontSize: "11px",
                  }}
                  colSpan="2"
                >
                  Elementos de protección personal, necesarios según el puesto
                  de trabajo:
                  <br />
                  <strong style={{ fontSize: "14px" }}>
                    {row.elementos_trabajo}
                  </strong>
                </td>
              </tr>
            </tbody>
          </table>

          {/* Elementos de protección */}

          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              marginBottom: "20px",
              marginTop: "20px",
              border: "1px solid black",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    width: "120px",
                    fontSize: "14px",
                  }}
                >
                  Producto
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    width: "200px",
                    fontSize: "14px",
                  }}
                >
                  Descripción
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    width: "80px",
                    fontSize: "14px",
                  }}
                >
                  Certificado <strong>SI/NO</strong>
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    width: "80px",
                    fontSize: "14px",
                  }}
                >
                  Cantidad
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    width: "140px",
                    fontSize: "14px",
                  }}
                >
                  Fecha de entrega
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    width: "150px",
                    fontSize: "14px",
                  }}
                >
                  Firma del trabajador
                </th>
              </tr>
            </thead>
            <tbody>
              {equipoItems.map((item, index) => (
                <tr key={index}>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "5px",
                      textAlign: "center",
                      fontSize: "14px",
                    }}
                  >
                    {item.producto}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "5px",
                      textAlign: "center",
                      fontSize: "14px",
                    }}
                  >
                    {item.descripcion}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "5px",
                      textAlign: "center",
                      fontSize: "14px",
                    }}
                  >
                    {" "}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "5px",
                      textAlign: "center",
                      fontSize: "14px",
                    }}
                  >
                    {item.cantidad}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "5px",
                      textAlign: "center",
                      fontSize: "14px",
                    }}
                  >
                    {item.fechaEntrega}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "5px",
                      textAlign: "center",
                      fontSize: "14px",
                    }}
                  ></td>
                </tr>
              ))}
            </tbody>
          </table>

          <p style={{ fontSize: "12px", marginBottom: "0px" }}>
            Información adicional
          </p>
          <p style={{ fontSize: "12px" }}>
            Me capacitaron en su uso y me comprometo a utilizarlo toda vez que
            corresponda.
            <br />
            El uso de la ropa y calzado de seguridad es obligatorio, en caso de
            no utilizarlos,
            <br />
            no se le permitirá el ingreso a la empresa y será suspendido sin
            excepción.
          </p>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "50px",
            }}
          >
            <p style={{ textAlign: "left" }}></p>
            <div style={{ textAlign: "right" }}>
              <p>______________________________</p>
              <p style={{ textAlign: "center" }}>Firma del trabajador</p>
            </div>
          </div>
        </div>
        {/* </div> */}
      </Dialog>
    </>
  );
}

export default Ficha_imprimir;
