import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  MenuItem,
  Select,
  TextField,
  InputLabel,
  CircularProgress,
  FormControl,
  Box,
} from "@mui/material";
import { UrlApi } from "../../../services/apirest";
import axios from "axios";

//Iconos y boludeces para los textfields
import InputAdornment from "@mui/material/InputAdornment";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";

const EditDatosProveedor = ({ proveedor, almacenar }) => {
  const [Provincia, setProvincia] = useState();
  const [Localidad, setLocalidad] = useState([]);
  const [CondVenta, setCondVenta] = useState();
  const [isLoading, setisLoading] = useState(false);

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  useEffect(() => {
    obtenerCondicion();
    obtenerProvincia();
  }, []);

  useEffect(() => {
    obtenerLocalidad();
  }, [proveedor.id_provincia]);

  const obtenerProvincia = async () => {
    try {
      setisLoading(true);
      await axios
        .get(UrlApi + "/provincias")
        .then((response) => setProvincia(response.data));
    } catch (err) {
      console.error(err);
    } finally {
      setisLoading(false);
    }
  };

  const obtenerLocalidad = async () => {
    try {
      await axios
        .get(UrlApi + "/localidades/prov/" + proveedor.id_provincia)
        .then((response) => setLocalidad(response.data));
    } catch (err) {
      console.error(err);
    } finally {
      setisLoading(false);
    }
  };

  console.log("cp", Localidad);

  const obtenerCondicion = async () => {
    try {
      await axios
        .get(UrlApi + "/condicion_pago")
        .then((res) => setCondVenta(res.data));
    } catch (err) {
      console.error(err);
    } finally {
      setisLoading(false);
    }
  };

  return (
    <>
      <Grid container spacing={2} justifyContent={"center"}>
        <Grid item xs={4}>
          <TextField
            style={style}
            defaultValue=""
            type="number"
            focused
            id="cuit"
            value={proveedor.nro_cuit}
            name="nro_cuit"
            onChange={almacenar}
            label="Numero Cuit"
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            focused
            style={style}
            placeholder="Razon Social"
            type="text"
            required
            name="razon_social"
            id="razon_social"
            label="Razon Social"
            onChange={almacenar}
            value={proveedor.razon_social}
            inputProps={{ maxLength: 200 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            focused
            style={style}
            placeholder="Nombre Contacto"
            type="text"
            name="nombre_contacto"
            id="nombre_contacto"
            label="Nombre Contacto"
            onChange={almacenar}
            value={proveedor.nombre_contacto}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle color="info" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            label="Telefono Contacto"
            name="telefono_contacto"
            value={proveedor.telefono_contacto}
            onChange={almacenar}
            focused
            style={style}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <ContactPhoneIcon color="info" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            focused
            style={style}
            placeholder="Nombre Fantasia"
            type="text"
            name="nombre_fantasia"
            id="razon_social"
            label="Nombre Fantasia"
            onChange={almacenar}
            value={proveedor.nombre_fantasia}
            inputProps={{ maxLength: 200 }}
          />
        </Grid>

        {!isLoading ? (
          <Grid item xs={4}>
            <FormControl style={style} focused>
              <InputLabel>Provincia</InputLabel>
              <Select
                label="Provincia"
                name="id_provincia"
                value={proveedor.id_provincia}
                onChange={almacenar}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                {Provincia
                  ? Provincia.map((elemento) => (
                      <MenuItem key={elemento.id} value={elemento.id}>
                        {elemento.provincia}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </FormControl>
          </Grid>
        ) : (
          <>
            <Box justifyContent={"center"} margin={"auto "}>
              <CircularProgress />
            </Box>
          </>
        )}

        {!isLoading ? (
          <Grid item xs={4}>
            <FormControl style={style} focused>
              <InputLabel>Localidad</InputLabel>
              <Select
                label="Localidad"
                name="id_localidad"
                value={proveedor.id_localidad}
                onChange={almacenar}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                {Localidad
                  ? Localidad.map((elemento) => (
                      <MenuItem key={elemento.id} value={elemento.id}>
                        {elemento.localidad}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </FormControl>
          </Grid>
        ) : (
          <>
            <Box justifyContent={"center"} margin={"auto "}>
              <CircularProgress />
            </Box>
          </>
        )}

        <Grid item xs={4}>
          <TextField
            focused
            style={style}
            disabled
            name="cp"
            label="Codigo Postal"
            value={proveedor.cp}
            inputProps={{ maxLength: 200 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            focused
            style={style}
            placeholder="Direccion"
            type="text"
            name="direccion"
            label="Direccion"
            onChange={almacenar}
            value={proveedor.direccion}
            inputProps={{ maxLength: 200 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationOnIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            label="Telefono"
            name="telefono"
            value={proveedor.telefono}
            onChange={almacenar}
            focused
            style={style}
            inputProps={{ maxLength: 200 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <ContactPhoneIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            focused
            style={style}
            placeholder="Email"
            type="text"
            name="email"
            id="email"
            label="Email"
            onChange={almacenar}
            value={proveedor.email}
            inputProps={{ maxLength: 200 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <ContactMailIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            focused
            style={style}
            placeholder="C.B.U"
            type="number"
            name="cbu"
            label="C.B.U"
            onChange={almacenar}
            value={proveedor.cbu}
            inputProps={{ maxLength: 200 }}
          />
        </Grid>
        {!isLoading ? (
          <Grid item xs={4}>
            <FormControl style={style} focused>
              <InputLabel>Cond Venta</InputLabel>
              <Select
                label="Cond Venta"
                name="id_condicionventa"
                defaultValue={proveedor.id_condicionventa || ""}
                value={proveedor.id_condicionventa || ""}
                onChange={almacenar}
                inputProps={{ "aria-label": "Without label" }}
              >
                {CondVenta
                  ? CondVenta.map((elemento) => (
                      <MenuItem key={elemento.id} value={elemento.id}>
                        {elemento.condicion}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </FormControl>
          </Grid>
        ) : (
          <>
            <Box justifyContent={"center"} margin={"10px auto"}>
              <CircularProgress />
            </Box>
          </>
        )}
        <Grid item xs={4}>
          <TextField
            focused
            style={style}
            placeholder="Sisven ID"
            type="text"
            name="sisven_id"
            label="Sisven ID"
            onChange={almacenar}
            value={proveedor.sisven_id}
            inputProps={{ maxLength: 22 }}
          />
        </Grid>
      </Grid>

      <br></br>
    </>
  );
};
export default EditDatosProveedor;
