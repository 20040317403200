import React, { useRef, useState, useEffect } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import { TextField } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";
import Box from "@mui/material/Box";
import { UrlApi } from "../../src/services/apirest";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import { Button } from "@mui/material";

const containerStyle = {
  width: "600px",
  height: "330px",
};

export default function Mapa(props) {
  const [coordinates, setCoordinates] = useState({
    latitud: "",
    longitud: "",
    verificada: null,
    direccion_entrega: "",
  });

  const getCoordinates = () => {
    fetch(UrlApi + "/direccionesentrega/latlong/" + props.idDireccion)
      .then((data) => data.json())
      .then((data) => setCoordinates(data[0]));
  };

  useEffect(() => {
    getCoordinates();
  }, []);

  const center = {
    lat: coordinates && coordinates.latitud * 1,
    lng: coordinates && coordinates.longitud * 1,
  };

  const inputRef = useRef();

  const handlecambiolugar = () => {
    const [places] = inputRef.current.getPlaces();
    if (places) {
      setCoordinates({
        latitud: places.geometry.location.lat(),
        longitud: places.geometry.location.lng(),
      });
    }
  };

  async function updateAdress(e) {
    e.preventDefault();
    const response = await fetch(
      UrlApi + "/direccionesentrega/" + props.idDireccion,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          latitud: coordinates.latitud,
          longitud: coordinates.longitud,
          verificada: 1,
          // direccion_entrega: coordinates.direccion_entrega,
        }),
      }
    );
    if (response.ok === false) {
      const error = response && response.json();
      alert("Error al guardar. " + error);
    } else {
      try {
        const json = await response.json();
        if (json.message) {
          alert("Ubicación guardada exitosamente!");
          props.handleClose();
        } else if (json.sqlMessage) {
          alert("Error al guardar.");
        } else {
          alert("Error desconocido.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          alert("Ubicación guardada exitosamente!");
          props.handleClose();
        } else {
          alert("Error desconocido.");
        }
      }
    }
  }

  // const [markerAdress, setMarkerAdress]=useState("");
  // async function getAddressFromCoordinates(lat, lon) {
  //   try {
  //     const response = await fetch(
  //       `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lon}`,
  //       {
  //         method: 'GET',
  //         headers: {
  //           'User-Agent': 'Someone', // Agrega un encabezado User-Agent con el nombre de tu aplicación
  //         },
  //       }
  //     );
  //     if (response.ok) {
  //       const data = await response.json();
  //       // console.log("data adress: " + data.display_name)
  //       if (data.display_name) {
  //         setMarkerAdress(data.display_name.split(',')[1].trim() + " " +  data.display_name.split(',')[0].trim())
  //       } else {
  //         console.error('No se encontró una dirección en la respuesta de Nominatim.');
  //         return null;
  //       }
  //     } else {
  //       console.error('Error al obtener la dirección desde Nominatim.');
  //       return null;
  //     }
  //   } catch (error) {
  //     console.error('Error en la solicitud a Nominatim:', error);
  //     return null;
  //   }
  // }

  // markerAdress&&console.log("Dir del marcador: " + markerAdress)

  return (
    <div>
      <Box py={2}>
        <LoadScript
          googleMapsApiKey={process.env.REACT_APP_Google_maps_key}
          libraries={["places"]}
        >
          <StandaloneSearchBox
            onLoad={(ref) => (inputRef.current = ref)}
            onPlacesChanged={handlecambiolugar}
          >
            <Grid container direction="row" mb={2}>
              <Grid item xs={12}>
                <TextField
                  focused
                  label="Dirección"
                  fullWidth
                  placeholder="Ingrese direccion"
                  size="small"
                  // inputProps={{
                  //   componentRestrictions: { country: "ar" }, // Código ISO 3166-1 de Argentina
                  // }}
                  onFocus={() => {
                    console.log("Hola")
                    // const options = {
                    //   types: ["geocode"],
                    //   componentRestrictions: { country: "AR" }, // "AR" es el código ISO 3166-1 de Argentina
                    // };
                    // inputRef.current.setOptions(options);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <TravelExploreIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </StandaloneSearchBox>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={15}
          >
            <Marker
              position={center}
              draggable={true}
              onDragEnd={(p) => {
                setCoordinates({
                  latitud: p.latLng.lat(),
                  longitud: p.latLng.lng(),
                });
                // getAddressFromCoordinates(p.latLng.lat(), p.latLng.lng())
              }}
            />
          </GoogleMap>
          <Grid container direction="row" justifyContent="space-between" mt={2}>
            <Button
              variant="outlined"
              color="error"
              onClick={props.handleClose}
            >
              Cerrar
            </Button>
            <Button
              color="success"
              variant="contained"
              onClick={(e) => updateAdress(e)}
            >
              Guardar Cambios
            </Button>
          </Grid>
        </LoadScript>
      </Box>
    </div>
  );
}
