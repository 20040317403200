import { useState, useEffect } from "react";
import Navbar from "../Navbar";
import { Container, Grid, Typography } from "@mui/material";
import { UrlApi } from "../../services/apirest";
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { ThemeProvider, createTheme } from "@mui/material";
import Chat from "../Chat";
function Correo() {
  const [mensajes, setMensajes] = useState();
  const [selectedRow, setSelectedRow] = useState(null);
  const [muestromensajes, setMuestroMensajes] = useState();
  const defaultMaterialTheme = createTheme();

  useEffect(() => {
    buscarmensajes();
  }, []);

  const buscarmensajes = async () => {
    const data = await fetch(
      UrlApi + "/mensajes/" + localStorage.getItem("user")
    );
    const mensajesdata = await data.json();
    setMensajes(mensajesdata);
  };

  const handleRow = (xx) => {
    setMuestroMensajes(xx.mensaje)
    setSelectedRow(xx.mensajes_id)
  }

  const localization = {
    toolbar: {
      nRowsSelected: '{0} filas(s) seleccionadas',
      searchPlaceholder: 'Buscar'
    },
    header: {
      actions: 'Acciones'
    },
    body: {
      emptyDataSourceMessage: 'No hay correo para mostrar',
      filterRow: {
        filterTooltip: 'Filtro'
      }
    }
  };

  const options = {
    // ...
    padding: "dense",
    paging: false,
    exportButton: false,
    headerStyle: {
      whiteSpace: "nowrap",
      textAlign: "left",
      flexDirection: "row",
      overflow: "hidden",
      textOverflow: "ellipsis",
      paddingLeft: 10,
      paddingRight: 0,
      backgroundColor: "navy",
      fontWeight: "normal",
      color: "white",
      fontSize: 14,
      position: "sticky",
      top: "0"
    },
    maxBodyHeight: "300px",
    actionsColumnIndex: -1,
    rowStyle: rowData => ({
      backgroundColor: (selectedRow === rowData.tableData.id) ? 'lightgreen' : 'white',
      fontSize: 12,
      paddingTop: 0,
    }),

  };

  return (
    <div>
      <Navbar />
      {/* <Chat/> */}
      <Container>
        <br />
        <Typography variant="h5" bgcolor='navy' color='white' borderRadius='0.3em' paddingLeft='10px'>Correo</Typography>
        <br />
        {console.log(mensajes)}
        <Grid container>
          <Grid item xs={6} border='1px solid navy' borderRadius='0.3em'>
            {mensajes && (

              <ThemeProvider theme={defaultMaterialTheme}>
                <MaterialReactTable
                  localization={MRT_Localization_ES}
                  enableDensityToggle={false}
                  enableColumnFilters={false}
                  enableTableFooter={false}
                  initialState={{
                    density: 'compact',
                    columnVisibility: { medicamento1: false, medicamento2: false, medicamento3: false, diagnostico: false }
                  }}
                  enableHiding={false}
                  enableColumnActions={false}
                  enableStickyHeader
                  muiTableContainerProps={{ sx: { maxHeight: '500px' } }}
                  enablePagination={false}
                  columns={[
                    { header: "De", accessorKey: "emisor", size: 50 },
                    { header: "Referencia", accessorKey: "referencia" },
                  ]}
                  data={mensajes}
                  muiTableBodyRowProps={({ row }) => ({
                    onClick: (event) => {
                      handleRow(row.original);
                    },
                    sx: {
                      cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                    },
                  })}
                />
              </ThemeProvider>
            )}
          </Grid>
          <Grid item xs={6} border='1px solid navy' borderRadius='0.3em'>
            {<div style={{ paddingLeft: "30px", paddingTop: "15px" }}>
              <Typography variant="h6" >Mensaje</Typography>
            </div>}
            <hr />
            {<div style={{ paddingLeft: "30px", paddingTop: "0px" }}>{muestromensajes}</div>}
            <br />
          </Grid>
        </Grid>
        <br /><br />
      </Container>
    </div>
  );
}

export default Correo;
