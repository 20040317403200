//----- Component --------------------------------------------------------/

// Developer : Nicolas Pascucci

// Creation Date : 23/11/2023

// Version : 1

// Description : pantalla para autorizaciones de articulos compras
//

//------Changes --------------------------------------------------------------------- -------------//

// Change Date :

// Change by :

// Change description:

//

// Change Date :

// Change by :

// Change description:

//

//========================================================================/
import React, { useState, useEffect, useMemo } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Box,
  TextField,
  Autocomplete,
  DialogContentText,
  Typography,
  DialogActions,
} from "@mui/material";
import moment from "moment"; // PARA LA FECHA
//email
import { enviarmails } from "../../services/services";
import { logEventos } from "../../services/services";
import { grey } from "@mui/material/colors";

function Dialog_autorizar_Art(props) {
  const [datosRechazar, setDatosRechazar] = useState({
    nombre_articulo: "",
    detalle_transaccion: "",
  });
  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [openSnackEditar, setOpenSnackEditar] = React.useState(false);
  const [openCrear, setOpenCrear] = React.useState(false);
  //   const [update, setUpdate] = useState(false);

  const [row, setRow] = useState(props.row);
  const [abrirConfirmacion, setAbrirConfirmacion] = useState(
    props.abrirConfirmacion
  );

  // console.log("ROW", row);
  //*************************************************************************************************************************************/
  // RECHAZO ART
  const [dialogRechazarArt, setDialogRechazarArt] = useState(false);
  const handleCloseDialogRechazarArt = () => {
    setDialogRechazarArt(false);
  };

  const handleClickOpenRechazar = async (row) => {
    setDialogRechazarArt(true);
    // setDatosArt(row);
  };

  const handleCloseRechazo = () => {
    setDialogRechazarArt(false);
  };
  //*************************************************************************************************************************************/
  const [articulos, setArticulos] = useState();

  //funcion para obtener articulos
  const obtenerArticulos = () => {
    fetch(UrlApi + "/stock_articulos")
      .then((data) => data.json())
      .then((data) => setArticulos(data));
  };

  useEffect(() => {
    obtenerArticulos();
  }, []);

  const [articuloSeleccionado, setArticuloSeleccionado] = useState({});
  //*************************************************************************************************************************************/
  const almacenar = (e) => {
    setDatosRechazar({
      ...datosRechazar,
      [e.target.name]: e.target.value,
    });
  };
  //*************************************************************************************************************************************/

  //Funcion para guardar AL EDITAR
  const guardarAutorizar = async (e) => {
    logEventos(
      "Guardar autorización de artículo",
      "Stock Autorizar Articulos",
      "Se guardo autorización",
      localStorage.getItem("user")
    );
    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(UrlApi + "/stock_articulos/" + row.id_articulo, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        aprobado: 1,
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          //   setUpdate(true);
          // setMensajeConfirma(true)
          setColorMensaje("#D4EFDF");
          setMensaje("El artículo se autorizó!");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al autorizar el artículo");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    // console.log(row.id_articulo);
    handleClose();
  };

  // console.log(row);
  const guardarRechazar = async (e) => {
    logEventos(
      "Guardar rechazar artículo",
      "Stock Autorizar Articulos",
      "Se rechazo el artículo",
      localStorage.getItem("user")
    );
    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(UrlApi + "/stock_articulos/" + row.id_articulo, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        aprobado: 2,
      }),
    })
    // enviarmails(
    //   row.email,
    //   "Artículo rechazado",
    //   `<p>El artículo <b>${row.nombre}</b> fue rechazado.<br/><b>Detalles:</b><br/>${datosRechazar.detalle_transaccion}<br/> <b>Corresponde a:</b><br/> ${articuloSeleccionado.nombre} </p>`
    // )
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          //   setUpdate(true);
          // setMensajeConfirma(true)
          setColorMensaje("#D4EFDF");
          setMensaje("El artículo se rechazó!");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al rechazar el artículo");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    // console.log(row.id_articulo);
    handleClose();
  };

  // console.log("Nombre", row.nombre, "EMAIL", row.email);

  const handleClose = () => {
    //   setAbrirConfirmacion(false);
    setAbrirConfirmacion(!abrirConfirmacion);

    // setSnack(true)
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <div>
      <Dialog
        open={abrirConfirmacion}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
      >
        <Box backgroundColor={"#1F618D"} mt={1}>
          <DialogTitle>
            <Typography variant="h6" align="center" color="#ffffff">
              Confirmar Autorización del Artículo
            </Typography>
          </DialogTitle>
        </Box>
        <form onSubmit={guardarAutorizar}>
          <Box
            border={0.5}
            borderColor={grey[300]}
            backgroundColor="#F4F6F6"
            p={2}
            py={2}
            mt={1}
          >
            <DialogContent>
              <DialogContentText textAlign={"center"}>
                ¿Desea Autorizar el Alta del Artículo {props.row.nombre}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItemns="center"
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClose}
                  style={{ backgroundColor: "#1976D2", color: "#ffffff" }}
                >
                  Volver
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleClickOpenRechazar}
                  style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
                >
                  Rechazar
                </Button>
                <Button
                  style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Autorizar
                </Button>
              </Grid>
            </DialogActions>
          </Box>
        </form>
      </Dialog>

      {/* ------ DIALOG RECHAZAR ARTICULO ------ */}
      <Dialog open={dialogRechazarArt} onClose={handleCloseDialogRechazarArt}>
        <form onSubmit={guardarRechazar}>
          <Box backgroundColor={"#1F618D"} mt={1}>
            <DialogTitle>
              <Typography variant="h6" align="center" color="#ffffff">
                Rechazar Artículo
              </Typography>
            </DialogTitle>
          </Box>
          <Box
            border={0.5}
            borderColor={grey[300]}
            backgroundColor="#F4F6F6"
            p={2}
            py={2}
            mt={1}
          >
            <Container maxWidth="xl">
              <Grid container mt={2} mb={1} spacing={1}>
                <Grid item xs={12} mb={2}>
                  <TextField
                    label="Motivo del Rechazo"
                    name="detalle_transaccion"
                    size="small"
                    placeholder="..."
                    inputProps={{
                      maxLength: 60,
                    }}
                    focused
                    required
                    fullWidth
                    value={datosRechazar.detalle_transaccion}
                    onChange={almacenar}
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={12} py={1} mb={1}>
                  <Autocomplete
                    disabled={!articulos}
                    size="small"
                    focused
                    disableClearable
                    id="stock_articulos"
                    noOptionsText={"Artículo INEXISTENTE"}
                    options={articulos}
                    autoHighlight
                    getOptionLabel={(articulos) => articulos.nombre}
                    onChange={(event, value) => setArticuloSeleccionado(value)}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        key={option.id}
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.nombre}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        focused
                        size="small"
                        placeholder="Tipee para buscar..."
                        {...params}
                        label="Corresponde a :"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Container>
            <DialogActions>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItemns="center"
              >
                <Button
                  variant="contained"
                  onClick={handleCloseRechazo}
                  style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
                >
                  Volver
                </Button>
                <Button
                  style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Confirmar
                </Button>
              </Grid>
            </DialogActions>
          </Box>
        </form>
      </Dialog>
    </div>
  );
}

export default Dialog_autorizar_Art;
