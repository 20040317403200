
import React, { useState, useEffect } from "react";
import { Container, Grid, Box, TextField, Button, Autocomplete } from "@mui/material";
import Title from "../../../Title";
import Notify from "../../../Notify";
import moment from "moment";
import { getData, postData, putData } from "../../../../services/library";
import { logEventos } from "../../../../services/services";
import FormPreciosEspeciales from "./FormPreciosEspeciales";
import FormActualizar from "./FormActualizar";

function AddPrecioEspecial(props) {
  const [datoslista, setDatoslista] = useState({
    // uuid_direccionentrega: "",
    id_cliente: null,
    lt_con_iva: "",
    lt_sin_iva: "",
    kg_con_iva: "",
    kg_sin_iva: "",
    vigencia: "",
    fin_vigencia: null
  });

  const [disabledGuardar, setDisabledGuardar] = useState(false);
  const [clientes, setClientes] = useState();
  const [buscando, setBuscando] = useState(false);
  const [clienteSeleccionada, setClienteSeleccionada] = useState("");
  const [direcciones, setDirecciones] = useState();
  const [direccionSeleccionada, setDireccionSeleccionada] = useState("");
  const [datosDirec, setDatosDirec] = useState([]);
  const [cambieCli, setCambieCli] = useState(false);
  const [cambieDirec, setcambieDirec] = useState(false);

  const [notificacion, setNotificacion] = useState({
    open: false,
    mensaje: "",
    severity: "",
    color: "",
  });

  useEffect(() => {
    // getData('/clientes/de/precioespecial', setClientes)
    getData('/precios_especiales/clientes', setClientes)

  }, [])

  // console.log("clientes", clientes);
  

  // //una vez que está seleccionada la diereccion de entrega verificaos que no haya un precio vigente cargado por dios que sueño que tengo :'()
  useEffect(() => {
    clienteSeleccionada &&
      getData(`/precios_especiales/xcliente/${clienteSeleccionada.id_cliente}`, setDatosDirec)
  }, [clienteSeleccionada]);

  //CUANDO CAMBIE DE CLIENTE, CAMBIO DE DIRECCION DE ENTREGA, PORQUE SI NO QUEDA SELECCIONADA LA OTRA
  // useEffect(() => {
  //   if (cambieCli) {
  //     // Reseteo de dirección seleccionada cuando se cambia el cliente
  //     // setDireccionSeleccionada(null);

  //     // Marcar como buscando
  //     setBuscando(true);

  //     // Obtener direcciones del cliente seleccionado
  //     // getData(`/direccionesentrega/precioespecialcliente/${clienteSeleccionada.id}`, (data) => {
  //     //   setDirecciones(data);
  //     //   setBuscando(false); // Marcar como no buscando
  //     // });

  //     // Resetear `cambieCli` después de ejecutar la lógica
  //     setCambieCli(false);
  //   }
  // }, [clienteSeleccionada, cambieCli]);

  // console.log("direccion seleccuonada", direccionSeleccionada);


  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  const size = "small";

  const handleClose = () => {
    props.alta(false);
    props.tabla(true);
  };

  // clienteSeleccionada && console.log("ID", clienteSeleccionada,)

  //funcion para guardar 
  const guardarPrecio = async (e) => {
    setDisabledGuardar(false);
    postData(e, '/precios_especiales',
      {
        // uuid_direccionentrega: direccionSeleccionada.nro_dir,
        id_cliente: clienteSeleccionada.id_cliente,
        lt_con_iva: datoslista.lt_con_iva,
        lt_sin_iva: datoslista.lt_sin_iva,
        kg_con_iva: datoslista.kg_con_iva,
        kg_sin_iva: datoslista.kg_sin_iva,
        vigencia: datoslista.vigencia,
        usuario: localStorage.getItem("user"),
        fin_vigencia:datoslista.fin_vigencia
      },
      setNotificacion
    )
    logEventos("Guardar nuevo precio especial", "Precio especiales", "referencias tabla", localStorage.getItem('user'));
  };

  //FUNCION PARA HACER UPDATE EN CASO DE QUE EXISTA UNA LISTA DE PRECIOS JAJAJAJJAAJAJJAJA
  const updateLista = async (e) => {
    postData(e, '/precios_especiales',
      {
        // uuid_direccionentrega: direccionSeleccionada.nro_dir,
        id_cliente: clienteSeleccionada.id_cliente,
        lt_con_iva: datoslista.lt_con_iva,
        lt_sin_iva: datoslista.lt_sin_iva,
        kg_con_iva: datoslista.kg_con_iva,
        kg_sin_iva: datoslista.kg_sin_iva,
        vigencia: datoslista.vigencia,
        usuario: localStorage.getItem("user"),
        fin_vigencia:datoslista.fin_vigencia
      },
      setNotificacion
    )
    // putData(e,
    //   `/precios_especiales/${datosDirec[0].id_precioespecial}`,
    //   {
    //     fin_vigencia: datoslista.vigencia,
    //     usuario: localStorage.getItem('user')
    //   },
    //   setNotificacion
    // )

    logEventos("Agregar fecha vigencia precio especial", "Precio especiales", "referencias tabla", localStorage.getItem('user'));
  }

  const almacenar = (event) => {
    //a medida que voy llenando los datos, hago directamente el calculo 

    const { name, value } = event.target;
    let newValue = { ...datoslista, [name]: value };

    //entonces en cada campo hago lo siguiente mi reynis
    //depende en el cmampo que estoy
    // hago el calculo con el valor del porcentaje de iva del producto
    // que obtengo de la direccion de entrega seleccionada ja ja ja 
    //y ademas con la densidad base del prducto lleno todo porque soy muy inteligente 

    const densidad = clienteSeleccionada && clienteSeleccionada.id_empresa === 1
      ? clienteSeleccionada.densidad_hipergas
      : clienteSeleccionada.densidad_autogas;

      // console.log("densidad", densidad)

    if (name === "lt_sin_iva") {
      const precioConIva = parseFloat(value) * clienteSeleccionada.porcentaje_iva
      newValue.lt_con_iva = precioConIva.toFixed(4);
      newValue.kg_con_iva = (precioConIva / densidad).toFixed(4);
      newValue.kg_sin_iva = (newValue.kg_con_iva / clienteSeleccionada.porcentaje_iva).toFixed(4)
    }

    if (name === "kg_sin_iva") {
      const precioConIva = parseFloat(value) * clienteSeleccionada.porcentaje_iva
      newValue.kg_con_iva = precioConIva.toFixed(4);
      newValue.lt_con_iva = (precioConIva * densidad).toFixed(4);
      newValue.lt_sin_iva = (newValue.lt_con_iva / clienteSeleccionada.porcentaje_iva).toFixed(4);
    }

    if (name === "kg_con_iva") {
      const precioConIva = parseFloat(value) / clienteSeleccionada.porcentaje_iva
      newValue.kg_sin_iva = precioConIva.toFixed(4);
      newValue.lt_con_iva = (precioConIva / densidad).toFixed(4);
      newValue.lt_sin_iva = (newValue.lt_con_iva / clienteSeleccionada.porcentaje_iva).toFixed(4);
    }

    if (name === "lt_con_iva") {
      const precioConIva = parseFloat(value) / clienteSeleccionada.porcentaje_iva
      newValue.lt_sin_iva = precioConIva.toFixed(4);
      newValue.kg_sin_iva = (precioConIva * densidad).toFixed(4);
      newValue.kg_con_iva = (newValue.kg_sin_iva * clienteSeleccionada.porcentaje_iva).toFixed(4);
    };

    setDatoslista(newValue)
  };

  useEffect(() => {
    // console.log("datoslista:", datoslista);
    if (datoslista.lt_con_iva || datoslista.lt_sin_iva ||
      datoslista.kg_con_iva || datoslista.kg_sin_iva || datoslista.vigencia) {
      setDisabledGuardar(false);
    } else {
      setDisabledGuardar(true);
    }
  }, [datoslista.lt_con_iva, datoslista.lt_sin_iva, datoslista.kg_con_iva, datoslista.kg_sin_iva, datoslista.vigencia]);

  useEffect(() => {
    if (cambieDirec || cambieCli) {
      setDatoslista((prevDatos) => ({
        ...prevDatos,
        lt_sin_iva: "",
        lt_con_iva: "",
        kg_sin_iva: "",
        kg_con_iva: ""
      }));
      setcambieDirec(false)
      setCambieCli(false);
    }
  }, [cambieCli, cambieDirec])


  //ENTONCES EL FORMULARIO SE VA A DIVIDIR EN LO SIGUIENTE: 
  //PRIMERO SE HABILITA PARA QUE SE CARGUE UN NUEVO PRECIO, SI YA EXISTE UNO
  //LE VA A PEGIMTAR AL USUARIO SI NECESITA O SI DESEA CARGAR UNO DEPENDIENDO LO QIE SE QIERA
  //ESTO VA A PASAR CARA VEZ QUE EL USUARIO ELIJA UNA DIRECCION DE ENTREGA
  return (
    <div>
      <Container>
        <Title
          titulo={datosDirec && datosDirec.length === 0 ? "Nuevo Precio" : "Actualizar precio"}
          handleClose={handleClose} />
        <form onSubmit={guardarPrecio} autoComplete="off">
          <Box
            backgroundColor="#F4F6F6"
            mt={2}
            p={3}
            mb={5}
            border={0.5}
            borderColor={"#ABB2B9"}
            borderRadius={4}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={8}>
                {clientes && (
                  <Autocomplete
                    disabled={!clientes}
                    size={size}
                    focused
                    disableClearable
                    id="clientes"
                    noOptionsText={"Cliente INEXISTENTE"}
                    options={clientes}
                    autoHighlight
                    getOptionLabel={(clientes) => `${clientes.razon_social} (${clientes.nombre_producto})`}
                    onChange={(event, value) => {
                      setClienteSeleccionada(value)
                      setCambieCli(true)
                    }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        key={option.id}
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {`${option.razon_social} (${option.nombre_producto})`}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        style={style}
                        required
                        focused
                        size="small"
                        placeholder="Tipee para buscar..."
                        {...params}
                        label="Clientes"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                )}
              </Grid>
              {/* <Grid item xs={6}>

                {buscando ?
                  (<TextField
                    disabled
                    focused
                    fullWidth
                    size="small"
                    label="Buscando Direccion..."
                  />)
                  :
                  (
                    <Autocomplete
                      disabled={!direcciones}
                      size={size}
                      focused
                      disableClearable
                      id="direcciones"
                      noOptionsText={"Dirección INEXISTENTE"}
                      options={direcciones}
                      autoHighlight
                      getOptionLabel={(direcciones) => direcciones.direccion_entrega}
                      onChange={(event, value) => {
                        setDireccionSeleccionada(value)
                        setcambieDirec(true)
                      }}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          key={option.id}
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option.direccion_entrega}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          style={style}
                          required
                          focused
                          size="small"
                          placeholder="Tipee para buscar..."
                          {...params}
                          label="Direcciones"
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}
                    />
                  )}
              </Grid> */}

              {datosDirec && datosDirec.length === 0 ?
                <FormPreciosEspeciales
                  size={size}
                  style={style}
                  almacenar={almacenar}
                  datoslista={datoslista} />
                :
                <FormActualizar
                  datosExisten={datosDirec}
                  datoslista={datoslista}
                  size={size}
                  style={style}
                  // fechaFinVigenciaFormat={fechaFinVigenciaFormat}
                  setDatoslista={setDatoslista}
                  disabledGuardar={disabledGuardar}
                  setDisabledGuardar={setDisabledGuardar}
                  almacenar={almacenar} />
              }



            </Grid>
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-end"
              mt={2}
            >
              <Button variant="contained" onClick={handleClose} color="error">
                Volver
              </Button>

              <Button
                variant="contained"
                type="submit"
                color="success"
                onClick={datosDirec && datosDirec.length > 0 ? updateLista : guardarPrecio}
                disabled={disabledGuardar}
              >
                {datosDirec && datosDirec.length > 0 ? "Actualizar" : "Guardar Precio"}
              </Button>
            </Grid>
          </Box>
        </form>
        {notificacion && notificacion.open ? (
          <Notify
            mensaje={notificacion.mensaje}
            open={notificacion.open}
            color={notificacion.color}
            handleClose={handleClose}
            severity={notificacion.severity}
          />
        ) : null}
      </Container>

    </div>
  )
}

export default AddPrecioEspecial
//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio / Nicolas pascucci
// Creation Date :26/06/2024
// Version : 1
// Description : Agregar precios
//------Changes ----------------------------------------------------------//
// Change Date : 15/8
// Change by : Candela Grosso
// Change description: Acomode el titulo y saque los console.log - Separe en dos componetntes los fomularios de alta y de actualizacion para que se carguen directamente...
//========================================================================/