import Navbar from "../../../Navbar";
import React, { useEffect, useState, useMemo, useRef } from "react";
import { UrlApi } from "../../../../services/apirest";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import {
  Box,
  Button,
  Container,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  TextField,
  InputAdornment,
} from "@mui/material";
// import Impresion from "./Impresion";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //idioma
import MuiAlert from "@mui/material/Alert";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import ReactToPrint from "react-to-print";
import moment from "moment";

// LOG EVENTOS
import { logEventos } from "../../../../services/services";

// ICONOS
import EditIcon from "@mui/icons-material/Edit";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AssignmentIcon from "@mui/icons-material/Assignment";
import NotesIcon from "@mui/icons-material/Notes";
import PersonIcon from "@mui/icons-material/Person";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import PrintIcon from "@mui/icons-material/Print";
import FeedIcon from "@mui/icons-material/Feed";
import ReceiptIcon from "@mui/icons-material/Receipt";
import CloseIcon from "@mui/icons-material/Close";

// DIALOG
import Dialog from "@mui/material/Dialog";

// FORMULARIO PARA CREAR ANALISIS
import AnalisisCalidad from "./AnalisisCalidad";
import { FichaAnalisisCalidad } from "./FichaAnalisisCalidad";

// ESTILO PARA EL TEXTO "Imprimir"
const theme = createTheme({
  palette: {
    primary: {
      main: "#f5f5f5",
    },
  },
});

// TRANSICION PARA APARECER DIALOG DE LA IMPRESION
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TablaAnalisis() {
  const [analisis, setAnalisis] = useState();
  const [tabla, setTabla] = useState(true); //Estado que mostrará o no la tabla (pantalla inicial) donde estan los analisis
  const [datosFormulario, setDatosFormulario] = useState({});

  // ----------------------------------------------------------------

  // ABRIR ANALISiS
  // OCULTO LA TABLA Y MUESTRO EL ALTA DE ANALISIS
  const [alta, setAlta] = React.useState(false);

  const handleClickAbrirAnalisis = () => {
    setAlta(true);
    setTabla(false);
  };

  const handleCerrarAnalisis = () => {
    setAlta(false);
    setTabla(true);
  };

  //**********************************************************************************************/
  // IMPRESION:

  // POPUP PARA MOSTRAR LA FICHA
  const [openFicha, setOpenFicha] = React.useState(false);

  const handleClickOpenFicha = () => {
    setOpenFicha(true);
  };

  const handleCloseFicha = () => {
    setOpenFicha(false);
  };

  // CONSTANTE PARA LA IMPRESION (Tomo el componente que voy a imprimir)
  const componentRef = useRef();

  // ----------------------------------------------------------------

  // POPUP PARA MOSTRAR LA ETIQUETA
  const [openEtiqueta, setOpenEtiqueta] = React.useState(false);

  const handleClickOpenEtiqueta = (row) => {
    setOpenEtiqueta(true);
    setDatosFormulario(row);
    console.log("DATOS FORMULARIO: ", datosFormulario.nro_muestra);
  };

  const handleCloseEtiqueta = (row) => {
    setOpenEtiqueta(false);
    setDatosFormulario(row);
  };

  // ----------------------------------------------------------------

  // POPUP PARA MOSTRAR EL REMITO
  const [openRemito, setOpenRemito] = React.useState(false);

  const handleClickOpenRemito = (row) => {
    setOpenRemito(true);
    // setDatosFormulario(row);
    // console.log("DATOS FORMULARIO: ", datosFormulario.nro_muestra);
  };

  const handleCloseRemito = (row) => {
    setOpenRemito(false);
    // setDatosFormulario(row);
  };

  // ----------------------------------------------------------------

  // OBTENER ANALISIS
  //Funcion para traer los analisis a la tabla
  const obtenerAnalisis = () => {
    fetch(UrlApi + "/propelente_analisis")
      .then((data) => data.json())
      .then((data) => setAnalisis(data));
  };

  //Cada vez que hay algun cambio de estado en "tabla", se hara la funcion "obtener analisis"
  useEffect(() => {
    obtenerAnalisis();
  }, [tabla]);

  const columns = useMemo(
    () => [
      {
        header: "ID",
        accessorKey: "id_propelente_analisis",
        minSize: 30,
        size: 50,
        maxSize: 100,
      },
      {
        header: "Receptor",
        accessorKey: "nombre_receptor",
        minSize: 50,
        size: 80,
        maxSize: 100,
      },
      {
        header: "Op. Físico",
        accessorKey: "nombre_fisico",
        minSize: 50,
        size: 80,
        maxSize: 100,
      },
      {
        header: "Op. Cromatográfico",
        accessorKey: "nombre_cromatografo",
        minSize: 50,
        size: 80,
        maxSize: 100,
      },
      {
        header: "Fecha",
        accessorKey: "fecha",
        type: "date",
        minSize: 50,
        size: 50,
        maxSize: 70,
        valueFormatter: (params) => moment(params?.value).format("DD-MM-YYYY"),
      },
      {
        header: "Observaciones",
        accessorKey: "observaciones",
        minSize: 50,
        size: 80,
        maxSize: 125,
      },
    ],
    []
  );

  // ESTILO PARA LA TABLA
  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)" //background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //override to make tooltip font size larger
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  // ************************************************************************************************

  logEventos("Lista analisis propelentes", "Analisis Propelentes", "Ver lista de propelentes", localStorage.getItem("user"))


  return (
    <>
      <Navbar />
      <br />
      <Container maxWidth="xl" style={{ marginBottom: "30px" }}>
        {analisis && tabla ? (
          <ThemeProvider theme={tableTheme}>
            <MaterialReactTable
              columns={columns}
              data={analisis}
              initialState={{
                showGlobalFilter: true,
                density: "compact",
              }}
              localization={MRT_Localization_ES} //idioma ES
              muiTableBodyProps={{
                sx: {
                  //HACE QUE LAS COLUMNAS IMPARES TENGAN UN COLOR MAS OSCURO
                  "& td:nth-of-type(odd)": {
                    backgroundColor: "rgb(245,245,245)",
                  },
                  //ESTILO DE LA TABLA
                  "& .MuiTableCell-root": {
                    borderBottom: "1px solid",
                  },
                  //COLOR AL PASAR EL MOUSE POR ENCIMA
                  "&:hover": {
                    backgroundColor: `rgb(220,220,220)`,
                  },
                  "& .MuiTableCell-root:first-child": {
                    border: "none",
                  },
                },
              }}
              // TITULO
              renderTopToolbarCustomActions={() => (
                <Grid item mt={-2.5}>
                  <h2>ANALISIS DE PROPELENTES</h2>
                </Grid>
              )}
              // ACCIONES
              renderToolbarInternalActions={({ table }) => (
                <>
                  <Tooltip arrow placement="bottom" title="Agregar Análisis">
                    <IconButton
                      color="primary"
                      onClick={() => {
                        handleClickAbrirAnalisis();
                      }}
                    >
                      <AddBoxIcon />
                    </IconButton>
                  </Tooltip>
                  <MRT_FullScreenToggleButton table={table} />
                </>
              )}
              enableRowActions
              renderRowActions={({ row }) => (
                <>
                  <Tooltip arrow placement="left" title="Editar Análisis">
                    <IconButton onClick={() => console.info("Edit")}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip arrow placement="left" title="Etiqueta">
                    <IconButton
                      onClick={() => handleClickOpenEtiqueta(row.original)}
                    >
                      <ReceiptIcon style={{ color: "#2E7D32" }} />
                    </IconButton>
                  </Tooltip>

                  <Tooltip arrow placement="left" title="Remito">
                    <IconButton
                      color="primary"
                      onClick={() => {
                        handleClickOpenRemito();
                        console.log("Remito");
                      }}
                    >
                      <FeedIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip
                    arrow
                    placement="left"
                    title="Ver/Imprimir Certificado"
                  >
                    <IconButton onClick={handleClickOpenFicha}>
                      <PrintIcon style={{ color: "#c902e3" }} />
                    </IconButton>
                  </Tooltip>
                </>
              )}
              positionActionsColumn="last"
              // TRUE
              enableTopToolbar={true}
              enableSorting={true}
              // PANEL DE DETALLES / FICHA
              renderDetailPanel={({ row }) => (
                <>
                  <Container maxWidth="x1">
                    <br />
                    <DialogTitle
                      style={{
                        color: "white",
                        backgroundColor: "#4abced",
                        
                        marginTop: "-25px",
                        marginBottom: "-10px",
                      }}
                    >
                      <Grid item mt={-1.5}>
                        ID DEL ANALISIS: {row.original.id_propelente_analisis}
                      </Grid>
                    </DialogTitle>
                    <br />
                    <Box border={2} px={2} pt={2} borderRadius={4}>
                      {/* FILA 1 */}
                      <Grid container maxWidth="x1" spacing={1}>
                        <Grid item xs={2} py={1} mb={1}>
                          <TextField
                            fullWidth
                            placeholder="No hay registro"
                            label="Nro de Muestra"
                            name="nro_muestra"
                            variant="filled"
                            size="small"
                            focused
                            value={row.original.nro_muestra}
                            InputProps={{
                              readOnly: true,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <AssignmentIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={2} py={1} mb={1}>
                          <TextField
                            fullWidth
                            placeholder="No hay registro"
                            label="Tanque"
                            name="nro_serie"
                            variant="filled"
                            size="small"
                            focused
                            value={row.original.nro_serie}
                            InputProps={{
                              readOnly: true,
                              // startAdornment: (
                              //   <InputAdornment position="start">
                              //     <NotesIcon />
                              //   </InputAdornment>
                              // ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={2} py={1} mb={1}>
                          <TextField
                            fullWidth
                            placeholder="No hay registro"
                            label="Producto"
                            name="producto"
                            variant="filled"
                            size="small"
                            focused
                            value={row.original.producto}
                            InputProps={{
                              readOnly: true,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <AssignmentIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={2} py={1} mb={1}>
                          <TextField
                            fullWidth
                            placeholder="No hay registro"
                            label="Aspecto"
                            name="aspecto"
                            variant="filled"
                            size="small"
                            focused
                            value={row.original.aspecto}
                            InputProps={{
                              readOnly: true,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <RemoveRedEyeIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={2} py={1} mb={1}>
                          <TextField
                            fullWidth
                            placeholder="No hay registro"
                            label="Olor al Ingreso"
                            name="olor_ingreso"
                            variant="filled"
                            size="small"
                            focused
                            value={row.original.olor_ingreso}
                            InputProps={{
                              readOnly: true,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <AssignmentIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={2} py={1} mb={1}>
                          <TextField
                            fullWidth
                            placeholder="No hay registro"
                            label="Muestras Testigo"
                            name="cantidad_muestras_testigo"
                            variant="filled"
                            size="small"
                            focused
                            value={row.original.cantidad_muestras_testigo}
                            InputProps={{
                              readOnly: true,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <AssignmentIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        {/* FILA 2: SECCION DE EMPLEADOS */}
                        {/* RECEPTORES */}
                        <Grid container>
                          <Grid item xs={4}>
                            <Box m={1} border={1} px={2} borderRadius={2}>
                              <DialogTitle textAlign={"center"} mb={-2} mt={-1}>
                                Receptor
                              </DialogTitle>
                              <Grid item py={1} mb={1}>
                                <TextField
                                  fullWidth
                                  placeholder="No hay registro"
                                  label="Nombre"
                                  name="nombre_receptor"
                                  variant="filled"
                                  size="small"
                                  focused
                                  value={row.original.nombre_receptor}
                                  InputProps={{
                                    readOnly: true,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <PersonIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Grid>
                              <Grid item py={1} mb={1}>
                                <TextField
                                  fullWidth
                                  placeholder="No hay registro"
                                  label="Apellido"
                                  name="apellido_receptor"
                                  variant="filled"
                                  size="small"
                                  focused
                                  value={row.original.apellido_receptor}
                                  InputProps={{
                                    readOnly: true,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <PersonIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Grid>
                            </Box>
                          </Grid>

                          {/* OPERADOR FISICO */}
                          <Grid item xs={4}>
                            <Box m={1} border={1} px={2} borderRadius={2}>
                              <DialogTitle textAlign={"center"} mb={-2} mt={-1}>
                                Operador Fisico
                              </DialogTitle>
                              <Grid item py={1} mb={1}>
                                <TextField
                                  fullWidth
                                  placeholder="No hay registro"
                                  label="Nombre"
                                  name="nombre_fisico"
                                  variant="filled"
                                  size="small"
                                  focused
                                  value={row.original.nombre_fisico}
                                  InputProps={{
                                    readOnly: true,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <PersonIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Grid>
                              <Grid item py={1} mb={1}>
                                <TextField
                                  fullWidth
                                  placeholder="No hay registro"
                                  label="Apellido"
                                  name="apellido_fisico"
                                  variant="filled"
                                  size="small"
                                  focused
                                  value={row.original.apellido_fisico}
                                  InputProps={{
                                    readOnly: true,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <PersonIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Grid>
                            </Box>
                          </Grid>

                          {/* OPERADOR CROMATOGRAFICO */}
                          <Grid item xs={4}>
                            <Box
                              mt={1}
                              ml={1}
                              mb={1}
                              px={2}
                              border={1}
                              borderRadius={2}
                            >
                              <DialogTitle textAlign={"center"} mb={-2} mt={-1}>
                                Operador Cromatográfico
                              </DialogTitle>
                              <Grid item py={1} mb={1}>
                                <TextField
                                  fullWidth
                                  placeholder="No hay registro"
                                  label="Nombre"
                                  name="nombre_cromatografo"
                                  variant="filled"
                                  size="small"
                                  focused
                                  value={row.original.nombre_cromatografo}
                                  InputProps={{
                                    readOnly: true,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <PersonIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Grid>
                              <Grid item py={1} mb={1}>
                                <TextField
                                  fullWidth
                                  placeholder="No hay registro"
                                  label="Apellido"
                                  name="apellido_cromatografo"
                                  variant="filled"
                                  size="small"
                                  focused
                                  value={row.original.apellido_cromatografo}
                                  InputProps={{
                                    readOnly: true,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <PersonIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>

                        {/* FILA 3 */}
                        <Grid item xs={2} py={1} mb={1}>
                          <TextField
                            fullWidth
                            placeholder="No hay registro"
                            label="Fecha"
                            name="fecha"
                            variant="filled"
                            size="small"
                            focused
                            value={row.original.fecha}
                            InputProps={{
                              readOnly: true,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <CalendarMonthIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={2} py={1} mb={1}>
                          <TextField
                            fullWidth
                            placeholder="No hay registro"
                            label="Hora"
                            name="hora"
                            variant="filled"
                            size="small"
                            focused
                            value={row.original.hora}
                            InputProps={{
                              readOnly: true,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <QueryBuilderIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={8} py={1} mb={1}>
                          <TextField
                            fullWidth
                            placeholder="No hay registro"
                            label="Observaciones"
                            name="observaciones"
                            variant="filled"
                            size="small"
                            focused
                            value={row.original.observaciones}
                            InputProps={{
                              readOnly: true,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <AssignmentIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Container>
                </>
              )}
              columnResizeMode="onChange"
              // FALSE
              enableColumnFilters={false} //opcion de filtrado por columnas
              enableDensityToggle={false} //para que no se vea pero esta activo en el estado
              enableHiding={false} //oculta la propiedad show hide columns
            />
          </ThemeProvider>
        ) : null}

        {/* DIALOG PARA LA ETIQUETA */}
        <Dialog maxWidth="md" open={openEtiqueta} onClose={handleCloseEtiqueta}>
          <div
            style={{
              margin: "4px",
            }}
          >
            <table
              border="0"
              position="absolute"
              width="600px"
              maxWidth="600px"
              margin="auto"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                borderTop: "1px solid",
                borderLeft: "1px solid",
                borderRight: "1px solid",
                borderBottom: "1px solid",
                borderCollapse: "collapse",
                borderWidth: "medium",
              }}
            >
              <table>
                <tr>
                  <td style={{ width: "420px", maxWidth: "500px" }}>
                    <text style={{ fontSize: 60, verticalAlign: "middle" }}>
                      Nro. {datosFormulario.nro_muestra}
                    </text>
                  </td>
                  <td>
                    <img
                      src="/Italgas.jpeg"
                      alt="ITALGAS"
                      height={82}
                      style={{ verticalAlign: "middle" }}
                    />
                  </td>
                </tr>
              </table>
              <hr
                style={{
                  border: "none",
                  height: "2px",
                  backgroundColor: "#333",
                }}
              />
              <table style={{ marginBottom: "-8px", marginTop: "-8px" }}>
                <tr>
                  <td>
                    <text style={{ fontSize: 15, marginLeft: "8px" }}>
                      Cliente: USUARIO JUNIN P
                    </text>
                  </td>
                </tr>
              </table>
              <hr
                style={{
                  border: "none",
                  height: "2px",
                  backgroundColor: "#333",
                }}
              />
              <table style={{ marginBottom: "-8px", marginTop: "-8px" }}>
                <tr>
                  <td>
                    <text style={{ fontSize: 15, marginLeft: "8px" }}>
                      Comprobante: - (Remito)
                    </text>
                  </td>
                </tr>
              </table>
              <hr
                style={{
                  border: "none",
                  height: "2px",
                  backgroundColor: "#333",
                }}
              />
              <table style={{ marginBottom: "-8px", marginTop: "-8px" }}>
                <tr>
                  <td>
                    <text style={{ fontSize: 15, marginLeft: "8px" }}>
                      Oper.: {datosFormulario.apellido_fisico}{" "}
                      {datosFormulario.nombre_fisico}
                    </text>
                  </td>
                </tr>
              </table>
              <hr
                style={{
                  border: "none",
                  height: "2px",
                  backgroundColor: "#333",
                }}
              />
              <table style={{ marginBottom: "-8px", marginTop: "-8px" }}>
                <tr>
                  <td>
                    <text style={{ fontSize: 15, marginLeft: "8px" }}>
                      Crom.: {datosFormulario.apellido_cromatografo}{" "}
                      {datosFormulario.nombre_cromatografo}
                    </text>
                  </td>
                </tr>
              </table>
              <hr
                style={{
                  border: "none",
                  height: "2px",
                  backgroundColor: "#333",
                }}
              />
              <table
                border="1"
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  height: "15%",
                  marginTop: "-10px",
                }}
              >
                <tr>
                  <td borderLeft="0">
                    <text style={{ fontSize: 15, marginLeft: "8px" }}>
                      PROPELENTE {datosFormulario.producto}
                    </text>
                  </td>
                </tr>
                {/* <hr style={{ border: "none", height: "2px", backgroundColor: "#333"}}/> */}
                <tr>
                  <td border="0">
                    <text style={{ fontSize: 15, marginLeft: "8px" }}>
                      {datosFormulario.nro_serie} (Fijo)
                    </text>
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: "center", fontSize: 24 }}>
                    <b>
                      {datosFormulario.fecha} - {datosFormulario.hora}
                    </b>
                  </td>
                  <td
                    border="0"
                    style={{
                      border: "0",
                      marginLeft: "20px",
                      textAlign: "center",
                    }}
                  >
                    <br />
                    <br />
                    ____________________________________
                    <br />
                    FIRMA
                  </td>
                </tr>
              </table>
            </table>
          </div>
        </Dialog>

        {/* DIALOG PARA EL REMITO */}
        <Dialog
          // maxWidth="xxl"
          maxWidth="lg"
          open={openRemito}
          onClose={handleCloseRemito}
          fullWidth
        >
          <table
            border="0"
            position="absolute"
            width="850px"
            maxWidth="850px"
            height="800px"
            margin="auto"
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              borderTop: "1px solid",
              borderLeft: "1px solid",
              borderRight: "1px solid",
              borderBottom: "1px solid",
              borderCollapse: "collapse",
              borderWidth: "medium",
            }}
          >
            <h2>REMITO</h2>
            <div
              style={{
                margin: "4px",
              }}
            >
              RTGHRTTGRETG
            </div>
          </table>
        </Dialog>

        {/* AGREGAR DIALOG DE "Impresion" ????? */}
        {openFicha ? (
          <div>
            <Button variant="outlined" onClick={handleClickOpenFicha}>
              Open full-screen dialog
            </Button>
            <Dialog
              fullScreen
              open={openFicha}
              TransitionComponent={Transition}
            >
              <AppBar sx={{ position: "relative" }}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleCloseFicha}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography
                    sx={{ ml: 2, flex: 1 }}
                    variant="h6"
                    component="div"
                  >
                    CERTIFICADO
                  </Typography>
                  {/* USO THEMEPROVIDER PARA DAR COLOR BLANCO AL BOTON */}
                  <ThemeProvider theme={theme}>
                    <ReactToPrint
                      trigger={() => <Button>IMPRIMIR</Button>}
                      content={() => componentRef.current}
                    />
                  </ThemeProvider>
                </Toolbar>
              </AppBar>
              <FichaAnalisisCalidad ref={componentRef} />
            </Dialog>
          </div>
        ) : null}

        {/* ----------------------------------- */}

        {alta ? (
          <AnalisisCalidad handleCerrarAnalisis={handleCerrarAnalisis} />
        ) : null}
      </Container>

      {/* {openFicha ? <Impresion ficha={setOpenFicha} datos={analisis} /> : null} */}
    </>
  );
}

export default TablaAnalisis;
