import React, { useState } from "react";
import FileUploadMultiple from "../../services/UploadFiles";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import { Box, Container, Grid, Button, Typography } from "@mui/material";
import Navbar from "../Navbar";
import { Link } from "react-router-dom";

const Archivo_Afip = () => {
  const [openclose, setOpenClose] = useState(false);

  return (
    <div>
      <Navbar />
      <Container maxWidth="xl">
        <Box sx={{ flexGrow: 1, backgroundColor: "#1F618D" }} mt={2}>
          <Grid container p={1.5}>
            <Grid
              // item

              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h5" color="#ffffff">
                AFIP
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box
          mb={3}
          mt={2}
          backgroundColor="#F4F6F6"
          p={3}
          border={0.5}
          borderColor={"#ABB2B9"}
          borderRadius={4}
        >
          <Typography variant="h5">Instrucciones:</Typography>
          <Typography variant="body1">
            1.Deberan entrar a{" "}
            <Button size="small" variant="outlined" color="primary">
              <Link
                to="https://www.afip.gob.ar/genericos/cInscripcion/archivoCompleto.asp"
                target="_blank"
                rel="noopener noreferrer"
              >
                Pagina AFIP
              </Link>
            </Button>{" "}
            y Descargar el Archivo condición tributaria con denominación /
            Apellido y Nombre
          </Typography>

          <Typography variant="body1" color="initial">
            {" "}
            2.Deberan guardarlo en el el servidor mediante el boton ENVIAR
          </Typography>

          <Button
            variant="contained"
            startIcon={<AttachFileIcon />}
            onClick={() => setOpenClose(!openclose)}
            color="primary"
          >
            ENVIAR
          </Button>
        </Box>
      </Container>

      {openclose ? <FileUploadMultiple openclose={true} /> : null}
    </div>
  );
};

export default Archivo_Afip;
