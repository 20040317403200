import React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Notify from "../../components/Notify";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";

function DeleteDialog(props) {
  const [disBtn, setDisBtn] = useState();

  const handleClose = () => {
    props.setOpen(false);
    props.setNotify({ open: false });
  };

  return (
    <div>
      {props.notify.open && (
        <Notify
          open={props.notify.open}
          severity={props.notify.severity}
          mensaje={props.notify.mensaje}
          handleClose={props.atras ? props.atras : handleClose}
          color={props.notify.color}
        />
      )}
      <Dialog open={props.open} onClose={handleClose}>
        <DialogTitle borderTop={6} borderColor="#f53649"></DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            wrap="wrap"
          >
            <Grid item xs={3}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item>
                  <Avatar sx={{ bgcolor: "#f53649", width: 80, height: 80 }}>
                    <DeleteIcon sx={{ fontSize: 40 }} />
                  </Avatar>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={9}>
              <Grid container direction="column">
                <Grid item>
                  <Typography
                    variant="body1"
                    color="#2E4053"
                    gutterBottom
                    fontWeight="bold"
                  >
                    {" "}
                    {props.title}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" color="textSecondary">
                    Antes de continuar, por favor, tenga en cuenta lo siguiente:
                  </Typography>
                </Grid>
                <Grid item>
                  <ul>
                    <li>
                      <Typography variant="body1" color="textSecondary">
                        Todos los datos asociados se perderán de forma
                        irreversible.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1" color="textSecondary">
                        No podrá recuperar la información eliminada.
                      </Typography>
                    </li>
                  </ul>
                </Grid>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  style={{ marginTop: 8 }}
                >
                  {props.confirmText}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={0} justifyContent="space-between">
            <Button
              onClick={handleClose}
              disabled={disBtn}
              color="error"
              variant="contained"
            >
              Volver
            </Button>

            <Button
              variant="contained"
              disabled={disBtn}
              autoFocus
              color="success"
              onClick={(e) => {
                props.guardar(e)
                setDisBtn(true);
                props.actualizar&&props.actualizar()
              }}
            >
              {props.btnText ?? "Guardar"}
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DeleteDialog;
