import React, { useState } from "react";
import { UrlApi } from "../../../services/apirest";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Container, Grid, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import InputAdornment from "@mui/material/InputAdornment";
import NoteIcon from "@mui/icons-material/Note";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import { grey } from "@mui/material/colors";
import Notify from '../../Notify';
import Alerta from '../../Alerta';

function Addsegmento(props) {
    const [datosFormulario, setDatosformulario] = useState({
        segmento: "",
        usuario: localStorage.getItem("user"),
        activo: 1,
    });
    const [openSnack, setOpenSnack] = React.useState(false);
    const [colormensaje, setColorMensaje] = useState("");
    const [mensaje, setMensaje] = useState("");
    const [confirmar, setConfirmar] = useState(false);
    const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
    const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
    const [mensajeAlerta, setMensajeAlerta] = useState(""); //mensaje que contendrá el componente "alerta"
    const [finalizar, setFinalizar] = useState(); //finalizar === 1 lo manda como prop el componente "notify" e indica que la transaccion ha finalizado y debe ejecutarse la funcion para cerrar
    const [severityAlerta, setSeverityAlerta] = useState(""); //define severidad de la alerta (success error o warning)
    const [severitySnack, setSeveritySnack] = useState(""); //define severidad del snackbar (notificación)
    const [disBtn, setDisBtn] = useState(false)

    const almacenar = (e) => {
        setDatosformulario({ ...datosFormulario, [e.target.name]: e.target.value });
    };

    const handleClose = () => {
        props.alta(false);
        props.tabla(true);
    };

    const guardarSegmento = async (e) => {
        setDisBtn(true);
        e.preventDefault();
        let response = await fetch(UrlApi + "/segmentos", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(datosFormulario),
        })
        //response&&console.log(response)
        if (response.ok === false) {
            console.log("ERROR EN API");
            const error = response && response.json();
            setError(error);
            setSeverityAlerta("error")
            setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
        } else {
            console.log("Conexión con api OK");
            try {
                //Parseo respuesta de la api a json()
                const json = await response.json();
                //si api me devuelve lo q le puse en message
                if (json.message) {
                    //Si ese mensaje es "OK"
                    if (json.message === "OK") {
                        console.log("Guardado Satisfactoriamente");
                        setNotificacion(true);
                        setOpenSnack(true);
                        setColorMensaje("#D4EFDF");
                        setMensaje("Guardado");
                        setSeveritySnack("success");
                    } else {
                        console.log("res en api incorrecto. Revisar que sea 'OK'")
                        setNotificacion(true);
                        setOpenSnack(true);
                        setColorMensaje("#D4EFDF");
                        setSeveritySnack("error");
                        setMensaje("Registro Guardado. REVISAR RESPUESTA DE API (E_S)");
                    }
                } else if (json.sqlMessage) {
                    json && setError(json);
                    console.log(json);
                    setMensajeAlerta("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
                } else {
                    setMensajeAlerta("ERROR DESCONOCIDO.");
                }
            } catch (error) {
                if (error.toString().includes("is not valid JSON")) {
                    console.log("Guardado. Advertencia: Actualizar respuesta de api. JSON invalido.");
                    setNotificacion(true);
                    setOpenSnack(true);
                    setColorMensaje("#D4EFDF");
                    setMensaje("Guardado");
                    setSeveritySnack("success");
                } else {
                    setMensajeAlerta("ERROR DESCONOCIDO.");
                }
            }
        }
        handleClose()
    };

    const style = {
        backgroundColor: "#ffffff",
        width: "100%"
    };

    return (
        <div>
            <Container>
                <Box sx={{ flexGrow: 1 }} mt={2}>
                    <Grid container backgroundColor="#1F618D" p={1.5}>
                        <Grid item xs={2}>
                            <IconButton onClick={handleClose}>
                                <ArrowBackIcon sx={{ color: grey[50] }} />
                            </IconButton>
                        </Grid>
                        <Grid
                            item
                            xs={8}
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography variant="h5" color="#ffffff">
                                Cargar nuevo segmento
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <form onSubmit={guardarSegmento}>
                    <Box
                        backgroundColor="#F4F6F6"
                        mt={2}
                        p={3}
                        border={0.5}
                        borderColor={"#ABB2B9"}
                        borderRadius={4}
                    >
                        <Grid
                            container spacing={1} direction={{ xs: 'column', sm: 'row' }}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    placeholder="Segmento"
                                    required
                                    focused
                                    name="segmento"
                                    id="segmento"
                                    label="Segmento"
                                    style={style}
                                    color="primary"
                                    onChange={almacenar}
                                    value={datosFormulario.segmento}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <ContentPasteIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            {/* <Grid item xs={6}>
                                <TextField
                                    placeholder="Codigo de Segmento"
                                    focused
                                    fullWidth
                                    style={style}
                                    name="codigo"
                                    id="codigo"
                                    label="Codigo"
                                    color="primary"
                                    onChange={almacenar}
                                    value={datosFormulario.codigo}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <NoteIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid> */}
                        </Grid>
                        <Grid mt={2} container direction="row" justifyContent="space-between" alignItemns="center">
                            <Button
                                disabled={disBtn}
                                variant="contained"
                                color="error"
                                onClick={handleClose}
                            >
                                Volver
                            </Button>
                            <Button
                                disabled={disBtn}
                                variant="contained"
                                type="submit"
                                color="success"
                            >
                                Guardar Segmento
                            </Button>
                        </Grid>
                    </Box>
                    {notificacion ? (
                        <Notify
                            mensaje={mensaje}
                            open={openSnack}
                            color={colormensaje}
                            finalizar={setFinalizar}
                            severity={severitySnack}
                        />
                    ) : null}
                    {/* ALERT --> SE MUESTRA SI HAY UN ERROR EN LA API (NO SE CONECTA O ESTÁ MAL LA RUTA, ETC.) */}
                    {error && mensajeAlerta ? (
                        <Alerta mensaje={mensajeAlerta} severity={severityAlerta} />
                    ) : null}



                </form>
            </Container>
        </div>
    );
}

export default Addsegmento;
