import { AppBar, Grid, Typography } from '@mui/material'
import { titulonavbar } from '../services/apirest';

export default function Footer4() {

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
        <div>
            <AppBar position="fixed"
                sx={{
                    top: 'auto',
                    paddingTop: "5px",
                    paddingLeft: '5px',
                    paddingRight: '5px',
                    bottom: 0,
                    backgroundColor: '#EEEFFF', color: 'teal',
                    borderTop: "0.1px solid lightblue",
                    borderBottom: "5px solid teal",
                }}
                variant="dense">
                <Grid container justify="space-between">
                    <Typography variant="body2">Netizar @{new Date().getFullYear()}</Typography>
                    <div style={{ marginLeft: "auto" }}> {/* lo tira para la derecha*/}
                        <Typography variant="body2">{titulonavbar} - Usuario: {capitalizeFirstLetter(localStorage.getItem('user'))} - Sucursal: {localStorage.getItem("sucursal")}-{localStorage.getItem("sucursalnombre")}</Typography>
                    </div>
                </Grid>
            </AppBar>
        </div>
    )
}
