// Developer     : Magalí Perea
// Creation Date : 2024-04-22
// Version       : 
// Description   : Componente destinado a ser utilizado por el personal de rrhh en caso de tener que añadir viandas para cobrar a algún empleado que no tenga usuario o bien, no haya completado el formulario a tiempo.

import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import { UrlApi } from "../../services/apirest";
import Autocomplete from "@mui/material/Autocomplete";
import TitleUploaded from "../TitleUploaded";
import FormGroup from "@mui/material/FormGroup";
import Alert from "@mui/material/Alert";
import { postData } from "../../services/library";

import {
  Container,
  Box,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Paper,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  Divider,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
// import Divider from '@mui/material/Divider';
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";

import Notify from "../Notify";
function NuevoPedido() {
  const [users, setUsers] = useState();
  const [seleccion, setSeleccion] = useState({
    usuario: "",
  });
  const [periodoViandas, setPeriodoViandas] = useState({
    inicio: moment(new Date()).format("YYYY-MM-DD"),
    fin: null,
  });
  const [disBtn, setDisBtn] = useState(false);
  const [opciones, setOpciones] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [disabledBusqueda, setDisabledBusqueda] = useState();

  const getUsers = () => {
    fetch(UrlApi + "/userdata")
      .then((data) => data.json())
      .then((data) =>
        setUsers(
          data.filter(
            (data) => data.sucursal === "1000" || data.sucursal === "8"
          )
        )
      );
  };

  const getOpciones = () => {
    // console.log("entro en get opciones");
    setDisabledBusqueda(true);
    fetch(
      UrlApi +
        "/viandas/semanal/" +
        periodoViandas.inicio +
        "/" +
        periodoViandas.fin
    )
      .then((data) => data.json())
      .then((data) => {
        const inicioSel = data.map((item) => {
          return {
            fecha: item.fecha,
            usuario: selectedUser,
            observaciones: "",
            opcion: 0,
          }; // Crear un objeto con la clave 'fecha' y el valor 'item.fecha'
        });
        // console.log(inicioSel);
        setSeleccion(inicioSel); //inicio la seleccion con la fecha de las viandas.
        setOpciones(data);
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  const [maxDate, setMaxDate] = useState();

  const obtenerFechaMax = () => {
    // Convertir la fecha de inicio a un objeto moment
    const inicioDate = moment(periodoViandas.inicio, "YYYY-MM-DD");

    // Calcular el número de días que faltan para llegar al viernes
    const daysUntilFriday = 5 - inicioDate.day(); // 5 es el índice del viernes en moment.js

    // Sumar los días restantes para obtener la fecha del viernes
    const fridayDate = moment(inicioDate).add(daysUntilFriday, "days");

    // Formatear la fecha del viernes en el formato adecuado para el atributo max
    const maxDate = fridayDate.format("YYYY-MM-DD");
    setMaxDate(maxDate);
  };

  useEffect(() => {
    obtenerFechaMax();
  }, [periodoViandas.inicio]);

  const handleInputChange = (index, name, value, id_opcion) => {
    console.log(id_opcion);
    const dataCopy = seleccion[index];
    var updated = {};
    if (name === "opcion") {
      updated = {
        ...dataCopy,
        [name]: value,
        guarnicion: "",
        id_opcion: id_opcion,
      };
    } else {
      updated = {
        ...dataCopy,
        [name]: value,
      };
    }
    const clone = [...seleccion];
    clone[index] = updated;
    setSeleccion(clone);
  };

  useEffect(() => {
    if (!!selectedUser && seleccion && seleccion.length > 0) {
      const newData = seleccion.map((item) => ({
        ...item,
        usuario: selectedUser,
      }));
      setSeleccion(newData);
    }
  }, [selectedUser]);

  const [notify, setNotify] = useState({
    open: false,
    severity: "",
    color: "",
    mensaje: "",
  });

  const handleClose = () => {
    window.location.replace(`/#/navbar`);
  };

  async function guardar(e) {
    e.preventDefault();
    //Antes de guardar, hacemos una serie de validaciones:
    //1- Que haya una opción escogida para cada dia:
    var ok = seleccion.every((reg) => reg.opcion === 0 || !!reg.opcion);
    if (ok) {
      //2- Que el usuario no tenga cargada una vianda para esa fecha
      var fechas = seleccion.map((pedido) => pedido.fecha);
      var fechasString = fechas.join(",");
      const algo = await fetch(
        UrlApi +
          "/viandas/repeticiones/" +
          seleccion[0].usuario +
          "/" +
          fechasString
      );
      const repeticiones = await algo.json();

      if (repeticiones.length > 0) {
        const fechas = repeticiones.map((opcion) =>
          moment(opcion.fecha).format("DD-MM-YYYY")
        );
        const listaFechas = fechas.join("\n- "); // Uniendo las fechas con un separador de salto de línea y guión
        alert(
          `Hay pedidos realizados para alguna de las fechas seleccionadas:\n- ${listaFechas}. \n Por favor, REVISE.`
        );
      } else {
        setDisBtn(true);
        postData(e, "/viandas/pedidos/instant", seleccion, setNotify);
      }
    } else {
      alert("Selección incompleta.");
    }
  }

  
    
  const deleteBusqueda = () => {
    setPeriodoViandas({ inicio: "", fin: "" });
    setSeleccion(null);
    setSelectedUser(null);
    setOpciones(null);
    setKey(new Date());
    setDisabledBusqueda(false);
  };
  const [key, setKey] = useState(new Date());

  return (
    <div style={{ padding: 5 }}>
      <Navbar />
      <Container maxWidth="xl" sx={{ mt: 1, px: 3 }}>
        <TitleUploaded title="Nuevo Pedido" />
        {notify.open ? (
          <Notify
            open={notify.open}
            color={notify.color}
            severity={notify.severity}
            mensaje={notify.mensaje}
            handleClose={() => handleClose()}
          />
        ) : null}
        <Grid container my={2} spacing={1}>
          <Grid item xs={12} mb={1}>
            <Autocomplete
              key={key} // Bool, or whatever just change it to re-render the component
              disabled={disabledBusqueda}
              required
              size="small"
              sx={{ backgroundColor: "#fff" }}
              //   focused
              noOptionsText={"Inexistente.."}
              options={users}
              autoHighlight
              getOptionLabel={(user) => `${user.apellido} ${user.nombrepila}`}
              onChange={(event, value) => {
                // console.log(value)
                setSelectedUser(value.username);
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  key={option.id}
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option.nombrepila} {option.apellido}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  disabled={disabledBusqueda}
                  required
                  color="primary"
                  //   focused
                  placeholder="Tipee para buscar empleado.."
                  {...params}
                  label="Empleado"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="initial">
              Buscar menús disponibles..
            </Typography>
            <FormHelperText>
              Si solo desea buscar el menú de un día, deje la fecha de fin
              vacía..
            </FormHelperText>
          </Grid>
          <Grid item xs={5}>
            <TextField
              disabled={disabledBusqueda}
              size="small"
              fullWidth
              label="Desde"
              type="date"
              value={periodoViandas.inicio}
              onChange={(e) =>
                setPeriodoViandas({ ...periodoViandas, inicio: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              disabled={disabledBusqueda}
              size="small"
              fullWidth
              label="Hasta"
              type="date"
              value={periodoViandas.fin}
              inputProps={{
                max: maxDate,
                // max:((moment(periodoViandas.inicio).format("YYYY-MM-DD")).clone().endOf('isoWeek').format("YYYY-MM-DD"))
                min: periodoViandas.inicio,
                // max:moment(periodoViandas.inicio).format("YYYY-MM-DD")
              }}
              onChange={(e) =>
                setPeriodoViandas({ ...periodoViandas, fin: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={1.5}>
            <Button
              variant="outlined"
              color="success"
              fullWidth
              onClick={() => getOpciones()}
              disabled={!selectedUser || disabledBusqueda}
            >
              Consultar
            </Button>
          </Grid>
          <Grid item xs={0.5}>
            <Tooltip title="Borrar Búsqueda">
              <IconButton aria-label="" onClick={() => deleteBusqueda()}>
                <DeleteIcon color="error" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Container>

      {/*  ---------------------------------------------------------------------- */}

      {opciones && opciones.length === 0 && !!seleccion ? (
        <Container
          maxWidth="xl"
          sx={{
            py: 2,
          }}
        >
          <Alert variant="outlined" severity="warning" color="warning">
            No hay viandas disponibles para esta fecha
          </Alert>
        </Container>
      ) : (
        <>
          <div style={{ padding: "10px 20px" }}>
            {opciones && (
              <form onSubmit={guardar}>
                <Container
                  maxWidth="xl"
                  sx={{
                    border: 1,
                    borderColor: "#99A3A4",
                    backgroundColor: "#ebebeb",
                    py: 2,
                    borderRadius: 4,
                  }}
                >
                  {opciones.map((opcion, index) => (
                    <Grid
                      container
                      direction="column"
                      backgroundColor="#ebebeb"
                      alignItems="flex-start"
                      pb={3}
                    >
                      <FormControl component="fieldset" variant="standard">
                        <Grid item>
                          <FormLabel component="legend" focused>
                            {" "}
                            {`${moment(opcion.fecha)
                              .format("dddd DD-MM-YYYY")
                              .toUpperCase()} ${
                              opcion.feriado === 1
                                ? "(Feriado/No Laborable)"
                                : ""
                            } `}
                          </FormLabel>
                        </Grid>

                        <FormGroup>
                          <FormControlLabel
                            focused
                            control={
                              <Checkbox
                                disabled={opcion.feriado === 1}
                                checked={
                                  seleccion[index] &&
                                  seleccion[index].opcion === 1
                                }
                                onChange={(e) => {
                                  handleInputChange(
                                    index,
                                    "opcion",
                                    e.target.checked ? 1 : null,
                                    //   opcion.fecha,
                                    opcion.id_opcion
                                  );
                                }}
                              />
                            }
                            label={opcion.opcion1.toUpperCase()}
                          />
                          {opcion.guarnicion11 ? (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                ml: 3,
                              }}
                            >
                              <FormControlLabel
                                label={opcion.guarnicion11}
                                control={
                                  <Checkbox
                                    required={
                                      (!!opcion.guarnicion11 &&
                                        seleccion &&
                                        seleccion[index].opcion === 1 &&
                                        !seleccion[index].guarnicion) ||
                                      seleccion[index].guarnicion ===
                                        opcion.guarnicion11
                                    }
                                    disabled={
                                      (seleccion[index] &&
                                        seleccion[index].opcion !== 1) ||
                                      opcion.feriado === 1
                                      // || !habilitarMod
                                    }
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "guarnicion",
                                        e.target.checked
                                          ? opcion.guarnicion11
                                          : null,
                                        null
                                      )
                                    }
                                    checked={
                                      seleccion[index] &&
                                      seleccion[index].opcion === 1 &&
                                      seleccion[index].guarnicion ===
                                        opcion.guarnicion11
                                    }
                                  />
                                }
                              />
                            </Box>
                          ) : null}
                          {opcion.guarnicion12 ? (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                ml: 3,
                              }}
                            >
                              <FormControlLabel
                                label={opcion.guarnicion12}
                                control={
                                  <Checkbox
                                    required={
                                      (!!opcion.guarnicion12 &&
                                        seleccion &&
                                        seleccion[index].opcion === 1 &&
                                        !seleccion[index].guarnicion) ||
                                      seleccion[index].guarnicion ===
                                        opcion.guarnicion12
                                    }
                                    disabled={
                                      (seleccion[index] &&
                                        seleccion[index].opcion !== 1) ||
                                      opcion.feriado === 1
                                      // || !habilitarMod
                                    }
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "guarnicion",
                                        e.target.checked
                                          ? opcion.guarnicion12
                                          : null
                                      )
                                    }
                                    checked={
                                      seleccion[index] &&
                                      seleccion[index].opcion === 1 &&
                                      seleccion[index].guarnicion ===
                                        opcion.guarnicion12
                                    }
                                  />
                                }
                              />
                            </Box>
                          ) : null}

                          <FormControlLabel
                            control={
                              <Checkbox
                                // disabled={opcion.feriado === 1 || !habilitarMod}
                                checked={
                                  seleccion[index] &&
                                  seleccion[index].opcion === 2
                                }
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "opcion",
                                    e.target.checked ? 2 : null,
                                    // opcion.fecha,
                                    opcion.id_opcion
                                  )
                                }
                              />
                            }
                            label={opcion.opcion2.toUpperCase()}
                          />
                          {opcion.guarnicion21 ? (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                ml: 3,
                              }}
                            >
                              <FormControlLabel
                                required={
                                  (!!opcion.guarnicion21 &&
                                    seleccion &&
                                    seleccion[index].opcion === 2 &&
                                    !seleccion[index].guarnicion) ||
                                  seleccion[index].guarnicion ===
                                    opcion.guarnicion21
                                }
                                label={opcion.guarnicion21}
                                control={
                                  <Checkbox
                                    disabled={
                                      (seleccion[index] &&
                                        seleccion[index].opcion !== 2) ||
                                      opcion.feriado === 1
                                      // || !habilitarMod
                                    }
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "guarnicion",
                                        e.target.checked
                                          ? opcion.guarnicion21
                                          : null
                                      )
                                    }
                                    checked={
                                      seleccion[index] &&
                                      seleccion[index].opcion === 2 &&
                                      seleccion[index].guarnicion ===
                                        opcion.guarnicion21
                                    }
                                  />
                                }
                              />
                            </Box>
                          ) : null}
                          {opcion.guarnicion22 ? (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                ml: 3,
                              }}
                            >
                              <FormControlLabel
                                required={
                                  (!!opcion.guarnicion22 &&
                                    seleccion &&
                                    seleccion[index].opcion === 2 &&
                                    !seleccion[index].guarnicion) ||
                                  seleccion[index].guarnicion ===
                                    opcion.guarnicion22
                                }
                                label={opcion.guarnicion22}
                                control={
                                  <Checkbox
                                    disabled={
                                      (seleccion[index] &&
                                        seleccion[index].opcion !== 2) ||
                                      opcion.feriado === 1
                                      // ||!habilitarMod
                                    }
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "guarnicion",
                                        e.target.checked
                                          ? opcion.guarnicion22
                                          : null
                                      )
                                    }
                                    checked={
                                      seleccion[index] &&
                                      seleccion[index].opcion === 2 &&
                                      seleccion[index].guarnicion ===
                                        opcion.guarnicion22
                                    }
                                  />
                                }
                              />
                            </Box>
                          ) : null}

                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={opcion.feriado === 1}
                                checked={
                                  seleccion[index] &&
                                  seleccion[index].opcion === 3
                                }
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "opcion",
                                    e.target.checked ? 3 : null,
                                    // opcion.fecha,
                                    opcion.id_opcion
                                  )
                                }
                              />
                            }
                            label={opcion.opcion3.toUpperCase()}
                          />
                          {opcion.guarnicion31 ? (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                ml: 3,
                              }}
                            >
                              <FormControlLabel
                                label={opcion.guarnicion31}
                                control={
                                  <Checkbox
                                    required={
                                      (!!opcion.guarnicion31 &&
                                        seleccion &&
                                        seleccion[index].opcion === 3 &&
                                        !seleccion[index].guarnicion) ||
                                      seleccion[index].guarnicion ===
                                        opcion.guarnicion31
                                    }
                                    disabled={
                                      (seleccion[index] &&
                                        seleccion[index].opcion !== 3) ||
                                      opcion.feriado === 1
                                      // || !habilitarMod
                                    }
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "guarnicion",
                                        e.target.checked
                                          ? opcion.guarnicion31
                                          : null
                                      )
                                    }
                                    checked={
                                      seleccion[index] &&
                                      seleccion[index].opcion === 3 &&
                                      seleccion[index].guarnicion ===
                                        opcion.guarnicion31
                                    }
                                  />
                                }
                              />
                            </Box>
                          ) : null}
                          {opcion.guarnicion32 ? (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                ml: 3,
                              }}
                            >
                              <FormControlLabel
                                label={opcion.guarnicion32}
                                disabled={
                                  (seleccion[index] &&
                                    seleccion[index].opcion !== 3) ||
                                  opcion.feriado === 1
                                  //   ||!habilitarMod
                                }
                                control={
                                  <Checkbox
                                    required={
                                      (!!opcion.guarnicion32 &&
                                        seleccion &&
                                        seleccion[index].opcion === 3 &&
                                        !seleccion[index].guarnicion) ||
                                      seleccion[index].guarnicion ===
                                        opcion.guarnicion32
                                    }
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "guarnicion",
                                        e.target.checked
                                          ? opcion.guarnicion32
                                          : null
                                      )
                                    }
                                    checked={
                                      seleccion[index] &&
                                      seleccion[index].opcion === 3 &&
                                      seleccion[index].guarnicion ===
                                        opcion.guarnicion32
                                    }
                                  />
                                }
                              />
                            </Box>
                          ) : null}
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={opcion.feriado === 1}
                                checked={
                                  seleccion[index] &&
                                  seleccion[index].opcion === 0
                                }
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "opcion",
                                    e.target.checked ? 0 : null,
                                    // opcion.fecha,
                                    opcion.id_opcion
                                  )
                                }
                              />
                            }
                            label={"NINGUNO"}
                          />
                        </FormGroup>
                      </FormControl>
                      <Grid item xs={12}>
                        <TextField
                          style={{ backgroundColor: "#fff" }}
                          fullWidth
                          inputProps={{
                            maxLength: 50,
                          }}
                          disabled={
                            opcion.feriado === 1 ||
                            (seleccion[index] && seleccion[index].opcion === 0)
                          }
                          label="Observaciones"
                          value={
                            seleccion[index] && seleccion[index].observaciones
                          }
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "observaciones",
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                  ))}
                  <Grid container direction="row" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      color="success"
                      type="submit"
                      disabled={disBtn}
                    >
                      guardar
                    </Button>
                  </Grid>
                </Container>
              </form>
            )}
          </div>
        </>
      )}

      {/* {
            opciones&&(

            )
          } */}

      {/* <Grid item xs={4}>
            <TextField
            //   id=""
              label="Fecha"
            //   value={}
            //   onChange={}
            type="date"
            />
          </Grid>
          <Grid item xs={8}>
                <FormControl fullWidth>
                  <InputLabel>Opciones</InputLabel>
                  <Select
                    label="Opciones"
                    name="id_tipocliente"
                    // value={props.dataCliente.id_tipocliente}
                    // onChange={(e) => {
                    //   almacenar(e);
                    // }}
                  >
                    {tipoCliente
                      ? tipoCliente.map((elemento) => (
                          <MenuItem
                            key={elemento.idtipocliente}
                            value={elemento.idtipocliente}
                          >
                            {elemento.nombre}
                          </MenuItem>
                        ))
                      : null} 
                  </Select>
                </FormControl>
          </Grid> */}
      {/* </Grid>
      </Container> */}
      <br />
      <br />
      <br />
    </div>
  );
}

export default NuevoPedido;
