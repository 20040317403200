import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import { Typography, Grid, Button, Tab } from "@mui/material";
import DatosProveedor from "./DatosProveedor";
import FormCondEsp from "./FormCondEsp";
import FormCondicionImp from "./FormCondicionImp";
import Container from "@mui/material/Container";
import { v4 as uuidv4 } from "uuid";
import useForm from "../../../Hooks/UseForm";
import UseFetchProveedor from "../hooks/UseFetchProveedor";
import useCuit from "../hooks/useCuit";
import Notify from "../../Notify";
import axios from "axios";
import { UrlApi } from "../../../services/apirest";
import { logEventos } from "../../../services/services";

const TabsAddProv = ({ setnotificacion, alta, onSuccess, tabla }) => {
  const [uuid, setUuid] = useState(uuidv4());
  const [initialForm, setInitialForm] = useState({
    razon_social: null,
    sisven_id: null,
    nombre_fantasia: null,
    direccion: null,
    telefono: null,
    email: null,
    nro_cuit: null,
    cbu: null,
    id_provincia: null,
    id_localidad: null,
    nombre_contacto: null,
    telefono_contacto: null,
    observaciones: null,
    inactivo: 0,
    saldo_en_pesos: null,
    saldo_en_dolares: null,
    factura_sin_orden_compra: null,
    ordende_compra_obligatoriasiempre: null,
    inscripto_ganancias: null,
    es_proveedor_de_gas: null,
    id_condicioniva: null,
    id_condicionventa: null,
    es_instalador: null,
    usuarioapp: null,
    id_impuestoalvalor: null,
    id_impuestoganancias: null,
    id_motivo: null,
    vto_certificado: null,
    id_tipopersona: null,
    id_tipo_operacion: null,
    motivo_otro: null,
    usuario: localStorage.getItem("user"),
    uuid_proveedor: uuid,
  });

  //Custom Hook para formulario
  const { DatosFormulario, onChange, handleChangeCheck } = useForm(initialForm);

  //Custom Hook para Agregar Proveedor
  const { nuevoProveedor } = UseFetchProveedor();
  const { Cuit, handleCuitChange, isValid } = useCuit();
  const [ProvCuit, setProvCuit] = useState([]);

  const [opcion, setopcion] = useState("datos");

  const [NotificacionCuit, setNotificacionCuit] = useState({
    open: null,
    mensaje: null,
    severity: null,
    color: null,
  });
  const [Notificacion, setNotificacion] = useState({
    open: null,
    mensaje: null,
    severity: null,
    color: null,
  });
  const HandleVolver = () => {
    tabla(true);
    alta(false);
  };

  const handleChange = (event, newopcion) => {
    setopcion(newopcion);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (ProvCuit.count_cuit > 0) {
      setNotificacionCuit({
        open: true,
        mensaje: "El Proveedor ya existe",
        severity: "error",
        color: "#FADBD8",
      });
    } else if (validarNull() === true) {
      setNotificacion({
        open: true,
        mensaje: "Falta rellenar campos requeridos",
        severity: "error",
        color: "#FADBD8",
      });
    } else {
      await nuevoProveedor(
        DatosFormulario,
        Cuit,
        setnotificacion,
        onSuccess,
        event
      );
      HandleVolver();
    }
  };
  const obtenerProvCuit = async () => {
    await axios
      .get(UrlApi + "/proveedores/obtcuit/" + Cuit)
      .then((response) => setProvCuit(response.data[0]));
  };
  useEffect(() => {
    obtenerProvCuit();
  }, [Cuit]);

  function validarNull() {
    const camposNulos = ["razon_social", "id_condicioniva", "id_provincia"];

    for (const campo of camposNulos) {
      if (DatosFormulario[campo] === null) {
        return true;
      }
    }

    return false;
  }
  useEffect(() => {
    logEventos(
      "Agregar Proveedor",
      "Proveedores",
      "proveedores",
      localStorage.getItem("user")
    );
  }, []);

  console.log(NotificacionCuit);
  console.log(Notificacion);
  return (
    <div>
      <Container maxWidth="lg">
        <Box sx={{ flexGrow: 1, backgroundColor: "#1F618D" }}>
          <Grid container p={1.5}>
            <Grid
              // item
              xs={12}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h5" color="#ffffff">
                Nuevo Proveedor
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {NotificacionCuit && NotificacionCuit.open ? (
          <Notify
            open={NotificacionCuit.open}
            mensaje={NotificacionCuit.mensaje}
            severity={NotificacionCuit.severity}
            color={NotificacionCuit.color}
          />
        ) : null}
        {Notificacion && Notificacion.open ? (
          <Notify
            open={Notificacion.open}
            mensaje={Notificacion.mensaje}
            severity={Notificacion.severity}
            color={Notificacion.color}
          />
        ) : null}
        <Box sx={{ width: "100%" }} mt={2}>
          <Tabs value={opcion} onChange={handleChange} variant="fullWidth">
            <Tab label="Datos" value="datos" />
            <Tab label="Condicion Impositiva" value="condicion_impositiva" />
            <Tab
              label="Condiciones Especiales"
              value="condiciones_especiales"
            />
          </Tabs>
        </Box>
        <form onSubmit={(event) => handleSubmit(event)} fullWidth>
          <Box
            mb={2}
            mt={2}
            backgroundColor="#F4F6F6"
            p={3}
            border={0.5}
            borderColor={"#ABB2B9"}
            borderRadius={4}
            width="100%"
          >
            {opcion === "datos" ? (
              <DatosProveedor
                DatosFormulario={DatosFormulario}
                onChange={onChange}
                Cuit={Cuit}
                handleCuitChange={handleCuitChange}
                isValid={isValid}
              />
            ) : null}

            {opcion === "condicion_impositiva" ? (
              <FormCondicionImp
                onChange={onChange}
                DatosFormulario={DatosFormulario}
                almacenarChecks={handleChangeCheck}
              />
            ) : null}

            {opcion === "condiciones_especiales" ? (
              <FormCondEsp
                onChange={onChange}
                DatosFormulario={DatosFormulario}
                almacenarChecks={handleChangeCheck}
              />
            ) : null}
          </Box>

          <Grid
            container
            spacing={2}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Grid item>
              <Button onClick={HandleVolver} color="error" variant="contained">
                Volver
              </Button>
            </Grid>
            <Grid item>
              <Button type="submit" color="success" variant="contained">
                Guardar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
      <br />
      <br />
    </div>
  );
};

export default TabsAddProv;
