//----- Component --------------------------------------------------------/

// Developer : Nicolas Pascucci

// Creation Date : 21/05/2024

// Version : 1

// Description : segundo paso de la evaluacion del comercial donde se ingresan los clientes del mes 
//

//------Changes --------------------------------------------------------------------- -------------//

// Change Date :

// Change by :

// Change description:

//

// Change Date :

// Change by :

// Change description:

//

//========================================================================/
import React, { useEffect, useState } from "react";
import Navbar from "../../Navbar";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { Button, Box, Grid, Typography, TextField } from "@mui/material";
import moment from "moment";
import { UrlApi } from "../../../services/apirest";
import { logEventos } from "../../../services/services";

function SegundoStep(props) {
  const [fechaSelect, setFechaSelect] = useState(props.fecha)
  const [subitem, setSubitem] = useState(null);
  const [resultadoAnterior, setResultadoAnterior] = useState(null);
  const [datosVendido, setDatosVendido] = useState({
    clientes: props.clientes,
  });

  const fechaActual = moment(fechaSelect)
  const fechaMesAnterior = fechaActual.subtract(1, "month");
  const primerDiaMesAnterior = moment(fechaMesAnterior).startOf("month");

  const [fecha, setFecha] = useState(primerDiaMesAnterior.format("YYYY-MM-DD"));
  const obtenerSubitems = () => {
    fetch(UrlApi + "/subitems_comerciales/xiditem/" + 3)
      .then((data) => data.json())
      .then((data) => setSubitem(data));
  };

  useEffect(() => {
    obtenerSubitems();
  }, []);

  const obtenerResultadoAnterior = () => {
    fetch(
      UrlApi +
        "/resultados_comerciales/mesusersuc/" +
        localStorage.getItem("user") +
        "/" +
        fecha +
        "/"+
        props.sucursalSeleccionada.id
    )
      .then((data) => data.json())
      .then((data) => setResultadoAnterior(data));
  };

  useEffect(() => {
    obtenerResultadoAnterior();
  }, []);


  const [subitemResultado, setSubitemResultado] = useState(null); // Agrega estado para subitemResultado

  const handleSubitemResultado = (resultado) => {
    setSubitemResultado(resultado);
  };

  const calcularResultado = () => {
    if (resultadoAnterior && resultadoAnterior[0] && resultadoAnterior[0].clientes >= datosVendido.clientes) {
      handleSubitemResultado(subitem.filter(item => item.id_subitem === 9))
    }else{
      handleSubitemResultado(subitem.filter(item => item.id_subitem === 8))
    }
  };
  
  useEffect(() => {
    if (subitemResultado !== null) {
      props.handleNext(
        datosVendido.clientes,
        subitemResultado?subitemResultado[0].porcentaje:null
      );
    }
  }, [subitemResultado]);

  const almacenar = (e) => {
    setDatosVendido({
      ...datosVendido,
      [e.target.name]: e.target.value,
    });
  };

  const [deshabilitado, setDeshabilitado] = useState(false)
  useEffect(() => {
    if (datosVendido.clientes === null) {
      setDeshabilitado(true)
    } else{
      setDeshabilitado(false)
    }
  }, [datosVendido])

  useEffect(() => {
    logEventos("Segundo paso de evaluacion", "Evaluacion Comerciales", "Se ingresan los clientes mensuales", localStorage.getItem("user"))
}, [])

  return (
    <div>
      {" "}
      {props.activeStep === 2 && (
        <div
          style={{
            marginTop: 20,
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid container spacing={1}>
          <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Ingrese la Cantidad de Clientes que Compraron en el Mes de {moment(fechaSelect).format("MMMM")}: 
              </Typography>
            </Grid>
            {props.configuracionVista[0].clientes === 1?  
            <Grid item xs={6} py={1} mb={1}>
              <TextField
                label="Cantidad de Clientes que Compraron En el Anterior Mes"
                name="clientes"
                size="small"
                placeholder="..."
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                inputProps={{
                  maxLength: 200,
                }}
                focused
                value={resultadoAnterior && resultadoAnterior[0]? resultadoAnterior[0].clientes : 0}
                required
                fullWidth
              />
            </Grid>
              :null}
            <Grid item xs={6} py={1} mb={1}>
              <TextField
                label="Cantidad de Clientes que Compraron En el Actual Mes"
                name="clientes"
                size="small"
                placeholder="..."
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                inputProps={{
                  maxLength: 200,
                }}
                focused
                value={datosVendido.clientes}
                onChange={almacenar}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={10}>
            <Button
              variant="contained"
              color="error"
              // disabled={activeStep === 0}
              onClick={props.handleBack}
              sx={{ mr: 1 }}
            >
              Volver
            </Button>
            </Grid>
            <Grid item xs={2}>
              <Button onClick={calcularResultado} fullWidth variant="contained" disabled={deshabilitado}>SIGUIENTE</Button>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
}

export default SegundoStep;
