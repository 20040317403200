import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Alert from '@mui/material/Alert';

export default function Modalcondicionnull(props) {

    const handleClose = () => {
        props.open(false)
    };

    return (
        <Dialog open={props.open} maxWidth="lg">
                <Alert severity="warning" onClose={handleClose} >Atención
                    <Typography>La condición de venta no puede quedar vacia</Typography>
                </Alert>
        </Dialog>
    )

}
