import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Grid,
} from "@mui/material";
import { useState } from "react";
import { UrlApi } from "../services/apirest";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect } from "react";
import { DownloadFiles } from "../services/DownloadFiles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Alert from "@mui/material/Alert";

function UpdatedUploadFiles(props) {
  const [fileList, setFileList] = useState([]);
  const [snack, setSnack] = useState(false);
  const [mensaje, setMensaje] = useState("Guardando Archivos...");
  const [severity, setSeverity]=useState();
  const [archivos, setArchivos] = useState();
  const [imageUrl, setImageUrl] = useState("");

  const soloVer=props.readOnly //si no me lo mandan como prop es undefined

  const getArchivos=()=>{
    fetch(UrlApi + "/uploadfiles/todownload/" + props.fileId)
    .then((data) => data.json())
    .then((data) => setArchivos(data));
  }

  useEffect(() => {
    props.fileId&&getArchivos();
  }, [snack]);

  const borraAdjunto = (e, id, archive) => {
    e.preventDefault();
    if (window.confirm("Usted está por eliminar el archivo: " + archive)) {
      fetch(UrlApi + "/uploadfiles/todownload/" + id, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      }).then((res) =>
        res.ok
          ? (setMensaje("Archivo borrado"),
            setSeverity("success"),
            setSnack(true))
          : (setMensaje("No se borro el archivo"),
          setSeverity("error"),
            setSnack(true))
      );
    }
  };

  const handleFileChange = (e) => {
    setFileList(e.target.files);
  };

  const removefiles = (e) => {
    const s = Array.from(fileList).filter((item, index) => index !== e);
    setFileList(s);
    // console.log(fileList);
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleUploadClick = () => {
    if (!fileList) {
      alert("No se han seleccionado archivos para guardar.")
      // setMensaje("No hay archivos para guardar");
      // setSnack(true);
      // setColorTexto("white");
      // setColorFondo("red");
      return;
    }
    // 👇 Creamos un nuevo objeto FormData y le agregamos los archivos
    const data = new FormData();
    data.append("uid", props.fileId); //--> agregamos 1 identificador unico lo recibimos como parametro (uuid)
    for (let i = 0; i < fileList.length; i++) {
      data.append("archivo", fileList[i]);
    }
    // 👇 Uploading de los archivos con Fetch a la API
    fetch(UrlApi + "/uploadfiles/uploadfiles", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then(
        (data) => setMensaje(data.message),
        setSnack(true),
        setSeverity("success"),
        setFileList([]),
        getArchivos()
      )
      .catch((err) => console.error(err));
  };

  const fetchPdf = async (archivo) => {
    const response = await fetch(UrlApi + "/uploadfiles/uploadspdf/" + archivo);
    setImageUrl(URL.createObjectURL(await response.blob()));
  };

  const fetchImage = async (archivo) => {
    const response = await fetch(UrlApi + "/uploadfiles/uploads/" + archivo);
    setImageUrl(URL.createObjectURL(await response.blob()));
  };

  const ViewFiles = async (archivo, extension) => {
    await extension === 'application/pdf' ? fetchPdf(archivo) : fetchImage(archivo)
};

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <div>
      <Dialog
        open={props.open}
        close={handleClose}
        fullWidth
        PaperProps={{
          sx: {
            // height: 600,
          },
        }}
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          backgroundColor="#1F618D"
          p={1}
        >
          <Grid item xs={1}>
            <UploadFileIcon sx={{ color: "#fff" }} />
          </Grid>
          <Grid item xs={11}>
            <Typography variant="button" color="#fff">
              {props.title ? props.title : "Carga de archivos"}
            </Typography>
          </Grid>
        </Grid>
        {/* </DialogTitle> */}
        <DialogContent>
          {props.fileId && (soloVer === false || soloVer === undefined) ? (
            <Box mb={2}>
              <Button
                disabled={fileList.length !== 0}
                component="label"
                variant="outlined"
                startIcon={<AttachFileIcon />}
              >
                Cargar Archivo
                <VisuallyHiddenInput type="file" onChange={handleFileChange} />
              </Button>
            </Box>
          ) : (
            <>
              {!props.fileId ? (
                <Alert variant="outlined" severity="warning">
                  No hay fileID, consulte a soporte.
                </Alert>
              ) : null}
            </>
          )}
          {props.fileId && fileList ? (
            <Table size="small">
              <TableBody>
                {Array.from(fileList).map(
                  (
                    file,
                    i ///Array.from lo transforma a array
                  ) => (
                    <TableRow key={i} sx={{ border: 1 }}>
                      <TableCell align="left" padding="none">
                        <IconButton size="small" >
                          <DeleteIcon
                            onClick={() => removefiles(i)}
                            // color="secondary"
                          />
                        </IconButton>
                      </TableCell>
                      <TableCell align="left" padding="none">
                        {file.name} - {(file.size / 1000).toFixed(2)} kb
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          ) : null}
          {/* archivos ya adjuntados anteriormente */}
          {/* <Divider /> */}
          <Table size="small">
            <TableBody>
              {props.fileId && archivos
                ? archivos.map((archivo) => (
                    <TableRow key={archivo.id}>
                      <TableCell>
                        <IconButton
                          size="small"
                          color="secondary"
                          component="label"
                          disabled={
                            archivo.extension === "application/pdf" ||
                            archivo.extension === "image/jpeg"
                              ? false
                              : true
                          }
                        >
                          <VisibilityIcon
                            onClick={() =>
                              ViewFiles(
                                archivo.id_archivo + "-" + archivo.nombre,
                                archivo.extension
                              )
                            }
                          />
                        </IconButton>
                        <IconButton
                          size="small"
                          color="success"
                          component="label"
                        >
                          <DownloadForOfflineIcon
                            onClick={() =>
                              DownloadFiles(
                                archivo.id_archivo + "-" + archivo.nombre
                              )
                            }
                          />
                        </IconButton>
                        <IconButton
                          size="small"
                          color="error"
                          component="label"
                          disabled={soloVer}
                        >
                          <DeleteIcon
                            onClick={(e) =>
                              borraAdjunto(e, archivo.id, archivo.nombre)
                            }
                          />
                        </IconButton>
                      </TableCell>
                      <TableCell>{archivo.nombre}</TableCell>
                    </TableRow>
                  ))
                : null}
              {archivos &&
              props.fileId &&
              archivos.length === 0 &&
              fileList.length === 0 ? (
                <Box my={1}>
                  <Alert variant="outlined" severity="info">
                    No hay archivos cargados.
                  </Alert>
                </Box>
              ) : null}
            </TableBody>
          </Table>
        </DialogContent>

        <DialogActions>
          <Grid
            container
            spacing={1}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item>
              <Button onClick={handleClose} variant="outlined" color="error">
                Volver
              </Button>
            </Grid>
{
  soloVer===false || soloVer===undefined ? (
<Grid item>
              <Button
                disabled={!props.fileId || fileList.length === 0}
                onClick={handleUploadClick}
                variant="contained"
                color="success"
              >
                Guardar
              </Button>
            </Grid>

  ): null 
}
            
          </Grid>
        </DialogActions>

        <Snackbar
          open={snack}
          autoHideDuration={3000}
          onClose={() => setSnack(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={() => setSnack(false)}
            severity={severity}
            sx={{ width: "100%" }}
            style={{ backgroundColor: props.color, color: "black" }}
          >
            {mensaje}
          </Alert>

          {/* <SnackbarContent
          style={{
            backgroundColor: colorfondo,
            color: colortexto,
            fontWeight: "bold",
          }}
          message={mensaje}
        /> */}
        </Snackbar>
      </Dialog>
      {imageUrl && window.open(imageUrl, "_blank", "noreferrer")}

    </div>
  );
}

export default UpdatedUploadFiles;
