//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio
// Creation Date : 12/09/2024
// Version : 1
// Description : Tabla de pertenencias
//------Changes -------------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useEffect, useState, useMemo } from "react";
import { UrlApi } from "../../services/apirest";
import { Container, Typography, Box, Button } from "@mui/material";
import Navbar from "../Navbar";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import TablaPertenencias2 from "./TablaPertenencias2";
import AgregarPertenencia from "./AgregarPertenencia";
import { logEventos } from "../../services/services";

function TablaPertenencias() {
  const [tanque, setTanque] = useState(); // p/tabla
  const [tabla, setTabla] = useState(true);
  const [alta, setAlta] = useState(false); //Estado q mostrará o no la pantalla de alta
  const [datosTanque, setDatosTanque] = useState({});
  const globalTheme = useTheme();
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
  const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
  useEffect(() => {
    logEventos("Acceso al menú Pertenencias Cheklist", "Pertenencias Cheklist", "Agregar nuevas pertenencias (grupo y tanque)", localStorage.getItem("user"))
}, [])
  //*************************************************************************************************************************************/
  const obtenerActivos = () => {
    fetch(UrlApi + "/tanque_rehabilitacion/funciona")
      .then((data) => data.json())
      .then((data) => setTanque(data));
  };

  // console.log("TANQUES", tanque);
  //ejecuta la funcion de obtener
  useEffect(() => {
    obtenerActivos();
  }, [tabla]);
  //*************************************************************************************************************************************/
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)" //background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
            },
          },
        },
      }),
    [globalTheme]
  );
  const columns = useMemo(
    () => [
      {
        header: "Matrícula",
        accessorKey: "matricula",
        size: 100,
      },
      {
        header: "N° Auditoría",
        accessorKey: "num_auditoria",
        size: 100,
      },
      {
        header: "Fabricante",
        accessorKey: "fabricante",
        size: 100,
      },
      {
        header: "Patente",
        accessorKey: "patente",
        size: 100,
      },
      {
        header: "Volumen",
        accessorKey: "volumen",
        size: 100,
      },
    ],
    []
  );
  const initialState = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
  };
  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };
  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "success",
    size: "small",
  };
  //*************************************************************************************************************************************/

  const handleClickNuevo = () => {
    setAlta(true);
    setTabla(false);
  };

  return (
    <div>
      <Navbar />
      <Container maxWidth="xl" mt={1} mb={2}>
        {tanque && tabla ? (
          <Box mt={2} boxShadow={3}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
                enableColumnResizing
                columns={columns}
                data={tanque}
                initialState={initialState}
                positionActionsColumn="last"
                enableExpandAll={false}
                enableToolbarInternalActions={true}
                muiTableHeadCellProps={tableHeadCellProps}
                muiTableBodyCellProps={({ row }) => ({
                  align: "left",
                })}
                enableColumnActions={true}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                enableDensityToggle={false}
                enableGrouping
                renderTopToolbarCustomActions={() => (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddBoxTwoToneIcon />}
                      onClick={handleClickNuevo}
                    >
                      Nueva Pertenencia
                    </Button>
                    <Typography variant="poster" fontSize={26}>
                      <LocalShippingIcon sx={{ size: 20 }} />
                      Tabla para checklist
                    </Typography>
                  </>
                )}
                layoutMode="grid"
                localization={MRT_Localization_ES}
                enableColumnDragging={false} //No permite que se arrastren las columnas.
                // enableEditing
                // enableRowActions
                // ACCIONES
                renderDetailPanel={({ row }) => (
                  <div style={{ marginTop: "-50px" }}>
                    <Box
                      sx={{
                        width: "200%",
                      }}
                    >
                      <TablaPertenencias2 row={row} />
                    </Box>
                  </div>
                )}
                renderToolbarInternalActions={(
                  { table } // boton para nuevo , al lado del buscar
                ) => (
                  <>
                    <MRT_FullScreenToggleButton table={table} />
                  </>
                )}
              />
            </ThemeProvider>
            <br />
            <br />
          </Box>
        ) : null}
        {alta ? (
          <AgregarPertenencia
            alta={setAlta}
            tabla={setTabla}
          ></AgregarPertenencia>
        ) : null}
      </Container>
    </div>
  );
}

export default TablaPertenencias;
