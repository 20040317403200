import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import PlaceIcon from "@mui/icons-material/Place";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import DirectionsIcon from "@mui/icons-material/Directions";
import BusinessIcon from "@mui/icons-material/Business";
import MapIcon from "@mui/icons-material/Map";
import Button from "@mui/material/Button";
import zIndex from "@mui/material/styles/zIndex";


import { UrlApi } from "../../../../../services/apirest";
import Mapa from "../../../../../services/Mapa";

function ModalInfoDireccion(props) {
  const [location, setLocation] = useState({
    latitud: props.direccion.latitud,
    longitud: props.direccion.longitud,
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "0.5px solid #000",
    boxShadow: 24,
    p: 2,
  };

  const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "600px",
    // bgcolor: 'background.paper',
    bgcolor: "#fff",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  function ChildModal() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };

    return (
      <React.Fragment>
        <Grid container justifyContent="space-between">
          <Button
            variant="outlined"
            color="error"
            onClick={() => props.setOpen(false)}
          >
            cerrar
          </Button>
          {/* <Button
            variant="contained"
            color={props.direccion.verificada === 1 ? "success" : "warning"}
            startIcon={
              <MapIcon
              />
            }
            onClick={handleOpen}
          >
            Verificar Ubicación En mapa
          </Button> */}
        </Grid>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          style={{zIndex:1}}
        >
          <Box sx={{ ...style2 }}>
            {/* <Box
              backgroundColor="#1F618D"
              justifyContent="center"
              alignItems="center"
              p={1}
            >
              <Typography
                fontSize={14}
                textAlign="center"
                variant="caption"
                color="#ffffff"
              >
                Verificar Ubicación De Domicilio
              </Typography>
            </Box> */}
            <Mapa
              idDireccion={props.direccion.id}
              handleClose={handleClose}
            />
          </Box>
        </Modal>
      </React.Fragment>
    );
  }

  return (
    <div>
      <Modal open={props.open} onClose={() => props.setOpen(false)}>
        <Box sx={style}>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            wrap="wrap"
            backgroundColor="#1F618D"
            pb={1}
          >
            <Grid item xs={10}>
              <Typography variant="h6" component="h2" color="#ffffff">
                Datos Dirección De Entrega
              </Typography>
            </Grid>
          </Grid>
          <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: "#1F618D" }}>
                  <MapIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={props.direccion.provincia}
                secondary={props.direccion.localidad}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: "#1F618D" }}>
                  <PlaceIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={props.direccion.nombre_direccion}
                secondary={props.direccion.direccion_entrega}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: "#1F618D" }}>
                  <ContactPhoneIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Contacto"
                secondary={
                  (props.direccion.telefono1 ? props.direccion.telefono1 : "") +
                  " / " +
                  (props.direccion.telefono2 ? props.direccion.telefono2 : "") +
                  " / " +
                  (props.direccion.telefono3 ? props.direccion.telefono3 : "")
                }
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: "#1F618D" }}>
                  <BusinessIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={props.direccion.sucursal} />
            </ListItem>
          </List>
          <ChildModal />
        </Box>
      </Modal>
    </div>
  );
}

export default ModalInfoDireccion;
