import React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import moment from "moment";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import HandymanIcon from "@mui/icons-material/Handyman";
import { Container } from "@mui/material";
import PersonSearchSharpIcon from "@mui/icons-material/PersonSearchSharp";
import EventIcon from "@mui/icons-material/Event";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ConstructionIcon from "@mui/icons-material/Construction";
import DomainIcon from "@mui/icons-material/Domain";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
// import Certificacionestanques from "./Certificacionestanques";
import Grid from "@mui/material/Grid";

function FichaTanque(props) {
  // const [certificaciones, setCertificaciones] = useState(false);
  const [datosformulario, setDatosformulario] = useState(props.datos); //aca viene el rowdata
  const [color, setColor] = useState("primary");

  console.log(props.datos);

  const handleClose = () => {
    props.ficha(false);
    props.tabla(true);
  };
  //************************************************************************************************* */
  //Certificaciones
  // const handleCloseCert = () => {
  //   setCertificaciones(false);
  //   setVertanques(true);
  // };

  // const mostrarCertificaciones = (datosformulario) => {
  //   setCertificaciones(true);
  //   setVertanques(false);
  //   setDatosTanque(datosformulario);
  // };

  return (
    <div>
      {/* <Container> */}
        {datosformulario ? (
          <Box
            mt={1}
            backgroundColor="#F4F6F6"
            p={3}
            mb={8}
            border={0.5}
            borderColor={"#ABB2B9"}
            borderRadius={4}
          >
            {/* <Grid
              sx={{
                backgroundColor: "#e0e0e0",
                flexGrow: 1,
                border: 0.5,
                mb: 2,
                borderRadius: 2,
                textAlign: "center",
                borderColor: "#90a4ae",
              }}
            >
              <Grid item>
                <h3>Tanque serie N°: {datosformulario.nro_serie} </h3>
              </Grid>
            </Grid> */}
            <Grid container spacing={1.5}>
              <Grid item xs={3}>
                <TextField
                  variant="filled"
                  placeholder="No registrado"
                  type="text"
                  focused
                  name="nro_serie"
                  label="Numero de Serie"
                  color={color}
                  value={datosformulario.nro_serie}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <StickyNote2Icon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  variant="filled"
                  placeholder="No registrado"
                  type="text"
                  focused
                  name="id_sisven"
                  label="Numero Sisven"
                  color={color}
                  value={datosformulario.id_sisven}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <StickyNote2Icon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  focused
                  color={color}
                  placeholder="No registrado"
                  name="capacidad"
                  id="capacidad"
                  label="Capacidad"
                  value={datosformulario.capacidad}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">m3</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="filled"
                  focused
                  fullWidth
                  color={color}
                  placeholder="No registrado"
                  name="estado_fisico"
                  id="estado_fisico"
                  label="Estado Físico"
                  value={datosformulario.descr_estado}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <HandymanIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="filled"
                  focused
                  color={color}
                  placeholder="No registrado"
                  name="duenio"
                  id="duenio"
                  label="Dueño"
                  value={datosformulario.descr_duenio}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountBoxIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="filled"
                  focused
                  color={color}
                  placeholder="No registrado"
                  name="nombre_fabricante"
                  id="fabricante"
                  label="Fabricante"
                  value={datosformulario.nombre_fabricante}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <ConstructionIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  placeholder="No registrado"
                  variant="filled"
                  focused
                  color={color}
                  id="date"
                  label="Fecha de fabricacion"
                  name="fecha_fabricacion"
                  type="date"
                  value={moment(datosformulario.fecha_fabricacion).format(
                    "YYYY-MM-DD"
                  )}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EventIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  fullWidth
                  placeholder="No registrado"
                  variant="filled"
                  focused
                  color={color}
                  id="date"
                  label="Fecha de compra"
                  name="fecha_compra"
                  type="date"
                  value={moment(datosformulario.fecha_compra).format(
                    "YYYY-MM-DD"
                  )}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <EventIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  placeholder="No registrado"
                  variant="filled"
                  focused
                  color={color}
                  id="date"
                  label="Sucursal"
                  name="sucursal"
                  value={datosformulario.sucursal}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DomainIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  placeholder="No registrado"
                  variant="filled"
                  focused
                  color={color}
                  id="date"
                  label="Fecha Ingreso Sucursal"
                  name="fecha_ubicacion"
                  type="date"
                  value={moment(datosformulario.fecha_ubicacion).format(
                    "YYYY-MM-DD"
                  )}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <EventIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  placeholder="No registrado"
                  variant="filled"
                  focused
                  color={color}
                  id="date"
                  label="Estado"
                  name="estado"
                  value={datosformulario.estado_tanque}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <HomeRepairServiceIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  placeholder="No registrado"
                  variant="filled"
                  focused
                  color={color}
                  id="date"
                  label="Fecha Estado"
                  name="fecha_estado"
                  type="date"
                  value={moment(datosformulario.fecha_estado).format(
                    "YYYY-MM-DD"
                  )}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <EventIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="filled"
                  focused
                  color={color}
                  label="Vencimiento Prueba Hidrálica"
                  name="fecha_estado"
                  type="date"
                  value={moment(datosformulario.vto_pruebahidraulica).format(
                    "YYYY-MM-DD"
                  )}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <EventIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  placeholder="No registrado"
                  variant="filled"
                  focused
                  color={color}
                  id="date"
                  label="Observaciones"
                  name="observaciones"
                  value={datosformulario.observaciones}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonSearchSharpIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            {/* <Grid
              mt={1}
              container
              spacing={1}
              direction="row"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              wrap="wrap"
            >
              <Grid item xs={3}>
                <Button
                  onClick={() => mostrarCertificaciones(datosformulario)} //LE PASO COMO PARAMETRO A LA FUNCION TODA LA LINEA
                  variant="contained"
                  color={color}
                >
                  Certificaciones de tanque
                </Button>
              </Grid>
            </Grid> */}
          </Box>
        ) : null}
        {/* {certificaciones ? (
          <Container>
            <br></br>
            <Box sx={{ "& > :not(style)": { m: 1 } }} autoComplete="on">
              <h3>
                <IconButton onClick={handleCloseCert} aria-label="delete">
                  <ArrowBackIcon />
                </IconButton>
                Certificaciones tanque serie: {datostanque.nro_serie}
              </h3>
            </Box>
            <Certificacionestanques
              datos={datostanque}
              certificaciones={setCertificaciones}
              vertanques={setVertanques}
            />
          </Container>
        ) : null} */}
      {/* </Container> */}
    </div>
  );
}

export default FichaTanque;
