import React, { useRef, useState, useEffect } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

const containerStyle = {
  width: "100%",
  height: "100%",
};

function MapaCliente(props) {
  const mapRef = useRef(null);
  const inputRef = useRef();
  const [mapLoaded, setMapLoaded] = useState(false);

  const handlecambiolugar = () => {
    const [places] = inputRef.current.getPlaces();
    if (places) {
      props.setDataDir({
        ...props.dataDir,
        latitud: places.geometry.location.lat(),
        longitud: places.geometry.location.lng(),
        direccion_entrega: places.formatted_address,
      });
      props.setLatLongParams({ ...props.latLongParams, readOnly: true });
    }
  };

  useEffect(() => {
    if (props.dataDir.longitud && props.dataDir.latitud) {
      props.setMapParams({
        ...props.mapParams,
        center: {
          lat: props.dataDir.latitud * 1,
          lng: props.dataDir.longitud * 1,
        },
        zoom: 15,
      });
    }
  }, [props.dataDir.latitud, props.dataDir.longitud]);

  const calcularDistanciaConDireccion = () => {
    const { latitud: lat1, longitud: lng1 } = props.dataSucursal || {};
    const { latitud: lat2, longitud: lng2 } = props.dataDir || {};

    if (lat1 && lng1 && lat2 && lng2) {
      const directionsService = new window.google.maps.DirectionsService();

      const request = {
        origin: new window.google.maps.LatLng(lat1, lng1),
        destination: new window.google.maps.LatLng(lat2, lng2),
        travelMode: window.google.maps.TravelMode.DRIVING, // Puedes cambiar a WALKING, BICYCLING, etc.
      };

      directionsService.route(request, (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          const distance = result.routes[0].legs[0].distance.text;
          props.setDataFact({
            ...props.dataFact,
            dist_suc_cli: parseFloat(distance),
          });
        } else {
          console.log("error");
        }
      });
    }
  };

  useEffect(() => {
    if (
      mapLoaded &&
      !!props.dataDir.latitud &&
      !!props.dataDir.longitud &&
      !!props.dataDir.id_sucursal
    ) {
      calcularDistanciaConDireccion();
    }
  }, [
    props.dataDir.latitud,
    props.dataDir.longitud,
    props.dataSucursal.latitud,
    props.dataSucursal.longitud,
    mapLoaded,
  ]);

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_Google_maps_key}
      libraries={["places", "geometry", "directions"]}
      onLoad={() => setMapLoaded(true)}
    >
      {/* {!props.readOnly ? ( */}
        <StandaloneSearchBox
          onLoad={(ref) => (inputRef.current = ref)}
          onPlacesChanged={handlecambiolugar}
        >
          <Grid container direction="row" mb={2}>
            {
              //Para que NO se muestre, se tiene que dar:
              //La factibilidad tiene que ser una ampliacion en domicilio existente
              //El domicilio debe tener latitud y longitud
              // console.log("props en mapa", props)
              !(props.direccionAmpliacion && (props.dataFact.id_tipofact ===236 && !!props.direccionAmpliacion.latitud && !!props.direccionAmpliacion.longitud))&&(
<Grid item xs={12}>
              <TextField
                disabled={props.readOnly}
                // variant={props.direccionAmpliacion? !props.direccionAmpliacion.latitud || !props.direccionAmpliacion.longitud ? "outlined":"standard"}
                // variant={props.direccionAmpliacion? !props.direccionAmpliacion.latitud || !props.direccionAmpliacion.latitud ? "outlined" : "starndard"}
                variant={props.direccionAmpliacion && (!props.direccionAmpliacion.latitud ||!props.direccionAmpliacion.longitud) ? "outlined" : "standard"}
                focused={props.direccionAmpliacion && (!props.direccionAmpliacion.latitud || !props.direccionAmpliacion.longitud)}
                color={props.direccionAmpliacion && (!props.dataDir.latitud || !props.dataDir.longitud ? "warning":"primary")}
                label={props.txtFieldLabel}
                fullWidth
                placeholder="Ingrese direccion"
                size="small"
                value={props.dataDir.direccion_entrega}
                onChange={(e) => {
                  props.setDataDir({
                    ...props.dataDir,
                    direccion_entrega: e.target.value,
                  });
                  if (e.target.value === "") {
                    props.setDataDir({
                      ...props.dataDir,
                      longitud: 0,
                      latitud: 0,
                      direccion_entrega: "",
                    });
                    props.setDataFact((dataFact) => ({
                      ...dataFact,
                      dist_suc_cli: 0,
                    }));
                    props.setLatLongParams({
                      ...props.latLongParams,
                      readOnly: false,
                    });
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        color="error"
                        disabled={props.readOnly}
                        onClick={() => {
                          props.setDataFact({
                            ...props.dataFact,
                            dist_suc_cli: "",
                          });
                          props.setDataDir({
                            ...props.dataDir,
                            latitud: 0,
                            longitud: 0,
                            direccion_entrega: "",
                          });
                          props.setMapParams({
                            ...props.mapParams,
                            zoom: 3,
                          });
                          props.setLatLongParams({
                            ...props.latLongParams,
                            readOnly: false,
                          });
                        }}
                        onKeyDown={()=>
                          props.setActiveTab("artefactos")
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
              )
            }
            
          </Grid>
        </StandaloneSearchBox>
      {/* // ) : null} */}

      <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={props.mapParams.zoom}
        onLoad={(map) => (mapRef.current = map)}
        center={props.mapParams.center}
      >
        <Marker
          position={{
            lat: props.dataSucursal && props.dataSucursal.latitud * 1,
            lng: props.dataSucursal && props.dataSucursal.longitud * 1,
          }}
        />
        {props.dataDir.longitud && props.dataDir.latitud ? (
          <Marker
            position={{
              lat: props.dataDir.latitud * 1,
              lng: props.dataDir.longitud * 1,
            }}
            draggable={!props.readOnly}
            onDragEnd={(p) => {
              props.setDataDir({
                ...props.dataDir,
                latitud: p.latLng.lat(),
                longitud: p.latLng.lng(),
              });
            }}
          />
        ) : null}
      </GoogleMap>
    </LoadScript>
  );
}

export default React.memo(MapaCliente);
