import React,{useState,useEffect,useMemo} from 'react'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider, useTheme,Typography,Button } from "@mui/material";
import Edit from '@mui/icons-material/Edit';
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import axios from 'axios';
import { UrlApi } from '../../../services/apirest';
import Addimputacion from './Addimputacion';
import Navbar from '../../Navbar';
import ArticleIcon from '@mui/icons-material/Article';
const TablaImputaciones = () => {

  const [Imputacion, setImputacion] = useState([])
  const [CreateModalOpen, setCreateModalOpen] = useState(false)
  const [Tabla, setTabla] = useState(true)
  const [Bandera, setBandera] = useState(0)



  function getImputaciones() {
    axios.get(UrlApi + '/imputacion_gastos').then((res) => {
      setImputacion(res.data);
    })
  };

  useEffect(() => {
    getImputaciones()
  }, [Bandera])


  const columns = useMemo(() => [
    { header: 'Numero', accessorKey: 'idimputacion' },
    { header: 'Descripcion', accessorKey: 'descripcion' },
    { header: 'Abreviatura', accessorKey: 'abreviatura' },
    { header: 'Cuenta Contable', accessorKey: 'cuenta_contable' }
], []);
 
const muiSearchTextFieldProps = {
  placeholder: "Buscar..",
  sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
  variant: "outlined",
  color: "primary",
  size: "small",
};

const initialState = {
  density: "compact",
  showGlobalFilter: true,
  pagination: {
    pageSize: 15,
    pageIndex: 0,
  },
};

const tableHeadCellProps = {
  align: "center",
  sx: {
    fontSize: 16,
    color: "white",
    fontWeight: "normal",
    backgroundColor: "#2471A3",
    borderColor: "black",
  },
};

const globalTheme = useTheme();
const tableTheme = useMemo(
  () =>
    createTheme({
      palette: {
        mode: globalTheme.palette.mode,
        primary: globalTheme.palette.primary, //primary color for the table
        info: {
          main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
        },
        background: {
          default:
            globalTheme.palette.mode === "light"
              ? "rgb(234, 239, 241)"
              : "#000", //pure black table in dark mode for fun
        },
      },
      typography: {
        button: {
          textTransform: "none", //customize typography styles for all buttons in table by default
          fontSize: "1rem",
        },
      },
      components: {
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              fontSize: "0.8rem", //larger tooltip font size
            },
          },
        },
      },
    }),
  [globalTheme]
);

  const close = () => {
    setCreateModalOpen(false); 
    setTabla(true);
  }
  return (
    <div>
      <Container  maxWidth >
        <Navbar></Navbar>
        <br />
      {Imputacion && Tabla ?
      <ThemeProvider theme={tableTheme}>
      <MaterialReactTable
                columns={columns}
                data={Imputacion}
                localization={MRT_Localization_ES}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                //displayColumnDefOptions={displayColumnDefOptions}
                enableExpandAll={false}
                // enableGlobalFilter={true}
                enableToolbarInternalActions={true}
                //enableTopToolbar={false} // habilita o deshabilita TODA la barra de herramientas de arriba
                enableColumnResizing
                layoutMode="grid"
                muiTableHeadCellProps={tableHeadCellProps}
                muiTableBodyCellProps={{
                    align: "center",
                }}
                enableColumnActions={false}
                enableColumnFilters={false} //desactiva filtros x  columna
                enableHiding={false}
                enableDensityToggle={false}
                enableColumnDragging={false} //No permite que se arrastren las columnas.
        
                initialState={initialState}
                enableStickyFooter
                positionActionsColumn="last"
                 renderTopToolbarCustomActions={() => (
                  <>
                    <Button
                        color="primary"
                        onClick={() => setCreateModalOpen(true)}
                        variant="contained">
                        Nueva Imputacion
                    </Button>

                    <Typography variant="poster" fontSize={26}>
                      <ArticleIcon sx={{ fontSize: 20 }} />
                      Imputaciones
                    </Typography>
                    </>
                )} 
            /> 
            </ThemeProvider>
            : console.log("546465146")}
            
            {CreateModalOpen ? <Addimputacion open={setCreateModalOpen} close={close} setBadera={setBandera} /> : null} 
      </Container>
    </div>
  )
}

export default TablaImputaciones