import { useState } from "react";
import { UrlApi } from "../../../services/apirest";
import {
  Typography,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  DialogTitle,
} from "@mui/material";
import Notify from "../../Notify";

const ModalAltaBaja = (props) => {
  const { datosFormulario, confirmar, handleVolver, getProv } = props;

  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [openSnack, setOpenSnack] = useState(false); //abre y cierra snack
  const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
  const [error, setError] = useState(null); //gu
  const marcarInactivo = async (e) => {
    e.preventDefault();
    const response = await fetch(
      UrlApi + "/proveedores/inactivo/" + datosFormulario.id,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(
          datosFormulario.inactivo === 1 ? { inactivo: 0 } : { inactivo: 1 }
        ),
      }
    );
    if (response.ok === false) {
      const error = response && response.json();
      setError(error);
      alert("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      try {
        const json = await response.json();
        if (json.message) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje(
            json.message === "OK" ? "Guardado" : "Guardado. Actualizar RTA Api."
          );
          getProv();
        } else if (json.sqlMessage) {
          json && setError(json);
          alert("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
        } else {
          alert("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje("Guardado");
          getProv();
        } else {
          alert("Error: " + error);
        }
      }
    }
    handleVolver();
    getProv();
  };
  return (
    <>
      {confirmar ? (
        <div>
          <Dialog open={confirmar} onClose={handleVolver}>
            <form onSubmit={marcarInactivo}>
              <Box backgroundColor={"#1F618D"}>
                <DialogTitle>
                  {datosFormulario.inactivo === 0 ? (
                    <Typography variant="h6" align="center" color="#ffffff">
                      Alta del Proveedor {datosFormulario.razon_social}
                    </Typography>
                  ) : (
                    <Typography variant="h6" align="center" color="#ffffff">
                      Baja del Proveedor {datosFormulario.razon_social}
                    </Typography>
                  )}
                </DialogTitle>
              </Box>
              <DialogContent>
                <DialogContentText textAlign={"center"}>
                  {datosFormulario.inactivo === 1 ? (
                    <>
                      {" "}
                      ¿Desea dar de Alta del Proveedor{" "}
                      {datosFormulario.razon_social}?{" "}
                    </>
                  ) : (
                    <>
                      {" "}
                      ¿Desea dar de Baja del Proveedor{" "}
                      {datosFormulario.razon_social}?{" "}
                    </>
                  )}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItemns="center"
                >
                  <Button
                    variant="contained"
                    onClick={handleVolver}
                    style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
                  >
                    Volver
                  </Button>
                  <Button
                    style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Confirmar
                  </Button>
                </Grid>
              </DialogActions>
            </form>
          </Dialog>
        </div>
      ) : null}
      {notificacion ? (
        <Notify
          mensaje={mensaje}
          open={openSnack}
          color={"#D4EFDF"}
          severity={"success"}
          handleClose={handleVolver}
        />
      ) : null}
    </>
  );
};

export default ModalAltaBaja;
