import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { UrlApi } from "../../../../../services/apirest";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { v4 as uuidv4 } from "uuid"; ///me da un unico id para poner en los pedidos y repartos
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AddBateria from "../../datoscliente/direccionescliente/AddBateria";
import InputMask from "react-input-mask";
import Notify from "../../../../Notify";
import Alerta from "../../../../Alerta";
import DialogContentText from "@mui/material/DialogContentText";

function FormTanqueRapido(props) {
  const [datos, setDatos] = useState({
    nro_serie: "",
    capacidad: "",
    duenio: "",
    id_direccion: props.direccion.id,
    uuidTanque: "", // este va a ser el uuid del tanque
    id_bateria: "",
    id_cliente: props.datosCliente.id,
  });
  const [duenios, setDuenios] = useState([{}]);
  const [baterias, setBaterias] = useState(null);
  const [uuidTq, setUuidTq] = useState("");
  const [idBateria, setIdBateria] = useState("");
  const [altaBateria, setAltaBateria] = useState(false);
  const [ultimaBateria, setearUltimaBateria] = useState(false);

  //Manejo de errores
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [openSnack, setOpenSnack] = useState(false); //abre y cierra snack
  const [severityAlerta, setSeverityAlerta] = useState(""); //abre y cierra snack
  const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
  const [mensajeAlerta, setMensajeAlerta] = useState(""); //mensaje que contendrá el componente "alerta"
  const [desBtn, setDesBtn] = useState(false);

  const obtenerDuenios = () => {
    // fetch(UrlApi + "/desplegables/modulo/dueniotanque")
    fetch(UrlApi + "/desplegables/dueniotanque")
      .then((data) => data.json())
      .then((data) => setDuenios(data));
  };

  const obtenerBaterias = () => {
    fetch(UrlApi + "/baterias2/dir/" + props.direccion.id)
      .then((data) => data.json())
      .then((data) => setBaterias(data));
  };

  useEffect(() => {
    obtenerDuenios();
    obtenerBaterias();
    setUuidTq(uuidv4());
  }, []);

  useEffect(() => {
    obtenerBaterias();
  }, [altaBateria]);

  const almacenarDatos = (e) => {
    setDatos({ ...datos, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (
      ultimaBateria === true &&
      baterias &&
      baterias[0] &&
      baterias[0].idbateria
    ) {
      setIdBateria(baterias[0].idbateria);
      setearUltimaBateria(false);
    }
  }, [baterias]);

  const handleClose = () => {
    props.setOpen(false);
  };

  async function guardarTanque(e) {
    e.preventDefault();
    setDesBtn(true);
    const response = await fetch(UrlApi + "/tanques", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        nro_serie: datos.nro_serie,
        capacidad: datos.capacidad === "" ? 0 : datos.capacidad,
        duenio: datos.duenio,
        baja: 0,
        id_estado: 6,
        delivery_stop: 0,
        usuario: localStorage.getItem("user"),
        id_sucursal: null,
        id_fabricante: 1,
        estado_fisico: 90,
        nro_tanque: uuidTq,
        incompleto: 1,
        // incompleto: datos.duenio === 137 ? 0 : 1,
      }),
    });
    if (response.status !== 200) {
      alert("ERROR AL GUARDAR");
    } else {
      try {
        const json = await response.json();
        if (json.message) {
          guardarRegistro(e);
          json.message !== "OK" &&
            console.log("ACTUALIZAR LA RESPUESTA DE LA API");
        } else if (json.sqlMessage) {
          json && setError(json);
          alert("ERROR SQL");
        } else {
          alert("ERROR DESCONOCIDO");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          guardarRegistro(e);
        } else {
          alert("ERROR DESCONOCIDO");
        }
      }
    }
  }

  async function guardarRegistro(e) {
    e.preventDefault();
    const response = await fetch(UrlApi + "/tanques_contratos", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_direccionentrega: datos.id_direccion,
        id_bateria: idBateria ? idBateria : null,
        baja: 0,
        uuid_tanque: uuidTq,
        usuario: localStorage.getItem("user"),
      }),
    });
    if (response.status !== 200) {
      alert("ERROR AL GUARDAR");
    } else {
      try {
        const json = await response.json();
        if (json.message) {
          guardarMovimiento(e);
          json.message !== "OK" &&
            console.log("ACTUALIZAR LA RESPUESTA DE LA API");
        } else if (json.sqlMessage) {
          json && setError(json);
          alert("ERROR SQL");
        } else {
          alert("ERROR DESCONOCIDO");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          guardarMovimiento(e);
        } else {
          alert("ERROR DESCONOCIDO");
        }
      }
    }
  }

  async function guardarMovimiento(e) {
    e.preventDefault();
    const response = await fetch(UrlApi + "/movimientos_tanques", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_cliente: datos.id_cliente,
        uuid_tanque: uuidTq,
        usuario: localStorage.getItem("user"),
      }),
    });
    if (response.status !== 200) {
      alert("ERROR AL GUARDAR");
    } else {
      try {
        const json = await response.json();
        if (json.message) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje(
            json.message === "OK" ? "Guardado" : "Guardado. Actualizar RTA Api"
          );
          console.log("ACTUALIZAR LA RESPUESTA DE LA API");
          props.actualizar();
        } else if (json.sqlMessage) {
          json && setError(json);
          alert("NO SE REGISTRO EL MOVIMENTO DEL TANQUE. ERROR SQL");
        } else {
          alert("ERROR DESCONOCIDO");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje("Guardado. Actualizar RTA Api a Json");
          props.actualizar();
        } else {
          alert("ERROR DESCONOCIDO");
        }
      }
    }
  }

  return (
    <div>
      {notificacion ? (
        <Notify
          mensaje={mensaje}
          open={openSnack}
          color={"#D4EFDF"}
          handleClose={handleClose}
          severity={"success"}
        />
      ) : null}
      {/* ALERT --> SE MUESTRA SI HAY UN ERROR EN LA API (NO SE CONECTA O ESTÁ MAL LA RUTA, ETC.) */}
      {error && mensajeAlerta ? (
        <Alerta mensaje={mensajeAlerta} severity={severityAlerta} />
      ) : null}

      <Dialog
        open={props.open}
        onClose={() => props.setOpen(false)}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              minWidth: "500px", // Set your width here
            },
          },
        }}
      >
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          wrap="wrap"
          backgroundColor="#1F618D"
        >
          <Grid item xs={12}>
            <DialogTitle>
              <Typography variant="h6" component="h2" color="#ffffff">
                Nuevo Tanque
              </Typography>
            </DialogTitle>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          direction="row"
          justify="center"
          alignItems="center"
          alignContent="center"
          wrap="nowrap"
          px={2}
          mt={1}
        >
          <Grid item>
            <DialogContentText>
              <strong>Atención</strong>: El tanque nuevo se ubicará en el
              domicilio: <b>{props.direccion.direccion_entrega}</b>. Los datos
              faltantes deberán completarse a la brevedad.
            </DialogContentText>
          </Grid>
        </Grid>

        <form onSubmit={guardarTanque}>
          <DialogContent>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={3}>
                  <InputLabel color="primary" focused>
                    Dueño
                  </InputLabel>
                </Grid>

                <Grid item xs={6}>
                  <FormControl
                    required
                    autoFocus
                    size="small"
                    fullWidth
                    focused
                    margin="dense"
                  >
                    <Select
                      name="duenio"
                      value={datos.duenio}
                      onChange={almacenarDatos}
                      color="primary"
                    >
                      {duenios
                        ? duenios.map((elemento) => (
                            <MenuItem key={elemento.id} value={elemento.id}>
                              {elemento.valor}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={3}>
                  <InputLabel color="primary" focused>
                    N° Serie/Nombre
                  </InputLabel>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="nro_serie"
                    margin="dense"
                    focused
                    size="small"
                    required
                    fullWidth
                    variant="outlined"
                    onChange={almacenarDatos}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={3}>
                  <InputLabel color="primary" focused>
                    Capacidad
                  </InputLabel>
                </Grid>
                <Grid item xs={6}>
                  <InputMask
                    mask="9.99"
                    value={datos.capacidad}
                    disabled={false}
                    maskChar="0"
                    onChange={almacenarDatos}
                  >
                    {() => (
                      <TextField
                        margin="dense"
                        size="small"
                        fullWidth
                        // required={datos.duenio !==137}
                        type="text"
                        focused
                        name="capacidad"
                      />
                    )}
                  </InputMask>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={3}>
                  <InputLabel color="primary" focused>
                    Bateria
                  </InputLabel>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth focused size="small" margin="dense">
                    <Select
                      name="idBateria"
                      focused
                      value={idBateria}
                      onChange={(e) => setIdBateria(e.target.value)}
                    >
                      {baterias
                        ? baterias.map((elemento) => (
                            <MenuItem
                              key={elemento.idbateria}
                              value={elemento.idbateria}
                            >
                              {elemento.nro} - {elemento.nombre}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={1}>
                  <Tooltip title="Borrar Selección">
                    <span>
                      <IconButton
                        color="primary"
                        size="large"
                        onClick={() => setIdBateria(null)}
                      >
                        <DeleteIcon fontSize="inherit" />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs={1}>
                  <Tooltip title="Nueva Bateria">
                    <span>
                      <IconButton
                        //   disabled={
                        //     !props.direccion || !datosCliente
                        //   }
                        color="primary"
                        size="large"
                        onClick={(e) => setAltaBateria(true)}
                      >
                        <AddBoxIcon fontSize="inherit" />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              alignContent="stretch"
              wrap="nowrap"
            >
              <Grid item>
                <Button variant="outlined" color="error" onClick={handleClose}>
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={desBtn || !datos.nro_serie || !datos.duenio}
                  type="submit"
                  variant="outlined"
                  color="success"
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
      {altaBateria ? (
        <>
          <AddBateria
            open={altaBateria}
            setOpen={setAltaBateria}
            datos={props.direccion.id}
            cliente={props.datosCliente}
            actualizar={obtenerBaterias}
            setearUltimaBateria={setearUltimaBateria}
          />
        </>
      ) : null}
    </div>
  );
}

export default FormTanqueRapido;
