import React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Notify from "../../components/Notify";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";

const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "--TextField-brandBorderColor": "#E0E3E7",
            "--TextField-brandBorderHoverColor": "#B2BAC2",
            "--TextField-brandBorderFocusedColor": "#6F7E8C",
            "& label.Mui-focused": {
              color: "var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: "var(--TextField-brandBorderColor)",
          },
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "var(--TextField-brandBorderHoverColor)",
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
    },
  });

function DialogConTextField(props) {
  const outerTheme = useTheme();
  const [disBtn, setDisBtn] = useState();

  const handleClose = () => {
    props.setOpen(false);
    props.setNotify({ open: false });
  };

  return (
    <div>
      {props.notify.open && (
        <Notify
          open={props.notify.open}
          severity={props.notify.severity}
          mensaje={props.notify.mensaje}
          handleClose={props.atras ? props.atras : handleClose}
          color={props.notify.color}
        />
      )}
      <Dialog open={props.open} onClose={handleClose}>
        <DialogTitle borderTop={6} borderColor={props.color}></DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            wrap="wrap"
          >
            <Grid item xs={2}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item>
                  <Avatar sx={{ bgcolor: props.color, width: 80, height: 80 }}>
                    {props.icon}
                  </Avatar>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={10}>
              <Grid container direction="column" ml={2}>
                <Grid item>
                  <Typography
                    variant="body1"
                    color="#2E4053"
                    gutterBottom
                    fontWeight="bold"
                  >
                    {" "}
                    {props.title}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" color="textSecondary">
                    {props.subtitle}
                  </Typography>
                </Grid>
                <Grid item>
                  <ul>
                    {props.items &&
                      props.items.map((item) => (
                        <li>
                          <Typography variant="body1" color="textSecondary">
                            {item}
                          </Typography>
                        </li>
                      ))}
                  </ul>
                </Grid>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  style={{ marginTop: 8 }}
                >
                  {props.confirmText}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container mt={2}>
            <Grid item xs={12}>
              <ThemeProvider theme={customTheme(outerTheme)}>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  label="Observaciones "
                  value={props.value}
                  onChange={(e) => props.setValue(e.target.value)}
                />
              </ThemeProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={0} justifyContent="space-between">
            <Button
              onClick={handleClose}
              disabled={disBtn}
              color="error"
              variant="contained"
            >
              Volver
            </Button>

            <Button
              variant="contained"
              disabled={disBtn}
              autoFocus
              color="success"
              onClick={(e) => {
                props.guardar(e);
                setDisBtn(true);
              }}
            >
              {props.btnText ?? "Guardar"}
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DialogConTextField;
