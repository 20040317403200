//----- Component --------------------------------------------------------/

// Developer : Nicolas Pascucci

// Creation Date : 21/05/2024

// Version : 1

// Description : Contenedor de la evaluacion por pasos
//

//------Changes --------------------------------------------------------------------- -------------//

// Change Date :

// Change by :

// Change description:

//

// Change Date :

// Change by :

// Change description:

//

//========================================================================/
import React, { useState, useEffect } from "react";
import Navbar from "../../Navbar";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { Button, Box, Grid, Typography, TextField } from "@mui/material";
import { logEventos } from "../../../services/services";
import PrimerStep from "./PrimerStep";
import SegundoStep from "./SegundoStep";
import TercerStep from "./TercerStep";
import CuartoStep from "./CuartoStep";
import PantallaFinal from "./Pantalla_final";
import { UrlApi } from "../../../services/apirest";
import moment from "moment";
import CeroStep from "./CeroStep";

const steps = [
  "Cantidad de Kg. totales vendidos",
  "Kilos Mensuales Butano",
  "Cant. Clientes Activos en el Mes",
  "Gestión de Cobranzas",
  "Resumen",
];

function EvaluacionComercial(props) {
  const [sucursalSeleccionada, setSucursalSeleccionada] = useState(props.sucursalSeleccionada)
  const [modificar, setModificar] = useState(props.modificar)
  const [resultados, setResultados] = useState(props.resultados)
  const [fecha, setFecha] = useState(props.fecha)
  const [hizoChecklist, setHizoChecklist] = useState(props.hizoChecklist)
  const [completo, setCompleto] = useState(false)
  const [comercial, setComercial] = useState(null)
  const [vendido, setVendido] = useState(props.vendido)
  const [distribuidores, setDistribuidores] = useState(props.distribuidores)
  const [comercio, setComercio] = useState(props.comercio)
  const [usuarios, setUsuarios] = useState(props.usuarios)
  const [porcentajeKgVendido, setPorcentajeKgVendido] = useState(null)
  const [clientes, setClientes] = useState(props.clientes)
  const [porcentajeClientes, setPorcentajeClientes] = useState(null)
  const [porcentajeTipoDistribuidores, setPorcentajeTipoDistribuidores] = useState(null)
  const [porcentajeTipoComercio, setPorcentajeTipoComercio] = useState(null)
  const [porcentajeTipoUsuarios, setPorcentajeTipoUsuarios] = useState(null)
  const [vencidos, setVencidos] = useState(props.vencidos)
  const [porcentajeVencidos, setporcentajeVencidos] = useState(null)
  const [estados, setEstados] = useState(props.estados)
  const [comerciosButano, setComerciosButano] = useState(props.comerciosButano)
  const [usuariosButano, setUsuariosButano] = useState(props.usuariosButano)
  const [distribuidoresButano, setDistribuidoresButano] = useState(props.distribuidoresButano)
  const [porcentajeVendidoDistribuidores, setPorcentajeVendidoDistribuidores] = useState(null)
  const [porcentajeVendidoComercios, setPorcentajeVendidoComercios] = useState(null)
  const [porcentajeVendidoUsuarios, setPorcentajeVendidoUsuarios] = useState(null)
  // console.log("estados", estados);


  const [subitemEvaluacion, setSubitemEvaluacion] = useState(null)

  const obtenerSubitems = () => {
    fetch(UrlApi + "/subitems_comerciales/xiditem/"+5)
      .then((data) => data.json())
      .then((data) => setSubitemEvaluacion(data));
  };

  useEffect(() => {
    obtenerSubitems()
  }, [])
  // console.log("item evaluacion",subitemEvaluacion );
  const porcentajeChecklist = subitemEvaluacion && hizoChecklist === true? subitemEvaluacion[0].porcentaje : 0 

  const [configuracionVista, setConfiguracionVista] = useState()
  const obtenerConfiguracion = () => {
    fetch(UrlApi + "/config_evaluacion/ultimaRow")
      .then((data) => data.json())
      .then((data) => setConfiguracionVista(data));
  };
  useEffect(() => {
    obtenerConfiguracion();
  }, []);
// console.log("CONFIGURACION DIAS", configuracionVista);

  // useEffect(() => {
  //     logEventos("Acceso al menú pedidos a petroleras", "Pedidos a Petroleras", "Hacer pedidos a NaturalGas o a Hipergas", localStorage.getItem("user"))
  // }, [])
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNextCero = (distribuidoresButano, comerciosButano, usuariosButano, porcentajeVendidoDistribuidoresButano, porcentajeVendidoComerciosButano, porcentajeVendidoUsuariosButano) => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setDistribuidoresButano(distribuidoresButano);
    setComerciosButano(comerciosButano);
    setUsuariosButano(usuariosButano);
    setPorcentajeVendidoDistribuidores(porcentajeVendidoDistribuidoresButano)
    setPorcentajeVendidoComercios(porcentajeVendidoComerciosButano)
    setPorcentajeVendidoUsuarios(porcentajeVendidoUsuariosButano)

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleNext = (vendido,distribuidores, comercio, usuarios, porcentajeKgVendido,porcentajeTipoDistribuidores,porcentajeTipoComercio,porcentajeTipoUsuarios, comercial ) => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setVendido(vendido)
    setDistribuidores(distribuidores);
    setComercio(comercio);
    setUsuarios(usuarios);
    setPorcentajeKgVendido(porcentajeKgVendido);
    setPorcentajeTipoDistribuidores(porcentajeTipoDistribuidores)
    setPorcentajeTipoComercio(porcentajeTipoComercio)
    setPorcentajeTipoUsuarios(porcentajeTipoUsuarios)
    setComercial(comercial)

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleNextTwo = (clientes, porcentajeClientes) => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setClientes(clientes);
    setPorcentajeClientes(porcentajeClientes);

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleNextThree = (vencidos, porcentajeVencidos) => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setVencidos(vencidos);
    setporcentajeVencidos(porcentajeVencidos);

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
    calcularPorcentajeTotal()
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleTerminar = () => {
    setCompleto(true)
  };

  // console.log("---comercial---", comercial);
  // console.log("---distribuidores---",distribuidores);
  // console.log("---comercio---",comercio);
  // console.log("---usuarios---",usuarios);
  // console.log("---clientes---",clientes);
  // console.log("---vencidos---", vencidos);
  // console.log("---porcentaje kg vendido!!!!! y kgs---",porcentajeKgVendido, vendido);
  // console.log("---porcentajeClientes---",porcentajeClientes);
  // console.log("---porcentajeTipoDistribuidores---",porcentajeTipoDistribuidores);
  // console.log("---porcentajeTipoComercio---",porcentajeTipoComercio);
  // console.log("---porcentajeTipoUsuarios---",porcentajeTipoUsuarios);
  // console.log("---porcentajeVencidos---", porcentajeVencidos);
  // console.log("---porcentajeChecklist---", porcentajeChecklist);
  // console.log("---porcentaje kg vendido Distribuidores---", porcentajeVendidoDistribuidores);
  // console.log("---porcentaje kg vendido Comercios---", porcentajeVendidoComercios);
  // console.log("---porcentaje kg vendido Usuarios---", porcentajeVendidoUsuarios);
  
  // SAQUE ESTO DE LA SUMA PORCENTAJE +parseFloat(porcentajeChecklist)

  const [totalPorcentaje, setTotalPorcentaje] = useState(null)
  const sumaPorcentajes = parseFloat(porcentajeKgVendido)+parseFloat(porcentajeClientes)+parseFloat(porcentajeVencidos)+parseFloat(porcentajeVendidoDistribuidores)+parseFloat(porcentajeVendidoComercios)+parseFloat(porcentajeVendidoUsuarios)
  const calcularPorcentajeTotal = () => {
    setTotalPorcentaje(parseFloat(sumaPorcentajes));
  };
  // console.log("PORCENTAJE GANADO",sumaPorcentajes);
// console.log("ACTIVE STEP", activeStep);
  return (
    <div>
      <Navbar />
      {completo===false?
      <>
      <br></br>
      <Box
        bgcolor="#2B76A6"
        padding={1}
        borderRadius={0}
        color="white"
        textAlign="center"
        display="flex" // Agregado para que los elementos se coloquen en línea
        alignItems="center"
        marginTop={1}
      >
        <AssignmentIcon
          style={{ fontSize: 40, marginRight: "20px", marginLeft: "20px" }}
        />
        <Typography variant="h4" component="div">
          Evaluación Comercial
        </Typography>
      </Box>
      <br></br>
      <Box sx={{ ml: 10, mr: 10 }}>
        <Stepper activeStep={activeStep} sx={{ width: "90%" }}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepSkipped(index)) {
              stepProps.completed = true;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 10, mb: 2, textAlign: "initial" }}>
              Ficha completada.
            </Typography>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {activeStep === 1 && (
              <div>
                <CeroStep activeStep={activeStep} 
                handleNext={handleNextCero}
                handleBack={handleBack}
                sucursalSeleccionada={sucursalSeleccionada}
                distribuidoresButano={distribuidoresButano}
                comerciosButano={comerciosButano}
                usuariosButano={usuariosButano}
                fecha={fecha}
                // configuracionVista={configuracionVista}
                />
              </div>
            )}

            {activeStep === 0 && (
              <div>
                <PrimerStep activeStep={activeStep} 
                handleNext={handleNext}
                handleBack={handleBack}
                sucursalSeleccionada={sucursalSeleccionada}
                distribuidores={distribuidores}
                comercio={comercio}
                usuarios={usuarios}
                vendido={vendido}
                configuracionVista={configuracionVista}
                fecha={fecha}
                />
              </div>
            )}

            {activeStep === 2 && (
              <div>
                <SegundoStep activeStep={activeStep} 
                handleNext={handleNextTwo} 
                handleBack={handleBack}
                sucursalSeleccionada={sucursalSeleccionada}
                clientes={clientes}
                configuracionVista={configuracionVista}
                fecha={fecha}
                />
              </div>
            )}

            {activeStep === 3 && (
              <div>
                <TercerStep activeStep={activeStep} 
                handleNext={handleNextThree} 
                handleBack={handleBack}
                vencidos={vencidos}
                fecha={fecha}
                />
              </div>
            )}

            {activeStep === 4 && (
              <div>
                <CuartoStep 
                activeStep={activeStep} 
                distribuidores={distribuidores}
                comercio={comercio}
                usuarios={usuarios}
                clientes={clientes}
                vencidos={vencidos}
                vendido={vendido}
                distribuidoresButano={distribuidoresButano}
                comerciosButano={comerciosButano}
                usuariosButano={usuariosButano}
                sumaPorcentajes={sumaPorcentajes}
                comercial={comercial}
                configuracionVista={configuracionVista}
                modificar={modificar}
                resultados={resultados}
                sucursalSeleccionada={sucursalSeleccionada}
                estados={estados}
                fecha={fecha}
                porcentajeKgVendido={porcentajeKgVendido}
                porcentajeClientes={porcentajeClientes}
                porcentajeVencidos={porcentajeVencidos}
                porcentajeVendidoDistribuidores={porcentajeVendidoDistribuidores}
                porcentajeVendidoComercios={porcentajeVendidoComercios}
                porcentajeVendidoUsuarios={porcentajeVendidoUsuarios}
                handleBack={handleBack}
                handleTerminar={handleTerminar}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </Box>
      </>
      :<PantallaFinal/>}
    </div>
  );
}

export default EvaluacionComercial;
