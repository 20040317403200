//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio
// Creation Date : 8/11/2024
// Version : 1
// Description : confirmacion de la revision de auditores desde panel
//------Changes --------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState } from "react";
import { UrlApi } from "../../../services/apirest";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Box,
  DialogContentText,
  Typography,
  DialogActions,
} from "@mui/material";
import { logEventos } from "../../../services/services";
import { grey } from "@mui/material/colors";
import moment from "moment";
// Notification
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Dialog_revision_panel(props) {
  const { revision, row, onClose } = props;
  // console.log("ROW", row);

  // Obtener la fecha de hoy en el formato deseado
  const fechaHoy = moment().format("YYYY-MM-DD");
  // console.log(fechaHoy); // Esto imprimirá la fecha de hoy en formato "DD-MM-YYYY"

  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");

  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
    onClose(); // Cerrar el diálogo al finalizar
  };
  //console.log("REVISION 1", localStorage.getItem("user"));

  const guardarAuditado = async (e) => {
    // logEventos(
    //   "Guardar auditado",
    //   "Resultados de Control",
    //   "Guardo los auditados en el estado evaluación ",
    //   localStorage.getItem("user")
    // );
    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(UrlApi + "/seg_hig_auditoria/" + row.id, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        auditado: 1,
        auditor: localStorage.getItem("user"),
        fecha_auditado: fechaHoy,
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          setColorMensaje("#D4EFDF");
          setMensaje("La auditoría se guardó exitosamente !");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar la auditoría");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  // console.log("DATOS RESULTADOS", props.datosResultados);

  return (
    <div>
      <Dialog open={revision} onClose={onclose} maxWidth="xs" fullWidth>
        <Box backgroundColor={"#1F618D"} mt={1}>
          <DialogTitle>
            <Typography variant="h6" align="center" color="#ffffff">
              Confirmar Revision de Checklist
            </Typography>
          </DialogTitle>
        </Box>
        <form onSubmit={guardarAuditado}>
          <Box
            border={0.5}
            borderColor={grey[300]}
            backgroundColor="#F4F6F6"
            p={2}
            py={2}
            mt={1}
          >
            <DialogContent>
              <DialogContentText textAlign={"center"}>
                ¿Desea Revisar el checklist de {props.row.nombre_apellido} con
                depósito {props.row.nombre} ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItemns="center"
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onClose}
                  style={{ backgroundColor: "#1976D2", color: "#ffffff" }}
                >
                  Volver
                </Button>
                <Button
                  style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Revisar
                </Button>
              </Grid>
            </DialogActions>
          </Box>
        </form>
      </Dialog>
      {/* SNACKBAR QUE SE MOSTRARA AL GUARDAR LOS CAMBIOS */}
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
}

export default Dialog_revision_panel;
