import React, { useState, useEffect, useMemo } from "react";
import { UrlApi } from "../../../../services/apirest";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Snackbar,
  Tooltip,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //idioma
import { createTheme, ThemeProvider, useTheme } from "@mui/material";

// ICONOS
import LinkIcon from "@mui/icons-material/Link";
import BlockIcon from "@mui/icons-material/Block";
import CircularProgress from "@mui/material/CircularProgress";

function ComponentesDelProducto(props) {
  const [row, setRow] = useState(props.row);
  const [tabla, setTabla] = useState(true);
  const [componentesDelProducto, setComponentesDelProducto] = useState([]);
  const [datosformulario, setDatosformulario] = useState([]);
  const [update, setUpdate] = useState();

  // GET EN BASE A UN ID DE COMPONENTE
  const obtenerComponentesPorID = () => {
    fetch(UrlApi + "/componentes_productos/producto/" + row.original.idproducto) //fetch con la urp de api q esta en services/apirest + la ruta
      .then((data) => data.json()) //mando los datos que recibo en json
      .then((data) => setComponentesDelProducto(data)); //seteo
  };

  useEffect(() => {
    obtenerComponentesPorID();
  }, [tabla]);

  // FUNCION QUE SE EJECUTA CADA VEZ QUE HAY UN CAMBIO EN LA BASE DE DATOS
  // SIRVE PARA QUE LA TABLA MOSTRADA SE ACTUALICE EN TIEMPO REAL
  useEffect(() => {
    if (update) obtenerComponentesPorID();
  }, [update]);

  // -----------------------------
    // GET AGRUPADO (OBTIENE SOLO LA PRIMER COINCIDENCIA, LO USO PARA LA TABLA)
    // const obtenerComponentesProductos = () => {
    //   fetch(UrlApi + "/componentes_productos/agrupados") //fetch con la urp de api q esta en services/apirest + la ruta
    //     .then((data) => data.json()) //mando los datos que recibo en json
    //     .then((data) => setComponentesProductos(data)); //seteo clientes
    // };
  
    // useEffect(() => {
    //   obtenerComponentesProductos();
    // }, [tabla2]);

    // FUNCION QUE SE EJECUTA CADA VEZ QUE HAY UN CAMBIO EN LA BASE DE DATOS
  // SIRVE PARA QUE LA TABLA MOSTRADA SE ACTUALICE EN TIEMPO REAL
  // useEffect(() => {
  //   if (update2) obtenerComponentesProductos();
  // }, [update2]);

  //   useEffect(() => {
  //   if (update2) obtenerComponentesPorID();
  // }, [update2]);

  // ----------------------------------------------------------------------------
  // UPDATE 2 (PROPS DE LA TABLA DE ComponentesProductos))

    // FUNCION QUE SE EJECUTA CADA VEZ QUE HAY UN CAMBIO EN LA BASE DE DATOS
  // SIRVE PARA QUE LA TABLA MOSTRADA SE ACTUALICE EN TIEMPO REAL
  // useEffect(() => {
  //   if (update2) obtenerComponentesPorID();
  // }, [update2]);

  //**********************************************************************************************/

  //Notificacion
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");

  //**********************************************************************************************/

  // NOTIFICACION
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
    setUpdate(false);
    setOpenDialogOnOff(false);
  };

  //**********************************************************************************************/

  // DIALOG PARA ACTIVAR/DESACTIVAR LA RELACIÓN
  const [openDialogOnOff, setOpenDialogOnOff] = React.useState(false);

  const handleCloseDialogOnOff = () => {
    setOpenDialogOnOff(!openDialogOnOff);
  };

  const dialogActivar = (row) => {
    setDatosformulario(row);
    setOpenDialogOnOff(true);
    setTabla(true);
  };

  //**********************************************************************************************/
  // FUNCIONES PARA GUARDAR:
  // ACTIVAR RELACION
  const guardarActivo = async (e) => {
    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(
      UrlApi + "/componentes_productos/" + datosformulario.id_relacion,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          estado: 1,
        }),
      }
    )
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("La relación se guardó exitosamente!");
          setUpdate(true);
          //console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar la relación");
          //console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  // DESACTIVAR RELACION
  const guardarInactivo = async (e) => {
    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(
      UrlApi + "/componentes_productos/" + datosformulario.id_relacion,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          estado: 2,
        }),
      }
    )
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("La relación se guardó exitosamente!");
          setUpdate(true);
          //console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar la relación");
          //console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  //**********************************************************************************************/

  const columns = [
    {
      header: "ID Relación",
      accessorKey: "id_relacion",
      minSize: 30,
      size: 50,
      maxSize: 100,
    },
    {
      header: "Componente",
      accessorKey: "nombre_componente",
    },
    {
      header: "Estado",
      accessorKey: "valor",
    },
  ];

  //**********************************************************************************************/

  // ESTILO PARA LA TABLA
  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //larger tooltip font size
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  return (
    <div>
      <ThemeProvider theme={tableTheme}>
        <MaterialReactTable
          columns={columns}
          data={componentesDelProducto}
          initialState={{
            showGlobalFilter: true,
            density: "compact",
          }}
          localization={MRT_Localization_ES} //idioma ES
          // COLORES PARA LAS CELDAS SEGUN ACTIVO/INACTIVO
          muiTableBodyCellProps={({ row }) => ({
            sx: {
              backgroundColor:
                row.original.estado === 1 ? "#66FF66" : "#FF5050",
              color: "black",
              borderColor: "black",
              //COLOR AL PASAR EL MOUSE POR ENCIMA
              "&:hover": {
                backgroundColor:
                  row.original.estado === 1 ? "#3ad63a" : "#f22929",
              },
              "& .MuiTableCell-root:first-child": {
                border: "none",
              },
            },
          })}
          muiTableBodyProps={({ row }) => ({
            //ESTILO DE LA TABLA
            "& .MuiTableCell-root": {
              borderBottom: "1px solid",
            },
          })}
          // TITULO
          renderTopToolbarCustomActions={({ table }) => (
            <Grid item mt={-2.5}>
              <h2>
                COMPONENTES DEL PRODUCTO: {row.original.nombre} - ID:{" "}
                {row.original.idproducto}
              </h2>
            </Grid>
          )}
          enableRowActions
          renderRowActions={({ row }) => (
            <>
              <Tooltip
                arrow
                placement="left"
                title={
                  row.original.estado === 1
                    ? "Anular Relación"
                    : "Activar Relación"
                }
              >
                <IconButton onClick={() => dialogActivar(row.original)}>
                  <BlockIcon
                    sx={{
                      color: row.original.estado === 1 ? "red" : "lightgreen",
                    }}
                  />
                </IconButton>
              </Tooltip>
            </>
          )}
          positionActionsColumn="last"
          // TRUE
          enableTopToolbar
          // FALSE
          enableColumnFilters={false} //opcion de filtrado por columnas
          enableDensityToggle={false}
          enableHiding={false} //oculta la propiedad show hide columns
          enableFullScreenToggle={false} // Deshabilito la pantalla completa
          enableGlobalFilter={false} // Desactivar barra de busqueda
        />
      </ThemeProvider>

      {/* DIALOG PARA BLOQUEAR/DESBLOQUEAR LA RELACION */}
      <Dialog open={openDialogOnOff} onClose={handleCloseDialogOnOff}>
        <Box
          style={{
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: datosformulario.estado === 1 ? "#e32712" : "#24ff1c",
            color: datosformulario.estado === 1 ? "red" : "green",
          }}
        >
          <DialogTitle>
            <Typography align="center">
              <b>
                SE {datosformulario.estado === 1 ? "BLOQUEARÁ" : "DESBLOQUEARÁ"}{" "}
                LA RELACIÓN:
              </b>
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              textAlign={"center"}
              style={{ marginBottom: "40px" }}
            >
              <Grid item textAlign={"left"} ml={2}>
                <br />
                <b>{datosformulario.nombre}</b>
              </Grid>
              <Grid item textAlign={"center"} mt={-3}>
                <LinkIcon />
              </Grid>
              <Grid item textAlign={"right"} mt={-4} mr={2}>
                <b>{datosformulario.nombre_componente}</b>
              </Grid>
            </DialogContentText>
            <DialogContentText
              textAlign={"center"}
              style={{ marginBottom: "-10px" }}
            >
              {"¿Confirma que quiere " +
                (datosformulario.estado === 1 ? "bloquear" : "desbloquear") +
                " esta relación?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Stack spacing={4} direction="row">
              <Grid item mr={14}>
                <Button
                  style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
                  variant="contained"
                  onClick={handleCloseDialogOnOff}
                >
                  Cancelar
                </Button>
              </Grid>
              {datosformulario.estado === 1 ? (
                <Button
                  style={{ backgroundColor: "#fc7a23", color: "#ffffff" }}
                  onClick={guardarInactivo}
                  variant="contained"
                >
                  Bloquear
                </Button>
              ) : (
                <Button
                  style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                  onClick={guardarActivo}
                  variant="contained"
                >
                  Desbloquear
                </Button>
              )}
            </Stack>
          </DialogActions>
        </Box>
      </Dialog>

      {/* SNACKBAR QUE SE MOSTRARA AL GUARDAR LOS CAMBIOS */}
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
}

export default ComponentesDelProducto;