//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio/Nicolas Pascucci
// Creation Date : 25/04/2024
// Version : 1
// Description : pantalla de checklist para completar
//------Changes --------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect } from "react";
import { UrlApi } from "../../../services/apirest";
import Navbar from "../../Navbar";
import {
  Box,
  Typography,
  Grid,
  Autocomplete,
  TextField,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { useTheme } from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import moment from "moment/moment";
import Notify from "../../Notify";
import Alerta from "../../Alerta";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { logEventos } from "../../../services/services";
import PantallaFinal from "../evaluacion_step/Pantalla_final";
import Divider from "@mui/material/Divider";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
//ADJUNTAR
import AttachFileIcon from "@mui/icons-material/AttachFile"; //ADJUNTAR IMAGEN
import FileUploadCheck from "./FileUploadCheck";
import { v4 as uuidv4 } from "uuid"; //ADJUNTAR IMAGEN
import VerResultadoCheck from "./VerResultadoCheck";

const CheckList = () => {
  const [datosCheck, setDatosCheck] = useState({
    id_item: null,
    id_grupo: null,
    cumple: null,
    no_cumple: null,
    n_a: null,
    observacion: "",
    deposito: null,
    responsable: null,
    fecha: moment().subtract(1, "month").format("YYYY-MM-DD"),
    dias_habiles: null,
    uuid: "",
    usuario: localStorage.getItem("user"),
  });

  useEffect(() => {
    logEventos(
      "Acceso al menú Control de Depositos",
      "Control de Depositos",
      "Checklist para completar",
      localStorage.getItem("user")
    );
  }, []);
  // COMPLETO O NO
  const [checklistCompleto, setChecklistCompleto] = useState(false);
  const globalTheme = useTheme();
  const [tabla, setTabla] = useState(true);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
  const [mensajeAlerta, setMensajeAlerta] = useState(""); //mensaje que contendrá el componente "alerta"
  const [finalizar, setFinalizar] = useState(); //finalizar === 1 lo manda como prop el componente "notify" e indica que la transaccion ha finalizado y debe ejecutarse la funcion para cerrar
  const [severityAlerta, setSeverityAlerta] = useState(""); //define severidad de la alerta (success error o warning)
  const [severitySnack, setSeveritySnack] = useState(""); //define severidad del snackbar (notificación)
  const [update, setUpdate] = useState(false);
  const [uuid, setUuid] = useState(uuidv4()); //ADJUNTAR IMAGEN
  const [openCloseAdj, setOpenCloseAdj] = useState(false); //ADJUNTAR IMAGEN

  // ********** AGREGADO PARA GUARDAR EN ESTADO EVALUACION ************* //
  const [subitemEvaluacion, setSubitemEvaluacion] = useState(null);

  const obtenerSubitems = () => {
    fetch(UrlApi + "/subitems_comerciales/xiditem/" + 5)
      .then((data) => data.json())
      .then((data) => setSubitemEvaluacion(data));
  };

  useEffect(() => {
    obtenerSubitems();
  }, []);
  const porcentajeChecklist =
    subitemEvaluacion && subitemEvaluacion[0]
      ? subitemEvaluacion[0].porcentaje
      : null;
  //console.log("porcentaje de checklist",porcentajeChecklist);

  // ****************************************************************** //

  const [selectedDate, setSelectedDate] = useState(
    moment().subtract(1, "month").format("YYYY-MM")
  );
  const formattedDate = `${selectedDate}-01`;

  const handleDateChange = (event) => {
    const newDate = event.target.value;
    setSelectedDate(newDate);

    // Actualiza datosCheck.fecha cuando cambia la selección
    setDatosCheck({
      ...datosCheck,
      fecha: moment(newDate, "YYYY-MM").format("YYYY-MM-DD"), // Ajusta el formato según necesites
    });
  };

  const currentMonth = moment();
  const monthOptions = Array.from({ length: 8 }, (_, i) => {
    const month = currentMonth
      .clone()
      .subtract(i + 1, "months")
      .format("YYYY-MM");
    return {
      value: month,
      label: currentMonth
        .clone()
        .subtract(i + 1, "months")
        .format("MMMM YYYY"),
    };
  });

  //console.log("Fecha seleccionada:", selectedDate);

  // Define date aquí
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);

  /*   GET     */
  const [deposito, setDeposito] = useState();
  const [depositoSeleccionado, setDepositoSeleccionado] = useState("");

  const obtenerDepositos = () => {
    fetch(
      UrlApi +
        "/sucursales/xsucauth/" +
        localStorage.getItem("suc_auth").split(",")
    )
      .then((data) => data.json())
      .then((data) => setDeposito(data));
  };
  useEffect(() => {
    obtenerDepositos();
  }, []);

  const [vehiculos, setVehiculos] = useState();
  const [vehiculoSeleccionado, setVehiculoSeleccionado] = useState("");

  const obtenerVehiculos = () => {
    fetch(UrlApi + "/vehiculos/paraChecklist/" + depositoSeleccionado.id)
      .then((data) => data.json())
      .then((data) => {
        setVehiculos(data);
        const nuevasLineas = data.map((vehiculo) => ({
          fecha: moment().subtract(1, "month").format("YYYY-MM-DD"),
          deposito: depositoSeleccionado.id,
          responsable:
            representante && representante[0] ? representante[0].id : null,
          dominio_tipo: vehiculo.id,
          dias_inactivos: null,
          kg_vendidos: null,
          zona: "",
          chofer_acompañante: "",
        }));
        setDatosFlota(nuevasLineas);
      });
  };

  useEffect(() => {
    obtenerVehiculos();
  }, [depositoSeleccionado]);

  const [representante, setRepresentante] = useState();

  const obtenerRepresentante = () => {
    fetch(UrlApi + "/representantes/getid/" + localStorage.getItem("user"))
      .then((data) => data.json())
      .then((data) => setRepresentante(data));
  };
  useEffect(() => {
    obtenerRepresentante();
  }, []);

  //datos para flota de vehiculos
  const [datosFlota, setDatosFlota] = useState([
    {
      fecha: moment(selectedDate, "YYYY-MM").format("YYYY-MM-DD"),
      deposito: depositoSeleccionado.id,
      responsable:
        representante && representante[0] ? representante[0].id : null,
      dominio_tipo: vehiculoSeleccionado ? vehiculoSeleccionado.id : null,
      dias_inactivos: null,
      kg_vendidos: null,
      zona: "",
      chofer_acompañante: "",
    },
  ]);
  // console.log("datosFlota:", datosFlota);
  // console.log("selectDate",selectedDate);
  // console.log("format",formattedDate);

  useEffect(() => {
    setDatosFlota((prevDatosFlota) =>
      prevDatosFlota.map((item) => ({
        ...item,
        fecha: moment(selectedDate, "YYYY-MM").format("YYYY-MM-DD"),
      }))
    );
  }, [selectedDate]);

  // useEffect para actualizar datosFlota cuando vehiculoSeleccionado cambie
  useEffect(() => {
    if (vehiculoSeleccionado && datosFlota.length === 1) {
      setDatosFlota((prevDatosFlota) => [
        {
          ...prevDatosFlota[0],
          dominio_tipo: vehiculoSeleccionado.id,
          deposito: depositoSeleccionado.id,
          responsable:
            representante && representante[0] ? representante[0].id : null,
        },
      ]);
    }
  }, [vehiculoSeleccionado]);

  const [gruposEItems, setGruposEItems] = useState([]);
  const [terminado, setTerminado] = useState(false);

  const obtenerGruposEItems = () => {
    fetch(UrlApi + "/checklist_rrhh_grupos/activos")
      .then((response) => response.json())
      .then((grupos) => {
        const promises = grupos.map((grupo) => {
          // Retorna una promesa para cada llamada a la API
          return fetch(
            UrlApi +
              `/checklist_rrhh_grupos/itemxgrupoActivos/${grupo.id_grupo}`
          )
            .then((response) => response.json())
            .then((items) => ({ grupo, items }));
        });
        // Espera a que todas las promesas se resuelvan
        return Promise.all(promises);
      })
      .then((gruposItemsArray) => {
        // Una vez que todas las llamadas a la API se completen con éxito,
        // actualiza el estado con el array completo de grupos e items
        setGruposEItems(gruposItemsArray);
        setTerminado(true);
      })
      .catch((error) =>
        console.error("Error al obtener grupos e items:", error)
      );
  };

  useEffect(() => {
    obtenerGruposEItems();
  }, []);

  // console.log("datosCheck.fecha: ", datosCheck.fecha);

  const almacenar = (e) => {
    setDatosCheck({
      ...datosCheck,
      [e.target.name]: e.target.value === "" ? null : e.target.value,
    });
  };

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  const size = "small";

  // Define el estado inicial para las observaciones
  const initialState = gruposEItems.reduce((acc, grupoItem) => {
    grupoItem.items.forEach((item) => {
      acc[item.id_item] = "";
    });
    return acc;
  }, {});

  /* OBSERVACIONES */
  const [observaciones, setObservaciones] = useState(initialState);

  // Función para actualizar la observación de un elemento específico
  const actualizarObservacion = (idItem, observacion) => {
    setObservaciones({
      ...observaciones,
      [idItem]: observacion,
    });
  };

  /*------------------------ CHECKBOX VALIDACION CON OBSERVACION--------------------------------- */
  const [checkboxStates, setCheckboxStates] = useState({});

  // Función para manejar cambios en los checkboxes
  const handleChange = (grupoId, itemId, checked) => {
    setCheckboxStates((prevState) => ({
      ...prevState,
      [grupoId]: {
        ...prevState[grupoId],
        [itemId]: checked,
      },
    }));
    // Si el checkbox de "No cumple" está marcado, asegúrate de que haya una observación
    // if (checked === "no_cumple") {
    //   setObservaciones((prevState) => ({
    //     ...prevState,
    //     [itemId]: prevState[itemId]  // Coloca un placeholder si no hay observación
    //   })
    // );
    // }
  };

  console.log("grupo item", checkboxStates);

  useEffect(() => {
    obtenerGruposEItems();
    // Inicializar checkboxStates con un objeto vacío para cada grupo
    setCheckboxStates({});
  }, []);

  //------------------------------ BLOQUEO DEL BOTON ------------------------------//
  const [choferAcompanante, setChoferAcompanante] = useState("");
  const [disabledGuardar, setDisabledGuardar] = useState(false);

  useEffect(() => {
    // Calcula la cantidad total de checkboxes esperados
    const totalCheckboxes = gruposEItems.reduce((acc, grupoItem) => {
      return acc + grupoItem.items.length;
    }, 0);

    // Calcula la cantidad de checkboxes marcados
    let checkboxesMarcados = 0;

    for (let grupoId in checkboxStates) {
      for (let itemId in checkboxStates[grupoId]) {
        const isChecked = checkboxStates[grupoId][itemId];
        const item = gruposEItems
          .find((grupo) => grupo.id === grupoId)
          ?.items.find((item) => item.id === itemId);

        // Verifica si el checkbox está marcado
        if (isChecked) {
          checkboxesMarcados++;
        }
      }
    }
    // Verifica si todos los checkboxes están marcados
    if (
      Object.keys(depositoSeleccionado).length !== 0 &&
      checkboxesMarcados === totalCheckboxes
    ) {
      setDisabledGuardar(false); // Deshabilita el botón guardar
    } else {
      setDisabledGuardar(true); // Habilita el botón guardar
    }
  }, [checkboxStates, depositoSeleccionado, gruposEItems]);

  const [estados, setEstados] = useState();
  const [fechaMes, setFechaMes] = useState(
    moment(datosCheck.fecha).format("YYYY-MM")
  );
  // console.log("mes", fechaMes);
  const obtenerEstados = () => {
    fetch(
      UrlApi +
        "/estados_evaluacion_comercial/mesusersuc/" +
        localStorage.getItem("user") +
        "/" +
        selectedDate +
        "/" +
        depositoSeleccionado.id
    )
      .then((data) => data.json())
      .then((data) => setEstados(data));
  };

  useEffect(() => {
    obtenerEstados();
  }, [depositoSeleccionado, selectedDate]);
  // console.log("Existe", estados);
  //-------------------------------FLOTA DE VEHICULOS ----------------------//
  const handleVehiculoChange = (index, vehiculoSeleccionado) => {
    setDatosFlota((prevLineas) =>
      prevLineas.map((linea, i) =>
        i === index
          ? { ...linea, dominio_tipo: vehiculoSeleccionado?.id || null }
          : linea
      )
    );
  };

  const handleDiasInactivoChange = (index, dias_inactivos) => {
    setDatosFlota((prevLineas) =>
      prevLineas.map((linea, i) =>
        i === index ? { ...linea, dias_inactivos } : linea
      )
    );
  };

  const handleKgVendidosChange = (index, kg_vendidos) => {
    setDatosFlota((prevLineas) =>
      prevLineas.map((linea, i) =>
        i === index ? { ...linea, kg_vendidos } : linea
      )
    );
  };

  const handleZonaChange = (index, zona) => {
    setDatosFlota((prevLineas) =>
      prevLineas.map((linea, i) => (i === index ? { ...linea, zona } : linea))
    );
  };

  const handleChoferChange = (index, chofer_acompañante) => {
    setChoferAcompanante(chofer_acompañante);
    setDatosFlota((prevLineas) =>
      prevLineas.map((linea, i) =>
        i === index ? { ...linea, chofer_acompañante } : linea
      )
    );
  };

  const guardarCheck = async () => {
    logEventos(
      "Guardar checklist ",
      "Control de Depositos",
      "Se guardo el checklist",
      localStorage.getItem("user")
    );

    // Iterar sobre los grupos y sus items
    for (let grupoItem of gruposEItems) {
      for (let item of grupoItem.items) {
        // Obtener el estado del checkbox y la observación correspondiente
        const checkboxState =
          checkboxStates[grupoItem.grupo.id_grupo]?.[item.id_item];
        const observacion = observaciones[item.id_item];

        // Realizar la petición POST para guardar los datos del checklist
        const response = await fetch(`${UrlApi}/checklist/`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            id_item: item.id_item,
            id_grupo: grupoItem.grupo.id_grupo,
            cumple: checkboxState === "cumple" ? 1 : 0,
            no_cumple: checkboxState === "no_cumple" ? 1 : 0,
            n_a: checkboxState === "n_a" ? 1 : 0,
            observacion: observacion,
            deposito: depositoSeleccionado.id,
            responsable: representante[0].id,
            fecha: formattedDate,
            //fecha: datosCheck.fecha,
            dias_habiles: datosCheck.dias_habiles,
            uuid: uuid,
            usuario: localStorage.getItem("user"),
          }),
        });

        // Manejar la respuesta de la API
        if (response.ok === false) {
          console.log("ERROR EN API");
          const error = await response.json();
          setError(error);
          setSeverityAlerta("error");
          setMensajeAlerta("Inconvenientes con la API. No se pudo guardar.");
        } else {
          console.log("Conexión con API OK");
          try {
            const json = await response.json();
            if (json.message) {
              if (json.message === "OK") {
                console.log("Guardado Satisfactoriamente");
                setNotificacion(true);
                setOpenSnack(true);
                setColorMensaje("#D4EFDF");
                setMensaje("Guardado");
                setSeveritySnack("success");
              } else {
                console.log(
                  "Respuesta incorrecta de la API. Revisar que sea 'OK'"
                );
                setNotificacion(true);
                setOpenSnack(true);
                setColorMensaje("#D4EFDF");
                setSeveritySnack("error");
                setMensaje("Registro Guardado. REVISAR RESPUESTA DE API (E_S)");
              }
            } else if (json.sqlMessage) {
              json && setError(json);
              console.log(json);
              setMensajeAlerta(
                "Error SQL (" + json.code + " Errno: " + json.errno + ")"
              );
            } else {
              setMensajeAlerta("ERROR DESCONOCIDO.");
            }
          } catch (error) {
            if (error.toString().includes("is not valid JSON")) {
              console.log(
                "Guardado. Advertencia: Actualizar respuesta de la API. JSON inválido."
              );
              setNotificacion(true);
              setOpenSnack(true);
              setColorMensaje("#D4EFDF");
              setMensaje("Guardado");
              setSeveritySnack("success");
            } else {
              setMensajeAlerta("ERROR DESCONOCIDO.");
            }
          }
        }
        setUpdate(true);
      }
    }
    guardarListaFlota();
    guardarEstados();
    handleTerminar();
  };

  const guardarListaFlota = async (e) => {
    logEventos(
      "Guardar lista flota ",
      "Control de Depositos",
      "Se guardo la lista flota",
      localStorage.getItem("user")
    );
    console.log("Estoy por guardar");
    for (let i = 0; i < datosFlota.length; i++) {
      const element = datosFlota[i];
      await fetch(UrlApi + "/checklist_chofer_flota", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          usuario: localStorage.getItem("user"),
          //fecha: datosFlota[i].fecha,
          fecha: formattedDate,
          dominio_tipo: datosFlota[i].dominio_tipo,
          deposito: datosFlota[i].deposito,
          responsable: datosFlota[i].responsable,
          dias_inactivos: datosFlota[i].dias_inactivos,
          kg_vendidos: datosFlota[i].kg_vendidos,
          zona: datosFlota[i].zona,
          chofer_acompañante: datosFlota[i].chofer_acompañante,
        }),
      })
        .then((res) => {
          if (!res.err) {
            // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
            setUpdate(true);
            console.log("Guardado");
          } else {
            console.log("Error");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };

  // Función para contar la cantidad de checks marcados
  const contarChecksMarcados = () => {
    let cantidadCumple = 0;
    let cantidadNoCumple = 0;
    let cantidadNA = 0;

    // Iterar sobre los grupos
    for (const grupoId in checkboxStates) {
      // Iterar sobre los items dentro del grupo
      for (const itemId in checkboxStates[grupoId]) {
        const estado = checkboxStates[grupoId][itemId];
        // Contar los estados de los checkboxes
        if (estado === "cumple") {
          cantidadCumple++;
        } else if (estado === "no_cumple") {
          cantidadNoCumple++;
        } else if (estado === "n_a") {
          cantidadNA++;
        }
      }
    }

    return {
      cumple: cantidadCumple,
      no_cumple: cantidadNoCumple,
      n_a: cantidadNA,
    };
  };
  // Llamada a la función para obtener la cantidad de checks marcados
  const cantidadChecks = contarChecksMarcados(checkboxStates);

  //adjunto
  const handleOpenCloseAdj = (value) => {
    setOpenCloseAdj(value);
  };

  const guardarEstados = async (e) => {
    logEventos(
      "Guardar estados de evaluación comercial",
      "Control de Depositos",
      "Guardo los estados ",
      localStorage.getItem("user")
    );
    console.log("Botón Guardar desactivado");
    console.log("Estoy por guardar");
    await fetch(UrlApi + "/estados_evaluacion_comercial/", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        comercial: representante[0].id,
        sucursal: depositoSeleccionado.id,
        checklist: 1,
        cumple: cantidadChecks.cumple,
        nocumple: cantidadChecks.no_cumple,
        // fecha: datosCheck.fecha,
        fecha: formattedDate,
        usuario: localStorage.getItem("user"),
        porcentaje_checklist: porcentajeChecklist,
      }),
    })
      .then((res) => {
        if (!res.err) {
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          console.log("Guardado");
        } else {
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleTerminar = () => {
    setChecklistCompleto(true);
  };

  const [mostrarResultadoCheck, setMostrarResultadoCheck] = useState(false);

  const handleVerResultadoClick = () => {
    setTabla(false);
    setMostrarResultadoCheck(true);
  };

  const handleCloseVer = () => {
    setMostrarResultadoCheck(false);
    setDepositoSeleccionado(""); // Limpiar la selección
    setTabla(true);
  };

  const obtenerPatentePorId = (id) => {
    const vehiculo = vehiculos.find((vehiculo) => vehiculo.id === id);
    return vehiculo
      ? `${vehiculo.patente} - ${vehiculo.marca} - ${vehiculo.modelo}`
      : "";
  };

  //console.log("checklist completo", checklistCompleto);

  // Función para calcular el total de kg_vendidos
  const calcularTotalKgVendidos = () => {
    return datosFlota.reduce(
      (total, linea) => total + Number(linea.kg_vendidos || 0),
      0
    );
  };

  return (
    <div>
      <Navbar />
      {representante && representante[0] ? (
        tabla ? (
          <>
            {checklistCompleto === false ? (
              <>
                <br></br>
                <Box
                  bgcolor="#2B76A6"
                  padding={1}
                  borderRadius={0}
                  color="white"
                  textAlign="center"
                  display="flex" // Agregado para que los elementos se coloquen en línea
                  alignItems="center"
                  marginTop={1}
                >
                  <AssignmentIcon
                    style={{
                      fontSize: 40,
                      marginRight: "20px",
                      marginLeft: "20px",
                    }}
                  />
                  <Typography variant="h4" component="div">
                    Control de depósitos
                  </Typography>
                </Box>
                <br></br>
                <Grid container spacing={2}>
                  <Grid item xs={4} md={4} marginLeft={12}>
                    {deposito && ( // Verifica si deposito tiene valores asignados
                      <Autocomplete
                        disabled={!!depositoSeleccionado} // Deshabilitar si ya se seleccionó un depósito
                        disableClearable
                        size="small"
                        required
                        // id="id_sucursal"
                        noOptionsText={"Depósito INEXISTENTE"}
                        options={deposito}
                        autoHighlight
                        getOptionLabel={(sucursal) =>
                          sucursal.id + "-" + sucursal.nombre
                        }
                        // value={depositoSeleccionado} // Añadido para establecer el valor seleccionado
                        onChange={(event, value) =>
                          setDepositoSeleccionado(value)
                        }
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            key={option.id}
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.id} - {option.nombre}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                            }}
                            focused
                            size="small"
                            placeholder="Tipee para buscar..."
                            label="Depósito"
                          />
                        )}
                      />
                    )}
                  </Grid>
                  <Grid item xs={4} md={3}>
                    <TextField
                      label="Responsable"
                      name="responsable"
                      size="small"
                      focused
                      required
                      fullWidth
                      value={
                        representante ? representante[0].nombre_apellido : null
                      }
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={4} md={3}>
                    <FormControl focused color="primary" required size="small">
                      <InputLabel id="mes">Período</InputLabel>
                      <Select
                        required
                        variant="outlined"
                        name="mes"
                        value={selectedDate}
                        onChange={handleDateChange}
                        label="Período"
                        labelId="mes"
                      >
                        {monthOptions.map((elemento) => (
                          <MenuItem key={elemento.value} value={elemento.value}>
                            {elemento.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <br></br>
                {estados && estados[0] && estados[0].checklist === 1 ? (
                  <div>
                    <Paper
                      elevation={6}
                      style={{ width: "80%", padding: "20px", marginLeft: 100 }}
                    >
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        align="center"
                      >
                        <strong style={{ cursor: "pointer", fontSize: 20 }}>
                          Control de depósito ya realizado.
                        </strong>
                      </Typography>
                      <br></br>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        align="center"
                      >
                        <strong
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                            color: "red",
                          }}
                          onClick={handleVerResultadoClick}
                        >
                          Presione aqui para verlo
                        </strong>
                      </Typography>
                    </Paper>
                  </div>
                ) : (
                  <>
                    {depositoSeleccionado ? (
                      <Paper
                        elevation={6}
                        style={{
                          width: "80%",
                          padding: "60px",
                          marginLeft: 70,
                        }}
                      >
                        <form
                          onSubmit={(e) => {
                            e.preventDefault(); // Evita el comportamiento por defecto del formulario
                            guardarCheck(); // Llama a la función de guardar
                            setDisabledGuardar(true); // Desactiva el botón después de guardar
                          }}
                        >
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <b
                                    style={{
                                      textDecoration: "underline",
                                      fontSize: 20,
                                    }}
                                  >
                                    Checklist de depósitos
                                  </b>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {/* Mapea sobre gruposEItems para renderizar cada grupo y sus items */}
                              {terminado === true &&
                                gruposEItems.map((grupoItem) => (
                                  <React.Fragment
                                    key={grupoItem.grupo.id_grupo}
                                  >
                                    <TableRow>
                                      <TableCell
                                        style={{
                                          fontSize: 17,
                                          backgroundColor: "#C7C7C7",
                                        }}
                                        colSpan={3}
                                      >
                                        <b>{grupoItem.grupo.nombre_grupo}</b>
                                      </TableCell>
                                    </TableRow>
                                    {grupoItem.items.map((item) => (
                                      <TableRow key={item.id_item}>
                                        <TableCell style={{ fontSize: 16 }}>
                                          <b>{item.nombre_item}</b>
                                          <br></br>
                                          <br></br>
                                          {item.definicion_item}
                                        </TableCell>
                                        <TableCell>
                                          <FormGroup
                                            aria-label="position"
                                            row
                                            style={{ marginRight: 220 }}
                                          >
                                            <FormControlLabel
                                              disabled={
                                                datosCheck.no_cumple === 1 ||
                                                datosCheck.n_a === 1
                                              }
                                              labelPlacement="end"
                                              label="Cumple"
                                              required={
                                                datosCheck.cumple === 0 ||
                                                datosCheck.cumple === 0
                                              }
                                              control={
                                                <Checkbox
                                                  name="cumple"
                                                  checked={
                                                    checkboxStates[
                                                      grupoItem.grupo.id_grupo
                                                    ]?.[item.id_item] ===
                                                      "cumple" || false
                                                  }
                                                  onChange={(e) =>
                                                    handleChange(
                                                      grupoItem.grupo.id_grupo,
                                                      item.id_item,
                                                      e.target.checked
                                                        ? "cumple"
                                                        : null
                                                    )
                                                  }
                                                />
                                              }
                                            />
                                            <FormControlLabel
                                              disabled={
                                                datosCheck.cumple === 1 ||
                                                datosCheck.n_a === 1
                                              }
                                              labelPlacement="end"
                                              label="No cumple"
                                              required={
                                                datosCheck.no_cumple === 0 ||
                                                datosCheck.no_cumple === 0
                                              }
                                              control={
                                                <Checkbox
                                                  name="no_cumple"
                                                  checked={
                                                    checkboxStates[
                                                      grupoItem.grupo.id_grupo
                                                    ]?.[item.id_item] ===
                                                      "no_cumple" || false
                                                  }
                                                  onChange={(e) =>
                                                    handleChange(
                                                      grupoItem.grupo.id_grupo,
                                                      item.id_item,
                                                      e.target.checked
                                                        ? "no_cumple"
                                                        : null
                                                    )
                                                  }
                                                />
                                              }
                                            />
                                            <FormControlLabel
                                              disabled={
                                                datosCheck.cumple === 1 ||
                                                datosCheck.no_cumple === 1
                                              }
                                              labelPlacement="end"
                                              label="N/A"
                                              required={
                                                datosCheck.n_a === 0 ||
                                                datosCheck.n_a === 0
                                              }
                                              control={
                                                <Checkbox
                                                  name="n_a"
                                                  checked={
                                                    checkboxStates[
                                                      grupoItem.grupo.id_grupo
                                                    ]?.[item.id_item] ===
                                                      "n_a" || false
                                                  }
                                                  onChange={(e) =>
                                                    handleChange(
                                                      grupoItem.grupo.id_grupo,
                                                      item.id_item,
                                                      e.target.checked
                                                        ? "n_a"
                                                        : null
                                                    )
                                                  }
                                                />
                                              }
                                            />
                                          </FormGroup>
                                        </TableCell>
                                        <TableCell>
                                          <TextField
                                            required={
                                              checkboxStates[
                                                grupoItem.grupo.id_grupo
                                              ]?.[item.id_item] ===
                                                "no_cumple" ||
                                              item.obs_oblig === 1
                                                ? true
                                                : false
                                            }
                                            fullWidth
                                            size={size}
                                            margin="normal"
                                            placeholder={
                                              checkboxStates[
                                                grupoItem.grupo.id_grupo
                                              ]?.[item.id_item] ===
                                                "no_cumple" ||
                                              item.obs_oblig === 1
                                                ? "Por favor, ingrese una observación"
                                                : ""
                                            }
                                            style={{
                                              ...style,
                                              width: "100%",
                                              marginRight: 300,
                                            }}
                                            focused
                                            color="primary"
                                            name="observacion"
                                            label="Observaciones"
                                            variant="outlined"
                                            multiline
                                            rows={5}
                                            value={observaciones[item.id_item]} // Usar la observación correspondiente
                                            inputProps={{
                                              maxLength: 200,
                                            }}
                                            onChange={(e) =>
                                              actualizarObservacion(
                                                item.id_item,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </React.Fragment>
                                ))}
                            </TableBody>
                          </Table>
                          <div style={{ textAlign: "center", marginTop: 20 }}>
                            <Typography variant="poster" fontSize={19}>
                              Cumplidos : {cantidadChecks.cumple} - No cumplidos
                              : {cantidadChecks.no_cumple}
                            </Typography>
                            <Grid item xs={4} py={1} mt={2}>
                              <Button
                                onClick={() => setOpenCloseAdj(!openCloseAdj)}
                                variant="outlined"
                                size="medium"
                                startIcon={<AttachFileIcon />}
                              >
                                ADJUNTAR ARCHIVOS
                              </Button>
                            </Grid>
                          </div>
                          <Divider style={{ width: "101%", marginTop: 20 }} />
                          <br></br>
                          <Typography
                            variant="poster"
                            fontSize={26}
                            marginLeft={9}
                            style={{ textDecoration: "underline" }}
                          >
                            Flota de vehículos
                          </Typography>
                          <Typography
                            variant="poster"
                            fontSize={20}
                            marginLeft={9}
                          >
                            *Ante faltante de vehículos comuníquese con RR.HH.
                          </Typography>
                          <br></br>
                          <br></br>
                          <Grid container spacing={2}>
                            <Grid item xs={3} md={2} marginLeft={3}>
                              <TextField
                                label="Días habiles en el periodo"
                                name="dias_habiles"
                                size="small"
                                focused
                                required
                                fullWidth
                                autoComplete="off"
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                inputProps={{
                                  max: 31,
                                }}
                                value={datosCheck.dias_habiles}
                                onChange={(event) => {
                                  const value = event.target.value;
                                  if (
                                    value === "" ||
                                    (Number(value) <= 31 && Number(value) >= 0)
                                  ) {
                                    almacenar(event);
                                  }
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Box sx={{ flexGrow: 1 }} alignItems="flex">
                            {datosFlota.map((linea, index) => (
                              <div
                                key={index}
                                style={{
                                  paddingBottom: "2px",
                                  paddingLeft: "3px",
                                  paddingTop: "10px",
                                }}
                              >
                                <Grid container spacing={2}>
                                  <Grid item xs={6} md={3.5} marginLeft={2}>
                                    <TextField
                                      label="Vehículo"
                                      name="vehiculo"
                                      size="small"
                                      focused
                                      fullWidth
                                      autoComplete="off"
                                      onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                      value={obtenerPatentePorId(
                                        linea.dominio_tipo
                                      )}
                                      inputProps={{
                                        readOnly: true,
                                      }}
                                      onChange={(e) =>
                                        handleVehiculoChange(
                                          index,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={4} md={1.4}>
                                    <TextField
                                      label="Días inactivos"
                                      name="dias_inactivos"
                                      size="small"
                                      focused
                                      required
                                      fullWidth
                                      autoComplete="off"
                                      onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                      value={linea.dias_inactivos}
                                      onChange={(e) =>
                                        handleDiasInactivoChange(
                                          index,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={3} md={1.3}>
                                    <TextField
                                      label="Kg.Vendidos"
                                      name="kg_vendidos"
                                      size="small"
                                      focused
                                      required
                                      fullWidth
                                      autoComplete="off"
                                      onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                      value={linea.kg_vendidos}
                                      onChange={(e) =>
                                        handleKgVendidosChange(
                                          index,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={4} md={2.5}>
                                    <TextField
                                      label="Zona de reparto"
                                      name="zona"
                                      size="small"
                                      focused
                                      required
                                      fullWidth
                                      autoComplete="off"
                                      value={linea.zona}
                                      onChange={(e) =>
                                        handleZonaChange(index, e.target.value)
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={4} md={3}>
                                    <TextField
                                      label="Chofer y Acompañante"
                                      name="chofer_acompañante"
                                      size="small"
                                      focused
                                      required
                                      fullWidth
                                      autoComplete="off"
                                      value={linea.chofer_acompañante}
                                      onChange={(e) =>
                                        handleChoferChange(
                                          index,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                            ))}
                            <Grid container>
                              <Grid item xs={2} marginTop={3}>
                                <Typography
                                  variant="poster"
                                  fontSize={20}
                                  style={{ textDecoration: "underline" }}
                                >
                                  Total Kg. Vendidos:
                                </Typography>
                              </Grid>
                              <Grid item xs={2} marginTop={3}>
                                <Typography variant="poster" fontSize={20}>
                                  {calcularTotalKgVendidos()}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                          <br></br>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="poster" fontSize={18}>
                              <strong>
                                * El botón no se habilitará hasta completar el
                                control *
                              </strong>
                            </Typography>
                          </div>
                          <Grid
                            container
                            sx={{ justifyContent: "center", mt: 4 }}
                          >
                            <Grid item mr={5} mt={-2}>
                              <Button
                                variant="contained"
                                disabled={disabledGuardar}
                                type="submit"
                                size="large"
                              >
                                Guardar
                              </Button>
                            </Grid>
                          </Grid>
                        </form>
                      </Paper>
                    ) : (
                      <Paper
                        elevation={6}
                        style={{
                          width: "80%",
                          padding: "20px",
                          marginLeft: 100,
                        }}
                      >
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          align="center"
                        >
                          <strong>
                            Seleccione un depósito y un período para comenzar.
                          </strong>
                        </Typography>
                      </Paper>
                    )}
                  </>
                )}

                {notificacion ? (
                  <Notify
                    mensaje={mensaje}
                    open={openSnack}
                    color={colormensaje}
                    finalizar={setFinalizar}
                    severity={severitySnack}
                  />
                ) : null}
                {/* ALERT --> SE MUESTRA SI HAY UN ERROR EN LA API (NO SE CONECTA O ESTÁ MAL LA RUTA, ETC.) */}
                {error && mensajeAlerta ? (
                  <Alerta mensaje={mensajeAlerta} severity={severityAlerta} />
                ) : null}

                {openCloseAdj ? ( // ADJUNTAR IMAGEN
                  <FileUploadCheck
                    fileId={uuid}
                    openclose={true}
                    mostrar={true}
                    onClose={() => handleOpenCloseAdj(false)} // Proporciona una función de cierre
                  />
                ) : null}
              </>
            ) : (
              <PantallaFinal />
            )}
          </>
        ) : null
      ) : (
        <>
          <br></br>
          <Paper
            elevation={6}
            style={{ width: "80%", padding: "20px", marginLeft: 70 }}
          >
            <Typography variant="body1" color="textSecondary" align="center">
              <strong>
                Esta pantalla solo es para representantes comerciales.
              </strong>
            </Typography>
          </Paper>
        </>
      )}

      {mostrarResultadoCheck ? (
        <VerResultadoCheck
          onClose={handleCloseVer}
          tabla={setTabla}
          responsable={representante ? representante[0].nombre_apellido : null}
          sucursal={depositoSeleccionado}
          fecha={datosCheck.fecha}
          estados={estados}
        />
      ) : null}
    </div>
  );
};

export default CheckList;
