import React, { useEffect, useState } from "react";
import { getData, putData } from "../../../../services/library";
import { Dialog, DialogContent, FormControl, Select, MenuItem, InputLabel, Box, Grid, Typography, TextField, DialogActions, Button } from "@mui/material";
import { grey } from "@mui/material/colors";
import { logEventos } from "../../../../services/services";
import Notify from "../../../Notify";

export default function ModalEditar(props) {
    const [datosf, setdatosf] = useState(props.datos);
    const [sucursales, setSucursales] = useState([]);

    useEffect(() => {
        getData('/sucursales/tipoPlanta', setSucursales)
    }, []);

    const style = {
        backgroundColor: "#ffffff",
        width: "100%",
    };

    const [notificacion, setNotificacion] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
    });

    const almacenar = (e) => {
        setdatosf({
            ...datosf, [e.target.name]: e.target.value === "" ? null : e .target.value
        })
    };

    const saveModificaciones = (e) => {
        putData(e,
            `/pedidos/${datosf.idpedido}`,
            {
                kgacargar: datosf.kgacargar,
                orden_prioridad: datosf.orden_prioridad,
                idsucursal_atiende: datosf.idsucursal_atiende,
                usuario_aumentakg: localStorage.getItem('user')
            },
            setNotificacion
        )
        logEventos('Modificacion', 'Crear Repartos', 'Modificar datos del pedido en el armado de repartos', localStorage.getItem('user'));
    }

    return (
        <Dialog open={props.open} onClose={() => props.setOpen(false)} fullWidth maxWidth="sm">
            <Box sx={{ flexGrow: 1 }}>
                <Grid container backgroundColor="#1F618D" p={1.5}>

                    <Grid
                        item
                        xs={12}
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Typography variant="h5" color="#ffffff">
                            Editar
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <DialogContent>
                <Box
                    border={0.5}
                    borderColor={grey[300]}
                    backgroundColor="#F4F6F6"
                    p={2}
                    py={4}
                >
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={4}>
                            <TextField
                                name="orden_prioridad"
                                label="Orden"
                                focused
                                value={datosf.orden_prioridad}
                                style={style}
                                onChange={almacenar}

                            ></TextField>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                name="kgacargar"
                                label="Kgs requeridos"
                                focused
                                value={datosf.kgacargar}
                                style={style}
                                onChange={almacenar}

                            ></TextField>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl style={style} focused required >
                                <InputLabel>Sucursal abastece</InputLabel>
                                <Select
                                    name="idsucursal_atiende"
                                    value={datosf.idsucursal_atiende}
                                    label="Sucursal abastece"
                                    onChange={almacenar}
                                >
                                    {sucursales
                                        ? sucursales.map((elemento) => (
                                            <MenuItem key={elemento.id} value={elemento.id}>
                                                {elemento.nombre}
                                            </MenuItem>
                                        ))
                                        : null}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Grid
                    mt={3}
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => props.setOpen(false)}
                    >
                        Volver
                    </Button>
                    <Button

                        variant="contained"
                        onClick={saveModificaciones}
                        color="success"
                    >
                        Guardar
                    </Button>
                </Grid>
            </DialogActions>
            {notificacion && notificacion.open ? (
                <Notify
                    mensaje={notificacion.mensaje}
                    open={notificacion.open}
                    color={notificacion.color}
                    handleClose={()=>{
                        props.setOpen(false)
                        props.actualizar();
                    }}
                    severity={notificacion.severity}
                />
            ) : null}
        </Dialog>

    )
}