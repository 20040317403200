//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio/Nicolas Pascucci
// Creation Date : 05/03/2024
// Version : 1
// Description : Pantalla de botones para ver los pedidos
//------Changes ----------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect } from "react";
import "moment/locale/es";
import { Button } from "@mui/material";
import Navbar from "../Navbar";
import ButtonGroup from "@mui/material/ButtonGroup";
import PedidosNatural from "./PedidosNatural";
import PedidosHipergas from "./PedidosHipergas";
import { logEventos } from "../../services/services";

function PedidosProveedores() {
  const [showPedidosNatural, setShowPedidosNatural] = useState(true);
  const [showPedidosHipergas, setShowPedidosHipergas] = useState(false);

  useEffect(() => {
    logEventos("Acceso al menú pedidos a petroleras", "Pedidos a Petroleras", "Hacer pedidos a NaturalGas o a Hipergas", localStorage.getItem("user"))
}, [])

  const handleNatural = () => {
    setShowPedidosNatural(true);
    setShowPedidosHipergas(false);
   // console.log("Botón NATURALGAS presionado");
  };

  const handleHiper = () => {
    setShowPedidosHipergas(true);
    setShowPedidosNatural(false);
  //  console.log("Botón HIPERGAS presionado");
  };

  return (
    <>
      <Navbar />
      <div style={{ textAlign: "center", marginTop: 10 }}>
        <ButtonGroup
          disableElevation
          variant="contained"
          aria-label="Disabled button group"
          size="large"
          // fullWidth
        >
          <Button
            onClick={handleNatural}
            style={{
              backgroundColor: "#7C297C",
              color: "white",
              borderRadius: 5,
              fontSize: 15,
            }}
          >
            NATURALGAS
          </Button>
          <Button
            onClick={handleHiper}
            style={{ borderRadius: 5, fontSize: 15, marginLeft: 3 }}
          >
            HIPERGAS
          </Button>
        </ButtonGroup>
      </div>
      {showPedidosNatural && <PedidosNatural />}
      {showPedidosHipergas && <PedidosHipergas />}
    </>
  );
}

export default PedidosProveedores;
