import React, { useState, useEffect } from 'react';
import { getData, postData } from '../../../services/library';
import Title from '../../Title';
import { Checkbox, Container, Box, Grid, Autocomplete, TextField, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Button } from '@mui/material';
import Notify from '../../Notify';

export default function AddBancos_cuentas(props) {
    const [proveedores, setProveedores] = useState([]);
    const [tipoMoneda, settipoMoneda] = useState(null);
    const [proveedorSelected, setproveedorSelected] = useState();
    const [disbtn, setDisBtn] = useState(false);
    const [datosFormulario, setdatosFormulario] = useState({
        codigo: null,
        id_proveedor: proveedorSelected || null,
        cbu: null,
        nro_cuenta: null,
        cuenta_contable: null,
        apto_cobranzafce: null,
        id_moneda: null,
        saldo_actual: null,
        saldo_pendiente: null
    });

    const [notificacion, setNotificacion] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
    });

    useEffect(() => {
        getData('/proveedores/activos', setProveedores)
        getData('/desplegables/tipoMoneda', settipoMoneda)
    }, []);

    const almacenar = (e) => {
        setdatosFormulario({
            ...datosFormulario, [e.target.name]: e.target.value ? e.target.value : null
        })
    };

    const handleChange = (e) => {
        setdatosFormulario({
            ...datosFormulario, [e.target.name]: e.target.checked ? 1 : 0
        })
    };

    const style = {
        backgroundColor: "#ffffff",
        width: "100%"
    };

    const handleClose = () => {
        props.tabla(true)
        props.alta(false)
    };

    const handleSubmit = (e) => {
        setDisBtn(true);
        postData(e,
            '/bancos_cuentas',
            {
                codigo: datosFormulario.codigo,
                id_proveedor: proveedorSelected.id,
                cbu: datosFormulario.cbu,
                nro_cuenta: datosFormulario.nro_cuenta,
                cuenta_contable: datosFormulario.cuenta_contable,
                apto_cobranzafce: datosFormulario.apto_cobranzafce,
                id_moneda: datosFormulario.id_moneda,
                saldo_actual: datosFormulario.saldo_actual,
                saldo_pendiente: datosFormulario.saldo_pendiente,
                usuario: localStorage.getItem('user'),
                activo: 1

            },
            setNotificacion
        )
    };


    return (
        <div>
            <Container>
                <Title titulo={`Cargar cuenta`} handleClose={handleClose} />
                <form autoComplete='off' onSubmit={handleSubmit}>
                    <Box backgroundColor="#F4F6F6"
                        mt={1}
                        p={3}
                        mb={2}
                        border={0.5}
                        borderColor={"#ABB2B9"}
                        borderRadius={4}>
                        <Grid container spacing={2} alignItems='center'>
                            <Grid item xs={2}>
                                <TextField
                                    style={style}
                                    required
                                    focused
                                    name="codigo"
                                    label='Código'
                                    value={datosFormulario.codigo}
                                    onChange={almacenar}
                                    placeholder='00' />
                            </Grid>
                            <Grid item xs={5}>
                                <Autocomplete
                                    fullWidth
                                    noOptionsText={"Proveedor INEXISTENTE"}
                                    // clearOnBlur
                                    options={proveedores}
                                    getOptionLabel={(p) => p.nombre_fantasia || p.razon_social}
                                    onChange={(event, value) => setproveedorSelected(value)}
                                    isOptionEqualToValue={(option, value) => (option.nombre_fantasia ?? option.razon_social) === (value.nombre_fantasia ?? option.razon_social)}
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            key={option.id}
                                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                            {...props}
                                        >
                                            {option.nombre_fantasia ?? option.razon_social} - {option.nro_cuit}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            focused
                                            color="primary"
                                            required
                                            label="Proveedor"
                                            style={style}
                                            inputProps={{ ...params.inputProps }}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={5}>
                                <TextField
                                    name="cbu"
                                    focused
                                    label="CBU"
                                    style={style}
                                    value={datosFormulario.cbu}
                                    onChange={almacenar} />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    name="nro_cuenta"
                                    label="Número de cuenta"
                                    style={style}
                                    focused
                                    value={datosFormulario.nro_cuenta}
                                    onChange={almacenar}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    name="cuenta_contable"
                                    label="Cuenta contable"
                                    style={style}
                                    focused
                                    value={datosFormulario.cuenta_contable}
                                    onChange={almacenar}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel
                                    label="Apto cobranza FCE"
                                    labelPlacement='start'
                                    control={
                                        <Checkbox
                                            name="apto_cobranzafce"
                                            onChange={handleChange}
                                        />
                                    } />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl style={style} focused required>
                                    <InputLabel>Moneda</InputLabel>
                                    <Select
                                        name="id_moneda"
                                        label="Moneda"
                                        value={datosFormulario.id_moneda || ""}
                                        onChange={almacenar}
                                    >
                                        {(tipoMoneda ?? []).map(elemento => (
                                            <MenuItem key={elemento.id} value={elemento.id}>
                                                {elemento.valor}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    name="saldo_actual"
                                    label="Saldo Actual"
                                    focused
                                    style={style}
                                    value={datosFormulario.saldo_actual}
                                    onChange={almacenar}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    name="saldo_pendiente"
                                    label="Saldo Pendiente"
                                    style={style}
                                    focused
                                    value={datosFormulario.saldo_pendiente}
                                    onChange={almacenar} />
                            </Grid>

                        </Grid>
                    </Box>
                    <Grid mt={2}
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItemns="center"
                    >
                        <Button
                            variant='contained'
                            color="error"
                            onClick={handleClose}>
                            Volver
                        </Button>
                        <Button
                            disabled={disbtn}
                            variant="contained"
                            color="success"
                            type='submit'>
                            Guardar
                        </Button>
                    </Grid>
                    {notificacion && notificacion.open ? (
                        <Notify
                            mensaje={notificacion.mensaje}
                            open={notificacion.open}
                            color={notificacion.color}
                            handleClose={handleClose}
                            severity={notificacion.severity}
                        />
                    ) : null}
                </form>
            </Container>
        </div >
    )
}