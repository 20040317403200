import React, { useState, useEffect } from 'react';
import { Box, Grid, FormControl, InputLabel, Select, MenuItem, TextField, IconButton, Tooltip } from '@mui/material';
import { AddCircleOutline, Delete } from '@mui/icons-material';

export default function Pie2(props) {
    const { impuestos, uuid, datosImpuestos, setdatosImpuestos } = props;

    const [dataForm, setdataForm] = useState(datosImpuestos);

    useEffect(() => {
        setdataForm(datosImpuestos);
    }, [datosImpuestos]);

    const agregarLinea = () => {
        const nuevaLinea = {
            id_impuesto: null,
            detalle: null,
            porcentaje_alicuota: null,
            importe: null,
            uuid_comprobante: uuid,
            usuario: localStorage.getItem('user')
        };
        setdatosImpuestos(prevState => [...prevState, nuevaLinea]);
    };

    const handleInputChange = (index, field, value) => {
        setdatosImpuestos(prevState => {
            const updatedValues = prevState.map((item, i) =>
                i === index ? { ...item, [field]: value } : item
            );
            return updatedValues;
        });
    };

    const handleDeshacerLinea = (index) => {
        setdatosImpuestos(prevState => {
            if (prevState.length === 1) {
                return prevState;
            }
            const newState = prevState.filter((_, i) => i !== index);
            return newState;
        });
    };

    const style = {
        backgroundColor: "#ffffff",
        width: "100%"
    };

    return (
        <div>
            <Box
                backgroundColor="#F4F6F6"
                mt={2}
                p={3}
                border={0.5}
                borderColor={"#ABB2B9"}
                borderRadius={4}
            >
                {dataForm.map((dato, index) => (
                    <div key={index}>
                        <Grid container spacing={1} alignItems="center" direction={{ xs: 'column', sm: 'row' }}>
                            <Grid item xs={4} mb={1}>
                                <FormControl fullWidth focused size="small" style={style}>
                                    <InputLabel>Impuestos</InputLabel>
                                    <Select
                                        label="Impuestos"
                                        value={dato.id_impuesto || ""}
                                        onChange={(e) => handleInputChange(index, 'id_impuesto', e.target.value)}
                                        name="id_impuesto"
                                    >
                                        {(impuestos ?? []).map(elemento => (
                                            <MenuItem key={elemento.idimpuestos} value={elemento.idimpuestos}>
                                                {elemento.descripcion}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} mb={1}>
                                <TextField
                                    onChange={(e) => handleInputChange(index, 'detalle', e.target.value)}
                                    value={dato.detalle || ""}
                                    focused
                                    name="detalle"
                                    style={style}
                                    required
                                    size="small"
                                    label="Detalle"
                                />
                            </Grid>
                            <Grid item xs={2} mb={1}>
                                <TextField
                                    focused
                                    style={style}
                                    required
                                    size="small"
                                    label="Alic %"
                                    value={dato.porcentaje_alicuota || ""}
                                    onChange={(e) => handleInputChange(index, 'porcentaje_alicuota', e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={2} mb={1}>
                                <TextField
                                    focused
                                    style={style}
                                    required
                                    size="small"
                                    label="Importe"
                                    value={dato.importe || ""}
                                    onChange={(e) => handleInputChange(index, 'importe', e.target.value)}
                                />
                            </Grid>

                            <Tooltip title="Eliminar línea">
                                <IconButton
                                    variant="outlined"
                                    color="error"
                                    onClick={() => handleDeshacerLinea(index)}
                                >
                                    <Delete />
                                </IconButton>
                            </Tooltip>

                            {index === dataForm.length - 1 && (
                                <Tooltip title="Agregar línea">
                                    <IconButton
                                        variant="outlined"
                                        color="primary"
                                        onClick={agregarLinea}
                                    >
                                        <AddCircleOutline />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Grid>
                    </div>
                ))}
            </Box>
        </div>
    );
}


//========================================================================
/*----- Component --------------------------------------------------------/
// Developer     : Candela Grosso   
// Creation Date : 23/05/2024
// Version       : 1.0  
// Description   :  Componente con el formulario para la carga del cuerpo del comprobante para guardar los impuestos.
//------Changes --------------------------------------------------------------------- -------------//
// Change Date   :
// Change by     :
// Change description: 
//========================================================================*/