//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio /Nicolas Pascucci
// Creation Date : 4/3/2024
// Version : 1
// Description : Tabla de reguladores
//------Changes ----------------------------------------------------------//
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useEffect, useState, useMemo } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Container,
  Typography,
  Box,
} from "@mui/material";
import Navbar from "../Navbar";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import { logEventos } from "../../services/services";

function Reguladores() {
  const [regulador, setRegulador] = useState(); // p/tabla
  const [tabla, setTabla] = useState(true);
  const [datosRegulador, setDatosRegulador] = useState({});
  const globalTheme = useTheme();

  useEffect(() => {
    logEventos("Acceso al menú reguladores", "Historial Reguladores", "Ver los reguladores instalados y retirados", localStorage.getItem("user"))
}, [])
  //*************************************************************************************************************************************/
  const obtenerReguladores = () => {
    fetch(UrlApi + "/reguladores/")
      .then((data) => data.json())
      .then((data) => setRegulador(data));
  };

  //ejecuta la funcion de obtener
  useEffect(() => {
    obtenerReguladores();
  }, [tabla]);
  //*************************************************************************************************************************************/
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)" //background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const columns = useMemo(
    () => [
      { header: "ID", accessorKey: "id_regulador", size: 100, enableHiding: true, },
      {
        header: "Nro.Serie",
        accessorKey: "nro_serie",
        size: 150,
      },
      {
        header: "Fabricante",
        accessorKey: "fabricante",
        size: 150,
      },
      {
        header: "Estado",
        accessorKey: "estado",
        size: 100,
        enableHiding: true,
      },
      {
        header: "Instalado",
        accessorKey: "fecha_instalado",
        size: 100,
      },
      {
        header: "Retirado",
        accessorKey: "fecha_retirado",
        size: 100,
      },
      {
        header: "Nro.Reclamo",
        accessorKey: "id_reclamo",
        size: 100,
      },
    ],
    []
  );

  const initialState = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
    columnVisibility: {
      estado: false,
      id_regulador: false,
    },
  };

  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "success",
    size: "small",
  };
  //*************************************************************************************************************************************/
  return (
    <div>
      <Navbar />
      <Container maxWidth="xl" mt={1} mb={2}>
        {regulador && tabla ? (
          <Box mt={2} boxShadow={3}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
                enableColumnResizing
                columns={columns}
                data={regulador}
                initialState={initialState}
                positionActionsColumn="last"
                enableExpandAll={false}
                enableToolbarInternalActions={true}
                muiTableHeadCellProps={tableHeadCellProps}
                muiTableBodyCellProps={({ row }) => ({
                  align: "left",
                  sx: {
                    color: "black",
                    borderColor: "black",
                  },
                })}
                enableColumnActions={false}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                enableDensityToggle={false}
                renderTopToolbarCustomActions={() => (
                  <>
                    <Typography variant="poster" fontSize={26}>
                      Historial Reguladores
                    </Typography>
                  </>
                )}
                layoutMode="grid"
                localization={MRT_Localization_ES}
                enableColumnDragging={false} //No permite que se arrastren las columnas.
                // ACCIONES
                renderToolbarInternalActions={(
                  { table } // boton para nuevo , al lado del buscar
                ) => (
                  <>
                    <MRT_FullScreenToggleButton table={table} />
                  </>
                )}
                renderRowActions={(
                  { row } //las acciones del costado editar y ver
                ) => (
                  <div>
                  </div>
                )}
              />
            </ThemeProvider>
            <br />
            <br />
          </Box>
        ) : null}

      </Container>
    </div>
  );
}

export default Reguladores;
