import { Alert, Avatar, Box, Checkbox, Container, Divider, Drawer, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Paper, Select, Snackbar, SnackbarContent, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableRow, TextField, Typography } from '@mui/material'
import { deepOrange, green } from '@mui/material/colors';
import { AuthApi, UrlApi } from '../../../services/apirest';
import { useEffect } from 'react';
import { useState } from 'react';
import { AddModeratorSharp, AddTask, AppSettingsAlt, DomainAdd, EditNoteOutlined, Key, LockOpen, LockPersonOutlined, Person2, Save, Savings } from '@mui/icons-material';
import ConfirmDialog2 from '../../../services/ConfirmDialog2';
import SucursalesAuth from './SucursalesAuth';
import GestionMenues from './GestionMenues';
import GestionApps from './GestionApps';

export default function DatosDelUsuario({ datos, handleDrawerToggle }) {
    console.log('props1', datos)
    const [sucursal, setSucursal] = useState("");
    const [cajonsucursales, setCajonSucursales] = useState(false)
    const [cajonmenues, setCajonMenues] = useState(false)
    const [cajonapps, setCajonapps] = useState(false)
    const [sectores, setSectores] = useState("")
    const [bloqueo, setBloqueo] = useState()
    const [snack, setSnack] = useState(false)
    const [snackMsg, setSnackMsg] = useState("")
    const [snackPass, setSnackPass] = useState(false)
    const [cierra, setCierra] = useState(true)

    const [confirmacambiopass, setConfirmacambiopass] = useState(false);

    const [wuser, setWuser] = useState()

    useEffect(() => {
        datos === "nuevo" ?
            setWuser({
                username: '',
                nombrepila: '',
                apellido: '',
                email: '',
                celular: '',
                sector: 0,
                sucursal: 0,
                rolegral: 'usuario',
                auditor: 0,
                autoriza_requerimientos: 0,
                controlador: 0,
                factibilidades: 0,
                adminrrhh: 0
            })
            :
            setWuser({
                username: datos.username,
                nombrepila: datos.nombrepila,
                apellido: datos.apellido,
                email: datos.email,
                celular: datos.celular,
                sector: datos.sector,
                sucursal: datos.sucursal,
                rolegral: datos.rolegral,
                auditor: datos.auditor,
                autoriza_requerimientos: datos.autoriza_requerimientos,
                controlador: datos.controlador,
                factibilidades: datos.factibilidades,
                adminrrhh: datos.adminrrhh,
                anula_facturas: datos.anula_facturas
            })
    }, []);

    useEffect(() => {
        datos === 'nuevo' ?
            setBloqueo(false) :
            setBloqueo(datos.bloqueado === "NO" ? false : true)
    }, [])

    const handleWuser = (e) => {
        const value = e.target.type === 'checkbox' ? (e.target.checked ? 1 : 0) : e.target.value;

        setWuser((prevWuser) => ({
            ...prevWuser,
            [e.target.name]: value,
        }));
    };


    useEffect(() => {
        ///Traer sucursales para los selects
        const obtenerSucursales = async () => {
            const data = await fetch(UrlApi + "/sucursales")
            const sucursalesData = await data.json()
            setSucursal(sucursalesData)
        }
        const obtenerSestores = async () => {
            const data = await fetch(UrlApi + "/sectores/tabla")
            const sectorData = await data.json()
            setSectores(sectorData)
        }
        obtenerSucursales()
        obtenerSestores()
    }, [])


    //cierra snackbars
    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnack(false)
        handleCloseDrawer()
    };

    const handleCloseSnackPass = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackPass(false);
        cierra && handleCloseDrawer()
        setCierra(true)
    };

    const bloquearUser = async () => {
        const data = await fetch(UrlApi + "/userdata/" + datos.id, {
            method: 'PUT', headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                datos.bloqueado === "NO" ? { bloqueado: 'SI' } : { bloqueado: 'NO' }
            )
        })
        setBloqueo(!bloqueo)
        setSnackMsg("Se bloqueo usuario !!")
        setSnack(!snack)
    }

    const addUsuario = async () => {
        setCierra(false)
        if (!validarCampos()) return; // Verifica campos antes de guardar
        await fetch(AuthApi + "sign-up", {
            method: 'POST', headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                username: wuser.username,
                password: "123456", ///ponemos esto de passsword incial
                password_repeat: "123456", ///ponemos esto de passsword incial
                nombrepila: wuser.nombrepila,
                apellido: wuser.apellido,
                email: wuser.email,
                celular: wuser.celular,
                sector: wuser.sector,
                sucursal: wuser.sucursal,
                rolegral: wuser.rolegral,
                auditor: wuser.auditor,
                autoriza_requerimientos: wuser.autoriza_requerimientos,
                anula_facturas: wuser.anula_facturas

            })
        })
            .then(response => {
                if (response.status === 409) {
                    console.log('Hubo un conflicto en el servidor (código 409)');
                    return response.json().then(data => {
                        console.log('Mensaje recibido del servidor:', data.msg);
                        throw new Error(data.msg); // Lanzar un error con el mensaje devuelto por el backend
                    });
                }
                if (!response.ok) {
                    console.log('Hubo un problema al realizar la solicitud (código ' + response.status + ')');
                    console.log(response);
                    throw new Error('Hubo un problema al registrar el usuario.'); // Manejo de otros errores en caso de que la respuesta del servidor no sea exitosa
                }
                console.log('La solicitud fue exitosa.');
                return response.json(); // Convertir la respuesta a JSON
            })
            .then(data => {
                // Manejar la respuesta exitosa del servidor
                console.log('Mensaje recibido del servidor:', data.msg);
                setSnackMsg(data.msg); // Mostrar el mensaje devuelto por el backend
                setSnackPass(true)
            })
            .catch(error => {
                console.error('Error:', error.message);
                setSnackMsg(error.message); // Mostrar el mensaje devuelto por el backend
                setSnackPass(true)
            })
    }

    const modificaUsuario = async () => {
        setCierra(false)
        if (!validarCampos()) return; // Verifica campos antes de guardar
        await fetch(UrlApi + "/userdata/" + datos.id, {
            method: 'PUT', headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                wuser
            )
        })
            .then(response => {
                if (response.status === 409) {
                    console.log('Hubo un conflicto en el servidor (código 409)');
                    return response.json().then(data => {
                        console.log('Mensaje recibido del servidor:', data.msg);
                        throw new Error(data.msg); // Lanzar un error con el mensaje devuelto por el backend
                    });
                }
                if (!response.ok) {
                    console.log('Hubo un problema al realizar la solicitud (código ' + response.status + ')');
                    throw new Error('Hubo un problema al registrar el usuario.'); // Manejo de otros errores en caso de que la respuesta del servidor no sea exitosa
                }
                console.log('La solicitud fue exitosa.');
                return response.json(); // Convertir la respuesta a JSON
            })
            .then(data => {
                // Manejar la respuesta exitosa del servidor
                console.log('Mensaje recibido del servidor:', data.msg);
                setSnackMsg(data.msg); // Mostrar el mensaje devuelto por el backend
                setSnackPass(true)
            })
            .catch(error => {
                console.error('Error:', error.message);
                setSnackMsg(error.message); // Mostrar el mensaje devuelto por el backend
                setSnackPass(true)
            })
    }

    ///Reseteo de contraseña a 123456
    const resetPass = async () => {
        const data = await fetch(UrlApi + "/userpass/" + datos.id, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                password: "123456", ///reseta a 123456
            }),
        })
            .then((res) => (res.ok ? [setSnackMsg("Se reseteo La contraseña a 123456"), setSnackPass(true)] : alert("No se pudo cambiar la contraseña")))
            .catch((err) => {
                console.log(err.message);
            });
    };

    ////Cerrar el drawer del componente padre
    const handleCloseDrawer = () => {
        // Llama a la función pasada desde el padre para cerrar el Drawer
        handleDrawerToggle();
    };

    const handleCajonsucursalesToggle = () => {
        setCajonSucursales(!cajonsucursales)
    }

    const handleCajonMenuesToggle = () => {
        setCajonMenues(!cajonmenues)
    }

    const handleCajonAppsToggle = () => {
        setCajonapps(!cajonapps)
    }


    const validarCampos = () => {
        // Implementación de la función de validación
        console.log(wuser); // Agrega este console.log para verificar los valores de wuser
        if (!wuser.username || !wuser.nombrepila || !wuser.apellido || !wuser.email || !wuser.celular || !wuser.sector || !wuser.sucursal || !wuser.rolegral) {
            setSnackMsg("Todos los campos requeridos deben ser completados.");
            setSnackPass(true);
            return false;
        }
        return true;
    };

    return (
        <div>
            {wuser ?
                <>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        border: '1px solid red',
                        borderRadius: '5px',
                        padding: '0px',
                        backgroundColor: 'white',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                    }}>
                        <Typography variant="h6" mr={1} ml={1}>
                            Acciones
                        </Typography>
                        {datos === 'nuevo' ?
                            <IconButton
                                onClick={() => addUsuario()}
                                title='Guardar datos'>
                                <AddTask color='success' />
                            </IconButton >
                            :
                            <Box sx={{ display: 'flex' }}>
                                <IconButton
                                    onClick={() => modificaUsuario()}
                                    title='Guardar datos'>
                                    <Save />
                                </IconButton>

                                <IconButton
                                    onClick={() => bloquearUser()}
                                    title={bloqueo ? "Desbloquear usuario" : "Bloquear usuario"}>
                                    {bloqueo ?
                                        <LockOpen color='success' /> :
                                        <LockPersonOutlined color='error' />}
                                </IconButton>

                                <IconButton
                                    onClick={() => setCajonMenues(true)}
                                    title='Seteos/Menues'>
                                    <AddModeratorSharp sx={{ color: 'palevioletred' }} />
                                </IconButton>

                                <IconButton
                                    onClick={() => setCajonapps(true)}
                                    title='Seteos para APPs'>
                                    <AppSettingsAlt sx={{ color: 'olivedrab' }} />
                                </IconButton>

                                <IconButton
                                    onClick={() => setConfirmacambiopass(true)}
                                    title='Password Reset'>
                                    <Key color='secondary' />
                                </IconButton>
                                <IconButton
                                    onClick={() => setCajonSucursales(true)}
                                    title='Sucursales'>
                                    <DomainAdd color='primary' />
                                </IconButton>


                            </Box>
                        }
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        border: '1px solid lightgrey',
                        borderRadius: '5px',
                        padding: '1px',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                    }}>
                        <Avatar sx={{ bgcolor: deepOrange[500] }} variant="rounded"><Person2 /></Avatar>
                        <Typography variant='h5' ml={1}>
                            {datos === 'nuevo' ?
                                <TextField
                                    fullWidth
                                    autoComplete='no'
                                    size='small'
                                    label="Nombre usuario"
                                    variant="standard"
                                    value={wuser.username}
                                    name='username'
                                    onChange={(e) => handleWuser(e)} />
                                :

                                wuser.username.trim()
                            } </Typography>
                    </Box>
                    <TableContainer component={Paper}>
                        <Table size='small'>
                            <TableBody>
                                <TableRow >
                                    <TableCell>
                                        <TextField
                                            fullWidth
                                            autoComplete='no'
                                            size='small'
                                            label="Apellido"
                                            variant="standard"
                                            value={wuser.apellido}
                                            name='apellido'
                                            onChange={(e) => handleWuser(e)} />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            fullWidth
                                            size='small'
                                            label="Nombre"
                                            variant="standard"
                                            value={wuser.nombrepila}
                                            name='nombrepila'
                                            onChange={(e) => handleWuser(e)} />
                                    </TableCell>
                                </TableRow>
                                <TableRow >
                                    <TableCell colSpan={2}>
                                        <TextField
                                            fullWidth
                                            size='small'
                                            label="E-Mail"
                                            variant="standard"
                                            value={wuser.email}
                                            name='email'
                                            onChange={(e) => handleWuser(e)} />
                                    </TableCell>
                                </TableRow>
                                <TableRow >
                                    <TableCell colSpan={2}>
                                        <TextField
                                            fullWidth
                                            size='small'
                                            label="TelefonoMovil"
                                            variant="standard"
                                            value={wuser.celular}
                                            name='celular'
                                            helperText='escribir +549 cod area y el nro telefono'
                                            onChange={(e) => handleWuser(e)} />
                                    </TableCell>
                                </TableRow>
                                <TableRow >
                                    <TableCell>
                                        <FormControl fullWidth>
                                            <InputLabel>Rol principal</InputLabel>
                                            <Select
                                                size="small"
                                                variant="standard"
                                                value={wuser.rolegral}
                                                name='rolegral'
                                                onChange={(e) => handleWuser(e)}
                                            >
                                                <MenuItem value={"admin"}>Admin</MenuItem>
                                                <MenuItem value={"usuario"}>Usuario</MenuItem>
                                                <MenuItem value={"superusuario"}>SuperUsuario</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </TableCell>

                                    <TableCell>
                                        <FormControl required fullWidth>
                                            <InputLabel>Sucursal</InputLabel>
                                            <Select
                                                size='small'
                                                id="sucursal"
                                                value={wuser.sucursal || ""}
                                                variant="standard"
                                                name='sucursal'
                                                onChange={(e) => handleWuser(e)}
                                            >
                                                {sucursal ? sucursal.map(elemento => (
                                                    <MenuItem key={elemento.id} value={elemento.id}>
                                                        {elemento.nombre}
                                                    </MenuItem>
                                                )) : null}
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <FormControl required fullWidth>
                                            <InputLabel>Sector</InputLabel>
                                            <Select
                                                id="sector"
                                                value={wuser.sector || ""}
                                                variant="standard"
                                                size="small"
                                                name='sector'
                                                onChange={(e) => handleWuser(e)}
                                            >
                                                {sectores ? sectores.filter(el => el.estado === 1).map(elemento => (
                                                    <MenuItem key={elemento.id_sectro} value={elemento.id_sector}>
                                                        {elemento.nombre_sector}
                                                    </MenuItem>
                                                )) : null}
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell>Bloqueado: {bloqueo ? "SI" : "NO"}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} sx={{ backgroundColor: 'bisque', color: 'red', fontSize: 24, }}>
                                        Condiciones especiales
                                    </TableCell>
                                </TableRow>
                                <TableRow >
                                    <TableCell>
                                        <FormControlLabel
                                            variant="dense"
                                            fullWidth
                                            control={
                                                <Checkbox
                                                    checked={wuser.auditor === 1}
                                                    onChange={() => handleWuser({
                                                        target: {
                                                            name: 'auditor',
                                                            value: wuser.auditor === 1 ? 0 : 1,
                                                        },
                                                    })}
                                                />
                                            }
                                            label="Es Auditor"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <FormControlLabel
                                            variant="dense"
                                            fullWidth
                                            control={
                                                <Checkbox
                                                    checked={wuser.autoriza_requerimientos === 1}
                                                    onChange={() => handleWuser({
                                                        target: {
                                                            name: 'autoriza_requerimientos',
                                                            value: wuser.autoriza_requerimientos === 1 ? 0 : 1,
                                                        },
                                                    })}
                                                />
                                            }
                                            label="Autoriza Requerimientos Materiales"
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <FormControlLabel
                                            variant="dense"
                                            fullWidth
                                            control={
                                                <Checkbox
                                                    checked={wuser.factibilidades === 1}
                                                    onChange={() => handleWuser({
                                                        target: {
                                                            name: 'factibilidades',
                                                            value: wuser.factibilidades === 1 ? 0 : 1,
                                                        },
                                                    })}
                                                />
                                            }
                                            label="Ver todas las Factibilidades"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <FormControlLabel
                                            variant="dense"
                                            fullWidth
                                            control={
                                                <Checkbox
                                                    checked={wuser.controlador === 1}
                                                    onChange={() => handleWuser({
                                                        target: {
                                                            name: 'controlador',
                                                            value: wuser.controlador === 1 ? 0 : 1,
                                                        },
                                                    })}
                                                />
                                            }
                                            label="Es Controlador"
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <FormControlLabel
                                            variant="dense"
                                            fullWidth
                                            control={
                                                <Checkbox
                                                    checked={wuser.adminrrhh === 1}
                                                    onChange={() => handleWuser({
                                                        target: {
                                                            name: 'adminrrhh',
                                                            value: wuser.adminrrhh === 1 ? 0 : 1,
                                                        },
                                                    })}
                                                />
                                            }
                                            label="RRHH Admin"
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <FormControlLabel
                                            variant="dense"
                                            fullWidth
                                            control={
                                                <Checkbox
                                                    checked={wuser.anula_facturas === 1}
                                                    onChange={() => handleWuser({
                                                        target: {
                                                            name: 'anula_facturas',
                                                            value: wuser.anula_facturas === 1 ? 0 : 1,
                                                        },
                                                    })}
                                                />
                                            }
                                            label="Anular Facturas"
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </> : <></>}

            {/*//cajon para sucursales*/}
            <Drawer anchor="right"
                open={cajonsucursales}
                onClose={handleCajonsucursalesToggle}
                PaperProps={{
                    style: { width: 450, backgroundColor: 'lightgoldenrodyellow' },
                }}
                transitionDuration={{ enter: 600, exit: 300 }} // Ajusta la duración de la transición
            >
                <Container sx={{ marginTop: '60px' }}>
                    <SucursalesAuth datos={datos.suc_auth} userid={datos.id} cerrarcajon={handleCajonsucursalesToggle} cerrarcajondatos={handleDrawerToggle} />
                </Container>
            </Drawer>

            {/*//cajon para menues*/}
            <Drawer anchor="right"
                open={cajonmenues}
                onClose={handleCajonMenuesToggle}
                PaperProps={{
                    style: { width: 450, backgroundColor: 'lightgoldenrodyellow' },
                }}
                transitionDuration={{ enter: 600, exit: 300 }} // Ajusta la duración de la transición
            >
                <Container sx={{ marginTop: '60px' }}>
                    <GestionMenues wusername={datos.username} />
                </Container>
            </Drawer>

            {/*//cajon para configuraciones de usuarios de apps*/}
            <Drawer anchor="right"
                open={cajonapps}
                onClose={handleCajonAppsToggle}
                PaperProps={{
                    style: { width: 450, backgroundColor: 'lightcyan' },
                }}
                transitionDuration={{ enter: 600, exit: 300 }} // Ajusta la duración de la transición
            >
                <Container sx={{ marginTop: '60px' }}>
                    {console.log("id desde usuario", datos.id_transportista)}
                    <GestionApps wtta={datos.id_transportista} wid={datos.id} />
                </Container>
            </Drawer>


            <Snackbar
                open={snack}
                autoHideDuration={3000}
                onClose={handleCloseSnack}
                sx={{ marginTop: '50px', marginRight: '10px' }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <SnackbarContent
                    style={{ backgroundColor: bloqueo ? 'red' : 'blueviolet', color: 'white', fontWeight: 'bold', }}
                    message={bloqueo ? "Se Bloqueo usuario" : "Se Desbloqueo usuario"}
                />
            </Snackbar>

            <Snackbar
                open={snackPass}
                autoHideDuration={5000}
                onClose={handleCloseSnackPass}
                sx={{ marginTop: '50px', marginRight: '10px' }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <SnackbarContent
                    style={{ backgroundColor: 'blueviolet', color: 'white', fontWeight: 'bold', }}
                    message={snackMsg}
                />
            </Snackbar>

            <ConfirmDialog2
                title="Resetear Password"
                open={confirmacambiopass}
                setOpen={setConfirmacambiopass}
                onConfirm={() => resetPass()}
                botonConfirma="Si, Resetar"
            >
                Resetea password del usuario {datos.username} ?
            </ConfirmDialog2>
        </div>


    )
}
