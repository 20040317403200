import React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Notify(props) {
  // console.log("props" , props)
  // Color WARNING: #F6DDCC
  // Color SUCCESS: #D4EFDF
  // Color ERROR: #F1948A

  const [openSnack, setOpenSnack] = React.useState(props.open);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
    props.handleClose ? props.handleClose() : props.finalizar(1)
  };

  return (
    <div>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={props.duracion || 1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity={props.severity}
            sx={{ width: "100%" }}
            style={{ backgroundColor: props.color, color: "black" }}
          >
            {props.mensaje}
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
}

export default Notify;
