//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio/Nicolas Pascucci
// Creation Date : 05/03/2024
// Version : 1
// Description : Pantalla de pedidos de NaturalGas
//------Changes ----------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect } from "react";
import moment from "moment";
import "moment/locale/es";
import {
  Button,
  Box,
  Stack,
  Snackbar,
  TextField,
  Alert,
  Grid,
  Autocomplete,
} from "@mui/material";
import Navbar from "../Navbar";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { UrlApi } from "../../services/apirest";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/system";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import { logEventos } from "../../services/services";

const Item = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  textAlign: "center",
  border: "0px",
}));

const StyledStack = styled(Stack)({
  borderRadius: "8px",
  margin: "20px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
});

function PedidosNatural() {
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [disabledGuardar, setDisabledGuardar] = useState(false);
  // FECHA NUEVA
  const fechaActual = moment();
  const [fechaInicioSemana, setFechaInicioSemana] = useState(
    fechaActual.clone().day(8)
  );
  const diasSemanaActual = [];
  //************************ OBTENER ****************************************************//
  const [bocaCargasSeleccionada, setBocaCargasSeleccionada] = useState("");

  const [solicitudes, setSolicitudes] = useState();
  const obtenerSolicitudes = () => {
    fetch(
      UrlApi +
        "/proveedores_abastecimiento/existentesNatural/" +
        bocaCargasSeleccionada.idbocas_carga +
        "/'" +
        diasSemanaActual[0].fecha +
        "'/'" +
        diasSemanaActual[6].fecha +
        "'"
    )
      .then((data) => data.json())
      .then((data) => setSolicitudes(data));
  };
  useEffect(() => {
    obtenerSolicitudes();
  }, [bocaCargasSeleccionada, fechaInicioSemana]);

 // console.log("SOLICITUDES EXISTENTES", solicitudes);

  //************************ OBTENER ****************************************************//

  const [bocasCargas, setBocasCargas] = useState();

  const obtenerBocas = () => {
    fetch(UrlApi + "/bocas_carga/")
      .then((data) => data.json())
      .then((data) => setBocasCargas(data));
  };
  useEffect(() => {
    obtenerBocas();
  }, []);
 // console.log("BOCAS DE CARGAS", bocasCargas);

 // console.log("BOCA DE CARGA SELECCIONADA", bocaCargasSeleccionada);

 // console.log("PROVEEDOR SELECCIONADO", bocaCargasSeleccionada.idbocas_carga);

  //************************ FECHA ****************************************************//

  // Obtén la fecha actual o la fecha deseada

  //  console.log(fechaActual.format("dddd"));

  // Calcula la fecha del próximo lunes
  const lunesProximo = fechaActual.clone().day(8); // 8 representa el día lunes de la próxima semana

  // Obtén los nombres y fechas de los días de la semana para la próxima semana
  const diasProximaSemana = [];
  for (let i = 0; i < 7; i++) {
    const fechaDia = lunesProximo.clone().add(i, "days");
    diasProximaSemana.push({
      id: i, // Utilizamos la posición en el bucle como id
      nombre: fechaDia.format("dddd"),
      fecha: fechaDia.format("YYYY-MM-DD"),
    });
  }
 // console.log(" DIAS ", diasProximaSemana);
  //******************************** VALORES ******************************************** //
  const [butanoValues, setButanoValues] = useState(
    Array(diasSemanaActual.length).fill("")
  );
  const [propanoValues, setPropanoValues] = useState(
    Array(diasSemanaActual.length).fill("")
  );

  useEffect(() => {
    // Obtener los valores de propano y butano de las solicitudes y actualizar el estado
    if (solicitudes) {
      const butanoData = Array(diasSemanaActual.length).fill("");
      const propanoData = Array(diasSemanaActual.length).fill("");

      solicitudes.forEach((solicitud) => {
        const index = diasSemanaActual.findIndex((item) =>
          moment(item.fecha).isSame(solicitud.fecha, "day")
        );

        if (index !== -1) {
          if (solicitud.butano !== null) {
            butanoData[index] = {
              fecha: solicitud.fecha,
              value: solicitud.butano,
            };
          }

          if (solicitud.propano !== null) {
            propanoData[index] = {
              fecha: solicitud.fecha,
              value: solicitud.propano,
            };
          }
        }
      });
      setButanoValues(butanoData);
      setPropanoValues(propanoData);
    }
  }, [solicitudes]);

  const handleButanoChange = (index, value, fecha) => {
    const newValues = [...butanoValues];
    newValues[index] = { fecha, value };
    setButanoValues(newValues);
  };

  const handlePropanoChange = (index, value, fecha) => {
    const newValues = [...propanoValues];
    newValues[index] = { fecha, value };
    setPropanoValues(newValues);
  };

  const guardarSolicitudes = async (index) => {
    logEventos("Guardar solicitud", "Pedidos a Petroleras", "Se guardo la solicitud", localStorage.getItem("user"))
    try {
      const item = diasSemanaActual[index];
      // Verifica si ya existe un registro con la fecha y la sucursal
      const solicitudExistente = solicitudes.find(
        (solicitud) =>
          moment(solicitud.fecha).isSame(item.fecha, "day") &&
          solicitud.proveedor === parseInt(bocaCargasSeleccionada.idbocas_carga)
      );

      if (solicitudExistente) {
        const response = await fetch(
          UrlApi + "/proveedores_abastecimiento/" + solicitudExistente.id,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              butano: butanoValues[index].value,
              propano: propanoValues[index].value,
              usuario: localStorage.getItem("user"),
              viaje_butano: butanoValues[index].value,
              viaje_propano: propanoValues[index].value,
            }),
          }
        );
        const responseData = await response.json();

        if (!responseData.err) {
          console.log("Guardado PUT");
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El pedido se guardo exitosamente !");
        } else {
          console.log("Error al guardar");
        }
      } else {
        const response = await fetch(UrlApi + "/proveedores_abastecimiento", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            fecha: butanoValues[index].fecha
              ? moment(butanoValues[index].fecha).format("YYYY-MM-DD")
              : diasSemanaActual[index].fecha,
            butano: butanoValues[index].value,
            propano: propanoValues[index].value,
            viaje_butano: butanoValues[index].value,
            viaje_propano: propanoValues[index].value,
            usuario: localStorage.getItem("user"),
            proveedor: bocaCargasSeleccionada.idbocas_carga,
            empresa_destino: 4,
          }),
        });

        const responseData = await response.json();

        if (!responseData.err) {
          console.log("Guardado POST");
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El pedido se guardo exitosamente !");
        } else {
          console.log("Error al guardar");
        }
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
    obtenerSolicitudes();
  };

  //******************************** TABLAS ******************************************** //


  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  // Imprime los nombres y fechas de los días de la próxima semana
 // console.log("VALOR DE PROPANO Y BUTANO:", butanoValues, propanoValues);

  useEffect(() => {
    if (bocaCargasSeleccionada.length === 0) {
      setDisabledGuardar(true);
    } else {
      setDisabledGuardar(false);
    }
  }, [bocaCargasSeleccionada]);
  //*****************************FECHAS****************************************/


const irSemanaAnterior = () => {
  setFechaInicioSemana((prevFecha) => prevFecha.clone().subtract(7, "days"));
  // setCambio(true)
  // obtenerSolicitudes()
};

const irSemanaSiguiente = () => {
  setFechaInicioSemana((prevFecha) => prevFecha.clone().add(7, "days"));
  // obtenerSolicitudes()
  // setCambio(true)
};

for (let i = 0; i < 7; i++) {
  const fechaDia = fechaInicioSemana.clone().add(i, "days");
  diasSemanaActual.push({
    id: i,
    nombre: fechaDia.format("dddd"),
    fecha: fechaDia.format("YYYY-MM-DD"),
  });
}

  return (
    <>
      {/* <Navbar /> */}
      <Box
        bgcolor="#2B76A6"
        padding={1}
        borderRadius={0}
        color="white"
        textAlign="center"
        display="flex" // Agregado para que los elementos se coloquen en línea
        alignItems="center"
        marginTop={1}
      >
        <LocalFireDepartmentIcon
          style={{ fontSize: 40, marginRight: "20px", marginLeft: "20px" }}
        />
        <Typography variant="h4" component="div">
          NaturalGas
        </Typography>
      </Box>
      <form>
        <Container style={{ marginTop: 12, marginLeft: -2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Autocomplete
                disabled={!bocasCargas}
                disableClearable
                size="small"
                focused={true}
                required
                id="idbocas_carga"
                noOptionsText={"Proveedor INEXISTENTE"}
                options={bocasCargas}
                autoHighlight
                getOptionLabel={(bocasCargas) =>
                  bocasCargas.idbocas_carga +
                  "-" +
                  bocasCargas.proveedor +
                  "-" +
                  bocasCargas.nombre_boca
                }
                onChange={(event, value) => setBocaCargasSeleccionada(value)}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    key={option.id}
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.idbocas_carga} - {option.proveedor} -{" "}
                    {option.nombre_boca}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                    }}
                    focused
                    style={style}
                    size="small"
                    placeholder="Tipee para buscar..."
                    label="Proveedor"
                  />
                )}
              />
            </Grid>
          </Grid>
        </Container>
        
        <div style={{ textAlign: 'center' }}>
        <Button 
          onClick={irSemanaAnterior} 
          variant="contained" size="small">
          &lt; Semana Anterior
        </Button>
        <Button
          onClick={irSemanaSiguiente}
          variant="contained"
          sx={{ marginLeft: 1 }}
          size="small"
        >
          Semana Siguiente &gt;
        </Button>
        </div>

        <StyledStack direction="row" spacing={2}>
          {diasSemanaActual.map((item, index) => (
            <>
              <Item
                key={index}
                sx={{
                  fontSize: 20,
                  color: "white",
                  backgroundColor: "#2B76A6",
                }}
              >
                <Box backgroundColor="#2B76A6">
                  <div style={{ margin: "1px 0" }}></div>
                  {`${item.nombre.toUpperCase()}`} <br />{" "}
                  {`${moment(item.fecha).format("DD-MM-YYYY")}`}
                  <div style={{ margin: "4px 0" }}></div>
                </Box>
                <Box backgroundColor="#F4F6F6" p={2} borderRadius={2}>
                  <TextField
                    style={{ backgroundColor: "white" }}
                    sx={{ mt: 2 }}
                    id={`butano-${index}`}
                    label="Butano"
                    variant="outlined"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    focused
                    required
                    value={
                      butanoValues[index] ? butanoValues[index].value : ""
                    }
                    onChange={(e) =>
                      handleButanoChange(index, e.target.value, item.fecha)
                    }
                  />
                  <TextField
                    style={{ backgroundColor: "white" }}
                    sx={{ mt: 2 }}
                    id={`propano-${index}`}
                    label="Propano"
                    variant="outlined"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    focused
                    required
                    value={
                      propanoValues[index] ? propanoValues[index].value : ""
                    }
                    onChange={(e) =>
                      handlePropanoChange(index, e.target.value, item.fecha)
                    }
                  />
                </Box>
              </Item>
            </>
          ))}
        </StyledStack>
        <br />
        <Grid container justifyContent="end">
          <Grid item mr={5} mt={-2}>
            <Button
              variant="contained"
              disabled={disabledGuardar}
              onClick={() => {
                diasSemanaActual.forEach((item, index) => {
                  guardarSolicitudes(index);
                });
              }}
              size="large"
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </form>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}

export default PedidosNatural;
