import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { UrlApi } from "../../../../services/apirest";
import Button from "@mui/material/Button";
import moment from "moment";
import DialogoTransitoSalida from "./DialogoTransitoSalida"
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';


function AddRegistroSalida(props) {
  const [motivos, setMotivos] = useState("");
  const [clientes, setClientes] = useState("");
  const [productos, setProductos] = useState();
  const [transportePropio, settransportePropio] = useState(true);
  const [tanquePropio, setTanquePropio] = useState(true);
  const [tractores, setTractores] = useState();
  const [tanquesMoviles, setTanquesMoviles] = useState();
  const [choferes, setChoferes] = useState("");
  const [niveles, setNiveles] = useState("");
  const [tanquesFijos, setTanquesFijos] = useState("");

  const [datosSalida, setDatosSalida] = useState({
    es_entrada_o_salida: 2, //entrada:1 | salida:2
    s_nroticket: "",
    s_fecha: moment(new Date()).format("YYYY-MM-DD"),
    s_hora: "",
    s_pesada: "",
    s_idproducto: "",
    s_idmotivo: "",
    s_idcliente1: "",
    s_idcliente2: "",
    s_idcliente3: "",
    s_idsucursal: "",
    s_idpropietariotractor: "",
    s_idpropietariotanque: "",
    s_idtractor: "",
    s_idtanquemovil: "",
    s_idchofer: "",
    s_tractor: "",
    s_tanquemovil: "",
    s_chofer: "",
    s_idnivelgasoil: "",
    s_observaciones: "",
    s_idtqcarga: "",
  });


  const obtenerMotivos = () => {
    // fetch(UrlApi + "/desplegables/modulo/motivoSalidaProp")
    fetch(UrlApi + "/desplegables/motivoSalidaProp")
      .then((data) => data.json())
      .then((data) => setMotivos(data));
    // console.log(UrlApi + "/desplegables/modulo/motivoSalidaProp");
  };

  const obtenerClientes = () => {
    fetch(UrlApi + "/clientes/propelente/activos")
      .then((data) => data.json())
      .then((data) => setClientes(data));
  };

  const obtenerTanquesFijos = () => {
    fetch(UrlApi + "/tanquesfijospropelente")
      .then((data) => data.json())
      .then((data) => setTanquesFijos(data));
  };

  const obtenerProductos = () => {
    fetch(UrlApi + "/productospropelente/propelente")
      .then((data) => data.json())
      .then((data) => setProductos(data));
  };

  const obtenerTractores = () => {
    fetch(UrlApi + "/vehiculos/tractorespropelente")
      .then((data) => data.json())
      .then((data) => setTractores(data));
  };

  const obtenerTanques = () => {
    fetch(UrlApi + "/vehiculos/tanquespropelente")
      .then((data) => data.json())
      .then((data) => setTanquesMoviles(data));
  };

  const obtenerChoferes = () => {
    fetch(UrlApi + "/choferes/propelente")
      .then((data) => data.json())
      .then((data) => setChoferes(data));
  };

  const obtenerNiveles = () => {
    fetch(UrlApi + "/desplegables/nivelesTanque")
      .then((data) => data.json())
      .then((data) => setNiveles(data));
  };

  useEffect(() => {
    obtenerMotivos();
    obtenerClientes();
    obtenerTanquesFijos();
    obtenerProductos();
    obtenerTractores();
    obtenerTanques();
    obtenerChoferes();
    obtenerNiveles();
  }, []);

  // console.log(motivos);

  const handleChangeEmpTrans = (e) => {
    settransportePropio(e.target.checked);
  };

  const handleTanquePropio = (e) => {
    setTanquePropio(e.target.checked);
  };

  const setearDatosSalida = (e) => {
    setDatosSalida({ ...datosSalida, [e.target.name]: e.target.value });
  };

  async function guardarSalida(e) {
    e.preventDefault();
    await fetch(UrlApi + "/entradas_salidas_propelente", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        es_entrada_o_salida: 2, //entrada:1 | salida:2
        s_nroticket: datosSalida.s_nroticket,
        s_fecha: datosSalida.s_fecha,
        s_hora: datosSalida.s_hora,
        s_pesada: datosSalida.s_pesada,
        s_idproducto: datosSalida.s_idproducto,
        s_idmotivo: datosSalida.s_idmotivo,
        s_idcliente1: datosSalida.s_idcliente1,
        s_idcliente2: null,
        s_idcliente3: null,
        s_idsucursal: null,
        s_idpropietariotractor: transportePropio ? 124 : 125,
        s_idpropietariotanque: tanquePropio ? 124 : 125,
        s_idtractor: datosSalida.s_idtractor,
        s_idtanquemovil: datosSalida.s_idtanquemovil,
        s_idchofer: datosSalida.s_idchofer,
        s_tractor: datosSalida.s_tractor,
        s_tanquemovil: datosSalida.s_tanquemovil,
        s_chofer: datosSalida.s_chofer,
        s_idnivelgasoil: datosSalida.s_idnivelgasoil,
        s_observaciones: datosSalida.s_observaciones,
        s_idtqcarga: datosSalida.s_idtqcarga,
        id_estado: 138
      }),
    }).then(handleClose())
    //response&&console.log(response)
    // if (response.ok === false) {
    //   console.log("ERROR EN API");
    //   const error = response && response.json();
    //   setError(error);
    //   setSeverityAlerta("error")
    //   setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
    // } else {
    //   console.log("Conexión con api OK");
    //   try {
    //     //Parseo respuesta de la api a json()
    //     const json = await response.json();
    //     //si api me devuelve lo q le puse en message
    //     if (json.message) {
    //       //Si ese mensaje es "OK"
    //       if (json.message === "OK") {
    //         console.log("Guardado Satisfactoriamente");
    //         setNotificacion(true);
    //         setOpenSnack(true);
    //         setColorMensaje("#D4EFDF");
    //         setMensaje("Guardado");
    //         setSeveritySnack("success");
    //       } else {
    //   console.log("res en api incorrecto. Revisar que sea 'OK'")
    //         setNotificacion(true);
    //         setOpenSnack(true);
    //         setColorMensaje("#D4EFDF");
    //         setSeveritySnack("error");
    //         setMensaje("Registro Guardado. REVISAR RESPUESTA DE API (E_S)");
    //       }
    //     } else if(json.sqlMessage) {
    //       json && setError(json);
    //       console.log(json);
    //       setMensajeAlerta("SQL erróneo (" + json.code + " Errno: " + json.errno + " )" );
    //     } else {
    //       setMensajeAlerta("ERROR DESCONOCIDO.");
    //     }
    //   } catch (error) {
    //     if (error.toString().includes("is not valid JSON")) {
    //       console.log("Guardado. Advertencia: Actualizar respuesta de api. JSON invalido.");
    //       setNotificacion(true);
    //       setOpenSnack(true);
    //       setColorMensaje("#D4EFDF");
    //       setMensaje("Guardado");
    //       setSeveritySnack("success");
    //     } else {
    //       setMensajeAlerta("ERROR DESCONOCIDO.");
    //     }
    //   }
    // }
  }


  const handleClose = () => {
    props.tabla(true);
    props.formSalida(false);
  };

  const [showDialogTransito, setShowDialogTransito]=useState(false);
  const [size,setSize]=useState("small")
  const [añadirCli2,setAñadirCli2]=useState(false);

  const eliminarCli2=()=>{
    setAñadirCli2(false)
    setDatosSalida({...datosSalida, ['s_idcliente2']: null})
  }

  // console.log(datosSalida.s_idcliente2)

  return (
    <div>
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
      >
        <h1>SALIDA DE VEHICULOS</h1>
      </Grid>
      <form onSubmit={guardarSalida}>
        <Grid
          mb={3}
          container
          spacing={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          alignContent="center"
          wrap="wrap"
        >
          <Grid item xs={3}>
            <TextField
              focused
              fullWidth
              color="success"
              name="s_nroticket"
              label="Ticket Balanza"
              variant="outlined"
              margin="none"
              size={size}
              value={datosSalida.s_nroticket}
              onChange={setearDatosSalida}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              focused
              color="success"
              name="s_fecha"
              fullWidth
              type="date"
              label="Fecha"
              variant="outlined"
              margin="none"
              size={size}
              value={moment(datosSalida.s_fecha).format("YYYY-MM-DD")}
              onChange={setearDatosSalida}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              focused
              name="s_hora"
              fullWidth
              type="time"
              label="Hora"
              variant="outlined"
              color="success"
              margin="none"
              size={size}
              value={datosSalida.s_hora}
              onChange={setearDatosSalida}
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl focused fullWidth required color="success" size={size}>
              <InputLabel>Motivo</InputLabel>
              <Select
                name="s_idmotivo"
                value={datosSalida.s_idmotivo}
                label="Motivo"
                onChange={setearDatosSalida}
              >
                {motivos
                  ? motivos.map((motivo) => (
                      <MenuItem key={motivo.id} value={motivo.id}>
                        {motivo.valor}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              focused
              name="s_pesada"
              fullWidth
              label="Pesada (KG)"
              variant="outlined"
              color="success"
              margin="none"
              size={size}
              value={datosSalida.s_pesada}
              onChange={setearDatosSalida}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl focused fullWidth color="success" required size={size}>
              <InputLabel>Producto</InputLabel>
              <Select
                name="s_idproducto"
                value={datosSalida.s_idproducto}
                label="Producto"
                onChange={setearDatosSalida}
              >
                {productos
                  ? productos.map((producto) => (
                      <MenuItem value={producto.idproducto}>
                        {producto.nombre}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
              <FormControl focused fullWidth color="success" size={size}>
                <InputLabel>Tanque Origen Carga</InputLabel>
                <Select
                  name="s_idtqcarga"
                  onChange={setearDatosSalida}
                  value={datosSalida.s_idtqcarga}
                  label="Tanque Origen Carga"
                >
                  {tanquesFijos
                    ? tanquesFijos.map((tanque) => (
                        <MenuItem key={tanque.idtanque} value={tanque.idtanque}>
                          {tanque.nro_serie}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </Grid>
        </Grid>
        <Grid
          mb={3}
          container
          spacing={1}
          direction="row"
          justifyContent="flex-start"
          // justifyContent="center"
          alignItems="center"
          // alignContent="center"
          // wrap="wrap"
        >
          <>
            <Grid item xs={8}>
              <FormControl focused fullWidth color="success" size={size}>
                <InputLabel>Cliente 1</InputLabel>
                <Select
                  name="s_idcliente1"
                  value={datosSalida.s_idcliente1}
                  label="Cliente 1"
                  onChange={setearDatosSalida}
                >
                  {clientes
                    ? clientes.map((cliente) => (
                        <MenuItem value={cliente.id}  disabled={cliente.id === datosSalida.s_idcliente2}>
                          {cliente.razon_social}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <Button variant="contained" color="primary" onClick={()=>setAñadirCli2(true)} disabled={añadirCli2}>
                Añadir Cliente
              </Button>
            </Grid>         
            {/* <Grid item xs={2}>
              <FormControl fullWidth color="success" size={size}>
                <InputLabel>Tanque Origen Carga</InputLabel>
                <Select
                  name="s_idtqcarga"
                  onChange={setearDatosSalida}
                  value={datosSalida.s_idtqcarga}
                  label="Tanque Origen Carga"
                >
                  {tanquesFijos
                    ? tanquesFijos.map((tanque) => (
                        <MenuItem key={tanque.idtanque} value={tanque.idtanque}>
                          {tanque.nro_serie}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </Grid> */}
          </>
        </Grid>
        <Grid
          // mt={1}
          container
          spacing={1}
          direction="row"
          // justifyContent="center"
          justifyContent="flex-start"

          alignItems="center"
          alignContent="center"
          wrap="wrap"
        >

        {
              añadirCli2 ? (
                <>
                <Grid item xs={8}>
                <FormControl focused fullWidth color="success" size={size}>
                  <InputLabel>Cliente 2</InputLabel>
                  <Select
                    name="s_idcliente2"
                    value={datosSalida.s_idcliente2}
                    label="Cliente 2"
                    onChange={setearDatosSalida}
                  >
                    {clientes
                      ? clientes.map((cliente) => (
                          <MenuItem value={cliente.id} disabled={cliente.id === datosSalida.s_idcliente1}>
                            {cliente.razon_social}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid item xs={2}> */}
              <IconButton color="error" size="large" onClick={()=>eliminarCli2()}>
                <DeleteIcon fontSize="inherit" />
              </IconButton>
              {/* </Grid> */}
            </>

              ):null
            }
        </Grid>

        <Grid
          mt={1}
          container
          spacing={1}
          direction="row"
          // justifyContent="center"
          justifyContent="flex-start"

          alignItems="center"
          alignContent="center"
          wrap="wrap"
        >
          <Grid item xs={2}>
            <FormControlLabel
              checked={transportePropio}
              onChange={handleChangeEmpTrans}
              control={<Checkbox />}
              label="Transporte Propio"
              sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
            />
          </Grid>
          {transportePropio ? (
            <>
              <Grid item xs={3}>
                <FormControl focused fullWidth color="success" size={size}>
                  <InputLabel>Tractor (DE ITALGAS)</InputLabel>
                  <Select
                    name="s_idtractor"
                    label="Tractor (DE ITALGAS)"
                    onChange={setearDatosSalida}
                    value={datosSalida.s_idtractor}
                  >
                    {tractores
                      ? tractores.map((tractor) => (
                          <MenuItem key={tractor.id} value={tractor.id}>
                            {tractor.patente}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl focused fullWidth color="success" size={size}>
                  <InputLabel id="demo-simple-select-label">
                    Chofer (DE ITALGAS)
                  </InputLabel>
                  <Select
                    name="s_idchofer"
                    label="Chofer (DE ITALGAS)"
                    onChange={setearDatosSalida}
                    value={datosSalida.s_idchofer}
                  >
                    {choferes
                      ? choferes.map((chofer) => (
                          <MenuItem key={chofer.id} value={chofer.id}>
                            {chofer.nombre_apellido}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl focused fullWidth color="success" size={size}>
                  <InputLabel>Nivel De Gasoil</InputLabel>
                  <Select
                    name="s_idnivelgasoil"
                    label="Nivel De Gasoil"
                    value={datosSalida.s_idnivelgasoil}
                    onChange={setearDatosSalida}
                  >
                    {niveles
                      ? niveles.map((nivel) => (
                          <MenuItem key={nivel.id} value={nivel.id}>
                            {nivel.valor}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={3}>
                <TextField
                focused
                  name="s_tractor"
                  fullWidth
                  label="Patente Tractor"
                  variant="outlined"
                  color="success"
                  margin="none"
                  size={size}
                  value={datosSalida.s_tractor}
                  onChange={setearDatosSalida}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                focused
                  name="s_chofer"
                  fullWidth
                  label="Chofer"
                  variant="outlined"
                  color="success"
                  margin="none"
                  size={size}
                  value={datosSalida.s_chofer}
                  onChange={setearDatosSalida}
                />
              </Grid>
            </>
          )}
        </Grid>
        <Grid
          mt={1}
          container
          spacing={1}
          direction="row"
          // justifyContent="center"
          justifyContent="flex-start"

          alignItems="center"
          alignContent="center"
          wrap="wrap"
        >
          <Grid item xs={2}>
            <FormControlLabel
              checked={tanquePropio}
              onChange={handleTanquePropio}
              control={<Checkbox />}
              label="Tanque Propio "
              sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
            />
          </Grid>
          {tanquePropio ? (
            <Grid item xs={4}>
              <FormControl focused fullWidth size={size} color="success">
                <InputLabel>Tanque Móvil (DE ITALGAS)</InputLabel>
                <Select
                  name="s_idtanquemovil"
                  label="Tanque Móvil (DE ITALGAS)"
                  value={datosSalida.s_idtanquemovil}
                  onChange={setearDatosSalida}
                >
                  {tanquesMoviles
                    ? tanquesMoviles.map((tanque) => (
                        <MenuItem key={tanque.id} value={tanque.id}>
                          {tanque.patente}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </Grid>
          ) : (
            <Grid item xs={4}>
              <TextField
              focused
                name="s_tanquemovil"
                fullWidth
                label="Patente Tanque Móvil"
                variant="outlined"
                color="success"
                margin="none"
                size={size}
                value={datosSalida.s_tanquemovil}
                onChange={setearDatosSalida}
              />
            </Grid>
          )}
        </Grid>
        <Grid
          mt={1}
          container
          spacing={1}
          direction="row"
          // justifyContent="center"
          justifyContent="flex-start"

          alignItems="center"
          // alignContent="center"
          // wrap="wrap"
        >
          <Grid item xs={10}>
            <TextField
              focused
              name="s_observaciones"
              fullWidth
              multiline
              label="Observaciones"
              variant="outlined"
              color="success"
              margin="none"
              size={size}
              value={datosSalida.s_observaciones}
              onChange={setearDatosSalida}
            />
          </Grid>
        </Grid>

        <Grid
          my={2}
          px={7}
          container
          spacing={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          alignContent="center"
          wrap="wrap"
        >
          <Grid item>
            <Button variant="outlined" color="error" onClick={handleClose}>
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" disabled={!datosSalida.s_idcliente1} onClick={()=>setShowDialogTransito(true)} 
            
            >
              Añadir Transito
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="success" type="submit">
              Guardar
            </Button>
          </Grid>
        </Grid>
      </form>
      {
        showDialogTransito ? 
        <DialogoTransitoSalida 
        datos={datosSalida} 
        open={true}
        setShowDialogTransito={setShowDialogTransito}
        /> : null
      }
    </div>
  );
}

export default AddRegistroSalida;
