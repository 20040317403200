import React, { useEffect, useState, useMemo } from "react";
import Navbar from "../../Navbar";
import { UrlApi } from "../../../services/apirest";
import {
  Container,
  Typography,
  ListItemIcon,
  MenuItem,
  Grid,
  Button,
} from "@mui/material";
import Box from "@mui/material/Box";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Delete } from "@mui/icons-material";
import PropaneIcon from "@mui/icons-material/Propane";
import CircularProgress from "@mui/material/CircularProgress";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";

//Componentes NETIZAR
import DatosTanques from "./DatosTanques";
import Editartanque from "./Editartanque";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import BajaTanque from "./BajaTanque";
import Addtanque from "./Addtanque";

function Tanques() {
  const [tanques, setTanques] = useState("");
  const [alta, setAlta] = useState(null);
  const [tabla, setTabla] = useState(true);
  const [editar, setEditar] = useState(false);
  const [ficha, setFicha] = useState(false);
  const [baja, setBaja] = useState(false);

  const [busqueda, setBusqueda] = useState("");
  const [tanque, setTanque] = useState({});

  const obtenerTanques = () => {
    fetch(UrlApi + "/tanques/nueva")
      .then((data) => data.json())
      .then((data) => setTanques(data));
  };

  useEffect(() => {
    obtenerTanques();
  }, [tabla]);

  //funcion q se ejecuta cuando hago click para añadir un tanque
  const handleClickNuevo = () => {
    setTabla(false);
    setAlta(true);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "nro_serie", //access nested data with dot notation
        header: "N° Serie",
        size: 10,
      },
      {
        accessorKey: "capacidad", //access nested data with dot notation
        header: "Capacidad (m3)",
        size: 100,
      },
      {
        accessorKey: "sucursal", //access nested data with dot notation
        header: "Sucursal",
        size: 300,
      },
      {
        accessorKey: "estado_tanque", //access nested data with dot notation
        header: "Estado",
        size: 30,
      },
      {
        accessorKey: "cliente", //access nested data with dot notation
        header: "Cliente",
        size: 40,
      },
    ],
    []
  );

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "primary",
    size: "small",
  };

  const initialState = {
    density: "compact",
    showGlobalFilter: true,
    pagination: {
      pageSize: 15,
      pageIndex: 0,
    },
  };

  const tableHeadCellProps = {
    align: "center",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      pt: 1,
      borderColor: "black",
    },
  };

  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //larger tooltip font size
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const rowBg = {
    2: "#c2f0ce", // just for example, remove it if you don't need
    6: "#FCF3CF ",
  };

  return (
    <div>
      <Navbar />
      {tabla && tanques ? (
        <>
          <Container maxWidth="xl">
            <Box my={2} mb={5}>
              <Box mt={2} boxShadow={3}>
                <ThemeProvider theme={tableTheme}>
                  <MaterialReactTable
                    enablePagination={true}
                    columns={columns}
                    data={tanques}
                    enableRowActions
                    initialState={initialState}
                    positionToolbarAlertBanner="bottom"
                    positionActionsColumn="last"
                    enableHiding={false}
                    muiTableHeadCellProps={tableHeadCellProps}
                    localization={MRT_Localization_ES}
                    defaultColumn={{
                      size: 5,
                    }}
                    muiTableBodyCellProps={({ row }) => ({
                      align: "center",
                      sx: {
                        backgroundColor:
                          rowBg[row.original.id_estado] ?? "#fff",
                        color: "black",
                        borderColor: "black",
                      },
                    })}
                    enableExpandAll={false}
                    enableColumnActions={false}
                    muiSearchTextFieldProps={muiSearchTextFieldProps}
                    enableColumnFilters={false} //Quita filtros por columna
                    enableDensityToggle={false}
                    renderTopToolbarCustomActions={() => (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<AddBoxTwoToneIcon color="asd" />}
                          onClick={() => {
                            setTabla(false);
                            setAlta(true);
                          }}
                        >
                          Nuevo Tanque
                        </Button>
                        <Box>
                          <Typography variant="poster" fontSize={26}>
                            <PropaneIcon sx={{ fontSize: 20 }} />
                            Tanques
                          </Typography>
                        </Box>
                      </>
                    )}
                    // renderToolbarInternalActions={({ table }) => (
                    //   <>
                    //     <Tooltip title="Pendientes" color="success">
                    //     <IconButton onClick={() => obtenerPendientes()}>
                    //       <PendingActionsIcon />
                    //     </IconButton>
                    //   </Tooltip>
                    //     <MRT_FullScreenToggleButton table={table} />
                    //   </>
                    // )}
                    renderRowActionMenuItems={({ closeMenu, row }) => [
                      <MenuItem
                        key={0}
                        onClick={() => {
                          closeMenu();
                          setTabla(false);
                          setFicha(true);
                          setTanque(row.original);
                        }}
                        sx={{ m: 0 }}
                      >
                        <ListItemIcon>
                          <RemoveRedEyeIcon color="primary" />
                        </ListItemIcon>
                        Ver más información
                      </MenuItem>,

                      <MenuItem
                        key={1}
                        onClick={() => {
                          closeMenu();
                          setTabla(false);
                          setTanque(row.original);
                          setEditar(true);
                        }}
                        sx={{ m: 0 }}
                      >
                        <ListItemIcon>
                          <BorderColorIcon color="primary" />
                        </ListItemIcon>
                        Editar
                      </MenuItem>,

                      <MenuItem
                        disabled={row.original.id_estado === 6}
                        key={2}
                        onClick={() => {
                          closeMenu();
                          setBaja(true);
                          setTanque(row.original);
                        }}
                        sx={{ m: 0 }}
                      >
                        <ListItemIcon>
                          <Delete color="error" />
                        </ListItemIcon>
                        Dar De Baja
                      </MenuItem>,
                    ]}
                  />
                </ThemeProvider>
              </Box>
            </Box>
          </Container>
        </>
      ) : null}
      {!tanques ? (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          p={3}
        >
          <Grid item xs>
            <Typography m={6} fontSize={30} variant="h2" color="initial">
              Cargando información de tanques...
            </Typography>
          </Grid>
          <Grid item>
            <CircularProgress size="7rem" color="primary" />
          </Grid>
        </Grid>
      ) : null}

      {ficha ? (
        <DatosTanques datos={tanque} tabla={setTabla} ficha={setFicha} />
      ) : null}
      {editar ? (
        <Editartanque
          datos={tanque}
          editar={setEditar}
          tabla={setTabla}
          actualizr={obtenerTanques}
        />
      ) : null}
      {baja ? (
        <BajaTanque
          open={baja}
          setOpen={setBaja}
          datos={tanque}
          actualizar={obtenerTanques}
        />
      ) : null}
      {alta ? <Addtanque tabla={setTabla} alta={setAlta} /> : null}
    </div>
  );
}

export default Tanques;
