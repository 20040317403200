import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { DialogTitle, TextField, FormControl, MenuItem } from "@mui/material";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import moment from "moment";
import Button from "@mui/material/Button";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import Grid from "@mui/material/Grid";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { UrlApi } from "../../../../../services/apirest";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { v4 as uuidv4 } from "uuid"; ///me da un unico id para poner en los pedidos y repartos
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Alerta from "../../../../Alerta";

function DialogRepartos(props) {
  const [repartos, setRepartos] = useState([]);
  const [repartoRepetido, setRepartoRepetido] = useState(null);

  const [choferes, setChoferes] = useState([{}]);
  const [vehicles, setVehicles] = useState([{}]);
  const [uuid, setUuid] = useState("");

  const [disTxtReparto, setDisTxtReparto] = useState(true);

  //EL REPARTO QUE SE VA A  UTILIZAR PARA ASIGNAR EL PEDIDO, NUNCA PUEDE IR EN EL MISMO ESTADO QUE UNO ELEGIDO Y YA EXISTENTE,
  // PORQE ES NECESARIO Q DE ALGUNA MANERA
  const [dataDelivery, setDataDelivery] = useState({});
  const [disChBox, setDisChBox]=useState(true);
  const [isNewDelivery, setIsNewDelivery] = useState(false);

  const getRepartos = () => {
    fetch(UrlApi + "/repartos/sucursal/" + props.direccion.id_sucursal)
      .then((data) => data.json())
      .then((data) => setRepartos(data));
    // console.log(UrlApi + "/repartos/sucursal/" + props.direccion.id_sucursal);
  };

  console.log("repatos", repartos)

  const getRepartosFechaVehiculo = () => {
    fetch(
      UrlApi +
        "/repartos/fechaveh/" +
        dataDelivery.fecha_salida_reparto +
        "/" +
        dataDelivery.id_vehiculo
    )
      .then((data) => data.json())
      .then((data) => setRepartoRepetido(data));
  };

  useEffect(() => {
    isNewDelivery &&
      dataDelivery.fecha_salida_reparto &&
      dataDelivery.id_vehiculo &&
      getRepartosFechaVehiculo();
  }, [
    isNewDelivery,
    dataDelivery.fecha_salida_reparto,
    dataDelivery.id_vehiculo,
  ]);

  const getVehicle = () => {
    fetch(UrlApi + "/vehiculos/sucursal/" + props.direccion.id_sucursal)
      .then((data) => data.json())
      .then((data) => setVehicles(data));
  };

  const getAllVehicles = () => {
    fetch(UrlApi + "/vehiculos/granel")
      .then((data) => data.json())
      .then((data) => setVehicles(data));
  };

  const getChoferes = () => {
    fetch(UrlApi + "/choferes/granel")
      .then((data) => data.json())
      .then((data) => setChoferes(data));
  };

  //obtengo los vehiculos una vez que tenga la sucursal
  useEffect(() => {
    getVehicle();
    getRepartos();
  }, [props.direccion.id_sucursal]);

  useEffect(() => {
    getChoferes();
    setUuid(uuidv4());
  }, []);

  const nuevoReparto = () => {
    setDataDelivery({
      id_reparto: "",
      fecha_salida_reparto: "",
      id_chofer: "",
      id_vehiculo: "",
      nro_reparto: uuid,
    });
    setDisTxtReparto(false);
    setIsNewDelivery(true);
    setRepartoRepetido(null);
    setDisChBox(false)
  };

  const store = (e) => {
    setDataDelivery({ ...dataDelivery, [e.target.name]: e.target.value });
  };

  const asignarPedido = (reparto) => {
    setDisTxtReparto(true);
    setIsNewDelivery(false);
    setRepartoRepetido(null);
    setDataDelivery({
      id_reparto: reparto.id_reparto,
      fecha_salida_reparto: moment(reparto.fecha_salida_reparto).format(
        "YYYY-MM-DD"
      ),
      id_vehiculo: reparto.id_vehiculo,
      nro_reparto: reparto.nro_reparto,
      telefono_choferinicia:reparto.telefono_choferinicia,
      id_estadoreparto:reparto.id_estadoreparto
    });
  };


  //SEGUIR POR ACÁ
  const save = () => {
    // console.log("Voy a guardar");}
    props.setIsNewDelivery(isNewDelivery);
    props.setDataDelivery(dataDelivery);
    props.setOpen(false);
  };

  const cancel = () => {
    props.setOpen(false);
    props.setIsNewDelivery(false);
  };

  const [deSucursal, setDeSucursal] = useState();

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => props.setOpen(false)}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle backgroundColor="#1F618D" color="#fff">
          Asignar A Rendición
        </DialogTitle>
        {props.kgCarga * 1 > 20000 ? (
          <Alert severity="error">
            La capacidad máxima de un vehículo es de 20.000 kg, no puede
            asignarse un pedido mayor a esa capacidad.
          </Alert>
        ) : null}
        <DialogContent>
          <Grid container direction="row" my={2}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              width="20%"
            >
              <Button
                onClick={() => nuevoReparto()}
                variant="contained"
                endIcon={<AddCircleOutlineIcon />}
              >
                NUEVA RENDICIÓN
              </Button>{" "}
            </Grid>

            {!dataDelivery.id_reparto ? (
              <>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                  width="80%"
                  spacing={2}
                >
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      name="fecha_salida_reparto"
                      label="Fecha Salida"
                      focused
                      type="date"
                      disabled={disTxtReparto}
                      fullWidth
                      variant="outlined"
                      color="primary"
                      margin="none"
                      placeholder=""
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={
                        dataDelivery &&
                        moment(dataDelivery.fecha_salida_reparto).format(
                          "YYYY-MM-DD"
                        )
                      }
                      onChange={store}
                      inputProps={{
                        min: moment(new Date()).format("YYYY-MM-DD"),
                      }}
                    />
                  </Grid>
                  {/* <Grid item xs={0.5}></Grid> */}
                  
                  <Grid item xs={5}>
                    <FormControl
                      focused
                      required
                      fullWidth
                      disabled={disTxtReparto}
                      size="small"
                    >
                      <InputLabel>Vehículo</InputLabel>
                      <Select
                        name="id_vehiculo"
                        value={dataDelivery && dataDelivery.id_vehiculo}
                        label="Vehículo"
                        onChange={store}
                      >
                        {vehicles
                          ? vehicles.map((vehiculo, index) => (
                              <MenuItem key={index} value={vehiculo.id}>
                                {vehiculo.patente}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      disabled={disChBox}
                      checked={deSucursal}
                      onChange={(e) => {setDeSucursal(e.target.checked)
                        setDataDelivery({...dataDelivery, "id_vehiculo":null})
                        e.target.checked ? getAllVehicles() : getVehicle()
                      }}
                      control={<Checkbox />}
                      label="Otra Sucursal"
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  {repartoRepetido && repartoRepetido.length > 0 ? (
                    <Grid item xs={12} marginTop={1}>
                      <Alert severity="warning">
                        Este vehículo ya tiene asignada una rendición para la
                        fecha{" "}
                        {moment(dataDelivery.fecha_salida_reparto).format(
                          "DD-MM-YYYY"
                        )}{" "}
                        !
                      </Alert>
                    </Grid>
                  ) : null}
                </Grid>
              </>
            ) : null}
          </Grid>

          {repartos && repartos.length > 0 ? (
            <>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
              >
                {repartos.length > 0 &&
                  repartos.map((reparto) => (
                    <>
                      <Grid item xs={3.5}>
                        <Card sx={{ maxWidth: 270, maxHeight: 280 }}>
                          <CardContent
                            style={{
                              backgroundColor:
                                dataDelivery &&
                                (reparto.nro_reparto ===
                                dataDelivery.nro_reparto
                                  ? "#D5F5E3"
                                  : "#ffffff"),
                            }}
                          >
                            <Typography variant="h5" component="div">
                              {moment(reparto.fecha_salida_reparto).format(
                                "DD-MM-YYYY"
                              )}{" "}
                              ({reparto.id_reparto})
                            </Typography>
                            <hr></hr>
                            <Typography variant="body3" color="text.secondary">
                              <b>Sucursal</b>: {reparto.sucursal}
                              <br></br>
                              <b>Vehiculo</b>: {reparto.patente}
                              <br></br>
                              <b>Kg Capacidad</b>:{reparto.capacidad}
                              {reparto.chofer ? (
                                <>
                                  <br></br>
                                  <b>Kg Capacidad</b>:{reparto.chofer}
                                </>
                              ) : null}
                              <br></br>
                              <b>Estado</b>: {reparto.estado_reparto}
                              <br></br>
                              <b>Kg Cargados</b>:{" "}
                              {reparto.kgxreparto * 1 > 0
                                ? reparto.kgxreparto
                                : "Sin Pedidos"}
                              <br></br>
                              <b>Kg DISPONIBLES</b>:{" "}
                              {reparto.capacidad * 1 - reparto.kgxreparto * 1}
                            </Typography>
                            {props.kgCarga >
                            reparto.capacidad * 1 - reparto.kgxreparto * 1 ? (
                              <>
                                <hr></hr>
                                <Typography
                                  gutterBottom
                                  sx={{ fontSize: 12, color: "red", mb: -1 }}
                                  component="div"
                                >
                                  ATENCIÓN: La carga ingresada supera la
                                  capacidad disponible.
                                </Typography>
                              </>
                            ) : null}
                          </CardContent>
                          {props.kgCarga <
                          reparto.capacidad * 1 - reparto.kgxreparto * 1 ? (
                            <CardActions
                              style={{
                                backgroundColor:
                                  dataDelivery &&
                                  (reparto.nro_reparto ===
                                  dataDelivery.nro_reparto
                                    ? "#D5F5E3"
                                    : "#ffffff"),
                              }}
                            >
                              <Button
                                style={{ backgroundColor: "#ffffff" }}
                                endIcon={<EventAvailableIcon />}
                                size="small"
                                color="primary"
                                variant="outlined"
                                onClick={() => asignarPedido(reparto)}
                                disabled={
                                  props.kgCarga >
                                  reparto.capacidad * 1 - reparto.kgxreparto * 1
                                }
                              >
                                asignar
                              </Button>
                            </CardActions>
                          ) : null}
                        </Card>
                      </Grid>
                    </>
                  ))}
              </Grid>
            </>
          ) : null}
        </DialogContent>
        <Box>
          <Grid
            px={3}
            my={3}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button variant="contained" color="error" onClick={() => cancel()}>
              Volver
            </Button>
            <Button
              onClick={() => save()}
              disabled={
                !dataDelivery.fecha_salida_reparto ||
                !dataDelivery.id_vehiculo ||
                dataDelivery.fecha_salida_reparto <
                  moment(new Date()).format("YYYY-MM-DD") || //Si la fecha de salida del repartoes menor a hoy
                (repartoRepetido && repartoRepetido.length > 0)
              }
              variant="contained"
              color="success"
            >
              Guardar En Rendición
            </Button>
          </Grid>
        </Box>
      </Dialog>
    </div>
  );
}

export default DialogRepartos;
