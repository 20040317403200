//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio
// Creation Date : 06/09/2024
// Version : 1
// Description : agregar tarea desde menu
//------Changes -------------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Container,
  Grid,
  Box,
  TextField,
  Button,
  Dialog,
  DialogContent,
  Stack,
  Autocomplete,
  IconButton,
  Tooltip,
  FormHelperText,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import Notify from "../Notify";
import Alerta from "../Alerta";
import Title from "../Title";
import { logEventos } from "../../services/services";
import moment from "moment";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { enviarmails } from "../../services/services";
import { postData } from "../../services/library";
import { v4 as uuidv4 } from "uuid"; //ADJUNTAR IMAGEN
import { List, ListItem, ListItemText } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";

function Agregar_tarea(props) {
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
  const [mensajeAlerta, setMensajeAlerta] = useState(""); //mensaje que contendrá el componente "alerta"
  const [finalizar, setFinalizar] = useState(); //finalizar === 1 lo manda como prop el componente "notify" e indica que la transaccion ha finalizado y debe ejecutarse la funcion para cerrar
  const [severityAlerta, setSeverityAlerta] = useState(""); //define severidad de la alerta (success error o warning)
  const [severitySnack, setSeveritySnack] = useState(""); //define severidad del snackbar (notificación)
  const [datosTanque, setDatosTanque] = useState(props.datos); // viene del motivos_salida {editar}
  const [tarea, setTarea] = useState(props.tarea);
  const [estadoTarea, setEstadoTarea] = useState(1); // Inicialmente en 'Finalizada'
  const [uuid2, setUuid2] = useState(uuidv4());
  const [repuestos, setRepuestos] = useState([]);

  const [datosTarea, setDatosTarea] = useState({
    id_tanque: props.datos.id_tanque || null,
    id_tarea: null,
    tarea_desde: "",
    tarea_hasta: "",
    motivo_tarea: "",
    detalle_tarea: "",
    lugar_tarea: "",
    encargado: "",
    proviene: 0,
    uuid_tarea: uuid2,
    usuario: localStorage.getItem("user"),
  });

  const [datosRepuestos, setDatosRepuestos] = useState([
    {
      id_repuesto: null,
      uuid_repuesto: uuid2,
      id_repuesto_usado: "",
      cantidad: "",
      almacen: "",
      usuario: localStorage.getItem("user"),
    },
  ]);

  /*------------------- OBTENER SUCURSAL EN LA QUE ESTOY ---------------------------*/
  const [sucursales, setSucursales] = useState([]);

  //funcion para obtener sucursal del usuario logueado
  const obtenerSucursales = () => {
    fetch(UrlApi + "/stock_sucursales/" + localStorage.getItem("sucursal"))
      .then((data) => data.json())
      .then((data) => setSucursales(data));
  };

  useEffect(() => {
    obtenerSucursales();
  }, []);

  /*------------------ DATOS DE ARTICULOS QUE HAY EN LA SUCURSAL ---------------------- */
  const [artSuc, setArtSuc] = useState([]);
  //funcion para obtener los repuestos x sucursal
  const obtenerArticulosSuc = () => {
    fetch(
      UrlApi +
        "/stock_inventarios/xsucursal/" +
        localStorage.getItem("sucursal")
    )
      .then((data) => data.json())
      .then((data) => setArtSuc(data));
  };

  useEffect(() => {
    obtenerArticulosSuc();
  }, []);
  //console.log("art suc", artSuc);

  /*---------------------------ARTICULOS PARA AUTOCOMPLETE--------------------------------------------*/
  const [articulo, setArticulo] = useState([]);
  const [articuloSeleccionado, setArticuloSeleccionado] = useState(null);
  //funcion para obtener los repuestos x sucursal
  const obtenerArticulos = () => {
    fetch(
      UrlApi +
        "/stock_articulos/articulosXsucursal/" +
        localStorage.getItem("sucursal")
    )
      .then((data) => data.json())
      .then((data) => setArticulo(data));
  };
 // console.log("articulos", articulo);

  useEffect(() => {
    obtenerArticulos();
  }, []);

  // LIMPIAR AUTOCOMPLETE
  useEffect(() => {
    setAlmacenSeleccionado("");
  }, [articuloSeleccionado]);

  /*---------------------------- ALMACENES DE LA SUCURSAL ----------------*/
  const [almacen, setAlmacen] = useState([]);
  const [almacenSeleccionado, setAlmacenSeleccionado] = useState(null);
  //console.log("almacen", almacen);
  //-----------funcion para obtener almacenes de la sucursal
  const obtenerAlmacenesSuc = () => {
    fetch(
      UrlApi + "/stock_almacenes/xsucursal/" + localStorage.getItem("sucursal")
    )
      .then((data) => data.json())
      .then((data) => setAlmacen(data));
  };

  useEffect(() => {
    obtenerAlmacenesSuc();
  }, []);

  /*-------------------------------- INVENTARIO ------------------------------- */
  const [inventario, setInventario] = useState([]);
 // console.log("INVENTARIO", inventario);
  //-----------funcion para obtener almacenes de la sucursal
  const obtenerInventario = () => {
    fetch(
      UrlApi +
        "/stock_inventarios/xalmart/" +
        almacenSeleccionado.id_almacen +
        "/" +
        articuloSeleccionado.id_articulo
    )
      .then((data) => data.json())
      .then((data) => setInventario(data));
  };

  useEffect(() => {
    if (almacenSeleccionado && articuloSeleccionado) {
      obtenerInventario();
    }
  }, [almacenSeleccionado, articuloSeleccionado]);

  /*---------------------------- ALMACEN SELECCIONADO CON SUS ARTICULOS / ES EL QUE TOMA TODO DE REFERENCIA -----------------------*/
  const [almacenConArt, setAlmacenConArt] = useState([]);

  //funcion para obtener almacenes de la sucursal
  const obtenerAlmacenesSucConArt = () => {
    fetch(
      UrlApi +
        "/stock_almacenes/xsucursal2/" +
        localStorage.getItem("sucursal") +
        "/" +
        articuloSeleccionado.id_articulo
    )
      .then((data) => data.json())
      .then((data) => setAlmacenConArt(data));
  };

  useEffect(() => {
    if (articuloSeleccionado && articuloSeleccionado.id_articulo) {
      obtenerAlmacenesSucConArt();
    }
  }, [articuloSeleccionado]);
  //console.log("almacen con art", almacenConArt);

  /*-------------------- VERIFICA EXISTENCIA DE ART EN EL ALMACEN ------------ */
  const [existe, setExiste] = useState([]);

  // Función para verificar si existe el artículo en el almacén
  const obtenerExiste = () => {
    if (articuloSeleccionado && almacenSeleccionado) {
      // Verifica que ambos estén definidos
      fetch(
        UrlApi +
          "/stock_inventarios/verificaExiste/" +
          articuloSeleccionado.id_articulo +
          "/" +
          almacenSeleccionado.id_almacen
      )
        .then((data) => data.json())
        .then((data) => setExiste(data));
    }
  };

  useEffect(() => {
    obtenerExiste();
  }, [articuloSeleccionado, almacenSeleccionado]);

 // console.log("existe art en el almacen", existe);

  /*-------------------- obtener las personas a cargo (proveedores)-------------*/
  const [proveedor, setProveedor] = useState([]);
  const [proveedorSeleccionado, setProveedorSeleccionado] = useState("");
  //-----------funcion para obtener
  const obtenerProveedores = () => {
    fetch(UrlApi + "/tanque_proveedor/activos")
      .then((data) => data.json())
      .then((data) => setProveedor(data));
  };

  useEffect(() => {
    obtenerProveedores();
  }, []);
  /*------------------ lo de siempre -----------------------------*/
  const handleClose = () => {
    setTarea(!tarea);
    if (props.onClose) {
      props.onClose();
    }
  };

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };
  const size = "small";

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "#1890ff",
          ...theme.applyStyles("dark", {
            backgroundColor: "#177ddc",
          }),
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: "rgba(0,0,0,.25)",
      boxSizing: "border-box",
      ...theme.applyStyles("dark", {
        backgroundColor: "rgba(255,255,255,.35)",
      }),
    },
  }));

  useEffect(() => {
    // Inicializar `datosTanque` y asignar solo `id_tanque` en `datosTarea`
    setDatosTanque({
      ...props.datos,
    });
    setDatosTarea((prev) => ({
      ...prev,
      id_tanque: props.datos.id_tanque || null,
    }));
  }, [props.datos]);

  const almacenarTarea = (e) => {
    const { name, value } = e.target;
    setDatosTarea((prevDatosTarea) => ({
      ...prevDatosTarea,
      [name]: value,
    }));
  };

  const almacenarRepuesto = (e) => {
    const { name, value } = e.target;
    setDatosRepuestos((prevRepuestos) => ({
      ...prevRepuestos,
      [name]: value,
    }));
  };

  const almacenarConsumo = (e) => {
    const { name, value } = e.target;
    setConsumo((prevConsumo) => ({
      ...prevConsumo,
      [name]: value,
    }));
  };
  /*--------------------------------------------PARTE DE BAJA DE STOCK /MAIL /REQUERIMIENTO----------------------*/
  //-------------- ver si existen requerimientos-------------------------//
  const [reqExiste, setReqExiste] = useState({});

  const obtenerRequerimientoExistente = async () => {
    try {
      const reqExistentes = {};

      // Usamos `Promise.all` para hacer las llamadas de manera paralela y optimizar el tiempo de respuesta
      await Promise.all(
        repuestos.map(async (repuesto) => {
          if (repuesto.articulo.id_articulo) {
            const response = await fetch(
              `${UrlApi}/requerimientos_materiales/reqexiste/${localStorage.getItem(
                "sucursal"
              )}/${repuesto.articulo.id_articulo}`
            );
            const data = await response.json();

            // Almacenar el resultado en `reqExistentes` para cada id_articulo
            reqExistentes[repuesto.articulo.id_articulo] = data.length > 0;
          }
        })
      );

      setReqExiste(reqExistentes); // Actualizar el estado con los requerimientos existentes
    } catch (error) {
      console.error("Error al verificar el requerimiento existente:", error);
    }
  };

  useEffect(() => {
    obtenerRequerimientoExistente();
  }, [repuestos]);

 // console.log("EXISTE REQUERIMIENTO", reqExiste);

  //------------------- RESTA DE CANT DE ART CON LA USADA ------------------//
  //------------- funciones guardar tarea y repuesto , bloqueo del boton-----------------------//

  const guardarTarea = async (e) => {
    logEventos(
      "Guardar nueva tarea",
      "Tanques Moviles",
      "Se guardo nueva tarea",
      localStorage.getItem("user")
    );
    setDisabledGuardar(true);
    e.preventDefault();
    const response = await fetch(UrlApi + "/tanque_tarea", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_tanque: datosTarea.id_tanque,
        tarea_desde: datosTarea.tarea_desde,
        tarea_hasta: datosTarea.tarea_hasta,
        motivo_tarea: datosTarea.motivo_tarea,
        detalle_tarea: datosTarea.detalle_tarea,
        lugar_tarea: datosTarea.lugar_tarea,
        encargado: proveedorSeleccionado.id,
        finalizada: estadoTarea,
        proviene: 0,
        uuid_tarea: uuid2,
        usuario: localStorage.getItem("user"),
      }),
    });
    if (response.ok === false) {
      console.log("ERROR EN API");
      const error = response && response.json();
      setError(error);
      setSeverityAlerta("error");
      setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      console.log("Conexión con api OK");
      try {
        //Parseo respuesta de la api a json()
        const json = await response.json();
        //si api me devuelve lo q le puse en message
        if (json.message) {
          //Si ese mensaje es "OK"
          if (json.message === "OK") {
            console.log("Guardado Satisfactoriamente");
            setNotificacion(true);
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setMensaje("Guardado");
            setSeveritySnack("success");
          } else {
            console.log("res en api incorrecto. Revisar que sea 'OK'");
            setNotificacion(true);
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setSeveritySnack("error");
            setMensaje("Registro Guardado. REVISAR RESPUESTA DE API (E_S)");
          }
        } else if (json.sqlMessage) {
          json && setError(json);
          console.log(json);
          setMensajeAlerta(
            "SQL erróneo (" + json.code + " Errno: " + json.errno + " )"
          );
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          console.log(
            "Guardado. Advertencia: Actualizar respuesta de api. JSON invalido."
          );
          setNotificacion(true);
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("Guardado");
          setSeveritySnack("success");
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      }
    }
    handleClose();
  };

  const guardarRepuesto = async (e) => {
    e.preventDefault();
    setDisabledGuardar(true);

    try {
      // Iterar sobre el array de repuestos y hacer un POST por cada uno
      const respuestas = await Promise.all(
        repuestos.map(async (repuesto) => {
          const response = await fetch(UrlApi + "/tanque_repuestos", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              id_repuesto: repuesto.id_repuesto,
              uuid_repuesto: uuid2,
              id_repuesto_usado: repuesto.articulo.id_articulo,
              cantidad: repuesto.cantidad,
              almacen: repuesto.almacen.id_almacen,
              usuario: localStorage.getItem("user"),
            }),
          });
          if (!response.ok) {
            throw new Error("Error en la API al guardar el repuesto");
          }
          return response.json();
        })
      );

      // Manejo de respuestas exitosas
      console.log("Guardado satisfactoriamente:", respuestas);
      setNotificacion(true);
      setOpenSnack(true);
      setColorMensaje("#D4EFDF");
      setMensaje("Guardado");
      setSeveritySnack("success");
    } catch (error) {
      console.log("Error al guardar repuestos:", error);
      setError(error);
      setSeverityAlerta("error");
      setMensajeAlerta("Inconvenientes con la API. No se pudo guardar.");
    } finally {
      handleClose();
      setDisabledGuardar(false);
    }
  };

  const [disabledGuardar, setDisabledGuardar] = useState(false);

  useEffect(() => {
    // Verifica las condiciones para habilitar o deshabilitar el botón
    const condicionesCumplidas =
      datosTarea.tarea_desde && // Verifica que haya una fecha seleccionada
      datosTarea.tarea_hasta && // Verifica que haya una fecha seleccionada
      datosTarea.lugar_tarea && // Verifica que el lugar de tarea no sea nulo
      proveedorSeleccionado && // Verifica que haya un proveedor seleccionado
      datosTarea.motivo_tarea && // Verifica que el motivo de tarea no sea nulo
      datosTarea.detalle_tarea &&
      repuestos.length > 0; // Verifica que haya al menos un repuesto agregado

    setDisabledGuardar(!condicionesCumplidas); // Actualiza el estado según las condiciones
  }, [datosTarea, proveedorSeleccionado, repuestos]); // Dependencias del useEffect

  //---------------LINEAS ------------------- //
  const [mensajeAdvertencia, setMensajeAdvertencia] = useState("");
  const [cantidad, setCantidad] = useState(""); // Estado para almacenar la cantidad
  const [desactivarAgregar, setDesactivarAgregar] = useState(false);

  const agregarRepuesto = () => {
    if (
      !articuloSeleccionado ||
      !almacenSeleccionado ||
      !datosRepuestos.cantidad
    ) {
      setMensajeAlerta("Por favor completa todos los campos.");
      setSeverityAlerta("warning");
      return;
    }

    const stockDisponible = obtenerCantidad(); // Obtener la cantidad en stock para el artículo y almacén seleccionados
    if (datosRepuestos.cantidad > stockDisponible) {
      setMensajeAdvertencia(
        "La cantidad usada no puede ser mayor que el stock disponible."
      );
      return;
    }

    // Calcular la cantidad de salida para este repuesto
    const cantidadSalida = stockDisponible - datosRepuestos.cantidad;

    const nuevoRepuesto = {
      articulo: articuloSeleccionado,
      almacen: almacenSeleccionado,
      cantidad: datosRepuestos.cantidad,
      cantidadSalida: cantidadSalida, // Asignar la cantidad de salida calculada para este repuesto
      inventarioID: inventarioID,
    };

    // Verificar si ya se agregó el mismo repuesto con el mismo almacén
    const existeRepuesto = repuestos.some(
      (repuesto) =>
        repuesto.articulo.id_articulo === nuevoRepuesto.articulo.id_articulo &&
        repuesto.almacen.id_almacen === nuevoRepuesto.almacen.id_almacen
    );

    if (existeRepuesto) {
      setMensajeAdvertencia("Este repuesto ya ha sido agregado.");
      return;
    } else {
      setMensajeAdvertencia("");
    }

    // Agregar el nuevo repuesto al estado, sin modificar los anteriores
    setRepuestos((prevRepuestos) => [...prevRepuestos, nuevoRepuesto]);

    // Limpiar la selección de artículo, almacén y cantidad
    setArticuloSeleccionado(null);
    setAlmacenSeleccionado(null);
    setDatosRepuestos((prev) => ({
      ...prev,
      cantidad: "",
    }));

    setMensajeAlerta("Repuesto agregado correctamente.");
    setSeverityAlerta("success");
  };

  const handleArticuloChange = (event, value) => {
    setArticuloSeleccionado(value);
    setMensajeAdvertencia(""); // Limpia el mensaje de advertencia al cambiar el artículo
  };

  const handleAlmacenChange = (event, value) => {
    setAlmacenSeleccionado(value);
    setMensajeAdvertencia(""); // Limpia el mensaje de advertencia al cambiar el almacén
  };

  // Función para eliminar un repuesto
  const eliminarRepuesto = (index) => {
    setRepuestos((prevRepuestos) =>
      prevRepuestos.filter((_, i) => i !== index)
    );
  };

  const obtenerCantidad = () => {
    if (articuloSeleccionado && almacenSeleccionado) {
      const encontrado = artSuc.find(
        (alm) =>
          alm.id_almacen === almacenSeleccionado.id_almacen &&
          alm.id_articulo === articuloSeleccionado.id_articulo
      );
      return encontrado ? encontrado.cantidad : "";
    }
    return "";
  };

  useEffect(() => {
    // Actualizar la cantidad cada vez que se cambia el artículo o el almacén
    const nuevaCantidad = obtenerCantidad();
    setCantidad(nuevaCantidad);

    // Desactivar el botón si la cantidad usada es mayor que el stock
    if (datosRepuestos.cantidad > nuevaCantidad) {
      setDesactivarAgregar(true);
    } else {
      setDesactivarAgregar(false);
    }
   // console.log("NUEVA CANTIDAD", nuevaCantidad);
  }, [articuloSeleccionado, almacenSeleccionado, datosRepuestos.cantidad]);

  useEffect(() => {
    // Asegurarse de que hay un artículo y almacén seleccionados
    if (articuloSeleccionado && almacenSeleccionado) {
      // Buscar en almacenConArt la cantidad disponible para el artículo y almacén seleccionados
      const almacenEncontrado = artSuc.find(
        (alm) =>
          alm.id_almacen === almacenSeleccionado.id_almacen &&
          alm.id_articulo === articuloSeleccionado.id_articulo
      );

      const cantidadUsada = Number(datosRepuestos.cantidad) || 0;

      // Si el almacén y artículo existen en artsuc, calcular la cantidad de salida
      if (almacenEncontrado && almacenEncontrado.cantidad !== undefined) {
        const nuevaCantidadSalida = almacenEncontrado.cantidad - cantidadUsada;

        // Si la cantidad usada es mayor que la cantidad disponible, mostrar advertencia
        if (nuevaCantidadSalida < 0) {
          setMensajeAdvertencia(
            "La cantidad usada es mayor que el stock disponible."
          );
          setDesactivarAgregar(true); // Desactivar el botón si la cantidad es mayor
        } else {
          setMensajeAdvertencia(""); // Limpiar advertencia si no es el caso
          setDesactivarAgregar(false); // Habilitar el botón si todo es correcto
        }

        // Actualizar el estado con la cantidad de salida calculada
        setCantidadSalida(nuevaCantidadSalida);
      } else {
        setCantidadSalida(null); // Si no hay coincidencia, se establece como null
      }
    }
  }, [
    artSuc,
    articuloSeleccionado,
    almacenSeleccionado,
    datosRepuestos.cantidad,
  ]);

  const obtenerID = () => {
    if (articuloSeleccionado && almacenSeleccionado) {
      const encontrado = artSuc.find(
        (alm) =>
          alm.id_almacen === almacenSeleccionado.id_almacen &&
          alm.id_articulo === articuloSeleccionado.id_articulo
      );
      return encontrado ? encontrado.id_inventario : "";
    }
    return "";
  };

  const [inventarioID, setInventarioID] = useState();

  useEffect(() => {
    // Actualizar EL ID cada vez que se cambia el artículo o el almacén
    const nuevoID = obtenerID();
    setInventarioID(nuevoID);
  }, [articuloSeleccionado, almacenSeleccionado]);
 // console.log("Inventario ID", inventarioID);

  //--------------------------- CONSUMO ------------------------------- //
  const [cantidadSalida, setCantidadSalida] = useState(0);

  useEffect(() => {
    const cantidadUsada = Number(datosRepuestos.cantidad) || 0;
    if (artSuc.length > 0 && artSuc.cantidad !== undefined) {
      setCantidadSalida(artSuc.cantidad - cantidadUsada);
    } else {
      setCantidadSalida(null);
    }
  }, [artSuc, datosRepuestos.cantidad]);

  useEffect(() => {
    if (cantidadSalida) {
      setConsumo((prev) => ({
        ...prev,
        cantidad_enviada: cantidadSalida, // Actualiza la cantidad en el estado de consumo
      }));
    }
  }, [cantidadSalida]);

  //console.log(" cantidad de salida", cantidadSalida);

  const [uuid, setUuid] = useState(uuidv4());

  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);
 // console.log("FECHA", date);

 // console.log("art suc", artSuc);

  //----------------------------------------------- requerimientos y datos lista , guardar stock -----------------------//
  const [consumo, setConsumo] = useState({
    fecha_transaccion: date,
    cantidad_enviada: cantidadSalida,
  });

  const [usuarioCompras, setUsuarioCompras] = useState([]);

  const obtenerUsuarioCompras = () => {
    fetch(UrlApi + "/userdata/sector/4/")
      .then((data) => data.json())
      .then((data) => setUsuarioCompras(data));
  };
  useEffect(() => {
    obtenerUsuarioCompras();
  }, []);

  const [envioRequerimiento, setEnvioRequerimiento] = useState({
    id_movil: null,
    id_sucursal: localStorage.getItem("sucursal"),
    fecha_entrega: moment(consumo.fecha_transaccion).format(
      "YYYY-MM-DD HH:mm:ss"
    ),
    usuario_registra: localStorage.getItem("user"),
    usuario_autoriza: null,
    estado: 50, //cuando se carga el pedido se pone en estado "A autorizar"
    para_comprar: 1,
    observaciones: "",
    uuid_requerimiento: uuid,
    comentarios_internos: "Pedido Automático - Definir Cantidad",
    para_compras: 0,
    para_rrhh: 0,
    autorizado_jefe_compras: 0,
  });

  const [datosLista, setDatosLista] = useState([
    {
      material: null,
      usuario: localStorage.getItem("user"),
      cantidad: 1,
      // id_unidad_medida: datosArt.id_unidad_medida,
      uuid_requerimiento: envioRequerimiento.uuid_requerimiento,
      id_articulo: null,
    },
  ]);

  // const envioMail = async (repuesto) => {
  //   const artSucMatch = artSuc.find(
  //     (art) =>
  //       art.id_articulo === repuesto.articulo.id_articulo &&
  //       art.id_almacen === repuesto.almacen.id_almacen
  //   );

  //   if (!artSucMatch) return; // Si no se encuentra, no enviar correo

  //   // Extraer valores de alerta mínima y valor mínimo del artículo
  //   const { alerta_minima, valor_minimo, articulo, almacen } = artSucMatch;

  //   usuarioCompras &&
  //     usuarioCompras.length > 0 &&
  //     usuarioCompras.map((usuario) => {
  //       if (usuario.email) {
  //         if (
  //           repuesto.cantidadSalida < alerta_minima &&
  //           repuesto.cantidadSalida > valor_minimo
  //         ) {
  //           enviarmails(
  //             usuario.email,
  //             "Advertencia de Nivel de Stock ",
  //             `<p>El artículo <b>${articulo}</b> del almacén <b>${almacen}</b> esta por debajo del punto de pedido.<br/></p>`
  //           );
  //         } else if (repuesto.cantidadSalida < valor_minimo) {
  //           enviarmails(
  //             usuario.email,
  //             "Alerta de Stock por Debajo del Nivel Mínimo",
  //             `<p>El artículo <b>${articulo}</b> del almacén <b>${almacen}</b> esta por debajo del stock mínimo.<br/></p>`
  //           );
  //         }
  //       }
  //     });
  // };

  useEffect(() => {
    // Actualizar datosLista solo con los artículos que requieren un nuevo requerimiento y cumplen con los niveles de stock
    const nuevosDatosLista = repuestos
      .filter((repuesto) => {
        // Verificar si no existe requerimiento y si está en el rango de alerta
        if (!reqExiste[repuesto.articulo.id_articulo]) {
          const artSucMatch = artSuc.find(
            (art) =>
              art.id_articulo === repuesto.articulo.id_articulo &&
              art.id_almacen === repuesto.almacen.id_almacen
          );

          if (artSucMatch) {
            const { alerta_minima, valor_minimo } = artSucMatch;
            return repuesto.cantidadSalida < alerta_minima;
          }
        }
        return false;
      })
      .map((repuesto) => ({
        material: null,
        usuario: localStorage.getItem("user"),
        cantidad: 1,
        uuid_requerimiento: envioRequerimiento.uuid_requerimiento,
        id_articulo: repuesto.articulo.id_articulo,
      }));

    setDatosLista(nuevosDatosLista);
   // console.log("NUEVOS DATOS LISTA ", nuevosDatosLista);
  }, [reqExiste, repuestos]);

 // console.log("DATOS LISTA", datosLista);

  const guardarListaMateriales = (e) => {
    postData(
      e,
      "/lista_materiales",
      {
        datosLista,
      },
      setNotificacion
    );
  };

  const guardarConsumo = async (e) => {
    logEventos("Guardar consumo", "Stock Sucursales", "Se guardo el consumo", localStorage.getItem("user"))
    console.log("Estoy por guardar");
    e.preventDefault();
    const promesas = repuestos.map(async (repuesto) => {
      await fetch(UrlApi + "/stock_transacciones", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id_accion: 200,
          id_articulo: repuesto.articulo.id_articulo,
          almacen_origen: repuesto.almacen.id_almacen,
          cantidad_enviada: repuesto.cantidad, // Utiliza la cantidad del repuesto
          fecha_transaccion: moment(consumo.fecha_transaccion).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          usuario: localStorage.getItem("user"),
        }),
      })
        .then((res) => {
          if (!res.err) {
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setMensaje("El consumo se guardó correctamente!");
            console.log("Guardado");
          } else {
            setOpenSnack(false);
            setColorMensaje("#F1948A");
            setMensaje("Error al guardar el consumo");
            console.log("Error");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    });
    const resultados = await Promise.all(promesas);
   // console.log("Transacciones guardadas:", resultados);

    const stockPromises = repuestos.map(async (repuesto) => {
      await fetch(UrlApi + "/stock_inventarios/" + repuesto.inventarioID, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          cantidad: repuesto.cantidadSalida,
          usuario: localStorage.getItem("user"),
        }),
      })
        .then((res) => {
          if (!res.err) {
            console.log("Guardado");
          } else {
            console.log("Error");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    });
    const resultado2 = await Promise.all(stockPromises);
    //console.log("STOCK RESULTADO", resultado2);

    if (datosLista.length > 0) {
      try {
        await postData(
          e,
          "/requerimientos_materiales",
          {
            id_sucursal: localStorage.getItem("sucursal"),
            id_movil: envioRequerimiento.id_movil,
            estado: 50, // Estado "Pendiente autorización"
            para_comprar: envioRequerimiento.para_comprar,
            para_compras: envioRequerimiento.para_compras,
            para_rrhh: envioRequerimiento.para_rrhh,
            fecha_entrega: envioRequerimiento.fecha_entrega,
            usuario_registra: envioRequerimiento.usuario_registra,
            usuario_autoriza: envioRequerimiento.username,
            observaciones: envioRequerimiento.observaciones,
            uuid_requerimiento: uuid,
            comentarios_internos: envioRequerimiento.comentarios_internos,
            autorizado_jefe_compras: 0,
          },
          setNotificacion,
          guardarListaMateriales(e)
        );
        console.log("Requerimiento creado con éxito.");
      } catch (error) {
        console.error("Error al crear el requerimiento:", error);
      }
    } else {
      console.log(
        "No se creará el requerimiento porque datosLista está vacío."
      );
    }

    const stockPromises2 = repuestos.map(async (repuesto) => {
      if (
        repuesto.cantidadSalida < repuesto.articulo.alerta_minima &&
        repuesto.cantidadSalida >= repuesto.articulo.valor_minimo
      ) {
      //  console.log("ADVERTENCIA AMARILLA");
        if (
          repuesto.articulo.pedido_automatico === 1 &&
          reqExiste.length === 0
        ) {
      //    console.log("ES AUTOMATICO AMARILLO");
        }
      } else if (repuesto.cantidadSalida < repuesto.articulo.valor_minimo) {
     //   console.log("ADVERTENCIA ROJA");
        if (
          repuesto.articulo.pedido_automatico === 1 &&
          reqExiste.length === 0
        ) {
      //    console.log("ES AUTOMATICO ROJO");
        }
      }
      // envioMail(repuesto);
    });
    const resultado3 = await Promise.all(stockPromises2);
   // console.log("STOCK RESULTADO 2", resultado3);
    handleClose();
  };

  //console.log("repuestos", repuestos);

  return (
    <div>
      <Dialog
        open={props.tarea}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        fullScreen
      >
        <Title
          titulo={`Cargar nueva tarea`}
          handleClose={() => {
            handleClose();
          }}
        />
        <Box
          border={0.5}
          borderColor={grey[300]}
          backgroundColor="#F4F6F6"
          p={2}
          py={2}
          mt={1}
        >
          <DialogContent>
            <Container maxWidth="xl">
              <form onSubmit={guardarTarea} autoComplete="off">
                <Grid
                  container
                  spacing={2}
                  marginTop={-3}
                  // justifyContent="center"
                  // alignItems="center"
                >
                  <Grid item xs={2}>
                    <TextField
                      style={style}
                      label="Tarea desde"
                      name="tarea_desde"
                      size="small"
                      type="date"
                      focused
                      required
                      fullWidth
                      value={moment(datosTarea.tarea_desde).format(
                        "YYYY-MM-DD"
                      )}
                      onChange={almacenarTarea}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      style={style}
                      label="Tarea hasta"
                      name="tarea_hasta"
                      size="small"
                      type="date"
                      focused
                      required
                      fullWidth
                      value={moment(datosTarea.tarea_hasta).format(
                        "YYYY-MM-DD"
                      )}
                      onChange={almacenarTarea}
                    />
                  </Grid>
                  <Grid item xs={2.5}>
                    <TextField
                      fullWidth
                      size={size}
                      placeholder="....."
                      style={style}
                      focused
                      color="primary"
                      required
                      name="lugar_tarea"
                      label="Lugar"
                      variant="outlined"
                      value={datosTarea.lugar_tarea}
                      inputProps={{
                        maxLength: 45,
                      }}
                      onChange={almacenarTarea}
                    />
                  </Grid>
                  <Grid item xs={2.5}>
                    <Autocomplete
                      // disabled={!grupos}
                      disableClearable
                      size="small"
                      required
                      noOptionsText={"Proveedor INEXISTENTE"}
                      options={proveedor}
                      style={style}
                      autoHighlight
                      getOptionLabel={(prov) => prov.razon_social}
                      onChange={(event, value) =>
                        setProveedorSeleccionado(value)
                      }
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          key={option.id}
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option.razon_social}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                          }}
                          focused
                          size="small"
                          placeholder="Tipee para buscar..."
                          label="Persona que llevo a cabo la tarea"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ alignItems: "center" }}
                      marginTop={1}
                    >
                      <Typography>No Finalizada</Typography>
                      <AntSwitch
                        checked={estadoTarea === 1} // El switch estará en 'checked' si estadoTarea es 1
                        onChange={(e) => {
                          const nuevoEstado = e.target.checked ? 1 : 0;
                          setEstadoTarea(nuevoEstado);
                          setDatosTarea({
                            ...datosTarea,
                            finalizada: nuevoEstado,
                          });
                        }}
                        inputProps={{ "aria-label": "ant design" }}
                      />
                      <Typography>Finalizada</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      size={size}
                      placeholder="....."
                      style={style}
                      focused
                      color="primary"
                      required
                      name="motivo_tarea"
                      label="Motivo"
                      variant="outlined"
                      value={datosTarea.motivo_tarea}
                      inputProps={{
                        maxLength: 100,
                      }}
                      onChange={almacenarTarea}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      size={size}
                      placeholder="....."
                      style={style}
                      focused
                      color="primary"
                      required
                      name="detalle_tarea"
                      label="Detalle"
                      variant="outlined"
                      value={datosTarea.detalle_tarea}
                      inputProps={{
                        maxLength: 100,
                      }}
                      onChange={almacenarTarea}
                    />
                  </Grid>
                  <Box
                    width="100%"
                    border={2}
                    borderRadius={3}
                    borderColor={grey[400]}
                    backgroundColor="#E6E6E6"
                    p={2} // Espaciado interior del Box
                    mt={1} // Separación superior
                  >
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          sx={{ textDecoration: "underline" }}
                        >
                          Agregar Repuestos:
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Autocomplete
                          // disabled={!grupos}
                          disableClearable
                          size="small"
                          required
                          noOptionsText={"Repuesto INEXISTENTE"}
                          options={articulo}
                          style={style}
                          autoHighlight
                          getOptionLabel={(rep) =>
                            rep.nombre || "Seleccionar Repuesto"
                          }
                          value={articuloSeleccionado}
                          // onChange={(event, value) =>
                          //   setArticuloSeleccionado(value)
                          // }
                          onChange={handleArticuloChange}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              key={option.id}
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              {option.nombre}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                              }}
                              focused
                              size="small"
                              placeholder="Tipee para buscar..."
                              label="Repuesto utilizado"
                              error={!!mensajeAdvertencia} // Agrega el error
                            />
                          )}
                        />
                        {mensajeAdvertencia && (
                          <FormHelperText error>
                            {mensajeAdvertencia}
                          </FormHelperText> // Muestra el helper text
                        )}
                      </Grid>

                      <Grid item xs={3}>
                        <Autocomplete
                          // disabled={!grupos}
                          disableClearable
                          size="small"
                          required
                          noOptionsText={"Almacén INEXISTENTE"}
                          options={almacen}
                          style={style}
                          autoHighlight
                          getOptionLabel={(alm) => alm.nombre || "..."}
                          value={almacenSeleccionado}
                          onChange={handleAlmacenChange}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              key={option.id}
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              {option.nombre}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                              }}
                              focused
                              size="small"
                              placeholder="Tipee para buscar..."
                              label="Almacén"
                              error={!!mensajeAdvertencia} // Agrega el error
                            />
                          )}
                        />
                        {mensajeAdvertencia && (
                          <FormHelperText error>
                            {mensajeAdvertencia}
                          </FormHelperText> // Muestra el helper text
                        )}
                      </Grid>

                      <Grid item xs={2}>
                        <TextField
                          // fullWidth
                          size={size}
                          placeholder="....."
                          style={style}
                          focused
                          color="primary"
                          required
                          name="cantidad"
                          label="Cantidad usada"
                          variant="outlined"
                          value={datosRepuestos.cantidad}
                          inputProps={{
                            maxLength: 45,
                          }}
                          onChange={almacenarRepuesto}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Grid>

                      {articuloSeleccionado && almacenSeleccionado ? (
                        <Grid item xs={1.5}>
                          <TextField
                            disabled
                            // fullWidth
                            size={size}
                            placeholder="0"
                            style={style}
                            focused
                            color="primary"
                            name="cantidad"
                            label="Cantidad Stock"
                            variant="outlined"
                            value={cantidad} // Usa el estado de cantidad
                            inputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                      ) : null}

                      <Grid item xs={1}>
                        <Tooltip title="Agregar Repuesto" arrow>
                          <IconButton
                            onClick={agregarRepuesto}
                            color="primary"
                            disabled={desactivarAgregar}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>

                      <Grid item xs={12} marginTop={2}>
                        <Typography
                          variant="h6"
                          sx={{ textDecoration: "underline" }}
                        >
                          Repuestos Agregados:
                        </Typography>

                        <Box
                          width="75%"
                          border={2}
                          borderRadius={3}
                          borderColor={grey[400]}
                          backgroundColor="#FFFFFF"
                          p={1} // Espaciado interior del Box
                          mt={1} // Separación superior
                        >
                          <Grid container spacing={2}>
                            <List sx={{ width: '100%' }}>
                              {repuestos.map((repuesto, index) => (
                                <ListItem
                                  key={index}
                                  sx={{
                                    padding: 2, // Aumenta el padding del ListItem
                                    marginY: 1, // Agrega espacio vertical entre los items
                                    borderBottom: "1px solid #e0e0e0", // Línea divisoria entre items (opcional)
                                  }}
                                >
                                  <ListItemText
                                    primary={
                                      <Typography>
                                        <Box
                                          component="span"
                                          sx={{
                                            fontWeight: "bold",
                                            color: "#1976d2",
                                            mr: 1,
                                          }}
                                        >
                                          Artículo:
                                        </Box>
                                        <Box component="span" sx={{ mr: 1 }}>
                                          {repuesto.articulo.nombre},
                                        </Box>
                                        <Box
                                          component="span"
                                          sx={{
                                            fontWeight: "bold",
                                            color: "#1976d2",
                                            mr: 1,
                                          }}
                                        >
                                          Almacén:
                                        </Box>
                                        <Box component="span" sx={{ mr: 1 }}>
                                          {repuesto.almacen.nombre},
                                        </Box>
                                        <Box
                                          component="span"
                                          sx={{
                                            fontWeight: "bold",
                                            color: "#1976d2",
                                            mr: 1,
                                          }}
                                        >
                                          Cantidad:
                                        </Box>
                                        <Box component="span" sx={{ mr: 1 }}>
                                          {repuesto.cantidad}
                                        </Box>
                                      </Typography>
                                    }
                                  />
                                  <Grid item xs={1} marginLeft={3}>
                                    <Tooltip title="Eliminar Repuesto" arrow>
                                      <IconButton
                                        color="error"
                                        onClick={() => eliminarRepuesto(index)}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                </ListItem>
                              ))}
                            </List>
                          </Grid>
                        </Box>                    
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Stack direction="row">
                  <Grid
                    container
                    direction={{ xs: "column", sm: "row" }}
                    marginTop={1}
                  >
                    <Grid item xs={9}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleClose}
                      >
                        Volver
                      </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={(e) => {
                          guardarTarea(e);
                          guardarRepuesto(e);
                          guardarConsumo(e);
                        }}
                        disabled={disabledGuardar}
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              </form>
            </Container>
          </DialogContent>
        </Box>
      </Dialog>
      {notificacion ? (
        <Notify
          mensaje={mensaje}
          open={openSnack}
          color={colormensaje}
          finalizar={setFinalizar}
          severity={severitySnack}
        />
      ) : null}
      {/* ALERT --> SE MUESTRA SI HAY UN ERROR EN LA API (NO SE CONECTA O ESTÁ MAL LA RUTA, ETC.) */}
      {error && mensajeAlerta ? (
        <Alerta mensaje={mensajeAlerta} severity={severityAlerta} />
      ) : null}
    </div>
  );
}

export default Agregar_tarea;
