import React, { useEffect, useState } from "react";
import { getData } from "../../../../services/library";
import Navbar from "../../../Navbar";
import { Tooltip, Fab, List, Checkbox, ListItem, IconButton, Container, Box, Typography, Grid, } from '@mui/material';
import { UrlApi } from "../../../../services/apirest";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { grey } from "@mui/material/colors";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { verEnviarPDF } from "../../../../services/services";
import axios from "axios";
import DoneIcon from '@mui/icons-material/Done';

export default function ListaFacturasSucursal() {
    const [facturas, setFacturas] = useState([]);
    const [items, setItems] = useState([]);

    const getFacturas = () => {
        getData(`/comprobantes/noimportadas/${localStorage.getItem('sucursal')}`, setFacturas)
    };

    const getItems = async () => {
        if (!facturas || facturas.length === 0) return;

        try {
            // Mapeo de las solicitudes para cada factura
            const itemsFacturas = await Promise.all(
                facturas.map((f) =>
                    fetch(UrlApi + `/comprobantes/items_facturas/${f.uuid}`)
                        .then(response => response.json())
                )
            );

            // Actualizar el estado con los items obtenidos
            setItems(itemsFacturas.flat()); // Aplanar si las respuestas son arrays

        } catch (error) {
            console.error("Error obteniendo items:", error);
        }
    };

    useEffect(() => {
        getFacturas()
    }, []);

    useEffect(() => {
        if (facturas) {
            getItems()
        }
    }, [facturas])

    const handleCheckboxChange = (id) => {
        setFacturas(prevFacturas =>
            prevFacturas.map(factura =>
                factura.id_factura === id ? { ...factura, selected: !factura.selected } : factura
            )
        );

    };

    const handleViewPDF = (id_factura, id_pedido) => {
        // Lógica para abrir el PDF de la factura
        buscarFactura(id_pedido)
        // console.log(`Abrir PDF para factura con ID: ${id_factura}`);
    };

    async function buscarFactura(reg) {
        // console.log("req", reg);

        const id_pedido = reg;
        try {
            const response = await fetch(`${UrlApi}/comprobantes/pedido/${id_pedido}`);
            if (!response.ok) {
                throw new Error("Error en la solicitud: " + response.statusText);
            }
            const factura = await response.json();
            console.log("URK", `${UrlApi}/comprobantes/pedido/${id_pedido}`);
            if (factura.length === 1) {
                verEnviarPDF(factura[0].id_factura, 0);
            } else {
                alert(
                    "Existe mas de un comprobante para este pedido. Contacte a sistemas."
                );
            }

        } catch (error) {
            console.error("Error al obtener la factura:", error);
            alert(error);
        }
    };

    const handleSaveChanges = (e) => {
          // Prepara los datos para enviar al backend
    const updates = facturas.map(factura => ({
        id_factura: factura.id_factura,
        importada: factura.selected ? 1 : 0, // Si está seleccionado, pasa a 1; de lo contrario, a 0
    }));

    axios.put(UrlApi + "/comprobantes/importar", updates)
        .then(response => {
            // Alerta de éxito
            alert('Los datos se actualizaron correctamente');
            console.log(response.data);
        })
        .catch(error => {
            // Alerta de error
            alert('Hubo un error al actualizar los datos');
            console.error("Error:", error);
        });

    // Llamada a la función getFacturas después de la actualización
    getFacturas();
    };

    return (
        <div>
            <Navbar />
            <Container maxWidth="xl" mt={1}>
                <Box sx={{ flexGrow: 1, backgroundColor: "#1F618D", mt: 2 }}>
                    <Grid container p={1} justifyContent="center" alignItems="center">
                        {/* {!formVisible && */}
                        <Grid item xs={2} >
                            <IconButton sx={{ border: 'white', color: "#ffffff" }}>
                                <ArrowBackIcon sx={{ color: grey[50] }} />
                            </IconButton>
                        </Grid>


                        <Grid item xs={10}>
                            <Typography variant="h5" color="#fff" textAlign="center">
                                Listado de facturas
                            </Typography>
                        </Grid>

                    </Grid>

                </Box>
                <List>
                    {facturas.map((factura) => (
                        <ListItem key={factura.id} divider>
                            <Box sx={{ width: '100%' }}>
                                {/* Contenedor para separar la parte izquierda (información) y derecha (icono y checkbox) */}
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                                    {/* Información de la factura alineada a la izquierda */}
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="h6">
                                            Factura: {`${factura.pto_venta} ${factura.nro_comprobante}`}
                                        </Typography>
                                        <Typography variant="body1">
                                            Cliente: <strong>{factura.razon_social}</strong>
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Total: ${factura.importe_total.toFixed(2)}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Fecha de emisión: {factura.fecha_emision}
                                        </Typography>
                                        {items
                                            .filter((item) => item.uuid === factura.uuid)
                                            .map((item) => (
                                                <Typography
                                                    key={item.idfacturas_items}
                                                    variant="body2"
                                                    color="textSecondary"
                                                >
                                                    Entregado: {item.detalle}: {item.cantidad} {item.unidad_medida}
                                                </Typography>
                                            ))}
                                        <Box sx={{ mt: 1 }}>
                                            <Typography variant="caption" color="textSecondary">
                                                ID del pedido relacionado: {factura.id_pedido}
                                            </Typography>
                                        </Box>
                                    </Box>

                                    {/* Icono y Checkbox alineados a la derecha */}
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                        <IconButton onClick={() => handleViewPDF(factura.id_factura, factura.id_pedido)}>
                                            <PictureAsPdfIcon color="secondary" />
                                        </IconButton>
                                        <Checkbox
                                            edge="start"
                                            checked={factura.selected || false}
                                            onChange={() => handleCheckboxChange(factura.id_factura)}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </ListItem>
                    ))}
                    <Box
                        sx={{
                            position: "fixed",
                            bottom: 16,
                            left: "50%",
                            transform: "translateX(-50%)",
                            mb: 3,
                        }}
                    >
                        <Tooltip title="Facturas importadas en sisven">
                            <Fab
                                color="success"
                                aria-label="add"
                                onClick={(e) => handleSaveChanges(e)}
                                // disabled={props.listaDom.length === 10}
                                sx={{ ml: 1 }}
                            >
                                <DoneIcon />
                            </Fab>
                        </Tooltip>
                    </Box>
                </List>


                <br />
            </Container>

        </div >
    )
}
{/* <ListItemIcon>

<Checkbox
    edge="start"
    checked={factura.selected || false}
    onChange={() => handleCheckboxChange(factura.id_factura)}
/>
</ListItemIcon>  <Box
                        sx={{
                            position: "fixed",
                            bottom: 16,
                            left: "50%",
                            transform: "translateX(-50%)",
                            mb: 3,
                        }}
                    >
                        <Tooltip title="Facturas importadas en sisven">
                            <Fab
                                color="success"
                                aria-label="add"
                                onClick={(e) => handleSaveChanges(e)}
                                // disabled={props.listaDom.length === 10}
                                sx={{ ml: 1 }}
                            >
                                <DoneIcon/>
                            </Fab>
                        </Tooltip>
                    </Box>*/}