/// Confirmacion de dialogos multiples usos, confirma si o no
// Eduardo Bufanio
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';


const ConfirmDialog2 = (props) => {
  const { title, children, open, setOpen, onConfirm, botonConfirma } = props;
  return (

    <Dialog
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => setOpen(false)}
          color="error"
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
          color="success"
        >
          {botonConfirma}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog2;