//----- Component --------------------------------------------------------/

// Developer : Nicolas Pascucci

// Creation Date : 21/05/2024

// Version : 1

// Description : ultimo paso de la evaluacion del comercial donde se confirman los datos antes ingresados
//

//------Changes --------------------------------------------------------------------- -------------//

// Change Date :

// Change by :

// Change description:

//

// Change Date :

// Change by :

// Change description:

//

//========================================================================/
import React, { useState } from "react";
import Navbar from "../../Navbar";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import AssignmentIcon from "@mui/icons-material/Assignment";
import {
  Button,
  Box,
  Grid,
  Typography,
  TextField,
  Snackbar,
  Alert,
  Stack,
} from "@mui/material";
import moment from "moment";
import { UrlApi } from "../../../services/apirest";
import { logEventos } from "../../../services/services";

function CuartoStep(props) {
  const [disBtn, setDisBtn] = useState(false)
  const kgVendidos = parseFloat(props.vendido)
  const [estados, setEstados] = useState(props.estados)
  const [fecha, setFecha] = useState(props.fecha)
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  // console.log("PROPS RESULTADOS", props.resultados);

  const guardarResultado = async (e) => {
    setDisBtn(true)
    logEventos("Guardar datos mensuales", "Evaluacion Comerciales", "Se confirman y guardan los datos mensuales", localStorage.getItem("user"))
    e.preventDefault();
    if (props.modificar === true) {
      await fetch(UrlApi + "/resultados_comerciales/"+ props.resultados[0].id_resultado, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          comercial:props.comercial,
          fecha:fecha,
          resultado: props.sumaPorcentajes,
          vendido: kgVendidos,
          sucursal:props.sucursalSeleccionada.id,
          clientes: props.clientes,
          vencidos: props.vencidos,
          distribuidores_butano: props.distribuidoresButano,
          comercios_butano: props.comerciosButano,
          usuarios_butano: props.usuariosButano,
          usuario: localStorage.getItem("user")
        }),
      })
        .then((res) => {
          if (!res.err) {
            console.log("Guardado");
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setMensaje("Los Datos del Mes se guardaron exitosamente !");
          } else {
            console.log("Error");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else {  
      await fetch(UrlApi + "/resultados_comerciales", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          comercial:props.comercial,
          fecha:fecha,
          resultado: props.sumaPorcentajes,
          vendido: kgVendidos,
          sucursal:props.sucursalSeleccionada.id,
          clientes: props.clientes,
          vencidos: props.vencidos,
          distribuidores_butano: props.distribuidoresButano,
          comercios_butano: props.comerciosButano,
          usuarios_butano: props.usuariosButano,
          usuario: localStorage.getItem("user"),
          porcentaje_vendido: props.porcentajeKgVendido,
          porcentaje_clientes: props.porcentajeClientes,
          porcentaje_vencidos: props.porcentajeVencidos,
          porcentaje_distribuidores: props.porcentajeVendidoDistribuidores,
          porcentaje_comercios: props.porcentajeVendidoComercios,
          porcentaje_usuarios: props.porcentajeVendidoUsuarios,
        }),
      })
        .then((res) => {
          if (!res.err) {
            console.log("Guardado");
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setMensaje("Los Datos del Mes se guardaron exitosamente !");
          } else {
            console.log("Error");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
    
    if (estados && estados[0]) {
      await fetch(UrlApi + "/estados_evaluacion_comercial/"+ estados[0].id, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          evaluacion:1,
        }),
      })
        .then((res) => {
          if (!res.err) {
            console.log("Guardado");
          } else {
            console.log("Error");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
    // PARA LIMPIAR LOS DATOS
    props.handleTerminar()
  };

  return (
  <div style={{ marginTop: 20, alignItems: "center", display: "flex", flexDirection: "column"}}>
    {props.activeStep === 4 && (
      <div>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sx={{textAlign:"center"}}>
            <Typography variant="h6" gutterBottom>
              ¿Confirma los Siguientes Datos?
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              size="small"
              label="Usuarios Butano"
              variant="outlined"
              value={props.usuariosButano}
              InputProps={{ readOnly: true }}
              fullWidth
              focused
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              size="small"
              label="Comercios Butano"
              variant="outlined"
              value={props.comerciosButano}
              InputProps={{ readOnly: true }}
              fullWidth
              focused
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              size="small"
              label="Distribuidores Butano"
              variant="outlined"
              value={props.distribuidoresButano}
              InputProps={{ readOnly: true }}
              fullWidth
              focused
            />
          </Grid>
          {/* <Grid item xs={12}>
            <TextField
              size="small"
              label="Vendido en el Mes (Kg)"
              variant="outlined"
              value={kgVendidos}
              InputProps={{ readOnly: true }}
              fullWidth
              focused
            />
          </Grid> */}
          <Grid item xs={12} sm={4}>
            <TextField
              size="small"
              label="Vendido en el Periodo (kg)"
              variant="outlined"
              value={props.vendido}
              InputProps={{ readOnly: true }}
              fullWidth
              focused
            />
          </Grid>
          {/* <Grid item xs={12} sm={4}>
            <TextField
              size="small"
              label="Vendidos a Distribuidores (Kg)"
              variant="outlined"
              value={props.distribuidores}
              InputProps={{ readOnly: true }}
              fullWidth
              focused
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              size="small"
              label="Vendido a Comercios (Kg)"
              variant="outlined"
              value={props.comercio}
              InputProps={{ readOnly: true }}
              fullWidth
              focused
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              size="small"
              label="Vendido a Usuarios (Kg)"
              variant="outlined"
              value={props.usuarios}
              InputProps={{ readOnly: true }}
              fullWidth
              focused
            />
          </Grid> */}
          <Grid item xs={4}>
            <TextField
              size="small"
              label="Clientes del Mes"
              variant="outlined"
              value={props.clientes}
              InputProps={{ readOnly: true }}
              fullWidth
              focused
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              size="small"
              label="Monto Vencido en el Mes ($)"
              variant="outlined"
              value={props.vencidos}
              InputProps={{ readOnly: true }}
              fullWidth
              focused
            />
          </Grid>
          {props.configuracionVista[0].porcentaje === 1? 
          <Grid item xs={4}>
            <TextField
              size="small"
              label="Porcentaje de Ganancia (%)"
              variant="outlined"
              value={props.sumaPorcentajes}
              InputProps={{ readOnly: true }}
              fullWidth
              focused
              />
          </Grid>
              :null}
          <Grid item xs={10}>
            <Button
              variant="contained"
              color="error"
              // disabled={activeStep === 0}
              onClick={props.handleBack}
              sx={{ mr: 1 }}
            >
              Volver
            </Button>
            </Grid>
            <Grid item xs={2}>
              <Button fullWidth variant="contained" onClick={guardarResultado}
              disabled={disBtn}
              >CONFIRMAR</Button>
            </Grid>
        </Grid>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={openSnack}
            autoHideDuration={1500}
            onClose={handleCloseSnack}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseSnack}
              severity="success"
              sx={{ width: "100%" }}
              style={{ backgroundColor: colormensaje, color: "black" }}
            >
              {mensaje}
            </Alert>
          </Snackbar>
        </Stack>

      </div>
      
    )}
  </div>
  )
}

export default CuartoStep