import Navbar from "../Navbar";
import React, { useEffect, useState, useMemo } from "react";
import { UrlApi } from "../../services/apirest";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Typography,
  Stack,
  Grid,
  TextField,
  InputAdornment,
} from "@mui/material";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import moment from "moment";

// EXPORTAR DATOS
import { ExportToCsv } from "export-to-csv";

//Notification
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

// ICONOS
import ArticleIcon from "@mui/icons-material/Article";
import BuildIcon from "@mui/icons-material/Build";
import BadgeIcon from "@mui/icons-material/Badge";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import NumbersIcon from "@mui/icons-material/Numbers";
import PropaneTankIcon from "@mui/icons-material/PropaneTank";
import PinIcon from "@mui/icons-material/Pin";
import RateReviewIcon from "@mui/icons-material/RateReview";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Romaneo_administrar_parahabilitar() {
  const [romaneo_envases, setRomaneo_envases] = useState();
  const [fecha, setFecha] = useState(
    moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
  );

  const [datosRomaneoEnvases, setDatosRomaneoEnvases] = useState({
    id_tipo_reparacion: null,
    id_tipo: null,
    nro_envase: null,
    id_mat_fabricante: null,
    lote: "",
    fecha_fabricacion: "",
    placa_relieve: 2,
    nro_placa: null,
    id_mot_baja: null,
    fecha_reparacion: "",
    id_pertenencia: null,
    fecha_habilitacion: fecha,
    hora_habilitacion: "",
    usuario: localStorage.getItem("user"),
  });

  console.log("DATOS NO SE QUE ", datosRomaneoEnvases);
  const [tabla, setTabla] = useState(true);
  const [update, setUpdate] = useState(false);

  //MANEJA TAB
  const [opcion, setOpcion] = React.useState("para habilitar");

  const handleChange = (event, newValue) => {
    setOpcion(newValue);
  };

  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");

  //funcion para obtener a los romaneo leyendas, pasarlos a json y guardarlos en data
  const obtenerRomaneoEnvases = () => {
    fetch(UrlApi + "/romaneo_envases/parahabilitar")
      .then((data) => data.json())
      .then((data) => setRomaneo_envases(data));
  };
  // console.log("romaneo_envases", romaneo_envases);

  // ---------------------------------------------------------------
  const [romaneo_pertenencias, setRomaneo_pertenencias] = useState();

  const obtenerRomaneoPertenencias = () => {
    fetch(UrlApi + "/romaneo_pertenencias")
      .then((data) => data.json())
      .then((data) => setRomaneo_pertenencias(data));
  };
  // console.log("romaneo_pertenencias", romaneo_pertenencias);
  // ---------------------------------------------------------------

  //funcion que espera los cambios de la funcion de obtener a los romaneo leyendas
  useEffect(() => {
    obtenerRomaneoEnvases();
    obtenerRomaneoPertenencias();
  }, [tabla]);

  // FUNCION QUE SE EJECUTA CADA VEZ QUE HAY UN CAMBIO EN LA BASE DE DATOS
  // SIRVE PARA QUE LA TABLA MOSTRADA SE ACTUALICE EN TIEMPO REAL
  useEffect(() => {
    if (update) obtenerRomaneoEnvases();
  }, [update]);

  //*************************************************************************************************************************************/
  //funcion para obtener a los romaneo descripcion_reparacion, pasarlos a json y guardarlos en data

  const [descripcion_reparacion, setDescripcion_reparacion] = useState();

  const obtenerDescripcionReparacion = () => {
    fetch(UrlApi + "/romaneo_reparaciones")
      .then((data) => data.json())
      .then((data) => setDescripcion_reparacion(data));
  };
  // console.log("trae la descripcion de la reparacion", descripcion_reparacion);

  //funcion que espera los cambios de la funcion de obtener a los romaneo descripcion_reparacion
  useEffect(() => {
    obtenerDescripcionReparacion();
  }, []);

  //*************************************************************************************************************************************/
  //funcion para obtener a los romaneo descripcion_tipo_envase, pasarlos a json y guardarlos en data

  const [romaneo_tipo_envase, setRomaneo_Tipo_Envase] = useState();

  const obtenerDescripcionTipoEnvase = () => {
    fetch(UrlApi + "/romaneo_tipos_envases")
      .then((data) => data.json())
      .then((data) => setRomaneo_Tipo_Envase(data));
  };
  console.log("trae la descripcion de tipo envase", romaneo_tipo_envase);

  //funcion que espera los cambios de la funcion de obtener a los romaneo descripcion_tipo_envase
  useEffect(() => {
    obtenerDescripcionTipoEnvase();
  }, []);

  //*************************************************************************************************************************************/
  //funcion para obtener a los romaneo leyendas, pasarlos a json y guardarlos en data

  const [mat_fabricante, setMat_fabricante] = useState();

  const obtenerMatFabricante = () => {
    fetch(UrlApi + "/romaneo_fabricantes")
      .then((data) => data.json())
      .then((data) => setMat_fabricante(data));
  };
  // console.log("trae matricula de fabricante", mat_fabricante);

  //funcion que espera los cambios de la funcion de obtener a los romaneo leyendas
  useEffect(() => {
    obtenerMatFabricante();
  }, []);

  //*************************************************************************************************************************************/
  //const para obtener las ordenes de compra x proveedor (antes se hace un select en el BKND y se trae el id del proveedor seleccionado y lo guarda en ordencompradata)

  const [firmaSeleccionada, setFirmaSeleccionada] = useState({});
  // firmaSeleccionada && console.log("firmaSeleccionada", firmaSeleccionada);
  const [firmaYleyenda, setFirmaYleyenda] = useState(null);

  const obtenerFirmayLeyenda = async () => {
    const data = await fetch(
      UrlApi +
        "romaneo_pertenencias/firmayleyendaxID/" +
        firmaSeleccionada.id_pertenencia
    );
    const FyLData = await data.json();
    setFirmaYleyenda(FyLData);
  };

  //obtenemos las firmas y leyendas UNA VEZ que elegimos la firma seleccionada
  useEffect(() => {
    firmaSeleccionada && obtenerFirmayLeyenda();
  }, [firmaSeleccionada]);

  //*************************************************************************************************************************************/
  // PRUEBA
  // DATETIME

  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;

  //*************************************************************************************************************************************/

  //const para obtener las ordenes de compra x proveedor (antes se hace un select en el BKND y se trae el id del proveedor seleccionado y lo guarda en ordencompradata)

  const [envaseSeleccionado, setEnvaseSeleccionado] = useState({});

  const [envaseYpeso, setEnvaseyPeso] = useState(null);

  const obtenerEnvaseYpeso = async () => {
    const data = await fetch(
      UrlApi + "/romaneo_tipos_envases/envaseXID/" + envaseSeleccionado.id_tipo
    );
    const EyPData = await data.json();
    setEnvaseyPeso(EyPData);
  };
  console.log("envase y peso dentro del obtener 1", envaseSeleccionado);
  console.log("envase y peso dentro del obtener 2", envaseSeleccionado.id_tipo);

  //obtenemos los pesos y envases UNA VEZ que elegimos el envase seleccionado
  useEffect(() => {
    envaseSeleccionado && obtenerEnvaseYpeso();
  }, [envaseSeleccionado]);

  //*************************************************************************************************************************************/

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  //*************************************************************************************************************************************/

  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
    setUpdate(false);
    setOpenDialogHabilitar(false);
  };

  //*************************************************************************************************************************************/

  const columns = useMemo(
    () => [
      // { header: "ID", accessorKey: "id_envase", size: 50 },
      {
        header: "Reparación",
        accessorKey: "descripcion_reparacion",
        size: 110,
      },
      {
        header: "Tipo de Envase",
        accessorKey: "descripcion_tipo_envase",
        size: 153,
      },
      { header: "N°.Envase", accessorKey: "nro_envase", size: 100 },
      { header: "N°.Acuñado", accessorKey: "nro_acuniado", size: 109 },
      {
        header: "Fabricante",
        accessorKey: "descripcion_fabricante",
        size: 105,
      },
      {
        header: "Fe.Rep.",
        accessorKey: "fecha_reparacion",
        size: 85,
        type: "date",
        format: "DD-MM-YYYY",
      },
      { header: "Leyenda", accessorKey: "leyenda", size: 130 },
      { header: "Firma", accessorKey: "firma", size: 120 },
    ],
    []
  );

  // COLUMNAS CSV
  const columnsCsv = useMemo(
    () => [
      { header: "ID Envase", accessorKey: "id_envase", size: 70 },
      { header: "ID Tipo Rep.", accessorKey: "id_tipo_reparacion", size: 80 },
      {
        header: "Reparación",
        accessorKey: "descripcion_reparacion",
        size: 85,
      },
      { header: "ID Tipo", accessorKey: "id_tipo", size: 80 },
      {
        header: "Tipo de Envase",
        accessorKey: "descripcion_tipo_envase",
        size: 150,
      },
      { header: "Peso", accessorKey: "desc_peso", size: 75 },
      { header: "N°. Envase", accessorKey: "nro_envase", size: 80 },
      { header: "ID Matr. Fab.", accessorKey: "id_mat_fabricante", size: 80 },
      { header: "Matr. Fabricante", accessorKey: "mat_fabricante", size: 75 },
      { header: "Fabricante", accessorKey: "descripcion_fabricante", size: 75 },
      { header: "Lote", accessorKey: "lote", size: 75 },
      {
        header: "Fecha Fabricación",
        accessorKey: "fecha_fabricacion",
        size: 97,
      },
      { header: "Pla/Rel.", accessorKey: "placa_relieve", size: 86 },
      { header: "N°. Placa", accessorKey: "nro_placa", size: 80 },
      {
        header: "Fecha Reparación",
        accessorKey: "fecha_reparacion",
        size: 90,
        type: "date",
        format: "DD-MM-YYYY",
      },
      {
        header: "ID Pertenencia",
        accessorKey: "id_pertenencia",
        size: 85,
      },
      { header: "Leyenda", accessorKey: "leyenda", size: 90 },
      { header: "Firma", accessorKey: "firma", size: 90 },
      { header: "ID Estado", accessorKey: "id_estado", size: 90 },
      { header: "Estado", accessorKey: "Valor", size: 90 },
    ],
    []
  );

  const muiSearchTextFieldProps = {
    // para el buscar
    placeholder: "Buscar...",
    sx: { minWidth: "18rem" },
    variant: "outlined",
    color: "success",
    size: "small",
  };

  // ---------------------------------------------------------------

  //  EXPORTAR DATOS
  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columnsCsv.map((c) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(romaneo_envases);
  };

  //*************************************************************************************************************************************/

  // DIALOG PARA ACTIVAR/DESACTIVAR
  const [openDialogHabilitar, setOpenDialogHabilitar] = React.useState(false);

  const handleCloseDialogHabilitar = () => {
    setOpenDialogHabilitar(!openDialogHabilitar);
  };

  const dialogHabilitar = (row) => {
    setDatosRomaneoEnvases(row);
    setOpenDialogHabilitar(true);
    setTabla(true);
    setFecha(moment(new Date()).format("YYYY-MM-DD"));
  };

  const handleOpenDialogHabilitar = () => {
    setOpenDialogHabilitar(true);
  };

  //**********************************************************************************************/
 
  console.log("PUT HABILITAR", datosRomaneoEnvases);
  console.log("fecha y hora", fecha);

  //*************************************************************************************************************************************/
  const almacenar = (e) => {
    setDatosRomaneoEnvases({
      ...datosRomaneoEnvases,
      [e.target.name]: e.target.value,
    });
  };

  //*************************************************************************************************************************************/

  // ESTILO PARA LA TABLA

  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)" //background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //override to make tooltip font size larger
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const displayColumnDefOptions = {
    "mrt-row-actions": {
      muiTableHeadCellProps: {
        align: "center",
        sx: {
          fontSize: 14,
          fontStyle: "italic",
        },
      },
      size: 100,
    },
  };

  const initialState = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    //expanded: true, //expand all groups by default
    pagination: { pageIndex: 0, pageSize: 2000 },
    // grouping: ["firma", "descripcion_tipo_envase"],
  };

  const [activarDialog, setActivarDialog] = useState(false)
  const handleDesactivarDialog = () => {
    setActivarDialog(false)
  }

  return (
    <div>
      <Navbar />
      <Container maxWidth="xl" mt={1}>
        {romaneo_envases && tabla ? (
          <Box mt={2} mb={4}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
                displayColumnDefOptions={displayColumnDefOptions}
                layoutMode="grid"
                muiTableHeadCellProps={{
                  align: "left", // los titulos de la tabla
                }}
                muiTableBodyCellProps={{
                  align: "left", //el contenido de la tabla
                }}
                localization={MRT_Localization_ES} //idioma
                enableColumnDragging={false} //para arrastrar columnas
                initialState={initialState}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                columns={columns}
                data={romaneo_envases} //con lo q la completas (esto cambia siempre)
                // TRUE
                enableStickyFooter
                // enableColumnResizing
                enableRowVirtualization
                enableGrouping // para permitir agrupar x estado x ej
                enableRowSelection
                enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
                // FALSE
                enableColumnFilters={false} //desactiva filtros x  columna
                enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
                enableDensityToggle={false} //
                positionActionsColumn="last" // posicion de la columna de acciones al final
                renderTopToolbarCustomActions={({ table }) => {
                  const handleActivarDialog = () => {
                    setActivarDialog(true)
                  }
                  const handleActivar = () => {
                    table.getSelectedRowModel().flatRows.map((row) => {
                      // ACTIVAR RELACION
                      const guardarHabilitar = async () => {
                        console.log("Estoy por guardar");
                        await fetch(
                          UrlApi + "/romaneo_envases/" + row.original.id_envase,
                          {
                            method: "PUT",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify({
                              id_estado: 152,
                              fecha_habilitacion: datosRomaneoEnvases.fecha_habilitacion
                            }),
                          }
                        )
                          .then((res) => {
                            if (!res.err) {
                              setOpenSnack(true);
                              setColorMensaje("#D4EFDF");
                              setMensaje("El envase se habilitó exitosamente!");
                              setUpdate(true);
                              setActivarDialog(false)
                              //console.log("Guardado");
                            } else {
                              setOpenSnack(false);
                              setColorMensaje("#F1948A");
                              setMensaje("Error al habilitar");
                              //console.log("Error");
                            }
                          })
                          .catch((err) => {
                            console.log(err.message);
                          });
                      };
                      guardarHabilitar();
                    });
                  };

                  return (
                    <div style={{ display: "flex", gap: "0.5rem" }}>
                      <Typography variant="poster" fontSize={26}>
                        <span style={{ color: "#247af2" }}>
                          <b>Administrar</b>{" "}
                        </span>
                        Envases para Habilitar
                      </Typography>
                      <Button
                        color="success"
                        disabled={
                          !table.getIsSomeRowsSelected() &&
                          !table.getIsAllRowsSelected()
                        }
                        // ACTIVAR
                        onClick={handleActivarDialog}
                        variant="contained"
                        size="small"
                      >
                        Rehabilitar
                      </Button>

                      <Dialog open={activarDialog} fullWidth>
                        <DialogTitle marginLeft={3}>
                          <b>{"Rehabilitar Envases"}</b>
                        </DialogTitle>
                        
                        <DialogContent>
                          <Container maxWidth="xl">
                          <DialogContentText>
                          <b>Fecha:</b>
                        </DialogContentText>
                            <Grid item xs={5} mb={2}>
                              <TextField
                                style={style}
                                focused
                                size="small"
                                type="date"
                                name="fecha_habilitacion"
                                variant="outlined"
                                value={moment(
                                  datosRomaneoEnvases.fecha_habilitacion
                                ).format("YYYY-MM-DD")}
                                onChange={almacenar}
                              />
                            </Grid>

                            <Stack direction="row">
                              <Grid
                                container
                                direction={{ xs: "column", sm: "row" }}
                              >
                                <Grid item xs={8}>
                                  <Button
                                    variant="contained"
                                    color="error"
                                    onClick={handleDesactivarDialog}
                                  >
                                    Cancelar
                                  </Button>
                                </Grid>
                                <Grid item xs={4}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="success"
                                    onClick={handleActivar}
                                  >
                                    Rehabilitar
                                  </Button>
                                </Grid>
                              </Grid>
                            </Stack>
                          </Container>
                        </DialogContent>
                      </Dialog>
                    </div>
                  );
                }}
                //es el panel de expansion del costado
                // PANEL DE DETALLES / FICHA
                // renderDetailPanel={({ row }) => (
                //   <>
                //     <Container maxWidth="x1">
                //       <br />
                //       <DialogTitle
                //         style={{
                //           color: "white",
                //           backgroundColor: "#4abced",
                //           height: "10px",
                //           marginTop: "-30px",
                //           width: "111%",
                //         }}
                //       >
                //         <Grid item mt={-1.5}>
                //           ID DEL ENVASE: {row.original.id_envase}
                //         </Grid>
                //       </DialogTitle>
                //       <br />
                //       <Box display="flex" width="116%">
                //         <Box border={2} px={2} pt={2} borderRadius={4} mt={-2}>
                //           <Grid container maxWidth="x1" spacing={1} ml={5}>
                //             <Grid item xs={2.5} py={1} mb={1}>
                //               <TextField
                //                 fullWidth
                //                 placeholder="No Tiene"
                //                 label="Reparación"
                //                 name="descripcion_reparacion"
                //                 variant="filled"
                //                 size="small"
                //                 focused
                //                 value={row.original.descripcion_reparacion}
                //                 InputProps={{
                //                   readOnly: true,
                //                   startAdornment: (
                //                     <InputAdornment position="start">
                //                       <BuildIcon />
                //                     </InputAdornment>
                //                   ),
                //                 }}
                //               />
                //             </Grid>
                //             <Grid item xs={3} py={1} mb={1}>
                //               <TextField
                //                 fullWidth
                //                 placeholder="No Tiene"
                //                 label="Tipo de Envase"
                //                 name="descripcion_tipo_envase"
                //                 variant="filled"
                //                 size="small"
                //                 focused
                //                 value={row.original.descripcion_tipo_envase}
                //                 InputProps={{
                //                   readOnly: true,
                //                   startAdornment: (
                //                     <InputAdornment position="start">
                //                       <PropaneTankIcon />
                //                     </InputAdornment>
                //                   ),
                //                 }}
                //               />
                //             </Grid>
                //             <Grid item xs={1.3} py={1} mb={1}>
                //               <TextField
                //                 fullWidth
                //                 placeholder="No Tiene"
                //                 label="Peso"
                //                 name="peso"
                //                 variant="filled"
                //                 size="small"
                //                 focused
                //                 value={row.original.peso}
                //                 InputProps={{
                //                   readOnly: true,
                //                   startAdornment: (
                //                     <InputAdornment position="start">
                //                       Kg
                //                     </InputAdornment>
                //                   ),
                //                 }}
                //               />
                //             </Grid>

                //             <Grid item xs={2.10} py={1} mb={1}>
                //               <TextField
                //                 fullWidth
                //                 placeholder="No Tiene"
                //                 label="Nro. Envase"
                //                 name="nro_envase"
                //                 variant="filled"
                //                 size="small"
                //                 focused
                //                 value={row.original.nro_envase}
                //                 InputProps={{
                //                   readOnly: true,
                //                   startAdornment: (
                //                     <InputAdornment position="start">
                //                       <NumbersIcon />
                //                     </InputAdornment>
                //                   ),
                //                 }}
                //               />
                //             </Grid>
                //             <Grid item xs={2.10} py={1} mb={1}>
                //                 <TextField
                //                   fullWidth
                //                   placeholder="No Tiene"
                //                   label="Nro. Acuñado"
                //                   name="nro_acuniado"
                //                   variant="filled"
                //                   size="small"
                //                   focused
                //                   value={row.original.nro_acuniado}
                //                   InputProps={{
                //                     readOnly: true,
                //                     startAdornment: (
                //                       <InputAdornment position="start">
                //                         <PinIcon />
                //                       </InputAdornment>
                //                     ),
                //                   }}
                //                 />
                //               </Grid>
                //             <Grid item xs={2.2} py={1} mb={1}>
                //               <TextField
                //                 fullWidth
                //                 placeholder="No Tiene"
                //                 label="Matrícula del Fabricante"
                //                 name="id_mat_fabricante"
                //                 variant="filled"
                //                 size="small"
                //                 focused
                //                 value={row.original.mat_fabricante}
                //                 InputProps={{
                //                   readOnly: true,
                //                   startAdornment: (
                //                     <InputAdornment position="start">
                //                       <BadgeIcon />
                //                     </InputAdornment>
                //                   ),
                //                 }}
                //               />
                //             </Grid>
                //             <Grid item xs={1.4} py={1} mb={1}>
                //               <TextField
                //                 fullWidth
                //                 placeholder="No Tiene"
                //                 label="Lote"
                //                 name="lote"
                //                 variant="filled"
                //                 size="small"
                //                 focused
                //                 value={row.original.lote}
                //                 InputProps={{
                //                   readOnly: true,
                //                 }}
                //               />
                //             </Grid>
                //             <Grid item xs={2} py={1} mb={1}>
                //               <TextField
                //                 fullWidth
                //                 placeholder="No Tiene"
                //                 label="Fecha de Fabricacion"
                //                 name="fecha_fabricacion"
                //                 variant="filled"
                //                 size="small"
                //                 focused
                //                 value={row.original.fecha_fabricacion}
                //                 InputProps={{
                //                   readOnly: true,
                //                   startAdornment: (
                //                     <InputAdornment position="start">
                //                       <CalendarMonthIcon />
                //                     </InputAdornment>
                //                   ),
                //                 }}
                //               />
                //             </Grid>

                //             <Grid item xs={2.4} py={1} mb={1}>
                //               <TextField
                //                 fullWidth
                //                 placeholder="No Tiene"
                //                 label="Fecha de Reparación"
                //                 name="fecha_reparacion"
                //                 variant="filled"
                //                 size="small"
                //                 focused
                //                 value={row.original.fecha_reparacion}
                //                 InputProps={{
                //                   readOnly: true,
                //                   startAdornment: (
                //                     <InputAdornment position="start">
                //                       <CalendarMonthIcon />
                //                     </InputAdornment>
                //                   ),
                //                 }}
                //               />
                //             </Grid>
                //             <Grid item xs={3} py={1} mb={1}>
                //               <TextField
                //                 fullWidth
                //                 placeholder="No Tiene"
                //                 label="Firma"
                //                 name="firma"
                //                 variant="filled"
                //                 size="small"
                //                 focused
                //                 value={row.original.firma}
                //                 InputProps={{
                //                   readOnly: true,
                //                   startAdornment: (
                //                     <InputAdornment position="start">
                //                       <RateReviewIcon />
                //                     </InputAdornment>
                //                   ),
                //                 }}
                //               />
                //             </Grid>
                //             <Grid item xs={3} py={1} mb={1}>
                //                 <TextField
                //                   fullWidth
                //                   placeholder="No Tiene"
                //                   label="Leyenda / Leyenda Chapa Circular"
                //                   name="leyenda"
                //                   variant="filled"
                //                   size="small"
                //                   focused
                //                   value={row.original.leyenda}
                //                   InputProps={{
                //                     readOnly: true,
                //                     startAdornment: (
                //                       <InputAdornment position="start">
                //                         <ArticleIcon />
                //                       </InputAdornment>
                //                     ),
                //                   }}
                //                 />
                //               </Grid>
                //               <Grid item xs={3} py={1} mb={1}>
                //                 <TextField
                //                   fullWidth
                //                   placeholder="No Tiene"
                //                   label="Leyenda Chapa Rectangular"
                //                   name="ley_placa_rect"
                //                   variant="filled"
                //                   size="small"
                //                   focused
                //                   value={row.original.ley_placa_rect}
                //                   InputProps={{
                //                     readOnly: true,
                //                     startAdornment: (
                //                       <InputAdornment position="start">
                //                         <ArticleIcon />
                //                       </InputAdornment>
                //                     ),
                //                   }}
                //                 />
                //               </Grid>
                //               <Grid item xs={2.5} py={1} mb={1}>
                //                 <TextField
                //                   fullWidth
                //                   placeholder="No Tiene"
                //                   label="Número Chapa Rectangular"
                //                   name="nro_placa_rect"
                //                   variant="filled"
                //                   size="small"
                //                   focused
                //                   value={row.original.nro_placa_rect}
                //                   InputProps={{
                //                     readOnly: true,
                //                     startAdornment: (
                //                       <InputAdornment position="start">
                //                         <ArticleIcon />
                //                       </InputAdornment>
                //                     ),
                //                   }}
                //                 />
                //               </Grid>
                //               <Grid item xs={2.5} py={1} mb={1}>
                //                 <TextField
                //                   fullWidth
                //                   placeholder="No hay placa"
                //                   label="Número de Placa / de Chapa Circular"
                //                   name="nro_placa"
                //                   variant="filled"
                //                   size="small"
                //                   focused
                //                   value={row.original.nro_placa}
                //                   InputProps={{
                //                     readOnly: true,
                //                     startAdornment: (
                //                       <InputAdornment position="start">
                //                         <PinIcon />
                //                       </InputAdornment>
                //                     ),
                //                   }}
                //                 />
                //               </Grid>
                //           </Grid>
                //         </Box>
                //       </Box>
                //     </Container>
                //   </>
                // )}
              />
            </ThemeProvider>
          </Box>
        ) : null}
      </Container>

      {/* SNACKBAR QUE SE MOSTRARA AL GUARDAR LOS CAMBIOS */}
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
}

export default Romaneo_administrar_parahabilitar;