//----- Component --------------------------------------------------------/

// Developer : Nicolas Pascucci

// Creation Date : 14/12/2023

// Version : 1

// Description : Creación de un nuevo reclamo

//

//------Changes --------------------------------------------------------------------- -------------//
// Change Date : 11-3-2024
// Change by : Magali Perea
// Change description: Se amplió la longitud de caracteres del textfield "detalle" A 500 para un nuevo reclamo y puse el textfield multilínea (por esos los cambios visuales en pantalla).
//Se quitó la propiedad "requerido" para designar al encargado de los gastos del reclamo.

//

// Change Date :

// Change by :

// Change description:

//

//========================================================================/
import React, { useState, useEffect, useMemo, useRef } from "react";
import { UrlApi } from "../../../services/apirest";
import {
  Button,
  Box,
  Grid,
  Stack,
  Snackbar,
  TextField,
  Autocomplete,
  Alert,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment"; // PARA LA FECHA
import AttachFileIcon from "@mui/icons-material/AttachFile"; //ADJUNTAR ARCHIVOS
import { grey } from "@mui/material/colors";
import { v4 as uuidv4 } from "uuid"; //ADJUNTAR IMAGEN
import { enviarmails } from "../../../services/services"; //MAILS
import { logEventos } from "../../../services/services";
import FileUploadReclamo from "./FileUploadReclamo";
// (direccion) => `${direccion.direccion_entrega} (${direccion.nombre_direccion})`
function NuevoReclamo(props) {
  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");

  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);

  const [nroReclamo, setNroReclamo] = useState(uuidv4());
  const [dataCliente, setDataCliente] = useState(props.dataCliente);
  const [motivosEmergencia, setMotivosEmergencia] = useState();
  const [prioridad, setPrioridad] = useState();
  const [motivoSeleccionado, setMotivoSeleccionado] = useState();
  const [direccionSeleccionada, setDireccionSeleccionada] = useState();
  const [mostrarNro, setMostrarNro] = useState(false);
  const [ultimaRow, setUltimaRow] = useState();
  const [direcciones, setDirecciones] = useState();
  const [cargo, setCargo] = useState();

  const [datosReclamo, setDatosReclamo] = useState({
    motivo: null,
    prioridad: null,
    valor: null,
    fecha_abierto: date,
    detalle: "",
    a_cargo: null,
    valor_a_cargo: null,
  });

  //funcion para obtener motivos
  const obtenerMotivosEmergencia = () => {
    fetch(UrlApi + "/motivos_emergencias/activos")
      .then((data) => data.json())
      .then((data) => setMotivosEmergencia(data));
  };
  //funcion que espera los cambios de la funcion de obtener familias
  useEffect(() => {
    obtenerMotivosEmergencia();
  }, []);

  const obtenerDirecciones = () => {
    fetch(UrlApi + "/direccionesentrega/cli/" + dataCliente.id)
      .then((data) => data.json())
      .then((data) => setDirecciones(data));
  };
  //funcion que espera los cambios de la funcion de obtener familias
  useEffect(() => {
    obtenerDirecciones();
  }, []);

  //funcion para obtener prioridades
  const obtenerPrioridades = () => {
    fetch(UrlApi + "/desplegables/reclamos")
      .then((data) => data.json())
      .then((data) => setPrioridad(data));
  };
  //funcion que espera los cambios de la funcion de obtener familias
  useEffect(() => {
    obtenerPrioridades();
  }, []);

  const obtenerCargo = () => {
    fetch(UrlApi + "/desplegables/reclamos_cargo")
      .then((data) => data.json())
      .then((data) => setCargo(data));
  };
  //funcion que espera los cambios de la funcion de obtener familias
  useEffect(() => {
    obtenerCargo();
  }, []);

  //funcion para obtener motivos
  const obtenerUltimaRow = () => {
    fetch(UrlApi + "/reclamos/ultimarow")
      .then((data) => data.json())
      .then((data) => setUltimaRow(data));
  };
  //funcion que espera los cambios de la funcion de obtener familias
  useEffect(() => {
    obtenerUltimaRow();
  }, []);

  // console.log("ultima row", ultimaRow? ultimaRow[0].id_reclamo + 1:"no disponible")

  let numero_reclamo = ultimaRow
    ? ultimaRow[0].id_reclamo + 1
    : "no disponible";

  const handleClickOpenCrear = () => {
    setNroReclamo(uuidv4());
    setMotivoSeleccionado([]);
    datosReclamo.prioridad = "";
    datosReclamo.detalle = "";
    datosReclamo.a_cargo = null;
    activarLimpieza();
  };

  const [usuarioMail, setUsuarioMail] = useState([]);

  const obtenerUsuarioMail = () => {
    fetch(UrlApi + "/reclamos_config/")
      .then((data) => data.json())
      .then((data) => setUsuarioMail(data));
  };

  useEffect(() => {
    obtenerUsuarioMail();
  }, []);

  //console.log("USUARIO MAIL", usuarioMail);


  // console.log("MOTIVO",motivoSeleccionado.descripcion_motivo);
 // console.log("PRIORIDAD", datosReclamo.prioridad);
 // console.log("NOMBRE PRIORIDAD (VALOR)", datosReclamo.valor);
 // console.log("A CARGO", datosReclamo.a_cargo);
 // console.log("DETALLE", datosReclamo.detalle);

  const guardarReclamo = async (e) => {
    // console.log("Estoy por guardar");
    logEventos("Guardar nuevo reclamo", "Reclamos Granel", "Se guardo el nuevo reclamo", localStorage.getItem("user"))
    e.preventDefault();
    await fetch(UrlApi + "/reclamos/", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_cliente: dataCliente.id,
        id_motivo: motivoSeleccionado.id_motivo,
        estado: 192,
        prioridad: datosReclamo.prioridad,
        detalle: datosReclamo.detalle,
        fecha_abierto: datosReclamo.fecha_abierto,
        direccion_entrega: direccionSeleccionada.id,
        usuario: localStorage.getItem("user"),
        uuid: nroReclamo,
        a_cargo: datosReclamo.a_cargo,
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El reclamo se guardó exitosamente !");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el reclamo");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    usuarioMail &&
      usuarioMail.length > 0 &&
      usuarioMail.map((usuario) => {
        // Verificar que el usuario tiene una dirección de correo electrónico
        if (usuario.email_usuario) {
          // Llamar a la función para enviar correos electrónicos
          enviarmails(
            usuario.email_usuario,
            "Nuevo incidente técnico",
            ` <p>Hay un nuevo incidente técnico pendiente:
            <br><b>Del cliente : </b>${dataCliente.id} - ${dataCliente.razon_social}</br>,
            <br><b>Con motivo : </b>${motivoSeleccionado.descripcion_motivo}</br>,
            <br><b>Prioridad : </b>${datosReclamo.valor}</br>,
            <br><b>Dirección : </b> ${direccionSeleccionada.direccion_entrega}</br>,       
            <b>A cargo de: </b> ${datosReclamo.valor_a_cargo === null ? 'No aplica' : datosReclamo.valor_a_cargo}
            <br><b>Con el detalle : </b>${datosReclamo.detalle}</br>.</p>`
          );
        } else {
          console.warn(
            `El usuario con ID ${usuario.idreclamos_config} no tiene una dirección de correo electrónico válida.`
          );
        }
      });
    // PARA LIMPIAR LOS DATOS
    handleClickOpenCrear();
    setMostrarNro(true);
  };

  const handleClose = () => {
    if (props.vengo === "PedidosCli") {
      props.alta(false);
      props.tabla(true);
    } else {
      props.addReclamo(false);
      props.tabla(true);
    }
  };
  //Estados para pantalla
  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  // console.log("datos Cliente", dataCliente);

  const almacenar = (e) => {
    setDatosReclamo({
      ...datosReclamo,
      [e.target.name]: e.target.value,
    });
  };

  // Esta función maneja el cambio en el Select de prioridad
  const almacenarPrioridad = (event) => {
    const selectedPrioridadId = event.target.value;
    const selectedPrioridad = prioridad.find(
      (p) => p.id === selectedPrioridadId
    );

    // Actualizar el estado con la prioridad seleccionada y su valor
    setDatosReclamo((prevState) => ({
      ...prevState,
      prioridad: selectedPrioridadId,
      valor: selectedPrioridad ? selectedPrioridad.valor : null, // Establecer el valor seleccionado o null si no se encuentra el cargo
    }));
  };

  // Esta función maneja el cambio en el Select de cargo
  const almacenarCargo = (event) => {
    const selectedCargoId = event.target.value;
    const selectedCargo = cargo.find((c) => c.id === selectedCargoId);

    // Actualizar el estado con la prioridad seleccionada y su valor
    setDatosReclamo((prevState) => ({
      ...prevState,
      a_cargo: selectedCargoId,
      valor_a_cargo: selectedCargo ? selectedCargo.valor : null, // Establecer el valor seleccionado o null si no se encuentra el cargo
    }));
  };

  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const [limpiarAutocomplete, setLimpiarAutocomplete] = useState(false);
  // Función para activar la limpieza del autocompletado
  const activarLimpieza = () => {
    setLimpiarAutocomplete(true);

    // Después este tiempo, cambiar el valor de limpiarAutocomplete a false
    setTimeout(() => {
      setLimpiarAutocomplete(false);
    }, 500); // 1000 milisegundos = 1 segundo
  };

  //console.log("DATOSRECLAMO", datosReclamo);

  //console.log("prioridad: ", prioridad);
 // console.log("cargo: ", cargo);
  const [openCloseAdj, setOpenCloseAdj] = useState(false); //ADJUNTAR COMPROBANTE

  const handleOpenCloseAdj = (value) => {
    setOpenCloseAdj(value);
  };

//  console.log("DATA CLIENTE",dataCliente);

  return (
    <>
      <>
        <form onSubmit={guardarReclamo}>
          <Box
            border={0.5}
            borderColor={grey[300]}
            backgroundColor="#F4F6F6"
            p={2}
            py={2}
            mt={1}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4}>
                {limpiarAutocomplete === true ? (
                  <TextField fullWidth size="medium" disabled label="Motivo" />
                ) : (
                  <Autocomplete
                    size="medium"
                    focused
                    disableClearable
                    required
                    id="motivo"
                    noOptionsText={"Motivo INEXISTENTE"}
                    options={motivosEmergencia}
                    autoHighlight
                    getOptionLabel={(motivo) =>
                      motivo.id_motivo + "-" + motivo.descripcion_motivo
                    }
                    onChange={(event, value) => {
                      setMotivoSeleccionado(value);
                      setMostrarNro(false);
                      obtenerUltimaRow();
                    }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        key={option.id}
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.id_motivo} - {option.descripcion_motivo}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        style={style}
                        focused
                        required
                        size="medium"
                        placeholder="Tipee para buscar..."
                        {...params}
                        label="Motivo"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                <FormControl style={style} focused required>
                  <InputLabel>Prioridad</InputLabel>
                  <Select
                    name="prioridad"
                    value={datosReclamo.prioridad}
                    label="Prioridad"
                    // onChange={almacenar}
                    onChange={almacenarPrioridad}
                  >
                    {prioridad
                      ? prioridad.map((prioridad) => (
                          <MenuItem key={prioridad.id} value={prioridad.id}>
                            {prioridad.valor}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  style={style}
                  label="Fecha"
                  name="fecha"
                  size="medium"
                  type="date"
                  focused
                  required
                  fullWidth
                  inputProps={{
                    readOnly: true,
                  }}
                  value={moment(datosReclamo.fecha_abierto).format(
                    "YYYY-MM-DD"
                  )}
                  onChange={almacenar}
                />
              </Grid>
              <Grid item xs={6}>
                {limpiarAutocomplete === true ? (
                  <TextField
                    fullWidth
                    size="medium"
                    disabled
                    label="Dirección de Entrega"
                  />
                ) : (
                  <Autocomplete
                    size="medium"
                    focused
                    disableClearable
                    required
                    id="direccion"
                    noOptionsText={"dirección INEXISTENTE"}
                    options={direcciones}
                    autoHighlight
                    getOptionLabel={(direccion) => `${direccion.direccion_entrega} (${direccion.nombre_direccion})`}
                    onChange={(event, value) => {
                      setDireccionSeleccionada(value);
                    }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        key={option.id}
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {/* {option.id} -  */}
                        {option.direccion_entrega} ({option.nombre_direccion})
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        style={style}
                        focused
                        required
                        size="medium"
                        placeholder="Tipee para buscar..."
                        {...params}
                        label="Dirección de Entrega"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                )}
              </Grid>

              <Grid item xs={6}>
                <FormControl style={style} focused>
                  <InputLabel>A Cargo De</InputLabel>
                  <Select
                    name="a_cargo"
                    value={datosReclamo.a_cargo}
                    label="A Cargo De"
                    // onChange={almacenar}
                    onChange={almacenarCargo}
                  >
                    {cargo
                      ? cargo.map((cargo) => (
                          <MenuItem key={cargo.id} value={cargo.id}>
                            {cargo.valor}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  style={style}
                  label="Detalles"
                  name="detalle"
                  size="medium"
                  placeholder="..."
                  inputProps={{
                    maxLength: 500,
                  }}
                  focused
                  fullWidth
                  value={datosReclamo.detalle}
                  onChange={almacenar}
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item xs={4} py={1} mb={1}>
                <Button
                  onClick={() => setOpenCloseAdj(!openCloseAdj)}
                  variant="outlined"
                  size="medium"
                  startIcon={<AttachFileIcon />}
                >
                  ADJUNTAR DOCUMENTACIÓN
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              mt={2}
            >
              <Button variant="contained" onClick={handleClose} color="error">
                Volver
              </Button>
              {mostrarNro ? (
                <label style={{ fontSize: "20px" }}>
                  Su número de reclamo es <b>{numero_reclamo}</b>
                </label>
              ) : null}
              <Button variant="contained" type="submit" color="success">
                Guardar Reclamo
              </Button>
            </Grid>
          </Box>
        </form>
      </>
      <>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={openSnack}
            autoHideDuration={1500}
            onClose={handleCloseSnack}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseSnack}
              severity="success"
              sx={{ width: "100%" }}
              style={{ backgroundColor: colormensaje, color: "black" }}
            >
              {mensaje}
            </Alert>
          </Snackbar>
        </Stack>
      </>
      {openCloseAdj ? ( // ADJUNTAR COMPROBANTES
        <FileUploadReclamo
          fileId={nroReclamo}
          openclose={true}
          mostrar={true}
          onClose={() => handleOpenCloseAdj(false)} // Proporciona una función de cierre
        />
      ) : null}
    </>
  );
}

export default NuevoReclamo;
