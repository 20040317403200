import Navbar from "../Navbar";
import React, { useEffect, useState, useMemo } from "react";
import { UrlApi } from "../../services/apirest";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Grid,
  Typography,
  Stack,
} from "@mui/material";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";

// ICONOS
import SendIcon from "@mui/icons-material/Send";

// EXPORTAR DATOS
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv";

//Notification
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Romaneo_envases_rebalanceados() {
  const [romaneo_envases, setRomaneo_envases] = useState();
  const [datosRomaneoEnvases, setDatosRomaneoEnvases] = useState({
    id_tipo_reparacion: null,
    id_tipo: null,
    nro_envase: null,
    id_mat_fabricante: null,
    lote: "",
    fecha_fabricacion: "",
    placa_relieve: 2,
    nro_placa: null,
    id_mot_baja: null,
    fecha_reparacion: "",
    id_pertenencia: null,
    usuario: localStorage.getItem("user"),
  });

  console.log("DATOS NO SE QUE ", datosRomaneoEnvases);
  const [tabla, setTabla] = useState(true);
  const [update, setUpdate] = useState(false);

  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");

  //funcion para obtener a los romaneo
  const obtenerRomaneoEnvases = () => {
    fetch(UrlApi + "/romaneo_envases/rebalanceados")
      .then((data) => data.json())
      .then((data) => setRomaneo_envases(data));
  };

  // ---------------------------------------------------------------

  //funcion que espera los cambios de la funcion de obtener a los romaneo leyendas
  useEffect(() => {
    obtenerRomaneoEnvases();
  }, [tabla]);

  // FUNCION QUE SE EJECUTA CADA VEZ QUE HAY UN CAMBIO EN LA BASE DE DATOS
  // SIRVE PARA QUE LA TABLA MOSTRADA SE ACTUALICE EN TIEMPO REAL
  useEffect(() => {
    if (update) obtenerRomaneoEnvases();
  }, [update]);


  //*************************************************************************************************************************************/

  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
    setUpdate(false);
  };

  //*************************************************************************************************************************************/

  const columns = useMemo(
    () => [
      // { header: "ID", accessorKey: "id_envase", size: 50 },
      { header: "N°.Envase", accessorKey: "nro_envase", size: 106 },
      { header: "N°.Acuñado", accessorKey: "nro_acuniado", size: 100 },
      {
        header: "Leyenda Chapa Rectangular",
        accessorKey: "ley_placa_rect",
        size: 140,
      },
      {
        header: "N° Chapa Rectangular",
        accessorKey: "nro_placa_rect",
        size: 132,
      },
      {
        header: "Fabricante",
        accessorKey: "descripcion_fabricante",
        size: 115,
      },
      {
        header: "Año Fabricación",
        accessorKey: "fecha_fabricacion",
        size: 140,
      },
      { header: "N° Chapa Circular", accessorKey: "nro_placa", size: 145 },
      { header: "E", accessorKey: "id_estado", size: 10, enableHiding: true },
      { header: "R", accessorKey: "rebalanceo", size: 10, enableHiding: true },
    ],
    []
  );

  const muiSearchTextFieldProps = {
    // para el buscar
    placeholder: "Buscar...",
    sx: { minWidth: "18rem" },
    variant: "outlined",
    color: "success",
    size: "small",
  };

  // ---------------------------------------------------------------
  //  EXPORTAR DATOS
  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    filename: "Rebalanceados",
    headers: columns.map((c) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  //*************************************************************************************************************************************/

  // ESTILO PARA LA TABLA

  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)" //background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //override to make tooltip font size larger
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const displayColumnDefOptions = {
    "mrt-row-actions": {
      muiTableHeadCellProps: {
        align: "center",
        sx: {
          fontSize: 14,
          fontStyle: "italic",
        },
      },
      size: 100,
    },
  };

  const initialState = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
    columnVisibility: { id_estado: false, rebalanceo: false },
  };

  const [activarDialog, setActivarDialog] = useState(false);
  const handleDesactivarDialog = () => {
    setActivarDialog(false);
  };

  // RETURN
  return (
    <div>
      <Navbar />
      <Container maxWidth="xl" mt={1}>
        {romaneo_envases && tabla ? (
          <Box mt={2} mb={4}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
                enableGrouping // para permitir agrupar x estado x ej
                displayColumnDefOptions={displayColumnDefOptions}
                layoutMode="grid"
                muiTableHeadCellProps={{
                  align: "left", // los titulos de la tabla
                }}
                muiTableBodyCellProps={{
                  align: "left", //el contenido de la tabla
                }}
                enableRowSelection
                enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
                enableColumnFilters={false} //desactiva filtros x  columna
                enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
                enableDensityToggle={false} //
                localization={MRT_Localization_ES} //idioma
                enableColumnDragging={false} //para arrastrar columnas
                initialState={initialState}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                columns={columns}
                data={romaneo_envases} //con lo q la completas (esto cambia siempre)
                enableStickyFooter
                positionActionsColumn="last" // posicion de la columna de acciones al final
                // ACCIONES
                renderTopToolbarCustomActions={({ table }) => {
                  const handleActivarDialog = () => {
                    setActivarDialog(true);
                  };
                  const handleActivar = () => {
                    table.getSelectedRowModel().flatRows.map((row) => {
                      // ACTIVAR RELACION
                      const guardarParaHabilitar = async () => {
                        console.log("Estoy por guardar");
                        await fetch(
                          UrlApi + "/romaneo_envases/" + row.original.id_envase,
                          {
                            method: "PUT",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify({
                              id_estado: 151,
                              rebalanceo: null,
                            }),
                          }
                        )
                          .then((res) => {
                            if (!res.err) {
                              setOpenSnack(true);
                              setColorMensaje("#D4EFDF");
                              setMensaje('Envio a "Para Habilitar" exitoso!');
                              setUpdate(true);
                              setActivarDialog(false);
                            } else {
                              setOpenSnack(false);
                              setColorMensaje("#F1948A");
                              setMensaje("Error al Enviar");
                            }
                          })
                          .catch((err) => {
                            console.log(err.message);
                          });
                      };
                      guardarParaHabilitar();
                    });
                  };

                  return (
                    <div style={{ display: "flex", gap: "0.5rem" }}>
                      <Grid container>
                        <Typography variant="poster" fontSize={26}>
                          <span style={{ color: "#247af2" }}>
                            <b>Administrar</b>
                          </span>{" "}
                          Rebalanceo
                        </Typography>
                      </Grid>
                      <Grid container>
                        <Grid xs={6} mr={1}>
                          <Button
                            disabled={
                              !table.getIsSomeRowsSelected() &&
                              !table.getIsAllRowsSelected()
                            }
                            //SOLO EXPORTAR FILAS SELECCIONADAS
                            onClick={() =>
                              handleExportRows(table.getSelectedRowModel().rows)
                            }
                            startIcon={<FileDownloadIcon />}
                            variant="contained"
                            size="small"
                          >
                            Exportar Filas Seleccionadas
                          </Button>
                        </Grid>
                        <Grid xs={5}>
                          <Button
                            color="success"
                            disabled={
                              !table.getIsSomeRowsSelected() &&
                              !table.getIsAllRowsSelected()
                            }
                            // ACTIVAR
                            onClick={handleActivarDialog}
                            startIcon={<SendIcon />}
                            variant="contained"
                            size="small"
                          >
                            Enviar a "Para Habilitar"
                          </Button>
                        </Grid>
                      </Grid>

                      <Dialog open={activarDialog} fullWidth>
                        <DialogTitle marginLeft={3}>
                          <b>¿Enviar Envases a "Para Habilitar"?</b>
                        </DialogTitle>
                        <DialogContent>
                          <Container maxWidth="xl">
                            <Stack direction="row">
                              <Grid
                                container
                                direction={{ xs: "column", sm: "row" }}
                              >
                                <Grid item xs={8}>
                                  <Button
                                    variant="contained"
                                    color="error"
                                    onClick={handleDesactivarDialog}
                                  >
                                    Cancelar
                                  </Button>
                                </Grid>
                                <Grid item xs={4}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="success"
                                    onClick={handleActivar}
                                  >
                                    Enviar
                                  </Button>
                                </Grid>
                              </Grid>
                            </Stack>
                          </Container>
                        </DialogContent>
                      </Dialog>
                    </div>
                  );
                }}
              />
            </ThemeProvider>
          </Box>
        ) : null}

        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={openSnack}
            autoHideDuration={1500}
            onClose={handleCloseSnack}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Alert
              onClose={handleCloseSnack}
              severity="success"
              sx={{ width: "100%" }}
              style={{
                backgroundColor: colormensaje,
                color: "black",
              }}
            >
              {mensaje}
            </Alert>
          </Snackbar>
        </Stack>
      </Container>
    </div>
  );
}

export default Romaneo_envases_rebalanceados;