import React, { useEffect, useState } from "react";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import {
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { UrlApiEnvasado } from "../../../services/apirest";

export default function BasicPie(props) {
  const [drawerOpen, setDrawerOpen] = useState(props.drawerOpen);

  const [datosGrafico, setDatosGrafico] = useState([]);

  const obtenerDatosGrafico = async (endpoint, label) => {
    try {
      const response = await fetch(`${UrlApiEnvasado}${endpoint}`);
      const data = await response.json();

      console.log(`Nuevos datos del gráfico (${label}):`, data);

      setDatosGrafico((prevData) => [...prevData, { label, data }]);
    } catch (error) {
      console.error(`Error al obtener datos del gráfico (${label}):`, error);
    }
  };

  useEffect(() => {
    // Obtener (Paso el parametro de la URL y el "Label")
    obtenerDatosGrafico("/envasado/movil/mbenz", "Mercedes Benz");
    obtenerDatosGrafico("/envasado/movil/hyundai", "Hyundai");
    obtenerDatosGrafico("/envasado/movil/ford", "Ford");
    obtenerDatosGrafico("/envasado/movil/chevrolet", "Chevrolet");
    obtenerDatosGrafico("/envasado/movil/inactivos", "Inactivos");
  }, []); // Se ejecuta solo una vez al montar el componente

  // Organizar datos
  // PARA EL CHART
  const datosParaGrafico = datosGrafico.map((conjunto) => ({
    label: conjunto.label,
    value: conjunto.data.length, // Obtener la cantidad de elementos en el conjunto
  }));

  // PARA LA TABLA
  const datosParaTabla = datosGrafico
    .filter((conjunto) => conjunto.label !== "Inactivos") // Excluir conjunto 'Inactivos'
    .flatMap((conjunto) =>
      conjunto.data.map((item) => ({
        label: conjunto.label,
        name: item.name,
        patente: item.patente,
      }))
    );

 // ----------------------------------------------

  // SUCURSALES
  const [sucursales, setSucursales] = useState();

  const obtenerSucursales = () => {
    fetch(UrlApiEnvasado + "/envasado/sucursales")
      .then((data) => data.json())
      .then((data) => setSucursales(data));
  };

  useEffect(() => {
    obtenerSucursales();
  }, []);

  console.log("Sucursales: ", sucursales);

  // ----------------------------------------------

    const [sucursalSeleccionada, setSucursalSeleccionada] = useState(null);

    const handleSucursalChange = (sucursal) => {
      setSucursalSeleccionada(sucursal);
      // setChoferSeleccionado(null);
      // setMovilSeleccionado(null);
  
      // obtenerChoferes();
      // obtenerMoviles();
    };

  // **************************************************************************

  useEffect(() => {
    setDrawerOpen(props.drawerOpen);
  }, [props.drawerOpen]);

  // ---------------------------------------------------------------

  // DRAWER
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    props.handleDrawer();

    console.log("EJECUTO toggleDrawer");
  };

  const list = (anchor) => (
    <>
      <Box
        sx={{
          width: anchor === "top" || anchor === "bottom" ? "auto" : 350,
          textAlign: "center",
        }}
        role="presentation"
        onClose={toggleDrawer("right", false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <Typography
          variant="h4"
          gutterBottom
          style={{
            backgroundColor: "#1976D2",
            color: "white",
            padding: "11px",
            fontWeight: "bold",
          }}
        >
          OPCIONES
        </Typography>
      </Box>

      
      <Box
        sx={{
          display: "flex",
          flexDirection: "column", // Establece la dirección de flexión en columna
          alignItems: "center", // Centra los elementos horizontalmente
          width: anchor === "top" || anchor === "bottom" ? "auto" : 350,
          textAlign: "center",
        }}
        role="presentation"
        onClose={toggleDrawer("right", false)}
      >
        {/* <Divider
          variant="middle"
          style={{ marginBottom: "14px", width: "90%" }}
        >
          Marca
        </Divider> */}
     <Autocomplete
            fullWidth
            disablePortal
            // options={sucursales || []}
            getOptionLabel={(option) => option.name || ""}
            // value={sucursalSeleccionada}
            // onChange={(_, sucursal) => handleSucursalChange(sucursal)}
            renderInput={(params) => (
              <TextField {...params} label="Marca" size="small" />
            )}
            disabled
            style={{ marginBottom: "10px"}}
          />
        
        {/* <Divider
          variant="middle"
          style={{ marginBottom: "14px", width: "90%" }}
        >
          Sucursal
        </Divider> */}
             <Autocomplete
            fullWidth
            disablePortal
            options={sucursales || []}
            getOptionLabel={(option) => option.name || ""}
            value={sucursalSeleccionada}
            onChange={(_, sucursal) => handleSucursalChange(sucursal)}
            renderInput={(params) => (
              <TextField {...params} label="Sucursal" size="small" />
            )}
            // disabled={!selectsHabilitados}
            style={{ marginBottom: "10px" }}
          />
        {/* <Divider
          variant="middle"
          style={{ marginBottom: "14px", width: "90%" }}
        >
          Solo Activos
        </Divider> */}
               <FormControlLabel
                  control={
                    <Checkbox
                      // checked={mostrarSegundosDatos}
                      // onChange={handleMostrarSegundosDatos}
                      color="primary"
                    />
                  }
                  label="Solo Activos"
                  style={{
                    marginLeft: "10px",
                    width: "85%", // Ancho menos el espacio para el margen izquierdo y derecho
                    borderRadius: "0px 0px 6px 6px", // Bordes redondeados
                    paddingLeft: "8px", // Espacio interno
                    paddingBottom: "2px",
                  }}
                />
        </Box>
    </>
  );

  // ---------------------------------------------------------------

  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <PieChart
          series={[
            {
              arcLabel: (item) => `${item.label} (${item.value})`,
              arcLabelMinAngle: 45,
              data: datosParaGrafico,
              highlightScope: { faded: "global", highlighted: "item" },
              faded: { innerRadius: 30, additionalRadius: -30, color: "gray" },
              // innerRadius: 30,
            },
          ]}
          sx={{
            [`& .${pieArcLabelClasses.root}`]: {
              fill: "white",
              fontWeight: "bold",
            },
          }}
          slotProps={{
            legend: {
              direction: "column",
              position: { vertical: "middle", horizontal: "right" },
              padding: -35,
            },
          }}
          width={750}
          height={500}
        />
        {/* TABLA */}
        {datosParaTabla.length > 0 ? (
          <>
            <div
              style={{
                marginLeft: "20px",
                overflowY: "auto",
                maxHeight: "500px",
                width: "40%",
                position: "relative",
              }}
            >
              <div
                style={{
                  overflowX: "auto",
                  position: "sticky",
                  top: "0",
                  zIndex: "1",
                }}
              >
                <table
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid black",
                    width: "100%",
                  }}
                >
                  <thead>
                    <tr
                      style={{ backgroundColor: "#1976D2", color: "#FFFFFF" }}
                    >
                      <th
                        style={{
                          padding: "8px",
                          width: "50%",
                          borderRight: "1px solid black",
                        }}
                      >
                        NOMBRE
                      </th>
                      <th style={{ padding: "8px", width: "50%" }}>PATENTE</th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div style={{ overflowX: "auto", width: "100%" }}>
                <table
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid black",
                    width: "100%",
                  }}
                >
                  <tbody>
                    {datosParaTabla.map((item, index) => (
                      <tr
                        key={index}
                        style={{
                          backgroundColor: "lightgreen",
                        }}
                      >
                        <td
                          style={{
                            borderBottom: "1px solid black",
                            borderLeft: "1px solid black",
                            borderRight: "1px solid black",
                            padding: "8px",
                            width: "50%",
                          }}
                        >
                          {item.name}
                        </td>
                        <td
                          style={{
                            borderBottom: "1px solid black",
                            borderLeft: "1px solid black",
                            borderRight: "1px solid black",
                            padding: "8px",
                            backgroundColor: "white",
                            width: "50%",
                          }}
                        >
                          {item.patente}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        ) : (
          <p>
            {datosParaTabla.length === 0
              ? "No hay datos disponibles."
              : "Cargando datos..."}
          </p>
        )}
      </div>

      <div>
        <React.Fragment>
          <Drawer
            anchor="right"
            open={drawerOpen}
            onClose={toggleDrawer("right", false)}
            ModalProps={{
              style: { zIndex: 1300 },
            }}
          >
            {list("right")}
          </Drawer>
        </React.Fragment>
      </div>
    </>
  );
}
