import React, { useState, useEffect } from "react";
import { UrlApi } from "../../../../services/apirest";
import {
  Container,
  Grid,
  Box,
  TextField,
  DialogTitle,
  Button,
  InputAdornment,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Stack,
  IconButton,
} from "@mui/material";
import moment from "moment";
import { grey } from "@mui/material/colors";
import { v4 as uuidv4 } from "uuid";
import Cromatografia from "./Cromatografia";
import EnsayoFisicoGLP from "./EnsayoFisicoGLP";
import Compuestos from "./Compuestos";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

//Imports Manejo de Errores
// import Notify from "../../../Notify";
// import Alerta from "../../../Alerta";

// LOG EVENTOS
import { logEventos } from "../../../../services/services";

// TAB
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// ICONOS
import PersonIcon from "@mui/icons-material/Person";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PinIcon from "@mui/icons-material/Pin";

// Para el Snackbar
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function AnalisisCalidad(props) {
  // ---------------------- PROBANDO ----------------------
  const [ultimaMuestra, setUltimaMuestra] = useState(null);

  // OBTENER ULTIMA MUESTRA
  //Funcion para traer los analisis a la tabla
  const obtenerUltimaMuestra = () => {
    fetch(UrlApi + "/propelente_analisis/ultima_muestra")
      .then((data) => data.json())
      .then((data) => setUltimaMuestra(data));
  };

  useEffect(() => {
    obtenerUltimaMuestra();
  }, []);

  console.log("ULTIMA MUESTRA: ", ultimaMuestra);

  // --------------------------------------------------------

  const [datosAnalisis, setDatosFormulario] = useState({
    nro_muestra: null,
    id_tanque: "",
    id_producto: null,
    id_aspecto: "",
    id_operador_fisico: "",
    id_operador_cromatografico: "",
    uuid_analisis: "",
    fecha: "",
    hora: "",
    id_receptor: "",
    id_olor_ingreso: "",
    cantidad_muestras_testigo: "",
    usuario: "",
    observaciones: "",
  });
  const [componentesProducto, setComponentesProducto] = useState(null);
  const [mostrarAnalisis, setMostrarAnalisis] = useState(true);
  const [uuid, setuuid] = useState(uuidv4()); //id unico
  // console.log(uuid);

  // const [analisis, setAnalisis] = useState(props.analisis)
  // const [tablaAnalisis, setTablaAnalisis] = useState(props.tabla)

  // VOLVER AL MENU ANTERIOR
  const handleVolver = () => {
    props.handleCerrarAnalisis(true);
    // --------- PRUEBA PARA LLEVARLO COMO PROPS ---------
    // setHabilitarResiduos(false)
    // setHabilitarHumedad(false)
  };

  //DESPLEGABLES: Signos (<, >, =)
  const [desplegables, setDesplegables] = useState();

  const ObtenerDesplegables = () => {
    fetch(UrlApi + "/desplegables/propelentesSignos")
      .then((data) => data.json())
      .then((data) => setDesplegables(data));
  };

  // FETCH PARA OBTENER LOS PRODUCTOS
  const [productos, setProductos] = useState();

  const obtenerProductos = () => {
    fetch(UrlApi + "/propelente_productos/activos") //fetch con la urp de api que está en services/apirest + la ruta
      .then((data) => data.json()) //mando los datos que recibo en json
      .then((data) => setProductos(data)); //seteo productos
  };

  // OBTENER LOS COMPONENTES SEGUN EL PRODUCTO ELEGIDO
  const obtenerComponentesProducto = async () => {
    const data = await fetch(
      UrlApi + "/componentes_productos/activos/" + datosAnalisis.id_producto
    );
    const componentesProductoData = await data.json();
    setComponentesProducto(componentesProductoData);
  };

  // console.log(componentesProducto);

  useEffect(() => {
    obtenerComponentesProducto();
  }, [datosAnalisis.id_producto]);

  // DESPLEGABLES: OLORES
  const [olores, setOlores] = useState();

  const obtenerOlores = () => {
    fetch(UrlApi + "/desplegables/analisisPropelentesOlor") //fetch con la urp de api q esta en services/apirest + la ruta
      .then((data) => data.json()) //mando los datos que recibo en json
      .then((data) => setOlores(data));
  };

  // DESPLEGABLES: ASPECTOS
  const [aspectos, setAspectos] = useState([{}]);

  const obtenerAspectos = () => {
    fetch(UrlApi + "/desplegables/analisisPropelentesAspecto") //fetch con la urp de api q esta en services/apirest + la ruta
      .then((data) => data.json()) //mando los datos que recibo en json
      .then((data) => setAspectos(data));
  };

  // PERSONAL: OPERADORES FISICOS
  const [operadoresFisicos, setOperadoresFisicos] = useState();

  const obtenerOperadoresFisicos = () => {
    fetch(UrlApi + "/propelente_personal/operadores_fisicos") //fetch con la urp de api q esta en services/apirest + la ruta
      .then((data) => data.json()) //mando los datos que recibo en json
      .then((data) => setOperadoresFisicos(data));
  };

  // PERSONAL: OPERADORES CROMATOGRAFICOS
  const [operadoresCromatograficos, setOperadoresCromatograficos] = useState();

  const obtenerOperadoresCromatograficos = () => {
    fetch(UrlApi + "/propelente_personal/operadores_cromatograficos") //fetch con la urp de api q esta en services/apirest + la ruta
      .then((data) => data.json()) //mando los datos que recibo en json
      .then((data) => setOperadoresCromatograficos(data));
  };

  // PERSONAL: RECEPTORES
  const [receptores, setReceptores] = useState();

  const obtenerReceptores = () => {
    fetch(UrlApi + "/propelente_personal/receptores") //fetch con la urp de api q esta en services/apirest + la ruta
      .then((data) => data.json()) //mando los datos que recibo en json
      .then((data) => setReceptores(data));
  };

  // TANQUES DE ORIGEN (FIJOS)
  const [tanquesfijos, setTanquesfijos] = useState();

  const obtenerTanquesfijos = () => {
    fetch(UrlApi + "/tanquesfijos") //fetch con la urp de api q esta en services/apirest + la ruta
      .then((data) => data.json()) //mando los datos que recibo en json
      .then((data) => setTanquesfijos(data));
  };

  // ENSAYO FISICO COMPUESTOS
  const [efCompuestos, setEfCompuestos] = useState();

  const obtenerEfCompuestos = () => {
    fetch(UrlApi + "/ef_compuestos") //fetch con la urp de api que está en services/apirest + la ruta
      .then((data) => data.json()) //mando los datos que recibo en json
      .then((data) => setEfCompuestos(data));
  };

  /************************************************************************************************/
  // Estados para manejo de errores
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
  const [colormensaje, setColorMensaje] = useState(""); //define el color que tendrá la notificación
  const [mensaje, setMensaje] = useState(""); //mensaje que mostrará la notif
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
  const [mensajeAlerta, setMensajeAlerta] = useState(""); //mensaje que contendrá el componente "alerta"
  const [finalizar, setFinalizar] = useState(); //finalizar === 1 lo manda como prop el componente "notify" e indica que la transaccion ha finalizado y debe ejecutarse la funcion para cerrar
  const [severityAlerta, setSeverityAlerta] = useState(""); //define severidad de la alerta (success, error o warning)
  const [severitySnack, setSeveritySnack] = useState(""); //define severidad del snackbar (notificacion)

  /************************************************************************************************/

  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
    handleVolver();
  };

  /************************************************************************************************/

  // FUNCION PARA GUARDAR
  async function guardarAnalisis(e) {
    logEventos("Guardar analisis", "Analisis Propelentes", "Guardar analisis de calidad", localStorage.getItem("user"))

    e.preventDefault();
    const response = await fetch(UrlApi + "/propelente_analisis", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        nro_muestra: datosAnalisis.nro_muestra,
        id_tanque: datosAnalisis.id_tanque,
        id_producto: datosAnalisis.id_producto,
        id_aspecto: datosAnalisis.id_aspecto,
        id_operador_fisico: datosAnalisis.id_operador_fisico,
        id_operador_cromatografico: datosAnalisis.id_operador_cromatografico,
        uuid_analisis: uuid,
        fecha: datosAnalisis.fecha,
        hora: datosAnalisis.hora,
        id_receptor: datosAnalisis.id_receptor,
        id_olor_ingreso: datosAnalisis.id_olor_ingreso,
        cantidad_muestras_testigo: datosAnalisis.cantidad_muestras_testigo,
        usuario: localStorage.getItem("user"),
        observaciones: datosAnalisis.observaciones,
      }),
    });
    console.log(response);
    if (response.ok === false) {
      const error = response && response.json();
      setError(error);
      alert("Error en la API");
    } else {
      try {
        //TRATO DE PASAR RESPONSE A JSON
        const json = await response.json();
        if (json.message) {
          if (json.message === "OK") {
            console.log("OK");
            setNotificacion(true);
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setMensaje("Guardado");
            setSeveritySnack("success");
            guardarCromatografia(e);
            guardarEnsayoFisico(e);
            guardarCompuestos(e);
          } else {
            // console.log("res en api incorrecto. Revisar que sea 'OK'")
            alert("corregir mensaje api");
          }
        } else if (json.sqlMessage) {
          json && setError(json);
          console.log(json);
          alert("Error SQL");
          setMensajeAlerta(
            "SQL erroneo (" + json.code + " Errno: " + json.errno + " )"
          );
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          console.log(
            "Guardado. Advertencia: Actualizar respuesta de api. JSON invalido."
          );
          setNotificacion(true);
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("Guardado");
          setSeveritySnack("success");
          guardarCromatografia(e);
          guardarEnsayoFisico(e);
          guardarCompuestos(e);
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      }
    }
  }

  const almacenar = (e) => {
    setDatosFormulario({
      ...datosAnalisis,
      [e.target.name]: e.target.value,
    });
  };

  const guardarCromatografia = async (e) => {
    // console.log("Estoy por guardar");
    console.log("Guardo Cromatografía");
    e.preventDefault();
    await fetch(UrlApi + "/cromatografia", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(cromatografia),
    });
  };

  const guardarEnsayoFisico = async (e) => {
    console.log("Guardo Ensayo Fisico");
    e.preventDefault();
    await fetch(UrlApi + "/ensayo_fisico", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(ensayoFisico),
    });
  };

  const guardarCompuestos = async (e) => {
    console.log("Guardo Compuestos");
    e.preventDefault();
    await fetch(UrlApi + "/ef_compuestos", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(compuestos),
    });
  };

  // AL CAMBIAR DE PRODUCTO SE REINICIA EL VALOR DE COMPONENTES_PRODUCTOS
  useEffect(() => {
    setComponentesProducto(null);
  }, [datosAnalisis.id_producto]);

  // ACA ABAJO TENDRIA QUE ESTAR EL POST PARA EL ANALISIS
  //  cosas nuevas (borrar si no funciona)
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //-------------------------- CROMATOGRAFIA -------------------------------------------- //

  const [cromatografia, setCromatografia] = useState();

  useEffect(() => {
    // INICIO COMPONENTES PRODUCTO CON SUS DATOS VACIOS
    const init =
      componentesProducto &&
      uuid &&
      componentesProducto.map((item) => ({
        id_componente: item.id_componente,
        valor: 0,
        uuid_cromatografia: uuid,
      }));
    setCromatografia(init);
  }, [componentesProducto]);

  // componentesProducto&&console.log(componentesProducto)

  // FUNCION QUE CREO EN EL COMPONENTE PADRE Y QUE LLEVO A EL COMPONENTE HIJO PARA MANTENER LOS DATOS
  const updateItemsCromatografia = (prop, event, index) => {
    const old = cromatografia[index];
    const updated = {
      ...old,
      // CON ESTA LINEA LE DIGO QUE SI EL VALOR ES === "" SE CONVIERTA A LO QUE LE ESCRIBO (NULL EN ESTE CASO)
      [prop]: event.target.value === "" ? null : event.target.value,
      id_componente: componentesProducto[index].id_componente,
    };
    const clone = [...cromatografia];
    clone[index] = updated;
    setCromatografia(clone);
  };

  //----------------------- ENSAYO -------------------------------------magali ---------- diosa //

  // ITEMS ENSAYO FISICO
  const [itemsEnsayoFisico, setItemsEnsayoFisico] = useState();

  const obtenerItemsEnsayoFisico = () => {
    fetch(UrlApi + "/items_ef/activos") //fetch con la urp de api que está en services/apirest + la ruta
      .then((data) => data.json()) //mando los datos que recibo en json
      .then((data) => setItemsEnsayoFisico(data)); //seteo
  };

  const [ensayoFisico, setEnsayoFisico] = useState();

  useEffect(() => {
    // INICIO CON SUS DATOS VACIOS
    const init =
      itemsEnsayoFisico &&
      uuid &&
      itemsEnsayoFisico.map((item) => ({
        id_item: item.id_item,
        valor: 0,
        visible: 1,
        uuid_analisis: uuid,
      }));
    setEnsayoFisico(init);
  }, [itemsEnsayoFisico]);

  // FUNCION QUE CREO EN EL COMPONENTE PADRE Y QUE LLEVO A EL COMPONENTE HIJO PARA MANTENER LOS DATOS
  const updateItemsEnsayoFisico = (prop, event, index) => {
    console.log(prop);
    console.log(event);
    const old = ensayoFisico[index];
    const updated = {
      ...old,
      // CON ESTA LINEA LE DIGO QUE SI EL VALOR ES === "" SE CONVIERTA A LO QUE LE ESCRIBO (NULL EN ESTE CASO)
      [prop]: event.target.value === "" ? null : event.target.value,
      id_item: itemsEnsayoFisico[index].id_item,
    };
    const clone = [...ensayoFisico];
    clone[index] = updated;
    setEnsayoFisico(clone);
  };

  console.log("Ensayo Fisico: ", ensayoFisico);

  //-------------------------- COMPUESTOS -------------------------------------------- //

  // ITEMS PROPELENTE COMPUESTO EF
  const [itemsPropelenteCompEF, setItemsPropelenteCompEF] = useState();

  const obtenerPropelenteCompEF = () => {
    fetch(UrlApi + "/propelente_compuestos_ef/activos") //fetch con la urp de api que está en services/apirest + la ruta
      .then((data) => data.json()) //mando los datos que recibo en json
      .then((data) => setItemsPropelenteCompEF(data)); //seteo
  };

  const [compuestos, setCompuestos] = useState();

  useEffect(() => {
    // INICIO CON SUS DATOS VACIOS
    const init =
      itemsPropelenteCompEF &&
      uuid &&
      itemsPropelenteCompEF.map((item) => ({
        id_compuesto: item.id_compuesto,
        cantidad: 0,
        // visible: 0,
        // detectable: 0,
        uuid_compuestos: uuid,
      }));
    setCompuestos(init);
  }, [itemsPropelenteCompEF]);

  // FUNCION QUE CREO EN EL COMPONENTE PADRE Y QUE LLEVO A EL COMPONENTE HIJO PARA MANTENER LOS DATOS
  const updateItemsCompuestos = (prop, event, index) => {
    const old = compuestos[index];
    const updated = {
      ...old,
      // CON ESTA LINEA LE DIGO QUE SI EL VALOR ES === "" SE CONVIERTA A LO QUE LE ESCRIBO (NULL EN ESTE CASO)
      [prop]: event.target.value === "" ? null : event.target.value,
      id_compuesto: itemsPropelenteCompEF[index].id_compuesto,
    };
    const clone = [...compuestos];
    clone[index] = updated;
    setCompuestos(clone);
  };

  console.log("Compuestos: ", compuestos);
  console.log("DATOS:", datosAnalisis);

  //--------------------------------------------------------------------------------- //

  // USE EFFECT
  useEffect(() => {
    obtenerProductos();
    obtenerAspectos();
    obtenerOlores();
    obtenerReceptores();
    obtenerOperadoresFisicos();
    obtenerOperadoresCromatograficos();
    obtenerTanquesfijos();
    ObtenerDesplegables();
    //
    obtenerEfCompuestos();
    obtenerPropelenteCompEF();
    obtenerItemsEnsayoFisico();
  }, []);

  return (
    <>
      {mostrarAnalisis ? (
        <Container maxWidth="xl">
          <form onSubmit={guardarAnalisis}>
            <DialogTitle
              style={{
                color: "white",
                backgroundColor: "#478ac9",
                height: "3px",
                marginTop: -5,
              }}
            >
              <Grid container>
                <Grid item xs={0.1}>
                  <IconButton aria-label="delete" onClick={handleVolver}>
                    <ArrowBackIcon
                      sx={{ color: grey[50], marginTop: -4, marginLeft: -2 }}
                    />
                  </IconButton>
                </Grid>
                <Grid item xs={5}>
                  <Typography style={{ marginTop: -9, marginLeft: 30 }}>
                    <b>ANALISIS DE CALIDAD DEL PRODUCTO</b>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container mt={-4.1} justifyContent={"right"}>
                {componentesProducto && componentesProducto.length > 0 ? (
                  <>
                    <Box
                      sx={{
                        "& > :not(style)": {
                          m: 1,
                          width: "60ch",
                          height: "6ch",
                          mt: "-25px",
                        },
                      }}
                      justifyContent="flex-start"
                    >
                        <Button
                          variant="contained"
                          color="error"
                          style={{ width: "150px", height: "25px" }}
                          onClick={handleVolver}
                        >
                          Cancelar
                        </Button>
                    
                        <Button
                          variant="contained"
                          color="success"
                          style={{ width: "200px", height: "25px" }}
                          type="submit"
                        >
                          Guardar Muestra
                        </Button>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box
                      sx={{
                        "& > :not(style)": {
                          m: 1,
                          width: "60ch",
                          height: "6ch",
                          mt: "-25px",
                        },
                      }}
                      justifyContent="flex-start"
                    >
                      
                      <Button
                        disabled
                        variant="contained"
                        style={{ width: "150px", height: "25px" }}
                      >
                        Cancelar
                      </Button>
                      <Button
                        disabled
                        variant="contained"
                        style={{ width: "200px", height: "25px" }}
                      >
                        Guardar Muestra
                      </Button>
                    </Box>
                  </>
                )}
              </Grid>
            </DialogTitle>
            <Grid container spacing={0.5} mt={-1.3}>
              <Grid item xs={2.4} mt={2} mb={1}>
                {/* HAGO UN MAP PARA OBTENER EL ULTIMO VALOR Y LE SUMO 1 */}
                {ultimaMuestra
                  ? ultimaMuestra.map((elemento, index) => {
                      return (
                        <TextField
                          key={index}
                          value={
                            (datosAnalisis.nro_muestra =
                              elemento.nro_muestra + 1)
                          }
                          InputProps={{
                            readOnly: true,
                            startAdornment: (
                              <InputAdornment position="start">
                                <PinIcon />
                              </InputAdornment>
                            ),
                          }}
                          //  value={elemento.nro_muestra+1}
                          size="small"
                          label="Muestra N°"
                          name="nro_muestra"
                          placeholder="-"
                          variant="outlined"
                          focused
                          fullWidth
                          onChange={almacenar}
                        />
                      );
                    })
                  : null}
              </Grid>

              <Grid item xs={2.5} mt={2} mb={1}>
                <FormControl required focused fullWidth>
                  <InputLabel>Producto</InputLabel>
                  <Select
                    value={datosAnalisis.id_producto}
                    size="small"
                    name="id_producto"
                    label="Producto"
                    required
                    onChange={almacenar}
                  >
                    {productos
                      ? productos.map((elemento) => (
                          <MenuItem
                            key={elemento.idproducto}
                            value={elemento.idproducto}
                          >
                            {elemento.nombre}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>

              {componentesProducto && componentesProducto.length > 0 ? (
                <>
                  <Grid item xs={2} mt={2} mb={1}>
                    <FormControl required focused fullWidth>
                      <InputLabel>Tanque de Origen</InputLabel>
                      <Select
                        value={datosAnalisis.id_tanque}
                        name="id_tanque"
                        size="small"
                        label="Tanque De Origen"
                        placeholder="Tanque 10"
                        variant="outlined"
                        focused
                        fullWidth
                        required
                        onChange={almacenar}
                      >
                        {tanquesfijos
                          ? tanquesfijos.map((elemento) => (
                              <MenuItem
                                key={elemento.idtanque}
                                value={elemento.idtanque}
                              >
                                {elemento.nro_serie}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2} mt={2} mb={1}>
                    <TextField
                      type="date"
                      name="fecha"
                      label="Fecha"
                      size="small"
                      variant="outlined"
                      focused
                      fullWidth
                      required
                      onChange={almacenar}
                      value={moment(datosAnalisis.fecha).format("YYYY-MM-DD")}
                    />
                  </Grid>
                  <Grid item xs={1.1} mt={2} mb={1}>
                    <TextField
                      type="time"
                      name="hora"
                      label="Hora"
                      size="small"
                      variant="outlined"
                      focused
                      fullWidth
                      onChange={almacenar}
                      value={datosAnalisis.hora}
                    />
                  </Grid>
                  <Grid item xs={2} mt={2} mb={1}>
                    <TextField
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      inputProps={{
                        maxLength: 3,
                      }}
                      name="cantidad_muestras_testigo"
                      size="small"
                      label="Nro. Muestras Testigo"
                      placeholder="-"
                      focused
                      fullWidth
                      required
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl focused fullWidth>
                      <InputLabel>Receptor</InputLabel>
                      <Select
                        onChange={almacenar}
                        value={datosAnalisis.id_receptor}
                        name="id_receptor"
                        size="small"
                        label="Receptor"
                        focused
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PersonIcon />
                            </InputAdornment>
                          ),
                        }}
                      >
                        {receptores
                          ? receptores.map((elemento) => (
                              <MenuItem key={elemento.id} value={elemento.id}>
                                {elemento.nombre} {elemento.apellido}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl focused fullWidth>
                      <InputLabel>Operador Físico</InputLabel>
                      <Select
                        onChange={almacenar}
                        value={datosAnalisis.id_operador_fisico}
                        name="id_operador_fisico"
                        size="small"
                        label="Operador Físico"
                        fullWidth
                      >
                        {operadoresFisicos
                          ? operadoresFisicos.map((elemento) => (
                              <MenuItem key={elemento.id} value={elemento.id}>
                                {elemento.nombre} {elemento.apellido}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl focused fullWidth>
                      <InputLabel>Operador Cromatográfico</InputLabel>
                      <Select
                        onChange={almacenar}
                        value={datosAnalisis.id_operador_cromatografico}
                        name="id_operador_cromatografico"
                        size="small"
                        label="Operador Cromatográfico"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PersonIcon />
                            </InputAdornment>
                          ),
                        }}
                      >
                        {operadoresCromatograficos
                          ? operadoresCromatograficos.map((elemento) => (
                              <MenuItem key={elemento.id} value={elemento.id}>
                                {elemento.nombre} {elemento.apellido}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2} mt={1} mb={1}>
                    <FormControl required focused fullWidth>
                      <InputLabel>Olor al Ingreso</InputLabel>
                      <Select
                        name="id_olor_ingreso"
                        label="Olor al Ingreso"
                        size="small"
                        required
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={datosAnalisis.olor}
                        onChange={almacenar}
                      >
                        {olores
                          ? olores.map((elemento) => (
                              <MenuItem key={elemento.id} value={elemento.id}>
                                {elemento.valor}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2} mt={1} mb={1}>
                    <FormControl required focused fullWidth>
                      <InputLabel>Aspecto</InputLabel>
                      <Select
                        name="id_aspecto"
                        size="small"
                        label="Aspecto"
                        focused
                        fullWidth
                        onChange={almacenar}
                        value={datosAnalisis.id_aspecto}
                      >
                        {aspectos
                          ? aspectos.map((elemento) => (
                              <MenuItem key={elemento.id} value={elemento.id}>
                                {elemento.valor}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={8} mt={1} mb={1}>
                    {componentesProducto && componentesProducto.length > 0 ? (
                      <>
                        <Grid item mb={1}>
                          <TextField
                            size="small"
                            label="Observaciones"
                            focused
                            multiline
                            fullWidth
                            name="observaciones"
                            maxRows={1}
                            inputProps={{
                              maxLength: 89,
                            }}
                            onChange={almacenar}
                          />
                        </Grid>
                      </>
                    ) : null}
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={2} mt={2} mb={1}>
                    <FormControl fullWidth>
                      <InputLabel>Tanque de Origen</InputLabel>
                      <Select
                        disabled
                        size="small"
                        label="Tanque De Origen"
                        variant="outlined"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={2} mt={2} mb={1}>
                    <TextField
                      disabled
                      size="small"
                      label="Fecha"
                      type="date"
                      variant="outlined"
                      fullWidth
                      value={moment(datosAnalisis.fecha).format("YYYY-MM-DD")}
                    />
                  </Grid>
                  <Grid item xs={1.1} mt={2} mb={1}>
                    <TextField
                      disabled
                      variant="outlined"
                      size="small"
                      label="Hora"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={2} mt={2} mb={1}>
                    <TextField
                      disabled
                      size="small"
                      label="Nro. Muestras Testigo"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel>Receptor</InputLabel>
                      <Select
                        disabled
                        size="small"
                        label="Receptor"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel>Operador Físico</InputLabel>
                      <Select
                        disabled
                        size="small"
                        label="Operador Físico"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel>Operador Cromatográfico</InputLabel>
                      <Select
                        disabled
                        size="small"
                        label="Operador Cromatográfico"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={2} mt={1} mb={1}>
                    <FormControl fullWidth>
                      <InputLabel>Olor al Ingreso</InputLabel>
                      <Select disabled label="Olor al Ingreso" size="small" />
                    </FormControl>
                  </Grid>
                  <Grid item xs={2} mt={1} mb={1}>
                    <FormControl fullWidth>
                      <InputLabel>Aspecto</InputLabel>
                      <Select disabled size="small" label="Aspecto" />
                    </FormControl>
                  </Grid>
                  <Grid item xs={8} mt={1} mb={1}>
                    <Grid item mb={1}>
                      <TextField
                        disabled
                        label="Observaciones"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </>
              )}
              {/* TABS */}
              {/* PANEL CROMATOGRAFIA */}
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs value={value} onChange={handleChange}>
                    <Tab label="CROMATOGRAFÍA" {...a11yProps(0)} />
                    <Tab label="ENSAYOS FISICOS DE GLP" {...a11yProps(1)} />
                    <Tab label="COMPUESTOS" {...a11yProps(2)} />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  {componentesProducto && componentesProducto.length > 0 ? (
                    <>
                      <Cromatografia
                        cromatografia={cromatografia}
                        componentes={componentesProducto}
                        updateItemsCromatografia={updateItemsCromatografia}
                      />
                    </>
                  ) : (
                    <Grid container spacing={2} px={2}>
                      <Grid item xs={12}>
                        <h1 style={{ textAlign: "center", marginLeft: "20" }}>
                          Selecciona un Producto con Componentes Válidos
                        </h1>
                        <br />
                        <hr />
                      </Grid>
                    </Grid>
                  )}
                </TabPanel>
                {/* PANEL ENSAYOS FISICOS DE GLP */}
                <TabPanel value={value} index={1}>
                  {componentesProducto && componentesProducto.length > 0 ? (
                    <>
                      <EnsayoFisicoGLP
                        olores={olores}
                        desplegables={desplegables}
                        itemsEnsayoFisico={itemsEnsayoFisico}
                        updateItemsEnsayoFisico={updateItemsEnsayoFisico}
                        ensayoFisico={ensayoFisico}
                        // ----------------
                        // ENVIO LOS LOCALSTORAGE COMO PROPS
                        // ----------------
                        // habilitarResiduos={setHabilitarResiduos}
                        // habilitarHumedad={setHabilitarResiduos}
                        // -------ESTO SE ROMPE---------
                        // habilitarResiduos={habilitarResiduos}
                        // habilitarHumedad={habilitarResiduos}
                        // ----------------
                        // check={check}
                        // visible={visible}
                      />
                    </>
                  ) : (
                    <Grid container spacing={2} px={2}>
                      <Grid item xs={12}>
                        <h1 style={{ textAlign: "center", marginLeft: "20" }}>
                          Selecciona un Producto con Componentes Válidos
                        </h1>
                        <br />
                        <hr />
                      </Grid>
                    </Grid>
                  )}
                </TabPanel>
                {/* PANEL DE COMPUESTOS */}
                <TabPanel value={value} index={2}>
                  {componentesProducto && componentesProducto.length > 0 ? (
                    <>
                      <Compuestos
                        desplegables={desplegables}
                        itemsPropelenteCompEF={itemsPropelenteCompEF}
                        updateItemsCompuestos={updateItemsCompuestos}
                        compuestos={compuestos}
                      />
                    </>
                  ) : (
                    <Grid container spacing={2} px={2}>
                      <Grid item xs={12}>
                        <h1 style={{ textAlign: "center", marginLeft: "20" }}>
                          Selecciona un Producto con Componentes Válidos
                        </h1>
                        <br />
                        <hr />
                      </Grid>
                    </Grid>
                  )}
                </TabPanel>
              </Box>
              {/* FIN TABS */}
            </Grid>

            <Stack spacing={2} sx={{ width: "100%" }}>
              <Snackbar
                open={openSnack}
                autoHideDuration={1500}
                onClose={handleCloseSnack}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <Alert
                  onClose={handleCloseSnack}
                  severity="success"
                  sx={{ width: "100%" }}
                  style={{ backgroundColor: colormensaje, color: "black" }}
                >
                  {mensaje}
                </Alert>
              </Snackbar>
            </Stack>
          </form>
        </Container>
      ) : null}
    </>
  );
}

export default AnalisisCalidad;
