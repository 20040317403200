import React, { useEffect, useState } from "react";
import { UrlApi } from "../../../../services/apirest";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Grid,
  Select,
  FormControl,
  TextField,
  MenuItem,
  InputLabel,
} from "@mui/material";
import moment from "moment";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

function FormSalidaProveedor(props) {
  const [datosRegistro, setDatosRegistro] = useState(props.datos);
  console.log( props.datos);
  // console.log(datosRegistro);

  // console.log(props.datos);

  const [datosSalida, setDatosSalida] = useState({
    s_nroticket: "",
    s_fecha: "",
    s_hora: "",
    s_pesada: "",
    s_idproducto: props.datos.e_idproducto ? props.datos.e_idproducto : "",
    s_idsucursal: "",
    s_idtractor: props.datos.e_idtractor ? props.datos.e_idtractor : "",
    s_idtanquemovil: props.datos.e_idtanquemovil
      ? props.datos.e_idtanquemovil
      : "",
    s_idchofer: props.datos.e_idchofer ? props.datos.e_idchofer : "",
    s_tractor: "",
    s_tanquemovil: "",
    s_chofer: "",
    s_idnivelgasoil: "",
    s_observaciones: "",
    s_idtqcarga: "",
    id_estado: "",


    // vamos a probar cosas
    nro_remito: "",
    fecha: "",
    pesada: "",
    id_tractor:"",
    id_tanquemovil:"",
    id_chofer:"",
    tractor:"",
    tanquemovil:"",
    chofer:"",
    id_sucursal:"",
    id_producto:"",
    km_inicial: "",
    cot: "", //se registra el COT del transito con el que circularan los choferes de ITALGAS con producto
    recorrido:"",
    p_precinto1:"",
    p_precinto2:"",
    p_precinto3:"",
    p_porccarga:"",
    p_tempproducto:"",
    p_presion:"",
    baja:0
  });

  console.log("asdadsa")

  // const [datosSalida, setdatosSalida] = useState({
  //   // cli_o_prov: "",
  //   nro_remito: "",
  //   fecha: "",
  //   pesada: "",
  //   id_tractor:"",
  //   id_tanquemovil:"",
  //   id_chofer:"",
  //   tractor:"",
  //   tanquemovil:"",
  //   chofer:"",
  //   id_sucursal:"",
  //   id_producto:"",
  //   km_inicial: "",
  //   cot: "", //se registra el COT del transito con el que circularan los choferes de ITALGAS con producto
  //   recorrido:"",
  //   p_precinto1:"",
  //   p_precinto2:"",
  //   p_precinto3:"",
  //   p_porccarga:"",
  //   p_tempproducto:"",
  //   p_presion:"",
  //   baja:0
  // });

  const [productos, setProductos] = useState();
  const [sucursales, setSucursales] = useState();
  const [tanquesMoviles, setTanquesMoviles] = useState();
  const [saleASucursal, setSaleASucursal] = useState(false);
  const [sucursalSelected, setSucursalSelected] = useState(null);
  // console.log(sucursalSelected);

  const handleSucursal = (e) => {
    setSucursalSelected(e.target.value);
  };

  const obtenerProductos = () => {
    fetch(UrlApi + "/productospropelente")
      .then((data) => data.json())
      .then((data) => setProductos(data));
  };

  const obtenerSucursales = () => {
    fetch(UrlApi + "/sucursales")
      .then((data) => data.json())
      .then((data) => setSucursales(data));
  };

  const [choferes, setChoferes] = useState();
  const obtenerChoferes = () => {
    fetch(UrlApi + "/choferes/propelente")
      .then((data) => data.json())
      .then((data) => setChoferes(data));
  };

  // const obtenerTractores = () => {
  //   fetch(UrlApi + "/vehiculos/tractorespropelente")
  //     .then((data) => data.json())
  //     .then((data) => setTractores(data));
  // };

  const obtenerTanques = () => {
    fetch(UrlApi + "/vehiculos/tanquespropelente")
      .then((data) => data.json())
      .then((data) => setTanquesMoviles(data));
  };

  useEffect(() => {
    obtenerProductos();
    obtenerSucursales();
    obtenerChoferes();
    obtenerTanques();
  }, []);

  const handleChBoxSuc = (e) => {
    setSaleASucursal(e.target.checked);
  };

  const handleClose = () => {
    props.open(false);
  };

  const setearSalida = (e) => {
    setDatosSalida({ ...datosSalida, [e.target.name]: e.target.value });
  };

  async function guardarSalida(e) {
    e.preventDefault();
    await fetch(
      UrlApi + "/entradas_salidas_propelente/" + props.datos.idregistro,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          s_nroticket: datosSalida.s_nroticket,
          s_fecha: datosSalida.s_fecha,
          s_hora: datosSalida.s_hora,
          s_pesada: datosSalida.s_pesada,
          s_idproducto: datosSalida.s_idproducto,
          s_idsucursal:
            datosSalida.id_sucursal === ""
              ? null
              : datosSalida.id_sucursal === "",
          s_idtractor:
            datosSalida.s_idtractor === "" ? null : datosSalida.s_idtractor,
          s_idtanquemovil:
            datosSalida.s_idtanquemovil === ""
              ? null
              : datosSalida.s_idtanquemovil,
          s_idchofer:
            datosSalida.s_idchofer === "" ? null : datosSalida.s_idchofer,
          s_tractor: datosSalida.s_tractor,
          s_tanquemovil: datosSalida.s_tanquemovil,
          s_chofer: datosSalida.s_chofer,
          s_idnivelgasoil:
            datosSalida.s_idnivelgasoil === ""
              ? null
              : datosSalida.s_idnivelgasoil,
          s_observaciones: datosSalida.s_observaciones,
          s_idtqcarga:
            datosSalida.s_idtqcarga === "" ? null : datosSalida.s_idtqcarga,
          id_estado: 134,
        }),
      }
    );
    // then(handleClose());
    //response&&console.log(response)
    // if (response.ok === false) {
    //   console.log("ERROR EN API");
    //   const error = response && response.json();
    //   setError(error);
    //   setSeverityAlerta("error")
    //   setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
    // } else {
    //   console.log("Conexión con api OK");
    //   try {
    //     //Parseo respuesta de la api a json()
    //     const json = await response.json();
    //     //si api me devuelve lo q le puse en message
    //     if (json.message) {
    //       //Si ese mensaje es "OK"
    //       if (json.message === "OK") {
    //         console.log("Guardado Satisfactoriamente");
    //         setNotificacion(true);
    //         setOpenSnack(true);
    //         setColorMensaje("#D4EFDF");
    //         setMensaje("Guardado");
    //         setSeveritySnack("success");
    //       } else {
    //   console.log("res en api incorrecto. Revisar que sea 'OK'")
    //         setNotificacion(true);
    //         setOpenSnack(true);
    //         setColorMensaje("#D4EFDF");
    //         setSeveritySnack("error");
    //         setMensaje("Registro Guardado. REVISAR RESPUESTA DE API (E_S)");
    //       }
    //     } else if(json.sqlMessage) {
    //       json && setError(json);
    //       console.log(json);
    //       setMensajeAlerta("SQL erróneo (" + json.code + " Errno: " + json.errno + " )" );
    //     } else {
    //       setMensajeAlerta("ERROR DESCONOCIDO.");
    //     }
    //   } catch (error) {
    //     if (error.toString().includes("is not valid JSON")) {
    //       console.log("Guardado. Advertencia: Actualizar respuesta de api. JSON invalido.");
    //       setNotificacion(true);
    //       setOpenSnack(true);
    //       setColorMensaje("#D4EFDF");
    //       setMensaje("Guardado");
    //       setSeveritySnack("success");
    //     } else {
    //       setMensajeAlerta("ERROR DESCONOCIDO.");
    //     }
    //   }
    // }
  }

  async function guardarTransito(e) {
    e.preventDefault();
    await fetch(UrlApi + "/remitos_new/" , {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        nro_remito: datosSalida.nro_remito,
        fecha: datosSalida.s_fecha,
        pesada: datosSalida.s_pesada,
        id_tractor: datosSalida.s_idtractor,
        id_tanquemovil:datosSalida.s_idtanquemovil,
        id_chofer:datosSalida.s_idchofer,
        tractor:datosSalida.chofer,
        tanquemovil:datosSalida.tanquemovil,
        chofer:datosSalida.chofer,
        id_sucursal:sucursalSelected.id,
        id_producto:datosSalida.s_idproducto,
        km_inicial: datosSalida.km_inicial,
        cot:  datosSalida.cot, //se registra el COT del transito con el que circularan los choferes de ITALGAS con producto
        recorrido: datosSalida.recorrido,
        p_precinto1: datosSalida.p_precinto1,
        p_precinto2: datosSalida.p_precinto2,
        p_precinto3: datosSalida.p_precinto3,
        p_porccarga:datosSalida.p_porccarga,
        p_tempproducto: datosSalida.p_tempproducto,
        p_presion: datosSalida.p_presion,
        uuid:props.datos.uuid,
        baja:0
      }),
    });
    // then(handleClose());
    //response&&console.log(response)
    // if (response.ok === false) {
    //   console.log("ERROR EN API");
    //   const error = response && response.json();
    //   setError(error);
    //   setSeverityAlerta("error")
    //   setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
    // } else {
    //   console.log("Conexión con api OK");
    //   try {
    //     //Parseo respuesta de la api a json()
    //     const json = await response.json();
    //     //si api me devuelve lo q le puse en message
    //     if (json.message) {
    //       //Si ese mensaje es "OK"
    //       if (json.message === "OK") {
    //         console.log("Guardado Satisfactoriamente");
    //         setNotificacion(true);
    //         setOpenSnack(true);
    //         setColorMensaje("#D4EFDF");
    //         setMensaje("Guardado");
    //         setSeveritySnack("success");
    //       } else {
    //   console.log("res en api incorrecto. Revisar que sea 'OK'")
    //         setNotificacion(true);
    //         setOpenSnack(true);
    //         setColorMensaje("#D4EFDF");
    //         setSeveritySnack("error");
    //         setMensaje("Registro Guardado. REVISAR RESPUESTA DE API (E_S)");
    //       }
    //     } else if(json.sqlMessage) {
    //       json && setError(json);
    //       console.log(json);
    //       setMensajeAlerta("SQL erróneo (" + json.code + " Errno: " + json.errno + " )" );
    //     } else {
    //       setMensajeAlerta("ERROR DESCONOCIDO.");
    //     }
    //   } catch (error) {
    //     if (error.toString().includes("is not valid JSON")) {
    //       console.log("Guardado. Advertencia: Actualizar respuesta de api. JSON invalido.");
    //       setNotificacion(true);
    //       setOpenSnack(true);
    //       setColorMensaje("#D4EFDF");
    //       setMensaje("Guardado");
    //       setSeveritySnack("success");
    //     } else {
    //       setMensajeAlerta("ERROR DESCONOCIDO.");
    //     }
    //   }
    // }
  }

  const guardar = (e) => {
    guardarSalida(e);
    if (saleASucursal) {
      guardarTransito(e);
    }
    handleClose();
  };

  return (
    <div>
      <form onSubmit={guardar}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          spacing={2}
        >
          <Grid item xs={3}>
            <TextField
              required
              fullWidth
              name="s_nroticket"
              label="Nro Ticket"
              variant="outlined"
              color="success"
              margin="none"
              value={datosSalida.s_nroticket}
              onChange={setearSalida}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              required
              fullWidth
              name="s_pesada"
              label="Pesada"
              variant="outlined"
              color="success"
              margin="none"
              value={datosSalida.s_pesada}
              onChange={setearSalida}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              required
              fullWidth
              type="date"
              name="s_fecha"
              // label="Fecha"
              variant="outlined"
              color="success"
              margin="none"
              value={moment(datosSalida.s_fecha).format("YYYY-MM-DD")}
              onChange={setearSalida}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              required
              fullWidth
              type="time"
              name="s_hora"
              variant="outlined"
              color="success"
              margin="none"
              value={datosSalida.s_hora}
              onChange={setearSalida}
            />
          </Grid>
          {datosRegistro.e_idpropietariotractor === 124 ? ( //Si el tractor es propiedad de la empresa, despliego los choferes cargados en sistema
            <Grid item xs={3}>
              <FormControl
                required={datosRegistro.e_idpropietariotractor === 124}
                fullWidth
              >
                <InputLabel color="success">Chofer</InputLabel>
                <Select
                  name="s_idchofer"
                  label="Chofer"
                  color="success"
                  value={datosSalida.s_idchofer}
                  onChange={setearSalida}
                >
                  {choferes
                    ? choferes.map((chofer) => (
                        <MenuItem key={chofer.id} value={chofer.id}>
                          {chofer.nombre_apellido}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </Grid>
          ) : (
            <Grid item xs={3}>
              <TextField
                required={datosRegistro.e_idpropietariotractor !== 124}
                fullWidth
                name="s_chofer"
                label="Chofer"
                variant="outlined"
                color="success"
                margin="none"
                value={datosSalida.s_chofer}
                onChange={setearSalida}
              />
            </Grid>
          )}

          {datosRegistro.e_idpropietariotanque === 124 ? (
            <Grid item xs={3}>
              <FormControl
                fullWidth
                required={datosRegistro.e_idpropietariotanque === 124}
              >
                <InputLabel color="success">Tanque Móvil</InputLabel>
                <Select
                  name="s_idtanquemovil"
                  label="Tanque Móvil"
                  color="success"
                  value={datosSalida.s_idtanquemovil}
                  onChange={setearSalida}
                >
                  {tanquesMoviles
                    ? tanquesMoviles.map((tanque) => (
                        <MenuItem key={tanque.id} value={tanque.id}>
                          {tanque.patente}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </Grid>
          ) : (
            <Grid item xs={3}>
              <TextField
                required={!datosRegistro.e_idpropietariotanque === 124}
                fullWidth
                name="s_tanquemovil"
                label="Tanque Movil"
                variant="outlined"
                color="success"
                margin="none"
                value={datosSalida.s_idtanquemovil}
                onChange={setearSalida}
              />
            </Grid>
          )}
          <Grid item xs={3}>
            <FormControl fullWidth required={saleASucursal}>
              <InputLabel color="success">Producto</InputLabel>
              <Select
                name="s_idproducto"
                label="Producto"
                color="success"
                value={datosSalida.s_idproducto}
                onChange={setearSalida}
              >
                {productos
                  ? productos.map((producto) => (
                      <MenuItem
                        key={producto.idproducto}
                        value={producto.idproducto}
                      >
                        {producto.nombre}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <FormControlLabel
              checked={saleASucursal}
              onChange={handleChBoxSuc}
              control={<Checkbox />}
              label="A Sucursal"
              sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
            />
          </Grid>
          {saleASucursal ? (
            <>
              <Grid item xs={3}>
                <FormControl fullWidth required>
                  <InputLabel color="success">Sucursal</InputLabel>
                  <Select
                    name="sucursalSelected"
                    label="Sucursal"
                    color="success"
                    value={sucursalSelected}
                    onChange={handleSucursal}
                  >
                    {sucursales
                      ? sucursales.map((sucursal) => (
                          <MenuItem key={sucursal.id} value={sucursal}>
                            {sucursal.nombre}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              {/* En caso de transito a sucursal, la direccion de entregae es meramente informativa, no se guardará como dato en el remito */}
              <Grid item xs={6}>
                <TextField
                  readOnly
                  focused
                  fullWidth
                  label="Dirección de entrega"
                  variant="outlined"
                  color="success"
                  margin="none"
                  value={sucursalSelected && sucursalSelected.direccion}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  required
                  fullWidth
                  name="nro_remito"
                  label="Nro Remito"
                  variant="outlined"
                  color="success"
                  margin="none"
                  value={datosSalida.nro_remito}
                  onChange={setearSalida}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  required
                  fullWidth
                  name="cot"
                  label="COT"
                  variant="outlined"
                  color="success"
                  margin="none"
                  value={datosSalida.cot}
                  onChange={setearSalida}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  name="recorrido"
                  label="Recorrido"
                  variant="outlined"
                  color="success"
                  margin="none"
                  value={datosSalida.recorrido}
                  onChange={setearSalida}
                />
              </Grid>
              {/* <Grid item xs={3}>
                <TextField
                  fullWidth
                  name="c_protocolo"
                  label="Protocolo"
                  variant="outlined"
                  color="success"
                  margin="none"
                  // value={}
                  // onChange={}
                />
              </Grid> */}
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  name="km_inicial"
                  label="KM inicial"
                  variant="outlined"
                  color="success"
                  margin="none"
                  value={datosSalida.km_inicial}
                  onChange={setearSalida}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  required
                  fullWidth
                  name="p_precinto1"
                  label="Precinto 1"
                  variant="outlined"
                  color="success"
                  margin="none"
                  value={datosSalida.p_precinto1}
                  onChange={setearSalida}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  name="p_precinto2"
                  label="Precinto 2"
                  variant="outlined"
                  color="success"
                  margin="none"
                  value={datosSalida.p_precinto2}
                  onChange={setearSalida}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  name="p_precinto3"
                  label="Precinto 3"
                  variant="outlined"
                  color="success"
                  margin="none"
                  value={datosSalida.p_precinto3}
                  onChange={setearSalida}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  required
                  fullWidth
                  name="p_porccarga"
                  label="% Carga"
                  variant="outlined"
                  color="success"
                  margin="none"
                  value={datosSalida.p_porccarga}
                  onChange={setearSalida}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  name="p_tempproducto"
                  label="Temperatura Producto"
                  variant="outlined"
                  color="success"
                  margin="none"
                  value={datosSalida.p_tempproducto}
                  onChange={setearSalida}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  name="p_presion"
                  label="Presión"
                  variant="outlined"
                  color="success"
                  margin="none"
                  value={datosSalida.p_presion}
                  onChange={setearSalida}
                />
              </Grid>
            </>
          ) : null}
        </Grid>
        <Grid
          my={2}
          container
          spacing={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          alignContent="center"
          wrap="wrap"
        >
          <Grid item>
            <Button variant="outlined" color="error" onClick={handleClose}>
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="success" type="submit">
              Guardar
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default FormSalidaProveedor;
