import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import { Box, Grid, IconButton, Paper, Typography } from '@mui/material';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { grey } from "@mui/material/colors";
import Title from '../Title';


function DrawerVehiculos({ isOpen, onClose, dataDrawer }) {
   const [vehiculosProps, setVehiculosProps] = useState(dataDrawer)
  // console.log("jkbnjjjhb",vehiculosProps);
  return (
    <Drawer     
    anchor="right"
    open={isOpen}
    onClose={onClose}
    role="presentation"
    TransitionComponent={Slide}
    sx={{
      '& .MuiDrawer-paper': {
        width: 600,
        backgroundColor:grey[200],
      },
    }}
    >
      <div style={{marginTop:25}}>
      <Title
          titulo={`Ultima Ubicación Informada`}
          handleClose={onClose}
      />
      </div>
      <Box p={2} >
          {vehiculosProps && vehiculosProps.length > 0?vehiculosProps.map((item, index) => (
            <Paper key={index} elevation={1} sx={{ p: 1, mb: 1 }}>
              <Typography variant="body2" color="textPrimary">
                Patente: {item.patente} - {item.ubicacion}
              </Typography>
            </Paper>
          ))
          :
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ marginTop: 'auto' }}>
          <Typography variant="h6" color="textPrimary">
               No hay Datos Recientes
          </Typography>
          </div>
          </Box>
          }
        </Box>
    </Drawer>
  )
}

export default DrawerVehiculos