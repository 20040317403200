//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio
// Creation Date : 06/09/2024
// Version : 1
// Description : tabla de tareas para la info de tanque
//------Changes -------------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect, useMemo } from "react";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  Dialog,
  Grid,
  Container,
  Tooltip,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import moment from "moment";
import { UrlApi } from "../../services/apirest";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import BlockIcon from "@mui/icons-material/Block";
import { grey } from "@mui/material/colors";
import RepuestosTareas from "./RepuestosTareas";
import { logEventos } from "../../services/services";

function TablaTareas(props) {
  const [datosTanque, setDatosTanque] = useState(props.datosTanque); // viene del motivos_salida {editar}
  const [tareasXtanque, setTareasXtanque] = useState();
  const [tabla, setTabla] = useState(true);
  const [confirmar, setConfirmar] = useState(false);
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
  const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)

  const obtenerTareaXtanque = () => {
    fetch(UrlApi + "/tanque_tarea/tareaXtanque/" + datosTanque.id_tanque)
      .then((data) => data.json())
      .then((data) => setTareasXtanque(data));
  };
  //console.log("TAREAS X TANQUE", tareasXtanque);
  //ejecuta la funcion de obtener
  useEffect(() => {
    obtenerTareaXtanque();
  }, [tabla]);

 // console.log("DATOS TANQUE TAREA", datosTanque);

  const columns = useMemo(
    () => [
      {
        header: "Desde",
        accessorFn: (desde) => moment(desde.tarea_desde).format("DD-MM-YYYY"),
        size: 80,
      },
      {
        header: "Hasta",
        accessorFn: (hasta) => moment(hasta.tarea_hasta).format("DD-MM-YYYY"),
        size: 80,
      },
      { header: "Motivo", accessorKey: "motivo_tarea", size: 150 },
       { header: "Detalle", accessorKey: "detalle_tarea", size: 200 },
      { header: "Lugar", accessorKey: "lugar_tarea", size: 150 },
      { header: "A cargo de", accessorKey: "razon_social", size: 110 },
      {
        header: "Finalizada",
        accessorKey: "estado_palabra_finalizada",
        size: 100,
      },
      {
        header: "Proviene",
        accessorKey: "estado_palabra_proviene",
        size: 90,
      },
    ],
    []
  );
  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "success",
    size: "small",
  };
  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)" //background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //override to make tooltip font size larger
              },
            },
          },
        },
      }),
    [globalTheme]
  );
  const initialState = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 5 },
    // sorting: [
    //     {
    //       id: 'tarea_desde',
    //       desc: true,
    //     },
    //   ],
  };
  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };

  const abrirModalConfirmar = (datosDeLaLinea) => {
    setConfirmar(true);
    setTareasXtanque(datosDeLaLinea);
    //  console.log("datos linea", datosDeLaLinea ? datosDeLaLinea : "NO");
  };

  const handleVolver = () => {
    setConfirmar(false);
    setTabla(true);
  };

  const marcarInactivo = async (e) => {
    logEventos(
      "Guardar marcado inactivo de tarea",
      "Tanques Moviles",
      "Se guardo tarea inactiva",
      localStorage.getItem("user")
    );
    e.preventDefault();
    const response = await fetch(
      UrlApi + "/tanque_tarea/" + tareasXtanque.id_tarea,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(
          tareasXtanque.finalizada === 0 ? { finalizada: 1 } : { finalizada: 0 }
        ),
      }
    );
    if (response.ok === false) {
      const error = response && response.json();
      setError(error);
      alert("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      try {
        const json = await response.json();
        if (json.message) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje(
            json.message === "OK" ? "Guardado" : "Guardado. Actualizar RTA Api."
          );
          obtenerTareaXtanque();
        } else if (json.sqlMessage) {
          json && setError(json);
          alert("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
        } else {
          alert("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje("Guardado");
          obtenerTareaXtanque();
        } else {
          alert("Error: " + error);
        }
      }
    }
    handleVolver();
    obtenerTareaXtanque();
  };

  const customLocalization = {
    ...MRT_Localization_ES,
    expandAll: 'Ex', // Cambia 'Expandir todo' a 'Ex'
    expand: 'Ex', // Cambia 'Expandir' a 'Ex'
  };

  return (
    <div>
      <Container maxWidth="xl" mt={1} mb={2}>
        {tareasXtanque && tabla ? (
          <Box mt={2} boxShadow={3}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableColumnResizing
                enableExpandAll={false}
                muiTableHeadCellProps={tableHeadCellProps}
                enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
                enableGrouping // para permitir agrupar x estado x ej
                layoutMode="grid"
                muiTableBodyCellProps={{
                  align: "left", //el contenido de la tabla
                }}
                enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
                enableColumnFilters={false} //desactiva filtros x  columna
                enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
                enableDensityToggle={false} //
                localization={customLocalization} //idioma
                enableColumnDragging={false} //para arrastrar columnas
                initialState={initialState}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                columns={columns}
                data={tareasXtanque} //con lo q la completas (esto cambia siempre)
                enableStickyFooter
                enableSorting={true}
                //enableEditing
                enableRowActions
                positionActionsColumn="last" // posicion de la columna de acciones al final
                // ACCIONES
                renderTopToolbarCustomActions={() => (
                  <>
                    <Typography variant="poster" fontSize={26}>
                      Tareas de visita al tanque
                    </Typography>
                  </>
                )}
                renderToolbarInternalActions={(
                  { table } // boton para nuevo , al lado del buscar
                ) => (
                  <>
                    <MRT_FullScreenToggleButton table={table} />
                  </>
                )}
                renderDetailPanel={({ row }) => (
                  <div style={{ marginTop: "-50px" }}>
                    <Box
                      sx={{
                        width: "200%",
                      }}
                    >
                      <RepuestosTareas tareasXtanque={row.original}  />
                    </Box>
                  </div>
                )}
                renderRowActions={({ row }) => (
                  <>
                    {row.original.finalizada === 0 ? (
                      <Tooltip title="Marcar como finalizada">
                        <IconButton
                          color="success"
                          onClick={() => abrirModalConfirmar(row.original)}
                        >
                          <CheckCircleOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Marcar como NO finalizada">
                        <IconButton
                          onClick={() => abrirModalConfirmar(row.original)}
                          color="error"
                        >
                          <BlockIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </>
                )}
              />
            </ThemeProvider>
            <br />
          </Box>
        ) : null}
        {confirmar ? (
          <div>
            <Dialog
              open={confirmar}
              onClose={handleVolver}
              maxWidth="xs"
              fullWidth
            >
              <Box mt={1} backgroundColor={"#1F618D"}>
                <DialogTitle>
                  {tareasXtanque.finalizada === 0 ? (
                    <Typography variant="h6" align="center" color="#ffffff">
                      Finalizar tarea: {tareasXtanque.detalle_tarea}
                    </Typography>
                  ) : (
                    <Typography variant="h6" align="center" color="#ffffff">
                      No Finalizar tarea : {tareasXtanque.detalle_tarea}
                    </Typography>
                  )}
                </DialogTitle>
              </Box>
              <form onSubmit={marcarInactivo}>
                <Box
                  border={0.5}
                  borderColor={grey[300]}
                  backgroundColor="#F4F6F6"
                  p={2}
                  py={2}
                  mt={1}
                >
                  <DialogContent>
                    <DialogContentText textAlign={"center"}>
                      {tareasXtanque.finalizada === 0 ? (
                        <>
                          {" "}
                          <b>¿Desea marcarla como finalizada?</b>{" "}
                        </>
                      ) : (
                        <>
                          {" "}
                          <b>¿Desea marcarla como NO finalizada?</b>{" "}
                        </>
                      )}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItemns="center"
                    >
                      <Button
                        variant="contained"
                        onClick={handleVolver}
                        style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
                      >
                        Volver
                      </Button>
                      <Button
                        style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Confirmar
                      </Button>
                    </Grid>
                  </DialogActions>
                </Box>
              </form>
            </Dialog>
          </div>
        ) : null}
      </Container>
    </div>
  );
}

export default TablaTareas;
