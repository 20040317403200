import React, { useEffect, useState } from 'react'
import { getData } from '../../services/library';
import { Card, CardContent, Typography, Grid, Container, Avatar, Stack, Box, IconButton, Button, Tooltip } from '@mui/material'
import { styled } from '@mui/system'
import '@fontsource/roboto-mono'
import '@fontsource/orbitron'
import useMqttSubscription from '../../Hooks/useMqttSubscription'; ////Para conectarse al server de mqtt
import Navbar from '../Navbar'
// Iconos
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import Inventory2Icon from '@mui/icons-material/Inventory2'
import LocalShippingIcon from '@mui/icons-material/LocalShipping' // Ícono de camión de reparto
import { LineChart } from '@mui/x-charts';
import { UrlApi } from '../../services/apirest';
import { AddCircleOutline, PlusOne, RemoveCircleOutline, TrainSharp } from '@mui/icons-material';

//aca empieza el estilo de las cartas
const StyledCard = styled(Card)({
    borderRadius: 10,
    padding: '1px',
    boxShadow: '10px 4px 20px rgba(0, 0, 0, 0.5)',
    backgroundColor: '#212121',
    color: '#ffffff',
})

const StyledCardFacturacion = styled(Card)({
    borderRadius: 10,
    padding: '1px',
    boxShadow: '10px 4px 20px rgba(0, 0, 0, 0.5)',
    backgroundColor: '#6A1B9A', // Color de alto contraste diferente
    color: '#ffffff',
})

const StyledCardMoviles = styled(Card)({
    borderRadius: 10,
    padding: '1px',
    boxShadow: '10px 4px 20px rgba(0, 0, 0, 0.5)',
    backgroundColor: '#2E7D32',
    color: '#ffffff',
})

const StyledCardMoviles2 = styled(Card)({
    borderRadius: 10,
    padding: '1px',
    boxShadow: '10px 4px 20px rgba(0, 0, 1, 0.5)',
    backgroundColor: '#BE0C32',
    color: '#ffffff',
})

const StyledGraphCard = styled(Card)({
    borderRadius: 10,
    padding: '0px',
    boxShadow: '10px 4px 20px rgba(0, 0, 0, 0.5)',
    backgroundColor: '#FFFFFF',
    color: '#000000',
})

const StyledCardGranelitos = styled(Card)({
    borderRadius: 10,
    padding: '1px',
    boxShadow: '10px 4px 20px rgba(0, 0, 0, 0.5)',
    backgroundColor: '#2E7FFF',
    color: '#ffffff',
})


const TitleTypography = styled(Typography)({
    fontFamily: 'Roboto, sans-serif',
    fontSize: '20px',
    fontWeight: 'normal',
    textAlign: 'left',
    marginBottom: '2px',
})

const NumberTypography = styled(Typography)({
    fontFamily: 'Orbitron, sans-serif',
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#ffeb3b',
    borderBottom: '1px solid #bdbdbd',
    marginBottom: '2px',
})

const NumberTypographyFacturacion = styled(NumberTypography)({
    color: '#FFC107',
})

const NumberTypographyMoviles = styled(NumberTypography)({
    color: '#FFEB3B',
})

const NumberTypographyMoviles2 = styled(NumberTypography)({
    color: '#00CCBB',
})

const SubtitleTypography = styled(Typography)({
    fontFamily: 'Roboto, sans-serif',
    fontSize: '12px',
    fontWeight: 'normal',
    color: '#bdbdbd',
    borderRight: '1px solid #bdbdbd',
    paddingRight: '5px',
})

const AvatarWithMargin = styled(Avatar)({
    marginRight: '5px',
})

//y aca termina

const PanelControlGranel = () => {
    const [dataPedidos, setData] = useState(null); //aca guardo lo que me traigo del backend de pedidos
    const [dataRepartos, setdataRepartos] = useState(null); //aca guardo lo que me traigo del backend de repartos
    const [pedidos, setPedidos] = useState({ fechas: [], seriesData: [] });
    const [pedidosMensuales, setPedidosMensuales] = useState({ fechas: [], seriesData: [] });

    const [mes, setMes] = useState(new Date().getMonth() + 1); // Mes actual (1-12)
    const [anio, setAnio] = useState(new Date().getFullYear()); // Año actual

    // Arreglo de meses en español
    const meses = [
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];

    const handleMesAnterior = () => {
        if (mes === 1) {
            setMes(12);
            setAnio(anio - 1);
        } else {
            setMes(mes - 1);
        }
    };

    const handleMesSiguiente = () => {
        if (mes === 12) {
            setMes(1);
            setAnio(anio + 1);
        } else {
            setMes(mes + 1);
        }
    };

    // Obtener el mes actual (0 = Enero, 11 = Diciembre)
    const mesActual = new Date().getMonth();
    // Obtener el año actual
    const anioActual = new Date().getFullYear();

    useEffect(() => {
        handleMessage()
    }, [mes, anio]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === '+') {
                handleMesSiguiente(); // Llama a la función de mes siguiente
            } else if (event.key === '-') {
                handleMesAnterior(); // Llama a la función de mes anterior
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        // Limpia el listener al desmontar el componente
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [mes, anio]);

    /// cuando recibo el mensaje publicado en el topico netizar/panelgranel
    const handleMessage = async (message) => {
        try {
            // Realiza ambas llamadas fetch en paralelo
            const [resultPedidos, resultRepartos, resultGraficaDiaria, resultGraficaMensual] =
                await Promise.all([
                    fetch(`${UrlApi}/datospanelgranel/pedidos?mes=${mes}&anio=${anio}`).then(res => res.json()),
                    fetch(`${UrlApi}/datospanelgranel/repartos?mes=${mes}&anio=${anio}`).then(res => res.json()),
                    fetch(`${UrlApi}/pedidos/fecha_registrado?mes=${mes}&anio=${anio}`).then(res => res.json()),
                    fetch(`${UrlApi}/datospanelgranel/mes_registrado?anio=${anio}`).then(res => res.json()),
                ]);

            // Actualiza los estados con los resultados obtenidos
            setData(resultPedidos);
            setdataRepartos(resultRepartos);
            processPedidosData(resultGraficaDiaria);
            processPedidosMensuales(resultGraficaMensual);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const { connectionStatus } = useMqttSubscription('netizar/panelgranel', handleMessage);
    console.log(connectionStatus);
    dataRepartos && console.log(dataRepartos)
    dataPedidos && console.log(dataPedidos)

    //filtramos data de pedidos ESTO ES POR MES ----------------------------------------------------------------------------------
    const pendientes = (dataPedidos && dataPedidos.find(pedido => pedido.id === 19)) || { cantidad: 0 };
    const sin_asignar = (dataPedidos && dataPedidos.find(pedido => pedido.id === 21)) || { cantidad: 0 };
    const asignados = (dataPedidos && dataPedidos.find(pedido => pedido.id === 23)) || { cantidad: 0 };
    const facturados = (dataPedidos && dataPedidos.find(pedido => pedido.id === 25)) || { cantidad: 0 };
    const errorfacturacion = (dataPedidos && dataPedidos.find(pedido => pedido.id === 243 || pedido.id === 244)) || { cantidad: 0 };
    const facturaespecial = (dataPedidos && dataPedidos.find(pedido => pedido.id === 248)) || { cantidad: 0 };
    const pendientedepago = (dataPedidos && dataPedidos.find(pedido => pedido.id === 263)) || { cantidad: 0 };

    /// Pedidos entregados ////////////////////////////////////////////////////////////////
    const idsPedidosEntregados = [25, 40, 263, 248, 244]; // IDs que quieres verificar
    // Filtrar y sumar
    const entregados = (dataPedidos &&
        dataPedidos
            .filter(pedido => idsPedidosEntregados.includes(pedido.id)) // Filtrar pedidos con IDs coincidentes
            .reduce((sum, pedido) => sum + (pedido.cantidad || 0), 0) // Sumar las cantidades
    ) || 0;
    console.log("Cantidad total entregados:", entregados);
    /////////////////////////////////////////////////////////////////////////////////////

    const tnEntregadas = dataPedidos && dataPedidos.reduce((acc, item) => {
        const kgEntregados = item.kgentregados || 0; // Si es null, toma 0
        const ltsEntregados = item.ltsentregados || 0; // Si es null, toma 0
        return acc + kgEntregados + (ltsEntregados * 0.52);
    }, 0);

    const vehiculosAsignados = (dataRepartos && dataRepartos.find(reparto => reparto.id === 6)) || { cantidad: 0 };
    const onDelivery = (dataRepartos && dataRepartos.find(reparto => reparto.id === 8)) || { cantidad: 0 }
    const tnDelivery = (dataRepartos && dataRepartos.find(reparto => (reparto.kgcargadoscamion / 1000))) || { cantidad: 0 }


    //ESTA ES LA FUNCION QUE USO PARA MOSTRAR EN EL GRAFICO LOS PEDIDOS POR DIA
    const processPedidosData = (data) => {
        const pedidosPorDia = data.reduce((acc, pedido) => {
            const dia = new Date(pedido.dia).toISOString().split('T')[0]; // Extrae la fecha en formato YYYY-MM-DD
            if (!acc[dia]) {
                acc[dia] = { total: 0, toneladas: 0, estados: {} };
            }

            acc[dia].total += pedido.cantidad;
            acc[dia].toneladas += (pedido.total_kgentregados || 0) / 1000; // Convertir de kg a toneladas

            if (!acc[dia].estados[pedido.estado]) {
                acc[dia].estados[pedido.estado] = 0;
            }
            acc[dia].estados[pedido.estado] += pedido.cantidad;

            return acc;
        }, {});

        // Generar todas las fechas del mes actual
        const now = new Date();
        const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1); // Primer día del mes actual
        const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0); // Último día del mes actual
        const fechas = [];
        for (let d = new Date(startOfMonth); d <= endOfMonth; d.setDate(d.getDate() + 1)) {
            fechas.push(new Date(d)); // usamos la fecha original
            const dateStr = d.toISOString().split('T')[0];
            if (!pedidosPorDia[dateStr]) {
                pedidosPorDia[dateStr] = { total: 0, toneladas: 0, estados: {} } //esto es para cuabndo el dia no tiene pedidos entonces pasamos todo como 0 jej3j
            }
        }

        const cantidades = fechas.map(date => pedidosPorDia[date.toISOString().split('T')[0]].total);
        const toneladas = fechas.map(date => pedidosPorDia[date.toISOString().split('T')[0]].toneladas);

        const seriesData = [
            { label: "Pedidos", data: cantidades },
            { label: "Toneladas", data: toneladas },
        ];

        setPedidos({
            fechas: fechas.map(dateStr => new Date(dateStr)),
            seriesData
        });
    };

    //ESTA ES LA FUNCION QUE VOY A USAR PARA MOSTRAR EN EL GRAFICO LOS PEDIDOS POR MES VAMOS A VER QUE SALE
    //SI NO ME SALE RENUNCIO JAJAJ
    const processPedidosMensuales = (data) => {
        const now = new Date();
        const currentYear = now.getFullYear();

        // Procesar datos para obtener el total por mes
        const pedidosPorMes = data.reduce((acc, pedido) => {
            // Formato "YYYY-MM"
            const mes = pedido.mes;
            const mesStr = `${currentYear}-${String(mes).padStart(2, '0')}`;

            if (!acc[mesStr]) {
                acc[mesStr] = { total: 0, toneladas: 0 };
            }

            acc[mesStr].total += pedido.cantidad;
            acc[mesStr].toneladas += (pedido.kgentregados || 0) / 1000; // Convertir kg a toneladas

            return acc;
        }, {});

        // Generar fechas para cada mes del año actual
        const fechas = [];
        const cantidades = [];
        const toneladas = [];

        for (let m = 0; m < 12; m++) {
            const mesStr = `${currentYear}-${String(m + 1).padStart(2, '0')}`;
            fechas.push(new Date(currentYear, m, 1)); // Primer día de cada mes

            // Verificar y agregar datos por mes
            if (pedidosPorMes[mesStr]) {
                cantidades.push(pedidosPorMes[mesStr].total);
                toneladas.push(pedidosPorMes[mesStr].toneladas);
            } else {
                cantidades.push(0);
                toneladas.push(0);
            }
        }

        // Preparar datos para el gráfico
        const seriesData = [
            { label: "Pedidos", data: cantidades },
            { label: "Toneladas", data: toneladas },
        ];

        setPedidosMensuales({
            fechas,
            seriesData
        });

    };

    return (
        <Container spacing={2} sx={{ marginLeft: '10px', marginTop: '10px' }}>
            <Navbar />
            {/* {dataPedidos && console.log("data",dataPedidos)}<Container style={{ marginTop: '20px', spacing: 2 }}> </Container> */}
            {dataPedidos ?

                <>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row', // Por defecto, en pantallas grandes todo estará en una fila
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            flexWrap: 'wrap', // Permite el cambio de línea en dispositivos pequeños
                            marginBottom: '20px',
                        }}
                    >
                        {/* Título "Panel Granel" */}
                        <Typography
                            variant="h5"
                            style={{
                                flex: '1 1 auto', // Permite que ocupe espacio disponible
                                marginBottom: '10px',
                                textAlign: 'left',
                            }}
                        >
                            Panel Granel
                        </Typography>

                        {/* Contenedor de los botones y el mes seleccionado */}
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '10px',
                                flexWrap: 'wrap', // Cambia a columnas en dispositivos pequeños
                                flex: '1 1 auto', // Asegura que ocupe espacio disponible
                            }}
                        >
                            <IconButton
                                variant="contained"
                                onClick={handleMesAnterior}
                            >
                                <RemoveCircleOutline />
                            </IconButton>
                            <Typography
                                variant="h5"
                                style={{
                                    width: '200px', // Mantener el texto centrado con un ancho fijo
                                    textAlign: 'center',
                                }}
                            >
                                {meses[mes - 1]} {anio}
                            </Typography>
                            <IconButton
                                variant="contained"
                                onClick={handleMesSiguiente}
                            >
                                <AddCircleOutline />
                            </IconButton>
                        </div>
                    </div>


                    <Grid container spacing={2} mb={5}>
                        {/* Tarjeta de Pedidos */}
                        <Grid item xs={12} sm={6}>
                            <StyledCard>
                                <CardContent>
                                    <Grid container alignItems='center'>
                                        <Grid item>
                                            <AvatarWithMargin sx={{ bgcolor: '#ffeb3b', color: '#212121' }}>
                                                <ShoppingCartIcon />
                                            </AvatarWithMargin>
                                        </Grid>
                                        <Grid item xs>
                                            <TitleTypography variant='h4' component='div'>
                                                Pedidos
                                            </TitleTypography>
                                        </Grid>
                                    </Grid>
                                    <Grid container justifyContent='center' alignItems='center'>
                                        <Grid item xs={3} style={{ textAlign: 'right' }}>
                                            <NumberTypography>{pendientes.cantidad}</NumberTypography>
                                            <SubtitleTypography>Pendientes</SubtitleTypography>
                                        </Grid>
                                        <Grid item xs={3} style={{ textAlign: 'right' }}>
                                            <NumberTypography>{sin_asignar.cantidad}</NumberTypography>
                                            <SubtitleTypography>SinAsignar</SubtitleTypography>
                                        </Grid>
                                        <Grid item xs={3} style={{ textAlign: 'right' }}>
                                            {/* <NumberTypography>{dataPedidos.onDelivery}</NumberTypography> */}
                                            <NumberTypography>{asignados.cantidad}</NumberTypography>
                                            <SubtitleTypography>En Delivery</SubtitleTypography>
                                        </Grid>
                                        <Grid item xs={3} style={{ textAlign: 'right' }}>
                                            {/* <NumberTypography>{dataPedidos.entregados}</NumberTypography> */}
                                            <NumberTypography>{entregados}</NumberTypography>
                                            <SubtitleTypography>Entregados</SubtitleTypography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </StyledCard>
                        </Grid>

                        {/* Tarjeta de Facturación */}
                        <Grid item xs={12} sm={6}>
                            <StyledCardFacturacion>
                                <CardContent>
                                    <Grid container alignItems='center'>
                                        <Grid item>
                                            <AvatarWithMargin sx={{ bgcolor: '#FFC107', color: '#6A1B9A' }}>
                                                <Inventory2Icon />
                                            </AvatarWithMargin>
                                        </Grid>
                                        <Grid item xs>
                                            <TitleTypography variant='h4' component='div'>
                                                Facturación
                                            </TitleTypography>
                                        </Grid>
                                    </Grid>
                                    <Grid container justifyContent='center' alignItems='center'>
                                        <Grid item xs={4} style={{ textAlign: 'right' }}>
                                            <NumberTypographyFacturacion>
                                                {facturados.cantidad}
                                            </NumberTypographyFacturacion>
                                            <SubtitleTypography>Facturas</SubtitleTypography>
                                        </Grid>
                                        <Tooltip title={`Más información sobre Errores`} arrow>
                                            <Grid item xs={4}
                                                style={{ textAlign: 'right', cursor: 'pointer' }}
                                                onClick={() => alert("hola")}>
                                                <NumberTypographyFacturacion>
                                                    {errorfacturacion.cantidad}
                                                </NumberTypographyFacturacion>
                                                <SubtitleTypography>Errores</SubtitleTypography>
                                            </Grid>
                                        </Tooltip>
                                        <Tooltip title={`Pendientes de facturar`} arrow>
                                            <Grid item xs={2}
                                                style={{ textAlign: 'right', cursor: 'pointer' }}
                                                onClick={() => alert("hola")}>
                                                <NumberTypographyFacturacion>
                                                    {facturaespecial.cantidad}
                                                </NumberTypographyFacturacion>
                                                <SubtitleTypography>Pendientes</SubtitleTypography>
                                            </Grid>
                                        </Tooltip>
                                        <Grid item xs={2} style={{ textAlign: 'right' }}>
                                            <NumberTypographyFacturacion>
                                                {pendientedepago.cantidad}
                                            </NumberTypographyFacturacion>
                                            <SubtitleTypography>SinPago</SubtitleTypography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </StyledCardFacturacion>
                        </Grid>

                        {/* Tarjeta de Móviles */}
                        <Grid item xs={12} sm={6}>
                            <StyledCardMoviles>
                                <CardContent>
                                    <Grid container alignItems='center'>
                                        <Grid item>
                                            <AvatarWithMargin sx={{ bgcolor: '#FFEB3B', color: '#2E7D32' }}>
                                                <LocalShippingIcon /> {/* Ícono de camión de reparto */}
                                            </AvatarWithMargin>
                                        </Grid>
                                        <Grid item xs>
                                            <TitleTypography variant='h4' component='div'>
                                                Repartos
                                            </TitleTypography>
                                        </Grid>
                                    </Grid>
                                    <Grid container justifyContent='center' alignItems='center'>
                                        <Grid item xs={3} style={{ textAlign: 'right' }}>
                                            <NumberTypographyMoviles>{vehiculosAsignados.cantidad}</NumberTypographyMoviles>
                                            <SubtitleTypography>Asignados</SubtitleTypography>
                                        </Grid>
                                        <Grid item xs={3} style={{ textAlign: 'right' }}>
                                            <NumberTypographyMoviles>{onDelivery.cantidad}</NumberTypographyMoviles>

                                            <SubtitleTypography>En Delivery</SubtitleTypography>
                                        </Grid>
                                        <Grid item xs={6} style={{ textAlign: 'right' }}>
                                            <NumberTypographyMoviles>
                                                {tnDelivery.cantidad}
                                            </NumberTypographyMoviles>
                                            <SubtitleTypography>TNs Transportados</SubtitleTypography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </StyledCardMoviles>
                        </Grid>

                        {/* Tarjeta de Moviles 2 */}
                        <Grid item xs={12} sm={6}>
                            <StyledCardMoviles2>
                                <CardContent>
                                    <Grid container alignItems='center'>
                                        <Grid item>
                                            <AvatarWithMargin sx={{ bgcolor: '#00CCBB', color: '#BE0C32' }}>
                                                <TrainSharp />
                                            </AvatarWithMargin>
                                        </Grid>
                                        <Grid item xs>
                                            <TitleTypography variant='h4' component='div'>
                                                Granelitos
                                            </TitleTypography>
                                        </Grid>
                                    </Grid>
                                    <Grid container justifyContent='center' alignItems='center'>
                                        <Grid item xs={4} style={{ textAlign: 'right' }}>
                                            <NumberTypographyMoviles2>{pendientes.cantidad}</NumberTypographyMoviles2>
                                            <SubtitleTypography>TN Transportadas</SubtitleTypography>
                                        </Grid>
                                        <Grid item xs={4} style={{ textAlign: 'right' }}>
                                            <NumberTypographyMoviles2>{(tnEntregadas / 1000).toFixed(2)}</NumberTypographyMoviles2>
                                            <SubtitleTypography>TN Entregadas</SubtitleTypography>
                                        </Grid>
                                        <Grid item xs={4} style={{ textAlign: 'right' }}>
                                            {/* <NumberTypography>{dataPedidos.onDelivery}</NumberTypography> */}
                                            <NumberTypographyMoviles2>{asignados.cantidad}</NumberTypographyMoviles2>
                                            <SubtitleTypography>Kms Recorridos</SubtitleTypography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </StyledCardMoviles2>
                        </Grid>

                        <Grid item xs={12} md={5}>
                            <StyledGraphCard>
                                <CardContent>
                                    <TitleTypography variant='h6' component='div'>
                                        Ventas del Mes
                                    </TitleTypography>
                                    <LineChart
                                        xAxis={[
                                            {
                                                label: "Fecha",
                                                data: pedidos.fechas.map(date => new Date(date)), // Asegúrate de que sean objetos Date
                                                scaleType: "time",
                                                min: new Date(new Date().getFullYear(), new Date().getMonth(), 1), // 1er día del mes actual
                                                max: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0), // Último día del mes actual
                                                valueFormatter: (date) => {
                                                    const dateObj = date instanceof Date ? date : new Date(date);
                                                    return new Intl.DateTimeFormat('es-AR', { day: '2-digit', month: '2-digit' }).format(dateObj);
                                                },
                                            },
                                        ]}
                                        yAxis={[
                                            {
                                                label: "Cantidad de Pedidos",
                                                valueFormatter: (value) => Math.floor(value), // Muestra solo números enteros para la cantidad de pedidos
                                            },
                                            {
                                                label: "Toneladas",
                                                valueFormatter: (value) => value.toFixed(2), // Muestra toneladas con dos decimales
                                            },
                                        ]}
                                        series={[
                                            { label: "Pedidos", data: pedidos.seriesData[0].data },
                                            { label: "Toneladas", data: pedidos.seriesData[1].data },
                                        ]}
                                        height={400}
                                    />
                                </CardContent>
                            </StyledGraphCard>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <StyledGraphCard>
                                <CardContent>
                                    <TitleTypography variant='h6' component='div'>
                                        Ventas del año
                                    </TitleTypography>
                                    <LineChart
                                        xAxis={[
                                            {
                                                label: "Mes",
                                                data: pedidosMensuales.fechas.map(date => new Date(date)),
                                                scaleType: "time",
                                                min: new Date(new Date().getFullYear(), 0, 1), // Primer día del año actual
                                                max: new Date(new Date().getFullYear(), 11, 31), // Último día del año actual
                                                valueFormatter: (date) => {
                                                    const dateObj = date instanceof Date ? date : new Date(date);
                                                    return new Intl.DateTimeFormat('es-AR', { year: 'numeric', month: 'long' }).format(dateObj); // Muestra "Enero 2024", "Febrero 2024"
                                                },
                                            },
                                        ]}
                                        yAxis={[
                                            {
                                                label: "Cantidad de Pedidos",
                                                valueFormatter: (value) => Math.floor(value), // Muestra solo números enteros para la cantidad de pedidos
                                            },
                                            {
                                                label: "Toneladas",
                                                valueFormatter: (value) => value.toFixed(2), // Muestra toneladas con dos decimales
                                            },
                                        ]}
                                        series={[
                                            { label: "Pedidos", data: pedidosMensuales.seriesData[0].data },
                                            { label: "Toneladas", data: pedidosMensuales.seriesData[1].data },
                                        ]}
                                        height={400}
                                    />
                                </CardContent>
                            </StyledGraphCard>
                        </Grid>
                        <Grid item xs={12} md={2}>

                            <Card>
                                <StyledCardGranelitos>
                                    <CardContent>

                                        <TitleTypography variant='h4' component='div'>
                                            Granelitos
                                        </TitleTypography>

                                    </CardContent>
                                </StyledCardGranelitos>
                            </Card>

                        </Grid>
                    </Grid>
                </>
                : "No hay datos"}
        </Container>

    )
}

export default PanelControlGranel

//========================================================================
/*----- Component --------------------------------------------------------/
// Developer     : Candela Grosso
// Creation Date : 23/8/2024
// Version       : 1.0
// Description   :  Con este componente el usuario es capaz de :
    - Visualizar cosas la cantidad de pedidos por mes y en el linechart por dia


//========================================================================*/
// {selectedData && (
//     <Box sx={{ padding: 2, marginTop: 2, border: '1px solid grey' }}>
//         <Typography variant="h6">Detalles del {selectedData.date}</Typography>
//         <Typography>Cantidad de pedidos: {selectedData.detalles}</Typography>
//         <Typography>Toneladas entregadas: {selectedData.toneladas.toFixed(2)}</Typography>
//         {/* Mostrar cantidad de pedidos por estado */}
//         {Object.entries(pedidos.seriesData[0].data).map(([estado, cantidad]) => (
//             <Typography key={estado}>
//                 {estado}: {cantidad} pedidos
//             </Typography>
//         ))}
//     </Box>
// )}