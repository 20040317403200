import React, { useState } from 'react';
import { Container, Box } from '@mui/material';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Navbar from '../../Navbar';
import AutorizacionCompras from './AutorizacionCompras';
import OrdenesGeneradas from './OrdenesGeneradas';

export default function VistaJefeDePlanta() {
    const [opcion, setOpcion] = useState("requerimientos_autorizados")
    const [tab, setTabs] = useState(true)

    const handleChange = (event, newValue) => {
        setOpcion(newValue);
    };

    return (
        <div>
            <Navbar />
            <Box sx={{ borderBottom: 1, borderColor: "divider", marginTop: 1 }}>
                {tab ?
                    <Tabs
                        value={opcion}
                        onChange={handleChange}
                        textColor="primary"
                        indicatorColor="primary"
                        aria-label="Requerimientos autorizados"
                        variant="fullWidth"
                    >
                        <Tab value="requerimientos_autorizados" label="Requerimientos autorizados" />
                        <Tab value="ordenes" label="Ordenes Generadas" />
                    </Tabs>
                    : null}
            </Box>
            {opcion === "requerimientos_autorizados" ? <AutorizacionCompras tab={setTabs} /> : null}
            {opcion === "ordenes" ? <OrdenesGeneradas tab={setTabs} /> : null}
        </div>
    )
}
