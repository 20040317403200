// Developer     : Magalí Perea
// Creation Date : 27-2-2024
// Version       : 1
// Description   : Este componente contiene el formulario de alta de un cliente, y además brinda la posibilidad de poder registrar la dirección fiscal como dirección de entrega tildando un casillero.

import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Paper,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  PopoverPaper,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import InputMask from "react-input-mask";
import { FormHelperText } from "@mui/material";
import ErrorDialog from "../../../../../Dialogs/ErrorDialog";

//Funciones Propias
import { getData, getCodeArg } from "../../../../../../services/library";
import {
  validarCuit,
  existeCuit,
  existeDni,
} from "../../../../../factibilidades/validaciones";

function AltaCliente(props) {
  //Estados que guardarán los datos necesarios para completar los datos del formulario.
  const [codArg, setCodArg] = useState("");
  const [sucursales, setSucursales] = useState(null);
  const [empresas, setEmpresas] = useState(null);
  const [localidades, setLocalidades] = useState(null);
  const [comerciales, setComerciales] = useState(null);
  const [segmentos, setSegmentos] = useState(null);
  const [subsegmentos, setSubsegmentos] = useState(null);
  const [provincias, setProvincias] = useState(null);
  const [condiciones, setCondiciones] = useState(null);
  const [categorias, setCategorias] = useState(null);
  const [sitIva, setSitIva] = useState(null);
  const [tipoCliente, setTipoCliente] = useState(null);
  const [unidadesFact, setUnidadesFact] = useState(null);
  const [cambieProv, setCambieProv] = useState(false);
  const [cambieSeg, setCambieSeg] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);

  // Variables o estados que manejan componentes visuales
  const size = "small";
  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  //--------------------- FUNCIONES ---------------------//
  //Obtengo por única vez al momento de carga del componente y mediante llamadas a la api, los siguientes datos necesarios para rellenar el formulario:
  useEffect(() => {
    getData("/categorias", setCategorias);
    getData("/representantes/activos", setComerciales);
    getData("/segmentos", setSegmentos);
    getData("/provincias", setProvincias);
    getData("/condicion_pago", setCondiciones);
    getData("/condiciones_iva", setSitIva);
    getData("/tipocliente", setTipoCliente);
    getCodeArg(setCodArg);
    getData("/desplegables/unidadFact", setUnidadesFact);
    getData("/empresas/granel", setEmpresas);
  }, []);

  //Esta función almacena en estado los datos pertinentes al cliente que ingreso en el formulario.
  const almacenar = (e) => {
    props.setDataCliente({
      ...props.dataCliente,
      [e.target.name]: e.target.value === "" ? null : e.target.value,
    });
  };

  useEffect(() => {
    props.dataCliente.id_tipocliente === 1 &&
      getData("/sucursales/tipoPlanta", setSucursales);
  }, [props.dataCliente.id_tipocliente]);

  //Si escogí una provincia y una localidad, y luego vuelvo a cambiar la provincia, inicio nuevamente la localidad en string vacío.
  useEffect(() => {
    if (cambieProv) {
      props.dataCliente.id_localidad = "";
    }
    getData(
      "/localidades/prov/" + props.dataCliente.id_provincia,
      setLocalidades
    );
  }, [props.dataCliente.id_provincia, cambieProv]);

  //Idem provincia y localidad
  useEffect(() => {
    if (cambieSeg) {
      props.dataCliente.id_subsegmento = "";
    }
    props.dataCliente.id_segmento &&
      getData(
        "/subsegmentos/seg/" + props.dataCliente.id_segmento,
        setSubsegmentos
      );
  }, [props.dataCliente.id_segmento, cambieSeg]);

  //Esta función se ejecuta cuando tildamos "Es dirección de entrega", indicando que la direccion que fue registrada como FISCAL en el perfil del cliente, también debe ser registrada como dirección de entrega.
  const setAsDeliverySite = (e) => {
    props.setEsDomEntrega(e.target.checked); //Guardamos en el estado esDomEntrega, si checked es true or false

    if (e.target.checked) {
      //SI ES DOMICILIO DE ENTREGA:
      //Primero, compruebo que no haya nada en el índice 0:
      if (
        !props.listaDom[0].nombre_direccion &&
        !props.listaDom[0].id_provincia &&
        !props.listaDom[0].id_localidad &&
        !props.listaDom[0].telefono1 &&
        !props.listaDom[0].telefono2 &&
        !props.listaDom[0].telefono3 &&
        !props.listaDom[0].mails
      ) {
        //Si no hay nada en el índice 0, entonces ubico el domicilio fiscal allí
        const listaDom = [...props.listaDom];
        listaDom[0] = {
          ...props.listaDom[0],
          id_provincia: props.dataCliente.id_provincia,
          id_localidad: props.dataCliente.id_localidad,
          telefono1: props.dataCliente.telefono1,
          telefono2: props.dataCliente.telefono2,
          telefono3: props.dataCliente.telefono3,
          mails: props.dataCliente.mails,
          nombre_direccion: "Direccion De Entrega",
          id_producto: props.dataCliente.id_tipocliente === 1 ? 1 : "",
          es_domicilio_fiscal: true,
          // id_empresa:props.dataCliente.id_empresa,
          id_tipomoneda: 2,
        };
        props.setListaDom(listaDom);
      } else {
        //Si hay datos en el índice 0, entonces, añado un nuevo registro de domicilio de entrega:
        props.setListaDom([
          ...props.listaDom,
          {
            nro_dir: props.uuid,
            direccion_entrega: "",
            nombre_direccion: "Direccion de entrega",
            id_provincia: props.dataCliente.id_provincia,
            id_localidad: props.dataCliente.id_localidad,
            id_sucursal: "",
            contacto: "",
            telefono1: props.dataCliente.telefono1,
            telefono2: props.dataCliente.telefono2,
            telefono3: props.dataCliente.telefono3,
            mails: props.dataCliente.mails,
            latitud: "",
            longitud: "",
            usuario: localStorage.getItem("user"),
            activo: 1,
            verificada: 1,
            id_producto: props.dataCliente.id_tipocliente === 1 ? 1 : "",
            es_domicilio_fiscal: true,
            // id_empresa: props.dataCliente.id_empresa,
            id_tipomoneda: 2,
          },
        ]);
      }
      //SI CANCELO QUE SEA EL DOMICILIO DE ENTREGA:
    } else {
      //Si el domicilio fiscal era el único, no voy a borrar lista Dom, si no que voy a volver a iniciarlo con los valores en nada.
      if (props.listaDom.length === 1) {
        props.setListaDom([
          {
            nro_dir: props.uuid,
            direccion_entrega: "",
            nombre_direccion: "",
            id_provincia: "",
            id_localidad: "",
            id_sucursal: "",
            contacto: "",
            telefono1: "",
            telefono2: "",
            telefono3: "",
            mails: "",
            latitud: "",
            longitud: "",
            usuario: localStorage.getItem("user"),
            activo: 1,
            verificada: 1,
            id_producto: props.dataCliente.id_tipocliente === 1 ? 1 : "",
            // id_empresa: ""
          },
        ]);
      } else if (props.listaDom.length > 1) {
        const listaDom = props.listaDom.filter(
          (domicilio) => !domicilio.es_domicilio_fiscal
        );
        // Establecer el nuevo estado sin el domicilio fiscal
        props.setListaDom(listaDom);
      }
    }
  };

  //Si está tildado "esDomEntrega", esta función, lo que hace es reflejar las modificaciones que pueda haber luego de tildar el checkBox, a la dirección de entrega que se corresponde con la fiscal.
  const handleChangeDatosDir = (e) => {
    almacenar(e);
    e.target.name === "id_provincia" && setCambieProv(true);
    props.esDomEntrega && almacenarFiscalAsEntrega(e);
  };

  const almacenarFiscalAsEntrega = (e) => {
    //Si es domicilio de entrega, debe setearse en listaDom, donde el indice indica la posición del registro que es_domicilio_fiscal
    const listaDom = [...props.listaDom];
    const domFiscalIndex = listaDom.findIndex(
      (domicilio) => domicilio.es_domicilio_fiscal === true
    ); //Obtiene la posición del json donde está la dirección fiscal para poder modificar.

    if (domFiscalIndex !== -1) {
      //Si el índice existe
      listaDom[domFiscalIndex] = {
        ...listaDom[domFiscalIndex],
        [e.target.name]: e.target.value,
      };
      props.setListaDom(listaDom);
    }
  };

  //Si no me fijo si hay algo en el campo dataCliente.cuit, y paso por el txtfield, y se dispara el "onblur", dice que ya existe el DNI o CUIT (cuando en realidad, el campo está vacío). Ergo: antes de verificar si ese cuit o dni, voy a fijarme si props.dataCliente.cuit, tiene algun digito que no sea "0". No lo puedo hacer con .length porque al utilizar una mascara, el campo ya arranca teniendo un length de 10(caso dni) o mas(caso cuit). Entonces lo unico que me queda es verificar si alguno de esos digitos no es 0. Si props.dataCliente.cuit contiene digitos que no son "0", entonces lo verifica.

  async function verificarCuit(e, setDataCliente, dataCliente) {
    const hayCuit = /[1-9]/.test(props.dataCliente.cuit);

    if (hayCuit) {
      const validado = validarCuit(e, props.setDataCliente, props.dataCliente);
      if (validado) {
        const coincidencias = await existeCuit(props.dataCliente.cuit);
        if (coincidencias.length > 0) {
          setErrorDialog(true);
          props.setDataCliente({ ...props.dataCliente, cuit: "" });
        }
      }
    }
  }

  async function verificarDni(e, setDataCliente, dataCliente) {
    const hayDni = /[1-9]/.test(props.dataCliente.cuit);

    if (hayDni) {
      const coincidencias = await existeDni(props.dataCliente.cuit);
      if (coincidencias.length > 0) {
        setErrorDialog(true);
        props.setDataCliente({ ...props.dataCliente, cuit: "" });
      }
    }
  }

  return (
    <div>
      <Container maxWidth="xl">
        <form>
          <Box
            mb={1}
            mt={1}
            backgroundColor="#ebebeb"
            p={2}
            border={0.5}
            borderColor={"#ABB2B9"}
            borderRadius={4}
          >
            <Grid container spacing={1.3}>
              <Grid item xs={4.5}>
                <TextField
                  autoFocus
                  size={size}
                  style={style}
                  placeholder="Razon Social"
                  type="text"
                  required
                  name="razon_social"
                  id="razon_social"
                  label="Razon Social"
                  onChange={almacenar}
                  value={
                    props.dataCliente.razon_social &&
                    props.dataCliente.razon_social.replace(/\b\w/g, (l) =>
                      l.toUpperCase()
                    )
                  }
                  inputProps={{ maxLength: 200 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={1.5}>
                <TextField
                  size={size}
                  style={style}
                  placeholder="Sisven ID"
                  type="text"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{ maxLength: 6 }} //solo 2 numeros
                  name="sisven_id"
                  label="Sisven ID"
                  color="primary"
                  onChange={almacenar}
                  value={props.dataCliente.sisven_id}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MeetingRoomIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={1.5}>
                <FormControl disabled style={style} size={size}>
                  <InputLabel> Tipo Cliente</InputLabel>
                  <Select
                    label="Tipo Cliente"
                    name="id_tipocliente"
                    value={props.dataCliente.id_tipocliente}
                    onChange={(e) => {
                      almacenar(e);
                    }}
                  >
                    {tipoCliente
                      ? tipoCliente.map((elemento) => (
                          <MenuItem
                            key={elemento.idtipocliente}
                            value={elemento.idtipocliente}
                          >
                            {elemento.nombre}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={1.5}>
                <FormControl
                  style={style}
                  size={size}
                  // required={props.dataCliente.id_tipocliente !== 1}
                  disabled={props.dataCliente.id_tipocliente !== 1}
                >
                  <InputLabel> Facturacion En: </InputLabel>
                  <Select
                    label="Facturacion En: "
                    name="unidad_fact"
                    value={props.dataCliente.unidad_fact}
                    onChange={almacenar}
                  >
                    {unidadesFact
                      ? unidadesFact.map((elemento) => (
                          <MenuItem key={elemento.id} value={elemento.id}>
                            {elemento.valor}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl required style={style} size={size}>
                  <InputLabel> Situacion Iva</InputLabel>
                  <Select
                    label="Situacion Iva"
                    name="situacion_iva"
                    value={props.dataCliente.situacion_iva}
                    onChange={almacenar}
                  >
                    {sitIva
                      ? sitIva.map((elemento) => (
                          <MenuItem key={elemento.id} value={elemento.id}>
                            {elemento.descripcion}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl required style={style} size={size}>
                  <InputLabel>Categoría Crediticia</InputLabel>
                  <Select
                    name="id_categoria"
                    value={props.dataCliente.id_categoria}
                    label="Categoria crediticia"
                    onChange={almacenar}
                  >
                    {categorias
                      ? categorias.map((elemento) => (
                          <MenuItem key={elemento.id} value={elemento.id}>
                            {elemento.categoria} ({elemento.descripcion})
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl style={style} size={size}>
                  <InputLabel>Cuit/Dni</InputLabel>
                  <Select
                    name="tipo_doc_afip"
                    labelId="Cuit/Dni"
                    value={props.dataCliente.tipo_doc_afip}
                    label="Sucursal"
                    onChange={almacenar}
                    // onChange={(event) => {
                    //   props.setTipo(event.target.value);
                    // }}
                  >
                    <MenuItem key={props.tipo} value={80}>
                      Cuit
                    </MenuItem>
                    <MenuItem key={props.tipo} value={96}>
                      Dni
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {props.dataCliente.tipo_doc_afip === 80 ? (
                <Grid item xs={2}>
                  <InputMask
                    mask="99-99999999-9"
                    value={props.dataCliente.cuit}
                    disabled={false}
                    maskChar=" "
                    onBlur={(e) =>
                      verificarCuit(e, props.setDataCliente, props.dataCliente)
                    }
                    // onBlur={(e) =>
                    //   validarCuit(e, props.setDataCliente, props.dataCliente)
                    // }
                    onChange={(e) =>
                      props.setDataCliente({
                        ...props.dataCliente,
                        cuit: e.target.value,
                      })
                    }
                  >
                    {() => (
                      <TextField
                        size={size}
                        style={style}
                        defaultValue=""
                        type="text"
                        name="cuit"
                        label="Numero Cuit"
                      />
                    )}
                  </InputMask>
                </Grid>
              ) : (
                <Grid item xs={2}>
                  <InputMask
                    mask="99.999.999"
                    value={props.dataCliente.cuit}
                    disabled={false}
                    maskChar=" "
                    onChange={almacenar}
                    onBlur={(e) =>
                      verificarDni(e, props.setDataCliente, props.dataCliente)
                    }
                  >
                    {() => (
                      <TextField
                        size={size}
                        style={style}
                        defaultValue=""
                        type="text"
                        name="cuit"
                        label="Numero Dni"
                      />
                    )}
                  </InputMask>
                </Grid>
              )}
              <Grid item xs={3}>
                <FormControl required style={style} size={size}>
                  <InputLabel>Condición de pago</InputLabel>
                  <Select
                    name="id_condicionpago"
                    labelId="id_condicionpago"
                    value={props.dataCliente.id_condicionpago}
                    label="Condición de pago"
                    onChange={almacenar}
                  >
                    {condiciones
                      ? condiciones.map((elemento) => (
                          <MenuItem key={elemento.id} value={elemento.id}>
                            {elemento.condicion}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size={size}
                  style={style}
                  fullWidth
                  placeholder="100000"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{ maxLength: 9 }} //solo 2 numeros
                  name="limite_credito"
                  label="Límite de crédito"
                  color="primary"
                  onChange={almacenar}
                  value={props.dataCliente.limite_credito}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  size={size}
                  required
                  style={style}
                  placeholder="Direccion Fiscal"
                  id="direccion_fiscal"
                  name="direccion_fiscal"
                  label="Direccion Fiscal"
                  color="primary"
                  value={
                    props.dataCliente.direccion_fiscal &&
                    props.dataCliente.direccion_fiscal.replace(/\b\w/g, (l) =>
                      l.toUpperCase()
                    )
                  }
                  onChange={almacenar}
                  inputProps={{ maxLength: 120 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationOnIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={2}>
                <FormControl required fullWidth size={size}>
                  <InputLabel> Provincia </InputLabel>
                  <Select
                    style={{ backgroundColor: "#FFFFFF" }}
                    label="Provincia"
                    color="primary"
                    name="id_provincia"
                    value={props.dataCliente.id_provincia}
                    onChange={(e) => handleChangeDatosDir(e)}
                  >
                    {provincias
                      ? provincias.map((elemento) => (
                          <MenuItem key={elemento.id} value={elemento.id}>
                            {elemento.provincia}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl required fullWidth size={size}>
                  <InputLabel>Localidad</InputLabel>
                  <Select
                    style={{ backgroundColor: "#FFFFFF" }}
                    label="Localidad"
                    name="id_localidad"
                    value={props.dataCliente.id_localidad}
                    onChange={(e) => handleChangeDatosDir(e)}
                  >
                    {localidades
                      ? localidades.map((elemento) => (
                          <MenuItem key={elemento.id} value={elemento.id}>
                            {elemento.localidad}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={2}>
                <FormControl required fullWidth size={size}>
                  <InputLabel>Empresa</InputLabel>
                  <Select
                    style={{ backgroundColor: "#FFFFFF" }}
                    name="id_empresa"
                    value={props.dataCliente.id_empresa}
                    label="Empresa"
                    onChange={almacenar}
                  >
                    {empresas
                      ? empresas.map((elemento) => (
                          <MenuItem
                            key={elemento.idempresa}
                            value={elemento.idempresa}
                          >
                            {elemento.empresa}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={2}>
                <FormControlLabel
                  labelPlacement="right"
                  control={
                    <Checkbox
                      checked={props.esDomEntrega}
                      onChange={(e) => setAsDeliverySite(e)}
                    />
                  }
                  label="Domicilio de entrega"
                />
              </Grid>

              <Grid item xs={2}>
                <FormControl required fullWidth size={size}>
                  <InputLabel>Sucursal</InputLabel>
                  <Select
                    style={{ backgroundColor: "#FFFFFF" }}
                    name="id_sucursal"
                    value={props.dataCliente.id_sucursal}
                    label="Sucursal"
                    onChange={almacenar}
                  >
                    {sucursales
                      ? sucursales.map((elemento) => (
                          <MenuItem key={elemento.id} value={elemento.id}>
                            {elemento.nombre}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={3}>
                <FormControl required fullWidth size={size}>
                  <InputLabel>Segmento</InputLabel>
                  <Select
                    style={{ backgroundColor: "#FFFFFF" }}
                    label="Segmento"
                    name="id_segmento"
                    value={props.dataCliente.id_segmento}
                    onChange={(e) => {
                      almacenar(e);
                      setCambieSeg(true);
                    }}
                  >
                    {segmentos
                      ? segmentos.map((elemento) => (
                          <MenuItem key={elemento.id} value={elemento.id}>
                            {elemento.segmento}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth size={size}>
                  <InputLabel>Subsegmento</InputLabel>
                  <Select
                    style={{ backgroundColor: "#FFFFFF" }}
                    label="Subsegmento"
                    name="id_subsegmento"
                    value={props.dataCliente.id_subsegmento}
                    onChange={almacenar}
                  >
                    {subsegmentos
                      ? subsegmentos.map((elemento) => (
                          <MenuItem key={elemento.id} value={elemento.id}>
                            {elemento.subsegmento} -{" "}
                            {elemento.codigo_subsegmento}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl required fullWidth size={size}>
                  <InputLabel id="lblcomercial">Comercial</InputLabel>
                  <Select
                    style={{ backgroundColor: "#FFFFFF" }}
                    label="Comercial"
                    name="id_comercial"
                    value={props.dataCliente.id_comercial}
                    onChange={almacenar}
                  >
                    {comerciales
                      ? comerciales.map((elemento) => (
                          <MenuItem key={elemento.id} value={elemento.id}>
                            {elemento.nombre_apellido}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size={size}
                  style={style}
                  placeholder="Mails"
                  id="mails"
                  name="mails"
                  label="Mails"
                  color="primary"
                  value={props.dataCliente.mails}
                  onChange={(e) => handleChangeDatosDir(e)}
                  // helperText="Para añadir más de un correo, sepárelos con ; (punto y coma)"
                  inputProps={{ maxLength: 500 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <ContactMailIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <FormHelperText sx={{ bgcolor: "#ebebeb", color: "#5D6D7E" }}>
                  Para añadir más de un correo, sepárelos con ; (punto y coma)
                </FormHelperText>
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth>
                  <TextField
                    size={size}
                    style={{ backgroundColor: "#FFFFFF" }}
                    fullWidth
                    type="text"
                    color="primary"
                    placeholder="Telefono 1"
                    name="telefono1"
                    label="Telefono 1"
                    value={props.dataCliente.telefono1}
                    onChange={(e) => handleChangeDatosDir(e)}
                    inputProps={{
                      maxLength: 10,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {codArg}
                        </InputAdornment>
                      ),
                    }}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <FormHelperText sx={{ bgcolor: "#ebebeb", color: "#5D6D7E" }}>
                    "Cód área (sin 0) + número sin el 15. Ej:341xxxxxx "
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth>
                  <TextField
                    size={size}
                    style={{ backgroundColor: "#FFFFFF" }}
                    fullWidth
                    type="text"
                    color="primary"
                    placeholder="Telefono 2"
                    name="telefono2"
                    label="Telefono 2"
                    value={props.dataCliente.telefono2}
                    onChange={(e) => handleChangeDatosDir(e)}
                    inputProps={{
                      maxLength: 10,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {codArg}
                        </InputAdornment>
                      ),
                    }}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <FormHelperText sx={{ bgcolor: "#ebebeb", color: "#5D6D7E" }}>
                    "Cód área (sin 0) + número sin el 15. Ej:341xxxxxx "
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth>
                  <TextField
                    size={size}
                    style={{ backgroundColor: "#fff" }}
                    fullWidth
                    type="text"
                    color="primary"
                    placeholder="Telefono 3"
                    name="telefono3"
                    label="Telefono 3"
                    onChange={(e) => handleChangeDatosDir(e)}
                    value={props.dataCliente.telefono3}
                    inputProps={{
                      maxLength: 10,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {codArg}
                        </InputAdornment>
                      ),
                    }}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <FormHelperText sx={{ bgcolor: "#ebebeb", color: "#5D6D7E" }}>
                    "Cód área (sin 0) + número sin el 15. Ej:341xxxxxx "
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={props.dataCliente.id_tipocliente === 3 ? 12 : 9}>
                <TextField
                  size={size}
                  style={{ backgroundColor: "#FFFFFF" }}
                  fullWidth
                  name="observaciones"
                  type="text"
                  inputProps={{ maxLength: 600 }}
                  label="Observaciones"
                  multiline
                  maxRows={4}
                  value={props.dataCliente.observaciones}
                  onChange={almacenar}
                />
              </Grid>

              {props.dataCliente && props.dataCliente.situacion_iva === 4 && (
                <Grid item xs={3}>
                  <FormControlLabel
                    labelPlacement="right"
                    control={
                      <Checkbox
                        checked={props.dataCliente.factura_consfinal}
                        onChange={(e) =>
                          props.setDataCliente({
                            ...props.dataCliente,
                            factura_consfinal: e.target.checked ? 1 : 0,
                          })
                        }
                      />
                    }
                    label="Factura A Consumidor Final"
                  />
                </Grid>
              )}

              {props.dataCliente && props.dataCliente.situacion_iva === 1 && (
                <Grid item xs={3}>
                  <FormControlLabel
                    labelPlacement="right"
                    control={
                      <Checkbox
                        checked={props.dataCliente.facturacion_especial}
                        onChange={(e) =>
                          props.setDataCliente({
                            ...props.dataCliente,
                            facturacion_especial: e.target.checked ? 1 : 0,
                          })
                        }
                      />
                    }
                    label="Facturacion Especial"
                  />
                </Grid>
              )}

              {props.dataCliente.id_tipocliente === 2 ||
              props.dataCliente.id_tipocliente === 1 ? (
                <>
                  <Grid item xs={3}>
                    <FormControlLabel
                      checked={props.dataCliente.pesada_cliente === 1}
                      onChange={(e) =>
                        props.setDataCliente({
                          ...props.dataCliente,
                          ["pesada_cliente"]: e.target.checked ? 1 : 0,
                        })
                      }
                      control={<Checkbox />}
                      label="PESADA CLIENTE"
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                    />
                  </Grid>
                </>
              ) : null}
            </Grid>
          </Box>
        </form>
        {errorDialog && (
          <ErrorDialog
            open={errorDialog}
            setOpen={setErrorDialog}
            title={"CLIENTE EXISTENTE"}
            subtitle={
              "El CUIT/DNI que intenta ingresar YA FUE REGISTRADO bajo otra razón social. Revise el listado de clientes."
            }
          />
        )}
        <br></br>
      </Container>
    </div>
  );
}

export default AltaCliente;
