//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio/Nicolas Pascucci
// Creation Date : 01/02/2024
// Version : 1
// Description : tabla de resumen de las solicitudes de abastecimiento semanales
//------Changes ----------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useEffect, useState, useMemo } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Button,
} from "@mui/material";
import moment from "moment";
import { UrlApi } from "../../services/apirest";
import { styled } from "@mui/system";
import Navbar from "../Navbar";
import { logEventos } from "../../services/services";
//CSV
import { ExportToCsv } from "export-to-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { blueGrey } from "@mui/material/colors";

const HeaderCell = styled(TableCell)({
  marginTop: "2px",
  fontSize: 16,
  color: "white",
  fontWeight: "normal",
  backgroundColor: "#2471A3",
  borderColor: "black",
  padding: "6.3px",
});

const TotalCell = styled(TableCell)({
  padding: "6px",
});

const MatrizDobleEntrada = ({ datos }) => {
  const [sucursales, setSucursales] = useState();
  const [solicitudes, setSolicitudes] = useState();

  useEffect(() => {
    logEventos("Acceso al menú resumen semanal", "Resumen Semanal", "Ver resumen de pedidos semanales", localStorage.getItem("user"))
}, [])

  const fechaActual = moment();
 // console.log(fechaActual);
  // Calcula la fecha del próximo lunes
  const lunesProximo = fechaActual.clone().day(8); // 8 representa el día lunes de la próxima semana

  // Obtén los nombres y fechas de los días de la semana para la próxima semana
  const diasProximaSemana = [];
  for (let i = 0; i < 6; i++) {
    const fechaDia = lunesProximo.clone().add(i, "days");
    diasProximaSemana.push({
      id: i, // Utilizamos la posición en el bucle como id
      nombre: fechaDia.format("dddd"),
      fecha: fechaDia.format("YYYY-MM-DD"),
    });
  }

  // Obtener un rango de 6 días
  const fechaInicio = moment(); // Utiliza la fecha
  const fechasRango = Array.from({ length: 6 }, (_, index) =>
    moment(fechaInicio).add(index, "days")
  );
  const obtenerSucursales = () => {
    fetch(UrlApi + "/sucursales/tipoPlanta")
      .then((data) => data.json())
      .then((data) => setSucursales(data));
  };
  useEffect(() => {
    obtenerSucursales();
  }, []);

  const obtenerSolicitudes = () => {
    fetch(UrlApi + "/solicitudes_abastecimiento")
      .then((data) => data.json())
      .then((data) => setSolicitudes(data));
  };
  useEffect(() => {
    obtenerSolicitudes();
  }, []);
 // console.log(solicitudes);


  const obtenerDatosPorFechaYSucursal = (fecha, sucursalId, tipoGas) => {
    // Filtrar las solicitudes por fecha y sucursal
    if (solicitudes) {
      const solicitudesFiltradas = solicitudes.filter((solicitud) => {
        const fechaSolicitud = moment(solicitud.para_fecha).format("YYYY-MM-DD");
        return fechaSolicitud === fecha && solicitud.id_sucursal === sucursalId;
      });
  
      // Verificar si hay datos filtrados
      if (solicitudesFiltradas.length > 0) {
        const solicitud = solicitudesFiltradas[0]; // Supongamos que solo hay una solicitud por celda
        // Devolver solo el valor numérico del tipo de gas correspondiente
        return solicitud[tipoGas] || 0;
      } else {
        return "-";
      }
    }
  };
  
  
  const [fechaInicioSemana, setFechaInicioSemana] = useState(
    fechaActual.clone().day(8)
  );
  const diasSemanaActual = [];
  const obtenerTotalesPorSemana = (sucursalId) => {
    if (solicitudes) {
      const totales = {
        propano: 0,
        butano: 0,
      };

      diasSemanaActual.forEach((fecha) => {
        const solicitudesFiltradas = solicitudes.filter(
          (solicitud) =>
            moment(solicitud.para_fecha).format("YYYY-MM-DD") === fecha.fecha &&
            solicitud.id_sucursal === sucursalId
        );

        solicitudesFiltradas.forEach((solicitud) => {
          totales.propano += solicitud.propano || 0;
          totales.butano += solicitud.butano || 0;
        });
      });

      return totales;
    }
  };
  const [totalesPorSucursal, setTotalesPorSucursal] = useState({});
  const [totalGeneral, setTotalGeneral] = useState({ butano: 0, propano: 0 });

  useEffect(() => {
    if (sucursales && solicitudes) {
      const totalesPorSucursal = {};
      const totalGeneralCalculado = { butano: 0, propano: 0 };

      sucursales.forEach((sucursal) => {
        totalesPorSucursal[sucursal.id] = obtenerTotalesPorSemana(sucursal.id);
        totalGeneralCalculado.butano +=
          totalesPorSucursal[sucursal.id]?.butano || 0;
        totalGeneralCalculado.propano +=
          totalesPorSucursal[sucursal.id]?.propano || 0;
      });

      setTotalesPorSucursal(totalesPorSucursal);
      setTotalGeneral(totalGeneralCalculado);
    }
  }, [sucursales, solicitudes, fechaInicioSemana]);

  // ========================================================================================================================== //
const [cambio, setCambio] = useState(true)
 // console.log(fechaInicioSemana, "lunes proximo");
  const irSemanaAnterior = () => {
    setFechaInicioSemana((prevFecha) => prevFecha.clone().subtract(7, "days"));
    setCambio(true)
  };

  const irSemanaSiguiente = () => {
    setFechaInicioSemana((prevFecha) => prevFecha.clone().add(7, "days"));
    setCambio(true)
  };

  for (let i = 0; i < 6; i++) {
    const fechaDia = fechaInicioSemana.clone().add(i, "days");
    diasSemanaActual.push({
      id: i,
      nombre: fechaDia.format("dddd"),
      fecha: fechaDia.format("YYYY-MM-DD"),
    });
  }

  const obtenerSumaPorTipoGasYFecha = (tipoGas, fecha) => {
    if (solicitudes) {
      let suma = 0;
      solicitudes.forEach((solicitud) => {
        if (moment(solicitud.para_fecha).format("YYYY-MM-DD") === fecha) {
          suma += solicitud[tipoGas] || 0;
        }
      });
      return suma;
    }
    return 0;
  };
  // ========================================================================================================================== //
    //CSV
    const [solicitudesCSV, setSolicitudesCSV] = useState();
   // console.log("dias semana actual", diasSemanaActual);
      const obtenerSolicitudesCSV = () => {
        fetch(UrlApi + "/solicitudes_abastecimiento/csv/'"+diasSemanaActual[0].fecha+"'/'"+diasSemanaActual[5].fecha+"'")
          .then((data) => data.json())
          .then((data) => setSolicitudesCSV(data));
      };
      // CSV
      useEffect(() => {
        if (cambio === true) {
          obtenerSolicitudesCSV();
          setCambio(false)
        }
      }, [solicitudes,cambio]);

  const columnsCSV = useMemo(
    () => [
      { header: "Fecha            ", accessorKey: "para_fecha", size: 150 },
      {
        header: "Cantidad de Butano",
        accessorKey: "butano",
        size: 100,
      },
      {
        header: "Cantidad de Propano",
        accessorKey: "cantidad",
        size: 110,
      },
      {
        header: "Sucursal",
        accessorKey: "nombre",
        size: 100,
      },
      { header: "Usuario", accessorKey: "usuario", size: 100 },
    ],
    []
  );

  //CSV
  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    filename: `Lista Solicitudes de Abastecimiento`,
    headers: columnsCSV.map((c) => c.header),
  };
  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportData = () => {
    csvExporter.generateCsv(solicitudesCSV);
  };

  //*************************************************************************/

  return (
    <>
      <Navbar />
      <div style={{ textAlign: "center", position: "relative", marginTop: 13 }}>
        <Button onClick={irSemanaAnterior} variant="contained" size="small">
          &lt; Semana Anterior
        </Button>
        <Button
          onClick={irSemanaSiguiente}
          variant="contained"
          sx={{ marginLeft: 1 }}
          size="small"
        >
          Semana Siguiente &gt;
        </Button>
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            marginTop: 3,
            marginRight: 6,
          }}
        >
          <Button
            onClick={handleExportData}
            variant="contained"
            style={{ backgroundColor: blueGrey[400], color: "white" }}
            size="small"
            endIcon={<FileDownloadIcon />}
          >
            Exportar a CSV
          </Button>
        </div>
      </div>
      <Paper elevation={3}>
      <Table sx={{ mt: 1 }}>
  <TableHead>
    <TableRow>
      <HeaderCell sx={{border: "1px solid white"}}>
        <b>Sucursal / Fecha</b>
      </HeaderCell>
      {diasSemanaActual.map((item, index) => (
        <React.Fragment key={index}>
          <HeaderCell align="center" colSpan={2} sx={{border: "1px solid white"}}>
            <b>{moment(item.fecha).format("DD/MM")}</b>
          </HeaderCell>
          {/* <HeaderCell align="center">
            <b>{moment(item.fecha).format("DD/MM")}</b>
          </HeaderCell> */}
        </React.Fragment>
      ))}
      <HeaderCell sx={{border: "1px solid white"}}>Total Butano</HeaderCell>
      <HeaderCell sx={{border: "1px solid white"}}>Total Propano</HeaderCell>
    </TableRow>
    <TableRow>
      <HeaderCell sx={{border: "1px solid white"}}></HeaderCell>
      {diasSemanaActual.map((item, index) => (
        <React.Fragment key={index}>
          <HeaderCell align="center" sx={{border: "1px solid white"}}>
            <b>Butano</b>
          </HeaderCell>
          <HeaderCell align="center" sx={{border: "1px solid white"}}>
            <b>Propano</b>
          </HeaderCell>
        </React.Fragment>
      ))}
      <HeaderCell sx={{border: "1px solid white"}}></HeaderCell>
      <HeaderCell sx={{border: "1px solid white"}}></HeaderCell>
    </TableRow>
  </TableHead>
  <TableBody>
    {sucursales
      ? sucursales.map((sucursal) => (
          <React.Fragment key={sucursal.id}>
            <TableRow>
              <HeaderCell sx={{border: "1px solid white"}}>
                {sucursal.id} - {sucursal.nombre}
              </HeaderCell>
              {diasSemanaActual.map((fecha, index) => (
                <React.Fragment key={`${fecha}-${index}`}>
                  <TotalCell align="center" sx={{borderLeft: "1px solid black", borderRight: "1px solid black"}}>
                    {obtenerDatosPorFechaYSucursal(fecha.fecha, sucursal.id, 'butano')}
                  </TotalCell>
                  <TotalCell align="center" sx={{borderLeft: "1px solid black", borderRight: "1px solid black"}}>
                    {obtenerDatosPorFechaYSucursal(fecha.fecha, sucursal.id, 'propano')}
                  </TotalCell>
                </React.Fragment>
              ))}
              <TotalCell align="center">
                <b>
                  {solicitudes
                    ? obtenerTotalesPorSemana(sucursal.id).butano
                    : null}
                </b>
              </TotalCell>
              <TotalCell align="center" sx={{borderLeft: "1px solid black", borderRight: "1px solid black"}}>
                <b>
                  {solicitudes
                    ? obtenerTotalesPorSemana(sucursal.id).propano
                    : null}
                </b>
              </TotalCell>
            </TableRow>
          </React.Fragment>
        ))
      : null}
    {totalesPorSucursal && sucursales && (
      <TableRow>
        <HeaderCell style={{ fontSize: "1.2em" }}>
          <b>Total</b>
        </HeaderCell>
        {diasSemanaActual.map((fecha, index) => (
    <React.Fragment key={`${fecha}-${index}`}>
      <TotalCell align="center" sx={{border: "1px solid black", fontSize: "1.2em"}}>
        {obtenerSumaPorTipoGasYFecha('butano', fecha.fecha)}
      </TotalCell>
      <TotalCell align="center" sx={{border: "1px solid black", fontSize: "1.2em"}}>
        {obtenerSumaPorTipoGasYFecha('propano', fecha.fecha)}
      </TotalCell>
    </React.Fragment>
  ))}
        <TotalCell align="center" style={{ fontSize: "1.2em", borderLeft: "1px solid black", borderRight: "1px solid black", borderBottom: "1px solid black" }}>
          <b>{totalGeneral.butano}</b>
        </TotalCell>
        <TotalCell align="center" style={{ fontSize: "1.2em", borderLeft: "1px solid black", borderRight: "1px solid black", borderBottom: "1px solid black" }}>
          <b>{totalGeneral.propano}</b>
        </TotalCell>
      </TableRow>
    )}
  </TableBody>
</Table>

      </Paper>
      <br></br>
      <br></br>
    </>
  );
};

export default MatrizDobleEntrada;