import Navbar from "../../../Navbar";
import React, { useEffect, useState, useMemo } from "react";
import { UrlApi } from "../../../../services/apirest";
import {
  Box,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Tooltip,
  TextField,
  Stack,
  IconButton,
  Button,
  InputAdornment,
  Typography,
  FormControl,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //idioma
import { createTheme, ThemeProvider, useTheme } from "@mui/material";

// ICONOS
import AddBoxIcon from "@mui/icons-material/AddBox";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";

//Notification
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Checkbox from "@mui/material/Checkbox";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function EnsayoItems() {
  const [tabla, setTabla] = useState(true);
  const [update, setUpdate] = useState(false);
  const [datosformulario, setDatosformulario] = useState({
    item: "",
    descripcion: "",
    visibilidad: 0,
    visible: 0,
    signo: 0,
    olor: 0,
    texto: 0,
    estado: 1,
  });

  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [items, setItems] = useState();

  const obtenerItems = () => {
    fetch(UrlApi + "/items_ef")
      .then((data) => data.json()) //mando los datos que recibo en json
      .then((data) => setItems(data));
  };

  useEffect(() => {
    obtenerItems();
  }, [tabla]);

  // FUNCION QUE SE EJECUTA CADA VEZ QUE HAY UN CAMBIO EN LA BASE DE DATOS
  // SIRVE PARA QUE LA TABLA MOSTRADA SE ACTUALICE EN TIEMPO REAL
  useEffect(() => {
    if (update) obtenerItems();
  }, [update]);

  //*************************************************************************************************************************************/
  // ----------------------------- CHECKBOXES -----------------------------

  // CHECKBOX Visibilidad
  // ORIGINAL:
  const [checkVisibilidad, setCheckVisibilidad] = useState(false);

  const handleOnChangeVisibilidad = () => {
    setCheckVisibilidad(!checkVisibilidad);
    {
      checkVisibilidad
        ? (datosformulario.visibilidad = 0)
        : (datosformulario.visibilidad = 1);
    }
    console.log("VISIBILIDAD: ", datosformulario.visibilidad);
    console.log("VISIBILIDAD: ", checkVisibilidad);
  };

  // CHECKBOX Visibilidad
  // const [checkVisibilidad2, setCheckVisibilidad2] = useState(false);

  // const handleOnChangeVisibilidad2 = () => {
  //   setCheckVisibilidad(!checkVisibilidad);
  //   {
  //     checkVisibilidad
  //       ? (datosformulario.visibilidad = 0)
  //       : (datosformulario.visibilidad = 1);
  //   }
  //   console.log("VISIBILIDAD: ", datosformulario.visibilidad);
  //   console.log("VISIBILIDAD: ", checkVisibilidad);
  // };

  // ------------------------------------------------------------------------
  // const [checkVisibilidadEditar, setCheckVisibilidadEditar] = useState(
  //   datosformulario.visibilidad
  // );

  // const handleOnChangeVisibilidadEditar = () => {
  //   setCheckVisibilidadEditar(!checkVisibilidadEditar);
  //   {
  //     checkVisibilidadEditar
  //       ? (datosformulario.visibilidad = 0)
  //       : (datosformulario.visibilidad = 1);
  //   }

  //   console.log("VISIBILIDAD: ", datosformulario.visibilidad);
  //   console.log("VISIBILIDAD: ", checkVisibilidadEditar);
  // };
  // ------------------------------------------------------------------------

  // const checkactiveVisibilidad = () => {
  //   setCheckVisibilidad(true);
  //   datosformulario.visibilidad = 1;
  //   console.log("VISIBILIDAD: ", datosformulario.visibilidad);
  // };

  // const checkoffVisibilidad = () => {
  //   setCheckVisibilidad(false);
  //   datosformulario.visibilidad = 0;
  //   console.log("VISIBILIDAD: ", datosformulario.visibilidad);
  // };

  // ------ PROBANDO (BORRAR?) ------
  // const [agreement, setAgreement] = useState(false);

  // const handleChange = (event) => {
  //   setAgreement(event.target.checked);
  // };
  // ---------------------------------------------------------

  // CHECKBOX Visible
  // const [checkVisible, setCheckVisible] = useState(false);
  const [checkVisible, setCheckVisible] = useState(false);

  const handleOnChangeVisible = () => {
    setCheckVisible(!checkVisible);
    {
      checkVisible
        ? (datosformulario.visible = 0)
        : (datosformulario.visible = 1);
    }

    console.log("VISIBLE: ", datosformulario.visible);
    console.log("VISIBLE: ", checkVisible);
  };

  // const checkactiveVisible = () => {
  //   setCheckVisible(true);
  //   datosformulario.visible = 1;
  //   console.log("VISIBLE: ", datosformulario.visible);
  // };

  // const checkoffVisible = () => {
  //   setCheckVisible(false);
  //   datosformulario.visible = 0;
  //   console.log("VISIBLE: ", datosformulario.visible);
  // };

  // CHECKBOX Signo
  const [checkSigno, setCheckSigno] = useState(false);

  const handleOnChangeSigno = () => {
    setCheckSigno(!checkSigno);
    {
      checkSigno ? (datosformulario.signo = 0) : (datosformulario.signo = 1);
    }

    console.log("SIGNO: ", datosformulario.signo);
    console.log("SIGNO: ", checkSigno);
  };

  // const checkactiveSigno = () => {
  //   setCheckSigno(true);
  //   datosformulario.signo = 1;
  //   console.log("SIGNO: ", datosformulario.signo);
  // };

  // const checkoffSigno = () => {
  //   setCheckSigno(false);
  //   datosformulario.signo = 0;
  //   console.log("SIGNO: ", datosformulario.signo);
  // };

  // CHECKBOX Olor
  const [checkOlor, setCheckOlor] = useState(false);

  const handleOnChangeOlor = () => {
    setCheckOlor(!checkOlor);
    {
      checkOlor ? (datosformulario.olor = 0) : (datosformulario.olor = 1);
    }

    console.log("OLOR: ", datosformulario.olor);
    console.log("OLOR: ", checkOlor);
  };

  // const checkactiveOlor = () => {
  //   setCheckOlor(true);
  //   datosformulario.olor = 1;
  //   console.log("OLOR: ", datosformulario.olor);
  // };

  // const checkoffOlor = () => {
  //   setCheckOlor(false);
  //   datosformulario.olor = 0;
  //   console.log("OLOR: ", datosformulario.olor);
  // };

  // CHECKBOX Texto
  const [checkTexto, setCheckTexto] = useState(false);

  const handleOnChangeTexto = () => {
    setCheckTexto(!checkTexto);
    {
      checkTexto ? (datosformulario.texto = 0) : (datosformulario.texto = 1);
    }

    console.log("TEXTO: ", datosformulario.texto);
    console.log("TEXTO: ", checkTexto);
  };

  // const checkactiveTexto = () => {
  //   setCheckTexto(true);
  //   datosformulario.texto = 1;
  //   console.log("TEXTO: ", datosformulario.texto);
  // };

  // const checkoffTexto = () => {
  //   setCheckTexto(false);
  //   datosformulario.texto = 0;
  //   console.log("TEXTO: ", datosformulario.texto);
  // };

  //*************************************************************************************************************************************/

  // DIALOG PARA EDITAR LOS DATOS
  const [openCrear, setOpenCrear] = React.useState(false);

  // USANDO ROW TOMO LOS DATOS DE ESA FILA
  const handleClickOpenCrear = (row) => {
    setOpenCrear(true);
    // AL ABRIR EL DIALOG El TEXTFIELDS SE VACIAN
    datosformulario.item = "";
    datosformulario.descripcion = "";
    // AL ABRIR EL DIALOG EL VALOR DE LOS CHECKBOX ES CERO
    setCheckVisibilidad(false);
    datosformulario.visibilidad = 0;
    setCheckVisible(false);
    datosformulario.visible = 0;
    setCheckSigno(false);
    datosformulario.signo = 0;
    setCheckOlor(false);
    datosformulario.olor = 0;
    setCheckTexto(false);
    datosformulario.texto = 0;
  };

  // const handleCloseCrear = (row) => {
  //   setOpenCrear(false);
  //   setDatosformulario(row);
  // };

  const handleCloseCrear = () => {
    setOpenCrear(false);
  };

  //*************************************************************************************************************************************/

  // DIALOG PARA EDITAR LOS DATOS
  const [openEditar, setOpenEditar] = React.useState(false);

  // USANDO ROW TOMO LOS DATOS DE ESA FILA
  const handleClickOpenEditar = (row) => {
    setOpenEditar(true);
    setDatosformulario(row);
  };

  const handleCloseEditar = (row) => {
    setOpenEditar(false);
    setDatosformulario(row);
  };

  //*************************************************************************************************************************************/

  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
    setUpdate(false);
    setOpenEditar(false);
    setOpenCrear(false);
    setOpenDialogOnOff(false);
  };

  //**********************************************************************************************/

  // DIALOG PARA ACTIVAR/DESACTIVAR LA RELACIÓN
  const [openDialogOnOff, setOpenDialogOnOff] = React.useState(false);

  const handleCloseDialogOnOff = () => {
    setOpenDialogOnOff(!openDialogOnOff);
  };

  const dialogActivar = (row) => {
    setDatosformulario(row);
    setOpenDialogOnOff(true);
    setTabla(true);
  };

  //*************************************************************************************************************************************/

  const columns = [
    {
      header: "ID",
      accessorKey: "id_item",
      minSize: 30,
      size: 30,
      maxSize: 100,
    },
    {
      header: "Nombre",
      accessorKey: "item",
    },
    {
      header: "Descripción",
      accessorKey: "descripcion",
    },
    // {
    //   header: "Visibilidad",
    //   accessorKey: "visibilidad",
    // },
    // {
    //   header: "Visible",
    //   accessorKey: "visible",
    // },
    // {
    //   header: "Signo",
    //   accessorKey: "signo",
    // },
    // {
    //   header: "Olor",
    //   accessorKey: "olor",
    // },
    // {
    //   header: "Texto",
    //   accessorKey: "texto",
    // },
    {
      header: "Estado",
      accessorKey: "valor",
    },
  ];

  //*************************************************************************************************************************************/

  //Funcion para guardar al crear
  const guardarCrear = async (e) => {
    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(UrlApi + "/items_ef", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        item: datosformulario.item,
        descripcion: datosformulario.descripcion,
        visibilidad: datosformulario.visibilidad,
        visible: datosformulario.visible,
        signo: datosformulario.signo,
        olor: datosformulario.olor,
        texto: datosformulario.texto,
        estado: 1,
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          setUpdate(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El item se guardó exitosamente !");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el item");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  console.log(datosformulario);

  //*************************************************************************************************************************************/

  //Funcion para guardar AL EDITAR
  const guardarEditar = async (e) => {
    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(UrlApi + "/items_ef/" + datosformulario.id_item, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        item: datosformulario.item,
        descripcion: datosformulario.descripcion,
        visibilidad: datosformulario.visibilidad,
        visible: datosformulario.visible,
        signo: datosformulario.signo,
        olor: datosformulario.olor,
        texto: datosformulario.texto,
        estado: datosformulario.estado,
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          setUpdate(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El item se guardó exitosamente !");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el item");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    console.log(datosformulario);
  };

  //**********************************************************************************************/
  // FUNCIONES PARA GUARDAR:
  // ACTIVAR ITEM
  const guardarActivo = async (e) => {
    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(UrlApi + "/items_ef/" + datosformulario.id_item, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        estado: 1,
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          setUpdate(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El ítem se guardó exitosamente!");
          setUpdate(true);
          //console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el ítem");
          //console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  // DESACTIVAR ITEM
  const guardarInactivo = async (e) => {
    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(UrlApi + "/items_ef/" + datosformulario.id_item, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        estado: 2,
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          setUpdate(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El ítem se guardó exitosamente!");
          setUpdate(true);
          //console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el ítem");
          //console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  //*************************************************************************************************************************************/
  const almacenar = (e) => {
    setDatosformulario({
      ...datosformulario,
      [e.target.name]: e.target.value,
    });
  };

  //*************************************************************************************************************************************/

  // ESTILO PARA LA TABLA

  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)" //background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //override to make tooltip font size larger
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  return (
    <>
      <Navbar />
      <br />
      <Container maxWidth="xl" style={{ marginBottom: "30px" }}>
        {items && tabla ? (
          <ThemeProvider theme={tableTheme}>
            <MaterialReactTable
              columns={columns}
              data={items}
              initialState={{
                showGlobalFilter: true,
                density: "compact",
              }}
              localization={MRT_Localization_ES}
              muiTableBodyCellProps={({ row }) => ({
                sx: {
                  backgroundColor:
                    row.original.estado === 1 ? "#66FF66" : "#FF5050",
                  color: "black",
                  borderColor: "black",
                  //COLOR AL PASAR EL MOUSE POR ENCIMA
                  "&:hover": {
                    backgroundColor:
                      row.original.estado === 1 ? "#3ad63a" : "#f22929",
                  },
                  //ESTILO DE LA TABLA
                  "& .MuiTableCell-root": {
                    borderRight: "1px solid",
                    borderBottom: "1px solid",
                  },
                  "& .MuiTableCell-root:first-child": {
                    border: "none",
                  },
                },
              })}
              renderTopToolbarCustomActions={({ table }) => (
                <Grid item mt={-2.5}>
                  <h2>ITEMS DE ENSAYO FISICO GLP</h2>
                </Grid>
              )}
              // ACCIONES
              renderToolbarInternalActions={({ table }) => (
                <>
                  <Tooltip arrow placement="bottom" title="Agregar Item">
                    <IconButton
                      color="primary"
                      onClick={() => {
                        handleClickOpenCrear();
                      }}
                    >
                      <AddBoxIcon />
                    </IconButton>
                  </Tooltip>
                  <MRT_FullScreenToggleButton table={table} />
                </>
              )}
              enableRowActions
              renderRowActions={({ row }) => (
                <>
                  <Tooltip arrow placement="left" title="Editar Item">
                    <IconButton
                      onClick={() => handleClickOpenEditar(row.original)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip
                    arrow
                    placement="left"
                    title={
                      row.original.estado === 1
                        ? "Desactivar Item"
                        : "Activar Item"
                    }
                  >
                    <IconButton onClick={() => dialogActivar(row.original)}>
                      <BlockIcon
                        sx={{
                          color:
                            row.original.estado === 1 ? "red" : "lightgreen",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </>
              )}
              positionActionsColumn="last"
              // TRUE
              enableTopToolbar={true}
              // FALSE
              enableColumnFilters={false} //opcion de filttrado por columnas
              enableDensityToggle={false} //para que no se vea pero esta activo en el estado
              enableHiding={false} //oculta la propiedad show hide columns
              renderDetailPanel={({ row }) => (
                <>
                  {/* <Container maxWidth="x1">
                    <br />
                    <DialogTitle
                      style={{
                        color: "white",
                        backgroundColor: "#4abced",
                        height: "10px",
                        marginTop: "-25px",
                        marginBottom: "-10px",
                      }}
                    >
                      <Grid item mt={-1.5}>
                        ID DEL ITEM: {row.original.id_item} -{" "}
                        {row.original.item}
                      </Grid>
                    </DialogTitle>
                    <br />
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item xs={0.6}>
                        <b>VISIBILIDAD:</b>
                      </Grid>
                      <Grid item xs={1} mt={-0.7}>
                        <img
                          src="Tooltip GLP/visibilidad.jpg"
                          alt="ITALGAS"
                          // border="3"
                          height={30}
                        />
                      </Grid>

                      <Grid item xs={0.6}>
                        <b>VISIBLE:</b>
                      </Grid>
                      <Grid item xs={1} mt={-0.7}>
                        <img
                          src="Tooltip GLP/visible.jpg"
                          alt="ITALGAS"
                          border="3"
                          height={30}
                        />
                      </Grid>

                      <Grid item xs={0.6}>
                        <b>SIGNO:</b>
                      </Grid>
                      <Grid item xs={1} mt={-0.7}>
                        <img
                          src="Tooltip GLP/signo.jpg"
                          alt="ITALGAS"
                          border="3"
                          height={30}
                        />
                      </Grid>

                      <Grid item xs={0.6}>
                        <b>OLOR:</b>
                      </Grid>

                      <Grid item xs={1} mt={-0.7}>
                        <img
                          src="Tooltip GLP/olor.jpg"
                          alt="ITALGAS"
                          border="3"
                          height={30}
                        />
                      </Grid>

                      <Grid item xs={0.6}>
                        <b>TEXTO:</b>
                      </Grid>

                      <Grid item xs={1} mt={-0.7}>
                        <img
                          src="Tooltip GLP/texto.jpg"
                          alt="ITALGAS"
                          border="3"
                          height={30}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item xs={1.5}>
                        <TextField
                          value={row.original.visibilidad}
                          disabled
                          size="small"
                          style={{ width: "100px", marginLeft: "20px" }}
                        />
                      </Grid>
                      <Grid item xs={1.5}>
                        <TextField
                          value={row.original.visible}
                          disabled
                          size="small"
                          style={{ width: "100px", marginLeft: "20px" }}
                        />
                      </Grid>
                      <Grid item xs={1.5}>
                        <TextField
                          value={row.original.signo}
                          disabled
                          size="small"
                          style={{ width: "100px", marginLeft: "20px" }}
                        />
                      </Grid>
                      <Grid item xs={1.5}>
                        <TextField
                          value={row.original.olor}
                          disabled
                          size="small"
                          style={{ width: "100px", marginLeft: "20px" }}
                        />
                      </Grid>
                      <Grid item xs={1.5}>
                        <TextField
                          value={row.original.texto}
                          disabled
                          size="small"
                          style={{ width: "100px", marginLeft: "20px" }}
                        />
                      </Grid>
                    </Grid>
                  </Container> */}

                  <Container maxWidth="x1">
                    <br />
                    <DialogTitle
                      style={{
                        color: "white",
                        backgroundColor: "#4abced",
                        height: "10px",
                        marginTop: "-25px",
                        marginBottom: "-10px",
                      }}
                    >
                      <Grid item mt={-1.5}>
                        ID DEL ITEM: {row.original.id_item} -{" "}
                        {row.original.item}
                      </Grid>
                    </DialogTitle>
                    <br />
                  </Container>

                  <Grid container justifyContent="center">
                    <Grid item xs={2}>
                      <Box m={1} border={1} px={2} borderRadius={2}>
                        <DialogTitle textAlign={"center"} mb={-2} mt={-1}>
                          Visibilidad
                        </DialogTitle>
                        <img
                          src="/Tooltip GLP/visibilidad.jpg"
                          alt="Visibilidad"
                          height={35}
                          style={{
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        />
                        <Grid item py={1} mb={1}>
                          <TextField
                            fullWidth
                            placeholder="No hay registro"
                            name="visibilidad"
                            variant="filled"
                            size="small"
                            focused
                            value={row.original.visibilidad}
                          />
                        </Grid>
                      </Box>
                    </Grid>

                    <Grid item xs={2}>
                      <Box m={1} border={1} px={2} borderRadius={2}>
                        <DialogTitle textAlign={"center"} mb={-2} mt={-1}>
                          Visible
                        </DialogTitle>
                        <img
                          src="/Tooltip GLP/visible.jpg"
                          alt="Visible"
                          height={35}
                          style={{
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        />
                        <Grid item py={1} mb={1}>
                          <TextField
                            fullWidth
                            placeholder="No hay registro"
                            name="visible"
                            variant="filled"
                            size="small"
                            focused
                            value={row.original.visible}
                          />
                        </Grid>
                      </Box>
                    </Grid>

                    <Grid item xs={2}>
                      <Box m={1} border={1} px={2} borderRadius={2}>
                        <DialogTitle textAlign={"center"} mb={-2} mt={-1}>
                          Signo
                        </DialogTitle>
                        <img
                          src="/Tooltip GLP/signo.jpg"
                          alt="Signo"
                          height={35}
                          style={{
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        />
                        <Grid item py={1} mb={1}>
                          <TextField
                            fullWidth
                            placeholder="No hay registro"
                            name="signo"
                            variant="filled"
                            size="small"
                            focused
                            value={row.original.signo}
                          />
                        </Grid>
                      </Box>
                    </Grid>

                    <Grid item xs={2}>
                      <Box m={1} border={1} px={2} borderRadius={2}>
                        <DialogTitle textAlign={"center"} mb={-2} mt={-1}>
                          Olor
                        </DialogTitle>
                        <img
                          src="/Tooltip GLP/olor.jpg"
                          alt="Olor"
                          height={35}
                          style={{
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        />
                        <Grid item py={1} mb={1}>
                          <TextField
                            fullWidth
                            placeholder="No hay registro"
                            name="olor"
                            variant="filled"
                            size="small"
                            focused
                            value={row.original.olor}
                          />
                        </Grid>
                      </Box>
                    </Grid>

                    <Grid item xs={2}>
                      <Box m={1} border={1} px={2} borderRadius={2}>
                        <DialogTitle textAlign={"center"} mb={-2} mt={-1}>
                          Texto
                        </DialogTitle>
                        <img
                          src="/Tooltip GLP/texto.jpg"
                          alt="Texto"
                          height={35}
                          style={{
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        />
                        <Grid item py={1} mb={1}>
                          <TextField
                            fullWidth
                            placeholder="No hay registro"
                            name="texto"
                            variant="filled"
                            size="small"
                            focused
                            value={row.original.texto}
                          />
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              )}
            />
          </ThemeProvider>
        ) : null}
      </Container>

      {/* AGREGAR ITEM */}
      <Dialog
        open={openCrear}
        onClose={handleCloseCrear}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle marginLeft={3}>
          <b>{"Agregar Item"}</b>
        </DialogTitle>
        <DialogContent>
          <Container maxWidth="xl">
            <form onSubmit={guardarCrear}>
              <Grid container mt={2} mb={1}>
                <Grid item xs={12} mb={2}>
                  <TextField
                    label="Nombre"
                    name="item"
                    size="small"
                    placeholder="..."
                    focused
                    required
                    fullWidth
                    id="item"
                    value={datosformulario.item}
                    onChange={almacenar}
                  />
                </Grid>
                <Grid item xs={12} mb={2}>
                  <TextField
                    label="Descripción"
                    name="descripcion"
                    size="small"
                    focused
                    fullWidth
                    id="descripcion"
                    placeholder="..."
                    onChange={almacenar}
                    value={datosformulario.descripcion}
                  />
                </Grid>
              </Grid>

              <Box
                style={{
                  borderWidth: 2,
                  borderStyle: "solid",
                  borderColor: "#1976d2",
                }}
              >
                <Grid
                  container
                  justifyContent="center"
                  style={{ backgroundColor: "#EAEFF1" }}
                >
                  <Grid item mt={-2} mb={-1}>
                    <h3>PROPIEDADES DEL ITEM:</h3>
                  </Grid>
                </Grid>

                {/* ------------ CHECKBOXES ------------ */}

                <Grid container justifyContent="center">
                  <Grid item xs={2} mt={0.5}>
                    <b>VISIBILIDAD</b>
                  </Grid>
                  <Grid item xs={1.7} ml={2}>
                    <Tooltip
                      arrow
                      placement="top-start"
                      title={
                        <img
                          src="Tooltip GLP/visibilidad.jpg"
                          alt="ITALGAS"
                          height={40}
                        />
                      }
                    >
                      <input
                        type="checkbox"
                        name="visibilidad"
                        value="visibilidad"
                        defaultChecked={checkVisibilidad}
                        // onChange={handleOnChangeVisibilidad}
                        onClick={handleOnChangeVisibilidad}
                        style={{
                          width: "23px",
                          height: "23px",
                          marginTop: "5px",
                          marginLeft: "5px",
                        }}
                      />
                    </Tooltip>
                  </Grid>

                  <Grid item xs={1} mt={0.5}>
                    <b>VISIBLE</b>
                  </Grid>
                  <Grid item xs={1.7} ml={3}>
                    <Tooltip
                      arrow
                      placement="top-start"
                      title={
                        <img
                          src="Tooltip GLP/visible.jpg"
                          alt="ITALGAS"
                          height={40}
                        />
                      }
                    >
                      <input
                        type="checkbox"
                        name="visible"
                        value="visible"
                        // checked={checkVisible}
                        checked={datosformulario.visible}
                        onChange={handleOnChangeVisible}
                        style={{
                          width: "23px",
                          height: "23px",
                          marginTop: "5px",
                          marginLeft: "5px",
                        }}
                      />
                    </Tooltip>
                  </Grid>

                  <Grid item xs={1} mt={0.5}>
                    <b>SIGNO</b>
                  </Grid>
                  <Grid item xs={1} ml={2}>
                    <Tooltip
                      arrow
                      placement="top-start"
                      title={
                        <img
                          src="Tooltip GLP/signo.jpg"
                          alt="ITALGAS"
                          height={40}
                        />
                      }
                    >
                      <input
                        type="checkbox"
                        name="signo"
                        value="signo"
                        // checked={checkSigno}
                        checked={datosformulario.signo}
                        onChange={handleOnChangeSigno}
                        style={{
                          width: "23px",
                          height: "23px",
                          marginTop: "5px",
                          marginLeft: "5px",
                        }}
                      />
                    </Tooltip>
                  </Grid>
                </Grid>

                <Grid container justifyContent="center" mb={1}>
                  <Grid item xs={1} mt={0.5}>
                    <b>OLOR</b>
                  </Grid>
                  <Grid item xs={1.7} ml={1.9}>
                    <Tooltip
                      arrow
                      placement="bottom-start"
                      title={
                        <img
                          src="Tooltip GLP/olor.jpg"
                          alt="ITALGAS"
                          height={40}
                        />
                      }
                    >
                      <input
                        type="checkbox"
                        name="olor"
                        value="olor"
                        // checked={checkOlor}
                        checked={datosformulario.olor}
                        onChange={handleOnChangeOlor}
                        style={{
                          width: "23px",
                          height: "23px",
                          marginTop: "5px",
                          marginLeft: "5px",
                        }}
                      />
                    </Tooltip>
                  </Grid>

                  <Grid item xs={1} mt={0.5}>
                    <b>TEXTO</b>
                  </Grid>

                  <Grid item xs={2} ml={3.1}>
                    <Tooltip
                      arrow
                      placement="bottom-start"
                      title={
                        <img
                          src="Tooltip GLP/texto.jpg"
                          alt="ITALGAS"
                          height={40}
                        />
                      }
                    >
                      <input
                        type="checkbox"
                        name="texto"
                        value="texto"
                        // checked={checkTexto}
                        checked={datosformulario.texto}
                        onChange={handleOnChangeTexto}
                        style={{
                          width: "23px",
                          height: "23px",
                          marginTop: "5px",
                          marginLeft: "5px",
                        }}
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Box>

              <Grid
                mt={2}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              />
              <Stack direction="row">
                <Grid container mt={2} direction={{ xs: "column", sm: "row" }}>
                  <Grid item xs={9}>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleCloseCrear}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      color="success"
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Stack>

              <Stack spacing={2} sx={{ width: "100%" }}>
                <Snackbar
                  open={openSnack}
                  autoHideDuration={1500}
                  onClose={handleCloseSnack}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                  <Alert
                    onClose={handleCloseSnack}
                    severity="success"
                    sx={{ width: "100%" }}
                    style={{ backgroundColor: colormensaje, color: "black" }}
                  >
                    {mensaje}
                  </Alert>
                </Snackbar>
              </Stack>
            </form>
          </Container>
        </DialogContent>
      </Dialog>

      {/* EDITAR ITEM */}

      {/* 
      FIX 
      A LOS 
      CHECKBOXES!!!!!
       */}
      <Dialog
        open={openEditar}
        onClose={handleCloseEditar}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        {/* BORDE DE COLOR PARA DIALOG DE EDITAR */}
        <Box
          style={{
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: "#1976D2",
          }}
        >
          <DialogTitle marginLeft={3}>
            <b>{"Editar Item"}</b>
          </DialogTitle>
          <DialogContent>
            <Container maxWidth="xl">
              <form onSubmit={guardarEditar}>
                <Grid container mt={2} mb={1}>
                  <Grid item xs={12} mb={2}>
                    <TextField
                      label="Nombre"
                      name="item"
                      size="small"
                      placeholder="-"
                      InputLabelProps={{ shrink: true }}
                      onChange={almacenar}
                      value={datosformulario.item}
                      fullWidth
                      focused
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EditIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} mb={2}>
                    <TextField
                      label="Descripción"
                      name="descripcion"
                      size="small"
                      placeholder="..."
                      InputLabelProps={{ shrink: true }}
                      onChange={almacenar}
                      value={datosformulario.descripcion}
                      fullWidth
                      focused
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EditIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>

                <Box
                  style={{
                    borderWidth: 2,
                    borderStyle: "solid",
                    borderColor: "#1976d2",
                  }}
                >
                  <Grid
                    container
                    justifyContent="center"
                    style={{ backgroundColor: "#EAEFF1" }}
                  >
                    <Grid item mt={-2} mb={-1}>
                      <h3>PROPIEDADES DEL ITEM</h3>
                    </Grid>
                  </Grid>
                  {/* ------------ CHECKBOXES ------------ */}
                  {/* ARREGLAR: */}
                  <Grid container justifyContent="center">
                    {/* <Grid item xs={2} mt={0.5}>
                      <b>VISIBILIDAD</b>
                    </Grid> */}
                    {/* <Grid item xs={1.7} ml={2}> */}
                    {/* <Tooltip
                        arrow
                        placement="top-start"
                        title={
                          <img
                            src="Tooltip GLP/visibilidad.jpg"
                            alt="ITALGAS"
                            height={40}
                          />
                        }
                      > */}
                    <FormControl>
                      <FormControlLabel
                        // checked={datosformulario.visibilidad}
                        checked={datosformulario.visibilidad}
                        onChange={handleOnChangeVisibilidad}
                        control={<Checkbox defaultChecked={checkVisibilidad}/>}
                        label="Visibilidad"
                        labelPlacement="start"
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                      />
                    </FormControl>
                    {/* EN FORMA DE TEXTFIELD */}
                    {/* <TextField
                        name="visibilidad"
                        label="visibilidad"
                        InputLabelProps={{ shrink: true }}
                        onChange={almacenar}
                        value={datosformulario.visibilidad}
                        fullWidth
                        focused
                        required
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EditIcon />
                            </InputAdornment>
                          ),
                        }}
                      /> */}
                    {/* </Grid> */}
                  </Grid>
                </Box>

                <Grid
                  mt={2}
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                />
                <Stack direction="row">
                  <Grid container direction={{ xs: "column", sm: "row" }}>
                    <Grid item xs={9}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleCloseEditar}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              </form>
            </Container>
          </DialogContent>
        </Box>
      </Dialog>

      {/* DIALOG PARA BLOQUEAR/DESBLOQUEAR LA RELACION */}
      <Dialog open={openDialogOnOff} onClose={handleCloseDialogOnOff}>
        <Box
          style={{
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: datosformulario.estado === 1 ? "#e32712" : "#24ff1c",
            color: datosformulario.estado === 1 ? "red" : "green",
          }}
        >
          <DialogTitle>
            <Typography align="center">
              <b>
                SE {datosformulario.estado === 1 ? "DESACTIVARÁ" : "ACTIVARÁ"}
                EL ITEM:
              </b>
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText textAlign={"center"}>
              <Grid item mb={4}>
                <br />
                <b>{datosformulario.item}</b>
              </Grid>
            </DialogContentText>
            <DialogContentText textAlign={"center"}>
              {" "}
              {"¿Confirma que quiere " +
                (datosformulario.estado === 1 ? "desactivar" : "activar") +
                " este item?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Stack spacing={4} direction="row">
              <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item mr={22}>
                  <Button
                    style={{
                      backgroundColor: "#d32f2f",
                      color: "#ffffff",
                    }}
                    variant="contained"
                    onClick={handleCloseDialogOnOff}
                  >
                    Cancelar
                  </Button>
                </Grid>
                {datosformulario.estado === 1 ? (
                  <Button
                    style={{ backgroundColor: "#fc7a23", color: "#ffffff" }}
                    onClick={guardarInactivo}
                    variant="contained"
                  >
                    Desactivar
                  </Button>
                ) : (
                  <Button
                    style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                    onClick={guardarActivo}
                    variant="contained"
                  >
                    Activar
                  </Button>
                )}
              </Grid>
            </Stack>
          </DialogActions>
        </Box>
      </Dialog>

      {/* SNACKBAR QUE SE MOSTRARA AL GUARDAR LOS CAMBIOS */}
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}

export default EnsayoItems;
