//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio
// Creation Date : 04/09/2024
// Version : 1
// Description : para agregar tanques
//------Changes -------------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Container,
  Grid,
  Box,
  TextField,
  Button,
  Dialog,
  DialogContent,
  Stack,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import Notify from "../Notify";
import Alerta from "../Alerta";
import Title from "../Title";
import { logEventos } from "../../services/services";
import moment from "moment";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
//adjuntado
import AttachFileIcon from "@mui/icons-material/AttachFile"; //ADJUNTAR ARCHIVOS
import FileUploadTanque from "./FileUploadTanque";
import { v4 as uuidv4 } from "uuid"; //ADJUNTAR IMAGEN

function AddTanque(props) {
  const [datosTanque, setDatosTanque] = useState({
    matricula: "",
    num_auditoria: "",
    fabricante: "",
    patente: "",
    volumen: "",
    datos: "",
    fecha_ultima: "",
    fecha_rev_val_seg: "",
    fecha_rev_val_exc: "",
    fecha_rev_man: "",
    prox_ultima: "",
    prox_rev_val_seg: "",
    prox_rev_val_exc: "",
    prox_rev_man: "",
    usuario: localStorage.getItem("user"),
  });

  const [uso, setUso] = useState(null);
  const [usuarios, setUsuarios] = useState(null);
  const [disBtn, setDisBtn] = useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
  const [mensajeAlerta, setMensajeAlerta] = useState(""); //mensaje que contendrá el componente "alerta"
  const [finalizar, setFinalizar] = useState(); //finalizar === 1 lo manda como prop el componente "notify" e indica que la transaccion ha finalizado y debe ejecutarse la funcion para cerrar
  const [severityAlerta, setSeverityAlerta] = useState(""); //define severidad de la alerta (success error o warning)
  const [severitySnack, setSeveritySnack] = useState(""); //define severidad del snackbar (notificación)
  const [alta, setAlta] = useState(props.alta);
  const [estadoTanque, setEstadoTanque] = useState(1); // Inicialmente en 'Funcional'

  const [uuid, setUuid] = useState(uuidv4()); //ADJUNTAR IMAGEN
  const [openCloseAdj, setOpenCloseAdj] = useState(false); //ADJUNTAR IMAGEN
  const [disabledGuardar, setDisabledGuardar] = useState(false);

  //adjunto
  const handleOpenCloseAdj = (value) => {
    setOpenCloseAdj(value);
  };

  const handleClose = () => {
    setAlta(!alta);
    if (props.onClose) {
      props.onClose();
    }
  };

  const almacenar = (e) => {
    setDatosTanque({
      ...datosTanque,
      [e.target.name]: e.target.value === "" ? null : e.target.value,
    });
  };
  //------------- CALCULO DE FECHAS PROXIMAS --------------------//
  // ULTIMA REVISION QUINQUENAL 5 AÑOS
  const fechaUltima = moment(datosTanque.fecha_ultima);
  const fechaProxUltima = fechaUltima.add(5, "years").format("YYYY-MM-DD");
  // VALVULA DE SEGURIDAD 2 AÑOS
  const fechaRevValSeg = moment(datosTanque.fecha_rev_val_seg);
  const fechaProxRevValSeg = fechaRevValSeg
    .add(2, "years")
    .format("YYYY-MM-DD");
  // VALVULA DE EXCESO 1 AÑO
  const fechaRevValExc = moment(datosTanque.fecha_rev_val_exc);
  const fechaproxRevValExc = fechaRevValExc
    .add(1, "years")
    .format("YYYY-MM-DD");
  // REVISION DE MANGUERA 6 MESES
  const fechaRevMan = moment(datosTanque.fecha_rev_man);
  const fechaProxRevMan = fechaRevMan.add(6, "months").format("YYYY-MM-DD");

  const guardarRegistro = async (e) => {
    logEventos(
      "Guardar nuevo tanque",
      "Tanques Moviles",
      "Se guardo nuevo tanque",
      localStorage.getItem("user")
    );
    setDisabledGuardar(true);
    e.preventDefault();
    const response = await fetch(UrlApi + "/tanque_rehabilitacion", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        matricula: datosTanque.matricula,
        num_auditoria: datosTanque.num_auditoria,
        fabricante: datosTanque.fabricante,
        patente: datosTanque.patente,
        volumen: datosTanque.volumen,
        datos: datosTanque.datos,
        fecha_ultima: datosTanque.fecha_ultima,
        fecha_rev_val_seg: datosTanque.fecha_rev_val_seg,
        fecha_rev_val_exc: datosTanque.fecha_rev_val_exc,
        fecha_rev_man: datosTanque.fecha_rev_man,
        prox_ultima: fechaProxUltima,
        prox_rev_val_seg: fechaProxRevValSeg,
        prox_rev_val_exc: fechaproxRevValExc,
        prox_rev_man: fechaProxRevMan,
        estado: estadoTanque,
        uuid: uuid,
        usuario: localStorage.getItem("user"),
      }),
    });
    if (response.ok === false) {
      console.log("ERROR EN API");
      const error = response && response.json();
      setError(error);
      setSeverityAlerta("error");
      setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      console.log("Conexión con api OK");
      try {
        //Parseo respuesta de la api a json()
        const json = await response.json();
        //si api me devuelve lo q le puse en message
        if (json.message) {
          //Si ese mensaje es "OK"
          if (json.message === "OK") {
            console.log("Guardado Satisfactoriamente");
            setNotificacion(true);
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setMensaje("Guardado");
            setSeveritySnack("success");
          } else {
            console.log("res en api incorrecto. Revisar que sea 'OK'");
            setNotificacion(true);
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setSeveritySnack("error");
            setMensaje("Registro Guardado. REVISAR RESPUESTA DE API (E_S)");
          }
        } else if (json.sqlMessage) {
          json && setError(json);
          console.log(json);
          setMensajeAlerta(
            "SQL erróneo (" + json.code + " Errno: " + json.errno + " )"
          );
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          console.log(
            "Guardado. Advertencia: Actualizar respuesta de api. JSON invalido."
          );
          setNotificacion(true);
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("Guardado");
          setSeveritySnack("success");
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      }
    }
    handleClose();
  };
  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };
  const size = "small";

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "#1890ff",
          ...theme.applyStyles("dark", {
            backgroundColor: "#177ddc",
          }),
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: "rgba(0,0,0,.25)",
      boxSizing: "border-box",
      ...theme.applyStyles("dark", {
        backgroundColor: "rgba(255,255,255,.35)",
      }),
    },
  }));

  // console.log("estado switch", estadoTanque);
  //console.log("DATA", datosTanque);
  // console.log("id tanque",datosTanque.id_tanque);

  return (
    <div>
      <Dialog
        open={props.alta}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <Title
          titulo={`Cargar nuevo tanque`}
          handleClose={() => {
            handleClose();
          }}
        />
        <Box
          border={0.5}
          borderColor={grey[300]}
          backgroundColor="#F4F6F6"
          p={2}
          py={2}
          mt={1}
        >
          <DialogContent>
            <Container maxWidth="xl">
              <form onSubmit={guardarRegistro} autoComplete="off">
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      size={size}
                      placeholder="....."
                      style={style}
                      focused
                      color="primary"
                      required
                      name="matricula"
                      label="Matrícula"
                      variant="outlined"
                      value={datosTanque.matricula}
                      inputProps={{
                        maxLength: 45,
                      }}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      size={size}
                      placeholder="....."
                      style={style}
                      focused
                      color="primary"
                      required
                      name="num_auditoria"
                      label="N° Auditoría"
                      variant="outlined"
                      value={datosTanque.num_auditoria}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      inputProps={{
                        maxLength: 45,
                      }}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      size={size}
                      placeholder="....."
                      style={style}
                      focused
                      color="primary"
                      required
                      name="fabricante"
                      label="Fabricante"
                      variant="outlined"
                      value={datosTanque.fabricante}
                      inputProps={{
                        maxLength: 45,
                      }}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      size={size}
                      placeholder="....."
                      style={style}
                      focused
                      color="primary"
                      required
                      name="patente"
                      label="Patente"
                      variant="outlined"
                      value={datosTanque.patente}
                      inputProps={{
                        maxLength: 45,
                      }}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      size={size}
                      placeholder="....."
                      style={style}
                      focused
                      color="primary"
                      required
                      name="volumen"
                      label="Vol.(m3)"
                      variant="outlined"
                      value={datosTanque.volumen}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      inputProps={{
                        maxLength: 45,
                      }}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      size={size}
                      placeholder="....."
                      style={style}
                      focused
                      color="primary"
                      required
                      name="datos"
                      label="Datos"
                      variant="outlined"
                      value={datosTanque.datos}
                      inputProps={{
                        maxLength: 45,
                      }}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      style={style}
                      label="Última Revisión Quinquenal"
                      name="fecha_ultima"
                      size="small"
                      type="date"
                      focused
                      required
                      fullWidth
                      value={moment(datosTanque.fecha_ultima).format(
                        "YYYY-MM-DD"
                      )}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      style={style}
                      label="Última revisión válvulas seguridad"
                      name="fecha_rev_val_seg"
                      size="small"
                      type="date"
                      focused
                      required
                      fullWidth
                      value={moment(datosTanque.fecha_rev_val_seg).format(
                        "YYYY-MM-DD"
                      )}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      style={style}
                      label="Última revisión válvulas exceso"
                      name="fecha_rev_val_exc"
                      size="small"
                      type="date"
                      focused
                      required
                      fullWidth
                      value={moment(datosTanque.fecha_rev_val_exc).format(
                        "YYYY-MM-DD"
                      )}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      style={style}
                      label="Última revisión mangueras"
                      name="fecha_rev_man"
                      size="small"
                      type="date"
                      focused
                      required
                      fullWidth
                      value={moment(datosTanque.fecha_rev_man).format(
                        "YYYY-MM-DD"
                      )}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={6} mb={3}>
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ alignItems: "center", mb: 2 }}
                    >
                      <Typography>En reparación</Typography>
                      <AntSwitch
                        checked={estadoTanque === 1} // El switch estará en 'checked' si estadoTanque es 1
                        onChange={(e) => {
                          const nuevoEstado = e.target.checked ? 1 : 0;
                          setEstadoTanque(nuevoEstado);
                          setDatosTanque({
                            ...datosTanque,
                            estado: nuevoEstado,
                          });
                        }}
                        inputProps={{ "aria-label": "ant design" }}
                      />
                      <Typography>Funcional</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={6} mb={3}>
                    <Button
                      onClick={() => setOpenCloseAdj(!openCloseAdj)}
                      variant="outlined"
                      size="medium"
                      startIcon={<AttachFileIcon />}
                    >
                      ADJUNTAR ARCHIVOS
                    </Button>
                  </Grid>
                </Grid>
                <Stack direction="row">
                  <Grid container direction={{ xs: "column", sm: "row" }}>
                    <Grid item xs={9}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleClose}
                      >
                        Volver
                      </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={disabledGuardar}
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              </form>
            </Container>
          </DialogContent>
        </Box>
      </Dialog>
      {notificacion ? (
        <Notify
          mensaje={mensaje}
          open={openSnack}
          color={colormensaje}
          finalizar={setFinalizar}
          severity={severitySnack}
        />
      ) : null}
      {openCloseAdj ? ( // ADJUNTAR IMAGEN
        <FileUploadTanque
          fileId={uuid}
          openclose={true}
          mostrar={true}
          onClose={() => handleOpenCloseAdj(false)} // Proporciona una función de cierre
        />
      ) : null}
      {/* ALERT --> SE MUESTRA SI HAY UN ERROR EN LA API (NO SE CONECTA O ESTÁ MAL LA RUTA, ETC.) */}
      {error && mensajeAlerta ? (
        <Alerta mensaje={mensajeAlerta} severity={severityAlerta} />
      ) : null}
    </div>
  );
}

export default AddTanque;
