import Navbar from '../../Navbar'
import React, { useEffect, useState, useMemo } from 'react';
import { UrlApi } from '../../../services/apirest'
import { Container, Typography, Box, Tooltip, Button, IconButton, Dialog, DialogContent, DialogActions, Grid, DialogTitle, DialogContentText } from '@mui/material'
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import EditIcon from '@mui/icons-material/Edit';
import Editarrutas from './Editarrutas';
import Addrutas from './Addrutas';
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import MaterialReactTable, { MRT_FullScreenToggleButton } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import Notify from '../../Notify';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonIcon from '@mui/icons-material/Person';
import MapIcon from '@mui/icons-material/Map';

export default function Rutas() {
    const [rutas, setRutas] = useState();
    const [alta, setAlta] = useState(false)
    const [tabla, setTabla] = useState(true)
    const [editar, setEditar] = useState(false)
    const [datosformulario, setDatosformulario] = useState({})
    const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
    const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
    const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
    const [error, setError] = useState(null); //guardará el error que lance la api (si existe)  
    const globalTheme = useTheme();
    const [confirmar, setConfirmar] = useState(false);
    const [hiddenmuestrainactivo, setHiddenmuestrainactivo] = useState(false);
    const [hiddenmuestraactivos, setHiddenmuestraactivos] = useState(true);

    const obtenerRutas = () => {
        setHiddenmuestraactivos(true);
        setHiddenmuestrainactivo(false);
        fetch(UrlApi + '/rutas')
            .then(data => data.json())
            .then(data => setRutas(data))
    };

    const obtenerInactivos = () => {
        setHiddenmuestrainactivo(true);
        setHiddenmuestraactivos(false);
        fetch(UrlApi + '/rutas/inactivas')
            .then(data => data.json())
            .then(data => setRutas(data))
    };

    useEffect(() => {
        obtenerRutas();
    }, [tabla]);

    const handleClickNuevo = () => {
        setAlta(true)
        setTabla(false)
    };

    const mostrareditar = (datosDeLaLinea) => {
        setEditar(true)
        setTabla(false)
        setDatosformulario(datosDeLaLinea)
    };

    const abrirModalConfirmar = (datosDeLaLinea) => {
        setConfirmar(true)
        setDatosformulario(datosDeLaLinea)
        console.log("skks")
    };

    const handleClose = () => {
        setConfirmar(false)
        setTabla(true)
    };

    //Funcion que se ejecuta cuando presionamos eliminar rutas
    //Solo se eliminan logicamente, no fisicamente, hacemos un put y cambiamos el estado a activo=0
    const marcarInactivo = async (e) => {
        e.preventDefault();
        const response = await fetch(UrlApi + "/rutas/" + datosformulario.idruta, {
            method: 'PUT', headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(datosformulario.activa === 0 ? { activa: 1 } : { activa: 0 })
        })
        if (response.ok === false) {
            const error = response && response.json();
            setError(error);
            alert("Inconvenientes con la API. No se pudo guardar. ");
        } else {
            try {
                const json = await response.json();
                if (json.message) {
                    setNotificacion(true);
                    setOpenSnack(true);
                    setMensaje(
                        json.message === "OK" ? "Guardado" : "Guardado. Actualizar RTA Api."
                    );
                    obtenerRutas()
                } else if (json.sqlMessage) {
                    json && setError(json);
                    alert("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
                } else {
                    alert("ERROR DESCONOCIDO.");
                }
            } catch (error) {
                if (error.toString().includes("is not valid JSON")) {
                    setNotificacion(true);
                    setOpenSnack(true);
                    setMensaje("Guardado");
                    obtenerRutas()
                } else {
                    alert("Error: " + error);
                }
            }
        }
        handleClose()
    };

    const columns = useMemo(() =>
        [{ header: 'Nombre', accessorKey: 'nombre_ruta' },
        { header: 'Descripcion', accessorKey: 'descripcion' },
        // { header: 'Día Asignado', accessorKey: 'dia_asignado' , size:60},
        { header: 'Geocerca', accessorKey: 'geocerca' },
        { header: 'Vehiculo', accessorKey: 'patente', type: "date", format: "DD-MM-YYYY" },
        { header: 'Sucursal', accessorKey: 'nombre' }
        ], []);

    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: globalTheme.palette.mode,
                    primary: globalTheme.palette.primary, //primary color for the table
                    info: {
                        main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            globalTheme.palette.mode === "light"
                                ? "rgb(234, 239, 241)"
                                : "#000", //pure black table in dark mode for fun
                    },
                },
                typography: {
                    button: {
                        textTransform: "none", //customize typography styles for all buttons in table by default
                        fontSize: "1rem",
                    },
                },
                components: {
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                fontSize: "0.8rem", //larger tooltip font size                                
                            },
                        },
                    },
                },

            }), [globalTheme]);

    const muiSearchTextFieldProps = {
        placeholder: "Buscar..",
        sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
        variant: "outlined",
        color: "success",
        size: "small",
    };

    const initialState = {
        density: "compact",
        showGlobalFilter: true,
        pagination: { pageIndex: 0, pageSize: 10 }
    };

    const tableHeadCellProps = {
        align: "center",
        sx: {
            fontSize: 16,
            color: "white",
            fontWeight: "normal",
            backgroundColor: "#2471A3",
            borderColor: "black",
        },
    };



    return (
        <div>
            <Navbar />
            <Container maxWidth="xl" mt={1}>
                {rutas && tabla ?
                    <Box mt={2} boxShadow={3}>
                        <ThemeProvider theme={tableTheme}>
                            <MaterialReactTable
                                enableColumnResizing
                                columns={columns}
                                data={rutas}
                                initialState={initialState}
                                positionActionsColumn="last"
                                enableExpandAll={false}
                                enableToolbarInternalActions={true}
                                muiTableHeadCellProps={tableHeadCellProps}
                                muiTableBodyCellProps={({ row }) => ({
                                    align: "left",
                                    sx: {
                                        backgroundColor:
                                            row.original.activa === 0 ? "#e39898" : "#afdbaf",
                                        color: "black",
                                        borderColor: "black",
                                    },
                                })}
                                enableColumnActions={false}
                                muiSearchTextFieldProps={muiSearchTextFieldProps}
                                enableDensityToggle={false}
                                renderTopToolbarCustomActions={() => (
                                    <>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<AddBoxTwoToneIcon />}
                                            onClick={handleClickNuevo}
                                        >
                                            Nuevo Registro
                                        </Button>
                                        <Typography variant="poster" fontSize={26}>
                                            <MapIcon sx={{ size: 19 }} />
                                            Rutas
                                        </Typography>
                                    </>
                                )}
                                layoutMode="grid"
                                localization={MRT_Localization_ES}
                                enableColumnDragging={false}
                                enableEditing
                                renderToolbarInternalActions={(
                                    { table }) => (
                                    <>
                                        {!hiddenmuestraactivos ?
                                            <Tooltip arrow placement="right" title="Ver rutas activas" disabled={hiddenmuestraactivos}>
                                                <IconButton color="success" onClick={() => {
                                                    obtenerRutas();
                                                }}>
                                                    <PersonIcon/>
                                                </IconButton>
                                            </Tooltip>
                                            : null}
                                        {!hiddenmuestrainactivo ?
                                            <Tooltip arrow placement="right" title="Ver rutas inactivas" disabled={hiddenmuestrainactivo}>
                                                <IconButton color="error" onClick={() => {
                                                    obtenerInactivos();
                                                }}>
                                                    <PersonOffIcon />
                                                </IconButton>
                                            </Tooltip> : null}

                                        <MRT_FullScreenToggleButton table={table} />
                                    </>
                                )}
                                renderRowActions={({ row, table }) => (
                                    <div>
                                        <Tooltip title="Editar chofer">
                                            <IconButton
                                                onClick={() => mostrareditar(row.original)}
                                                color="primary"
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                        {(row.original.activa === 0) ?
                                            <Tooltip title="Marcar como activo">
                                                <IconButton
                                                    onClick={() => abrirModalConfirmar(row.original)}
                                                    color="success"
                                                >
                                                    <CheckCircleOutlineIcon />
                                                </IconButton>
                                            </Tooltip>

                                            :
                                            <Tooltip title="Marcar como inactivo">
                                                <IconButton
                                                    onClick={() => abrirModalConfirmar(row.original)}
                                                    color="error"
                                                >
                                                    <BlockIcon />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </div>
                                )} />
                        </ThemeProvider>
                    </Box>
                    : null}

                {alta ? <Addrutas alta={setAlta} tabla={setTabla}></Addrutas> : null}
                {editar ? <Editarrutas editar={setEditar} tabla={setTabla} datos={datosformulario}></Editarrutas> : null}

                {confirmar ?
                    <div>
                        <Dialog open={confirmar} onClose={handleClose}>
                            <form onSubmit={marcarInactivo}>
                                <Box backgroundColor={"#1F618D"}>
                                    <DialogTitle>
                                        {datosformulario.activo === 1 ?
                                            <Typography variant="h6" align="center" color="#ffffff">
                                                Alta de ruta {datosformulario.nombre_ruta}
                                            </Typography>
                                            :
                                            <Typography variant="h6" align="center" color="#ffffff">
                                                Baja de ruta {datosformulario.nombre_ruta}
                                            </Typography>
                                        }
                                    </DialogTitle>
                                </Box>
                                <DialogContent>
                                    <DialogContentText textAlign={"center"}>
                                        {datosformulario.activo === 0 ?
                                            <> ¿Desea dar de alta la ruta + {datosformulario.nombre_ruta}? </>
                                            :
                                            <> ¿Desea dar de baja la ruta {datosformulario.nombre_ruta}? </>
                                        }
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Grid container direction="row" justifyContent="space-between" alignItemns="center">
                                        <Button
                                            variant="contained"
                                            onClick={handleClose}
                                            style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
                                        >
                                            Cancelar
                                        </Button>
                                        <Button
                                            style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                                            type="submit"
                                            variant="contained"
                                            color="primary"

                                        >
                                            Confirmar
                                        </Button>
                                    </Grid>
                                </DialogActions>
                            </form>
                        </Dialog>

                    </div>
                    : null
                }
            </Container >
            {
                notificacion ? (
                    <Notify
                        mensaje={mensaje}
                        open={openSnack}
                        color={"#D4EFDF"}
                        severity={"success"}
                        handleClose={handleClose}
                    />
                ) : null
            }
        </div >
    )


}

