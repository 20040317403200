import React, { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import PropaneIcon from "@mui/icons-material/Propane";
import ListItemIcon from "@mui/material/ListItemIcon";
import ScaleIcon from "@mui/icons-material/Scale";
import ConstructionIcon from "@mui/icons-material/Construction";
import DescriptionIcon from "@mui/icons-material/Description";
import Grid from '@mui/material/Grid'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import GavelIcon from '@mui/icons-material/Gavel';
import EventIcon from '@mui/icons-material/Event';
import EditNoteIcon from '@mui/icons-material/EditNote';

import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
function DetalleContrato(props) {
  const [dataContrato,setDataContrato] = useState(props.contrato);
  console.log(dataContrato);

  return (
    <div>
      {dataContrato ? (
        <>
          <List sx={{ width: "100%" }}>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
              <Grid item xs={6}>
                <ListItem>
                  <ListItemIcon>
                    <GavelIcon color="primary"sx={{fontSize:30}} />
                  </ListItemIcon>
                  <ListItemText
                    primary={<>N° Contrato: {dataContrato.nro_contrato ? dataContrato.nro_contrato : "No registrado"}</>}
                    secondary={
                      <>
                        <b>Fecha Inicio: </b>{dataContrato.fecha_inicio ? dataContrato.fecha_inicio : "No Registrada"}
                        <br />
                        <b>Fecha Vto: </b>{dataContrato.vencimiento_contrato ? dataContrato.vencimiento_contrato : "No Registrada"}
                        <br />
                      </>
                    }
                  />
                </ListItem>
              </Grid>

              <Grid item xs={6}>
                <ListItem>
                  <ListItemIcon>
                    <HistoryEduIcon color="primary" sx={{fontSize:35}} />
                  </ListItemIcon>
                  <ListItemText
                    secondary={
                        <>
                        <b>Modalidad: </b>{dataContrato.modalidad ? dataContrato.modalidad : "No Registrada"}
                        <br />
                        <b>Producto: </b>{dataContrato.producto ? dataContrato.producto : "No Registrado"}
                        <br />
                        {
                            dataContrato.consumo_kilos?  (
                                <>
                                <b>Consumo KG: </b>{dataContrato.consumo_kilos ? dataContrato.consumo_kilos : "No Registrado"}
                                <br />
                                </>
                            ):  
                            <>
                            <b>Consumo LTS: </b>{dataContrato.consumo_litros ? dataContrato.consumo_litros : "No Registrado"}
                            <br />
                            </>
                        }
                      </>
                    }

                />
                </ListItem>
              </Grid>
            </Grid>
          </List>
        </>
      ) : null}
    </div>
  );
}

export default DetalleContrato;
