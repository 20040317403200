import Navbar from "../../../Navbar";
import React, { useState, useEffect, useMemo } from "react";
import { UrlApi } from "../../../../services/apirest";
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Snackbar,
  Tooltip,
  IconButton,
  Stack,
} from "@mui/material";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //idioma
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import ComponentesDelProducto from "./ComponentesDelProducto";

// ICONOS
import AddBoxIcon from "@mui/icons-material/AddBox";
import LinkIcon from "@mui/icons-material/Link";
import CircularProgress from "@mui/material/CircularProgress";

//Notification
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ComponentesProductos() {
  const [componentesProductos, setComponentesProductos] = useState([]);
  const [tabla, setTabla] = useState(true);
  const [update, setUpdate] = useState();

  // AGREGAR PARA MOSTRAR EN LA TABLA

  // GET AGRUPADO (OBTIENE SOLO LA PRIMER COINCIDENCIA, LO USO PARA LA TABLA)
  const obtenerComponentesProductos = () => {
    fetch(UrlApi + "/componentes_productos/agrupados") //fetch con la urp de api q esta en services/apirest + la ruta
      .then((data) => data.json()) //mando los datos que recibo en json
      .then((data) => setComponentesProductos(data)); //seteo clientes
  };

  useEffect(() => {
    obtenerComponentesProductos();
  }, [tabla]);

  // ----------------------------------------------------------------------

  // FUNCION QUE SE EJECUTA CADA VEZ QUE HAY UN CAMBIO EN LA BASE DE DATOS
  // SIRVE PARA QUE LA TABLA MOSTRADA SE ACTUALICE EN TIEMPO REAL
  useEffect(() => {
    if (update) obtenerComponentesProductos();
  }, [update]);

  //**********************************************************************************************/

  // AL ABRIR EL DIALOG
  const handleClickNuevo = () => {
    setOpen(true);
    // AL ABRIR EL DIALOG LOS TEXTFIELDS SE VACIAN
    datosFormulario.producto = "";
    datosFormulario.componente = "";
  };

  //**********************************************************************************************/

  // PARA EL ALTA DE LA RELACION ENTRE COMPONENTES Y PRODUCTOS
  const [datosFormulario, setDatosFormulario] = useState({
    producto: "",
    componente: "",
    estado: 1,
  });

  const [productosPropelentes, setProductosPropelentes] = useState();

  const obtenerProductosPropelentes = () => {
    fetch(UrlApi + "/propelente_productos/activos") //fetch con la urp de api q esta en services/apirest + la ruta
      .then((data) => data.json()) //mando los datos que recibo en json
      .then((data) => setProductosPropelentes(data)); //seteo clientes
  };

  useEffect(() => {
    obtenerProductosPropelentes();
  }, []);

  ///

  const [componentes, setComponentes] = useState();

  const obtenerComponentes = () => {
    fetch(UrlApi + "/componentes_productos/" + datosFormulario.producto) //fetch con la urp de api q esta en services/apirest + la ruta
      .then((data) => data.json()) //mando los datos que recibo en json
      .then((data) => setComponentes(data));
  };

  useEffect(() => {
    obtenerComponentes();
  }, [datosFormulario.producto]);

  ///

  //Funcion para guardar
  const guardar = async (e) => {
    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(UrlApi + "/componentes_productos", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        idproducto: datosFormulario.producto,
        id_componente: datosFormulario.componente,
        estado: datosFormulario.estado,
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("La relación se guardó exitosamente !");
          setUpdate(true);
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar la relación");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    console.log(datosFormulario);
  };

  const almacenar = (e) => {
    console.log("DATOS:", datosFormulario);
    setDatosFormulario({
      ...datosFormulario,
      [e.target.name]: e.target.value,
    });
  };

  //**********************************************************************************************/

  // NOTIFICACION
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");

  //**********************************************************************************************/

  // CIERRE DEL SNACK
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
    setUpdate(false);
    setOpen(false);
  };

  //**********************************************************************************************/

  // DIALOG PARA CREAR NUEVA RELACION

  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      header: "ID Producto",
      accessorKey: "idproducto",
    },
    {
      header: "Producto",
      accessorKey: "nombre",
    },
  ];

  //**********************************************************************************************/

  // ESTILO PARA LA TABLA
  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //larger tooltip font size
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  return (
    <>
      <Navbar />
      <br />
      <Container maxWidth="xl">
        {componentesProductos && tabla ? (
          <ThemeProvider theme={tableTheme}>
            <MaterialReactTable
              columns={columns}
              data={componentesProductos}
              initialState={{
                showGlobalFilter: true,
                density: "compact",
              }}
              localization={MRT_Localization_ES} //idioma ES
              muiTableBodyProps={{
                sx: {
                  //ESTILO DE LA TABLA
                  "& .MuiTableCell-root": {
                    borderBottom: "1px solid",
                  },
                  //COLOR AL PASAR EL MOUSE POR ENCIMA
                  "&:hover": {
                    backgroundColor: `rgb(220,220,220)`,
                  },
                  "& .MuiTableCell-root:first-child": {
                    border: "none",
                  },
                },
              }}
              // TITULO
              renderTopToolbarCustomActions={({ table }) => (
                <Grid item mt={-2.5}>
                  <h2>RELACIÓN PRODUCTOS Y COMPONENTES</h2>
                </Grid>
              )}
              // ACCIONES
              renderToolbarInternalActions={({ table }) => (
                <>
                  <Tooltip arrow placement="bottom" title="Agregar Relación">
                    <IconButton
                      color="primary"
                      onClick={() => {
                        handleClickNuevo();
                      }}
                    >
                      <AddBoxIcon/>
                    </IconButton>
                  </Tooltip>
                  <MRT_FullScreenToggleButton table={table} />
                </>
              )}
              positionActionsColumn="last"
              // TRUE
              enableTopToolbar
              // FALSE
              enableColumnFilters={false} //opcion de filtrado por columnas
              enableDensityToggle={false}
              enableHiding={false} //oculta la propiedad show hide columns
              // PANEL DE DETALLES / FICHA / 2da TABLA
              renderDetailPanel={({ row }) => (
                <>
                  <Container maxWidth="x1">
                    {/* {update ? (
                      <ComponentesDelProducto
                        row={row}
                        // update2={update}
                      ></ComponentesDelProducto>
                    ) : (
                      <ComponentesDelProducto
                        row={row}
                        // update2={update}
                      ></ComponentesDelProducto>
                    ) } */}
                    
                    {!update ? (
                      <ComponentesDelProducto
                        row={row}
                        update2={update}
                        // tabla2={tabla}
                        // guardarRelacion={guardar}
                      />
                    ) : (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <CircularProgress size={65} />
                      </div>
                    )}
                  </Container>
                </>
              )}
            />
          </ThemeProvider>
        ) : null}

        {/* DIALOG PARA CREAR UNA NUEVA RELACION */}
        <Container maxWidth="xl">
          <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={open}
            onClose={handleClose}
          >
            <DialogTitle>AGREGAR RELACION</DialogTitle>
            <DialogContent>
              <form onSubmit={guardar}>
                <Grid container>
                  <Grid item xs={5.5} mt={2} mb={2}>
                    <FormControl focused fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Producto
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="producto"
                        label="Producto"
                        required
                        InputLabelProps={{ shrink: true }}
                        value={datosFormulario.producto}
                        onChange={almacenar}
                      >
                        {productosPropelentes
                          ? productosPropelentes.map((elemento) => (
                              <MenuItem
                                key={elemento.idproducto}
                                value={elemento.idproducto}
                              >
                                {elemento.nombre}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    alignSelf={"center"}
                    display={"flex"}
                    alignContent={"center"}
                    alignItems={"center"}
                  >
                    <LinkIcon
                      style={{ display: "inline-block", width: "100%" }}
                    />
                  </Grid>

                  {datosFormulario.producto ? (
                    <Grid item xs={5.5} mt={2} mb={2}>
                      <FormControl focused fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Componente
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="componente"
                          label="Componente"
                          required
                          InputLabelProps={{ shrink: true }}
                          value={datosFormulario.componente}
                          onChange={almacenar}
                        >
                          {componentes
                            ? componentes.map((elemento) => (
                                <MenuItem
                                  key={elemento.id_componente}
                                  value={elemento.id_componente}
                                >
                                  {elemento.nombre_componente}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : (
                    <Grid item xs={5.5} mt={2} mb={2}>
                      <FormControl focused fullWidth>
                        <InputLabel
                          id="demo-simple-select-label"
                          style={{ color: "GrayText" }}
                        >
                          Componente
                        </InputLabel>
                        <Select
                          id="demo-simple-select"
                          label="Componente"
                          disabled
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Grid>

                <Grid
                  mt={2}
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                />

                <Stack spacing={2} direction="row">
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleClose}
                  >
                    Cancelar
                  </Button>
                  {datosFormulario.producto && datosFormulario.componente ? (
                    <Button variant="contained" type="submit" color="success">
                      Guardar
                    </Button>
                  ) : (
                    <Button variant="contained" color="success" disabled>
                      Guardar
                    </Button>
                  )}
                </Stack>

                <Stack spacing={2} sx={{ width: "100%" }}>
                  <Snackbar
                    open={openSnack}
                    autoHideDuration={1500}
                    onClose={handleCloseSnack}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  >
                    <Alert
                      onClose={handleCloseSnack}
                      severity="success"
                      sx={{ width: "100%" }}
                      style={{ backgroundColor: colormensaje, color: "black" }}
                    >
                      {mensaje}
                    </Alert>
                  </Snackbar>
                </Stack>
              </form>
            </DialogContent>
          </Dialog>
        </Container>

        {/* SNACKBAR QUE SE MOSTRARA AL GUARDAR LOS CAMBIOS */}
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={openSnack}
            autoHideDuration={1500}
            onClose={handleCloseSnack}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseSnack}
              severity="success"
              sx={{ width: "100%" }}
              style={{ backgroundColor: colormensaje, color: "black" }}
            >
              {mensaje}
            </Alert>
          </Snackbar>
        </Stack>
      </Container>
    </>
  );
}

export default ComponentesProductos;
