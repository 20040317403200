import React, { useEffect, useState, useMemo } from "react";
import Navbar from "../../components/Navbar";

import { getData } from "../../services/library";
import NuevaFacturacion from "./NuevaFacturacion";

//MRT
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";

//MUI
import {
  createTheme,
  ThemeProvider,
  useTheme,
  IconButton,
  Tooltip,
  Container,
  Typography,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function FacturacionEspecial() {
  const [pedidos, setPedidos] = useState();
  const [tabla, setTabla] = useState(true);
  const [showNuevaFactura, setShowNuevaFactura] = useState(false);

  useEffect(() => {
    getData(
      "/pedidos/estado/248/" + localStorage.getItem("suc_auth"),
      setPedidos
    );
  }, []);

  const [initialState, setInitialState] = useState({
    density: "compact",
    showGlobalFilter: true,
    showColumnFilters: false,
    pagination: {
      pageSize: 10,
      pageIndex: 0,
    },
  });

  const columns = useMemo(
    () => [
      {
        header: "Datos Pedido ",
        columns: [
          {
            accessorKey: "id_pedido",
            header: "#",
            enableColumnActions: false,
            enableColumnFilter: false,
            size: 10,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                // backgroundColor: rowBg[row.original.id_estado] ?? "#fff",
                color: "black",
                maxWidth: 10,
              },
            }),
          },
          {
            accessorKey: "id_reparto",
            header: "Reparto",
            enableColumnActions: false,
            enableColumnFilter: false,
            size: 10,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                // backgroundColor: rowBg[row.original.id_estado] ?? "#fff",
                color: "black",
                maxWidth: 10,
              },
            }),
          },
          {
            accessorKey: "cliente",
            header: "Cliente",
            enableColumnActions: false,
            showColumnFilters: false,
            enableColumnFilter: false,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                // backgroundColor: rowBg[row.original.id_estado] ?? "#fff",
                color: "black",
                maxWidth: "250px",
              },
            }),
            Cell: ({ row }) => (
              <Tooltip title={row.original.cliente}>
                <span>
                  {row.original.sisven_id ? `${row.original.sisven_id} -` : ""}{" "}
                  {row.original.cliente}
                </span>
              </Tooltip>
            ),
          },
          {
            accessorKey: "localidad_direccionentrega", //access nested data with dot notation
            header: "Localidad Domicilio",
            enableColumnActions: false,
            enableColumnFilter: false,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                // backgroundColor: rowBg[row.original.id_estado] ?? "#fff",
                color: "black",
                width: "100px",
                maxWidth: "100px",
              },
            }),
            Cell: ({ row }) => (
              <Tooltip title={row.original.localidad_direccionentrega}>
                <span>{row.original.localidad_direccionentrega}</span>
              </Tooltip>
            ),
          },
          //   {
          //     accessorKey: "fecha_requerida",
          //     header: "Fecha Req",
          //     enableColumnActions: false,
          //     enableColumnFilter: false,
          //     size: 10,
          //     muiTableBodyCellProps: ({ row }) => ({
          //       sx: {
          //         // backgroundColor: rowBg[row.original.id_estado] ?? "#fff",
          //         color: "black",
          //       },
          //     }),
          //   },
          {
            accessorKey: "sucursal_atiende",
            header: "Sucursal Atiende",
            enableColumnActions: false,
            maxSize: 5,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                // backgroundColor: rowBg[row.original.id_estado] ?? "#fff",
                color: "black",
              },
            }),
          },
          {
            accessorKey: "empresa",
            header: "Empresa",
            enableColumnActions: false,
            enableColumnFilter: false,
            size: 10,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                // backgroundColor: rowBg[row.original.id_estado] ?? "#fff",
                color: "black",
              },
            }),
          },
          //   {
          //     accessorKey: "valor",
          //     header: "Estado",
          //     enableColumnActions: false,
          //     enableColumnFilter: false,
          //     size: 20,
          //     muiTableBodyCellProps: ({ row }) => ({
          //       sx: {
          //         // backgroundColor: rowBg[row.original.id_estado] ?? "#fff",
          //         color: "black",
          //         width: "20px",
          //         maxWidth: "20px",
          //       },
          //     }),
          //     Cell: ({ row }) => (
          //       <Tooltip title={row.original.valor} arrow>
          //         <span>{row.original.valor}</span>
          //       </Tooltip>
          //     ),
          //   },
        ],
      },
      {
        header: "Datos Entrega",
        muiTableHeadCellProps: {
          sx: {
            borderLeft: 1,
            borderRight: 1,
            borderColor: "#fff",
            backgroundColor: "#2471A3",
            color: "white", // Color del texto
            fontSize: 16,
            fontWeight: "normal",
            pt: 1,
          },
        },
        columns: [
          {
            accessorKey: "kgentregados",
            header: "Kgs",
            enableColumnActions: false,
            enableColumnFilter: false,
            size: 5,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                // backgroundColor: rowBg[row.original.id_estado] ?? "#fff",
                color: "black",
                width: "20px",
                maxWidth: "20px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
            }),
            muiTableHeadCellProps: {
              sx: {
                fontSize: 16,
                fontWeight: "normal",
                pt: 1,
                backgroundColor: "#2471A3",
                borderLeft: 1,
                borderRight: 1,
                borderColor: "#fff",
                color: "white", // Puedes ajustar el color del texto también
              },
            },
          },
          {
            accessorKey: "ltsentregados",
            header: "Lts",
            enableColumnActions: false,
            enableColumnFilter: false,
            size: 5,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                // backgroundColor: rowBg[row.original.id_estado] ?? "#fff",
                color: "black",
                width: "20px",
                maxWidth: "20px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
            }),
            muiTableHeadCellProps: {
              sx: {
                fontSize: 16,
                fontWeight: "normal",
                pt: 1,
                borderRight: 1,
                backgroundColor: "#2471A3",
                color: "white", // Puedes ajustar el color del texto también
              },
            },
          },
          {
            accessorKey: "fecha_real_entrega",
            header: "Fecha Entrega",
            enableColumnActions: false,
            enableColumnFilter: false,
            size: 5,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                // backgroundColor: rowBg[row.original.id_estado] ?? "#fff",
                color: "black",
                width: "20px",
                maxWidth: "20px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
            }),
            muiTableHeadCellProps: {
              sx: {
                fontSize: 16,
                fontWeight: "normal",
                pt: 1,
                borderRight: 1,
                backgroundColor: "#2471A3",
                color: "white", // Puedes ajustar el color del texto también
              },
            },
          },
          {
            accessorKey: "nro_remito",
            header: "Remito",
            enableColumnActions: false,
            enableColumnFilter: false,
            size: 5,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                // backgroundColor: rowBg[row.original.id_estado] ?? "#fff",
                color: "black",
                width: "20px",
                maxWidth: "20px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
            }),
            muiTableHeadCellProps: {
              sx: {
                fontSize: 16,
                fontWeight: "normal",
                pt: 1,
                borderRight: 1,
                backgroundColor: "#2471A3",
                color: "white", // Puedes ajustar el color del texto también
              },
            },
          },
        ],
      },
    ],
    []
  );

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "primary",
    size: "small",
  };

  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      pt: 1,
    },
  };

  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000",
          },
        },
        typography: {
          button: {
            textTransform: "none",
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //larger tooltip font size
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const customLocalization = {
    ...MRT_Localization_ES,
    expandAll: false,
    expand: <ExpandMoreIcon />,
  };

  return (
    <div>
      <Navbar />
      {tabla && pedidos && columns ? (
        <Container disableGutters maxWidth="xl" sx={{ mt: 1 }}>
          <ThemeProvider theme={tableTheme}>
            <MaterialReactTable
              enableFacetedValues
              enablePinning={false}
              enableDensityToggle={false}
              enableExpandAll={false}
              columns={columns}
              data={pedidos}
              initialState={initialState}
              positionActionsColumn="last"
              muiTableHeadCellProps={tableHeadCellProps}
              displayColumnDefOptions={{
                "mrt-row-expand": {
                  size: 5,
                },
              }}
              localization={customLocalization}
              muiTableBodyCellProps={({ row }) => ({
                sx: {
                  // backgroundColor: rowBg[row.original.id_estado] ?? "#fff",
                  color: "black",
                },
              })}
              // renderDetailPanel={({ row }) => (
              //   <DetallePedido datos={row.original} />
              // )}
              muiSearchTextFieldProps={muiSearchTextFieldProps}
              renderTopToolbarCustomActions={() => (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    // startIcon={<AddBoxTwoToneIcon color="asd" />}
                    onClick={() => {
                      setShowNuevaFactura(true);
                      setTabla(false);
                    }}
                  >
                    Facturar
                  </Button>
                  <Typography variant="poster" fontSize={26}>
                    Facturación Especial
                  </Typography>
                </>
              )}
            />
          </ThemeProvider>
        </Container>
      ) : null}
      {showNuevaFactura && <NuevaFacturacion />}
    </div>
  );
}

export default FacturacionEspecial;
