import { Box, Button, TextField, Grid, Container, Typography, MenuItem, Select, FormControlLabel, Checkbox } from '@mui/material';
import React, { useEffect, useState } from 'react'
import swal from 'sweetalert';
import {checkExisteRecuentoFisico} from '../../services/services';
import {UrlApi} from '../../services/apirest';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment'

function StkFisicoGranel(props) {

    let fechahora = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
    let fechasola = moment(new Date()).format("YYYY-MM-DD")
    let segmento = "granel" ///solo grabamos granel aca
    let usuario = localStorage.getItem('user')
    let sucursal = localStorage.getItem('sucursal')

    const [stkuuid,setStkuuid] = useState(uuidv4())
    const [stkuuidOld,setStkuuidOld] = useState("")
    
    const [campos, setCampos] = useState([])
    const [butano, setButano] = useState(0.560)    //--> esto hay que ponerlo en las tablas de productos
    const [propano, setPropano] = useState(0.520)  //--> esto hay que ponerlo en las tablas de productos
    const [totalbutano, setTotalButano] = useState(0)
    const [totalpropano, setTotalPropano] = useState(0)
    const [observaciones, setObservaciones] = useState("")

     useEffect(() => {
        const recoverCampos= async () =>{
            const data = await fetch(UrlApi+"/formstkgranel/"+sucursal)
            const dataresult = await data.json()
            setCampos(dataresult)
            return dataresult;
        }

        const recoverDatos= async () =>{
            const data = await fetch(UrlApi+"/stkfisicototal/"+sucursal+"/"+segmento+"/"+fechasola)
            const dataresult = await data.json()
            setStkuuidOld(dataresult[0].stkuuid)
            setCampos(dataresult)
            setObservaciones(dataresult[0].observacion)
            return dataresult;
            console.log("hola soy los campos",campos)
        }

        props.modificar ? recoverDatos() : recoverCampos()
        
    },[])


    useEffect(() => {
        let butanototal = 0
        let propanototal = 0
        for (let i = 0; i < campos.length; i++) {
            campos[i].stkuuid= stkuuid
            campos[i].fecharecuento= fechahora
           if (campos[i].tanque[0].toLowerCase() !== "v") {
            campos[i].producto === "Butano" ? campos[i].cantidad = (campos[i].capacidadltrs*butano*campos[i].porcentaje)/100
                                            : campos[i].cantidad = (campos[i].capacidadltrs*propano*campos[i].porcentaje)/100 }
                                            else {
                                                campos[i].cantidad = campos[i].porcentaje*1
                                            }
            campos[i].producto === "Butano" ? butanototal += campos[i].cantidad 
                                            : propanototal += campos[i].cantidad ;
            campos[i].producto === "Butano" ? campos[i].densidad = butano : campos[i].densidad= propano; 
            setTotalButano(butanototal);
            setTotalPropano(propanototal)
            
          }
    }, [campos, butano, propano])

    const handleVolver = () => window.location.hash = "/navbar" ;

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(JSON.stringify(campos))
        props.modificar && handleBorrarstkfisicototal()
        handleGrabarstkfisicototal()
        handleGrabarObservaciones()
        handleVolver()
        swal({
            title: "Recuento Granel",
            text: "Se registro el recuento correctamente",
            icon: "success",
            closeOnClickOutside: false,
             })
    } 

    const handleBorrarstkfisicototal = async () =>{
        const data = await fetch(UrlApi+"/stkfisicototal/"+stkuuidOld,{
            method:'DELETE', headers:{'Content-Type':'application/json'}
        })
        .catch(error => {
              console.error('There was an error!', error);
      })}



    const updateItem = (prop, event, index) => {
        console.log(event.target.checked ? 1 : 0)
        const old = campos[index];
        const updated = { ...old, [prop]: event.target.value, 
                        'cantidad': (old.tanque[0].toLowerCase() !== "v" ? old.producto === "Butano" ? 
                        (old.capacidadltrs*butano*event.target.value)/100 : 
                        (old.capacidadltrs*propano*event.target.value)/100 : event.target.value*1),
                        'estado': event.target.checked ? 1 : 0 ,
                        'fecharecuento': fechahora,
                        'stkuuid': stkuuid, 'usuario': usuario }
        const clone = [...campos];
        clone[index] = updated;
        setCampos(clone);
        console.log(campos)
    }


    const handleGrabarstkfisicototal = async () =>{
        const data = await fetch(UrlApi+"/stkfisicototal",{
            method:'POST', headers:{'Content-Type':'application/json'},
            body: JSON.stringify(campos)
              
        } )
          .then (console.log("listo")) 
          .catch(error => {
                  console.error('There was an error!', error);
                  alert("error",error)
          })
      }

      const handleGrabarObservaciones = async () =>{
        const data = await fetch(UrlApi+"/stkfisicoobservaciones",{
            method:'POST', headers:{'Content-Type':'application/json'},
            body: JSON.stringify([{stkuuid:stkuuid,observacion:observaciones,segmento:segmento, usuario:usuario}])
              
        } )
          .then (console.log("listo")) 
          .catch(error => {
                  console.error('There was an error!', error);
                  alert("error",error)
          })
      }

      return (
        
        <div>
        <Container>
        <br/>
        <Typography variant="h5" component="h4" bgcolor='navy' color='white'> {props.titulo} Recuento físico de Granel </Typography>
        <div><br/>
            <TextField
                disabled
                variant="outlined"
                size="small"
                style = {{width: 125}}
                label="Densidad Butano"
                name="Dbutano"
                value={butano}
                type="number"
                onChange={(e)=> {setButano(e.target.value);}}
                inputProps={{style: { textAlign: 'right', background:"#f2f0aa"}}}
            />
            <TextField
                disabled
                variant="outlined"
                size="small"
                style = {{width: 125}}
                label="Densidad Propano"
                name="Dpropano"
                value={propano}
                type="number"
                onChange={(e)=> {setPropano(e.target.value);}}
                inputProps={{style: { textAlign: 'right', background:"#f2f0aa"}}}
            />
            <TextField
                disabled
                variant="outlined"
                size="small"
                style = {{width: 150}}
                label="Total Butano Kgrs"
                name="Tbutano"
                value={isNaN(totalbutano) ? 0 :parseInt(totalbutano)}
                inputProps={{style: { textAlign: 'right', background:"#f5f0f7"}}}
            />
            <TextField
                disabled
                variant="outlined"
                size="small"
                style = {{width: 150}}
                label="Total Propano Kgrs"
                name="Tpropano"
                value={isNaN(totalpropano) ? 0 : parseInt(totalpropano)}
                inputProps={{style: { textAlign: 'right', background:"#e1e7f5",  }}}
            />
            
        </div>
        <form id="myform"  autoComplete='off' onSubmit={handleSubmit}>
        {campos.map((campo, index) => {
            if (campo.tanque[0].toLowerCase() !== "v") {
        return (
          <div key={campo.id}>
              <br />
            <TextField
                disabled
                variant="outlined"
                label="Tanque"
                name="Tanque"
                value={campo.tanque}
                size="small"
                style = {{width: 125}}
            />
            <TextField
                disabled
                variant="outlined"
                label="Capacidad Ltrs"
                name="Capacidad"
                value={campo.capacidadltrs}
                size="small"
                style = {{width: 125}}
            />
            
{/*             <Select
                size="small"
                style = {{width: 100}}
                value={campo.producto}
                label="Producto"
                onChange={e => {updateItem('producto', e, index)}}
                >
                <MenuItem value={'Butano'}>Butano</MenuItem>
                <MenuItem value={'Propano'}>Propano</MenuItem>
                <MenuItem value={'Mezcla'}>Mezcla</MenuItem>
            </Select> */}
          

           
            <TextField
                disabled
                variant="outlined"
                label="Producto"
                name="Producto"
                value={campo.producto}
                size="small"
                style = {{width: 100}}
            /> 
            <TextField
                variant="outlined"
                label="Porcentaje %" color="warning"
                onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
                }}}
                onChange={e => {updateItem('porcentaje', e, index)}}
                value={isNaN(campo.porcentaje) ? 0 : campo.porcentaje}
                name="porcentaje"
                inputProps={{style: { textAlign: 'right', maxLength: 2, background:"#f2f0aa"}}}
                onInput={e => {
                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2);
                  }}
                size="small"   
                style = {{width: 100}}
            />
            <TextField
                disabled
                variant="outlined"
                label="Existencia Kgrs"
                name="Kgrss"
                value={isNaN(campo.cantidad) ? 0 : parseInt(campo.cantidad)}
                inputProps={{style: { textAlign: 'right', }}}
                size="small"
                style = {{width: 100}}
            />
            
          </div>
          
        )};
        })}</form>
        {<div><br/>
            <Typography variant="h5" component="h4" bgcolor='lightblue' color='black'> Vehiculos cargados con producto</Typography>
            <br />
            {campos.map((campo, index) => {
            if (campo.tanque[0].toLowerCase() == "v")  {
              return (<div key={campo.id}>
              <FormControlLabel
                variant="outlined"
                control={<Checkbox checked={campo.estado == 1 ? true : false} 
                onChange={e => {updateItem('estado', e, index)}} />}
                label="En Reparto"
            />
              <TextField
                variant="outlined"
                size="small"
                style = {{width: 125}}
                label="Patente"
                name="wpatente"
                value={campo.vehiculo}
                onChange={e => {updateItem('vehiculo', e, index)}}
            />
            <Select
                size="small"
                style = {{width: 150}}
                value={campo.producto}
                label="Producto"
                onChange={e => {updateItem('producto', e, index)}}
                >
                <MenuItem value={'Butano'}>Butano</MenuItem>
                <MenuItem value={'Propano'}>Propano</MenuItem>
            </Select>
            <TextField
                variant="outlined"
                onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                    }}}
                label="Kgrs"
                name="Kgrs"
                value={isNaN(campo.porcentaje) ? 0 : parseInt(campo.porcentaje)}
                inputProps={{style: { textAlign: 'right', }}}
                size="small"
                style = {{width: 100}}
                onChange={e => {updateItem('porcentaje', e, index)}}
            />
 

            </div>
            )}})}
            </div>}
            <hr />
            <TextField           
                label="Observaciones"
                multiline
                fullWidth
                maxRows={4}
                value={observaciones}
                onChange={(e)=> setObservaciones(e.target.value)}
            />
            <Box sx={{'& > :not(style)': { m: 1, width: '20ch' },}} justifyContent="flex-start" >
            <Button 
                variant="outlined"
                onClick={handleVolver} 
                color="warning">
                Cancelar
            </Button>
            <Button 
                variant="outlined"
                color="success"
                type="submit"
                form="myform">
                Guardar datos
            </Button>
            </Box>
        </Container>
        </div>
    )
}

export default StkFisicoGranel
