import React from "react";
import { useState} from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import FichaTanque from "./FichaTanque";
import HistorialTanque from "./HistorialTanque";
import Title from "../../Title"


//Iconos y boludeces para los textfields
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import { grey } from "@mui/material/colors";

//TABLAS
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

function DatosTanques(props) {
  const [opcion, setOpcion] = useState("fichatecnica");
  const handleChange = (event, newValue) => {
    setOpcion(newValue);
  };

  //Cierra la ficha del cliente
  const handleClose = () => {
    props.ficha(false);
    props.tabla(true);
  };

  return (
    <div>
      <Container>
        <Title
          titulo={`Datos Tanque N° Serie: ${props.datos.nro_serie}`}
          handleClose={handleClose}
         />
        <Box sx={{ width: "100%" }}>
          <Tabs
            value={opcion}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
            variant="fullWidth"
          >
            <Tab value="fichatecnica" label="Ficha Técnica" />
            <Tab value="historial" label="Historial" />
          </Tabs>
        </Box>
        {/* <br></br> */}
        {opcion === "fichatecnica" ? ( 
          <FichaTanque datos={props.datos} />
        ) : null}
        {opcion === "historial" ? ( 
          <HistorialTanque datos={props.datos} />
        ) : null}
      </Container>
    </div>
  );
}

export default DatosTanques;
