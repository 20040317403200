import Navbar from "../Navbar";
import React, { useEffect, useState, useMemo } from "react";
import { UrlApi } from "../../services/apirest";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  Typography,
  Stack,
  Grid,
  Tooltip,
  TextField,
} from "@mui/material";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddBoxIcon from "@mui/icons-material/AddBox";

//Notification
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

function Romaneo_fabricantes() {
  const [romaneo_fabricantes, setRomaneo_fabricantes] = useState();
  const [datosRomaneoFabricantes, setDatosRomaneoFabricantes] = useState({});
  const [tabla, setTabla] = useState(true);
  const [update, setUpdate] = useState(false);
  const [disBtn, setDisBtn] = useState(false);

  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");

  //funcion para obtener a los romaneo leyendas, pasarlos a json y guardarlos en data
  const obtenerRomaneoFabricantes = () => {
    fetch(UrlApi + "/romaneo_fabricantes")
      .then((data) => data.json())
      .then((data) => setRomaneo_fabricantes(data));
  };
  // console.log("romaneo_fabricantes", setRomaneo_fabricantes)

  //funcion que espera los cambios de la funcion de obtener a los romaneo leyendas
  useEffect(() => {
    obtenerRomaneoFabricantes();
  }, [tabla]);

  // FUNCION QUE SE EJECUTA CADA VEZ QUE HAY UN CAMBIO EN LA BASE DE DATOS
  // SIRVE PARA QUE LA TABLA MOSTRADA SE ACTUALICE EN TIEMPO REAL
  useEffect(() => {
    if (update) obtenerRomaneoFabricantes();
  }, [update]);

  //*************************************************************************************************************************************/

  // DIALOG PARA CREAR LOS DATOS
  const [openCrear, setOpenCrear] = React.useState(false);

  // USANDO ROW TOMO LOS DATOS DE ESA FILA
  const handleClickOpenCrear = (row) => {
    setOpenCrear(true);
    // AL ABRIR EL DIALOG El TEXTFIELDS SE VACIAN
    datosRomaneoFabricantes.mat_fabricante = "";
    datosRomaneoFabricantes.descripcion_fabricante = "";
  };

  const handleCloseCrear = () => {
    setOpenCrear(false);
  };

  //*************************************************************************************************************************************/
   // DIALOG PARA EDITAR LOS DATOS
   const [openEditar, setOpenEditar] = React.useState(false);

   // USANDO ROW TOMO LOS DATOS DE ESA FILA
   const handleClickOpenEditar = (row) => {
     setOpenEditar(true);
     setDatosRomaneoFabricantes(row);
   };
 
   const handleCloseEditar = (row) => {
     setOpenEditar(false);
     setDatosRomaneoFabricantes(row);
   };
 
   //*************************************************************************************************************************************/

  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
    setUpdate(false);
    setOpenCrear(false);
    setOpenEditar(false);
    setDisBtn(false);
  };

  //*************************************************************************************************************************************/

  const columns = useMemo(
    () => [
      { header: "Matrícula fabricante", accessorKey: "mat_fabricante", size: 200 },
      {
        header: "Descripción del fabricante",
        accessorKey: "descripcion_fabricante",
        size: 200,
      },
    ],
    []
  );

  const muiSearchTextFieldProps = {
    // para el buscar
    placeholder: "Buscar...",
    sx: { minWidth: "18rem" },
    variant: "outlined",
    color: "success",
    size: "small",
  };

  //*************************************************************************************************************************************/

   //Funcion para guardar al crear
   const guardarCrear = async (e) => {
    console.log("Estoy por guardar");
    setDisBtn(true);
    e.preventDefault();
    await fetch(UrlApi + "/romaneo_fabricantes", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        mat_fabricante: datosRomaneoFabricantes.mat_fabricante,
        descripcion_fabricante: datosRomaneoFabricantes.descripcion_fabricante,
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          setUpdate(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El fabricante se guardó exitosamente !");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el fabricante");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  console.log(datosRomaneoFabricantes);

  //*************************************************************************************************************************************/
   //Funcion para guardar AL EDITAR
   const guardarEditar = async (e) => {
    console.log("Estoy por guardar");
    setDisBtn(true);
    e.preventDefault();
    await fetch(
      UrlApi + "/romaneo_fabricantes/" + datosRomaneoFabricantes.id_fabricante,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            mat_fabricante: datosRomaneoFabricantes.mat_fabricante,
            descripcion_fabricante: datosRomaneoFabricantes.descripcion_fabricante,
        }),
      }
    )
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          setUpdate(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El fabricante se guardó exitosamente !");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el fabricante");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    console.log(datosRomaneoFabricantes);
  };

  //*************************************************************************************************************************************/

  const almacenar = (e) => {
    setDatosRomaneoFabricantes({
      ...datosRomaneoFabricantes,
      [e.target.name]: e.target.value,
    });
  };

  // ESTILO PARA LA TABLA

  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)" //background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //override to make tooltip font size larger
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const displayColumnDefOptions = {
    "mrt-row-actions": {
      muiTableHeadCellProps: {
        align: "center",
        sx: {
          fontSize: 14,
          fontStyle: "italic",
        },
      },
      size: 100,
    },
  };

  const initialState = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
  };

 console.log ("Datos fabricante",datosRomaneoFabricantes)
  return (
    <>
      <Navbar />
      <Container maxWidth="xl" mt={1}>
        {romaneo_fabricantes && tabla ? (
          <Box mt={2}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableExpandAll={false}
                enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
                // enableColumnResizing
                enableGrouping // para permitir agrupar x estado x ej
                displayColumnDefOptions={displayColumnDefOptions}
                layoutMode="grid"
                muiTableHeadCellProps={{
                  align: "left", // los titulos de la tabla
                }}
                muiTableBodyCellProps={{
                  align: "left", //el contenido de la tabla
                }}
                enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
                enableColumnFilters={false} //desactiva filtros x  columna
                enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
                enableDensityToggle={false} //
                localization={MRT_Localization_ES} //idioma
                enableColumnDragging={false} //para arrastrar columnas
                initialState={initialState}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                columns={columns}
                data={romaneo_fabricantes} //con lo q la completas (esto cambia siempre)
                enableStickyFooter
                enableEditing
                positionActionsColumn="last" // posicion de la columna de acciones al final
                // ACCIONES
                renderToolbarInternalActions={(
                  { table } // boton para nuevo , al lado del buscar
                ) => (
                  <>
                    <Tooltip arrow placement="right" title="Nuevo Fabricante">
                      <IconButton color="primary">
                        <AddBoxIcon
                          onClick={() => {
                            handleClickOpenCrear();
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                    <MRT_FullScreenToggleButton table={table} />
                  </>
                )}
                renderTopToolbarCustomActions={() => (
                  <>
                    <Typography variant="poster" fontSize={26}>
                      Fabricantes
                    </Typography>
                  </>
                )}
                renderRowActions={(
                  { row } //las acciones del costado editar
                ) => (
                  <div>
                    <Tooltip arrow placement="left" title="Editar Fabricante">
                      <IconButton
                        onClick={() => handleClickOpenEditar(row.original)}
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              />
            </ThemeProvider>
          </Box>
        ) : null}
      </Container>

      {/* AGREGAR ITEM */}
      <Dialog
        open={openCrear}
        onClose={handleCloseCrear}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle marginLeft={3}>
          <b>{"Agregar Fabricante"}</b>
        </DialogTitle>
        <DialogContent>
          <Container maxWidth="xl">
            <form onSubmit={guardarCrear}>
              <Grid container mt={2} mb={1} spacing={1}>
                <Grid item xs={3} mb={2}>
                  <TextField
                    label="Matrícula"
                    name="mat_fabricante"
                    size="small"
                    placeholder="ej 123"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    inputProps={{
                      maxLength: 6,
                    }}
                    focused
                    required
                    fullWidth
                    value={datosRomaneoFabricantes.mat_fabricante}
                    onChange={almacenar}
                  />
                </Grid>
                <Grid item xs={9} mb={2}>
                  <TextField
                    label="Descripción fabricante"
                    name="descripcion_fabricante"
                    size="small"
                    placeholder="ej Hipergas"
                    focused
                    required
                    fullWidth
                    value={datosRomaneoFabricantes.descripcion_fabricante}
                    onChange={almacenar}
                  />
                </Grid>
              </Grid>

              <Stack direction="row">
                <Grid container direction={{ xs: "column", sm: "row" }}>
                  <Grid item xs={9}>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleCloseCrear}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={disBtn}
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Stack>
            </form>
          </Container>
        </DialogContent>
      </Dialog>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>

      {/* EDITAR ITEM */}
      <Dialog
        open={openEditar}
        onClose={handleCloseEditar}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle marginLeft={3}>
          <b>{"Editar Fabricante"}</b>
        </DialogTitle>
        <DialogContent>
          <Container maxWidth="xl">
            <form onSubmit={guardarEditar}>
              <Grid container mt={2} mb={1} spacing={1}>
                <Grid item xs={3} mb={2}>
                  <TextField
                    label="Matrícula"
                    name="mat_fabricante"
                    size="small"
                    placeholder="..."
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    inputProps={{
                      maxLength: 6,
                    }}
                    focused
                    required
                    fullWidth
                    value={datosRomaneoFabricantes.mat_fabricante}
                    onChange={almacenar}
                  />
                </Grid>
                <Grid item xs={9} mb={2}>
                  <TextField
                    label="Descripción fabricante"
                    name="descripcion_fabricante"
                    size="small"
                    placeholder="..."
                    focused
                    required
                    fullWidth
                    value={datosRomaneoFabricantes.descripcion_fabricante}
                    onChange={almacenar}
                  />
                </Grid>
              </Grid>

              <Stack direction="row">
                <Grid container direction={{ xs: "column", sm: "row" }}>
                  <Grid item xs={9}>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleCloseEditar}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Stack>
            </form>
          </Container>
        </DialogContent>
      </Dialog>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>
    </>  
  )
}

export default Romaneo_fabricantes