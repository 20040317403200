import React, { useState, useEffect } from "react";
import { UrlApi } from "../../../../../services/apirest";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import moment from "moment";
import Notify from "../../../../Notify";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import {getData, putData} from "../../../../../services/library" 

function FormBajaCliente(props) {
  const [datosCliente, setDatosCliente] = useState(props.cliente);
  const [observaciones, setObservaciones] = useState("");
  const [fechaInactivo, setFechaInactivo] = useState("");
  const [disBtn, setDisBtn] = useState(false);

  //buscar si este cliente tiene vinculos de tanques_contratos activos, o sea si tiene tanques asociados. Si los tiene, primero quitarselos y luego borrar.
  //poner una advertencia.
  const [vinculosActivos, setVinculosActivos] = useState("");

  useEffect(() => {
    getData("/tanques_contratos/vinculosactivos/" + datosCliente.id,setVinculosActivos )
  }, []);
   // a


  const handleClose = () => {
    props.setOpen(false)
  };

  const [notificacion, setNotificacion] = useState({
    open: false,
    mensaje: "",
    severity: "",
    color: "",
  });

  async function marcarInactivo(e) {
    setDisBtn(true);
    putData(
      e,
      "/clientes/" + datosCliente.id,
      {
        activo: 0,
        motivo_inactivo: observaciones,
        fecha_inactivo: fechaInactivo,
      },
      setNotificacion
    );
  }
  
  return (
    <div>
      <Dialog open={props.open} onClose={handleClose}>
        {notificacion && notificacion.open ? (
          <Notify
            mensaje={notificacion.mensaje}
            open={notificacion.open}
            color={notificacion.color}
            handleClose={() => {
              props.actualizar();
              props.setOpen(false);
              setNotificacion({open:false})
            }}
            severity={notificacion.severity}
          />
        ) : null}
        <form onSubmit={marcarInactivo}>
          <Box backgroundColor={"#1F618D"}>
            <DialogTitle>
              <Typography color="#ffffff" fontSize={20}>
                Baja de cliente: {datosCliente.razon_social}
              </Typography>
            </DialogTitle>
          </Box>
          <DialogContent>
            {vinculosActivos.length && vinculosActivos.length > 0 ? (
              <Alert severity="warning">
                <AlertTitle>Info</AlertTitle>
                Para poder dar de baja un cliente, es necesario que se hayan
                removido todos los tanques de sus domicilios de entrega—{" "}
                <strong> Revisar!</strong>
              </Alert>
            ) : null}
            {vinculosActivos && vinculosActivos.length === 0 ? (
              <>
                <DialogContentText textAlign={"center"}>
                  Ingrese motivos u observaciones y fecha de baja del cliente.
                </DialogContentText>
                <Box>
                  <TextField
                    disabled={
                      vinculosActivos.length && vinculosActivos.length > 0
                    }
                    required
                    color="primary"
                    onChange={(event) => {
                      setObservaciones(event.target.value);
                    }}
                    placeholder="Escriba aquí sus observaciones "
                    value={observaciones}
                    margin="dense"
                    fullWidth
                    variant="standard"
                  />
                </Box>
                <br></br>
                <Box>
                  <TextField
                    disabled={
                      vinculosActivos.length && vinculosActivos.length > 0
                    }
                    required
                    name="fecha_inactivo"
                    focused
                    onChange={(event) => setFechaInactivo(event.target.value)}
                    value={moment(fechaInactivo).format("YYYY-MM-DD")}
                    autoFocus
                    margin="dense"
                    id="name"
                    type="date"
                    fullWidth
                    variant="outlined"
                  />
                </Box>
              </>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Stack spacing={45} direction="row">
              <Button
                color="error"
                disabled={disBtn}
                variant="contained"
                onClick={handleClose}
              >
                Cancelar
              </Button>
              <Button
                color="success"
                disabled={
                  disBtn ||
                  (vinculosActivos.length && vinculosActivos.length > 0)
                }
                type="submit"
                variant="contained"
              >
                Confirmar
              </Button>
            </Stack>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default FormBajaCliente;
