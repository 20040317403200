import {
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";

const HasInscripcion = ({ open, close, InscripcionesBefore, agregar }) => {
  return (
    <div>
      <Dialog open={open} onClose={close}>
        <DialogTitle>El Proveedor ya tiene Inscripciones</DialogTitle>
        <DialogContent>
          <List>
            {InscripcionesBefore.map((inscripciones) => (
              <ListItem key={inscripciones.idprovincia}>
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary={inscripciones.provincia} />
              </ListItem>
            ))}
          </List>
          <Divider />

          <DialogContentText>Desea Agregar Mas ?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={agregar} color="success">
            Agregar
          </Button>
          <Button variant="contained" onClick={close} color="error">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default HasInscripcion;
