import React, { useEffect, useState } from 'react';
import { Card, CardHeader, Avatar, TableBody, IconButton, TableHead, TableRow, TableCell, Paper, Table, TableContainer, AccordionDetails, Divider, Typography, AccordionSummary, Grid, Button, TextField, Container, Box, List, Accordion } from '@mui/material';
import moment from 'moment' // con esto manejamos las fechas bien
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import { getData } from '../../../../services/library';
import { UrlApi } from '../../../../services/apirest';
import { verEnviarPDF } from '../../../../services/services';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";


export default function OpenRendiciones(props) {
    const { sucursalSelected, open, setopen, nohay, fecha, setFecha } = props;

    const [kgsEmpresa1, setKgsEmpresa1] = useState(0);  // Estado para los kgs de la empresa 1
    const [kgsEmpresa4, setKgsEmpresa4] = useState(0);  // Estado para los kgs de la empresa 4
    const [repartos, setRepartos] = useState([]);
    const [pedidos, setPedidos] = useState([]);
    const [facturas, setFacturas] = useState([]);
    const [expandedRepartos, setExpandedRepartos] = useState([]);
    const [pedidosRepartos, setpedidosRepartos] = useState([]);

    const getRepartos = async () => {
        let query = {};

        // Agregar los parámetros al query si están definidos
        if (sucursalSelected) query.id_sucursal = sucursalSelected;

        if (fecha) query.fecha_finalizado = moment(fecha).format('YYYY-MM-DD');

        // Construir los parámetros de consulta
        const queryParams = new URLSearchParams(query).toString();
        const endpoint = queryParams ? `/repartos/listado?${queryParams}` : '/repartos/listado';

        try {
            // Llamada asíncrona para obtener datos
            await getData(endpoint, setRepartos);
        } catch (error) {
            console.error("Error al obtener repartos:", error);
        }
    };

    const getPedidosRepartos = async () => {
        if (!repartos) {
            return;
        }

        try {
            const pedidosPromises = repartos.map((r) =>
                fetch(UrlApi + `/pedidos/reparto/${r.nro_reparto}`)
                    .then(response => response.json())
                // .then(console.log("URL", UrlApi + `/pedidos/lista_repartos/${idSucursal}/${r.nro_reparto}`))
            );

            const pedidosResults = await Promise.all(pedidosPromises);

            const allPedidos = pedidosResults.flat();
            setpedidosRepartos(allPedidos);
            // console.log("pedidos DEL REPARTO", pedidosRepartos);

        } catch (error) {
            console.error("Error obteniendo pedidos", error);
        }
    };

    useEffect(() => {
        getRepartos()
    }, [fecha]);

    const getPedidos = async () => {
        if (!repartos) {
            return;
        }

        try {
            const pedidosPromises = repartos.map((r) =>
                fetch(UrlApi + `/pedidos/finalizados/${r.nro_reparto}`)
                    .then(response => response.json())

            );

            const pedidosResults = await Promise.all(pedidosPromises);
            const allPedidos = pedidosResults.flat();
            setPedidos(allPedidos);

            // Suponiendo que repartos contiene un 'nro_reparto', puedes pasar este valor
            // para filtrar los pedidos de ese reparto en particular
            // const nroReparto = repartos[0].nro_reparto; // O cualquier valor de nro_reparto específico
            // Llamamos a la función con el nro_reparto

        } catch (error) {
            console.error("Error obteniendo pedidos:", error);
        }
    };

    const getFacturas = async () => {
        if (!pedidos) {
            console.log("No hay pedidos disponibles para obtener facturas");
            return;
        };

        //try para obtener las facturas
        try {
            const facturasPromises = pedidos.map((m) =>
                // console.log("m",m)
                fetch(UrlApi + `/comprobantes/pedido/${m.id}`)
                    .then(response => response.json())
                // .then(console.log("URL", UrlApi + `/comprobantes/pedido/${pedidos[0].id_cliente}`))
            )

            const facturasResults = await Promise.all(facturasPromises);
            const allFacturas = facturasResults.flat();
            // console.log("allFacturas", allFacturas);
            setFacturas(allFacturas)
        } catch (error) {
            console.error("Error obteniendo facturas:", error)
        }
    };

    const handleViewPDF = (id_factura, id_pedido) => {
        // Lógica para abrir el PDF de la factura
        buscarFactura(id_pedido)
        console.log(`Abrir PDF para factura con ID: ${id_factura}`);
    };

    useEffect(() => {
        getRepartos()
    }, [])

    useEffect(() => {
        if (repartos) {
            // console.log("Form visible, ejecutando getPedidos.");
            getPedidos();
            getPedidosRepartos()
        }
    }, [repartos]);

    useEffect(() => {
        if (pedidos) {
            // console.log("Form visible, ejecutando getFacturas.");
            getFacturas();
        }
    }, [pedidos]);

    const style = {
        backgroundColor: "#ffffff",
        width: "100%",
    };

    async function buscarFactura(reg) {
        console.log("req", reg);

        const id_pedido = reg;
        try {
            const response = await fetch(`${UrlApi}/comprobantes/pedido/${id_pedido}`);
            if (!response.ok) {
                throw new Error("Error en la solicitud: " + response.statusText);
            }
            const factura = await response.json();
            console.log("URK", `${UrlApi}/comprobantes/pedido/${id_pedido}`);
            if (factura.length === 1) {
                verEnviarPDF(factura[0].id_factura, 0);
            } else {
                alert(
                    "Existe mas de un comprobante para este pedido. Contacte a sistemas."
                );
            }

        } catch (error) {
            console.error("Error al obtener la factura:", error);
            alert(error);
        }
    };

    const handleAccordionChange = (idreparto, nro_reparto) => {
        setExpandedRepartos((prevState) => ({
            ...prevState,
            [idreparto]: !prevState[idreparto], // Cambiar el estado de expansión
        }));

        calcularKgsPorReparto(nro_reparto);
    };

    const calcularKgsPorReparto = (nroReparto) => {
        let totalKgsEmpresa1 = 0; // Total de kgs entregados por la empresa 1
        let totalKgsEmpresa4 = 0; // Total de kgs entregados por la empresa 4

        // Filtrar pedidos por nro_reparto
        const pedidosFiltrados = pedidosRepartos.filter(pedido => pedido.nro_reparto === nroReparto);

        // Iterar sobre los pedidos filtrados
        pedidosFiltrados.forEach(pedido => {
            const { id_empresa, kgentregados = 0, ltsentregados = 0, densidad_hipergas, densidad_autogas } = pedido;

            // Calcular los kgs a partir de los litros entregados y densidad
            const kgsDesdeLitros = id_empresa === 1
                ? ltsentregados * (densidad_hipergas || 1) // Evitar división por 0 o null
                : ltsentregados * (densidad_autogas || 1);

            // Sumar kgs por empresa
            if (id_empresa === 1) {
                totalKgsEmpresa1 += kgentregados + kgsDesdeLitros;
            } else if (id_empresa === 4) {
                totalKgsEmpresa4 += kgentregados + kgsDesdeLitros;
            }
        });

        // Actualizar estados
        setKgsEmpresa1(totalKgsEmpresa1);
        setKgsEmpresa4(totalKgsEmpresa4);

        // Opcional: Imprimir resultados para verificar
        // console.log(`Total kgs entregados por empresa 1: ${totalKgsEmpresa1}`);
        // console.log(`Total kgs entregados por empresa 4: ${totalKgsEmpresa4}`);
    };

    // console.log("repartos", repartos)
    // console.log("sucursal", sucursalSelected);    
    // console.log("repartos", repartos);
    // console.log("pedidos", pedidos)
    // console.log("facturas,", facturas);

    const noHayNadaParaMostrar = <>
        <Card>
            <CardHeader
                avatar={<Avatar sx={{ backgroundColor: "red" }}>!</Avatar>}
                title="No hay repartos finalizados."
            />

        </Card>
    </>

    console.log("pedidosRepartos", pedidosRepartos);
    

    return (
        <div>

            <Box sx={{ width: '100%', margin: 0, padding: 0 }}>
                {/* Mostrar resultados de repartos */}
                <List>
                    {repartos.length > 0 ? repartos.map((rendicion) => (
                        // console.log("rendicion", rendicion),
                        <Accordion
                            key={rendicion.idreparto}
                            sx={{ mb: 0.5, borderRadius: 2, boxShadow: 2, width: "100%" }}
                            expanded={expandedRepartos[rendicion.idreparto]} // Determinar si el acordeón está expandido
                            onChange={() => handleAccordionChange(rendicion.idreparto, rendicion.nro_reparto)} // Cambiar el estado al hacer clic
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ bgcolor: "#f5f5f5" }}>
                                <Box display="flex" flexDirection="column">
                                    <Typography variant="button" fontWeight="bold">
                                        PLANILLA DE REPARTO N° {rendicion.idreparto} {`(${rendicion.sucursal_atiende})`}
                                    </Typography>
                                    <Typography variant="button" fontWeight="bold">
                                        CHOFER: {`${rendicion.nombrepila} ${rendicion.apellido}`} VEHICULO: {`(${rendicion.patente})`}
                                    </Typography>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails sx={{ padding: 2 }}>
                                <Divider sx={{ borderColor: "#ccc" }} />

                                {/* Información General */}
                                <Grid container direction="column" spacing={2} sx={{ mb: 0.5 }}>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1" fontWeight="bold">
                                            Información General:
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Fecha: {moment(rendicion.fecha_salida_reparto).format("DD-MM-YYYY")}<br />
                                            Km inicial: {rendicion.km_iniciales} - Km final: {rendicion.km_finales}<br />
                                            Diferencia: {rendicion.km_finales - rendicion.km_iniciales} km<br />
                                            Chofer: {rendicion.nombrepila + " " + rendicion.apellido}<br />
                                            Peso inicial: {rendicion.peso_bruto_inicial} - Peso final: {rendicion.peso_bruto_final}<br />
                                            Diferencia de kilos: {rendicion.peso_bruto_inicial - rendicion.peso_bruto_final}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider sx={{ my: 2, borderColor: "#ddd" }} />

                                {/* Detalle de Comprobante de Venta */}
                                {(() => {
                                    // Filtrar los pedidos por el número de reparto
                                    const pedidosDelReparto = pedidos.filter((pedido) => pedido.nro_reparto === rendicion.nro_reparto);

                                    if (pedidosDelReparto.length === 0) {
                                        return (
                                            <Typography variant="button" color="textSecondary" sx={{ mt: 2 }}>
                                                No hay pedidos en este reparto.
                                            </Typography>
                                        );
                                    }

                                    // Calcular sumatorias para los importes
                                    const importeEfectivo = pedidosDelReparto.reduce((acc, pedido) => acc + (pedido.importe_efectivo || 0), 0);
                                    const importeCheque = pedidosDelReparto.reduce((acc, pedido) => acc + (pedido.importe_cheque || 0), 0);
                                    const importeTransferencia = pedidosDelReparto.reduce((acc, pedido) => acc + (pedido.importe_transferencia || 0), 0);

                                    const importeCtaCte = pedidosDelReparto
                                        .filter((pedido) => !pedido.importe_efectivo && !pedido.importe_cheque && !pedido.importe_transferencia)
                                        .reduce((acc, pedido) => {
                                            const facturasPedido = facturas?.filter((factura) => factura.id_pedido === pedido.id) || [];
                                            const totalFacturasPedido = facturasPedido.reduce((sum, factura) => sum + (factura.importe_total || 0), 0);
                                            return acc + totalFacturasPedido;
                                        }, 0);

                                    const totalVenta = importeEfectivo + importeCheque + importeTransferencia + importeCtaCte;

                                    return (
                                        <>
                                            <Typography variant="button" fontWeight="bold" sx={{ mt: 2 }}>
                                                DETALLE DE COMPROBANTE DE VENTA:
                                            </Typography>
                                            <TableContainer component={Paper} sx={{ mt: 2, mb: 3, borderRadius: 1, boxShadow: 1 }}>
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow sx={{ bgcolor: "#e0e0e0" }}>
                                                            {/* Condición para mostrar el encabezado correcto */}
                                                            {facturas?.length > 0 ? (
                                                                <>
                                                                    <TableCell>Comprobante</TableCell>
                                                                    <TableCell>Razón Social</TableCell>
                                                                    <TableCell align="right">Importe Total</TableCell>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <TableCell sx={{ bgcolor: "#FFCDD2" }}>Pedido</TableCell>
                                                                    <TableCell sx={{ bgcolor: "#FFCDD2" }}>Razón Social</TableCell>
                                                                    <TableCell sx={{ bgcolor: "#FFCDD2" }} align="right">Estado</TableCell>
                                                                </>
                                                            )}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {pedidosDelReparto.map((pedido) => {
                                                            // Filtrar facturas correspondientes al pedido
                                                            const facturasDelPedido = facturas?.filter((factura) => factura.id_pedido === pedido.id) || [];
                                                            // console.log("pedido", pedido.id_estado);

                                                            if (pedido.id_estado === 25) {
                                                                // Si el id_estado es 25, mostrar la tabla de facturas
                                                                return facturasDelPedido.map((factura) => (
                                                                    <TableRow key={factura.id_factura}>
                                                                        <TableCell>{`(${factura.id_factura}) ${factura.nombre_comprobante}`}
                                                                            <IconButton onClick={() => handleViewPDF(factura.id_factura, factura.id_pedido)}>
                                                                                <PictureAsPdfIcon color="secondary" />
                                                                            </IconButton>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {factura.nro_sisven
                                                                                ? `(${factura.nro_sisven}) ${factura.razon_social}`
                                                                                : factura.razon_social}
                                                                        </TableCell>
                                                                        <TableCell align="right">${factura.importe_total}</TableCell>
                                                                    </TableRow>
                                                                ));
                                                            } else {
                                                                // Si el id_estado no es 25, mostrar nombre del cliente y el estado del pedido
                                                                return (
                                                                    <TableRow key={pedido.id} sx={{ bgcolor: "#FFCDD2" }}> {/* Color de fondo rojizo */}
                                                                        <TableCell>{`Pedido: ${pedido.id}`}</TableCell>
                                                                        <TableCell>{pedido.nro_sisven
                                                                            ? `(${pedido.nro_sisven}) ${pedido.razon_social}`
                                                                            : pedido.razon_social}</TableCell>
                                                                        <TableCell align="right">{pedido.valor}</TableCell> {/* Estado del pedido también aquí */}
                                                                    </TableRow>
                                                                );
                                                            }
                                                        })}

                                                        {/* Solo mostrar las filas de venta si existen facturas */}
                                                        {facturas?.length > 0 && (
                                                            <>
                                                                <TableRow>
                                                                    <TableCell align="right" colSpan={2}>
                                                                        Venta en cta cte
                                                                    </TableCell>
                                                                    <TableCell align="right">${importeCtaCte.toFixed(2)}</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell align="right" colSpan={2}>
                                                                        Efectivo
                                                                    </TableCell>
                                                                    <TableCell align="right">${importeEfectivo}</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell align="right" colSpan={2}>
                                                                        Cheque
                                                                    </TableCell>
                                                                    <TableCell align="right">${importeCheque}</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell align="right" colSpan={2}>
                                                                        Transferencia
                                                                    </TableCell>
                                                                    <TableCell align="right">${importeTransferencia}</TableCell>
                                                                </TableRow>
                                                                <TableRow sx={{ bgcolor: "#f5f5f5" }}>
                                                                    <TableCell align="right" colSpan={2} fontWeight="bold">
                                                                        Total Venta
                                                                    </TableCell>
                                                                    <TableCell align="right">${totalVenta.toFixed(2)}</TableCell>
                                                                </TableRow>
                                                            </>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </>
                                    );
                                })()}

                                {/* Detalles de Remitos */}
                                <Divider sx={{ my: 2, borderColor: "#ddd" }} />
                                <Typography variant="button" fontWeight="bold" sx={{ mt: 2 }}>DETALLES DE REMITOS DE VENTA/COMODATO:</Typography>
                                <TableContainer component={Paper} sx={{ mt: 2, mb: 3, borderRadius: 1, boxShadow: 1, width: "100%" }}>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow sx={{ bgcolor: "#e0e0e0" }}>
                                                <TableCell>Pedido</TableCell>
                                                <TableCell>Nro. Remito</TableCell>
                                                <TableCell>Razón Social</TableCell>
                                                <TableCell>Producto</TableCell>
                                                <TableCell>Sucursal</TableCell>
                                                <TableCell>Empresa</TableCell>
                                                <TableCell>Kgs entregados</TableCell>
                                                <TableCell>Lts entregados</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {pedidosRepartos
                                                .filter(
                                                    (pedido) =>
                                                        pedido.nro_reparto === rendicion.nro_reparto &&
                                                        pedido.nro_remito !== null // Filtra donde nro_remito no sea null
                                                )
                                                .map((pedido) => (
                                                    <TableRow key={pedido.id}>
                                                        <TableCell>{pedido.id}</TableCell>
                                                        <TableCell>{pedido.nro_remito}</TableCell>
                                                        <TableCell>{pedido.sisven_id ? `(${pedido.sisven_id}) ${pedido.razon_social}` : `${pedido.razon_social}`}</TableCell>
                                                        <TableCell>{pedido.nombre_producto}</TableCell>
                                                        <TableCell>{pedido.nombre_sucursal}</TableCell>
                                                        <TableCell>{pedido.empresa}</TableCell>
                                                        <TableCell>{pedido.kgentregados ?? "-"}</TableCell>
                                                        <TableCell>{pedido.ltsentregados ?? "-"}</TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                        {/* Calcular sumatorias */}
                                        {(() => {
                                            const totalKgs = pedidos
                                                .filter((pedido) =>
                                                    pedido.nro_reparto === rendicion.nro_reparto &&
                                                    pedido.nro_remito !== null // Asegúrate de considerar solo los pedidos válidos
                                                )
                                                .reduce((acc, pedido) => acc + (pedido.kgentregados || 0), 0);

                                            const totalLts = pedidos
                                                .filter((pedido) =>
                                                    pedido.nro_reparto === rendicion.nro_reparto &&
                                                    pedido.nro_remito !== null // Asegúrate de considerar solo los pedidos válidos
                                                )
                                                .reduce((acc, pedido) => acc + (pedido.ltsentregados || 0), 0);

                                            return (
                                                <>
                                                    <TableRow sx={{ bgcolor: "#f5f5f5" }}>
                                                        <TableCell align="right" colSpan={4} fontWeight="bold">
                                                            <strong>Total KILOS</strong>
                                                        </TableCell>
                                                        <TableCell align="right" fontWeight="bold">
                                                            {totalKgs}
                                                        </TableCell>
                                                        <TableCell align="right" colSpan={1} fontWeight="bold">
                                                            <strong>Total LITROS</strong>
                                                        </TableCell>
                                                        <TableCell align="right" fontWeight="bold">
                                                            {totalLts}
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            );
                                        })()}
                                    </Table>


                                </TableContainer>
                                <TableRow sx={{ bgcolor: "#f5f5f5" }}>
                                    <TableCell align="right" colSpan={4} fontWeight="bold">
                                        Total KILOS HIPERGAS <strong>{kgsEmpresa1 !== 0 ? kgsEmpresa1.toFixed(2) : 0}</strong>
                                    </TableCell>
                                    <TableCell align="right" colSpan={4} fontWeight="bold">
                                        Total KILOS AUTOGAS <strong>  {kgsEmpresa4 !== 0 ? kgsEmpresa4.toFixed(2) : 0}</strong>
                                    </TableCell>

                                    <TableCell align="right" colSpan={4} style={{ fontWeight: 'bold' }}>
                                        DIFERENCIA VENTA/PESADA:
                                        <strong>
                                            {((kgsEmpresa4 + kgsEmpresa1) - (rendicion.peso_bruto_inicial - rendicion.peso_bruto_final)).toFixed(2)}
                                        </strong>
                                    </TableCell>
                                </TableRow>
                            </AccordionDetails>
                        </Accordion>
                    )) :
                        <Grid item xs={12} sm={12} md={12}>
                            <>
                                {noHayNadaParaMostrar}
                            </>
                        </Grid>
                    }
                </List>
            </Box>
        </div>
    )
}