import Navbar from "../Navbar";
import React, { useEffect, useState, useMemo } from "react";
import { UrlApi } from "../../services/apirest";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import { Button, Container, Box, Typography } from "@mui/material";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";

// EXPORTAR DATOS
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv";

function Romaneo_envases_destruidos() {
  const [tabla, setTabla] = useState(true);
  const [romaneo_envases, setRomaneo_envases] = useState();
  const [romaneo_envasesCSV, setRomaneo_envasesCSV] = useState();

  //funcion para obtener los datos, pasarlos a json y guardarlos en data
  const obtenerRomaneoEnvases = () => {
    fetch(UrlApi + "/romaneo_envases/destruidos")
      .then((data) => data.json())
      .then((data) => setRomaneo_envases(data));
  };

  const obtenerRomaneoEnvasesCSV = () => {
    fetch(UrlApi + "/romaneo_envases/destruidos/csv")
      .then((data) => data.json())
      .then((data) => setRomaneo_envasesCSV(data));
  };

  // ---------------------------------------------------------------

  //funcion que espera los cambios de la funcion de obtener a los romaneo leyendas
  useEffect(() => {
    obtenerRomaneoEnvases();
    obtenerRomaneoEnvasesCSV();
  }, []);

  //*************************************************************************************************************************************/

  const columns = useMemo(
    () => [
      { header: "N°.Envase", accessorKey: "nro_envase", size: 100 },
      { header: "Mat-Fab", accessorKey: "descripcion_fabricante", size: 100 },
      {
        header: "Motivo de Baja",
        accessorKey: "descripcion_motivo",
        size: 100,
      },
    ],
    []
  );

  // COLUMNAS CSV
  const columnsCsv = useMemo(
    () => [
      { header: "N°. Envase", accessorKey: "nro_envase", size: 80 },
      { header: "Mat-Fab", accessorKey: "descripcion_fabricante", size: 75 },
      { header: "Motivo", accessorKey: "descripcion_motivo", size: 150 },
    ],
    []
  );

  const muiSearchTextFieldProps = {
    // para el buscar
    placeholder: "Buscar...",
    sx: { minWidth: "18rem" },
    variant: "outlined",
    color: "success",
    size: "small",
  };

  // ---------------------------------------------------------------
  //  EXPORTAR DATOS
  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columnsCsv.map((c) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(romaneo_envasesCSV);
  };

  //*************************************************************************************************************************************/

  // ESTILO PARA LA TABLA

  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)" //background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //override to make tooltip font size larger
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const displayColumnDefOptions = {
    "mrt-row-actions": {
      muiTableHeadCellProps: {
        align: "center",
        sx: {
          fontSize: 14,
          fontStyle: "italic",
        },
      },
      size: 100,
    },
  };

  const initialState = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
  };

  return (
    <div>
      <Navbar />
      <Container maxWidth="xl" mt={1}>
        {romaneo_envases && tabla ? (
          <Box mt={2} mb={4}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
                enableGrouping // para permitir agrupar x estado x ej
                displayColumnDefOptions={displayColumnDefOptions}
                layoutMode="grid"
                muiTableHeadCellProps={{
                  align: "left", // los titulos de la tabla
                }}
                muiTableBodyCellProps={{
                  align: "left", //el contenido de la tabla
                }}
                enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
                enableColumnFilters={false} //desactiva filtros x  columna
                enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
                enableDensityToggle={false} //
                localization={MRT_Localization_ES} //idioma
                enableColumnDragging={false} //para arrastrar columnas
                initialState={initialState}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                columns={columns}
                data={romaneo_envases} //con lo q la completas (esto cambia siempre)
                enableStickyFooter
                positionActionsColumn="last" // posicion de la columna de acciones al final
                renderTopToolbarCustomActions={() => (
                  <>
                    <Typography
                      variant="poster"
                      fontSize={26}
                      color={"#ec2617"}
                    >
                      <b>Envases Destruidos</b>
                    </Typography>
                    <Button
                      color="primary"
                      //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                      onClick={handleExportData}
                      startIcon={<FileDownloadIcon />}
                      variant="contained"
                      size="small"
                    >
                      Exportar a CSV
                    </Button>
                  </>
                )}
              />
            </ThemeProvider>
          </Box>
        ) : null}
      </Container>
    </div>
  );
}

export default Romaneo_envases_destruidos;
