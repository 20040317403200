import Navbar from "../Navbar";
import React, { useEffect, useState, useMemo } from "react";
import { UrlApi } from "../../services/apirest";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import {
  Container,
  DialogTitle,
  Box,
  Typography,
  Grid,
  TextField,
  InputAdornment,
} from "@mui/material";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";

// ICONOS
import ArticleIcon from "@mui/icons-material/Article";
import BuildIcon from "@mui/icons-material/Build";
import DeleteIcon from "@mui/icons-material/Delete";
import BadgeIcon from "@mui/icons-material/Badge";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import NumbersIcon from "@mui/icons-material/Numbers";
import PropaneTankIcon from "@mui/icons-material/PropaneTank";
import PinIcon from "@mui/icons-material/Pin";
import RateReviewIcon from "@mui/icons-material/RateReview";

function Romaneo_envases_lista() {
  const [romaneo_envases, setRomaneo_envases] = useState();

  const [tabla, setTabla] = useState(true);

  const obtenerRomaneoEnvases = () => {
    fetch(UrlApi + "/romaneo_envases/lista")
      .then((data) => data.json())
      .then((data) => setRomaneo_envases(data));
  };

  useEffect(() => {
    obtenerRomaneoEnvases();
  }, [tabla]);

  //*************************************************************************************************************************************/
  // COLUMNAS TABLA
  const columns = useMemo(
    () => [
      { header: "N°.Envase", accessorKey: "nro_envase", size: 85 },
      { header: "N°.Acuñado", accessorKey: "nro_acuniado", size: 90 },
      {
        header: "Fe.Reparación",
        accessorKey: "fecha_reparacion",
        size: 90,
        type: "date",
        format: "DD-MM-YYYY",
      },
      {
        header: "Reparación",
        accessorKey: "descripcion_reparacion",
        size: 90,
      },
      {
        header: "Tipo de Envase",
        accessorKey: "descripcion_tipo_envase",
        size: 150,
      },
      {
        header: "Fabricante",
        accessorKey: "descripcion_fabricante",
        size: 100,
      },
      { header: "Leyenda", accessorKey: "leyenda", size: 90 },
      { header: "Firma", accessorKey: "firma", size: 90 },
    ],
    []
  );

  const muiSearchTextFieldProps = {
    // para el buscar
    placeholder: "Buscar...",
    sx: { minWidth: "18rem" },
    variant: "outlined",
    color: "success",
    size: "small",
  };

  //*************************************************************************************************************************************/

  // ESTILO PARA LA TABLA

  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)" //background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //override to make tooltip font size larger
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const displayColumnDefOptions = {
    "mrt-row-actions": {
      muiTableHeadCellProps: {
        align: "center",
        sx: {
          fontSize: 14,
          fontStyle: "italic",
        },
      },
      size: 100,
    },
  };

  const initialState = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    sorting: [
      {
        id: "fecha_reparacion", //fija que la fecha de reparacion sea desde hoy para atras
        desc: true,
      },
    ],
    pagination: { pageIndex: 0, pageSize: 1000 },
  };

  // RETURN
  return (
    <>
      <Navbar />
      <Container maxWidth="xl" mt={1}>
        {romaneo_envases && tabla ? (
          <Box mt={2} mb={4}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
                enableColumnVirtualization
                displayColumnDefOptions={displayColumnDefOptions}
                layoutMode="grid"
                muiTableHeadCellProps={{
                  align: "left", // los titulos de la tabla
                }}
                muiTableBodyCellProps={{
                  align: "left", //el contenido de la tabla
                }}
                enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
                enableColumnFilters={false} //desactiva filtros x  columna
                enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
                enableDensityToggle={false} //
                localization={MRT_Localization_ES} //idioma
                enableColumnDragging={false} //para arrastrar columnas
                initialState={initialState}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                columns={columns}
                data={romaneo_envases} //con lo q la completas (esto cambia siempre)
                enableStickyFooter
                positionActionsColumn="last" // posicion de la columna de acciones al final
                // ACCIONES
                enableRowVirtualization
                renderToolbarInternalActions={(
                  { table } // boton para nuevo , al lado del buscar
                ) => (
                  <>
                    <MRT_FullScreenToggleButton table={table} />
                  </>
                )}
                renderTopToolbarCustomActions={() => (
                  <>
                    <Typography variant="poster" fontSize={26}>
                      Lista de Envases y Cilindros
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        gap: "1rem",
                        p: "0.5rem",
                        flexWrap: "wrap",
                      }}
                    ></Box>
                  </>
                )}
                //es el panel de expansion del costado
                // PANEL DE DETALLES / FICHA
                renderDetailPanel={({ row }) => (
                  <>
                    <Container maxWidth="xl">
                      <br />
                      <DialogTitle
                        style={{
                          color: "white",
                          backgroundColor: "#4abced",
                          height: "10px",
                          marginTop: "-30px",
                          width: "143%",
                        }}
                      >
                        <Grid mt={-1.5}>
                          ID DEL ENVASE: {row.original.id_envase}
                        </Grid>
                      </DialogTitle>
                      <br />
                      <Box display="flex" width="150%">
                        <Box border={2} px={2} pt={2} borderRadius={4} mt={-2}>
                          <Grid container maxWidth="x1" spacing={1} ml={4.5}>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="No Tiene"
                                label="Reparación"
                                name="descripcion_reparacion"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.descripcion_reparacion}
                                InputProps={{
                                  readOnly: true,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <BuildIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid item xs={3} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="No Tiene"
                                label="Motivo de Baja"
                                name="id_mot_baja"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.descripcion_motivo}
                                InputProps={{
                                  readOnly: true,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <DeleteIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid item xs={4} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="No Tiene"
                                label="Tipo de Envase"
                                name="descripcion_tipo_envase"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.descripcion_tipo_envase}
                                InputProps={{
                                  readOnly: true,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <PropaneTankIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid item xs={1.3} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="No Tiene"
                                label="Peso"
                                name="peso"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.peso}
                                InputProps={{
                                  readOnly: true,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      Kg
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>

                            <Grid item xs={2} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="No Tiene"
                                label="Nro. Envase"
                                name="nro_envase"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.nro_envase}
                                InputProps={{
                                  readOnly: true,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <NumbersIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid item xs={2} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="No Tiene"
                                label="Nro. Acuñado"
                                name="nro_acuniado"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.nro_acuniado}
                                InputProps={{
                                  readOnly: true,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <PinIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid item xs={2} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="No Tiene"
                                label="Matrícula del Fabricante"
                                name="id_mat_fabricante"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.mat_fabricante}
                                InputProps={{
                                  readOnly: true,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <BadgeIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid item xs={1} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="No Tiene"
                                label="Lote"
                                name="lote"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.lote}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="No Tiene"
                                label="Fecha de Fabricacion"
                                name="fecha_fabricacion"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.fecha_fabricacion}
                                InputProps={{
                                  readOnly: true,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <CalendarMonthIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>

                            <Grid item xs={2} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="No Tiene"
                                label="Fecha de Reparación"
                                name="fecha_reparacion"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.fecha_reparacion}
                                InputProps={{
                                  readOnly: true,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <CalendarMonthIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.5} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="No Tiene"
                                label="Firma"
                                name="firma"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.firma}
                                InputProps={{
                                  readOnly: true,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <RateReviewIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid item xs={3} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="No Tiene"
                                label="Leyenda / Leyenda Chapa Circular"
                                name="leyenda"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.leyenda}
                                InputProps={{
                                  readOnly: true,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <ArticleIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid item xs={3} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="No Tiene"
                                label="Leyenda Chapa Rectangular"
                                name="ley_placa_rect"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.ley_placa_rect}
                                InputProps={{
                                  readOnly: true,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <ArticleIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.5} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="No Tiene"
                                label="Número Chapa Rectangular"
                                name="nro_placa_rect"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.nro_placa_rect}
                                InputProps={{
                                  readOnly: true,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <ArticleIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.5} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="No hay placa"
                                label="Número de Placa / de Chapa Circular"
                                name="nro_placa"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.nro_placa}
                                InputProps={{
                                  readOnly: true,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <PinIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </Container>
                  </>
                )}
              />
            </ThemeProvider>
          </Box>
        ) : null}
      </Container>
    </>
  );
}

export default Romaneo_envases_lista;
