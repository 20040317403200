import Navbar from "../Navbar";
import React, { useEffect, useState } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Button,
  Container,
  Dialog,
  Stack,
  Grid,
} from "@mui/material";
import Slide from "@mui/material/Slide";

// COMPONENTES
import Romaneo_M_envases_carga_30 from "./Romaneo_M_envases_carga_30";
import Romaneo_M_envases_carga_45 from "./Romaneo_M_envases_carga_45";
import Romaneo_M_envases_destruido from "./Romaneo_M_envases_destruido";

//Notification
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

// ICONOS
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Romaneo_M_envases_carga() {
  const [datosRomaneoEnvases, setDatosRomaneoEnvases] = useState({
    id_tipo_reparacion: null,
    id_tipo: null,
    nro_envase: null,
    id_mat_fabricante: null,
    lote: "",
    fecha_fabricacion: "",
    placa_relieve: 2,
    nro_placa: null,
    nro_placa_rect: null,
    ley_placa_rect: null,
    nro_acuniado: null,
    id_mot_baja: null,
    fecha_reparacion: "",
    id_pertenencia: null,
    id_pertenencia2: null,
    id_firma: null,
    id_firma2: null,
    rebalanceo: null,
    id_estado: null,
    id_envase: null,
    usuario: localStorage.getItem("user"),
  });

  console.log("DATOS NO SE QUE ", datosRomaneoEnvases);
  const [matriculaSeleccionada, setMatriculaSeleccionada] = useState({});
  const [disBtn, setDisBtn] = useState(false);
  const [tipoEnvaseSeleccionado, setTipoEnvaseSeleccionado] = useState({});
  const [firmaSeleccionado, setFirmaSeleccionado] = useState({});
  const [leyendaSeleccionado, setLeyendaSeleccionado] = useState({});

  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");

  // -------------------------- SWITCHS -------------------------------------
  // AGREGAR DESPUES
  const [valorAcuniado, setValorAcuniado] = useState();

  const obtenerValorAcuniado = () => {
    fetch(UrlApi + "/romaneo_envases/valoracuniado")
      .then((data) => data.json())
      .then((data) => setValorAcuniado(data));
  };

  console.log("valor acuñado maximo:", valorAcuniado);
  // -----------------------------------------------------------------
  // REBALANCEO
  const [checkSwitchRebalanceo, setCheckSwitchRebalanceo] = useState(
    datosRomaneoEnvases.rebalanceo === 1 ? true : false
  );

  const handleOnChangeSwitchRebalanceo = (e) => {
    setCheckSwitchRebalanceo(e.target.checked);
    obtenerValorAcuniado();
  };

  console.log("checkSwitchRebalanceo", checkSwitchRebalanceo);

  // PLACA
  const [checkSwitchPlaca, setCheckSwitchPlaca] = useState(
    datosRomaneoEnvases.placa_relieve === 2 ? true : false
  );

  const handleOnChangeSwitchPlaca = (e) => {
    setCheckSwitchPlaca(e.target.checked);
  };

  console.log("checkSwitchPlaca", checkSwitchPlaca);

  // ACUÑADO
  const [checkSwitchAcuniado, setCheckSwitchAcuniado] = useState(
    datosRomaneoEnvases.acuniado === 1 ? true : false
  );

  const handleOnChangeSwitchAcuniado = (e) => {
    setCheckSwitchAcuniado(e.target.checked);
  };

  useEffect(() => {
    datosRomaneoEnvases.nro_envase = null;
  }, [checkSwitchAcuniado]);

  useEffect(() => {
    datosRomaneoEnvases.nro_acuniado = null;
  }, [!checkSwitchAcuniado]);

  console.log("checkSwitchAcuniado", checkSwitchAcuniado);

  // CASQUETE
  const [checkSwitchCasquete, setCheckSwitchCasquete] = useState(
    datosRomaneoEnvases.cambio_casquete === 1 ? true : false
  );

  const handleOnChangeSwitchCasquete = (e) => {
    setCheckSwitchCasquete(e.target.checked);
  };

  console.log("checkSwitchCasquete", checkSwitchCasquete);

  // ---------------------------------------------------------------

  const [firmas, setFirmas] = useState();

  //funcion para obtener a los romaneo leyendas, pasarlos a json y guardarlos en data
  const obtenerFirmas = () => {
    fetch(UrlApi + "/romaneo_pertenencias/agrupados")
      .then((data) => data.json())
      .then((data) => setFirmas(data));
  };

  console.log("trae las firmas", firmas);

  //funcion que espera los cambios de la funcion de obtener a los romaneo leyendas
  useEffect(() => {
    obtenerFirmas();
  }, []);

  //*************************************************************************************************************************************/
  //funcion para obtener a los romaneo descripcion_tipo_envase, pasarlos a json y guardarlos en data

  const [romaneo_tipo_envase, setRomaneo_Tipo_Envase] = useState();

  const obtenerDescripcionTipoEnvase = () => {
    fetch(UrlApi + "/romaneo_tipos_envases/menos45")
      .then((data) => data.json())
      .then((data) => setRomaneo_Tipo_Envase(data));
  };
  console.log("trae la descripcion de tipo envase", romaneo_tipo_envase);

  //funcion que espera los cambios de la funcion de obtener a los romaneo descripcion_tipo_envase
  useEffect(() => {
    obtenerDescripcionTipoEnvase();
  }, []);

  //*************************************************************************************************************************************/
  //funcion para obtener a los romaneo descripcion_tipo_envase, pasarlos a json y guardarlos en data

  const [romaneo_tipo_envase45, setRomaneo_Tipo_Envase45] = useState();

  const obtenerDescripcionTipoEnvase45 = () => {
    fetch(UrlApi + "/romaneo_tipos_envases/45")
      .then((data) => data.json())
      .then((data) => setRomaneo_Tipo_Envase45(data));
  };
  console.log("trae la descripcion de tipo envase", romaneo_tipo_envase45);

  //funcion que espera los cambios de la funcion de obtener a los romaneo descripcion_tipo_envase
  useEffect(() => {
    obtenerDescripcionTipoEnvase45();
  }, []);

  //*************************************************************************************************************************************/
  //funcion para obtener a los romaneo leyendas, pasarlos a json y guardarlos en data

  const [mat_fabricante, setMat_fabricante] = useState();

  const obtenerMatFabricante = () => {
    fetch(UrlApi + "/romaneo_fabricantes")
      .then((data) => data.json())
      .then((data) => setMat_fabricante(data));
  };
  // console.log("trae matricula de fabricante", mat_fabricante);

  //funcion que espera los cambios de la funcion de obtener a los romaneo leyendas
  useEffect(() => {
    obtenerMatFabricante();
  }, []);

  //*************************************************************************************************************************************/
  const [envaseSeleccionado, setEnvaseSeleccionado] = useState({});

  const [envaseYpeso, setEnvaseyPeso] = useState(null);

  const obtenerEnvaseYpeso = async () => {
    const data = await fetch(
      UrlApi + "/romaneo_tipos_envases/envaseXID/" + datosRomaneoEnvases.id_tipo
    );
    const EyPData = await data.json();
    setEnvaseyPeso(EyPData);
  };
  console.log("envase y peso dentro del obtener 1", envaseYpeso);

  //obtenemos los pesos y envases UNA VEZ que elegimos el envase seleccionado
  useEffect(() => {
    envaseSeleccionado && obtenerEnvaseYpeso();
  }, [envaseSeleccionado]);

  //*************************************************************************************************************************************/
  const [estadoEnvase, setEstadoEnvase] = useState(null);

  //obtener los tipos de moneda de la tabla desplegables
  const obtenerEstadoEnvase = () => {
    fetch(
      UrlApi + "/desplegables/estadoEnvases/" + datosRomaneoEnvases.id_estado
    )
      .then((data) => data.json())
      .then((data) => setEstadoEnvase(data));
  };
  useEffect(() => {
    obtenerEstadoEnvase();
  }, []);
  console.log("estado de envase", estadoEnvase);

  //*************************************************************************************************************************************/
  const [leyendaXid, setLeyendaXid] = useState(null);

  //obtener los tipos de moneda de la tabla desplegables
  const obtenerLeyendaXID = () => {
    fetch(
      UrlApi +
        "/romaneo_pertenencias/traeLeyendaxID/" +
        datosRomaneoEnvases.id_firma
    )
      .then((data) => data.json())
      .then((data) => setLeyendaXid(data));
  };
  useEffect(() => {
    obtenerLeyendaXID();
  }, [datosRomaneoEnvases.id_firma]);

  console.log("Leyenda x id", leyendaXid);

  //*************************************************************************************************************************************/

  // OBTENER FECHA ACTUAL
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);

  //*************************************************************************************************************************************/

  // REPETICION: PARA VERIFICAR SI AL CARGAR SE ESTÁ REPITIENDO UN DATO

  const [repeticion, setRepeticion] = useState(null); //Guardo el que esté repetido (en caso de que exista)
  //Funcion con la que consulto a la api si ya existe un envase con X firma, X  y X nro de envase, para no cargar repetidos

  const obtenerRepeticion = () => {
    fetch(
      UrlApi +
        "/romaneo_envases/envases_repetidos/" +
        datosRomaneoEnvases.id_tipo +
        "/" +
        datosRomaneoEnvases.id_firma +
        "/" +
        datosRomaneoEnvases.id_pertenencia +
        "/" +
        datosRomaneoEnvases.nro_envase
    )
      .then((data) => data.json())
      .then((data) => setRepeticion(data));
  };

  // Ejecuto la funcion escuchando al proveedor seleccionado , al tipo de comprobante y al numero de comprobante
  // para que haga la busqueda cada vez que elija un proveedor (o lo cambie), y tipee un numero de comprobante

  useEffect(() => {
    firmas && obtenerRepeticion();
  }, [
    datosRomaneoEnvases.id_tipo,
    datosRomaneoEnvases.id_firma,
    datosRomaneoEnvases && datosRomaneoEnvases.id_pertenencia,
    datosRomaneoEnvases.nro_envase,
  ]);

  // repeticion && console.log("repeticion", repeticion);
  const [modalRepetido, setModalRepetido] = useState();

  // escuchando a la repeticion, pregunto si lo que me traje de la api tiene longitud > 0
  // (o sea, si hay algo en repeticion)

  useEffect(() => {
    if (repeticion && repeticion.length > 0) {
      setModalRepetido(true);
      setDatosRomaneoEnvases({
        ...datosRomaneoEnvases,
        ["nro_envase"]: "",
      });
    }
  }, [repeticion, firmas]);

  //*************************************************************************************************************************************/

  // DIALOG PARA CREAR LOS DATOS
  const [openCrear, setOpenCrear] = React.useState(false);

  // USANDO ROW TOMO LOS DATOS DE ESA FILA
  const handleClickOpenCrear = (row) => {
    setOpenCrear(true);
    setCheckSwitchPlaca(false); // para apgar el switch cada vez que abre el dialog
    // AL ABRIR EL DIALOG LOS TEXTFIELDS SE VACIAN
    datosRomaneoEnvases.id_tipo_reparacion = null;
    datosRomaneoEnvases.id_tipo = null;
    datosRomaneoEnvases.leyenda = null;
    datosRomaneoEnvases.id_firma = null;
    datosRomaneoEnvases.id_firma2 = null;
    datosRomaneoEnvases.id_pertenencia = null;
    datosRomaneoEnvases.id_pertenencia2 = null;
    datosRomaneoEnvases.nro_envase = null;
    matriculaSeleccionada.id_mat_fabricante = null;
    datosRomaneoEnvases.lote = "001";
    datosRomaneoEnvases.fecha_fabricacion = "";
    datosRomaneoEnvases.placa_relieve = 1;
    datosRomaneoEnvases.nro_placa = null;
    datosRomaneoEnvases.nro_placa_rect = null;
    datosRomaneoEnvases.ley_placa_rect = null;
    datosRomaneoEnvases.nro_acuniado = null;
    datosRomaneoEnvases.id_mot_baja = null;
    datosRomaneoEnvases.fecha_reparacion = date;
    datosRomaneoEnvases.rebalanceo = null;
    datosRomaneoEnvases.id_estado = null;
  };

  const handleCloseCrear = () => {
    setOpenCrear(false);
  };

  //*************************************************************************************************************************************/

  // DIALOG PARA CREAR LOS DATOS
  const [openCrear45, setOpenCrear45] = React.useState(false);

  // USANDO ROW TOMO LOS DATOS DE ESA FILA
  const handleClickOpenCrear45 = (row) => {
    setOpenCrear45(true);
    setCheckSwitchPlaca(false); // para apgar el switch cada vez que abre el dialog
    setCheckSwitchRebalanceo(false);
    setCheckSwitchCasquete(false);

    // AL ABRIR EL DIALOG LOS TEXTFIELDS SE VACIAN
    datosRomaneoEnvases.id_tipo_reparacion = null;
    datosRomaneoEnvases.id_tipo = null;
    datosRomaneoEnvases.leyenda = null;
    datosRomaneoEnvases.id_firma = null;
    datosRomaneoEnvases.id_firma2 = null;
    datosRomaneoEnvases.id_pertenencia = null;
    datosRomaneoEnvases.id_pertenencia2 = null;
    datosRomaneoEnvases.nro_envase = null;
    matriculaSeleccionada.id_mat_fabricante = null;
    datosRomaneoEnvases.lote = "001";
    datosRomaneoEnvases.fecha_fabricacion = "";
    datosRomaneoEnvases.placa_relieve = 1;
    datosRomaneoEnvases.nro_placa = null;
    datosRomaneoEnvases.nro_placa_rect = null;
    datosRomaneoEnvases.ley_placa_rect = null;
    datosRomaneoEnvases.nro_acuniado = null;
    datosRomaneoEnvases.id_mot_baja = null;
    datosRomaneoEnvases.fecha_reparacion = date;
    datosRomaneoEnvases.rebalanceo = null;
    datosRomaneoEnvases.id_estado = null;
  };

  const handleCloseCrear45 = () => {
    setOpenCrear45(false);
    setCheckSwitchRebalanceo(false);
    datosRomaneoEnvases.rebalanceo = null;
    setCheckSwitchPlaca(false);
    datosRomaneoEnvases.placa_relieve = 1;
    setCheckSwitchAcuniado(false);
    setCheckSwitchCasquete(false);
  };

  //*************************************************************************************************************************************/

  // DIALOG PARA CREAR LOS DATOS
  const [openDestruir, setOpenDestruir] = React.useState(false);

  // USANDO ROW TOMO LOS DATOS DE ESA FILA
  const handleClickOpenDestruir = (row) => {
    setOpenDestruir(true);
    setCheckSwitchPlaca(false); // para apagar el switch cada vez que abre el dialog
    // AL ABRIR EL DIALOG LOS TEXTFIELDS SE VACIAN
    datosRomaneoEnvases.id_tipo_reparacion = null;
    datosRomaneoEnvases.id_tipo = null;
    datosRomaneoEnvases.leyenda = null;
    datosRomaneoEnvases.id_firma = null;
    datosRomaneoEnvases.id_pertenencia = null;
    datosRomaneoEnvases.nro_envase = null;
    matriculaSeleccionada.id_mat_fabricante = null;
    datosRomaneoEnvases.lote = "";
    datosRomaneoEnvases.fecha_fabricacion = "";
    datosRomaneoEnvases.placa_relieve = 1;
    datosRomaneoEnvases.nro_placa = null;
    datosRomaneoEnvases.id_mot_baja = null;
    datosRomaneoEnvases.fecha_reparacion = date;
    datosRomaneoEnvases.rebalanceo = null;
    datosRomaneoEnvases.id_estado = null;
  };

  const handleCloseDestruir = () => {
    setOpenDestruir(false);
  };

  //*************************************************************************************************************************************/

  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
    setLimpiarCrear30(false);
    setLimpiarCrear45(false);
    setLimpiarDestruir(false);
    setLimpiarMatricula(false);
    setDisBtn(false);
  };

  //*************************************************************************************************************************************/
  // ------------USE EFFECTS PARA LIMPIAR LOS DATOS AL GUARDAR------------

  // CREAR 30
  const [limpiarCrear30, setLimpiarCrear30] = useState(false);

  useEffect(() => {
    datosRomaneoEnvases.id_tipo = null;
    datosRomaneoEnvases.nro_envase = "";
  }, [limpiarCrear30]);

  // CREAR 45
  const [limpiarCrear45, setLimpiarCrear45] = useState(false);

  useEffect(() => {
    setCheckSwitchRebalanceo(false);
    setCheckSwitchPlaca(false);
    setCheckSwitchCasquete(false);
    obtenerDescripcionTipoEnvase45();
    datosRomaneoEnvases.rebalanceo = null;
    datosRomaneoEnvases.id_tipo = null;
    datosRomaneoEnvases.nro_envase = "";
  }, [limpiarCrear45]);

  // DESTRUIR
  const [limpiarDestruir, setLimpiarDestruir] = useState(false);

  useEffect(() => {
    datosRomaneoEnvases.nro_envase = "";
  }, [limpiarDestruir]);

  const [limpiarMatricula, setLimpiarMatricula] = useState(false);

  useEffect(() => {
    datosRomaneoEnvases.id_mat_fabricante = null;
  }, [limpiarMatricula]);

  // TIPO DE REPARACIÓN
  const [limpiarTipoReparacion, setLimpiarTipoReparacion] = useState(false);

  useEffect(() => {
    datosRomaneoEnvases.id_tipo_reparacion = null;
  }, [limpiarTipoReparacion]);

  // TIPO DE ENVASE
  const [limpiarTipoEnvase, setLimpiarTipoEnvase] = useState(false);

  useEffect(() => {
    datosRomaneoEnvases.id_tipo = null;
  }, [limpiarTipoEnvase]);

  // FIRMA
  const [limpiarFirma, setLimpiarFirma] = useState(false);

  useEffect(() => {
    datosRomaneoEnvases.id_firma = null;
  }, [limpiarFirma]);

  // ------------------------------------------------------------

  useEffect(() => {
    setCheckSwitchPlaca(true);
  }, [checkSwitchRebalanceo]);

  // ------------------------------------------------------------
  return (
    <>
      <Navbar />
      <Container maxWidth="x1" mt={1}>
        <Grid container mt={20}>
          <Grid item xs={12} textAlign={"center"}>
            <img src="/logoMetalgarMini.jpg" alt="Logo Metalgar" height={200}/>
            <hr />
            <h3>Administrar Envases</h3>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              startIcon={<AddBoxTwoToneIcon />}
              onClick={handleClickOpenCrear}
            >
              Cargar (10 a 30 Kg)
            </Button>
          </Grid>
          <Grid item xs={12} textAlign={"center"} mt={2}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              fullWidth
              startIcon={<AddBoxTwoToneIcon />}
              onClick={handleClickOpenCrear45}
            >
              Cargar (45 Kg)
            </Button>
          </Grid>
          <Grid item xs={12} textAlign={"center"} mt={2}>
            <Button
              variant="contained"
              color="error"
              size="large"
              fullWidth
              startIcon={<DeleteForeverIcon />}
              onClick={handleClickOpenDestruir}
            >
              Destruir
            </Button>
          </Grid>
        </Grid>
      </Container>

      {/* AGREGAR ITEM 10 a 30 kg */}
      <Dialog
        fullScreen
        open={openCrear}
        onClose={handleCloseCrear}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
      >
        <Romaneo_M_envases_carga_30
          openCrear={openCrear}
          datosRomaneoEnvases={datosRomaneoEnvases}
          romaneo_tipo_envase={romaneo_tipo_envase}
          date={date}
          firmas={firmas}
          mat_fabricante={mat_fabricante}
          estadoEnvase={estadoEnvase}
          leyendaXid={leyendaXid}
          // SELECT AUTOCOMPLETE
          tipoEnvaseSeleccionado={tipoEnvaseSeleccionado}
          firmaSeleccionado={firmaSeleccionado}
          leyendaSeleccionado={leyendaSeleccionado}
          matriculaSeleccionada={matriculaSeleccionada}
          // LIMPIAR
          handleClickOpenCrear={handleClickOpenCrear}
          limpiarCrear30={limpiarCrear30}
          limpiarTipoReparacion={limpiarTipoReparacion}
          limpiarTipoEnvase={limpiarTipoEnvase}
          limpiarFirma={limpiarFirma}
          limpiarMatricula={limpiarMatricula}
          // CHECKS
          checkSwitchPlaca={checkSwitchPlaca}
          // NOTIFICACION
          openSnack={openSnack}
          colormensaje={colormensaje}
          mensaje={mensaje}
          disBtn={disBtn}
          // CERRAR
          handleCloseCrear={handleCloseCrear}
        />
      </Dialog>

      {/* AGREGAR ITEM 45 KG */}
      <Dialog
        fullScreen
        open={openCrear45}
        onClose={handleCloseCrear45}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
      >
        <Romaneo_M_envases_carga_45
          openCrear45={openCrear45}
          datosRomaneoEnvases={datosRomaneoEnvases}
          romaneo_tipo_envase45={romaneo_tipo_envase45}
          date={date}
          firmas={firmas}
          mat_fabricante={mat_fabricante}
          envaseYpeso={envaseYpeso}
          estadoEnvase={estadoEnvase}
          leyendaXid={leyendaXid}
          // SELECT AUTOCOMPLETE
          firmaSeleccionado={firmaSeleccionado}
          tipoEnvaseSeleccionado={tipoEnvaseSeleccionado}
          leyendaSeleccionado={leyendaSeleccionado}
          matriculaSeleccionada={matriculaSeleccionada}
          envaseSeleccionado={envaseSeleccionado}
          // NOTIFICACION
          openSnack={openSnack}
          colormensaje={colormensaje}
          mensaje={mensaje}
          disBtn={disBtn}
          // CERRAR
          handleCloseCrear45={handleCloseCrear45}
        />
      </Dialog>

      {/* AGREGAR ITEM (DESTRUIDO) */}
      <Dialog
        fullScreen
        open={openDestruir}
        onClose={handleCloseDestruir}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
      >
<Romaneo_M_envases_destruido
          openDestruir={openDestruir}
          datosRomaneoEnvases={datosRomaneoEnvases}
          mat_fabricante={mat_fabricante}
          // SELECT AUTOCOMPLETE
          matriculaSeleccionada={matriculaSeleccionada}
          limpiarMatricula={limpiarMatricula}
          // NOTIFICACION
          openSnack={openSnack}
          colormensaje={colormensaje}
          mensaje={mensaje}
          disBtn={disBtn}
          // CERRAR
          handleCloseDestruir={handleCloseDestruir}
/>

      </Dialog>

      {/* GUARDAR AL CREAR */}
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}

export default Romaneo_M_envases_carga;
