import React, { useState, useEffect, useReducer } from "react";
import { getData, postData, putData } from "../../../services/library";
import { TextField, Typography, Box, DialogTitle, DialogContentText, Select, Dialog, DialogContent, DialogActions, Button, Grid, FormControl, InputLabel, MenuItem } from '@mui/material';
import Notify from "../../Notify";
import { enviarmails } from "../../../services/services";
import { v4 as uuidv4 } from "uuid";
import { AttachFile } from "@mui/icons-material";
import moment from "moment";
import { logEventos } from "../../../services/services";

export default function FormPrepararReq(props) {
    // Crear una nueva instancia de Date, que representa la fecha y hora actuales.
    var fechaActual = new Date();

    // Obtener la fecha en formato ISO (yyyy-mm-dd).
    var fechaISO = fechaActual.toISOString().substring(0, 10);

    // Obtener la hora actual en formato HH:mm:ss.
    var horaActual = fechaActual.toLocaleTimeString('es-ES', { hour12: false });

    // Combinar la fecha y hora.
    var fechaYHora = fechaISO + ' ' + horaActual;
    const [disbtn, setdisbtn] = useState(false);
    const [openclose, setOpenClose] = useState(false);
    const [uuid, setUuid] = useState(uuidv4());
    const [lista, setLista] = useState([]);
    const [almacenes, setAlmacenes] = useState(null);
    const [almacenSelected, setalmacenSelected] = useState();
    const [listaMaterialesTransferencia, setlistaMaterialesTransferencia] = useState([]);
    const [observaciones, setObservaciones] = useState({
        observaciones_entregado: null,
        usuario_gestiona: localStorage.getItem('user'),
        estado: 184,
    });

    const [notificacion, setNotificacion] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
    });

    const [datosTransferencias, setdatosTransferencias] = useState({
        id_accion: 160,
        sucursal_destino: props.datos.id_sucursal,
        nro_remito_puntodeventa: "",
        nro_remito_numero: "",
        fecha_transaccion: fechaYHora,
        usuario: localStorage.getItem('user'),
        detalle_transaccion: "",
        en_transito: 1,
        uuid_documentacion: uuid,
        estado_confirmacion: 0
    });

    useEffect(() => {
        if (lista) {
            setlistaMaterialesTransferencia(
                lista.map((articulo) => ({
                    id_articulo: articulo.id_articulo,
                    almacen_origen: almacenSelected ?? "",
                    cantidad_enviada: null
                }))
            )
        }
    }, [lista, almacenSelected]);

    useEffect(() => {
        getData('/lista_materiales/req/' + props.datos.uuid_requerimiento, setLista);
        getData('/stock_almacenes/xsucursal/' + localStorage.getItem('sucursal'), setAlmacenes);
    }, [props.datos.uuid_requerimiento]);

    const handleClose = () => {
        props.open(false)
        props.dialog(false)
        props.actualizar()
    };

    const almacenar = (e) => {
        setObservaciones({ ...observaciones, [e.target.name]: e.target.value })
    };

    const almacenarTransferencia = (e) => {
        setdatosTransferencias({ ...datosTransferencias, [e.target.name]: e.target.value })
    };

    const handleCantidadChange = (index, cantidad_enviada, almacen_origen, id_articulo) => {
        setlistaMaterialesTransferencia((prevLineas) =>
            prevLineas.map((linea, i) =>
                i === index ? { ...linea, cantidad_enviada, id_articulo } : linea
            )
        );
    };

    const handleAlmacenChange = (outerIndex, nuevoAlmacen) => {
        setlistaMaterialesTransferencia((prevLineas) =>
            prevLineas.map((linea, i) =>
                i === outerIndex ? { ...linea, almacen_origen: nuevoAlmacen } : linea
            )
        );
    };

    async function entregarRequerimiento(e) {
        setdisbtn(true)
        await putData(e,
            "/requerimientos_materiales/" + props.datos.idrequerimiento,
            {
                observaciones_entregado: observaciones.observaciones_entregado,
                usuario_gestiona: localStorage.getItem('user'),
                estado: 184,
            },
            setNotificacion
        );
        postTransaccion(e)
        observaciones.observaciones_entregado = ""
        props.datos && enviarmails(props.datos.email_registrante, "Requerimiento aprobado", `<p>El requerimiento ${props.datos.idrequerimiento} fue aprobado por el usuario ${localStorage.getItem('user')}.</p>`)
        logEventos("Preparar requerimiento interno", "Generar ordenes", "Requerimientos materiales", localStorage.getItem("user"))
    };

    async function postTransaccion(e) {
        const cuerpoSolicitud = listaMaterialesTransferencia.map(item => ({
            id_accion: 160,
            sucursal_destino: props.datos.id_sucursal,
            nro_remito_puntodeventa: datosTransferencias.nro_remito_puntodeventa,
            nro_remito_numero: datosTransferencias.nro_remito_numero,
            fecha_transaccion: moment(datosTransferencias.fecha_transaccion).format(
                "YYYY-MM-DD HH:mm:ss"
            ),
            usuario: localStorage.getItem('user'),
            detalle_transaccion: datosTransferencias.detalle_transaccion,
            en_transito: 1,
            uuid_documentacion: uuid,
            estado_confirmacion: 0,
            id_articulo: item.id_articulo,
            almacen_origen: item.almacen_origen,
            cantidad_enviada: item.cantidad_enviada,
            id_requerimiento:props.datos.idrequerimiento
        }));


        postData(e,
            '/stock_transacciones/req',
            {
                cuerpoSolicitud
            },
            setNotificacion
        );
        logEventos("Guardar transaccion", "Generar ordenes", "Requerimientos materiales", localStorage.getItem("user"))
    };

    const style = {
        backgroundColor: "#ffffff",
        width: "100%"
    };

    return (
        <Dialog open={props.open} onClose={() => props.open(false)} maxWidth>
            <form onSubmit={entregarRequerimiento} autoComplete='off'>
                <Box backgroundColor={"#1F618D"}>
                    <DialogTitle>
                        <Typography variant="h6" align="center" color="#ffffff">
                            Enviar articulos a sucursal {props.datos.nombre_sucursal}
                        </Typography>
                    </DialogTitle>
                </Box>
                <DialogContent>
                    <Box
                        backgroundColor="#F4F6F6"
                        p={3}
                        mb={5}
                        border={0.5}
                        borderColor={"#ABB2B9"}
                        borderRadius={4}
                    >
                        <Grid container spacing={1} direction={{ xs: 'column', sm: 'row' }}>
                            {lista && lista.map((articulo, outerIndex) => (
                                <>
                                    <Grid item xs={1.5}>
                                        <TextField
                                            label="Solicitado"
                                            focused
                                            color="primary"
                                            style={style}
                                            size="small"
                                            value={articulo.cantidad}
                                            inputProps={{
                                                readOnly: true
                                            }}
                                        />
                                    </Grid>
                                    {/* <Grid item xs={1}>
                                        <TextField
                                            label="Disponible"
                                            focused
                                            color="primary"
                                            style={style}
                                            size="small"
                                            value={articulo.cantidad_disponible}
                                            inputProps={{
                                                readOnly: true
                                            }}
                                        />
                                    </Grid> */}
                                    <Grid item xs={1.5}>
                                        <TextField
                                            label="A enviar"
                                            focused
                                            color="primary"
                                            style={style}
                                            size="small"
                                            name="cantidad_enviada"
                                            // value={listaMaterialesTransferencia[outerIndex]?.cantidad_enviada || ""}
                                            value={
                                                listaMaterialesTransferencia[outerIndex]?.cantidad_enviada || ""
                                            }
                                            onChange={(e) =>
                                                handleCantidadChange(
                                                    outerIndex,
                                                    e.target.value,
                                                    almacenSelected,
                                                    articulo.id_articulo
                                                )
                                            }
                                        // onChange={(e) => handleCantidadChange(outerIndex, e.target.value, almacenSelected, articulo.id_articulo)}
                                        />
                                    </Grid>


                                    <Grid item xs={5}>
                                        <TextField
                                            label="Articulo"
                                            focused
                                            color="primary"
                                            style={style}
                                            size="small"
                                            value={articulo.nombre}
                                            onChange={(e) => handleCantidadChange(outerIndex, e.target.value, almacenSelected, articulo.id_articulo)}
                                            inputProps={{
                                                readOnly: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl style={style} required focused size="small" >
                                            <InputLabel>Almacen origen</InputLabel>
                                            <Select
                                                // name="almacen_origen"
                                                // label="Almacen origen"
                                                // value={listaMaterialesTransferencia[outerIndex]?.almacen_origen || ""}
                                                // onChange={(e) => handleAlmacenChange(outerIndex, e.target.value)}>
                                                // {(almacenes ?? []).map(elemento => (
                                                //     <MenuItem key={elemento.id_almacen} value={elemento.id_almacen}>
                                                //         {elemento.nombre}
                                                //     </MenuItem>
                                                // ))}
                                                name="almacen_origen"
                                                label="Almacen origen"
                                                value={
                                                    listaMaterialesTransferencia[outerIndex]?.almacen_origen || ""
                                                }
                                                onChange={(e) => handleAlmacenChange(outerIndex, e.target.value)}
                                            >
                                                {(almacenes ?? []).map((elemento) => (
                                                    <MenuItem key={elemento.id_almacen} value={elemento.id_almacen}>
                                                        {elemento.nombre}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </>
                            ))}
                        </Grid>
                        <Grid container spacing={1} mt={0.5} direction={{ xs: "column", sm: "row" }}>
                            <Grid item xs={3}>
                                <TextField
                                    label="Pto venta"
                                    size="small"
                                    name="nro_remito_puntodeventa"
                                    focused
                                    required
                                    color="primary"
                                    style={style}
                                    value={datosTransferencias.nro_remito_puntodeventa}
                                    onChange={almacenarTransferencia}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    label="Número remito"
                                    size="small"
                                    required
                                    name="nro_remito_numero"
                                    focused
                                    color="primary"
                                    style={style}
                                    value={datosTransferencias.nro_remito_numero}
                                    onChange={almacenarTransferencia}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    name="fecha_transaccion"
                                    focused
                                    size='small'
                                    required
                                    style={style}
                                    label="Fecha de entrega"
                                    value={moment(datosTransferencias.fecha_transaccion).format("YYYY-MM-DD")}
                                    onChange={almacenarTransferencia}
                                    type="date"
                                // inputProps={{
                                //     min: moment(new Date()).format("YYYY-MM-DD")
                                // }}
                                />

                            </Grid>
                            <Grid item xs={3}>
                                <Button onClick={() => setOpenClose(!openclose)} size="small" variant="outlined" style={{
                                    borderColor: "#13406e",
                                    backgroundColor: "#ffffff",
                                }}
                                    startIcon={<AttachFile />} >Adjuntar documentación</Button>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    label="Detalle transaccion"
                                    // size="small"
                                    name="detalle_transaccion"
                                    focused
                                    color="primary"
                                    style={style}
                                    value={datosTransferencias.detalle_transaccion}
                                    onChange={almacenarTransferencia}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    <DialogContentText>
                        <Typography textAlign={"center"} >
                            <strong>Ingrese las observaciones de entrega del requerimiento</strong>
                        </Typography>
                    </DialogContentText>

                    <Box>
                        <TextField
                            // required
                            onChange={almacenar}
                            placeholder="Escriba aquí sus observaciones "
                            value={observaciones.observaciones_entregado}
                            margin="dense"
                            type="text"
                            multiline
                            fullWidth
                            variant="standard"
                            name="observaciones_entregado"
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Grid container direction="row" justifyContent="space-between" alignItemns="center">
                        <Button
                            variant="contained"
                            onClick={() => props.open(false)}
                            color="error"
                        >
                            Volver
                        </Button>
                        <Button
                            disabled={disbtn}
                            type="submit"
                            variant="contained"
                            color="success"
                        >
                            Confirmar
                        </Button>
                    </Grid>
                </DialogActions>
            </form>
            {notificacion && notificacion.open ? (
                <Notify
                    mensaje={notificacion.mensaje}
                    open={notificacion.open}
                    color={notificacion.color}
                    handleClose={handleClose}
                    severity={notificacion.severity}
                />
            ) : null}
        </Dialog>
    )
}
//========================================================================
/*----- Component --------------------------------------------------------/
// Developer     : Candela Grosso   
// Creation Date : 27/12/2023
// Version       : 1.0  
// Description   :  Con este componente el usuario es capaz de :
    - ENVIAR ARTICULOS A UNA SUCURSAL MEDIANTE TRANSFERENCIA 
    - MARCAR REQUERIMIENTO DE MATERIALES COMO ENVIADO QSY
//------Changes --------------------------------------------------------------------- -------------//
// Change Date   :
// Change by     :
// Change description: 
//========================================================================*/