import React, { useEffect, useState } from "react";
import { UrlApi } from "../../../services/apirest";
import Stack from "@mui/material/Stack";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

// import CircularProgress from '@mui/joy/CircularProgress';

function HistorialTanque(props) {
  console.log(props.datos); // estos son los datos del tanque
  const [movimientos, setMovimientos] = useState();
  const obtenerMovimientos = () => {
    fetch(
      UrlApi +
        "/movimientos_tanques/" +
        props.datos.id +
        "/" +
        props.datos.nro_tanque
    )
      .then((data) => data.json())
      .then((data) => setMovimientos(data));
  };

  useEffect(() => {
    obtenerMovimientos();
  }, []);

  console.log(movimientos);

  return (
    <div>
      <Container maxWidth="lg" disableGutters>
        {movimientos ? (
          movimientos.map((movimiento) => (
            <>
              <Box
                mb={1}
                p={1.5}
                backgroundColor="#EAF2F8"
                border={1}
                borderColor="#D4E6F1"
              >
                {movimiento.cliente ? (
                  <>
                    <strong>Cliente:</strong> {movimiento.cliente} <br></br>
                  </>
                ) : null}
                {movimiento.sucursal ? (
                  <>
                    <strong>Sucursal:</strong> {movimiento.sucursal} <br></br>
                  </>
                ) : null}
                {movimiento.nro_remito ? (
                  <>
                    <strong>Remito:</strong> {movimiento.nro_remito} <br></br>
                  </>
                ) : null}

                {movimiento.fecha ? (
                  <>
                    <strong>Fecha Remito:</strong> {movimiento.fecha} <br></br>
                  </>
                ) : null}

                {movimiento.created_at ? (
                  <>
                    <strong>Registrado:</strong> {movimiento.created_at}{" "}
                    <br></br>
                  </>
                ) : null}
              </Box>
              {/* <hr></hr> */}
            </>
          ))
        ) : (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs>
              <Typography m={4} fontSize={22} variant="h2" color="initial">
                Cargando historial del tanque..
              </Typography>
            </Grid>
            <Grid item>
              <CircularProgress color="primary" />
            </Grid>
          </Grid>
        )}
        <br></br>
      </Container>
    </div>
  );
}

export default HistorialTanque;
