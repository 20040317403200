import React, { useEffect, useState, useRef, useMemo } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Button,
  Container,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Stack,
  Grid,
  TextField,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import moment from "moment";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import Romaneo_repetido from "./Romaneo_repetido";

// ICONOS
import PinIcon from "@mui/icons-material/Pin";

// CHECKBOX
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

// SWITCH
import Switch from "@mui/material/Switch";

// Notification
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Romaneo_M_envases_carga_45(props) {
  const [openCrear45, setOpenCrear45] = useState(props.openCrear45);
  const [datosRomaneoEnvases, setDatosRomaneoEnvases] = useState(
    props.datosRomaneoEnvases
  );

  const [date, setDate] = useState(props.date);
  const [firmas, setFirmas] = useState(props.firmas);

  const [mat_fabricante, setMat_fabricante] = useState(props.mat_fabricante);
  const [estadoEnvase, setEstadoEnvase] = useState(props.estadoEnvase);
  const [leyendaXid, setLeyendaXid] = useState(null);

  //*************************************************************************************************************************************/
  //funcion para obtener a los romaneo descripcion_tipo_envase, pasarlos a json y guardarlos en data

  const [romaneo_tipo_envase45, setRomaneo_Tipo_Envase45] = useState();

  const obtenerDescripcionTipoEnvase45 = () => {
    fetch(UrlApi + "/romaneo_tipos_envases/45")
      .then((data) => data.json())
      .then((data) => setRomaneo_Tipo_Envase45(data));
  };
  console.log("trae la descripcion de tipo envase", romaneo_tipo_envase45);

  //funcion que espera los cambios de la funcion de obtener a los romaneo descripcion_tipo_envase
  useEffect(() => {
    obtenerDescripcionTipoEnvase45();
  }, []);

  // -------------------------- SWITCHS -------------------------------------
  // NUMERO DE ACUÑADO
  const [valorAcuniado, setValorAcuniado] = useState();

  const obtenerValorAcuniado = () => {
    fetch(UrlApi + "/romaneo_envases/valoracuniado")
      .then((data) => data.json())
      .then((data) => setValorAcuniado(data));
  };

  console.log("valor acuñado maximo:", valorAcuniado);

  // -----------------------------------------------------------------------------
  // REBALANCEO
  const [checkSwitchRebalanceo, setCheckSwitchRebalanceo] = useState(
    datosRomaneoEnvases.rebalanceo === 1 ? true : false
  );

  const handleOnChangeSwitchRebalanceo = (e) => {
    setCheckSwitchRebalanceo(e.target.checked);
    obtenerValorAcuniado();
  };

  console.log("checkSwitchRebalanceo", checkSwitchRebalanceo);

  useEffect(() => {
    setCheckSwitchPlaca(true);
  }, [checkSwitchRebalanceo]);

  // -----------------------------------------------------------------------------

  // PLACA
  const [checkSwitchPlaca, setCheckSwitchPlaca] = useState(
    datosRomaneoEnvases.placa_relieve === 2 ? true : false
  );

  const handleOnChangeSwitchPlaca = (e) => {
    setCheckSwitchPlaca(e.target.checked);
  };

  console.log("checkSwitchPlaca", checkSwitchPlaca);

  // -----------------------------------------------------------------------------

  // ACUÑADO
  const [checkSwitchAcuniado, setCheckSwitchAcuniado] = useState(
    datosRomaneoEnvases.acuniado === 1 ? true : false
  );

  const handleOnChangeSwitchAcuniado = (e) => {
    setCheckSwitchAcuniado(e.target.checked);
  };

  useEffect(() => {
    datosRomaneoEnvases.nro_envase = null;
  }, [checkSwitchAcuniado]);

  useEffect(() => {
    datosRomaneoEnvases.nro_acuniado = null;
  }, [!checkSwitchAcuniado]);

  console.log("checkSwitchAcuniado", checkSwitchAcuniado);

  // -----------------------------------------------------------------------------

  // CASQUETE
  const [checkSwitchCasquete, setCheckSwitchCasquete] = useState(
    datosRomaneoEnvases.cambio_casquete === 1 ? true : false
  );

  const handleOnChangeSwitchCasquete = (e) => {
    setCheckSwitchCasquete(e.target.checked);
  };

  console.log("checkSwitchCasquete", checkSwitchCasquete);

  // ---------------------------------------------------------------

  // AUTOCOMPLETE
  const [tipoEnvaseSeleccionado, setTipoEnvaseSeleccionado] = useState({});
  const [firmaSeleccionado, setFirmaSeleccionado] = useState(
    props.firmaSeleccionado
  );

  const [matriculaSeleccionada, setMatriculaSeleccionada] = useState(
    props.matriculaSeleccionada
  );

  // CREAR 45
  const [limpiarCrear45, setLimpiarCrear45] = useState(false);

  useEffect(() => {
    setCheckSwitchRebalanceo(false);
    setCheckSwitchPlaca(false);
    setCheckSwitchCasquete(false);
    obtenerDescripcionTipoEnvase45();
    datosRomaneoEnvases.rebalanceo = null;
    datosRomaneoEnvases.id_tipo = null;
    datosRomaneoEnvases.nro_envase = "";
  }, [limpiarCrear45]);

  const [limpiarTipoEnvase, setLimpiarTipoEnvase] = useState(
    props.limpiarTipoEnvase
  );
  const [limpiarFirma, setLimpiarFirma] = useState(props.limpiarFirma);
  const [limpiarMatricula, setLimpiarMatricula] = useState(
    props.limpiarMatricula
  );

  // NOTIFICACION
  const [openSnack, setOpenSnack] = useState(props.openSnack);
  const [colormensaje, setColorMensaje] = useState(props.colormensaje);
  const [mensaje, setMensaje] = useState(props.mensaje);
  //
  const [update, setUpdate] = useState(props.update);
  const [disBtn, setDisBtn] = useState(props.disBtn);

  //*************************************************************************************************************************************/

  // USANDO ROW TOMO LOS DATOS DE ESA FILA
  const handleClickOpenCrear45 = (row) => {
    setOpenCrear45(true);
    setCheckSwitchPlaca(false); // para apgar el switch cada vez que abre el dialog
    setCheckSwitchRebalanceo(false);
    setCheckSwitchCasquete(false);

    // AL ABRIR EL DIALOG LOS TEXTFIELDS SE VACIAN
    datosRomaneoEnvases.id_tipo_reparacion = null;
    datosRomaneoEnvases.id_tipo = null;
    tipoEnvaseSeleccionado.id_tipo = null;
    datosRomaneoEnvases.leyenda = null;
    firmaSeleccionado.id_firma = null;
    datosRomaneoEnvases.id_pertenencia = null;
    datosRomaneoEnvases.id_pertenencia2 = null;
    datosRomaneoEnvases.nro_envase = null;
    matriculaSeleccionada.id_mat_fabricante = null;
    datosRomaneoEnvases.lote = "001";
    datosRomaneoEnvases.fecha_fabricacion = "";
    datosRomaneoEnvases.placa_relieve = 1;
    datosRomaneoEnvases.nro_placa = null;
    datosRomaneoEnvases.nro_placa_rect = null;
    datosRomaneoEnvases.ley_placa_rect = null;
    datosRomaneoEnvases.nro_acuniado = null;
    datosRomaneoEnvases.id_mot_baja = null;
    datosRomaneoEnvases.fecha_reparacion = date;
    datosRomaneoEnvases.rebalanceo = null;
    datosRomaneoEnvases.id_estado = null;
  };

  // ------------------------------- REPETIDOS -------------------------------

  // MODAL DE REPETIDOS
  const [modalRepetido, setModalRepetido] = useState(false);

  // REPETICION: PARA VERIFICAR SI AL CARGAR SE ESTÁ REPITIENDO UN DATO

  const [repeticion, setRepeticion] = useState(null); //Guardo el que esté repetido (en caso de que exista)
  //Funcion con la que consulto a la api si ya existe un envase con X firma, X  y X nro de envase, para no cargar repetidos

  const obtenerRepeticion = () => {
    fetch(
      UrlApi +
        "/romaneo_envases/envases_repetidos/" +
        tipoEnvaseSeleccionado.id_tipo +
        "/" +
        firmaSeleccionado.id_firma +
        "/" +
        datosRomaneoEnvases.id_pertenencia +
        "/" +
        datosRomaneoEnvases.nro_envase
    )
      .then((data) => data.json())
      .then((data) => setRepeticion(data));
  };

  console.log("REPETICION DESDE ADETRO DEL COMP: ", repeticion);
  console.log("MODAL REP: ", modalRepetido);

  // escuchando a la repeticion, pregunto si lo que me traje de la api tiene longitud > 0
  // (o sea, si hay algo en repeticion)

  useEffect(() => {
    if (repeticion && repeticion.length > 0) {
      setModalRepetido(true);
      setDatosRomaneoEnvases({
        ...datosRomaneoEnvases,
        ["nro_envase"]: "",
      });
    }
  }, [repeticion, firmas]);

  // Ejecuto la funcion escuchando al proveedor seleccionado , al tipo de comprobante y al numero de comprobante
  // para que haga la busqueda cada vez que elija un proveedor (o lo cambie), y tipee un numero de comprobante

  useEffect(() => {
    firmas && obtenerRepeticion();
  }, [
    tipoEnvaseSeleccionado.id_tipo,
    firmaSeleccionado.id_firma,
    datosRomaneoEnvases.id_pertenencia,
    datosRomaneoEnvases.nro_envase,
  ]);

  // ------------------------------------------------------------------------

  const obtenerFirmas = () => {
    fetch(UrlApi + "/romaneo_pertenencias/agrupados")
      .then((data) => data.json())
      .then((data) => setFirmas(data));
  };

  const obtenerLeyendaXID = () => {
    fetch(
      UrlApi +
        "/romaneo_pertenencias/traeLeyendaxID/" +
        firmaSeleccionado.id_firma
    )
      .then((data) => data.json())
      .then((data) => setLeyendaXid(data));
  };

  useEffect(() => {
    obtenerLeyendaXID();
    console.log(
      "EJECUTO PARA OBTENER PERTENENCIAS: ",
      firmaSeleccionado.id_firma
    );
    console.log("LEYENDAS: ", leyendaXid);
    console.log("ROMANEO ID FIRMA: ", datosRomaneoEnvases.id_firma);
  }, [firmaSeleccionado.id_firma]);

  //*************************************************************************************************************************************/

  const handleParaHabilitar = (setParaHabilitar) => {
    setParaHabilitar(true);
  };

  //*************************************************************************************************************************************/

  //Funcion para guardar al crear (ENVASE 45 KG)
  const guardarCrear45 = async (e) => {
    console.log("Estoy por guardar");
    setDisBtn(true);
    e.preventDefault();
    await fetch(UrlApi + "/romaneo_envases", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_tipo: tipoEnvaseSeleccionado.id_tipo,
        nro_envase: datosRomaneoEnvases.nro_envase,
        id_mat_fabricante: matriculaSeleccionada.id_fabricante,
        lote: datosRomaneoEnvases.lote,
        fecha_fabricacion: datosRomaneoEnvases.fecha_fabricacion,
        placa_relieve: checkSwitchPlaca === true ? 2 : 1,
        nro_placa: datosRomaneoEnvases.nro_placa,
        cambio_casquete: checkSwitchCasquete === true ? 1 : null,
        nro_placa_rect:
          checkSwitchRebalanceo === true
            ? datosRomaneoEnvases.nro_placa_rect
            : null,
        id_mot_baja: datosRomaneoEnvases.id_mot_baja,
        ley_placa_rect: datosRomaneoEnvases.ley_placa_rect,
        nro_acuniado: datosRomaneoEnvases.nro_acuniado,
        fecha_reparacion: moment(datosRomaneoEnvases.fecha_reparacion).format(
          "YYYY-MM-DD"
        ),
        rebalanceo: checkSwitchRebalanceo === true ? 1 : null,
        id_estado: checkSwitchRebalanceo === true ? null : 151,
        id_pertenencia: datosRomaneoEnvases.id_pertenencia,
        id_pertenencia2: datosRomaneoEnvases.id_pertenencia2,
        id_firma: firmaSeleccionado.id_firma,
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El envase se guardó exitosamente !");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el envase");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    // PARA LIMPIAR LOS DATOS
    handleClickOpenCrear45();
    setLimpiarCrear45(true);
    setCheckSwitchAcuniado(false);
    setLimpiarMatricula(true);
    setLimpiarFirma(true);
    setLimpiarTipoEnvase(true);
    // OBTENGO FIRMAS AL GUARDAR (SINO SE ROMPE EL ID EN EL AUTOCOMPLETE)
    obtenerFirmas();
  };
  console.log("POST CREAR 45", datosRomaneoEnvases);

  //  ------------------------------------------------------------------------

  const almacenar = (e) => {
    setDatosRomaneoEnvases({
      ...datosRomaneoEnvases,
      [e.target.name]: e.target.value,
    });
  };

  //  ------------------------------------------------------------------------

  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
    setUpdate(false);
    setLimpiarCrear45(false);
    setLimpiarMatricula(false);
    setLimpiarTipoEnvase(false);
    setLimpiarFirma(false);
    setDisBtn(false);
    // FOCUS AL SWITCH
    focusElement(switch1Ref);
    console.log("UPDATE EN EL COMPONENTE HIJO 2: ", update);
  };

  //  ------------------------------------------------------------------------

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  // ------------------ PARA EL FOCUS ------------------
  // REHABILITACION:
  const switch1Ref = useRef(null); // REHABILITAR O REBALANCEAR
  const autocomplete1Ref = useRef(null); // TIPO DE REPARACION
  const autocomplete2Ref = useRef(null); // FIRMA
  const select1Ref = useRef(null); // LEYENDA
  const textfield1Ref = useRef(null); // NRO DE ENVASE
  const autocomplete3Ref = useRef(null); // MATRICULA DEL FABRICANTE
  const switch2Ref = useRef(null); // RELIEVE O PLACA
  const textfield2Ref = useRef(null); // NRO DE PLACA
  const checkbox1Ref = useRef(null); // CAMBIO DE CASQUETE
  const textfield3Ref = useRef(null); // FECHA DE FABRICACION
  const buttonRef = useRef(null); // BOTON DE GUARDAR
  const textfield4Ref = useRef(null); // LEYENDA CHAPA RECTANGULAR
  const textfield5Ref = useRef(null); // NUMERO CHAPA RECTANGULAR
  const textfield6Ref = useRef(null); // NUMERO CHAPA CIRCULAR
  const switch3Ref = useRef(null); // SWITCH NUMERO DE ENVASE / NUMERO ACUÑADO

  // REBALANCEO:
  const [focusedElement, setFocusedElement] = useState(null);

  const focusElement = (elementRef) => {
    if (elementRef.current) {
      elementRef.current.focus();
    }
  };

  const handleFocusChange = (elementName) => {
    setFocusedElement(elementName);
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Enter" && !e.repeat) {
        e.preventDefault(); // Evitar el comportamiento predeterminado de la tecla "Enter"
        if (checkSwitchRebalanceo) {
          // Verificar si checkSwitchRebalanceo es true
          // SI HAY REBALANCEO:
          switch (focusedElement) {
            case "switchRef1": // Elijo cuando ocurre
              focusElement(autocomplete1Ref); // El nuevo elemento al que voy a enfocar
              setFocusedElement("autocomplete1"); // el nombre del elemento
              break;
            case "autocomplete1":
              focusElement(autocomplete2Ref);
              setFocusedElement("autocomplete2");
              break;
            case "autocomplete2":
              focusElement(select1Ref);
              setFocusedElement("select1");
              break;
            case "select1":
              focusElement(autocomplete3Ref);
              setFocusedElement("autocomplete3");
              console.log("EJECUTO EL QUE TIENE REBALANCEO");
              break;
            case "autocomplete3":
              focusElement(textfield4Ref);
              setFocusedElement("textfield4");
              break;
            case "textfield4":
              focusElement(textfield1Ref);
              setFocusedElement("textfield1");
              break;
            case "textfield1":
              focusElement(switch3Ref);
              setFocusedElement("switchRef3");
              break;
            case "switchRef3":
              focusElement(textfield1Ref);
              setFocusedElement("textfield1");
              break;
            case "textfield5":
              focusElement(textfield6Ref);
              setFocusedElement("textfield6");
              break;
            case "textfield6":
              focusElement(checkbox1Ref);
              setFocusedElement("checkbox1");
              break;
            case "checkbox1":
              focusElement(textfield3Ref);
              setFocusedElement("textfield3");
              break;
            case "textfield3":
              // Enfocar el botón
              if (buttonRef.current) {
                buttonRef.current.focus();
              }
              setFocusedElement("buttonRef");
              break;
            default:
              break;
          }
        } else {
          // SI HAY REHABILITACION
          switch (focusedElement) {
            case "switchRef1": // Elijo cuando ocurre
              focusElement(autocomplete1Ref); // El nuevo elemento al que voy a enfocar
              setFocusedElement("autocomplete1"); // el nombre del elemento
              break;
            case "autocomplete1":
              focusElement(autocomplete2Ref);
              setFocusedElement("autocomplete2");
              break;
            case "autocomplete2":
              focusElement(select1Ref);
              setFocusedElement("select1");
              break;
            case "select1":
              focusElement(textfield1Ref);
              setFocusedElement("textfield1");
              console.log("NO EJECUTO EL QUE TIENE REBALANCEO");
              break;
            case "textfield1":
              focusElement(autocomplete3Ref);
              setFocusedElement("autocomplete3");
              break;
            case "autocomplete3":
              focusElement(switch2Ref);
              setFocusedElement("switchRef2");
              break;
            case "switchRef2" && checkSwitchPlaca:
              focusElement(textfield2Ref);
              setFocusedElement("textfield2");

              break;
            case "switchRef2":
              focusElement(checkbox1Ref);
              setFocusedElement("checkbox1");

              break;
            case "textfield2":
              focusElement(checkbox1Ref);
              setFocusedElement("checkbox1");
              break;
            case "checkbox1":
              focusElement(textfield3Ref);
              setFocusedElement("textfield3");
              break;
            case "textfield3":
              // Enfocar el botón
              if (buttonRef.current) {
                buttonRef.current.focus();
              }
              setFocusedElement("buttonRef");
              break;
            default:
              break;
          }
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [checkSwitchRebalanceo, focusedElement]);

  // -----------------------------------------------------------------------------

  // FOCUS AL SWITCH AL INICIAR EL DIALOG/COMPONENTE
  useEffect(() => {
    focusElement(switch1Ref);
  }, []);

  // ------------- CAMBIO DE FOCUS AUTOMATICO AL COMPLETAR CAMPOS -----------------

  // AL ESCRIBIR EL NUMERO DE ENVASE, FOCUS A LA MATRICULA (REHABILITADOS)
  useEffect(() => {
    if (
      datosRomaneoEnvases.nro_envase &&
      datosRomaneoEnvases.nro_envase.length === 9 &&
      !checkSwitchRebalanceo
    ) {
      focusElement(autocomplete3Ref);
    }
  }, [datosRomaneoEnvases.nro_envase, checkSwitchRebalanceo]);

  // AL ESCRIBIR EL NUMERO DE ENVASE, FOCUS AL NUMERO DE CHAPA RECTANGULAR (REBALANCEO)
  useEffect(() => {
    if (
      datosRomaneoEnvases.nro_envase &&
      datosRomaneoEnvases.nro_envase.length === 9 &&
      checkSwitchRebalanceo
    ) {
      focusElement(textfield5Ref);
    }
  }, [datosRomaneoEnvases.nro_envase, checkSwitchRebalanceo]);

  // AL ESCRIBIR EL NUMERO DE CHAPA RECTANGULAR, FOCUS A LA LEYENDA DE CHAPA RECTANGULAR (REBALANCEO)
  useEffect(() => {
    if (
      datosRomaneoEnvases.nro_placa_rect &&
      datosRomaneoEnvases.nro_placa_rect.length === 6
    ) {
      focusElement(textfield6Ref);
    }
  }, [datosRomaneoEnvases.nro_placa_rect]);

  // CUANDO SE ACTIVA EL SWITCH DE PLACA, SE HACE FOCUS AL TEXTFIELD "NUMERO DE PLACA"
  useEffect(() => {
    focusElement(textfield2Ref);
  }, [checkSwitchPlaca]);

  // CUANDO SE ACTIVA EL SWITCH DE ACUÑADO, SE HACE FOCUS AL TEXTFIELD "NUMERO DE CHAPA RECTANGULAR"
  useEffect(() => {
    focusElement(textfield5Ref);
  }, [checkSwitchAcuniado]);

  // AL ESCRIBIR EL NUMERO DE PLACA, FOCUS AL CHECKBOX DE CAMBIO DE CASQUETE
  useEffect(() => {
    if (datosRomaneoEnvases.nro_placa.length === 6) {
      focusElement(checkbox1Ref);
    }
  }, [datosRomaneoEnvases.nro_placa]);

  // AL ACTIVAR EL CASQUETE, FOCUS A LA FECHA DE FABRICACION
  useEffect(() => {
    if (checkSwitchCasquete) {
      focusElement(textfield3Ref);
    }
  }, [checkSwitchCasquete]);

  // AL ESCRIBIR LA FECHA DE FABRICACION, FOCUS AL BOTON DE GUARDAR
  useEffect(() => {
    if (datosRomaneoEnvases.fecha_fabricacion.length === 4) {
      focusElement(buttonRef);
    }
  }, [datosRomaneoEnvases.fecha_fabricacion]);

  // -----------------------------------------------------------------------------

  const boxStyles = {
    backgroundColor: "#EAEFF1", // Color grisaceo de fondo
    borderRadius: "5px", // Bordes redondeados
    padding: "20px", // Espacio interno
    width: "200px", // Ancho
    height: "1px", // Alto
  };

  // -----------------------------------------------------------------------------

  console.log("UPDATE EN EL COMPONENTE HIJO: ", update);

  return (
    <>
      <DialogTitle alignSelf="center">
        <b>{"Agregar Cilindro (45 KG)"}</b>
      </DialogTitle>
      <DialogContent>
        <Container maxWidth="xl">
          <form onSubmit={guardarCrear45}>
            <Grid container mt={2} mb={1} spacing={1}>
              <Grid item xs={6} mb={2}>
                <Box style={boxStyles}>
                  <Stack direction="row" alignItems="center">
                    {checkSwitchRebalanceo ? (
                      <Typography
                        style={{ marginLeft: "-8px", marginTop: "-10px" }}
                      >
                        Rehabilitar
                      </Typography>
                    ) : (
                      <Typography
                        style={{ marginLeft: "-8px", marginTop: "-10px" }}
                      >
                        <b>Rehabilitar</b>
                      </Typography>
                    )}
                    <FormControlLabel
                      inputRef={switch1Ref}
                      onFocus={() => handleFocusChange("switchRef1")}
                      checked={checkSwitchRebalanceo}
                      onChange={handleOnChangeSwitchRebalanceo}
                      control={<Switch />}
                      style={{
                        height: "23px",
                        width: "23px",
                        marginTop: "-10px",
                        marginLeft: "-4px",
                      }}
                    />
                    {checkSwitchRebalanceo ? (
                      <Typography
                        style={{ marginLeft: "12px", marginTop: "-10px" }}
                      >
                        <b>Rebalancear</b>
                      </Typography>
                    ) : (
                      <Typography
                        style={{ marginLeft: "12px", marginTop: "-10px" }}
                      >
                        Rebalancear
                      </Typography>
                    )}
                  </Stack>
                </Box>
              </Grid>

              <Grid item xs={6} mb={2}>
                {limpiarTipoEnvase ? (
                  <>
                    <TextField
                      value={(tipoEnvaseSeleccionado.id_tipo = null)}
                      disabled
                      size="small"
                      label="Tipo de Envase"
                      fullWidth
                    />
                  </>
                ) : (
                  <Autocomplete
                    id="autocomplete"
                    size="small"
                    disableClearable
                    noOptionsText={"Tipo de envase INEXISTENTE"}
                    options={romaneo_tipo_envase45}
                    autoHighlight
                    getOptionLabel={(romaneo_tipo_envase45) =>
                      romaneo_tipo_envase45.id_tipo +
                      "-" +
                      romaneo_tipo_envase45.descripcion_tipo_envase
                    }
                    onChange={(event, value) =>
                      setTipoEnvaseSeleccionado(value)
                    }
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        key={option.id}
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.id_tipo} - {option.descripcion_tipo_envase}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        inputRef={autocomplete1Ref}
                        onFocus={() => handleFocusChange("autocomplete1")}
                        required
                        size="small"
                        placeholder="Tipee para buscar..."
                        {...params}
                        label="Tipo de Envase"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                )}
              </Grid>

              <Grid item xs={6} mb={2}>
                {limpiarFirma ? (
                  <>
                    <TextField
                      value={(firmaSeleccionado.id_firma = null)}
                      disabled
                      size="small"
                      label="Firma"
                      fullWidth
                    />
                  </>
                ) : (
                  <Autocomplete
                    id="autocomplete2"
                    disabled={!firmas}
                    key={firmas}
                    size="small"
                    disableClearable
                    noOptionsText={"Firma INEXISTENTE"}
                    options={firmas}
                    autoHighlight
                    getOptionLabel={(firmas) =>
                      firmas.id_firma + "-" + firmas.firma
                    }
                    onChange={(event, value) => setFirmaSeleccionado(value)}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        key={option.id}
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.id_firma} - {option.firma}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        inputRef={autocomplete2Ref}
                        onFocus={() => handleFocusChange("autocomplete2")}
                        required
                        size="small"
                        placeholder="Tipee para buscar..."
                        {...params}
                        label="Firma"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                )}
              </Grid>

              <>
                <Grid item xs={6} mb={2}>
                  <FormControl fullWidth>
                    {checkSwitchRebalanceo ? (
                      <>
                        <InputLabel>Leyenda Chapa Circular *</InputLabel>
                        <Select
                          inputRef={select1Ref}
                          onFocus={() => handleFocusChange("select1")}
                          label="Leyenda Chapa Circular"
                          required
                          size="small"
                          name="id_pertenencia"
                          value={datosRomaneoEnvases.id_pertenencia}
                          onChange={almacenar}
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          {leyendaXid
                            ? leyendaXid.map((elemento) => (
                                <MenuItem
                                  key={elemento.id_pertenencia}
                                  value={elemento.id_pertenencia} // Debe coincidir con el tipo de dato de la tabla a la que inserta
                                >
                                  {elemento.leyenda}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </>
                    ) : (
                      <>
                        <InputLabel>Leyenda *</InputLabel>
                        <Select
                          inputRef={select1Ref}
                          onFocus={() => handleFocusChange("select1")}
                          label="Leyenda "
                          required
                          size="small"
                          name="id_pertenencia"
                          value={datosRomaneoEnvases.id_pertenencia}
                          onChange={almacenar}
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          {leyendaXid
                            ? leyendaXid.map((elemento) => (
                                <MenuItem
                                  key={elemento.id_pertenencia}
                                  value={elemento.id_pertenencia} // Debe coincidir con el tipo de dato de la tabla a la que inserta
                                >
                                  {elemento.leyenda}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </>
                    )}
                  </FormControl>
                </Grid>
              </>

              {checkSwitchRebalanceo ? (
                <Grid item xs={6}>
                  {limpiarMatricula ? (
                    <>
                      <TextField
                        value={(datosRomaneoEnvases.id_mat_fabricante = null)}
                        disabled
                        size="small"
                        label="Matrícula Fabricante"
                        fullWidth
                      />
                    </>
                  ) : (
                    <>
                      <Autocomplete
                        disabled={!mat_fabricante}
                        size="small"
                        focused
                        required
                        id="id_fabricante"
                        noOptionsText={"Matrícula Fabricante INEXISTENTE"}
                        options={mat_fabricante}
                        autoHighlight
                        getOptionLabel={(mat_fabricante) =>
                          mat_fabricante.id_fabricante +
                          "-" +
                          mat_fabricante.descripcion_fabricante
                        }
                        onChange={(event, value) =>
                          setMatriculaSeleccionada(value)
                        }
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            key={option.id}
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.id_fabricante} -{" "}
                            {option.descripcion_fabricante}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            inputRef={autocomplete3Ref}
                            onFocus={() => handleFocusChange("autocomplete3")}
                            focused
                            size="small"
                            placeholder="Tipee para buscar..."
                            {...params}
                            label="Matrícula Fabricante"
                            inputProps={{
                              ...params.inputProps,
                            }}
                          />
                        )}
                      />
                    </>
                  )}
                </Grid>
              ) : null}

              {checkSwitchRebalanceo ? (
                <>
                  <Grid item xs={6} mb={1}>
                    <TextField
                      inputRef={textfield4Ref}
                      onFocus={() => handleFocusChange("textfield4")}
                      label="Leyenda Chapa Rectangular"
                      name="ley_placa_rect"
                      value={datosRomaneoEnvases.ley_placa_rect}
                      onChange={almacenar}
                      required
                      focused
                      size="small"
                      inputProps={{
                        maxLength: 22,
                      }}
                      fullWidth
                    />
                  </Grid>
                </>
              ) : null}

              {checkSwitchRebalanceo ? (
                <>
                  {checkSwitchAcuniado ? (
                    <Grid item xs={5} mb={2}>
                      <TextField
                        inputRef={textfield1Ref}
                        onFocus={() => handleFocusChange("textfield1")}
                        label="Número de Envase"
                        size="small"
                        placeholder="-"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        inputProps={{
                          maxLength: 9,
                        }}
                        disabled
                        fullWidth
                        value=""
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={5} mb={2}>
                      <TextField
                        inputRef={textfield1Ref}
                        onFocus={() => handleFocusChange("textfield1")}
                        label="Número de Envase"
                        name="nro_envase"
                        size="small"
                        placeholder="-"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        inputProps={{
                          maxLength: 9,
                        }}
                        focused
                        required
                        fullWidth
                        value={datosRomaneoEnvases.nro_envase}
                        onChange={almacenar}
                      />
                    </Grid>
                  )}
                </>
              ) : (
                <Grid item xs={6} mb={2}>
                  <TextField
                    inputRef={textfield1Ref}
                    onFocus={() => handleFocusChange("textfield1")}
                    label="Número de Envase"
                    name="nro_envase"
                    size="small"
                    placeholder="-"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    inputProps={{
                      maxLength: 9,
                    }}
                    focused
                    required
                    fullWidth
                    value={datosRomaneoEnvases.nro_envase}
                    onChange={almacenar}
                  />
                </Grid>
              )}

              {checkSwitchRebalanceo ? (
                <>
                  <Grid xs={2}>
                    <FormControlLabel
                      inputRef={switch3Ref}
                      onFocus={() => handleFocusChange("switchRef3")}
                      checked={checkSwitchAcuniado}
                      onChange={handleOnChangeSwitchAcuniado}
                      control={<Switch />}
                      style={{
                        width: "23px",
                        height: "23px",
                        // marginLeft: "28px",
                        marginLeft: "16px",
                        marginTop: "14px",
                      }}
                    />
                  </Grid>

                  {checkSwitchAcuniado ? (
                    <Grid item xs={5}>
                      {/* HAGO UN MAP PARA OBTENER EL ULTIMO VALOR Y LE SUMO 1 */}
                      {valorAcuniado.nro_acuniado > 0
                        ? null
                        : valorAcuniado.map((elemento, index) => {
                            return (
                              <TextField
                                key={index}
                                value={
                                  (datosRomaneoEnvases.nro_acuniado =
                                    elemento.nro_acuniado + 1)
                                }
                                InputProps={{
                                  readOnly: true,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <PinIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                size="small"
                                label="Número Acuñado"
                                name="nro_acuniado"
                                placeholder="-"
                                variant="outlined"
                                focused
                                fullWidth
                                onChange={almacenar}
                              />
                            );
                          })}
                    </Grid>
                  ) : (
                    <Grid item xs={5}>
                      <TextField
                        label="Número Acuñado"
                        size="small"
                        placeholder="-"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        inputProps={{
                          maxLength: 9,
                        }}
                        disabled
                        fullWidth
                        value=""
                      />
                    </Grid>
                  )}
                </>
              ) : null}

              {checkSwitchRebalanceo ? null : (
                <Grid item xs={6}>
                  {limpiarMatricula ? (
                    <>
                      <TextField
                        value={(datosRomaneoEnvases.id_mat_fabricante = null)}
                        disabled
                        size="small"
                        label="Matrícula Fabricante"
                        fullWidth
                      />
                    </>
                  ) : (
                    <>
                      <Autocomplete
                        disabled={!mat_fabricante}
                        size="small"
                        focused
                        required
                        id="id_fabricante"
                        noOptionsText={"Matrícula Fabricante INEXISTENTE"}
                        options={mat_fabricante}
                        autoHighlight
                        getOptionLabel={(mat_fabricante) =>
                          mat_fabricante.id_fabricante +
                          "-" +
                          mat_fabricante.descripcion_fabricante
                        }
                        onChange={(event, value) =>
                          setMatriculaSeleccionada(value)
                        }
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            key={option.id}
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.id_fabricante} -{" "}
                            {option.descripcion_fabricante}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            inputRef={autocomplete3Ref}
                            onFocus={() => handleFocusChange("autocomplete3")}
                            focused
                            size="small"
                            placeholder="Tipee para buscar..."
                            {...params}
                            label="Matrícula Fabricante"
                            inputProps={{
                              ...params.inputProps,
                            }}
                          />
                        )}
                      />
                    </>
                  )}
                </Grid>
              )}

              {checkSwitchRebalanceo ? (
                <>
                  <Container maxWidth="xl">
                    <Grid container mb={1} spacing={1}>
                      <Grid item xs={6}>
                        <TextField
                          inputRef={textfield5Ref}
                          onFocus={() => handleFocusChange("textfield5")}
                          label="Núm. de Chapa (Rectangular)"
                          name="nro_placa_rect"
                          size="small"
                          placeholder="-"
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          inputProps={{
                            maxLength: 6,
                          }}
                          focused
                          required
                          fullWidth
                          value={datosRomaneoEnvases.nro_placa_rect}
                          onChange={almacenar}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        {checkSwitchPlaca ? (
                          <TextField
                            inputRef={textfield6Ref}
                            onFocus={() => handleFocusChange("textfield6")}
                            label="Núm. de Chapa (Circular)"
                            name="nro_placa"
                            size="small"
                            placeholder="-"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            inputProps={{
                              maxLength: 6,
                            }}
                            focused
                            required
                            fullWidth
                            value={datosRomaneoEnvases.nro_placa}
                            onChange={almacenar}
                          />
                        ) : (
                          <TextField
                            label="Núm. de Placa"
                            name="nro_placa"
                            size="small"
                            inputProps={{
                              maxLength: 6,
                            }}
                            fullWidth
                            disabled
                            value={(datosRomaneoEnvases.nro_placa = "")}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Container>
                </>
              ) : (
                <>
                  <Grid xs={6} mb={2} mt={1}>
                    <Box style={boxStyles} marginLeft={1}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        style={{ marginLeft: "35px" }}
                      >
                        {checkSwitchPlaca ? (
                          <Typography
                            style={{ marginLeft: "-8px", marginTop: "-10px" }}
                          >
                            Relieve
                          </Typography>
                        ) : (
                          <Typography
                            style={{ marginLeft: "-8px", marginTop: "-10px" }}
                          >
                            <b>Relieve</b>
                          </Typography>
                        )}
                        <FormControlLabel
                          inputRef={switch2Ref}
                          onFocus={() => handleFocusChange("switchRef2")}
                          checked={checkSwitchPlaca}
                          onChange={handleOnChangeSwitchPlaca}
                          control={<Switch />}
                          // style={{ width: "23px", height: "23px" }}
                          style={{
                            height: "23px",
                            width: "23px",
                            marginTop: "-10px",

                            marginLeft: "-4px",
                          }}
                        />
                        {checkSwitchPlaca ? (
                          <Typography
                            style={{ marginLeft: "12px", marginTop: "-10px" }}
                          >
                            <b>Placa</b>
                          </Typography>
                        ) : (
                          <Typography
                            style={{ marginLeft: "12px", marginTop: "-10px" }}
                          >
                            Placa
                          </Typography>
                        )}{" "}
                      </Stack>
                    </Box>
                  </Grid>

                  <Grid item xs={6} mb={2}>
                    {checkSwitchPlaca ? (
                      <TextField
                        inputRef={textfield2Ref}
                        onFocus={() => handleFocusChange("textfield2")}
                        label="Núm. de Placa"
                        name="nro_placa"
                        size="small"
                        placeholder="-"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        inputProps={{
                          maxLength: 6,
                        }}
                        focused
                        required
                        fullWidth
                        value={datosRomaneoEnvases.nro_placa}
                        onChange={almacenar}
                      />
                    ) : (
                      <TextField
                        label="Núm. de Placa"
                        name="nro_placa"
                        size="small"
                        inputProps={{
                          maxLength: 6,
                        }}
                        fullWidth
                        disabled
                        value={(datosRomaneoEnvases.nro_placa = "")}
                      />
                    )}
                  </Grid>
                </>
              )}

              <Grid item xs={6} mt={1}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <FormControlLabel
                    inputRef={checkbox1Ref}
                    onFocus={() => handleFocusChange("checkbox1")}
                    checked={checkSwitchCasquete}
                    onChange={handleOnChangeSwitchCasquete}
                    control={<Checkbox />}
                    style={{ width: "23px", height: "23px" }}
                  />
                  <Typography style={{ marginLeft: "15px" }}>
                    Cambio de Casquete
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={2} mb={2}>
                <TextField
                  label="Lote"
                  name="lote"
                  size="small"
                  placeholder="001"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{
                    maxLength: 3,
                  }}
                  focused
                  required
                  fullWidth
                  value={datosRomaneoEnvases.lote}
                  onChange={almacenar}
                />
              </Grid>

              <Grid item xs={4} mb={2}>
                <TextField
                  inputRef={textfield3Ref}
                  onFocus={() => handleFocusChange("textfield3")}
                  label="Fecha de Fabricación"
                  name="fecha_fabricacion"
                  size="small"
                  placeholder="0816"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{
                    maxLength: 4,
                  }}
                  focused
                  required
                  fullWidth
                  value={datosRomaneoEnvases.fecha_fabricacion}
                  onChange={almacenar}
                />
              </Grid>

              <Grid item xs={6} mb={2}>
                <TextField
                  fullWidth={true}
                  style={style}
                  focused
                  required
                  size="small"
                  type="date"
                  name="fecha_reparacion"
                  label="Fecha Reparacion"
                  variant="outlined"
                  value={moment(datosRomaneoEnvases.fecha_reparacion).format(
                    "YYYY-MM-DD"
                  )}
                  onChange={almacenar}
                />
              </Grid>

              <Grid item xs={6} mb={2}>
                {checkSwitchRebalanceo ? (
                  <TextField
                    label="Estado"
                    name="id_estado"
                    size="small"
                    placeholder="-"
                    disabled
                    fullWidth
                    value={(datosRomaneoEnvases.id_estado = null)}
                    onChange={almacenar}
                  />
                ) : (
                  <FormControl style={style} focused>
                    <InputLabel style={{ color: "gray" }}>Estado</InputLabel>
                    <Select
                      label="Estado"
                      required
                      disabled
                      size="small"
                      name="id_estado"
                      value={(datosRomaneoEnvases.id_estado = 151)}
                      onChange={handleParaHabilitar}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {estadoEnvase
                        ? estadoEnvase.map((elemento) => (
                            <MenuItem key={elemento.id} value={elemento.id}>
                              {elemento.valor}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                )}
              </Grid>
            </Grid>

            <Stack direction="row">
              <Grid container direction={{ xs: "column", sm: "row" }}>
                <Grid item xs={9}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={props.handleCloseCrear45}
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    ref={buttonRef}
                    onFocus={() => handleFocusChange("buttonRef")}
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={disBtn}
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </Stack>
          </form>
        </Container>
      </DialogContent>

      {/* MENSAJE AL GUARDAR */}
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>

      {modalRepetido ? (
        <Romaneo_repetido
          alertaRepetido={repeticion}
          open={true}
          modalRepetido={setModalRepetido}
        />
      ) : null}
    </>
  );
}

export default Romaneo_M_envases_carga_45;
