//----- Component --------------------------------------------------------/

// Developer : Nicolas Pascucci

// Creation Date : 23/11/2023

// Version : 1

// Description : pantalla para autorizaciones de articulos compras
//

//------Changes --------------------------------------------------------------------- -------------//

// Change Date :

// Change by :

// Change description:

//

// Change Date :

// Change by :

// Change description:

//
//========================================================================/
import React, { useState, useEffect, useMemo } from "react";
import { UrlApi } from "../../services/apirest";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import {
  Button,
  Container,
  Box,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import { logEventos } from "../../services/services";

//ICONOS
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import Dialog_autorizar_Art from "./Dialog_autorizar_Art";
import Navbar from "../Navbar";
import AttachFileIcon from "@mui/icons-material/AttachFile"; //ADJUNTAR IMAGEN
import FileUploadStock from "./FileUploadStock"; //ADJUNTAR IMAGEN

function Articulos_autorizados() {
  const [articulosPen, setArticulosPen] = useState();
  const [abrirConfirmacion, setAbrirConfirmacion] = useState(false);
  const [articuloPenRow, setArticuloPenRow] = useState();
  const [tabla, setTabla] = useState(true);
  const [update, setUpdate] = useState(false);
  const [newUuid, setNewUuid] = useState(""); //ADJUNTAR IMAGEN
  const [openCloseAdj, setOpenCloseAdj] = useState(false); //ADJUNTAR IMAGEN
  useEffect(() => {
    logEventos("Acceso al menú stock autorizar artículos", "Stock Autorizar Artículos", "Autorizar o rechazar nuevos artículos", localStorage.getItem("user"))
}, [])

  const obtenerArticulosPen = () => {
    fetch(UrlApi + "/stock_articulos/paraautorizar")
      .then((data) => data.json())
      .then((data) => setArticulosPen(data));
  };

 // console.log("trae los articulos", articulosPen);

  //funcion que espera los cambios de la funcion de obtener a los articulos
  useEffect(() => {
    obtenerArticulosPen();
  }, [tabla]);

  const handleOpenConfir = (value) => {
    setAbrirConfirmacion(value);
    setUpdate(true);
  };

  useEffect(() => {
    if (update) {
      obtenerArticulosPen();
      setUpdate(false);
    }
  }, [update]);

  const handleOpenCloseAdj = (value) => {
    setOpenCloseAdj(value);
  };

 // console.log("ESTADO ABRIR OCNF", abrirConfirmacion);

  const columns = useMemo(
    () => [
      { header: "Familia", accessorKey: "descripcion_familia", size: 60 },
      // { header: "Código", accessorKey: "codigo", size: 60 },
      { header: "Nombre", accessorKey: "nombre", size: 100 },
      { header: "Descripción", accessorKey: "descripcion", size: 130 },
      { header: "Medida", accessorKey: "descripcion_unidad", size: 60 },
      { header: "Stock Mínimo", accessorKey: "valor_minimo", size: 80 },
      { header: "Pto. Pedido", accessorKey: "alerta_minima", size: 80 },
      {
        header: "Estado",
        accessorKey: "estado",
        size: 100,
        enableHiding: true,
      },
      { header: "Estado", accessorKey: "estado_palabra", size: 80 },
      {
        header: "uuid",
        accessorKey: "uuid_articulo",
        size: 70,
        enableHiding: true,
      },
    ],
    []
  );

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "success",
    size: "small",
  };

  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)" //background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //override to make tooltip font size larger
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const initialState = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
    columnVisibility: {
      id_articulo: false,
      uuid_articulo: false,
      estado: false,
    },
  };

  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };

  return (
    <>
      <Navbar />
      <Container maxWidth="xl" mt={1} mb={2}>
        {articulosPen && tabla ? (
          <Box mt={2} boxShadow={3}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableColumnResizing
                enableExpandAll={false}
                muiTableHeadCellProps={tableHeadCellProps}
                enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
                enableGrouping // para permitir agrupar x estado x ej
                layoutMode="grid"
                muiTableBodyCellProps={{
                  align: "left", //el contenido de la tabla
                }}
                enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
                enableColumnFilters={false} //desactiva filtros x  columna
                enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
                enableDensityToggle={false} //
                localization={MRT_Localization_ES} //idioma
                enableColumnDragging={false} //para arrastrar columnas
                initialState={initialState}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                columns={columns}
                data={articulosPen} //con lo q la completas (esto cambia siempre)
                enableStickyFooter
                enableEditing
                positionActionsColumn="last" // posicion de la columna de acciones al final
                // ACCIONES
                renderTopToolbarCustomActions={() => (
                  <>
                    <Typography variant="poster" fontSize={26}>
                      Autorización de Carga de Artículos
                    </Typography>
                  </>
                )}
                renderToolbarInternalActions={(
                  { table } // boton para nuevo , al lado del buscar
                ) => (
                  <>
                    <MRT_FullScreenToggleButton table={table} />
                  </>
                )}
                renderRowActions={({ row, table }) => (
                  //las acciones del costado editar y ver
                  <div>
                    <Tooltip arrow placement="top" title="Autorizar artículo">
                      <IconButton
                        onClick={() => {
                          setAbrirConfirmacion(!abrirConfirmacion);
                          setArticuloPenRow(row.original);
                        }}
                        color="primary"
                      >
                        <TaskAltIcon />
                      </IconButton>
                    </Tooltip>
                    {/* ADJUNTAR ARCHIVOS */}
                    <Tooltip arrow placement="bottom" title="Ver adjunto">
                      <Button
                        backgroundColor="#ffffff"
                        size="small"
                        onClick={() => {
                          setNewUuid(row.original.uuid_articulo);
                          setOpenCloseAdj(!openCloseAdj);
                        }}
                      >
                        <AttachFileIcon />
                      </Button>
                    </Tooltip>
                  </div>
                )}
              />
            </ThemeProvider>
            <br />
          </Box>
        ) : null}
      </Container>
      {abrirConfirmacion ? (
        <Dialog_autorizar_Art
          row={articuloPenRow}
          abrirConfirmacion={abrirConfirmacion}
          onClose={() => handleOpenConfir(false)}
        />
      ) : null}
      {openCloseAdj ? ( // ADJUNTAR IMAGEN
        <FileUploadStock
          fileId={newUuid}
          openclose={true}
          mostrar={false}
          onClose={() => handleOpenCloseAdj(false)} // Proporciona una función de cierre
        />
      ) : null}
    </>
  );
}

export default Articulos_autorizados;
