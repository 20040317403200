import React, { useRef } from "react";
import { useEffect, useState } from "react";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Autocomplete,
  createFilterOptions,
  Tooltip,
  IconButton,
  styled,
  FormHelperText,
  Grid,
  Stack,
  Snackbar,
  Alert,
} from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Notify from "../../Notify";
import {
  Delete,
  AddCircleOutline,
  DisabledByDefaultRounded,
} from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import { getData, postData, putData } from "../../../services/library";
import { UrlApi } from "../../../services/apirest";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { useStaticPicker } from "@mui/x-date-pickers/internals";
import { enviarmails } from "../../../services/services";

function BajarStockVehiculo(props) {
  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [openSnackEditar, setOpenSnackEditar] = React.useState(false);
  const [disabledGuardarTr, setDisabledGuardarTr] = useState(false);
  const [colorTextField, setcolorTextField] = useState();
  const [articulosEspecificos, setArticulosEspecificos] = useState();
  const [articuloEspecificoSeleccionado, setArticuloEspecificoSeleccionado] =
    useState("");
  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };
  const [notificacion, setNotificacion] = useState({
    open: false,
    mensaje: "",
    severity: "",
    color: "",
  });
  const [datos, setDatos] = useState({
    vehiculo: null,
    articulo: null,
    cantidad: 1,
    fecha: moment(new Date()).format("YYYY-MM-DD"),
  });
  const [deshabilitado, setDeshabilitado] = useState(false);
  const [cantidadNueva, setCantidadNueva] = useState(0);

  useEffect(() => {
    setCantidadNueva(parseInt(props.row.cantidad) - parseInt(datos.cantidad));
  }, [datos.cantidad]);

  useEffect(() => {
    getData(
      "/stock_seguimiento/detalle/" +
        props.row.articulo +
        "/" +
        props.row.vehiculo,
      setArticulosEspecificos
    );
  }, []);
  // console.log("articulosespecificos", articulosEspecificos, articuloEspecificoSeleccionado);

  // console.log("cantidad nueva", cantidadNueva);

  const guardarBaja = async (e) => {
    e.preventDefault();
    await fetch(UrlApi + "/stock_vehiculo_historial", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        vehiculo: props.row.vehiculo,
        articulo: props.row.articulo,
        cantidad: datos.cantidad,
        tipo: 2,
        fecha: datos.fecha,
        usuario: localStorage.getItem("user"),
        // almacen_origen: almacenSeleccionado.id_almacen,
        identificador: articuloEspecificoSeleccionado.identificador,
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("Se quitó stock exitosamente !");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el stock");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });

    // ------ { AGREGAR SEGUNDO GUARDAR (?) } ------
    e.preventDefault();
    await fetch(UrlApi + "/stock_vehiculos/" + props.row.id, {
      // PUT SI YA EXISTE / POST SI ES NUEVO
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        cantidad: cantidadNueva,
        usuario: localStorage.getItem("user"),
      }),
    })
      .then((res) => {
        if (!res.err) {
          // setOpenSnack(true);
          // setColorMensaje("#D4EFDF");
          // setMensaje("El artículo se asignó exitosamente !");
        } else {
          // setOpenSnack(false);
          // setColorMensaje("#F1948A");
          // setMensaje("Error al asignar el artículo");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });

    //**************************** SEGUIMIENTO DE ARTICULO ************************//
    if (datos.cantidad === 1 || datos.cantidad === "1") {
      if (Object.keys(articuloEspecificoSeleccionado).length === 0) {
        console.log("nada");
      } else {
        putData(e, "/stock_seguimiento/" + articuloEspecificoSeleccionado.id, {
          baja: 1,
        });
      }
    } else {
      console.log("no hago nada");
    }
  };

  const almacenar = (e) => {
    setDatos({
      ...datos,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (isNaN(parseInt(datos.cantidad)) || cantidadNueva < 0) {
      setDeshabilitado(true);
    } else {
      setDeshabilitado(false);
    }
  }, [cantidadNueva, datos.cantidad]);

  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
    props.onClose(false);
  };

  return (
    <>
      <Dialog
        fullWidth
        open={props.open}
        onClose={() => props.onClose(false)}
        maxWidth="md"
      >
        {notificacion && notificacion.open ? (
          <Notify
            mensaje={notificacion.mensaje}
            open={notificacion.open}
            color={notificacion.color}
            // handleClose={() => {
            //   props.onClose(false);
            //   // props.actualizar();
            // }}
            severity={notificacion.severity}
          />
        ) : null}
        <Box justifyContent="center" backgroundColor="#1F618D">
          <DialogTitle>
            <Typography fontSize={20} align="center" color="#ffffff">
              Quitar {props.row.nombre} a {props.patente}
            </Typography>
          </DialogTitle>
        </Box>
        <form>
          <DialogContent>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={datos.cantidad === 1 || datos.cantidad === "1" ? 2 : 4.5}
                >
                  <TextField
                    label="Existencia"
                    name="existencia"
                    size="small"
                    type="number"
                    focused
                    required
                    fullWidth
                    autoComplete="off"
                    value={props.row.cantidad}
                    // onChange={almacenar}
                    disabled
                    //   error={!!erroresStock[index]}  // Cambia a rojo si hay error
                  />
                </Grid>
                <Grid
                  item
                  xs={datos.cantidad === 1 || datos.cantidad === "1" ? 3 : 4.5}
                >
                  <TextField
                    label="Cantidad Quitada"
                    name="cantidad"
                    size="small"
                    type="number"
                    focused
                    required
                    fullWidth
                    autoComplete="off"
                    value={datos.cantidad}
                    onChange={almacenar}
                    error={cantidadNueva < 0 ? true : false} // Cambia a rojo si hay error
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Fecha"
                    name="fecha"
                    size="small"
                    focused
                    type="date"
                    required
                    fullWidth
                    autoComplete="off"
                    value={datos.fecha}
                    onChange={almacenar}
                    //   error={!!erroresStock[index]}
                  />
                </Grid>
                {datos.cantidad === 1 || datos.cantidad === "1" ? (
                  <Grid item xs={4}>
                    {/* {openSnack ? (
                    <TextField
                      disabled
                      focused
                      fullWidth
                      size="small"
                      label="Guardando..."
                    />
                  ) : ( */}
                    <Autocomplete
                      disabled={!articulosEspecificos}
                      disableClearable
                      size="small"
                      required
                      noOptionsText={"Identificador INEXISTENTE"}
                      options={articulosEspecificos}
                      autoHighlight
                      getOptionLabel={(articulo) => articulo.identificador}
                      //   value={getProductoById(linea.producto)}
                      onChange={(event, value) => {
                        setArticuloEspecificoSeleccionado(value);
                        // setBuscandoSucursales(true)
                        // setBuscandoAlmacen(true)
                      }}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          key={option.id}
                          sx={{
                            "& > img": { mr: 2, flexShrink: 0 },
                          }}
                          {...props}
                        >
                          {option.identificador}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                          }}
                          focused
                          size="small"
                          placeholder="Tipee para buscar..."
                          label="Identificador"
                          // error={!!erroresStock[index]}
                        />
                      )}
                    />
                    {/* )} */}
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              mt={3}
            >
              <Grid item>
                <Button
                  color="error"
                  variant="outlined"
                  onClick={() => props.onClose(false)}
                  style={{ backgroundColor: "#fff" }}
                >
                  Volver
                </Button>
              </Grid>
              <Grid item>
                <Button
                  // type="submit"
                  //   disabled={disabled}
                  color="success"
                  variant="contained"
                  disabled={deshabilitado}
                  // endIcon={<SaveIcon />}
                  onClick={guardarBaja}
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </form>
      </Dialog>
      {/* GUARDAR AL CREAR */}
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}

export default BajarStockVehiculo;
