//----- Component --------------------------------------------------------/

// Developer : Nicolas Pascucci

// Creation Date : 21/05/2024

// Version : 1

// Description : tabla donde se visualizan los objetivos de los comerciales
//

//------Changes --------------------------------------------------------------------- -------------//

// Change Date :

// Change by :

// Change description:

//

// Change Date :

// Change by :

// Change description:

//

//========================================================================/
import React, { useState, useEffect, useMemo } from "react";
import { UrlApi } from "../../../services/apirest";
import { IconButton, TextField, Tooltip, createTheme, useTheme } from "@mui/material";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import { Box, Typography, Grid } from "@mui/material";
import { ThemeProvider } from "@mui/material";
// Boton desplegable
import Button from "@mui/material/Button";
import Navbar from "../../Navbar";
import EditIcon from "@mui/icons-material/Edit";
import "dayjs/locale/es";
import { logEventos } from "../../../services/services";
import AgregaMonto from "./AgregaMonto";
import PaidIcon from '@mui/icons-material/Paid';

function Montos() {
    const [comerciales, setComerciales] = useState()
    const [objetivos, setObjetivos] = useState({})
    const [selectedDate, setSelectedDate] = useState('');
    const [agregarObjetivo, setAgregarObjetivo] = useState(false)
    const [editar, setEditar] = useState(false)
    const [datosRow, setDatosRow] = useState()

    const obtenerComerciales = () => {
      fetch(UrlApi + "/representantes/activos")
        .then((data) => data.json())
        .then((data) => setComerciales(data));
    };
      
    useEffect(() => {
      obtenerComerciales();
    }, []);
    // console.log("comerciales",comerciales);
    const obtenerObjetivos = () => {
      fetch(UrlApi + "/objetivos_comerciales/mes/"+formattedDate)
        .then((data) => data.json())
        .then((data) => setObjetivos(data));
    };
        
    useEffect(() => {
      obtenerObjetivos();
    }, [selectedDate]);
    // console.log("datos", objetivos);

    const handleDateChange = (event) => {
      setSelectedDate(event.target.value);
    };
    const formattedDate = `${selectedDate}-01`
    // console.log('Mes seleccionado:', formattedDate);

    const handleEditarObjetivo = (row) => {
      setEditar(true)
      setDatosRow(row)
    };

    const handleCloseEditarObjetivo = () => {
      setEditar(false)
      obtenerObjetivos()
    };
// *********************************************************** Tabla ********************************************************************** //
const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "primary",
    size: "small",
  };
  
  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };

  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem",
              },
            },
          },
        },
      }),
    [globalTheme]);

  const initialState = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
  };

  const columns = useMemo(
    // TABLA Objetivos
    () => [
      { header: "Comercial", accessorKey: "nombre_apellido", size: 110 },
      { header: "Sucursal", accessorKey: "nombre_sucursal", size: 110 },
      {
        header: "Objetivo",
        accessorKey: "objetivo",
        size: 100,
      },
      {
        header: "Usuarios",
        accessorKey: "usuarios_butano",
        size: 100,
      },
      {
        header: "Comercios",
        accessorKey: "comercios_butano",
        size: 100,
      },
      {
        header: "Distribuidores",
        accessorKey: "distribuidores_butano",
        size: 100,
      },
      {
        header: "Monto en $",
        accessorKey: "monto_pesos",
        size: 100,
      },
      {
        header: "Mes",
        accessorKey: "mes",
        size: 100,
        enableHiding: true,
      },
    ],
    []
  );

  useEffect(() => {
    logEventos("Tabla de Montos a pagar de comerciales", "Evaluacion Comerciales", "Se visualizan los objetivos y montos a pagar de comerciales", localStorage.getItem("user"))
}, [])

  return (
<div style={{marginTop:20}}>
    <Navbar/>
    <div style={{ textAlign: 'center' }}>
    </div>
      <Box mt={2} mb={4}>
        <ThemeProvider theme={tableTheme}>
          <MaterialReactTable
            enableExpandAll={false}
            enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
            enableGrouping // para permitir agrupar x estado x ej
            layoutMode="grid"
            muiTableHeadCellProps={tableHeadCellProps}
            muiTableBodyCellProps={({ row }) => ({
              align: "left",
              sx: {
                color: "black",
                borderColor: "black",
              },
            })}
            enableEditing
            enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
            enableColumnFilters={false} //desactiva filtros x  columna
            enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
            enableDensityToggle={false} //
            localization={MRT_Localization_ES} //idioma
            enableColumnDragging={false} //para arrastrar columnas
            initialState={initialState}
            muiSearchTextFieldProps={muiSearchTextFieldProps}
            columns={columns}
            data={objetivos} //con lo q la completas (esto cambia siempre)
            enableStickyFooter
            // enableEditing
            positionActionsColumn="last" // posicion de la columna de acciones al final
            // ACCIONES
            renderToolbarInternalActions={(
              { table } // boton para nuevo , al lado del buscar
            ) => (
              <>
                <MRT_FullScreenToggleButton table={table} />
              </>
            )}
            renderTopToolbarCustomActions={() => (
              <>
                <Typography variant="poster" fontSize={26}>
                  Objetivos y Montos de Comerciales
                </Typography>
                <TextField
                  focused
                  size="small"
                  label="Período"
                  type="month"
                  value={selectedDate}
                  onChange={handleDateChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </>
            )}
            renderRowActions={({ row, table }) => (
              //las acciones del costado editar y ver
                <Tooltip arrow placement="top" title="Agregar Monto">
                  <IconButton
                    onClick={() =>
                      handleEditarObjetivo(row.original)
                    }
                    color="primary"
                  >
                    <PaidIcon />
                  </IconButton>
                </Tooltip>
            )}
          />
        </ThemeProvider>
      </Box>

      {editar?
      <AgregaMonto
        editar={editar}
        datosRow={datosRow}
        onClose={() => handleCloseEditarObjetivo(false)}
      />
      :null}
    </div>
)
}

export default Montos