import React, { useEffect, useState } from 'react';
import { UrlApi } from '../../../services/apirest';
import { Switch, FormGroup, FormControlLabel, IconButton, Grid, Box, Select, Typography, FormControl, TextField, MenuItem, InputLabel, Container, Stack, Button, Tooltip } from '@mui/material';
import moment from 'moment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { grey } from '@mui/material/colors';
import Notify from "../../Notify";

function Editarcertificaciones(props) {
  const [datosFormulario, setDatosFormulario] = useState(props.datos);
  const [certificadora, setCertificadora] = useState(null);
  const [tanques, setTanque] = useState(null);
  const [disBtn, setDisBtn] = useState(false);
  const [ficticia, setFicticia] = useState(datosFormulario.ficticia);
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
  const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)  

  useEffect(() => {
    if (datosFormulario.ficticia === 1) {
      setFicticia(true)
      console.log("ficticia true")
    } else {
      setFicticia(false)
      console.log("ficticia false")
    }
  }, [datosFormulario.ficticia])

  const handleFicticia = (e) => {
    setFicticia(e.target.checked);
  };

  //Variable para guardar el usuario del localStorage
  let usuario = localStorage.getItem('user')

  // ir a certificadoras 
  const handleFormCertificadoras = () => {
    window.location.hash = "/certificadoras"
  };

  //ir a tanques
  const handleFormTanques = () => {
    window.location.hash = "/tanques"
  };

  //Consulta a la API para traer a todas las certificadoras
  const obtenerCertificadoras = () => {
    fetch(UrlApi + '/certificadoras')
      .then(data => data.json())
      .then(data => setCertificadora(data))
  };

  //Consulta a la API para traer a todos los tanques
  const obtenerTanques = () => {
    fetch(UrlApi + '/tanques')
      .then(data => data.json())
      .then(data => setTanque(data))
  };

  useEffect(() => {
    obtenerCertificadoras()
    obtenerTanques()
  }, []);

  const handleClose = () => {
    return (
      props.tabla(true),
      props.editar(false))
  };

  //Funcion para guardar
  const handleguardarCertificaciones = async (e) => {
    setDisBtn(true)
    e.preventDefault();
    const response = await fetch(UrlApi + "/certificaciones/" + datosFormulario.id, {
      method: 'PUT', headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        fecha_certificacion: datosFormulario.fecha_certificacion,
        vencimiento_certificacion: datosFormulario.vencimiento_certificacion,
        id_certificadora: datosFormulario.id_certificadora,
        id_tanque: datosFormulario.id_tanque,
        usuario: localStorage.getItem('user'),
        ficticia: ficticia ? 1 : 0
      })
    }
    )//response&&console.log(response)
    if (response.ok === false) {
      const error = response && response.json();
      setError(error);
      alert("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      try {
        const json = await response.json();
        if (json.message) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje(
            json.message === "OK" ? "Guardado" : "Guardado. Actualizar RTA Api."
          );
          props.actualizar()
        } else if (json.sqlMessage) {
          json && setError(json);
          alert("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
        } else {
          alert("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje("Guardado");
          props.actualizar()
        } else {
          alert("Error: " + error);
        }
      }
    }
    handleClose()
  };

  //value
  const almacenar = (e) => {
    setDatosFormulario({ ...datosFormulario, usuario, [e.target.name]: e.target.value })
  };

  const style = {
    backgroundColor: "#ffffff",
    width: "100%"
  };

  return (
    <div>
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container backgroundColor="#1F618D" p={1.5}>
            <Grid item xs={2}>
              <IconButton onClick={handleClose}>
                <ArrowBackIcon sx={{ color: grey[50] }} />
              </IconButton>
            </Grid>
            <Grid
              item
              xs={8}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h5" color="#ffffff">
                Editar certificación
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <form onSubmit={handleguardarCertificaciones} autoComplete='off' >
          <Box
            backgroundColor="#F4F6F6"
            mt={2}
            p={3}
            border={0.5}
            borderColor={"#ABB2B9"}
            borderRadius={4}
          >
            <Grid container spacing={1} direction={{ xs: 'column', sm: 'row' }}>
              <Grid item xs={5.5}>
                <FormControl style={style} margin="dense" required focused color="primary">
                  <InputLabel id="lbl_certificadora">Certificadora</InputLabel>
                  <Select
                    name='id_certificadora'
                    value={datosFormulario.id_certificadora}
                    onChange={almacenar}
                    label="Certificadora">
                    {certificadora ? certificadora.map(elemento => (
                      <MenuItem key={elemento.id} value={elemento.id}>
                        {elemento.nombre}
                      </MenuItem>
                    )) : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={0.5}>
                <Tooltip title="Agregar Certificadora"><IconButton tooltip="Nueva Certificacion" edge='start' color="primary" size="small" onClick={handleFormCertificadoras} margin="dense">
                  <AddBoxIcon />
                </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={5.5}>
                <FormControl style={style} focused color="primary" required margin="dense">
                  <InputLabel id="lbl_tanque">Tanque</InputLabel>
                  <Select
                    name='id_tanque'
                    value={datosFormulario.id_tanque}
                    onChange={almacenar}
                    label="Tanque ">
                    {tanques ? tanques.map(elemento => (
                      <MenuItem key={elemento.id} value={elemento.id}>
                        {elemento.nro_serie}
                      </MenuItem>
                    )) : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={0.5}>
                <Tooltip title="Agregar Tanque">
                  <IconButton color="primary" size="small" onClick={handleFormTanques}>
                    <AddBoxIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <TextField label="Fecha certificación"
                  required
                  margin="dense"
                  focused
                  color="primary"
                  style={style}
                  name='fecha_certificacion'
                  type="date"
                  value={moment(datosFormulario.fecha_certificacion).format("YYYY-MM-DD")}
                  onChange={almacenar}
                  /* inputProps={{
                    min: moment(new Date()).format("YYYY-MM-DD")
                  }} */ />
              </Grid>

              <Grid item xs={6}>
                <TextField label="Vencimiento certificacion"
                  style={style}
                  margin="dense"
                  color="primary"
                  focused
                  required
                  name='vencimiento_certificacion'
                  type="date"
                  variant="outlined"
                  value={moment(datosFormulario.vencimiento_certificacion).format("YYYY-MM-DD")}
                  onChange={almacenar}
                  /* inputProps={{
                    min: moment(new Date()).format("YYYY-MM-DD")
                  }} */ />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl component="fieldset" variant="standard">

                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch checked={ficticia} onChange={handleFicticia} />
                      }
                      label="Certificacion Ficticia"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Grid mt={2} container direction="row" justifyContent="space-between" alignItemns="center">
              <Button variant="contained"
                onClick={handleClose}
                color="error">
                Volver
              </Button>
              <Button disabled={disBtn}
                variant="contained"
                type="submit"
                color="success">
                Guardar Certificacion
              </Button>
            </Grid>
          </Box>
        </form>
      </Container>
      {
        notificacion ? (
          <Notify
            mensaje={mensaje}
            open={openSnack}
            color={"#D4EFDF"}
            severity={"success"}
            handleClose={handleClose}
          />
        ) : null}
    </div>
  )
}

export default Editarcertificaciones