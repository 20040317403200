import Navbar from "../Navbar";
import React from "react";
import { Box, Grid } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { makeStyles } from "@material-ui/core";

// SWITCH
import { styled } from "@mui/material/styles";

// ICONOS
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import SettingsIcon from "@mui/icons-material/Settings";

import Romaneo_administrar_parahabilitar from "./Romaneo_administrar_parahabilitar";
//sacarlo , solo es una prueba
import Romaneo_lista_parahabilitar from "./Romaneo_lista_parahabilitar";

function Romaneo_envases_parahabilitar() {
  //MANEJA TAB
  const [opcion, setOpcion] = React.useState("para habilitar");

  const handleChange = (event, newValue) => {
    setOpcion(newValue);
  };

  const useStyles = makeStyles({
    customLabelColor: {
      color: "#fff",
    },
  });

  const classes = useStyles();

  const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  ))({
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
    },
    "& .MuiTabs-indicatorSpan": {
      maxWidth: "100%",
      width: "100%",
      backgroundColor: "#1A2C73",
    },
  });

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),
      color: "rgba(255, 255, 255, 0.7)",
      "&.Mui-selected": {
        color: "#fff",
      },
      "&.Mui-focusVisible": {
        backgroundColor: "rgba(100, 95, 228, 0.32)",
      },
    })
  );

  return (
    <div>
      <Navbar />
      <Box sx={{ bgcolor: "#35A4FB", width: "100%", marginTop: "14px" }}>
        <StyledTabs
          value={opcion}
          onChange={handleChange}
          textColor={"secondary"}
          indicatorColor="secondary"
          aria-label="Envases"
          variant="fullWidth"
        >
          <StyledTab
            icon={<FormatListBulletedIcon />}
            iconPosition="end"
            value="lista para habilitar"
            label="Lista"
            classes={{
              textColorPrimary: classes.customLabelColor,
            }}
          />
          <StyledTab
            icon={<SettingsIcon />}
            iconPosition="end"
            value="administracion"
            label="Administrar"
            classes={{
              textColorPrimary: classes.customLabelColor,
            }}
          />
        </StyledTabs>
      </Box>

      {opcion === "lista para habilitar" ? (
        <Grid mt={-7}>
          <Romaneo_lista_parahabilitar />
        </Grid>
      ) : null}

      {opcion === "administracion" ? (
        <Grid mt={-7}>
          <Romaneo_administrar_parahabilitar />
        </Grid>
      ) : null}
    </div>
  );
}

export default Romaneo_envases_parahabilitar;
