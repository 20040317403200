import React, { useEffect, useState, useMemo } from 'react';
import { UrlApi } from '../../../services/apirest';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { MenuItem, Typography, Box, ListItemIcon, Divider, createTheme, ThemeProvider, useTheme, Container, Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import FileUploadMultiple from '../../../services/UploadFiles';
import { Edit, Assignment, Preview } from '@mui/icons-material';
import EditarOrden from './EditarOrden';
import Openpdf from '../OpenPdf';
import DatosOrden from './DatosOrden'

export default function OrdenesGeneradas(props) {
    const [ordenes, setOrdenes] = useState(null);
    const globalTheme = useTheme();
    const [openclose, setOpenClose] = useState(false);
    const [datosFormulario, setDatosFormulario] = useState({});
    const [editar, setEditar] = useState(false);
    const [tabla, setTabla] = useState(true);
    const [openpdf, setOpenpdf] = useState(false);
    const [open, setOpen] = useState(false);

    const obtenerOrdenesCompra = () => {
        fetch(UrlApi + '/ordencompra/generadas')
            .then(data => data.json())
            .then(data => setOrdenes(data))
    };

    useEffect(() => {
        obtenerOrdenesCompra()
    }, [tabla]);

    const columns = useMemo(() =>
        [{ header: 'Numero', accessorKey: 'idordencompra', size: 85 },
        { header: 'Proveedor', accessorFn: (ordenes) => ordenes.nombre_fantasia ? ordenes.nombre_fantasia : ordenes.razon_social },
        // { header: 'Total', accessorKey: 'total', size: 120 },
        { header: 'Pago', accessorFn: (ordenes) => ordenes.pago_anticipado === 1 ? 'Anticipado' : "No Anticipado", size: 120 },
        // { header: 'Pago Anticipado', accessorKey: 'pago_anticipado', size: 120 },
        // { header: 'Condicion', accessorKey: 'condicion' },
        { header: 'Entrega', accessorKey: 'fecha_entrega', size: 120, type: "date", format: "YYYYY-MM-DD" },
        { header: 'Estado', accessorKey: 'valor_estado' }
        ], []);

    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: globalTheme.palette.mode,
                    primary: globalTheme.palette.primary, //primary color for the table
                    info: {
                        main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            globalTheme.palette.mode === "light"
                                ? "rgb(234, 239, 241)"
                                : "#000", //pure black table in dark mode for fun
                    },
                },
                typography: {
                    button: {
                        textTransform: "none", //customize typography styles for all buttons in table by default
                        fontSize: "1rem",
                    },
                },
                components: {
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                fontSize: "0.8rem", //larger tooltip font size                                
                            },
                        },
                    },
                },

            }), [globalTheme]);

    const muiSearchTextFieldProps = {
        placeholder: "Buscar..",
        sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
        variant: "outlined",
        color: "success",
        size: "small",
    };

    const tableHeadCellProps = {
        align: "center",
        sx: {
            fontSize: 16,
            color: "white",
            fontWeight: "normal",
            // paddingTop: 1,
            // paddingBottom: 1,
            backgroundColor: "#2471A3",
            borderColor: "black",
        },
    };

    const initialState = {
        density: "compact",
        showGlobalFilter: true,
        pagination: { pageIndex: 0, pageSize: 10 }
    };

    const rowBg = {
        102: "#ec7063",
    };

    const handleEditar = (datosDeLaLinea) => {
        setEditar(true)
        setDatosFormulario(datosDeLaLinea)
        setTabla(false)
        setOpen(false)
        props.tab(false)
    };

    const handleOpenpdf = (datos) => {
        setOpenpdf(true)
        setDatosFormulario(datos)
        setTabla(false)
        setOpen(false)
        props.tab(false)
    };

    const toggleDrawer = (row, newOpen) => () => {
        setOpen(true);
        setDatosFormulario(row)
    };
    return (
        <div>
            <Container maxWidth="xl" mt={1} mb={2}>
                {ordenes && tabla ? (
                    <Box mt={2} boxShadow={3}>
                        <ThemeProvider theme={tableTheme}>
                            <MaterialReactTable
                                enableColumnResizing
                                columns={columns}
                                data={ordenes}
                                enableRowActions
                                initialState={initialState}
                                positionActionsColumn="last"
                                muiTableHeadCellProps={tableHeadCellProps}
                                muiTableBodyCellProps={({ row }) => ({
                                    align: "left",
                                    sx: {
                                        backgroundColor:
                                            rowBg[row.original.id_estado] ?? "#fff",
                                        color: "black",
                                        borderColor: "black",
                                    },
                                })}
                                enableColumnActions={false}
                                enableDensityToggle={false}
                                muiSearchTextFieldProps={muiSearchTextFieldProps}
                                renderTopToolbarCustomActions={() => (
                                    <Typography variant="poster" fontSize={26}>
                                        <Assignment sx={{ size: 19 }} ></Assignment>
                                        Ordenes
                                    </Typography>
                                )}
                                layoutMode="grid"
                                localization={MRT_Localization_ES}
                                enableColumnDragging={false}
                                renderRowActionMenuItems={({ closeMenu, row }) => [
                                    <MenuItem
                                        key={0}
                                        onClick={() => {
                                            handleEditar(row.original)
                                            closeMenu();
                                        }}
                                        sx={{ m: 0 }}>
                                        <ListItemIcon>
                                            <EditIcon color="primary" />
                                        </ListItemIcon>
                                        Editar
                                    </MenuItem>,
                                    <Divider />,
                                    <MenuItem
                                        disabled={row.original.id_estado !== 81}
                                        key={1}
                                        onClick={() => {
                                            // setDatosreq(row.original)
                                            handleOpenpdf(row.original)
                                            closeMenu();
                                        }}
                                        sx={{ m: 0 }}>
                                        <ListItemIcon>
                                            <Preview color="secondary" />
                                        </ListItemIcon>
                                        Visualizar
                                    </MenuItem>,

                                ]}
                                enableColumnFilters={false}
                                enableHiding={false}
                                enableFacetedValues
                                enableMultiRowSelection={false}
                                // enableRowSelection
                                muiToolbarAlertBannerProps={false}
                                // muiSelectCheckboxProps={false}
                                muiTableBodyRowProps={({ row }) => ({
                                    onClick: () => {
                                        row.getToggleSelectedHandler()
                                        toggleDrawer(row.original)()
                                    },
                                    sx: {
                                        cursor: 'pointer'
                                    }
                                })} />
                        </ThemeProvider>
                    </Box>
                ) : null}
                {open ?
                    <Grid key={datosFormulario.idordencompra}>
                        <DatosOrden datos={datosFormulario} anchor={setOpen} />
                    </Grid>
                    : null}
            </Container>
            {openclose ?
                <FileUploadMultiple fileId={ordenes.uuid_ordencompra} openclose={true}
                /> : null}

            {editar ? <EditarOrden datos={datosFormulario} tabla={setTabla} editar={setEditar} tabs={props.tab} ></EditarOrden> : null}

            {openpdf ? 
            <Openpdf 
            datos={datosFormulario} 
            uuid={datosFormulario.uuid_ordencompra}
            open={setOpenpdf} 
            tabla={setTabla} 
            tab={props.tab}/> : null}

        </div>
    )

}