import { useState } from 'react';
import { Grid, Typography, Button, Container, Box, IconButton } from '@mui/material';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import AddCommentIcon from '@mui/icons-material/AddComment';
import Navbar from '../Navbar';




function PanelAdmin() {
    const [roles, setRoles] = useState(localStorage.getItem("role"))

    const handleUsers = () => {
       window.location.hash = "/gestionusuarios" 
    }

    const handleTablas = () => {
        window.location.hash = "/tablas"
    }


    return (
        <div>
            <Navbar />

            <Container>
                <br />
                <Typography variant="h5">Panel administración del sistema</Typography>
                <hr />
                <Box bgcolor="#fafafa" >
                    <Grid container spacing={2} padding={1}>
                    {roles === 'admin' ? 
                        <Grid item xs={3}>
                            <IconButton color="primary" size="small" onClick={handleUsers}>
                                <SupervisedUserCircleIcon />Usuarios
                            </IconButton>
                        </Grid>
                    :null}
                        <Grid item xs={3}>
                            <IconButton color="primary" size="small" onClick={handleTablas}>
                                <AddCommentIcon />Referencias-Tablas
                            </IconButton>
                        </Grid>
                        <Grid item xs={3}>

                        </Grid>
                    </Grid>
                </Box>
            </Container>

        </div>
    )
}

export default PanelAdmin
