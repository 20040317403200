import * as React from "react";
import { useState } from "react";
import { Dialog, DialogActions, Box, Typography, Grid, DialogTitle, Button, DialogContent, DialogContentText } from "@mui/material";
import { UrlApi } from "../../../services/apirest";
import Notify from "../../Notify";

function AlertaCambio(props) {
  const [datosPedidos, setDatosPedidos] = useState(props.datos);
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
  const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)  

  const handleClose = () => {
    props.modalCambio(false);
    props.tabla(true);
  };

  const handleEstado = async (e) => {
    e.preventDefault();
    let response = await fetch(UrlApi + "/pedidos/" + datosPedidos.id_pedido, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_estado: 25,
        usuario: localStorage.getItem("user"),
      }),
    })
    if (response.ok === false) {
      const error = response && response.json();
      setError(error);
      alert("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      try {
        const json = await response.json();
        if (json.message) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje(
            json.message === "OK" ? "Guardado" : "Guardado. Actualizar RTA Api."
          );
          // obtenerchoferes()
        } else if (json.sqlMessage) {
          json && setError(json);
          alert("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
        } else {
          alert("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje("Guardado");
          // obtenerchoferes()
        } else {
          alert("Error: " + error);
        }
      }
    }
    handleClose();
  };

  return (
    <div>
      <Dialog open={props.modalCambio} onClose={handleClose}>
        <Box backgroundColor={"#1F618D"}>
          <DialogTitle>
            <Typography variant="h6" align="center" color="#ffffff">
              Marcar pedido como facturado
            </Typography>
          </DialogTitle>
        </Box>
        <DialogContent>
          <DialogContentText>
            ¿Desea marcar el pedido como facturado?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justifyContent="space-between" alignItemns="center">
            <Button
              variant="contained"
              onClick={handleClose}
              style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
            >
              Cancelar
            </Button>
            <Button
              style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleEstado}
            >
              Confirmar
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
      {
        notificacion ? (
          <Notify
            mensaje={mensaje}
            open={openSnack}
            color={"#D4EFDF"}
            severity={"success"}
            handleClose={handleClose}
          />
        ) : null
      }
    </div>
  );
}

export default AlertaCambio;
