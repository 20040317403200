import React, { useState, useEffect } from 'react'
import { Typography, Grid, MenuItem, Select, TextField, Slide, InputLabel, Container, Stack, Box, Button, FormControl, Tab, Tabs } from '@mui/material'
import { UrlApi } from '../../../services/apirest'
import axios from 'axios'
import FileUploadMultiple from '../../../services/UploadFiles';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { getData } from '../../../services/library';
import moment from 'moment';
function FormEditCondicionImp(props) {
  const { almacenar, proveedor } = props;

  const [iva, setiva] = useState()
  const [openclose, setopenclose] = useState(false)
  const [Motivo, setMotivo] = useState([]);
  const [ImpuestoProv, setImpuestoProv] = useState([])
  const [tipoPersona, settipoPersona] = useState([])
  const [tipoOperacion, settipoOperacion] = useState([])
  const obtenerIva = async () => {
    await axios.get(UrlApi + '/condiciones_iva').then(response => setiva(response.data))
  };


  useEffect(() => {
    getData('/desplegables/impuestoProveedor', setImpuestoProv);
    getData('/desplegables/tipoPersona', settipoPersona)
    getData('/tipo_operacion', settipoOperacion)
    obtenerIva();
  }, []);
  useEffect(() => {
    getData('/desplegables/motivoImpuestos', setMotivo);
  }, [ImpuestoProv]);

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };


  return (
    <>
      <Grid container spacing={2}>

        <Grid item xs={3} >
          <FormControl style={style} focused>
            <InputLabel>I.V.A</InputLabel>
            <Select
              name='id_condicioniva'
              value={proveedor.id_condicioniva}
              onChange={almacenar}
              fullWidth
              required
              label="I.V.A"
            >
              {iva ? iva.map(elemento => (
                <MenuItem key={elemento.id} value={elemento.id}>
                  {elemento.descripcion}
                </MenuItem>
              )) : null}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <FormControl style={style} focused>
            <InputLabel>Impuesto a la Ganacia</InputLabel>
            <Select
              label="Impuesto a la Ganacia"
              required
              name="id_impuestoganancia"
              value={proveedor.id_impuestoganancia}
              onChange={almacenar}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              {ImpuestoProv
                ? ImpuestoProv.map((elemento) => (
                  <MenuItem
                    key={elemento.id}
                    value={elemento.id}
                  >
                    {elemento.valor}
                  </MenuItem>
                ))
                : null}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3} >
          <FormControl style={style} focused>
            <InputLabel>Impuesto al Valor Agregado</InputLabel>
            <Select
              name='id_impuestoalvalor'
              value={proveedor.id_impuestoalvalor}
              onChange={almacenar}
              fullWidth
              required
              label="Impuesto al Valor Agregado"
            >
              {ImpuestoProv ? ImpuestoProv.map(elemento => (
                <MenuItem key={elemento.id} value={elemento.id}>
                  {elemento.valor}
                </MenuItem>
              )) : null}
            </Select>
          </FormControl>
        </Grid>
        {proveedor.id_impuestoalvalor === 206 || proveedor.id_impuestoganancia === 206 ?
          <>

            <Grid item xs={3} >
              <FormControl style={style} focused>
                <InputLabel>Motivo</InputLabel>
                <Select
                  name='id_motivo'
                  value={proveedor.id_motivo}
                  onChange={almacenar}
                  fullWidth
                  required
                  label="Motivo"
                >
                  {Motivo ? Motivo.map(elemento => (
                    <MenuItem key={elemento.id} value={elemento.id}>
                      {elemento.valor}
                    </MenuItem>
                  )) : null}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={2}
            >
              <TextField
                id="fecha Vto"
                label="Vto Certificado"
                fullWidth
                focused
                name='vto_certificado'
                value={moment(proveedor.vto_certificado).format('YYYY-MM-DD')}
                onChange={almacenar}
                type='date'
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </> : null}

        <Grid item xs={2}>
          <Button
            onClick={() => setopenclose(!openclose)}
            variant="outlined"
            color="primary"
            fullWidth
            startIcon={<AttachFileIcon />}>
            Carga de Documentos
          </Button>
        </Grid>

        <Grid item xs={3} >
          <FormControl style={style} focused>
            <InputLabel>Tipo de Operacion</InputLabel>
            <Select
              name='id_tipo_operacion'
              value={proveedor.id_tipo_operacion}
              onChange={almacenar}
              fullWidth
              required
              label="Tipo de Operacion"
            >
              {tipoOperacion ? tipoOperacion.map(elemento => (
                <MenuItem key={elemento.id} value={elemento.id}>
                  {elemento.descripcion}
                </MenuItem>
              )) : null}
            </Select>
          </FormControl>
        </Grid>
        {proveedor.id_tipo_operacion === 5 ? <>
          <Grid item xs={3}>
            <TextField
              focused
              style={style}
              placeholder="Motivo Otro"
              type="text"
              name="motivo_otro"
              label="Motivo Otro"
              onChange={almacenar}
              value={proveedor.motivo_otro}
              inputProps={{ maxLength: 22 }}

            />
          </Grid>
        </> : null}
        <Grid item xs={3} >
          <FormControl style={style} focused>
            <InputLabel>Tipo Persona</InputLabel>
            <Select
              name='id_tipopersona'
              value={proveedor.id_tipopersona}
              onChange={almacenar}
              fullWidth
              required
              label="Tipo Persona"
            >
              {tipoPersona ? tipoPersona.map(elemento => (
                <MenuItem key={elemento.id} value={elemento.id}>
                  {elemento.valor}
                </MenuItem>
              )) : null}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {openclose ? <FileUploadMultiple fileId={proveedor.uuid_proveedor} openclose={true} /> : null}


    </>
  )
}

export default FormEditCondicionImp