import React, { useEffect, useState, useMemo } from 'react';
import { Container, Typography, Tooltip, IconButton, Box, Button, Dialog, DialogContent, DialogContentText, DialogActions, Grid, DialogTitle } from '@mui/material';
import Navbar from '../../Navbar';
import MaterialReactTable, { MRT_FullScreenToggleButton } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import { getData } from '../../../services/library';
import { AccountBalance, CreditCardOff, CheckCircleOutline, Edit, Block, AddBoxTwoTone } from '@mui/icons-material';
import AddBancos_cuentas from './AddBancos_cuentas';
import EditarBancos_cuentas from './EditarBancos_cuentas';
import Editarestado from './Editarestado';

export default function Bancos() {
    const globalTheme = useTheme();
    const [bancos, setBancos] = useState(null);
    const [tabla, setTabla] = useState(true);
    const [editar, setEditar] = useState(false);
    const [alta, setAlta] = useState(false);
    const [datosformulario, setDatosformulario] = useState({});
    const [hiddenmuestrainactivo, setHiddenmuestrainactivo] = useState(false);
    const [hiddenmuestraactivos, setHiddenmuestraactivos] = useState(true);
    const [modalEditar, setmodalEstado] = useState(false);

    const getActivos = () => {
        setHiddenmuestraactivos(true)
        setHiddenmuestrainactivo(false)
        getData('/bancos_cuentas/activos', setBancos);
    };

    const getInactivos = () => {
        setHiddenmuestrainactivo(true);
        setHiddenmuestraactivos(false);
        getData('/bancos_cuentas/inactivos', setBancos)
    };

    useEffect(() => {
        getActivos()
    }, [tabla])

    const handleClickNuevo = () => {
        setTabla(false);
        setAlta(true);
    };

    const mostrareditar = (datosDeLaLinea) => {
        setEditar(true);
        setTabla(false);
        setDatosformulario(datosDeLaLinea);
    };

    const editarEstado = (datosDeLaLinea) => {
        setmodalEstado(true);
        // setTabla(false);
        setDatosformulario(datosDeLaLinea);
    };

    const columns = useMemo(() =>
        [{ header: 'Código', accessorKey: 'codigo', size: 100 },
        { header: 'Razon Social', accessorKey: 'razon_social' },
        { header: 'Nro de cuenta', accessorKey: 'nro_cuenta', size: 130 },
        { header: 'Cuenta Contable', accessorKey: 'cuenta_contable' },
        { header: 'CBU', accessorKey: 'cbu' },
        // { header: 'Moneda', accessorKey: 'valor'},
        { header: 'Saldo Actual', accessorKey: 'saldo_actual', size: 130 },
        { header: 'Saldo Pendiente', accessorKey: 'saldo_pendiente', size: 130 }
        ], []);

    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: globalTheme.palette.mode,
                    primary: globalTheme.palette.primary, //primary color for the table
                    info: {
                        main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            globalTheme.palette.mode === "light"
                                ? "rgb(234, 239, 241)"
                                : "#000", //pure black table in dark mode for fun
                    },
                },
                typography: {
                    button: {
                        textTransform: "none", //customize typography styles for all buttons in table by default
                        fontSize: "1rem",
                    },
                },
                components: {
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                fontSize: "0.8rem", //larger tooltip font size                                
                            },
                        },
                    },
                },

            }), [globalTheme])

    const muiSearchTextFieldProps = {
        placeholder: "Buscar..",
        sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
        variant: "outlined",
        color: "success",
        size: "small",
    };

    const initialState = {
        density: "compact",
        showGlobalFilter: true,
        pagination: { pageIndex: 0, pageSize: 10 }
    };

    const tableHeadCellProps = {
        align: "left",
        sx: {
            fontSize: 16,
            color: "white",
            fontWeight: "normal",
            backgroundColor: "#2471A3",
            borderColor: "black",
        },
    };

    return (
        <div>
            <Navbar />
            <Container maxWidth="xl" mt={1} mb={2}>
                {bancos && tabla ? (
                    <Box mt={2} boxShadow={3}>
                        <ThemeProvider theme={tableTheme}>
                            <MaterialReactTable
                                enableColumnResizing
                                columns={columns}
                                data={bancos}
                                initialState={initialState}
                                positionActionsColumn="last"
                                enableExpandAll={false}
                                enableToolbarInternalActions={true}
                                muiTableHeadCellProps={tableHeadCellProps}
                                muiTableBodyCellProps={({ row }) => ({
                                    align: "left",
                                    sx: {
                                        backgroundColor:
                                            row.original.activo === 1 ? "#b3e6b3" : "#efc3c3",
                                        color: "black",
                                        borderColor: "black",
                                    },
                                })}
                                enableColumnActions={false}
                                muiSearchTextFieldProps={muiSearchTextFieldProps}
                                enableDensityToggle={false}
                                renderTopToolbarCustomActions={() => (
                                    <>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<AddBoxTwoTone />}
                                            onClick={handleClickNuevo}
                                        >
                                            Nuevo Registro
                                        </Button>
                                        <Typography textAlign={'center'} variant="poster" fontSize={26}>
                                            <AccountBalance sx={{ size: 20 }} />
                                            Cuentas corrientes
                                        </Typography>
                                    </>
                                )}
                                layoutMode="grid"
                                localization={MRT_Localization_ES}
                                enableColumnDragging={false} //No permite que se arrastren las columnas.
                                enableEditing
                                renderToolbarInternalActions={({ table }) => (
                                    <>
                                        {!hiddenmuestraactivos ?
                                            <Tooltip title="Ver cuentas activos" >
                                                <IconButton
                                                    color="success"
                                                    onClick={() => {
                                                        getActivos();
                                                    }}
                                                >
                                                    <AccountBalance />
                                                </IconButton>
                                            </Tooltip>
                                            : null}
                                        {!hiddenmuestrainactivo ?
                                            <Tooltip title="Ver cuentas inactivos" >
                                                <IconButton
                                                    color="error"
                                                    onClick={() => {
                                                        getInactivos();
                                                    }}
                                                >
                                                    <CreditCardOff />
                                                </IconButton>
                                            </Tooltip>
                                            : null}

                                        <MRT_FullScreenToggleButton table={table} />
                                    </>
                                )}
                                renderRowActions={({ row, table }) => (
                                    <div>
                                        <Tooltip title="Editar">
                                            <IconButton
                                                onClick={() => mostrareditar(row.original)}
                                                color="primary"
                                            >
                                                <Edit />
                                            </IconButton>
                                        </Tooltip>
                                        {(row.original.activo === 0) ?
                                            <Tooltip title="Marcar como activo">
                                                <IconButton
                                                    color="success"
                                                    onClick={() => editarEstado(row.original)}
                                                >
                                                    <CheckCircleOutline />
                                                </IconButton>
                                            </Tooltip>
                                            :
                                            <Tooltip title="Marcar como inactivo">
                                                <IconButton
                                                    onClick={() => editarEstado(row.original)}
                                                    color="error"
                                                >
                                                    <Block />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </div>
                                )}
                            />
                        </ThemeProvider>
                    </Box>
                ) : null}

                {alta ?
                    <AddBancos_cuentas
                        alta={setAlta}
                        tabla={setTabla} />
                    : null}

                {editar ?
                    <EditarBancos_cuentas
                        editar={setEditar}
                        tabla={setTabla}
                        datos={datosformulario}
                        actualizar={getActivos} /> : null}

                {modalEditar ?
                    <Editarestado
                        open={setmodalEstado}
                        // tabla={setTabla}
                        datos={datosformulario}
                        actualizar={getActivos} /> : null}
            </Container >
        </div >

    )

}