import React, { useEffect, useState } from "react";
import { UrlApi } from "../../../../services/apirest";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Grid,
  Select,
  FormControl,
  TextField,
  MenuItem,
  InputLabel,
} from "@mui/material";
import moment from "moment";

function FormRemitoProveedor(props) {
  console.log(props.datos);
  const [datosRemito, setDatosRemito] = useState({
    fecha: "",
    nro_remito: "",
    pesada: "",
    baja: 0,
    // uuid: "",
    e_idtanquedescarga: "",
  });

  const [tanquesFijos, setTanquesFijos] = useState([{}]);
  const [tanqueDescarga, setTanqueDescarga] = useState(null);

  const obtenerTanquesFijos = () => {
    fetch(UrlApi + "/tanquesfijospropelente/materiaprima")
      .then((data) => data.json())
      .then((data) => setTanquesFijos(data));
  };

  useEffect(() => {
    obtenerTanquesFijos();
  }, []);

  const setearDatosRemito = (e) => {
    setDatosRemito({ ...datosRemito, [e.target.name]: e.target.value });
  };

  const handleClose = () => {
    props.open(false);
  };

  console.log(props.datos)

  async function guardarRemito(e) {
    e.preventDefault();
    await fetch(UrlApi + "/remitos_new/uuid/" + props.datos.uuid, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        fecha: datosRemito.fecha,
        nro_remito: datosRemito.nro_remito,
        pesada: datosRemito.pesada,
        baja: 0,
        // uuid: uuid,
      }),
    });
    // then(handleClose());
    //response&&console.log(response)
    // if (response.ok === false) {
    //   console.log("ERROR EN API");
    //   const error = response && response.json();
    //   setError(error);
    //   setSeverityAlerta("error")
    //   setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
    // } else {
    //   console.log("Conexión con api OK");
    //   try {
    //     //Parseo respuesta de la api a json()
    //     const json = await response.json();
    //     //si api me devuelve lo q le puse en message
    //     if (json.message) {
    //       //Si ese mensaje es "OK"
    //       if (json.message === "OK") {
    //         console.log("Guardado Satisfactoriamente");
    //         setNotificacion(true);
    //         setOpenSnack(true);
    //         setColorMensaje("#D4EFDF");
    //         setMensaje("Guardado");
    //         setSeveritySnack("success");
    //       } else {
    //   console.log("res en api incorrecto. Revisar que sea 'OK'")
    //         setNotificacion(true);
    //         setOpenSnack(true);
    //         setColorMensaje("#D4EFDF");
    //         setSeveritySnack("error");
    //         setMensaje("Registro Guardado. REVISAR RESPUESTA DE API (E_S)");
    //       }
    //     } else if(json.sqlMessage) {
    //       json && setError(json);
    //       console.log(json);
    //       setMensajeAlerta("SQL erróneo (" + json.code + " Errno: " + json.errno + " )" );
    //     } else {
    //       setMensajeAlerta("ERROR DESCONOCIDO.");
    //     }
    //   } catch (error) {
    //     if (error.toString().includes("is not valid JSON")) {
    //       console.log("Guardado. Advertencia: Actualizar respuesta de api. JSON invalido.");
    //       setNotificacion(true);
    //       setOpenSnack(true);
    //       setColorMensaje("#D4EFDF");
    //       setMensaje("Guardado");
    //       setSeveritySnack("success");
    //     } else {
    //       setMensajeAlerta("ERROR DESCONOCIDO.");
    //     }
    //   }
    // }
  }

  async function actualizarEntrada(e) {
    e.preventDefault();
    await fetch(
      UrlApi + "/entradas_salidas_propelente/" + props.datos.idregistro,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          e_idtanquedescarga: datosRemito.tanque_descarga,
          id_estado: 133,
        }),
      }
    );
    // then(handleClose());
    //response&&console.log(response)
    // if (response.ok === false) {
    //   console.log("ERROR EN API");
    //   const error = response && response.json();
    //   setError(error);
    //   setSeverityAlerta("error")
    //   setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
    // } else {
    //   console.log("Conexión con api OK");
    //   try {
    //     //Parseo respuesta de la api a json()
    //     const json = await response.json();
    //     //si api me devuelve lo q le puse en message
    //     if (json.message) {
    //       //Si ese mensaje es "OK"
    //       if (json.message === "OK") {
    //         console.log("Guardado Satisfactoriamente");
    //         setNotificacion(true);
    //         setOpenSnack(true);
    //         setColorMensaje("#D4EFDF");
    //         setMensaje("Guardado");
    //         setSeveritySnack("success");
    //       } else {
    //   console.log("res en api incorrecto. Revisar que sea 'OK'")
    //         setNotificacion(true);
    //         setOpenSnack(true);
    //         setColorMensaje("#D4EFDF");
    //         setSeveritySnack("error");
    //         setMensaje("Registro Guardado. REVISAR RESPUESTA DE API (E_S)");
    //       }
    //     } else if(json.sqlMessage) {
    //       json && setError(json);
    //       console.log(json);
    //       setMensajeAlerta("SQL erróneo (" + json.code + " Errno: " + json.errno + " )" );
    //     } else {
    //       setMensajeAlerta("ERROR DESCONOCIDO.");
    //     }
    //   } catch (error) {
    //     if (error.toString().includes("is not valid JSON")) {
    //       console.log("Guardado. Advertencia: Actualizar respuesta de api. JSON invalido.");
    //       setNotificacion(true);
    //       setOpenSnack(true);
    //       setColorMensaje("#D4EFDF");
    //       setMensaje("Guardado");
    //       setSeveritySnack("success");
    //     } else {
    //       setMensajeAlerta("ERROR DESCONOCIDO.");
    //     }
    //   }
    // }
  }

  const guardar = (e) => {
    guardarRemito(e);
    actualizarEntrada(e);
    handleClose();
  };

  return (
    <div>
      <form onSubmit={guardar}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          <Grid item xs={4}>
            <TextField
              label="Nro Remito"
              focused
              variant="outlined"
              color="success"
              fullWidth
              required
              name="nro_remito"
              value={datosRemito.nro_remito}
              onChange={setearDatosRemito}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Fecha"
              focused
              variant="outlined"
              color="success"
              type="date"
              fullWidth
              required
              name="fecha"
              value={moment(datosRemito.fecha).format("YYYY-MM-DD")}
              onChange={setearDatosRemito}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Pesada"
              focused
              variant="outlined"
              color="success"
              type="number"
              fullWidth
              required
              name="pesada"
              value={datosRemito.pesada}
              onChange={setearDatosRemito}
            />
          </Grid>
        </Grid>
        <br></br>
        <hr></hr>
        <Grid
          mt={3}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={4}>
            <FormControl fullWidth focused>
              <InputLabel color="success">Tanque Descarga</InputLabel>
              <Select
                color="success"
                name="tanque_descarga"
                label="Tanque Descarga"
                value={datosRemito.tanque_descarga}
                onChange={setearDatosRemito}
              >
                {tanquesFijos
                  ? tanquesFijos.map((tanque) => (
                      <MenuItem key={tanque.idtanque} value={tanque.idtanque}>
                        {tanque.nro_serie}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          my={2}
          container
          spacing={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          alignContent="center"
          wrap="wrap"
        >
          <Grid item>
            <Button variant="outlined" color="error" onClick={handleClose}>
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="success" type="submit">
              Guardar
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default FormRemitoProveedor;
