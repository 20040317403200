import React , {useEffect, useState} from 'react'
import {UrlApi} from "../../../services/apirest"
import Alerta from "../../Alerta"
import Notify from "../../Notify"
import Grid from '@mui/material/Grid'
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

function FormPending(props) {
  const pedido = props.pedido;
  const [disBtn, setDisBtn]=useState(false);

//   console.log("el pedido esss", pedido);
  //Manejo de errores
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
  const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
  const [mensajeAlerta, setMensajeAlerta] = useState(""); //mensaje que contendrá el componente "alerta"
  const [finalizar, setFinalizar] = useState(); //finalizar === 1 lo manda como prop el componente "notify" e indica que la transaccion ha finalizado y debe ejecutarse la funcion para cerrar
  const [severityAlerta, setSeverityAlerta] = useState(""); //define severidad de la alerta (success error o warning)

  async function setPending(e) {
    e.preventDefault();
    setDisBtn(true)
    const response = await fetch(UrlApi + "/pedidos/" + pedido.id_pedido, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_estado: 21,
        baja_obs:null
      }),
    });
    if (response.ok === false) {
      console.log("ERROR EN API");
      const error = response && response.json();
      setError(error);
      setSeverityAlerta("error");
      setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      console.log("Conexión con api OK");
      try {
        const json = await response.json();
        if (json.message) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje("Guardado");
          if (json.message !== "OK") console.log("Actualizar Respuesta de API");
          props.actualizar()
        } else if (json.sqlMessage) {
          json && setError(json);
          setMensajeAlerta(
            "SQL erróneo (" + json.code + " Errno: " + json.errno + " )"
          );
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje("Guardado");
          props.actualizar()

        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      }
    }
  }
  return <div>
    
    <Dialog open={props.open} onClose={() => props.setOpen(false)}>
      {notificacion ? (
          <Notify
            mensaje={mensaje}
            open={openSnack}
            color={"#D4EFDF"}
            severity={"success"}
            handleClose={()=>props.setOpen(false)}
          />
        ) : null}
        {error && mensajeAlerta ? (
          <Alerta mensaje={mensajeAlerta} severity={"error"} />
        ) : null}
        <Box justifyContent="center" backgroundColor="#1F618D">
          <DialogTitle>
            <Typography variant="h6" align="center" color="#ffffff">
              Marcar Pedido Aprobado
            </Typography>
          </DialogTitle>
        </Box>
        <DialogContent>
          <DialogContentText textAlign="center">
            Confirme que desea que el pedido vuelva a estar disponible para asignar a un reparto.
          </DialogContentText>
         
          <br></br>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="space-between"
          >
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => props.setOpen(false)}
                color="error"
              >
                Cancel
              </Button>
            </Grid>

            <Grid item>
              <Button variant="contained" color="success" onClick={setPending} disabled={disBtn}>
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
  </div>;
}

export default FormPending