import React, { useEffect, useState, useMemo } from 'react';
import { UrlApi } from '../../../../../services/apirest';
import { getData } from '../../../../../services/library';
import moment from 'moment';
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { createTheme, ThemeProvider, Typography, useTheme } from "@mui/material";
import { Grid, Divider, Box, Button, MenuItem, ListItemIcon, Container } from '@mui/material';
import Addrequerimientos from './Addrequerimientos';
import Editarreq1 from './Editarreq1';
import VerDatosReq from './VerDatosReq';
import Navbar from '../../../../Navbar';
import { Delete, AddBoxTwoTone, Edit, Preview } from '@mui/icons-material';
import DeleteDialog from '../../../../Dialogs/DeleteDialog';
import { logEventos } from "../../../../../services/services";
import Openpdf from '../../../OpenPdf';

function Requerimientos_materiales() {
    let user = localStorage.getItem('user');
    const globalTheme = useTheme();
    const [showRequerimientos, setshowRequerimientos] = useState();
    const [showTabla, setshowTabla] = useState(true);
    const [showAlta, setshowAlta] = useState(false);
    const [showEdit, setshowEdit] = useState(false);
    const [showDelete, setshowDelete] = useState(false);
    const [open, setOpen] = useState(false);
    const [openpdf, setOpenpdf] = useState(false);
    const [dataReq, setdataReq] = useState({});
    const [notify, setNotify] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
    });

    //CONSULTAS A LA API-*--*-*-**-*-*
    const getRequerimientos = () => {
        getData("/requerimientos_materiales/todos/" + user, setshowRequerimientos)
    };

    useEffect(() => {
        getRequerimientos()
    }, [showTabla, user]);

    const handleClickNew = () => {
        setshowAlta(true)
        setshowTabla(false)
    };

    const handleClickEdit = (datos) => {
        setshowTabla(false)
        setshowEdit(true)
        setdataReq(datos)
    };

    const handleClickDelete = (datos) => {
        setshowDelete(true)
        setdataReq(datos)
    };

    const handleOpenpdf = (datos) => {
        setOpenpdf(true)
        setdataReq(datos)
        setshowTabla(false)
    };

    const toggleDrawer = (row, newOpen) => () => {
        setOpen(true);
        setdataReq(row)
    };

    const columns = useMemo(() =>
        [{ header: '#', accessorKey: 'idrequerimiento', size: 90 },
        { header: 'Movil', accessorKey: 'patente', size: 120 },
        { header: 'Solicitado', accessorFn: (req) => moment(req.fecha_creacion).format("DD-MM-YYYY"), size: 100 },
        { header: 'Requerimiento', accessorFn: (req) => req.para_comprar === 1 ? "Para comprar" : "Interno", size: 100 },
        { header: 'Estado', accessorKey: 'valor_estado' }
        ], []);

    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
                    primary: globalTheme.palette.primary, //primary color for the table
                    info: {
                        main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            globalTheme.palette.mode === "light"
                                ? "rgb(234, 239, 241)" //background in light mode
                                : "#000", //pure black table in dark mode for fun
                    },
                },
                typography: {
                    button: {
                        textTransform: "none", //customize typography styles for all buttons in table by default
                        fontSize: "1.2rem",
                    },
                },
                components: {
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                fontSize: "1.1rem", //override to make tooltip font size larger
                            },
                        },
                    },
                },
            }),
        [globalTheme]
    );

    const muiSearchTextFieldProps = {
        placeholder: "Buscar..",
        sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
        variant: "outlined",
        color: "success",
        size: "small",
    };

    const initialState = {
        density: "compact",
        showGlobalFilter: true,
        pagination: { pageIndex: 0, pageSize: 1000 },
    };

    const tableHeadCellProps = {
        align: "center",
        sx: {
            fontSize: 16,
            color: "white",
            fontWeight: "normal",
            backgroundColor: "#2471A3",
            borderColor: "black",
        },
    };

    const rowBg = {
        49: "#ffffff",
        50: "#abebc6",
        58: "#85c1e9",
        68: "#fef5e7",
        87: "#ec7063",
        88: "#3bad97",
        93: "#ec7063",
        184: "#6ab0de",
    };

    const deleteReq = async (e) => {
        e.preventDefault();
         await fetch(UrlApi + '/requerimientos_materiales/' + dataReq.idrequerimiento, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' }
        })
        deleteDeleteList(e)
        logEventos("Eliminar requerimientos", "Requerimientos materiales", "Compras", localStorage.getItem("user"))
    };

    const deleteDeleteList = async (e) => {
        await fetch(UrlApi + '/lista_materiales/uuid/' + dataReq.uuid_requerimiento, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' }
        })
        logEventos("Eliminar lista de materiales", "Requerimientos materiales", "Compras", localStorage.getItem("user"))
        getRequerimientos()
    };

    return (
        <>
            <Navbar />
            <Container maxWidth="xl" mt={1} mb={2}>
                {showRequerimientos && showTabla ? (
                    <Box mt={2} boxShadow={3}>
                        <ThemeProvider theme={tableTheme}>
                            <MaterialReactTable
                                columns={columns}
                                data={showRequerimientos}
                                enableBottomToolbar={false}
                                enablePagination={false}
                                enableRowActions
                                enableRowVirtualization
                                initialState={initialState}
                                positionActionsColumn="last"
                                muiTableHeadCellProps={tableHeadCellProps}
                                muiTableBodyCellProps={({ row }) => ({
                                    align: "center",
                                    sx: {
                                        backgroundColor:
                                            rowBg[row.original.estado] ?? "#fff",
                                        color: "black",
                                        borderColor: "black",
                                    },
                                })}
                                enableColumnActions={false}
                                enableDensityToggle={false}
                                muiSearchTextFieldProps={muiSearchTextFieldProps}
                                renderTopToolbarCustomActions={() => (
                                    <>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<AddBoxTwoTone />}
                                            onClick={handleClickNew}
                                        >
                                            Nuevo Registro
                                        </Button>
                                        <Typography variant="poster" fontSize={26}>
                                            Requerimientos
                                        </Typography>
                                    </>
                                )}
                                layoutMode='grid'
                                enableHiding={false}
                                localization={MRT_Localization_ES}
                                enableColumnDragging={false}
                                renderRowActionMenuItems={({ closeMenu, row }) => [
                                    <MenuItem
                                        key={0}
                                        disabled={(row.original.estado !== 49) && (row.original.estado) !== 87 && (row.original.estado !== 93)}
                                        onClick={() => {
                                            handleClickEdit(row.original)
                                            closeMenu();
                                        }}
                                        sx={{ m: 0 }}>
                                        <ListItemIcon>
                                            <Edit color="primary" />
                                        </ListItemIcon>
                                        Editar requerimiento
                                    </MenuItem>,
                                    <Divider />,
                                    <MenuItem
                                        key={1}
                                        disabled={(row.original.estado !== 49) && (row.original.estado) !== 87 && (row.original.estado !== 93)}
                                        onClick={() => {
                                            handleClickDelete(row.original)
                                            closeMenu();
                                        }}
                                        sx={{ m: 0 }}>
                                        <ListItemIcon>
                                            <Delete color="error" />
                                        </ListItemIcon>
                                        Eliminar requerimiento
                                    </MenuItem>,
                                    <Divider />,
                                    <MenuItem
                                        key={2}
                                        disabled={row.original.estado !== 58}
                                        onClick={() => {
                                            setdataReq(row.original)
                                            handleOpenpdf(row.original)
                                            closeMenu();
                                        }}
                                        sx={{ m: 0 }}>
                                        <ListItemIcon>
                                            <Preview color="secondary" />
                                        </ListItemIcon>
                                        Visualizar
                                    </MenuItem>
                                ]}
                                enableColumnFilters={false}
                                enableMultiRowSelection={false}
                                muiToolbarAlertBannerProps={false}
                                muiTableBodyRowProps={({ row }) => ({
                                    onClick: () => {
                                        row.getToggleSelectedHandler()
                                        toggleDrawer(row.original)()
                                    },
                                    sx: {
                                        cursor: 'pointer'
                                    }
                                })}
                            />
                        </ThemeProvider>
                    </Box>
                ) : null}
            </Container>

            {open ? (
                <Grid key={dataReq.idrequerimiento}>
                    <VerDatosReq
                        datosreq={dataReq}
                        setdataReq={setdataReq}
                        anchor={setOpen}
                        tabla={setshowTabla}
                    />
                </Grid>
            ) : null}

            {showAlta ? (
                <Addrequerimientos
                    alta={setshowAlta}
                    tabla={setshowTabla} />
            ) : null}

            {showEdit ? (
                <Editarreq1
                    tabla={setshowTabla}
                    datos={dataReq}
                    editar={setshowEdit} />
            ) : null}

            {showDelete ? (
                <DeleteDialog
                    title={"Eliminar Requerimiento"}
                    confirmText={"¿Confirma que desea eliminar el requerimiento?"}
                    btnText="Confirmar"
                    notify={notify}
                    setNotify={setNotify}
                    open={showDelete}
                    setOpen={setshowDelete}
                    guardar={deleteReq}
                />
            ) : null}

            {openpdf ? (
                <Openpdf
                    uuid={dataReq.uuid_ordencompra}
                    open={setOpenpdf}
                    tabla={setshowTabla}
                    uuidreq={dataReq.uuid_requerimiento}
                    datos={dataReq}
                />
            ) : null}
        </>
    )
}
export default Requerimientos_materiales


//========================================================================
/*----- Component --------------------------------------------------------/
// Developer     : Candela Grosso   
// Creation Date : 29/12/2023
// Version       : 1.0  
// Description   :  Con este componente el usuario es capaz de :
    - VISUSALIZAR LOS REQUERIMIENTOS QUE HAYA CARGADO, TANTO COMO INTERNO PARA RRHH Y COMPRAS COMO PARA COMPRAR
    - EDITAR LOS REQUERIMIENTOS ANTES DE SU AUTORIZACION O UNA VEZ QUE ESTOS ESTÉN RECHAZADOS.
    - ELIMINAR REQUERIMIENTOS ANTES DE QUE ESTEN AUTORIZADOS 
    - VER EL ESTADO DE LA ORDEN DE COMPRA EN CASO DE HABER SIDO ASIGNADA A UNA.
    - VISUALIZAR UN PDF CON LA ORDEN DE COMPRA.
//------Changes --------------------------------------------------------------------- -------------//
// Change Date   : 17/4/2024
// Change by     : Candela Grosso
// Change description: Modifique la forma de visualizar los datos. En vez de ver una pantalla dividida a partir de ahora el usuario podrá visualizar con DRAWER
                    Cambie el modal de eliminar por el de DELETE DIALOG que es mas lindo jaja
//========================================================================*/

