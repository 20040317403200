import Navbar from "../Navbar";
import React, { useEffect, useState, useMemo } from "react";
import { UrlApi } from "../../services/apirest";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  Typography,
  Stack,
  Grid,
  Tooltip,
  TextField,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";

// ICONOS
import AddBoxIcon from "@mui/icons-material/AddBox";
import CircularProgress from "@mui/material/CircularProgress";

//Notification
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import RomaneoPertenencias2 from "./Romaneo_pertenencias_2";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Romaneo_pertenencias() {
  const [romaneo_pertenencias, setRomaneo_pertenencias] = useState();
  const [datosRomaneoPertenencias, setDatosRomaneoPertenencias] = useState({});
  const [tabla, setTabla] = useState(true);
  const [update, setUpdate] = useState(false);
  const [disBtn, setDisBtn] = useState(false);


  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");

  //funcion para obtener a los romaneo leyendas, pasarlos a json y guardarlos en data
  const obtenerRomaneoPertenencias = () => {
    fetch(UrlApi + "/romaneo_pertenencias/agrupados")
      .then((data) => data.json())
      .then((data) => setRomaneo_pertenencias(data));
  };
  console.log("trae las pertenencias", setRomaneo_pertenencias);

  //funcion que espera los cambios de la funcion de obtener a los romaneo leyendas
  useEffect(() => {
    obtenerRomaneoPertenencias();
  }, [tabla]);

  useEffect(() => {
    if (update) obtenerRomaneoPertenencias();
  }, [update]);

  // *************************************************************************************************************************************/

  const [firmas, setFirmas] = useState();

  //funcion para obtener a los romaneo leyendas, pasarlos a json y guardarlos en data
  const obtenerFirmas = () => {
    fetch(UrlApi + "/romaneo_firmas")
      .then((data) => data.json())
      .then((data) => setFirmas(data));
  };
  console.log("trae las firmas", firmas);

  //funcion que espera los cambios de la funcion de obtener a los romaneo leyendas
  useEffect(() => {
    obtenerFirmas();
  }, []);

  //*************************************************************************************************************************************/

  // DIALOG PARA CREAR LOS DATOS
  const [openCrear, setOpenCrear] = React.useState(false);

  // USANDO ROW TOMO LOS DATOS DE ESA FILA
  const handleClickOpenCrear = (row) => {
    setOpenCrear(true);
    // AL ABRIR EL DIALOG El TEXTFIELDS SE VACIAN
    datosRomaneoPertenencias.id_firma = "";
    datosRomaneoPertenencias.leyenda = "";
  };

  const handleCloseCrear = () => {
    setOpenCrear(false);
  };

  //*************************************************************************************************************************************/
  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
    setUpdate(false);
    setOpenCrear(false);
    setDisBtn(false);
  };

  //*************************************************************************************************************************************/
  const columns = useMemo(
    () => [
      { header: "Descripción", accessorKey: "firma", size: 100 },
    ],
    []
  );

  const muiSearchTextFieldProps = {
    // para el buscar
    placeholder: "Buscar...",
    sx: { minWidth: "18rem" },
    variant: "outlined",
    color: "success",
    size: "small",
  };

  //*************************************************************************************************************************************/
  //Funcion para guardar al crear
  const guardarCrear = async (e) => {
    console.log("Estoy por guardar");
    setDisBtn(true);
    e.preventDefault();
    await fetch(UrlApi + "/romaneo_pertenencias", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        leyenda: datosRomaneoPertenencias.leyenda,
        id_firma: datosRomaneoPertenencias.id_firma,
        estado: 1
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          setUpdate(true);
          setColorMensaje("#D4EFDF");
          setMensaje("La pertenencia se guardó exitosamente !");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar la pertenencia");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  console.log(datosRomaneoPertenencias);

   //**********************************************************************************************/

  const almacenar = (e) => {
    setDatosRomaneoPertenencias({
      ...datosRomaneoPertenencias,
      [e.target.name]: e.target.value,
    });
  };

  // ESTILO PARA LA TABLA

  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)" //background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //override to make tooltip font size larger
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const displayColumnDefOptions = {
    "mrt-row-actions": {
      muiTableHeadCellProps: {
        align: "center",
        sx: {
          fontSize: 14,
          fontStyle: "italic",
        },
      },
      size: 100,
    },
  };

  const initialState = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
  };

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  //*********************************************************************************************************************************************/
  return (
    <>
      <Navbar />
      <Container maxWidth="xl" mt={1}>
        {romaneo_pertenencias && tabla ? (
          <Box mt={2} mb={4}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableExpandAll={false}
                enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
                enableColumnResizing
                enableGrouping // para permitir agrupar x estado x ej
                displayColumnDefOptions={displayColumnDefOptions}
                layoutMode="grid"
                muiTableHeadCellProps={{
                  align: "left", // los titulos de la tabla
                }}
                enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
                enableColumnFilters={false} //desactiva filtros x  columna
                enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
                enableDensityToggle={false} //
                localization={MRT_Localization_ES} //idioma
                enableColumnDragging={false} //para arrastrar columnas
                initialState={initialState}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                columns={columns}
                data={romaneo_pertenencias} //con lo q la completas (esto cambia siempre)
                enableStickyFooter
                // ACCIONES
                renderToolbarInternalActions={(
                  { table } // boton para nuevo , al lado del buscar
                ) => (
                  <>
                    <Tooltip arrow placement="right" title="Nueva Pertenencia">
                      <IconButton color="primary">
                        <AddBoxIcon
                          onClick={() => {
                            handleClickOpenCrear();
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                    <MRT_FullScreenToggleButton table={table} />
                  </>
                )}
                renderTopToolbarCustomActions={() => (
                  <>
                    <Typography variant="poster" fontSize={26}>
                      Leyendas y Fabricantes
                    </Typography>
                  </>
                )}
                renderDetailPanel={({ row }) => (
                  <>
                    <Container maxWidth="x1">
                      {update ? (
                        <Box sx={{ display: "flex" }}>
                          <CircularProgress />
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: "grid",
                            marginLeft: "33vh",
                            gridTemplateColumns: "1fr 1fr",
                            width: "100%",
                          }}
                        >
                          <RomaneoPertenencias2 row={row} />
                        </Box>
                      )}
                    </Container>
                  </>
                )}
              />
            </ThemeProvider>
          </Box>
        ) : null}
      </Container>

      {/* AGREGAR ITEM */}
      <Dialog
        open={openCrear}
        onClose={handleCloseCrear}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle marginLeft={3}>
          <b>{"Agregar Leyenda"}</b>
        </DialogTitle>
        <DialogContent>
          <Container maxWidth="xl">
            <form onSubmit={guardarCrear}>
              <Grid container mt={2} mb={1} spacing={1}>
                <Grid item xs={7} mb={2}>
                  <FormControl style={style} focused>
                    <InputLabel>Firma *</InputLabel>
                    <Select
                      label="Firma"
                      required
                      size="small"
                      name="id_firma"
                      value={datosRomaneoPertenencias.id_firma}
                      onChange={almacenar}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {firmas
                        ? firmas.map((elemento) => (
                            <MenuItem
                              key={elemento.id_firma}
                              value={elemento.id_firma} // Debe coincidir con el tipo de dato de la tabla a la que inserta
                            >
                              {elemento.firma}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={5} mb={2}>
                  <TextField
                    style={{ backgroundColor: "#FFFFFF" }}
                    fullWidth
                    focused
                    required
                    size="small"
                    color="primary"
                    placeholder="Leyenda"
                    name="leyenda"
                    label="Leyenda"
                    onChange={almacenar}
                    value={datosRomaneoPertenencias.leyenda}
                    inputProps={{
                      maxLength: 30,
                    }}
                  />
                </Grid>
              </Grid>

              <Stack direction="row">
                <Grid container direction={{ xs: "column", sm: "row" }}>
                  <Grid item xs={9}>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleCloseCrear}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={disBtn}
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Stack>
            </form>
          </Container>
        </DialogContent>
      </Dialog>

      {/* SNACKBAR QUE SE MOSTRARA AL GUARDAR LOS CAMBIOS */}
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}

export default Romaneo_pertenencias;