import React, { useState, useEffect, useMemo, useCallback } from "react";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { UrlApi } from "../../../services/apirest";
import axios from "axios";
import {
  Tooltip,
  IconButton,
  MenuItem,
  ListItemIcon,
  Container,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { ThemeProvider, Grid } from "@mui/material";
import Edit from "@mui/icons-material/Edit";
import MaterialReactTable from "material-react-table";
import EditarInscripcion from "./EditarInscripcion";
import moment from "moment";
import Notify from "../../Notify";
import { UtilsMaterialTable } from "../hooks/UtilsMUITable";

const Inscripciones = (props) => {
  const [Inscripciones, setInscripciones] = useState([]);
  const [padron, setpadron] = useState([]);
  const [DatosInscripcion, setDatosInscripcion] = useState({
    exencion: null,
    idinscripciones: null,
    idprovincia: null,
    iibb_auto: null,
    porc_percep: null,
    porc_retencion: null,
    provincia: null,
    vto_exento: null,
    coeficiente_unificado: null,
    certificado_exclusion: null,
  });
  const [notificacion, setNotificacion] = useState({
    open: null,
    severity: null,
    mensaje: null,
    color: null,
  });
  const [ModalEditar, setModalEditar] = useState(false);

  //Hooks
  const {
    tableTheme,
    muiSearchTextFieldProps,
    tableHeadCellProps,
    initialState,
  } = UtilsMaterialTable();

  const getInscripciones = useCallback(() => {
    axios
      .get(UrlApi + "/inscripciones_iibb/" + props.proveedor.id)
      .then((res) => setInscripciones(res.data))
      .catch((err) => console.log(err));
  }, [props.proveedor.id]);
  const getPadron = useCallback(() => {
    axios
      .get(
        UrlApi +
          "/inscripciones_iibb/padron/" +
          Number(props.proveedor.nro_cuit)
      )
      .then((res) => setpadron(res.data))
      .catch((err) => console.log(err));
  }, [props.proveedor.nro_cuit]);
  useEffect(() => {
    getInscripciones();
    getPadron();
  }, [getInscripciones, getPadron]);

  console.log("padron", padron);
  console.log(Inscripciones);
  const columns = useMemo(
    () => [
      {
        header: "Provincia",
        accessorKey: "provincia",
        enableEditing: false,
        size: 150,
      },

      { header: "Liquida", accessorKey: "liquida", size: 130 },

      { header: "Percepcion", accessorKey: "percepcion", size: 130 },

      { header: "Retencion", accessorKey: "retencion", size: 130 },

      { header: "Exencion", accessorKey: "exencion", size: 130 },

      {
        header: "Vto exento",
        accessorKey: "vto_exento",
        accessorFn: (data) =>
          data.vto_exento ? moment(data.vto_exento).format("YYYY-MM-DD") : null,
        size: 140,
      },

      {
        header: "Coeficiente Unificado",
        accessorKey: "coeficiente_unificado",
        size: 130,
      },

      {
        header: "Certificado Exclusion",
        accessorFn: (row) => (row.certificado_exclusion === 1 ? "Si" : "No"),
        size: 150,
      },
    ],
    []
  );

  const handleEditar = (DatosDeFila) => {
    setDatosInscripcion(DatosDeFila);
    setModalEditar(true);
    // props.setShowInscripcionesIIBB(false);
  };
  const HandleClose = () => {
    props.setTablaiibb(true);
    props.setShowInscripcionesIIBB(false);
  };

  function Onchange(e) {
    setDatosInscripcion({
      ...DatosInscripcion,
      [e.target.name]:
        e.target.value === null || e.target.value === ""
          ? null
          : e.target.value,
    });
  }
  function OnchangeCheck(e) {
    setDatosInscripcion({
      ...DatosInscripcion,
      [e.target.name]: e.target.checked,
    });
  }
  //console.log(Inscripciones, 'inscrip');
  //console.log('Inscripcion Selected',InscripcionSelected);
  const [rowSelection, setrowSelection] = useState({});
  const [InscripcionSelected, setInscripcionSelected] = useState(null);
  useEffect(() => {
    const { InscripcionSelected } = Object.keys(rowSelection).reduce(
      (result, key) => {
        if (rowSelection[key]) {
          result.InscripcionSelected.push({
            idproveedor: props.proveedor.id,
            idinscripciones: Inscripciones[key].idinscripciones,
            idprovincia: Inscripciones[key].idprovincia,
          });
        }
        return result;
      },
      { InscripcionSelected: [] }
    );
    setInscripcionSelected(InscripcionSelected);
  }, [Inscripciones, props.proveedor.id, rowSelection]);

  //INSCRIPCIONES_PROVEEDOR / Inscripcion seleccionada por el proveedor
  const guardarInscripcionSeleccionada = async () => {
    let response = await fetch(UrlApi + "/inscripcion_proveedor/", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(InscripcionSelected),
    });
    getInscripciones();
    if (response.ok === false) {
      console.log("ERROR EN API");
      const error = response && response.json();
      console.log(error);
      setNotificacion({
        open: true,
        severity: "error",
        color: "#D4EFDF",
        mensaje: "Inconveniente en la API no se Pudo Guardar",
      });
    } else {
      console.log("Conexión con api OK");
      try {
        //Parseo respuesta de la api a json()
        const json = await response.json();
        //si api me devuelve lo q le puse en message
        if (json.message) {
          //Si ese mensaje es "OK"
          if (json.message === "OK") {
            console.log("Guardado Satisfactoriamente");
            setNotificacion({
              open: true,
              severity: "success",
              color: "#D4EFDF",
              mensaje: "Guardado",
            });
          } else {
            console.log("res en api incorrecto. Revisar que sea 'OK'");
            setNotificacion({
              open: true,
              severity: "error",
              color: "#D4EFDF",
              mensaje: "Registro Guardado. revisar RES en la API",
            });
          }
        } else if (json.sqlMessage) {
          console.log(json);
          setNotificacion({
            open: true,
            severity: "error",
            color: "#D4EFDF",
            mensaje: "Error de SQL",
          });
        } else {
          console.error("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          console.log(
            "Guardado. Advertencia: Actualizar respuesta de api. JSON invalido."
          );
          setNotificacion({
            open: true,
            severity: "success",
            color: "#D4EFDF",
            mensaje: "Guardado",
          });
        } else {
          console.error("ERROR DESCONOCIDO.");
        }
      }
    }
    setTimeout(() => HandleClose(), 2000);
  };

  return (
    <Container maxWidth="xl">
      <Box
        mb={3}
        mt={2}
        backgroundColor="#F4F6F6"
        p={3}
        border={0.5}
        borderColor={"#ABB2B9"}
        borderRadius={4}
      >
        {notificacion ? (
          <Notify
            mensaje={notificacion.mensaje}
            open={notificacion.open}
            color={notificacion.color}
            severity={notificacion.severity}
          />
        ) : null}
        <ThemeProvider theme={tableTheme}>
          <MaterialReactTable
            columns={columns}
            data={Inscripciones}
            localization={MRT_Localization_ES}
            muiSearchTextFieldProps={muiSearchTextFieldProps}
            //  displayColumnDefOptions={displayColumnDefOptions}
            enableExpandAll={false}
            muiTableHeadCellProps={tableHeadCellProps}
            // enableGlobalFilter={true}
            enableToolbarInternalActions={true}
            //enableTopToolbar={false} // habilita o deshabilita TODA la barra de herramientas de arriba
            enableColumnResizing
            layoutMode="grid"
            enableColumnActions={false}
            enableColumnFilters={false} //desactiva filtros x  columna
            enableHiding={false}
            muiTableBodyCellProps={({ row }) => ({
              align: "left",
              sx: {
                border: "1px solid rgba(81, 81, 81, .5)",
                background: row.original.iibb_auto === 1 ? "#7ae582" : null,
              },
            })}
            enableDensityToggle={false} //
            enableColumnDragging={false} //No permite que se arrastren las columnas.
            initialState={initialState}
            enableStickyFooter
            enableRowActions
            enableEditing
            enableRowSelection
            onRowSelectionChange={setrowSelection}
            state={{ rowSelection }}
            positionActionsColumn="last"
            renderTopToolbarCustomActions={() => (
              <>
                <Typography
                  variant="poster"
                  fontSize={26}
                  sx={{ marginLeft: 70 }}
                >
                  Inscripciones
                </Typography>
              </>
            )}
            renderRowActions={({ row, table }) => (
              <Box sx={{ display: "flex", gap: "1rem" }}>
                <Tooltip arrow placement="left" title="Edit">
                  <IconButton onClick={() => handleEditar(row.original)}>
                    <Edit color="primary" />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          />
        </ThemeProvider>
        <br />
        <Grid
          container
          spacing={2}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <Grid item>
            <Button onClick={HandleClose} color="error" variant="contained">
              Volver
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={guardarInscripcionSeleccionada}
              color="success"
              variant="contained"
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
        {ModalEditar ? (
          <EditarInscripcion
            DatosInscripcion={DatosInscripcion}
            idproveedor={props.proveedor.id}
            uuid={props.proveedor.uuid_proveedor}
            modalEditar={setModalEditar}
            onSuccess={getInscripciones}
            Onchange={Onchange}
            OnchangeCheck={OnchangeCheck}
          />
        ) : null}
      </Box>
    </Container>
  );
};

export default Inscripciones;
