import { UrlApi } from "../../../../../services/apirest";

export async function save(reqBody, setNotify) {
  const response = await fetch(UrlApi + "/iibb_clientes", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  });
  if (response.ok === false) {
    setNotify({
      open: true,
      severity: "error",
      color: "#FADBD8",
      mensaje: "Error al guardar",
    });
  } else {
    try {
      const json = await response.json();
      if (json.message) {
        setNotify({
          open: true,
          severity: "success",
          color: "#D4EFDF",
          mensaje: "Guardado",
        });
      } else if (json.sqlMessage) {
        setNotify({
          open: true,
          severity: "error",
          color: "#FADBD8",
          mensaje: "Error al guardar",
        });
      } else {
        setNotify({
          open: true,
          severity: "error",
          color: "#FADBD8",
          mensaje: "Error al guardar",
        });
      }
    } catch (error) {
      if (error.toString().includes("is not valid JSON")) {
        setNotify({
          open: true,
          severity: "success",
          color: "#D4EFDF",
          mensaje: "Guardado",
        });
        // actualizar()
      } else {
        setNotify({
          open: true,
          severity: "error",
          color: "#FADBD8",
          mensaje: "Error al guardar",
        });
      }
    }
  }
}
