import React, { useEffect, useState } from 'react';
import {
    FormControlLabel, Checkbox, Autocomplete,
    IconButton, Grid, Box, Select,
    FormControl, TextField, MenuItem, InputLabel,
    Container, Stack, Button, InputAdornment, Tooltip
} from '@mui/material';
import moment from 'moment';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { v4 as uuidv4 } from 'uuid';   ///me da un unico id para poner en los pedidos
import FileUploadMultiple from '../../../services/UploadFiles';
import Notify from '../../Notify';
import Title from '../../Title';
import { Delete, AddCircleOutline } from '@mui/icons-material';
import { getData, postData } from '../../../services/library';
import Modalcondicionnull from './Modalcondicionnull';
import { logEventos } from '../../../services/services';

export default function GenerarOrdenes(props) {
    const [openclose, setOpenClose] = useState(false);
    const [bonificacionPesos, setBonificacionPesos] = useState(null);
    const [bonificacionPorcentaje, setBonificacionPorcentaje] = useState(null);
    const [totalFinal, setTotalFinal] = useState();
    const [proveedores, setProveedores] = useState(null);
    const [proveedorSelected, setproveedorSelected] = useState(null);
    const [condiciones, setCondiciones] = useState(null);
    const [tipoMoneda, settipoMoneda] = useState(null);
    const [sucursales, setSucursales] = useState(null);
    const [vehiculos, setVehiculos] = useState(null)
    const [uuid, setUuid] = useState(uuidv4());
    const [condVentaNew, setCondVentaNew] = useState(null);
    const [subtotal, setsubtotal] = useState(0);
    const [articulos, setArticulos] = useState([]);
    const [medidas, setmedidas] = useState(null);
    const [empresas, setempresas] = useState(null);
    const [modalVacio, setmodalVacio] = useState(false);
    const [notificacion, setNotificacion] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
    });

    const [datosFormulario, setDatosFormulario] = useState({
        tipo_moneda: 83,
        id_proveedor: null,
        iva_incluido: null,
        pago_anticipado: null,
        condicion_venta: null,
        uuid_ordencompra: uuid,
        id_sucursal: null,
        id_vehiculo: null,
        fecha_entrega: null,
        comentarios_internos: null,
        observaciones: null,
        saldo_pendiente: 1,
        usuario_crea: localStorage.getItem('user'),
        origen: "manual",
        facturar_a_empresa: null
    });

    const [datosLista, setDatosLista] = useState([{
        cantidad: null,
        usuario: localStorage.getItem('user'),
        uuid_ordencompra: uuid,
        precio_unitario: null,
        subtotal: null,
        bonificacion_pesos: null,
        id_articulo: null,
        bonificacion_porcentaje: null,
    }]);

    const [checked, setChecked] = useState(datosLista.map(() => true));

    useEffect(() => {
        getData('/proveedores', setProveedores)
        getData('/desplegables/tipoMoneda', settipoMoneda)
        getData('/condicion_pago', setCondiciones)
        getData('/sucursales', setSucursales)
        getData('/stock_articulos', setArticulos)
        getData("/unidad_medida", setmedidas);
        getData('/empresas', setempresas)
    }, []);

    const getBonificacionPorcentaje = () => {
        // console.log("ENtro a bonificacion")
        if (!!subtotal && !!bonificacionPorcentaje) {
            setTotalFinal(subtotal - (subtotal * bonificacionPorcentaje / 100))
        } else if (!!subtotal && !!bonificacionPesos) {
            setTotalFinal(subtotal - bonificacionPesos)
        }
        else {
            setTotalFinal(subtotal)
        }
    };

    useEffect(() => {
        if (proveedorSelected && proveedorSelected.id_condicionventa === null) {
            setmodalVacio(true)
        }
    }, [proveedorSelected, setproveedorSelected]);

    useEffect(() => {
        getBonificacionPorcentaje()
    }, [bonificacionPesos, bonificacionPorcentaje, subtotal]);

    useEffect(() => {
        getData('/vehiculos/sucursal/' + datosFormulario.id_sucursal, setVehiculos)
    }, [datosFormulario.id_sucursal]);

    useEffect(() => {
        proveedorSelected &&
            setCondVentaNew(proveedorSelected.id_condicionventa);
    }, [proveedorSelected, setproveedorSelected]);

    const handleOnClickCheckBox = (e) => {
        setDatosFormulario({ ...datosFormulario, [e.target.name]: e.target.checked })
    };

    const almacenar = (e) => {
        setDatosFormulario({ ...datosFormulario, [e.target.name]: e.target.value ? e.target.value : "" })
    };

    const setearCondicion = (e) => {
        setCondVentaNew(e.target.value);
    };

    const style = {
        backgroundColor: "#ffffff",
        width: "100%"
    };

    const guardarOrdenCompra = async (e) => {
        // console.log("guardar orden")
        postData(e,
            '/ordencompra',
            {
                id_proveedor: proveedorSelected.id,
                comentarios_internos: datosFormulario.comentarios_internos,
                uuid_ordencompra: datosFormulario.uuid_ordencompra,
                pago_anticipado: datosFormulario.pago_anticipado,
                iva_incluido: datosFormulario.iva_incluido,
                condicion_venta: condVentaNew,
                tipo_moneda: datosFormulario.tipo_moneda,
                total: totalFinal,
                usuario: localStorage.getItem('user'),
                fecha_entrega: datosFormulario.fecha_entrega,
                id_estado: 89,
                bonificacion_pesos: bonificacionPesos,
                bonificacion_porcentaje: bonificacionPorcentaje,
                observaciones: datosFormulario.observaciones,
                id_sucursal: datosFormulario.id_sucursal,
                id_vehiculo: datosFormulario.id_vehiculo,
                usuario_crea: datosFormulario.usuario_crea,
                saldo_pendiente: 1,
                origen: datosFormulario.origen,
                facturar_a_empresa: datosFormulario.facturar_a_empresa
            },
            setNotificacion
        )
        guardarListaMateriales(e)
    };

    const guardarListaMateriales = async (e) => {
        
        postData(e,
            '/lista_materiales',
            {
                datosLista
            },
            setNotificacion,
            logEventos("Generar orden d compra", "Generar ordenes", "Orden de compra", localStorage.getItem("user")))
    };

    // console.log("datoslista", datosLista)

    const handleClose = () => {
        props.tabla(true)
        props.alta(false)
        props.tab(true)
    };

    const agregarLinea = () => {
        const nuevaLinea = { id_unidad_medida: null, material: null, cantidad: null, id_articulo: null, usuario: localStorage.getItem('user'), uuid_requerimiento: datosFormulario.uuid_requerimiento, uuid_ordencompra: datosFormulario.uuid_ordencompra };
        setDatosLista((prevLineas) => [...prevLineas, nuevaLinea]);
    };

    const handleChecked = (index, isChecked) => {
        const newCheckedState = [...checked];
        newCheckedState[index] = isChecked;
        setChecked(newCheckedState);
    };

    const deshacerAlmacenar = (index) => {
        setDatosLista(prevState => prevState.filter((_, i) => i !== index));
    };

    const handleArticuloChange = (index, selectedArticulo) => {
        setDatosLista((prevLineas) =>
            prevLineas.map((linea, i) =>
                i === index ? { ...linea, id_articulo: selectedArticulo?.id_articulo || null } : linea,

            )
        );
    };

    const calcularSubtotalPesos = (cantidad, precio, bonificacion_pesos) => {
        const cantidadNumerica = parseFloat(cantidad);
        const precioNumerico = parseFloat(precio);
        const bonificacionNumerico = parseFloat(bonificacion_pesos) || 0;
        return (cantidadNumerica * precioNumerico) - bonificacionNumerico;
    };

    const calcularSubtotalPorcentaje = (cantidad, precio, bonificacion_porcentaje) => {
        const cantidadNumerica = parseFloat(cantidad);
        const precioNumerico = parseFloat(precio);
        const bonificacionPorcentaje = parseFloat(bonificacion_porcentaje) || 0;
        const bonificacionDecimal = bonificacionPorcentaje / 100;
        return cantidadNumerica * precioNumerico * (1 - bonificacionDecimal);
    };

    // Funciones de manejo de cambios
    const handleCantidadChange = (index, cantidad) => {
        setDatosLista((prevLineas) =>
            prevLineas.map((linea, i) =>
                i === index ? { ...linea, cantidad } : linea
            )
        );
    };

    const handleMaterialChange = (index, material) => {
        setDatosLista((prevLineas) =>
            prevLineas.map((linea, i) =>
                i === index ? { ...linea, material } : linea
            )
        );
    };

    const handleMedidaChange = (index, id_unidad_medida) => {
        setDatosLista((prevLineas) =>
            prevLineas.map((linea, i) =>
                i === index ? { ...linea, id_unidad_medida } : linea
            )
        );
    };

    const handlePrecioChange = (index, precio_unitario) => {
        setDatosLista((prevDatosLista) =>
            prevDatosLista.map((linea, i) => {
                if (i === index) {
                    const subtotal = linea.bonificacion_pesos
                        ? calcularSubtotalPesos(linea.cantidad, precio_unitario, linea.bonificacion_pesos)
                        : calcularSubtotalPorcentaje(linea.cantidad, precio_unitario, linea.bonificacion_porcentaje);

                    return { ...linea, precio_unitario, subtotal };
                }
                return linea;
            })
        );
    };

    const handleBonificacionPorcentajeChange = (index, bonificacion_porcentaje) => {
        setDatosLista((prevLineas) =>
            prevLineas.map((linea, i) =>
                i === index
                    ? {
                        ...linea,
                        bonificacion_porcentaje,
                        subtotal: calcularSubtotalPorcentaje(linea.cantidad, linea.precio_unitario, bonificacion_porcentaje),
                    }
                    : linea
            )
        );
    };

    const handleBonificacionPesosChange = (index, bonificacion_pesos) => {
        setDatosLista((prevLineas) =>
            prevLineas.map((linea, i) =>
                i === index
                    ? {
                        ...linea,
                        bonificacion_pesos,
                        subtotal: calcularSubtotalPesos(linea.cantidad, linea.precio_unitario, bonificacion_pesos),
                    }
                    : linea
            )
        );
    };

    // Función para calcular el total
    const getTotal = (datosLista, setSubtotal) => {
        const total = datosLista.reduce((acc, lista) => {
            const subtotal = parseFloat(lista.subtotal) || 0;
            return isNaN(subtotal) ? acc : acc + subtotal;
        }, 0);
        setSubtotal(total);
    };

    // Efecto secundario para calcular el total cuando cambia la lista de datos
    useEffect(() => {
        getTotal(datosLista, setsubtotal);
    }, [datosLista]);

    return (
        <div>
            <Container maxWidth="xl">
                <Title titulo="Generar nueva orden de compra"
                    handleClose={handleClose}
                />
                <form onSubmit={guardarOrdenCompra} autoComplete='off'>
                    <Box backgroundColor="#F4F6F6"
                        mt={1}
                        p={3}
                        mb={5}
                        border={0.5}
                        borderColor={"#ABB2B9"}
                        borderRadius={4}>
                        <Grid container spacing={2} alignItems='center' >
                            <Grid item xs={3} alignItems='flex-start'>
                                <Autocomplete
                                    focused
                                    size="small"
                                    style={style}
                                    noOptionsText={"Proveedor INEXISTENTE"}
                                    options={proveedores}
                                    autoHighlight
                                    getOptionLabel={(proveedor) => proveedor.nombre_fantasia ?? proveedor.razon_social}
                                    onChange={(event, value) => setproveedorSelected(value)}
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            key={option.nombre_fantasia}
                                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                            {...props}
                                        >
                                            {option.nombre_fantasia ?? option.razon_social} - {option.nro_cuit}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            focused
                                            required
                                            fullWidth={true}
                                            placeholder="Tipee para buscar proveedores.."
                                            {...params}
                                            variant='outlined'
                                            size="small"
                                            label="Proveedores "
                                            inputProps={{
                                                ...params.inputProps,
                                            }
                                            }
                                        />
                                    )}
                                />

                            </Grid>

                            <Grid item xs={2}>
                                <FormControl fullWidth={true} size="small" focused color="primary" required >
                                    <InputLabel id="lbl_estado">Condicion De Venta</InputLabel>
                                    <Select
                                        variant='outlined'
                                        minHeight='20px'
                                        onSelect={proveedorSelected && proveedorSelected.id_condicionventa}
                                        name='condicion_venta'
                                        value={condVentaNew}
                                        style={style}
                                        onChange={setearCondicion}
                                        label="Condición de venta">
                                        {condiciones ? condiciones.map(elemento => (
                                            <MenuItem key={elemento.id} value={elemento.id}>
                                                {elemento.condicion}
                                            </MenuItem>
                                        )) : null}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={2}>
                                <FormControl fullWidth={true} style={style} focused color="primary" required size="small">
                                    <InputLabel id="lbl_estado">Moneda</InputLabel>
                                    <Select
                                        required
                                        variant='outlined'
                                        name='tipo_moneda'
                                        value={datosFormulario.tipo_moneda}
                                        onChange={almacenar}
                                        label="Moneda">
                                        {tipoMoneda ? tipoMoneda.map(elemento => (
                                            <MenuItem key={elemento.id} value={elemento.id}>
                                                {elemento.valor}
                                            </MenuItem>
                                        )) : null}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={2}>
                                <FormControl fullWidth={true} style={style} focused color="primary" required size="small">
                                    <InputLabel id="lbl_estado">Facturar a </InputLabel>
                                    <Select
                                        required
                                        variant='outlined'
                                        name='facturar_a_empresa'
                                        value={datosFormulario.facturar_a_empresa}
                                        onChange={almacenar}
                                        label="Facturar a ">
                                        {(empresas ?? []).map(elemento => (
                                            <MenuItem key={elemento.idempresa} value={elemento.idempresa}>
                                                {elemento.empresa}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>


                            <Grid item xs={1}>
                                <FormControlLabel
                                    value="start"
                                    control={
                                        <Checkbox
                                            name='iva_incluido'
                                            onClick={handleOnClickCheckBox}
                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }} />}
                                    label="Impuestos incluidos "
                                    labelPlacement="start"
                                />
                            </Grid>

                            <Grid item xs={1} ml={3}>
                                <FormControlLabel
                                    value="start"
                                    control={
                                        <Checkbox
                                            name='pago_anticipado'
                                            onClick={handleOnClickCheckBox}
                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }} />}
                                    label="Pago anticipado"
                                    labelPlacement="start"
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} alignItems='center' mt={0.3} mb={3}>
                            <Grid item xs={4}>
                                <TextField
                                    name='id_sucursal'
                                    select
                                    size='small'
                                    style={style}
                                    required
                                    focused
                                    color="primary"
                                    placeholder='Elija una sucursal'
                                    value={datosFormulario.id_sucursal}
                                    onChange={almacenar}
                                    label="Sucursal"
                                >
                                    {sucursales ? sucursales.map(elemento => (
                                        <MenuItem key={elemento.id} value={elemento.id}>
                                            {elemento.nombre}
                                        </MenuItem>
                                    )) : null}
                                </TextField>
                            </Grid>

                            <Grid item xs={4}>
                                <FormControl style={style} focused color="primary" size='small'>
                                    <InputLabel id="lbl_movil">Movil</InputLabel>
                                    <Select
                                        name='id_vehiculo'
                                        value={datosFormulario.id_vehiculo}
                                        onChange={almacenar}
                                        label="Movil">
                                        {vehiculos ? vehiculos.map(elemento => (
                                            <MenuItem key={elemento.id} value={elemento.id}>
                                                {elemento.modelo}  -  {elemento.patente}
                                            </MenuItem>
                                        )) : null}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={4}>
                                <TextField
                                    name="fecha_entrega"
                                    size='small'
                                    focused
                                    required
                                    style={style}
                                    label="Fecha de entrega"
                                    value={moment(datosFormulario.fecha_entrega).format("YYYY-MM-DD")}
                                    onChange={almacenar}
                                    type="date"
                                    inputProps={{
                                        min: moment(new Date()).format("YYYY-MM-DD")
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Box sx={{ flexGrow: 2, mt: 1 }} alignItems="flex">
                            {datosLista.map((linea, index) => (
                                <div key={index} style={{ paddingBottom: '12px', paddingLeft: "10px", }} >
                                    <Stack spacing={1} direction="row" mt={1}>
                                        <FormControlLabel
                                            size="small"
                                            onChange={(e) => handleChecked(index, e.target.checked)}
                                            labelPlacement="start"
                                            label="De Stock"
                                            control={<Checkbox checked={checked[index]} />}
                                        />
                                        <TextField
                                            autoComplete='off'
                                            focused color="primary" required size="small"
                                            style={{ width: '20ch', backgroundColor: "#ffffff" }}
                                            type="number"
                                            label="Cantidad"
                                            value={linea.cantidad}
                                            onChange={(e) => handleCantidadChange(index, e.target.value)}
                                        />
                                        {checked[index] ? (
                                            <Autocomplete
                                                fullWidth
                                                // selectOnFocus
                                                options={articulos}  // Reemplaza con tus opciones de artículos
                                                getOptionLabel={(option) => (option ? option.nombre || '' : '')}
                                                value={articulos.find((articulo) => articulo.id_articulo === linea.id_articulo) || null}
                                                onChange={(event, newValue) => handleArticuloChange(index, newValue)}
                                                renderInput={(params) => (
                                                    <TextField {...params} focused color="primary" required label="Articulo" size="small" style={style} />
                                                )}
                                            />
                                        )
                                            :
                                            (
                                                <>
                                                    <Grid item xs={2}>
                                                        <FormControl focused color="primary" required size='small'>
                                                            <InputLabel id="lbl_autorizante">Unidad de medida</InputLabel>
                                                            <Select
                                                                labelId={`unidadMedida-${index}`}
                                                                id={`unidadMedida-${index}`}
                                                                value={linea.id_unidad_medida}
                                                                onChange={(e) => handleMedidaChange(index, e.target.value)}
                                                                style={{ width: '10ch', backgroundColor: "#ffffff" }}
                                                                name='id_unidad_medida'
                                                                label="Unidad de medida"
                                                            >
                                                                {(medidas ?? []).map(elemento => (
                                                                    <MenuItem key={elemento.id} value={elemento.id}>
                                                                        {elemento.descripcion}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    {/* <br/>  */}

                                                    <Grid item xs={3}>
                                                        <TextField
                                                            focused color="primary" required size="small"
                                                            style={{ width: '40ch', backgroundColor: "#ffffff" }}
                                                            label="Material/ Servicio"
                                                            value={linea.material}
                                                            onChange={(e) => handleMaterialChange(index, e.target.value)}
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                        <TextField
                                            label="Precio"
                                            type='number'
                                            required
                                            size="small"
                                            focused
                                            multiline
                                            color="primary"
                                            style={{ width: '30ch', backgroundColor: "#ffffff" }}
                                            margin="none"
                                            name='precio_unitario'
                                            value={linea.precio_unitario}
                                            onChange={(e) => handlePrecioChange(index, e.target.value)}

                                        />

                                        <TextField
                                            autoComplete='off'
                                            label="Bonif. $"
                                            type="number"
                                            size="small"
                                            focused
                                            multiline
                                            color="primary"
                                            style={{ width: '20ch', backgroundColor: "#ffffff" }}
                                            margin="none"
                                            name='bonificacion_pesos'
                                            value={linea.bonificacion}
                                            onChange={(e) => handleBonificacionPesosChange(index, e.target.value ? e.target.value : null)}
                                            InputProps={{ inputProps: { min: 0, max: 100 } }}
                                        />
                                        <TextField
                                            label="Bonif. %"
                                            type="number"
                                            size="small"
                                            focused
                                            multiline
                                            autoComplete='off'
                                            color="primary"
                                            style={{ width: '20ch', backgroundColor: "#ffffff" }}
                                            margin="none"
                                            name='bonificacion_porcentaje'
                                            value={linea.bonificacion}
                                            onChange={(e) => handleBonificacionPorcentajeChange(index, e.target.value ? e.target.value : null)}
                                            InputProps={{ inputProps: { min: 0, max: 100 } }}
                                        />

                                        <TextField
                                            label="Subtotal"
                                            type="number"
                                            required
                                            size="small"
                                            focused
                                            autoComplete='off'
                                            color="primary"
                                            style={{ width: '20ch', backgroundColor: "#ffffff" }}
                                            margin="none"
                                            name='subtotal'
                                            value={linea.subtotal}
                                            // onChange={(e) => handleSubtotalChange(index, e.target.value)}
                                            // onChange={(e)=>handleSubtotalChan}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                        <Tooltip title="Deshacer linea">
                                            <IconButton
                                                variant="outlined"
                                                color='error'
                                                onClick={() => deshacerAlmacenar(index)}
                                            >
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                        {index === datosLista.length - 1 && (
                                            <Tooltip title="Agregar linea">
                                                <IconButton
                                                    variant="outloned"
                                                    color="primary"
                                                    onClick={() => { agregarLinea() }}>
                                                    <AddCircleOutline />
                                                </IconButton>
                                            </Tooltip>
                                        )}

                                    </Stack>
                                </div>
                            ))}
                        </Box>
                        {/*
                    <Grid container spacing={2} alignItems='center' mt={1} mb={1.5} ml={1}>
                        <Button size="small" onClick={agregarLinea} color="primary" variant="contained" > Agregar materiales</Button>
                    </Grid> */}


                        <Grid container justifyContent='space-between' alignItems='center' mt={1}>
                            <Stack spacing={2} direction="row" padding={1}>
                                <Button
                                    onClick={() => setOpenClose(!openclose)}
                                    variant="outlined"
                                    // color="primary"
                                    style={{
                                        borderColor: "#13406e",
                                        backgroundColor: "#ffffff",
                                    }}
                                    startIcon={<AttachFileIcon />}>
                                    Añadir presupuestos
                                </Button>
                            </Stack>

                            <Grid item xs={1}>
                                <TextField
                                    disabled={!!bonificacionPorcentaje}
                                    focused
                                    variant='outlined'
                                    color='primary'
                                    label='Bonificación $'
                                    size="small"
                                    type="number"
                                    name='bonificacion'
                                    style={{
                                        marginLeft: 350, marginRight: 0, backgroundColor: "#ffffff",
                                        width: "100%"
                                    }}
                                    value={bonificacionPesos}
                                    onChange={(e) => setBonificacionPesos(e.target.value ? e.target.value : null)}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <TextField
                                    disabled={!!bonificacionPesos}
                                    focused
                                    type="number"
                                    variant='outlined'
                                    color='primary'
                                    label='Bonificación %'
                                    size="small"
                                    name='bonificacion'
                                    style={{
                                        marginLeft: 180, marginRight: 0, backgroundColor: "#ffffff",
                                        width: "100%"
                                    }}
                                    value={bonificacionPorcentaje}
                                    onChange={(e) => setBonificacionPorcentaje(e.target.value ? e.target.value : null)}
                                />
                            </Grid>

                            <Grid item xs={2} >
                                <TextField
                                    disabled
                                    focused
                                    style={style}
                                    variant='outlined'
                                    color='primary'
                                    autoComplete='off'
                                    label='Total'
                                    name='total'
                                    size="small"
                                    value={totalFinal !== null ? parseFloat(totalFinal).toFixed(2) : 0}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                        </Grid>


                        <Box sx={{ '& > :not(style)': { m: 1, mt: 1 }, }} autoComplete="off" >
                            <TextField
                                placeholder="Comentarios internos"
                                variant='outlined'
                                autoComplete='off'
                                focused
                                style={style}
                                size="small"
                                name="comentarios_internos"
                                id="id"
                                multiline
                                label="Comentarios internos para la orden de compra"
                                color="primary"
                                onChange={almacenar}
                                value={datosFormulario.comentarios_internos}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Box>
                        <Box sx={{ '& > :not(style)': { m: 1, mt: 1 }, }} autoComplete="off" >
                            <TextField
                                focused
                                variant='outlined'
                                style={style}
                                color='primary'
                                size="small"
                                autoComplete='off'
                                label='Observaciones'
                                name='observaciones'
                                value={datosFormulario.observaciones}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment
                                            position="start"
                                        ></InputAdornment>)
                                }} />
                        </Box>

                        <Grid mt={1} container direction="row" justifyContent="space-between" alignItemns="center">
                            <Button
                                variant="contained"
                                color="error"
                                onClick={handleClose}
                            >Volver</Button>
                            <Button
                                variant="contained"
                                type="submit"
                                color="success">
                                Guardar Orden
                            </Button>
                        </Grid>
                    </Box>
                </form>

                {openclose ?
                    <FileUploadMultiple
                        fileId={datosFormulario.uuid_ordencompra}
                        openclose={true}
                    />
                    : null}

                {notificacion && notificacion.open ? (
                    <Notify
                        mensaje={notificacion.mensaje}
                        open={notificacion.open}
                        color={notificacion.color}
                        handleClose={handleClose}
                        severity={notificacion.severity}
                    />
                ) : null}

                {modalVacio ? (
                    <Modalcondicionnull
                        open={setmodalVacio}
                        proveedor={proveedorSelected}
                    />
                ) : null}
            </Container>

        </div >
    )
};

//========================================================================
/*----- Component --------------------------------------------------------/
// Developer     : Candela Grosso   
// Creation Date : 28/11/2023
// Version       : 1.0  
// Description   :  Con este componente el usuario es capaz de generar ordenes de compras desde 0 sin la necesidad de partir desde un requerimiento
//
//------Changes --------------------------------------------------------------------- -------------//
// Change Date   :
// Change by     :
// Change description: 
//========================================================================*/
