//----- Component --------------------------------------------------------/

// Developer : Nicolas Pascucci

// Creation Date : 21/05/2024

// Version : 1

// Description : pantalla final de agradecimiento por haber completado la evaluacion mensual 
//

//------Changes --------------------------------------------------------------------- -------------//

// Change Date :

// Change by :

// Change description:

//

// Change Date :

// Change by :

// Change description:

//

//========================================================================/
import React from 'react';
import Navbar from '../../Navbar';

function PantallaFinal() {
  return (
    <>
    <div style={styles.container}>
      <h1 style={styles.title}>¡Gracias por completar los datos!</h1>
      <p style={styles.text}>¡Tu información ha sido recibida con éxito!</p>
      {/* <p style={styles.text}>Nos pondremos en contacto contigo pronto.</p> */}
    </div>
    </>
  );
}

const styles = {
  container: {
    textAlign: 'center',
    marginTop: '50px',
    backgroundColor: '#f9f9f9',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  },
  title: {
    color: '#333',
  },
  text: {
    color: '#666',
    marginBottom: '10px',
  },
};

export default PantallaFinal;
