import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import EventIcon from "@mui/icons-material/Event";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import DescriptionIcon from "@mui/icons-material/Description";
import Typography from "@mui/material/Typography";

function DetalleFicha(props) {
  const rowItems = props.row.equipo
    ? props.row.equipo.split(" -- ").map((item, index) => {
        // Separar el detalle en partes (cantidad, descripción, fecha)
        const [cantidadDesc, fechaEntrega] = item.split(" fecha de Entrega: ");
        const [cantidad, descripcion] = cantidadDesc.split(" descripción: ");

        return (
          <Grid container key={index} spacing={2} alignItems="center">
            {/* Cantidad y tipo */}
            <Grid item xs={2} display="flex" alignItems="center">
              <Avatar sx={{ bgcolor: "#EBF5FB", width: 24, height: 24, mr: 1 }}>
                <ShoppingBagIcon fontSize="small" />
              </Avatar>
              <Typography variant="body3" color="textSecondary">
                Artículo:
              </Typography>
              <Typography variant="body2" fontWeight="bold">
                {cantidad}
              </Typography>
            </Grid>

            {/* Descripción */}
            <Grid item xs={5} display="flex" alignItems="center">
              <Avatar sx={{ bgcolor: "#EBF5FB", width: 24, height: 24, mr: 1 }}>
                <DescriptionIcon fontSize="small" />
              </Avatar>
              <Typography variant="body3" color="textSecondary">
                Descripción:
              </Typography>
              <Typography variant="body2" fontWeight="bold">
                {descripcion}
              </Typography>
            </Grid>

            {/* Fecha de entrega */}
            <Grid item xs={3} display="flex" alignItems="center">
              <Avatar sx={{ bgcolor: "#EBF5FB", width: 24, height: 24, mr: 1 }}>
                <EventIcon fontSize="small" />
              </Avatar>
              <Typography variant="body3" color="textSecondary">
                Fecha:
              </Typography>
              <Typography variant="body2" fontWeight="bold">
                {fechaEntrega}
              </Typography>
            </Grid>
          </Grid>
        );
      })
    : null;

  return (
    <List
      sx={{
        width: "97vw",
        bgcolor: "#EBF5FB",
        border: 0.5,
        borderRadius: 1,
        borderColor: "#A9CCE3",
        margin: "auto",
      }}
    >
      {rowItems}
    </List>
  );
}

export default DetalleFicha;
