import React, { useState} from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Navbar from "../../Navbar";
import Gestiones from "./Gestiones";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function TabGestiones (){
    const [activeTab, setActiveTab] = useState(0);
    const [showTabs, setShowTabs] = useState(true);

    return (
        <div>
          <Navbar />
          {showTabs ? (
            <Box sx={{ borderBottom: 1, borderColor: "divider", marginTop: 1 }}>
              <Tabs
                value={activeTab}
                onChange={(event, newValue) => setActiveTab(newValue)}
                aria-label="basic tabs example"
                centered
                variant="fullWidth"
              >
                <Tab label="Pendientes" {...a11yProps(0)} />
                <Tab label="Aprobados" {...a11yProps(1)} />  
                {/* <Tab label="Rechazados" {...a11yProps(2)} />             */}
              </Tabs>
            </Box>
          ) : null}
            <Gestiones activeTab={activeTab} setShowTabs={setShowTabs} />
        </div>
      );
}