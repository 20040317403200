import React, { useState, useEffect, useMemo } from 'react'
import axios from 'axios';
import { UrlApi } from '../../../services/apirest';
import {Button,Dialog,DialogContent,DialogTitle,Stack,DialogActions,TextField,InputLabel} from '@mui/material'
import Alerta from '../../Alerta';
import Notify from '../../Notify';
const Addimputacion = ({ open, close,setBadera }) => {

  const [DatosForm, setDatosForm] = useState(
    {
      descripcion:null,
      abreviatura:null,
      cuenta_contable:null
    }
  );
  
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
  const [mensajeAlerta, setMensajeAlerta] = useState(""); //mensaje que contendrá el componente "alerta"
  const [finalizar, setFinalizar] = useState(); //finalizar === 1 lo manda como prop el componente "notify" e indica que la transaccion ha finalizado y debe ejecutarse la funcion para cerrar
  const [severityAlerta, setSeverityAlerta] = useState(""); //define severidad de la alerta (success error o warning)
  const [severitySnack, setSeveritySnack] = useState(""); //define severidad del snackbar (notificación)

  const handleSubmit =async () => {
    //put your validation logic here
    let response = await fetch(UrlApi+'/imputacion_gastos', {
      method: 'POST', headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        descripcion:DatosForm.descripcion,
        abreviatura:DatosForm.abreviatura,
        cuenta_contable:DatosForm.cuenta_contable
    })
    })
    
    if (response.ok === false) {
      console.log("ERROR EN API");
      const error = response && response.json();
      setError(error);
      setSeverityAlerta("error")
      setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      console.log("Conexión con api OK");
      try {
        //Parseo respuesta de la api a json()
        const json = await response.json();
        //si api me devuelve lo q le puse en message
        if (json.message) {
          //Si ese mensaje es "OK"
          if (json.message === "OK") {
            console.log("Guardado Satisfactoriamente");
            setNotificacion(true);
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setMensaje("Guardado");
            setSeveritySnack("success");
          } else {
            console.log("res en api incorrecto. Revisar que sea 'OK'")
            setNotificacion(true);
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setSeveritySnack("error");
            setMensaje("Registro Guardado. REVISAR RESPUESTA DE API (E_S)");
          }
        } else if (json.sqlMessage) {
          json && setError(json);
          console.log(json);
          setMensajeAlerta("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          console.log("Guardado. Advertencia: Actualizar respuesta de api. JSON invalido.");
          setNotificacion(true);
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("Guardado");
          setSeveritySnack("success");
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      }
    }
    setBadera(1);
  };
  const almacenar =(e) => {
  setDatosForm({...DatosForm,[e.target.name]: e.target.value});

  }

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle textAlign="center" sx={{color:'#FFFF',backgroundColor:'#1F618D'}}>Nueva Imputacion</DialogTitle>
      <DialogContent>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
            }}
          >
           {notificacion ? (
          <Notify
            mensaje={mensaje}
            open={openSnack}
            color={colormensaje}
            finalizar={setFinalizar}
            handleClose={close}
            severity={severitySnack}
          />
        ) : null}
        {/* ALERT --> SE MUESTRA SI HAY UN ERROR EN LA API (NO SE CONECTA O ESTÁ MAL LA RUTA, ETC.) */}
        {error && mensajeAlerta ? (
          <Alerta mensaje={mensajeAlerta} severity={severityAlerta} />
        ) : null}

            <br />
              <TextField
                label='Descripcion'
                name='descripcion'
                value={DatosForm.descripcion}
                onChange={almacenar }
              />
           <TextField
                label='Abreviatura'
                name='abreviatura'
                onChange={almacenar }
                value={DatosForm.abreviatura}
              />
           <TextField
                label='Cuenta Contable'
                name='cuenta_contable'
                value={DatosForm.cuenta_contable}
                onChange={almacenar }
              />

          </Stack>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={close}>Cancelar</Button>
        <Button color="success" onClick={handleSubmit} variant="contained">
          Agregar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Addimputacion