import React, { useEffect, useState } from "react";
import { postData, putData } from '../../../../services/library';
import { Typography, Grid, Box, Card, TextField, InputAdornment, CardContent, Dialog, Button } from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PercentIcon from "@mui/icons-material/Percent";
import moment from "moment";
import { logEventos } from "../../../../services/services";
import Notify from "../../../Notify";
import { UrlApi } from "../../../../services/apirest";

export default function Actualizar_selection(props) {
    const { datosExisten, open, setDatosExisten, actualizar, tabla } = props;

    const style = {
        backgroundColor: "#ffffff",
        width: "100%",
    };

    const [datoslista, setDatoslista] = useState([{
        // uuid_direccionentrega: datosExisten.uuid_direccionentrega,
        id_cliente: "",
        lt_con_iva: "",
        lt_sin_iva: "",
        kg_con_iva: "",
        kg_sin_iva: "",
        vigencia: "",
        fin_vigencia: null
    }]);

    const [notificacion, setNotificacion] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
    });
    const [vigencia, setVigencia] = useState();
    const [disabledGuardar, setDisabledGuardar] = useState(false);
    const [calculoHecho, setCalculoHecho] = useState(false);
    const [aumento, setAumento] = useState("");
    const [desactivarPorcentaje, setDesactivarPorcentaje] = useState(false);
    const [desactivarPesos, setDesactivarPesos] = useState(false);
    const [porcentaje, setPorcentaje] = useState("");
    const [calculosRealizados, setCalculosRealizados] = useState(false);

    const handleClose = () => {
        open(false);
        props.tabla(true);
    };

    const aplicarIncrementoYVigencia = (porcentaje, vigencia) => {
        if (!porcentaje) {
            console.error("No se ha especificado un porcentaje.");
            return;
        }
    
        if (!vigencia) {
            console.error("No se ha seleccionado una fecha de vigencia.");
            return;
        }
    
        const fechaVigencia = moment(vigencia);
        const fechaFinVigencia = fechaVigencia.clone().subtract(1, "day").format("YYYY-MM-DD");
    
        const incremento = 1 + porcentaje / 100;
    
        const nuevosDatosLista = datosExisten.map((cliente) => {
            const lt_sin_iva = parseFloat(cliente.lt_sin_iva) || 0;
            const kg_sin_iva = parseFloat(cliente.kg_sin_iva) || 0;
    
            const nuevo_lt_sin_iva = lt_sin_iva * incremento;
            const nuevo_kg_sin_iva = kg_sin_iva * incremento;
    
            return {
                id_cliente: cliente.id_cliente,
                lt_sin_iva: nuevo_lt_sin_iva.toFixed(4),
                lt_con_iva: (nuevo_lt_sin_iva * cliente.valor).toFixed(4),
                kg_sin_iva: nuevo_kg_sin_iva.toFixed(4),
                kg_con_iva: (nuevo_kg_sin_iva * cliente.valor).toFixed(4),
                vigencia: vigencia,
            };
        });
    
        setDatoslista(nuevosDatosLista);
    
        const nuevosDatosExisten = datosExisten.map((cliente) => ({
            ...cliente,
            fin_vigencia: fechaFinVigencia,
        }));
        setDatosExisten(nuevosDatosExisten);
        setCalculosRealizados(true);
    };

    const aplicarAumentoYVigencia = () => {
        if (!aumento) {
            console.error("No se ha especificado un valor para aumento.");
            return;
        }
    
        if (!vigencia) {
            console.error("No se ha seleccionado una fecha de vigencia.");
            return;
        }
    
        const fechaVigencia = moment(vigencia);
        const fechaFinVigencia = fechaVigencia.clone().subtract(1, "day").format("YYYY-MM-DD");
    
        const nuevosDatosLista = datosExisten.map((cliente) => {
            const lt_sin_iva = parseFloat(cliente.lt_sin_iva) || 0;
            const kg_sin_iva = parseFloat(cliente.kg_sin_iva) || 0;
    
            const nuevo_lt_sin_iva = lt_sin_iva + parseFloat(aumento);
            const nuevo_kg_sin_iva = kg_sin_iva + parseFloat(aumento);
    
            return {
                id_cliente: cliente.id_cliente,
                lt_sin_iva: nuevo_lt_sin_iva.toFixed(4),
                lt_con_iva: (nuevo_lt_sin_iva * cliente.valor).toFixed(4),
                kg_sin_iva: nuevo_kg_sin_iva.toFixed(4),
                kg_con_iva: (nuevo_kg_sin_iva * cliente.valor).toFixed(4),
                vigencia: vigencia,
            };
        });
    
        setDatoslista(nuevosDatosLista);
    
        const nuevosDatosExisten = datosExisten.map((cliente) => ({
            ...cliente,
            fin_vigencia: fechaFinVigencia,
        }));
        setDatosExisten(nuevosDatosExisten);
        setCalculosRealizados(true);
    };

    const confirmarActualizacion = async (e) => {
        // logEventos("Actualizar precios especiales", "ListaPrecios Especiales", "Precios actualizados", localStorage.getItem('user'));
        setDisabledGuardar(true);
        // setDisabledGuardar(true);

        const reqBody = {
            body: datoslista.map((dl) => ({
                lt_con_iva: dl.lt_con_iva === "" ? null : dl.lt_con_iva,
                lt_sin_iva: dl.lt_sin_iva === "" ? null : dl.lt_sin_iva,
                kg_con_iva: dl.kg_con_iva === "" ? null : dl.kg_con_iva,
                kg_sin_iva: dl.kg_sin_iva === "" ? null : dl.kg_sin_iva,
                id_cliente: dl.id_cliente,
                vigencia: moment(dl.vigencia).format("YYYY-MM-DD"),
                fin_vigencia: dl.fin_vigencia,
                usuario: localStorage.getItem("user"),
            })),
        };

        const body2 = {
            datosExisten: datosExisten.map((de) => ({
                id_precioespecial: de.id_precioespecial,
                fin_vigencia: de.fin_vigencia,
                usuario: localStorage.getItem("user"),
            })),
        };

        try {
            // Actualizar datos existentes
            await fetch(`${UrlApi}/precios_especiales/z/actualizar_lista`, {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(body2),
            });

            // Insertar nuevos datos
            await postData(e, "/precios_especiales/post_lista", reqBody, setNotificacion);

            alert("Datos actualizados correctamente.");
            open(false);
            tabla(true);
            actualizar();
        } catch (error) {
            console.error("Error al procesar los datos:", error);
            alert("Hubo un error al guardar los datos.");
        } finally {
            setDisabledGuardar(false);
        }
    };


    

    return (
        <div>
            <Dialog open={open} fullWidth maxWidth="xl" onClose={() => open(false)}>
                <Box sx={{ padding: 2 }}>
                    <Card variant="outlined" sx={{ mb: 1, backgroundColor: "#F4F6F6" }}>
                        <CardContent>
                            <Typography sx={{ fontSize: 19, mb: 2, textAlign: "center" }}>
                                Actualización de Precios
                            </Typography>
                            <Grid container spacing={2} alignItems="center" justifyContent="center">
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        style={style}
                                        focused
                                        color="primary"
                                        label="Actualización en pesos"
                                        variant="outlined"
                                        type="number"
                                        value={aumento}
                                        onChange={(e) => {
                                            setAumento(e.target.value);
                                            setDesactivarPorcentaje(true);
                                        }}
                                        disabled={desactivarPesos}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AttachMoneyIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        fullWidth
                                        label="Actualización en porcentaje"
                                        variant="outlined"
                                        type="number"
                                        size="small"
                                        style={style}
                                        focused
                                        color="primary"
                                        value={porcentaje}
                                        onChange={(e) => {
                                            setPorcentaje(e.target.value);
                                            setDesactivarPesos(true);
                                        }}
                                        disabled={desactivarPorcentaje}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        fullWidth
                                        label="Fecha de entrada en vigencia"
                                        size="small"
                                        style={style}
                                        focused
                                        color="primary"
                                        type="date"
                                        value={vigencia}
                                        onChange={(e) => setVigencia(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            if (!vigencia) {
                                                console.error("No se ha seleccionado una fecha de vigencia.");
                                                return;
                                            }

                                            if (porcentaje && vigencia) {
                                                // aplicarIncremento(porcentaje);
                                                aplicarIncrementoYVigencia(porcentaje, vigencia)
                                            } else if (aumento && vigencia) {
                                                aplicarAumentoYVigencia(aumento, vigencia)
                                                // handleAumentoChange();
                                            }

                                            // aplicarFinVigencia(); // Agrega las fechas de vigencia y fin_vigencia
                                        }}
                                    >
                                        Calcular
                                    </Button>
                                </Grid>

                            </Grid>
                        </CardContent>
                    </Card>
                    {calculosRealizados && (
                        <Box sx={{ textAlign: "center", mt: 2 }}>
                            <Typography color="primary">Precios calculados correctamente.</Typography>
                            <Button
                                variant="contained"
                                color="success"
                                onClick={confirmarActualizacion}
                                sx={{ mt: 1 }}
                            >
                                Confirmar Actualización
                            </Button>
                        </Box>
                    )}
                </Box>
            </Dialog>
        </div>
    );
}

{/* <CardContent>
                                <Typography sx={{ fontSize: 19, mb: 2, textDecoration: "underline", textAlign: "center" }}>
                                    Precio actualizado
                                </Typography>
                                {/* Precios con aumento en un solo renglón
                                <Grid container spacing={1} alignItems="center" justifyContent="center">
                                    {[
                                        { label: "Precio litro sin IVA", name: "lt_sin_iva", value: datoslista.lt_sin_iva || 0 },
                                        { label: "Precio litro con IVA", name: "lt_con_iva", value: datoslista.lt_con_iva || 0 },
                                        { label: "Precio kilo sin IVA", name: "kg_sin_iva", value: datoslista.kg_sin_iva || 0 },
                                        { label: "Precio kilo con IVA", name: "kg_con_iva", value: datoslista.kg_con_iva || 0 },
                                    ].map((item, index) => (
                                        <Grid item xs={12} sm={6} md={2} key={index}>
                                            <TextField
                                                fullWidth
                                                style={style}
                                                focused
                                                size="small"
                                                variant="outlined"
                                                label={item.label}
                                                name={item.name}
                                                value={item.value}
                                                onChange={almacenar}
                                                InputProps={{
                                                    readOnly: true,
                                                    startAdornment:
                                                        <InputAdornment position="start">
                                                            <AttachMoneyIcon /></InputAdornment>
                                                }}
                                            />
                                        </Grid>
                                    ))}
                                    {/* Fecha Minima 
                                    <Grid item xs={12} sm={6} md={2}>
                                        <TextField
                                            fullWidth
                                            style={style}
                                            focused
                                            size="small"
                                            variant="outlined"
                                            label="Fecha de entrada en vigencia"
                                            type="date"
                                            inputProps={{ min: moment(datosExisten.vigencia).format("YYYY-MM-DD") }}
                                            name="vigencia"
                                            value={datoslista.vigencia || ""}
                                            onChange={almacenar}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    justifyContent="space-between"
                                    alignItems="flex-end"
                                    mt={2}
                                >
                                    <Button variant="contained"
                                        onClick={() => open(false)}
                                        color="error">
                                        Volver
                                    </Button>

                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color={"success"}
                                        // onClick={guardarPrecio}
                                        disabled={disabledGuardar}
                                    >
                                        Actualizar
                                    </Button>
                                </Grid>
                            </CardContent> */}
// Función que maneja el cambio en el porcentaje
// const handlePorcentajeChange = (event) => {
//     const nuevoPorcentaje = parseFloat(event.target.value) || 0; // Convierte el valor a número
//     setPorcentaje(nuevoPorcentaje);
//     // console.log("nuevoporcentaje", nuevoPorcentaje);

//     // Llamar a la función aplicarIncremento cuando cambia el porcentaje
//     aplicarIncremento(nuevoPorcentaje);
// };
    // const handleAumentoChange = () => {
    //     if (!datosExisten || datosExisten.length === 0) {
    //         console.log("No hay datos para aplicar el aumento.");
    //         return;
    //     }

    //     const nuevoAumento = parseFloat(aumento);
    //     if (isNaN(nuevoAumento) || nuevoAumento === 0) {
    //         console.error("Aumento inválido o igual a cero.");
    //         return;
    //     }

    //     const nuevosDatosLista = datosExisten.map((cliente) => ({
    //         id_cliente: cliente.id_cliente || "",
    //         vigencia: null, // Esto se actualizará después
    //         fin_vigencia: null, // Esto también
    //         lt_con_iva: (parseFloat(cliente.lt_con_iva || 0) + nuevoAumento).toFixed(4),
    //         lt_sin_iva: (parseFloat(cliente.lt_sin_iva || 0) + nuevoAumento).toFixed(4),
    //         kg_con_iva: (parseFloat(cliente.kg_con_iva || 0) + nuevoAumento).toFixed(4),
    //         kg_sin_iva: (parseFloat(cliente.kg_sin_iva || 0) + nuevoAumento).toFixed(4),
    //     }));

    //     setDatoslista(nuevosDatosLista);
    //     setCalculosRealizados(true);
    //     console.log("Datoslista después de aplicar el aumento:", nuevosDatosLista);
    // };

    // Función que aplica el incremento en porcentaje a todos los elementos seleccionados
    // const aplicarIncremento = (porcentaje) => {
    //     if (!datosExisten || datosExisten.length === 0) {
    //         console.log("No hay datos para aplicar el incremento.");
    //         return;
    //     }

    //     const porcentajeValido = parseFloat(porcentaje);
    //     if (isNaN(porcentajeValido) || porcentajeValido === 0) {
    //         console.error("Porcentaje inválido o igual a cero.");
    //         return;
    //     }

    //     // Mapear los datos existentes y aplicar el incremento
    //     const nuevosDatosLista = datosExisten.map((cliente) => {
    //         // Aseguramos que el incremento solo se aplique si existen valores válidos
    //         return {
    //             ...cliente,
    //             lt_con_iva: cliente.lt_con_iva
    //                 ? (parseFloat(cliente.lt_con_iva) * (1 + porcentajeValido / 100)).toFixed(4)
    //                 : null,
    //             lt_sin_iva: cliente.lt_sin_iva
    //                 ? (parseFloat(cliente.lt_sin_iva) * (1 + porcentajeValido / 100)).toFixed(4)
    //                 : null,
    //             kg_con_iva: cliente.kg_con_iva
    //                 ? (parseFloat(cliente.kg_con_iva) * (1 + porcentajeValido / 100)).toFixed(4)
    //                 : null,
    //             kg_sin_iva: cliente.kg_sin_iva
    //                 ? (parseFloat(cliente.kg_sin_iva) * (1 + porcentajeValido / 100)).toFixed(4)
    //                 : null,
    //         };
    //     });

    //     // Actualizar el estado con los nuevos datos calculados
    //     setDatoslista(nuevosDatosLista);
    //     setCalculosRealizados(true);

    //     console.log("Porcentaje aplicado:", porcentajeValido);
    //     console.log("DatosLista después de aplicar el incremento:", nuevosDatosLista);
    // };

    // // const aplicarPorcentaje = (valor, porcentaje) => {
    // //     const valorNumerico = parseFloat(valor) || 0;
    // //     return (valorNumerico + (valorNumerico * porcentaje) / 100).toFixed(4);
    // // };


    // const aplicarFinVigencia = () => {
    //     if (!vigencia) {
    //         console.error("No se ha seleccionado una fecha de vigencia.");
    //         return;
    //     }

    //     const fechaVigencia = moment(vigencia);
    //     const fechaFinVigencia = fechaVigencia.clone().subtract(1, "day").format("YYYY-MM-DD");

    //     // Actualizar datosExisten
    //     const nuevosDatosExisten = datosExisten.map((d) => ({
    //         ...d,
    //         fin_vigencia: fechaFinVigencia,
    //     }));
    //     setDatosExisten(nuevosDatosExisten);

    //     // Actualizar datosLista independientemente de datosExisten
    //     const nuevosDatosLista = datoslista.map((cliente) => ({
    //         ...cliente,
    //         vigencia: vigencia, // Nueva fecha de vigencia
    //         fin_vigencia: fechaFinVigencia, // Nueva fecha de fin de vigencia
    //     }));
    //     setDatoslista(nuevosDatosLista);

    //     console.log("Nuevos datosExisten:", nuevosDatosExisten);
    //     console.log("Nuevos datosLista:", nuevosDatosLista);
    // };
    

    // useEffect(() => {
    //     aplicarFinVigencia()
    //     // console.log("datoslista", datoslista);
    //     // console.log("datosExisten", datosExisten)

    // }, [vigencia])