import React, { useEffect, useState } from "react";
import { getData } from '../../../../services/library';
import { Container, Grid, Button } from "@mui/material";
import { AttachFile } from "@material-ui/icons";
import { v4 as uuidv4 } from 'uuid';
import { UrlApi } from "../../../../services/apirest";
import FileUploadMultiple from "../../../../services/UploadFiles";
import { logEventos } from "../../../../services/services";
import Formcomprobante from "./Formcomprobante";
import Title from "../../../Title";

export default function Addcomprobantes2(props) {
    const [tiposComprobantes, settiposComprobantes] = useState([]);
    const [proveedores, setProveedores] = useState();
    const [proveedorSelected, setproveedorSelected] = useState(null);
    const [total, setTotal] = useState();
    const [imputaciones, setImputaciones] = useState();
    const [condicionesPago, setcondicionesPago] = useState();
    const [sucursales, setSucursales] = useState();
    const [tipoMoneda, settipoMoneda] = useState();
    const [impuestos, setimpuestos] = useState();
    const [valorPorcentajes, setvalorPorcentajes] = useState([]);
    const [uuid, setuuid] = useState(uuidv4());
    const [openclose, setOpenClose] = useState(false);
    const [condVentaNew, setcondVentaNew] = useState(null);
    const [calcularSubtotalIva, setCalcularSubtotalIva] = useState(true);
    const [otrostributos, setotrosTributos] = useState();
    const [saldonoaplic, setsaldonoaplic] = useState();
    const [neto, setnetoGravado] = useState();
    const [disbtn, setdisbtn] = useState(false);
    const [comprobanteExiste, setcomprobanteExiste] = useState([]);
    const [comprobanteSelected, setcomprobanteSelected] = useState("");
    const [periodos, setPeriodos] = useState();
    const [periodoSelected, setperiodoSelected] = useState(null);

    const [datosCabecera, setdatosCabecera] = useState({
        id_tipocomprobante: null,
        id_proveedor: null,
        id_condicionpago: null,
        id_imputacion: null,
        id_sucursal: null,
        nro_comprobante: null,
        fecha_factura: null,
        id_periodocontable: null,
        vto_factura: null,
        vto_cae: null,
        observaciones: null,
        id_tipomoneda: 83,
        tipo_cambio: null,
        total: 0,
        nro_cae: null,
        uuid_comprobante: uuid,
        id_estado: 136,
        usuario: localStorage.getItem('user'),
        facturado_desde: null,
        facturado_hasta: null
    });

    const [datosCuerpo, setdatosCuerpo] = useState([
        {
            subtotal: null,
            subtotal_iva: null,
            id_porcentajeiva: null,
            detalle_productos: null,
            uuid_comprobante: uuid,
            usuario: localStorage.getItem('user')
        }
    ]);

    const [datosImpuestos, setdatosImpuestos] = useState([{
        id_impuesto: null,
        detalle: null,
        porcentaje_alicuota: null,
        importe: null,
        uuid_comprobante: uuid,
        usuario: localStorage.getItem('user')
    }]);

    const [datosPie, setdatosPie] = useState([{
        importe_neto_gr: null,
        id_porcentajeiva: null,
        otros_tributos: null,
        uuid_comprobante: uuid,
        usuario: localStorage.getItem('user'),
        importe: null
    }]);

    useEffect(() => {
        getData('/tipos_comprobantes', settiposComprobantes)
        getData('/proveedores/activos', setProveedores)
        getData('/sucursales', setSucursales)
        getData('/impuestos', setimpuestos)
        getData('/porcentaje_iva', setvalorPorcentajes)
        getData('/condicion_pago', setcondicionesPago)
        getData('/desplegables/tipoMoneda', settipoMoneda)
        getData('/impuestos', setimpuestos)
        getData('/periodo_contable', setPeriodos)
    }, []);

    async function handleSubmit(e) {
        e.preventDefault();
        setdisbtn(true);
        const esCredito = comprobanteSelected && comprobanteSelected.credito === -1;
        let adjustedTotal = total * (esCredito ? -1 : 1);
        let adjustedNeto = neto * (esCredito ? -1 : 1);

        const cuerpo = datosCuerpo.map((item) => ({
            subtotal: item.subtotal * (esCredito ? -1 : 1),
            subtotal_iva: parseFloat(item.subtotal_iva * (esCredito ? -1 : 1)).toFixed(2),
            id_porcentajeiva: item.id_porcentajeiva,
            detalle_productos: item.detalle_productos,
            uuid_comprobante: uuid,
            usuario: localStorage.getItem('user')
        }));

        const impuestos = datosImpuestos.map((item) => ({
            id_impuesto: item.id_impuesto,
            detalle: item.detalle,
            porcentaje_alicuota: item.porcentaje_alicuota,
            importe: item.importe !== 0 ? item.importe * (esCredito ? -1 : 1) : null,
            uuid_comprobante: uuid,
            usuario: localStorage.getItem('user')
        }));

        const pie = datosPie.map((item) => ({
            importe_neto_gr: adjustedNeto,
            id_porcentajeiva: item.id_porcentajeiva,
            otros_tributos: item.otros_tributos !== 0 ? item.otros_tributos * (esCredito ? -1 : 1) : null,
            uuid_comprobante: uuid,
            usuario: localStorage.getItem('user'),
            importe: item.importe * (esCredito ? -1 : 1)
        }));

        const cabecera = {
            id_tipocomprobante: comprobanteSelected.id,
            id_proveedor: proveedorSelected.id,
            id_condicionpago: condVentaNew,
            id_imputacion: datosCabecera.id_imputacion,
            id_sucursal: datosCabecera.id_sucursal,
            nro_comprobante: datosCabecera.nro_comprobante,
            fecha_factura: datosCabecera.fecha_factura,
            id_periodocontable: periodoSelected,
            vto_factura: datosCabecera.vto_factura,
            vto_cae: datosCabecera.vto_cae,
            observaciones: datosCabecera.observaciones,
            id_tipomoneda: datosCabecera.id_tipomoneda,
            tipo_cambio: datosCabecera.tipo_cambio,
            total: adjustedTotal,
            nro_cae: datosCabecera.nro_cae,
            uuid_comprobante: uuid,
            id_estado: 136,
            usuario: localStorage.getItem('user'),
            facturado_desde: datosCabecera.facturado_desde,
            facturado_hasta: datosCabecera.facturado_hasta
        };

        const requestData = {
            datosCabecera: cabecera,
            datosCuerpo: cuerpo,
            datosImpuestos: impuestos,
            datosPie: pie
        };

        try {
            await fetch(UrlApi + '/comprobantes_cabecera', {
                method: 'POST', headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestData)
            });
            props.alta(false)
            props.tabla(true)
        } catch (error) {
            console.error('Error al guardar los datos:', error);
            alert('Hubo un error al guardar los datos');
        }
        logEventos("Carga de comprobantes", "Ingreso facturas", "Compras", localStorage.getItem('user'))
    };

    const style = {
        backgroundColor: "#ffffff",
        width: "100%"
    };

    return (
        <div>
            <Container maxWidth="xl">
                <Title titulo={`Cargar nuevo comprobante`}
                    handleClose={() => [props.alta(false), props.tabla(true)]} />


                <Formcomprobante
                    style={style}
                    datosCabecera={datosCabecera}
                    setdatosCabecera={setdatosCabecera}
                    tiposComprobantes={tiposComprobantes}
                    proveedores={proveedores}
                    sucursales={sucursales}
                    impuestos={impuestos}
                    valorPorcentajes={valorPorcentajes}
                    condicionesPago={condicionesPago}
                    tipoMoneda={tipoMoneda}
                    proveedorSelected={proveedorSelected}
                    setproveedorSelected={setproveedorSelected}
                    imputaciones={imputaciones}
                    setImputaciones={setImputaciones}
                    condVentaNew={condVentaNew}
                    setcondVentaNew={setcondVentaNew}
                    total={total}
                    setTotal={setTotal}
                    calcularSubtotalIva={calcularSubtotalIva}
                    setCalcularSubtotalIva={setCalcularSubtotalIva}
                    comprobanteExiste={comprobanteExiste}
                    setcomprobanteExiste={setcomprobanteExiste}
                    comprobanteSelected={comprobanteSelected}
                    setcomprobanteSelected={setcomprobanteSelected}
                    datosCuerpo={datosCuerpo}
                    setdatosCuerpo={setdatosCuerpo}
                    setdatosImpuestos={setdatosImpuestos}
                    datosImpuestos={datosImpuestos}
                    setnetoGravado={setnetoGravado}
                    neto={neto}
                    otrostributos={otrostributos}
                    setotrosTributos={setotrosTributos}
                    datosPie={datosPie}
                    setdatosPie={setdatosPie}
                    saldonoaplic={saldonoaplic}
                    setsaldonoaplic={setsaldonoaplic}
                    periodos={periodos}
                    periodoSelected={periodoSelected}
                    setperiodoSelected={setperiodoSelected}
                />
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="flex-end"
                    mt={1}
                    mb={5}
                >
                    <Button
                        color="error"
                        variant="contained"
                        onClick={() => {
                            props.alta(false)
                            props.tabla(true)
                        }}
                    >
                        Volver
                    </Button>
                    <Button
                        onClick={() => setOpenClose(!openclose)}
                        variant="outlined"
                        startIcon={<AttachFile />}
                    >
                        Adjuntar comprobante
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        disabled={disbtn}
                        color="success"
                        onClick={handleSubmit}
                    >
                        Guardar Comprobante
                    </Button>
                </Grid>
            </Container>
            {openclose ?
                <FileUploadMultiple
                    fileId={datosCabecera.uuid_comprobante}
                    openclose={true} /> : null}
        </div>)
}