// Developer     : Magalí Perea
// Creation Date : 2024-04-22
// Version       : 1
// Description   : Formulario dónde se escoge empleado, fecha de inicio y fecha de fin para poder listar las viandas pedidas entre esas fechas.

import React, { useEffect, useState } from "react";
import Navbar from "../../Navbar";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField, Button } from "@mui/material";
import { UrlApi } from "../../../services/apirest";
import ListaPedidosPorEmpleado from "../Informe/ListaPedidosPorEmpleado";

function FormResumenXEmpleado() {
  const [showForm, setShowForm] = useState(true);
  const [showList, setShowList] = useState(false);
  const [empleado, setEmpleado] = useState();

  const [users, setUsers] = useState();
  const [reqData, setReqData] = useState({
    usuario: localStorage.getItem("user"),
    fecha_inicio: "",
    fecha_fin: "",
  });
  const [pedidos, setPedidos] = useState([{}]);
  const esAdmin = localStorage.getItem("adminrrhh") === "1";

  useEffect(() => {
    fetch(UrlApi + "/userdata")
      .then((data) => data.json())
      .then((data) => {
        if (!esAdmin) {
          setUsers(
            data.filter(
              (data) => data.username === localStorage.getItem("user")
            )
          );
        } else {
          setUsers(
            data.filter(
              (data) => data.sucursal === "1000" || data.sucursal === "8"
            )
          );
        }
      });
  }, []);

  const obtenerPedidos = () => {
    fetch(
      UrlApi +
        `/viandas/resumen/xempleado/${reqData.usuario}/${reqData.fecha_inicio}/${reqData.fecha_fin}`
    )
      .then((data) => data.json())
      .then((data) => {
        if (data.length === 0) {
          alert(
            "No hay registros de viandas pedidas para este empleado en las fechas seleccionadas."
          );
        } else {
          setPedidos(data.filter((data) => data.opcion !== 0));
          setShowForm(false);
          setShowList(true);
        }
      });
  };

  return (
    <div>
      <Navbar />
      {showList ? (
        <>
          <ListaPedidosPorEmpleado
            pedidos={pedidos}
            reqData={reqData}
            empleado={empleado}
            setOpen={setShowList}
            setShowForm={setShowForm}
            setReqData={setReqData}
          />
        </>
      ) : null}
      {showForm ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              "& > :not(style)": {
                width: "60vh",
                height: "70vh",
              },
            }}
          >
            <Paper elevation={10} sx={{ backgroundColor: "#ebebeb" }}>
              <Grid container spacing={1} direction="row" p={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    color="initial"
                    textAlign="center"
                  >
                    Resumen Viandas Por Empleado
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    sx={{ backgroundColor: "#fff" }}
                    focused
                    noOptionsText={"Inexistente.."}
                    options={users}
                    autoHighlight
                    getOptionLabel={(user) =>
                      `${user.apellido} ${user.nombrepila}`
                    }
                    onChange={(event, value) => {
                      setReqData({ ...reqData, usuario: value.username });
                      setEmpleado(value.apellido + " " + value.nombrepila);
                    }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        key={option.id}
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.nombrepila} {option.apellido}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        color="primary"
                        focused
                        placeholder="Tipee para buscar empleado.."
                        {...params}
                        label="Empleado"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    focused
                    fullWidth
                    sx={{ backgroundColor: "#fff" }}
                    id=""
                    label="Fecha Inicio"
                    type="date"
                    onChange={(e) =>
                      setReqData({ ...reqData, fecha_inicio: e.target.value })
                    }
                    value={reqData.fecha_inicio}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    focused
                    fullWidth
                    sx={{ backgroundColor: "#fff" }}
                    id=""
                    label="Fecha Fin"
                    type="date"
                    onChange={(e) =>
                      setReqData({ ...reqData, fecha_fin: e.target.value })
                    }
                    value={reqData.fecha_fin}
                    inputProps={{
                      min: reqData.fecha_inicio, // Aquí debes especificar la función o valor que defina tu fecha mínima
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="row" justifyContent="center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={obtenerPedidos}
                      disabled={
                        !reqData.usuario ||
                        !reqData.fecha_inicio ||
                        !reqData.fecha_fin
                      }
                    >
                      consultar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Box>
          {/* )} */}
        </div>
      ) : null}
    </div>
  );
}

export default FormResumenXEmpleado;
