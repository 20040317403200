import Navbar from '../../Navbar'
import React, { useEffect, useState, useMemo } from 'react';
import { UrlApi } from '../../../services/apirest'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Container, Typography, Box, Button, Tooltip, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import Addcontratos from './Addcontratos';
import Editarcontratos from './Editarcontratos';
import Fichacontratos from './Fichacontratos';

export default function Contratos() {
    const [contratos, setContratos] = useState()
    const [alta, setAlta] = useState(false);
    const [tabla, setTabla] = useState(true);
    const [editar, setEditar] = useState(false);
    const [ficha, setFicha] = useState(false);
    const [datosformulario, setDatosformulario] = useState({});
    const globalTheme = useTheme();

    const obtenerContratos = () => {
        fetch(UrlApi + '/contratos')
            .then(data => data.json())
            .then(data => setContratos(data))
    };

    useEffect(() => {
        obtenerContratos()
    }, [tabla]);

    const handleClickNuevo = () => {
        setAlta(true)
        setTabla(false)
    };

    const mostrareditar = (datosDeLaLinea) => {
        setEditar(true)
        setTabla(false)
        setDatosformulario(datosDeLaLinea)
    };

    const mostrarficha = (datosDeLaLinea) => {
        setFicha(true)
        setTabla(false)
        setDatosformulario(datosDeLaLinea)
    };

    const columns = useMemo(() =>
        [{ header: 'Nro Contrato', accessorKey: 'nro_contrato' },
        { header: 'Cliente', accessorKey: 'razon_social' },
        { header: 'Modalidad', accessorKey: 'modalidad' },
        { header: 'Producto', accessorKey: 'producto' },
        { header: 'Fecha Inicio', accessorKey: 'fecha_inicio', type: "date", format: "YYYY-MM-DD" },
        { header: 'Fecha Vencimiento', accessorKey: 'vencimiento_contrato', type: "date", format: "YYYY-MM-DD" },
        { header: 'Estado', accessorKey: 'estado' }
        ], []);

    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: globalTheme.palette.mode,
                    primary: globalTheme.palette.primary, //primary color for the table
                    info: {
                        main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            globalTheme.palette.mode === "light"
                                ? "rgb(234, 239, 241)"
                                : "#000", //pure black table in dark mode for fun
                    },
                },
                typography: {
                    button: {
                        textTransform: "none", //customize typography styles for all buttons in table by default
                        fontSize: "1rem",
                    },
                },
                components: {
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                fontSize: "0.8rem", //larger tooltip font size                                
                            },
                        },
                    },
                },

            }), [globalTheme])

    const muiSearchTextFieldProps = {
        placeholder: "Buscar..",
        sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
        variant: "outlined",
        color: "success",
        size: "small",
    };

    const initialState = {
        density: "compact",
        showGlobalFilter: true,
        pagination: { pageIndex: 0, pageSize: 10 }
    };

    const tableHeadCellProps = {
        align: "center",
        sx: {
            fontSize: 16,
            color: "white",
            fontWeight: "normal",
            backgroundColor: "#2471A3",
            borderColor: "black",
        },
    };

    return (
        <div>
            <Navbar />
            <Container maxWidth="xl" mt={1}>
                {contratos && tabla ? (
                    <Box mt={2} boxShadow={3}>
                        <ThemeProvider theme={tableTheme}>
                            <MaterialReactTable
                                enableColumnResizing
                                columns={columns}
                                data={contratos}
                                initialState={initialState}
                                positionActionsColumn="last"
                                enableExpandAll={false}
                                enableToolbarInternalActions={true}
                                muiTableHeadCellProps={tableHeadCellProps}
                                muiTableBodyCellProps={{
                                    align: "center",
                                    sx: {
                                        backgroundColor:"#fff",
                                        color: "black",
                                        borderColor: "black",
                                    },
                                }}
                                enableColumnActions={false}
                                muiSearchTextFieldProps={muiSearchTextFieldProps}
                                enableDensityToggle={false}
                                renderTopToolbarCustomActions={() => (
                                    <>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<AddBoxTwoToneIcon />}
                                            onClick={handleClickNuevo}
                                        >
                                            Nuevo Registro
                                        </Button>
                                        <Typography variant="poster" fontSize={26}>
                                            Contratos
                                        </Typography>
                                    </>
                                )}
                                layoutMode="grid"
                                localization={MRT_Localization_ES}
                                enableColumnDragging={false}
                                enableEditing
                                renderRowActions={({ row, table }) => (
                                    <div>
                                        <Tooltip title="Editar contrato">
                                            <IconButton
                                                onClick={() => mostrareditar(row.original)}
                                                color="primary"
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Ver ficha">
                                            <IconButton
                                                onClick={() => mostrarficha(row.original)}
                                                color="primary"
                                            >
                                                <RemoveRedEyeIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                )}
                                enableColumnFilters={false}
                                enableHiding={false}
                                positionExpandColumn='first' />
                        </ThemeProvider>
                    </Box>
                ) : null}
            </Container>
            {alta ?
                <Addcontratos
                    alta={setAlta}
                    tabla={setTabla} /> : null}
            {editar ?
                <Editarcontratos
                    editar={setEditar}
                    tabla={setTabla}
                    datos={datosformulario} /> : null}
            {ficha ?
                <Fichacontratos
                    ficha={setFicha}
                    tabla={setTabla}
                    datos={datosformulario} /> : null}
            <br />
        </div>
    )
}