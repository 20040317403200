import React, { useEffect, useState } from "react";
import { postData, putData } from '../../../../services/library';
import { Typography, Grid, Box, Card, TextField, InputAdornment, CardContent, Dialog, DialogContent, DialogActions, DialogTitle, Button } from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PercentIcon from "@mui/icons-material/Percent";
import moment from "moment";
import { logEventos } from "../../../../services/services";
import Notify from "../../../Notify";

export default function Editar_precios(props) {
    const { datosExisten, open } = props;
    const [datosForm, setdatosForm] = useState(datosExisten);

    const style = {
        backgroundColor: "#ffffff",
        width: "100%",
    };

    const [datoslista, setDatoslista] = useState({
      
    });

    const [notificacion, setNotificacion] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
    });


  
    const fechaVigencia = moment(datoslista.vigencia);
    const fechaFinVigencia = fechaVigencia.subtract(1, "day");
    // const fechaFinVigenciaFormat = datosExisten.vigencia === "" ? "dd/mm/aaaa" : fechaFinVigencia.format("DD/MM/YYYY");
    const fechaFinVigenciaPut = fechaFinVigencia.format("YYYY-MM-DD");

    const almacenar = (event) => {
        const { name, value } = event.target;
        let newValue = { ...datosForm, [name]: value };
        
        const densidad = newValue.id_empresa === 1 
            ? newValue.densidad_hipergas 
            : newValue.densidad_autogas;
    
        if (name === "precio_litro_sin_iva") {
            const precioConIva = parseFloat(value) * datosForm.valor;
            newValue.precio_litro_con_iva = precioConIva.toFixed(4);
            newValue.precio_kilo_con_iva = (precioConIva / densidad).toFixed(4);
            newValue.precio_kilo_sin_iva = (newValue.precio_kilo_con_iva / datosForm.valor).toFixed(4);
        }
    
        if (name === "precio_kilo_sin_iva") {
            const precioConIva = parseFloat(value) * datosForm.valor;
            newValue.precio_kilo_con_iva = precioConIva.toFixed(4);
            newValue.precio_litro_con_iva = (precioConIva * densidad).toFixed(4);
            newValue.precio_litro_sin_iva = (newValue.precio_litro_con_iva / datosForm.valor).toFixed(4);
        }
    
        if (name === "precio_litro_con_iva") {
            const precioSinIva = parseFloat(value) / datosForm.valor;
            newValue.precio_litro_sin_iva = precioSinIva.toFixed(4);
            newValue.precio_kilo_sin_iva = (precioSinIva / densidad).toFixed(4);
            newValue.precio_kilo_con_iva = (newValue.precio_kilo_sin_iva * datosForm.valor).toFixed(4);
        }
    
        if (name === "precio_kilo_con_iva") {
            const precioSinIva = parseFloat(value) / datosForm.valor;
            newValue.precio_kilo_sin_iva = precioSinIva.toFixed(4);
            newValue.precio_litro_con_iva = (precioSinIva * densidad).toFixed(4);
            newValue.precio_litro_sin_iva = (newValue.precio_litro_con_iva / datosForm.valor).toFixed(4);
        }
    
        setdatosForm(newValue);

        // console.log("newValue", newValue);
        // console.log("densidad", densidad);
        // console.log("datosForm", datosForm);
        
        
        
    };




    //ESTA FUNCION ES PARA GUARDAR, DEBERIA MODIFICARLA MAS ADELATE PARA HACER UNA TRANSACCION
    const putPrecios = async (e) => {
        putData(e, `/lista_precios/${datosForm.id_lista}`,
            {
              
                precio_litro_con_iva: datosForm.precio_litro_con_iva,
                precio_litro_sin_iva: datosForm.precio_litro_sin_iva,
                precio_kilo_con_iva: datosForm.precio_kilo_con_iva,
                precio_kilo_sin_iva: datosForm.precio_kilo_sin_iva,
                vigencia: moment(datosForm.vigencia).format("YYYY-MM-DD"),
                usuario:localStorage.getItem('user')
            },
            setNotificacion         
            
        )
        console.log("datosForm", datosForm);
        logEventos(
            "Editar lista de precios",
            "LISTA DE PRECIOS GRANEL",
            "Actualiza lista de precios ",
            localStorage.getItem("user")
        );
    };

    const handleClose = () => {
        open(false);
        props.actualizar();
        props.tabla(true);
    };



    return (
        <div>
            <Dialog open={open} fullWidth maxWidth="xl" onClose={handleClose}>
                {datosExisten && (
                    <Box sx={{ padding: 2 }}>
                        {/* Card para Precio Vigente */}
                        <Box backgroundColor={"#1F618D"}>
                            <DialogTitle>
                                <Typography variant="h6" align="center" color="#ffffff">
                                    Editar
                                    <br />
                                    Empresa:{datosForm.empresa}  Sucursal:{datosForm.sucursal}  Segmento:{datosForm.segmento}  Producto: {datosForm.nombre}

                                </Typography>
                            </DialogTitle>
                        </Box>
                        <Card variant="outlined" sx={{ mb: 1, backgroundColor: "#F4F6F6" }}>
                            <CardContent>

                                {/* Precios en un solo renglón */}
                                <Grid container spacing={1} alignItems="center" justifyContent="center">
                                    {[
                                        { label: "Precio litro sin IVA", value: datosForm.precio_litro_sin_iva || '', name: "precio_litro_sin_iva" },
                                        { label: "Precio litro con IVA", value: datosForm.precio_litro_con_iva || '', name: "precio_litro_con_iva" },
                                        { label: "Precio kilo sin IVA", value: datosForm.precio_kilo_sin_iva || '', name: "precio_kilo_sin_iva" },
                                        { label: "Precio kilo con IVA", value: datosForm.precio_kilo_con_iva || '', name: "precio_kilo_con_iva" },
                                    ].map((item, index) => (
                                        <Grid item xs={12} sm={6} md={2} key={index}>
                                            <TextField
                                                fullWidth
                                                style={style}
                                                focused
                                                size="small"
                                                variant="outlined"
                                                label={item.label}
                                                name={item.name}
                                                value={item.value}
                                                onChange={almacenar}
                                            // InputProps={{ readOnly: true }}
                                            />
                                        </Grid>
                                    ))}
                                    {/* Fechas de Vigencia */}
                                    <Grid item xs={12} sm={6} md={2}>
                                        <TextField
                                            fullWidth
                                            style={style}
                                            focused
                                            size="small"
                                            variant="outlined"
                                            label="Entrada en Vigencia"
                                            value={moment(datosForm.vigencia).format("DD-MM-YYYY") || ""}
                                        // InputProps={{ readOnly: true }}
                                        />
                                    </Grid>
                                    {/* <Grid item xs={12} sm={6} md={2}>
                                        <TextField
                                            fullWidth
                                            style={style}
                                            focused
                                            size="small"
                                            variant="outlined"
                                            label="Fin de Vigencia"
                                            value={fechaFinVigenciaFormat || ""}
                                            InputProps={{ readOnly: true }}
                                        />
                                    </Grid> */}
                                </Grid>
                                <Grid
                                    container
                                    justifyContent="space-between"
                                    alignItems="flex-end"
                                    mt={2}
                                >
                                    <Button variant="contained"
                                        onClick={() => open(false)}
                                        color="error">
                                        Volver
                                    </Button>

                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color={"success"}
                                        onClick={putPrecios}
                                    >
                                        Actualizar
                                    </Button>
                                </Grid>
                            </CardContent>


                        </Card>
                        {notificacion && notificacion.open ? (
                            <Notify
                                mensaje={notificacion.mensaje}
                                open={notificacion.open}
                                color={notificacion.color}
                                handleClose={handleClose}
                                severity={notificacion.severity}
                            />
                        ) : null}
                    </Box>
                )}
            </Dialog>

        </div>
    )

}