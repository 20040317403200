import React from 'react'
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AlertTitle from "@mui/material/AlertTitle";

function AlertWButtonCapYPH(props) {
  // console.log("Hola, soy el alert y me abro.")

  return (
    <div>
        <Box mb={1}>
          <Alert
            action={
              <Button
                color="inherit"
                size="small"
                onClick={() => {
                  console.log("Voy a cerrar")
                  props.setTksSinCap([]);
                  props.setTksSinPHOVencida([]);
                }}
              >
                Cerrar
              </Button>
            }
            severity="warning"
          >
            <AlertTitle>Atención !</AlertTitle>
            {props.tksSinCap && props.tksSinCap.length > 0 ? (
              <>
                Algunos de los tanques en la bateria no tienen su capacidad
                registrada.{" "}
                <strong>
                  Sólo será posible registrar la cantidad de KG solicitados
                </strong>
                <br></br>
              </>
            ) : null}
            {props.tksSinPHOVencida.length > 0 ? (
              <>
                Los tanques{" "}
                {props.tksSinPHOVencida.map((tanque) => (
                  <strong> {tanque}, </strong>
                ))}{" "}
                tienen su fecha de prueba hidráulica vencida, o no ha sido
                registrada.
                <strong>- Revisar. </strong>
              </>
            ) : null}
          </Alert>
        </Box>
    </div>
  )
}

export default AlertWButtonCapYPH