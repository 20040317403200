import React, { useState, useEffect, useRef } from "react";
import { UrlApi } from "../../../services/apirest";
import MapaCliente from "./MapaCliente";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {getData} from "../../../services/library"
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";


function FormDirAmpliacion(props) {
  const [sucursales, setSucursales]=useState();
  const readOnly = props.readOnly;
  const latitudRegex = /^-?([1-8]?\d(\.\d+)?|90(\.0+)?)$/;
  const longitudRegex =
    /^-?((1[0-7]\d(\.\d+)?|180(\.0+)?)|([0-9]?\d(\.\d+)?))$/;
  const regexTxt = /^-?\d*\.?\d*$/; // Expresión regular para permitir solo números, punto decimal opcional y el signo "-"
  const sucAuth = localStorage.getItem("suc_auth").split(",").map(Number);
  const almacenar = (e) => {
    props.setDataDir({ ...props.dataDir, [e.target.name]: e.target.value });
  };

  useEffect(()=>{
    getData("/sucursales/tipoPlanta", setSucursales)
  },[props.dataDir.mostrar_desplegable])
  const variant = "standard";


  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        pt={2}
      >
        <Grid item xs={5}>
          <Typography variant="subtitle2" color="#000" sx={{ fontSize: 15 }}>
            Lugar: {props.dataDir.nombre_direccion}
          </Typography>
          <Typography variant="subtitle2" color="#000" sx={{ fontSize: 15 }}>
            Dirección: {props.dataDir.direccion_entrega_mostrar}
          </Typography>
          <Typography variant="subtitle2" color="#000" sx={{ fontSize: 15 }}>
            Provincia: {props.dataDir.provincia}
          </Typography>
          <Typography variant="subtitle2" color="#000" sx={{ fontSize: 15 }}>
            Localidad: {props.dataDir.localidad}
          </Typography>
          {
            !props.dataDir.mostrar_desplegable&&(
              <Typography variant="subtitle2" color="#000" sx={{ fontSize: 15 }}>
              Sucursal: {props.dataDir.sucursal}
            </Typography>
            )
          }
          {/* lo comento porque ahora la empresa esta en el cliente */}
           {/* <Typography variant="subtitle2" color="#000" sx={{ fontSize: 15 }}>
              Empresa: {props.direccion.empresa}
            </Typography> */}
          <Typography variant="subtitle2" color="#000" sx={{ fontSize: 15 }}>
            Cliente: {props.dataDir.cliente}
          </Typography>
          <Typography variant="subtitle2" color="#000" sx={{ fontSize: 15 }}>
            Latitud: {props.dataDir.latitud}
          </Typography>
          <Typography variant="subtitle2" color="#000" sx={{ fontSize: 15 }}>
            Longitud: {props.dataDir.longitud}
          </Typography>

          <Typography variant="subtitle2" color="#000" sx={{ fontSize: 15 }}>
            Distancia Cliente - Sucursal: {props.dataFact.dist_suc_cli} km
          </Typography>
          <Typography variant="subtitle2" color="#000" sx={{ fontSize: 15 }}>
            Distancia Productor - Sucursal: {props.dataSucursal.distanciabocacarga} km
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {props.dataDir.mostrar_desplegable && <Grid item xs={12}>
            <FormControl
                size="small"
                fullWidth
                variant="outlined"
                color={!props.dataDir.id_sucursal?"warning":"primary"}
                required
              >
                <InputLabel>Sucursal</InputLabel>
                <Select
                  // inputRef={sucRef}
                  name="id_sucursal"
                  label="Sucursal"
                  value={props.dataDir.id_sucursal}
                  onChange={almacenar}
                >
                  {sucursales &&
                    sucursales
                      .filter((sucursal) => sucAuth.includes(sucursal.id) || sucursal.id===parseInt(props.dataDir.id_sucursal))
                      .map((sucursal) => (
                        <MenuItem key={sucursal.id} value={sucursal.id}>
                          {sucursal.nombre}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
              
              
              
              </Grid>}

            <Grid item xs={12} style={{ height: "280px" }}>
              <MapaCliente
                sucursalId={props.dataDir.id_sucursal}
                setDataDir={props.setDataDir}
                dataDir={props.dataDir}
                dataSucursal={props.dataSucursal}
                setDataSucursal={props.setDataSucursal}
                mapParams={props.mapParams}
                setMapParams={props.setMapParams}
                dataFact={props.dataFact}
                setDataFact={props.setDataFact}
                latLongParams={props.latLongParams}
                setLatLongParams={props.setLatLongParams}
                readOnly={readOnly}
                setActiveTab={props.setActiveTab}
                direccionAmpliacion={props.direccion}
                txtFieldLabel={"Buscar en mapa: "}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default FormDirAmpliacion;
