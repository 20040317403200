import React from 'react'
import Stack from "@mui/material/Stack";
import AlertTitle from "@mui/material/AlertTitle";
import Alert from '@mui/material/Alert';

function Alerta(props) {
  return (
    <div>
        <Stack sx={{ width: "100%" }} spacing={2} mt={props.marginTop?props.marginTop:2}>
            <Alert severity={props.severity}>
              <AlertTitle>{props.titulo? props.titulo : "ERROR" }</AlertTitle>
              <strong>{props.mensaje}</strong> 
            </Alert>
          </Stack>

    </div>
  )
}

export default Alerta