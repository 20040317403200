import React, { useEffect, useState } from "react";
import { getData } from "../../../../services/library";
import Navbar from "../../../Navbar";
import { IconButton, Container, Stack, Box, Autocomplete, TextField, Button, Accordion, AccordionSummary, AccordionDetails, Typography, Divider, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, List, Card, CardHeader, Avatar } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { grey } from "@mui/material/colors";
import OpenRendiciones from "./OpenRendiciones";
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import moment from "moment";

export default function RepartosFinalizados() {
    const [sucursales, setSucursales] = useState([]);
    const [sucursalSelected, setsucursalSelected] = useState("");
    const [openRendiciones, setopenRendiciones] = useState(false);
    const [mostrarFicha, setmostrarFicha] = useState(true);
    const [fecha, setFecha] = useState(moment(new Date()).format("YYYY-MM-DD"));
    


    const getPlantas = () => {
        getData('/sucursales/tipoPlanta', setSucursales)
    };

    const abrirRendiciones = (id) => {
        setsucursalSelected(id); // Actualiza el estado
        setopenRendiciones(true);
        setmostrarFicha(false);

        // Trabaja directamente con el valor
        // console.log("Seleccionando sucursal con id:", id);
    };
    useEffect(() => {
        getPlantas()
    }, []);

    const handleVolver = () => {
        setmostrarFicha(true)
        setopenRendiciones(false)
    };

    const handleChangeFecha = (newValue) => {
        setFecha(moment(newValue).format('YYYY-MM-DD'));
    };

    const handleChangeMas = () => {
        setFecha(moment(fecha).add(1, 'days').format("YYYY-MM-DD"));
    };
    const handleChangeMenos = () => {
        setFecha(moment(fecha).subtract(1, 'days').format("YYYY-MM-DD"));
    };

    const handleChangeHoy = () => {
        setFecha(moment(Date()).format("YYYY-MM-DD"));
    };

    // console.log("sucursales", sucursales);


    return (
        <div>
            <Navbar />
            <Container disableGutters mt={1} mb={5} >
                <Box sx={{ flexGrow: 1, backgroundColor: "#1F618D", mt: 2 }}>
                    <Grid container p={1} justifyContent="center" alignItems="center">
                        <Grid item xs={2} >
                            <IconButton onClick={handleVolver} sx={{ border: 'white', color: "#ffffff" }}>
                                <ArrowBackIcon sx={{ color: grey[50] }} />
                            </IconButton>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="h5" color="#fff" textAlign="center">
                                Listado de repartos finalizados
                            </Typography>
                        </Grid>
                        {
                            !mostrarFicha && openRendiciones &&
                            <Grid item xs={2} sm={2} md={2}>
                                <Typography color={"white"} textAlign={"center"}>Finalizado el: {fecha}</Typography>
                                <Button variant="outlined" sx={{ backgroundColor: "white", '&:hover': { backgroundColor: "#f5f5f5" } }} size="small" onClick={handleChangeMenos} startIcon={<FastRewindIcon />}></Button>
                                <Button variant="outlined" sx={{ backgroundColor: "white", '&:hover': { backgroundColor: "#f5f5f5" } }} size="small" color="error" onClick={handleChangeHoy} >Hoy</Button>
                                <Button variant="outlined" sx={{ backgroundColor: "white", '&:hover': { backgroundColor: "#f5f5f5" } }} size="small" onClick={handleChangeMas} endIcon={<FastForwardIcon />}></Button>
                            </Grid>
                        }

                    </Grid>
                </Box>
                <Grid container spacing={1} >
                    {!openRendiciones && mostrarFicha && sucursales ?
                        sucursales.map(elemento =>
                            <Grid item xs={6} sm={6} md={4} key={elemento.id}>

                                <Card
                                    variant="outlined"
                                    onClick={() => abrirRendiciones(elemento.id)}
                                    style={{
                                        backgroundColor: "#ffffe6", boxShadow: "1px 4px grey", cursor: 'pointer'
                                    }} >
                                    <CardHeader
                                        avatar={<Stack>
                                            <Avatar sx={{ bgcolor: elemento.color_id }} aria-label="recipe">
                                                {elemento.avatar}
                                            </Avatar>
                                        </Stack>
                                        }
                                        titleTypographyProps={{ variant: 'h5' }}
                                        title={`Sucursal: ${elemento.nombre}`}
                                    />
                                </Card>
                            </Grid>
                        )
                        : null
                    }
                </Grid>

                {openRendiciones &&
                    <OpenRendiciones
                        sucursalSelected={sucursalSelected}
                        setopen={setopenRendiciones}
                        open={openRendiciones}
                      
                        fecha={fecha}
                        setFecha={setFecha}
                    />}
            </Container>
        </div>
    )

}