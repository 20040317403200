
import React, { useState, useEffect } from "react";
import { Container, Grid, Box, TextField, Button, Autocomplete, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { logEventos } from "../../../../services/services";
import Title from "../../../Title";
import { getData, postData, putData } from "../../../../services/library";
import Actualizar_precio from "./Actualizar_precio";
import FormPrecios from "./FormPrecios";
import Notify from '../../../Notify';
import moment from "moment";

export default function AddPrecio(props) {
    const [datoslista, setDatoslista] = useState({
        id_empresa: null,
        id_sucursal: null,
        id_segmento: null,
        id_producto: "",
        precio_litro_con_iva: "",
        precio_litro_sin_iva: "",
        precio_kilo_con_iva: "",
        precio_kilo_sin_iva: "",
        vigencia: "",       
        usuario: localStorage.getItem('user'),
        fin_vigencia:null       
    });

    // Fecha fin vigencia
  const fechaVigencia = moment(datoslista && datoslista.vigencia);
  const fechaFinVigencia = fechaVigencia.subtract(1, "day");
  const fechaFinVigenciaPut = fechaFinVigencia.format("YYYY-MM-DD");

    // console.log("datosLista", datoslista);

    const [disabledGuardar, setDisabledGuardar] = useState(false);
    const [sucursal, setSucursal] = useState([]);
    const [sucursalSeleccionada, setSucursalSeleccionada] = useState([]);
    const [productos, setProductos] = useState([]);
    const [segmento, setSegmento] = useState();
    const [segmentoSeleccionado, setSegmentoSeleccionado] = useState("");
    const [productoSeleccionado, setproductoSeleccionado] = useState("");
    const [empresas, setEmpresas] = useState([]);
    const [empresaSelected, setempresaSelected] = useState ("");
    const [datosExisten, setdatosExisten] = useState();
    const [cambieProd, setcambieProd] = useState(false);
    const [cambieSeg, setcambieSeg] = useState(false);
    const [cambieSuc, setcambieSuc] = useState(false);
    const [cambieEmpr, setcambieEmpr]= useState(false);
    const [notificacion, setNotificacion] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
    });

    useEffect(() => {
        // getData('/sucursales', setSucursal);
        getData('/empresas', setEmpresas)
        getData('/segmentos', setSegmento);
        getData('/sucursales/tipoPlanta', setSucursal,)
        getData('/productos/sector/249', setProductos) //aca me tengo que traer los productos por sector en este caso de granel    
    }, []);

    // useEffect(()=>{
    //     getData(`/sucursales/empresa/${empresaSelected.idempresa}`, setSucursal)
    // },[empresaSelected.idempresa]);       
    
    useEffect(() => {
        if (
            empresaSelected?.idempresa &&
            sucursalSeleccionada.length > 0 &&
            segmentoSeleccionado?.id &&
            productoSeleccionado?.idproducto
        ) {
            
            const sucursalesIds = sucursalSeleccionada.map(sucursal => sucursal.id).join(",");
            
           
            getData(
                `/lista_precios/verificar/${empresaSelected.idempresa}/${sucursalesIds}/${segmentoSeleccionado.id}/${productoSeleccionado.idproducto}`,
                setdatosExisten
            );
            console.log( `/lista_precios/verificar/${empresaSelected.idempresa}/${sucursalesIds}/${segmentoSeleccionado.id}/${productoSeleccionado.idproducto}`);
            
        }
    }, [empresaSelected.idempresa, sucursalSeleccionada, segmentoSeleccionado, productoSeleccionado]);
    
       useEffect(() => {
        if (cambieProd || cambieSeg  || cambieEmpr) {
          setDatoslista((prevDatosLista) => ({
            ...prevDatosLista,
            precio_litro_con_iva: "",
            precio_litro_sin_iva: "",
            precio_kilo_con_iva: "",
            precio_kilo_sin_iva: "",
          }));
          // Resetear banderas después de ejecutar el efecto
          setcambieProd(false);
          setcambieSeg(false);
          setcambieSuc(false);
          setcambieEmpr(false);
        }
    }, [cambieProd, cambieSeg, cambieSuc, cambieEmpr]);

    useEffect(()=>{
        if(cambieEmpr){
            setSucursalSeleccionada([])
        }
    }, [cambieEmpr]);     

    console.log("cambie empres", cambieEmpr);
    

    //una vez que obtengo el producto de ahi hago el calculo del iva por kilo o litro segun corresponda 
    //y densidad base - esto es para que se me llenen todos los campos solos directamente agregando un solo valor
    const almacenar = (event) => {
            const { name, value } = event.target;
            let newValue = { ...datoslista, [name]: value };
            
            const densidad = datoslista.id_empresa === 1 
                ? productoSeleccionado.densidad_hipergas 
                : productoSeleccionado.densidad_autogas;
        
            if (name === "precio_litro_sin_iva") {
                const precioConIva = parseFloat(value) * productoSeleccionado.valor;
                newValue.precio_litro_con_iva = precioConIva.toFixed(4);
                newValue.precio_kilo_con_iva = (precioConIva / densidad).toFixed(4);
                newValue.precio_kilo_sin_iva = (newValue.precio_kilo_con_iva / productoSeleccionado.valor).toFixed(4);
            }
        
            if (name === "precio_kilo_sin_iva") {
                const precioConIva = parseFloat(value) * productoSeleccionado.valor;
                newValue.precio_kilo_con_iva = precioConIva.toFixed(4);
                newValue.precio_litro_con_iva = (precioConIva * densidad).toFixed(4);
                newValue.precio_litro_sin_iva = (newValue.precio_litro_con_iva / productoSeleccionado.valor).toFixed(4);
            }
        
            if (name === "precio_litro_con_iva") {
                const precioSinIva = parseFloat(value) / productoSeleccionado.valor;
                newValue.precio_litro_sin_iva = precioSinIva.toFixed(4);
                newValue.precio_kilo_sin_iva = (precioSinIva / densidad).toFixed(4);
                newValue.precio_kilo_con_iva = (newValue.precio_kilo_sin_iva * productoSeleccionado.valor).toFixed(4);
            }
        
            if (name === "precio_kilo_con_iva") {
                const precioSinIva = parseFloat(value) / productoSeleccionado.valor;
                newValue.precio_kilo_sin_iva = precioSinIva.toFixed(4);
                newValue.precio_litro_con_iva = (precioSinIva * densidad).toFixed(4);
                newValue.precio_litro_sin_iva = (newValue.precio_litro_con_iva / productoSeleccionado.valor).toFixed(4);
            }
        
            setDatoslista(newValue);
    };        

    //**********************************************************************************************************************************//
    const style = {
        backgroundColor: "#ffffff",
        width: "100%",
    };
    const size = "small";

    const handleClose = () => {
        props.alta(false);
        props.tabla(true);
    };
    //*************************** GUARDAR *******************************************************************************************************//
    const guardarPrecio = async (e) => {
        logEventos(
            "Guardar nuevo precio",
            "Lista de Precios",
            "Se guardo el nuevo precio",
            localStorage.getItem("user")
        );
        setDisabledGuardar(true)

        const reqBody = {
            body: sucursalSeleccionada?.map((s) => ({
              id_sucursal: s.id,
              id_empresa: datoslista.id_empresa,
              id_segmento: datoslista.id_segmento,
              id_producto: datoslista.id_producto,
              precio_litro_con_iva: datoslista.precio_litro_con_iva,
              precio_litro_sin_iva: datoslista.precio_litro_sin_iva,
              precio_kilo_con_iva: datoslista.precio_kilo_con_iva, // Corrige aquí
              precio_kilo_sin_iva: datoslista.precio_kilo_sin_iva,
              vigencia: datoslista.vigencia,
              usuario: localStorage.getItem("user"),
              fin_vigencia: datoslista.fin_vigencia,
            })),
          };

        console.log("Reqbody", reqBody);
        

        postData(e,
            '/lista_precios/add_form',
            reqBody,
            setNotificacion
        );
    };

    const putPrecios = async (e) => {
       // GUARDAMOS LA LISTA DE PRECIOS NUEVA
    postData(e, '/lista_precios', {
        id_empresa: datoslista.id_empresa,
        id_sucursal: sucursalSeleccionada.id,
        id_segmento: segmentoSeleccionado.id,
        id_producto: productoSeleccionado.idproducto,
        precio_litro_con_iva: datoslista.precio_litro_con_iva,
        precio_litro_sin_iva: datoslista.precio_litro_sin_iva,
        precio_kilo_con_iva: datoslista.precio_kilo_con_iva,
        precio_kilo_sin_iva: datoslista.precio_kilo_sin_iva,
        vigencia: datoslista.vigencia,
        usuario: localStorage.getItem("user"),
    }, setNotificacion);

    // putData(e, `/lista_precios/${datosExisten[0].id_lista}`, {
    //     fin_vigencia: fechaFinVigenciaPut,
    //     usuario:localStorage.getItem('user')
    // }, setNotificacion);

    //guardamos las acciones
    logEventos(
        "Guardar actualización del precio",
        "Lista de precios",
        "Se guardo el precio actualizado",
        localStorage.getItem("user")
    );

    };

    // console.log("datos Existen",datosExisten)
    // console.log("URL", `/lista_precios/existe/${empresaSelected.idempresa}/${sucursalSeleccionada.id}/${segmentoSeleccionado.id}/${productoSeleccionado.idproducto}`)

    //*************************** HABILITAR Y DESHABILITAR BOTON GUARDAR ***************************************************************************************//
    useEffect(() => {
        if (datoslista.precio_litro_con_iva === "" || datoslista.precio_litro_sin_iva === "" || datoslista.precio_kilo_con_iva === "" || datoslista.precio_kilo_sin_iva === "" || datoslista.vigencia === "") {
            setDisabledGuardar(true);
        }
        if (datosExisten && datosExisten.length !== 0) {
            setDisabledGuardar(true);
        }
        else {
            setDisabledGuardar(false);
        }
    }, [datoslista.precio_litro_con_iva, datoslista.precio_litro_sin_iva, datoslista.precio_kilo_con_iva, datoslista.precio_kilo_sin_iva, datoslista.vigencia, datosExisten]);
    
    // console.log("datoslista", datoslista.vigencia);
    
    return (
        <div>
            <Container>
                <Title
                    titulo={"Nuevo Precio"}
                    handleClose={handleClose} />
                <Box
                    backgroundColor="#F4F6F6"
                    mt={2}
                    p={3}
                    mb={5}
                    border={0.5}
                    borderColor={"#ABB2B9"}
                    borderRadius={4}
                >
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                    >
                         <Grid item xs={3}>
                            {/* {sucursal && ( */}
                                <Autocomplete
                                    disabled={!empresas}
                                    size={size}
                                    focused
                                    disableClearable
                                    id="empresas"
                                    noOptionsText={"Empresa INEXISTENTE"}
                                    options={empresas}
                                    autoHighlight
                                    getOptionLabel={(empresa) =>
                                        empresa.empresa
                                    }
                                    onChange={(event, value) =>{
                                        setempresaSelected(value)
                                        setcambieEmpr(true)
                                        setDatoslista((prev) => ({
                                            ...prev,
                                            id_empresa: value ? value.idempresa : null, // Actualiza id_empresa
                                        }));
                                    } }
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            key={option.id}
                                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                            {...props}
                                        >
                                            {option.empresa}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            style={style}
                                            required
                                            focused
                                            size="small"
                                            placeholder="Tipee para buscar..."
                                            {...params}
                                            label="Empresa"
                                            inputProps={{
                                                ...params.inputProps,
                                            }}
                                        />
                                    )}
                                />
                            {/* )} */}
                        </Grid>
                        <Grid item xs={3}>
                            {sucursal && (
                                <Autocomplete
                                multiple
                                    autoFocus
                                    disableClearable
                                    size={size}
                                    disabled={!sucursal}
                                    noOptionsText={"Sucursal INEXISTENTE"}
                                    options={sucursal}
                                    getOptionLabel={(suc) => suc.nombre}
                                    onChange={(event, value) => {
                                        setSucursalSeleccionada(value)
                                        setcambieSuc(true)
                                        // setDatoslista({ ...datoslista, ["id_sucursal"]: value.id })
                                    }}
                                    // renderOption={(props, option) => (
                                    //     <li {...props} key={option.id}>
                                    //        {option.nombre}
                                    //     </li>
                                    // )}
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            style={style}
                                            // value={datoslista.id_sucursal}
                                            focused
                                            placeholder="Sucursal.."
                                            {...params}
                                            label="Sucursal"
                                            inputProps={{
                                                ...params.inputProps
                                            }}
                                        />
                                    )}
                                />
                            )}
                        </Grid>
                        <Grid item xs={3}>
                            {segmento && (
                                <Autocomplete
                                    disabled={!segmento}
                                    size={size}
                                    focused
                                    disableClearable
                                    id="segmentos"
                                    noOptionsText={"Segmento INEXISTENTE"}
                                    options={segmento}
                                    autoHighlight
                                    getOptionLabel={(segmento) =>
                                         segmento.segmento
                                    }
                                    onChange={(event, value) =>{ 
                                        setSegmentoSeleccionado(value)
                                        setcambieSeg(true)
                                        setDatoslista((prev) => ({
                                            ...prev,
                                            id_segmento: value ? value.id : null, // Actualiza id_segmento
                                        }));
                                    }}
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            key={option.id}
                                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                            {...props}
                                        >
                                            {option.segmento}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            style={style}
                                            required
                                            focused
                                            size="small"
                                            placeholder="Tipee para buscar..."
                                            {...params}
                                            label="Segmento"
                                            inputProps={{
                                                ...params.inputProps,
                                            }}
                                        />
                                    )}
                                />
                            )}
                        </Grid>
                        <Grid item xs={3}>
                            {productos && (
                                <Autocomplete
                                    disabled={!productos}
                                    size={size}
                                    focused
                                    disableClearable
                                    id="productos"
                                    noOptionsText={"Producto INEXISTENTE"}
                                    options={productos}
                                    autoHighlight
                                    getOptionLabel={(productos) =>
                                        productos.nombre
                                    }
                                    onChange={(event, value) => {
                                        setproductoSeleccionado(value)
                                        setcambieProd(true)
                                        setDatoslista((prev) => ({
                                            ...prev,
                                            id_producto: value ? value.idproducto : null, // Actualiza id_segmento
                                        }));
                                    }}
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            key={option.id}
                                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                            {...props}
                                        >
                                            {option.nombre}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            style={style}
                                            required
                                            focused
                                            size="small"
                                            placeholder="Tipee para buscar..."
                                            {...params}
                                            label="Producto"
                                            inputProps={{
                                                ...params.inputProps,
                                            }}
                                        />
                                    )}
                                />
                            )}
                        </Grid>
                        {datosExisten && datosExisten.length === 0 ?
                            <FormPrecios
                                size={size}
                                style={style}
                                almacenar={almacenar}
                                datoslista={datoslista} /> :
                            <Actualizar_precio
                                datosExisten={datosExisten}
                                datoslista={datoslista}
                                size={size}
                                style={style}
                                // fechaFinVigenciaFormat={fechaFinVigenciaFormat}
                                setDatoslista={setDatoslista}
                                disabledGuardar={disabledGuardar}
                                setDisabledGuardar={setDisabledGuardar}
                                 />
                            // "hola"
                        }

                    </Grid>
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="flex-end"
                        mt={2}
                    >
                        <Button variant="contained" onClick={handleClose} color="error">
                            Volver
                        </Button>

                        <Button
                            variant="contained"
                            type="submit"
                            color={"success"}
                            onClick={datosExisten && datosExisten.length > 0 ? putPrecios : guardarPrecio}
                            disabled={disabledGuardar}
                        >
                            {datosExisten && datosExisten.length > 0 ? "Actualizar" : "Guardar Precio"}
                        </Button>
                    </Grid>
                </Box>
            </Container>
            {notificacion && notificacion.open ? (
                <Notify
                    mensaje={notificacion.mensaje}
                    open={notificacion.open}
                    color={notificacion.color}
                    handleClose={handleClose}
                    severity={notificacion.severity}
                />
            ) : null}
        </div>
    )
};

//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio / Nicolas pascucci
// Creation Date :26/06/2024
// Version : 1
// Description : Agregar precios
//------Changes ----------------------------------------------------------//
// Change Date : 15/8
// Change by : Candela Grosso
// Change description: Acomode el titulo y saque los console.log
// Change Date : 06/09/2024
// Change by : Candela Grosso
// Change description: Agregamos el producto al formulario y lo que hago es lo siguiente
    //-PRIMERO ELIJO SUCURSAL, SEGMENTO Y PRODUCTO
    //- SI NO HAY DATOS CARGADOS PARA ESO PUEDO CARGAR UNO NUEVO TODO OK QUE ESE FORMULARIO LO MANEJO DESDE FORMPRECIOS
    //- SI HAY DATOS CARGADOS HABILITA OTRO FORMULARIO PARA ACTUALIZAR ESE PRECIO ESO LO ESTOY HACIENDO EL DIA DE HOY Y LO TENGO QUE TERMINAR 
    //- PERO POR AHORA FUNCIONA . ME PARECE MASS PRACTICO SEPARAR EN COMPONENTES Y QUE SE PUEDA HACER TODO DE UNA 
// BUENO BESOS , AH Y ESTE COMPONENTE ES PARA LISTA DE PRECIOS DE GRANEL YA QUE DESPUES TENGO QUE HACER LA DE ENVASADO J3J3NT
//========================================================================/

// useEffect(() => {
    //     // getData(`/lista_precios/existe/${sucursalSeleccionada.id}/${segmentoSeleccionado.id}/${productoSeleccionado.idproducto}`, setdatosExisten);
    //     getData(`/lista_precios/existe/${empresaSelected.idempresa}/${sucursalSeleccionada}/${segmentoSeleccionado.id}/${productoSeleccionado.idproducto}`, setdatosExisten);
    // }, [empresaSelected.idempresa, sucursalSeleccionada, segmentoSeleccionado, productoSeleccionado]);