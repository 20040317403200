import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Alert from '@mui/material/Alert';

export default function ModalVencimientos(props) {   

    const handleClose = () => {
        props.open(false)
    };

    return (
        <Dialog open={props.open} maxWidth="lg">
            <DialogTitle>
            <Alert severity="warning">Atención
            {props.vtv === false ? <Typography>El vehiculo tiene vencida la VTV</Typography> : null}
               {props.hidraulica === false ? <Typography>El vehiculo tiene vencida la prueba hidraulica</Typography> : null}
             {props.licencia === false ? <Typography>El chofer tiene vencida la licencia de conducir</Typography> : null}
            {props.carga === false ? <Typography>El chofer tiene vencida la licencia de cargas peligrosas</Typography> : null}</Alert>
            </DialogTitle>
            {/* <DialogContent>
                <DialogContentText>                
                </DialogContentText>
            </DialogContent>                */}
            
            <DialogActions>
                <Button onClick={handleClose} variant="outlined" color="success" > Volver</Button>
            </DialogActions>
        </Dialog>
    )

}
