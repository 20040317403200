import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import Icon from '@mui/material/Icon';
import { TreeView, TreeItem } from '@mui/x-tree-view'
import { AdminPanelSettingsTwoTone, ChevronRight, ExpandMore, PasswordRounded, SuperscriptOutlined } from '@mui/icons-material'
import { Typography, IconButton, Box, Button } from '@mui/material';
import { UrlApi } from '../../services/apirest';

export default function ArmaMenu({ data, parentItem }) {
    let navigate = useNavigate();
    const [query, setQuery] = useState("")


    const [cambiapassword, setCambiaPassword] = useState('N')

    useEffect(() => {
        const VerificaPass = () => {
            fetch(UrlApi + '/userpass/verify', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username: localStorage.getItem("user"), password: "123456"
                })
            })
                .then(response => response.json())
                .then(data => setCambiaPassword(data.message));
        }
        VerificaPass()
    }, [])

    //console.log("es aca ", cambiapassword)
    const iraruta = (ruta) => {
        navigate("/" + ruta)
    };

    return (
        <>
            {cambiapassword === 'N' ?
                <TreeView defaultCollapseIcon={<ExpandMore />} defaultExpandIcon={<ChevronRight />}>
                    {data
                        .filter(item => item.menu_padre_id === parentItem)
                        .map(item => (
                            <TreeItem
                                key={item.id_menu}
                                nodeId={item.id_menu.toString()}
                                label={<>
                                    <Typography sx={{ display: "flex", alignItems: "center", fontFamily: item.font_family }}>
                                        <Icon sx={{ paddingRight: '5px', paddingBottom: '5px' }}>{item.icon}</Icon>{item.menu}
                                    </Typography>
                                </>}
                                //icon={<Icon>{item.icon}</Icon>}
                                onClick={() => {
                                    if (!data.some(subItem => subItem.menu_padre_id === item.id_menu)) {
                                        iraruta(item.ruta);
                                    }
                                }}
                                sx={{
                                    fontFamily: item.font_family,
                                    backgroundColor: item.background_color,
                                    borderTop: '1px solid teal'
                                }}
                            >
                                {data.some(subItem => subItem.menu_padre_id === item.id_menu) ? (
                                    <ArmaMenu data={data} parentItem={item.id_menu} />
                                ) : null}
                            </TreeItem>
                        ))}
                </TreeView>
                :
                <>
                    <Box sx={{
                        display: 'flex', // Usa flexbox para alinear el contenido
                        flexDirection: 'column', // Dirección de los items (opcional si solo es un elemento)
                        alignItems: 'center', // Centrado horizontal
                        justifyContent: 'center', // Centrado vertical
                        width: '100%', // Asegúrate de que ocupa todo el ancho disponible
                        height: '10vh'
                    }}> <Typography variant='h6' color='error' >Debe cambiar Contraseña</Typography></Box>
                    <Box
                        sx={{
                            display: 'flex',       // Activa flexbox
                            flexDirection: 'column', // Orientación vertical de los elementos
                            alignItems: 'center',   // Centrado horizontal (a lo largo del eje transversal)
                            justifyContent: 'center', // Centrado vertical (a lo largo del eje principal)
                            height: '5vh'          // Altura del Box para asegurarse de que hay espacio para centrar (ajuste según necesidad)
                        }}
                    >
                        <Button variant='outlined'
                            onClick={() => navigate("/infousuario")}>
                            <AdminPanelSettingsTwoTone />Cambiar contraseña
                        </Button>
                    </Box>

                </>
            }
        </>
    )
}
