import React, {useState} from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import { BarChart } from "@mui/x-charts/BarChart";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function LineChart() {
  const [visibleBars, setVisibleBars] = useState(12); // Número inicial de barras visibles
  const [filteredMonths, setFilteredMonths] = useState([]);

  const handleVisibleBarsChange = (event, newValue) => {
    setVisibleBars(newValue);
  };

  const handleMonthCheckboxChange = (month) => {
    setFilteredMonths((prevMonths) =>
      prevMonths.includes(month)
        ? prevMonths.filter((m) => m !== month)
        : [...prevMonths, month]
    );
  };

  const chartSetting = {
    xAxis: [
      {
        label: "rainfall (mm)",
      },
    ],
    width: 800,
    height: 600,
  };
  const dataset = [
    {
      london: 59,
      paris: 57,
      newYork: 86,
      seoul: 21,
      month: "Enero",
    },
    {
      london: 50,
      paris: 52,
      newYork: 78,
      seoul: 28,
      month: "Feb",
    },
    {
      london: 47,
      paris: 53,
      newYork: 106,
      seoul: 41,
      month: "Marzo",
    },
    {
      london: 54,
      paris: 56,
      newYork: 92,
      seoul: 73,
      month: "Abril",
    },
    {
      london: 57,
      paris: 69,
      newYork: 92,
      seoul: 99,
      month: "Mayo",
    },
    {
      london: 60,
      paris: 63,
      newYork: 103,
      seoul: 144,
      month: "Junio",
    },
    {
      london: 59,
      paris: 60,
      newYork: 105,
      seoul: 319,
      month: "Julio",
    },
    {
      london: 65,
      paris: 60,
      newYork: 106,
      seoul: 249,
      month: "Agosto",
    },
    {
      london: 51,
      paris: 51,
      newYork: 95,
      seoul: 131,
      month: "Sept",
    },
    {
      london: 60,
      paris: 65,
      newYork: 97,
      seoul: 55,
      month: "Oct",
    },
    {
      london: 67,
      paris: 64,
      newYork: 76,
      seoul: 48,
      month: "Nov",
    },
    {
      london: 61,
      paris: 70,
      newYork: 103,
      seoul: 25,
      month: "Dic",
    },
  ];

  const valueFormatter = (value) => `${value}mm`;

  // Filtrar los meses que deseas ocultar
  const filteredDataset = dataset.filter((data) => !filteredMonths.includes(data.month));

  // const visibleDataset = dataset.slice(0, visibleBars); // Obtener el subconjunto de datos visible
  const visibleDataset = filteredDataset.slice(0, visibleBars);

  return (
    <Stack
    direction="column"
    sx={{
      width: "800px",
      height: "100%",
    }}
  >
    <Box sx={{ flexGrow: 1 }}>
      <BarChart
        dataset={visibleDataset}
        yAxis={[{ scaleType: "band", dataKey: "month" }]}
        series={[
          { dataKey: "seoul", label: "Seoul rainfall", valueFormatter },
        ]}
        layout="horizontal"
        {...chartSetting}
      />
    </Box>
    <Box sx={{ width: "100%" }}>
      <Typography id="input-visible-bars" gutterBottom>
        Número de barras visibles
      </Typography>
      <Slider
        value={visibleBars}
        onChange={handleVisibleBarsChange}
        valueLabelDisplay="auto"
        min={1}
        max={filteredDataset.length}
        aria-labelledby="input-visible-bars"
      />

      <Typography id="input-month-filter" gutterBottom>
        Filtrar meses:
      </Typography>
      <Stack direction="row">
        {["Enero", "Feb", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Sep", "Oct", "Nov", "Dic"].map((month) => (
          <FormControlLabel
            key={month}
            control={
              <Checkbox
                checked={!filteredMonths.includes(month)}
                onChange={() => handleMonthCheckboxChange(month)}
              />
            }
            label={month}
          />
        ))}
      </Stack>
    </Box>
  </Stack>
  );
}
