import React, { useState, useEffect } from "react";
import { FormControl, Typography, InputLabel, Select, MenuItem, Grid, Box, TextField, DialogTitle, Button, Dialog, DialogContent } from "@mui/material";
import Notify from "../../Notify";
import { getData, postData, putData } from "../../../services/library";

export default function EditarProductos(props) {
    const [datosProductos, setdatosProductos] = useState(props.datos);
    const [notificacion, setNotificacion] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
    });
    const [uso, setUso] = useState([]);
    const [porcentajes, setporcentajes] = useState([]);
    const [disBtn, setDisBtn] = useState(false);
    const style = {
        backgroundColor: "#ffffff",
        width: "100%",
    };
    const size = "small";

    useEffect(() => {
        getData('/desplegables/uso', setUso);
        getData('/porcentaje_iva', setporcentajes)
    }, []);

    const almacenar = (e) => {
        setdatosProductos({
            ...datosProductos,
            [e.target.name]: e.target.value === "" ? null : e.target.value,
        });
    };

    const handleClose = () => {
        props.open(false);
        props.tabla(true)
        props.actualizar();
    }; 

    // console.log("datosproductos", datosProductos);   


    async function saveProductos(e) {
        setDisBtn(true);
        await putData(e,
            '/productos/'+datosProductos.idproducto,
            {
                id_porcentajeiva: datosProductos.id_porcentajeiva,
                id_uso: datosProductos.id_uso,
                codigoproducto: datosProductos.codigoproducto,
                nombre: datosProductos.nombre,
                usuario: localStorage.getItem('user'),
                peso: datosProductos.peso,
                densidadbase: datosProductos.densidadbase ?? null,
                detalle_factura: datosProductos.detalle_factura,
                abreviatura: datosProductos.abreviatura,
                densidad_autogas: datosProductos.densidad_autogas,
                densidad_hipergas: datosProductos.densidad_hipergas
            },
            setNotificacion,
            
        )
    };

    return (
        <Dialog open={props.open} onClose={handleClose} maxWidth="xl" fullWidth>
            <Box justifyContent="center" backgroundColor="#1F618D">
                <DialogTitle>
                    <Typography fontSize={20} align="center" color="#ffffff">
                        Editar producto
                    </Typography>
                </DialogTitle>
            </Box>
           
            <form onSubmit={saveProductos} autoComplete="off">
            {notificacion.open ? (
                <Notify
                    mensaje={notificacion.mensaje}
                    open={notificacion.open}
                    color={notificacion.color}
                    handleClose={handleClose}
                    severity={notificacion.severity}
                />
            ) : null}
                <DialogContent>
                    <Box
                        backgroundColor="#F4F6F6"
                        px={2}
                        py={2}
                        border={0.5}
                        borderColor={"#ABB2B9"}
                        borderRadius={3}
                    >
                        <Grid container spacing={1} direction={{ xs: "column", sm: "row" }}>
                            <Grid item xs={4}>
                                <TextField
                                    name="codigoproducto"
                                    label="Código"
                                    style={style}
                                    size={size}
                                    focused
                                    placeholder="Código"
                                    value={datosProductos.codigoproducto}
                                    onChange={almacenar}>

                                </TextField>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    name="nombre"
                                    label="Nombre"
                                    style={style}
                                    size={size}
                                    focused
                                    placeholder="Nombre"
                                    value={datosProductos.nombre}
                                    onChange={almacenar}>

                                </TextField>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl style={style} focused color="primary" required size={size}>
                                    <InputLabel>Uso</InputLabel>
                                    <Select
                                        name="id_uso"
                                        label="Uso "
                                        value={datosProductos.id_uso}
                                        onChange={almacenar}
                                    >
                                        {uso
                                            ? uso.map((elemento) => (
                                                <MenuItem key={elemento.id} value={elemento.id}>
                                                    {elemento.valor}
                                                </MenuItem>
                                            ))
                                            : null}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {datosProductos.id_uso === 249 ?
                                <>
                                <Grid item xs={4}>
                                    <TextField
                                        name="densidad_hipergas"
                                        label="Densidad HIPERGAS"
                                        style={style}
                                        size={size}
                                        focused
                                        placeholder="Densidad HIPERGAS"
                                        value={datosProductos.densidad_hipergas || ''}
                                        onChange={almacenar}>

                                    </TextField>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        name="densidad_autogas"
                                        label="Densidad AUTOGAS"
                                        style={style}
                                        size={size}
                                        focused
                                        placeholder="Densidad AUTOGAS"
                                        value={datosProductos.densidad_autogas || ''}
                                        onChange={almacenar}>
                                    </TextField>
                                </Grid>
                            </>
                                :
                                <>
                                    <Grid item xs={2}>
                                        <TextField
                                            name="peso"
                                            label="Peso"
                                            style={style}
                                            size={size}
                                            focused
                                            placeholder="Peso"
                                            value={datosProductos.peso}
                                            onChange={almacenar}>

                                        </TextField>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            name="abreviatura"
                                            label="Abreviatura"
                                            style={style}
                                            size={size}
                                            focused
                                            placeholder="Abreviatura"
                                            value={datosProductos.abreviatura}
                                            onChange={almacenar}>

                                        </TextField>
                                    </Grid>
                                </>
                            }

                            <Grid item xs={4}>
                                <FormControl fullWidth size="small" focused style={style}>
                                    <InputLabel>Valor IVA (%)</InputLabel>
                                    <Select
                                        value={datosProductos.id_porcentajeiva}
                                        onChange={almacenar}
                                        label="Valor IVA (%)"
                                        focused
                                        name='id_porcentajeiva'
                                    >
                                        {porcentajes.map(({ idporcentaje_iva, descripcion }) => (
                                            <MenuItem key={idporcentaje_iva} value={idporcentaje_iva}>
                                                {descripcion} %
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    name="detalle_factura"
                                    label="Detalle Factura"
                                    style={style}
                                    size={size}
                                    focused
                                    placeholder="Por ej: GAS A GRANEL BUTANO"
                                    value={datosProductos.detalle_factura}
                                    onChange={almacenar}>

                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="flex-end"
                            mt={2}
                        >
                            <Button variant="contained" onClick={() => props.open(false)} color="error">
                                Volver
                            </Button>

                            <Button
                                disabled={disBtn}
                                variant="contained"
                                type="submit"
                                color="success"
                            >
                                Guardar Producto
                            </Button>
                        </Grid>
                    </Box>
                </DialogContent>
            </form>
        </Dialog>
    )
}