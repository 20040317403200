//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio
// Creation Date : 11/11/2024
// Version : 1
// Description : Pantalla para ver los reclamos segun sean : *Instalación, *Motivo Instalación o *Desconexión.
//
//------Changes --------------------------------------------------------------------- -------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//
//========================================================================/
import React, { useEffect, useState, useMemo } from "react";
import { UrlApi } from "../../../services/apirest";
import { Typography, ListItemIcon, MenuItem } from "@mui/material";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import { AlignHorizontalLeft, Build } from "@mui/icons-material";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import MaterialReactTable from "material-react-table";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import moment from "moment";
import AsignarTecnico from "./AsignarTecnico";
import DetalleReclamo2 from "./DetalleReclamo2";
import FinalizarReclamo from "./FinalizarReclamo";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import CancelarReclamo from "./CancelarReclamo";
import EditIcon from "@mui/icons-material/Edit";
//adjunto
import AttachFileIcon from "@mui/icons-material/AttachFile"; //ADJUNTAR
import FileUploadReclamo from "./FileUploadReclamo"; //ADJUNTAR
import { v4 as uuidv4 } from "uuid";
import EditarReclamo from "./EditarReclamo";
import ReceiptIcon from "@mui/icons-material/Receipt";
import Facturado from "./Facturado";
import Badge from "@mui/material/Badge";

export default function Instalaciones(props) {
  const [uuid, setUuid] = useState(uuidv4());
  const [reclamos, setReclamos] = useState();
  const [tabla, setTabla] = useState(true);
  const globalTheme = useTheme();
  const [asignar, setAsignar] = useState(false);
  const [dataReclamo, setDataReclamo] = useState({});
  const [finalizar, setFinalizar] = useState(false);
  const [cancelar, setCancelar] = useState(false);
  const [showEditar, setShowEditar] = useState(false);
  const [facturar, setFacturar] = useState(false);
  const [cantidadAbiertos, setCantidadAbiertos] = useState();
  const [cantidadAbiertos2, setCantidadAbiertos2] = useState();
  const [cantidadAbiertos3, setCantidadAbiertos3] = useState();
  const [activeTab, setActiveTab] = useState(props.activeTab);
  // adjuntos
  const [newUuid, setNewUuid] = useState(""); //ADJUNTAR COMPROBANTE
  const [openCloseAdj, setOpenCloseAdj] = useState(false); //ADJUNTAR COMPROBANTE

  const handleOpenCloseAdj = (value) => {
    setOpenCloseAdj(value);
  };

  const obtenerReclamosInstalacionesConFact = (estado) => {
    fetch(UrlApi + "/reclamos/instalacionesConFact/contadorAjuntos/")
      .then((data) => data.json())
      .then((data) => setReclamos(data));
  };

  const obtenerReclamosInstalacionesSinFact = (estado) => {
    fetch(UrlApi + "/reclamos/instalacionesSinFact/contadorAdjuntos/")
      .then((data) => data.json())
      .then((data) => setReclamos(data));
  };

  const obtenerReclamosDesconexion = (estado) => {
    fetch(UrlApi + "/reclamos/desconexion/ContadorDeAdjuntos/")
      .then((data) => data.json())
      .then((data) => setReclamos(data));
  };

  const obtenerCantidadInstalacionesAbiertasConFact = (estado) => {
    fetch(UrlApi + "/reclamos/instalacionesAbiertasConFact/")
      .then((data) => data.json())
      .then((data) => setCantidadAbiertos(data));
  };

  const obtenerCantidadInstalacionesAbiertasSinFact = (estado) => {
    fetch(UrlApi + "/reclamos/instalacionesAbiertasSinFact/")
      .then((data) => data.json())
      .then((data) => setCantidadAbiertos2(data));
  };

  const obtenerCantidadDesconexionesAbiertas = (estado) => {
    fetch(UrlApi + "/reclamos/desconexionesAbiertas/")
      .then((data) => data.json())
      .then((data) => setCantidadAbiertos3(data));
  };

  useEffect(() => {
    obtenerCantidadInstalacionesAbiertasConFact();
    obtenerCantidadInstalacionesAbiertasSinFact();
    obtenerCantidadDesconexionesAbiertas();
  }, []);

  const getReclamos = () => {
    switch (props.activeTab) {
      case 0:
        obtenerReclamosInstalacionesConFact();
        break;
      case 1:
        obtenerReclamosInstalacionesSinFact();
        break;
      case 2:
        obtenerReclamosDesconexion();
        break;

      default:
        console.log("Error");
        break;
    }
  };

  useEffect(() => {
    setActiveTab(props.activeTab);
    getReclamos();
  }, [props.activeTab, tabla]);

  // INSTALACION ID_MOTIVO =19 CON FACT / SIN FACT // DESINSTALACION ID_MOTIVO = 20
  const getColumns2 = (reclamos) => {
    let columns2 = [
      { header: "OT", accessorKey: "idreclamo", maxSize: 50 },
      {
        header: "Cliente",
        accessorFn: (row) => `${row.id_cliente} - ${row.razon_social}`,
        size: 340,
      },
      {
        header: "Registrado",
        accessorFn: (reclamos) =>
          moment(reclamos.created_at).format("DD-MM-YYYY"),
        size: 90,
      },
      { header: "Prioridad", accessorKey: "valor_prioridad", size: 90 },
      { header: "Instalador", accessorKey: "proveedor", size: 100 },
      { header: "Estado", accessorKey: "valor_estado", size: 85 },
      { header: "Facturado", accessorKey: "facturado", size: 85 },
      {
        header: "Fecha",
        accessorKey: "fecha_finalizado",
        size: 90,
      },
    ];
    return columns2;
  };
  const columns2 = useMemo(() => getColumns2(reclamos), [reclamos]);

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "success",
    size: "small",
  };

  const initialState = {
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
    sorting: [{ id: "idreclamo", desc: true }],
    columnPinning: { right: ["mrt-row-actions"] },
  };

  const tableHeadCellProps = {
    align: "center",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //larger tooltip font size
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const customLocalization = {
    ...MRT_Localization_ES,
    expandAll: "Ex", // Cambia 'Expandir todo' a 'Ex'
    expand: "Ex", // Cambia 'Expandir' a 'Ex'
  };

  return (
    <div>
      {tabla && reclamos ? (
        <ThemeProvider theme={tableTheme}>
          <MaterialReactTable
            enableRowActions
            enableColumnResizing
            enableFacetedValues
            columns={columns2}
            data={reclamos}
            initialState={initialState}
            positionActionsColumn="last"
            enableExpandAll={false}
            enableToolbarInternalActions={true}
            muiTableHeadCellProps={tableHeadCellProps}
            muiTableBodyCellProps={({ row }) => ({
              align: "left",
              sx: {
                backgroundColor:
                  (props.activeTab === 0 ||
                    props.activeTab === 1 ||
                    props.activeTab === 2) &&
                  row.original.valor_estado === "Finalizado"
                    ? "#efc3c3"
                    : "#ffffff",
                color: "black",
                borderColor: "black",
              },
            })}
            enableColumnActions={false}
            muiSearchTextFieldProps={muiSearchTextFieldProps}
            enableDensityToggle={false}
            layoutMode="grid"
            localization={customLocalization}
            enableColumnDragging={false}
            // enableEditing
            enablePinning={false}
            enableHiding={false}
            enableColumnFilters={false}
            renderDetailPanel={({ row }) => (
              <DetalleReclamo2 datos={row.original} activeTab={activeTab} />
            )}
            renderTopToolbarCustomActions={() => (
              <>
                <Typography variant="poster" fontSize={26}>
                  Instalaciones Granel
                </Typography>
                {props.activeTab === 0 ? (
                  <Typography variant="poster" fontSize={24}>
                    Abiertos:{" "}
                    {cantidadAbiertos
                      ? cantidadAbiertos[0].reclamosAbiertos
                      : "0"}
                  </Typography>
                ) : null}
                {props.activeTab === 1 ? (
                  <Typography variant="poster" fontSize={24}>
                    Abiertos:{" "}
                    {cantidadAbiertos2
                      ? cantidadAbiertos2[0].reclamosAbiertos
                      : "0"}
                  </Typography>
                ) : null}
                {props.activeTab === 2 ? (
                  <Typography variant="poster" fontSize={24}>
                    Abiertos:{" "}
                    {cantidadAbiertos3
                      ? cantidadAbiertos3[0].reclamosAbiertos
                      : "0"}
                  </Typography>
                ) : null}
              </>
            )}
            renderRowActionMenuItems={({ closeMenu, row }) => [
              <MenuItem
                disabled={row.original.estado !== 192}
                key={0}
                onClick={() => {
                  closeMenu();
                  setDataReclamo(row.original);
                  setAsignar(true);
                  setUuid(uuidv4());
                }}
                sx={{ m: 0 }}
              >
                <ListItemIcon>
                  <Build color="success" />
                </ListItemIcon>
                Asignar Técnico
              </MenuItem>,

              <MenuItem
                disabled={row.original.estado !== 170}
                key={0}
                onClick={() => {
                  closeMenu();
                  setDataReclamo(row.original);
                  setAsignar(true);
                  setUuid(uuidv4());
                }}
                sx={{ m: 0 }}
              >
                <ListItemIcon>
                  <Build color="success" />
                </ListItemIcon>
                Reasignar Técnico
              </MenuItem>,

              <MenuItem
                disabled={row.original.estado !== 170}
                key={1}
                onClick={() => {
                  closeMenu();
                  setDataReclamo(row.original);
                  setFinalizar(true);
                }}
                sx={{ m: 0 }}
              >
                <ListItemIcon>
                  <BeenhereIcon color="primary" />
                </ListItemIcon>
                Finalizar reclamo
              </MenuItem>,

              <MenuItem
                disabled={row.original.estado !== 170}
                key={2}
                onClick={() => {
                  closeMenu();
                  setDataReclamo(row.original);
                  setCancelar(true);
                }}
                sx={{ m: 0 }}
              >
                <ListItemIcon>
                  <DoDisturbAltIcon color="error" />
                </ListItemIcon>
                Cancelar reclamo
              </MenuItem>,

              <Badge
                color="secondary"
                badgeContent={row.original.total_archivos} // Muestra el número correcto de adjuntos
                showZero
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "left",
                }}
                sx={{
                  "& .MuiBadge-badge": {
                    fontSize: "0.75rem", // Tamaño de fuente más pequeño
                    minWidth: "17px", // Ancho mínimo del badge
                    height: "17px", // Alto mínimo del badge
                    padding: "0 3px", // Padding ajustado para el contenido
                    transform: "translateX(32px)", // Desplaza el badge hacia la derecha
                  },
                }}
              >
                <MenuItem
                  onClick={() => {
                    setNewUuid(row.original.uuid);
                    setOpenCloseAdj(!openCloseAdj);
                    closeMenu();
                  }}
                >
                  <ListItemIcon>
                    <AttachFileIcon color="action" />
                  </ListItemIcon>
                  Adjuntos
                </MenuItem>
              </Badge>,

              <MenuItem
                disabled={
                  row.original.estado === 171 || row.original.estado === 223
                }
                onClick={() => {
                  setDataReclamo(row.original);
                  setShowEditar(true);
                  closeMenu();
                }}
              >
                <ListItemIcon>
                  <EditIcon color="action" />
                </ListItemIcon>
                Editar
              </MenuItem>,

              <MenuItem
                disabled={row.original.estado !== 171}
                onClick={() => {
                  setDataReclamo(row.original);
                  setFacturar(true);
                  closeMenu();
                }}
              >
                <ListItemIcon>
                  <ReceiptIcon color="secondary" />
                </ListItemIcon>
                Factura
              </MenuItem>,
            ]}
          />
        </ThemeProvider>
      ) : null}
      {asignar ? (
        <AsignarTecnico
          datos={dataReclamo}
          open={setAsignar}
          tabla={setTabla}
          actualizar={getReclamos}
          uuid={uuid}
          activeTab={activeTab}
        />
      ) : null}
      {finalizar ? (
        <FinalizarReclamo
          datos={dataReclamo}
          open={setFinalizar}
          tabla={setTabla}
          actualizar={getReclamos}
        />
      ) : null}
      {cancelar ? (
        <CancelarReclamo
          datos={dataReclamo}
          open={setCancelar}
          tabla={setTabla}
          actualizar={getReclamos}
        />
      ) : null}
      {openCloseAdj ? ( // ADJUNTAR COMPROBANTES
        <FileUploadReclamo
          fileId={newUuid}
          openclose={true}
          mostrar={true}
          onClose={() => handleOpenCloseAdj(false)} // Proporciona una función de cierre
        />
      ) : null}
      {showEditar ? (
        <EditarReclamo
          datos={dataReclamo}
          open={showEditar}
          setOpen={setShowEditar}
          actualizar={getReclamos}
        />
      ) : null}
      {facturar ? (
        <Facturado
          datos={dataReclamo}
          open={setFacturar}
          tabla={setTabla}
          actualizar={getReclamos}
        />
      ) : null}
      <br></br>
      <br></br>
    </div>
  );
}
