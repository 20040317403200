//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio
// Creation Date : 05/08/2024
// Version : 1
// Description : Pantalla principal de estadisticas de desconexion
//------Changes ----------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import Navbar from "../../../Navbar";
import Tabla_estados_desconexion from "./Tabla_estados_desconexion";
import Grafico_estados_desconexion from "./Grafico_estados_desconexion";
import Tabla_instaladores_desconexion from "./Tabla_instaladores_desconexion";



function Estadisticas_desconexion() {
  return (
    <div>
      <Navbar />
      <Box
        bgcolor="#2B76A6"
        padding={1}
        borderRadius={0}
        color="white"
        textAlign="center"
        display="flex" // Agregado para que los elementos se coloquen en línea
        alignItems="center"
        marginTop={1}
      >
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Typography variant="h4" component="div">
            Panel de estadísticas de reclamos con desconexión
          </Typography>
        </div>
      </Box>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
        }}
      >
        <div style={{ marginBottom: "20px" }}>
          <Tabla_estados_desconexion />
        </div>
        <div style={{ marginBottom: "20px" }}>
          <Grafico_estados_desconexion />
        </div>
        <div style={{ marginBottom: "20px" }}>
          <Tabla_instaladores_desconexion />
        </div>
      </div>
    </div>
  );
}

export default Estadisticas_desconexion;
