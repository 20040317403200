import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Image from "../img/foto_25.jpg";
import Logo from "../img/logoNetizar.png";
import {
  Grid,
  Paper,
  Avatar,
  TextField,
  Button,
  Alert,
} from "@mui/material";
import QRCode from "react-qr-code";
import { AuthApi } from "../services/apirest";
import LockIcon from "@mui/icons-material/Lock";
import { logEventos } from "../services/services";

function Login2() {
  let navigate = useNavigate();
  const [dataLogin, setDataLogin] = useState({ username: "", password: "" });

  const [mensaje, setMensaje] = useState("");

  //Estados utilizados para login
  const [error, setError] = useState(false); //Indica si hay error para logearse o no.

  const almacenar = (e) => {
    setDataLogin({
      ...dataLogin,
      [e.target.name]: e.target.value,
    });
  };

  const ingresar = async (e) => {
    e.preventDefault();
    await fetch(AuthApi + "login", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataLogin),
    })
      .then((res) => res.json())
      .then((data) =>
        data.token
          ? (guardarlocal(data),
            logEventos("Login exitoso", "Login", "Login", data.user.username),
            setMensaje("Ingreso correcto"),
            navigate("navbar"))
          :
          setError(true),
        setMensaje("Error de ingreso")
      );
  };

  const guardarlocal = (response) => {
    //console.log(response)
    localStorage.setItem("token", response.token)
    localStorage.setItem("user", response.user.username)
    localStorage.setItem('token-expiration', Date.now() + 2 * 60 * 60 * 1000)
    localStorage.setItem("role", response.user.rolegral)
    localStorage.setItem("sucursal", response.user.sucursal)
    localStorage.setItem("sucursalnombre", response.user.nombre)
    localStorage.setItem("suc_auth", response.user.suc_auth)
    localStorage.setItem("factib", response.user.factibilidades)
    localStorage.setItem("stkcontrol", response.user.stkcontrol);
    localStorage.setItem("email", response.user.email);
    localStorage.setItem("sector", response.user.sector);
    localStorage.setItem("auditor", response.user.auditor);
    localStorage.setItem("controlador", response.user.controlador);
    localStorage.setItem("departamento", response.user.departamento);
    localStorage.setItem("adminrrhh", response.user.adminrrhh);
    localStorage.setItem("anula_facturas", response.user.anula_facturas);
  };

  const styles = {
    paperContainer: {
      backgroundImage: `url(${Image})`,
      backgroundSize: "cover",
      overflow: "hidden",
    },
    paperStyle: {
      padding: 40,
      height: "80vh",
      width: 200,
      margin: "10px 40px",
      backgroundColor: "rgb(250, 250, 250)",
      opacity: "85%",
    },
    avatarStyle: { backgroundColor: "rgb(50, 120, 120)" },
    btnstyle: { margin: "8px 0" },
    cajaLogo: { width: "30%", height: "50px", border: "5px" },
  };

  return (
    <div>
      <Grid>
        <Paper style={styles.paperContainer}>
          <Paper elevation={20} style={styles.paperStyle}>
            <Grid
              item
              xs={12}
              sx={{ fontSize: "h5.fontSize", m: 0, padding: "2px" }}
              boxShadow={2}
              container
              alignItems="flex-start"
              justify="flex-end"
              direction="row"
            >
              <img src={`${Logo}`} alt=""></img>
              <Grid item xs={3} sx={{ fontSize: "h5.fontSize", m: 1 }}>
                Netizar
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={2} columns={16}>
              <Grid item xs={3}>
                <Avatar style={styles.avatarStyle}>
                  <LockIcon />
                </Avatar>
              </Grid>
              <Grid item xs={8} sx={{ fontSize: "h5.fontSize", m: 1 }}>
                Ingreso
              </Grid>
            </Grid>
            <form onSubmit={ingresar}>
              <TextField
                autoFocus
                variant="standard"
                name="username"
                label="Usuario"
                placeholder="Ingrese usuario"
                fullWidth
                required
                onChange={almacenar}
                autoComplete="off"
              />
              <TextField
                variant="standard"
                name="password"
                label="Password"
                placeholder="Su password"
                type="password"
                fullWidth
                required
                onChange={almacenar}
              />
              <br />
              <br />
              <br />
              <Button
                type="submit"
                color="primary"
                variant="outlined"
                style={styles.btnstyle}
                fullWidth
              >
                Entrar
              </Button>
              {error ? (
                <Alert severity="warning">
                  {/* <AlertTitle>Error</AlertTitle> */}
                  {mensaje}
                </Alert>
              ) : null}
            </form>
            <br />
            <br />
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{ height: 100, display: "flex", justifyContent: "center" }}
              >
                <QRCode value="http://prod.netizar.com" size={100} />
              </Grid>
            </Grid>
          </Paper>
        </Paper>
      </Grid>
    </div>
  );
}

export default Login2;
