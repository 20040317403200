import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { UrlApi } from "../../../../services/apirest";
import moment from 'moment';
import Button from '@mui/material/Button'
import { v4 as uuidv4 } from "uuid"; ///me da un unico id para poner en los pedidos y repartos


function AddRegistroEntrada(props) {
  const [motivos, setMotivos] = useState("");
  const [proveedores, setProveedores] = useState("");
  const [productos, setProductos] = useState();
  const [transportePropio, settransportePropio] = useState(true);
  const [tanquePropio, setTanquePropio] = useState(true);
  const [tractores, setTractores] = useState();
  const [tanquesMoviles, setTanquesMoviles] = useState();
  const [choferes, setChoferes] = useState("");
  const [niveles, setNiveles] = useState("");
  const [uuid, setUuid]=useState();

  const [datosEntrada, setDatosEntrada] = useState({
    es_entrada_o_salida: 1, //entrada:1 | salida:2
    e_motivo: "",
    e_nroticket: "",
    e_fecha: "",
    e_hora: "",
    e_pesada: "",
    e_idproducto: "",
    e_idpropietariotractor: "",
    e_idpropietariotanque: "",
    e_idchofer: null,
    e_idtanquemovil: null,
    e_idtractor: null,
    e_chofer: "",
    e_tanquemovil: "",
    e_tractor: "",
    e_idproveedor: "",
    e_idnivelgasoil: "",
    e_remitoproveedor: "", //aca va un uuid
    e_observaciones: "",
    id_estado:""
  });

  //Llamadas a api para desplegable.
  //obtener motivos de entrada
  const obtenerMotivos = () => {
    // fetch(UrlApi + "/desplegables/modulo/motivoEntradaProp")
    fetch(UrlApi + "/desplegables/motivoEntradaProp")
      .then((data) => data.json())
      .then((data) => setMotivos(data));
  };

  const obtenerProveedores = () => {
    fetch(UrlApi + "/proveedores/activos")
      .then((data) => data.json())
      .then((data) => setProveedores(data));
  };

  const obtenerProductos = () => {
    fetch(UrlApi + "/productospropelente/materiaprima")
      .then((data) => data.json())
      .then((data) => setProductos(data));
  };

  const obtenerTractores = () => {
    fetch(UrlApi + "/vehiculos/tractorespropelente")
      .then((data) => data.json())
      .then((data) => setTractores(data));
  };

  const obtenerTanques = () => {
    fetch(UrlApi + "/vehiculos/tanquespropelente")
      .then((data) => data.json())
      .then((data) => setTanquesMoviles(data));
  };

  const obtenerChoferes = () => {
    fetch(UrlApi + "/choferes/propelente")
      .then((data) => data.json())
      .then((data) => setChoferes(data));
  };

  const obtenerNiveles = () => {
    fetch(UrlApi + "/desplegables/nivelesTanque")
      .then((data) => data.json())
      .then((data) => setNiveles(data));
  };

  useEffect(() => {
    obtenerMotivos();
    obtenerProveedores();
    obtenerProductos();
    obtenerTractores();
    obtenerTanques();
    obtenerChoferes();
    obtenerNiveles();
    setUuid(uuidv4()); //generamos el unique id para el reparto
  }, []);

  const handleChangeEmpTrans = (e) => {
    console.log(e.target.value)

    settransportePropio(e.target.checked);
  };

  console.log(transportePropio)

  const handleTanquePropio = (e) => {
    setTanquePropio(e.target.checked);
  };

  const setearDatosEntrada=(e)=>{
    setDatosEntrada({...datosEntrada, [e.target.name]:e.target.value})
  }

  const handleClose=()=>{
    props.tabla(true)
    props.formEntrada(false )
  }

  console.log("teno q guardar con remitos_new")

  async function guardarEntrada(e) {
    e.preventDefault();
    await fetch(UrlApi + "/entradas_salidas_propelente", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          es_entrada_o_salida: datosEntrada.es_entrada_o_salida, //entrada:1 | salida:2
          e_motivo: datosEntrada.e_motivo,
          e_nroticket: datosEntrada.e_nroticket,
          e_fecha: datosEntrada.e_fecha,
          e_hora: datosEntrada.e_hora,
          e_pesada: datosEntrada.e_pesada,
          e_idproducto: datosEntrada.e_idproducto,
          e_idpropietariotractor: transportePropio ? 124 : 125  ,
          e_idpropietariotanque: tanquePropio? 124 : 125,
          e_idchofer: datosEntrada.e_idchofer,
          e_idtanquemovil: datosEntrada.e_idtanquemovil,
          e_idtractor: datosEntrada.e_idtractor,
          e_chofer:datosEntrada.e_chofer,
          e_tanquemovil: datosEntrada.e_tanquemovil,
          e_tractor: datosEntrada.e_tractor,
          e_idproveedor: datosEntrada.e_idproveedor,
          e_idnivelgasoil: datosEntrada.e_idnivelgasoil,
          // e_remitoproveedor: uuid, //aca va un uuid
          uuid:uuid,
          e_observaciones: datosEntrada.e_observaciones,
          id_estado:132 //Implica que queda pendiente la carga del remito
        }),
      })
      // .then(handleClose())
      //response&&console.log(response)
      // if (response.ok === false) {
      //   console.log("ERROR EN API");
      //   const error = response && response.json();
      //   setError(error);
      //   setSeverityAlerta("error")
      //   setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
      // } else {
      //   console.log("Conexión con api OK");
      //   try {
      //     //Parseo respuesta de la api a json()
      //     const json = await response.json();
      //     //si api me devuelve lo q le puse en message
      //     if (json.message) {
      //       //Si ese mensaje es "OK"
      //       if (json.message === "OK") {
      //         console.log("Guardado Satisfactoriamente");
      //         setNotificacion(true);
      //         setOpenSnack(true);
      //         setColorMensaje("#D4EFDF");
      //         setMensaje("Guardado");
      //         setSeveritySnack("success");
      //       } else {
	    //   console.log("res en api incorrecto. Revisar que sea 'OK'")
      //         setNotificacion(true);
      //         setOpenSnack(true);
      //         setColorMensaje("#D4EFDF");
      //         setSeveritySnack("error");
      //         setMensaje("Registro Guardado. REVISAR RESPUESTA DE API (E_S)");
      //       }
      //     } else if(json.sqlMessage) {
      //       json && setError(json);
      //       console.log(json);
      //       setMensajeAlerta("SQL erróneo (" + json.code + " Errno: " + json.errno + " )" );
      //     } else {
      //       setMensajeAlerta("ERROR DESCONOCIDO.");
      //     }
      //   } catch (error) {
      //     if (error.toString().includes("is not valid JSON")) {
      //       console.log("Guardado. Advertencia: Actualizar respuesta de api. JSON invalido.");
      //       setNotificacion(true);
      //       setOpenSnack(true);
      //       setColorMensaje("#D4EFDF");
      //       setMensaje("Guardado");
      //       setSeveritySnack("success");
      //     } else {
      //       setMensajeAlerta("ERROR DESCONOCIDO.");
      //     }
      //   }
      // }
  }

  async function guardarRemito(e) {
    e.preventDefault();
  console.log("teno q guardar en remitos_new")
    await fetch(UrlApi + "/remitos_new", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          cli_o_prov:datosEntrada.e_idproveedor ? 60 : null, 
          id_producto:datosEntrada.e_idproducto,
          id_proveedor:datosEntrada.e_idproveedor?datosEntrada.e_idproveedor : null, 
          id_tractor: transportePropio ? datosEntrada.e_idtractor : null, 
          id_tanquemovil: tanquePropio ? datosEntrada.e_idtanquemovil:null,
          id_chofer: transportePropio ? datosEntrada.e_idchofer : null,
          chofer: !transportePropio ? datosEntrada.e_chofer:null,
          tractor: !transportePropio ? datosEntrada.e_tractor:null,
          tanquemovil: !tanquePropio ? datosEntrada.e_tanquemovil:null,
          baja:0,
          uuid:uuid
        }),
      })
      .then(handleClose())
      //response&&console.log(response)
      // if (response.ok === false) {
      //   console.log("ERROR EN API");
      //   const error = response && response.json();
      //   setError(error);
      //   setSeverityAlerta("error")
      //   setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
      // } else {
      //   console.log("Conexión con api OK");
      //   try {
      //     //Parseo respuesta de la api a json()
      //     const json = await response.json();
      //     //si api me devuelve lo q le puse en message
      //     if (json.message) {
      //       //Si ese mensaje es "OK"
      //       if (json.message === "OK") {
      //         console.log("Guardado Satisfactoriamente");
      //         setNotificacion(true);
      //         setOpenSnack(true);
      //         setColorMensaje("#D4EFDF");
      //         setMensaje("Guardado");
      //         setSeveritySnack("success");
      //       } else {
	    //   console.log("res en api incorrecto. Revisar que sea 'OK'")
      //         setNotificacion(true);
      //         setOpenSnack(true);
      //         setColorMensaje("#D4EFDF");
      //         setSeveritySnack("error");
      //         setMensaje("Registro Guardado. REVISAR RESPUESTA DE API (E_S)");
      //       }
      //     } else if(json.sqlMessage) {
      //       json && setError(json);
      //       console.log(json);
      //       setMensajeAlerta("SQL erróneo (" + json.code + " Errno: " + json.errno + " )" );
      //     } else {
      //       setMensajeAlerta("ERROR DESCONOCIDO.");
      //     }
      //   } catch (error) {
      //     if (error.toString().includes("is not valid JSON")) {
      //       console.log("Guardado. Advertencia: Actualizar respuesta de api. JSON invalido.");
      //       setNotificacion(true);
      //       setOpenSnack(true);
      //       setColorMensaje("#D4EFDF");
      //       setMensaje("Guardado");
      //       setSeveritySnack("success");
      //     } else {
      //       setMensajeAlerta("ERROR DESCONOCIDO.");
      //     }
      //   }
      // }
  }

  const guardar =(e)=>{
    guardarEntrada(e)
    if(datosEntrada.e_idproveedor){
      guardarRemito(e)
    }
  }

  return (
    <div>
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
      >
        <h1>INGRESO DE VEHICULO</h1>
      </Grid>
      <form onSubmit={guardar}>
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
        p={3}
      >
        <Grid item xs={2}>
          <TextField
            name="e_nroticket"
            label="Nro Ticket"
            variant="outlined"
            color="primary"
            margin="none"
            sizes="small"
            value={datosEntrada.e_nroticket}
            onChange={setearDatosEntrada}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            name="e_fecha"
            fullWidth
            variant="outlined"
            color="primary"
            margin="none"
            sizes="small"
            type="date"
            value={moment(datosEntrada.e_fecha).format("YYYY-MM-DD")}
            onChange={setearDatosEntrada}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            name="e_hora"
            fullWidth
            // label="Hora"
            variant="outlined"
            color="primary"
            margin="none"
            sizes="small"
            type="time"
            value={datosEntrada.e_hora}
            onChange={setearDatosEntrada}
          />
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <InputLabel>Motivo</InputLabel>
            <Select
              name="e_motivo"
              value={datosEntrada.e_motivo}
              label="Motivo"
              onChange={setearDatosEntrada}
              >
              {motivos
                ? motivos.map((motivo) => (
                    <MenuItem key={motivo.id} value={motivo.id}>
                      {motivo.valor}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <TextField
            name="e_pesada"
            label="Pesada (KG)"
            variant="outlined"
            color="primary"
            margin="none"
            sizes="small"
            value={datosEntrada.e_pesada}
            onChange={setearDatosEntrada}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
      >
        <Grid item xs={7}>
          <FormControl fullWidth>
            <InputLabel>Proveedor</InputLabel>
            <Select
              name="e_idproveedor"
              label="Proveedor"
              value={datosEntrada.e_idproveedor}
              onChange={setearDatosEntrada}
              >
              {proveedores
                ? proveedores.map((proveedor) => (
                    <MenuItem key={proveedor.id} value={proveedor.id}>
                      {proveedor.razon_social}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel>Producto</InputLabel>
            <Select
              name="e_idproducto"
              label="Producto"
              value={datosEntrada.e_idproducto}
              onChange={setearDatosEntrada}
              >
              {productos
                ? productos.map((producto) => (
                    <MenuItem
                      key={producto.idproducto}
                      value={producto.idproducto}
                    >
                      {producto.nombre}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        mt={1}
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
      >
        <Grid item xs={2}>
          <FormControlLabel
            checked={transportePropio}
            onChange={handleChangeEmpTrans}
            // onChange={(e)=>settransportePropio(e.target.checked?1:0)}
            control={<Checkbox />}
            label="Transporte Propio"
            sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
          />
        </Grid>
        {transportePropio ? (
          <>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel >
                  Tractor (DE ITALGAS)
                </InputLabel>
                <Select
                  name="e_idtractor"
                  label="Tractor (DE ITALGAS)"
                  value={datosEntrada.e_idtractor}
                  onChange={setearDatosEntrada}
                  >
                  {tractores
                    ? tractores.map((tractor) => (
                        <MenuItem value={tractor.id}>
                          {tractor.patente}{" "}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel >
                  Chofer (DE ITALGAS)
                </InputLabel>
                <Select
                  name="e_idchofer"
                  label="Chofer (DE ITALGAS)"
                  value={datosEntrada.e_idchofer}
                  onChange={setearDatosEntrada}
                  >
                  {choferes
                    ? choferes.map((chofer) => (
                        <MenuItem value={chofer.id}>
                          {chofer.nombre_apellido}{" "}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel>
                  Nivel De Gasoil
                </InputLabel>
                <Select
                  name="e_idnivelgasoil"
                  label="Nivel De Gasoil"
                  value={datosEntrada.e_idnivelgasoil}
                  onChange={setearDatosEntrada}
                  >
                  {
                    niveles ? niveles.map((nivel)=>(
                      <MenuItem value={nivel.id}>{nivel.valor}</MenuItem>
                    )) : null

                  }
                </Select>
              </FormControl>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={3}>
              <TextField
                name="e_tractor"
                fullWidth
                id=""
                label="Patente Tractor"
                variant="outlined"
                color="primary"
                margin="none"
                sizes="small"
                value={datosEntrada.e_tractor}
                onChange={setearDatosEntrada}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                name="e_chofer"
                label="Chofer"
                variant="outlined"
                color="primary"
                margin="none"
                sizes="small"
                value={datosEntrada.e_chofer}
                onChange={setearDatosEntrada}
                />
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel>
                  Nivel De Gasoil
                </InputLabel>
                <Select
                  name="e_idnivelgasoil"
                  label="Nivel De Gasoil"
                  value={datosEntrada.e_idnivelgasoil}
                  onChange={setearDatosEntrada}
                  >
                  {
                    niveles ? niveles.map((nivel)=>(
                      <MenuItem value={nivel.id}>{nivel.valor}</MenuItem>

                    )) : null

                  }
                </Select>
              </FormControl>
            </Grid>
          </>
        )}
      </Grid>
      <Grid
        mt={1}
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
      >
        <Grid item xs={2}>
          <FormControlLabel
            checked={tanquePropio}
            onChange={handleTanquePropio}
            control={<Checkbox />}
            label="Tanque Propio "
            sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
          />
        </Grid>
        {tanquePropio ? (
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel>
                Tanque Móvil (DE ITALGAS)
              </InputLabel>
              <Select
                name="e_idtanquemovil"
                label="Tanque Móvil (DE ITALGAS)"
                value={datosEntrada.e_idtanquemovil}
                onChange={setearDatosEntrada}
              >
                {tanquesMoviles
                  ? tanquesMoviles.map((tanque) => (
                      <MenuItem key={tanque.id} value={tanque.id}>
                        {tanque.patente}{" "}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </FormControl>
          </Grid>
        ) : (
          <Grid item xs={4}>
            <TextField
              fullWidth
              name="e_tanquemovil"
              label="Patente Tanque Móvil"
              variant="outlined"
              color="primary"
              margin="none"
              sizes="small"
              value={datosEntrada.e_tanquemovil}
              onChange={setearDatosEntrada}
              />
          </Grid>
        )}
      </Grid>
      <Grid
        p={2}
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
      >
        <Grid item xs={10}>
          <TextField  
            name="e_observaciones"
            fullWidth
            multiline
            label="Observaciones"
            variant="outlined"
            color="primary"
            margin="none"
            sizes="small"
            value={datosEntrada.e_observaciones}
            onChange={setearDatosEntrada}
          />
        </Grid>
      </Grid>
      <Grid
        my={2}
        px={7}
        container
        spacing={1}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
        
      >
        <Grid item>
          <Button variant="outlined" color="error" onClick={handleClose}>
            Cancelar
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="success" type="submit">
            Guardar
          </Button>
          
        </Grid>
      </Grid>
      </form>
    </div>
  );
}

export default AddRegistroEntrada;
