//------Changes --------------------------------------------------------------------- -------------//

// Change Date :

// Change by :

// Change description:

//

// Change Date :

// Change by :

// Change description:

//

//========================================================================/
import React, { useState, useEffect } from "react";
import { UrlApi } from "../../../services/apirest";
import {
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  DialogTitle,
  Box,
  TextField,
  IconButton,
  Button,
  InputAdornment,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { grey } from "@mui/material/colors";
import Notify from "../../Notify";
import Alerta from "../../Alerta";
import WifiCalling3Icon from "@mui/icons-material/WifiCalling3";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { logEventos } from "../../../services/services";

function Rechazar_checklist(props) {
    const [openSnack, setOpenSnack] = React.useState(false);
    const [colormensaje, setColorMensaje] = useState("");
    const [mensaje, setMensaje] = useState("");
    const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
    const [finalizar, setFinalizar] = useState(); //finalizar === 1 lo manda como prop el componente "notify" e indica que la transaccion ha finalizado y debe ejecutarse la funcion para cerrar
    const [severitySnack, setSeveritySnack] = useState(""); //define severidad del snackbar (notificación)
    const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
    const [rechazar, setRechazar] = useState(props.rechazar)

    const Rechazar = async (e) => {
        e.preventDefault();
        const response = await fetch(
          UrlApi + "/estados_evaluacion_comercial/" + props.datosRow.id_estado,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              checklist_rechazado: 2 ,
              porcentaje_checklist: 0,
            }),
          }
        );
        if (response.ok === false) {
          const error = response && response.json();
          setError(error);
          alert("Inconvenientes con la API. No se pudo guardar. ");
        } else {
          try {
            const json = await response.json();
            if (json.message) {
              setNotificacion(true);
              setOpenSnack(true);
              setMensaje(
                json.message === "OK" ? "Guardado" : "Guardado. Actualizar RTA Api."
              );
            } else if (json.sqlMessage) {
              json && setError(json);
              alert("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
            } else {
              alert("ERROR DESCONOCIDO.");
            }
          } catch (error) {
            if (error.toString().includes("is not valid JSON")) {
              setNotificacion(true);
              setOpenSnack(true);
              setMensaje("Guardado");
            } else {
              alert("Error: " + error);
            }
          }
        }
        handleClose()
      };
      const handleClose = () => {
        setRechazar(!rechazar);
        if (props.onClose) {
          props.onClose();
        }
      };

  return (
    <div>
      <Dialog open={rechazar} onClose={handleClose}>
        <form onSubmit={Rechazar}>
          <Box backgroundColor={"#1F618D"}>
            <DialogTitle>
                <Typography variant="h6" align="center" color="#ffffff">
                  Rechazar Checklist 
                </Typography>
            </DialogTitle>
          </Box>
          <DialogContent>
            <DialogContentText textAlign={"center"}>
             ¿Desea Marcar Como Rechazado el Checklist de {props.datosRow.nombre_apellido}? 
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button
                variant="contained"
                onClick={handleClose}
                style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
              >
                Volver
              </Button>
              <Button
                style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                type="submit"
                variant="contained"
                color="primary"
              >
                Confirmar
              </Button>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
      {notificacion ? (
          <Notify
            mensaje={mensaje}
            open={openSnack}
            color={colormensaje}
            finalizar={setFinalizar}
            severity={severitySnack}
          />
        ) : null}
    </div>
  )
}

export default Rechazar_checklist