//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio
// Creation Date : 25/10/2024
// Version : 1
// Description : pantalla para tabla de checklist por grupo e items
//------Changes --------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect } from "react";
import { UrlApi } from "../../../services/apirest";
import moment from "moment";
import {
  Typography,
  MenuItem,
  FormControl,
  Select,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
} from "@mui/material";
import Navbar from "../../Navbar";
import { styled } from "@mui/system";

function TablaChecklistSH() {
  const [tablaCheck, setTablaCheck] = useState([]);

  const [selectedDate, setSelectedDate] = useState(
    moment().subtract(1, "month").format("YYYY-MM")
  );
  const formattedDate = `${selectedDate}-01`;

  const [fechaString, setFechaString] = useState("");

  useEffect(() => {
    if (tablaCheck.fecha) {
      setFechaString(tablaCheck.fecha.toString());
    }
  }, [tablaCheck]);

  const handleDateChange = (event) => {
    const newDate = event.target.value;
    setSelectedDate(newDate);

    // Actualiza datosCheck.fecha cuando cambia la selección
    setTablaCheck({
      ...tablaCheck,
      fecha: moment(newDate, "YYYY-MM").format("YYYY-MM-DD"), // Ajusta el formato según necesites
    });
  };

  const currentMonth = moment();
  const monthOptions = Array.from({ length: 12 }, (_, i) => {
    const month = currentMonth
      .clone()
      .subtract(i + 1, "months")
      .format("YYYY-MM");
    return {
      value: month,
      label: currentMonth
        .clone()
        .subtract(i + 1, "months")
        .format("MMMM YYYY"),
    };
  });

  const obtenerResultados = () => {
    const year = formattedDate.split("-")[0];
    const month = formattedDate.split("-")[1];

    // Construir la URL con el año y el mes
    const url = `${UrlApi}/seg_hig_checklist/checklistTabla/${year}/${month}`;

    fetch(url)
      .then((data) => data.json())
      .then((data) => setTablaCheck(data));
  };

  useEffect(() => {
    obtenerResultados();
  }, [selectedDate]);

  console.log("TABLA CHECK", tablaCheck);

  const [deposito, setDeposito] = useState([]);

  const obtenerDepositos = () => {
    fetch(UrlApi + "/sucursales/xsucauth/" + localStorage.getItem("suc_auth"))
      .then((data) => data.json())
      .then((data) => setDeposito(data));
  };
  useEffect(() => {
    obtenerDepositos();
  }, []);

  const HeaderCell = styled(TableCell)({
    marginTop: "2px",
    fontSize: 16,
    color: "white",
    fontWeight: "normal",
    backgroundColor: "#2471A3",
    borderColor: "black",
    padding: "6.3px",
  });

  // *********************************************************** Tabla ********************************************************************** //
  return (
    <div>
      <Navbar />
      <Box
        bgcolor="#2B76A6"
        padding={1}
        borderRadius={0}
        color="white"
        textAlign="center"
        display="flex" // Agregado para que los elementos se coloquen en línea
        alignItems="center"
        marginTop={1}
        fullwidth
      >
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="h4" component="div">
              Tabla Checklist Seguridad e Higiene
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "6vh" }}
        >
          <Grid item>
            <FormControl
              focused
              color="primary"
              sx={{
                backgroundColor: "white",
                width: "200px",
                display: "flex",
                justifyContent: "center",
              }}
              required
              size="small"
            >
              <Select
                required
                variant="outlined"
                name="mes"
                value={selectedDate}
                onChange={handleDateChange}
                //   label="Período"
                labelId="mes"
              >
                {monthOptions.map((elemento) => (
                  <MenuItem key={elemento.value} value={elemento.value}>
                    {elemento.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      <TableContainer sx={{ maxHeight: "400px", overflow: "auto" }}>
        <Table sx={{ mt: 1 }}>
          <TableHead>
            <TableRow
              sx={{
                position: "sticky",
                top: 6,
                backgroundColor: "#fff",
                zIndex: 1,
                border: "1px solid black",
              }}
            >
              <HeaderCell>Grupos e items / Sucursales</HeaderCell>
              {deposito && deposito.length > 0 ? (
                deposito.map((dep) => (
                  <HeaderCell key={dep.id} sx={{ border: "1px solid black" }}>
                    {dep.nombre}
                  </HeaderCell>
                ))
              ) : (
                <HeaderCell>No hay sucursales</HeaderCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {tablaCheck && tablaCheck.length > 0 ? (
              // Agrupamos los datos por nombre_grupo
              Object.entries(
                tablaCheck.reduce((acc, item) => {
                  if (!acc[item.nombre_grupo]) {
                    acc[item.nombre_grupo] = {
                      nombre_grupo: item.nombre_grupo,
                      items: {},
                    };
                  }
                  // Agrupamos los items por id_item dentro del grupo
                  if (!acc[item.nombre_grupo].items[item.id_item]) {
                    acc[item.nombre_grupo].items[item.id_item] = {
                      id_item: item.id_item,
                      nombre_item: item.nombre_item,
                      sucursales: {}, // Almacenaremos los estados por sucursal
                    };
                  }
                  // Agregamos el estado de cumplimiento/no cumplimiento por sucursal
                  acc[item.nombre_grupo].items[item.id_item].sucursales[
                    item.sucursal
                  ] = {
                    cumple: item.cumple_total > item.no_cumple_total,
                    noCumple: item.no_cumple_total > item.cumple_total,
                  };
                  return acc;
                }, {})
              ).map(([nombre_grupo, grupo]) => (
                <React.Fragment key={nombre_grupo}>
                  {/* Fila para el nombre del grupo */}
                  <TableRow>
                    <TableCell
                      colSpan={deposito.length + 1}
                      style={{
                        fontWeight: "bold",
                        backgroundColor: "#f0f0f0",
                        borderTopColor: "black",
                        borderBottomColor: "black",
                      }}
                      sx={{ border: "1px solid black" }}
                    >
                      {grupo.nombre_grupo}
                    </TableCell>
                  </TableRow>

                  {/* Filas para los items del grupo */}
                  {Object.entries(grupo.items).map(([_, item]) => (
                    <TableRow key={item.id_item}>
                      <TableCell>{item.nombre_item}</TableCell>
                      {deposito.map((dep) => (
                        <TableCell
                          align="center"
                          sx={{ border: "1px solid black" }}
                          key={dep.id}
                        >
                          {item.sucursales[dep.nombre]
                            ? item.sucursales[dep.nombre].cumple >
                              item.sucursales[dep.nombre].noCumple
                              ? "✔️"
                              : item.sucursales[dep.nombre].cumple <
                                item.sucursales[dep.nombre].noCumple
                              ? "❌"
                              : "n/a"
                            : "-"}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </React.Fragment>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={deposito.length + 1}
                  align="center"
                  style={{
                    padding: "40px",
                    backgroundColor: "#f5f5f5",
                    color: "#757575",
                    fontStyle: "italic",
                    fontSize: "20px",
                  }}
                >
                  No hay datos disponibles
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
}

export default TablaChecklistSH;
