import React, { useState, useEffect, useMemo } from "react";
import { UrlApi } from "../../../../services/apirest";
import Container from "@mui/material/Container";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import { Menu, MenuItem, Button, Tooltip, IconButton } from "@mui/material";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import Box from "@mui/material/Box";
import MaterialReactTable from "material-react-table";
import DescriptionIcon from "@mui/icons-material/Description";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Typography } from '@mui/material';
import moment from 'moment';

import ReceiptIcon from '@mui/icons-material/Receipt';

//Componentes propios
import AddRegistroEntrada from "./AddRegistroEntrada";
import AddRegistroSalida from "./AddRegistroSalida";
import Dialogo from "./Dialogo";
import Navbar from "../../../Navbar";

function Registros() {
  const [registros, setRegistros] = useState();
  const [datosRegistro, setDatosRegistro] = useState("");

  //VISUALES
  const [tabla, setTabla] = useState(true);
  const [formEntrada, setFormEntrada] = useState(false);
  const [formSalida, setFormSalida] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const obtenerRegistros = () => {
    fetch(UrlApi + "/entradas_salidas_propelente/all")
      .then((data) => data.json())
      .then((data) => setRegistros(data));
  };

  useEffect(() => {
    obtenerRegistros();
  }, [tabla, showDialog, formEntrada, formSalida]);

  const showFormEntrada = () => {
    setAnchorEl(null);
    setTabla(false);
    setFormEntrada(true);
  };

  const showFormSalida = () => {
    setAnchorEl(null);
    setTabla(false);
    setFormSalida(true);
  };

  const showDialogs = (row) => {
    setAnchorEl(null);
    setShowDialog(true);
    setDatosRegistro(row);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "e_tractoritalgas", //access nested data with dot notation
        header: "Tractor",
      },
      {
        accessorKey: "s_tractoritalgas", //access nested data with dot notation
        header: "Tractor",
      },
      // {
      //   accessorKey: "e_tanquemovil", //access nested data with dot notation
      //   header: "Tanque",
      // },
      {
        accessorKey: "e_descr_motivo", //access nested data with dot notation
        header: "Motivo Ingreso",
      },
      {
        accessorKey: "s_motivo", //access nested data with dot notation
        header: "Motivo Salida",
      },
      {
        accessorKey: "estado", //access nested data with dot notation
        header: "Estado",
      },
    ],
    []
  );

  const displayColumnDefOptions = {
    "mrt-row-actions": {
      muiTableHeadCellProps: {
        sx: {
          fontSize: 14,
          fontStyle: "italic",
        },
      },
      size: 5,
    },
  };

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem" },
    variant: "outlined",
    color: "success",
    size: "small",
  };

  const initialState = {
    density: "compact",
    // showGlobalFilter: true,
  };

  console.log(registros)

  return (
    <div>
      <Container maxWidth="xl">
        <Navbar />
        {tabla && registros ? (
          <Box mt={2}>
            <MaterialReactTable
              columns={columns}
              data={registros}
              localization={MRT_Localization_ES}
              displayColumnDefOptions={displayColumnDefOptions}
              muiSearchTextFieldProps={muiSearchTextFieldProps}
              initialState={initialState}
              renderTopToolbarCustomActions={() => (
                <>
                  <Button
                    variant="contained"
                    color="success"
                    startIcon={<AddBoxTwoToneIcon color="asd" />}
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    Añadir Registro
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem onClick={() => showFormEntrada()}>
                      Entrada
                    </MenuItem>
                    <MenuItem onClick={() => showFormSalida()}>Salida</MenuItem>
                  </Menu>
                </>
              )}
              enableExpandAll={false}
              enableToolbarInternalActions={true}
              enableColumnResizing
              muiTableHeadCellProps={{
                align: "center",
              }}
              muiTableBodyCellProps={{
                align: "left",
              }}
              enableColumnActions={false}
              enableColumnFilters={false} //desactiva filtros x  columna
              enableHiding={false}
              enableDensityToggle={false} //
              enableColumnDragging={false} //No permite que se arrastren las columnas.
              enableStickyFooter
              enableEditing
              positionActionsColumn="last"
              positionExpandColumn="first"
              renderRowActions={({ row, table }) => (
                <div>
                  {row.original.id_estado === 132 ? (
                    <Tooltip title="Registrar Remito Proveedor">
                      <IconButton
                        color="success"
                        onClick={() => showDialogs(row.original)}
                      >
                        <DescriptionIcon />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                  {row.original.id_estado === 133 ? (
                    <Tooltip title="Registrar Salida De Vehiculo">
                      <IconButton
                        color="error"
                        onClick={() => showDialogs(row.original)}
                      >
                        <ExitToAppIcon />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                                    {row.original.id_estado === 138 ? (
                    <Tooltip title="Registrar Tránsito Salida">
                      <IconButton
                        color="primary"
                        // onClick={() => showDialogs(row.original)}
                      >
                        <ReceiptIcon />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                  {/* {row.original.id_estado !== 76 ? (
                    <>
                      <Tooltip title="Registrar Salida">
                        <IconButton
                          color="success"
                          onClick={() => handleRegSalida(row.original)}
                        >
                          <ExitToAppIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Editar">
                        <IconButton
                          disabled={
                            row.original.baja === 1 ||
                            ((row.original.id_estado === 74 ||
                              row.original.id_estado === 75) &&
                              localStorage.getItem("role") !== "admin")
                          }
                          color="primary"
                          onClick={() => handleEdicion(row.original)}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : null}
                  {row.original.baja !== 1 ||
                  row.original.id_estado !== 76 ||
                  localStorage.getItem("role") !== "admin" ? (
                    <>
                      <Tooltip title="Borrar">
                        <IconButton
                          color="error"
                          onClick={() => handleBaja(row.original)}
                          disabled={row.original.baja === 1}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : null} */}
                </div>
              )}
              renderDetailPanel={({ row }) => (
                <Box
                  sx={{
                    display: 'grid',
                    margin: 'auto',
                    gridTemplateColumns: '1fr 1fr 1fr 1fr',
                    width: '100%',
                    backgroundColor: "#EBF5FB"
                  }}
                >
                  { row.original.e_nroticket ? ( <Typography><b>Nro Ticket</b>: {row.original.e_nroticket}</Typography>):( <Typography><b>Nro Ticket</b>: {row.original.s_nroticket}</Typography>)}
                  { row.original.e_fecha ? ( <Typography><b>Fecha</b>: {moment(row.original.e_fecha).format("DD-MM-YYYY")}</Typography>):( <Typography><b>Fecha</b>: {moment(row.original.s_fecha).format("DD-MM-YYYY")}</Typography>)}
                  { row.original.e_hora ? ( <Typography><b>Hora</b>: {row.original.e_hora}</Typography>):( <Typography><b>Hora</b>: {row.original.s_hora}</Typography>)}
                  { row.original.e_pesada ? ( <Typography><b>Pesada</b>: {row.original.e_pesada}</Typography>):( <Typography><b>Pesada</b>: {row.original.s_pesada}</Typography>)}
                  {
                    row.original.es_entrada_o_salida===1 ? ( //Si ES UN INGRESO: 
                    <>
                    { row.original.e_tractoritalgas? ( <Typography><b>Tractor</b>: {row.original.e_tractoritalgas}</Typography>):( <Typography><b>Tractor</b>: {row.original.e_tractor}</Typography>) }
                    { row.original.e_tanquemovilitalgas ? ( <Typography><b>Tanque</b>: {row.original.e_tanquemovilitalgas}</Typography>):( <Typography><b>Tanque</b>: {row.original.e_tanquemovil}</Typography>) }
                    { row.original.e_choferitalgas ? ( <Typography><b>Chofer</b>: {row.original.e_choferitalgas}</Typography>):( <Typography><b>Chofer</b>: {row.original.e_chofer}</Typography>) }
                    { row.original.e_gasoil ? ( <Typography><b>Gasoil</b>: {row.original.e_gasoil}</Typography>):null }
                    { row.original.e_descr_motivo ? ( <Typography><b>Motivo</b>: {row.original.e_descr_motivo}</Typography>):null }
                    { row.original.e_proveedor ? ( <Typography><b>Proveedor</b>: {row.original.e_proveedor}</Typography>):null }
                    { row.original.e_tanquefijodescarga ? ( <Typography><b>Tanque Descarga:</b> {row.original.e_tanquefijodescarga}</Typography>):null }
                    { row.original.e_producto ? ( <Typography><b>Producto:</b> {row.original.e_producto}</Typography>):null }
                    <Button variant="contained" color="primary" disabled>
                      ver remito de proveedor
                    </Button>
                    </>                   
                     ) :null  
                  }
                  {
                    row.original.es_entrada_o_salida === 2 ? (
                      <>
                        { row.original.s_motivo? ( <Typography><b>Motivo De Salida</b>: {row.original.s_motivo}</Typography>): null }
                        { row.original.s_tractoritalgas? ( <Typography><b>Tractor</b>: {row.original.s_tractoritalgas}</Typography>):( <Typography><b>Tractor</b>: {row.original.s_tractor}</Typography>) }
                        { row.original.s_idtanquemovil ? ( <Typography><b>Tanque</b>: {row.original.s_tanqueitalgas}</Typography>):( <Typography><b>Tanque</b>: {row.original.s_tanquemovil}</Typography>) }
                        { row.original.s_choferitalgas ? ( <Typography><b>Chofer</b>: {row.original.s_choferitalgas}</Typography>):( <Typography><b>Chofer</b>: {row.original.s_chofer}</Typography>) }              
                        { row.original.s_producto ? ( <Typography><b>Producto</b>: {row.original.s_producto}</Typography>) :null  }                   
                        { row.original.s_idtqcarga ? ( <Typography><b>Tanque Origen Carga</b>: {row.original.s_tanquecarga}</Typography>) :null  }                   
                        { row.original.s_cliente1 ? ( <Typography><b>Cliente</b>: {row.original.s_cliente1}</Typography>) :null  }       
                        { row.original.s_cliente2 ? ( <Typography><b>Cliente</b>: {row.original.s_cliente2}</Typography>) :null  }       

                      </>
                    ):null  
                  }
                </Box>
              )}
            />
          </Box>
        ) : null}
        {formEntrada ? (
          <AddRegistroEntrada tabla={setTabla} formEntrada={setFormEntrada} />
        ) : null}
        {formSalida ? (
          <AddRegistroSalida tabla={setTabla} formSalida={setFormSalida} />
        ) : null}
        {showDialog ? (
          <Dialogo
            datos={datosRegistro}
            open={true}
            showDialog={setShowDialog}
            titulo={
              datosRegistro.id_estado === 132
                ? "Remito De Proveedor"
                : "Salida De Vehículo"
            }
          />
        ) : null}
      </Container>
    </div>
  );
}

export default Registros;
