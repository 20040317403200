import React, { useEffect, useState, useMemo } from 'react';
import { UrlApi } from '../../../services/apirest';
import Navbar from '../../Navbar';
import { Container, Typography, Tooltip, IconButton, Box, Button } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import Addcertificaciones from './AddCertificaciones';
import Editarcertificaciones from './EditarCertificacion';
import { createTheme, ThemeProvider, useTheme } from "@mui/material";

export default function Certificaciones() {
    const [certificaciones, setCertificaciones] = useState();
    const [alta, setAlta] = useState(false)
    const [tabla, setTabla] = useState(true)
    const [editar, setEditar] = useState(false)
    const globalTheme = useTheme();
    const [datosCertificaciones, setDatosCertificaciones] = useState({})//datos del formulario

    const handlebuscarCertificaciones = () => {
        fetch(UrlApi + '/certificaciones')
            .then(data => data.json())
            .then(data => setCertificaciones(data))
    };

    useEffect(() => {
        handlebuscarCertificaciones()
    }, [tabla])

    const handleClickNuevo = () => {
        setAlta(true)
        setTabla(false)
    };

    const handleEditar = (datosDeLaLinea) => {
        setEditar(true)
        setTabla(false)
        setDatosCertificaciones(datosDeLaLinea)
    };

    const columns = useMemo(() =>
        [{ header: 'Fecha Certificación', accessorKey: 'fecha_certificacion', type: "date", format: "YYYY-MM-DD" },
        { header: 'Vencimiento Certificación', accessorKey: 'vencimiento_certificacion', type: "date", format: "YYYY-MM-DD" },
        { header: 'Certificadora', accessorKey: 'certificadora' },
        { header: 'Tanque', accessorKey: 'numero_serie' }
        ], []);

    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: globalTheme.palette.mode,
                    primary: globalTheme.palette.primary, //primary color for the table
                    info: {
                        main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            globalTheme.palette.mode === "light"
                                ? "rgb(234, 239, 241)"
                                : "#000", //pure black table in dark mode for fun
                    },
                },
                typography: {
                    button: {
                        textTransform: "none", //customize typography styles for all buttons in table by default
                        fontSize: "1rem",
                    },
                },
                components: {
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                fontSize: "0.8rem", //larger tooltip font size                                
                            },
                        },
                    },
                },

            }), [globalTheme])

    const muiSearchTextFieldProps = {
        placeholder: "Buscar..",
        sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
        variant: "outlined",
        color: "success",
        size: "small",
    };

    const initialState = {
        density: "compact",
        showGlobalFilter: true,
        pagination: { pageIndex: 0, pageSize: 10 }
    };

    const tableHeadCellProps = {
        align: "center",
        sx: {
            fontSize: 16,
            color: "white",
            fontWeight: "normal",
            backgroundColor: "#2471A3",
            borderColor: "black",
        },
    };

    return (
        <div>
            <Navbar />
            <Container maxWidth="xl" mt={1}>
                {
                    certificaciones && tabla ? (
                        <Box mt={2} boxShadow={3}>
                            <ThemeProvider theme={tableTheme}>
                                <MaterialReactTable
                                    enableColumnResizing
                                    columns={columns}
                                    data={certificaciones}
                                    initialState={initialState}
                                    positionActionsColumn="last"                                    
                                    enableExpandAll={false}
                                    enableToolbarInternalActions={true}
                                    muiTableHeadCellProps={tableHeadCellProps}
                                    muiTableBodyCellProps={({ row }) => ({
                                        align: "center",
                                        sx: {
                                            backgroundColor:"#fff",
                                            color: "black",
                                            borderColor: "black",
                                        },
                                    })}
                                    enableColumnActions={false}
                                    muiSearchTextFieldProps={muiSearchTextFieldProps}
                                    enableDensityToggle={false}
                                    renderTopToolbarCustomActions={() => (
                                        <>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                startIcon={<AddBoxTwoToneIcon />}
                                                onClick={handleClickNuevo}
                                            >
                                                Nuevo Registro
                                            </Button>
                                            <Typography variant="poster" fontSize={26}>
                                                Certificaciones 
                                            </Typography>
                                        </>
                                    )}
                                    layoutMode="grid"
                                    localization={MRT_Localization_ES}
                                    enableColumnDragging={false}
                                    enableEditing
                                    renderRowActions={({ row, table }) => (
                                        <div>
                                            <Tooltip title="Editar certificacion">
                                                <IconButton onClick={() => handleEditar(row.original)} color="primary">
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    )}
                                    enableColumnFilters={false} 
                                    enableHiding={false}
                                    positionExpandColumn='first'/>
                            </ThemeProvider>
                        </Box>
                    ) : null}
                {alta ? <Addcertificaciones actualizar={handlebuscarCertificaciones} alta={setAlta} tabla={setTabla} /> : null}
                {editar ? <Editarcertificaciones actualizar={handlebuscarCertificaciones} editar={setEditar} tabla={setTabla} datos={datosCertificaciones}></Editarcertificaciones> : null}
            </Container>
            <br />
        </div>
    )
};