import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {DialogTitle,} from "@mui/material";
import FormRemitoProveedor from "./FormRemitoProveedor"
import FormSalidaProveedor from "./FormSalidaProveedor"


function Dialogo(props) {
  const [open, setOpen] = useState(props.open); // Esto abre o no el modal
  const [datosRegistro, setDatosRegistro]=useState(props.datos);

  const handleClose = () => {
    setOpen(false);
    props.showDialog(false);
  };

  useEffect(()=> {
    props.showDialog(open)
  },[open])

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <Box backgroundColor="#076d55" color="white">
          <DialogTitle>{props.titulo}</DialogTitle>
        </Box>
        <DialogContent>
            {props.datos.id_estado===132 ?(
                <FormRemitoProveedor datos={datosRegistro} open={setOpen} />
            ):null
            }
                        {props.datos.id_estado===133 ?(
                <FormSalidaProveedor datos={datosRegistro} open={setOpen} />
            ):null
            }
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Dialogo;
