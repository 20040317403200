import React, {useState, useEffect} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import {UrlApi} from "../../../services/apirest"
import Notify from "../../Notify"
import Alerta from "../../Alerta"


function FormBajaPedido2(props) {
  const handleClose = () => {
    props.setOpen(false);
  };

  const [observaciones, setObservaciones] = useState("");
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
//   const [colormensaje, setColorMensaje] = useState(""); //define el color que tendrá la notificación
  const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
  const [mensajeAlerta, setMensajeAlerta] = useState(""); //mensaje que contendrá el componente "alerta"
  const [disBtn, setDisBtn]=useState(false);

  async function eliminarPedido(e) {
    setDisBtn(true)
    e.preventDefault();
    const response = await fetch(UrlApi + "/pedidos/" + props.datos.id_pedido, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_estado: 32,
        baja_obs: observaciones,
      }),
    });
    if (response.ok === false) {
      const error = response && response.json();
      setError(error);
      setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      try {
        const json = await response.json();
        if (json.message) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje(
            json.message === "OK" ? "Guardado" : "Guardado. Actualizar RTA Api"
          );
          props.actualizar()
        } else if (json.sqlMessage) {
          json && setError(json);
          setMensajeAlerta(
            "SQL erróneo (" + json.code + " Errno: " + json.errno + " )"
          );
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje("Guardado. Actualizar RTA A Json");
          props.actualizar()

        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      }
    }
  }


  return (
    <div>
      <Dialog open={props.open} onClose={() => handleClose()}>
      {notificacion ? (
          <Notify
            mensaje={mensaje}
            open={openSnack}
            color={"#D4EFDF"}
            severity={"success"}
            handleClose={handleClose}
          />
        ) : null}
        {/* ALERT --> SE MUESTRA SI HAY UN ERROR EN LA API (NO SE CONECTA O ESTÁ MAL LA RUTA, ETC.) */}
        {error && mensajeAlerta ? (
          <Alerta mensaje={mensajeAlerta} severity={"error"} />
        ) : null}

        <Box justifyContent="center" backgroundColor="#1F618D">
          <DialogTitle>
            <Typography variant="h6" align="center" color="#ffffff">
              Baja De Pedido
            </Typography>
          </DialogTitle>
        </Box>
        <DialogContent>
          <DialogContentText textAlign="center">
            Ingrese motivos u observaciones por los que se cancela el pedido.
          </DialogContentText>
          <Box>
            <TextField
              required
              onChange={(event) => {
                setObservaciones(event.target.value);
              }}
              placeholder="Escriba aquí sus observaciones "
              value={observaciones}
              margin="dense"
              type="text"
              fullWidth
              variant="standard"
            />
          </Box>
          <br></br>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="space-between"
            // alignItems="flex-start"
            // alignContent="stretch"
          >
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => handleClose()}
                color="error"
              >
                Cancel
              </Button>
            </Grid>

            <Grid item>
              <Button variant="contained" color="success" onClick={eliminarPedido} disabled={disBtn}>
                Guardar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        {/* <DialogActions> */}

        {/* </DialogActions> */}
      </Dialog>
    </div>
  );
}

export default FormBajaPedido2;
