//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio
// Creation Date : 1/10/2024
// Version : 1
// Description : Pantalla principal de estadisticas de reclamos que son
// instalacion -> id_motivo = 19
//------Changes ----------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React from "react";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@mui/material";
import Navbar from "../../../Navbar";
import Tabla_estados_inst_mobile from "./Tabla_estados_inst_mobile";
import Graf_estados_inst_mobile from "./Graf_estados_inst_mobile";
import Tabla_instaladores_inst_mobile from "./Tabla_instaladores_inst_mobile";
import Tabla_tanque_mobile from "./Tabla_tanque_mobile";
import Graf_tanques_mobile from "./Graf_tanques_mobile";


function Estadisticas_instalacion_mobile() {
  return (
    <div>
      <Navbar />
      <Box
        bgcolor="#2B76A6"
        color="white"
        marginBottom="1em"
        borderRadius="0.5em"
        marginTop={1}
        width="90%" // Ajusta esto según el ancho deseado
        mx="auto" // Centrar horizontalmente
      >
        <Typography variant="h6" component="div" sx={{ marginLeft: "1em" }}>
          Panel de estadísticas de instalación
        </Typography>
      </Box>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Tabla_estados_inst_mobile />
        </Grid>
        <Grid item xs={12}>
          <Graf_estados_inst_mobile />
        </Grid>
        <Grid item xs={12}>
          <Tabla_instaladores_inst_mobile />
        </Grid>
        <Grid item xs={12}>
          <Tabla_tanque_mobile />
        </Grid>
        <Grid item xs={12}>
          <Graf_tanques_mobile />
        </Grid>
      </Grid>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
}

export default Estadisticas_instalacion_mobile;
