//----- Component --------------------------------------------------------/
// Developer : Nicolas Pascucci
// Creation Date : 21/05/2024
// Version : 1
// Description : Dialog para agregar objetivo a un comercial
//------Changes --------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  Box,
  Grid,
  Stack,
  TextField,
  Autocomplete,
  Snackbar,
  Alert,
  Typography,
} from "@mui/material";
import moment from "moment"; // PARA LA FECHA
//adjuntado
import AttachFileIcon from "@mui/icons-material/AttachFile"; //ADJUNTAR ARCHIVOS
import { grey } from "@mui/material/colors";
import Title from "../Title";
import Notify from "../Notify";
import { v4 as uuidv4 } from "uuid"; 
import { logEventos } from "../../services/services";
import "dayjs/locale/es";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

function Dialog_objetivo(props) {
    const [agregarObjetivo, setAgregarObjetivo] = useState(props.agregarObjetivo)
    const [selectedDate, setSelectedDate] = useState('');
    const [datosObjetivo, setDatosObjetivo] = useState({
        objetivo: '',
        monto_pesos: '',
        comercios_butano:'',
        usuarios_butano:'',
        distribuidores_butano:'',
    })
    const [comerciales, setComerciales] = useState(props.comerciales)
    const [comercialSeleccionado, setComercialSeleccionado] = useState({})
    const [openSnack, setOpenSnack] = React.useState(false);
    const [colormensaje, setColorMensaje] = useState("");
    const [mensaje, setMensaje] = useState("");
    const [sucursales, setSucursales] = useState()
    const [sucursalSeleccionada, setSucursalSeleccionada] = useState({})
    const [deshabilitado, setDeshabilitado] = useState(false)

    const [sucursalAuth, setSucursalAuth] = useState()
    const obtenerSucAuth = () => {
      fetch(UrlApi + "/representantes/suc_authxcomercial/"+comercialSeleccionado.id)
        .then((data) => data.json())
        .then((data) => setSucursalAuth(data));
    };
      
    useEffect(() => {
      obtenerSucAuth();
    }, [comercialSeleccionado]);
    // console.log("suc_auth", sucursalAuth);

    const obtenerSucursales = () => {
      if (sucursalAuth && sucursalAuth.length > 0) {
        fetch(UrlApi + "/sucursales/xsucauth/"+sucursalAuth[0].suc_auth)
          .then((data) => data.json())
          .then((data) => setSucursales(data));
      }
      };
          
      useEffect(() => {
        obtenerSucursales();
      }, [sucursalAuth]);

    //Snackbar Notificacion
    const handleCloseSnack = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setOpenSnack(false);
    };

    const handleClose = () => {
      setAgregarObjetivo(!agregarObjetivo);
      if (props.onClose) {
        props.onClose();
      }
    };

    const handleDateChange = (event) => {
      setSelectedDate(event.target.value);
    };
    const formattedDate = `${selectedDate}-01`
    
    const almacenar = (e) => {
      setDatosObjetivo({
        ...datosObjetivo,
        [e.target.name]: e.target.value,
      });
    };
    // console.log("datosObjetivos", datosObjetivo);
    // console.log("Comercial", comercialSeleccionado);
    // console.log("fecha", selectedDate, formattedDate);

    const guardarObjetivo = async (e) => {
      setDeshabilitado(true)
      logEventos("Agregar objetivo a un comercial", "Evaluacion Comerciales", "Agrego objetivo a un comercial", localStorage.getItem("user"))
      e.preventDefault();
      await fetch(UrlApi + "/objetivos_comerciales", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          objetivo:datosObjetivo.objetivo,
          comercial:comercialSeleccionado.id,
          sucursal:sucursalSeleccionada.id,
          fecha: formattedDate,
          comercios_butano: datosObjetivo.comercios_butano,
          usuarios_butano: datosObjetivo.usuarios_butano,
          distribuidores_butano: datosObjetivo.distribuidores_butano,
        }),
      })
        .then((res) => {
          if (!res.err) {
            console.log("Guardado");
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setMensaje("El Objetivo se guardó exitosamente !");
          } else {
            console.log("Error");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
      // PARA LIMPIAR LOS DATOS
      handleClose()
      setDatosObjetivo({ objetivo: '', monto_pesos: '', comercios_butano:'', usuarios_butano:'', distribuidores_butano:'' });
    };

    const [datosExiste, setDatosExiste] = useState({})
    const [existe, setExiste] = useState(false)


    useEffect(() => {
      if (Object.keys(comercialSeleccionado).length === 0 || datosObjetivo.objetivo === ''|| datosObjetivo.comercios_butano === '' || datosObjetivo.usuarios_butano === '' || datosObjetivo.distribuidores_butano === '' || !selectedDate || existe === true ) {
        setDeshabilitado(true)
      } else{
        setDeshabilitado(false)
      }
    }, [datosObjetivo,comercialSeleccionado,existe,selectedDate])
    
    useEffect(() => {
      fetch(
        UrlApi +
          "/objetivos_comerciales/mescomercialsuc/" +
          comercialSeleccionado.id +
          "/" +
          formattedDate +
          "/" +
          sucursalSeleccionada.id
      )
        .then((data) => data.json())
        .then((data) => setDatosExiste(data));
    }, [comercialSeleccionado, selectedDate, sucursalSeleccionada])

    useEffect(() => {
      if (datosExiste && datosExiste[0]) {
        setExiste(true)
      }else{
        setExiste(false)
      }
    }, [datosExiste, selectedDate, datosObjetivo,comercialSeleccionado,sucursalSeleccionada])
    
    // console.log("EXISTE",datosExiste ,datosObjetivo, comercialSeleccionado, selectedDate, existe, deshabilitado);



  return (
    <>
    <Dialog
      open={agregarObjetivo}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      color="#F4F6F6"
    >
      <Title
        titulo={`Agregar Objetivo`}
        handleClose={() => {
          handleClose();
        }}
      />
      <Box
        border={0.5}
        borderColor={grey[300]}
        backgroundColor="#F4F6F6"
        p={2}
        py={2}
        mt={1}
      >
        <DialogContent>
          <Container maxWidth="xl">
            <form autoComplete="off" 
            onSubmit={guardarObjetivo}
            >
              <Grid container mb={1} spacing={1}>
                    <Grid item xs={12} py={1}>
                    {openSnack? 
                      (<TextField
                      // value={(bocaSeleccionada.idbocas_carga = null)}
                      disabled
                      focused
                      fullWidth
                      size="small"
                      label= "Guardando Objetivo..."
                      />)
                      :
                    (<Autocomplete
                        disabled={!comerciales}
                        size="small"
                        focused
                        required
                        disableClearable
                        id="comercial"
                        noOptionsText={"representante Comercial INEXISTENTE"}
                        options={comerciales}
                        autoHighlight
                        getOptionLabel={(comerciales) =>
                            comerciales.id + "-" + comerciales.nombre_apellido
                        }
                        onChange={(event, value) =>{
                            setComercialSeleccionado(value)
                        }}
                        renderOption={(props, option) => (
                            <Box
                                component="li"
                                key={option.id}
                                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                {...props}
                            >
                                {option.id} - {option.nombre_apellido}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                focused
                                size="small"
                                placeholder="Tipee para buscar..."
                                {...params}
                                label="Representante Comercial"
                                inputProps={{
                                    ...params.inputProps,
                                }}
                            />
                        )}
                    />
                      )}
                    </Grid>
                    <Grid item xs={12} py={1} >
                    {openSnack? 
                      (<TextField
                      // value={(bocaSeleccionada.idbocas_carga = null)}
                      disabled
                      focused
                      fullWidth
                      size="small"
                      label= "Guardando Objetivo..."
                      />)
                      :
                    (
                      <Autocomplete
                          disabled={!sucursales}
                          size="small"
                          focused
                          required
                          disableClearable
                          id="sucursal"
                          noOptionsText={"Sucursal INEXISTENTE"}
                          options={sucursales}
                          autoHighlight
                          getOptionLabel={(sucursal) =>
                              sucursal.id + "-" + sucursal.nombre
                          }
                          onChange={(event, value) =>
                              setSucursalSeleccionada(value)
                          }
                          renderOption={(props, option) => (
                              <Box
                                  component="li"
                                  key={option.id}
                                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                  {...props}
                              >
                                  {option.id} - {option.nombre}
                              </Box>
                          )}
                          renderInput={(params) => (
                              <TextField
                                  focused
                                  size="small"
                                  placeholder="Tipee para buscar..."
                                  {...params}
                                  label="SUCURSAL"
                                  inputProps={{
                                      ...params.inputProps,
                                  }}
                              />
                          )}
                      />
                    )}
                  </Grid>
                <Grid item xs={12} py={1} >
                <TextField
                    label="Objetivo Mensual"
                    name="objetivo"
                    size="small"
                    placeholder="..."
                    onKeyPress={(event) => {
                      if (!/[0-9.]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    inputProps={{
                      maxLength: 10,
                    }}
                    focused
                    required
                    fullWidth
                    value={datosObjetivo.objetivo}
                    onChange={almacenar}
                  />
                </Grid>

                <Grid item xs={12} py={1} >
                <TextField
                  size="small"
                  focused
                  label="Período"
                  type="month"
                  value={selectedDate}
                  onChange={handleDateChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                </Grid>

                <Grid item xs={12} py={1}>
                <Typography variant="h6" color="initial">BUTANO</Typography>
                </Grid>
                <Grid item xs={4} py={1}>
                <TextField
                    label="Usuarios"
                    name="usuarios_butano"
                    size="small"
                    placeholder="..."
                    onKeyPress={(event) => {
                      if (!/[0-9.]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    inputProps={{
                      maxLength: 10,
                    }}
                    focused
                    required
                    fullWidth
                    value={datosObjetivo.usuarios_butano}
                    onChange={almacenar}
                  />
                </Grid>
                <Grid item xs={4} py={1} mb={1}>
                <TextField
                    label="Comercios"
                    name="comercios_butano"
                    size="small"
                    placeholder="..."
                    onKeyPress={(event) => {
                      if (!/[0-9.]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    inputProps={{
                      maxLength: 10,
                    }}
                    focused
                    required
                    fullWidth
                    value={datosObjetivo.comercios_butano}
                    onChange={almacenar}
                  />
                </Grid>
                <Grid item xs={4} py={1}>
                <TextField
                    label="Distribuidores"
                    name="distribuidores_butano"
                    size="small"
                    placeholder="..."
                    onKeyPress={(event) => {
                      if (!/[0-9.]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    inputProps={{
                      maxLength: 10,
                    }}
                    focused
                    required
                    fullWidth
                    value={datosObjetivo.distribuidores_butano}
                    onChange={almacenar}
                  />
                </Grid>

                {existe === true?
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom style={{ color: 'red' }}>
                     Ya Existe un Objetivo Para el Comercial en Este Período 
                  </Typography>
                </Grid>
                :null}
              </Grid>
              <Stack direction="row">
                <Grid container direction={{ xs: "column", sm: "row" }}>
                  <Grid item xs={8}>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleClose}
                    >
                      Volver
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={deshabilitado}
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Stack>
            </form>
          </Container>
        </DialogContent>
      </Box>
    </Dialog>

    <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>
  </>
  )
}

export default Dialog_objetivo