import { Button, Container, 
Card, CardContent,Typography,CardActions } from '@mui/material'
import React, { useState } from 'react'

function ConfirmDialog(props) {
  const [abierto, setAbierto] = useState(props.open)
  return (
    <div>
      {abierto ?
      <Container style={{paddingLeft:"20px"}}>
        <br />
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <Typography sx={{ fontSize: 16 }} color="white" bgcolor="teal" gutterBottom>
                  Notificación del Sistema
            </Typography>
            <Typography variant="h5" component="div" color="Highlight">
                  {props.titulo}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="brown">
                  {props.errores}
            </Typography>
            <Typography variant="body2">
                  Hacé un click en el boton {props.botonconfirmar} para modificarlo o en {props.botoncancelar} para volver al menu.
            </Typography>
          </CardContent>
          <CardActions>
                <Button variant="outlined" color="primary" onClick={() => props.onclick(true) & setAbierto(false)}>{props.botonconfirmar}</Button>  
                <Button variant="outlined" color="warning" onClick={() => window.location.hash = "/navbar"}>{props.botoncancelar}</Button>
          </CardActions>
        </Card>
      </Container>
      : null }
    </div>
  )
}






export default ConfirmDialog
