/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  filledInputClasses,
  IconButton,
  List,
  ListItem,
  Snackbar,
  SnackbarContent,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import { useState } from "react";
import { UrlApi } from "../../../services/apirest";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect } from "react";
import { DownloadFiles } from "../../../services/DownloadFiles";
import PreviewIcon from "@mui/icons-material/Preview";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { Container } from "@mui/system";
//import swal from 'sweetalert';

function FileUploadMultipleDocu(props) {
  const [openclose, setOpenClose] = useState(props.openclose);
  const [fileList, setFileList] = useState([]);
  const [snack, setSnack] = useState(false);
  const [mensaje, setMensaje] = useState("Guardando Archivos...");
  const [colortexto, setColorTexto] = useState("white");
  const [colorfondo, setColorFondo] = useState("black");
  const [archivos, setArchivos] = useState();
  //dialogo si/no
  const [confirmOpen, setConfirmOpen] = useState(false);
  // para ver pdfs e imagenes
  const [pdfUrl, setPdfUrl] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  // console.log(props.fileId)

  const borraAdjunto = (e, id, archive) => {
    e.preventDefault();
    if (window.confirm("Seguro de borrar el archivo " + archive)) {
      fetch(UrlApi + "/uploadfiles/todownload/" + id, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      }).then((res) =>
        res.ok
          ? (setMensaje("Archivo borrado"),
            setColorTexto("white"),
            setColorFondo("green"),
            setSnack(true))
          : (setMensaje("No se borro el archivo"),
            setColorTexto("white"),
            setColorFondo("red"),
            setSnack(true))
      );
    }
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnack(false);
  };

  const handleFileChange = (e) => {
    setFileList(e.target.files);
  };

  const removefiles = (e) => {
    const s = Array.from(fileList).filter((item, index) => index !== e);
    setFileList(s);
    console.log(fileList);
  };

  const handleClose = () => {
    // setOpenClose(!props.openclose);
    setOpenClose(false);
    setMensaje("Guardar Archivos Cancelado");
    setColorTexto("white");
    setColorFondo("red");
    setSnack(true);
  };

  const handleUploadClick = () => {
    if (!fileList) {
      setMensaje("No hay archivos para guardar");
      setSnack(true);
      setColorTexto("white");
      setColorFondo("red");
      setOpenClose(!openclose);
      return;
    }
    // 👇 Creamos un nuevo objeto FormData y le agregamos los archivos
    const data = new FormData();
    data.append("uid", props.fileId); //--> agregamos 1 identificador unico lo recibimos como parametro (uuid)
    data.append("idclasedocumento", props.idclasedocumento);
    for (let i = 0; i < fileList.length; i++) {
      data.append("archivo", fileList[i]);
    }
    console.log("esto mando a guardar", fileList);
    // 👇 Uploading de los archivos con Fetch a la API
    fetch(UrlApi + "/uploadfiles/uploadfiles/proveedor", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then(
        (data) => setMensaje(data.message),
        setSnack(true),
        setColorTexto("white"),
        setColorFondo("green"),
        setFileList([]),
        setOpenClose(!openclose)
      )
      .catch((err) => console.error(err));
    props.setActualizar ? props.setActualizar(1) : null;
    props.disBtn ? props.disBtn(false) : props.disBtn(true);
  };

  // 👇 files is not an array, but it's iterable, spread to get an array of files
  //const files = fileList ? [...fileList] : [];

  const fetchPdf = async (archivo) => {
    //setImageUrl("")
    const response = await fetch(UrlApi + "/uploadfiles/uploadspdf/" + archivo);
    //setPdfUrl(URL.createObjectURL(await response.blob()));
    setImageUrl(URL.createObjectURL(await response.blob()));
  };

  const fetchImage = async (archivo) => {
    //setPdfUrl("")
    const response = await fetch(UrlApi + "/uploadfiles/uploads/" + archivo);
    setImageUrl(URL.createObjectURL(await response.blob()));
  };

  const ViewFiles = async (archivo, extension) => {
    (await extension) === "application/pdf"
      ? fetchPdf(archivo)
      : fetchImage(archivo);
  };

  useEffect(() => {
    props.fileId &&
      fetch(
        UrlApi +
          "/uploadfiles/todownloadDocumentacion/" +
          props.fileId +
          "/" +
          props.idclasedocumento
      )
        .then((data) => data.json())
        .then((data) => setArchivos(data));
  }, [snack, props]);

  console.log(props);

  return (
    <>
      {openclose ? (
        <div>
          <Dialog
            open={props.openclose}
            close={handleClose}
            fullWidth
            PaperProps={{
              sx: {
                height: 600,
              },
            }}
          >
            <DialogTitle>
              <IconButton
                component="label"
                size="small"
                sx={{
                  border: "1px solid blue",
                  borderRadius: 1,
                  color: "blue",
                  fontStyle: "italic",
                  paddingRight: "10px",
                }}
              >
                <input
                  hidden
                  type="file"
                  multiple
                  onChange={handleFileChange}
                />
                <ContentPasteSearchIcon size="small" />
                Agregar archivos
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {console.log(fileList)}
              {fileList ? (
                <Table size="small">
                  <TableBody>
                    {Array.from(fileList).map(
                      (
                        file,
                        i ///Array.from lo transforma a array
                      ) => (
                        <TableRow key={i} sx={{ border: 1 }}>
                          <TableCell align="left" padding="none">
                            <IconButton size="small">
                              <DeleteIcon
                                onClick={() => removefiles(i)}
                                color="error"
                              />
                            </IconButton>
                          </TableCell>
                          <TableCell align="left" padding="none">
                            {file.name} - {(file.size / 1000).toFixed(2)} kb
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              ) : null}
              {/* archivos ya adjuntados anteriormente */}
              <Divider />
              <Table size="small">
                <TableBody>
                  {archivos ? (
                    archivos.map((archivo) => (
                      <TableRow key={archivo.id}>
                        <TableCell>
                          <IconButton
                            size="small"
                            color="secondary"
                            component="label"
                            disabled={
                              archivo.extension === "application/pdf" ||
                              archivo.extension === "image/jpeg"
                                ? false
                                : true
                            }
                          >
                            <VisibilityIcon
                              onClick={() =>
                                ViewFiles(
                                  archivo.id_archivo + "-" + archivo.nombre,
                                  archivo.extension
                                )
                              }
                            />
                          </IconButton>
                          <IconButton
                            size="small"
                            color="success"
                            component="label"
                          >
                            <DownloadForOfflineIcon
                              onClick={() =>
                                DownloadFiles(
                                  archivo.id_archivo + "-" + archivo.nombre
                                )
                              }
                            />
                          </IconButton>
                          <IconButton
                            size="small"
                            color="error"
                            component="label"
                          >
                            <DeleteIcon
                              onClick={(e) =>
                                borraAdjunto(e, archivo.id, archivo.nombre)
                              }
                            />
                          </IconButton>
                        </TableCell>
                        <TableCell>{archivo.nombre}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <Typography variant="h7">
                      No hay archivos adjuntos
                    </Typography>
                  )}
                </TableBody>
              </Table>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleClose} variant="outlined" color="error">
                Volver
              </Button>
              <Button
                onClick={handleUploadClick}
                variant="outlined"
                color="success"
              >
                Guardar
              </Button>
            </DialogActions>
          </Dialog>
          {/*{pdfUrl && window.open(pdfUrl)} */}
          {imageUrl && window.open(imageUrl, "_blank", "noreferrer")}
        </div>
      ) : null}
      <Snackbar
        open={snack}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <SnackbarContent
          style={{
            backgroundColor: colorfondo,
            color: colortexto,
            fontWeight: "bold",
          }}
          message={mensaje}
        />
      </Snackbar>
    </>
  );
}

export default FileUploadMultipleDocu;
